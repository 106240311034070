import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { useEffect, useState } from "react";
import { HotelInitialState } from "slices/hotel/reducer";
import { FilePondFile } from "filepond";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  AdditionalPagesInitialState,
  resetAddAdditionalPageFormFields,
  resetUpdateAdditionalPageFormFields,
} from "slices/myWebSite/additionalPages/reducer";
import {
  AddAdditionalPagesPayload,
  UpdateAdditionalPagesPayload,
} from "types/myWebSite";
import {
  addAdditionalPage,
  getAdditionalPages,
  updateAdditionalPage,
} from "slices/myWebSite/additionalPages/thunk";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    additionalPage,
    additionalPageFormErrors,
    addAdditionalPageLoading,
    updateAdditionalPageLoading,
  } = useSelector(
    (state: { AdditionalPages: AdditionalPagesInitialState }) =>
      state.AdditionalPages
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const pageStatusOptions: Option[] = [
    { value: "0", label: "Kapalı" },
    { value: "1", label: "Açık" },
  ];

  const handleAddNewAdditionalPage = () => {
    const payload: AddAdditionalPagesPayload = new FormData();

    payload.append("otel_id", otel_id.toString());
    payload.append("seflink", additionalPage.seflink);
    payload.append(
      "organizasyon_sayfasi_acik",
      additionalPage.organizasyon_sayfasi_acik.toString()
    );
    payload.append(
      "organizasyon_link_adi",
      additionalPage.organizasyon_link_adi
    );
    payload.append("organizasyon_baslik", additionalPage.organizasyon_baslik);
    payload.append(
      "organizasyon_aciklama",
      additionalPage.organizasyon_aciklama
    );
    payload.append(
      "organizasyon_kart_baslik",
      additionalPage.organizasyon_kart_baslik
    );
    payload.append(
      "organizasyon_kart_aciklama",
      additionalPage.organizasyon_kart_aciklama
    );

    const file_organizasyon_vitrin_resmi =
      additionalPage.organizasyon_vitrin_resmi as FilePondFile;
    payload.append(
      "organizasyon_vitrin_resmi",
      file_organizasyon_vitrin_resmi.file,
      file_organizasyon_vitrin_resmi.file.name
    );

    const file_organizasyon_kart_resim =
      additionalPage.organizasyon_kart_resim as FilePondFile;
    payload.append(
      "organizasyon_kart_resim",
      file_organizasyon_kart_resim.file,
      file_organizasyon_kart_resim.file.name
    );

    dispatch(
      addAdditionalPage(payload, () => {
        dispatch(getAdditionalPages(otel_id));
        dispatch(resetAddAdditionalPageFormFields());
      })
    );
  };

  const handleUpdateAdditionalPage = () => {
    const payload: UpdateAdditionalPagesPayload = new FormData();

    payload.append("otel_id", otel_id.toString());
    payload.append("id", additionalPage.id.toString());
    payload.append("seflink", additionalPage.seflink);
    payload.append(
      "organizasyon_sayfasi_acik",
      additionalPage.organizasyon_sayfasi_acik.toString()
    );
    payload.append(
      "organizasyon_link_adi",
      additionalPage.organizasyon_link_adi
    );
    payload.append("organizasyon_baslik", additionalPage.organizasyon_baslik);
    payload.append(
      "organizasyon_aciklama",
      additionalPage.organizasyon_aciklama
    );
    payload.append(
      "organizasyon_kart_baslik",
      additionalPage.organizasyon_kart_baslik
    );
    payload.append(
      "organizasyon_kart_aciklama",
      additionalPage.organizasyon_kart_aciklama
    );

    if (additionalPage.organizasyon_vitrin_resmi) {
      const file_organizasyon_vitrin_resmi =
        additionalPage.organizasyon_vitrin_resmi as FilePondFile;
      payload.append(
        "organizasyon_vitrin_resmi",
        file_organizasyon_vitrin_resmi.file,
        file_organizasyon_vitrin_resmi.file.name
      );
    } 
    if (additionalPage.organizasyon_kart_resim) {
      const file_organizasyon_kart_resim =
        additionalPage.organizasyon_kart_resim as FilePondFile;
      payload.append(
        "organizasyon_kart_resim",
        file_organizasyon_kart_resim.file,
        file_organizasyon_kart_resim.file.name
      );
    } 

    dispatch(
      updateAdditionalPage(payload, () => {
        dispatch(getAdditionalPages(otel_id));
        dispatch(resetAddAdditionalPageFormFields());
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    pageStatusOptions,
    additionalPage,
    additionalPageFormErrors,
    addAdditionalPageLoading,
    updateAdditionalPageLoading,
    onAddNewAdditionalPage: handleAddNewAdditionalPage,
    onUpdateAdditionalPage: handleUpdateAdditionalPage,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
