import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrmDashboardInitialState } from "slices/dashboard/crm/reducer";
import { getCrmDashboardData } from "slices/dashboard/crm/thunk";

const useDashboard = () => {
  const dispatch: Dispatch<any> = useDispatch();

  // const { user } = useSelector((state: any) => state.Login);

  // interface UserInfo {
  //   inet: string;
  //   username: string;
  //   name: string;
  //   email: string;
  //   officeInternalPhoneNumber: string;
  // }

  // const userInfo: UserInfo = {
  //   inet: user.inet_kodu,
  //   username: user.kullanici_adi,
  //   name: user.ad_soyad,
  //   email: user.email_adresi,
  //   officeInternalPhoneNumber: user.dahili,
  // };


  const { isLoading } = useSelector(
    (state: { CrmDashboard: CrmDashboardInitialState }) => state.CrmDashboard
  );

  useEffect(
    () => {
      dispatch(getCrmDashboardData());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return {
    isLoading,
  };
};

export default useDashboard;
