import { useState } from "react";
import { Tooltip } from "reactstrap";
import { BankInstallment } from "types/reservation";

export interface BankInstallmentsWithNoInterestTooltipProps {
  bankInstallments: BankInstallment[];
}

const BankInstallmentsWithNoInterestTooltip = ({
  bankInstallments,
}: BankInstallmentsWithNoInterestTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const url = "https://crm.otelfiyat.com/img/";

  const noInterestInstallments = bankInstallments.filter(
    (installment) => installment.komisyon === 0
  );

  const noInterestInstallmentsGroupedByBankCode = noInterestInstallments.reduce(
    (acc, installment) => {
      if (!acc[installment.icon]) {
        acc[installment.icon] = [];
      }
      acc[installment.icon].push(installment);
      return acc;
    },
    {} as { [key: string]: BankInstallment[] }
  );

  return (
    <div>
      <span
        id="bankInstallmentsWithNoInterestTooltip"
        className="fw-medium fs-13 text-nowrap theme-text-primary text-decoration-underline cursor-pointer"
      >
        Peşin Fiyatına Taksit Seçenekleri
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        target="bankInstallmentsWithNoInterestTooltip"
        toggle={toggle}
        popperClassName="border rounded"
        style={{
          maxWidth: "500px",
          width: "fit-content",
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        <div className="d-flex flex-column gap-2 p-2">
          {Object.entries(noInterestInstallmentsGroupedByBankCode).map(
            ([bankCode, installments], index) => (
              <div key={index} className="d-flex gap-4 aling-items-end">
                <img
                  src={`${url}${bankCode}`}
                  alt="bank"
                  style={{ width: "45px", height: "20px" }}
                />
                <div className="d-flex gap-2">
                  {installments.map((installment, index) => (
                    <span key={index} className="fw-medium fs-13 text-nowrap">
                      {installment.ay} Taksit
                    </span>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default BankInstallmentsWithNoInterestTooltip;
