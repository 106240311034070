import { UncancellableRoomRatesPage } from "Components/Custom/DiscountsAndActionsPages";
import useUncancellableRoomRates from "Components/Hooks/discountsAndActions/useUncancellableRoomRates";
import PageContainer from "pages/PageContainer";

const UncancellableRoomRates = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useUncancellableRoomRates();

  return (
    <PageContainer title="İptal Edilemez Oda Oranları">
      <UncancellableRoomRatesPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        isLoading={isLoading}
        error={error}
        {...props}
      />
    </PageContainer>
  );
};

export default UncancellableRoomRates;
