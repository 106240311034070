import { MonthlySalesPage } from "Components/Custom/ReportsPages";
import useMonthlySales from "Components/Hooks/reports/useMonthlySales";
import PageContainer from "pages/PageContainer";

const MonthlySales = () => {
  const { isLoading, defaultState } = useMonthlySales();

  return (
    <PageContainer title="Personel Aylık Satış Listesi">
      <MonthlySalesPage isLoading={isLoading} defaultState={defaultState} />
    </PageContainer>
  );
};

export default MonthlySales;
