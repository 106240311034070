import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { FacilityRoomName } from "types/facility";
import useHandleManipulateRoom from "./useHandleManipulateRoom";

interface ManipulateRoomInputProps {
  room: FacilityRoomName;
  isLoading: boolean;
  forPassive?: boolean;
}

const ManipulateRoomInput = ({
  room,
  isLoading,
  forPassive,
}: ManipulateRoomInputProps) => {
  const [roomName, setRoomName] = useState(room.oda_ismi);

  const [isEditing, setIsEditing] = useState(false);

  const { onUpdate, onPassify, onActivate } = useHandleManipulateRoom();

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setRoomName(room.oda_ismi);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !isLoading && setLoading(false);
  }, [isLoading]);

  return (
    <div
      className={`d-flex justify-content-between align-items-center gap-2 p-1 rounded theme-bg-light border ${
        forPassive ? "border-danger" : "border-primary"
      }`}
    >
      <Input
        className="fs-14"
        type="text"
        autoCorrect="on"
        value={roomName}
        onChange={(e) => setRoomName(e.target.value)}
        disabled={!isEditing}
      />
      {forPassive ? (
        <>
          <div className="d-flex gap-3 py-1 px-2 rounded shadow-sm bg-white">
            {loading ? (
              <i className="ri-loader-2-line animate-spin fs-18"></i>
            ) : (
              <i
                className="ri-add-circle-fill theme-text-success fs-18 cursor-pointer"
                title="Aktif Yap"
                onClick={() => {
                  setLoading(true);
                  onActivate(room.oda_id);
                }}
              ></i>
            )}
          </div>
        </>
      ) : (
        <div className="d-flex gap-3 py-1 px-2 rounded shadow-sm bg-white">
          {loading ? (
            <i className="ri-loader-2-line animate-spin fs-18"></i>
          ) : isEditing ? (
            <>
              <i
                className="ri-save-fill theme-text-success fs-18 cursor-pointer"
                title="Kaydet"
                onClick={() => {
                  setLoading(true);
                  onUpdate(room.oda_id, roomName);
                  setIsEditing(false);
                }}
              ></i>
              <i
                className="ri-close-fill theme-text-danger fs-18 cursor-pointer"
                title="İptal"
                onClick={cancelEditing}
              ></i>
            </>
          ) : (
            <>
              <i
                className="ri-pencil-fill theme-text-primary fs-18 cursor-pointer"
                title="Düzenle"
                onClick={startEditing}
              ></i>
              <i
                className="ri-stop-circle-fill theme-text-danger fs-18 cursor-pointer"
                title="Pasife Al"
                onClick={() => {
                  setLoading(true);
                  onPassify(room.oda_id);
                }}
              ></i>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ManipulateRoomInput;
