import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddGiftsAndPromotionFormErrors } from "slices/discountsAndActions/giftsAndPromotions/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GiftAndPromotionFormErrors,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: GiftAndPromotionState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: GiftAndPromotionFormErrors = {};

    if (
      values.hediye_kosulu !== 3 &&
      (!values.konaklama_baslangic_tarihi || !values.konaklama_bitis_tarihi)
    ) {
      errors.konaklama_tarihi = "Başlangıç ve bitiş tarihleri boş bırakılamaz.";
    }

    if (values.tum_gunler === "0" && values.gunler.length === 0) {
      errors.gun = "En az bir gün seçilmelidir.";
    }

    if (values.hediye_kosulu !== 1 && !values.min_gece_sayisi) {
      errors.min_gece_sayisi = "Minimum gece sayısı boş bırakılamaz.";
    }

    if (values.hediye_id === 0) {
      errors.hediye_id = "Hediye seçmelisiniz.";
    }

    dispatch(handleAddGiftsAndPromotionFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: GiftAndPromotionState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
