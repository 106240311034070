import useDailyReservations from "Components/Hooks/reports/useDailyReservations";
import { DailyReservationsPage } from "Components/Custom/ReportsPages";

import PageContainer from "pages/PageContainer";

const DailyReservations = () => {
  const { isLoading } = useDailyReservations();

  return (
    <PageContainer title="Günlük Satış Raporu">
      <DailyReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default DailyReservations;
