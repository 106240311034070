const Footer = () => {
  return (
    <footer
      // className="footer"
      style={{
        backgroundColor: `var(--theme-page-bg)`,
        position: "fixed",
        bottom: 0,
        width: "100%",
        left: 0,
        padding: "6px 0",
      }}
    >
      <p className="text-center text-gray-500 fs-14 m-0 text-muted fw-semibold">
        &copy;{new Date().getFullYear()} Tüm Hakları Saklıdır. İdeal Tur Turizm
        Tic. A.Ş.
      </p>
    </footer>
  );
};

export default Footer;
