import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableBox from "./SortableBox";
import DragItem from "./DragItem";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { setToastError } from "slices/toast/reducer";

interface Item {
  id: string;
  text: string;
  order?: number;
}

interface CustomDragDropBoxProps {
  draggableItems: Item[];
  sortableItems: Item[];
  setDraggableItems: (items: Item[]) => void;
  setSortableItems: (items: Item[]) => void;
  draggableListTitle: string;
  sortableListTitle: string;
  checkItemIsRemovable?: (item: Item) => {
    status: boolean;
    errorMessage: string;
  };
}

const CustomDragDropBox = ({
  draggableItems,
  sortableItems,
  setDraggableItems,
  setSortableItems,
  draggableListTitle,
  sortableListTitle,
  checkItemIsRemovable,
}: CustomDragDropBoxProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const addItemToSortableBox = (item: Item) => {
    setDraggableItems(draggableItems.filter((i) => i.id !== item.id));
    setSortableItems(
      sortableItems.length
        ? [...sortableItems, { ...item, order: sortableItems.length + 1 }]
        : [{ ...item, order: 1 }]
    );
  };

  const removeItemFromSortableBox = (item: Item) => {
    const itemIsRemovable = checkItemIsRemovable
      ? checkItemIsRemovable(item)
      : { status: false, errorMessage: "" };

    if (itemIsRemovable.status) {
      dispatch(setToastError(itemIsRemovable.errorMessage));
      return;
    }
    setSortableItems(
      sortableItems
        .filter((i) => i.id !== item.id)
        .map((i, index) => ({ ...i, order: index + 1 }))
    );
    setDraggableItems([...draggableItems, item]);
  };
  return (
    <>
    <DndProvider backend={HTML5Backend}>
      <div className="d-flex gap-2">
        <div className="d-flex flex-column gap-1">
          <p className="text-center fw-medium m-0 p-1 border border-primary theme-text-primary bg-primary bg-opacity-10 rounded-top">
            {sortableListTitle}
          </p>
          <SortableBox
            sortableItems={sortableItems}
            addItemToSortableBox={addItemToSortableBox}
            removeItemFromSortableBox={removeItemFromSortableBox}
          />
        </div>
        <div className="d-flex flex-column gap-1">
          <p className="text-center fw-medium m-0 p-1 border border-primary theme-text-primary bg-primary bg-opacity-10 rounded-top">
            {draggableListTitle}
          </p>
          <div
            className="d-flex flex-column gap-1 px-1 py-2 border border-2 rounded-bottom theme-bg-light"
            style={{ minHeight: "200px", minWidth: "350px" }}
          >
            {draggableItems.map((item) => (
              <DragItem key={item.id} id={item.id} text={item.text} />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
    </>
  );
};

export default CustomDragDropBox;
