import CalculatePriceForm from "../ReservationCallPage/CalculatePriceForm";
import GreetingMessage from "./GreetingMessage";
import HotelChildrenInfo from "./HotelChildrenInfo";

const CalculatePricePage = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <CalculatePriceForm singleCalculation />
      <HotelChildrenInfo />
      <GreetingMessage />
    </div>
  );
};

export default CalculatePricePage;
