import ListHotelsWithoutCommentsPage from "Components/Custom/CommentsPages/ListHotelsWithoutCommentPage";
import useListHotelsWithoutComments from "Components/Hooks/comments/useListHotelsWithoutComments";
import PageContainer from "pages/PageContainer";

const ListHotelsWithoutComments = () => {
    const { isLoading } = useListHotelsWithoutComments();

    return (
        <PageContainer title={"Yorumsuz Otelleri Listele"}>
            <ListHotelsWithoutCommentsPage isLoading={isLoading}  />
        </PageContainer>
    )
}

export default ListHotelsWithoutComments;