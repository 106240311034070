import { useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import CustomTableContainer from "./CustomTableContainer";
import { PaginationBottom } from "types/GlobalTypes";
import CustomExcelExport from "../CustomExcelExport";
import CustomPdfExport from "../CustomPdfExport";
import CustomTableLegend from "../CustomTableElements/CustomTableLegend";

interface Column {
  header: string;
  cell?: (cell: any) => JSX.Element;
  accessorKey: string;
  enableColumnFilter: boolean;
}

interface CustomReactTableProps {
  tableData: any[];
  tableColumns: Column[];
  columnVisibility?: any;
  fileName?: string;
  legend?: any;
  pagination?: boolean;
  loading?: boolean;
  onSearch?: (searchValue: string) => void;
  onPageSizeChange?: (pageSize: number) => void;
  paginationBottomProps?: PaginationBottom;
}

const CustomReactTable = ({
  tableData,
  tableColumns,
  columnVisibility,
  fileName,
  legend,
  pagination = false,
  loading = false,
  onSearch = () => {},
  onPageSizeChange = () => {},
  paginationBottomProps,
}: CustomReactTableProps) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);

  const filteredData = useMemo(() => {
    if (columnVisibility)
      return tableData.map((data) => {
        const filteredData: any = {};
        Object.keys(data).forEach((item: any) => {
          !Object.keys(columnVisibility).includes(item) &&
            (filteredData[item] = data[item]);
        });

        delete filteredData["rowColor"];
        return filteredData;
      });
    return tableData;
  }, [tableData, columnVisibility]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody className="theme-bg-light rounded overflow-hidden">
            <div className="d-flex justify-content-between align-items-center mb-3 gap-3">
              <div className="d-flex align-items-center gap-2">
                {fileName && filteredData.length ? (
                  <>
                    <CustomExcelExport
                      fileData={filteredData}
                      fileName={fileName}
                    />
                    <CustomPdfExport
                      objectToPrint={filteredData}
                      fileName={fileName}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              {legend ? <CustomTableLegend legend={legend} /> : <></>}
            </div>

            <CustomTableContainer
              columns={columns || []}
              columnVisibility={columnVisibility || []}
              data={tableData || []}
              isGlobalFilter={true}
              customPageSize={25}
              SearchPlaceholder="Ara..."
              thClass="bg-secondary text-secondary bg-opacity-10 fs-12 text-start border border-2 cursor-pointer"
              tableClass="border border-2 rounded p-1"
              divClass="table-responsive"
              pagination={pagination}
              loading={loading}
              onSearch={onSearch}
              onPageSizeChange={onPageSizeChange}
              paginationBottomProps={paginationBottomProps}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomReactTable;
