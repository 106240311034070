import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";

import DiscountsForm from "./DiscountsForm";
import DiscountsTable from "./DiscountesTable";
import { SPECIAL_DISCOUNTS_DESCRIPTIONS } from "utils/pageDescriptions";
import RoomSelector from "./DiscountsForm/RoomSelector";
import PageContainer from "../PageContainer";

interface SpecialDiscountsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const SpecialDiscountsPage = (props: SpecialDiscountsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={SPECIAL_DISCOUNTS_DESCRIPTIONS}
      roomSelector={<RoomSelector {...props} />}
      isLoading={isLoading}
    >
      <DiscountsForm {...props} />
      <DiscountsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(SpecialDiscountsPage);
