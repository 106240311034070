import CalculatePriceForm from "../ReservationCallPage/CalculatePriceForm";
import SpecialDiscountsForm from "./SpecialDiscountsForm";
import { CalculatePriceFormValues } from "types/reservation";
import FacilityDetails from "./FacilityDetails";

interface SpecialDiscountsFormProps {
  formValues: CalculatePriceFormValues;
  handleChangeForm: (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => void;
}

const CalculatePriceForHotelPage = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <CalculatePriceForm>
        {({ formValues, handleChangeForm }: SpecialDiscountsFormProps) => (
          <SpecialDiscountsForm
            formValues={formValues}
            handleChangeForm={handleChangeForm}
          />
        )}
      </CalculatePriceForm>
      <FacilityDetails />
    </div>
  );
};

export default CalculatePriceForHotelPage;
