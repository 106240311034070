// SortableBox.tsx
import { useDrop } from "react-dnd";
import { ItemType } from "./DragItem";
import SortableItem from "./SortableItem";

interface BoxItem {
  id: string;
  text: string;
}

interface SortableBoxProps {
  sortableItems: BoxItem[];
  addItemToSortableBox: (item: BoxItem) => void;
  removeItemFromSortableBox: (item: BoxItem) => void;
}

const SortableBox = ({
  sortableItems,
  addItemToSortableBox,
  removeItemFromSortableBox,
}: SortableBoxProps) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemType,
    drop: (item: BoxItem) => addItemToBox(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const addItemToBox = (item: BoxItem) => {
    if (!sortableItems.find((i) => i.id === item.id)) {
      addItemToSortableBox(item);
    }
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    // const updatedItems = [...sortableItems];
    // const [removed] = updatedItems.splice(dragIndex, 1);
    // updatedItems.splice(hoverIndex, 0, removed);
    // addItemToSortableBox(updatedItems);
  };

  const removeItem = (id: string) => {
    const item = sortableItems.find((i) => i.id === id) as BoxItem;

    removeItemFromSortableBox(item);
  };

  return (
    <div
      className="d-flex flex-column gap-2 px-1 py-2 border border-2 rounded-bottom theme-bg-light"
      ref={drop}
      style={{
        minHeight: "200px",
        minWidth: "350px",
      }}
    >
      {isOver && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        />
      )}
      {sortableItems.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          id={item.id}
          text={item.text}
          moveItem={moveItem}
          removeItem={removeItem}
        />
      ))}
    </div>
  );
};

export default SortableBox;
