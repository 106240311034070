import {
  CustomFormFieldContainer,
  CustomMultipleFileUploader,
} from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeSpecialDayInput } from "slices/facility/facilitySpecialDays/reducer";
import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";
import { FACILITY_SPECIAL_DAY_IMAGE_DESCRIPTION } from "utils/fieldDescriptions";

interface SpecialDayImageSelectorProps {
  values: FacilitySpecialDayState;
  formErrors: FacilitySpecialDayFormErrors;
  validateFormChange: (values: FacilitySpecialDayState) => void;
}

const SpecialDayImageSelector = ({
  values,
  formErrors,
  validateFormChange,
}: SpecialDayImageSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { id } = values;

  const files = values.ozel_gun_resim ? [values.ozel_gun_resim] : [];
  const imageUrl = values.ozel_gun_resim_yol;

  return (
    <>
      <CustomFormFieldContainer
        label="Özel Gün Resmi"
        description={FACILITY_SPECIAL_DAY_IMAGE_DESCRIPTION}
        required={id ? false : true}
      >
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeSpecialDayInput({ resim: fileItems[0] }));
          }}
          maxFiles={1}
        />
      </CustomFormFieldContainer>
      {id ? (
        <CustomFormFieldContainer label="Mevcut Resim">
          {imageUrl ? (
            <div
              className="border rounded bg-white"
              style={{ height: "200px" }}
            >
              <img
                src={imageUrl}
                alt="Resim"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          ) : (
            <p>Resim Bulunamadı</p>
          )}
        </CustomFormFieldContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpecialDayImageSelector;
