import { FacilityPartialPaymentDatesPage } from "Components/Custom/FacilityPages";
import useFacilityPartialPaymentDates from "Components/Hooks/facility/useFacilityPartialPaymentDates";
import PageContainer from "pages/PageContainer";

const FacilityPartialPaymentDates = () => {
  const { isLoading } = useFacilityPartialPaymentDates();

  return (
    <PageContainer title="Tesis Kısmi Ödeme Tarihleri">
      <FacilityPartialPaymentDatesPage loading={isLoading} />
    </PageContainer>
  );
};

export default FacilityPartialPaymentDates;
