import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { CommercialTableCardData } from "types/dashboard";
import useHandleTable from "./useHandleTable";
import CustomSimpleTable from "Components/Custom/UI/CustomSimpleTable/CustomSimpleTable";

interface CommercialTableCardProps {
  values: CommercialTableCardData[];
}

const CommercialTableCard = ({ values }: CommercialTableCardProps) => {
  const { tableData, tableColumns } = useHandleTable(values);
  
  return (
    <>
      <CustomDashboardCard xl={12} title="Tesis Reklam Tablosu">
      <CustomSimpleTable
        tableData={tableData}
        tableColumns={tableColumns}
      />
      </CustomDashboardCard>
    </>
  );
};

export default CommercialTableCard;
