import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/nightRestrictions/reducer";
import {
  NightRestrictionFormErrors,
  NightRestrictionState,
} from "types/DiscountsAndActions";

import Select from "react-select";

interface RestrictionsSwitchesProps {
  values: NightRestrictionState;
  formErrors: NightRestrictionFormErrors;
  validateFormChange: (values: NightRestrictionState) => void;
  conceptsOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}
const RestrictionsSwitches = ({
  values,
  formErrors,
  validateFormChange,
  conceptsOptions,
}: RestrictionsSwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Yinelemeli"
        tooltipId="nightRestrictionsRecurrence"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.yineleme === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  yineleme: values.yineleme === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Toplam Geceden Hesapla">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.toplam_geceden_hesaplama === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  toplam_geceden_hesaplama:
                    values.toplam_geceden_hesaplama === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tüm Odalara Uygula"
        tooltipId="nightRestrictionsApplyToAllRooms"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.grup_id === 0}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  grup_id: values.grup_id === 0 ? 1 : 0,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>

      <CustomFormFieldContainer
        label="Tüm Pansiyonlara Uygula"
        tooltipId="nightRestrictionsApplyToAllConcepts"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_otel_pansiyonlar === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_otel_pansiyonlar:
                    values.tum_otel_pansiyonlar === "1" ? "0" : "1",
                })
              );
              dispatch(handleChangeInput({ otel_pansiyonlar: [] }));
              validateFormChange({
                ...values,
                tum_otel_pansiyonlar:
                  values.tum_otel_pansiyonlar === "1" ? "0" : "1",
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.tum_otel_pansiyonlar === "0" && (
        <CustomFormFieldContainer
          label="Seçili Pansiyonlara Uygula"
          error={formErrors.secili_pansiyonlar}
        >
          <Select
            isMulti={true}
            value={
              values.otel_pansiyonlar.map((pansiyon_id) => {
                return conceptsOptions.find(
                  (option) => option.value === pansiyon_id
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedRooms = selected.map((item) => item.value);
              dispatch(handleChangeInput({ otel_pansiyonlar: selectedRooms }));
              validateFormChange({
                ...values,
                otel_pansiyonlar: selectedRooms,
              });
            }}
            options={conceptsOptions}
            placeholder="Pansiyon Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default RestrictionsSwitches;
