import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import RoomSalesAndPaymentMethodsForm from "./RoomSalesAndPaymentMethodsForm";
import { CustomLoadingPage } from "Components/Custom/UI";

interface RoomSalesAndPaymentMethodsPageProps {
  data: any;
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const RoomSalesAndPaymentMethodsPage = ({
  ...props
}: RoomSalesAndPaymentMethodsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={props.isLoading} />
      <RoomSalesAndPaymentMethodsForm {...props} />
    </>
  );
};

export default withPageNeedsHotel(RoomSalesAndPaymentMethodsPage);
