import { CONTRACT_TYPE } from "enums";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  addHotelContractRule,
  getHotelContractRules,
} from "slices/financeAndAccounting/contractRules/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import useValidateForm from "./useValidateForm";
import { ContractRulesInitialState } from "slices/financeAndAccounting/contractRules/reducer";

const useHandleForm = () => {
  const [formValues, setFormValues] = useState({
    otel_id: 0,
    adi: "",
    kontrat_tipi: CONTRACT_TYPE.Tekrarli,
    baslangic_tarihi: moment().format("YYYY-MM-DD"),
    limit1: "",
    limit2: "",
    veri: "",
  });

  const contratTypeOptions = [
    {
      value: CONTRACT_TYPE.Tekrarli,
      label: "Tekrarlı",
    },
    {
      value: CONTRACT_TYPE.Aylik,
      label: "Aylık",
    },
    {
      value: CONTRACT_TYPE["Oda Basi"],
      label: "Oda Başı Prim",
    },
    {
      value: CONTRACT_TYPE["Sezon Sonu"],
      label: "Sezon Sonu Kotası",
    },
    {
      value: CONTRACT_TYPE["Tek Seferlik"],
      label: "Tek Seferlik",
    },
  ];

  const handleFormChange = (key: string, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const { addHotelContractRuleFormErrors } = useSelector(
    (state: { ContractRules: ContractRulesInitialState }) => state.ContractRules
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetHotelContractRules = () => {
    dispatch(getHotelContractRules(otel_id));
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm(formValues);

  const handleAddContractRule = () => {
    const payload = {
      ...formValues,
      otel_id,
    };

    const { isValid } = validateForm(formValues);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addHotelContractRule(payload, () => {
          handleGetHotelContractRules();
        })
      );

      setFormValues({
        otel_id: 0,
        adi: "",
        kontrat_tipi: CONTRACT_TYPE.Tekrarli,
        baslangic_tarihi: moment().format("YYYY-MM-DD"),
        limit1: "",
        limit2: "",
        veri: "",
      });
    }
  };

  return {
    formValues,
    handleFormChange,
    onAddContractRule: handleAddContractRule,
    contratTypeOptions,
    formErrors: addHotelContractRuleFormErrors,
    validateFormChange,
  };
};
export default useHandleForm;
