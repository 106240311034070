import { GiftsAndPromotionsPage } from "Components/Custom/DiscountsAndActionsPages";
import useGiftsAndPromotions from "Components/Hooks/discountsAndActions/useGiftsAndPromotions";
import PageContainer from "pages/PageContainer";

const GiftsAndPromotions = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useGiftsAndPromotions();

  return (
    <PageContainer title="Hediyeler ve Promosyonlar">
      <GiftsAndPromotionsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default GiftsAndPromotions;
