import { createSlice } from "@reduxjs/toolkit";
import {
  SaveRoomPersonPriceRate,
  SaveRoomPersonPriceRateState,
} from "types/PricesQuotas";
export interface PersonPriceRatesInitialState {
  roomPersonPriceRate: SaveRoomPersonPriceRate;
  roomPersonPriceRateState: SaveRoomPersonPriceRateState;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  saveRatesLoading: boolean;
  saveRatesError: string;
  checkedAccommodationTypes: string[];
}
export const personPriceRatesInitialState: PersonPriceRatesInitialState = {
  roomPersonPriceRate: {
    detayli_oda_kontenjan: false,
    uygulanabilecek_max_yetiskin: 0,
    uygulanabilecek_max_konuk: 0,
    kisi_basi_fiyat: 0,
    bir_yetiskin_yaninda_cocuk_varmi: false,
    ozel_katsayi: false,
    konaklama_cesitleri: {},
    misafir_oranlari: [],
    cocuk_ozel_yas: [],
  },
  roomPersonPriceRateState: {
    detayli_oda_kontenjan: false,
    uygulanabilecek_max_yetiskin: 0,
    uygulanabilecek_max_konuk: 0,
    kisi_basi_fiyat: 0,
    bir_yetiskin_yaninda_cocuk_varmi: false,
    ozel_katsayi: false,
    konaklama_cesitleri: {},
    misafir_oranlari: [],
    cocuk_ozel_yas: 0,
    cocuk_ozel_yas_ozel_katsayi: 0,
  },
  selectedRoom: 0,
  isLoading: false,
  error: "",
  saveRatesLoading: false,
  saveRatesError: "",
  checkedAccommodationTypes: [],
};
const roomPersonPriceRateSlice = createSlice({
  name: "roomPersonPriceRate",
  initialState: personPriceRatesInitialState,
  reducers: {
    handleSaveRoomPersonPriceRate(state, action) {
      state.roomPersonPriceRate = action.payload;
    },
    handleSaveRoomPersonPriceRateState(state, action) {
      const payload = action.payload;

      const personPerRates = payload.misafir_oranlari ?? [];
      const accommodationTypes = payload.konaklama_cesitleri ?? {};

      state.roomPersonPriceRateState = {
        detayli_oda_kontenjan: payload.detayli_oda_kontenjan,
        uygulanabilecek_max_yetiskin: payload.uygulanabilecek_max_yetiskin,
        uygulanabilecek_max_konuk: payload.uygulanabilecek_max_konuk,
        kisi_basi_fiyat: payload.kisi_basi_fiyat,
        bir_yetiskin_yaninda_cocuk_varmi:
          payload.bir_yetiskin_yaninda_cocuk_varmi,
        ozel_katsayi: payload.ozel_katsayi,

        konaklama_cesitleri: accommodationTypes,
        misafir_oranlari: personPerRates.map((item: any) => {
          return {
            kisi_tipi: item.oda_konuk_tipi,
            kisi_sayisi: item.oda_konuk_sayisi,
            tek_yani_cocuk: item.tek_yani_cocuk,
            kisi_orani: item.oda_konuk_orani,
          };
        }),
      };

      state.checkedAccommodationTypes = accommodationTypes.length
        ? accommodationTypes
        : Object.keys(accommodationTypes).map((item) => item);
    },
    handleSaveRoomPersonPriceRateStateInSelect(state, action) {
      const payload = action.payload;

      const personPerRates = payload.misafir_oranlari ?? [];
      const accommodationTypes = payload.konaklama_cesitleri ?? {};

      state.roomPersonPriceRateState = {
        detayli_oda_kontenjan:
          state.roomPersonPriceRateState.detayli_oda_kontenjan,
        uygulanabilecek_max_yetiskin: payload.uygulanabilecek_max_yetiskin,
        uygulanabilecek_max_konuk: payload.uygulanabilecek_max_konuk,
        kisi_basi_fiyat: payload.kisi_basi_fiyat,
        bir_yetiskin_yaninda_cocuk_varmi:
          payload.bir_yetiskin_yaninda_cocuk_varmi,
        ozel_katsayi: state.roomPersonPriceRateState.ozel_katsayi,
        konaklama_cesitleri: accommodationTypes,
        misafir_oranlari: personPerRates.map((item: any) => {
          return {
            kisi_tipi: item.oda_konuk_tipi,
            kisi_sayisi: item.oda_konuk_sayisi,
            tek_yani_cocuk: item.tek_yani_cocuk,
            kisi_orani: item.oda_konuk_orani,
          };
        }),
      };

      state.checkedAccommodationTypes = accommodationTypes.length
        ? accommodationTypes
        : Object.keys(accommodationTypes).map((item) => item);
    },
    handleResetState(state) {
      state.roomPersonPriceRateState = {
        detayli_oda_kontenjan: false,
        uygulanabilecek_max_yetiskin: 0,
        uygulanabilecek_max_konuk: 0,
        kisi_basi_fiyat: 0,
        bir_yetiskin_yaninda_cocuk_varmi: false,
        ozel_katsayi: false,
        konaklama_cesitleri: {},
        misafir_oranlari: [],
        cocuk_ozel_yas: 0,
        cocuk_ozel_yas_ozel_katsayi: 0,
      };
    },
    handleSaveRoomPersonPriceRateLoading(state, action) {
      state.isLoading = action.payload;
    },
    handleSaveRoomPersonPriceRateError(state, action) {
      state.error = action.payload;
    },
    handleChangeSelectAccomodationType(state, action) {
      const payload = action.payload;

      if (state.checkedAccommodationTypes.includes(payload)) {
        state.checkedAccommodationTypes =
          state.checkedAccommodationTypes.filter((item) => item !== payload);
      } else {
        state.checkedAccommodationTypes.push(payload);
      }
    },
    handleAddAllAccommodationTypes(state, action) {
      state.checkedAccommodationTypes = action.payload;
    },
    handleChangeInput(state, action) {
      state.roomPersonPriceRateState = {
        ...state.roomPersonPriceRateState,
        ...action.payload,
      };
    },
    handleChangeGuestNumbersInput(state, action) {
      state.roomPersonPriceRateState = {
        ...state.roomPersonPriceRateState,
        ...action.payload,
      };
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSaveRateLoading(state, action) {
      state.saveRatesLoading = action.payload;
    },
    setSaveRateError(state, action) {
      state.saveRatesError = action.payload;
    },
  },
});

export const {
  handleSaveRoomPersonPriceRate,
  handleSaveRoomPersonPriceRateState,
  handleSaveRoomPersonPriceRateStateInSelect,
  handleSaveRoomPersonPriceRateLoading,
  handleSaveRoomPersonPriceRateError,
  handleChangeSelectAccomodationType,
  handleAddAllAccommodationTypes,
  handleResetState,
  handleChangeInput,
  handleChangeGuestNumbersInput,
  handleSelectRoom,
  setIsLoading,
  setError,
  setSaveRateLoading,
  setSaveRateError,
} = roomPersonPriceRateSlice.actions;

export default roomPersonPriceRateSlice.reducer;
