import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { FilterLogsPayload } from "types/userOperations";

interface Option {
  value: string;
  label: string;
}
interface PageTitleSelectorProps {
  options: Option[];
  values: FilterLogsPayload;
  handleFormChange: (key: string, value: string) => void;
}

const PageTitleSelector = ({     
  options, 
  values,
  handleFormChange, 
}: PageTitleSelectorProps) => {

  return (
    <CustomFormFieldContainer
      label="Sayfa Seç :">
      <Select
        options={options}
        value={options.find(
          (option) => option.value === String(values.page)
        )}
        onChange={(selectedOption: Option) => {
          handleFormChange('sayfa', String(selectedOption.value));
        }}
        placeholder="Sayfa Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default PageTitleSelector;
