import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
  Months,
} from "types/PricesQuotas";

import { CustomFormContainer } from "Components/Custom/UI";

import KismiPaymentType from "./KismiPaymentType";
import KapidaPaymentType from "./KapidaPaymentType";
import KaporaliPaymentType from "./KaporaliPaymentType";

interface FacilityPaymentTypesProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  kismiPaymentDaysOptions: Option[];
  monthsOptions: MonthsOption[];
}

interface Option {
  value: string;
  label: string;
}

interface MonthsOption {
  label: string;
  value: Months;
}

const FacilityPaymentTypes = ({
  values,
  formErrors,
  validateFormChange,
  kismiPaymentDaysOptions,
  monthsOptions,
}: FacilityPaymentTypesProps) => {
  const handleAddPayment = () => {};

  const checkPaymentTypes = (
    kismi: boolean,
    kapida: boolean,
    kaporali: boolean
  ) => {
    const notValid = [kismi, kapida, kaporali].every((val) => val);

    notValid && alert("En fazla iki ödeme tipi seçilmelidir.");

    return !notValid;
  };

  return (
    <CustomFormContainer title="Tesisteki Ödeme Yöntemleri">
      <KismiPaymentType
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        kismiPaymentDaysOptions={kismiPaymentDaysOptions}
        monthsOptions={monthsOptions}
        onAddPayment={handleAddPayment}
        checkPaymentTypes={checkPaymentTypes}
      />
      <hr />
      <KapidaPaymentType
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        monthsOptions={monthsOptions}
        checkPaymentTypes={checkPaymentTypes}
      />
      <hr />
      <KaporaliPaymentType
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        monthsOptions={monthsOptions}
        checkPaymentTypes={checkPaymentTypes}
      />
    </CustomFormContainer>
  );
};

export default FacilityPaymentTypes;
