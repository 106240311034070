import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
import BannerTwoTitle from "./BannerTwoTitle";
import BannerTwoImageOne from "./BannerTwoImageOne";
import BannerTwoImageTwo from "./BannerTwoImageTwo";
import BannerTwoImageThree from "./BannerTwoImageThree";
import BannerTwoExplanation from "./BannerTwoExplanation";

interface BannerTwoFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const BannerTwoFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: BannerTwoFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer
        title="Banner-2 Bilgileri"
        lg={12}
        xl={12}
      >
        <BannerTwoTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <BannerTwoImageOne
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerTwoImageTwo
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerTwoImageThree
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerTwoExplanation
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default BannerTwoFields;
