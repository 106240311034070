import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListPlacesToVisitInitialState } from "slices/definitions/listPlacesToVisit/reducer";
import { getPlacesToVisit } from "slices/definitions/listPlacesToVisit/thunk";

const useListPlacesToVisit = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { placesToVisitList,placeToVisit, isLoading, error } = useSelector(
    (state: { ListPlacesToVisit: ListPlacesToVisitInitialState }) =>
      state.ListPlacesToVisit
  );

  useEffect(() => {
    dispatch(getPlacesToVisit());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    placesToVisitList,
    placeToVisit,
    isLoading,
    error,
  };
};

export default useListPlacesToVisit;
