import { useCallback } from 'react';
import {
  NotificationState,
  ObjectNotification,
  StringNotification,
  HTMLTableNotification,
  HTMLLinkNotification,
} from 'types/userOperations';

/* 

  * This hook provides functions to format notifications based on their type

*/

const useFormatNotificationData = () => {
  const handleTypeObject = useCallback(
    (notification: NotificationState): ObjectNotification => {
      const data = JSON.parse(notification.bildirim);
      return {
        ...notification,
        data,
        contentType: 'object',
      };
    },
    []
  );

  const handleTypeString = useCallback(
    (notification: NotificationState): StringNotification => {
      return {
        ...notification,
        message: notification.bildirim,
        contentType: 'string',
      };
    },
    []
  );

  const handleTypeHTMLTable = useCallback(
    (notification: NotificationState): HTMLTableNotification => {
      return {
        ...notification,
        htmlContent: notification.bildirim,
        contentType: 'htmlTable',
      };
    },
    []
  );

  const handleTypeHTMLLink = useCallback(
    (notification: NotificationState): HTMLLinkNotification => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(notification.bildirim, 'text/html');
      const aTag = doc.querySelector('a');
      const link = aTag?.getAttribute('href') || '';
      const linkText = aTag?.textContent || '';
      return {
        ...notification,
        link,
        linkText,
        contentType: 'htmlLink',
      };
    },
    []
  );

  return {
    onTypeObject: handleTypeObject,
    onTypeString: handleTypeString,
    onTypeHTMLTable: handleTypeHTMLTable,
    onTypeHTMLLink: handleTypeHTMLLink,
  };
};

export default useFormatNotificationData;
