import { createSlice } from "@reduxjs/toolkit";
import {
  EarlyReservationDiscount,
  EarlyReservationDiscountFormErrors,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";

import moment from "moment";

export interface EarlyReservationDiscountsInitialState {
  earlyReservationDiscounts: EarlyReservationDiscount[];
  selectedRoom: number;
  earlyReservationDiscount: EarlyReservationDiscountState;
  earlyReservationDiscountFormErrors: EarlyReservationDiscountFormErrors;
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
  updateDiscountLoading: boolean;
  updateDiscountError: string;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string;
}

export const initialState: EarlyReservationDiscountsInitialState = {
  earlyReservationDiscounts: [],
  earlyReservationDiscount: {
    id: 0,
    otel_id: 0,
    otel_oda_id: 0,
    grup_id: 0,
    satis_baslangic_tarihi: moment().format("YYYY-MM-DD"),
    satis_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
    konaklama_baslangic_tarihi: moment().format("YYYY-MM-DD"),
    konaklama_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
    tum_gunler: "1",
    gunler: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    oran: "0",
    gercek_indirim_orani: "0",
    gostermelik_indirim_orani: 0,
    minimum_gece: 1,
    kumulatif_durum: "1",
    otele_odeme_gunu: moment().format("YYYY-MM-DD"),
    otele_odeme_orani: "0",
    tum_otel_pansiyonlar: "1",
    otel_pansiyonlar: [],
    tum_odeme_tipleri: "1",
    odeme_tipleri: [],
  },
  earlyReservationDiscountFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
  updateDiscountLoading: false,
  updateDiscountError: "",
  deleteDiscountLoading: false,
  deleteDiscountError: "",
};

const earlyReservationDiscountsSlice = createSlice({
  name: "earlyReservationDiscounts",
  initialState,
  reducers: {
    handleSaveEarlyReservationDiscounts: (state, action) => {
      state.earlyReservationDiscounts = action.payload;
    },
    handleDeleteFromEarlyReservationDiscountsWithId: (state, action) => {
      state.earlyReservationDiscounts = state.earlyReservationDiscounts.filter(
        (discount) => discount.id !== action.payload
      );
    },
    handleSaveEarlyReservationDiscountForEdit: (state, action) => {
      state.earlyReservationDiscount = action.payload;
    },
    handleChangeInput: (state, action) => {
      state.earlyReservationDiscount = {
        ...state.earlyReservationDiscount,
        ...action.payload,
      };
    },
    handleAddEarlyReservationDiscountFormErrors: (state, action) => {
      state.earlyReservationDiscountFormErrors = action.payload;
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetEarlyReservationDiscounts: (state) => {
      state.earlyReservationDiscounts = [];
    },
    resetEarlyReservationDiscount: (state) => {
      state.earlyReservationDiscount = {
        id: 0,
        otel_id: 0,
        otel_oda_id: 0,
        grup_id: 0,
        satis_baslangic_tarihi: moment().format("YYYY-MM-DD"),
        satis_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
        konaklama_baslangic_tarihi: moment().format("YYYY-MM-DD"),
        konaklama_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
        tum_gunler: "1",
        gunler: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        oran: "0",
        gercek_indirim_orani: "0",
        gostermelik_indirim_orani: 0,
        minimum_gece: 1,
        kumulatif_durum: "1",
        otele_odeme_gunu: moment().format("YYYY-MM-DD"),
        otele_odeme_orani: "0",
        tum_otel_pansiyonlar: "1",
        otel_pansiyonlar: [],
        tum_odeme_tipleri: "1",
        odeme_tipleri: [],
      };
    },

    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateDiscountLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateDiscountError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteDiscountLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteDiscountError = action.payload;
    },
  },
});

export const {
  handleSaveEarlyReservationDiscounts,
  handleDeleteFromEarlyReservationDiscountsWithId,
  handleSaveEarlyReservationDiscountForEdit,
  handleChangeInput,
  handleAddEarlyReservationDiscountFormErrors,
  handleSelectRoom,
  setIsLoading,
  setError,
  resetEarlyReservationDiscounts,
  resetEarlyReservationDiscount,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} = earlyReservationDiscountsSlice.actions;

export default earlyReservationDiscountsSlice.reducer;
