import { Form, FormFeedback, Input, Label } from "reactstrap";

import {
  CustomBox,
  CustomFormButton,
  CustomFormStatusMessages,
} from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";

import { Router } from "types";

const ResetPasswordForm = (props: Router) => {
  const {
    validation,
    oldPasswordShow,
    setOldPasswordShow,
    passwordShow,
    setPasswordShow,
    passwordConfirmationShow,
    setPasswordConfirmationShow,
    isLoading,
    error,
    success,
  } = useHandleForm(props);

  return (
    <CustomBox title="Şifreyi Değiştir" md={12} lg={12}>
      <div className="w-100 p-2">
        <CustomFormStatusMessages error={error} success={success} />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-4">
            <Label className="form-label" htmlFor="old-password-input">
              Eski Şifre
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="old-password-input"
                name="oldPassword"
                value={validation.values.oldPassword || ""}
                type={passwordShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Eski Şifre"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.oldPassword &&
                  validation.errors.oldPassword
                    ? true
                    : false
                }
              />
              {validation.touched.oldPassword &&
              validation.errors.oldPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.oldPassword}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="old-password-addon"
                onClick={() => setOldPasswordShow(!oldPasswordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div className="mb-4">
            <Label className="form-label" htmlFor="password-input">
              Yeni Şifre
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="password-input"
                name="password"
                value={validation.values.password || ""}
                type={passwordShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Yeni Şifre"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div className="mb-4">
            <Label className="form-label" htmlFor="password-confirmation-input">
              Yeni Şifre Tekrarı
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="password-confirmation-input"
                name="password_confirmation"
                value={validation.values.password_confirmation || ""}
                type={passwordConfirmationShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Yeni Şifre Tekrarı"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password_confirmation &&
                  validation.errors.password_confirmation
                    ? true
                    : false
                }
              />
              {validation.touched.password_confirmation &&
              validation.errors.password_confirmation ? (
                <FormFeedback type="invalid">
                  {validation.errors.password_confirmation}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-confirmation-addon"
                onClick={() =>
                  setPasswordConfirmationShow(!passwordConfirmationShow)
                }
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>

          <div className="text-center mt-4">
            <CustomFormButton
              type="submit"
              variant="success"
              isLoading={isLoading}
            >
              Şifreyi Değiştir
            </CustomFormButton>
          </div>
        </Form>
      </div>
    </CustomBox>
  );
};

export default ResetPasswordForm;
