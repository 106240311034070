import { AllGiftsListPage } from "Components/Custom/DiscountsAndActionsPages";
import useAllGiftsList from "Components/Hooks/discountsAndActions/useAllGiftsList";
import PageContainer from "pages/PageContainer";

const AllGiftsList = () => {
  const { error, isLoading } = useAllGiftsList();

  return (
    <PageContainer title="Tüm Otellere Ait Hediyeler">
      <AllGiftsListPage loading={isLoading} error={error} />
    </PageContainer>
  );
};

export default AllGiftsList;
