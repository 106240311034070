import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";

import { FacilitySpecialDaysInitialState } from "slices/facility/facilitySpecialDays/reducer";

import {
  AddFacilitySpecialDayRequestPayload,
  UpdateFacilitySpecialDayRequestPayload,
} from "types/facility";
import { useEffect, useState } from "react";
import { HotelInitialState } from "slices/hotel/reducer";
import { FilePondFile } from "filepond";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  getFacilitySpecialDays,
  addFacilitySpecialDay,
  updateFacilitySpecialDay,
} from "slices/facility/facilitySpecialDays/thunk";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    specialDay,
    specialDaysCategories,
    specialDaysFormErrors,
    addSpecialDayLoading,
    updateSpecialDayLoading,
  } = useSelector(
    (state: { FacilitySpecialDays: FacilitySpecialDaysInitialState }) =>
      state.FacilitySpecialDays
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const [specialDaysCategoriesOptions, setSpecialDaysCategoriesOptions] =
    useState<Option[]>([]);

  useEffect(() => {
    if (specialDaysCategories) {
      const options = Object.entries(specialDaysCategories).map(
        ([key, value]) => ({
          value: value,
          label: key,
        })
      );
      setSpecialDaysCategoriesOptions(options);
    }
  }, [specialDaysCategories]);

  const handleAddNewSpecialDay = () => {
    const payload: AddFacilitySpecialDayRequestPayload = new FormData();

    payload.append("otel_id", otel_id.toString());
    payload.append("kategori_id", specialDay.kategori_id.toString());
    payload.append("ozel_gun_baslik", specialDay.ozel_gun_baslik);
    payload.append("ozel_gun_sanatci_adi", specialDay.ozel_gun_sanatci_adi);
    payload.append("ozel_gun_baslangic", specialDay.ozel_gun_baslangic);
    payload.append("ozel_gun_bitis", specialDay.ozel_gun_bitis);
    payload.append("ozel_gun_aciklama", specialDay.ozel_gun_aciklama);

    const file = specialDay.resim as FilePondFile;
    payload.append("file", file.file, file.file.name);

    dispatch(
      addFacilitySpecialDay(payload, () => {
        dispatch(
          getFacilitySpecialDays({
            otel_id: otel_id,
            page: 1,
            per_page: 10,
            search: "",
          })
        );
      })
    );
  };

  const handleUpdateSpecialDay = () => {
    const payload: UpdateFacilitySpecialDayRequestPayload = new FormData();

    payload.append("id", specialDay.id.toString());
    payload.append("otel_id", specialDay.otel_id.toString());
    payload.append("kategori_id", specialDay.kategori_id.toString());
    payload.append("ozel_gun_baslik", specialDay.ozel_gun_baslik);
    payload.append("ozel_gun_sanatci_adi", specialDay.ozel_gun_sanatci_adi);
    payload.append("ozel_gun_baslangic", specialDay.ozel_gun_baslangic);
    payload.append("ozel_gun_bitis", specialDay.ozel_gun_bitis);
    payload.append("ozel_gun_aciklama", specialDay.ozel_gun_aciklama);

    const file = specialDay.resim as FilePondFile;
    file && payload.append("file", file.file, file.file.name);

    dispatch(
      updateFacilitySpecialDay(payload, () => {
        dispatch(
          getFacilitySpecialDays({
            otel_id: otel_id,
            page: 1,
            per_page: 10,
            search: "",
          })
        );
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    specialDay,
    specialDaysCategoriesOptions,
    formErrors: specialDaysFormErrors,
    addSpecialDayLoading,
    updateSpecialDayLoading,
    onAddNewSpecialDay: handleAddNewSpecialDay,
    onUpdateSpecialDay: handleUpdateSpecialDay,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
