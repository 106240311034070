import { AddLocationPage } from "Components/Custom/DefinitionsPages";
import { CustomLoadingPage } from "Components/Custom/UI";
import useAddLocation from "Components/Hooks/definitions/useAddLocation";
import PageContainer from "pages/PageContainer";

const AddLocation = () => {
  const { isLoading, addLocationLoading } = useAddLocation();

  return (
    <PageContainer title="Lokasyon Ekle">
      <CustomLoadingPage isLoading={isLoading} />
      <AddLocationPage loading={addLocationLoading} />
    </PageContainer>
  );
};

export default AddLocation;
