import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Dispatch } from "redux";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";

import { userForgetPassword } from "slices/thunks";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectLayoutState = (state: any) => state.ForgetPassword;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    forgetError: state.forgetError,
    forgetSuccessMsg: state.forgetSuccessMsg,
    isLoading: state.isLoading,
  }));

  // Inside your component
  const { forgetError, forgetSuccessMsg } = useSelector(selectLayoutProperties);

  const [isLoading, setIsLoading] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("E-posta alanı boş bırakılamaz")
        .email("Geçersiz e-posta adresi"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values));
      setIsLoading(true);
    },
  });

  useEffect(() => {
    if (forgetError) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [forgetError]);

  return {
    validation,
    forgetError,
    forgetSuccessMsg,
    isLoading,
  };
};

export default useHandleForm;
