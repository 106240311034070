import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetDiscountCode } from "slices/discountsAndActions/discountCodes/reducer";

import {
  getDiscountCodes,
  updateDiscountCode,
} from "slices/discountsAndActions/discountCodes/thunk";

import {
  DiscountCodeRequestPayload,
  DiscountCodeState,
} from "types/DiscountsAndActions";

interface UpdateCodeButtonProps {
  values: DiscountCodeState;
  requestPayload: DiscountCodeRequestPayload;
  validateForm: (values: DiscountCodeState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateCodeButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateCodeButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateCode = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateDiscountCode(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getDiscountCodes());
        })
      );
    }
  };
  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="secondary"
        onClick={() => dispatch(resetDiscountCode())}
      >
        İptal
      </CustomButton>
      <CustomButton
        variant="primary"
        onClick={handleUpdateCode}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateCodeButton;
