import { useDispatch, useSelector } from "react-redux";
import { Dispatch, SetStateAction, useState } from "react";
import { CategorizeFacilityInitialState } from "slices/facilityCategorization/categorizeFaclity/reducer";
import { CategorizeFacilityPayload } from "types/facilityCategorization";
import {
  categorizeFacilities,
  getCitySelectionResponse,
} from "slices/facilityCategorization/categorizeFaclity/thunk";

const useHandleForm = (setSelectedCityId: Dispatch<SetStateAction<number>>) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices, categorizeFacility } = useSelector(
    (state: { CategorizeFacility: CategorizeFacilityInitialState }) =>
      state.CategorizeFacility
  );

  const [formState, setFormState] = useState<CategorizeFacilityPayload>({
    otel_idleri: [],
    sinif: "",
  });

  const handleSelectCity = (id: number) => {
    setSelectedCityId(id);
    dispatch(getCitySelectionResponse(id));
  };

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCategorizeFacilities = () => {
    const params = {
      otel_idleri: formState.otel_idleri,
      sinif: formState.sinif,
    };

    dispatch(
      categorizeFacilities(params, () => {
        setFormState({
          otel_idleri: [],
          sinif: "",
        });
        setSelectedCityId(0);
      })
    );
  };

  const handleResetForm = () => {
    setFormState({
      otel_idleri: [],
      sinif: "",
    });
    setSelectedCityId(0);
  };

  const cityOptions = formChoices.sehirler?.map((item) => ({
    value: item.otel_lokasyon_id,
    label: item.lokasyon,
  }));

  const facilityOptions = categorizeFacility.tesisler.map((item) => ({
    value: item.otel_id.toString(),
    label: item.otel_adi,
  }));

  const categoryOptions = categorizeFacility.otel_siniflari.map((item) => ({
    value: item.value,
    label: item.text,
  }));

  return {
    values: formState,
    cityOptions,
    facilityOptions,
    categoryOptions,
    handleFormChange,
    handleSelectCity,
    handleSave: handleCategorizeFacilities,
    handleClearForm: handleResetForm,
  };
};

export default useHandleForm;
