import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddFacilityPullImageFormUrlErrors } from "slices/facility/facilityImages/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  FacilityPullImageFormUrlErrors,
  FacilityPullImageFormUrlState,
} from "types/facility";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FacilityPullImageFormUrlState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: FacilityPullImageFormUrlErrors = {};

    if (!values.url) {
      errors.url = "Link boş bırakılamaz.";
    }

    if (values.url && !values.url.includes("http")) {
      errors.url = "Link geçerli değil.";
    }

    dispatch(handleAddFacilityPullImageFormUrlErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FacilityPullImageFormUrlState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
