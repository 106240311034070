import PersonnelRequestPage from "Components/Custom/PersonnelRequestPages";
import usePersonnelRequest from "Components/Hooks/personnelRequest/usePersonnelRequest";
import PageContainer from "pages/PageContainer";

const PersonnelRequest = () => {
    const { isLoading } = usePersonnelRequest();

    return (
        <PageContainer title="Personel Talepleri">
            <PersonnelRequestPage isLoading={isLoading}/>
        </PageContainer>
    );
};

export default PersonnelRequest;
