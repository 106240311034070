import { CustomFormContainer } from "Components/Custom/UI";
import ChildCalculateSwitch from "./ChildCalculateSwitch";
import ChildSpecialAgeSelect from "./ChildSpecialAgeSelect";
import RoomSelector from "./RoomSelector";
import useHandleForm from "./useHandleForm";
import ChildAgeRanges from "./ChildAgeRanges";
import SaveChildInformationButton from "./SaveChildInformationButton";

interface ChildInformationFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const ChildInformationForm = (props: ChildInformationFormProps) => {
  const {
    childInformationState,
    handleSave,
    getChildAgeInformation,
    childSingleGeneralInformation,
    childMultipleGeneralInformation,
    childSpecialAgeOptions,
    isClicked,
    setIsClicked,
  } = useHandleForm(props.hotelAndSelectedRoom);

  const childrenSingle = childInformationState.cocuk_bilgileri.filter(
    (child) => child.tip === "tek"
  );

  const childrenSingleData = childrenSingle.map((child) => ({
    child: child.cocuk,
    data: child,
  }));

  const childrenMultiple = childInformationState.cocuk_bilgileri.filter(
    (child) => child.tip === "cift"
  );

  const childrenMultipleData = childrenMultiple.map((child) => ({
    child: child.cocuk,
    data: child,
  }));

  return (
    <div className="d-flex flex-column gap-4">
      <RoomSelector {...props} />
      <CustomFormContainer title="Çocuk Bilgileri">
        <ChildCalculateSwitch childInformationState={childInformationState} />
        <ChildSpecialAgeSelect
          childInformationState={childInformationState}
          childSpecialAgeOptions={childSpecialAgeOptions}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
          hotelId={props.hotelAndSelectedRoom.otel_id}
          roomId={props.hotelAndSelectedRoom.oda_id}
        />
      </CustomFormContainer>
      <CustomFormContainer title="Çocuk Yaş Aralıkları">
        {isClicked === "0" &&
          ["1", "2", "3", "4"].map((order) => {
            const child = childSingleGeneralInformation.find(
              (child: any) => child.cocuk === order
            );

            return (
              <ChildAgeRanges
                key={order}
                childInformationState={childInformationState}
                childSingleGeneralInformation={childSingleGeneralInformation}
                childMultipleGeneralInformation={
                  childMultipleGeneralInformation
                }
                id={child?.id || 0}
                otel_id={child?.otel_id || 0}
                oda_id={child?.oda_id || 0}
                otel_oda_id={child?.otel_oda_id || 0}
                tip={child?.tip || ""}
                ozel_yas_id={child?.ozel_yas_id || 0}
                cocuk={child?.cocuk.toString() || ""}
                cocuk_kabul={child?.cocuk_kabul.toString() || ""}
                cocuk_kabul2={child?.cocuk_kabul2.toString() || ""}
                ucretsiz_yas_baslangic={child?.ucretsiz_yas_baslangic || ""}
                ucretsiz_yas_bitis={child?.ucretsiz_yas_bitis || ""}
                ucretli_yas_baslangic={child?.ucretli_yas_baslangic || ""}
                ucretli_yas_bitis={child?.ucretli_yas_bitis || ""}
                getChildAgeInformation={getChildAgeInformation}
                isClicked={isClicked}
                setIsClicked={setIsClicked}
              />
            );
          })}
        {isClicked === "1" &&
          ["1", "2", "3", "4"].map((order) => {
            const child = childMultipleGeneralInformation.find(
              (child: any) => child.cocuk === order
            );

            return (
              <ChildAgeRanges
                key={order}
                childInformationState={childInformationState}
                childSingleGeneralInformation={childSingleGeneralInformation}
                childMultipleGeneralInformation={
                  childMultipleGeneralInformation
                }
                id={child?.id || 0}
                otel_id={child?.otel_id || 0}
                oda_id={child?.oda_id || 0}
                otel_oda_id={child?.otel_oda_id || 0}
                tip={child?.tip || ""}
                ozel_yas_id={child?.ozel_yas_id || 0}
                cocuk={child?.cocuk.toString() || ""}
                cocuk_kabul={child?.cocuk_kabul.toString() || ""}
                cocuk_kabul2={child?.cocuk_kabul2.toString() || ""}
                ucretsiz_yas_baslangic={child?.ucretsiz_yas_baslangic || ""}
                ucretsiz_yas_bitis={child?.ucretsiz_yas_bitis || ""}
                ucretli_yas_baslangic={child?.ucretli_yas_baslangic || ""}
                ucretli_yas_bitis={child?.ucretli_yas_bitis || ""}
                getChildAgeInformation={getChildAgeInformation}
                isClicked={isClicked}
                setIsClicked={setIsClicked}
              />
            );
          })}
      </CustomFormContainer>
      <SaveChildInformationButton onSave={handleSave} />
    </div>
  );
};

export default ChildInformationForm;
