import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";
import HotelContractRulesTable from "./HotelContractRulesTable";
import HotelContractProceduresTable from "./HotelContractProceduresTable";
import ContractProceduresTable from "./ContractProceduresTable";
import AddHotelContractRuleForm from "./AddHotelContractRuleForm";

interface ContractRulesPageProps {
  isLoading: boolean;
  hotelContractRuleLoading: boolean;
  addContractRuleLoading: boolean;
}

const ContractRulesPage = ({
  isLoading,
  hotelContractRuleLoading,
  addContractRuleLoading,
}: ContractRulesPageProps) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );
  const { otel_id } = hotel;

  return (
    <div className="d-flex flex-column gap-4">
      {otel_id ? (
        <>
          <HotelContractRulesTable isLoading={hotelContractRuleLoading} />
          <AddHotelContractRuleForm isLoading={addContractRuleLoading} />
          <HotelContractProceduresTable isLoading={isLoading} />
        </>
      ) : (
        <ContractProceduresTable isLoading={isLoading} />
      )}
    </div>
  );
};

export default ContractRulesPage;
