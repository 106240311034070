import { CustomFormContainer } from "Components/Custom/UI";
import TeamCard from "./TeamCard";
import useHandleCards from "./useHandleCards";

const ExtensionNumbersCards = () => {
  const { values } = useHandleCards();

  return (
    <CustomFormContainer>
      <div className="overflow-auto">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "0.5rem",
          }}
        >
          {Object.entries(values).map(([key, teamData]) => (
            <TeamCard key={key} teamData={teamData} />
          ))}
        </div>
      </div>
    </CustomFormContainer>
  );
};
export default ExtensionNumbersCards;
