import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Hotel, IncomingPrice } from "types/PricesQuotas";

export interface BasicPriceEntryInitialState {
  hotel: Hotel;
  isLoading: boolean;
  error: string;
  changedPrices: IncomingPrice[];
  selectedDates: {
    startDate: string;
    endDate: string;
  };
  selectedDays: number[];
  isAllDaysSelected: boolean;
  addPricesLoading: boolean;
  addPricesError: string;
}

export const initialState: BasicPriceEntryInitialState = {
  hotel: {
    otel_id: 0,
    otel_adi: "",
    currency: "",
    odalar: [],
  },
  isLoading: false,
  error: "",
  changedPrices: [],
  selectedDates: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(1, "months").format("YYYY-MM-DD"),
  },
  selectedDays: [],
  isAllDaysSelected: true,
  addPricesLoading: false,
  addPricesError: "",
};

const basicPriceEntrySlice = createSlice({
  name: "basicPriceEntry",
  initialState,
  reducers: {
    handleSaveHotel(state, action) {
      state.hotel = action.payload;
    },
    handleAddChangedPrice(state, action) {
      const filteredPrices = state.changedPrices.filter(
        (price) =>
          `${price.oda_id}-${price.pansiyon_id}` !==
          `${action.payload.oda_id}-${action.payload.pansiyon_id}`
      );
      state.changedPrices = [...filteredPrices, action.payload];
    },
    handleRemoveChangedPrice(state, action) {
      state.changedPrices = state.changedPrices.filter(
        (price) =>
          `${price.oda_id}-${price.pansiyon_id}` !==
          `${action.payload.oda_id}-${action.payload.pansiyon_id}`
      );
    },
    handleSelectedDates(state, action) {
      const dates = action.payload;
      state.selectedDates = {
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
    },
    handleSelectedDays(state, action) {
      state.selectedDays = action.payload
        ? action.payload.map((day: any) => day.value)
        : [];
    },
    handleIsAllDaysSelected(state, action) {
      state.isAllDaysSelected = action.payload;

      if (state.isAllDaysSelected) {
        state.selectedDays = [];
      }
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetAll(state) {
      state.changedPrices = [];
      state.selectedDays = [];
      state.isAllDaysSelected = true;
      state.selectedDates = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "months").format("YYYY-MM-DD"),
      };
      state.isLoading = false;
      state.error = "";
    },

    setAddPricesLoading(state, action) {
      state.addPricesLoading = action.payload;
    },
    setAddPricesError(state, action) {
      state.addPricesError = action.payload;
    },
  },
});

export const {
  handleSaveHotel,
  handleAddChangedPrice,
  handleRemoveChangedPrice,
  handleSelectedDates,
  handleSelectedDays,
  handleIsAllDaysSelected,
  setError,
  setIsLoading,
  resetAll,
  setAddPricesLoading,
  setAddPricesError,
} = basicPriceEntrySlice.actions;

export default basicPriceEntrySlice.reducer;
