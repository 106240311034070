export const CHART_COLORS = {
  SUCCESS_BOLD: "#15803d",
  SUCCESS: "#22c55e",
  SUCCESS_LIGHT: "#86efac",
  INFO_BOLD: "#0369a1",
  INFO: "#0ea5e9",
  INFO_LIGHT: "#7dd3fc",
  WARNING_BOLD: "#a16207",
  WARNING: "#eab308",
  WARNING_LIGHT: "#fde047",
  DANGER_BOLD: "#b91c1c",
  DANGER: "#ef4444",
  DANGER_LIGHT: "#fca5a5",
  PRIMARY_BOLD: "#1d4ed8",
  PRIMARY: "#3b82f6",
  PRIMARY_LIGHT: "#93c5fd",
  SECONDARY_BOLD: "#334155",
  SECONDARY: "#64748b",
  SECONDARY_LIGHT: "#cbd5e1",
};
