import { handleChangeUpdateFacilityRoomFormInput } from "slices/facility/facilityRooms/reducer";
import {
  UpdateFacilityRoomFormErrors,
  UpdateFacilityRoomState,
} from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Select from "react-select";
import { FACILITY_SPECIAL_FEATURES_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilitySpecialFeaturesProps {
  values: UpdateFacilityRoomState;
  formErrors: UpdateFacilityRoomFormErrors;
  validateFormChange: (values: UpdateFacilityRoomState) => void;
  roomFeaturesOptions: Option[];
}

interface Option {
  value: number;
  label: string;
}

const FacilitySpecialFeatures = ({
  values,
  formErrors,
  validateFormChange,
  roomFeaturesOptions,
}: FacilitySpecialFeaturesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Öne Çıkan Özellikler"
      description={FACILITY_SPECIAL_FEATURES_DESCRIPTION}
      error={formErrors.oda_one_cikan_ozellikler}
      divider
      required
      orientation="vertical"
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "1rem",
        }}
      >
        <Select
          options={roomFeaturesOptions}
          value={
            roomFeaturesOptions.find(
              (option) => option.value === values.oda_one_cikan_ozellikler[1]
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                ...values,
                oda_one_cikan_ozellikler: {
                  ...values.oda_one_cikan_ozellikler,
                  1: selectedOption.value,
                },
              })
            );
          }}
          placeholder="1. Özellik"
        />
        <Select
          options={roomFeaturesOptions}
          value={
            roomFeaturesOptions.find(
              (option) => option.value === values.oda_one_cikan_ozellikler[2]
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                ...values,
                oda_one_cikan_ozellikler: {
                  ...values.oda_one_cikan_ozellikler,
                  2: selectedOption.value,
                },
              })
            );
          }}
          placeholder="2. Özellik"
        />
        <Select
          options={roomFeaturesOptions}
          value={
            roomFeaturesOptions.find(
              (option) => option.value === values.oda_one_cikan_ozellikler[3]
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                ...values,
                oda_one_cikan_ozellikler: {
                  ...values.oda_one_cikan_ozellikler,
                  3: selectedOption.value,
                },
              })
            );
          }}
          placeholder="3. Özellik"
        />
        <Select
          options={roomFeaturesOptions}
          value={
            roomFeaturesOptions.find(
              (option) => option.value === values.oda_one_cikan_ozellikler[4]
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                ...values,
                oda_one_cikan_ozellikler: {
                  ...values.oda_one_cikan_ozellikler,
                  4: selectedOption.value,
                },
              })
            );
          }}
          placeholder="4. Özellik"
        />
      </div>
    </CustomFormFieldContainer>
  );
};

export default FacilitySpecialFeatures;
