import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { FACILITY_PHONE_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityPhoneNumberProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityPhoneNumber = ({
  values,
  validateFormChange,
  formErrors,
}: FacilityPhoneNumberProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Telefon Numarası"
        // error={formErrors.telefon}
        description={FACILITY_PHONE_DESCRIPTION}
        divider
        required
      >
        <Input
          type="text"
          value={values.telefon}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                telefon: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              telefon: e.target.value,
            });
          }}
          disabled={true}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityPhoneNumber;
