import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";
import {
  EarlyReservationDiscountFormErrors,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface RatesProps {
  values: EarlyReservationDiscountState;
  formErrors: EarlyReservationDiscountFormErrors;
  validateFormChange: (values: EarlyReservationDiscountState) => void;
}

const Rates = ({ values, validateFormChange, formErrors }: RatesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Erken Rezervasyon İndirim Oranı"
        error={formErrors.oran}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.oran}
            onChange={(e) => {
              dispatch(handleChangeInput({ oran: e.target.value }));
              validateFormChange({
                ...values,
                oran: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Gerçek İndirim Oranı"
        error={formErrors.gercek_indirim_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.gercek_indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeInput({ gercek_indirim_orani: e.target.value })
              );
              validateFormChange({
                ...values,
                gercek_indirim_orani: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Göstermelik İndirim Oranı"
        error={formErrors.gostermelik_indirim_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.gostermelik_indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  gostermelik_indirim_orani: Number(e.target.value),
                })
              );
              validateFormChange({
                ...values,
                gostermelik_indirim_orani: Number(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default Rates;
