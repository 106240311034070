import { CustomPageDescriptions } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { CommissionPaymentInitialState } from "slices/financeAndAccounting/commissionPayment/reducer";

interface CommissionPaymentDescriptionsProps {
  children: React.ReactNode;
}

const CommissionPaymentDescriptions = ({
  children,
}: CommissionPaymentDescriptionsProps) => {
  const { commissionPayment } = useSelector(
    (state: { CommissionPayment: CommissionPaymentInitialState }) =>
      state.CommissionPayment
  );

  const paymentAmount = commissionPayment.tutar;
  return (
    <div className="d-flex flex-column gap-4">
      {paymentAmount ? (
        <>
          <CustomPageDescriptions
            variant="danger"
            descriptions={[
              `Şirketinize kesilmiş olan komisyon faturası/faturaları nedeni ile ${paymentAmount} TL komisyon alacağımız bulunmaktadır.`,
              "Bu rakam dilerseniz sizlere ödenecek olan konaklama tutar/tutarlarından düşülebilir, havale yolu ile ödeyebilir ya da aşağıdaki sanal pos seçeneği ile kredi kartınız üzerinden işlemlerinizi gerçekleştirebilirsiniz.",
            ]}
          />
          <div className="d-flex justify-content-start align-items-end gap-4">
            <span className="fw-semibold fs-18">Komisyon Ödemesi Tutarı:</span>
            <span className="fw-bold fs-20">{paymentAmount} TL</span>
          </div>
          {children}
          <CustomPageDescriptions
            variant="info"
            descriptions={[
              "Havale Bilgileri",
              `${commissionPayment.havale_bilgileri.banka}`,
              `${commissionPayment.havale_bilgileri.sube}`,
              `${commissionPayment.havale_bilgileri.iban}`,
              `Hesap Sahibi: ${commissionPayment.havale_bilgileri.hesap_sahibi}`,
            ]}
          />
          <CustomPageDescriptions
            variant="info"
            descriptions={[
              "Muhasebe İletişim Bilgileri",
              `${commissionPayment.muhasebe_ve_ileitism_bilgileri}`,
            ]}
          />
        </>
      ) : (
        <CustomPageDescriptions
          variant="success"
          descriptions={["Komisyon ödemesi bulunmamaktadır."]}
        />
      )}
    </div>
  );
};

export default CommissionPaymentDescriptions;
