import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  resetAfterAddNewTheme,
  handleSaveThemeRegionOptions,
  handleSaveThemeTypesOptions,
  setAddThemeLoading,
  setAddThemeError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_THEME_REGION_OPTIONS,
  GET_THEME_TYPES_OPTIONS,
  ADD_NEW_THEME,
} from "helpers/url_helper";

import { AddNewThemeRequestPayload } from "types/themes";

import { Dispatch } from "redux";

export const getThemeRegionOptions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEME_REGION_OPTIONS);

    dispatch(handleSaveThemeRegionOptions(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getThemeTypesOptions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEME_TYPES_OPTIONS);

    dispatch(handleSaveThemeTypesOptions(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addNewTheme =
  (payload: AddNewThemeRequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setAddThemeLoading(true));
      await axios.post(ADD_NEW_THEME, payload);

      dispatch(setToastSuccess("Yeni tema eklendi."));
      dispatch(resetAfterAddNewTheme());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddThemeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddThemeLoading(false));
    }
  };
