interface SummaryBoxProps {
  items: {
    [key: string]: number;
  };
}
const SummaryBox = ({ items }: SummaryBoxProps) => {
  return (
    <div className="d-flex flex-column bg-white border rounded overflow-hidden">
      {Object.keys(items).map((key, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
          className={
            index === 0
              ? "theme-bg-primary text-white fw-bold fs-18 text-center"
              : ""
          }
        >
          <span
            className={
              index === 0
                ? "border p-1 fw-semibold"
                : "border p-1 fw-semibold fs-15"
            }
          >
            {key}
          </span>
          <span
            className={
              index === 0 ? "border p-1 fw-semibold" : "border p-1 fs-15"
            }
          >
            {items[key]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SummaryBox;
