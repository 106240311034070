import { UncontrolledAlert } from "reactstrap";
import { ReservationReview } from "types/reservation";

interface DismissableAlertsProps {
  reservation: ReservationReview;
}

const DismissableAlerts = ({ reservation }: DismissableAlertsProps) => {
  const showInetWarning =
    reservation.operator_firma && reservation.konfirme_edildi_mi === "e";
  const showKapidaWarning =
    reservation.rez_tipi === "kapidaodeme" &&
    reservation.konfirme_edildi_mi === "e";

  const showKaporaliWarning =
    reservation.rez_tipi === "kaporalisatis" &&
    reservation.konfirme_edildi_mi === "e";

  return (
    <div className="d-flex flex-column gap-2">
      {showInetWarning && (
        <UncontrolledAlert color="danger">
          <strong> I-NET :</strong> REZERVASYON I-NET'E GİRİLMİŞTİR.
          REZERVASYONA AİT TÜM DETAYLARI I-NET'TEN KONTROL EDİNİZ. FATURALAR
          I-NET ÜZERİNDEN KESİNİZ. FATURA BİLGİLERİNİ KAYDETMEK İÇİN
          REZERVASYONLARDAN İSİM YADA KODU İLE ARATARAK FATURA BİLGİLERİNİZ
          KAYDEDİN.İPTAL VE İADE YAPILMAMIŞ İSE OPERASYONA AKTARINIZ. İADE
          YAPILMIŞ İSE I-NET'TEN KONTROL EDEREK MİSAFİRE BİLGİ VERİNİZ.
        </UncontrolledAlert>
      )}
      {showKapidaWarning && (
        <UncontrolledAlert color="danger">
          <strong> KAPIDA ÖDEMELİ : </strong> REZERVASYONLAR CRM / I-NET OLARAK
          GİRİLİR. REZERVASYONA AİT TÜM DETAYLARI CRM DEN KONTROL EDİNİZ. KAPIDA
          ÖDEMEDE MİSAFİR ÖDEMEYİ OTELE YAPAR. MİSAFİRİN FATURASINI OTEL KESER.
          BİZ OTELE KOMİSYON FATURASI KESEREK KOMİSYONUMUZU ALIRIZ. OTEL BİZİ
          MİSAFİR GELMEDİ REZ. İPTAL EDİLDİ DİYE ARARSA İLK ÖNCE MİSAFİRİ
          ARAYARAK MERHABALAR, ......... OTEL KONAKLAMANIZ İÇİN ARIYORUM. GİRİŞ
          ÇIKIŞ TARİHLERİNİ SORARAK KONAKLAMANIZ İPTAL BİLGİSİ GELDİ OTELDE
          KONAKLAMA YAPTINIZMI DİYE SORUNUZ. EĞER EVET İPTAL ETTİM DERSE CRM DEN
          REZERVASYONU İPTAL EDİYORUZ. CRM DEN İPTAL ETTİĞİMİZDE OTELE MAİL
          OTOMATİK DÜŞÜYOR. EĞER MİSAFİR OTELDE KONAKLADIM DERSE OTELE TEKRARDAN
          MAİL ATIYORUZ MİSAFİRİMİZ BU TARİHLERDE OTELDE KONAKLADIĞINI BEYAN
          ETMİŞTİR. REZERVASYON İPTAL EDİLMEMİŞTİR. SİZDE BU ŞEKİLDE
          KAYITLARINIZI YAPABİLİRMİSİNİZ DİYE GERİ DÖNÜŞ YAPINIZ.
        </UncontrolledAlert>
      )}
      {showKaporaliWarning && (
        <UncontrolledAlert color="danger">
          <strong> KAPORALI REZERVASYONLAR : </strong> REZERVASYONLAR CRM /
          I-NET OLARAK GİRİLİR. REZERVASYONLARA AİT TÜM DETAYLARI CRM DEN
          KONTROL EDİNİZ. REZERVASYONUN KAPORASI MİSAFİR TARAFINDAN ACENTEYE
          ÖDENİR. ( KAPORA BİZİM HAKEDİŞİMİZDİR). KALAN KISMI OTELİN HAKEDİŞİ
          MİSAFİR OTELE GİTTİĞİNDE OTELDE ÖDER. MİSAFİRİN FATURASI ACENTEYE
          ÖDENEN KISIM MİSAFİRE ACENTE TARAFINDAN FATURA EDİLİR. OTELE ÖDENEN
          KISIM OTEL TARAFINDAN MİSAFİRE FATURA EDİLİR. FATURANIN KESİLDİĞİNİ
          ANLAMAMIZ İÇİN REZERVASYONA ULAŞIP REZERVASYONUN ALTINDA FATURA
          NUMARASI VAR İSE KESİLMİŞTİR. İPTAL VE İADE YAPILMAMIŞ İSE OPERASYONA
          AKTARINIZ. İADE YAPILMIŞ İSE I-NET'TEN VEYA CRM DEN KONTROL EDEREK
          MİSAFİRE BİLGİ VERİNİZ.
        </UncontrolledAlert>
      )}
    </div>
  );
};

export default DismissableAlerts;
