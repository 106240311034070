import { createSlice } from "@reduxjs/toolkit";

import {
  PlaceToVisit,
  UpdatePlaceToVisitState,
  UpdatePlaceToVisitFormErrors,
} from "types/definitions";

export interface ListPlacesToVisitInitialState {
  placesToVisitList: PlaceToVisit[];
  placeToVisit: UpdatePlaceToVisitState;
  updatePlaceToVisitFormErrors: UpdatePlaceToVisitFormErrors;
  isLoading: boolean;
  error: string;
  updatePlaceToVisitLoading: boolean;
  updatePlaceToVisitError: string;
}

export const listPlacesToVisitInitialState: ListPlacesToVisitInitialState = {
  placesToVisitList: [],
  placeToVisit: {
    id: 0,
    yer_adi: "",
    aciklama: "",
    resim: "",
    file: "",
  },
  updatePlaceToVisitFormErrors: {},
  isLoading: false,
  error: "",
  updatePlaceToVisitLoading: false,
  updatePlaceToVisitError: "",
};

export const listPlacesToVisitSlice = createSlice({
  name: "listPlacesToVisit",
  initialState: listPlacesToVisitInitialState,
  reducers: {
    handleSavePlacesToVisitList(state, action) {
      state.placesToVisitList = action.payload;
    },
    handleAddUpdatePlaceToVisitFormErrors(state, action) {
      state.updatePlaceToVisitFormErrors = action.payload;
    },
    handleSavePlaceToVisitForUpdate(state, action) {
      const id = action.payload;

      const selected = state.placesToVisitList.find(
        (place) => place.id === id
      ) || {
        id: 0,
        yer_adi: "",
        aciklama: "",
        resim: "",
      };

      state.placeToVisit = {
        id: selected.id,
        yer_adi: selected.yer_adi,
        aciklama: selected.aciklama,
        resim: selected.resim,
        file: "",
      };
    },
    handleChangeInput(state, action) {
      state.placeToVisit = {
        ...state.placeToVisit,
        ...action.payload,
      };
    },
    resetPlaceToVisit(state) {
      state.placeToVisit = {
        id: 0,
        yer_adi: "",
        aciklama: "",
        resim: "",
        file: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdatePlaceToVisitLoading(state, action) {
      state.updatePlaceToVisitLoading = action.payload;
    },
    setUpdatePlaceToVisitError(state, action) {
      state.updatePlaceToVisitError = action.payload;
    },
  },
});

export const {
  handleSavePlacesToVisitList,
  handleAddUpdatePlaceToVisitFormErrors,
  handleSavePlaceToVisitForUpdate,
  handleChangeInput,
  resetPlaceToVisit,
  setIsLoading,
  setError,
  setUpdatePlaceToVisitLoading,
  setUpdatePlaceToVisitError,
} = listPlacesToVisitSlice.actions;

export default listPlacesToVisitSlice.reducer;
