import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface AdsReportTableProps {
  isLoading: boolean;
}
const AdsReportTable = ({ isLoading }: AdsReportTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Reklam Raporu">
      <CustomReactTable
        fileName="Reklam Raporu"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
        legend={legend}
      />
    </CustomFormContainer>
  );
};

export default AdsReportTable;
