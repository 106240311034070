import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/addUser/reducer";
import { AddUserFormErrors, AddUserState } from "types/userOperations";

interface UserGeneralInformationProps {
  values: AddUserState;
  formErrors: AddUserFormErrors;
  validateFormChange: (values: AddUserState) => void;
}



const UserGeneralInformation = ({
  values,
  formErrors,
  validateFormChange,
}: UserGeneralInformationProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [passwordCheck, setPasswordCheck] = useState("");
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (passwordCheck && passwordCheck !== values.sifre) {
      setPasswordError("Şifreler eşleşmiyor.");
    } else {
      setPasswordError(undefined);
    }
  }, [passwordCheck, values.sifre]);

  return (
    <>
      <CustomFormFieldContainer
        label="Ad Soyad"
        error={formErrors.ad_soyad}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.ad_soyad}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ad_soyad: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ad_soyad: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Kullanıcı Adı"
        error={formErrors.kullanici_adi}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.kullanici_adi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                kullanici_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              kullanici_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Email Adresi"
        error={formErrors.email_adresi}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.email_adresi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                email_adresi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              email_adresi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Şifre"
        error={formErrors.sifre}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.sifre}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                sifre: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              sifre: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Şifreyi Tekrarla"
        error={passwordError}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={passwordCheck}
          onChange={(e) => {
            setPasswordCheck(e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UserGeneralInformation;
