import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/addHotelFeature/reducer";
import {
  AddHotelFeatureFormErrors,
  AddHotelFeatureState,
} from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface FeatureNameProps {
  values: AddHotelFeatureState;
  formErrors: AddHotelFeatureFormErrors;
  validateFormChange: (values: AddHotelFeatureState) => void;
}

const FeatureName = ({
  values,
  validateFormChange,
  formErrors,
}: FeatureNameProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Tesis Özelliği"
        error={formErrors.ozellik_adi}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.ozellik_adi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                ozellik_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ozellik_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FeatureName;
