import { RoomsLackPicturePage } from "Components/Custom/ReportsPages";
import useRoomsLackPicture from "Components/Hooks/reports/useRoomsLackPicture";
import PageContainer from "pages/PageContainer";

const RoomsLackPicture = () => {
  const { isLoading } = useRoomsLackPicture();

  return (
    <PageContainer title="Resmi Eksik Odalar">
      <RoomsLackPicturePage isLoading={isLoading} />
    </PageContainer>
  );
};

export default RoomsLackPicture;
