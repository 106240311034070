import { Spinner } from "reactstrap";

interface PieChartContainerProps {
  children: React.ReactNode;
  noData?: boolean;
  containerWidth?: number | string;
  containerHeight?: number;
  isLoading?: boolean;
}

const PieChartContainer = ({
  children,
  containerWidth = "500px",
  containerHeight = 300,
  noData = false,
  isLoading = false,
}: PieChartContainerProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <div className="w-100 shadow-sm rounded p-2 theme-bg-light d-flex justify-content-center">
        <div
          style={{
            width: containerWidth,
            height: containerHeight + 40,
          }}
        >
          {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </div>
          ) : noData ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <span className="text-muted fw-medium ">
                Gösterilecek veri bulunamadı.
              </span>
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChartContainer;
