import { useSelector } from "react-redux";

import { CallsFromGeneralInitialState } from "slices/reports/callsFromGeneral/reducer";

const useHandleTable = () => {
  const { callsFromGeneral } = useSelector(
    (state: { CallsFromGeneral: CallsFromGeneralInitialState }) =>
      state.CallsFromGeneral
  );

  const tableData = callsFromGeneral?.map((call) => ({
    // "Müşteri ID": call.musteri_id,
    "Müşteri Adı Soyadı": call.musteri_isim,
    "Müşteri Telefon": call.arayan_no,
    "Aranan Numara": call.aranan_no,
    // "Otel ID": call.otel_id,
    "Otel Adı": call.otel_adi,
    "Kayıt Yapan ID": call.crm_kul_id,
    "Kayıt Yapan": call.ad_soyad,
    "Kayıt Tarihi": call.kayit_tarihi,
  }));

  const tableColumns = [
    {
      header: "Müşteri Adı Soyadı",
      accessorKey: "Müşteri Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Müşteri Telefon",
      accessorKey: "Müşteri Telefon",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Numara",
      accessorKey: "Aranan Numara",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Yapan",
      accessorKey: "Kayıt Yapan",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Tarihi",
      accessorKey: "Kayıt Tarihi",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
