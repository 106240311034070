import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { DiscountCodesInitialState } from "slices/discountsAndActions/discountCodes/reducer";
import { getDiscountCodes } from "slices/discountsAndActions/discountCodes/thunk";

const useDiscountCodes = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { error, isLoading } = useSelector(
    (state: { DiscountCodes: DiscountCodesInitialState }) => state.DiscountCodes
  );

  useEffect(() => {
    dispatch(getDiscountCodes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    isLoading,
  };
};

export default useDiscountCodes;
