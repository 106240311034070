import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveCompetitorAnalysisInfo,
  setIsLoading,
  setError,
  setUpdateAnalysisLoading,
  setUpdateAnalysisError,
} from "./reducer";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import {
  GET_COMPETITOR_ANALYSIS_INFO,
  UPDATE_COMPETITOR_ANALYSIS_INFO,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { CompetitorAnalysisInfo } from "types/adsAndAnalyze";

type SuccessCallback = () => void;

export const getCompetitorAnalysisInfo = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_COMPETITOR_ANALYSIS_INFO);

    const res = response.data || response;

    dispatch(handleSaveCompetitorAnalysisInfo(res[0]));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateCompetitorAnalysisInfo =
  (payload: CompetitorAnalysisInfo, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateAnalysisLoading(true));
      await axios.post(UPDATE_COMPETITOR_ANALYSIS_INFO, payload);

      successCallback();
      dispatch(setToastSuccess("Analiz bilgisi güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateAnalysisError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateAnalysisLoading(false));
    }
  };
