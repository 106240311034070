import { createSlice } from "@reduxjs/toolkit";

import {
  FacilityUser,
  FacilityUserFormErrors,
  FacilityUserState,
  FacilityUserResponse,
} from "types/facility";

export interface FacilityUsersInitialState {
  facilityUserResponse: FacilityUserResponse;
  updateFacilityUser: FacilityUserState;
  username: string;
  userMails: { mail: "" }[];
  selectedMail: string;
  updateFacilityUserFormErrors: FacilityUserFormErrors;
  isLoading: boolean;
  error: string;
  addUserLoading: boolean;
  addUserError: string;
  updateUserLoading: boolean;
  updateUserError: string;
  resetPasswordLoading: boolean;
  resetPasswordError: string;
}

const initialState: FacilityUsersInitialState = {
  facilityUserResponse: {
    kullanici_bilgileri: {
      id: 0,
      aktiflik: "1",
      inet_kodu: "",
      kullanici_adi: "",
    },
    mailler: [],
  },
  updateFacilityUser: {
    id: 0,
    inet_kodu: "",
    aktiflik: "1",
    kullanici_adi: "",
    mail: "",
  },
  username: "",
  userMails: [],
  selectedMail: "",
  updateFacilityUserFormErrors: {},
  isLoading: false,
  error: "",
  addUserLoading: false,
  addUserError: "",
  updateUserLoading: false,
  updateUserError: "",
  resetPasswordLoading: false,
  resetPasswordError: "",
};

const facilityUsersSlice = createSlice({
  name: "facilityUsers",
  initialState,
  reducers: {
    handleSaveFacilityUserResponse(state, action) {
      state.facilityUserResponse = action.payload;
    },
    handleSaveUserMails(state, action) {
      state.userMails = action.payload;
    },
    handleSaveUpdateFacilityUserState(state, action) {
      if (action.payload) {
        const { id, aktiflik, inet_kodu, kullanici_adi } =
          action.payload as FacilityUser;

        state.username = kullanici_adi;

        state.updateFacilityUser = {
          id,
          aktiflik,
          inet_kodu,
          kullanici_adi,
          mail: "",
        };
      } else {
        state.updateFacilityUser = {
          id: 0,
          aktiflik: "1",
          inet_kodu: "",
          kullanici_adi: "",
          mail: "",
        };

        state.username = "";
      }
    },
    handleAddUpdateFacilityUserFormErrors(state, action) {
      state.updateFacilityUserFormErrors = action.payload;
    },
    handleChangeUpdateFacilityUserFormInput(state, action) {
      state.updateFacilityUser = {
        ...state.updateFacilityUser,
        ...action.payload,
      };
    },
    handleSelectMail(state, action) {
      state.selectedMail = action.payload;
    },
    resetUpdateFacilityUserForm(state, action) {
      if (action.payload) {
        const { id, aktiflik, inet_kodu, kullanici_adi } =
          action.payload as FacilityUser;

        state.updateFacilityUser = {
          id,
          aktiflik,
          inet_kodu,
          kullanici_adi,
          mail: "",
        };
      } else {
        state.updateFacilityUser = {
          id: 0,
          aktiflik: "1",
          inet_kodu: "",
          kullanici_adi: "",
          mail: "",
        };
      }
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddUserLoading(state, action) {
      state.addUserLoading = action.payload;
    },
    setAddUserError(state, action) {
      state.addUserError = action.payload;
    },
    setUpdateUserLoading(state, action) {
      state.updateUserLoading = action.payload;
    },
    setUpdateUserError(state, action) {
      state.updateUserError = action.payload;
    },
    setResetPasswordLoading(state, action) {
      state.resetPasswordLoading = action.payload;
    },
    setResetPasswordError(state, action) {
      state.resetPasswordError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityUserResponse,
  handleSaveUserMails,
  handleSaveUpdateFacilityUserState,
  handleAddUpdateFacilityUserFormErrors,
  handleChangeUpdateFacilityUserFormInput,
  handleSelectMail,
  resetUpdateFacilityUserForm,
  setIsLoading,
  setError,
  setAddUserLoading,
  setAddUserError,
  setUpdateUserLoading,
  setUpdateUserError,
  setResetPasswordLoading,
  setResetPasswordError,
} = facilityUsersSlice.actions;

export default facilityUsersSlice.reducer;
