import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/addPlaceToVisit/reducer";
import {
  AddPlaceToVisitFormErrors,
  AddPlaceToVisitState,
} from "types/definitions";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface PlaceToVisitImageProps {
  values: AddPlaceToVisitState;
  formErrors: AddPlaceToVisitFormErrors;
  validateFormChange: (values: AddPlaceToVisitState) => void;
}

const PlaceToVisitImage = ({
  values,
  validateFormChange,
  formErrors,
}: PlaceToVisitImageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.file ? [values.file] : [];

  return (
    <>
      <CustomFormFieldContainer label="Resim" error={formErrors.file}>
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeInput({ file: fileItems[0] }));
          }}
          maxFiles={1}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PlaceToVisitImage;
