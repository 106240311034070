import { createSlice } from "@reduxjs/toolkit";
import { UserIPsResponse } from "types/userOperations";

export interface UserIPsInitialState {
  userIPs: UserIPsResponse[];
  isLoading: boolean;
  error: string;
}

export const userIPsInitialState: UserIPsInitialState =
  {
    userIPs: [],
    isLoading: false,
    error: "",
  };

const userIPsSlice = createSlice({
  name: "userIPs",
  initialState: userIPsInitialState,
  reducers: {
    handleSaveUserIpsResponse(state, action) {
      state.userIPs = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveUserIpsResponse,
  setIsLoading,
  setError,
} = userIPsSlice.actions;
export default userIPsSlice.reducer;
