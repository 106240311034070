import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteFromUnderTheDeskDiscountsWithId,
  handleSaveUnderTheDeskDiscountForEdit,
} from "slices/discountsAndActions/underTheDeskDiscounts/reducer";
import { deleteUnderTheDeskDiscount } from "slices/discountsAndActions/underTheDeskDiscounts/thunk";
import {
  UnderTheDeskDiscount,
  UnderTheDeskDiscountState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { underTheDeskDiscounts } = useSelector(
    (state: any) => state.UnderTheDeskDiscounts
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(handleSaveUnderTheDeskDiscountForEdit(selectedRow));
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      desk_alti_id: id,
    };
    dispatch(deleteUnderTheDeskDiscount(payload) as any);
    dispatch(handleDeleteFromUnderTheDeskDiscountsWithId(id));
  };

  const tableData: UnderTheDeskDiscountState[] = underTheDeskDiscounts?.map(
    (discount: UnderTheDeskDiscount) => {
      return {
        id: discount.id,
        grup_id: discount.grup_id,
        her_zaman: discount.her_zaman,
        satis_baslangic_tarih: discount.satis_baslangic_tarih,
        satis_bitis_tarih: discount.satis_bitis_tarih,
        baslangic_tarih: discount.baslangic_tarih,
        bitis_tarih: discount.bitis_tarih,
        oran: discount.oran,
        tum_otel_pansiyonlar: discount.tum_otel_pansiyonlar,
        otel_pansiyonlar: discount.otel_pansiyonlar ?? [],
        tum_odeme_tipleri: discount.tum_odeme_tipleri,
        odeme_tipleri: discount.odeme_tipleri ?? [],
      } as UnderTheDeskDiscountState;
    }
  );

  const tableColumns = [
    {
      header: "Her Zaman",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "her_zaman",
      enableColumnFilter: true,
    },
    {
      header: "Satış Tarihi",
      cell: (cell: any) => (
        <span>
          {moment(cell.row.original.satis_baslangic_tarih).format("DD.MM.YYYY")}
          <br />
          {moment(cell.row.original.satis_bitis_tarih).format("DD.MM.YYYY")}
        </span>
      ),
      accessorKey: "satis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihi",
      cell: (cell: any) => (
        <span>
          {moment(cell.row.original.baslangic_tarih).format("DD.MM.YYYY")}
          <br />
          {moment(cell.row.original.bitis_tarih).format("DD.MM.YYYY")}
        </span>
      ),
      accessorKey: "konaklama_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      cell: (cell: any) => (
        <span className="fw-medium">%{cell.row.original.oran}</span>
      ),
      accessorKey: "oran",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalara Uygula",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue() ?? [];

        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue() ?? [];
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
