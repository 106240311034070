import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import {
  AdsReportInitialState,
  handleChangeSelectedDates,
  handleSaveSelectedManager,
} from "slices/adsAndAnalyze/adsReport/reducer";
import { getAdsReport } from "slices/adsAndAnalyze/adsReport/thunk";
import moment from "moment";

interface Option {
  value: string | number;
  label: string;
}
export type SearchCriteria = "anlik" | "gecmis";

const useHandleForm = () => {
  const { adsReportManagers, oldDates } = useSelector(
    (state: { AdsReport: AdsReportInitialState }) => state.AdsReport
  );

  const [filterForm, setFilterForm] = useState({
    sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
    edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    oldDates: "",
    yetkili_id: 0,
  });

  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>("anlik");

  const handleSearchCriteriaChange = (value: SearchCriteria) => {
    setSearchCriteria(value);
  };
  const handleChangeSelectedOldDates = (start: string, end: string) => {
    dispatch(
      handleChangeSelectedDates({
        sdate: start,
        edate: end,
      })
    );
  };

  const handleFormChange = (key: string, value: string | number) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [oldDatesOptions, setOldDatesOptions] = useState<Option[]>([]);
  const [managerOptions, setManagerOptions] = useState<Option[]>([]);
  const searchCriteriaOptions = [
    { value: "anlik", label: "Anlık" },
    { value: "gecmis", label: "Eskiler" },
  ];

  useEffect(() => {
    const oldDatesOptions = oldDates.map((date) => ({
      value: date.tarihler,
      label: date.tarihler,
    }));
    const managerOptions = Object.entries(adsReportManagers).map(
      ([key, value]) => ({
        value: value,
        label: key,
      })
    );
    managerOptions.unshift({ value: 0, label: "Tüm Sorumlular" });
    handleFormChange("oldDates", oldDatesOptions[0]?.value);

    setOldDatesOptions(oldDatesOptions);
    setManagerOptions(managerOptions);
  }, [oldDates, adsReportManagers]);

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const startDate =
      searchCriteria === "anlik"
        ? filterForm.sdate
        : filterForm.oldDates.split(" - ")[0];
    const endDate =
      searchCriteria === "anlik"
        ? filterForm.edate
        : filterForm.oldDates.split(" - ")[1];

    const params = {
      sdate: startDate,
      edate: endDate,
      yetkili_id: filterForm.yetkili_id,
      per_page: 10,
      search: "",
      page: 1,
    };
    dispatch(getAdsReport(params));
    dispatch(
      handleChangeSelectedDates({
        sdate: filterForm.sdate,
        edate: filterForm.edate,
      })
    );
    dispatch(handleSaveSelectedManager(filterForm.yetkili_id));
  };

  const handleResetFilter = () => {
    setFilterForm({
      sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      oldDates: "",
      yetkili_id: 0,
    });

    setSearchCriteria("anlik");

    const params = {
      sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      yetkili_id: 0,
      per_page: 10,
      search: "",
      page: 1,
    };

    dispatch(getAdsReport(params));
    dispatch(
      handleChangeSelectedDates({
        sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
        edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      })
    );
    dispatch(handleSaveSelectedManager(0));
  };

  return {
    filterForm,
    searchCriteria,
    handleFormChange,
    handleSearchCriteriaChange,
    handleChangeSelectedOldDates,
    searchCriteriaOptions,
    oldDatesOptions,
    managerOptions,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
