import { CustomButton } from "Components/Custom/UI";
interface AddNewChildSpecialAgeInformationButtonProps {
  onSave: () => void;
  isLoading: boolean;
}
const AddNewChildSpecialAgeInformationButton = ({
  onSave,
  isLoading,
}: AddNewChildSpecialAgeInformationButtonProps) => {
  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton variant="success" isLoading={isLoading} onClick={onSave}>
        Yeni Özel Çocuk Yaşı Bilgisi Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewChildSpecialAgeInformationButton;
