import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleAddChangedQuota } from "slices/pricesAndQuota/basicQuotaEntry/reducer";

import { IncomingQuota } from "types/PricesQuotas";

interface QuotaInputProps {
  name: string;
  defaultValue: IncomingQuota;
}

const QuotaInput = ({ name, defaultValue }: QuotaInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleChangeQuota = (quota: string) => {
    const incomingQuota: IncomingQuota = {
      oda_id: parseInt(name.split("-")[0]),
      kontenjan: parseInt(quota),
    };
    dispatch(handleAddChangedQuota(incomingQuota));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    handleChangeQuota(newValue);
  };

  return (
    <div className="input-group input-group-sm flex-nowrap border p-2 h-100">
      <Input
        type="number"
        className="form-control user-select-none"
        name={name}
        value={defaultValue.kontenjan ?? "30"}
        onChange={handleChange}
        style={{ maxWidth: "100px", height: "32px" }}
      />
      <span
        className="input-group-text fs-10 fw-medium user-select-none"
        style={{ cursor: "pointer", width: "34px", height: "32px" }}
      >
        Oda
      </span>
    </div>
  );
};

export default QuotaInput;
