import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListGroupsInitialState } from "slices/userOperations/listGroups/reducer";
import { getGroups } from "slices/userOperations/listGroups/thunk";

const useListGroups = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { ListGroups: ListGroupsInitialState }) => state.ListGroups
  );
  
  useEffect(() => {
    dispatch(
        getGroups()
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useListGroups;
