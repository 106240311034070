import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface UpdateUserAuthorityFormProps {
  value: string;
  formErrors: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UpdateUserAuthorityForm = ({
  value,
  onChange,
  formErrors,
}: UpdateUserAuthorityFormProps) => {

  return (
    <>
      <CustomFormFieldContainer
        label="Eklenecek Yetki"
      >
        <Input
          type="text"
          autoCorrect="on"
          value={value}
          onChange={onChange}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UpdateUserAuthorityForm;
