import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

interface ContentContainerProps {
  tabContents: {
    tabId: string;
    tabContent: JSX.Element;
  }[];

  tabButtons: { tabId: string; tabName: string }[];
}

const ContentContainer = ({
  tabButtons,
  tabContents,
}: ContentContainerProps) => {
  const [arrowNavTab, setArrowNavTab] = useState("1");
  const arrowNavToggle = (tab: any) => {
    if (arrowNavTab !== tab) {
      setArrowNavTab(tab);
    }
  };

  return (
    <div className="mt-5 p-1">
      <Nav pills className="nav nav-pills nav-justified bg-light mb-1">
        {tabButtons.map((tabButton) => (
          <NavItem key={tabButton.tabId} className="border rounded me-1">
            <NavLink
              key={tabButton.tabId}
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
              className={classnames({
                active: arrowNavTab === tabButton.tabId,
              })}
              onClick={() => {
                arrowNavToggle(tabButton.tabId);
              }}
            >
              {tabButton.tabName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent
        activeTab={arrowNavTab}
        className="border rounded p-2 theme-bg-light"
      >
        {tabContents.map((tabContent) => (
          <TabPane key={tabContent.tabId} tabId={tabContent.tabId}>
            {tabContent.tabContent}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default ContentContainer;
