import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import FilterForm from "./FilterForm";
import ForecastReportCharts from "./ForecastReportCharts";

interface ForecastReportPageProps {
  isLoading: boolean;
}

const ForecastReportPage = ({ isLoading }: ForecastReportPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <ForecastReportCharts isLoading={isLoading} />
    </div>
  );
};

export default withPageNeedsHotel(ForecastReportPage);
