import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface MailOrderInfoTableProps {
  isLoading: boolean;
}

const MailOrderInfoTable = ({ isLoading }: MailOrderInfoTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomFormContainer title="Mail Order Bilgileri">
      <CustomReactTable
        fileName="Mail Order Bilgileri"
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
      />
    </CustomFormContainer>
  );
};

export default MailOrderInfoTable;
