import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";

interface Option {
  value: number;
  label: string;
}

interface CitySelectorProps {
  selectedCityId: number;
  options: Option[];
  handleSelectCity: (id: number) => void;
}

const CitySelector = ({
  selectedCityId,
  options,
  handleSelectCity,
}: CitySelectorProps) => {
  const selectedValue = options.find(option => option.value === selectedCityId);

  return (
    <CustomFormFieldContainer label={"Şehirler : "} required divider>
      <Select
        options={options}
        values={selectedCityId === 0 ? null : selectedValue}
        onChange={(selected: Option) => {
          const cityId = selected ? Number(selected.value) : 0;
          handleSelectCity(cityId);
        }}
        placeholder="Şehir seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default CitySelector;
