import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { CALENDAR_ITEMS } from "./items";

const CalendarPage = () => {
  return (
    <Row>
      {CALENDAR_ITEMS.map((item, key) => (
        <Col sm={6} md={6} lg={4} xl={3} key={key}>
          <Link
            to={item.link}
            className="text-decoration-none user-select-none"
          >
            <Card className={item.class} style={{ height: "250px" }}>
              <CardBody>
                <div className="mb-2">
                  <i className={"ri-" + item.icon + " fs-48"}></i>
                </div>
                <p className="fs-18 fw-bold">{item.title}</p>
                <p>{item.subtitle}</p>
              </CardBody>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default CalendarPage;
