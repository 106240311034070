import {
  CustomFormFieldContainer,
  CustomFormEditor,
} from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";

interface BannerOneExplanationProps {
    values: CreateWebSiteState;
    formErrors: CreateWebSiteFormErrors;
    validateFormChange: (values: CreateWebSiteState) => void;
}

const BannerOneExplanation = ({
  values,
  formErrors,
  validateFormChange,
}: BannerOneExplanationProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Genel Açıklama"
      error={formErrors.banner1_icerik}
      orientation="vertical"
      required
    >
      <CustomFormEditor
        editorData={values.banner1_icerik}
        handleGetEditorData={(data) => {
          dispatch(
            handleChangeInput({
              banner1_icerik: data,
            })
          );
          validateFormChange({
            ...values,
            banner1_icerik: data,
          });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default BannerOneExplanation;
