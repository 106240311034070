import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddNightRestrictionFormErrors } from "slices/discountsAndActions/nightRestrictions/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  NightRestrictionFormErrors,
  NightRestrictionState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: NightRestrictionState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: NightRestrictionFormErrors = {};

    if (!values.baslangic_tarih || !values.bitis_tarih) {
      errors.konaklama_tarihi =
        "Konaklama tarihi başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (!values.minimum_gece || Number(values.minimum_gece) === 0) {
      errors.min_gece_sayisi = "Minimum gece sayısı alanı boş bırakılamaz.";
    }

    if (Number(values.tum_gunler) === 0 && values.gunler.length === 0) {
      errors.gun = "En az bir gün seçmelisiniz.";
    }

    if (
      Number(values.tum_otel_pansiyonlar) === 0 &&
      values.otel_pansiyonlar.length === 0
    ) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }

    dispatch(handleAddNightRestrictionFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };
  const validateFormChange = (values: NightRestrictionState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
