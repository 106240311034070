import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface OutboundCallsListWithNumberTableProps {
  isLoading: boolean;
}

const OutboundCallsListWithNumberTable = ({
  isLoading,
}: OutboundCallsListWithNumberTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Dış Aramalar (Dahili)"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default OutboundCallsListWithNumberTable;
