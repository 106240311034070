import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { CompetitorAnalysisInfoFormState } from "types/adsAndAnalyze";

interface ChildrenAgesProps {
  formValues: CompetitorAnalysisInfoFormState;
  handleChangeForm: (key: string, value: number | string) => void;
}

const ChildrenAges = ({ formValues, handleChangeForm }: ChildrenAgesProps) => {
  return (
    <>
      {formValues.cocuk_sayisi > 0 && (
        <div className="d-flex flex-column gap-2">
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <p className="fw-medium m-0">1.Çocuk Yaşı</p>
            <CustomNumberSelector
              value={formValues.cocuk_yasi_1}
              max={12}
              onChange={(value) => handleChangeForm("cocuk_yasi_1", value)}
              disabled={true}
            />
          </div>
        </div>
      )}
      {formValues.cocuk_sayisi > 1 && (
        <div className="d-flex flex-column gap-2">
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <p className="fw-medium m-0">2.Çocuk Yaşı</p>
            <CustomNumberSelector
              value={formValues.cocuk_yasi_2}
              max={12}
              onChange={(value) => handleChangeForm("cocuk_yasi_2", value)}
              disabled={true}
            />
          </div>
        </div>
      )}
      {formValues.cocuk_sayisi > 2 && (
        <div className="d-flex flex-column gap-2">
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <p className="fw-medium m-0">3.Çocuk Yaşı</p>
            <CustomNumberSelector
              value={formValues.cocuk_yasi_3}
              max={12}
              onChange={(value) => handleChangeForm("cocuk_yasi_3", value)}
              disabled={true}
            />
          </div>
        </div>
      )}
      {formValues.cocuk_sayisi > 3 && (
        <div className="d-flex flex-column gap-2">
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <p className="fw-medium m-0">4.Çocuk Yaşı</p>
            <CustomNumberSelector
              value={formValues.cocuk_yasi_4}
              max={12}
              onChange={(value) => handleChangeForm("cocuk_yasi_4", value)}
              disabled={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChildrenAges;
