import { CustomLoadingPage } from "Components/Custom/UI";
import ThemeSelector from "./ThemeSelector";
import { ThemeHotel } from "types/themes";
import AssigningLists from "./AssigningLists";

interface AssignHotelToThemePageProps {
  hotels: ThemeHotel[];
  selectedTheme: number;
  handleSelectTheme: (id: number) => void;
  themesOptions: Option[];
  isLoading: boolean;
}

interface Option {
  value: number;
  label: string;
}

const AssignHotelToThemePage = ({
  hotels,
  selectedTheme,
  handleSelectTheme,
  themesOptions,
  isLoading,
}: AssignHotelToThemePageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ThemeSelector
          themesOptions={themesOptions}
          selectedTheme={selectedTheme}
          handleSelectTheme={handleSelectTheme}
        />
        {selectedTheme ? (
          <AssigningLists hotels={hotels} selectedTheme={selectedTheme} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AssignHotelToThemePage;
