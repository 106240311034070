import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Input, Label } from "reactstrap";
import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import {
  FACILITY_ACTIVITY_DESCRIPTION,
  FACILITY_ACTIVITY_FEATURES_DESCRIPTION,
} from "utils/fieldDescriptions";

interface FacilityFoodFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  facilityServicesOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityServicesFields = ({
  values,
  formErrors,
  validateFormChange,
  facilityServicesOptions,
}: FacilityFoodFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Aktivite Bilgileri" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="Aktivite  Açıklama"
        error={formErrors.otel_aktivite_aciklama}
        orientation="vertical"
        description={FACILITY_ACTIVITY_DESCRIPTION}
        required
        divider
      >
        <CustomFormEditor
          editorData={values.otel_aktivite_aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                otel_aktivite_aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              otel_aktivite_aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Aktiviteler"
        // error={formErrors.aktiviteler}
        description={FACILITY_ACTIVITY_FEATURES_DESCRIPTION}
        // required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityServicesOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.aktiviteler.includes(parseInt(option.value))}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.aktiviteler, parseInt(value)]
                    : values.aktiviteler.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      aktiviteler: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    aktiviteler: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityServicesFields;
