import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Input, Label } from "reactstrap";
import {
  FACILITY_FOOD_DESCRIPTION,
  FACILITY_FOOD_FEATURES_DESCRIPTION,
} from "utils/fieldDescriptions";

interface FacilityFoodFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityFoodOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityFoodFields = ({
  values,
  formErrors,
  validateFormChange,
  facilityFoodOptions,
}: FacilityFoodFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Yiyecek Özellikleri" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="Yiyecek ve İçecek Açıklama"
        error={formErrors.yiyecek_metin}
        orientation="vertical"
        description={FACILITY_FOOD_DESCRIPTION}
        required
        divider
      >
        <CustomFormEditor
          editorData={values.yiyecek_metin}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                yiyecek_metin: data,
              })
            );
            validateFormChange({
              ...values,
              yiyecek_metin: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Yiyecek Özellikleri"
        error={formErrors.yiyecek_ozellik}
        description={FACILITY_FOOD_FEATURES_DESCRIPTION}
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityFoodOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.yiyecek_ozellik.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.yiyecek_ozellik, parseInt(value)]
                    : values.yiyecek_ozellik.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      yiyecek_ozellik: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    yiyecek_ozellik: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityFoodFields;
