import { CustomFormContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangePaymentStatus } from "slices/pricesAndQuota/roomSalesAndPaymentMethods/reducer";
import { RoomSalesAndPaymentMethodsState } from "types/PricesQuotas";

interface PaymentStatusProps {
  formValues: RoomSalesAndPaymentMethodsState;
}

const PaymentStatus = ({ formValues }: PaymentStatusProps) => {
  const dispatch: Dispatch = useDispatch();
  const showKapida =
    formValues.fiyat_durum.kapida !== undefined &&
    formValues.odeme.kapida === true;
  const showKaporali =
    formValues.fiyat_durum.kaporali !== undefined &&
    formValues.odeme.kaporali === true;
  const showKismi =
    formValues.fiyat_durum.kismi !== undefined &&
    formValues.odeme.kismi === true;
  const showIptal =
    formValues.fiyat_durum.iptal !== undefined &&
    formValues.odeme.iptal_edilemez === true;


  return (
    <CustomFormContainer title="Girilen Standart Fiyatlar Aşağıdaki Ödeme Yöntemleri İçin de Geçerli Olsun">
      <div className="d-flex gap-5 align-items-center">
        {showIptal && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formValues.fiyat_durum.iptal}
                onChange={(e) =>
                  dispatch(
                    handleChangePaymentStatus({
                      iptal: e.target.checked,
                    })
                  )
                }
              />
              İptal Edilemez
            </Label>
          </FormGroup>
        )}
        {showKapida && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formValues.fiyat_durum.kapida}
                onChange={(e) =>
                  dispatch(
                    handleChangePaymentStatus({
                      kapida: e.target.checked,
                    })
                  )
                }
              />
              Tesiste Ödemeli
            </Label>
          </FormGroup>
        )}
        {showKaporali && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formValues.fiyat_durum.kaporali}
                onChange={(e) =>
                  dispatch(
                    handleChangePaymentStatus({
                      kaporali: e.target.checked,
                    })
                  )
                }
              />
              Kapora Ödemeli
            </Label>
          </FormGroup>
        )}
        {showKismi && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formValues.fiyat_durum.kismi}
                onChange={(e) =>
                  dispatch(
                    handleChangePaymentStatus({
                      kismi: e.target.checked,
                    })
                  )
                }
              />
              Kısmi Ödemeli
            </Label>
          </FormGroup>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default PaymentStatus;
