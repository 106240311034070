import { MoneyPointsPage } from "Components/Custom/ReportsPages";
import useMoneyPoints from "Components/Hooks/reports/useMoneyPoints";
import PageContainer from "pages/PageContainer";

const MoneyPoints = () => {
  const { loading, error, isValidHotel, isLoading } = useMoneyPoints();
  return (
    <PageContainer title="Tesis Para Puan Listesi">
      <MoneyPointsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default MoneyPoints;
