import { Col, Row } from "reactstrap";

import UpdateProfileForm from "./UpdateProfileForm";
import UserProfileInfo from "./UserProfileInfo";
import ResetPasswordForm from "./ResetPasswordForm";
import { CustomTab } from "Components/Custom/UI";

import { Router } from "types";

interface Tab {
  id: number;
  title: string;
  content: React.ReactNode;
}

const UserProfilePage = (props: Router) => {
  const tabs: Tab[] = [
    {
      id: 1,
      title: "Bilgileri Güncelle",
      content: <UpdateProfileForm />,
    },
    {
      id: 2,
      title: "Şifreyi Değiştir",
      content: <ResetPasswordForm {...props} />,
    },
  ];
  return (
    <Row>
      <Col lg={6}>
        <UserProfileInfo />
      </Col>
      <Col lg={6}>
        <CustomTab tabs={tabs} />
      </Col>
    </Row>
  );
};

export default UserProfilePage;
