import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacility,
  handleSaveFacilityForUpdate,
  handleSaveFacilityFormChoices,
  setUpdateFacilityLoading,
  setUpdateFacilityError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_FACILITY,
  UPDATE_FACILITY,
  GET_FACILITY_FORM_CHOICES,
} from "helpers/url_helper";

import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getFacility = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_FACILITY}/${id}`);

    dispatch(handleSaveFacility(response.data || response));
    dispatch(handleSaveFacilityForUpdate(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateFacility =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateFacilityLoading(true));
      await axios.post(UPDATE_FACILITY, payload);

      successCallback();
      dispatch(setToastSuccess("Tesis güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateFacilityError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateFacilityLoading(false));
    }
  };

export const getFacilityFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_FACILITY_FORM_CHOICES);

    dispatch(handleSaveFacilityFormChoices(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
