import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { IssuedInvoicesInitialState } from "slices/financeAndAccounting/issuedInvoices/reducer";
import { getIssuedInvoices } from "slices/financeAndAccounting/issuedInvoices/thunk";

const useIssuedInvoices = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, selectedDates } = useSelector(
    (state: { IssuedInvoices: IssuedInvoicesInitialState }) =>
      state.IssuedInvoices
  );

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 10,
      search: "",
      otel_id: 0,
      yetkili_id: 0,
      b_tarih: selectedDates[0],
      s_tarih: selectedDates[1],
    };

    dispatch(getIssuedInvoices(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useIssuedInvoices;
