import { createSlice } from "@reduxjs/toolkit";
import {
  ReservationCollectionResponse,
  ReservationPaymentTableResponse,
  ReservationTableResponse,
} from "types/userOperations";

export interface InformationTechnologiesInitialState {
  reservations: ReservationTableResponse[];
  reservationPayments: ReservationPaymentTableResponse[];
  reservationCollections: ReservationCollectionResponse[];
  isLoading: boolean;
  error: string;
  updatePriceProviderLoading: boolean;
  updatePriceProviderError: string;
  updateCollectionLoading: boolean;
  updateCollectionError: string;
  updatePaymentStatusLoading: boolean;
  updatePaymentStatusError: string;
}

export const informationTechnologiesInitialState: InformationTechnologiesInitialState =
  {
    reservations: [],
    reservationPayments:  [],
    reservationCollections: [],
    isLoading: false,
    error: "",
    updatePriceProviderLoading: false,
    updatePriceProviderError: "",
    updateCollectionLoading: false,
    updateCollectionError: "",
    updatePaymentStatusLoading: false,
    updatePaymentStatusError: "",
  };

const userIPsSlice = createSlice({
  name: "userIPs",
  initialState: informationTechnologiesInitialState,
  reducers: {
    handleSaveReservationTableResponse(state, action) {
      state.reservations = action.payload;
    },
    handleSaveReservationPaymentTableResponse(state, action) {
      state.reservationPayments = action.payload;
    },
    handleSaveReservationCollectionResponse(state, action) {
      state.reservationCollections = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdatePriceProviderLoading(state, action) {
      state.updatePriceProviderLoading = action.payload;
    },
    setUpdatePriceProviderError(state, action) {
      state.updatePriceProviderError = action.payload;
    },
    setUpdateCollectionLoading(state, action) {
      state.updateCollectionLoading = action.payload;
    },
    setUpdateCollectionError(state, action) {
      state.updateCollectionError = action.payload;
    },
    setUpdatePaymentStatusLoading(state, action) {
      state.updatePaymentStatusLoading = action.payload;
    },
    setUpdatePaymentStatusError(state, action) {
      state.updatePaymentStatusError = action.payload;
    },
  },
});

export const {
  handleSaveReservationTableResponse,
  handleSaveReservationPaymentTableResponse,
  handleSaveReservationCollectionResponse,
  setIsLoading,
  setError,
  setUpdatePriceProviderLoading,
  setUpdatePriceProviderError,
  setUpdateCollectionLoading,
  setUpdateCollectionError,
  setUpdatePaymentStatusLoading,
  setUpdatePaymentStatusError,
} = userIPsSlice.actions;
export default userIPsSlice.reducer;
