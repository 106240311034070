import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FormGroup, Input, Label } from "reactstrap";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/underTheDeskDiscounts/reducer";
import {
  UnderTheDeskDiscountFormErrors,
  UnderTheDeskDiscountState,
} from "types/DiscountsAndActions";
import { useSelector } from "react-redux";
import { setToastError } from "slices/toast/reducer";

interface ApplicationCriteriaProps {
  values: UnderTheDeskDiscountState;
  formErrors: UnderTheDeskDiscountFormErrors;
  validateFormChange: (values: UnderTheDeskDiscountState) => void;
}

const ApplicationCriteria = ({ values }: ApplicationCriteriaProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { underTheDeskDiscounts, underTheDeskDiscount } = useSelector(
    (state: any) => state.UnderTheDeskDiscounts
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Uygulama Kriteri"
        tooltipId="underTheDeskApplicationCriteria"
      >
        <div className="d-flex align-items-center justify-content-around gap-2">
          <FormGroup check>
            <Input
              type="radio"
              name="applicationCriteria"
              id="criteria-always"
              checked={values.her_zaman === "1"}
              value="1"
              onChange={(e) => {
                (underTheDeskDiscounts.length === 1 &&
                  underTheDeskDiscount.id) ||
                underTheDeskDiscounts.length === 0
                  ? dispatch(
                      handleChangeInput({
                        her_zaman: e.target.value,
                      })
                    )
                  : dispatch(
                      setToastError(
                        "Her Zaman Seçilebilmesi için Bu Odaya Ait Hiç Desk Altı Tanımlanmamış Olmalıdır!"
                      )
                    );
              }}
            />
            <Label for="criteria-always">Her zaman</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="radio"
              name="applicationCriteria"
              id="criteria-between-dates"
              checked={values.her_zaman === "0"}
              value="0"
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    her_zaman: e.target.value,
                  })
                );
              }}
            />
            <Label for="criteria-between-dates">Tarih Aralığında</Label>
          </FormGroup>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default ApplicationCriteria;
