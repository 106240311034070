import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import RoomPersonPriceRatesForm from "./RoomPersonPriceRatesForm";
import { CustomLoadingPage } from "Components/Custom/UI";

interface RoomPersonPriceRatesPageProps {
  isLoading: boolean;
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const RoomPersonPriceRatesPage = (props: RoomPersonPriceRatesPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={props.isLoading} />
      <RoomPersonPriceRatesForm {...props} />
    </>
  );
};

export default withPageNeedsHotel(RoomPersonPriceRatesPage);
