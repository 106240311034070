import { SpecialDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import { useSpecialDiscounts } from "Components/Hooks";
import PageContainer from "pages/PageContainer";

const SpecialDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useSpecialDiscounts();

  return (
    <PageContainer title="Özel İndirimler">
      <SpecialDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default SpecialDiscounts;
