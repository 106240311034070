import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import HotelInvoicesTable from "./HotelInvoicesTable";

interface HotelInvoicesPageProps {
  isLoading: boolean;
}

const HotelInvoicesPage = ({ isLoading }: HotelInvoicesPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <HotelInvoicesTable isLoading={isLoading} />
    </div>
  );
};

export default withPageNeedsHotel(HotelInvoicesPage);
