import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  AddLocationInitialState,
  handleSaveLocations,
  handleSaveSelectedLocations,
} from "slices/definitions/addLocation/reducer";
import { getLocations } from "slices/definitions/addLocation/thunk";
import { Location } from "types/definitions";

const useAddLocation = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, addLocationLoading } = useSelector(
    (state: { AddLocation: AddLocationInitialState }) => state.AddLocation
  );

  useEffect(() => {
    dispatch(
      getLocations({
        parent_id: -1,
        successCallback: (response: Location[]) => {
          dispatch(handleSaveLocations(response));
          dispatch(
            handleSaveSelectedLocations([
              {
                key: -1,
                value: [...response],
              },
            ])
          );
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    addLocationLoading,
  };
};

export default useAddLocation;
