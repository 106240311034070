import { CustomModal } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { FacilityCallInitialState } from "slices/facility/facilityCalls/reducer";

interface ShowDetailedInfoModalProps {
  hotelId: number;
  isOpen: boolean;
  handleToggle: () => void;
}

const ShowDetailedInfoModal = ({
  hotelId,
  isOpen,
  handleToggle,
}: ShowDetailedInfoModalProps) => {
  const { facilityCalls } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  const facilityCall = facilityCalls.find(
    (facilityCall) => facilityCall.otel_id === hotelId
  );

  const tableData = {
    Açıklama: facilityCall?.operasyon_durum_aciklama ?? "",
    "Güncelleme Zamanı": facilityCall?.operasyon_durum_guncelleme_zamani ?? "",
    "Arama Durumu": facilityCall?.operasyon_durum
      ? facilityCall.operasyon_durum === "1"
        ? "Çözüldü"
        : facilityCall.operasyon_durum === "2"
        ? "Çözülmedi"
        : "Beklemede"
      : "",
    Güncelleyen: facilityCall?.operasyon_durum_guncelleyen_ad_soyad ?? "",
  };

  return (
    <CustomModal
      isOpen={isOpen}
      handleToggle={handleToggle}
      title="Detaylı Bilgi"
    >
      <div className="p-2">
        <div className="d-flex flex-column gap-3 p-2 border rounded">
          {Object.entries(tableData).map(([key, value], index) => (
            <div
              key={index}
              className={`d-flex ${
                key === "Açıklama"
                  ? "flex-column gap-2"
                  : "justify-content-between"
              } border-bottom p-1`}
            >
              <span>{key}:</span>
              <span className="fw-medium">{value}</span>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default ShowDetailedInfoModal;
