import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  handleSelectRoom,
  RoomSalesAndPaymentMethodsInitialState,
} from "slices/pricesAndQuota/roomSalesAndPaymentMethods/reducer";
import { getRoomSalesAndPaymentMethods } from "slices/pricesAndQuota/roomSalesAndPaymentMethods/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useRoomSalesAndPaymentMethods = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { selectedRoom, isLoading, error } = useSelector(
    (state: {
      RoomSalesAndPaymentMethods: RoomSalesAndPaymentMethodsInitialState;
    }) => state.RoomSalesAndPaymentMethods
  );

  const otel_id = hotel.otel_id;
  const hotelRooms = hotel.odalar;

  const rooms = hotel.odalar.map((room) => ({
    otel_id: room.otel_id,
    oda_id: room.id,
    oda_isim: room.oda_isim,
  }));
  const hotelAndSelectedRoom = {
    otel_id,
    oda_id: selectedRoom,
  };

  useEffect(() => {
    if (otel_id) {
      dispatch(handleSelectRoom(selectedRoom || rooms[0].oda_id));
      dispatch(
        getRoomSalesAndPaymentMethods({
          otel_id,
          oda_id: selectedRoom || rooms[0].oda_id,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = hotelRooms.length > 0;

  return {
    loading,
    error,
    rooms,
    isLoading,
    hotelAndSelectedRoom,
    isValidHotel,
  };
};
export default useRoomSalesAndPaymentMethods;
