import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";

interface CommercialPopupDatesProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}

const CommercialPopupDates = ({
  values,
  formErrors,
  validateFormChange,
}: CommercialPopupDatesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Reklam Popup Gösterim Tarih Aralığı :"
        error={formErrors.reklam_start_date || formErrors.reklam_end_date}
        required
      >
        <Flatpickr
          className="form-control"
          value={[values.reklam_start_date, values.reklam_end_date]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeInput({
                  reklam_start_date: stringStartDate,
                  reklam_end_date: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                reklam_start_date: stringStartDate,
                reklam_end_date: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CommercialPopupDates;
