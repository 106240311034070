import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import AsyncSelect from "react-select/async";

import useHandleForm from "./useHandleForm";
import { InputGroup, InputGroupText } from "reactstrap";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import GuestNumbers from "./GuestNumbers";
import ChildrenAges from "./ChildrenAges";
import { CalculatePriceFormValues } from "types/reservation";

interface CalculatePriceFormProps {
  singleCalculation?: boolean;
  children?: React.JSXElementConstructor<ChildrenComponentProps>;
}

interface ChildrenComponentProps {
  formValues: CalculatePriceFormValues;
  handleChangeForm: (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => void;
}

const CalculatePriceForm = ({
  singleCalculation = false,
  children,
}: CalculatePriceFormProps) => {
  const {
    formValues,
    handleFormChange,
    hotelInfo,
    loadOptions,
    handleChangeQuery,
    isLoading,
    onCalculatePrice,
  } = useHandleForm(singleCalculation);

  const { onDayCreate, onChange } = useSpecialDaysForFlatpickr(true);

  const ChildrenComponent =
    children as React.JSXElementConstructor<ChildrenComponentProps>;

  return (
    <CustomFormContainer title="Fiyat Hesapla">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "4fr 3fr 3fr 1fr",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          onInputChange={handleChangeQuery}
          placeholder={
            hotelInfo
              ? hotelInfo.otel_adi
              : "Otel Adı veya Bölge Adı Giriniz..."
          }
          onChange={(selectedOption) => {
            const option = selectedOption as {
              value: number;
              label: string;
              category: string;
            };
            handleFormChange("secilen", option);
          }}
          defaultValue={
            formValues.secilen.value
              ? {
                  value: formValues.secilen.value,
                  label: formValues.secilen.label,
                  category: formValues.secilen.category,
                }
              : null
          }
          defaultOptions={
            formValues.secilen.value
              ? [
                  {
                    value: formValues.secilen.value,
                    label: formValues.secilen.label,
                    category: formValues.secilen.category,
                  },
                ]
              : []
          }
        />

        <InputGroup>
          <InputGroupText>
            <i className="ri-calendar-todo-fill"></i>
          </InputGroupText>
          <Flatpickr
            className="form-control"
            value={[formValues.giris_tarihi, formValues.cikis_tarihi]}
            onChange={(dates) => {
              const [startDate, endDate] = dates;

              if (startDate && endDate) {
                const stringStartDate = moment(startDate).format("D MMMM Y");
                const stringEndDate = moment(endDate).format("D MMMM Y");

                handleFormChange("giris_tarihi", stringStartDate);
                handleFormChange("cikis_tarihi", stringEndDate);
              }
            }}
            options={{
              mode: "range",
              dateFormat: "d M Y",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
              onChange,
              minDate: moment().subtract(1, "days").toDate(),
            }}
            placeholder="Giriş Tarihi - Çıkış Tarihi"
          />
        </InputGroup>
        <div className="d-flex flex-column gap-2">
          <GuestNumbers
            formValues={formValues}
            handleChangeForm={handleFormChange}
          />
          <ChildrenAges
            formValues={formValues}
            handleChangeForm={handleFormChange}
          />
        </div>
        <CustomButton
          variant="info"
          onClick={() => {
            onCalculatePrice();
          }}
          isLoading={isLoading}
        >
          Fiyat Hesapla
        </CustomButton>
      </div>
      {children ? (
        <ChildrenComponent
          formValues={formValues}
          handleChangeForm={handleFormChange}
        />
      ) : (
        <></>
      )}
    </CustomFormContainer>
  );
};

export default CalculatePriceForm;
