import { createSlice } from "@reduxjs/toolkit";
import {
  CreateWebSiteFormChoices,
  CreateWebSiteFormErrors,
  WebSite,
  CreateWebSiteState,
} from "types/myWebSite";

export interface CreateWebSiteInitialState {
  webSite: WebSite;
  createWebSite: CreateWebSiteState;
  createWebSiteFormChoices: CreateWebSiteFormChoices;
  createWebSiteFormErrors: CreateWebSiteFormErrors;
  imageUrl: string;
  isLoading: boolean;
  error: string;
  createWebSiteLoading: boolean;
  createWebSiteError: string;
}

export const createWebSiteInitialState: CreateWebSiteInitialState = {
  webSite: {
    otel_telefon: "",
    otel_email: "",
    google_map_x: "",
    google_map_y: "",
    otel_adres: "",
    otel_adi: "",
    id: 0,
    otel_id: 0,
    domain_link: "",
    aktiflik: "",
    temax_type_text: "",
    tema_id: 0,
    temax_type: "",
    footer_idealtur_yazi_goster: "",
    recaptcha_site: "",
    recaptcha_gizli: "",
    yandex_kodu: "",
    facebook_pixel: "",
    facebook_adres: "",
    instagram_adres: "",
    karsilama_cumlesi: "",
    vitrin_resim: "",
    vitrin_resim_2: "",
    vitrin_resim_3: "",
    one_cikan_ozellikler: "",
    reklam_popup: "",
    reklam_baslik: "",
    reklam_resim: "",
    reklam_start_date: "",
    reklam_end_date: "",
    otel_numarasi_goster: "",
    one_cikan_ozellik_baslik_1: "",
    one_cikan_ozellik_resim_1: "",
    one_cikan_ozellik_aciklama_1: "",
    one_cikan_ozellik_baslik_2: "",
    one_cikan_ozellik_resim_2: "",
    one_cikan_ozellik_aciklama_2: "",
    one_cikan_ozellik_baslik_3: "",
    one_cikan_ozellik_resim_3: "",
    one_cikan_ozellik_aciklama_3: "",
    banner1_baslik: "",
    banner1_resimleri: "",
    banner1_icerik: "",
    banner2_baslik: "",
    banner2_resimleri: "",
    banner2_icerik: "",
    genel_ozellikler_resim: "",
    genel_ozellikler: "",
    konaklama_bilgileri_resim: "",
    konaklama_bilgileri: "",
    spa_bilgileri_resim: "",
    spa_bilgileri: "",
    yeme_icme_bilgileri_resim: "",
    yeme_icme_bilgileri: "",
    aktivite_bilgileri_resim: "",
    aktivite_bilgileri: "",
    havuz_bilgileri_resim: "",
    havuz_bilgileri: "",
    toplanti_salonu_bilgileri_resim: "",
    toplanti_salonu_bilgileri: "",
    ekstra_ozellikler_baslik: "",
    ekstra_ozellikler_resim: "",
    ekstra_ozellikler: "",
    diger_departmanlar_telefon: "",
    banner1_resim1: "",
    banner1_resim2: "",
    banner1_resim3: "",
    banner2_resim1: "",
    banner2_resim2: "",
    banner2_resim3: "",
  },
  createWebSite: {
    otel_telefon: "",
    otel_mail: "", //! farklı
    otel_google_map_x: "", //! farklı
    otel_google_map_y: "", //! farklı
    otel_adres: "",
    domain_link: "",
    aktiflik: "",
    tema_id: 0,
    recaptcha_site: "",
    recaptcha_gizli: "",
    yandex_kodu: "",
    facebook_pixel: "",
    facebook_adres: "",
    instagram_adres: "",
    karsilama_cumlesi: "",
    vitrin_resim: "",
    vitrin_resim_2: "",
    vitrin_resim_3: "",
    one_cikan_ozellik: [], //! farklı
    reklam_popup: "",
    reklam_baslik: "",
    reklam_resim: "",
    reklam_start_date: "",
    reklam_end_date: "",
    otel_numarasi_goster: "",
    one_cikan_ozellik_baslik_1: "",
    one_cikan_ozellik_resim_1: "",
    one_cikan_ozellik_aciklama_1: "",
    one_cikan_ozellik_baslik_2: "",
    one_cikan_ozellik_resim_2: "",
    one_cikan_ozellik_aciklama_2: "",
    one_cikan_ozellik_baslik_3: "",
    one_cikan_ozellik_resim_3: "",
    one_cikan_ozellik_aciklama_3: "",
    banner1_baslik: "",
    banner1_icerik: "",
    banner2_baslik: "",
    banner2_icerik: "",
    genel_ozellikler_resim: "",
    genel_ozellikler: "",
    konaklama_bilgileri_resim: "",
    konaklama_bilgileri: "",
    spa_bilgileri_resim: "",
    spa_bilgileri: "",
    yeme_icme_bilgileri_resim: "",
    yeme_icme_bilgileri: "",
    aktivite_bilgileri_resim: "",
    aktivite_bilgileri: "",
    havuz_bilgileri_resim: "",
    havuz_bilgileri: "",
    toplanti_salonu_bilgileri_resim: "",
    toplanti_salonu_bilgileri: "",
    ekstra_ozellikler_baslik: "",
    ekstra_ozellikler_resim: "",
    ekstra_ozellikler: "",
    diger_departmanlar_telefon: "",
    banner1_resim_1: "",
    banner1_resim_2: "",
    banner1_resim_3: "",
    banner2_resim1: "",
    banner2_resim2: "",
    banner2_resim3: "",
    vitrin_resim_onizleme: "",
    vitrin_resim_2_onizleme: "",
    vitrin_resim_3_onizleme: "",
    reklam_resim_onizleme: "",
    one_cikan_ozellik_resim_1_onizleme: "",
    one_cikan_ozellik_resim_2_onizleme: "",
    one_cikan_ozellik_resim_3_onizleme: "",
    genel_ozellikler_resim_onizleme: "",
    konaklama_bilgileri_resim_onizleme: "",
    spa_bilgileri_resim_onizleme: "",
    yeme_icme_bilgileri_resim_onizleme: "",
    aktivite_bilgileri_resim_onizleme: "",
    havuz_bilgileri_resim_onizleme: "",
    toplanti_salonu_bilgileri_resim_onizleme: "",
    ekstra_ozellikler_resim_onizleme: "",
    banner1_resim1_onizleme: "",
    banner1_resim2_onizleme: "",
    banner1_resim3_onizleme: "",
    banner2_resim1_onizleme: "",
    banner2_resim2_onizleme: "",
    banner2_resim3_onizleme: "",
  },
  createWebSiteFormChoices: {
    temalar: [],
    otel_numara_goster: [],
    reklam_popup: [],
    one_cikan_ozellikler_list: [],
  },
  createWebSiteFormErrors: {},
  imageUrl: "",
  isLoading: false,
  error: "",
  createWebSiteLoading: false,
  createWebSiteError: "",
};

const createWebSiteSlice = createSlice({
  name: "createWebSite",
  initialState: createWebSiteInitialState,
  reducers: {
    handleSaveWebSite(state, action) {
      state.webSite = action.payload;
    },
    handleSaveWebSiteForUpdate(state, action) {
      const payload = action.payload as WebSite;

      const createWebSite: CreateWebSiteState = {
        otel_telefon: payload.otel_telefon,
        otel_mail: payload.otel_email,
        otel_google_map_x: payload.google_map_x,
        otel_google_map_y: payload.google_map_y,
        otel_adres: payload.otel_adres,
        domain_link: payload.domain_link,
        aktiflik: payload.aktiflik,
        tema_id: payload.tema_id,
        recaptcha_site: payload.recaptcha_site,
        recaptcha_gizli: payload.recaptcha_gizli,
        yandex_kodu: payload.yandex_kodu,
        facebook_pixel: payload.facebook_pixel,
        facebook_adres: payload.facebook_adres,
        instagram_adres: payload.instagram_adres,
        karsilama_cumlesi: payload.karsilama_cumlesi,
        vitrin_resim: "",
        vitrin_resim_2: "",
        vitrin_resim_3: "",
        one_cikan_ozellik: payload.one_cikan_ozellikler
          ? payload.one_cikan_ozellikler.split(",")
          : [],
        reklam_popup: payload.reklam_popup,
        reklam_baslik: payload.reklam_baslik,
        reklam_resim: "",
        reklam_start_date: payload.reklam_start_date,
        reklam_end_date: payload.reklam_end_date,
        otel_numarasi_goster: payload.otel_numarasi_goster,
        one_cikan_ozellik_baslik_1: payload.one_cikan_ozellik_baslik_1,
        one_cikan_ozellik_resim_1: "",
        one_cikan_ozellik_aciklama_1: payload.one_cikan_ozellik_aciklama_1,
        one_cikan_ozellik_baslik_2: payload.one_cikan_ozellik_baslik_2,
        one_cikan_ozellik_resim_2: "",
        one_cikan_ozellik_aciklama_2: payload.one_cikan_ozellik_aciklama_2,
        one_cikan_ozellik_baslik_3: payload.one_cikan_ozellik_baslik_3,
        one_cikan_ozellik_resim_3: "",
        one_cikan_ozellik_aciklama_3: payload.one_cikan_ozellik_aciklama_3,
        banner1_baslik: payload.banner1_baslik,
        banner1_icerik: payload.banner1_icerik,
        banner2_baslik: payload.banner2_baslik,
        banner2_icerik: payload.banner2_baslik,
        genel_ozellikler_resim: "",
        genel_ozellikler: payload.genel_ozellikler,
        konaklama_bilgileri_resim: "",
        konaklama_bilgileri: payload.konaklama_bilgileri,
        spa_bilgileri_resim: "",
        spa_bilgileri: payload.spa_bilgileri,
        yeme_icme_bilgileri_resim: "",
        yeme_icme_bilgileri: payload.yeme_icme_bilgileri,
        aktivite_bilgileri_resim: "",
        aktivite_bilgileri: payload.aktivite_bilgileri,
        havuz_bilgileri_resim: "",
        havuz_bilgileri: payload.havuz_bilgileri,
        toplanti_salonu_bilgileri_resim: "",
        toplanti_salonu_bilgileri: payload.toplanti_salonu_bilgileri,
        ekstra_ozellikler_baslik: payload.ekstra_ozellikler_baslik,
        ekstra_ozellikler_resim: "",
        ekstra_ozellikler: payload.ekstra_ozellikler,
        diger_departmanlar_telefon: payload.diger_departmanlar_telefon,
        banner1_resim_1: "",
        banner1_resim_2: "",
        banner1_resim_3: "",
        banner2_resim1: "",
        banner2_resim2: "",
        banner2_resim3: "",
        vitrin_resim_onizleme: payload.vitrin_resim,
        vitrin_resim_2_onizleme: payload.vitrin_resim_2,
        vitrin_resim_3_onizleme: payload.vitrin_resim_3,
        reklam_resim_onizleme: payload.reklam_resim,
        one_cikan_ozellik_resim_1_onizleme: payload.one_cikan_ozellik_resim_1,
        one_cikan_ozellik_resim_2_onizleme: payload.one_cikan_ozellik_resim_2,
        one_cikan_ozellik_resim_3_onizleme: payload.one_cikan_ozellik_resim_3,
        genel_ozellikler_resim_onizleme: payload.genel_ozellikler_resim,
        konaklama_bilgileri_resim_onizleme: payload.konaklama_bilgileri_resim,
        spa_bilgileri_resim_onizleme: payload.spa_bilgileri_resim,
        yeme_icme_bilgileri_resim_onizleme: payload.yeme_icme_bilgileri_resim,
        aktivite_bilgileri_resim_onizleme: payload.aktivite_bilgileri_resim,
        havuz_bilgileri_resim_onizleme: payload.havuz_bilgileri_resim,
        toplanti_salonu_bilgileri_resim_onizleme:
          payload.toplanti_salonu_bilgileri_resim,
        ekstra_ozellikler_resim_onizleme: payload.ekstra_ozellikler_resim,
        banner1_resim1_onizleme: payload.banner1_resim1,
        banner1_resim2_onizleme: payload.banner1_resim2,
        banner1_resim3_onizleme: payload.banner1_resim3,
        banner2_resim1_onizleme: payload.banner2_resim1,
        banner2_resim2_onizleme: payload.banner2_resim2,
        banner2_resim3_onizleme: payload.banner2_resim3,
      };

      state.createWebSite = createWebSite;
    },
    handleSaveImageUrl(state, action) {
      state.imageUrl = action.payload;
    },
    handleSaveCreateWebSiteFormChoices(state, action) {
      state.createWebSiteFormChoices = action.payload;
    },
    handleAddCreateWebSiteFormErrors(state, action) {
      state.createWebSiteFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.createWebSite = {
        ...state.createWebSite,
        ...action.payload,
      };
      console.log("input is changing: ", action.payload)
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCreateWebSiteLoading(state, action) {
      state.createWebSiteLoading = action.payload;
    },
    setCreateWebSiteError(state, action) {
      state.createWebSiteError = action.payload;
    },
  },
});

export const {
  handleSaveWebSite,
  handleSaveWebSiteForUpdate,
  handleSaveImageUrl,
  handleSaveCreateWebSiteFormChoices,
  handleAddCreateWebSiteFormErrors,
  handleChangeInput,
  setIsLoading,
  setError,
  setCreateWebSiteLoading,
  setCreateWebSiteError,
} = createWebSiteSlice.actions;

export default createWebSiteSlice.reducer;
