import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  MailOrderInfoInitialState,
  resetMailOrderForm,
  handleChangeMailOrderForm,
} from "slices/financeAndAccounting/mailOrderInfo/reducer";
import {
  getMailOrders,
  addMailOrder,
  updateMailOrder,
} from "slices/financeAndAccounting/mailOrderInfo/thunk";
import useValidateForm from "./useValidateForm";
import {
  AddMailOrderRequestPayload,
  UpdateMailOrderRequestPayload,
} from "types/financeAndAccounting";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { mailOrder, formErrors, banks } = useSelector(
    (state: { MailOrderInfo: MailOrderInfoInitialState }) => state.MailOrderInfo
  );

  const handleChange = (key: string, value: string) => {
    dispatch(handleChangeMailOrderForm({ [key]: value }));
  };

  const [banksOptions, setBanksOptions] = useState<Option[]>([]);

  useEffect(() => {
    const banksOptions = Object.entries(banks).map(([key, value]) => ({
      value: value,
      label: key,
    }));
    setBanksOptions(banksOptions);
  }, [banks]);

  const handleGetMailOrders = () => {
    dispatch(getMailOrders());
  };

  const handleAddMailOrder = () => {
    const payload: AddMailOrderRequestPayload = {
      kart_sahibi: mailOrder.kart_sahibi,
      adi: mailOrder.adi,
      kart_numarasi: mailOrder.kart_numarasi.match(/.{1,4}/g)?.join(" ") || "",
      kart_kodu: mailOrder.kart_kodu,
      kart_son_kullanim: mailOrder.kart_son_kullanim,
      kart_cvc: mailOrder.kart_cvc,
      durum: mailOrder.durum,
      ekle_durum: 1,
      otel_odeme: mailOrder.otel_odeme,
    };

    const { isValid } = validateForm(mailOrder);
    handleSubmitted(true);

    isValid && dispatch(addMailOrder(payload, handleGetMailOrders));
  };
  const handleUpdateMailOrder = () => {
    const payload: UpdateMailOrderRequestPayload = {
      id: mailOrder.id,
      kart_sahibi: mailOrder.kart_sahibi,
      adi: mailOrder.adi,
      kart_numarasi: mailOrder.kart_numarasi.match(/.{1,4}/g)?.join(" ") || "",
      kart_kodu: mailOrder.kart_kodu,
      kart_son_kullanim: mailOrder.kart_son_kullanim,
      kart_cvc: mailOrder.kart_cvc,
      durum: mailOrder.durum,
      otel_odeme: mailOrder.otel_odeme,
      guncelle: 1,
    };

    const { isValid } = validateForm(mailOrder);

    isValid && dispatch(updateMailOrder(payload, handleGetMailOrders));
  };

  const handleResetForm = () => {
    dispatch(resetMailOrderForm());
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    mailOrder,
    formErrors,
    banksOptions,
    onAddMailOrder: handleAddMailOrder,
    onUpdateMailOrder: handleUpdateMailOrder,
    onResetForm: handleResetForm,
    validateFormChange,
    handleChange,
  };
};

export default useHandleForm;
