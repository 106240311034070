import { createSlice } from "@reduxjs/toolkit";
import { Widget, WidgetLocation } from "types/myWebSite";

export interface CreateWidgetInitialState {
  widget: Widget;
  widgetOption: WidgetLocation[];
  isLoading: boolean;
  error: string;
  createWidgetLoading: boolean;
  createWidgetError: string;
}

export const createWidgetInitialState: CreateWidgetInitialState = {
  widget: {
    otel_id: 0,
    otel_adi: "",
    inet_kodu: "",
    oteldenal_sef_link: "",
    oteldenal_title: "",
    oteldenal_keywords: "",
    oteldenal_description: "",
    wScode: "",
    wUrl: "",
    widget_yonlendirme: "",
    wScode_durum: 0,
  },
  widgetOption: [],
  isLoading: false,
  error: "",
  createWidgetLoading: false,
  createWidgetError: "",
};

const createWidgetSlice = createSlice({
  name: "createWidget",
  initialState: createWidgetInitialState,
  reducers: {
    handleSaveWidget(state, action) {
      state.widget = action.payload;
    },
    handleSaveWidgetOption(state, action) {
      state.widgetOption = action.payload;
    },
    handleChangeInput(state, action) {
      state.widget = {
        ...state.widget,
        ...action.payload,
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCreateWidgetLoading: (state, action) => {
      state.createWidgetLoading = action.payload;
    },
    setCreateWidgetError: (state, action) => {
      state.createWidgetError = action.payload;
    },
  },
});

export const {
  handleSaveWidget,
  handleSaveWidgetOption,
  handleChangeInput,
  setIsLoading,
  setError,
  setCreateWidgetLoading,
  setCreateWidgetError,
} = createWidgetSlice.actions;

export default createWidgetSlice.reducer;
