import { useSelector } from "react-redux";
import { FacilityPartialPaymentDatesInitialState } from "slices/facility/facilityPartialPaymentDates/reducer";

const useHandleTable = () => {
  const { facilityPartialPaymentDates } = useSelector(
    (state: {
      FacilityPartialPaymentDates: FacilityPartialPaymentDatesInitialState;
    }) => state.FacilityPartialPaymentDates
  );

  const tableData = facilityPartialPaymentDates?.map((paymentDate) => ({
    otel_adi: paymentDate.otel_adi,
    yetkili: paymentDate.yetkili_adi,
    kismi_durum: paymentDate.kismi_durum,
    kismi_odeme_donemi: paymentDate.kismi_odeme_donemi_aylar,
    kapida_durum: paymentDate.kapida_durum,
    kapida_odeme_donemi: paymentDate.kapida_odeme_donemi_aylar,
    kaporali_durum: paymentDate.kaporali_durum,
    kaporali_odeme_donemi: paymentDate.kaporali_satis_donemi_aylar,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Yetkili",
      accessorKey: "yetkili",
      enableColumnFilter: false,
    },
    {
      header: "Kısmi Durum",
      accessorKey: "kismi_durum",
      cell: (cell: any) => (
        <span
          className={`${
            cell.getValue() === "Açık"
              ? "theme-text-success"
              : "theme-text-danger"
          }`}
        >
          {cell.getValue()}
        </span>
      ),
      enableColumnFilter: false,
    },
    {
      header: "Kısmi Ödeme Aylar",
      accessorKey: "kismi_odeme_donemi",
      cell: (cell: any) => {
        const value = cell.getValue();
        const firstMonth = value[0];
        const lastMonth = value[1];
        const isValid = firstMonth && lastMonth;

        const months = isValid ? `${firstMonth} - ${lastMonth}` : "-";

        return <span>{months} </span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Durum",
      accessorKey: "kapida_durum",
      cell: (cell: any) => (
        <span
          className={`${
            cell.getValue() === "Açık"
              ? "theme-text-success"
              : "theme-text-danger"
          }`}
        >
          {cell.getValue()}
        </span>
      ),
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Ödeme Dönemi",
      accessorKey: "kapida_odeme_donemi",
      cell: (cell: any) => {
        const value = cell.getValue();
        const firstMonth = value[0];
        const lastMonth = value[1];
        const isValid = firstMonth && lastMonth;

        const months = isValid ? `${firstMonth} - ${lastMonth}` : "-";

        return <span>{months} </span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Kaporalı Durum",
      accessorKey: "kaporali_durum",
      cell: (cell: any) => (
        <span
          className={`${
            cell.getValue() === "Açık"
              ? "theme-text-success"
              : "theme-text-danger"
          }`}
        >
          {cell.getValue()}
        </span>
      ),
      enableColumnFilter: false,
    },
    {
      header: "Kaporalı Ödeme Dönemi",
      accessorKey: "kaporali_odeme_donemi",
      cell: (cell: any) => {
        const value = cell.getValue();
        const firstMonth = value[0];
        const lastMonth = value[1];
        const isValid = firstMonth && lastMonth;

        const months = isValid ? `${firstMonth} - ${lastMonth}` : "-";

        return <span>{months} </span>;
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
