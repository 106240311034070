import { CustomDualListBox, CustomFormFieldContainer } from "Components/Custom/UI";
import { UpdateGroupFormErrors } from "types/userOperations";

interface GroupMembersListProps {
  formChoices: {
    value: string;
    label: string;
  }[];
  selectedOptions: string[];
  formErrors: UpdateGroupFormErrors;
  handleSelectOptions: (selected: string[]) => void;
}

const GroupMembersList = ({
  formChoices,
  selectedOptions,
  formErrors,
  handleSelectOptions,
}: GroupMembersListProps) => {

  return (
    <>
      <CustomFormFieldContainer
        label="Grup Üyeleri "
        error={formErrors.grup_uyeleri_id}
        orientation="vertical"
        divider
        required
      >
        <CustomDualListBox
          listOptions={formChoices}
          selectedOptions={selectedOptions}
          handleSelectOptions={handleSelectOptions}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default GroupMembersList;
