import { DASHBOARD_PAGE_DESCRIPTION } from "utils/pageDescriptions";
import { CustomPageDescriptions } from "Components/Custom/UI";

const PageInfoCard = () => {
  return (
    <>
      <CustomPageDescriptions descriptions={DASHBOARD_PAGE_DESCRIPTION} />
    </>
  );
};

export default PageInfoCard;
