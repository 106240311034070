import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { PersonnelRequestInitialState } from "slices/personnelRequest/reducer";
import { getPersonnelRequests } from "slices/personnelRequest/thunk";

const usePersonnelRequest = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const { isLoading } = useSelector(
        (state: { PersonnelRequest: PersonnelRequestInitialState }) => state.PersonnelRequest
    );


    useEffect(() => {
        dispatch(getPersonnelRequests());
    }, []);

    return {
        isLoading,
    };
};

export default usePersonnelRequest;
