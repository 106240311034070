import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  FACILITY_LINK_DESCRIPTION,
  FACILITY_STARS_DESCRIPTION,
} from "utils/fieldDescriptions";
import Select from "react-select";

interface FacilityStarsAndSefLinkProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  starsOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityStarsAndSefLink = ({
  values,
  validateFormChange,
  formErrors,
  starsOptions,
}: FacilityStarsAndSefLinkProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Değerlendirme Yıldızı"
        error={formErrors.yildiz}
        description={FACILITY_STARS_DESCRIPTION}
        divider
        required
      >
        <Select
          options={starsOptions}
          value={starsOptions.find((option) => option.value === values.yildiz)}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                yildiz: selected.value,
              })
            );
            validateFormChange({
              ...values,
              yildiz: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Otelfiyat Sef Link"
        description={FACILITY_LINK_DESCRIPTION}
        error={formErrors.otel_link}
        required
        divider
      >
        <Input
          type="text"
          value={values.otel_link}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_link: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_link: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityStarsAndSefLink;
