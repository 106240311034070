import CustomDragDropBox from "Components/Custom/UI/CustomDragDropBox";
import useHandleList from "./useHandleList";
import { CustomDnDOrdering } from "Components/Custom/UI";

const RoomMatchList = () => {
  const {
    supplierRooms,
    selectedSupplierRooms,
    inetRooms,
    handleChangeSupplierRooms,
    handleChangeSelectedSupplierRooms,
    handleChangeInetRooms,
    onAddRoomMatch,
    onRemoveRoomMatch,
    checkItemIsMatched,
    matchStatus,
  } = useHandleList();

  const selectedSupplierRoomsLength = selectedSupplierRooms.length;
  const matchedInetRooms = inetRooms.filter((inetRoom) =>
    selectedSupplierRooms.some(
      (selectedSupplierRoom) => selectedSupplierRoom.id === inetRoom.id
    )
  );

  return (
    <div className="d-flex flex-column gap-2">
      <p className="fw-semibold fs-20 m-0 p-1 flex-grow-1">Oda Eşleştir</p>
      <div className="d-flex gap-2">
        <div className="d-flex flex-column gap-1">
          <p className="text-center fw-medium m-0 p-1 border border-primary theme-text-primary bg-primary bg-opacity-10 rounded-top">
            INET Oda Listesi
          </p>
          <div
            className="d-flex flex-column gap-1 px-1 py-2 border border-2 rounded-bottom theme-bg-light"
            style={{ minHeight: "200px", width: "350px" }}
          >
            <CustomDnDOrdering
              items={inetRooms}
              labelKey="text"
              orderKey="order"
              handleOrderItems={handleChangeInetRooms}
              numbersOfFixedItems={matchedInetRooms.length}
            />
          </div>
        </div>
        {selectedSupplierRoomsLength ? (
          <div className="d-flex flex-column gap-1">
            <p className="text-center fw-medium m-0 p-1 border border-primary theme-text-primary bg-primary bg-opacity-10  rounded-top">
              -
            </p>
            <div className="d-flex flex-column gap-2 px-1 py-2 border border-2 rounded-bottom theme-bg-light rounded">
              {Array.from({ length: selectedSupplierRoomsLength }).map(
                (_, index) => (
                  <div
                    key={index}
                    className="px-2 py-1 rounded border fs-14"
                    style={
                      matchStatus[index]?.status
                        ? {
                            backgroundColor: "green",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "red",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => {
                      !matchStatus[index]?.status
                        ? onAddRoomMatch(
                            selectedSupplierRooms[index],
                            inetRooms[index]
                          )
                        : onRemoveRoomMatch(inetRooms[index]);
                    }}
                    title={
                      matchStatus[index]?.status
                        ? "Eşleşmeyi Kaldır"
                        : "Eşleştir"
                    }
                  >
                    <i className="ri-arrow-left-right-line fw-semibold text-white"></i>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <CustomDragDropBox
          draggableListTitle="Tedarikçi Oda Listesi"
          sortableListTitle="Tedarikçi Seçilen Odalar"
          draggableItems={supplierRooms}
          sortableItems={selectedSupplierRooms}
          setDraggableItems={handleChangeSupplierRooms}
          setSortableItems={handleChangeSelectedSupplierRooms}
          checkItemIsRemovable={checkItemIsMatched}
        />
      </div>
    </div>
  );
};

export default RoomMatchList;
