import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AnsweredCallsInitialState } from "slices/reports/answeredCalls/reducer";
import { getAnsweredCalls } from "slices/reports/answeredCalls/thunk";

const useAnsweredCalls = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { AnsweredCalls: AnsweredCallsInitialState }) => state.AnsweredCalls
  );

  const params = {
    date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    dispatch(getAnsweredCalls(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useAnsweredCalls;
