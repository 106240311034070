import { CalculatePriceForHotelRoomCard } from "types/reservation";
import ImageSlider from "./ImageSlider";
import RoomInfo from "./RoomInfo";
import ConceptPrices from "./ConceptPrices";

interface RoomCardProps extends CalculatePriceForHotelRoomCard {}

const RoomCard = ({
  oda_id,
  oda_adi,
  oda_aciklama,
  oda_genisligi,
  resimler,
  gostermelik_indirim_orani,
  kullanilabilir_ozel_indirimler,
  secilen_ozel_indirim,
  yetiskin_sayisi,
  cocuk_sayisi,
  giris_tarihi,
  cikis_tarihi,
  toplam_gece,
  minimum_gece_durum,
  banka_taksit,
  iptal_sigortasi_durum,
  iptal_sigortasi_gun,
  odeme_sekli,
}: RoomCardProps) => {
  return (
    <div
      className="border border-2 rounded pe-2 pb-2"
      style={{
        display: "grid",
        gridTemplateColumns: "300px 1fr",
        gap: "1rem",
        backgroundColor: "#f8fafc",
      }}
    >
      <ImageSlider images={resimler} />
      <div className="d-flex flex-column gap-1">
        <RoomInfo
          oda_adi={oda_adi}
          oda_aciklama={oda_aciklama}
          oda_genisligi={oda_genisligi}
          gostermelik_indirim_orani={gostermelik_indirim_orani}
          kullanilabilir_ozel_indirimler={kullanilabilir_ozel_indirimler}
          secilen_ozel_indirim={secilen_ozel_indirim}
          yetiskin_sayisi={yetiskin_sayisi}
          cocuk_sayisi={cocuk_sayisi}
          giris_tarihi={giris_tarihi}
          cikis_tarihi={cikis_tarihi}
          toplam_gece={toplam_gece}
          minimum_gece_durum={minimum_gece_durum}
          banka_taksit={banka_taksit}
        />
        <ConceptPrices
          oda_id={oda_id}
          oda_adi={oda_adi}
          giris_tarihi={giris_tarihi}
          iptal_sigortasi_durum={iptal_sigortasi_durum}
          iptal_sigortasi_gun={iptal_sigortasi_gun}
          odeme_sekli={odeme_sekli}
          banka_taksit={banka_taksit}
        />
      </div>
    </div>
  );
};

export default RoomCard;
