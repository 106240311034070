import { useState } from "react";
import { Tooltip } from "reactstrap";

interface CustomTooltipProps {
  target: string;
  title?: string;
  message: string;
}

const CustomTooltip = ({ target, title, message }: CustomTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <i
        id={target}
        className="ri-information-fill fs-15 ms-1 p-2 ps-0 theme-text-primary"
        style={{ cursor: "pointer" }}
      ></i>
      <Tooltip
        autohide={true}
        flip={true}
        isOpen={tooltipOpen}
        target={target}
        toggle={toggle}
        placement="bottom-start"
      >
        <div className="text-start">
          <p className="fw-bold">{title}</p>
          {message.split("\n").map((msg, index) => (
            <p key={index} className="fs-13">
              {msg}
            </p>
          ))}
        </div>
      </Tooltip>
    </>
  );
};

export default CustomTooltip;
