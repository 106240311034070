import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  setIsLoading,
  setError,
  handleSaveUserResponse,
  setChangeUserStatusLoading,
  handleChangeUserStatus,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_USERS, CHANGE_USER_STATUS } from "helpers/url_helper";
import { Dispatch } from "redux";

interface GetUsersParams {
  page: number;
  per_page: number;
  search: string;
}

interface ChangeUserStatusParams {
  statu: string;
  id: number;
}

type SuccessCallback = () => void;

export const getUsers =
  (params: GetUsersParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_USERS, params);
      
      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveUserResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const changeUserStatus =
  (params: ChangeUserStatusParams, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeUserStatusLoading(true));

      await axios.post(CHANGE_USER_STATUS, params);

      dispatch(handleChangeUserStatus({ id: params.id, status: params.statu }));

      const successMessage =
        params.statu === "1"
          ? "Kullanıcı başarıyla aktifleştirildi."
          : "Kullanıcı başarıyla pasifleştirildi.";
      dispatch(setToastSuccess(successMessage));

      successCallback();
    } catch (error) {
      dispatch(setToastError(error));
    } finally {
      dispatch(setChangeUserStatusLoading(false));
    }
  };
