import { TABLE_COLORS } from "utils/tableColors";

interface Member {
  id: number;
  ad_soyad: string;
  kapida_toplam_satis: string;
  toplam_satis: string;
  toplam_oda_sayisi: number;
}

const useHandleTable = (members: Member[], avgRoomSalesOfGroup: number) => {

  const tableData = members.map((member, index) => {
    let rowColor = "";
    if (member.toplam_oda_sayisi > avgRoomSalesOfGroup) {
      rowColor = TABLE_COLORS.green;
    } else {
      rowColor = TABLE_COLORS.red;
    }

    return {
      order: index + 1,
      id: member.id,
      ad_soyad: member.ad_soyad,
      kapida_toplam_satis: member.kapida_toplam_satis,
      toplam_satis: member.toplam_satis,
      toplam_oda_sayisi: member.toplam_oda_sayisi,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "order",
      enableColumnFilter: false,
    },
    {
      header: "Ad Soyad",
      accessorKey: "ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Satış Tutarı",
      accessorKey: "kapida_toplam_satis",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tutarı",
      accessorKey: "toplam_satis",
      enableColumnFilter: false,
    },
    {
      header: "Oda Sayısı",
      accessorKey: "toplam_oda_sayisi",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
