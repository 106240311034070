import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Select from "react-select";
import {
  ChildSpecialAgeInformationFormErrors,
  ChildSpecialAgeInformationState,
} from "types/PricesQuotas";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { NIGHT_COUNT } from "enums";
import { FormGroup, Input } from "reactstrap";
import { handleChangeChildSpecialAgeInformationInput } from "slices/pricesAndQuota/childSpecialAgeInformation/reducer";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
interface ChildSpecialAgeSelectProps {
  childSpecialAgeInformationState: ChildSpecialAgeInformationState;
  nightCountOptions: {
    value: NIGHT_COUNT;
    label: string;
  }[];
  validateFormChange: (values: ChildSpecialAgeInformationState) => void;
  formErrors: ChildSpecialAgeInformationFormErrors;
}
const ChildSpecialAgeInformationFields = ({
  childSpecialAgeInformationState,
  nightCountOptions,
  validateFormChange,
  formErrors,
}: ChildSpecialAgeSelectProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { onDayCreate } = useSpecialDaysForFlatpickr();
  return (
    <>
      <CustomFormFieldContainer
        label="Yaş Aralığı İsmi Giriniz."
        error={formErrors.ozel_yas_ismi}
      >
        <Input
          type="text"
          autoCorrect="on"
          placeholder="Yaş Aralığı İsmi Giriniz."
          value={childSpecialAgeInformationState.ozel_yas_ismi}
          onChange={(e) => {
            dispatch(
              handleChangeChildSpecialAgeInformationInput({
                ozel_yas_ismi: e.target.value,
              })
            );
            validateFormChange({
              ...childSpecialAgeInformationState,
              ozel_yas_ismi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Gece Seçiniz." error={formErrors.gun}>
        <Select
          placeholder="Gece Seçiniz."
          options={nightCountOptions}
          value={
            nightCountOptions.find(
              (option) => option.value === childSpecialAgeInformationState.gun
            ) || null
          }
          onChange={(selectedOption: any) => {
            dispatch(
              handleChangeChildSpecialAgeInformationInput({
                gun: selectedOption.value,
              })
            );

            validateFormChange({
              ...childSpecialAgeInformationState,
              gun: selectedOption.value,
            });
          }}
        ></Select>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Giriş Gününde Uygula">
        <FormGroup switch>
          <Input
            type="switch"
            checked={
              childSpecialAgeInformationState.cocuk_ozel_yas_giris_gunu === "1"
            }
            onChange={(e) => {
              dispatch(
                handleChangeChildSpecialAgeInformationInput({
                  cocuk_ozel_yas_giris_gunu: e.target.checked ? "1" : "0",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Satış Tarihi Seçimi">
        <FormGroup switch>
          <Input
            type="switch"
            checked={childSpecialAgeInformationState.satis_tarihi_secim === "1"}
            onChange={(e) => {
              dispatch(
                handleChangeChildSpecialAgeInformationInput({
                  satis_tarihi_secim: e.target.checked ? "1" : "0",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Satış Tarih Aralığı">
        <Flatpickr
          placeholder="Satış Tarih Aralığı Seçiniz."
          className="form-control"
          value={[
            childSpecialAgeInformationState.satis_tarihi_baslangic,
            childSpecialAgeInformationState.satis_tarihi_bitis,
          ]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");
              dispatch(
                handleChangeChildSpecialAgeInformationInput({
                  satis_tarihi_baslangic: stringStartDate,
                  satis_tarihi_bitis: stringEndDate,
                })
              );
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ChildSpecialAgeInformationFields;
