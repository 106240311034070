import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
    handleGroupToUpdate,
    setIsLoading,
    setError,
    setUpdateGroupLoading,
    setUpdateGroupError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { UPDATE_GROUP, GET_GROUP } from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getGroupToUpdate = (group_id: number, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_GROUP}?grup_id=${group_id}`);

    successCallback();
    dispatch(handleGroupToUpdate(response.data || response));
  } catch (error) {
    dispatch(setError("Hata! Grup bulunamadı."));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateGroup = (payload: FormData, navigate: (path: string) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(setUpdateGroupLoading(true));
    await axios.post(UPDATE_GROUP, payload);

    dispatch(setToastSuccess("Grup güncellendi."));
    navigate("/gruplari-listele");
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setUpdateGroupError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setUpdateGroupLoading(false));
  }
};
