import { Label } from "reactstrap";

import { CustomTooltip } from "Components/Custom/UI";
import { TOOLTIPS } from "utils/tooltips";
import parse from "html-react-parser";

interface CustomFieldContainerProps {
  label: string;
  tooltipId?: string;
  error?: string;
  children: React.ReactNode;
  orientation?: "horizontal" | "vertical";
  required?: boolean | string;
  description?: string;
  divider?: boolean;
}

const CustomFieldContainer = ({
  label,
  tooltipId = "",
  children,
  error,
  orientation = "horizontal",
  required = false,
  description,
  divider,
}: CustomFieldContainerProps) => {
  const tooltip = TOOLTIPS[tooltipId] || "";

  const requiredText = typeof required === "string" ? ` *(${required})` : "*";

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: orientation === "horizontal" ? "1fr 1fr" : "1fr",
        justifyContent: "space-between",
        alignItems: "start",
        gap: orientation === "horizontal" ? "3rem" : "1rem",
        paddingBottom: divider ? "1rem" : "0",
        borderBottom: divider ? "1px solid #e9ecef" : "none",
      }}
    >
      <div>
        <Label className="form-label text-nowrap m-0">
          {label}
          {required && (
            <span className="theme-text-danger fs-14">{requiredText}</span>
          )}
        </Label>
        {tooltip && (
          <CustomTooltip
            target={TOOLTIPS[tooltipId].target}
            title={TOOLTIPS[tooltipId].title}
            message={TOOLTIPS[tooltipId].message}
          />
        )}
        {description && (
          <div className="fs-12 text-muted mt-1 fw-medium">
            {parse(description)}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <div
          style={{
            width: orientation === "horizontal" ? "400px" : "100%",
          }}
        >
          <div
            className={`${error ? "border border-danger rounded" : "rounded"}`}
          >
            {children}
          </div>
          {error && <p className="theme-text-danger fs-12 mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default CustomFieldContainer;
