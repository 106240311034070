import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { MOBILE_DISCOUNTS_DESCRIPTIONS } from "utils/pageDescriptions";
import MobileDiscountsForm from "./MobileDiscountsForm";
import MobileDiscountsTable from "./MobileDiscountsTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./MobileDiscountsForm/RoomSelector";

interface MobilelDiscountsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const MobileDiscountsPage = (props: MobilelDiscountsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={MOBILE_DISCOUNTS_DESCRIPTIONS}
      roomSelector={<RoomSelector {...props} />}
      isLoading={isLoading}
    >
      <MobileDiscountsForm {...props} />
      <MobileDiscountsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(MobileDiscountsPage);
