import { useEffect } from "react";
import { Room } from "types/PricesQuotas";
import HeaderContainer from "../../CommonContainers/HeaderContainer";

interface AvailabilityEntryTableHeaderProps {
  room: Room;
  onLoad: (key: number, value: boolean) => void;
}

const AvailabilityEntryTableHeader = ({
  room,
  onLoad,
}: AvailabilityEntryTableHeaderProps) => {
  useEffect(() => {
    room.pansiyonlar.forEach((concept) => {
      onLoad(concept.id, false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!room) {
    return null;
  }
  return <HeaderContainer concepts={room.pansiyonlar} />;
};

export default AvailabilityEntryTableHeader;
