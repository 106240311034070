import { useSelector } from "react-redux";

import { HotelSalesInitialState } from "slices/reports/hotelSales/reducer";

const useHandleTable = () => {
  const { hotelSales } = useSelector(
    (state: { HotelSales: HotelSalesInitialState }) => state.HotelSales
  );

  const tableData = hotelSales?.map((hotelSale) => ({
    "Otel Adı": hotelSale.otel_adi,
    "Sorumlu Adı": hotelSale.sorumlu_adi,
    "Otel İli": hotelSale.il_adi,
    "Otel İlçesi": hotelSale.ilce_adi,
    "Otel Sınıfı": hotelSale.tesis_sinif,
    Operatör: hotelSale.otel_operator,
    "Karşılama Kodu": hotelSale.karsilama_kodu,
    "Toplam Oda": hotelSale.toplam_oda,
    "Toplam Tutar": hotelSale.toplam_satis,
    "Toplam İptal Tutarı": hotelSale.toplam_iptal_satis,
    "Toplam Kapıda Tutarı": hotelSale.toplam_kapida_satis,
    "Toplam Kaporalı Tutarı": hotelSale.toplam_kapora_satis,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Sorumlu Adı",
      accessorKey: "Sorumlu Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel İli",
      accessorKey: "Otel İli",
      enableColumnFilter: false,
    },
    {
      header: "Otel İlçesi",
      accessorKey: "Otel İlçesi",
      enableColumnFilter: false,
    },
    {
      header: "Otel Sınıfı",
      accessorKey: "Otel Sınıfı",
      enableColumnFilter: false,
    },
    {
      header: "Operatör",
      accessorKey: "Operatör",
      enableColumnFilter: false,
    },
    {
      header: "Karşılama Kodu",
      accessorKey: "Karşılama Kodu",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Oda",
      accessorKey: "Toplam Oda",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Tutar",
      accessorKey: "Toplam Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Toplam İptal Tutarı",
      accessorKey: "Toplam İptal Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Kapıda Tutarı",
      accessorKey: "Toplam Kapıda Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Kaporalı Tutarı",
      accessorKey: "Toplam Kaporalı Tutarı",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
