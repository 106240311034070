import AddNewSpecialDayButton from "./AddNewSpecialDayButton";
import SpecialDayCategorySelector from "./SpecialDayCategorySelector";
import SpecialDayDateSelector from "./SpecialDayDateSelector";
import SpecialDayDescription from "./SpecialDayDescription";
import SpecialDayImageSelector from "./SpecialDayImageSelector";
import SpecialDayTitleAndSingerName from "./SpecialDayTitleAndSingerName";
import UpdateSpecialDayButton from "./UpdateSpecialDayButton";
import useHandleForm from "./useHandleForm";
import { CustomFormContainer } from "Components/Custom/UI";

const FacilitySpecialDaysForm = () => {
  const {
    specialDay,
    specialDaysCategoriesOptions,
    formErrors,
    onAddNewSpecialDay,
    onUpdateSpecialDay,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addSpecialDayLoading,
    updateSpecialDayLoading,
  } = useHandleForm();

  const { id } = specialDay;
  return (
    <CustomFormContainer
      title={id ? "Özel Günü Güncelle" : "Özel Günü Ekle"}
      xl={12}
      lg={12}
    >
      <SpecialDayCategorySelector
        values={specialDay}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        specialDaysCategoriesOptions={specialDaysCategoriesOptions}
      />
      <SpecialDayTitleAndSingerName
        values={specialDay}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <SpecialDayDateSelector
        values={specialDay}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <SpecialDayDescription
        values={specialDay}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <SpecialDayImageSelector
        values={specialDay}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {id ? (
        <UpdateSpecialDayButton
          values={specialDay}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          onUpdateSpecialDay={onUpdateSpecialDay}
          isLoading={updateSpecialDayLoading}
        />
      ) : (
        <AddNewSpecialDayButton
          values={specialDay}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          onAddNewSpecialDay={onAddNewSpecialDay}
          isLoading={addSpecialDayLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default FacilitySpecialDaysForm;
