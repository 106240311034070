import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationTableForm from "./ReservationTableForm";
import ReservationPaymentForm from "./ReservationPaymentForm";
import ReservationCollectionForm from "./ReservationCollectionForm";

interface InformationTechnologiesPageProps {
  isLoading: boolean;
}

const InformationTechnologiesPage = ({ isLoading }: InformationTechnologiesPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ReservationTableForm />
        <ReservationPaymentForm />
        <ReservationCollectionForm />
      </div>
    </>
  );
};

export default InformationTechnologiesPage;
