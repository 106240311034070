import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { TransferReservationsInitialState } from "slices/userOperations/transferReservations/reducer";
import { transferReservations } from "slices/userOperations/transferReservations/thunk";
import { TransferReservationPayload } from "types/userOperations";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices } = useSelector(
    (state: { TransferReservations: TransferReservationsInitialState }) =>
      state.TransferReservations
  );

  const [formState, setFormState] = useState<TransferReservationPayload>({
    rez_kodu: "",
    kullanici: 0,
  });

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeApproveCancel = () => {
    const params = {
      rez_kodu: formState.rez_kodu,
      kullanici: formState.kullanici,
    };
    
    dispatch(transferReservations(params));
  };

  const handleResetForm = () => {
    setFormState({
      rez_kodu: "",
      kullanici: 0,
    });
  };

  const personnelOptions = formChoices.map((item) => ({
    value: item.id,
    label: item.ad_soyad,
  }));

  return {
    formState,
    personnelOptions,
    handleFormChange,
    handleSave: handleChangeApproveCancel,
    handleClearForm: handleResetForm,
  };
};

export default useHandleForm;
