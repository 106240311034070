import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import PlaceToVisitTitle from "./PlaceToVisitTitle";
import PlaceToVisitContent from "./PlaceToVisitContent";
import PlaceToVisitImage from "./PlaceToVisitImage";
import AddPlaceToVisitButton from "./AddPlaceToVisitButton";

interface AddPlaceToVisitFormProps {
  isLoading: boolean;
}

const AddPlaceToVisitForm = ({ isLoading }: AddPlaceToVisitFormProps) => {
  const {
    placeToVisit,
    addNewPlaceToVisitRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer>
      <PlaceToVisitTitle
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <PlaceToVisitContent
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <PlaceToVisitImage
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddPlaceToVisitButton
        values={placeToVisit}
        requestPayload={addNewPlaceToVisitRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default AddPlaceToVisitForm;
