import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
  CustomSelectBox,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";

interface MatchAdAndHotelFormProps {
  isLoading: boolean;
  matchAdHotelLoading: boolean;
}

const MatchAdAndHotelForm = ({
  isLoading,
  matchAdHotelLoading,
}: MatchAdAndHotelFormProps) => {
  const {
    adsOptions,
    hotelOptions,
    formValues,
    handleFormChange,
    onAddAdAndHotelMatch,
  } = useHandleForm();

  return (
    <CustomFormContainer
      title="Reklam ve Otel Eşleştirme"
      isLoading={isLoading}
    >
      <div className="d-flex gap-4 flex-wrap align-items-end">
        <CustomFormFieldContainer label="Oteller" orientation="vertical">
          <CustomSelectBox
            inputId="select-hotel-input"
            options={hotelOptions}
            onSelect={(value) => {
              handleFormChange("otel_id", Number(value));
            }}
            placeholder="Otel Seçiniz..."
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Reklamlar" orientation="vertical">
          <CustomSelectBox
            inputId="select-ads-input"
            options={adsOptions}
            onSelect={(value) => {
              handleFormChange("reklam_grup_id", Number(value));
            }}
            placeholder="Reklam Seçiniz..."
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="" orientation="vertical">
          <div className="d-flex gap-2">
            <CustomButton
              variant="success"
              onClick={onAddAdAndHotelMatch}
              isLoading={matchAdHotelLoading}
              disabled={
                formValues.otel_id === 0 || formValues.reklam_grup_id === 0
              }
            >
              Kaydet
            </CustomButton>
          </div>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default MatchAdAndHotelForm;
