import { CustomRowSpan } from "Components/Custom/UI";
import { useSelector } from "react-redux";

import { UncancellableRoomRateState } from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { uncancellableRoomRates: rate } = useSelector(
    (state: any) => state.UncancellableRoomRates
  );

  const tableData: UncancellableRoomRateState[] = [
    {
      indirim_orani: rate.iptal_oran,
      aktiflik_durum: rate.iptal_odeme === "checked" ? "1" : "0",
      tum_odalara_uygulama_durum: "1",
    },
  ];

  const tableColumns = [
    {
      header: "Durum",
      cell: (cell: any) => {
        const value = cell.getValue();
        return <CustomRowSpan condition={value === "1"} isStatus />;
      },
      accessorKey: "aktiflik_durum",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Adı",
      cell: () => <span>İptal Edilemez Oda İndirimi</span>,
      accessorKey: "indirim_adi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "indirim_orani",
      cell: (cell: any) => {
        const value = cell.getValue();
        return <span className="fw-medium">{value ? `${value}%` : "-"}</span>;
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};
export default useHandleTable;
