import { useSelector } from "react-redux";
import { UpdateEarlyReservationDefinitionRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { AddEarlyReservationDefinitionInitialState } from "slices/definitions/addEarlyReservationDefinition/reducer";

const useHandleForm = () => {
  const {
    earlyReservationDefinition,
    addEarlyReservationDefinitionFormErrors,
    updateDefinitionLoading,
  } = useSelector(
    (state: {
      AddEarlyReservationDefinition: AddEarlyReservationDefinitionInitialState;
    }) => state.AddEarlyReservationDefinition
  );

  const updateEarlyReservationDefinitionRequestPayload: UpdateEarlyReservationDefinitionRequestPayload =
    {
      donem: earlyReservationDefinition.donem,
      tarih: earlyReservationDefinition.tarih,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    earlyReservationDefinition,
    isLoading: updateDefinitionLoading,
    updateEarlyReservationDefinitionRequestPayload,
    formErrors: addEarlyReservationDefinitionFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
