import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { OrderThemeHotelsInitialState } from "slices/themes/orderThemeHotels/reducer";

import {
  getOrderThemeHotels,
  getThemes,
} from "slices/themes/orderThemeHotels/thunk";

const useOrderThemeHotels = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { themes, hotels, selectedTheme, isLoading, error } = useSelector(
    (state: { OrderThemeHotels: OrderThemeHotelsInitialState }) =>
      state.OrderThemeHotels
  );

  const handleSelectTheme = (id: number) => {
    dispatch(getOrderThemeHotels({ tema_id: id }));
  };

  useEffect(() => {
    dispatch(getThemes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themesOptions = themes.map((theme) => ({
    value: theme.tema_id,
    label: theme.baslik,
  }));


  return {
    hotels,
    selectedTheme,
    handleSelectTheme,
    themesOptions,
    isLoading,
    error,
  };
};

export default useOrderThemeHotels;
