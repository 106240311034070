import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { CommercialWarningCardData } from "types/dashboard";

interface CommercialWarningCardProps {
  values: CommercialWarningCardData;
}

const CommercialWarningCard = ({ values }: CommercialWarningCardProps) => {
  const title_info = (
    <>
      <i className="ri-error-warning-fill" />
    </>
  );
  const textStyle: React.CSSProperties = {
    fontSize: "16px",
  };
  return (
    <>
      <CustomDashboardCard
        xl={4}
        title="Reklam Bilgilendirme"
        color="info"
        titleInfo={title_info}
        titleInfoLocation="left"
      >
        <div style={textStyle}>{values.message}</div>
      </CustomDashboardCard>
    </>
  );
};

export default CommercialWarningCard;
