import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ListWebSitesTableProps {
  isLoading: boolean;
}
const ListWebSitesTable = ({
  isLoading,
}: ListWebSitesTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();

  return (
    <CustomReactTable
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
      legend={legend}
    />
  );
};

export default ListWebSitesTable;
