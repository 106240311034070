import { CustomButton } from "Components/Custom/UI";
import { Input, Label, Modal, ModalHeader } from "reactstrap";

interface KapidaPaymentModalProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  kapidaOdemePrice: number;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  isOpen: boolean;
  toggle: () => void;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const KapidaPaymentModal = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  kapidaOdemePrice,
  onClick,
  isOpen,
  toggle,
  checked,
  setChecked,
}: KapidaPaymentModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <p className="fs-16">
          KAPIDA ÖDEME YERİNE MİSAFİRİ ŞİMDİ ÖDEMEYE İKNA EDELİM
        </p>
      </ModalHeader>
      <div className="d-flex flex-column gap-2 p-3 border-bottom theme-bg-light">
        <p className="fs-16 fw-medium">1. Ödemeniz nasıl olacaktı? </p>
        <p className="fs-16 fw-medium">
          2. Ödemeyi şu an yaparsanız kredi kartına taksit yapabilirim, otelde
          ödeme yaparsanız yalnızca tek çekim veya nakit olarak yapabilirsiniz.
        </p>
      </div>
      <div className="d-flex flex-column gap-2 p-3">
        <Label
          check
          className="d-flex align-items-center gap-2"
          style={{ cursor: "pointer" }}
        >
          <Input
            type="checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span className="fs-14 fw-semibold">
            2 bilgiyide misafire ilettim ancak ödemeyi alamadım.
          </span>
        </Label>
        <div className="w-100 text-end">
          <CustomButton
            variant="primary"
            disabled={!checked}
            onClick={() => {
              onClick({
                oda_id,
                oda_adi,
                pansiyon_id,
                pansiyon_adi,
                odeme_tipi: "kapida",
                fiyat: kapidaOdemePrice,
              });
              toggle();
            }}
          >
            Devam Et
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default KapidaPaymentModal;
