import { UpdateFacilityState } from "types/facility";
import { FilePondFile } from "filepond";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandlePayload = (
  requestPayload: FormData,
  values: UpdateFacilityState
) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const getPayload = () => {
    requestPayload.append("otel_id", hotel.otel_id.toString());

    Object.entries(values).forEach(([key, value]) => {
      if (key === "resim") {
        if (value) {
          const file = value as FilePondFile;
          requestPayload.append(key, file.file, file.file.name);
        } else {
          requestPayload.append(key, "");
        }
      } else if (
        ["otel_fiyat_id", "acante2_id", "resim_onizlemesi"].includes(key)
      ) {
      } else if (key === "otel_odasayisi") {
        requestPayload.append("otel_oda_sayisi", value.toString());
      } else if (value instanceof Array) {
        const newValue: {
          [key: string]: boolean;
        } = {};
        value.forEach((item) => {
          newValue[item] = true;
        });
        requestPayload.append(key, JSON.stringify(newValue));
      } else {
        requestPayload.append(key, value);
      }
    });

    return requestPayload;
  };

  const { otel_id } = hotel;

  return { getPayload, otel_id };
};

export default useHandlePayload;
