import { CustomFormContainer } from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
import {
  CreateRoomAddedRoom,
  EnterReservationFormValues,
} from "types/reservation";

interface KapidaPaymentFormProps {
  formValues: EnterReservationFormValues;
  addedRoomsInfo: CreateRoomAddedRoom[];
}

const KapidaPaymentForm = ({
  formValues,
  addedRoomsInfo,
}: KapidaPaymentFormProps) => {
  const totalAmount = addedRoomsInfo.reduce((acc, room) => acc + room.fiyat, 0);

  return (
    <CustomFormContainer title="Kapıda Ödeme Bilgileri">
      <div className="d-flex gap-5 align-items-center">
        <Label check>
          <Input
            type="checkbox"
            checked={formValues.odeme_tipi === "kapida"}
            disabled
          />
          <span className="fs-16 ms-2">Kapıda Ödeme</span>
        </Label>
        {addedRoomsInfo.length ? (
          <div className="d-flex gap-3 align-items-center">
            <Label className="text-nowrap m-0">Kapıda Ödeme Tutarı</Label>
            <Input
              type="number"
              value={Math.ceil(totalAmount ?? 0)}
              disabled
            />{" "}
            TL
          </div>
        ) : (
          <></>
        )}
      </div>
      <Label check>
        <Input type="checkbox" />
        <span className="fs-16 ms-2">İptal Sigortası</span>
      </Label>
    </CustomFormContainer>
  );
};

export default KapidaPaymentForm;
