import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import {
  AddLocationInitialState,
  handleChangeInput,
  handleSaveSelectedLocationOptions,
} from "slices/definitions/addLocation/reducer";
import { getLocations } from "slices/definitions/addLocation/thunk";
import { AddLocationState, Location } from "types/definitions";

interface Option {
  value: number;
  label: string;
}

interface LocationSelectProps {
  index: number;
  values: {
    key: number;
    value: Location[];
  };
  formValues: AddLocationState;
  validateFormChange: (values: AddLocationState) => void;
  handleSelectedLocation: (selected: Location[], index: number) => void;
}

const LocationSelect = ({
  index,
  values,
  formValues,
  validateFormChange,
  handleSelectedLocation,
}: LocationSelectProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const locationsOptions = values.value.map((location) => ({
    value: location.id,
    label: location.lokasyon,
  }));

  const { selectedLocationOptions } = useSelector(
    (state: { AddLocation: AddLocationInitialState }) => state.AddLocation
  );

  return (
    <Select
      value={selectedLocationOptions[index]}
      onChange={(selected: Option) => {
        dispatch(
          getLocations({
            parent_id: selected.value,
            successCallback: (response: Location[]) => {
              handleSelectedLocation(response, index);
            },
          })
        );
        dispatch(
          handleSaveSelectedLocationOptions({
            index,
            value: selected,
          })
        );

        dispatch(
          handleChangeInput({
            ...values,
            parent_id: selected.value,
          })
        );

        validateFormChange({
          ...formValues,
          parent_id: selected.value,
        });
      }}
      options={locationsOptions}
      placeholder="Üst Bölge"
    />
  );
};

export default LocationSelect;
