import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ApplicableDays from "./ApplicableDays";
import HourRangeSelector from "./HourRangeSelector";
import DiscountRateAndSwitches from "./DiscountRateAndSwitches";
import SelectFields from "./SelectFields";
import AddNewHourlyDiscountButton from "./AddNewHourlyDiscountButton";
import UpdateHourlyDiscountButton from "./UpdateHourlyDiscountButton";

interface HourlyDiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const HourlyDiscountsForm = (props: HourlyDiscountsFormProps) => {
  const {
    hourlyDiscount,
    conceptsOptions,
    paymentMethodOptions,
    addNewHourlyDiscountRequestPayload,
    updateHourlyDiscountRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addDiscountLoading,
    updateDiscountLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <ApplicableDays
        values={hourlyDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <HourRangeSelector
        values={hourlyDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <DiscountRateAndSwitches
        values={hourlyDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <SelectFields
        values={hourlyDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        conceptsOptions={conceptsOptions}
        paymentMethodOptions={paymentMethodOptions}
      />
      {hourlyDiscount.id ? (
        <UpdateHourlyDiscountButton
          values={hourlyDiscount}
          requestPayload={updateHourlyDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateDiscountLoading}
        />
      ) : (
        <AddNewHourlyDiscountButton
          values={hourlyDiscount}
          requestPayload={addNewHourlyDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addDiscountLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default HourlyDiscountsForm;
