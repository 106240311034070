import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STOPS } from "enums";
import { PriceType } from "types/PricesQuotas";
import { handleSaveColumnsInputValues } from "slices/pricesAndQuota/advancedEntry/reducer";

interface InputValues {
  [key: string]: number;
}

const addDefaultInputValues = (priceTypes: PriceType[]) => {
  const newInputValues: InputValues = {};
  priceTypes.forEach((priceType) => {
    newInputValues[priceType.fiyat_tipi] = 0;
  });
  newInputValues["cta"] = 0;
  newInputValues["ctd"] = 0;
  newInputValues["musaitlik"] = STOPS.YOK;
  return newInputValues;
};

const useHandleForm = (
  priceTypes: PriceType[],
  paymentMethods: string[],
  paymentMethod: string
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { columnsInputValues } = useSelector(
    (state: any) => state.AdvancedEntry
  );

  const [isReady, setIsReady] = useState(false);

  const [formInputValues, setFormInputValues] = useState<InputValues>({});

  const handlePriceChange = (
    type: string,
    value: number,
    isBasePrice: boolean
  ) => {
    if (isBasePrice) {
      const newInputValues: InputValues = {};
      priceTypes.forEach((priceType) => {
        if (priceType.baz_fiyat) {
          newInputValues[priceType.fiyat_tipi] = Math.ceil(value);
        } else {
          newInputValues[priceType.fiyat_tipi] = Math.ceil(
            value * priceType.katsayi
          );
        }
      });

      setFormInputValues((prev) => ({
        ...prev,
        ...newInputValues,
      }));

      const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

      Object.keys(newInputValues).forEach((key) => {
        Object.keys(newValues).forEach((newKey) => {
          if (newKey.endsWith(key)) {
            newValues[newKey] = newInputValues[key];
          }
        });
      });

      const payload = {
        ...columnsInputValues,
        [paymentMethod]: newValues,
      };

      dispatch(handleSaveColumnsInputValues(payload));
    }

    if (!isBasePrice) {
      const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

      setFormInputValues((prev) => {
        return {
          ...prev,
          [type]: value,
        };
      });

      Object.keys(newValues).forEach((key) => {
        if (key.endsWith(type)) {
          newValues[key] = value;
        }
      });

      const newInputValues = {
        ...columnsInputValues,
        [paymentMethod]: newValues,
      };

      dispatch(handleSaveColumnsInputValues(newInputValues));
    }
  };

  const handleChange = (type: string, value: number) => {
    const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

    setFormInputValues((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });

    Object.keys(newValues).forEach((key) => {
      if (key.endsWith(type)) {
        newValues[key] = value;
      }
    });

    const newInputValues = {
      ...columnsInputValues,
      [paymentMethod]: newValues,
    };

    dispatch(handleSaveColumnsInputValues(newInputValues));
  };

  useEffect(() => {
    const newInputValues = addDefaultInputValues(priceTypes);
    setFormInputValues(newInputValues);

    setIsReady(true);
  }, [priceTypes, dispatch]);

  return { formInputValues, isReady, handleChange, handlePriceChange };
};

export default useHandleForm;
