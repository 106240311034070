import { createSlice } from "@reduxjs/toolkit";

import { CallFromGeneral } from "types/reports";

export interface CallsFromGeneralInitialState {
  callsFromGeneral: CallFromGeneral[];
  isLoading: boolean;
  error: string;
}

const initialState: CallsFromGeneralInitialState = {
  callsFromGeneral: [],
  isLoading: false,
  error: "",
};

const callsFromGeneralSlice = createSlice({
  name: "callsFromGeneral",
  initialState,
  reducers: {
    handleSaveCallsFromGeneral(state, action) {
      state.callsFromGeneral = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveCallsFromGeneral, setIsLoading, setError } =
  callsFromGeneralSlice.actions;

export default callsFromGeneralSlice.reducer;
