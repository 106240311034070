import CustomCollapse from "Components/Custom/UI/CustomCollapse/CustomCollapse";
import CustomSimpleTable from "Components/Custom/UI/CustomSimpleTable/CustomSimpleTable";
import { CrmDashboardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";
import useHandleTable from "./useHandleTable";

interface ExtensionListsProps {
  values: CrmDashboardData;
}

const ExtensionLists = ({ values }: ExtensionListsProps) => {
  const value = values.dahili_listesi;
  const processedGroups = Object.entries(value).map(([key, group]: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { tableData, tableColumns } = useHandleTable(group.uyeler);
    return {
      key,
      grup_adi: group.grup_adi,
      tableData,
      tableColumns,
    };
  });

  return (
    <>
      <CustomCollapse
        title="Dahili Listesi"
        bgColor={DASHBOARD_COLORS.purple}
        icon="ri-customer-service-fill"
      >
        <div className="d-flex flex-column gap-3">
          {processedGroups.map((group) => (
            <CustomCollapse
              key={group.key}
              title={group.grup_adi}
              bgColor={DASHBOARD_COLORS.orange}
              isBorder={false}
            >
              <CustomSimpleTable
                tableData={group.tableData}
                tableColumns={group.tableColumns}
              />
            </CustomCollapse>
          ))}
        </div>
      </CustomCollapse>
    </>
  );
};

export default ExtensionLists;
