import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { LoginInitialState } from "slices/auth/login/reducer";
import { handleChangeReservationFilterChoices, ReservationsInitialState } from "slices/reports/reservations/reducer";
import {
  getReservationFormChoices,
  getReservations,
} from "slices/reports/reservations/thunk";
import { ReservationFilterPayload } from "types/reports";

const useReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {  isLoading, filterReservations } = useSelector(
    (state: { Reservations: ReservationsInitialState }) => state.Reservations
  );
  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki, id } = user;

  useEffect(() => {
    const payload: ReservationFilterPayload = {
      tarih_turu: filterReservations.tarih_turu,
      otel_id: yetki === "otel" ? id : 0,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      end_date: filterReservations.end_date,
      acente_id: yetki === "acente" && "acente_user" ? id.toString() : "0",
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      inet_kodu: filterReservations.inet_kodu,
      musteri_adi: filterReservations.musteri_adi,
      otel_list: filterReservations.otel_list,
      per_page: filterReservations.per_page,
      page: filterReservations.page,
      search: filterReservations.search,
    };

    dispatch(getReservations(payload));
    dispatch(getReservationFormChoices());
    dispatch(handleChangeReservationFilterChoices(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useReservations;
