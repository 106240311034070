import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveHotelsWithoutCommentResponse,
  setIsLoading,
  setError,
  setAddCommentLoading,
  setaddCommentError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_HOTELS_WITHOUT_COMMENT } from "helpers/url_helper";
import { Dispatch } from "redux";

/*
    *Commented areas can be used for add comment button if needed
*/

interface GetHotelsWithoutCommentParams {
  page: number;
  per_page: number;
  search: string;
}

type SuccessCallback = () => void;

export const getHotelsWithoutComment =
  (params: GetHotelsWithoutCommentParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_HOTELS_WITHOUT_COMMENT, params);
      const res = (response as any).message ? response.data : response;

      console.log("getHotelsWithoutComment API response:", res);

      dispatch(handleSaveHotelsWithoutCommentResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

// export const approveComment =
//   (yorum_id: number, successCallback: SuccessCallback) =>
//   async (dispatch: Dispatch) => {
//     try {
//       dispatch(setApproveCommentLoading(true));

//       await axios.post(APPROVE_COMMENT, { yorum_id });
//       console.log("Yorum onaylandı");

//       dispatch(handleApproveComment(yorum_id));

//       dispatch(setToastSuccess("Yorum başarıyla onaylandı."));
//       successCallback();
//     } catch (error) {
//       dispatch(setToastError(error));
//     } finally {
//       dispatch(setApproveCommentLoading(false));
//     }
//   };
