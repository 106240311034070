import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveMoneyPointsResponse,
  setIsLoading,
  setError,
  setAddMoneyPointLoading,
  setAddMoneyPointError,
  resetAddMoneyPointFormState,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_MONEY_POINTS, ADD_MONEY_POINT } from "helpers/url_helper";

import { Dispatch } from "redux";

import {
  AddMoneyPointRequestPayload,
  MoneyPointsResponse,
} from "types/reports";

export const getMoneyPoints = (otel_id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_MONEY_POINTS}?otel_id=${otel_id}`);

    const data: MoneyPointsResponse = response.data || response;

    dispatch(handleSaveMoneyPointsResponse(data));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addMoneyPoint =
  (payload: AddMoneyPointRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAddMoneyPointLoading(true));
      await axios.post(ADD_MONEY_POINT, payload);

      dispatch(setToastSuccess("Para puan eklendi"));
      dispatch(resetAddMoneyPointFormState());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddMoneyPointError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddMoneyPointLoading(false));
    }
  };
