import UpdateWelcomeAndCalculationForm from "./UpdateWelcomeAndCalculationForm";
import WelcomeAndCalculationTable from "./WelcomeAndCalculationTable";

interface WelcomeAndCalculationPageProps {
  isLoading: boolean;
}

const WelcomeAndCalculationPage = ({
  isLoading,
}: WelcomeAndCalculationPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <UpdateWelcomeAndCalculationForm />
      <WelcomeAndCalculationTable isLoading={isLoading} />
    </div>
  );
};

export default WelcomeAndCalculationPage;
