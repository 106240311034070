import { CustomLoadingPage, CustomPageDescriptions } from "../UI";

interface PageContainerProps {
  children: React.ReactNode;
  descriptions: string[];
  roomSelector: React.ReactNode;
  isLoading: boolean;
}
const PageContainer = ({
  children,
  descriptions,
  roomSelector,
  isLoading,
}: PageContainerProps) => {
  const showDescriptions = descriptions.length > 0;
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-2">
        {showDescriptions && (
          <CustomPageDescriptions descriptions={descriptions} />
        )}
        {roomSelector}
        {children}
      </div>
    </>
  );
};

export default PageContainer;
