import ListGroupsTable from "./ListGroupsTable";

interface ListGroupsPageProps {
  isLoading: boolean;
}

const ListGroupsPage = ({ isLoading }: ListGroupsPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <ListGroupsTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListGroupsPage;
