import { useSelector } from "react-redux";
import { AddCertificateInitialState } from "slices/myWebSite/addCertificate/reducer";
import { CreateWidgetInitialState } from "slices/myWebSite/createWidget/reducer";

const useHandleForm = () => {
  const {
    widget,
    widgetOption,
    isLoading,
  } = useSelector(
    (state: { CreateWidget: CreateWidgetInitialState }) =>
      state.CreateWidget
  );

  const addCertificateRequestPayload = new FormData();

  // console.log("addCertificateRequestPayload in form hook :", addCertificateRequestPayload)

  const widgetRequestPayload = new FormData();

  return {
    values: widget,
    formChoices: widgetOption,
    isLoading,
    requestPayload: widgetRequestPayload,
  };
};

export default useHandleForm;
