import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelectors from "./DateSelectors";
import ApplicableDays from "./ApplicableDays";
import Rates from "./Rates";
import MinNights from "./MinNights";
import PayingToHotel from "./PayingToHotel";
import SelectFields from "./SelectFields";
import UpdateEarlyReservationDiscountButton from "./UpdateEarlyReservationDiscountButton";
import AddNewEarlyReservationDiscountButton from "./AddNewEarlyReservationDiscountButton";

interface EarlyReservationDiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const EarlyReservationDiscountsForm = (
  props: EarlyReservationDiscountsFormProps
) => {
  const {
    earlyReservationDiscount,
    conceptsOptions,
    paymentMethodOptions,
    addNewEarlyReservationDiscountRequestPayload,
    updateEarlyReservationDiscountRequestPayload,
    formErrors: earlyReservationDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addDiscountLoading,
    updateDiscountLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <DateSelectors
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
      />
      <ApplicableDays
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
      />
      <Rates
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
      />
      <MinNights
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
      />
      <PayingToHotel
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
      />
      <SelectFields
        values={earlyReservationDiscount}
        formErrors={earlyReservationDiscountFormErrors}
        validateFormChange={validateFormChange}
        conceptsOptions={conceptsOptions}
        paymentMethodOptions={paymentMethodOptions}
      />
      {earlyReservationDiscount.id ? (
        <UpdateEarlyReservationDiscountButton
          values={earlyReservationDiscount}
          requestPayload={updateEarlyReservationDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateDiscountLoading}
        />
      ) : (
        <AddNewEarlyReservationDiscountButton
          values={earlyReservationDiscount}
          requestPayload={addNewEarlyReservationDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addDiscountLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default EarlyReservationDiscountsForm;
