import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  HotelContractsInitialState,
  handleSaveHotelContractStateForEdit,
} from "slices/financeAndAccounting/hotelContracts/reducer";

import {
  getHotelContracts,
  deleteHotelContract,
  approveHotelContract,
} from "slices/financeAndAccounting/hotelContracts/thunk";

import { HotelContract, HotelContractState } from "types/financeAndAccounting";

const useHandleTable = () => {
  const { hotelContracts } = useSelector(
    (state: { HotelContracts: HotelContractsInitialState }) =>
      state.HotelContracts
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetHotelContracts = (otel_id: number) => {
    const payload = {
      page: 1,
      per_page: 10,
      search: "",
      otel_id,
    };

    dispatch(getHotelContracts(payload));
  };

  const handleClickEdit = (hotelContract: HotelContract) => {
    const editingContract: HotelContractState = {
      otel_id: hotelContract.otel_id,
      kontrat_id: hotelContract.id,
      dosya_adi: hotelContract.dosya_adi,
      b_tarih: hotelContract.baslangic_tarihi,
      s_tarih: hotelContract.bitis_tarihi,
      kontrol_durum: hotelContract.kontrol_durum,
      belge: null,
    };
    dispatch(handleSaveHotelContractStateForEdit(editingContract));
    window.scrollTo(0, 0);
  };

  const handleClickDelete = (
    otel_id: number,
    kontrat_id: number,
    kontrol_durum: "0" | "1"
  ) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("kontrat_id", kontrat_id.toString());
    formData.append("sil_durum", "1");
    formData.append("kontrol_durum", kontrol_durum);

    dispatch(
      deleteHotelContract(formData, () => handleGetHotelContracts(otel_id))
    );
  };

  const handleClickApprove = (otel_id: number, kontrat_id: number) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("kontrat_id", kontrat_id.toString());
    formData.append("kontrol_durum", "2");

    dispatch(
      approveHotelContract(formData, () => handleGetHotelContracts(otel_id))
    );
  };

  const tableData = hotelContracts?.map((hotelContract) => ({
    "Kontrat ID": hotelContract.id,
    "Otel ID": hotelContract.otel_id,
    "Otel Adı": hotelContract.otel_adi,
    "Yetkili Kişi": hotelContract.ad_soyad,
    "Başlangıç Tarihi": hotelContract.baslangic_tarihi,
    "Bitiş Tarihi": hotelContract.bitis_tarihi,
    "Dosya Adı": hotelContract.dosya_adi,
    "Kontrol Durum ID": hotelContract.kontrol_durum,
    "Kontrol Durum":
      hotelContract.kontrol_durum === "0"
        ? "Onay Bekliyor"
        : hotelContract.kontrol_durum === "1"
        ? "Onaylandı"
        : hotelContract.kontrol_durum,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Yetkili Kişi",
      accessorKey: "Yetkili Kişi",
      enableColumnFilter: false,
    },
    {
      header: "Başlangıç Tarihi",
      accessorKey: "Başlangıç Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Bitiş Tarihi",
      accessorKey: "Bitiş Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Dosya Adı",
      accessorKey: "Dosya Adı",
      cell: (cell: any) => {
        return (
          <a href={cell.getValue()} target="_blank" rel="noreferrer">
            Kontrat Görüntüle
          </a>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Kontrat ID",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const otel_id = cell.row.original["Otel ID"];
        const kontrat_id = cell.getValue();
        const kontrol_durum = cell.row.original["Kontrol Durum ID"];

        const selectedContract = hotelContracts.find(
          (contract) => contract.id === kontrat_id
        ) as HotelContract;

        return (
          <div className="d-flex gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={() => handleClickEdit(selectedContract)}
              title="Düzenle"
            />
            {kontrol_durum === "0" && (
              <CustomRowOperationButton
                variant="check"
                onClick={() => handleClickApprove(otel_id, kontrat_id)}
                title="Onayla"
              />
            )}
            <CustomRowOperationButton
              variant="delete"
              onClick={() =>
                handleClickDelete(otel_id, kontrat_id, kontrol_durum)
              }
              title="Sil"
            />
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
