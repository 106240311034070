import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import CommissionPaymentDescriptions from "./CommissionPaymentDescriptions";
import PaymentForm from "./PaymentForm";

const CommissionPaymentPage = () => {
  return (
    <CommissionPaymentDescriptions>
      <PaymentForm />
    </CommissionPaymentDescriptions>
  );
};

export default withPageNeedsHotel(CommissionPaymentPage);
