import { AdsGroupsPage } from "Components/Custom/AdsAndAnalyzePages";
import useAdsGroups from "Components/Hooks/adsAndAnalyze/useAdsGroups";
import PageContainer from "pages/PageContainer";
import React from "react";

const AdsGroups = () => {
  const {
    isLoading,
    matchedHotelsLoading,
    unmatchedHotelsLoading,
    unmatchedAdsGroupsLoading,
    matchAdHotelLoading,
  } = useAdsGroups();
  return (
    <PageContainer title="Tesisler & Reklam Grupları">
      <AdsGroupsPage
        isLoading={isLoading}
        matchedHotelsLoading={matchedHotelsLoading}
        unmatchedHotelsLoading={unmatchedHotelsLoading}
        unmatchedAdsGroupsLoading={unmatchedAdsGroupsLoading}
        matchAdHotelLoading={matchAdHotelLoading}
      />
    </PageContainer>
  );
};

export default AdsGroups;
