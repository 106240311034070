import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { PersonnelRequestInitialState } from "slices/personnelRequest/reducer";
import {
  postPersonnelRequests,
  getPersonnelRequests,
} from "slices/personnelRequest/thunk";
import { AnswerPersonnelRequestPeriod } from "types/personelRequest";

function useHandleRequest() {
  const dispatch: Dispatch<any> = useDispatch();

  const [showPersonnelRequestModal, setShowPersonnelRequestModal] = useState<{
    [key: number]: boolean;
  }>({});

  const handleTooglePersonnelRequestModal = (talep_id: number) => {
    setShowPersonnelRequestModal((prevState) => ({
      ...prevState,
      [talep_id]: !prevState[talep_id],
    }));
  };

  const handleSavePersonnelRequestAnswer = (
    payload: AnswerPersonnelRequestPeriod
  ) => {
    try {
      dispatch(
        postPersonnelRequests(payload, () => {
          setShowPersonnelRequestModal((prevState) => ({
            ...prevState,
            [payload.talep_id]: false,
          }));
          dispatch(getPersonnelRequests());
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showPersonnelRequestModal,
    setShowPersonnelRequestModal,
    onTogglePersonnelRequestModal: handleTooglePersonnelRequestModal,
    onSavePersonnelRequestAnswer: handleSavePersonnelRequestAnswer,
  };
}

export default useHandleRequest;
