import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FacilityCallInitialState } from "slices/facility/facilityCalls/reducer";

interface UpdateFacilityCallModalProps {
  hotelId: number;
  isOpen: boolean;
  handleToggle: () => void;
  approveCallback: (description: string) => void;
  rejectCallback: (description: string) => void;
}

const UpdateFacilityCallModal = ({
  hotelId,
  isOpen,
  handleToggle,
  approveCallback,
  rejectCallback,
}: UpdateFacilityCallModalProps) => {
  const { facilityCall, facilityCalls, updateCallLoading } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  const call = facilityCalls.find(
    (facilityCall) => facilityCall.otel_id === hotelId
  );

  const [description, setDescription] = useState(
    call?.operasyon_durum_aciklama ?? ""
  );

  const approveOrReject =
    facilityCall.operasyon_durum === "1"
      ? "approve"
      : facilityCall.operasyon_durum === "2"
      ? "reject"
      : "";

  const onSave =
    facilityCall.operasyon_durum === "1"
      ? approveCallback
      : facilityCall.operasyon_durum === "2"
      ? rejectCallback
      : () => {};

  return (
    <CustomModal isOpen={isOpen} handleToggle={handleToggle}>
      <div className="d-flex flex-column gap-3 p-2 mt-4">
        <Input
          type="textarea"
          placeholder="Açıklama"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <CustomButton
          variant={
            approveOrReject === "approve"
              ? "success"
              : approveOrReject === "reject"
              ? "danger"
              : "secondary"
          }
          onClick={() => onSave(description)}
          isLoading={updateCallLoading}
        >
          {approveOrReject === "approve"
            ? "Çözüldü Olarak İşaretle"
            : approveOrReject === "reject"
            ? "Çözülmedi Olarak İşaretle"
            : "İşlem Yapılamaz"}
        </CustomButton>
      </div>
    </CustomModal>
  );
};

export default UpdateFacilityCallModal;
