import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface AnsweredCallsTableProps {
  isLoading: boolean;
}

const AnsweredCallsTable = ({ isLoading }: AnsweredCallsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Cevaplanan Çağrı Hareketleri"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default AnsweredCallsTable;
