import { useSelector } from "react-redux";

import { RoomsLackDescriptionInitialState } from "slices/reports/roomsLackDescription/reducer";

const useHandleTable = () => {
  const { roomsLackDescription } = useSelector(
    (state: { RoomsLackDescription: RoomsLackDescriptionInitialState }) =>
      state.RoomsLackDescription
  );

  const tableData = roomsLackDescription?.map((roomLackDescription) => ({
    "Otel Adı": roomLackDescription.otel_adi,
    "Oda Adı": roomLackDescription.oda_isim,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Oda Adı",
      accessorKey: "Oda Adı",
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
