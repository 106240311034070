import { CustomLoadingPage } from "Components/Custom/UI";
import UpdateThemeForm from "./UpdateThemeForm";
import ThemeSelector from "./ThemesSelector";
import { UpdateThemeState } from "types/themes";

interface Option {
  value: number;
  label: string;
}

interface UpdateThemePageProps {
  updateTheme: UpdateThemeState;
  themesOptions: Option[];
  isLoading: boolean;
}

const ListThemesPage = ({
  updateTheme,
  isLoading,
  themesOptions,
}: UpdateThemePageProps) => {
  const { tema_id } = updateTheme;
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-3">
        <ThemeSelector themesOptions={themesOptions} />
        {tema_id ? <UpdateThemeForm /> : <></>}
      </div>
    </>
  );
};

export default ListThemesPage;
