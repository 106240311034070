import { Col, Row } from "reactstrap";
import InputField from "../../InputField";
import { PriceType } from "types/PricesQuotas";

interface ColumnPricesContainerProps {
  date: string;
  values: PriceType[];
  onChange: (type: string, value: number, isBasePrice: boolean) => void;
  priceTypes: PriceType[];
  notAllowedToEdit: boolean;
  availability: number;
}

const ColumnPricesContainer = ({
  date,
  values,
  onChange,
  priceTypes,
  notAllowedToEdit,
  availability,
}: ColumnPricesContainerProps) => {
  const backgroundColor =
    availability === 0
      ? "bg-success"
      : availability === 1
      ? "bg-warning"
      : "bg-danger";

  return (
    <div
      className={`d-flex flex-column gap-2 bg-success p-2 ${backgroundColor} rounded bg-opacity-50`}
    >
      <>
        {values.map((price, index) => (
          <Row
            key={index}
            className="d-flex justify-content-between align-items-baseline"
          >
            <Col>
              <InputField
                identifier="TRY"
                value={price.fiyat}
                isEditable={price.fiyat_girilebilir && !notAllowedToEdit}
                type={`${date}-${price.fiyat_tipi}`}
                isBasePrice={price.baz_fiyat}
                onChange={onChange}
              />
            </Col>
          </Row>
        ))}
      </>
    </div>
  );
};

export default ColumnPricesContainer;
