import ReservationsPage from "Components/Custom/ReportsPages/ReservationsPage";
import useReservations from "Components/Hooks/reports/useReservations";
import PageContainer from "pages/PageContainer";

const Reservations = () => {
  const { isLoading } = useReservations();

  return (
    <PageContainer title="Rezervasyonlar">
      <ReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default Reservations;
