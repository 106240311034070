import { ReactElement } from "react";

interface ReservationCardProps {
  title: string;
  value: string;
  color: string;
  cardIcon: ReactElement;
}
const ReservationCard = ({
  title,
  value,
  color,
  cardIcon,
}: ReservationCardProps) => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center rounded px-12"
        style={{ backgroundColor: color, width: "240px" }}
      >
        <div >{cardIcon}</div>
        <div className="d-flex flex-column align-items-start gap-2 rounded p-4 text-white">
          <span className="fw-semibold fs-18">{value} TL</span>
          <span className="fs-14">{title}</span>
        </div>
      </div>
    </>
  );
};

export default ReservationCard;
