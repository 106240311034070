import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import useHandlePagination from "./useHandlePagination";

interface ListHotelsWithoutCommentsPageProps {
  isLoading: boolean;
}
const ListHotelsWithoutCommentsTable = ({ isLoading }: ListHotelsWithoutCommentsPageProps) => {
  const { tableData, tableColumns } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <>
      <CustomReactTable
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
      />
    </>
  );
};

export default ListHotelsWithoutCommentsTable;
