import ConceptButtonContainer from "../ConceptButtonContainer";
import { CustomButton } from "Components/Custom/UI";
import ButtonTooltip from "../ButtonTooltip";

interface KaporaliPayButtonProps {
  stop: 0 | 1 | 2 | 3 | null;
  stopMessage: string;
  showKaporaliOdemeButton: boolean;
  kaporaliOdemeFiyatDurum: number;
  kaporaliOdemeButtonText: string;
  kaporaliOdemePrice: number;
  kaporaAmount: number;
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
}

const KaporaliPayButton = ({
  stop,
  stopMessage,
  showKaporaliOdemeButton,
  kaporaliOdemeFiyatDurum,
  kaporaliOdemeButtonText,
  kaporaliOdemePrice,
  kaporaAmount,
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  onClick,
}: KaporaliPayButtonProps) => {
  return (
    <ConceptButtonContainer
      tooltipId={"kaporali-"}
      showButton={showKaporaliOdemeButton}
      text={
        stop === 0
          ? "Kalan Tutar otele girişte, tek çekim veya nakit olarak ödenecektir."
          : stop === 1
          ? "<span class='fw-medium'>Son Odalar</span><br/><span>Rezervasyon için lütfen satış danışmanlarımızı arayınız.</span>"
          : ""
      }
      stop={stop}
      stopMessage={stopMessage}
    >
      <CustomButton
        classes="w-100"
        variant={
          stop === 1
            ? "warning"
            : kaporaliOdemeFiyatDurum
            ? "success"
            : "warning"
        }
        disabled={kaporaliOdemeFiyatDurum === 0}
        onClick={() =>
          onClick({
            oda_id: oda_id,
            oda_adi: oda_adi,
            pansiyon_id: pansiyon_id,
            pansiyon_adi: pansiyon_adi,
            odeme_tipi: "kaporali",
            fiyat: kaporaliOdemePrice,
          })
        }
      >
        {kaporaliOdemeButtonText}
      </CustomButton>
      <ButtonTooltip
        target={"kaporali" + pansiyon_id}
        title="Misafiri Bilgilendiriniz!"
        description={`<span>1. Kredi kartınızdan <strong>${kaporaAmount} TL</strong> Ön Ödeme tutarı taksitli olarak tahsil edilecektir. Kalan Tutarı Otele girişte, Tek çekim veya nakit olarak yapmanız gerekmektedir.Tüm Rezervasyon tutarınızı taksitlendirmek isterseniz, Tamamını Öde Butonu ile işleminizi gerçekleştiriniz.</span><br/><span>2. İptal Durumunda Ön Ödeme Tutarının iadesi bulunmamaktadır.</span>`}
      />
    </ConceptButtonContainer>
  );
};

export default KaporaliPayButton;
