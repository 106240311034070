import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { ViewFeaturedImagesInitialState } from "slices/facility/viewFeaturedImages/reducer";

const useHandleTable = ({
  handleChangeIndex,
}: {
  handleChangeIndex: (index: number) => void;
}) => {
  const { images } = useSelector(
    (state: { ViewFeaturedImages: ViewFeaturedImagesInitialState }) =>
      state.ViewFeaturedImages
  );

  const tableData = images?.map((image, index) => ({
    id: index,
    hotel: image.otel_adi,
    url: image.resim_url,
    seflink: image.otel_sef_link,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "hotel",
      cell: (cell: any) => (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{
            height: "200px",
          }}
        >
          <span className="fw-medium fs-16">{cell.getValue()}</span>
        </div>
      ),
      enableColumnFilter: false,
    },
    {
      header: "",
      accessorKey: "url",

      cell(cell: any) {
        return (
          <div
            className="d-flex justify-content-end align-items-center"
            style={{
              minWidth: "400px",
            }}
          >
            <img
              className="rounded"
              src={cell.getValue()}
              alt="resim"
              style={{ width: "300px", height: "200px", objectFit: "cover" }}
            />
          </div>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      cell(cell: any) {
        const onClick = () => {
          const id = cell.getValue();
          handleChangeIndex(id);
        };

        const seflink = cell.row.original.seflink;
        return (
          <div
            className="d-flex flex-column gap-2 align-items-center justify-content-center border"
            style={{
              maxWidth: "100%",
              height: "200px",
            }}
          >
            <Button
              className="bg-primary text-primary bg-opacity-25 fw-bold border-primary w-100"
              onClick={onClick}
            >
              Resmi Büyüt
            </Button>
            <Button className="bg-success text-success bg-opacity-25 fw-bold border-success w-100">
              <a
                className="text-success text-decoration-none"
                href={seflink}
                target="_blank"
                rel="noreferrer"
              >
                Otele Git
              </a>
            </Button>
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
