import { Card, CardBody, Col, Container, Row } from "reactstrap";

import LoginForm from "./LoginForm";
import LoginFormHeader from "./LoginFormHeader";

import logoLight from "../../../../assets/images/logo-light.png";

import { Router } from "types";

const LoginPage = (props: Router) => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Card className="mt-4 card-bg-fill">
            <CardBody className="p-4">
              <LoginFormHeader logo={logoLight} />
              <LoginForm {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
