import { Spinner } from "reactstrap";
import styles from "./CustomButton.module.scss";

interface CustomButtonProps {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "orange";
  disabled?: boolean;
  onClick?: () => void;
  classes?: string;
  isLoading?: boolean;
}

const CustomButton = ({
  children,
  type = "button",
  variant = "primary",
  disabled = false,
  onClick = () => {},
  classes = "",
  isLoading = false,
}: CustomButtonProps) => {
  return (
    <button
      type={type}
      className={`${styles.button} ${styles[variant]} ${classes}`}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {children}
      {isLoading && <Spinner className="ms-2" color="light" size="sm" />}
    </button>
  );
};

export default CustomButton;
