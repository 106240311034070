import { CustomButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  addFacilityManager,
  getFacilityManagers,
} from "slices/facility/facilityManagers/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  AddFacilityManagerRequestPayload,
  FacilityManagerState,
} from "types/facility";

interface AddManagerButtonProps {
  values: FacilityManagerState;
  requestPayload: AddFacilityManagerRequestPayload;
  validateForm: (values: FacilityManagerState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddManagerButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddManagerButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const handleAddManager = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addFacilityManager(requestPayload, () => {
          dispatch(getFacilityManagers(otel_id));
        })
      );
    }
  };
  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="success"
        onClick={handleAddManager}
        isLoading={isLoading}
      >
        Yeni Yetkili Ekle
      </CustomButton>
    </div>
  );
};

export default AddManagerButton;
