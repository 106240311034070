import { createSlice } from "@reduxjs/toolkit";

import {
  AddNewThemeState,
  AddNewThemeFormErrors,
  ThemeRegionOption,
  ThemeTypesOption,
} from "types/themes";

export interface AddNewThemeInitialState {
  themeRegionOptions: ThemeRegionOption[];
  themeTypesOptions: ThemeTypesOption[];
  theme: AddNewThemeState;
  addNewThemeFormErrors: AddNewThemeFormErrors;
  isLoading: boolean;
  error: string;
  addThemeLoading: boolean;
  addThemeError: string;
}

export const addNewThemeInitialState: AddNewThemeInitialState = {
  themeRegionOptions: [
    {
      value: "",
      label: "Tema Bölgesi Seçiniz.",
      selected: "",
    },
  ],
  themeTypesOptions: [
    {
      value: "",
      text: "Durum Seçiniz.",
      selected: "",
    },
  ],
  theme: {
    tema_bolge: "",
    durum: "",
    baslik: "",
    alt_baslik: "",
    aciklama: "",
    link: "",
    file: "",
  },
  addNewThemeFormErrors: {},
  isLoading: false,
  error: "",
  addThemeLoading: false,
  addThemeError: "",
};

export const addNewThemeSlice = createSlice({
  name: "addNewTheme",
  initialState: addNewThemeInitialState,
  reducers: {
    handleSaveThemeRegionOptions(state, action) {
      state.themeRegionOptions = [
        {
          value: "",
          label: "Tema Bölgesi Seçiniz.",
          selected: "",
        },
        ...action.payload,
      ];
    },

    handleSaveThemeTypesOptions(state, action) {
      state.themeTypesOptions = [
        {
          value: "",
          text: "Durum Seçiniz.",
          selected: "",
        },
        ...action.payload,
      ];
    },
    handleChangeInput(state, action) {
      state.theme = {
        ...state.theme,
        ...action.payload,
      };
    },
    handleAddAddNewThemeFormErrors(state, action) {
      state.addNewThemeFormErrors = action.payload;
    },

    resetAfterAddNewTheme(state) {
      state.theme = {
        tema_bolge: "",
        durum: "",
        baslik: "",
        alt_baslik: "",
        aciklama: "",
        link: "",
        file: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddThemeLoading(state, action) {
      state.addThemeLoading = action.payload;
    },
    setAddThemeError(state, action) {
      state.addThemeError = action.payload;
    },
  },
});

export const {
  handleSaveThemeRegionOptions,
  handleSaveThemeTypesOptions,
  handleChangeInput,
  handleAddAddNewThemeFormErrors,
  resetAfterAddNewTheme,
  setIsLoading,
  setError,
  setAddThemeLoading,
  setAddThemeError,
} = addNewThemeSlice.actions;

export default addNewThemeSlice.reducer;
