import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addLocation } from "slices/definitions/addLocation/thunk";

import { AddLocationRequestPayload, AddLocationState } from "types/definitions";

interface AddNewLocationButtonProps {
  values: AddLocationState;
  requestPayload: AddLocationRequestPayload;
  validateForm: (values: AddLocationState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddNewLocationButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddNewLocationButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddNewLocation = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(addLocation(requestPayload));
    }
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton
        variant="success"
        onClick={handleAddNewLocation}
        isLoading={isLoading}
      >
        Yeni Lokasyon Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewLocationButton;
