import ListUsersTable from "./ListUsersTable";

interface ListUsersPageProps {
  isLoading: boolean;
}

const ListUsersPage = ({ isLoading }: ListUsersPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <ListUsersTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListUsersPage;
