import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacilityRoom,
  handleSaveRooms,
  handleSaveAllRoomsNames,
  setRoomsLoading,
  setRoomsError,
  setUpdateFacilityRoomLoading,
  setUpdateFacilityRoomError,
  setUpdateFacilityRoomFeaturesLoading,
  setUpdateFacilityRoomFeaturesError,
  setAddFacilityRoomImagesLoading,
  setAddFacilityRoomImagesError,
  setDeleteFacilityRoomImageLoading,
  setDeleteFacilityRoomImageError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_FACILITY_ROOM,
  UPDATE_FACILITY_ROOM,
  UPDATE_FACILITY_ROOM_FEATURES,
  ADD_FACILITY_ROOM_IMAGES,
  DELETE_FACILITY_ROOM_IMAGES,
  GET_FACILITY_ROOMS_NAMES,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetFacilityRoomPayload {
  otel_id: number;
  oda_id: number;
}

interface GetFacilityRoomNamesParams {
  page: number;
  per_page: number;
  search: string;
}

type SuccessCallback = () => void;

export const getFacilityRoom =
  (payload: GetFacilityRoomPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_FACILITY_ROOM, payload);

      const data = response.data || response;

      const rooms = Object.values(data[0]["tüm oda adları"]);

      dispatch(handleSaveFacilityRoom(data[0]));
      dispatch(handleSaveRooms(rooms));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateFacilityRoom =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateFacilityRoomLoading(true));
      await axios.post(UPDATE_FACILITY_ROOM, payload);

      successCallback();
      dispatch(setToastSuccess("Oda güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateFacilityRoomError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateFacilityRoomLoading(false));
    }
  };

export const updateFacilityRoomFeatures =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateFacilityRoomFeaturesLoading(true));

      await axios.post(UPDATE_FACILITY_ROOM_FEATURES, payload);

      successCallback();
      dispatch(setToastSuccess("Oda özellikleri güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateFacilityRoomFeaturesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateFacilityRoomFeaturesLoading(false));
    }
  };

export const addFacilityRoomImages =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddFacilityRoomImagesLoading(true));

      await axios.post(ADD_FACILITY_ROOM_IMAGES, payload);

      successCallback();
      dispatch(setToastSuccess("Oda resmi eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddFacilityRoomImagesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddFacilityRoomImagesLoading(false));
    }
  };

export const deleteFacilityRoomImage =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteFacilityRoomImageLoading(true));
      await axios.post(DELETE_FACILITY_ROOM_IMAGES, payload);

      successCallback();
      dispatch(setToastSuccess("Oda resmi silindi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteFacilityRoomImageError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteFacilityRoomImageLoading(false));
    }
  };

export const getFacilityRoomNames =
  (params: GetFacilityRoomNamesParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setRoomsLoading(true));
      const response = await axios.get(GET_FACILITY_ROOMS_NAMES, { params });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveAllRoomsNames({ res, page: params.page }));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setRoomsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setRoomsLoading(false));
    }
  };
