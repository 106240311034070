import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface CallsFromGeneralTableProps {
  isLoading: boolean;
}

const CallsFromGeneralTable = ({ isLoading }: CallsFromGeneralTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Genelden Gelen Çağrılar"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default CallsFromGeneralTable;
