import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleAddToLocationsDontHaveChild,
  handleDeleteLastSelectedLocation,
  handleSaveSelectedLocationOptions,
} from "slices/definitions/listLocations/reducer";
import {
  deleteLocation,
  getLocations,
} from "slices/definitions/listLocations/thunk";
import { Location } from "types/definitions";

interface LocationSelectProps {
  index: number;
  locations: Location[];
  selectedLocation: { key: number; value: Location[] };
  selectedLocations: { key: number; value: Location[] }[];
  handleSelectedLocation: (selected: Location[], index: number) => void;
  showList: boolean;
  showButtons: boolean;
  isDefaultLocation: boolean;
  isPreLocation: boolean;
  isLocation: boolean;
  locationsDontHaveChild: number[];
}

const LocationSelect = ({
  index,
  locations,
  selectedLocation,
  selectedLocations,
  handleSelectedLocation,
  showList,
  showButtons,
  isDefaultLocation,
  isPreLocation,
  isLocation,
  locationsDontHaveChild,
}: LocationSelectProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const locationsOptions =
    selectedLocation.value.map((location) => ({
      value: location.id,
      label: location.lokasyon,
      parent_id: location.parent_id,
    })) || [];

  const handleClick = () => {
    dispatch(handleDeleteLastSelectedLocation());
  };

  const selectedPreLocationsKeys = selectedLocations.map(
    (selected) => selected.key
  );

  const handleDeleteLocation = (locationId: number) => {
    dispatch(
      deleteLocation({
        id: locationId,
      })
    );
    dispatch(handleDeleteLastSelectedLocation());
  };

  return (
    <>
      {showList && (
        <div
          className="d-flex gap-2 align-items-start border border-3 p-2 rounded flex-grow-1"
          style={{ maxWidth: "600px" }}
        >
          <div className="w-100">
            <p className="text-center fw-bold cursor-pointer theme-text-primary bg-primary p-1 rounded bg-opacity-25">
              {isPreLocation
                ? "Üst Lokasyon"
                : isLocation
                ? "Lokasyon"
                : "Üst Lokasyon Seçin."}
            </p>
            <ul className="list-group w-100">
              {locationsOptions.map((location) => {
                const isSelectedListItem = selectedPreLocationsKeys.includes(
                  location.value
                );
                const showDeleteButton = locationsDontHaveChild.includes(
                  location.value
                );
                return (
                  <li
                    className={`list-group-item cursor-pointer ${
                      isSelectedListItem
                        ? "bg-primary text-primary bg-opacity-10 fw-bold"
                        : "theme-bg-light"
                    } `}
                    key={location.value}
                    onClick={() => {
                      const selected = locations.filter(
                        (loc) => loc.id === location.value
                      );

                      const haveChildLocations =
                        !locationsDontHaveChild.includes(location.value);

                      haveChildLocations &&
                        dispatch(
                          getLocations({
                            parent_id: location.value,
                            successCallback: (response: Location[]) => {
                              handleSelectedLocation(response, index);

                              !response.length &&
                                dispatch(
                                  handleAddToLocationsDontHaveChild(
                                    location.value
                                  )
                                );
                            },
                          })
                        );

                      dispatch(
                        handleSaveSelectedLocationOptions({
                          index,
                          value: selected,
                        })
                      );
                    }}
                  >
                    {location.label}
                    {isSelectedListItem && (
                      <i className="ri-arrow-right-s-line float-end fw-bold"></i>
                    )}
                    {showDeleteButton && (
                      <i
                        className="ri-delete-bin-line float-end  theme-text-danger fw-bold"
                        onClick={() => handleDeleteLocation(location.value)}
                      ></i>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          {showButtons && !isDefaultLocation && (
            <CustomButton variant="primary" onClick={handleClick}>
              Geri
            </CustomButton>
          )}
        </div>
      )}
    </>
  );
};

export default LocationSelect;
