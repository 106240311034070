import { AddNewThemePage } from "Components/Custom/ThemesPages";
import useAddNewTheme from "Components/Hooks/themes/useAddNewTheme";
import PageContainer from "pages/PageContainer";

const AddNewTheme = () => {
  const { isLoading } = useAddNewTheme();

  return (
    <PageContainer title="Tema Ekle">
      <AddNewThemePage isLoading={isLoading} />
    </PageContainer>
  );
};

export default AddNewTheme;
