import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { ReservationFilterPayload } from "types/reports";

interface InetCodeInputProps {
  values: ReservationFilterPayload;
  handleFormChange: (key: string, value: string) => void;
  fieldKey: keyof ReservationFilterPayload;
}

const InetCodeInput = ({
  values,
  handleFormChange,
  fieldKey,
}: InetCodeInputProps) => {

  const value = values[fieldKey] as string;

  return (
    <>
      <CustomFormFieldContainer label="Inet Kodu">
        <Input
          type="text"
          autoCorrect="on"
          value={value}
          onChange={(e) => {
            handleFormChange("inet_kodu", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default InetCodeInput;
