import {
  BookingType,
  blockedDaysType,
  IHandleBookings,
  IFormatBookingsData,
  IGetAllBookedDays,
  IGetAllHalfDays,
  IGetDatesInRange,
  IGetMonthName,
  DaysOfWeekType,
  Days,
  DaysOfWeekOffsetType,
  DayOffset,
} from "./types";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const dateFormat = "M-D-YYYY";

export const daysOfTheWeek: DaysOfWeekType = [
  Days.Monday,
  Days.Tusday,
  Days.Wednesday,
  Days.Thursday,
  Days.Friday,
  Days.Saturday,
  Days.Sunday,
];

export const daysOfTheWeekOffset: DaysOfWeekOffsetType = [
  DayOffset.Monday,
  DayOffset.Tusday,
  DayOffset.Wednesday,
  DayOffset.Thursday,
  DayOffset.Friday,
  DayOffset.Saturday,
  DayOffset.Sunday,
];

export const isValidMonthsOption = (numOfMonths: number): boolean => {
  if (!numOfMonths || typeof numOfMonths !== "number") return false;

  const validOptions = [12, 4, 2, 1];
  const isValid = validOptions.includes(numOfMonths);

  return isValid;
};

export const getMonthName = (month: number): string => {
  const months: IGetMonthName = {
    1: "Ocak",
    2: "Şubat",
    3: "Mart",
    4: "Nisan",
    5: "Mayıs",
    6: "Haziran",
    7: "Temmuz",
    8: "Ağustos",
    9: "Eylül",
    10: "Ekim",
    11: "Kasım",
    12: "Aralık",
  };

  return months[month];
};

export const formatBookingsData = ({
  bookings,
  year,
}: IFormatBookingsData): BookingType[] => {
  if (!Array.isArray(bookings) || bookings.length < 1) return [];

  const arr: BookingType[] = [];

  bookings.forEach((booking) => {
    const from = booking?.tarih;
    const durum = booking?.durum;

    const validStartDate = dayjs(from).year() === Number(year);
    const validEndDate = dayjs(from).year() === Number(year);

    if (!validStartDate && !validEndDate) return null;

    const nxtBooking: BookingType = {
      tarih: dayjs(from).format(dateFormat),
      to: dayjs(from).format(dateFormat),
      durum,
    };

    arr.push(nxtBooking);
  });

  return arr;
};

export const getDatesInRange = ({
  startDate,
  endDate,
}: IGetDatesInRange): blockedDaysType => {
  let _startDate = dayjs(startDate, "M-D-YYYY");
  const _endDate = dayjs(endDate, "M-D-YYYY");

  const dates: blockedDaysType = [];

  while (!_startDate.isAfter(_endDate)) {
    dates.push(_startDate.format(dateFormat));

    _startDate = _startDate.add(1, "day");
  }

  return dates;
};

export const getAllBookedDays = ({
  dates,
}: IGetAllBookedDays): blockedDaysType => {
  if (!Array.isArray(dates) || dates.length < 1) return [];

  const arr: blockedDaysType = [];

  dates.forEach(({ tarih }) => {
    const nxt = getDatesInRange({ startDate: tarih, endDate: tarih });

    nxt.forEach((_date) => {
      arr.push(_date);
    });
  });

  return arr;
};

export const getDays = ({
  dates,
  status,
}: IGetAllHalfDays): blockedDaysType => {
  if (!Array.isArray(dates) || dates.length < 1) return [];

  const arr: blockedDaysType = [];

  dates.forEach(({ tarih, durum }) => {
    if (durum === status) {
      arr.push(tarih.toString());
    }
  });

  return arr;
};

export const handleBookings = ({
  bookings,
  year,
}: IHandleBookings): {
  bookedDays: blockedDaysType;
  availableDays: blockedDaysType;
  lastRoomsDays: blockedDaysType;
  unavailableDays: blockedDaysType;
  noQuotaDays: blockedDaysType;
} => {
  const dates = formatBookingsData({ bookings, year });
  const bookedDays = getAllBookedDays({ dates });
  const availableDays = getDays({ dates, status: 1 });
  const lastRoomsDays = getDays({ dates, status: 2 });
  const unavailableDays = getDays({ dates, status: 3 });
  const noQuotaDays = getDays({ dates, status: 4 });

  return {
    bookedDays,
    availableDays,
    lastRoomsDays,
    unavailableDays,
    noQuotaDays,
  };
};
