import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import { Label } from "reactstrap";
import UserSelector from "./UserSelector";
import UserAuthorityOptions from "./UserAuthorityOptions";
import AuthorityAssigneeList from "./AuthorityAssigneeList";
import { User, UserAuthority } from "types/userOperations";

interface UpdateUserAuthorityFormProps {
  users: User[];
  authorities: UserAuthority[];
  selectedUsers: number[];
  selectedPermissions: { [authorityId: number]: string };
  isLoading: boolean;
  addAuthoritiesToUsersLoading: boolean;
  addAuthoritiesLoading: boolean;
  handleSelectUser: (userIds: number[]) => void;
  handleSaveAuthorities: () => void;
}

const UpdateUserAuthorityForm = (props: UpdateUserAuthorityFormProps) => {
  const { selectedUsers } = props;

  return (
    <CustomFormContainer title="Kullanıcı Yetkileri">
      <div className="d-flex flex-column gap-4">
        {/* <UserSelector
          users={props.users}
          selectedUsers={props.selectedUsers}
          handleSelectUser={props.handleSelectUser}
        /> */}
        <AuthorityAssigneeList handleSelectUser={props.handleSelectUser} />
        {selectedUsers.length > 0 ? (
          <>
            {" "}
            <UserAuthorityOptions />
            <div
              style={{
                position: "sticky",
                bottom: 50,
                zIndex: 100,
              }}
            >
              <div className="d-flex justify-content-end">
                <CustomButton
                  variant="success"
                  onClick={props.handleSaveAuthorities}
                  isLoading={props.addAuthoritiesToUsersLoading}
                >
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default UpdateUserAuthorityForm;
