import { CustomButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  addMoneyPoint,
  getMoneyPoints,
} from "slices/reports/moneyPoints/thunk";

import { AddMoneyPointRequestPayload, AddMoneyPointState } from "types/reports";

interface AddMoneyPointButtonProps {
  values: AddMoneyPointState;
  requestPayload: AddMoneyPointRequestPayload;
  validateForm: (values: AddMoneyPointState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddMoneyPointButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddMoneyPointButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  // const payload = new URLSearchParams();
  // payload.append("operator_kodu", values.operator_kodu);
  // payload.append("parapuan_tutar", values.parapuan_tutar);

  const handleAddMoneyPoint = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      // dispatch(addMoneyPoint(payload));
      dispatch(addMoneyPoint(requestPayload));

      setTimeout(() => {
        dispatch(getMoneyPoints(otel_id));
      }, 1000);
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="success"
        onClick={handleAddMoneyPoint}
        isLoading={isLoading}
      >
        Parapuan Ekle
      </CustomButton>
    </div>
  );
};

export default AddMoneyPointButton;
