import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleCertificateLinkFormErrors } from "slices/myWebSite/addCertificate/reducer";
import { setToastError } from "slices/toast/reducer";
import {
  AddCertificateFormErrors,
  AddCertificatePayload,
} from "types/myWebSite";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddCertificatePayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddCertificateFormErrors = {};

    if (!values.sertifika_adi) {
      errors.sertifika_adi = "Sertifika adı boş bırakılamaz.";
    }
    if (!values.sertifika_link) {
      errors.sertifika_link = "Sertifika yükleyiniz.";
    }

    dispatch(handleCertificateLinkFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddCertificatePayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
