import {
  BankInstallment,
  CalculatePriceForHotelConceptPrice,
  CalculatePriceForHotelProvider,
} from "types/reservation";
import ConceptPrice from "../ConceptPrice";
import ConceptInfo from "../../ConceptInfo";
import PriceCalculationStatusMessages from "../../PriceCalculationStatusMessages";

import useHandleNormalPayButton from "../../PayButtonsHooks/useHandleNormalPayButton";
import NormalPayButton from "../PayButtons/NormalPayButton";

interface NormalPaymentProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  ayni_fiyatli_odeme_tipleri: string[];
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  tedarikci: CalculatePriceForHotelProvider;
  giris_tarihi: string;
  iptal_sigortasi_durum: "1" | "0";
  iptal_sigortasi_gun: string;
  kismi_odeme: {
    kismi_odeme: 0 | 1;
    kismi_odeme_orani: number;
    kismi_odeme_tarihi: string;
    iptal_sigortasi_durum: "1" | "0";
  };
  gifts: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  banka_taksit: BankInstallment[];
}

const NormalPayment = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  kontenjan,
  priceCalculationStatusMessages,
  giris_tarihi,
  iptal_sigortasi_durum,
  iptal_sigortasi_gun,
  tedarikci,
  kismi_odeme,
  gifts,
  onClick,
  banka_taksit,
}: NormalPaymentProps) => {
  const {
    hasPrice,
    stop,
    stopMessage,
    showPayNowButton,
    payNowFiyatDurum,
    payNowPriceButtonText,
    payNowPrice,
    payNowOverlinedPrice,
    cancellationDate,
    kismiOdeme,
    kismiOdemeTarihi,
    kismiOdemeButtonText,
    kismiOdemePrice,
    iptalSigortasiFiyat,
    modal,
    toggle,
    messages,
  } = useHandleNormalPayButton({
    fiyatlar,
    tedarikci,
    giris_tarihi,
    iptal_sigortasi_gun,
    kismi_odeme,
    priceCalculationStatusMessages,
  });
  return (
    <>
      <div
        className="rounded p-2 border bg-white"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          minHeight: "120px",
        }}
      >
        <ConceptInfo
          priceType={["normal"]}
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          gifts={gifts}
        />

        <ConceptPrice
          showPrice={showPayNowButton}
          price={payNowPrice}
          overlinedPrice={payNowOverlinedPrice}
          normal={{
            status: true,
            kismiOdeme,
            kismiOdemePrice,
            iptal_sigortasi_durum,
            iptalSigortasiFiyat,
          }}
          banka_taksit={banka_taksit}
        />

        {hasPrice ? (
          <NormalPayButton
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            stop={stop}
            stopMessage={stopMessage}
            payNowFiyatDurum={payNowFiyatDurum}
            payNowPrice={payNowPrice}
            payNowPriceButtonText={payNowPriceButtonText}
            kismiOdeme={kismiOdeme}
            kismiOdemePrice={kismiOdemePrice}
            kismiOdemeTarihi={kismiOdemeTarihi}
            kismiOdemeButtonText={kismiOdemeButtonText}
            onClick={onClick}
            modal={modal}
            toggle={toggle}
            showPayNowButton={showPayNowButton}
            iptal_sigortasi_durum={iptal_sigortasi_durum}
            cancellationDate={cancellationDate}
          />
        ) : (
          <>
            <div></div>
            <PriceCalculationStatusMessages messages={messages} />
          </>
        )}
      </div>
    </>
  );
};
export default NormalPayment;
