import { ReservationReview } from "types/reservation";
import { Fragment, useState } from "react";
import { Collapse } from "reactstrap";
import ListItem from "../ListItem";
import useHandleRequests from "./useHandleRequests";
import { CustomButton } from "Components/Custom/UI";

interface RoomInfoProps {
  count: number;
  reservation: ReservationReview;
}

const RoomInfo = ({ reservation, count }: RoomInfoProps) => {
  const { onCancelRoom, onPrintRoom } = useHandleRequests();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  const adults = reservation.misafirler
    .filter((misafir) => misafir.tip === "yetiskin")
    .map((misafir, index) => ({
      title: index + 1 + ". Yetişkin ",
      name: misafir.ad_soyad,
      gender: misafir.cinsiyet === "e" ? "Erkek" : "Kadın",
      birthdate: misafir.dogum_tarihi,
      tc: misafir.tc_kimlik,
    }));

  const children = reservation.misafirler
    .filter((misafir) => misafir.tip === "cocuk")
    .map((misafir, index) => ({
      title: index + 1 + ". Çocuk ",
      name: misafir.ad_soyad,
      gender: misafir.cinsiyet === "e" ? "Erkek" : "Kadın",
      birthdate: misafir.dogum_tarihi,
    }));
  const financialInfo = reservation.finans_bilgileri || "";

  const stopStatus = financialInfo ? financialInfo.stop_durum : "";

  const roomAvailability =
    stopStatus === "0"
      ? "Açık Satış"
      : stopStatus === "1"
      ? "Stoplu Satış"
      : stopStatus === "2"
      ? "Sorsat Satış"
      : stopStatus === "3"
      ? "Kontenjan Yok"
      : "";

  const paymentType = financialInfo.odeme_tipi;

  const paymentStatus =
    paymentType === "normal"
      ? reservation.kismi_odeme_durum === "1"
        ? "Acente Ödemeli Standart / Kısmi Ödemeli"
        : "Acente Ödemeli Standart"
      : paymentType === "iptal_edilemez"
      ? "Acente Ödemeli İptal Edilemez"
      : paymentType === "kaporali"
      ? "Kaporalı Rezervasyon"
      : "";

  const iptalSigortasi = reservation.iptal_sigortasi === "var";
  const iptalSigortasiFiyat =
    iptalSigortasi && financialInfo
      ? financialInfo.iptal_sigortasi_tutar + " " + financialInfo.para_birimi
      : "";

  return (
    <div className="d-flex flex-column gap-4 border rounded bg-white">
      <div
        className="d-flex align-items-center gap-2 p-3 rounded bg-dark bg-opacity-10"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <span className="fw-bold fs-18">{count}. Oda</span>
        <span>-</span>
        <span className="fw-medium ">({reservation.rezervasyon_id})</span>
      </div>
      <Collapse className="p-3" isOpen={isOpen}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            listStyle: "none",
          }}
        >
          <ListItem
            title="Satış Kanalı"
            value={
              <span className="theme-text-danger fw-bold">
                {reservation.satis_operator}
              </span>
            }
            topBorder
          />
          <ListItem
            title="Fiyat Hesaplama"
            value={
              <span className="theme-text-info fw-bold">
                {reservation.fiyat_hesaplama}
              </span>
            }
            topBorder
          />
        </div>
        <ListItem
          title="Oda ve Pansiyon Tipi"
          value={
            <div className="d-flex gap-2">
              <span>{reservation.oda_adi}</span>
              <span>-</span>
              <span>{reservation.pansiyon_adi}</span>
            </div>
          }
          allRow
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            listStyle: "none",
          }}
        >
          <ListItem title="Ödeme Tipi" value={paymentStatus} isBold />
          <ListItem title="Musaitlik Durum" value={roomAvailability} isBold />
          <ListItem
            title="Toplam Tutar"
            value={
              financialInfo.indirimli_tutar + " " + financialInfo.para_birimi
            }
            isBold
          />
          {iptalSigortasi && (
            <ListItem
              title="İptal Güvencesi Tutar"
              value={iptalSigortasiFiyat}
              isBold
            />
          )}
        </div>
        <div className="d-flex flex-column gap-3 mt-4">
          <div>
            {adults.map((misafir, index) => (
              <Fragment key={index}>
                <div
                  className="d-flex justify-content-start fw-bold p-2"
                  style={{ columnSpan: "all" }}
                >
                  {misafir.title}
                </div>
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr 1fr 1fr",
                    columnSpan: "all",
                  }}
                >
                  <ListItem title="Ad Soyad" value={misafir.name} topBorder />
                  <ListItem title="Cinsiyet" value={misafir.gender} topBorder />
                  <ListItem
                    title="D. Tarihi"
                    value={misafir.birthdate}
                    topBorder
                  />
                  <ListItem title="TC Kimlik" value={misafir.tc} topBorder />
                </div>
              </Fragment>
            ))}
          </div>
          <div>
            {children.length ? (
              children.map((misafir, index) => (
                <Fragment key={index}>
                  <div
                    className="d-flex justify-content-start fw-bold p-2"
                    style={{ columnSpan: "all" }}
                  >
                    {misafir.title}
                  </div>
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 1fr 1fr 1fr",
                      columnSpan: "all",
                    }}
                  >
                    <ListItem title="Ad Soyad" value={misafir.name} topBorder />
                    <ListItem
                      title="Cinsiyet"
                      value={misafir.gender}
                      topBorder
                    />
                    <ListItem
                      title="Doğum Tarihi"
                      value={misafir.birthdate}
                      topBorder
                    />
                  </div>
                </Fragment>
              ))
            ) : (
              <></>
            )}
          </div>
          <div>
            <ListItem
              title="Müşteri Talepleri"
              value={reservation.request}
              allRow
              topBorder
            />
            <ListItem
              title="Acenta Talepleri"
              value={reservation.request_acenta}
              allRow
            />
            <div className="d-flex flex-column mt-3">
              <span className="fw-semibold p-2">Uygulanan İndirimler:</span>
              <div
                className="border border-2 p-2 bg-white d-flex flex-column gap-2"
                style={{ minHeight: "50px" }}
              >
                {reservation.uygulanan_indirimler.map((indirim, index) => (
                  <span key={index}>
                    {indirim.satis_indirim_orani &&
                    indirim.satis_indirim_orani > 0 ? (
                      <strong>{indirim.satis_indirim_orani}%</strong>
                    ) : (
                      indirim.kal &&
                      indirim.kal > 0 && (
                        <strong>
                          {indirim.kal} Kal {indirim.ode} Öde
                        </strong>
                      )
                    )}
                  </span>
                ))}
              </div>
            </div>
            {reservation.verilen_hediyeler &&
            reservation.verilen_hediyeler.length ? (
              <div className="d-flex flex-column mt-3">
                <span className="fw-semibold p-2">Verilen Hediyeler:</span>
                <div
                  className="border border-2 p-2 bg-white d-flex flex-column gap-2"
                  style={{ minHeight: "50px" }}
                >
                  {reservation.verilen_hediyeler.map((hediye, index) => (
                    <strong key={index}>
                      <i className="ri-gift-line me-2"></i>
                      {hediye.hediye_aciklama}
                    </strong>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start gap-3 mt-4">
          <CustomButton variant="secondary" onClick={onPrintRoom}>
            Bu Odayı Yazdır
          </CustomButton>
          <CustomButton variant="danger" onClick={onCancelRoom}>
            İptal Talebi
          </CustomButton>
        </div>
      </Collapse>
    </div>
  );
};

export default RoomInfo;
