import { getConceptStatusMessages } from "helpers/general_helpers";
import { CalculatePriceForHotelConceptPrice } from "types/reservation";

const useHandleKaporaliPayButton = ({
  fiyatlar,
  kaporali_odeme,
  priceCalculationStatusMessages,
  mayNotExist,
}: {
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kaporali_odeme: {
    kaporali_satis_durum: "evet" | "hayir";
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  mayNotExist?: boolean;
}) => {
  if (mayNotExist) {
    return {
      hasPrice: false,
      stop: null,
      stopMessage: "",
      showKaporaliOdemeButton: false,
      kaporaliOdemeFiyatDurum: 0,
      kaporaliOdemeButtonText: "",
      kaporaliOdemePrice: 0,
      kaporaliOdemeOverlinedPrice: 0,
      kaporaAmount: 0,
      messages: [],
    };
  } else {
    const hasPrice = fiyatlar.kaporali.fiyat_durum === 1;

    //Kaporalı Ödeme
    const kaporaliOdeme = fiyatlar.kaporali;

    const stop = kaporaliOdeme.stop_durum ?? null;
    const stopMessage = kaporaliOdeme.stop_durum_mesaj ?? "";

    const showKaporaliOdemeButton =
      kaporali_odeme.kaporali_satis_durum === "evet" && hasPrice;

    const kaporaliOdemeFiyatDurum = kaporaliOdeme.fiyat_durum ?? 0;

    const kaporaliOdemeButtonText =
      stop === 1 ? "Kapora Öde" : "Ön Ödeme ile Rezervasyon";

    const kaporaliOdemePrice = kaporaliOdeme.kaporali_indirimli ?? 0;

    const kaporaliOdemeOverlinedPrice = kaporaliOdeme.kaporali_gostermelik ?? 0;

    const kaporaAmount =
      Math.ceil(kaporaliOdemePrice - kaporaliOdeme.kaporali_maliyet) ?? 0;

    const hasPriceStatus = kaporaliOdeme.fiyat_durum === 1;
    const hasQuotaStatus = kaporaliOdeme.kontenjan_durum !== 0;
    const quotaMessage = kaporaliOdeme.kontenjan_durum_mesaj;
    const minNightStatus = kaporaliOdeme.minimum_gece_durum > 0;

    const messages = getConceptStatusMessages(
      priceCalculationStatusMessages,
      hasPriceStatus,
      hasQuotaStatus,
      quotaMessage,
      minNightStatus,
      kaporaliOdeme.minimum_gece_durum ?? 0
    );

    return {
      hasPrice,
      stop,
      stopMessage,
      showKaporaliOdemeButton,
      kaporaliOdemeFiyatDurum,
      kaporaliOdemeButtonText,
      kaporaliOdemePrice,
      kaporaliOdemeOverlinedPrice,
      kaporaAmount,
      messages,
    };
  }
};

export default useHandleKaporaliPayButton;
