import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveGiftsAndPromotions,
  resetGiftsAndPromotions,
  resetGiftsAndPromotion,
  handleSaveGiftsAndPromotionsList,
  setAddPromotionLoading,
  setAddPromotionError,
  setUpdatePromotionLoading,
  setUpdatePromotionError,
  setDeletePromotionLoading,
  setDeletePromotionError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_GIFTS_AND_PROMOTIONS,
  ADD_GIFTS_AND_PROMOTIONS,
  UPDATE_GIFTS_AND_PROMOTIONS,
  DELETE_GIFTS_AND_PROMOTIONS,
  GET_GIFTS_AND_PROMOTIONS_LIST,
} from "helpers/url_helper";

import { Dispatch } from "redux";

import { GiftAndPromotionRequestPayload } from "types/DiscountsAndActions";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteGiftsAndPromotionsPayload {
  hediye_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getGiftsAndPromotionsList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_GIFTS_AND_PROMOTIONS_LIST);

    dispatch(handleSaveGiftsAndPromotionsList(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getGiftsAndPromotions =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetGiftsAndPromotions());
      const response = await axios.get(GET_GIFTS_AND_PROMOTIONS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveGiftsAndPromotions(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addGiftsAndPromotions =
  (payload: GiftAndPromotionRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddPromotionLoading(true));

      await axios.post(ADD_GIFTS_AND_PROMOTIONS, payload);

      successCallback();
      dispatch(resetGiftsAndPromotion());
      dispatch(setToastSuccess("Hediye eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddPromotionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddPromotionLoading(false));
    }
  };

export const updateGiftsAndPromotions =
  (payload: GiftAndPromotionRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdatePromotionLoading(true));

      await axios.post(UPDATE_GIFTS_AND_PROMOTIONS, payload);

      successCallback();
      dispatch(resetGiftsAndPromotion());
      dispatch(setToastSuccess("Hediye güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdatePromotionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdatePromotionLoading(false));
    }
  };

export const deleteGiftsAndPromotions =
  (payload: DeleteGiftsAndPromotionsPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setDeletePromotionLoading(true));

      await axios.post(DELETE_GIFTS_AND_PROMOTIONS, payload);

      dispatch(setToastSuccess("Hediye silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeletePromotionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeletePromotionLoading(false));
    }
  };
