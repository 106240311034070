import { CustomLoadingPage } from "Components/Custom/UI";
import ThemeSelector from "./ThemeSelector";
import { OrderHotel } from "types/themes";
import OrderingList from "./OrderingList";

interface OrderThemeHotelsPageProps {
  hotels: OrderHotel[];
  selectedTheme: number;
  handleSelectTheme: (id: number) => void;
  themesOptions: Option[];
  isLoading: boolean;
}

interface Option {
  value: number;
  label: string;
}

const OrderThemeHotelsPage = ({
  hotels,
  selectedTheme,
  handleSelectTheme,
  themesOptions,
  isLoading,
}: OrderThemeHotelsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ThemeSelector
          themesOptions={themesOptions}
          selectedTheme={selectedTheme}
          handleSelectTheme={handleSelectTheme}
        />
        {selectedTheme ? <OrderingList /> : <></>}
      </div>
    </>
  );
};

export default OrderThemeHotelsPage;
