import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EnterReservationFormValues } from "types/reservation";

const useEnterReservation = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState<EnterReservationFormValues>({
    otel_id: 0,
    otel_adi: "",
    giris_tarihi: "",
    cikis_tarihi: "",
    giris_saati: "",
    musteri_ad_soyad: "",
    musteri_tel: "",
    musteri_tel_2: "",
    musteri_eposta: "",
    musteri_tc: "",
    yetiskin_sayisi: 0,
    cocuk_sayisi: 0,
    cocuk_dogum_1: "",
    cocuk_dogum_2: "",
    cocuk_dogum_3: "",
    cocuk_dogum_4: "",
    parapuan_secim: 0,
    ozel_indirimler: [],
    odeme_tipi: "",
    fiyat: 0,
    oda_pansiyon_tipi: "",
    arama_id: 0,
    musteri_id: 0,
    geri_arama_id: 0,
    geri_arama_tipi: "",
    ozel_indirim: [],
    parapuan_toplam_tutar: "0",
    secilen_ozel_indirimler: [],
    iptal_sigortasi_durum: "0",
    iptal_sigortasi_gun: "0",
    oda_id: "0",
    oda_adi: "",
    pansiyon_id: "0",
    pansiyon_adi: "",
    tedarikci: {
      tedarikci: "",
      fiyat_hesaplama: "",
      inet_durum: "0",
    },
    karsilama_kodu: "",
    fiyat_hesaplama: "",
    para_birimi: "TRY",
    kur: "TRY",
    kur_degeri: 1,
    komisyon_orani: "0",
    indirim_orani: "0",
    cari: {
      odeme_tipi: "",
      odeme_tipi_gunu: 0,
    },
    stop_durum: 0,
    ucretli_cocuk_durum: 0,
    verilen_hediyeler: "",
    otele_odeme_gunu: "",
    fiyat_saglayici: "",
    fiyatlar: {
      brut_tutar: 0,
      indirimli_tutar: 0,
      maliyet_tutar: 0,
      brut_tutar_kur: 0,
      indirimli_tutar_kur: 0,
      maliyet_tutar_kur: 0,
      iptal_sigortasi_fiyat: 0,
    },
    acente_komisyon_orani: "0",
    uygulanan_indirimler: "[]",
    kismi_odeme: 0,
    kismi_odeme_orani: 0,
    kismi_odeme_tarihi: "",
    kapida_odeme_komisyon_orani: 0,
    kaporali_satis_orani: 0,
  });

  useEffect(() => {
    const defaultValues =
      localStorage.getItem("enterReservationFormValues") || "";

    const sessionValues =
      sessionStorage.getItem("enterReservationFormValues") || "";

    if (defaultValues) {
      setValues(JSON.parse(defaultValues));

      sessionStorage.setItem("enterReservationFormValues", defaultValues);
      localStorage.removeItem("enterReservationFormValues");
    } else if (sessionValues) {
      setValues(JSON.parse(sessionValues));
    } else {
      navigate("/fiyat-hesapla");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { values };
};

export default useEnterReservation;
