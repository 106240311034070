import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getDailyCallsPerPerson } from "slices/reports/dailyCallsPerPerson/thunk";

const useHandleForm = () => {
  const [filterForm, setFilterForm] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
    };
    dispatch(getDailyCallsPerPerson(params));
  };

  const handdleResetFilter = () => {
    setFilterForm({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    });

    const params = {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getDailyCallsPerPerson(params));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handdleResetFilter,
  };
};

export default useHandleForm;
