import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveForecastReportResponse,
  setIsLoading,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_FORECAST_REPORT } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetForecastReportParams {
  otel_id: number;
  start_date: string;
  end_date: string;
}

export const getForecastReport =
  (params: GetForecastReportParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FORECAST_REPORT, {
        params,
      });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveForecastReportResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
