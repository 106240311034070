import { AdminDashboardPage } from "Components/Custom/DashboardPages";
import useDashboard from "Components/Hooks/dashboard/useDashboard";
import PageContainer from "pages/PageContainer";

const AdminDashboard = () => {
  const {
    loading,
    error,
    isValidHotel,
    isLoading,
    otel_id,
    hotel_name,
  } = useDashboard();

  return (
    <PageContainer title="Admin | Ana Sayfa">
        <AdminDashboardPage
          data={true}
          loading={loading}
          error={error}
          isValidHotel={isValidHotel}
          isLoading={isLoading}
          otel_id={otel_id}
          hotel_name={hotel_name}
        />
    </PageContainer>
  );
};

export default AdminDashboard;
