import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  MailOrderInfoInitialState,
  handleSaveMailOrderStateForEdit,
  handleSetMailOrderFormErrors,
} from "slices/financeAndAccounting/mailOrderInfo/reducer";

import {
  getMailOrders,
  deleteMailOrder,
} from "slices/financeAndAccounting/mailOrderInfo/thunk";

const useHandleTable = () => {
  const { mailOrders } = useSelector(
    (state: { MailOrderInfo: MailOrderInfoInitialState }) => state.MailOrderInfo
  );

  const dispatch: Dispatch<any> = useDispatch();

  const getMailOrdersForCallback = () => {
    dispatch(getMailOrders());
  };

  const deleteMailOrderById = (id: number) => {
    const payload = {
      id,
      sil_durum: 1 as const,
    };
    dispatch(deleteMailOrder(payload, () => getMailOrdersForCallback()));
  };

  const handleClickedEdit = (id: number) => {
    const mailOrder = mailOrders.find((mailOrder) => mailOrder.id === id);

    if (mailOrder) {
      dispatch(handleSetMailOrderFormErrors({}));
      const order = {
        ...mailOrder,
        kart_numarasi: mailOrder.kart_numarasi.replaceAll(" ", ""),
      };
      dispatch(handleSaveMailOrderStateForEdit(order));
      window.scrollTo(0, 0);
    }
  };

  const tableData = mailOrders?.map((mailOrder) => ({
    ID: mailOrder.id,
    "Kart Sahibi": mailOrder.kart_sahibi,
    "Kart Kodu": mailOrder.kart_kodu,
    "Banka Adı": mailOrder.adi,
    "Kart Numarası": mailOrder.kart_numarasi,
    "Kart Son Kullanım": mailOrder.kart_son_kullanim,
    "Kart CVC": mailOrder.kart_cvc,
    "Otel Ödeme": mailOrder.otel_odeme,
  }));

  const tableColumns = [
    {
      header: "Kart Sahibi",
      accessorKey: "Kart Sahibi",
      enableColumnFilter: false,
    },
    {
      header: "Banka Adı",
      accessorKey: "Banka Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kart Numarası",
      accessorKey: "Kart Numarası",
      enableColumnFilter: false,
    },
    {
      header: "Kart Son Kullanım",
      accessorKey: "Kart Son Kullanım",
      enableColumnFilter: false,
    },
    {
      header: "Kart CVC",
      accessorKey: "Kart CVC",
      enableColumnFilter: false,
    },
    {
      header: "Otel Ödeme",
      accessorKey: "Otel Ödeme",
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <span className={`text-${value === "1" ? "success" : "danger"}`}>
            {value === "1" ? "Evet" : "Hayır"}
          </span>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "ID",
      cell: (cell: any) => {
        const id = cell.getValue();

        return (
          <div className="d-flex gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={() => handleClickedEdit(id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => deleteMailOrderById(id)}
            />
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
