import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";
import { getMatchedHotels } from "slices/adsAndAnalyze/adsGroups/thunk";

import { PaginationBottom } from "types/GlobalTypes";

let firstRender = true;

interface GetMatchedHotelsPayload {
  page: number;
  per_page: number;
  search: string;
}

const useHandlePagination = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [paginationTop, setPaginationTop] = useState({
    per_page: 10,
    search: "",
  });

  const { matchedHotelPagination: pagination } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  const handleClickNextPage = () => {
    const payload: GetMatchedHotelsPayload = {
      ...paginationTop,
      page: pagination.current_page + 1,
    };

    pagination.next_page_url && dispatch(getMatchedHotels(payload));
  };

  const handleClickPrevPage = () => {
    const payload: GetMatchedHotelsPayload = {
      ...paginationTop,
      page: pagination.current_page - 1,
    };

    pagination.prev_page_url && dispatch(getMatchedHotels(payload));
  };

  const paginationBottomProps: PaginationBottom = {
    total: pagination.total,
    current_page: pagination.current_page,
    per_page: pagination.per_page,
    prev_page_url: pagination.prev_page_url,
    next_page_url: pagination.next_page_url,
    onClickNextPage: handleClickNextPage,
    onClickPrevPage: handleClickPrevPage,
  };

  const handleChange = (key: string, value: number | string) => {
    if (firstRender) {
      firstRender = false;
      return;
    }
    setPaginationTop((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    const payload = {
      ...paginationTop,
      [key]: value,
      page: 1,
    };

    dispatch(getMatchedHotels(payload));
  };

  return {
    handleChange,
    paginationBottomProps,
  };
};

export default useHandlePagination;
