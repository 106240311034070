import axios from "axios";

import { POST_BASIC_QUOTA_ENTRIES } from "helpers/url_helper";
import { handleErrors } from "helpers/axios_helpers";
import { setIsLoading, setSuccess, setError } from "./reducer";
import { IncomingQuota } from "types/PricesQuotas";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

interface RequestPayload {
  otel_id: number;
  odalar: IncomingQuota[];
}

export const postBasicQuotaEntries =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(POST_BASIC_QUOTA_ENTRIES, payload);
      dispatch(setSuccess("Kontenjanlar kaydedildi."));
      dispatch(setToastSuccess("Kontenjanlar kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
