import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { handleSelectRoom } from "slices/pricesAndQuota/childInformation/reducer";
import {
  AdvancedEntryInitialState,
  handleIsAllDaysSelected,
  handleSaveChilSpecialAgeId,
  handleSaveNights,
  handleSaveSelectedConcept,
  handleSelectedDates,
} from "slices/pricesAndQuota/advancedEntry/reducer";
import { getPeriods } from "slices/pricesAndQuota/advancedEntry/thunk";
import moment from "moment";

const useAdvancedEntry = (
  cocuk_yas_id: string,
  satis_tarihi_baslangic: number,
  satis_tarihi_bitis: number,
  gun: number,
  selectedConcept: number
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );
  const { selectedRoom, isLoading } = useSelector(
    (state: { AdvancedEntry: AdvancedEntryInitialState }) => state.AdvancedEntry
  );

  const otel_id = hotel.otel_id;
  const rooms = hotel.odalar.map((room) => ({
    otel_id: room.otel_id,
    oda_id: room.id,
    oda_isim: room.oda_isim,
  }));

  const hotelAndSelectedRoom = {
    otel_id,
    oda_id: selectedRoom,
  };

  const tarihler = `${moment(satis_tarihi_baslangic).format(
    "YYYY-MM-DD"
  )} - ${moment(satis_tarihi_bitis).format("YYYY-MM-DD")}`;

  useEffect(() => {
    if (
      otel_id &&
      rooms.length > 0 &&
      cocuk_yas_id &&
      satis_tarihi_baslangic &&
      satis_tarihi_bitis &&
      gun &&
      selectedConcept
    ) {
      dispatch(handleSelectRoom(selectedRoom || rooms[0].oda_id));
      dispatch(
        getPeriods({
          otel_id,
          oda_id: selectedRoom || rooms[0].oda_id,
          pansiyon_id: selectedConcept,
          tarihler,
          tum_gunler: 1,
          gunler: [],
          geceleme_gunu: gun,
          cocuk_ozel_yas_id: Number(cocuk_yas_id),
        })
      );
      dispatch(handleSaveSelectedConcept(selectedConcept));
      dispatch(
        handleSelectedDates({
          startDate: moment(satis_tarihi_baslangic).format("YYYY-MM-DD"),
          endDate: moment(satis_tarihi_bitis).format("YYYY-MM-DD"),
        })
      );
      dispatch(handleSaveNights(gun));
      dispatch(handleIsAllDaysSelected(true));
      dispatch(handleIsAllDaysSelected(true));
      dispatch(handleSaveChilSpecialAgeId(Number(cocuk_yas_id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id, cocuk_yas_id, satis_tarihi_baslangic, satis_tarihi_bitis, gun]);

  const isValidHotel = rooms.length > 0;

  return {
    error,
    isLoading,
    loading,
    rooms,
    hotelAndSelectedRoom,
    isValidHotel,
  };
};

export default useAdvancedEntry;
