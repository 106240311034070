import { createSlice } from "@reduxjs/toolkit";
import {
  CallsDueLaterCardData,
  CrmDashboardData,
  CrmDashboardResponse,
  ExtensionDetailCardData,
  ExtensionNumberListCardData,
  ExtensionSituationCardData,
  MonthlySalesListCardData,
} from "types/dashboard";

export interface CrmDashboardInitialState {
  crmDashboardData: CrmDashboardData;
  isLoading: boolean;
  error: string;
}

export const crmDashboardInitialState: CrmDashboardInitialState = {
  crmDashboardData: {
    dahili_listesi: {},
    aylik_satis_listesi: {
      satis_gruplari: {},
      month_name: "",
    },
    dahili_durum: {},
    sonra_aranacaklar: {},
    personel_aktivite_sureler: {
      mesgulde_gecen_sure: "",
      yemekte_gecen_sure: "",
    },
  },
  isLoading: false,
  error: "",
};

const crmDashboardSlice = createSlice({
  name: "crmDashboard",
  initialState: crmDashboardInitialState,
  reducers: {
    handleSaveCrmDashboard(state, action) {
      const payload = action.payload as CrmDashboardResponse;

      const dahili_listesi: ExtensionNumberListCardData = {};
      for (const key in payload.dahili_listesi) {
        if (payload.dahili_listesi.hasOwnProperty(key)) {
          const group = payload.dahili_listesi[key];
          const uyelerArray = Array.isArray(group.uyeler)
            ? group.uyeler
            : Object.values(group.uyeler);
          dahili_listesi[key] = {
            grup_adi: group.grup_adi,
            uyeler: uyelerArray.map((member: any) => ({
              id: member.id,
              ad_soyad: member.ad_soyad,
              email_adresi: member.email_adresi,
              dahili: member.dahili,
            })),
          };
        }
      }

      const satis_gruplari: MonthlySalesListCardData = {};
      for (const key in payload.aylik_satis_listesi.satis_gruplari) {
        if (payload.aylik_satis_listesi.satis_gruplari.hasOwnProperty(key)) {
          const group = payload.aylik_satis_listesi.satis_gruplari[key];
          const personnel = group.personeller.map((person) => ({
            uye_id: person.uye_id,
            ad_soyad: person.ad_soyad,
            toplam_satis: person.toplam_satis,
            kapida_toplam_satis: person.kapida_toplam_satis,
            toplam_oda_sayisi: person.toplam_oda_sayisi,
          }));

          const firstTwoPersonnel = personnel.slice(0, 2);

          const firstTwoPersonnelSum = firstTwoPersonnel.reduce(
            (sum, personnel) => sum + personnel.toplam_oda_sayisi,
            0
          );

          const firstTwoPersonnelRatio = firstTwoPersonnelSum * 0.75;

          const degerlendirilenOdaOrtalamasi =
            personnel.length > 0 ? firstTwoPersonnelRatio / 2 : 0;

          satis_gruplari[key] = {
            grup_id: group.grup_id,
            grup_adi: group.grup_adi,
            grup_aciklama: group.grup_aciklama,
            personeller: personnel,
            degerlendirilen_oda_ortalaması: Math.ceil(
              degerlendirilenOdaOrtalamasi
            ),
          };
        }
      }

      const aylik_satis_listesi = {
        satis_gruplari,
        month_name: payload.aylik_satis_listesi.month_name,
      };

      const dahili_durum: ExtensionSituationCardData = {};
      for (const key in payload.dahili_durum) {
        if (payload.dahili_durum.hasOwnProperty(key)) {
          const item = payload.dahili_durum[key];
          const dahili_durum_details: ExtensionDetailCardData = {};
          for (const detailKey in item.dahili_durum) {
            if (item.dahili_durum.hasOwnProperty(detailKey)) {
              const detail = item.dahili_durum[detailKey];
              dahili_durum_details[detailKey] = {
                tarih: detail.tarih,
                ilk_islem_zamani: detail.ilk_islem_zamani,
                son_islem_zamani: detail.son_islem_zamani,
                cevrimici: detail.cevrimici,
                mesgul: detail.mesgul,
                yemekte: detail.yemekte,
                disarida: detail.disarida,
                cevrimdisi: detail.cevrimdisi,
                gorusme: detail.gorusme,
              };
            }
          }
          dahili_durum[key] = {
            uye_id: item.uye_id,
            ad_soyad: item.ad_soyad,
            dahili: item.dahili,
            grup_adi: item.grup_adi,
            dahili_durum: dahili_durum_details,
          };
        }
      }

      const sonra_aranacaklar: CallsDueLaterCardData =
        payload.sonra_aranacaklar;

      const personel_aktivite_sureler = payload.personel_aktivite_sureler;

      const dashboard: CrmDashboardData = {
        dahili_listesi,
        aylik_satis_listesi,
        dahili_durum,
        sonra_aranacaklar,
        personel_aktivite_sureler,
      };

      state.crmDashboardData = dashboard;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveCrmDashboard, setIsLoading, setError } =
  crmDashboardSlice.actions;

export default crmDashboardSlice.reducer;
