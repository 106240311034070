import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import useValidateForm from "./useValidateForm";
import { FacilityImagesInitialState } from "slices/facility/facilityImages/reducer";

const useHandleForm = () => {
  const selectedFacilityImagesState = (state: {
    FacilityImages: FacilityImagesInitialState;
  }) => state.FacilityImages;

  const selectFacilityImagesProperties = createSelector(
    selectedFacilityImagesState,
    (state) => ({
      facilityImages: state.facilityImages,
      facilityPullImageFormUrl: state.facilityPullImageFormUrl,
      facilityPullImageFormUrlErrors: state.facilityPullImageFormUrlErrors,
      addImagesLoading: state.addImagesLoading,
    })
  );

  const {
    facilityPullImageFormUrl,
    facilityPullImageFormUrlErrors,
    addImagesLoading,
  } = useSelector(selectFacilityImagesProperties);

  const facilityImagesRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    facilityPullImageFormUrl,
    facilityImagesRequestPayload,
    formErrors: facilityPullImageFormUrlErrors,
    isLoading: addImagesLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
