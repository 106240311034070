import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";

import UnderTheDeskDiscountsForm from "./UnderTheDeskDiscountsForm";
import UnderTheDeskDiscountsTable from "./UnderTheDeskDiscountsTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./UnderTheDeskDiscountsForm/RoomSelector";

interface UnderTheDeskDiscountsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const UnderTheDeskDiscountsPage = (props: UnderTheDeskDiscountsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={[]}
      roomSelector={<RoomSelector {...props} />}
      isLoading={isLoading}
    >
      <UnderTheDeskDiscountsForm {...props} />
      <UnderTheDeskDiscountsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(UnderTheDeskDiscountsPage);
