import DailyCallsForHotelsTable from "./DailyCallsForHotelsTable";
import FilterForm from "./FilterForm";

interface DailyCallsForHotelsPageProps {
  isLoading: boolean;
}

const DailyCallsForHotelsPage = ({
  isLoading,
}: DailyCallsForHotelsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <DailyCallsForHotelsTable isLoading={isLoading} />
    </div>
  );
};

export default DailyCallsForHotelsPage;
