import { Col, Input, Label, Row } from "reactstrap";

interface ColumnCtaCtdFieldrops {
  payMethod: string;
  date: string;
  values: any;
  onChange: (name: string, value: any) => void;
  notAllowedToEdit: boolean;
}

const ColumnCtaCtdField = ({
  payMethod,
  values,
  onChange,
  date,
  notAllowedToEdit,
}: ColumnCtaCtdFieldrops) => {
  const ctaFieldName = `${date}-cta`;
  const ctdFieldName = `${date}-ctd`;

  const ctaFieldId = `${payMethod}-${ctaFieldName}`;
  const ctdFieldId = `${payMethod}-${ctdFieldName}`;

  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <Input
            id={ctaFieldId}
            type="checkbox"
            className="form-check-input mt-0"
            checked={parseInt(values[ctaFieldName]) === 1}
            onChange={(e) => onChange(ctaFieldName, e.target.checked ? 1 : 0)}
            disabled={notAllowedToEdit}
          />
          <Label htmlFor={ctaFieldId} className="m-0 fs-14">
            CTA
          </Label>
        </div>
      </Col>
      <Col xs={6}>
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-input mt-0"
            id="ctd"
            checked={parseInt(values[ctdFieldName]) === 1}
            onChange={(e) => onChange(ctdFieldName, e.target.checked ? 1 : 0)}
            disabled={notAllowedToEdit}
          />
          <Label htmlFor={ctdFieldId} className="m-0 fs-14">
            CTD
          </Label>
        </div>
      </Col>
    </Row>
  );
};

export default ColumnCtaCtdField;
