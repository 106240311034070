import { useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

interface KismiPaymentModalProps {
  paymentDate: string;
  modal: boolean;
  toggle: () => void;
  onApprove: () => void;
}

const KismiPaymentModal = ({
  paymentDate,
  modal,
  toggle,
  onApprove,
}: KismiPaymentModalProps) => {
  const [isChecked, setIsChecked] = useState({
    date: false,
    installment: false,
  });

  const handleApprove = () => {
    if (isChecked.date && isChecked.installment) {
      onApprove();
      setIsChecked({ date: false, installment: false });
      toggle();
    }
  };

  const handleToggle = () => {
    setIsChecked({ date: false, installment: false });
    toggle();
  };

  return (
    <Modal isOpen={modal} toggle={handleApprove} size="lg">
      <ModalHeader toggle={toggle}>
        <span className="fw-medium fs-18 theme-text-orange">
          <i className="ri-information-fill me-2"></i>
          KISMİ ÖDEME KONTROL BİLGİLENDİRME
        </span>
      </ModalHeader>
      <ModalBody>
        <Label check className="mb-3">
          <Input
            type="checkbox"
            checked={isChecked.date}
            onChange={(e) =>
              setIsChecked({ ...isChecked, date: e.target.checked })
            }
          />
          <span className="fs-14 ms-2">
            Kalan ödeme {paymentDate} tarihinde tek çekim veya nakit olarak
            ödenecektir bilginize.
          </span>
        </Label>
        <Label check>
          <Input
            type="checkbox"
            checked={isChecked.installment}
            onChange={(e) =>
              setIsChecked({ ...isChecked, installment: e.target.checked })
            }
          />
          <span className="fs-14 ms-2">
            Misafir taksit ister (en fazla 3 taksit olarak yapılacaktır,
            bilgisini verdim)
          </span>
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleToggle}>
          Vazgeç
        </Button>
        <Button color="success" onClick={handleApprove}>
          Devam Et
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default KismiPaymentModal;
