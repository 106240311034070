import { CustomAvailabilityRadioInput } from "Components/Custom/UI";
import { STOPS } from "enums";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "redux";
import { handleAddChangedAvailability } from "slices/pricesAndQuota/basicAvailabilityEntry/reducer";
import { IncomingAvailability } from "types/PricesQuotas";

interface AvailabilityInputProps {
  name: string;
  allSelected: AllSelected;
  defaultValue: IncomingAvailability;
}

interface AllSelected {
  [key: number]: boolean;
}

const AvailabilityInput = ({ name, allSelected }: AvailabilityInputProps) => {
  const [availability, setAvailability] = useState<STOPS>(STOPS.YOK);

  const dispatch: Dispatch<any> = useDispatch();

  const notAllowed = allSelected[parseInt(name.split("-")[1])];

  const handleChangeAvailability = (availability: STOPS) => {
    handleAddAvailability(availability);
    setAvailability(availability);
  };

  useEffect(() => {
    notAllowed && setAvailability(STOPS.YOK);
  }, [notAllowed]);

  const handleAddAvailability = (availability: STOPS) => {
    const incomingAvailability: IncomingAvailability = {
      oda_id: parseInt(name.split("-")[0]),
      pansiyon_id: parseInt(name.split("-")[1]),
      musaitlik: availability,
    };

    dispatch(handleAddChangedAvailability(incomingAvailability));
  };

  return (
    <div className="d-flex gap-1 flex-nowrap border p-2 h-100">
      <CustomAvailabilityRadioInput
        name={name}
        value={STOPS.SATISA_ACIK}
        availability={availability}
        onChange={handleChangeAvailability}
        notAllowed={notAllowed}
      />
      <CustomAvailabilityRadioInput
        name={name}
        value={STOPS.SOR_SAT}
        availability={availability}
        onChange={handleChangeAvailability}
        notAllowed={notAllowed}
      />
      <CustomAvailabilityRadioInput
        name={name}
        value={STOPS.STOPLU}
        availability={availability}
        onChange={handleChangeAvailability}
        notAllowed={notAllowed}
      />
    </div>
  );
};

export default AvailabilityInput;
