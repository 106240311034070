import { CustomButton } from "Components/Custom/UI";

interface SaveGeneralPricesSettingsButtonProps {
  isLoading: boolean;
  onSave: () => void;
}

const SaveRoomPersonPriceButton = ({
  isLoading,
  onSave,
}: SaveGeneralPricesSettingsButtonProps) => {
  return (
    <div className="d-flex justify-content-end">
      <CustomButton
        variant="success"
        isLoading={isLoading}
        onClick={() => {
          onSave();
        }}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};
export default SaveRoomPersonPriceButton;
