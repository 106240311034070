import { CustomRowOperationButton } from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  FacilitySpecialDaysInitialState,
  handleSaveSpecialDayForEdit,
} from "slices/facility/facilitySpecialDays/reducer";
import {
  deleteFacilitySpecialDay,
  getFacilitySpecialDays,
} from "slices/facility/facilitySpecialDays/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import { FacilitySpecialDayState } from "types/facility";

const useHandleTable = () => {
  const { specialDays, specialDaysCategories } = useSelector(
    (state: { FacilitySpecialDays: FacilitySpecialDaysInitialState }) =>
      state.FacilitySpecialDays
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = specialDays.find((row) => row.id === id);

    if (selectedRow) {
      dispatch(handleSaveSpecialDayForEdit(selectedRow));
      window.scrollTo(0, 0);
    }
  };

  const handleDelete = (id: number) => {
    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("sil", "1");
    dispatch(
      deleteFacilitySpecialDay(formData, () => {
        dispatch(
          getFacilitySpecialDays({ otel_id, page: 1, per_page: 10, search: "" })
        );
      })
    );
  };

  const tableData: FacilitySpecialDayState[] = specialDays?.map(
    (specialDay) => ({
      id: specialDay.id,
      otel_id: specialDay.otel_id,
      kategori_id: specialDay.kategori_id,
      ozel_gun_baslik: specialDay.ozel_gun_baslik,
      ozel_gun_sanatci_adi: specialDay.ozel_gun_sanatci_adi,
      ozel_gun_aciklama: specialDay.ozel_gun_aciklama,
      ozel_gun_resim: specialDay.ozel_gun_resim,
      ozel_gun_resim_yol: specialDay.ozel_gun_resim_yol,
      ozel_gun_baslangic: specialDay.ozel_gun_baslangic,
      ozel_gun_bitis: specialDay.ozel_gun_bitis,
      resim: "",
    })
  );

  const tableColumns = [
    {
      header: "Kategori",
      accessorKey: "kategori_id",
      cell: (cell: any) => {
        const category = Object.entries(specialDaysCategories).find(
          ([key, value]) => value === cell.getValue()
        )?.[0];

        return <span className="fw-medium fs-16">{category}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Başlık",
      accessorKey: "ozel_gun_baslik",
      enableColumnFilter: false,
    },
    {
      header: "Tarih Aralığı",
      accessorKey: "ozel_gun_baslangic",
      cell: (cell: any) => {
        const startDate = moment(cell.row.original.ozel_gun_baslangic).format(
          "DD.MM.YYYY"
        );
        const endDate = moment(cell.row.original.ozel_gun_bitis).format(
          "DD.MM.YYYY"
        );

        return (
          <span>
            {startDate} - {endDate}
          </span>
        );
      },
      enableColumnFilter: false,
    },

    {
      header: "Sanatçı",
      accessorKey: "ozel_gun_sanatci_adi",
      enableColumnFilter: false,
    },
    {
      header: "",
      accessorKey: "ozel_gun_resim_yol",
      cell(cell: any) {
        const url = cell.getValue();
        const title = cell.row.original.ozel_gun_baslik;
        return (
          <div
            className="d-flex justify-content-end align-items-center"
            style={{
              minWidth: "400px",
            }}
          >
            <img
              className="rounded"
              src={url}
              alt={title}
              style={{ width: "300px", height: "200px", objectFit: "cover" }}
            />
          </div>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
