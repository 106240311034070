import { createSlice } from "@reduxjs/toolkit";
import { FACILITY_MANAGER_ROLE } from "enums";

import {
  FacilityManager,
  FacilityManagerFormErrors,
  FacilityManagerState,
} from "types/facility";

export interface FacilityManagersInitialState {
  facilityManagers: FacilityManager[];
  facilityManager: FacilityManagerState;
  facilityManagerFormErrors: FacilityManagerFormErrors;
  isLoading: boolean;
  error: string;
  addManagerLoading: boolean;
  addManagerError: string;
  updateManagerLoading: boolean;
  updateManagerError: string;
  deleteManagerLoading: boolean;
  deleteManagerError: string;
}

const initialState: FacilityManagersInitialState = {
  facilityManagers: [],
  facilityManager: {
    id: 0,
    unvan: "",
    yetkili_adi: "",
    mail: "",
    telefon: "",
    birim: FACILITY_MANAGER_ROLE.rezervasyon,
  },
  facilityManagerFormErrors: {},
  isLoading: false,
  error: "",
  addManagerLoading: false,
  addManagerError: "",
  updateManagerLoading: false,
  updateManagerError: "",
  deleteManagerLoading: false,
  deleteManagerError: "",
};

const facilityManagersSlice = createSlice({
  name: "facilityManagers",
  initialState,
  reducers: {
    handleSaveFacilityManagers(state, action) {
      state.facilityManagers = action.payload;
    },
    handleDeleteFromFacilityManagersWithId(state, action) {
      state.facilityManagers = state.facilityManagers.filter(
        (manager) => manager.id !== action.payload
      );
    },
    handleSaveFacilityManagerForEdit(state, action) {
      state.facilityManager = action.payload;
      state.facilityManagerFormErrors = {};
    },
    handleChangeFacilityManagerInput(state, action) {
      state.facilityManager = {
        ...state.facilityManager,
        ...action.payload,
      };
    },
    handleAddFacilityManagerFormErrors(state, action) {
      state.facilityManagerFormErrors = action.payload;
    },
    resetFacilityManager(state) {
      state.facilityManager = {
        id: 0,
        unvan: "",
        yetkili_adi: "",
        mail: "",
        telefon: "",
        birim: FACILITY_MANAGER_ROLE.rezervasyon,
      };
      state.facilityManagerFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setAddManagerLoading(state, action) {
      state.addManagerLoading = action.payload;
    },
    setAddManagerError(state, action) {
      state.addManagerError = action.payload;
    },
    setUpdateManagerLoading(state, action) {
      state.updateManagerLoading = action.payload;
    },
    setUpdateManagerError(state, action) {
      state.updateManagerError = action.payload;
    },
    setDeleteManagerLoading(state, action) {
      state.deleteManagerLoading = action.payload;
    },
    setDeleteManagerError(state, action) {
      state.deleteManagerError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityManagers,
  handleDeleteFromFacilityManagersWithId,
  handleSaveFacilityManagerForEdit,
  handleChangeFacilityManagerInput,
  handleAddFacilityManagerFormErrors,
  resetFacilityManager,
  setIsLoading,
  setError,
  setAddManagerLoading,
  setAddManagerError,
  setUpdateManagerLoading,
  setUpdateManagerError,
  setDeleteManagerLoading,
  setDeleteManagerError,
} = facilityManagersSlice.actions;

export default facilityManagersSlice.reducer;
