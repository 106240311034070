import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddEarlyReservationDiscountFormErrors } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  EarlyReservationDiscountFormErrors,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: EarlyReservationDiscountState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: EarlyReservationDiscountFormErrors = {};

    if (!values.satis_baslangic_tarihi || !values.satis_bitis_tarihi) {
      errors.rez_tarihi =
        "Rezervasyon tarihi başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (!values.konaklama_baslangic_tarihi || !values.konaklama_bitis_tarihi) {
      errors.konaklama_tarihi =
        "Konaklama tarihi başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (Number(values.tum_gunler) === 0 && values.gunler.length === 0) {
      errors.gun = "En az bir gün seçmelisiniz.";
    }

    if (!values.minimum_gece) {
      errors.min_gece_sayisi = "Minimum gece sayısı alanı boş bırakılamaz.";
    }
    if (!values.minimum_gece || values.minimum_gece < 1) {
      errors.min_gece_sayisi = "Minimum gece sayısı 1'den küçük olamaz.";
    }

    if (!values.oran || Number(values.oran) < 1) {
      errors.oran = "Erken rezervasyon indirim oranı 0'dan küçük olamaz.";
    }

    if (Number(values.oran) > 100) {
      errors.oran = "Erken rezervasyon indirim oranı 100'den büyük olamaz.";
    }

    if (
      !values.gercek_indirim_orani ||
      Number(values.gercek_indirim_orani) < 1
    ) {
      errors.gercek_indirim_orani = "Gerçek indirim oranı 0'dan küçük olamaz.";
    }

    if (Number(values.gercek_indirim_orani) > 100) {
      errors.gercek_indirim_orani =
        "Gerçek indirim oranı 100'den büyük olamaz.";
    }

    if (!values.gostermelik_indirim_orani) {
      errors.gostermelik_indirim_orani =
        "Göstermelik indirim oranı alanı boş bırakılamaz.";
    }

    if (
      !values.gostermelik_indirim_orani ||
      Number(values.gostermelik_indirim_orani) < 1
    ) {
      errors.gostermelik_indirim_orani =
        "Göstermelik indirim oranı 0'dan küçük olamaz.";
    }

    if (Number(values.gostermelik_indirim_orani) > 100) {
      errors.gostermelik_indirim_orani =
        "Göstermelik indirim oranı 100'den büyük olamaz.";
    }

    if (
      Number(values.tum_otel_pansiyonlar) === 0 &&
      values.otel_pansiyonlar.length === 0
    ) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }

    if (
      Number(values.tum_odeme_tipleri) === 0 &&
      values.odeme_tipleri.length === 0
    ) {
      errors.secili_odeme_tipleri = "En az bir ödeme tipi seçmelisiniz.";
    }

    if (Object.keys(errors).length > 0) {
      if (isScroll) {
        scrollToError();
      }

      if (showErrorToast) {
        dispatch(setToastError("Lütfen tüm alanları doldurunuz."));
      }

      dispatch(handleAddEarlyReservationDiscountFormErrors(errors));
    }

    dispatch(handleAddEarlyReservationDiscountFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: EarlyReservationDiscountState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};

export default useValidateForm;
