import { Button, ButtonGroup } from "reactstrap";
import { UserAuthority } from "types/userOperations";

interface AuthorityRowProps {
  authority: UserAuthority;
  level: number;
  selectedPermission?: string;
  onPermissionChange: (authorityId: number, permission: string) => void;
}

const AuthorityRow = ({
  authority,
  level,
  selectedPermission,
  onPermissionChange,
}: AuthorityRowProps) => {
  const { id, adi, turu } = authority;

  const handlePermissionClick = (permission: string) => {
    onPermissionChange(id, permission);
  };

  const getRowStyle = () => {
    switch (turu) {
      case "anayetki":
        return { backgroundColor: "#2364aa" };
      case "ortayetki":
        return { backgroundColor: "#5da9e9" };
      case "altyetki":
        return { backgroundColor: "#bfbdc1" };
      default:
        return {};
    }
  };

  const buttonGroup = [
    {type: "gorme", text: "Görme"},
    {type: "gor", text: "Gör"},
    {type: "duzenle", text: "Düzenle"},
  ]

  return (
    <div
      style={{
        paddingLeft: `${level * 20}px`,
        ...getRowStyle(),
        padding: "5px 10px",
        borderBottom: "1px solid #ccc",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{adi}</span>
      <div className="d-flex">
        <ButtonGroup>
            {buttonGroup.map((button, index) => (
                <Button
                key={index}
                style={{ background: "none", border: "none", padding: "0", marginRight: '24px'}}
                onClick={() => handlePermissionClick(button.type)}
                active={selectedPermission === button.type}
              >
                {selectedPermission === button.type ? (
                  <i className="ri-checkbox-fill" style={{marginRight: '8px'}}></i>
                ) : (
                  <i className="ri-checkbox-indeterminate-line" style={{marginRight: '8px'}}></i>
                )}
                <span>{button.text}</span>
              </Button>
            ))}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default AuthorityRow;
