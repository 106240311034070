import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { DailyCallsChartsInitialState } from "slices/reports/dailyCallsCharts/reducer";
import { getDailyCallsCharts } from "slices/reports/dailyCallsCharts/thunk";

const useDailyCallsCharts = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { DailyCallsCharts: DailyCallsChartsInitialState }) =>
      state.DailyCallsCharts
  );

  const params = {
    date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    dispatch(getDailyCallsCharts(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useDailyCallsCharts;
