import { HotelContractsPage } from "Components/Custom/FinanceAndAccountingPages";
import useHotelContracts from "Components/Hooks/financeAndAccounting/useHotelContracts";
import PageContainer from "pages/PageContainer";

const HotelContracts = () => {
  const { isLoading, loading, error, isValidHotel } = useHotelContracts();
  return (
    <PageContainer title="Otel Kontrat İşlemleri">
      <HotelContractsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default HotelContracts;
