import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface PersonnelRequestTableProps {
  isLoading: boolean;
}


const PersonnelRequestTable = ({ isLoading }: PersonnelRequestTableProps) => {

  const { tableData, tableColumns } = useHandleTable();

  return (
    <>
      <CustomReactTable
        fileName="Personel Talepleri"
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
      />
    </>
  );
};

export default PersonnelRequestTable;
