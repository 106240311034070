import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  getFacilityRoom,
  updateFacilityRoom,
} from "slices/facility/facilityRooms/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandleManipulateRoom = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const handleUpdateRoom = (oda_id: number, oda_isim_degistir: string) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_id", oda_id?.toString() || "");
    formData.append("oda_isim_degistir", oda_isim_degistir);

    dispatch(
      updateFacilityRoom(formData, () => {
        dispatch(
          getFacilityRoom({
            otel_id,
            oda_id: 0,
          })
        );
      })
    );
  };

  const handlePasifyRoom = (oda_id: number) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_id", oda_id?.toString() || "");
    formData.append("oda_pasifmi", "1");

    dispatch(
      updateFacilityRoom(formData, () => {
        dispatch(
          getFacilityRoom({
            otel_id,
            oda_id: 0,
          })
        );
      })
    );
  };

  const handleMakeRoomActive = (oda_id: number) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_id", oda_id?.toString() || "");
    formData.append("aktifmi", "1");

    dispatch(
      updateFacilityRoom(formData, () => {
        dispatch(
          getFacilityRoom({
            otel_id,
            oda_id: 0,
          })
        );
      })
    );
  };

  return {
    onUpdate: handleUpdateRoom,
    onPassify: handlePasifyRoom,
    onActivate: handleMakeRoomActive,
  };
};

export default useHandleManipulateRoom;
