import { createSlice } from "@reduxjs/toolkit";

import {
  CommissionPayment,
  CommissionPaymentCardInfo,
} from "types/financeAndAccounting";

export interface CommissionPaymentInitialState {
  commissionPayment: CommissionPayment;
  commissionPaymentCardInfo: CommissionPaymentCardInfo;
  cardInfoLoading: boolean;
  cardInfoError: string;
  makeCommissionPaymentLoading: boolean;
  makeCommissionPaymentError: string;
  isLoading: boolean;
  error: string;
}

const initialState: CommissionPaymentInitialState = {
  commissionPayment: {
    havale_bilgileri: {
      banka: "",
      sube: "",
      iban: "",
      hesap_sahibi: "",
    },
    muhasebe_ve_ileitism_bilgileri: "",
    tutar: "",
  },
  commissionPaymentCardInfo: {
    id: 0,
    bank_id: 0,
    bank: "",
    bankName: "",
    cardType: "",
    cardBrand: "",
    cardProgram: "",
    cvvRequired: false,
    installment: false,
    countryCode: "",
    cardLength: 0,
    cardBrandIcon: "",
    cardProgramIcon: "",
    installment_details: {},
  },
  isLoading: false,
  error: "",
  cardInfoLoading: false,
  cardInfoError: "",
  makeCommissionPaymentLoading: false,
  makeCommissionPaymentError: "",
};

const commissionPaymentSlice = createSlice({
  name: "commissionPayment",
  initialState,
  reducers: {
    handleSaveCommissionPayment(state, action) {
      state.commissionPayment = action.payload;
    },
    handleSaveCommissionPaymentCardInfo(state, action) {
      state.commissionPaymentCardInfo = action.payload;
    },
    resetCommissionPaymentCardInfo(state) {
      state.commissionPaymentCardInfo = {
        id: 0,
        bank_id: 0,
        bank: "",
        bankName: "",
        cardType: "",
        cardBrand: "",
        cardProgram: "",
        cvvRequired: false,
        installment: false,
        countryCode: "",
        cardLength: 0,
        cardBrandIcon: "",
        cardProgramIcon: "",
        installment_details: {},
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCardInfoLoading(state, action) {
      state.cardInfoLoading = action.payload;
    },
    setCardInfoError(state, action) {
      state.cardInfoError = action.payload;
    },
    setMakeCommissionPaymentLoading(state, action) {
      state.makeCommissionPaymentLoading = action.payload;
    },
    setMakeCommissionPaymentError(state, action) {
      state.makeCommissionPaymentError = action.payload;
    },
  },
});

export const {
  handleSaveCommissionPayment,
  setIsLoading,
  setError,
  handleSaveCommissionPaymentCardInfo,
  resetCommissionPaymentCardInfo,
  setCardInfoLoading,
  setCardInfoError,
  setMakeCommissionPaymentLoading,
  setMakeCommissionPaymentError,
} = commissionPaymentSlice.actions;

export default commissionPaymentSlice.reducer;
