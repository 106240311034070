import { useSelector } from "react-redux";
import { AdvancedEntryPage } from "Components/Custom/PricesAndQuotaPages";
import { HotelInitialState } from "slices/hotel/reducer";
import PageContainer from "pages/PageContainer";
import { useLocation } from "react-router-dom";
import useAdvancedEntry from "Components/Hooks/pricesAndQuota/useAdvanceEntry";

const AdvancedEntry = () => {
  const { hotel, loading, error } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const location = useLocation();
  const {
    cocuk_yas_id,
    satis_tarihi_baslangic,
    satis_tarihi_bitis,
    gun,
    selectedConcept,
  } = location.state || {};

  useAdvancedEntry(
    cocuk_yas_id,
    satis_tarihi_baslangic,
    satis_tarihi_bitis,
    gun,
    selectedConcept
  );
  const isValidHotel =
    !!hotel.otel_id && hotel.odalar.length > 0 && hotel.pansiyonlar.length > 0;

  return (
    <PageContainer title="Gelişmiş Giriş">
      <AdvancedEntryPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
      />
    </PageContainer>
  );
};

export default AdvancedEntry;
