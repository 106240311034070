import { Link } from "react-router-dom";

import { Form, Input, Label, FormFeedback } from "reactstrap";

import {
  CustomFormButton,
  CustomFormStatusMessages,
} from "Components/Custom/UI";
import LoginFormFooter from "../LoginFormFooter";

import useHandleForm from "./useHandleForm";

import { Router } from "types";

const LoginForm = (props: Router) => {
  const { validation, loader, setPasswordShow, passwordShow, error } =
    useHandleForm(props);

  return (
    <>
      <CustomFormStatusMessages error={error} />
      <div className="p-2 mt-1">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="mb-3">
            <Label htmlFor="username-input" className="form-label">
              Kullanıcı Adı
            </Label>
            <Input
              id="username-input"
              name="username"
              className="form-control"
              placeholder="Kullanıcı Adı"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.username || ""}
              invalid={
                validation.touched.username && validation.errors.username
                  ? true
                  : false
              }
            />
            {validation.touched.username && validation.errors.username ? (
              <FormFeedback type="invalid">
                {validation.errors.username}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label" htmlFor="password-input">
              Şifre
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="password-input"
                name="password"
                value={validation.values.password || ""}
                type={passwordShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Şifre"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div className="float-end mb-4">
            <Link to="/sifremi-unuttum" className="text-muted">
              Şifremi Unuttum
            </Link>
          </div>

          <div className="mt-4">
            <CustomFormButton
              type="submit"
              variant="success"
              isLoading={loader}
            >
              Giriş Yap
            </CustomFormButton>
          </div>
          <LoginFormFooter
            title="ve ya"
            label="Otelinizi Ekleyin"
            link="https://www.otelfiyat.com/otelinizi-ekleyin"
          />
        </Form>
      </div>
    </>
  );
};

export default LoginForm;
