import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import FacilitySpecialDaysForm from "./FacilitySpecialDaysForm";
import FacilitySpecialDaysTable from "./FacilitySpecialDaysTable";
import { CustomLoadingPage } from "Components/Custom/UI";

interface FacilitySpecialDaysPageProps {
  isLoading: boolean;
}

const FacilitySpecialDaysPage = ({
  isLoading,
}: FacilitySpecialDaysPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <FacilitySpecialDaysForm />
        <FacilitySpecialDaysTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default withPageNeedsHotel(FacilitySpecialDaysPage);
