import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityImagesInitialState } from "slices/facility/facilityImages/reducer";
import { getFacilityImages } from "slices/facility/facilityImages/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useFacilityImages = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const { isLoading } = useSelector(
    (state: { FacilityImages: FacilityImagesInitialState }) =>
      state.FacilityImages
  );

  useEffect(() => {
    if (otel_id) {
      dispatch(getFacilityImages(otel_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    error,
    isValidHotel,
    isLoading,
  };
};

export default useFacilityImages;
