import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityCallInitialState } from "slices/facility/facilityCalls/reducer";
import { getFacilityCalls } from "slices/facility/facilityCalls/thunk";

const useFacilityCalls = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  useEffect(() => {
    dispatch(
      getFacilityCalls({
        page: 1,
        per_page: 10,
        search: "",
        start_date: "",
        end_date: "",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useFacilityCalls;
