import { createSlice } from "@reduxjs/toolkit";

import {
  WelcomeAndCalculation,
  UpdateWelcomeAndCalculation,
  UpdateWelcomeAndCalculationState,
} from "types/reports";

export interface WelcomeAndCalculationInitialState {
  welcomeAndCalculation: WelcomeAndCalculation[];
  selectedHotel: number;
  updateWelcomeAndCalculation: UpdateWelcomeAndCalculation;
  updateWelcomeAndCalculationState: UpdateWelcomeAndCalculationState;
  isLoading: boolean;
  error: string;
  updateFormLoading: boolean;
  updateFormError: string;
}

const initialState: WelcomeAndCalculationInitialState = {
  welcomeAndCalculation: [],
  selectedHotel: 0,
  updateWelcomeAndCalculation: {
    otel_adi: "",
    karsilama_kodu: [
      {
        value: "",
        text: "",
        type: "radio",
        name: "",
        checked: "",
      },
    ],
    fiyat_hesaplama: [
      {
        value: "",
        text: "",
        type: "radio",
        name: "",
        checked: "",
      },
    ],
    yetkililik: [
      {
        value: "",
        text: "",
        type: "radio",
        name: "",
        checked: "",
      },
    ],
    reklam: [
      {
        value: "",
        text: "",
        type: "radio",
        name: "",
        checked: "",
      },
    ],
    sorunlu_reklam: "",
  },
  updateWelcomeAndCalculationState: {
    otel_id: 0,
    karsilama_kodu: "",
    fiyat_hesaplama: "",
    yetkililik: "",
    reklam: "",
    sorunlu_reklam: "",
  },
  isLoading: false,
  error: "",
  updateFormLoading: false,
  updateFormError: "",
};

const welcomeAndCalculationSlice = createSlice({
  name: "welcomeAndCalculation",
  initialState,
  reducers: {
    handleSaveWelcomeAndCalculation(state, action) {
      state.welcomeAndCalculation = action.payload;
    },
    handleSaveUpdateWelcomeAndCalculation(state, action) {
      const payload: UpdateWelcomeAndCalculation = action.payload;
      state.updateWelcomeAndCalculation = payload;

      const findValue = (payload: UpdateWelcomeAndCalculation, key: string) => {
        const value =
          payload[
            key as unknown as
              | "karsilama_kodu"
              | "fiyat_hesaplama"
              | "yetkililik"
              | "reklam"
          ].find((item) => item.checked === "checked")?.value ?? "";

        return value;
      };

      state.updateWelcomeAndCalculationState = {
        otel_id: state.selectedHotel,
        karsilama_kodu: findValue(payload, "karsilama_kodu"),
        fiyat_hesaplama: findValue(payload, "fiyat_hesaplama"),
        yetkililik: findValue(payload, "yetkililik"),
        reklam: findValue(payload, "reklam"),
        sorunlu_reklam: payload.sorunlu_reklam,
      };
    },

    handleChangeInput(state, action) {
      state.updateWelcomeAndCalculationState = {
        ...state.updateWelcomeAndCalculationState,
        ...action.payload,
      };
    },
    handleSelectHotel(state, action) {
      state.selectedHotel = action.payload;
    },
    handleSetDefaultToUpdateWelcomeAndCalculationState(state) {
      const payload = state.updateWelcomeAndCalculation;

      const findValue = (payload: UpdateWelcomeAndCalculation, key: string) => {
        const value =
          payload[
            key as unknown as
              | "karsilama_kodu"
              | "fiyat_hesaplama"
              | "yetkililik"
              | "reklam"
          ].find((item) => item.checked === "checked")?.value ?? "";

        return value;
      };

      state.updateWelcomeAndCalculationState = {
        otel_id: state.selectedHotel,
        karsilama_kodu: findValue(payload, "karsilama_kodu"),
        fiyat_hesaplama: findValue(payload, "fiyat_hesaplama"),
        yetkililik: findValue(payload, "yetkililik"),
        reklam: findValue(payload, "reklam"),
        sorunlu_reklam: payload.sorunlu_reklam,
      };
    },
    resetUpdateWelcomeAndCalculationState(state) {
      state.updateWelcomeAndCalculationState = {
        otel_id: 0,
        karsilama_kodu: "",
        fiyat_hesaplama: "",
        yetkililik: "",
        reklam: "",
        sorunlu_reklam: "",
      };

      state.selectedHotel = 0;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateFormLoading(state, action) {
      state.updateFormLoading = action.payload;
    },
    setUpdateFormError(state, action) {
      state.updateFormError = action.payload;
    },
  },
});

export const {
  handleSaveWelcomeAndCalculation,
  handleSaveUpdateWelcomeAndCalculation,
  handleChangeInput,
  handleSelectHotel,
  handleSetDefaultToUpdateWelcomeAndCalculationState,
  resetUpdateWelcomeAndCalculationState,
  setIsLoading,
  setError,
  setUpdateFormLoading,
  setUpdateFormError,
} = welcomeAndCalculationSlice.actions;

export default welcomeAndCalculationSlice.reducer;
