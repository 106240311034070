import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { getTheme } from "slices/themes/listThemes/thunk";

import { ListThemesInitialState } from "slices/themes/listThemes/reducer";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectedListThemesState = (state: {
    ListThemes: ListThemesInitialState;
  }) => state.ListThemes;

  const selectListThemesProperties = createSelector(
    selectedListThemesState,
    (state) => ({
      selectedTheme: state.selectedTheme,
    })
  );

  const { selectedTheme } = useSelector(selectListThemesProperties);

  const handleSelectTheme = (tema_id: number) => {
    dispatch(getTheme({ tema_id }));
  };

  return {
    selectedTheme,
    handleSelectTheme,
  };
};

export default useHandleForm;
