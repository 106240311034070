import FullScreenDropdown from "../../Components/Common/FullScreenDropdown";
import NotificationDropdown from "../../Components/Common/NotificationDropdown";
import ProfileDropdown from "../../Components/Common/ProfileDropdown";

import { changeSidebarVisibility } from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import HotelSearchByName from "./HotelSearchByName";
import RightSidebar from "Components/Common/RightSidebar";
import { Link } from "react-router-dom";

const Header = ({ headerClass }: any) => {
  const dispatch: any = useDispatch();

  const selectDashboardData = createSelector(
    (state: any) => state.Layout,
    (sidebarVisibilitytype) => sidebarVisibilitytype.sidebarVisibilitytype
  );
  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    dispatch(changeSidebarVisibility("show"));

    if (windowSize > 767) humberIcon.classList.toggle("open");

    //For collapse vertical menu
    if (
      sidebarVisibilitytype === "show" &&
      document.documentElement.getAttribute("data-layout") === "vertical"
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
  };

  return (
    <header
      id="page-topbar"
      className={headerClass}
      style={{
        maxHeight: "50px",
      }}
    >
      <div className="layout-width">
        <div
          className="navbar-header"
          style={{
            maxHeight: "50px",
          }}
        >
          <div className="d-flex">
            <button
              onClick={toogleMenuBtn}
              type="button"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger py-0"
              id="topnav-hamburger-icon"
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
            <HotelSearchByName />
            <div className="d-flex align-items-center gap-2 ms-5">
              <Link
                to="/fiyat-hesapla"
                className="border rounded text-decoration-none px-3 py-2 theme-bg-primary text-white fw-semibold fs-14"
              >
                Fiyat Hesapla
              </Link>
              <Link
                to="/rezervasyon-cagri?aranan=AMBER-PALACE-HOTEL_02129124215-902129124215&arayan=23004242427&dahili=723-IDEALTUR"
                className="border rounded text-decoration-none p-1 theme-bg-light text-white fw-semibold"
                style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  //TODO: resetForm();
                }}
              >
                <i className="ri-phone-line fs-20 text-dark"></i>
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <FullScreenDropdown />
            <NotificationDropdown />
            <RightSidebar />
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
