import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import { ListUsersInitialState } from "slices/userOperations/listUsers/reducer";
import {
  changeUserStatus,
  getUsers,
} from "slices/userOperations/listUsers/thunk";

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { pagination } = useSelector(
    (state: { ListUsers: ListUsersInitialState }) => state.ListUsers
  );

  const handleUpdateUser = (id: number) => {
    if (id !== null) {
      navigate(`/kullanici-duzenle?uye_id=${id}`);
    }
  };

  const handleChangeUserStatus = (statu: string, id: number) => {

    const newStatus = statu === "1" ? "0" : "1"; 

    dispatch(
      changeUserStatus({ statu: newStatus, id }, () => {
        dispatch(
          getUsers({
            page: 1,
            per_page: pagination.per_page,
            search: "",
          })
        );
      })
    );
  };

  return {
    onUpdate: handleUpdateUser,
    onChangeUserStatus: handleChangeUserStatus,
  };
};

export default useHandleRequests;
