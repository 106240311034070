import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSavePhoneStatusList, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_PHONE_STATUS_LIST } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetPhoneStatusListRequestPayload {
  date: string;
}

export const getPhoneStatusList =
  (payload: GetPhoneStatusListRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_PHONE_STATUS_LIST, payload);

      dispatch(handleSavePhoneStatusList(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
