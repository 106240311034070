import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import withRouter from "../../Components/Common/withRouter";

import { LoginPage } from "Components/Custom";

import { Router } from "types";

const Login = (props: Router) => {
  document.title = "Giriş Yap - otelfiyat.com Admin Panel";

  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <LoginPage {...props} />
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(Login);
