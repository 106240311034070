import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ReservationChangeApproveCancelInitialState } from "slices/userOperations/reservationChangeApproveCancel/reducer";
import {
  changeReservationApproveCancel,
  getReservationAmount,
} from "slices/userOperations/reservationChangeApproveCancel/thunk";
import { ReservationChangeApproveCancelPayload } from "types/userOperations";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices, resetReservationAmountLoading} = useSelector(
    (state: {
      ReservationChangeApproveCancel: ReservationChangeApproveCancelInitialState;
    }) => state.ReservationChangeApproveCancel
  );

  const [formState, setFormState] =
    useState<ReservationChangeApproveCancelPayload>({
      rez_kodu: "",
      islem: "",
    });

  const [showResetAmount, setShowResetAmount] = useState(false);

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeApproveCancel = () => {
    const params = {
      rez_kodu: formState.rez_kodu,
      islem: formState.islem,
    };
    
    if (formState.islem === "sifirla") {
      dispatch(getReservationAmount(params.rez_kodu));
      setShowResetAmount(true);
    } else {
      dispatch(changeReservationApproveCancel(params));
    }
  };

  const handleResetForm = () => {
    setFormState({
      rez_kodu: "",
      islem: "",
    });
    setShowResetAmount(false);
  };

  const operationOptions = formChoices.map((item) => ({
    value: item.value,
    label: item.text,
  }));

  return {
    resetReservationAmountLoading,
    showResetAmount,
    formState,
    operationOptions,
    handleFormChange,
    handleSave: handleChangeApproveCancel,
    handleClearForm: handleResetForm,
  };
};

export default useHandleForm;
