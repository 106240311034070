import { Input, Label } from "reactstrap";
import { CreditCardInstallment } from "types/GlobalTypes";

import styles from "./CustomCreditCard.module.scss";

interface InstallmentsListProps {
  hasInstallments: boolean;
  installments: CreditCardInstallment[];
  selectedInstallment: CreditCardInstallment;
  onSelectedInstallment: (installment: CreditCardInstallment) => void;
}

const InstallmentsList = ({
  hasInstallments,
  installments,
  selectedInstallment,
  onSelectedInstallment,
}: InstallmentsListProps) => {
  return (
    <>
      {hasInstallments && (
        <div className={styles.container__installments}>
          <p className="fs-16 fw-semibold m-0">Taksit Seçenekleri</p>
          {installments.map((installment) => (
            <Label
              key={installment.month}
              className={`cursor-pointer m-0 rounded ${
                selectedInstallment.month === installment.month
                  ? "bg-success bg-opacity-25 border border-success"
                  : ""
              }`}
              for={"installment" + installment.month.toString()}
            >
              <div className={styles.container__installment}>
                <div className="d-flex gap-2 align-items-center">
                  <Input
                    id={"installment" + installment.month.toString()}
                    className="m-0"
                    type="radio"
                    name="installment"
                    value={installment.month}
                    onChange={(e) => {
                      e.target.checked && onSelectedInstallment(installment);
                    }}
                    checked={selectedInstallment.month === installment.month}
                  />

                  {installment.month === 1 ? (
                    <span className="fs-13 fw-normal">Tek Çekim</span>
                  ) : (
                    <>
                      <span className="fs-13 fw-normal">
                        {installment.month} Taksit x{" "}
                      </span>
                      <span className="fw-medium fs-14">
                        {installment.amount}{" "}
                        <span className="fs-10 fw-normal">TL</span>
                      </span>
                    </>
                  )}
                </div>
                <span className="fs-14 fw-semibold">
                  {installment.total}
                  <span className="fs-12 fw-normal ms-1">TL</span>
                </span>
              </div>
            </Label>
          ))}
        </div>
      )}
    </>
  );
};

export default InstallmentsList;
