import FilterForm from "./FilterForm";
import RoomsLackPictureTable from "./RoomsLackPictureTable";

interface RoomsLackPicturePageProps {
  isLoading: boolean;
}

const RoomsLackPicturePage = ({ isLoading }: RoomsLackPicturePageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <RoomsLackPictureTable isLoading={isLoading} />
    </div>
  );
};

export default RoomsLackPicturePage;
