import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/userOperations/createNotification/reducer";
import { CreateNotificationFormChoices, CreateNotificationFormErrors, CreateNotificationPayload } from "types/userOperations";


interface NotificationTypeSelectorProps {
  values: CreateNotificationPayload,
  formChoices: CreateNotificationFormChoices;
  formErrors: CreateNotificationFormErrors;
  validateFormChange: (values: CreateNotificationPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const NotificationTypeSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange
}: NotificationTypeSelectorProps) => {
  
  const dispatch: Dispatch<any> = useDispatch();

  const options: Option[] = Object.entries(formChoices.bildirim_tipi).map(
    ([key, value]) => ({
      value: String(value.value),
      label: value.name,
    })
  );

  const selectedOption = options.find(
    (option) => option.value === values.tipi.toString()
  );

  return (
    <CustomFormFieldContainer 
      label={"Bildirim Tipi : "} 
      error={formErrors.tipi}
      divider
      required
      >
      <Select
        options={options}
        value={selectedOption}
        onChange={(selected: Option) => {
          const selectedType = selected ? selected.value : "";
          dispatch(
            handleChangeInput({
              tipi: selectedType,
            })
          );
          validateFormChange({ ...values, tipi: selected.value })
        }}
        placeholder="Bildirim tipi seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default NotificationTypeSelector;
