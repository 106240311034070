import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteDiscountCodeWithId,
  handleSaveDiscountCodeForEdit,
} from "slices/discountsAndActions/discountCodes/reducer";
import { deleteDiscountCode } from "slices/discountsAndActions/discountCodes/thunk";
import { DiscountCode, DiscountCodeState } from "types/DiscountsAndActions";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { discountCodes } = useSelector((state: any) => state.DiscountCodes);

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(handleSaveDiscountCodeForEdit(selectedRow));
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    dispatch(
      deleteDiscountCode({
        indirim_kuponu_id: id,
      }) as any
    );
    dispatch(handleDeleteDiscountCodeWithId(id));
  };

  const tableData: DiscountCodeState[] = discountCodes?.map(
    (discountCode: DiscountCode) => {
      return {
        id: discountCode.id,
        indirim_adi: discountCode.indirim_adi,
        indirim_orani: discountCode.indirim_orani,
        indirim_tutari: discountCode.indirim_tutari,
        indirim_tipi: discountCode.indirim_tipi,
        indirim_kuponu_kodu: discountCode.indirim_kuponu_kodu,
      } as DiscountCodeState;
    }
  );

  const tableColumns = [
    {
      header: "İndirim Adı",
      accessorKey: "indirim_adi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Tipi",
      accessorKey: "indirim_tipi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "indirim_orani",
      cell: (cell: any) => {
        const showRate = cell.row.original.indirim_tipi === "oran";
        return <span>{showRate ? cell.row.original.indirim_orani : "-"}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "İndirim Tutarı",
      accessorKey: "indirim_tutari",
      cell: (cell: any) => {
        const showAmount = cell.row.original.indirim_tipi === "tutar";
        return (
          <span>{showAmount ? cell.row.original.indirim_tutari : "-"}</span>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İndirim Kuponu Kodu",
      accessorKey: "indirim_kuponu_kodu",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
