import { getConceptStatusMessages } from "helpers/general_helpers";
import { CalculatePriceForHotelConceptPrice } from "types/reservation";

const useHandleIptalEdilemezPayButton = ({
  fiyatlar,
  priceCalculationStatusMessages,
  mayNotExist,
}: {
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  mayNotExist?: boolean;
}) => {
  if (mayNotExist) {
    return {
      hasPrice: false,
      stop: null,
      stopMessage: "",
      showIptalEdilemezButton: false,
      iptalEdilemezFiyatDurum: 0,
      iptalEdilemezPriceButtonText: "",
      iptalEdilemezPrice: 0,
      iptalEdilemezOverlinedPrice: 0,
      messages: [],
    };
  } else {
    const hasPrice = fiyatlar.iptal_edilemez.fiyat_durum === 1;

    //İptal Edilemez Ödeme
    const iptalEdilemez = fiyatlar.iptal_edilemez;

    const stop = iptalEdilemez.stop_durum ?? null;
    const stopMessage = iptalEdilemez.stop_durum_mesaj ?? "";

    const showIptalEdilemezButton = iptalEdilemez.fiyat_durum === 1;

    const iptalEdilemezFiyatDurum = iptalEdilemez.fiyat_durum ?? 0;
    const iptalEdilemezPriceButtonText = "Rezervasyon Yap";
    const iptalEdilemezPrice = iptalEdilemez.iptal_edilemez_indirimli ?? 0;
    const iptalEdilemezOverlinedPrice =
      iptalEdilemez.iptal_edilemez_gostermelik ?? 0;

    const hasPriceStatus = iptalEdilemez.fiyat_durum === 1;
    const hasQuotaStatus = iptalEdilemez.kontenjan_durum !== 0;
    const quotaMessage = iptalEdilemez.kontenjan_durum_mesaj;
    const minNightStatus = iptalEdilemez.minimum_gece_durum > 0;

    const messages = getConceptStatusMessages(
      priceCalculationStatusMessages,
      hasPriceStatus,
      hasQuotaStatus,
      quotaMessage,
      minNightStatus,
      fiyatlar?.iptal_edilemez?.minimum_gece_durum ?? 0
    );

    return {
      hasPrice,
      stop,
      stopMessage,
      showIptalEdilemezButton,
      iptalEdilemezFiyatDurum,
      iptalEdilemezPriceButtonText,
      iptalEdilemezPrice,
      iptalEdilemezOverlinedPrice,
      messages,
    };
  }
};

export default useHandleIptalEdilemezPayButton;
