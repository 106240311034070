import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleLocation = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const {
    otel_adi,
    vitrin,
    konum_aciklama,
    otel_adres,
    aciklamali_adres,
    google_map_x,
    google_map_y,
  } = calculatePriceForHotel;

  const location = {
    "Konum Açıklama": konum_aciklama,
    "Otel Adres": otel_adres,
    "Açıklamalı Adres": aciklamali_adres,
  };
  const googleMap = {
    google_map_x,
    google_map_y,
  };

  const title = otel_adi;
  const image = vitrin[0] || {
    resim_url: "",
  };

  return { location, googleMap, title, image };
};

export default useHandleLocation;
