import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilitySpecialDaysInitialState } from "slices/facility/facilitySpecialDays/reducer";
import { getFacilitySpecialDays } from "slices/facility/facilitySpecialDays/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useFacilitySpecialDays = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { FacilitySpecialDays: FacilitySpecialDaysInitialState }) =>
      state.FacilitySpecialDays
  );

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  useEffect(() => {
    if (otel_id) {
      dispatch(
        getFacilitySpecialDays({
          otel_id,
          page: 1,
          per_page: 10,
          search: "",
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    isLoading,
    error,
    isValidHotel,
  };
};

export default useFacilitySpecialDays;
