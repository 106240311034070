import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FACILITY_GOOGLE_MAPS_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityGoogleMapsCoordinatesProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityGoogleMapsCoordinates = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityGoogleMapsCoordinatesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Google Map X Koordinatı"
        error={formErrors.google_map_x}
        description={FACILITY_GOOGLE_MAPS_DESCRIPTION}
        divider
        required
      >
        <Input
          type="text"
          value={values.google_map_x}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                google_map_x: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              google_map_x: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Google Map Y Koordinatı"
        error={formErrors.google_map_y}
        description={FACILITY_GOOGLE_MAPS_DESCRIPTION}
        divider
        required
      >
        <Input
          type="text"
          value={values.google_map_y}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                google_map_y: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              google_map_y: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityGoogleMapsCoordinates;
