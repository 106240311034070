import RoomCard from "./RoomCard";
import useHandleRoomCards from "./useHandleRoomCards";

const RoomCards = () => {
  const { rooms } = useHandleRoomCards();

  return (
    <div className="d-flex flex-column gap-4">
      {rooms.map((room) => (
        <RoomCard key={room.oda_id} {...room} />
      ))}
    </div>
  );
};

export default RoomCards;
