import { Hotel } from "types/PricesQuotas";
import AvailabiltyEntryContainer from "./AvailabilityEntryContainer";
import AvailabilityEntryDateRangeSelector from "./AvailabilityEntryDateRangeSelector";

import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSaveHotel,
  resetAll,
} from "slices/pricesAndQuota/basicAvailabilityEntry/reducer";
import useHandleForm from "./useHandleForm";
import { useEffect } from "react";
import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { BASIC_AVAILABILITY_ENTRY_DESCRIPTIONS } from "utils/pageDescriptions";

interface BasicAvailabilityEntryPageProps {
  data: Hotel;
  isLoading: boolean;
}

const BasicAvailabilityEntryPage = ({
  data,
  isLoading,
}: BasicAvailabilityEntryPageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { handleSave, addAvailabilitiesLoading, ...props } = useHandleForm();

  dispatch(handleSaveHotel(data));

  useEffect(() => {
    dispatch(resetAll());
  }, [dispatch]);

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <CustomPageDescriptions
        descriptions={BASIC_AVAILABILITY_ENTRY_DESCRIPTIONS}
      />
      <AvailabilityEntryDateRangeSelector {...props} />
      <AvailabiltyEntryContainer
        data={data}
        isLoading={addAvailabilitiesLoading}
        onSave={handleSave}
      />
    </>
  );
};

export default withPageNeedsHotel(BasicAvailabilityEntryPage);
