import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";

interface FacilityStatusAndShowPriceListProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  statusOptions: Option[];
  showPriceListOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityStatusAndShowPriceList = ({
  values,
  validateFormChange,
  formErrors,
  statusOptions,
  showPriceListOptions,
}: FacilityStatusAndShowPriceListProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer
        label="Otelfiyat Aktiflik"
        error={formErrors.aktifmi}
        divider
      >
        <Select
          options={statusOptions}
          value={statusOptions.find(
            (option) => option.value === values.aktifmi
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                aktifmi: selected.value === "1" ? "1" : "0",
              })
            );
            validateFormChange({
              ...values,
              aktifmi: selected.value === "1" ? "1" : "0",
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Fiyat Listesi Göster"
        error={formErrors.fiyat_listesi_goster}
        divider
      >
        <Select
          options={showPriceListOptions}
          value={showPriceListOptions.find(
            (option) => option.value === values.fiyat_listesi_goster
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                fiyat_listesi_goster: selected.value === "e" ? "e" : "h",
              })
            );
            validateFormChange({
              ...values,
              fiyat_listesi_goster: selected.value === "e" ? "e" : "h",
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityStatusAndShowPriceList;
