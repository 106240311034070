import {
    CustomFormFieldContainer,
    CustomFormEditor,
  } from "Components/Custom/UI";
  import { useDispatch } from "react-redux";
  import { Dispatch } from "redux";
  import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
  import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
  
  interface BannerTwoExplanationProps {
      values: CreateWebSiteState;
      formErrors: CreateWebSiteFormErrors;
      validateFormChange: (values: CreateWebSiteState) => void;
  }
  
  const BannerTwoExplanation = ({
    values,
    formErrors,
    validateFormChange,
  }: BannerTwoExplanationProps) => {
    const dispatch: Dispatch<any> = useDispatch();
  
    return (
      <CustomFormFieldContainer
        label="Genel Açıklama"
        error={formErrors.banner2_icerik}
        orientation="vertical"
        required
      >
        <CustomFormEditor
          editorData={values.banner2_icerik}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                banner2_icerik: data,
              })
            );
            validateFormChange({
              ...values,
              banner2_icerik: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    );
  };
  
  export default BannerTwoExplanation;
  