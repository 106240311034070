import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { PhoneStatusInitialState } from "slices/reports/phoneStatus/reducer";
import { getAllPhoneStatus } from "slices/reports/phoneStatus/thunk";

const usePhoneStatus = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { PhoneStatus: PhoneStatusInitialState }) => state.PhoneStatus
  );

  useEffect(() => {
    dispatch(getAllPhoneStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default usePhoneStatus;
