import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import useValidateForm from "./useValidateForm";
import { ListWebSitesInitialState } from "slices/myWebSite/listWebSites/reducer";
import { addWebSite, getWebSitesList } from "slices/myWebSite/listWebSites/thunk";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    webSite,
    hotelsWithoutWebSite,
    webSiteFormErrors,
    addWebSiteLoading,
  } = useSelector(
    (state: { ListWebSites: ListWebSitesInitialState }) =>
      state.ListWebSites
  );


  const [hotelsWithoutWebSiteOptions, sethotelsWithoutWebSiteOptions] =
    useState<Option[]>([]);

  useEffect(() => {
    if (hotelsWithoutWebSite) {
      const options = Object.entries(hotelsWithoutWebSite).map(
        ([key, value]) => ({
          value: value.otel_id,
          label: value.otel_adi,
        })
      );
      sethotelsWithoutWebSiteOptions(options);
    }
  }, [hotelsWithoutWebSite]);

  const handleAddNewWebSite= () => {
    const payload = new FormData();

    payload.append("otel_id", webSite.otel_id.toString());
    payload.append("domain_link", webSite.domain_link.toString());
    payload.append("temax_type", webSite.temax_type);

    dispatch(
      addWebSite(payload, () => {
        dispatch(
          getWebSitesList()
        );
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    webSite,
    hotelsWithoutWebSite,
    hotelsWithoutWebSiteOptions,
    formErrors: webSiteFormErrors,
    addWebSiteLoading,
    handleAddNewWebSite,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
