import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveFormChoicesResponse,
  setIsLoading,
  setError,
  setTransferReservationLoading,
  setTransferReservationError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_PERSONNEL_FORM_CHOICES,
  TRANSFER_RESERVATION,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { TransferReservationPayload } from "types/userOperations";

export const getFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_PERSONNEL_FORM_CHOICES);
    const res = (response as any).message ? response.data : response;

    dispatch(handleSaveFormChoicesResponse(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const transferReservations =
  (payload: TransferReservationPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setTransferReservationLoading(true));
      await axios.post(TRANSFER_RESERVATION, payload);
      
      dispatch(setToastSuccess("Rezervasyon başarıyla aktarıldı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setTransferReservationError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setTransferReservationLoading(false));
    }
  };
