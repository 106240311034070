import { createSlice } from "@reduxjs/toolkit";
import {
  FacilityAllRoomsName,
  FacilityRoom,
  FacilityRoomImageFile,
  FacilityRoomName,
  UpdateFacilityRoomFormErrors,
  UpdateFacilityRoomState,
} from "types/facility";
import { Pagination } from "types/GlobalTypes";

type RoomImagesKeys = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface FacilityRoomsInitialState {
  facilityRoom: FacilityRoom;
  rooms: FacilityRoomName[];
  updateFacilityRoom: UpdateFacilityRoomState;
  facilityRoomImages: Record<RoomImagesKeys, string>;
  updateFacilityRoomFormErrors: UpdateFacilityRoomFormErrors;
  facilityRoomImageFiles: FacilityRoomImageFile[];
  isLoading: boolean;
  error: string;
  updateFacilityRoomLoading: boolean;
  updateFacilityRoomError: string;
  updateFacilityRoomFeaturesLoading: boolean;
  updateFacilityRoomFeaturesError: string;
  addFacilityRoomImagesLoading: boolean;
  addFacilityRoomImagesError: string;
  deleteFacilityRoomImageLoading: boolean;
  deleteFacilityRoomImageError: string;

  allRoomsNames: FacilityAllRoomsName[];
  pagination: Pagination;
  roomsLoading: boolean;
  roomsError: string;
}

const initialState: FacilityRoomsInitialState = {
  facilityRoom: {
    "tüm oda adları": [],
    "oda özellikleri": {
      zemin: [],
      genel: [],
      medya: [],
      banyo: [],
      yeme_icme: [],
      dış_mekan: [],
    },
    "oda bilgileri": [],
  },
  rooms: [],
  updateFacilityRoom: {
    oda_isim: "",
    aciklama: "",
    metrekare: "",
    oda_ozellikleri: [],
    oda_one_cikan_ozellikler: { 1: 0, 2: 0, 3: 0, 4: 0 },
  },
  facilityRoomImages: {
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  },

  updateFacilityRoomFormErrors: {},
  facilityRoomImageFiles: [],
  isLoading: false,
  error: "",
  updateFacilityRoomLoading: false,
  updateFacilityRoomError: "",
  updateFacilityRoomFeaturesLoading: false,
  updateFacilityRoomFeaturesError: "",
  addFacilityRoomImagesLoading: false,
  addFacilityRoomImagesError: "",
  deleteFacilityRoomImageLoading: false,
  deleteFacilityRoomImageError: "",

  allRoomsNames: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  roomsLoading: false,
  roomsError: "",
};

const facilityRoomsSlice = createSlice({
  name: "facilityRooms",
  initialState,
  reducers: {
    handleSaveFacilityRoom: (state, action) => {
      const payload: FacilityRoom = action.payload;
      state.facilityRoom = payload;

      if (payload["oda bilgileri"][0]) {
        const roomFeatures = Object.entries(payload["oda özellikleri"])
          .map(([key, value]) => value)
          .flat();

        state.updateFacilityRoom = {
          oda_isim: payload["oda bilgileri"][0].oda_isim,
          aciklama: payload["oda bilgileri"][0].aciklama,
          metrekare: payload["oda bilgileri"][0].metrekare,
          oda_ozellikleri: roomFeatures
            .filter((item) => item.aktifmi === true)
            .map((item) => item.ozellik_id),

          oda_one_cikan_ozellikler: {
            1:
              roomFeatures.find((item) => item.one_cikan_ozellik_id === 1)
                ?.ozellik_id || 0,
            2:
              roomFeatures.find((item) => item.one_cikan_ozellik_id === 2)
                ?.ozellik_id || 0,
            3:
              roomFeatures.find((item) => item.one_cikan_ozellik_id === 3)
                ?.ozellik_id || 0,
            4:
              roomFeatures.find((item) => item.one_cikan_ozellik_id === 4)
                ?.ozellik_id || 0,
          },
        } as UpdateFacilityRoomState;

        const roomImages = {
          1: payload["oda bilgileri"][0].resim1,
          2: payload["oda bilgileri"][0].resim2,
          3: payload["oda bilgileri"][0].resim3,
          4: payload["oda bilgileri"][0].resim4,
          5: payload["oda bilgileri"][0].resim5,
          6: payload["oda bilgileri"][0].resim6,
          7: payload["oda bilgileri"][0].resim7,
          8: payload["oda bilgileri"][0].resim8,
          9: payload["oda bilgileri"][0].resim9,
          10: payload["oda bilgileri"][0].resim10,
        };

        state.facilityRoomImages = roomImages;
      }
    },
    handleSaveRooms: (state, action) => {
      state.rooms = action.payload;
    },
    handleChangeUpdateFacilityRoomFormInput: (state, action) => {
      state.updateFacilityRoom = {
        ...state.updateFacilityRoom,
        ...action.payload,
      };
    },
    handleUpdateFacilityRoomImages: (state, action) => {
      state.facilityRoomImages = action.payload;
    },
    handleAddUpdateFacilityRoomFormErrors: (state, action) => {
      state.updateFacilityRoomFormErrors = action.payload;
    },
    handleChangeFacilityRoomImageFilesInput(state, action) {
      state.facilityRoomImageFiles = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    handleSaveAllRoomsNames: (state, action) => {
      const { data, ...rest } = action.payload.res[0];

      const isFirstPage = rest.current_page === 1;

      state.allRoomsNames = isFirstPage
        ? data
        : [...state.allRoomsNames, ...data];
      state.pagination = rest;
    },
    setRoomsLoading: (state, action) => {
      state.roomsLoading = action.payload;
    },
    setRoomsError: (state, action) => {
      state.roomsError = action.payload;
    },
    setUpdateFacilityRoomLoading: (state, action) => {
      state.updateFacilityRoomLoading = action.payload;
    },
    setUpdateFacilityRoomError: (state, action) => {
      state.updateFacilityRoomError = action.payload;
    },
    setUpdateFacilityRoomFeaturesLoading: (state, action) => {
      state.updateFacilityRoomFeaturesLoading = action.payload;
    },

    setUpdateFacilityRoomFeaturesError: (state, action) => {
      state.updateFacilityRoomFeaturesError = action.payload;
    },
    setAddFacilityRoomImagesLoading: (state, action) => {
      state.addFacilityRoomImagesLoading = action.payload;
    },
    setAddFacilityRoomImagesError: (state, action) => {
      state.addFacilityRoomImagesError = action.payload;
    },
    setDeleteFacilityRoomImageLoading: (state, action) => {
      state.deleteFacilityRoomImageLoading = action.payload;
    },
    setDeleteFacilityRoomImageError: (state, action) => {
      state.deleteFacilityRoomImageError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityRoom,
  handleSaveRooms,
  handleChangeUpdateFacilityRoomFormInput,
  handleUpdateFacilityRoomImages,
  handleAddUpdateFacilityRoomFormErrors,
  handleChangeFacilityRoomImageFilesInput,
  setIsLoading,
  setError,
  setUpdateFacilityRoomLoading,
  setUpdateFacilityRoomError,
  setUpdateFacilityRoomFeaturesLoading,
  setUpdateFacilityRoomFeaturesError,
  setAddFacilityRoomImagesLoading,
  setAddFacilityRoomImagesError,
  setDeleteFacilityRoomImageLoading,
  setDeleteFacilityRoomImageError,
  handleSaveAllRoomsNames,
  setRoomsLoading,
  setRoomsError,
} = facilityRoomsSlice.actions;

export default facilityRoomsSlice.reducer;
