import { CONTRACT_TYPE } from "enums";
import { useSelector } from "react-redux";

import { IssuedInvoicesInitialState } from "slices/financeAndAccounting/issuedInvoices/reducer";

import { TABLE_COLORS } from "utils/tableColors";

const rowColors = {
  [CONTRACT_TYPE.Aylik]: TABLE_COLORS.blue,
  [CONTRACT_TYPE["Oda Basi"]]: TABLE_COLORS.red,
  [CONTRACT_TYPE["Sezon Sonu"]]: TABLE_COLORS.purple,
  [CONTRACT_TYPE["Tek Seferlik"]]: TABLE_COLORS.gray,
  [CONTRACT_TYPE.Tekrarli]: TABLE_COLORS.yellow,
  tamamlanmis: TABLE_COLORS.green,
};

const useHandleTable = () => {
  const { issuedInvoices } = useSelector(
    (state: { IssuedInvoices: IssuedInvoicesInitialState }) =>
      state.IssuedInvoices
  );

  const tableData = issuedInvoices?.map((issuedInvoice) => ({
    "Otel Adı": issuedInvoice.otel_adi,
    "Otel Yetkilisi": issuedInvoice.ad_soyad,
    Adı: issuedInvoice.adi,
    "Kontrat Tipi": issuedInvoice.kontrat_tipi,
    Tutar: issuedInvoice.tutar,
    "İşlem Tarihi": issuedInvoice.guncelleme_tarihi,
    "Fatura Tarihi": issuedInvoice.fatura_tarihi,
    Durum: issuedInvoice.durum,
    rowColor: issuedInvoice.fatura_tarihi
      ? rowColors.tamamlanmis
      : rowColors[issuedInvoice.kontrat_tipi as CONTRACT_TYPE],
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel Yetkilisi",
      accessorKey: "Otel Yetkilisi",
      enableColumnFilter: false,
    },
    {
      header: "Adı",
      accessorKey: "Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kontrat Tipi",
      accessorKey: "Kontrat Tipi",
      enableColumnFilter: false,
    },
    {
      header: "Tutar",
      accessorKey: "Tutar",
      enableColumnFilter: false,
    },
    {
      header: "İşlem Tarihi",
      accessorKey: "İşlem Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Fatura Tarihi",
      accessorKey: "Fatura Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "Durum",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.blue,
      label: "Aylık",
    },
    {
      color: TABLE_COLORS.red,
      label: "Oda Başı Prim",
    },
    {
      color: TABLE_COLORS.purple,
      label: "Sezon Sonu Kotası",
    },
    {
      color: TABLE_COLORS.gray,
      label: "Tek Seferlik",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Tekrarlı Kota",
    },
    {
      color: TABLE_COLORS.green,
      label: "Tamamlanmış",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
  };
};

export default useHandleTable;
