import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface EmployeeSalesAmountTableProps {
  isLoading: boolean;
}

const EmployeeSalesAmountTable = ({
  isLoading,
}: EmployeeSalesAmountTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <div className="d-flex flex-column gap-4">
      {tableData.map((table, index) => {
        return (
          <div className="d-flex flex-column gap-1" key={index}>
            <p className="fs-24 fw-semibold">{table["Grup Adı"]}</p>
            <CustomReactTable
              fileName={table["Grup Adı"] + " Grubu Satış Raporu"}
              tableData={table["uyeler"]}
              tableColumns={tableColumns}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeSalesAmountTable;
