import { useState } from "react";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";

interface ImageSliderProps {
  images: [string, string, string, string];
}

const ImageSlider = ({ images }: ImageSliderProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  const next = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = images.map((url, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <div
          className="d-flex justify-content-center align-items-center rounded-start"
          style={{ width: "300px", height: "200px" }}
        >
          <img
            className="rounded-start bg-white w-100 h-100"
            src={"https://www.otelfiyat.com/media/oda_resim/buyuk/" + url}
            alt={url}
          />
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      fade={true}
      style={{ width: "300px", height: "200px" }}
      interval={false}
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default ImageSlider;
