interface Member {
  id: number;
  ad_soyad: string;
  email_adresi: string;
  dahili: number;
}

const useHandleTable = (members: Member[]) => {
  const tableData = members.map((member, index) => ({
    order: index + 1,
    id: member.id,
    ad_soyad: member.ad_soyad,
    email_adresi: member.email_adresi,
    dahili: member.dahili,
  }));

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "order",
      enableColumnFilter: false,
    },
    {
      header: "Ad Soyad",
      accessorKey: "ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "dahili",
      enableColumnFilter: false,
    },
    {
      header: "Mail",
      accessorKey: "email_adresi",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
