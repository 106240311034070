import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getPhoneStatusList } from "slices/reports/phoneStatusList/thunk";

const useHandleForm = () => {
  const [filterForm, setFilterForm] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const payload = {
      date: filterForm.date,
    };
    dispatch(getPhoneStatusList(payload));
  };

  const handdleResetFilter = () => {
    setFilterForm({
      date: moment().format("YYYY-MM-DD"),
    });

    const payload = {
      date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getPhoneStatusList(payload));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handdleResetFilter,
  };
};

export default useHandleForm;
