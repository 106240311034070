import PageContainer from "pages/PageContainer";
import image from "../.././assets/images/welcome.png";

const Dashboard = () => {
  return (
    <PageContainer title="Ana Sayfa">
      <div className="w-100 d-flex justify-content-center align-items-center bg-transparent">
          <img
            src={image}
            alt="empty"
            style={{ width: "80%", height: "80%", userSelect: "none" }}
            className="rounded-2"
          />
        </div>
    </PageContainer>
  );
};

export default Dashboard;
