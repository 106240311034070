import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import { UpdateGroupPayload } from "types/userOperations";
import useHandlePayload from "./useHandlePayload";
import { useNavigate } from "react-router-dom";
import { updateGroup } from "slices/userOperations/updateGroup/thunk";

interface UpdateGroupButtonProps {
  values: UpdateGroupPayload;
  requestPayload: FormData;
  validateForm: (values: UpdateGroupPayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateGroupButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateGroupButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();
  const getPayload = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(updateGroup(getPayload(), navigate));
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={isLoading}
      >
        Grubu Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateGroupButton;
