import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import { getComments } from "slices/comments/listComments/thunk";

/*
 *This file uses "themes > listThemes" as a template
  Aim is to reach the personel value. 
  Then, by changing it I will dispatch getComments with revised personel value.
 */

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { start_date, end_date, pagination, personel } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const handlePersonelOrCustomer = (personel: string) => {
    dispatch(
      getComments({
        otel_id: 0,
        page: 1,
        per_page: pagination.per_page,
        start_date: start_date,
        end_date: end_date,
        personel: personel,
      })
    );
  };

  return {
    personel,
    handlePersonelOrCustomer,
  };
};

export default useHandleForm;
