import RiskyHotelsTable from "./RiskyHotelsTable";

interface RiskyHotelsPageProps {
  isLoading: boolean;
}

const RiskyHotelsPage = ({ isLoading }: RiskyHotelsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <RiskyHotelsTable isLoading={isLoading} />
    </div>
  );
};

export default RiskyHotelsPage;
