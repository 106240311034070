import { createSlice } from "@reduxjs/toolkit";
import {
  CategorizeFacilityFormChoices,
  CategorySelectionResponse,
  CitySelectionResponse,
  ListFacilitiesByCityAndCategoryResponse,
} from "types/facilityCategorization";

export interface CategorizeFacilityInitialState {
  formChoices: CategorizeFacilityFormChoices;
  categorizeFacility: CitySelectionResponse;
  changeFacilityCategory: CategorySelectionResponse[];
  facilityList: ListFacilitiesByCityAndCategoryResponse[];
  isLoading: boolean;
  error: string;
  categorizeFacilityLoading: boolean;
  categorizeFacilityError: string;
  changeFacilityCategoryLoading: boolean;
  changeFacilityCategoryError: string;
  facilityListLoading: boolean;
  facilityListError: string;
}

export const categorizeFacilityInitialState: CategorizeFacilityInitialState = {
  formChoices: {
    sehirler: [],
    otel_siniflari: [],
  },
  categorizeFacility: {
    tesisler: [],
    otel_siniflari: [],
  },
  changeFacilityCategory: [],
  facilityList: [],
  isLoading: false,
  error: "",
  categorizeFacilityLoading: false,
  categorizeFacilityError: "",
  changeFacilityCategoryLoading: false,
  changeFacilityCategoryError: "",
  facilityListLoading: false,
  facilityListError: "",
};

export const categorizeFacilitySlice = createSlice({
  name: "categorizeFacility",
  initialState: categorizeFacilityInitialState,
  reducers: {
    handleSaveCategorizeFacilityFormChoices(state, action) {
      state.formChoices = action.payload;
    },
    handleSaveCitySelectionResponse(state, action) {
      state.categorizeFacility = action.payload;
      console.log("categorizeFacility : ", state.categorizeFacility)
    },
    handleSaveCategorySelectionResponse(state, action) {
      state.changeFacilityCategory = action.payload;
    },
    handleSaveFacilityListResponse(state, action) {
      state.facilityList = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCategorizeFacilityLoading(state, action) {
      state.categorizeFacilityLoading = action.payload;
    },
    setCategorizeFacilityError(state, action) {
      state.categorizeFacilityError = action.payload;
    },
    setChangeFacilityCategoryLoading(state, action) {
      state.changeFacilityCategoryLoading = action.payload;
    },
    setChangeFacilityCategoryError(state, action) {
      state.changeFacilityCategoryError = action.payload;
    },
    setFacilityListLoading(state, action) {
      state.facilityListLoading = action.payload;
    },
    setFacilityListError(state, action) {
      state.facilityListError = action.payload;
    },
  },
});

export const {
    handleSaveCategorizeFacilityFormChoices,
    handleSaveCitySelectionResponse,
    handleSaveCategorySelectionResponse,
    handleSaveFacilityListResponse,
    setIsLoading,
    setError,
    setCategorizeFacilityLoading,
    setCategorizeFacilityError,
    setChangeFacilityCategoryLoading,
    setChangeFacilityCategoryError,
    setFacilityListLoading,
    setFacilityListError,
} = categorizeFacilitySlice.actions;

export default categorizeFacilitySlice.reducer;
