import { ReviewReservationPage } from "Components/Custom/ReservationPages";
import useReviewReservation from "Components/Hooks/reservation/useReviewReservation";
import PageContainer from "pages/PageContainer";
import { Navigate } from "react-router-dom";

const ReviewReservation = () => {
  const { isValid, isLoading } = useReviewReservation();

  if (!isValid) {
    return <Navigate to="/fiyat-hesapla" />;
  }

  return (
    <PageContainer title="Rezervasyon İnceleme">
      <ReviewReservationPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ReviewReservation;
