import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AdsReportInitialState } from "slices/adsAndAnalyze/adsReport/reducer";

import {
  getAdsReport,
  getAdsReportManagers,
  getAdsReportSummary,
} from "slices/adsAndAnalyze/adsReport/thunk";

const useAdsReport = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, managersLoading, summaryLoading } = useSelector(
    (state: { AdsReport: AdsReportInitialState }) => state.AdsReport
  );

  useEffect(() => {
    const getAdsReportRequestParams = {
      sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      yetkili_id: 0,
      search: "",
      per_page: 10,
      page: 1,
    };

    const getAdsReportSummaryRequestPayload = {
      sdate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    };

    dispatch(getAdsReport(getAdsReportRequestParams));
    dispatch(getAdsReportManagers());
    dispatch(getAdsReportSummary(getAdsReportSummaryRequestPayload));
  }, [dispatch]);

  return {
    isLoading,
    managersLoading,
    summaryLoading,
  };
};

export default useAdsReport;
