// import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
// import { Dispatch } from "redux";
import SimpleBar from "simplebar-react";
// import { handleSelectHotel } from "slices/hotelSearcBar/reducer";
// import { getHotel } from "slices/thunks";
import { SearchedHotel } from "types/hotelSearchBar";

interface SearchResultsProps {
  results: SearchedHotel[];
  isLoading: boolean;
  resetSearchTerm: () => void;
}

const SearchResults = ({
  results,
  isLoading,
  resetSearchTerm,
}: SearchResultsProps) => {
  // const dispatch: Dispatch<any> = useDispatch();

  return (
    <div className="dropdown-menu dropdown-menu-lg mt-2" id="search-dropdown">
      <SimpleBar scrollbarMaxSize={5}>
        {isLoading ? (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Spinner color="primary" />
          </div>
        ) : !results.length ? (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <p className="text-center text-muted mt-2">Sonuç bulunamadı.</p>
          </div>
        ) : (
          <div
            className="dropdown-menu-inner"
            style={{
              maxHeight: "200px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#f1f1f1 #fff",
            }}
          >
            {results.map((result: SearchedHotel, index: number) => (
              <div
                key={index}
                className={`dropdown-header mt-2 ${
                  index % 2 === 0 ? "" : "theme-bg-light"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // dispatch(getHotel(result.otel_id));
                  window.history.pushState(
                    {},
                    "",
                    `?otel_id=${result.otel_id}`
                  );
                  // dispatch(handleSelectHotel(result));
                  const dropdown = document.getElementById(
                    "search-dropdown"
                  ) as HTMLElement;
                  dropdown.classList.remove("show");
                  resetSearchTerm();

                  //reload the page
                  window.location.reload();
                }}
              >
                <p className="text-overflow text-muted mb-1">
                  {result.otel_adi}
                </p>
              </div>
            ))}
          </div>
        )}
      </SimpleBar>
    </div>
  );
};

export default SearchResults;
