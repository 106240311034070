import {
  CalculatePriceForHotelSpecialDiscount,
  CalculatePriceFormValues,
  EnterReservationFormValues,
} from "types/reservation";
import useHandleForm from "./useHandleForm";
import SpecialDiscountRow from "./SpecialDiscountRow";
import { Input, Label } from "reactstrap";

interface SpecialDiscountsFormProps {
  passedProps?: {
    specialDiscounts: CalculatePriceForHotelSpecialDiscount[];
    moneyPoint: number;
  };
  formValues: CalculatePriceFormValues | EnterReservationFormValues;
  handleChangeForm: (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => void;
}

const SpecialDiscountsForm = ({
  passedProps,
  formValues,
  handleChangeForm,
}: SpecialDiscountsFormProps) => {
  const { specialDiscounts, moneyPoint } = useHandleForm();

  const discounts = passedProps
    ? passedProps.specialDiscounts
    : specialDiscounts;
  const point = passedProps ? passedProps.moneyPoint : moneyPoint;

  return (
    <div className="d-flex flex-column gap-2 mt-2 gap-1">
      <div className="d-flex flex-column">
        <div className="d-flex flex-column gap-4">
          {discounts.map((specialDiscount) => (
            <SpecialDiscountRow
              key={specialDiscount.ozel_indirim_id}
              formValues={formValues}
              handleChangeForm={handleChangeForm}
              {...specialDiscount}
            />
          ))}
        </div>
        {discounts.length ? (
          <p className="fs-12 text-muted m-0">
            * Fiyatlar yalnızca indirimde belirtilen şartları sağlayan
            rezervasyon sahipleri için geçerlidir. Tesis girişinde şartların
            sağlandığı ibraz edilmesi zorunludur.
          </p>
        ) : (
          <></>
        )}
      </div>
      {point ? (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="pb-1 border-bottom">
              <Input
                id="parapuan_secim"
                className="cursor-pointer"
                type="checkbox"
                checked={formValues.parapuan_secim === 1}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  handleChangeForm("parapuan_secim", isChecked ? 1 : 0);
                }}
              />
              <Label
                for="parapuan_secim"
                className="m-0 ms-2 fw-semibold fs-14 cursor-pointer"
              >
                ParaPuan: | {point} TL
              </Label>
            </div>
          </div>
          <p className="fs-12 text-muted m-0">
            * ParaPuan ını kullanmak isteyen müşterimiz için seçim yapıp tekrar
            hesaplama yapınız.
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SpecialDiscountsForm;
