import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { CustomLoadingPage } from "Components/Custom/UI";
import AddFacilityConceptForm from "./AddFacilityConceptForm";

interface FacilityConceptsPageProps {
  isLoading: boolean;
}

const FacilityConceptsPage = ({ isLoading }: FacilityConceptsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <AddFacilityConceptForm />
    </>
  );
};

export default withPageNeedsHotel(FacilityConceptsPage);
