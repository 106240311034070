import {
  AddLocationFormErrors,
  AddLocationState,
  Location,
} from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import LocationSelect from "./LocationSelect";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  AddLocationInitialState,
  handleSaveSelectedLocations,
} from "slices/definitions/addLocation/reducer";

interface LocationSelectorsProps {
  values: AddLocationState;
  formErrors: AddLocationFormErrors;
  validateFormChange: (values: AddLocationState) => void;
}

interface SelectedLocation {
  key: number;
  value: Location[];
}

const LocationSelectors = ({
  values,
  validateFormChange,
  formErrors,
}: LocationSelectorsProps) => {
  const { selectedLocations } = useSelector(
    (state: { AddLocation: AddLocationInitialState }) => state.AddLocation
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleAddLocation = (selected: Location[], index: number) => {
    const dumb = [...selectedLocations];

    const key = selected[0]?.parent_id;

    if (key) {
      if (dumb.length >= index) {
        dumb.splice(index + 1, dumb.length - index);
        dumb.push({ key, value: selected });
        dispatch(handleSaveSelectedLocations(dumb));
      } else {
        dumb.push({ key, value: selected });
        dispatch(handleSaveSelectedLocations(dumb));
      }
    } else {
      dumb.splice(index + 1, dumb.length - index);
      dispatch(handleSaveSelectedLocations(dumb));
    }
  };

  return (
    <CustomFormFieldContainer label="Üst Bölge" error={formErrors.parent_id}>
      <div className="d-flex flex-column gap-2">
        {selectedLocations.map(
          (selectedLocation: SelectedLocation, index: number) => (
            <LocationSelect
              key={index}
              index={index}
              formValues={values}
              values={selectedLocation}
              validateFormChange={validateFormChange}
              handleSelectedLocation={handleAddLocation}
            />
          )
        )}
      </div>
    </CustomFormFieldContainer>
  );
};

export default LocationSelectors;
