import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import { WEEKDAY_OPTIONS } from "utils/weekdays";
import {
  handleIsAllDaysSelected,
  handleSaveNights,
  handleSaveSelectedConcept,
  handleSaveSelectedRoom,
  handleSelectedDates,
  handleSelectedDays,
} from "slices/pricesAndQuota/advancedEntry/reducer";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { useEffect } from "react";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { CustomButton } from "Components/Custom/UI";

interface AdvancedEntryDateRangeSelectorProps {
  onSave: () => void;
  selectedRoom: number;
  selectedConcept: number;
  selectedDates: {
    startDate: Date;
    endDate: Date;
  };
  selectedDays: number[];
  isAllDaysSelected: boolean;
  roomOptions: {
    value: number;
    label: string;
  }[];
  conceptOptions: {
    value: number;
    label: string;
  }[];
  nights: number;
  periodsLoading: boolean;
  periodsError: string;
}

const AdvancedEntryDateRangeSelector = ({
  selectedRoom,
  selectedConcept,
  roomOptions,
  conceptOptions,
  selectedDates,
  selectedDays,
  isAllDaysSelected,
  nights,
  onSave,
  periodsLoading,
  periodsError,
}: AdvancedEntryDateRangeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  useEffect(() => {
    dispatch(handleSaveSelectedRoom(roomOptions[0].value));
    dispatch(handleSaveSelectedConcept(conceptOptions[0].value));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-2 mb-2 theme-bg-light border border-2 rounded">
      <Row className="gap-4 gap-xl-0 align-items-end">
        <Col xl={3}>
          <div className="flex-grow-1">
            <Label className="form-label" htmlFor="room-select-input">
              Oda Tipi
            </Label>
            <Input
              id="room-select-input"
              name="room"
              type="select"
              value={selectedRoom}
              onChange={(e) => {
                dispatch(handleSaveSelectedRoom(parseInt(e.target.value)));
              }}
            >
              {roomOptions.map((roomOption) => (
                <option key={roomOption.value} value={roomOption.value}>
                  {roomOption.label}
                </option>
              ))}
            </Input>
          </div>
        </Col>
        <Col xl={3}>
          <div className="flex-grow-1">
            <Label className="form-label" htmlFor="concept-select-input">
              Pansiyon Tipi
            </Label>
            <Input
              id="concept-select-input"
              name="concept"
              type="select"
              value={selectedConcept}
              onChange={(e) => {
                dispatch(handleSaveSelectedConcept(parseInt(e.target.value)));
              }}
            >
              {conceptOptions.map((conceptOption) => (
                <option key={conceptOption.value} value={conceptOption.value}>
                  {conceptOption.label}
                </option>
              ))}
            </Input>
          </div>
        </Col>

        <Col xl={3}>
          <div className="flex-grow-1">
            <Label className="form-label">Tarih Aralığı</Label>
            <Flatpickr
              className="form-control"
              value={[selectedDates.startDate, selectedDates.endDate]}
              onChange={(dates) => {
                const [startDate, endDate] = dates;
                if (startDate && endDate) {
                  const stringStartDate =
                    moment(startDate).format("YYYY-MM-DD");
                  const stringEndDate = moment(endDate).format("YYYY-MM-DD");
                  dispatch(
                    handleSelectedDates({
                      startDate: stringStartDate,
                      endDate: stringEndDate,
                    })
                  );
                }
              }}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                showMonths: 2,
                locale: Turkish,
                onDayCreate,
              }}
            />
          </div>
        </Col>
        <Col xl={3}>
          <div className="d-flex gap-1">
            <CustomNumberSelector
              label="Gece Sayısı"
              value={nights}
              min={1}
              onChange={(value) => {
                dispatch(handleSaveNights(value));
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4 align-items-start">
        <Col md={3}>
          <div className="form-check form-switch">
            <Input
              id="all-days-switch"
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={isAllDaysSelected}
              onChange={(e) => {
                dispatch(handleIsAllDaysSelected(e.target.checked));
              }}
            />
            <Label className="form-check-label" htmlFor="all-days-switch">
              Tüm Günler
            </Label>
          </div>
        </Col>
        {!isAllDaysSelected && (
          <Col md={9}>
            <div className="d-flex gap-3 align-items-start flex-grow-1">
              <Label htmlFor="select-days-select" className="form-label">
                Seçili Günler
              </Label>
              <Select
                value={selectedDays.map((day: any) => {
                  return WEEKDAY_OPTIONS.find((item) => item.value === day);
                })}
                isMulti={true}
                onChange={(selectedMulti: any) => {
                  dispatch(handleSelectedDays(selectedMulti));
                }}
                options={WEEKDAY_OPTIONS}
                placeholder="Gün Seçiniz..."
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          <CustomButton
            variant="primary"
            onClick={onSave}
            isLoading={periodsLoading}
          >
            Dönem Belirle
          </CustomButton>
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedEntryDateRangeSelector;
