import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import useHandlePagination from "./useHandlePagination";

interface ViewFeaturedImagesTableProps {
  loading: boolean;
  handleChangeIndex: (index: number) => void;
}

const ViewFeaturedImagesTable = ({
  loading,
  handleChangeIndex,
}: ViewFeaturedImagesTableProps) => {
  const { tableData, tableColumns } = useHandleTable({
    handleChangeIndex,
  });

  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomReactTable
      pagination={true}
      loading={loading}
      tableData={tableData}
      tableColumns={tableColumns}
      onSearch={(searchValue) => {
        handleChange("search", searchValue);
      }}
      onPageSizeChange={(pageSize) => {
        handleChange("per_page", pageSize);
      }}
      paginationBottomProps={paginationBottomProps}
    />
  );
};

export default ViewFeaturedImagesTable;
