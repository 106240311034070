import { AddHotelFeaturePage } from "Components/Custom/DefinitionsPages";
import PageContainer from "pages/PageContainer";

const AddHotelFeature = () => {
  return (
    <PageContainer title="Tesis Özelliği Ekle">
      <AddHotelFeaturePage />
    </PageContainer>
  );
};

export default AddHotelFeature;
