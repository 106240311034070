import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import GeneralInformationImage from "./GeneralInformationImage";
import GeneralInformationText from "./GeneralInformationText";

interface GeneralInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const GeneralInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: GeneralInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Genel Bilgiler" lg={12} xl={12}>
        <GeneralInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <GeneralInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default GeneralInformationFields;
