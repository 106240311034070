import { EditReservationPage } from "Components/Custom/ReservationPages";
import useEditReservation from "Components/Hooks/reservation/useEditReservation";
import PageContainer from "pages/PageContainer";
import { Navigate } from "react-router-dom";

const EditReservation = () => {
  const { isValid } = useEditReservation();

  if (!isValid) {
    return <Navigate to="/fiyat-hesapla" />;
  }

  return (
    <PageContainer title="Rezervasyon Düzenleme">
      <EditReservationPage />
    </PageContainer>
  );
};

export default EditReservation;
