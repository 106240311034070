import { ReservationReview } from "types/reservation";

const StatusMessages = ({
  muhasebe_bilgileri,
  rez_tipi,
  komisyon_faturali_rezervasyon,
}: ReservationReview) => {
  const {
    muhasebe_tahsilat_onay,
    otele_odeme_durum,
    otele_odeme_tutar,
    otel_fatura_no,
    misafir_fatura_no,
  } = muhasebe_bilgileri;

  const showStatusMessages = rez_tipi !== "kapida";

  return (
    <>
      {showStatusMessages && (
        <div
          className="border p-2 rounded bg-white"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <span
            className={`fs-20 fw-bold ${
              muhasebe_tahsilat_onay === "1"
                ? "theme-text-success"
                : "theme-text-danger"
            }`}
          >
            ÖDEME TAMAM
          </span>
          <span
            className={`fs-20 fw-bold ${
              otele_odeme_durum === "2"
                ? "theme-text-primary"
                : otele_odeme_durum === "1"
                ? "theme-text-pink"
                : "theme-text-danger"
            }`}
          >
            OTELE ÖDEME{" "}
            {otele_odeme_durum === "1"
              ? "YAPILDI"
              : otele_odeme_durum === "2" || Number(otele_odeme_tutar) > 0
              ? "KISMİ YAPILDI"
              : "YAPILMADI"}
          </span>
          <span
            className={`fs-20 fw-bold ${
              otel_fatura_no ? "theme-text-primary" : "theme-text-danger"
            }`}
          >
            OTELDEN FATURA {otel_fatura_no ? "GELDİ" : "GELMEDİ"}
          </span>
          <span
            className={`fs-20 fw-bold ${
              misafir_fatura_no ? "theme-text-info" : "theme-text-danger"
            }`}
          >
            MİSAFİRE FATURA {misafir_fatura_no ? "KESİLDİ" : "KESİLMEDİ"}
          </span>
          {komisyon_faturali_rezervasyon === "1" && (
            <span className="fw-semibold theme-text-danger">
              Tam Ücret Ödemeli (Komisyon Faturalı)
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default StatusMessages;
