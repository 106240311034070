import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { FacilityConceptsInitialState } from "slices/facility/facilityConcepts/reducer";
import {
  getFacilityConcepts,
  addFacilityConcept,
  updateFacilityConcept,
} from "slices/facility/facilityConcepts/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import {
  AddFacilityConceptRequestPayload,
  UpdateFacilityConceptRequestPayload,
} from "types/facility";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityConcepts, addConceptLoading, updateConceptLoading } =
    useSelector(
      (state: { FacilityConcepts: FacilityConceptsInitialState }) =>
        state.FacilityConcepts
    );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);

  const [selectedConcept, setSelectedConcept] = useState(0);

  const handleSelectConcept = (selectedConcept: number) => {
    setSelectedConcept(selectedConcept);
  };

  const hotelConcepts = facilityConcepts.mevcut_pansiyonlar.filter(
    (item) => item.aktiflik === "1"
  );

  const selectableConcepts = useMemo(() => {
    return facilityConcepts.datalar.map((item) => ({
      id: item.id,
      pansiyon_isim: item.pansiyon_isim,
    }));
  }, [facilityConcepts.datalar]);

  useEffect(() => {
    setConceptsOptions(
      selectableConcepts.map((item) => ({
        value: item.id,
        label: item.pansiyon_isim,
      }))
    );
  }, [selectableConcepts]);

  const handleAddConceptToFacility = () => {
    const addFacilityConceptRequestPayload: AddFacilityConceptRequestPayload = {
      otel_id: hotel.otel_id,
      pansiyonlar: [
        {
          pansiyon_id: selectedConcept,
        },
      ],
    };

    dispatch(
      addFacilityConcept(addFacilityConceptRequestPayload, () => {
        dispatch(getFacilityConcepts(hotel.otel_id));
        setSelectedConcept(0);
      })
    );
  };

  const handleUpdateConcept = (newConceptName: string, pansiyon_id: number) => {
    const updateFacilityConceptRequestPayload: UpdateFacilityConceptRequestPayload =
      {
        otel_id: hotel.otel_id,
        pansiyonlar: [
          {
            pansiyon_id,
            statu: true,
            yeni_isim: newConceptName,
          },
        ],
      };

    dispatch(
      updateFacilityConcept(updateFacilityConceptRequestPayload, () => {
        dispatch(getFacilityConcepts(hotel.otel_id));
      })
    );
  };

  const handleRemoveConcept = (pansiyon_id: number, conceptName: string) => {
    const updateFacilityConceptRequestPayload: UpdateFacilityConceptRequestPayload =
      {
        otel_id: hotel.otel_id,
        pansiyonlar: [
          {
            pansiyon_id,
            statu: false,
            yeni_isim: conceptName,
          },
        ],
      };

    dispatch(
      updateFacilityConcept(updateFacilityConceptRequestPayload, () => {
        dispatch(getFacilityConcepts(hotel.otel_id));
      })
    );
  };

  return {
    conceptsOptions,
    selectedConcept,
    handleSelectConcept,
    hotelConcepts,
    onAddConcept: handleAddConceptToFacility,
    onUpdateConcept: handleUpdateConcept,
    onRemoveConcept: handleRemoveConcept,
    addConceptLoading,
    updateConceptLoading,
  };
};

export default useHandleForm;
