import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { UpdateFacilityRoomState } from "types/facility";

const useHandlePayload = (
  requestPayload: FormData,
  values: UpdateFacilityRoomState,
  selectedRoom: number
) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const getPayload = () => {
    // const roomFeatures = values.oda_ozellikleri.map((id) => ({
    //   ozellik_id: id,
    //   aktifmi: true,
    // }));

    const roomSpecialFeatures = Object.entries(
      values.oda_one_cikan_ozellikler
    ).map(([key, value]) => ({
      ozellik_id: value,
      ozellik_sira: parseInt(key),
    }));

    requestPayload.append("otel_id", hotel.otel_id.toString());
    requestPayload.append("oda_id", selectedRoom.toString());
    requestPayload.append("metrekare", values.metrekare);
    requestPayload.append("oda_aciklamasi", values.aciklama);
    requestPayload.append(
      "oda_ozellikleri",
      JSON.stringify(values.oda_ozellikleri)
    );
    requestPayload.append(
      "oda_ozellik_siralama",
      JSON.stringify(roomSpecialFeatures)
    );
    requestPayload.append("aktiflik", "1");

    return requestPayload;
  };

  return { getPayload, otel_id: hotel.otel_id };
};

export default useHandlePayload;
