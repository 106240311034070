import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import Select from "react-select";
import useHandleForm from "./useHandleForm";
import ManipulateConceptInput from "./ManipulateConceptInput";

interface Option {
  value: number;
  label: string;
  aktiflik: "0" | "1";
}

const AddFacilityConceptForm = () => {
  const {
    conceptsOptions,
    selectedConcept,
    handleSelectConcept,
    hotelConcepts,
    onAddConcept,
    onUpdateConcept,
    onRemoveConcept,
    addConceptLoading,
    updateConceptLoading,
  } = useHandleForm();

  const value = conceptsOptions.find((item) => item.value === selectedConcept);

  return (
    <CustomFormContainer title="Pansiyon Ekleme ve Düzenleme" lg={12} xl={12}>
      <div className="d-flex gap-4">
        <div className="d-flex flex-column gap-4" style={{ width: "450px" }}>
          <Select
            className="w-100"
            value={
              conceptsOptions.find((item) => item.value === selectedConcept) ||
              null
            }
            options={conceptsOptions}
            onChange={(selectedOptions: Option) => {
              handleSelectConcept(selectedOptions.value);
            }}
            placeholder="Pansiyon Seçiniz..."
          />
          {!!value?.value && (
            <CustomButton
              classes="w-100"
              variant="success"
              onClick={onAddConcept}
              isLoading={addConceptLoading}
            >
              Tesise Pansiyon Ekle
            </CustomButton>
          )}
          <div className="d-flex flex-column gap-2"></div>
        </div>
        <div
          className="d-flex flex-column gap-2 border p-2 rounded bg-white"
          style={{ width: "450px" }}
        >
          <p className="text-center fw-bold fs-18 m-0 mb-1">
            Aktif Pansiyonlar
          </p>
          {hotelConcepts.length ? (
            hotelConcepts.map((item) => (
              <ManipulateConceptInput
                key={item.id}
                concept={item}
                isLoading={updateConceptLoading}
                onUpdateConcept={onUpdateConcept}
                onRemoveConcept={onRemoveConcept}
              />
            ))
          ) : (
            <p className="text-center p-2 border rounded">
              Aktif Pansiyon Bulunamadı...
            </p>
          )}
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default AddFacilityConceptForm;
