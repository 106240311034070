import { CustomRowOperationButton } from "Components/Custom/UI";
import { CONTRACT_TYPE } from "enums";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { ContractRulesInitialState } from "slices/financeAndAccounting/contractRules/reducer";
import {
  deleteHotelContractRule,
  getHotelContractRules,
} from "slices/financeAndAccounting/contractRules/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import { TABLE_COLORS } from "utils/tableColors";

const rowColors = {
  [CONTRACT_TYPE.Aylik]: TABLE_COLORS.blue,
  [CONTRACT_TYPE["Oda Basi"]]: TABLE_COLORS.red,
  [CONTRACT_TYPE["Sezon Sonu"]]: TABLE_COLORS.purple,
  [CONTRACT_TYPE["Tek Seferlik"]]: TABLE_COLORS.gray,
  [CONTRACT_TYPE.Tekrarli]: TABLE_COLORS.yellow,
};

const useHandleTable = () => {
  const { hotelContractRules } = useSelector(
    (state: { ContractRules: ContractRulesInitialState }) => state.ContractRules
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const dispatch: Dispatch<any> = useDispatch();

  const getHotelContractRulesForCallback = (otel_id: number) => {
    dispatch(getHotelContractRules(otel_id));
  };

  const deleteHotelContractRuleById = (kural_id: number) => {
    dispatch(
      deleteHotelContractRule(kural_id, () =>
        getHotelContractRulesForCallback(otel_id)
      )
    );
  };

  const tableData = hotelContractRules?.map((hotelContractRule) => ({
    "Kural ID": hotelContractRule.id,
    "Kural Adı": hotelContractRule.adi,
    "Başlangıç Tarihi": hotelContractRule.baslangic_tarihi,
    "Kontrat Tipi": hotelContractRule.kontrat_tipi,
    "Oran/Tutar": hotelContractRule.veri,
    Limit: hotelContractRule.limit1,
    "Limit 2": hotelContractRule.limit2,
    rowColor: rowColors[hotelContractRule.kontrat_tipi as CONTRACT_TYPE],
  }));

  const tableColumns = [
    {
      header: "Kural ID",
      accessorKey: "Kural ID",
      enableColumnFilter: false,
    },
    {
      header: "Kural Adı",
      accessorKey: "Kural Adı",
      enableColumnFilter: false,
    },
    {
      header: "Başlangıç Tarihi",
      accessorKey: "Başlangıç Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Kontrat Tipi",
      accessorKey: "Kontrat Tipi",
      enableColumnFilter: false,
    },
    {
      header: "Oran/Tutar",
      accessorKey: "Oran/Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Limit",
      accessorKey: "Limit",
      enableColumnFilter: false,
    },
    {
      header: "Limit 2",
      accessorKey: "Limit 2",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Kural ID",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <CustomRowOperationButton
            variant="delete"
            onClick={() => {
              deleteHotelContractRuleById(id);
            }}
          />
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.blue,
      label: "Aylık",
    },
    {
      color: TABLE_COLORS.red,
      label: "Oda Başı Prim",
    },
    {
      color: TABLE_COLORS.purple,
      label: "Sezon Sonu Kotası",
    },
    {
      color: TABLE_COLORS.gray,
      label: "Tek Seferlik",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Tekrarlı Kota",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
  };
};

export default useHandleTable;
