import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  FacilityImagesInitialState,
  handleSaveOrderedFacilityImages,
  handleResetOrderedFacilityImagesToDefault,
  handleChangeSelectedImages,
  handleSelectAllImages,
  handleDeselectAllImages,
} from "slices/facility/facilityImages/reducer";
import {
  deleteFacilityImages,
  getFacilityImages,
  updateFacilityImagesOrder,
} from "slices/facility/facilityImages/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandleList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const {
    facilityImages,
    selectedImages,
    orderedFacilityImages,
    updateImagesOrderLoading,
    deleteImagesLoading,
  } = useSelector(
    (state: { FacilityImages: FacilityImagesInitialState }) =>
      state.FacilityImages
  );

  const { otel_id } = hotel;

  const handleOrderItems = (items: any[]) => {
    dispatch(handleSaveOrderedFacilityImages(items));
  };

  const handleDelete = (items: string[]) => {
    const payload = {
      otel_id,
      resim_adi: items,
    };

    dispatch(
      deleteFacilityImages(payload, () => {
        dispatch(getFacilityImages(otel_id));
      })
    );
  };

  const handleResetChanges = () => {
    dispatch(handleResetOrderedFacilityImagesToDefault());
  };

  const handleSelectImage = (image: string) => {
    dispatch(handleChangeSelectedImages(image));
  };

  const handleSelectAll = () => {
    dispatch(handleSelectAllImages());
  };

  const handleDeselectAll = () => {
    dispatch(handleDeselectAllImages());
  };

  const handleDeleteSelectedImages = () => {
    const payload = {
      otel_id,
      resim_adi: selectedImages,
    };

    dispatch(
      deleteFacilityImages(payload, () => {
        dispatch(getFacilityImages(otel_id));
      })
    );
  };

  const handleReorderImages = () => {
    const data = orderedFacilityImages.map((img) => ({
      resim_adi: img.resim_url.slice(img.resim_url.lastIndexOf("/") + 1),
      sira: img.sira,
    }));

    const payload = {
      otel_id,
      data,
    };

    dispatch(updateFacilityImagesOrder(payload));
  };

  return {
    facilityImages,
    orderedFacilityImages,
    handleOrderItems,
    handleDelete,
    handleDeleteSelectedImages,
    selectedImages,
    handleSelectImage,
    handleSelectAll,
    handleDeselectAll,
    handleReorderImages,
    handleResetChanges,
    updateImagesOrderLoading,
    deleteImagesLoading,
  };
};

export default useHandleList;
