import AuthorityRowField from "./AuthorityRowField";
import useHandleUserAuthorityOptions from "./useHandleUserAuthorityOptions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

const UserAuthorityOptions = () => {
  const { authorities, selectedPermissions, handlePermissionChange } =
    useHandleUserAuthorityOptions();

  const topLevelAuthorities = authorities.filter(
    (auth) => auth.turu === "anayetki"
  );

  return (
    <CustomFormFieldContainer label="Yetkiler " orientation="vertical">
      {topLevelAuthorities.map((topAuthority) => (
        <AuthorityRowField
          key={topAuthority.id}
          topAuthority={topAuthority}
          allAuthorities={authorities}
          selectedPermissions={selectedPermissions}
          onPermissionChange={handlePermissionChange}
        />
      ))}
    </CustomFormFieldContainer>
  );
};

export default UserAuthorityOptions;
