import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FACILITY_FEATURES_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityFeaturesProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityFeatures = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityFeaturesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Öne Çıkan Özellik 1"
        error={formErrors.one_cikan_ozellik1}
        description={FACILITY_FEATURES_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.one_cikan_ozellik1}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                one_cikan_ozellik1: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              one_cikan_ozellik1: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Öne Çıkan Özellik 2"
        error={formErrors.one_cikan_ozellik2}
        description={FACILITY_FEATURES_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.one_cikan_ozellik2}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                one_cikan_ozellik2: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              one_cikan_ozellik2: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Öne Çıkan Özellik 3"
        error={formErrors.one_cikan_ozellik3}
        description={FACILITY_FEATURES_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.one_cikan_ozellik3}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                one_cikan_ozellik3: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              one_cikan_ozellik3: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Öne Çıkan Özellik 4"
        error={formErrors.one_cikan_ozellik4}
        description={FACILITY_FEATURES_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.one_cikan_ozellik4}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                one_cikan_ozellik4: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              one_cikan_ozellik4: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityFeatures;
