import DebouncedInput from "./DebounceInput";
import PageSizeSelect from "./PageSizeSelect";

interface PaginationTopProps {
  onSearch: (searchValue: string) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const PaginationTop = ({
  onSearch = () => {},
  onPageSizeChange = () => {},
}: PaginationTopProps) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <DebouncedInput
        type="text"
        value=""
        onChange={onSearch}
        placeholder="Ara..."
        className="w-36 border shadow rounded"
        style={{ maxWidth: "300px" }}
      />
      <PageSizeSelect
        sizes={[10, 25, 50, 100]}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default PaginationTop;
