import { createSlice } from "@reduxjs/toolkit";

import {
  DefineCurrency,
  DefineCurrencyState,
  DefineCurrencyFormErrors,
} from "types/definitions";

export interface DefineCurrencyInitialState {
  defineCurrencyDefault: DefineCurrency;
  defineCurrency: DefineCurrencyState;
  defineCurrencyFormErrors: DefineCurrencyFormErrors;
  isLoading: boolean;
  error: string;
  defineCurrencyLoading: boolean;
  defineCurrencyError: string;
}

export const defineCurrencyInitialState: DefineCurrencyInitialState = {
  defineCurrencyDefault: {
    usd: "",
    euro: "",
  },
  defineCurrency: {
    usd: "",
    euro: "",
  },
  defineCurrencyFormErrors: {},
  isLoading: false,
  error: "",
  defineCurrencyLoading: false,
  defineCurrencyError: "",
};

export const defineCurrencySlice = createSlice({
  name: "defineCurrency",
  initialState: defineCurrencyInitialState,
  reducers: {
    handleSaveDefineCurrencyDefault(state, action) {
      state.defineCurrencyDefault = action.payload;
    },

    handleSaveDefineCurrency(state, action) {
      state.defineCurrency = action.payload;
    },
    handleAddDefineCurrencyFormErrors(state, action) {
      state.defineCurrencyFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.defineCurrency = {
        ...state.defineCurrency,
        ...action.payload,
      };
    },
    resetAfterUpdateDefineCurrency(state) {
      state.defineCurrency = {
        ...state.defineCurrencyDefault,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDefineCurrencyLoading(state, action) {
      state.defineCurrencyLoading = action.payload;
    },
    setDefineCurrencyError(state, action) {
      state.defineCurrencyError = action.payload;
    },
  },
});

export const {
  handleSaveDefineCurrencyDefault,
  handleSaveDefineCurrency,
  handleAddDefineCurrencyFormErrors,
  handleChangeInput,
  resetAfterUpdateDefineCurrency,
  setIsLoading,
  setError,
  setDefineCurrencyLoading,
  setDefineCurrencyError,
} = defineCurrencySlice.actions;

export default defineCurrencySlice.reducer;
