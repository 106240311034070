import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { IssuedInvoice } from "types/financeAndAccounting";
import { Pagination } from "types/GlobalTypes";

export interface IssuedInvoicesInitialState {
  issuedInvoices: IssuedInvoice[];
  managers: {
    [key: string]: string;
  };
  selectedManager: string;
  selectedDates: [string, string];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: IssuedInvoicesInitialState = {
  issuedInvoices: [],
  managers: {
    "0": "Tüm Sorumlular",
  },
  selectedManager: "",
  selectedDates: [
    moment().subtract(1, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const issuedInvoicesSlice = createSlice({
  name: "issuedInvoices",
  initialState,
  reducers: {
    handleSaveIssuedInvoices(state, action) {
      const { sonuclar, yetkililer, ...rest } = action.payload;

      state.issuedInvoices = sonuclar;
      state.managers = {
        "Tüm Sorumlular": "0",
        ...yetkililer,
      };
      state.pagination = rest;
    },
    handleSaveSelectedManager(state, action) {
      state.selectedManager = action.payload;
    },
    handleSaveSelectedDates(state, action) {
      state.selectedDates = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveIssuedInvoices,
  handleSaveSelectedManager,
  handleSaveSelectedDates,
  setIsLoading,
  setError,
} = issuedInvoicesSlice.actions;

export default issuedInvoicesSlice.reducer;
