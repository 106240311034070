import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateProfile } from "slices/thunks";
import { OFFICE_LOCATIONS } from "enums";

interface FormData {
  email: string;
  phoneNumber: string;
  office: string;
}

interface OfficeLoactionSelectOption {
  label: string;
  value: OFFICE_LOCATIONS;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectLayoutState = (state: any) => state.Profile;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    error: state.error,
    success: state.success,
  }));

  // Inside your component
  const { error, success } = useSelector(selectLayoutProperties);

  const selectUserState = (state: any) => state.Login;
  const selectUserProperties = createSelector(selectUserState, (state) => ({
    user: state.user,
  }));

  // Inside your component
  const { user } = useSelector(selectUserProperties);

  const [isLoading, setIsLoading] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: user?.email_adresi || "",
      phoneNumber: user?.profil_cep_telefon || "",
      office: user?.profil_adres || "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("E-posta gerekli")
        .email("Geçerli bir e-posta adresi girin"),
      phoneNumber: Yup.string().required("Telefon numarası gerekli"),
      office: Yup.string().required("En az bir ofis seçmelisiniz"),
    }),
    onSubmit: (values: FormData) => {
      dispatch(updateProfile(values));
      setIsLoading(true);
    },
  });

  useEffect(() => {
    if (error || success) {
      setIsLoading(false);
    }
  }, [error, success]);

  const officeLoactionSelectOptions: OfficeLoactionSelectOption[] = [
    {
      label: "İzmir",
      value: OFFICE_LOCATIONS.İzmir,
    },
    {
      label: "Alsancak",
      value: OFFICE_LOCATIONS.Alsancak,
    },
    {
      label: "Ankara",
      value: OFFICE_LOCATIONS.Ankara,
    },
  ];

  return {
    validation,
    isLoading,
    error,
    success,
    officeLoactionSelectOptions,
  };
};

export default useHandleForm;
