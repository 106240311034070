import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSelectedUsers,
} from "slices/userOperations/authorities/reducer";
import { UserAuthoritiesInitialState } from "slices/userOperations/authorities/reducer";

const useHandleList = (handleSelectUser: (userIds: number[]) => void) => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    users,
    selectedUsers
  } = useSelector(
    (state: { UserAuthorities: UserAuthoritiesInitialState }) =>
      state.UserAuthorities
  );

  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    const options = users.map((user) => ({
      value: user.id,
      label: user.name,
    }));
    setUserOptions(options);
  }, [users]);

  useEffect(() => {
    const selected = selectedUsers.map((id) => id.toString());
    setSelectedOptions(selected);
  }, [selectedUsers]);

  const handleSelectOptions = (selected: string[]) => {
    const userIds = selected.map((id) => parseInt(id));
    dispatch(handleSelectedUsers(userIds));
    handleSelectUser(userIds);
  };

  return {
    userOptions,
    selectedOptions,
    handleSelectOptions
  };
};

export default useHandleList;
