import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { UserIPsInitialState } from "slices/userOperations/userIPs/reducer";
import { getUserIPs } from "slices/userOperations/userIPs/thunk";

const useTransferReservation = () => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const { isLoading, error } = useSelector(
    (state: { UserIPs: UserIPsInitialState }) => state.UserIPs
  );

  useEffect(() => {
      dispatch(getUserIPs());
  }, [dispatch]);

  return {
    isLoading,
    error,
  };
};

export default useTransferReservation;
