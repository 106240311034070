import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/addUser/reducer";
import { AddUserState, AddUserFormErrors } from "types/userOperations";

interface UserCodeAndExtensionProps {
  values: AddUserState;
  formErrors: AddUserFormErrors;
  validateFormChange: (values: AddUserState) => void;
}

const UserCodeAndExtension = ({
  values,
  formErrors,
  validateFormChange,
}: UserCodeAndExtensionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Operatör Kodu"
        error={formErrors.inet_kodu}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.inet_kodu}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                inet_kodu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              inet_kodu: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Dahili Numarası"
        error={formErrors.dahili}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.dahili}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                dahili: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              dahili: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UserCodeAndExtension;
