import {
  BankInstallment,
  CalculatePriceForHotelConceptPrice,
} from "types/reservation";
import ConceptPrice from "../ConceptPrice";
import ConceptInfo from "../../ConceptInfo";
import PriceCalculationStatusMessages from "../../PriceCalculationStatusMessages";
import useHandleKaporaliPayButton from "../../PayButtonsHooks/useHandleKaporaliPayButton";
import KaporaliPayButton from "../PayButtons/KaporaliPayButton";

interface KaporaliPaymentProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  ayni_fiyatli_odeme_tipleri: string[];
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  kaporali_odeme: {
    kaporali_satis_durum: "evet" | "hayir";
    kaporali_satis_orani: string;
    kaporali_indirim_orani: string;
  };
  gifts: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  banka_taksit: BankInstallment[];
}

const KaporaliPayment = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  priceCalculationStatusMessages,
  kontenjan,
  kaporali_odeme,
  gifts,
  onClick,
  banka_taksit,
}: KaporaliPaymentProps) => {
  const {
    hasPrice,
    stop,
    stopMessage,
    showKaporaliOdemeButton,
    kaporaliOdemeFiyatDurum,
    kaporaliOdemeButtonText,
    kaporaliOdemePrice,
    kaporaliOdemeOverlinedPrice,
    kaporaAmount,
    messages,
  } = useHandleKaporaliPayButton({
    fiyatlar,
    kaporali_odeme,
    priceCalculationStatusMessages,
  });

  return (
    <div
      className="rounded p-2 border bg-white"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        minHeight: "120px",
      }}
    >
      <ConceptInfo
        priceType={["kaporali"]}
        oda_id={oda_id}
        pansiyon_id={pansiyon_id}
        pansiyon_adi={pansiyon_adi}
        fiyatlar={fiyatlar}
        kontenjan={kontenjan}
        gifts={gifts}
      />
      <ConceptPrice
        showPrice={showKaporaliOdemeButton}
        price={kaporaliOdemePrice}
        overlinedPrice={kaporaliOdemeOverlinedPrice}
        kaporali={{
          status: true,
          kaporaAmount: kaporaAmount,
        }}
        banka_taksit={banka_taksit}
      />

      <div id={"kaporali" + pansiyon_id}>
        {hasPrice ? (
          <KaporaliPayButton
            stop={stop}
            stopMessage={stopMessage}
            showKaporaliOdemeButton={showKaporaliOdemeButton}
            kaporaliOdemeFiyatDurum={kaporaliOdemeFiyatDurum}
            kaporaliOdemeButtonText={kaporaliOdemeButtonText}
            kaporaliOdemePrice={kaporaliOdemePrice}
            kaporaAmount={kaporaAmount}
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            onClick={onClick}
          />
        ) : (
          <>
            <div></div>
            <PriceCalculationStatusMessages messages={messages} />
          </>
        )}
      </div>
    </div>
  );
};

export default KaporaliPayment;
