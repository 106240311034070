import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { Input, Label } from "reactstrap";
import { UpdateWelcomeAndCalculationFormRadioInput } from "types/reports";

const UpdateWelcomeAndCalculationForm = () => {
  const {
    updateWelcomeAndCalculation,
    updateWelcomeAndCalculationState,
    selectedHotel,
    onChangeInput,
    keyMatches,
    onUpdate,
    onEndEditing,
    onClearForm,
    isLoading,
  } = useHandleForm();

  return (
    <>
      {selectedHotel ? (
        <CustomFormContainer title="Karşılama ve Fiyat Hesaplama Güncelleme">
          {Object.entries(updateWelcomeAndCalculation).map(
            ([key, value], index) => {
              if (key === "otel_adi") {
                return (
                  <CustomFormFieldContainer
                    key={index}
                    label={keyMatches[key]}
                    divider
                  >
                    <Input type="text" value={value} readOnly />
                  </CustomFormFieldContainer>
                );
              } else if (key === "sorunlu_reklam") {
                return (
                  <>
                    {updateWelcomeAndCalculationState.reklam === "sorunlu" && (
                      <CustomFormFieldContainer
                        key={index}
                        label={keyMatches[key]}
                        orientation="vertical"
                        required
                        description="Üstteki seçenek 'Sorunlu' olarak seçildiğinde doldurulması gerekir. En fazla 250 karakter olmalıdır."
                      >
                        <Input
                          type="textarea"
                          value={
                            updateWelcomeAndCalculationState.sorunlu_reklam
                          }
                          onChange={(e) =>
                            onChangeInput(key, e.target.value.toString())
                          }
                          max={250}
                        />
                      </CustomFormFieldContainer>
                    )}
                  </>
                );
              } else
                return (
                  <CustomFormFieldContainer
                    key={index}
                    label={keyMatches[key]}
                    orientation="vertical"
                    divider
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(5, 1fr)",
                        gap: "1rem",
                      }}
                    >
                      {value.map(
                        (
                          item: UpdateWelcomeAndCalculationFormRadioInput,
                          index: number
                        ) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2 border rounded bg-white p-2"
                          >
                            <Input
                              id={item.name + item.value}
                              className="m-0 cursor-pointer"
                              type="radio"
                              name={item.name}
                              value={item.value}
                              checked={
                                (
                                  updateWelcomeAndCalculationState as unknown as any
                                )[key] === item.value
                              }
                              onChange={(e) =>
                                onChangeInput(key, e.target.value)
                              }
                            />
                            <Label
                              className="m-0 fs-14 cursor-pointer"
                              for={item.name + item.value}
                            >
                              {item.text}
                            </Label>
                          </div>
                        )
                      )}
                    </div>
                  </CustomFormFieldContainer>
                );
            }
          )}
          <div
            className="w-100 text-end mb-3 d-flex align-items-center
          justify-content-end gap-3"
          >
            <CustomButton variant="danger" onClick={onEndEditing}>
              Düzenlemeden Çık
            </CustomButton>
            <CustomButton variant="warning" onClick={onClearForm}>
              Formu Temizle
            </CustomButton>
            <CustomButton
              variant="primary"
              onClick={onUpdate}
              isLoading={isLoading}
            >
              Güncelle
            </CustomButton>
          </div>
        </CustomFormContainer>
      ) : (
        <> </>
      )}
    </>
  );
};

export default UpdateWelcomeAndCalculationForm;
