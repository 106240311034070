import { useSelector } from "react-redux";
import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";

const useHandleRates = () => {
  const { competitorAnalysisResponse } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const rates = competitorAnalysisResponse.personel_oran;

  return { rates };
};

export default useHandleRates;
