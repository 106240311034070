import { useSelector } from "react-redux";
import { LoginInitialState } from "slices/auth/login/reducer";
import { ReservationsInitialState } from "slices/reports/reservations/reducer";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const { reservations, reservationsResponse } = useSelector(
    (state: { Reservations: ReservationsInitialState }) => state.Reservations
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki } = user;

  const dataKeyMatch: { [key: string]: string } = {
    otele_odeme_durum: "otele_odeme_durum", 
    muhasebe_tahsilat_onay: "muhasebe_tahsilat_onay", 
    ana_rez_kod: "ana_rez_kod", 
    rezervasyon_kodu: "rezervasyon_kodu",
    kayit_tarihi: "kayit_tarihi",
    giris_cikis_tarihi: "giris_cikis_tarihi",
    musteri_ad_soyad: "musteri_ad_soyad",
    otel_adi: "otel_adi",
    otel_id: "otel_id",
    toplam_tutar: "toplam_tutar",
    grup: "grup", 
    personel: "personel",
    musaitlik: "musaitlik",
    operator_firma: "operator_firma",
    operator_rez_kodu: "operator_rez_kodu",
    id: "id",
    oda_id: "oda_id",
    cekilen: "cekilen",
    komisyon_tutar: "komisyon_tutar",
    otel_tutar: "otel_tutar", 
    durum: "durum",
    renk: "renk",
    tr_display: "tr_display",
    komisyon: "komisyon",
  };

  const hiddenColumns =
  reservationsResponse.gosterilmeyecek_alanlar[yetki] ?? [];

  const columnVisibility: { [key: string]: boolean } = {};

  hiddenColumns.forEach((column) => {
    columnVisibility[dataKeyMatch[column]] = false;
  });

  const rowColors = {
    success: TABLE_COLORS.green,
    danger: TABLE_COLORS.red,
    warning: TABLE_COLORS.yellow,
  };

  const tableData = reservations?.map((reservation) => ({
    otele_odeme_durum: reservation.otele_odeme_durum, //type?
    muhasebe_tahsilat_onay: reservation.muhasebe_tahsilat_onay, //type?
    ana_rez_kod: reservation.ana_rez_kod, //type?
    rezervasyon_kodu: reservation.rezervasyon_kodu,
    kayit_tarihi: reservation.kayit_tarihi,
    giris_cikis_tarihi: `${reservation.giris_tarihi} - ${reservation.cikis_tarihi}`,
    musteri_ad_soyad: reservation.musteri_ad_soyad,
    otel_adi: reservation.otel_adi,
    otel_id: reservation.otel_id,
    toplam_tutar: reservation.toplam_tutar,
    grup: reservation.grup, //type?
    personel: reservation.personel,
    musaitlik: reservation.musaitlik,
    operator_firma: reservation.operator_firma,
    operator_rez_kodu: reservation.operator_rez_kodu,
    id: reservation.id,
    oda_id: reservation.oda_id,
    cekilen: reservation.cekilen,
    komisyon_tutar: reservation.komisyon_tutar,
    otel_tutar: reservation.otel_tutar, //type?
    durum: reservation.durum,
    renk: reservation.renk,
    tr_display: reservation.tr_display,
    komisyon: reservation.komisyon,
    rowColor: rowColors[reservation.renk as keyof typeof rowColors],
  }));

  const tableColumns = [
    {
      header: "Otele Ödeme Durumu",
      accessorKey: "otele_odeme_durum",
      enableColumnFilter: false,
    },
    {
      header: "Muhasebe Tahsilat Onayı",
      accessorKey: "muhasebe_tahsilat_onay",
      enableColumnFilter: false,
    },
    {
      header: "Ana Rezervasyon Kodu",
      accessorKey: "ana_rez_kod",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Kodu",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Tarihi",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "100px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Giriş - Çıkış Tarihleri",
      accessorKey: "giris_cikis_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "100px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Müşteri Ad Soyad",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Tutarı",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "grup",
      enableColumnFilter: false,
    },
    {
      header: "Personel Ad Soyad",
      accessorKey: "personel",
      enableColumnFilter: false,
    },
    {
      header: "Müsaitlik",
      accessorKey: "musaitlik",
      enableColumnFilter: false,
    },
    {
      header: "Operatör Firma",
      accessorKey: "operator_firma",
      enableColumnFilter: false,
    },
    {
      header: "Operatör Rezervasyon Kodu",
      accessorKey: "operator_rez_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Id",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Oda Id",
      accessorKey: "oda_id",
      enableColumnFilter: false,
    },
    {
      header: "Çekilen Tutar",
      accessorKey: "cekilen",
      enableColumnFilter: false,
    },
    {
      header: "Komisyon Tutarı",
      accessorKey: "komisyon_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Otel Tutarı",
      accessorKey: "otel_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Durumu",
      accessorKey: "durum",
      enableColumnFilter: false,
    },
    {
      header: "Komisyon Oranı",
      accessorKey: "komisyon",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "Evet",
    },
    {
      color: TABLE_COLORS.red,
      label: "İptal",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Hayır",
    },
  ];

  return { tableData, tableColumns, columnVisibility, legend };
};

export default useHandleTable;
