import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { FacilityCallInitialState } from "slices/facility/facilityCalls/reducer";

import useHandleRequests from "./useHandleRequests";
import ShowFacilityManagersModal from "./TableModals/ShowFacilityManagersModal";
import UpdateFacilityCallModal from "./TableModals/UpdateFacilityCallModal";
import ShowDetailedInfoModal from "./TableModals/ShowDetailedInfoModal";

const useHandleTable = () => {
  const { facilityCalls } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  const {
    showFacilityManagersModal,
    showUpdateFacilityCallModal,
    showDetailedInfoModal,
    setShowUpdateFacilityCallModal,
    onToggleUpdateFacilityCallModal,
    onToggleFacilityManagersModal,
    onToggleDetailedInfoModal,
    onApproveFacilityCall,
    onRejectFacilityCall,
    onGetManagers,
  } = useHandleRequests();

  const tableData = facilityCalls?.map((facilityCall) => ({
    otel_id: facilityCall.otel_id,
    otel_adi: facilityCall.otel_adi,
    yetkili: facilityCall.yetkili,
    il: facilityCall.sehir,
    ilçe: facilityCall.ilce,
    rez_satis_tutari: facilityCall.rez_satis_tutari,
    rez_oda_sayisi: facilityCall.rez_oda_sayisi,
    durum: facilityCall.operasyon_durum,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Yetkili",
      accessorKey: "yetkili",
      enableColumnFilter: false,
    },
    {
      header: "İl",
      accessorKey: "il",
      enableColumnFilter: false,
    },
    {
      header: "İlçe",
      accessorKey: "ilçe",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Satış Tutarı",
      accessorKey: "rez_satis_tutari",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Oda Sayısı",
      accessorKey: "rez_oda_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "durum",
      cell: (cell: any) => {
        const value = cell.getValue();
        const status =
          value === "1" ? "Çözüldü" : value === "2" ? "Çözülmedi" : "Beklemede";

        const color =
          value === "1" ? "success" : value === "2" ? "danger" : "secondary";
        return (
          <span className={`badge bg-${color} text-${color} bg-opacity-25`}>
            {status}
          </span>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "otel_id",
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="check"
                onClick={() => onToggleUpdateFacilityCallModal(id, "approve")}
                title="Çözüldü olarak işaretle"
              />
              <CustomRowOperationButton
                variant="uncheck"
                onClick={() => onToggleUpdateFacilityCallModal(id, "reject")}
                title="Çözülmedi olarak işaretle"
              />
              <CustomRowOperationButton
                variant="detail"
                onClick={() => onToggleDetailedInfoModal()}
                title="Durumunu gör"
              />
              <CustomRowOperationButton
                variant="info"
                onClick={() => {
                  onGetManagers(id);
                  onToggleFacilityManagersModal();
                }}
                title="Yetkili listesi"
              />
            </div>
            <ShowFacilityManagersModal
              hotelId={id}
              isOpen={showFacilityManagersModal}
              handleToggle={onToggleFacilityManagersModal}
            />
            <UpdateFacilityCallModal
              hotelId={id}
              isOpen={showUpdateFacilityCallModal}
              handleToggle={() =>
                setShowUpdateFacilityCallModal(
                  (prevState: boolean) => !prevState
                )
              }
              approveCallback={onApproveFacilityCall}
              rejectCallback={onRejectFacilityCall}
            />
            <ShowDetailedInfoModal
              hotelId={id}
              isOpen={showDetailedInfoModal}
              handleToggle={onToggleDetailedInfoModal}
            />
          </>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
