import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveDiscountCodes,
  resetDiscountCodes,
  resetDiscountCode,
  setAddCodeLoading,
  setAddCodeError,
  setUpdateCodeLoading,
  setUpdateCodeError,
  setDeleteCodeLoading,
  setDeleteCodeError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_DISCOUNT_CODES,
  ADD_DISCOUNT_CODE,
  UPDATE_DISCOUNT_CODE,
  DELETE_DISCOUNT_CODE,
} from "helpers/url_helper";

import { Dispatch } from "redux";

import { DiscountCodeRequestPayload } from "types/DiscountsAndActions";

interface DeleteDiscountCodePayload {
  indirim_kuponu_id: number;
}

type SuccessCallback = () => void;

export const getDiscountCodes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(resetDiscountCodes());

    const response = await axios.get(GET_DISCOUNT_CODES);

    dispatch(handleSaveDiscountCodes(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addDiscountCode =
  (payload: DiscountCodeRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddCodeLoading(true));

      await axios.post(ADD_DISCOUNT_CODE, payload);

      successCallback();
      dispatch(resetDiscountCode());
      dispatch(setToastSuccess("İndirim kuponu eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddCodeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddCodeLoading(false));
    }
  };

export const updateDiscountCode =
  (payload: DiscountCodeRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setUpdateCodeLoading(true));

      await axios.post(UPDATE_DISCOUNT_CODE, payload);

      successCallback();
      dispatch(resetDiscountCode());
      dispatch(setToastSuccess("İndirim kuponu güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateCodeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateCodeLoading(false));
    }
  };

export const deleteDiscountCode =
  (payload: DeleteDiscountCodePayload) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setDeleteCodeLoading(true));

      await axios.post(DELETE_DISCOUNT_CODE, payload);

      dispatch(setToastSuccess("İndirim kuponu silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteCodeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteCodeLoading(false));
    }
  };
