import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveMonthlySales,
  handleSaveMonthlySalesFilter,
  setIsLoading,
  setError,
  setFilterLoading,
  setFilterError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_MONTHLY_SALES,
  GET_MONTHLY_SALE_FILTERS,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetMonthlySalesPayload {
  grup_id: number;
  date: string;
}

export const getMonthlySales =
  (payload: GetMonthlySalesPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_MONTHLY_SALES, payload);

      dispatch(handleSaveMonthlySales(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getMonthlySalesFilter = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setFilterLoading(true));
    const response = await axios.get(GET_MONTHLY_SALE_FILTERS);

    dispatch(handleSaveMonthlySalesFilter(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setFilterError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setFilterLoading(false));
  }
};
