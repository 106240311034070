import { OrderThemeHotelsPage } from "Components/Custom/ThemesPages";
import useOrderThemeHotels from "Components/Hooks/themes/useOrderThemeHotels";
import PageContainer from "pages/PageContainer";

const OrderThemeHotels = () => {
  const props = useOrderThemeHotels();

  return (
    <PageContainer title="Tema Otel Sıralama">
      <OrderThemeHotelsPage {...props} />
    </PageContainer>
  );
};

export default OrderThemeHotels;
