import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FormGroup, Input, Label } from "reactstrap";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/giftsAndPromotions/reducer";
import {
  GiftAndPromotionFormErrors,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

interface ApplicationCriteriaProps {
  values: GiftAndPromotionState;
  formErrors: GiftAndPromotionFormErrors;
  validateFormChange: (values: GiftAndPromotionState) => void;
}

const ApplicationCriteria = ({ values }: ApplicationCriteriaProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Uygulama Kriteri"
        tooltipId="giftPromotionApplicationCriteria"
      >
        <div className="d-flex flex-column gap-1">
          <FormGroup check>
            <Input
              type="radio"
              name="applicationCriteria"
              id="criteria-according-to-date-range"
              checked={values.hediye_kosulu === 1}
              value={1}
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    hediye_kosulu: Number(e.target.value),
                  })
                );
              }}
            />
            <Label for="criteria-according-to-date-range" className="fs-14">
              Tarih Aralığına Göre
            </Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="radio"
              name="applicationCriteria"
              id="criteria-according-to-date-range-with-minimum-night"
              checked={values.hediye_kosulu === 2}
              value={2}
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    hediye_kosulu: Number(e.target.value),
                  })
                );
              }}
            />
            <Label
              for="criteria-according-to-date-range-with-minimum-night"
              className="fs-14"
            >
              Tarih Aralığı ve Minimum Gece Sayısına Göre
            </Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="radio"
              name="applicationCriteria"
              id="criteria-according-to-minimum-night"
              checked={values.hediye_kosulu === 3}
              value={3}
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    hediye_kosulu: Number(e.target.value),
                  })
                );
              }}
            />
            <Label for="criteria-according-to-minimum-night" className="fs-14">
              Minimum Gece Sayısına Göre
            </Label>
          </FormGroup>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default ApplicationCriteria;
