import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";
import FieldPopup from "./FieldPopup";

import OtelzImg from "../../../../../../assets/images/competitor-analyse/otelz-rakip.png";
import HotelsImg from "../../../../../../assets/images/competitor-analyse/hotels-rakip.png";
import EtsTurImg from "../../../../../../assets/images/competitor-analyse/ets-rakip.png";
import TatilSepetiImg from "../../../../../../assets/images/competitor-analyse/tatilsepeti-rakip.png";
import TatilComImg from "../../../../../../assets/images/competitor-analyse/tatilcom-rakip.png";
import TatilbudurImg from "../../../../../../assets/images/competitor-analyse/tatilbudur-rakip.png";
import OdamaxImg from "../../../../../../assets/images/competitor-analyse/odamax-rakip.png";

import {
  FACILITY_COMPETITOR_ETSTUR_DESCRIPTION,
  FACILITY_COMPETITOR_HOTELS_DESCRIPTION,
  FACILITY_COMPETITOR_ODAMAX_DESCRIPTION,
  FACILITY_COMPETITOR_OTELZ_DESCRIPTION,
  FACILITY_COMPETITOR_TATILBUDUR_DESCRIPTION,
  FACILITY_COMPETITOR_TATILCOM_DESCRIPTION,
  FACILITY_COMPETITOR_TATILSEPETI_DESCRIPTION,
} from "utils/fieldDescriptions";

interface FacilityCompetitorAnalyseProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityCompetitorAnalyse = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityCompetitorAnalyseProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Tesis Rakip Analizi Verileri" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="Jolly Tur ID"
        error={formErrors.jollytur}
        divider
      >
        <FieldPopup
          label="Jolly Otel Listesi"
          link="https://www.otelfiyat.com/yonetim/jollyveriler.html"
        />
        <Input
          type="text"
          value={values.jollytur}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                jollytur: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              jollytur: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Coral Travel ID"
        error={formErrors.odeon}
        divider
      >
        <FieldPopup
          label="Coral Otel Listesi"
          link="https://www.otelfiyat.com/yonetim/veriler.html"
        />
        <Input
          type="text"
          value={values.odeon}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                odeon: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              odeon: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Otelz.com ID"
        error={formErrors.otel_z}
        divider
      >
        <FieldPopup
          title="Otelz.com ID"
          content={FACILITY_COMPETITOR_OTELZ_DESCRIPTION}
          imageUrl={OtelzImg}
          imageWidth={450}
          imageHeight={70}
        />
        <Input
          type="text"
          value={values.otel_z}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_z: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_z: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Hotels ID"
        error={formErrors.hotels}
        divider
      >
        <FieldPopup
          title="Hotels ID"
          content={FACILITY_COMPETITOR_HOTELS_DESCRIPTION}
          imageUrl={HotelsImg}
        />
        <Input
          type="text"
          value={values.hotels}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                hotels: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              hotels: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="ETS Tur URL"
        error={formErrors.ets_tur_url}
        divider
      >
        <FieldPopup
          title="ETS Tur URL"
          content={FACILITY_COMPETITOR_ETSTUR_DESCRIPTION}
          imageUrl={EtsTurImg}
        />
        <Input
          type="text"
          value={values.ets_tur_url}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                ets_tur_url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ets_tur_url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tatilsepeti URL"
        error={formErrors.tatil_sepeti_url}
        divider
      >
        <FieldPopup
          title="Tatilsepeti URL"
          content={FACILITY_COMPETITOR_TATILSEPETI_DESCRIPTION}
          imageUrl={TatilSepetiImg}
        />
        <Input
          type="text"
          value={values.tatil_sepeti_url}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                tatil_sepeti_url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              tatil_sepeti_url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tatil.com URL"
        error={formErrors.tatil_com_url}
        divider
      >
        <FieldPopup
          title="Tatil.com URL"
          content={FACILITY_COMPETITOR_TATILCOM_DESCRIPTION}
          imageUrl={TatilComImg}
        />

        <Input
          type="text"
          value={values.tatil_com_url}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                tatil_com_url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              tatil_com_url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tatilbudur.com URL"
        error={formErrors.tatil_budur_url}
        divider
      >
        <FieldPopup
          title="Tatilbudur.com URL"
          content={FACILITY_COMPETITOR_TATILBUDUR_DESCRIPTION}
          imageUrl={TatilbudurImg}
          imageHeight={45}
        />

        <Input
          type="text"
          value={values.tatil_budur_url}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                tatil_budur_url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              tatil_budur_url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Odamax URL"
        error={formErrors.odamax_url}
      >
        <FieldPopup
          title="Odamax URL"
          content={FACILITY_COMPETITOR_ODAMAX_DESCRIPTION}
          imageUrl={OdamaxImg}
        />
        <Input
          type="text"
          value={values.odamax_url}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                odamax_url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              odamax_url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityCompetitorAnalyse;
