import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
import AccomodationInformationImage from "./AccomodationInformationImage";
import AccomodationInformationText from "./AccomodationInformationText";

interface AccomodationInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const AccomodationInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: AccomodationInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Konaklama Bilgileri" lg={12} xl={12}>
        <AccomodationInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <AccomodationInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default AccomodationInformationFields;
