import { useState } from "react";

interface FieldPopupProps {
  label?: string;
  link?: string;
  title?: string;
  imageUrl?: string;
  content?: string;
  imageWidth?: number;
  imageHeight?: number;
}

const FieldPopup = ({
  label,
  link,
  title,
  imageUrl,
  content,
  imageWidth = 500,
  imageHeight = 35,
}: FieldPopupProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      {label && link ? (
        <div className="mb-2">
          <a
            style={{
              textDecoration: "none",
            }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {label}
          </a>
        </div>
      ) : (
        <div className=" position-relative">
          {showTooltip && (
            <div
              className="d-flex flex-column gap-2 border border-2 p-2 bg-white rounded-3 shadow-sm"
              style={{
                position: "absolute",
                bottom: "30px",
                width: `${imageWidth + 30}px`,
              }}
            >
              <p className="fw-bold p-0">{title}</p>
              <img
                src={imageUrl}
                alt={title}
                width={imageWidth}
                height={imageHeight}
              />

              <span className="fs-14 fw-medium">{content}</span>
            </div>
          )}
          <i
            className="ri-information-fill fs-18 ms-1 p-2 ps-0 theme-text-primary"
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          ></i>
        </div>
      )}
    </>
  );
};

export default FieldPopup;
