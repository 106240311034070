import AddCertificateForm from "./AddCertificateForm";
import ListCertificatesTable from "./ListCertificatesTable";
import useHandleForm from "./useHandleForm";

const CertificatesForm = () => {
  const {
    addCertificate,
    certificateLinkFormErrors,
    addCertificateRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
    addCertificateLoading
  } = useHandleForm();

  return (
    <>
      <div className="px-4 mb-4">
        <AddCertificateForm
          values={addCertificate}
          formErrors={certificateLinkFormErrors}
          requestPayload={addCertificateRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          addCertificateLoading={addCertificateLoading} 
          validateFormChange={validateFormChange}
        />
        <ListCertificatesTable isLoading={isLoading}/>
      </div>
    </>
  );
};

export default CertificatesForm;
