import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";

import { FacilityManagersInitialState } from "slices/facility/facilityManagers/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  AddFacilityManagerRequestPayload,
  UpdateFacilityManagerRequestPayload,
} from "types/facility";
import { FACILITY_MANAGER_ROLE } from "enums";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const {
    facilityManager,
    facilityManagerFormErrors,
    addManagerLoading,
    updateManagerLoading,
  } = useSelector(
    (state: { FacilityManagers: FacilityManagersInitialState }) =>
      state.FacilityManagers
  );

  const managerRolesOptions: Option[] = [
    {
      label: "Ana Yetkili",
      value: FACILITY_MANAGER_ROLE.ana_yetkili,
    },
    {
      label: "Rezervasyon",
      value: FACILITY_MANAGER_ROLE.rezervasyon,
    },
    {
      label: "Muhasebe",
      value: FACILITY_MANAGER_ROLE.muhasebe,
    },
    {
      label: "Bilgi İşlem",
      value: FACILITY_MANAGER_ROLE.bilgi_islem,
    },
    {
      label: "Otel Genel İletişim",
      value: FACILITY_MANAGER_ROLE.otel_genel_iletisim,
    },
  ];

  const addFacilityManagerRequestPayload: AddFacilityManagerRequestPayload = {
    otel_id: hotel.otel_id,
    yeni_kullanicimi: 1,
    yetkili_adi: facilityManager.yetkili_adi,
    unvan: facilityManager.unvan,
    mail: facilityManager.mail,
    telefon: facilityManager.telefon,
    birim: FACILITY_MANAGER_ROLE[facilityManager.birim],
  };

  const updateFacilityManagerRequestPayload: UpdateFacilityManagerRequestPayload =
    {
      id: facilityManager.id,
      yetkili_adi: facilityManager.yetkili_adi,
      unvan: facilityManager.unvan,
      mail: facilityManager.mail,
      telefon: facilityManager.telefon,
      birim: FACILITY_MANAGER_ROLE[facilityManager.birim],
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    facilityManager,
    managerRolesOptions,
    formErrors: facilityManagerFormErrors,
    addManagerLoading,
    updateManagerLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addFacilityManagerRequestPayload,
    updateFacilityManagerRequestPayload,
  };
};

export default useHandleForm;
