import StopButtons from "./PaymentButtons/StopButtons";
import parse from "html-react-parser";

interface ConceptButtonContainerProps {
  children: React.ReactNode;
  text: string;
  showButton: boolean;
  tooltipId: string;
  showTooltip?: boolean;
  stop: 0 | 1 | 2 | 3 | null;
  stopMessage: string;
}

const ConceptButtonContainer = ({
  children,
  text,
  showButton,
  tooltipId,
  showTooltip = false,
  stop,
  stopMessage,
}: ConceptButtonContainerProps) => {
  const id = showTooltip ? tooltipId : tooltipId + "no-tooltip";

  return (
    <>
      {showButton && (
        <div id={id} className="d-flex flex-column gap-2">
          {stop === 2 ? (
            <StopButtons
              stop={stop}
              message="Müsaitlik Yoktur!"
              description="Bilgi için lütfen operasyonu arayınız."
            />
          ) : stop === 3 ? (
            <StopButtons
              stop={stop}
              message={stopMessage}
              description="Operasyonu Arayıp Kontenjanı Arttırabilirsiniz."
            />
          ) : (
            children
          )}
          <span className="flex-grow-1 text-center fs-14 text-muted ">
            {parse(text)}
          </span>
        </div>
      )}
    </>
  );
};
export default ConceptButtonContainer;
