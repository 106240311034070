import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import FeatureName from "./FeatureName";
import AddNewHotelFeatureButton from "./AddNewHotelFeatureButton";

interface AddHotelFeatureFormProps {
  isLoading: boolean;
}

const AddHotelFeatureForm = ({ isLoading }: AddHotelFeatureFormProps) => {
  const {
    hotelFeature,
    addNewHotelFeatureRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();
  return (
    <CustomFormContainer>
      <FeatureName
        values={hotelFeature}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddNewHotelFeatureButton
        values={hotelFeature}
        requestPayload={addNewHotelFeatureRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default AddHotelFeatureForm;
