import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleAddCreateWebSiteFormErrors } from "slices/myWebSite/createWebSite/reducer";
import { setToastError } from "slices/toast/reducer";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: CreateWebSiteState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: CreateWebSiteFormErrors = {};

    if (!values.tema_id) {
      errors.tema_id = "Tema seçimi yapınız.";
    }

    if (!values.domain_link) {
      errors.domain_link = "Domain boş bırakılamaz.";
    }

    if (!values.otel_telefon) {
      errors.otel_telefon = "Otel telefon alanı boş bırakılamaz.";
    }

    if (!values.otel_mail) {
      errors.otel_mail = "Otel mail alanı boş bırakılamaz.";
    }

    if (!values.otel_adres) {
      errors.otel_adres = "Otel adresi boş bırakılamaz.";
    }

    if (!values.otel_google_map_x) {
      errors.otel_google_map_x = "Otel lokasyonu x koordinatı boş bırakılamaz.";
    }

    if (!values.otel_google_map_y) {
      errors.otel_google_map_y = "Otel lokasyonu y koordinatı boş bırakılamaz.";
    }

    if (!values.karsilama_cumlesi) {
      errors.karsilama_cumlesi = "Karşılama cümlesi boş bırakılamaz.";
    }

    if (!values.vitrin_resim && !values.vitrin_resim_onizleme) {
      errors.vitrin_resim = "Vitrin resimleri boş bırakılamaz.";
    }

    if (values.one_cikan_ozellik.length > 3) {
      errors.one_cikan_ozellik = "En fazla 3 tane özellik seçiniz.";
    }

    /*-------------- Reklam Popup --------------- */
    if (!values.reklam_popup) {
      errors.reklam_popup = "Reklam popup gösterip göstermeyeceğinizi seçmelisiniz.";
    }

    if (!values.banner1_baslik) {
      errors.banner1_baslik = "Banner başlığı boş bırakılamaz.";
    }

    if (!values.banner1_resim_1 && !values.banner1_resim1_onizleme) {
      errors.banner1_resim1 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner1_resim_2 && !values.banner1_resim2_onizleme) {
      errors.banner1_resim2 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner1_resim_3 && !values.banner1_resim3_onizleme) {
      errors.banner1_resim3 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner1_icerik) {
      errors.banner1_icerik = "Banner açıklaması boş bırakılamaz.";
    }

    if (!values.banner2_baslik) {
      errors.banner2_baslik = "Banner başlığı boş bırakılamaz.";
    }

    if (!values.banner2_resim1 && !values.banner2_resim1_onizleme) {
      errors.banner2_resim1 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner2_resim2 && !values.banner2_resim2_onizleme) {
      errors.banner2_resim2 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner2_resim3 && !values.banner2_resim3_onizleme) {
      errors.banner2_resim3 = "Banner resimleri boş bırakılamaz.";
    }

    if (!values.banner2_icerik) {
      errors.banner2_icerik = "Banner açıklaması boş bırakılamaz.";
    }

    dispatch(handleAddCreateWebSiteFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: CreateWebSiteState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
