import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { OutboundCallsListInitialState } from "slices/reports/outboundCallsList/reducer";
import { getOutboundCalls } from "slices/reports/outboundCallsList/thunk";

const useOutboundCallsList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { OutboundCallsList: OutboundCallsListInitialState }) =>
      state.OutboundCallsList
  );

  const params = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    dispatch(getOutboundCalls(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useOutboundCallsList;
