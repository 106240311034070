import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import moment from "moment";
import { postBasicPriceEntries } from "slices/pricesAndQuota/basicPriceEntry/thunk";
import { IncomingPrice } from "types/PricesQuotas";

interface RequestPayload {
  otel_id: number;
  tarihler: string;
  tum_gunler: number;
  gunler: number[];
  gelen_fiyatlar: IncomingPrice[];
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectedBasicPriceEntryState = (state: any) => state.BasicPriceEntry;
  const selectBasicPriceEntryProperties = createSelector(
    selectedBasicPriceEntryState,
    (state) => ({
      hotel: state.hotel,
      changedPrices: state.changedPrices,
      selectedDates: state.selectedDates,
      selectedDays: state.selectedDays,
      isAllDaysSelected: state.isAllDaysSelected,
      addPricesLoading: state.addPricesLoading,
    })
  );

  const {
    hotel,
    changedPrices,
    selectedDates,
    selectedDays,
    isAllDaysSelected,
    addPricesLoading,
  } = useSelector(selectBasicPriceEntryProperties);

  const handleSave = () => {
    const startDate = moment(selectedDates.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectedDates.endDate).format("YYYY-MM-DD");

    const modifiedChangedPrices = changedPrices.length
      ? changedPrices.map((price: IncomingPrice) => {
          return {
            ...price,
            tarih: `${startDate}`,
          };
        })
      : [];

    const payload: RequestPayload = {
      otel_id: hotel.otel_id,
      tarihler: `${startDate} - ${endDate}`,
      tum_gunler: isAllDaysSelected ? 1 : 0,
      gunler: selectedDays,
      gelen_fiyatlar: modifiedChangedPrices,
    };

    dispatch(postBasicPriceEntries(payload));
  };

  return {
    selectedDates,
    selectedDays,
    isAllDaysSelected,
    handleSave,
    isLoading: addPricesLoading,
  };
};

export default useHandleForm;
