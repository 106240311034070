import { createSlice } from "@reduxjs/toolkit";

import { OurOwnHotel } from "types/reports";

export interface OurOwnHotelsInitialState {
  ourOwnHotels: OurOwnHotel[];
  isLoading: boolean;
  error: string;
}

const initialState: OurOwnHotelsInitialState = {
  ourOwnHotels: [],
  isLoading: false,
  error: "",
};

const ourOwnHotelsSlice = createSlice({
  name: "ourOwnHotels",
  initialState,
  reducers: {
    handleSaveOurOwnHotels(state, action) {
      state.ourOwnHotels = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveOurOwnHotels, setIsLoading, setError } =
  ourOwnHotelsSlice.actions;

export default ourOwnHotelsSlice.reducer;