import { convertPricingStructure } from "helpers/general_helpers";
import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleDetails = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const {
    telefon,
    otelin_gercek_telefonu,
    otel_adres,
    otel_turu,
    otel_mevkii,
    otel_yenileme,
    otel_oda_sayisi,
    ucretsiz_cocuk,
    one_cikan_ozellik,
    genel_aciklama,
    konaklama_aciklama,
    yiyecek_aciklama,
    aktivite_aciklama,
    termal,
    havuz,
    toplanti,
    balayi,
    internet,
    tesis_ozellikleri,
  } = calculatePriceForHotel;

  const generalInfo = {
    "Otelfiyat Hattımız": telefon,
    "Otel Telefonu": otelin_gercek_telefonu.telefon,
    Adresi: otel_adres,
    Türü: otel_turu,
    Mevkii: Object.values(otel_mevkii)
      .map((item) => item.lokasyon)
      .join(" - "),
    "Otel Yenileme Tarihi": otel_yenileme,
    "Oda Sayısı": otel_oda_sayisi,
    "Ücretsiz Çocuk": convertPricingStructure(ucretsiz_cocuk).join(" "),
    "Öne Çıkan Özellikler": one_cikan_ozellik,
  };

  const descriptions = [
    {
      title: "Genel Açıklama",
      description: genel_aciklama,
      features: tesis_ozellikleri.genel ?? [],
    },
    {
      title: "Konaklama",
      description: konaklama_aciklama,
      features: tesis_ozellikleri.oda ?? [],
    },
    {
      title: "Yiyecek",
      description: yiyecek_aciklama,
      features: tesis_ozellikleri.yiyecek ?? [],
    },
    {
      title: "Aktiviteler",
      description: aktivite_aciklama,
      features: tesis_ozellikleri.aktivite ?? [],
    },
    {
      title: "Termal Olanaklar",
      description: termal,
      features: tesis_ozellikleri.termal ?? [],
    },
    {
      title: "Havuz",
      description: havuz,
      features: tesis_ozellikleri.havuz ?? [],
    },
    {
      title: "Toplantı",
      description: toplanti,
      features: tesis_ozellikleri.toplanti ?? [],
    },
    {
      title: "Balayı",
      description: balayi,
      features: tesis_ozellikleri.balayi ?? [],
    },
    {
      title: "İnternet",
      description: internet,
      features: tesis_ozellikleri.internet ?? [],
    },
  ];

  return {
    generalInfo,
    descriptions,
  };
};

export default useHandleDetails;
