import { Button, Input, Label } from "reactstrap";

interface CustomNumberSelectorProps {
  label?: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
}
const CustomNumberSelector = ({
  label,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  disabled = false,
}: CustomNumberSelectorProps) => {
  return (
    <div>
      {label && <Label className="form-label">{label}</Label>}
      <div className="d-flex align-items-center gap-1">
        <Button
          style={{ width: "40px" }}
          onClick={() => {
            if (value > min) {
              onChange(value - step);
            }
          }}
        >
          -
        </Button>
        <Input
          style={{ width: "50px", textAlign: "center" }}
          type="number"
          value={value}
          onChange={(e) => {
            onChange(parseInt(e.target.value));
          }}
          disabled={disabled}
        />
        <Button
          style={{ width: "40px" }}
          onClick={() => {
            if (value < max) {
              onChange(value + step);
            }
          }}
        >
          +
        </Button>
      </div>
    </div>
  );
};

export default CustomNumberSelector;
