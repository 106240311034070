import { createSlice } from "@reduxjs/toolkit";

import { ArchivedContract } from "types/financeAndAccounting";
import { Pagination } from "types/GlobalTypes";

export interface ArchivedContractsInitialState {
  archivedContracts: ArchivedContract[];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: ArchivedContractsInitialState = {
  archivedContracts: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const archivedContractsSlice = createSlice({
  name: "archivedContracts",
  initialState,
  reducers: {
    handleSaveArchivedContracts(state, action) {
      const { data, ...rest } = action.payload;
      state.archivedContracts = data;
      state.pagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveArchivedContracts, setIsLoading, setError } =
  archivedContractsSlice.actions;

export default archivedContractsSlice.reducer;
