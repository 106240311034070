import { Form, FormFeedback, Input, Label } from "reactstrap";

import {
  CustomBox,
  CustomFormButton,
  CustomFormStatusMessages,
} from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";

const UpdateProfileForm = () => {
  const { validation, error, success, isLoading, officeLoactionSelectOptions } =
    useHandleForm();

  return (
    <CustomBox title="Bilgileri Güncelle" md={12} lg={12}>
      <div className="w-100 p-2">
        <CustomFormStatusMessages error={error} success={success} />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-4">
            <Label className="form-label" htmlFor="email-input">
              E-Posta
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="email-input"
                name="email"
                value={validation.values.email || ""}
                type="email"
                className="form-control pe-5"
                placeholder="E-Posta"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <Label className="form-label" htmlFor="phone-number-input">
              Telefon Numarası
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="phone-number-input"
                name="phoneNumber"
                value={validation.values.phoneNumber || ""}
                type="tel"
                className="form-control pe-5"
                placeholder="Telefon Numarası"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.phoneNumber &&
                  validation.errors.phoneNumber
                    ? true
                    : false
                }
              />
              {validation.touched.phoneNumber &&
              validation.errors.phoneNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.phoneNumber}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <Label className="form-label" htmlFor="office-select-input">
              Ofis
            </Label>
            <Input
              id="office-select-input"
              name="office"
              type="select"
              value={validation.values.office || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.office && validation.errors.office
                  ? true
                  : false
              }
            >
              <option value="">--Seçiniz--</option>
              {officeLoactionSelectOptions.map((officeLocation) => (
                <option key={officeLocation.value} value={officeLocation.value}>
                  {officeLocation.label}
                </option>
              ))}
            </Input>
            {validation.touched.office && validation.errors.office ? (
              <FormFeedback type="invalid">
                {validation.errors.office}
              </FormFeedback>
            ) : null}
          </div>

          <div className="text-center mt-4">
            <CustomFormButton
              type="submit"
              variant="success"
              isLoading={isLoading}
            >
              Güncelle
            </CustomFormButton>
          </div>
        </Form>
      </div>
    </CustomBox>
  );
};

export default UpdateProfileForm;
