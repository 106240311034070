import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";

interface UserInfo {
  inet: string;
  username: string;
  name: string;
  email: string;
  officeInternalPhoneNumber: string;
}

interface UserInfoItemProps {
  title: string;
  value: string;
}

const UserInfoItem = ({ title, value }: UserInfoItemProps) => {
  return (
    <div className="d-flex flex-column gap-1 align-items-start">
      <span className="text-muted fs-13 fw-medium">{title}</span>
      <span className="fs-16 fw-semibold ">{value}</span>
    </div>
  );
};

const UserProfileInfo = () => {
  const { user } = useSelector((state: any) => state.Login);

  const userInfo: UserInfo = {
    inet: user.inet_kodu,
    username: user.kullanici_adi,
    name: user.ad_soyad,
    email: user.email_adresi,
    officeInternalPhoneNumber: user.dahili,
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <div className="d-flex flex-column gap-4">
              <div className=" flex-grow-1 text-center">
                <i className="bx bx-user-circle fs-48" />
              </div>
              <div className=" d-flex flex-column gap-3">
                <div className=" border-bottom pb-2">
                  <h5 className="text-center fw-bold">{userInfo.name}</h5>
                </div>
                <UserInfoItem title="INET Kodu" value={userInfo.inet} />
                <UserInfoItem title="Kullanıcı Adı" value={userInfo.username} />
                <UserInfoItem title="Email" value={userInfo.email} />
                <UserInfoItem
                  title="Dahili Numara"
                  value={userInfo.officeInternalPhoneNumber}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UserProfileInfo;
