import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetAfterUpdateEarlyReservationDefinition } from "slices/definitions/addEarlyReservationDefinition/reducer";

import {
  getEarlyReservationDefinition,
  updateEarlyReservationDefinition,
} from "slices/definitions/addEarlyReservationDefinition/thunk";

import {
  UpdateEarlyReservationDefinitionRequestPayload,
  AddEarlyReservationDefinitionState,
} from "types/definitions";

interface UpdateEarlyReservationDefinitionButtonProps {
  values: AddEarlyReservationDefinitionState;
  requestPayload: UpdateEarlyReservationDefinitionRequestPayload;
  validateForm: (values: AddEarlyReservationDefinitionState) => {
    isValid: boolean;
  };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateEarlyReservationDefinitionButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateEarlyReservationDefinitionButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateEarlyReservationDefinition = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateEarlyReservationDefinition(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getEarlyReservationDefinition());
        })
      );
    }
  };

  const handleClearForm = () => {
    dispatch(resetAfterUpdateEarlyReservationDefinition());
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton variant="danger" onClick={handleClearForm}>
        Formu Temizle
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleUpdateEarlyReservationDefinition}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateEarlyReservationDefinitionButton;
