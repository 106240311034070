import { Alert } from "reactstrap";
import { CustomLoadingPage } from "../UI";

import { useSearchParams } from "react-router-dom";

import image from "../../../assets/images/need-hotel.png";
import Cookies from "js-cookie";

interface WithPageNeedsHotelProps {
  isValidHotel?: boolean;
  loading: boolean;
  error: string;
  [key: string]: any;
}

function withPageNeedsHotel(Component: any) {
  return function WithPageNeedsHotel(props: WithPageNeedsHotelProps) {
    const { loading, error, isValidHotel, ...rest } = props;

    const [searchParams] = useSearchParams();

    const idFromUrl = searchParams.get("otel_id") ?? "";
    const idFromStorage = sessionStorage.getItem("hotel");
    const idFromCookies = Cookies.get("hotel");

    if (idFromUrl) {
      sessionStorage.setItem("hotel", idFromUrl);
    } else if (idFromStorage) {
      window.history.pushState({}, "", `?otel_id=${idFromStorage}`);
    } else if (idFromCookies) {
      sessionStorage.setItem("hotel", idFromCookies);
      window.history.pushState({}, "", `?otel_id=${idFromCookies}`);
    }

    if (!idFromUrl && !idFromStorage && !idFromCookies) {
      return (
        <div className="w-100 d-flex justify-content-center align-items-center bg-transparent">
          <img
            src={image}
            alt="empty"
            style={{ width: "80%", height: "80%", userSelect: "none" }}
            className="rounded-2"
          />
        </div>
      );
    }

    if (loading) {
      // Render loading page or spinner here
      return <CustomLoadingPage isLoading={loading} />;
    }

    if (error) {
      // Render error page here
      return (
        <div className="w-100 min-vh-100 d-flex justify-content-start align-items-start bg-transparent">
          <Alert color="danger">{error}</Alert>
        </div>
      );
    }

    if (isValidHotel === false) {
      // Render page not found or 404 page here
      return (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center bg-transparent">
          <div>Otele ait oda ve pansiyon bilgilerini girmelisiniz.</div>
        </div>
      );
    }

    if (rest.data)
      // Render the wrapped component with the remaining props if data exists
      return <Component {...rest} />;
  };
}

export default withPageNeedsHotel;
