import { useGetUser } from "Components/Hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface MenuItems {
  id: string;
  label: string;
  icon: string;
  link?: string;
  stateVariables?: boolean;
  click?: any;
  subItems?: SubItems[];
}

interface SubItems {
  id: string;
  label: string;
  link: string;
  parentId: string;
  badgeColor?: string;
  badgeName?: string;
}

const useNavdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPricesAndQuotas, setIsPricesAndQuotas] = useState(false);
  const [isDiscountsAndActions, setIsDiscountsAndActions] = useState(false);
  const [isDefinitions, setIsDefinitions] = useState(false);
  const [isThemes, setIsThemes] = useState(false);
  const [isFacility, setIsFacility] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isAdsAndAnalyze, setIsAdsAndAnalyze] = useState(false);
  const [isFinanceAndAccounting, setIsFinanceAndAccounting] = useState(false);
  const [isPersonnelRequest, setIsPersonnelRequest] = useState(false);
  const [isComments, setIsComments] = useState(false);
  const [isMyWebSite, setIsMyWebSite] = useState(false);
  const [isFacilityCategorization, setFacilityCategorization] = useState(false);
  const [isUserOperations, setIsUserOperations] = useState(false);
  const [isReservation, setIsReservation] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("dashboard");

  function updateIconSidebar(e: MouseEvent) {
    if (e && e.target && (e.target as HTMLElement).getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "pricesAndQuotas") {
      setIsPricesAndQuotas(false);
    }

    if (iscurrentState !== "discountsAndActions") {
      setIsDiscountsAndActions(false);
    }

    if (iscurrentState !== "definitions") {
      setIsDefinitions(false);
    }

    if (iscurrentState !== "themes") {
      setIsThemes(false);
    }

    if (iscurrentState !== "facility") {
      setIsFacility(false);
    }

    if (iscurrentState !== "reports") {
      setIsReports(false);
    }

    if (iscurrentState !== "adsAndAnalyze") {
      setIsAdsAndAnalyze(false);
    }

    if (iscurrentState !== "financeAndAccounting") {
      setIsFinanceAndAccounting(false);
    }

    if (iscurrentState !== "personnelRequest") {
      setIsPersonnelRequest(false);
    }

    if (iscurrentState !== "comments") {
      setIsComments(false);
    }

    if (iscurrentState !== "myWebSite") {
      setIsMyWebSite(false);
    }

    if (iscurrentState !== "facilityCategorization") {
      setFacilityCategorization(false);
    }
    if (iscurrentState !== "userOperations") {
      setIsUserOperations(false);
    }
    if (iscurrentState !== "reservation") {
      setIsReservation(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isDefinitions,
    isDiscountsAndActions,
    isFacility,
    isPricesAndQuotas,
    isThemes,
    isReports,
    isAdsAndAnalyze,
    isFinanceAndAccounting,
    isPersonnelRequest,
    isComments,
    isMyWebSite,
    isFacilityCategorization,
    isUserOperations,
    isReservation,
  ]);

  const menuItems: MenuItems[] = [
    {
      id: "dashboard",
      label: "Masaüstü",
      icon: "ri-dashboard-2-line",
      stateVariables: isDashboard,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "adminDashboard",
          label: "Admin",
          link: "/admin-dashboard",
          parentId: "dashboard",
        },
        {
          id: "crmDashboard",
          label: "CRM",
          link: "/crm-dashboard",
          parentId: "dashboard",
        },
      ],
    },
    {
      id: "pricesAndQuotas",
      label: "Fiyatlar & Kontenjan",
      icon: "ri-calendar-2-line",
      stateVariables: isPricesAndQuotas,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsPricesAndQuotas(!isPricesAndQuotas);
        setIscurrentState("pricesAndQuotas");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "calendar",
          label: "Takvim",
          link: "/takvim",
          parentId: "pricesAndQuotas",
        },
        {
          id: "generalPriceSettings",
          label: "Genel Fiyat Ayarları",
          link: "/genel-fiyat-ayarlari",
          parentId: "pricesAndQuotas",
        },
        {
          id: "roomSalesAndPaymentMethods",
          label: "Oda Satış & Ödeme Yöntemleri",
          link: "/oda-satis-odeme-yontemleri",
          parentId: "pricesAndQuotas",
        },
        {
          id: "roomPersonPriceRates",
          label: "Oda / Kişi / Fiyat Oranları",
          link: "/oda-kisi-fiyat-oranlari",
          parentId: "pricesAndQuotas",
        },
        {
          id: "childInformation",
          label: "Çocuk Bilgileri",
          link: "/cocuk-bilgileri",
          parentId: "pricesAndQuotas",
        },
        {
          id: "childSpecialAgeInformation",
          label: "Çocuk Özel Yaş Bilgileri",
          link: "/cocuk-ozel-yas-bilgileri",
          parentId: "pricesAndQuotas",
        },
      ],
    },
    {
      id: "discountsAndActions",
      label: "İndirim & Aksiyonlar",
      icon: "ri-price-tag-2-line",
      stateVariables: isDiscountsAndActions,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsDiscountsAndActions(!isDiscountsAndActions);
        setIscurrentState("discountsAndActions");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "specialDiscounts",
          label: "Özel İndirimler",
          link: "/ozel-indirimler",
          parentId: "discountsAndActions",
        },
        {
          id: "mobileDiscounts",
          label: "Mobil İndirimler",
          link: "/mobil-indirimler",
          parentId: "discountsAndActions",
        },
        {
          id: "breakingDiscounts",
          label: "Son Dakika İndirimleri",
          link: "/son-dakika-indirimleri",
          parentId: "discountsAndActions",
        },
        {
          id: "hourlyDiscounts",
          label: "Saatlik İndirimler",
          link: "/saatlik-indirimler",
          parentId: "discountsAndActions",
        },
        {
          id: "uncancellableRoomRates",
          label: "İptal Edilemez Oda Oranları",
          link: "/iptal-edilemez-oda-oranlari",
          parentId: "discountsAndActions",
        },
        {
          id: "earlyReservationDiscounts",
          label: "Erken Rezervasyon İndirimleri",
          link: "/erken-rezervasyon-indirimleri",
          parentId: "discountsAndActions",
        },
        {
          id: "stayLongPayLessActions",
          label: "Çok Kal Az Öde Aksiyonları",
          link: "/cok-kal-az-ode-aksiyonlari",
          parentId: "discountsAndActions",
        },
        {
          id: "underTheDeskDiscounts",
          label: "Desk Altı İndirimler",
          link: "/desk-alti-indirimler",
          parentId: "discountsAndActions",
        },
        {
          id: "giftsAndPromotions",
          label: "Hediyeler & Promosyonlar",
          link: "/hediyeler-ve-promosyonlar",
          parentId: "discountsAndActions",
        },
        {
          id: "allGiftsList",
          label: "Tüm Otellere Ait Hediyeler",
          link: "/tum-otellere-ait-hediyeler",
          parentId: "discountsAndActions",
        },
        {
          id: "discountCodes",
          label: "İndirim Kuponları",
          link: "/indirim-kuponlari",
          parentId: "discountsAndActions",
        },
        {
          id: "nightRestrictions",
          label: "Gece Kısıtlamaları",
          link: "/gece-kisitlamalari",
          parentId: "discountsAndActions",
        },
      ],
    },
    {
      id: "definitions",
      label: "Tanımlamalar",
      icon: "ri-book-2-line",
      stateVariables: isDefinitions,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsDefinitions(!isDefinitions);
        setIscurrentState("definitions");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "addLocation",
          label: "Lokasyon Ekle",
          link: "/lokasyon-ekle",
          parentId: "definitions",
        },
        {
          id: "listLocations",
          label: "Lokasyon Listele",
          link: "/lokasyon-listele",
          parentId: "definitions",
        },
        {
          id: "addPlaceToVisit",
          label: "Gezilecek Yer Ekle",
          link: "/gezilecek-yer-ekle",
          parentId: "definitions",
        },
        {
          id: "listPlacesToVisit",
          label: "Gezilecek Yer Listele",
          link: "/gezilecek-yer-listele",
          parentId: "definitions",
        },
        {
          id: "addHotelFeature",
          label: "Tesis Özelliği Ekle",
          link: "/tesis-ozelligi-ekle",
          parentId: "definitions",
        },
        {
          id: "addEarlyReservationDefinition",
          label: "Erken Rezervasyon Tarihi Tanımlama",
          link: "/erken-rezervasyon-tarihi-tanimlama",
          parentId: "definitions",
        },
        {
          id: "defineCurrency",
          label: "Kur Tanımlama",
          link: "/kur-tanimlama",
          parentId: "definitions",
        },
      ],
    },
    {
      id: "themes",
      label: "Temalar",
      icon: "ri-paint-brush-line",
      stateVariables: isThemes,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsThemes(!isThemes);
        setIscurrentState("themes");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "addTheme",
          label: "Tema Ekle",
          link: "/tema-ekle",
          parentId: "themes",
        },
        {
          id: "listThemes",
          label: "Tema Listele",
          link: "/tema-listele",
          parentId: "themes",
        },
        {
          id: "assignHotelToTheme",
          label: "Tema Otel Ata",
          link: "/tema-otel-ata",
          parentId: "themes",
        },
        {
          id: "assignRecommendedHotels",
          label: "Tema Tavsiye Edilen Otel Atama",
          link: "/tema-tavsiye-edilen-otel-atama",
          parentId: "themes",
        },
        {
          id: "orderThemeHotels",
          label: "Tema Otel Sıralama",
          link: "/tema-otel-siralama",
          parentId: "themes",
        },
      ],
    },
    {
      id: "facility",
      label: "Tesis",
      icon: "ri-building-2-line",
      stateVariables: isFacility,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsFacility(!isFacility);
        setIscurrentState("facility");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "addFacility",
          label: "Tesis Ekle",
          link: "/tesis-ekle",
          parentId: "facility",
        },
        {
          id: "updateFacility",
          label: "Tesis Güncelle",
          link: "/tesis-guncelle",
          parentId: "facility",
        },
        {
          id: "viewFeaturedImages",
          label: "Tesis Vitrin Görselleri",
          link: "/tesis-vitrin-gorselleri",
          parentId: "facility",
        },
        {
          id: "facilityImages",
          label: "Tesis Resimleri",
          link: "/tesis-resimleri",
          parentId: "facility",
        },
        {
          id: "facilityRooms",
          label: "Tesis Odaları",
          link: "/tesis-odalari",
          parentId: "facility",
        },
        {
          id: "facilityConcepts",
          label: "Tesis Pansiyonları",
          link: "/tesis-pansiyonlari",
          parentId: "facility",
        },
        {
          id: "facilityUsers",
          label: "Tesis Kullanıcıları",
          link: "/tesis-kullanicilari",
          parentId: "facility",
        },
        {
          id: "facilityManagers",
          label: "Tesis Yetkilileri",
          link: "/tesis-yetkilileri",
          parentId: "facility",
        },
        {
          id: "facilitySpecialDays",
          label: "Tesis Özel Günleri",
          link: "/tesis-ozel-gunleri",
          parentId: "facility",
        },
        {
          id: "listSpecialDays",
          label: "Özel Gün Listele",
          link: "/ozel-gun-listele",
          parentId: "facility",
        },
        {
          id: "roomConceptMatch",
          label: "Tedarikçi Oda-Pansiyon Eşleştir",
          link: "/oda-pansiyon-eslestir",
          parentId: "facility",
        },
        {
          id: "facilityCalls",
          label: "Tesis Aramaları",
          link: "/tesis-aramalari",
          parentId: "facility",
        },
        {
          id: "facilityPartialPaymentDates",
          label: "Tesis Kısmi Ödeme Tarihleri",
          link: "/tesis-kismi-odeme-tarihleri",
          parentId: "facility",
        },
      ],
    },
    {
      id: "reports",
      label: "Raporlar",
      icon: "ri-file-list-2-line",
      stateVariables: isReports,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "moneyPoints",
          label: "Tesis Para Puan Listesi",
          link: "/tesis-parapuan-listesi",
          parentId: "reports",
        },
        {
          id: "customerMails",
          label: "Müşteri E-Postaları",
          link: "/musteri-mailleri",
          parentId: "reports",
        },
        {
          id: "phoneStatus",
          label: "Dahili Durum",
          link: "/dahili-durum",
          parentId: "reports",
        },
        {
          id: "dailyReservations",
          label: "Günlük Satış Raporu",
          link: "/gunluk-satis-raporu",
          parentId: "reports",
        },
        {
          id: "dailyCallsCharts",
          label: "Günlük Çağrı Grafiği",
          link: "/gunluk-cagri-grafigi",
          parentId: "reports",
        },
        {
          id: "hourlyCallsChart",
          label: "Saatlik Çağrı Grafiği",
          link: "/saatlik-cagri-grafigi",
          parentId: "reports",
        },
        // {
        //   id: "ourOwnHotels",
        //   label: "Fiyatı Olan Bize Ait Oteller",
        //   link: "/fiyati-olan-bize-ait-oteller",
        //   parentId: "reports",
        // },
        {
          id: "welcomeAndCalculation",
          label: "Karşılama ve Hesaplama",
          link: "/karsilama-ve-hesaplama",
          parentId: "reports",
        },
        {
          id: "roomsLackPicture",
          label: "Oda Resmi Olmayanlar",
          link: "/oda-resmi-olmayanlar",
          parentId: "reports",
        },
        {
          id: "forecastReport",
          label: "Forecast Raporu",
          link: "/forecast-raporu",
          parentId: "reports",
        },
        {
          id: "answeredCalls",
          label: "Cevaplanan Çağrı Hareketleri",
          link: "/cevaplanan-cagri-hareketleri",
          parentId: "reports",
        },
        {
          id: "outboundCallsList",
          label: "Dış Arama Sayıları",
          link: "/dis-arama-sayilari",
          parentId: "reports",
        },
        {
          id: "dailyCallsPerPerson",
          label: "Kişilere Gelen Günlük Çağrı",
          link: "/kisilere-gelen-gunluk-cagri",
          parentId: "reports",
        },
        {
          id: "employeeSalesAmount",
          label: "Personel Satış Rakamları",
          link: "/personel-satislari-listele",
          parentId: "reports",
        },
        {
          id: "roomsLackDescription",
          label: "Oda Açıklaması Olmayanlar",
          link: "/oda-aciklamasi-olmayanlar",
          parentId: "reports",
        },
        {
          id: "riskyHotels",
          label: "Riskli Oteller",
          link: "/riskli-oteller",
          parentId: "reports",
        },
        {
          id: "phoneStatusList",
          label: "Dahili Durum Listesi",
          link: "/dahili-durum-listesi",
          parentId: "reports",
        },
        {
          id: "dailyCallsForHotels",
          label: "Otellere Gelen Günlük Çağrı",
          link: "/otellere-gelen-gunluk-cagri",
          parentId: "reports",
        },
        {
          id: "callsFromGeneral",
          label: "Genelden Gelen Çağrılar",
          link: "/genelden-gelen-cagrilar",
          parentId: "reports",
        },
        {
          id: "monthlySales",
          label: "Personel Aylık Satış Listesi",
          link: "/personel-aylik-satis-listesi",
          parentId: "reports",
        },
        {
          id: "hotelSales",
          label: "Otel Satışları",
          link: "/otel-satislari",
          parentId: "reports",
        },
        {
          id: "reservations",
          label: "Rezervasyonlar",
          link: "/rezervasyonlar",
          parentId: "reports",
        },
      ],
    },
    {
      id: "adsAndAnalyze",
      label: "Reklam & Analiz",
      icon: "ri-bar-chart-grouped-line",
      stateVariables: isAdsAndAnalyze,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsAdsAndAnalyze(!isAdsAndAnalyze);
        setIscurrentState("adsAndAnalyze");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "adsGroups",
          label: "Tesisler & Reklam Grupları",
          link: "/tesisler-reklam-gruplari",
          parentId: "adsAndAnalyze",
        },
        {
          id: "adsReport",
          label: "Reklam Raporu",
          link: "/reklam-raporu",
          parentId: "adsAndAnalyze",
        },
        {
          id: "competitorAnalysis",
          label: "Rakip Analizi",
          link: "/rakip-analizi",
          parentId: "adsAndAnalyze",
        },
        {
          id: "competitorAnalysisInfo",
          label: "Rakip Analizi Bilgileri",
          link: "/rakip-analizi-bilgileri",
          parentId: "adsAndAnalyze",
        },
      ],
    },
    {
      id: "financeAndAccounting",
      label: "Finans & Muhasebe",
      icon: "ri-bank-line",
      stateVariables: isFinanceAndAccounting,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsFinanceAndAccounting(!isFinanceAndAccounting);
        setIscurrentState("financeAndAccounting");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "hotelInvoices",
          label: "Otelinize Kesilen Faturalar",
          link: "/otelinize-kesilen-faturalar",
          parentId: "financeAndAccounting",
        },
        {
          id: "contractRules",
          label: "Kontrat Kuralları",
          link: "/kontrat-kurallari",
          parentId: "financeAndAccounting",
        },
        {
          id: "hotelContracts",
          label: "Otel Kontrat İşlemleri",
          link: "/otel-kontrat-islemleri",
          parentId: "financeAndAccounting",
        },
        {
          id: "archivedContracts",
          label: "Arşivlenmiş Kontratlar",
          link: "/arsivlenmis-kontratlar",
          parentId: "financeAndAccounting",
        },
        {
          id: "issuedInvoices",
          label: "Kesilen Faturalar",
          link: "/kesilen-faturalar",
          parentId: "financeAndAccounting",
        },
        {
          id: "mailOrderInfo",
          label: "Mail Order Bilgileri",
          link: "/mail-order-bilgileri",
          parentId: "financeAndAccounting",
        },
        {
          id: "commissionPayment",
          label: "Komisyon Ödemesi",
          link: "/komisyon-odemesi",
          parentId: "financeAndAccounting",
        },
      ],
    },
    {
      id: "myWebSite",
      label: "Web Sitem",
      icon: "ri-global-line",
      stateVariables: isMyWebSite,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsMyWebSite(!isMyWebSite);
        setIscurrentState("myWebSite");

        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "createWebSite",
          label: "Site Oluştur",
          link: "/site-olustur",
          parentId: "myWebSite",
        },
        {
          id: "listWebSites",
          label: "Web Site Listesi",
          link: "/web-site-listesi",
          parentId: "myWebSite",
        },
        // {
        //   id: "createWidget",
        //   label: "Widget Oluştur",
        //   link: "/widget-olustur",
        //   parentId: "myWebSite",
        // },
      ],
    },
    {
      id: "facilityCategorization",
      label: "Tesis Sınıflandırma",
      icon: "ri-hotel-line",
      stateVariables: isFacilityCategorization,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setFacilityCategorization(!isFacilityCategorization);
        setIscurrentState("facilityCategorization");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "categorizeFacility",
          label: "Tesis Sınıflandır",
          link: "/tesis-siniflandir",
          parentId: "facilityCategorization",
        },
      ],
    },
    {
      id: "personnelRequest",
      label: "Personel Talepleri",
      icon: "ri-mail-line",
      stateVariables: isPersonnelRequest,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsPersonnelRequest(!isPersonnelRequest);
        setIscurrentState("personnelRequest");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "personnelRequestTable",
          label: "Personel Talepleri",
          link: "/personel-talepleri",
          parentId: "personnelRequest",
        },
      ],
    },
    {
      id: "comments",
      label: "Yorumlar",
      icon: "ri-message-2-line",
      stateVariables: isComments,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsComments(!isComments);
        setIscurrentState("comments");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "listComments",
          label: "Yorumları Listele",
          link: "/yorumlari-listele",
          parentId: "comments",
        },
        {
          id: "yorumEkle",
          label: "Yorum Ekle",
          link: "/yorum-ekle",
          parentId: "comments",
        },
        {
          id: "yorumsuzOtelleriListele",
          label: "Yorumsuz Otelleri Listele",
          link: "/yorumsuz-oteller",
          parentId: "comments",
        },
      ],
    },

    {
      id: "userOperations",
      label: "Kullanıcı İşlemleri",
      icon: "ri-fingerprint-line",
      stateVariables: isUserOperations,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsUserOperations(!isUserOperations);
        setIscurrentState("userOperations");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "userNotifications",
          label: "Bildirimlerim",
          link: "/bildirimler",
          parentId: "userOperations",
        },
        {
          id: "createNotification",
          label: "Bildirim Oluştur",
          link: "/bildirim-olustur",
          parentId: "userOperations",
        },
        {
          id: "userProfile",
          label: "Profilim",
          link: "/profile",
          parentId: "userOperations",
        },
        {
          id: "addUser",
          label: "Kullanıcı Ekle",
          link: "/kullanici-ekle",
          parentId: "userOperations",
        },
        {
          id: "listUsers",
          label: "Kullanıcıları Listele",
          link: "/kullanicilari-listele",
          parentId: "userOperations",
        },
        {
          id: "personnelHotelTasks",
          label: "Personel Otel Görevleri",
          link: "/personel-otel-gorevleri",
          parentId: "userOperations",
        },
        {
          id: "userAuthorities",
          label: "Yetkiler",
          link: "/yetkiler",
          parentId: "userOperations",
        },
        {
          id: "listLogs",
          label: "Logları Listele",
          link: "/loglari-listele",
          parentId: "userOperations",
        },
        {
          id: "addGroup",
          label: "Grup Ekle",
          link: "/grup-ekle",
          parentId: "userOperations",
        },
        {
          id: "listGroups",
          label: "Grupları Listele",
          link: "/gruplari-listele",
          parentId: "userOperations",
        },
        {
          id: "peritoExtensionNumbers",
          label: "Perito Dahili Durum",
          link: "/perito-dahili-durum",
          parentId: "userOperations",
        },
        {
          id: "reservationApproveCancelMng",
          label: "Rez Onay İptal Değişikliği",
          link: "/rezervasyon-onay-iptal-degisikligi",
          parentId: "userOperations",
        },
        {
          id: "transferReservation",
          label: "Rezarvasyon Aktar",
          link: "/rezervasyon-aktar",
          parentId: "userOperations",
        },
        {
          id: "userIPs",
          label: "Dış IPler",
          link: "/dis-ipler",
          parentId: "userOperations",
        },
        {
          id: "informationTechnologies",
          label: "Bilgi İşlem",
          link: "/bilgi-islem",
          parentId: "userOperations",
        },
      ],
    },
    {
      id: "reservation",
      label: "Rezervasyon",
      icon: "ri-calendar-check-line",
      stateVariables: isReservation,
      click: function (e: MouseEvent) {
        e.preventDefault();
        setIsReservation(!isReservation);
        setIscurrentState("reservation");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "calculatePrice",
          label: "Fiyat Hesapla",
          link: "/fiyat-hesapla",
          parentId: "reservation",
        },
      ],
    },
  ];

  const { userInfo } = useGetUser();
  const { authorities } = userInfo;

  const decoded_authorities = atob(authorities);
  const authoritiesArray = JSON.parse(decoded_authorities);

  const itemsThatUserCanSee = menuItems.map((menuItem) => {
    if (menuItem.subItems) {
      const subItemsThatUserCanSee = menuItem.subItems.filter(
        (subItem) => !authoritiesArray.includes(subItem.link)
      );
      return {
        ...menuItem,
        subItems:
          subItemsThatUserCanSee.length > 0
            ? subItemsThatUserCanSee
            : undefined,
      };
    }
    return menuItem;
  });

  return { navData: itemsThatUserCanSee };
};
export default useNavdata;
