import { CustomFormContainer } from "Components/Custom/UI";
import useHandleRates from "./useHandleRates";
import PriceRateCard from "./PriceRateCard";

interface EmployeeBestPriceRatesProps {
  isLoading: boolean;
}

const EmployeeBestPriceRates = ({ isLoading }: EmployeeBestPriceRatesProps) => {
  const { rates } = useHandleRates();

  return (
    <CustomFormContainer title="Personel İyi Fiyat Oranları">
      <div className="d-flex flex-wrap gap-2 justify-center">
        {rates.map((rate) => (
          <PriceRateCard key={rate.uye_id} {...rate} />
        ))}
      </div>
    </CustomFormContainer>
  );
};

export default EmployeeBestPriceRates;
