import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import { ListUsersResponse, ListUsersState } from "types/userOperations";

export interface ListUsersInitialState {
  users: ListUsersState[];
  usersResponse: ListUsersResponse;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
  updateUserLoading: boolean;
  updateUserError: string;
  changeUserStatusLoading: boolean;
  changeUserStatusError: string;
}

export const listUsersInitialState: ListUsersInitialState = {
  users: [],
  usersResponse: {
    data: [],
    sabit_datalar: {},
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
  updateUserLoading: false,
  updateUserError: "",
  changeUserStatusLoading: false,
  changeUserStatusError: "",
};

const listUsersSlice = createSlice({
  name: "listUsers",
  initialState: listUsersInitialState,
  reducers: {
    handleSaveUserResponse(state, action) {
      const { data, ...rest } = action.payload;
      state.usersResponse = action.payload;
      state.users = data;
      state.pagination = rest;
    },
    handleUpdateUser(state, action) {
      const updatedUser = action.payload;
      state.users = state.users.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
      );
    },
    handleChangeUserStatus(state, action) {
      const { id, status } = action.payload;
      state.users = state.users.map((user) =>
        user.id === id ? { ...user, aktiflik: status } : user
      );
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateUserLoading(state, action) {
      state.updateUserLoading = action.payload;
    },
    setUpdateUserError(state, action) {
      state.updateUserError = action.payload;
    },
    setChangeUserStatusLoading(state, action) {
      state.changeUserStatusLoading = action.payload;
    },
    setChangeUserStatusError(state, action) {
      state.changeUserStatusError = action.payload;
    },
  },
});

export const {
  handleSaveUserResponse,
  handleUpdateUser,
  handleChangeUserStatus,
  setIsLoading,
  setError,
  setUpdateUserLoading,
  setUpdateUserError,
  setChangeUserStatusLoading,
  setChangeUserStatusError,
} = listUsersSlice.actions;

export default listUsersSlice.reducer;
