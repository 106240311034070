import { ListThemesPage } from "Components/Custom/ThemesPages";
import useListThemes from "Components/Hooks/themes/useListThemes";
import PageContainer from "pages/PageContainer";

const ListThemes = () => {
  const props = useListThemes();

  return (
    <PageContainer title="Tema Listele">
      <ListThemesPage {...props} />
    </PageContainer>
  );
};

export default ListThemes;
