import { CustomButton } from "Components/Custom/UI";
import { FilePondFile } from "filepond";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addNewTheme } from "slices/themes/addNewTheme/thunk";

import { AddNewThemeRequestPayload, AddNewThemeState } from "types/themes";

interface AddNewThemeButtonProps {
  values: AddNewThemeState;
  requestPayload: AddNewThemeRequestPayload;
  validateForm: (values: AddNewThemeState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
}

const AddNewThemeButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
}: AddNewThemeButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddNewTheme = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      requestPayload.append("tema_bolge", values.tema_bolge);
      requestPayload.append("durum", values.durum);
      requestPayload.append("baslik", values.baslik);
      requestPayload.append("alt_baslik", values.alt_baslik);
      requestPayload.append("aciklama", values.aciklama);
      requestPayload.append("link", values.link);

      const file = values.file as FilePondFile;
      requestPayload.append("resim", file.file, file.file.name);

      dispatch(addNewTheme(requestPayload));
    }
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton variant="success" onClick={handleAddNewTheme}>
        Yeni Tema Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewThemeButton;
