import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveEmployeeSalesAmount,
  setIsLoading,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_EMPLOYEE_SALES_AMOUNT } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetEmployeeSalesAmountRequestPayload {
  start_date: string;
  end_date: string;
}

export const getEmployeeSalesAmount =
  (payload: GetEmployeeSalesAmountRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_EMPLOYEE_SALES_AMOUNT, payload);

      dispatch(handleSaveEmployeeSalesAmount(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
