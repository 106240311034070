import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/themes/addNewTheme/reducer";
import {
  AddNewThemeFormErrors,
  AddNewThemeState,
  ThemeRegionOption,
  ThemeTypesOption,
} from "types/themes";

interface ThemeSelectorsProps {
  values: AddNewThemeState;
  formErrors: AddNewThemeFormErrors;
  validateFormChange: (values: AddNewThemeState) => void;
  themeRegionOptions: ThemeRegionOption[];
  themeTypesOptions: ThemeTypesOption[];
}

interface Option {
  value: string;
  label: string;
}

const ThemeSelectors = ({
  values,
  validateFormChange,
  formErrors,
  themeRegionOptions,
  themeTypesOptions,
}: ThemeSelectorsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const updatedThemeTypesOptions = themeTypesOptions.map((option) => ({
    value: option.value,
    label: option.text,
  }));

  return (
    <>
      <CustomFormFieldContainer
        label="Tema Bölgesi"
        error={formErrors.tema_bolge}
      >
        <Select
          value={
            themeRegionOptions.find(
              (option) => option.value === values.tema_bolge
            ) || null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                tema_bolge: selected.value,
              })
            );
            validateFormChange({ ...values, tema_bolge: selected.value });
          }}
          options={themeRegionOptions}
          placeholder="Yer Adı Seçiniz"
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Tema Türü" error={formErrors.durum}>
        <Select
          value={
            updatedThemeTypesOptions.find(
              (option) => option.value === values.durum
            ) || null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                durum: selected.value,
              })
            );
            validateFormChange({ ...values, durum: selected.value });
          }}
          options={updatedThemeTypesOptions}
          placeholder="Tema Türü Seçiniz"
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ThemeSelectors;
