import { CustomFormContainer, CustomLoadingPage } from "Components/Custom/UI";
import CityAndPersonnelFilterForm from "./CityAndPersonnelFilterForm";
import AssignedHotelsTable from "./AssignedHotelsTable";
import { useState } from "react";

interface ListAssignedHotelsProps {
  isLoading: boolean;
}
type ShowTableState = "show_data" | "no_data" | "default";

const ListAssignedHotels = ({ isLoading }: ListAssignedHotelsProps) => {

  const [showTableData, setShowTableData] = useState<ShowTableState>("default");

  return (
    <>
      <CustomFormContainer title="Atanmış Otelleri Listele">
        <CustomLoadingPage isLoading={isLoading} />
        <CityAndPersonnelFilterForm setShowTableData={setShowTableData} />
        <AssignedHotelsTable showTableData={showTableData} />
      </CustomFormContainer>
    </>
  );
};

export default ListAssignedHotels;
