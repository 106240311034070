import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import RoomMatchList from "./RoomMatchList";
import ConceptMatchList from "./ConceptMatchList";
import { CustomLoadingPage } from "Components/Custom/UI";

interface RoomConceptMatchPageProps {
  isLoading: boolean;
}

const RoomConceptMatchPage = ({ isLoading }: RoomConceptMatchPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <RoomMatchList />
        <ConceptMatchList />
      </div>
    </>
  );
};

export default withPageNeedsHotel(RoomConceptMatchPage);
