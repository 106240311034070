import { AddPlaceToVisitPage } from "Components/Custom/DefinitionsPages";
import PageContainer from "pages/PageContainer";

const AddPlaceToVisit = () => {
  return (
    <PageContainer title="Gezilecek Yer Ekle">
      <AddPlaceToVisitPage />
    </PageContainer>
  );
};

export default AddPlaceToVisit;
