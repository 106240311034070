import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CustomButton } from "Components/Custom/UI";
import {
  handleChangeDateInput,
  ListCommentsInitialState,
} from "slices/comments/listComments/reducer";
import { getComments } from "slices/comments/listComments/thunk";

/*
 * end_date and start_date filters tarih ("İşlem Tarihi")
 */

const DateSelector = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const { start_date, end_date, pagination, personel } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const canFilter = start_date && end_date;

  const handleFilter = () => {
    dispatch(
      getComments({
        otel_id: 0,
        page: 1,
        per_page: pagination.per_page,
        start_date: start_date,
        end_date: end_date,
        personel: personel,
      })
    );
  };

  const handleClearFilter = () => {
    dispatch(
      handleChangeDateInput({
        start_date: "",
        end_date: "",
      })
    );

    dispatch(
      getComments({
        otel_id: 0,
        page: 1,
        per_page: pagination.per_page,
        start_date: "",
        end_date: "",
        personel: "0",
      })
    );
  };

  return (
    <div className="d-flex gap-4">
      <div
        style={{
          width: "300px",
        }}
      >
        <Flatpickr
          className="form-control"
          value={[start_date, end_date]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeDateInput({
                  start_date: stringStartDate,
                  end_date: stringEndDate,
                })
              );
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
          placeholder="Filtrelemek İçin Tarih Seçiniz..."
        />
      </div>
      <div className="d-flex gap-2">
        <CustomButton
          variant="primary"
          disabled={!canFilter}
          onClick={handleFilter}
        >
          Tarih ile Filtrele
        </CustomButton>
        <CustomButton
          variant="danger"
          disabled={!canFilter}
          onClick={handleClearFilter}
        >
          Tarih Filtresini Temizle
        </CustomButton>
      </div>
    </div>
  );
};

export default DateSelector;
