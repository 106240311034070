import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { ADD_COMMENT_DESCRIPTION } from "utils/pageDescriptions";
import AddCommentForm from "./AddCommentForm";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";

/*
  *This page renders from and description at the top of the page.
  *Takes isLoading as a prop

  *@using "FacilitySpecialDays" as template

*/
interface AddCommentPageProps {
  isLoading: boolean;
}

const AddCommentPage = ({ isLoading }: AddCommentPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <CustomPageDescriptions
          variant="danger"
          descriptions={ADD_COMMENT_DESCRIPTION}
        />
        <AddCommentForm/>
      </div>
    </>
  );
};

export default withPageNeedsHotel(AddCommentPage);
