import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { TABLE_COLORS } from "utils/tableColors";
import useHandleRequests from "./useHandleRequests";
import { ListGroupsInitialState } from "slices/userOperations/listGroups/reducer";

const useHandleTable = () => {
  const { groups } = useSelector(
    (state: { ListGroups: ListGroupsInitialState }) => state.ListGroups
  );

  const { onUpdate, onChangeStatus } = useHandleRequests();

  const tableData = groups?.map((group) => {
    let rowColor = "";
    if (group.grup_aktiflik === "0") {
      rowColor = TABLE_COLORS.red;
    } else {
      rowColor = TABLE_COLORS.green;
    }

    return {
      grup_id: group.grup_id,
      grup_adi: group.grup_adi,
      yetkili_id: group.yetkili_id,
      yetkili_ad_soyad: group.yetkili_ad_soyad,
      aboneler: group.aboneler,
      grup_aktiflik: group.grup_aktiflik,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Grup Adı",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
    },
    {
      header: "Grup Yöneticisi",
      accessorKey: "yetkili_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Grup Üyeleri",
      accessorKey: "aboneler",
      enableColumnFilter: false,
      
      cell: (cell: any) => {const aboneler = cell.getValue();
        const filteredAboneler = aboneler.filter((abone: any) => abone.ad_soyad.trim() !== "");
        return (
          <div className="d-flex flex-wrap">
            {filteredAboneler.map((abone: any, index: number) => (
              <span key={abone.uye_id} className="me-2">
                {abone.ad_soyad}
                {index < filteredAboneler.length - 1 && ","}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "grup_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        const grupAktiflik = cell.row.original.grup_aktiflik;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => onUpdate(id)}
                title="Duzenle"
              />
              <CustomRowOperationButton
                variant={grupAktiflik === "1" ? "uncheck" : "check"}
                onClick={() => {
                  onChangeStatus(grupAktiflik, id);
                }}
                title={grupAktiflik === "1" ? "Pasifleştir" : "Aktifleştir"}
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.red,
      label: "Pasif",
    },
    {
      color: TABLE_COLORS.green,
      label: "Aktif",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
