import useHandleLocation from "./useHandleLocation";
import parse from "html-react-parser";
import { Map, Marker, Overlay } from "pigeon-maps";

const FacilityLocation = () => {
  const { location, googleMap, title, image } = useHandleLocation();

  return (
    <div className="d-flex flex-column gap-2 mt-3">
      {Object.entries(location).map(([key, value]) => (
        <div key={key} className="d-flex align-items-start gap-2">
          <span className="fw-medium text-muted fs-14 text-nowrap pb-1">
            {key}:
          </span>
          <span className="fw-semibold fs-15">{parse(value)}</span>
        </div>
      ))}
      <Map
        height={500}
        defaultCenter={[
          Number(googleMap.google_map_x),
          Number(googleMap.google_map_y),
        ]}
        defaultZoom={15}
      >
        <Marker
          width={50}
          color="red"
          anchor={[
            Number(googleMap.google_map_x),
            Number(googleMap.google_map_y),
          ]}
        />
        <Overlay
          anchor={[
            Number(googleMap.google_map_x),
            Number(googleMap.google_map_y),
          ]}
        >
          <div className="d-flex align-items-center  gap-2 border rounded bg-white p-1">
            <img
              src={image.resim_url}
              alt="otel"
              width="120"
              height="80"
              className="rounded"
            />
            <div className="d-flex flex-column gap-2 p-3">
              <span className="fw-semibold fs-15 text-center">{title}</span>
              <span
                className="fw-medium fs-14 text-primary cursor-pointer"
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${googleMap.google_map_x},${googleMap.google_map_y}`,
                    "_blank"
                  );
                }}
              >
                Google Map
              </span>
            </div>
          </div>
        </Overlay>
      </Map>
    </div>
  );
};

export default FacilityLocation;
