import { CustomFormContainer } from "Components/Custom/UI";
import RoomSelector from "./RoomSelector";
import RoomMaxPeopleCount from "./RoomMaxPeopleCount";
import useHandleForm from "./useHandleForm";
import SwitchesSection from "./SwitchesSection";
import RoomAccomodationTypes from "./RoomAccomodationTypes";
import PricePerPerson from "./PricePerPerson";
import PerPersonSwitch from "./PerPersonSwitch";
import PersonRoom from "./PersonRoom";
import SaveRoomPersonPriceButton from "./SaveRoomPersonPriceButton";

interface RoomPersonPriceRatesFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}
const RoomPersonPriceRatesForm = (props: RoomPersonPriceRatesFormProps) => {
  const {
    roomPersonPriceRateState,
    arrangeAccumodationTypes,
    childOptions = [],
    handleSave,
    calculateWithChild,
    calculateWithOutChild,
    numberOfChildren,
    withChild,
    setWithChild,
    withOutChild,
    setWithOutChild,
    isClicked,
    setIsClicked,
    isLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <div className="d-flex flex-column gap-4">
      <RoomSelector {...props} />
      <CustomFormContainer title="Oda Kontenjanları">
        <RoomMaxPeopleCount
          values={roomPersonPriceRateState}
          hotelId={props.hotelAndSelectedRoom.otel_id}
          roomId={props.hotelAndSelectedRoom.oda_id}
        />
        <SwitchesSection
          values={roomPersonPriceRateState}
          arrangeAccumodationTypes={arrangeAccumodationTypes}
        />
        <RoomAccomodationTypes
          values={roomPersonPriceRateState}
          childOptions={childOptions}
          arrangeAccumodationTypes={arrangeAccumodationTypes}
          hotelId={props.hotelAndSelectedRoom.otel_id}
          roomId={props.hotelAndSelectedRoom.oda_id}
        />
      </CustomFormContainer>
      {roomPersonPriceRateState.ozel_katsayi === false && (
        <CustomFormContainer title="Kişi Başı Oranları">
          <PricePerPerson values={roomPersonPriceRateState} />
          <PerPersonSwitch values={roomPersonPriceRateState} />
          <PersonRoom
            values={roomPersonPriceRateState}
            childOptions={childOptions}
            arrangeAccumodationTypes={arrangeAccumodationTypes}
            hotelId={props.hotelAndSelectedRoom.otel_id}
            roomId={props.hotelAndSelectedRoom.oda_id}
            calculateWithChild={calculateWithChild}
            calculateWithOutChild={calculateWithOutChild}
            numberOfChildren={numberOfChildren}
            withChild={withChild}
            setWithChild={setWithChild}
            withOutChild={withOutChild}
            setWithOutChild={setWithOutChild}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
          />
        </CustomFormContainer>
      )}
      <SaveRoomPersonPriceButton isLoading={isLoading} onSave={handleSave} />
    </div>
  );
};

export default RoomPersonPriceRatesForm;
