import useHandleTable from "./useHandleTable";
import { CustomLoadingPage, CustomReactTable } from "Components/Custom/UI";

interface LocationHotelsTableProps {
  isLoading: boolean;
}

const LocationHotelsTable = ({ isLoading }: LocationHotelsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <CustomReactTable
        fileName="Konuma Göre Otel Fiyatları"
        tableData={tableData}
        tableColumns={tableColumns}
      />
    </>
  );
};

export default LocationHotelsTable;
