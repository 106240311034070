import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";
import {
  getAdsAndHotels,
  getMatchedHotels,
  getUnmatchedHotels,
  getUnmatchedAdsGroups,
  deleteMatchedHotel,
} from "slices/adsAndAnalyze/adsGroups/thunk";

const useHandleTable = () => {
  const { matchedHotels } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleDelete = (id: number) => {
    const payload = {
      otel_id: id,
    };
    const successCallback = () => {
      dispatch(getAdsAndHotels());
      dispatch(getMatchedHotels({ page: 1, per_page: 10, search: "" }));
      dispatch(getUnmatchedHotels({ page: 1, per_page: 10, search: "" }));
      dispatch(getUnmatchedAdsGroups({ page: 1, per_page: 10, search: "" }));
    };
    dispatch(deleteMatchedHotel(payload, successCallback));
  };

  const tableData = matchedHotels?.map((matchedHotel) => ({
    "Otel ID": matchedHotel.otel_id,
    "Otel Adı": matchedHotel.otel_adi,
    "Reklam Grup Adı": matchedHotel.reklam_grup_adi,
    "Kampanya Adı": matchedHotel.kampanya_adi,
    "Bizdeki Durum": matchedHotel.bizdeki_durum,
    "Reklam Grubu Durum": matchedHotel.reklam_grup_durum,
    "Kampanya Durum": matchedHotel.kampanya_durum,
  }));

  const tableColumns = [
    {
      header: "Otel ID",
      accessorKey: "Otel ID",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Reklam Grup Adı",
      accessorKey: "Reklam Grup Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kampanya Adı",
      accessorKey: "Kampanya Adı",
      enableColumnFilter: false,
    },
    {
      header: "Bizdeki Durum",
      accessorKey: "Bizdeki Durum",
      enableColumnFilter: false,
    },
    {
      header: "Reklam Grubu Durum",
      accessorKey: "Reklam Grubu Durum",
      enableColumnFilter: false,
    },
    {
      header: "Kampanya Durum",
      accessorKey: "Kampanya Durum",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Otel ID",
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
