import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import useValidateForm from "./useValidateForm";
import { handleChangeInput, UpdateGroupInitialState } from "slices/userOperations/updateGroup/reducer";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { groupToUpdate, updatedGroup, updateGroupFormErrors, updateGroupLoading } =
    useSelector(
      (state: { UpdateGroup: UpdateGroupInitialState }) => state.UpdateGroup
    );

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const updateGroupRequestPayload = new FormData();

  const [selectedGroupMemberOptions, setSelectedGroupMemberOptions] =
    useState<string[]>([]);

  useEffect(() => {
    if (groupToUpdate.uyeler && groupToUpdate.uyeler.length > 0) {
      const selectedMembers = groupToUpdate.uyeler
        .filter((member) => member.selected === "selected")
        .map((member) => member.id.toString());

      setSelectedGroupMemberOptions(selectedMembers);
    }
  }, [groupToUpdate.uyeler]);

  const handleSelectGroupMemberOptions = (selected: string[]) => {
    setSelectedGroupMemberOptions(selected);

    const selectedMemberIds = selected.map(Number);

    dispatch(handleChangeInput({ grup_uyeleri_id : selectedMemberIds }));
  };

  const groupManagerOptions = groupToUpdate.yetkililer?.map((manager) => ({
    value: manager.id,
    label: manager.ad_soyad,
  }));

  const selectedManager = updatedGroup.yetkili_id;

  const groupMemberOptions = groupToUpdate.uyeler?.map((member) => ({
    value: member.id.toString(),
    label: member.ad_soyad,
  }));

  return {
    groupManagerOptions,
    selectedManager,
    groupMemberOptions,
    selectedOptions: selectedGroupMemberOptions,
    handleSelectOptions: handleSelectGroupMemberOptions,
    values: updatedGroup,
    formErrors: updateGroupFormErrors,
    updateGroupLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    updateGroupRequestPayload,
  };
};

export default useHandleForm;
