import { CustomButton, CustomFormFieldContainer } from "Components/Custom/UI";
import { useState } from "react";
import { Input } from "reactstrap";

interface TransferInfoFormProps {
  paymentId?: string;
  transferInfo?: TransferFormValues;
  remainingAmount: number;
  onAddPaymentMethod: (
    formValues: TransferFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    onResetForm: () => void
  ) => void;
  onEditPaymentMethod?: (
    formValues: TransferFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    paymentId: string
  ) => void;
  onRemoveSelectedPaymentMethod: () => void;
}

interface TransferFormValues {
  paymentId: string;
  bankName: string;
  amount: number;
}

type PaymentMethod = "cash" | "creditCard" | "transfer";

const validateForm = (
  formValues: TransferFormValues,
  remainingAmount: number
) => {
  const errors: {
    bankName?: string;
    amount?: string;
  } = {};
  if (formValues.bankName.length < 3) {
    errors.bankName = "Banka adı en az 3 karakter olmalıdır.";
  }

  if (formValues.amount <= 0) {
    errors.amount = "Tutar 0'dan büyük olmalıdır.";
  }

  if (formValues.amount > remainingAmount) {
    // errors.amount = "Tutar kalan tutardan büyük olamaz.";
  }

  const isValid = Object.keys(errors).length === 0;

  return { errors, isValid };
};

const TransferInfoForm = ({
  paymentId,
  transferInfo,
  remainingAmount,
  onAddPaymentMethod,
  onEditPaymentMethod,
  onRemoveSelectedPaymentMethod,
}: TransferInfoFormProps) => {
  const [formValues, setFormValues] = useState<TransferFormValues>({
    paymentId: transferInfo ? transferInfo.paymentId : "",
    bankName: transferInfo ? transferInfo.bankName : "",
    amount: transferInfo ? transferInfo.amount : 0,
  });

  const onInputChange = (key: string, value: string | number) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const onResetForm = () => {
    setFormValues({
      paymentId: "",
      bankName: "",
      amount: 0,
    });
  };

  const { errors, isValid } = validateForm(formValues, remainingAmount);

  return (
    <div className="d-flex flex-column gap-3 border rounded bg-white p-2">
      <span className="fw-bold">Havale Ödemesi Bilgileri</span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "1rem",
        }}
      >
        <CustomFormFieldContainer label="Banka Adı" error={errors.bankName}>
          <Input
            type="text"
            placeholder="Banka Adı"
            value={formValues.bankName}
            onChange={(e) => onInputChange("bankName", e.target.value)}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Tutar" error={errors.amount}>
          <Input
            type="number"
            placeholder="Tutar"
            value={formValues.amount}
            onChange={(e) => onInputChange("amount", Number(e.target.value))}
          />
        </CustomFormFieldContainer>
        <div>
          {paymentId ? (
            <CustomButton
              variant="primary"
              onClick={() =>
                isValid &&
                onEditPaymentMethod &&
                onEditPaymentMethod(
                  formValues,
                  "transfer",
                  formValues.amount,
                  paymentId
                )
              }
            >
              Ödemeyi Düzenle
            </CustomButton>
          ) : (
            <CustomButton
              variant="success"
              onClick={() =>
                isValid &&
                onAddPaymentMethod(
                  formValues,
                  "transfer",
                  formValues.amount,
                  onResetForm
                )
              }
            >
              Havale Ödemesi Ekle
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransferInfoForm;
