import { createSlice } from "@reduxjs/toolkit";
import {
  FooterLink,
  FooterLinkFormErrors,
} from "types/myWebSite";

export interface AddFooterLinkInitialState {
  addFooterLink: FooterLink;
  footerLinks: FooterLink[];
  footerLinkFormErrors: FooterLinkFormErrors;
  isLoading: boolean;
  error: string;
  addFooterLoading: boolean;
  addFooterError: string;
  deleteFooterLoading: boolean;
  deleteFooterError: string;
}

export const addFooterLinkInitialState: AddFooterLinkInitialState = {
  addFooterLink: {
    id: 0,
    otel_id: 0,
    title: "",
    link: "",
    add_time: "",
  },
  footerLinks: [],
  footerLinkFormErrors: {},
  isLoading: false,
  error: "",
  addFooterLoading: false,
  addFooterError: "",
  deleteFooterLoading: false,
  deleteFooterError: "",
};

const addFooterLinkSlice = createSlice({
  name: "addFooterLink",
  initialState: addFooterLinkInitialState,
  reducers: {
    handleSaveFooterLinks(state, action) {
      state.footerLinks = action.payload;

      // console.log("footerlinks first states : ", action.payload);
    },
    handleChangeInput(state, action) {
      state.addFooterLink = {
        ...state.addFooterLink,
        ...action.payload,
      };
    },
    handleFooterLinkFormErrors(state, action) {
      state.footerLinkFormErrors = action.payload;
    },
    handleResetAddFooterLinkFormFields(state){
       state.addFooterLink = {
        id: 0,
        otel_id: 0,
        title: "",
        link: "",
        add_time: "",
       };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddFooterLinkLoading(state, action) {
      state.addFooterLoading = action.payload;
    },
    setAddFooterLinkError(state, action) {
      state.addFooterError = action.payload;
    },
    setDeleteFooterLinkLoading(state, action) {
      state.deleteFooterLoading = action.payload;
    },
    setDeleteFooterLinkError(state, action) {
      state.deleteFooterError = action.payload;
    },
  },
});

export const {
    handleSaveFooterLinks,
    handleChangeInput,
    handleFooterLinkFormErrors,
    handleResetAddFooterLinkFormFields,
    setIsLoading,
    setError,
    setAddFooterLinkLoading,
    setAddFooterLinkError,
    setDeleteFooterLinkLoading,
    setDeleteFooterLinkError,
} = addFooterLinkSlice.actions;

export default addFooterLinkSlice.reducer;
