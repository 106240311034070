export type BookingType = {
  tarih: string | Date;
  to?: string | Date;
  durum: number;
};

export type blockedDaysType = string[];

export type ShowMonths = 12 | 4 | 2 | 1;

export interface ICalendarPropTypes {
  bookings?: BookingType[];
  showNumberOfMonths?: ShowMonths;
  showKey?: boolean;
  showCurrentYear?: boolean;
  showControls?: boolean;
}

export interface IHandleBookings {
  bookings: BookingType[];
  year: number;
}

export interface IFormatBookingsData {
  bookings: BookingType[];
  year: number;
}

export interface IGetAllBookedDays {
  dates: BookingType[];
}

export interface IGetAllHalfDays {
  dates: BookingType[];
  status?: number;
}

export interface IGetDatesInRange {
  startDate: string | Date;
  endDate: string | Date;
}

export interface IControls {
  prev: () => void;
  initCal: () => void;
  next: () => void;
  currentYear: number;
}

export enum Days {
  Monday = "Pzt",
  Tusday = "Sal",
  Wednesday = "Çar",
  Thursday = "Per",
  Friday = "Cum",
  Saturday = "Ctesi",
  Sunday = "Paz",
}

export type DaysOfWeekType = Days[];

export enum DayOffset {
  Monday = "6",
  Tusday = "5",
  Wednesday = "4",
  Thursday = "3",
  Friday = "2",
  Saturday = "1",
  Sunday = "0",
}

export type DaysOfWeekOffsetType = DayOffset[];

export interface IYear {
  showNumberOfMonths?: ShowMonths;
  bookedDates: blockedDaysType;
  availableDates: blockedDaysType;
  lastRoomsDates: blockedDaysType;
  unavailableDates: blockedDaysType;
  noQuotaDates: blockedDaysType;
  activeYear: number;
  monthsFrom: number;
}

export interface IGetMonthName {
  [key: number]: string;
}
