import { CustomFormContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { CreateRoomFormValues } from "types/reservation";

interface ReservationNeedsProps {
  formValues: CreateRoomFormValues;
  handleChangeInput: (key: string, value: any) => void;
}

const ReservationNeeds = ({
  formValues,
  handleChangeInput,
}: ReservationNeedsProps) => {
  return (
    <CustomFormContainer>
      <div className="d-flex flex-column gap-2">
        <span className="theme-text-danger fw-medium fs-14">
          Misafirin rezervasyonla ilgili talepleri: (Bu alana yazdığınız
          talepler hem tesise iletilmekte hemde müşteri voucherında yazmaktadır.
          Lütfen taleplerinizi "Mümkünse Ricadır" şeklinde yazınız ve misafire
          bu bilgilerin tesisin müsaitliği doğrultusunda konfirme edilececeğini
          belirtiniz.)
        </span>
        <Input
          type="textarea"
          name="guestNeeds"
          id="guestNeeds"
          placeholder="Otele Gidecek Müşteri Açıklaması"
          value={formValues.guestNeeds}
          onChange={(e) => handleChangeInput("guestNeeds", e.target.value)}
          style={{
            textTransform: "uppercase",
          }}
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <span className="theme-text-danger fw-medium fs-14">
          Rezervasyonla ilgili Acenta talepleri: (Bu alana yazdığınız bilgiler
          sadece otele iletilmekte olup müşteri vocherlarında yer almaz.)
        </span>
        <Input
          type="textarea"
          name="agentNeeds"
          id="agentNeeds"
          placeholder="Otele Gidecek Acenta Açıklaması"
          value={formValues.agentNeeds}
          onChange={(e) => handleChangeInput("agentNeeds", e.target.value)}
          style={{
            textTransform: "uppercase",
          }}
        />
      </div>
    </CustomFormContainer>
  );
};

export default ReservationNeeds;
