import { CustomButton } from "Components/Custom/UI";
import { FilePondFile } from "filepond";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleSavePlaceToVisitForUpdate } from "slices/definitions/listPlacesToVisit/reducer";
import {
  getPlacesToVisit,
  updatePlaceToVisit,
} from "slices/definitions/listPlacesToVisit/thunk";
import {
  UpdatePlaceToVisitRequestPayload,
  UpdatePlaceToVisitState,
} from "types/definitions";

interface UpdatePlaceToVisitButtonProps {
  values: UpdatePlaceToVisitState;
  requestPayload: UpdatePlaceToVisitRequestPayload;
  validateForm: (values: UpdatePlaceToVisitState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdatePlaceToVisitButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdatePlaceToVisitButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdatePlaceToVisit = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      requestPayload.append("id", values.id.toString());
      requestPayload.append("yer_adi", values.yer_adi);
      requestPayload.append("aciklama", values.aciklama);

      const file = values.file as FilePondFile;

      file && requestPayload.append("file", file.file, file.file.name);

      dispatch(updatePlaceToVisit(requestPayload));

      setTimeout(() => {
        dispatch(getPlacesToVisit());
      }, 1000);
    }
  };

  const resetToInitialState = (id: number) => {
    dispatch(handleSavePlaceToVisitForUpdate(id));
    window.scrollTo(0, 0);
  };
  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton
        variant="danger"
        onClick={() => resetToInitialState(values.id)}
      >
        Temizle
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleUpdatePlaceToVisit}
        isLoading={isLoading}
      >
        Yeri Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdatePlaceToVisitButton;
