import PaymentStatus from "./PaymentStatus";
import PaymentTypes from "./PaymentTypes";
import RoomSelector from "./RoomSelector";
import SaveMethodsAndStatusButton from "./SaveMethodsAndStatusButton";
import useHandlForm from "./useHandlForm";

interface RoomSalesAndPaymentMethodsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const RoomSalesAndPaymentMethodsForm = (
  props: RoomSalesAndPaymentMethodsFormProps
) => {
  const { initialValues, formValues, disableUncancelableRate, isLoading } =
    useHandlForm();

  return (
    <>
      <RoomSelector {...props} />
      <div className="d-flex flex-column gap-4">
        <PaymentTypes
          formValues={formValues}
          disableUncancelableRate={disableUncancelableRate}
        />
        <PaymentStatus formValues={formValues} />
        <SaveMethodsAndStatusButton
          otel_id={props.hotelAndSelectedRoom.otel_id}
          oda_id={props.hotelAndSelectedRoom.oda_id}
          initialValues={initialValues}
          formValues={formValues}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default RoomSalesAndPaymentMethodsForm;
