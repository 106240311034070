import { CustomFormContainer } from "Components/Custom/UI";
import { FooterLink, FooterLinkFormErrors } from "types/myWebSite";
import FooterLinkTitle from "./FooterLinkTitle";
import FooterLinkUrl from "./FooterLinkUrl";
import AddFooterLinkButton from "./AddFooterLinkButton";

interface AddFooterFormFieldsProps {
  values: FooterLink;
  requestPayload: FormData;
  formErrors: FooterLinkFormErrors;
  validateFormChange: (values: FooterLink) => void;
  validateForm: (values: FooterLink) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddFooterFormFields = ({
  values,
  requestPayload,
  formErrors,
  validateFormChange,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddFooterFormFieldsProps) => {
  return (
    <div className="mb-4 px-3">
      <CustomFormContainer title="Footer Link Ekle" lg={12} xl={12}>
        <FooterLinkTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <FooterLinkUrl
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <div className="d-flex justify-content-end">
          <AddFooterLinkButton
            values={values}
            requestPayload={requestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            isLoading={isLoading}
          />
        </div>
      </CustomFormContainer>
    </div>
  );
};
export default AddFooterFormFields;
