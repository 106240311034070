import { useSelector } from "react-redux";
import { RoomsLackPictureInitialState } from "slices/reports/roomsLackPicture/reducer";

const useHandleTable = () => {
  const { roomsLackPictureData } = useSelector(
    (state: { RoomsLackPicture: RoomsLackPictureInitialState }) =>
      state.RoomsLackPicture
  );

  const tableData = roomsLackPictureData?.map((roomsLackPictureData) => ({
    "Otel Adı": roomsLackPictureData.otel_adi,
    "Oda Adı": roomsLackPictureData.oda_isim,
    Resim1: roomsLackPictureData.resim1,
    Resim2: roomsLackPictureData.resim2,
    Resim3: roomsLackPictureData.resim3,
    Resim4: roomsLackPictureData.resim4,
    Resim5: roomsLackPictureData.resim5,
    Resim6: roomsLackPictureData.resim6,
    Resim7: roomsLackPictureData.resim7,
    Resim8: roomsLackPictureData.resim8,
    Resim9: roomsLackPictureData.resim9,
    Resim10: roomsLackPictureData.resim10,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Oda Adı",
      accessorKey: "Oda Adı",
      enableColumnFilter: false,
    },
    {
      header: "1. Resim",
      accessorKey: "Resim1",
      enableColumnFilter: false,
    },
    {
      header: "2. Resim",
      accessorKey: "Resim2",
      enableColumnFilter: false,
    },
    {
      header: "3. Resim",
      accessorKey: "Resim3",
      enableColumnFilter: false,
    },
    {
      header: "4. Resim",
      accessorKey: "Resim4",
      enableColumnFilter: false,
    },
    {
      header: "5. Resim",
      accessorKey: "Resim5",
      enableColumnFilter: false,
    },
    {
      header: "6. Resim",
      accessorKey: "Resim6",
      enableColumnFilter: false,
    },
    {
      header: "7. Resim",
      accessorKey: "Resim7",
      enableColumnFilter: false,
    },
    {
      header: "8. Resim",
      accessorKey: "Resim8",
      enableColumnFilter: false,
    },
    {
      header: "9. Resim",
      accessorKey: "Resim9",
      enableColumnFilter: false,
    },
    {
      header: "10. Resim",
      accessorKey: "Resim10",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
