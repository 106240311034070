import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListWebSitesInitialState } from "slices/myWebSite/listWebSites/reducer";
import { getHotelsWithoutWebSites, getWebSitesList } from "slices/myWebSite/listWebSites/thunk";

const useListWebSites = () => {
  const dispatch: Dispatch<any> = useDispatch();



  const { isLoading } = useSelector(
    (state: { ListWebSites: ListWebSitesInitialState }) =>
      state.ListWebSites
  );


  useEffect(() => {
    dispatch(getWebSitesList());
    dispatch(getHotelsWithoutWebSites());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    isLoading,
  };
};

export default useListWebSites;
