import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { PlaceToVisit } from "types/definitions";
import useHandleForm from "./useHandleForm";
import Select from "react-select";

interface PlaceToVisitSelectorProps {
  placesToVisitList: PlaceToVisit[];
}

interface Option {
  value: number;
  label: string;
}

const PlaceToVisitSelector = ({
  placesToVisitList,
}: PlaceToVisitSelectorProps) => {
  const { options, placeToVisit, handleSelectPlaceToVisit } =
    useHandleForm(placesToVisitList);

  return (
    <CustomFormContainer>
      <CustomFormFieldContainer label="Yer Adı">
        <Select
          value={
            options.find((option) => option.value === placeToVisit.id) || null
          }
          onChange={(selected: Option) => {
            handleSelectPlaceToVisit(selected.value);
          }}
          options={options}
          placeholder="Yer Adı Seçiniz"
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default PlaceToVisitSelector;
