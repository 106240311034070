import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import FacilityTitle from "./FacilityTitle";
import FacilityLogo from "./FacilityLogo";
import FacilityStarsAndSefLink from "./FacilityStarsAndSefLink";
import FacilityInfo from "./FacilityInfo";
import FacilityFeatures from "./FacilityFeatures";
import FacilityGeneralDescription from "./FacilityGeneralDescription";
import FacilityFeaturesAndChildrenOptions from "./FacilityFeaturesAndChildrenOptions";
import FacilityPhoneNumber from "./FacilityPhoneNumber";
import FacilityStatus from "./FacilityStatus";

interface GeneralInfoFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  statusOptions: Option[];
  facilityStarsOptions: Option[];
  facilityFeaturesOptions: Option[];
  facilityChildrenOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const GeneralInfoFields = ({
  values,
  formErrors,
  validateFormChange,
  statusOptions,
  facilityStarsOptions,
  facilityFeaturesOptions,
  facilityChildrenOptions,
}: GeneralInfoFieldsProps) => {
  return (
    <CustomFormContainer title="Genel Bilgiler" lg={12} xl={12}>
      <FacilityTitle
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityPhoneNumber
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityStatus
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        statusOptions={statusOptions}
      />
      <FacilityLogo
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityStarsAndSefLink
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        starsOptions={facilityStarsOptions}
      />
      <FacilityInfo
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityFeatures
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityGeneralDescription
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityFeaturesAndChildrenOptions
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityFeaturesOptions={facilityFeaturesOptions}
        facilityChildrenOptions={facilityChildrenOptions}
      />
    </CustomFormContainer>
  );
};

export default GeneralInfoFields;
