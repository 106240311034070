import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Input, Label } from "reactstrap";
import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { FACILITY_POOL_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilitySwimmingFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  facilitySwimmingOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilitySwimmingFields = ({
  values,
  formErrors,
  validateFormChange,
  facilitySwimmingOptions,
}: FacilitySwimmingFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Havuz Deniz Plaj" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="Havuz Deniz Plaj Açıklama"
        // error={formErrors.txt_havuz}
        orientation="vertical"
        divider
      >
        <CustomFormEditor
          editorData={values.txt_havuz}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                txt_havuz: data,
              })
            );
            validateFormChange({
              ...values,
              txt_havuz: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Havuz Deniz Plaj Seçenekleri"
        // error={formErrors.havuz_deniz_plaj}
        description={FACILITY_POOL_DESCRIPTION}
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilitySwimmingOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.havuz_deniz_plaj.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.havuz_deniz_plaj, parseInt(value)]
                    : values.havuz_deniz_plaj.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      havuz_deniz_plaj: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    havuz_deniz_plaj: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilitySwimmingFields;
