import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToastError } from "slices/toast/reducer";
import {
  handleSetPermissionForAuthority,
  UserAuthoritiesInitialState,
} from "slices/userOperations/authorities/reducer";

const useHandleUserAuthorityOptions = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { authorities, selectedPermissions } = useSelector(
    (state: { UserAuthorities: UserAuthoritiesInitialState }) =>
      state.UserAuthorities
  );

  const handlePermissionChange = (authorityId: number, permission: string) => {
    try {
      dispatch(handleSetPermissionForAuthority({ authorityId, permission }));
    } catch (error) {
      dispatch(setToastError(error));
    }
  };

  return {
    authorities,
    selectedPermissions,
    handlePermissionChange,
  };
};

export default useHandleUserAuthorityOptions;
