import { useSelector } from "react-redux";
import { AddGroupInitialState } from "slices/userOperations/addGroup/reducer";
import useValidateForm from "./useValidateForm";


const useHandleForm = () => {

  const {
    addGroupFormChoices,
    addGroup,
    addGroupFormErrors,
    addGroupLoading,
  } = useSelector(
    (state: { AddGroup: AddGroupInitialState }) => state.AddGroup
  );

  const { validateForm, validateFormChange, handleSubmitted } = useValidateForm();

  const addGroupRequestPayload = new FormData();


  return {
    formChoices: addGroupFormChoices,
    values: addGroup,
    formErrors: addGroupFormErrors,
    isLoading: addGroupLoading,
    validateForm, 
    validateFormChange, 
    handleSubmitted,
    requestPayload: addGroupRequestPayload
  };
};

export default useHandleForm;
