import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  FacilityImagesInitialState,
  handleChangeFacilityImageFilesInput,
} from "slices/facility/facilityImages/reducer";

import { CustomMultipleFileUploader } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import AddNewImageButton from "./AddNewImageButton";

const FacilityImageSelector = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityImageFiles, addImagesLoading } = useSelector(
    (state: { FacilityImages: FacilityImagesInitialState }) =>
      state.FacilityImages
  );

  return (
    <>
      <CustomMultipleFileUploader
        files={facilityImageFiles as any}
        handleAddFiles={(fileItems) => {
          dispatch(handleChangeFacilityImageFilesInput(fileItems));
        }}
        maxFiles={10}
      />
      <AddNewImageButton
        facilityImageFiles={facilityImageFiles}
        isLoading={addImagesLoading}
      />
    </>
  );
};

export default FacilityImageSelector;
