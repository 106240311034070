import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleChangeReservationFilterChoices,
  ReservationsInitialState,
} from "slices/reports/reservations/reducer";
import { getReservations } from "slices/reports/reservations/thunk";
import { PaginationBottom } from "types/GlobalTypes";
import { ReservationFilterPayload } from "types/reports";

let firstRender = true;

const useHandlePagination = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [paginationTop, setPaginationTop] = useState({
    per_page: 10,
    search: "",
  });

  const { pagination, filterReservations } = useSelector(
    (state: { Reservations: ReservationsInitialState }) => state.Reservations
  );

  const handleClickNextPage = () => {
    const params: ReservationFilterPayload = {
      ...paginationTop,
      page: pagination.current_page + 1,
      tarih_turu: filterReservations.tarih_turu,
      otel_id: 0,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      end_date: filterReservations.end_date,
      acente_id: "",
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      inet_kodu: filterReservations.inet_kodu,
      musteri_adi: filterReservations.musteri_adi,
      otel_list: filterReservations.otel_list,
    };

    dispatch(handleChangeReservationFilterChoices(params));
    pagination.next_page_url &&
      dispatch(getReservations(params)) &&
      console.log("PARAMS : ", params);
  };
  const handleClickPrevPage = () => {
    const params: ReservationFilterPayload = {
      ...paginationTop,
      page: pagination.current_page - 1,
      tarih_turu: filterReservations.tarih_turu,
      otel_id: 0,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      end_date: filterReservations.end_date,
      acente_id: "",
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      inet_kodu: filterReservations.inet_kodu,
      musteri_adi: filterReservations.musteri_adi,
      otel_list: filterReservations.otel_list,
    };

    pagination.prev_page_url &&
      dispatch(getReservations(params)) &&
      dispatch(handleChangeReservationFilterChoices(params));
  };

  const paginationBottomProps: PaginationBottom = {
    total: pagination.total,
    current_page: pagination.current_page,
    per_page: pagination.per_page,
    prev_page_url: pagination.prev_page_url,
    next_page_url: pagination.next_page_url,
    onClickNextPage: handleClickNextPage,
    onClickPrevPage: handleClickPrevPage,
  };

  const handleChange = (key: string, value: number | string) => {
    if (firstRender) {
      firstRender = false;
      return;
    }
    const newPaginationTop = {
      ...paginationTop,
      [key]: value,
      page: key === "per_page" ? 1 : pagination.current_page,
    };

    setPaginationTop(newPaginationTop);

    const params: ReservationFilterPayload = {
      ...newPaginationTop,
      page: pagination.current_page - 1,
      tarih_turu: filterReservations.tarih_turu,
      otel_id: 0,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      end_date: filterReservations.end_date,
      acente_id: "",
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      inet_kodu: filterReservations.inet_kodu,
      musteri_adi: filterReservations.musteri_adi,
      otel_list: filterReservations.otel_list,
    };

    if (key === "per_page") {
      params.page = 1;
    }
    
    dispatch(getReservations(params));
    dispatch(handleChangeReservationFilterChoices(params));
  };

  return { handleChange, paginationBottomProps };
};

export default useHandlePagination;
