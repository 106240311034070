import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ApplicationCriteria from "./ApplicationCriteria";
import DateSelectors from "./DateSelectors";
import ApplicableDays from "./ApplicableDays";
import MinimumNights from "./MinimumNights";
import SelectFields from "./SelectFields";
import UpdateGiftButton from "./UpdateGiftButton";
import AddNewGiftButton from "./AddNewGiftButton";

interface GiftsAndPromotionsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const GiftsAndPromotionsForm = (props: GiftsAndPromotionsFormProps) => {
  const {
    giftsAndPromotion,
    addNewGiftsAndPromotionRequestPayload,
    updateGiftsAndPromotionRequestPayload,
    promotionOptions,
    promotionRateOptions,
    promotionPersonOptions,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addPromotionLoading,
    updatePromotionLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  const { hediye_kosulu } = giftsAndPromotion;
  return (
    <CustomFormContainer>
      <ApplicationCriteria
        values={giftsAndPromotion}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {hediye_kosulu !== 3 && (
        <DateSelectors
          values={giftsAndPromotion}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      )}
      {hediye_kosulu === 1 && (
        <ApplicableDays
          values={giftsAndPromotion}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      )}
      {hediye_kosulu !== 1 && (
        <MinimumNights
          values={giftsAndPromotion}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      )}
      <SelectFields
        values={giftsAndPromotion}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        promotionOptions={promotionOptions}
        promotionRateOptions={promotionRateOptions}
        promotionPersonOptions={promotionPersonOptions}
      />
      {giftsAndPromotion.id ? (
        <UpdateGiftButton
          values={giftsAndPromotion}
          requestPayload={updateGiftsAndPromotionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updatePromotionLoading}
        />
      ) : (
        <AddNewGiftButton
          values={giftsAndPromotion}
          requestPayload={addNewGiftsAndPromotionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addPromotionLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default GiftsAndPromotionsForm;
