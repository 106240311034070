import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import { handleChangeUpdateFacilityRoomFormInput } from "slices/facility/facilityRooms/reducer";
import {
  UpdateFacilityRoomState,
  UpdateFacilityRoomFormErrors,
} from "types/facility";
import {
  CustomFormFieldContainer,
  CustomFormEditor,
} from "Components/Custom/UI";

interface FacilityRoomDescriptionFieldProps {
  values: UpdateFacilityRoomState;
  formErrors: UpdateFacilityRoomFormErrors;
  validateFormChange: (values: UpdateFacilityRoomState) => void;
}

const FacilityRoomDescriptionField = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityRoomDescriptionFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Genel Açıklama"
      error={formErrors.aciklama}
      orientation="vertical"
      required
      divider
    >
      <CustomFormEditor
        editorData={values.aciklama}
        handleGetEditorData={(data) => {
          dispatch(
            handleChangeUpdateFacilityRoomFormInput({
              aciklama: data,
            })
          );
          validateFormChange({
            ...values,
            aciklama: data,
          });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default FacilityRoomDescriptionField;
