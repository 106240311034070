import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleFooterLinkFormErrors } from "slices/myWebSite/addFooterLinks/reducer";
import { setToastError } from "slices/toast/reducer";
import {  FooterLink, FooterLinkFormErrors } from "types/myWebSite";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FooterLink,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: FooterLinkFormErrors = {};

    if (!values.title) {
      errors.title = "Footer link başlığı boş bırakılamaz.";
    }
    if (!values.link) {
        errors.link = "Footer linki boş bırakılamaz.";
      }



    dispatch(handleFooterLinkFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FooterLink) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
