import { FilePondFile } from "filepond";
import { UpdateGroupPayload } from "types/userOperations";

const useHandlePayload = (
  requestPayload: FormData,
  values: UpdateGroupPayload
) => {
  const getPayload = () => {
    Object.entries(values).forEach(([key, value]) => {
      if (key === "resim") {
        const file = value as FilePondFile;
        requestPayload.append(key, file.file, file.file.name);
      } else if (Array.isArray(value)) {
        // requestPayload.append(key, JSON.stringify(value));
        value.forEach((val) => {
          requestPayload.append(`${key}[]`, val);
        });
      } else {
        requestPayload.append(key, value);
      }
    });
    return requestPayload;
  };

  return getPayload;
};

export default useHandlePayload;
