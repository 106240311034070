import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { FormGroup, Input, Label } from "reactstrap";
import ReservationCallTable from "../ReservationCallTable";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import Select from "react-select";
import ReservationCallHeader from "../ReservationCallHeader";

interface Option {
  value: number;
  label: string;
}

interface ReservationCallFormProps {
  params: {
    aranan: string;
    arayan: string;
    dahili: string;
  };
}

const ReservationCallForm = ({ params }: ReservationCallFormProps) => {
  const {
    reservationCall,
    formValues,
    hotelsOptions,
    personOptions,
    handleFormChange,
    onSaveCallInfo,
    onResetForm,
  } = useHandleForm(params);

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const noHotel = reservationCall.otel_sorgu.length > 1 ? true : false;

  const hotelInfo =
    reservationCall.otel_sorgu.length === 1
      ? reservationCall.otel_sorgu[0]
      : null;

  return (
    <div className="d-flex flex-column gap-2">
      <ReservationCallHeader hotelInfo={hotelInfo} />
      <CustomFormContainer title="Müşteri Bilgileri">
        <CustomFormFieldContainer label="Adı Soyadı" divider required>
          <Input
            type="text"
            className="fw-semibold fs-18"
            value={formValues.musteri_ad_soyad}
            onChange={(e) =>
              handleFormChange("musteri_ad_soyad", e.target.value)
            }
            placeholder="Adı Soyadı"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Telefon Numarası" divider required>
          <div className="d-flex gap-2 align-items-center">
            <Input
              type="text"
              value={formValues.musteri_tel}
              onChange={(e) => handleFormChange("musteri_tel", e.target.value)}
              placeholder="1.Telefon Numarası"
              disabled
            />
            <Input
              type="text"
              value={formValues.musteri_tel_2}
              onChange={(e) =>
                handleFormChange("musteri_tel_2", e.target.value)
              }
              placeholder="2.Telefon Numarası"
            />
          </div>
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="E-Posta" divider>
          <Input
            type="email"
            value={formValues.musteri_eposta}
            onChange={(e) => handleFormChange("musteri_eposta", e.target.value)}
            placeholder="E-Posta Adresi"
          />
        </CustomFormFieldContainer>
        {noHotel && (
          <CustomFormFieldContainer label="Oteller" divider>
            <Select
              options={hotelsOptions}
              value={
                hotelsOptions.find(
                  (option) => option.value === formValues.otel_id
                ) || null
              }
              onChange={(selectedOption: Option) => {
                handleFormChange("otel_id", selectedOption.value);
                handleFormChange("otel_adi", selectedOption.label);
              }}
              placeholder="Otel Seçiniz"
              isDisabled={noHotel}
            />
          </CustomFormFieldContainer>
        )}
        <CustomFormFieldContainer label="İşlemler" divider>
          <div className="d-flex gap-2">
            <FormGroup check>
              <Input
                id="rezervasyon-islem-yok"
                type="radio"
                name="rezervasyon_disi_islem"
                value=""
                checked={formValues.rezervasyon_disi_islem === ""}
                onChange={() => {
                  handleFormChange("rezervasyon_disi", "hayir");
                  handleFormChange("rezervasyon_disi_islem", "");
                  handleFormChange("aciklama_islemler", "");
                  handleFormChange("sonra_aranacak_tarih", "");
                  handleFormChange("sonra_aranacak_saat", "");
                  handleFormChange("uye_id", 0);
                }}
              />
              <Label className="m-0" for="rezervasyon-islem-yok">
                İşlem Yok
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                id="rezervasyon-disi"
                type="radio"
                name="rezervasyon_disi_islem"
                value="rezervasyon_disi"
                checked={
                  formValues.rezervasyon_disi_islem === "rezervasyon-disi"
                }
                onChange={() => {
                  handleFormChange("rezervasyon_disi", "evet");
                  handleFormChange(
                    "rezervasyon_disi_islem",
                    "rezervasyon-disi"
                  );
                }}
              />
              <Label className="m-0" for="rezervasyon-disi">
                Rezervasyon Dışı
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                id="sonra-aranacak"
                type="radio"
                name="rezervasyon_disi_islem"
                value="sonra-ara"
                checked={formValues.rezervasyon_disi_islem === "sonra-ara"}
                onChange={() => {
                  handleFormChange("rezervasyon_disi", "evet");
                  handleFormChange("rezervasyon_disi_islem", "sonra-ara");
                }}
              />
              <Label className="m-0" for="sonra-aranacak">
                Sonra Aranacak
              </Label>
            </FormGroup>
          </div>
        </CustomFormFieldContainer>
        {formValues.rezervasyon_disi === "evet" && (
          <CustomFormFieldContainer
            label="İşlem Açıklama"
            orientation="vertical"
            divider
          >
            <div className="d-flex flex-column gap-2">
              {formValues.rezervasyon_disi === "evet" && (
                <Input
                  type="textarea"
                  value={formValues.aciklama_islemler}
                  onChange={(e) =>
                    handleFormChange("aciklama_islemler", e.target.value)
                  }
                  placeholder="*İşlem Açıklama (Zorunlu)"
                />
              )}
              {formValues.rezervasyon_disi_islem === "sonra-ara" && (
                <Flatpickr
                  className="form-control"
                  value={
                    formValues.sonra_aranacak_tarih
                      ? [
                          `${formValues.sonra_aranacak_tarih} ${formValues.sonra_aranacak_saat}`,
                        ]
                      : []
                  }
                  onChange={(date) => {
                    const [startDate] = date;

                    if (startDate) {
                      const stringStartDate =
                        moment(startDate).format("YYYY-MM-DD");
                      const stringStartTime = moment(startDate).format("HH:mm");

                      handleFormChange("sonra_aranacak_tarih", stringStartDate);
                      handleFormChange("sonra_aranacak_saat", stringStartTime);
                    }
                  }}
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    showMonths: 1,
                    locale: Turkish,
                    onDayCreate,
                  }}
                  placeholder="*Sonra Aranacak Tarih ve Saat (Zorunlu)"
                />
              )}
              {formValues.rezervasyon_disi_islem === "sonra-ara" && (
                <Select
                  options={personOptions}
                  value={
                    personOptions.find(
                      (option) => option.value === formValues.uye_id
                    ) || null
                  }
                  onChange={(selectedOption: Option) =>
                    handleFormChange("uye_id", selectedOption.value)
                  }
                  placeholder="Kişi Seçiniz..."
                />
              )}
            </div>
          </CustomFormFieldContainer>
        )}
        <span className="fw-semibold fs-16">Müşteri Geçmişi</span>
        <ReservationCallTable />
        <CustomFormFieldContainer
          label="Müşteri Notu"
          divider
          orientation="vertical"
        >
          <Input
            type="textarea"
            value={reservationCall.musteri_notu}
            placeholder="Müşteri Notu"
            disabled
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Müşteri Hakkında"
          divider
          orientation="vertical"
        >
          <div className="d-flex flex-column gap-2">
            <Input
              type="textarea"
              value={formValues.aciklama_musteri}
              onChange={(e) =>
                handleFormChange("aciklama_musteri", e.target.value)
              }
              placeholder="Müşteri Hakkında Açıklama"
            />
            <Input
              type="textarea"
              value={reservationCall.arama_aciklama.join("\n* ")}
              placeholder="Müşteri Hakkında Açıklama"
              rows={5}
              disabled
            />
          </div>
        </CustomFormFieldContainer>

        <div className="d-flex gap-2 justify-content-end mt-4">
          <CustomButton variant="warning" onClick={onResetForm}>
            Temizle
          </CustomButton>
          <CustomButton variant="success" onClick={onSaveCallInfo}>
            Kaydet
          </CustomButton>
        </div>
      </CustomFormContainer>
    </div>
  );
};

export default ReservationCallForm;
