import { FacilityRoomsPage } from "Components/Custom/FacilityPages";
import useFacilityRooms from "Components/Hooks/facility/useFacilityRooms";
import PageContainer from "pages/PageContainer";

const FacilityRooms = () => {
  const { loading, error, isValidHotel, ...props } = useFacilityRooms();

  return (
    <PageContainer title="Tesis Odaları">
      <FacilityRoomsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        {...props}
      />
    </PageContainer>
  );
};

export default FacilityRooms;
