import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Widget } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWidget/reducer";
import { Input } from "reactstrap";

interface WidgetUrlProps {
  values: Widget;
}

const WidgetUrl = ({ values }: WidgetUrlProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="URL Adresi :">
        <Input
          type="text"
          autoCorrect="on"
          value={values.wUrl}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                domain_link: e.target.value,
              })
            );
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default WidgetUrl;
