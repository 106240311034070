import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  addBreakingDiscount,
  getBreakingDiscounts,
} from "slices/discountsAndActions/breakingDiscounts/thunk";
import {
  BreakingDiscountRequestPayload,
  BreakingDiscountState,
} from "types/DiscountsAndActions";

interface AddBreakingDiscountButtonProps {
  values: BreakingDiscountState;
  requestPayload: BreakingDiscountRequestPayload;
  validateForm: (values: BreakingDiscountState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const AddBreakingDiscountButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: AddBreakingDiscountButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddBreakingDiscount = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addBreakingDiscount(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getBreakingDiscounts(hotelAndSelectedRoom));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleAddBreakingDiscount}
        isLoading={isLoading}
      >
        Son Dakika İndirimi Ekle / Düzenle
      </CustomButton>
    </div>
  );
};

export default AddBreakingDiscountButton;
