import { Room } from "types/PricesQuotas";
import QuotaInput from "./QuotaInput";
import RowContainer from "Components/Custom/PricesAndQuotaPages/CommonContainers/RowContainer";
import { BasicQuoteEntryInitialState } from "slices/pricesAndQuota/basicQuotaEntry/reducer";
import { useSelector } from "react-redux";

interface QuotaEntryItemProps {
  room: Room;
  index: number;
}
const QuotaEntryItem = ({ room, index }: QuotaEntryItemProps) => {
  const { changedQuotas } = useSelector(
    (state: { BasicQuotaEntry: BasicQuoteEntryInitialState }) =>
      state.BasicQuotaEntry
  );

  const findQuota = (oda_id: number) => {
    const quota = changedQuotas.find((quota) => quota.oda_id === oda_id);

    return quota ? quota : { oda_id: oda_id, kontenjan: 30 };
  };
  return (
    <RowContainer title={room.oda_isim} index={index}>
      <QuotaInput name={`${room.id}`} defaultValue={findQuota(room.id)} />
    </RowContainer>
  );
};

export default QuotaEntryItem;
