import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import { changeGroupStatus, getGroups } from "slices/userOperations/listGroups/thunk";

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const handleUpdateGroup = (id: number) => {
    if (id !== null) {
      navigate(`/grup-duzenle?grup_id=${id}`);
    }
  };

  const handleChangeStatus = (status: string, id: number) => {
    dispatch(
        changeGroupStatus(id , () => {
        dispatch(
            getGroups()
        );
      })
    );
  };

  return {
    onUpdate: handleUpdateGroup,
    onChangeStatus: handleChangeStatus,
  };
};

export default useHandleRequests;
