import { useSelector } from "react-redux";

import { OutboundCallsListWithNumberInitialState } from "slices/reports/outboundCallsListWithNumber/reducer";

const useHandleTable = () => {
  const { outboundCallsWithNumber } = useSelector(
    (state: {
      OutboundCallsListWithNumber: OutboundCallsListWithNumberInitialState;
    }) => state.OutboundCallsListWithNumber
  );

  const tableData = outboundCallsWithNumber?.map((outboundCall) => ({
    Tarih: outboundCall.tarih,
    "Adı Soyadı": outboundCall.ad_soyad,
    Dahili: outboundCall.dahili,
    "Arayan Numara": outboundCall.arayan_no,
    "Aranan Numara": outboundCall.aranan_no,
    Tip: outboundCall.tip,
    Durum: outboundCall.durum,
  }));

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "Dahili",
      enableColumnFilter: false,
    },
    {
      header: "Arayan Numara",
      accessorKey: "Arayan Numara",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Numara",
      accessorKey: "Aranan Numara",
      enableColumnFilter: false,
    },
    {
      header: "Tip",
      accessorKey: "Tip",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "Durum",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
