import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Input } from "reactstrap";
import { Dispatch } from "redux";

import { handleChangeAddMoneyPointFormInput } from "slices/reports/moneyPoints/reducer";

import { AddMoneyPointState, AddMoneyPointFormErrors } from "types/reports";

interface UserAndMoneyPointFieldsProps {
  values: AddMoneyPointState;
  formErrors: AddMoneyPointFormErrors;
  validateFormChange: (values: AddMoneyPointState) => void;
  hotelUsersOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const UserAndMoneyPointFields = ({
  values,
  formErrors,
  validateFormChange,
  hotelUsersOptions,
}: UserAndMoneyPointFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Kullanıcısı"
        error={formErrors.operator_kodu}
      >
        <Select
          value={
            hotelUsersOptions.find(
              (option) => option.value === values.operator_kodu
            ) || null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeAddMoneyPointFormInput({
                ...values,
                operator_kodu: selected.value,
              })
            );

            validateFormChange({ ...values, operator_kodu: selected.value });
          }}
          options={hotelUsersOptions}
          placeholder="Otel Kullanıcısı Seçiniz"
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Düşülecek Para Puan Miktarı"
        error={formErrors.parapuan_tutar}
      >
        <Input
          type="text"
          value={values.parapuan_tutar}
          onChange={(e) => {
            dispatch(
              handleChangeAddMoneyPointFormInput({
                parapuan_tutar: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              parapuan_tutar: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UserAndMoneyPointFields;
