import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FormGroup, Input } from "reactstrap";
import { KEEP_BOTH_PERSONNEL_DESCRIPTION } from "utils/fieldDescriptions";
import { AssignHotelToUserFormErrors, ChangeHotelAssigneePayload } from "types/userOperations";
import { handleChangeHotelAssignee } from "slices/userOperations/personnelHotelAssignments/reducer";

interface KeepBothPersonnelCheckboxProps {
  values: ChangeHotelAssigneePayload;
  validateFormChange: (values: ChangeHotelAssigneePayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const KeepBothPersonnelCheckbox = ({
  values,
  validateFormChange,
}: KeepBothPersonnelCheckboxProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İki Personel de Kalsın : "
        description={KEEP_BOTH_PERSONNEL_DESCRIPTION}
        divider
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.iki_personel === "evet"}
            onChange={(e) => {
              const value = e.target.checked ? "evet" : "hayir";
              dispatch(handleChangeHotelAssignee({
                ...values,
                iki_personel: value,
              }));
              validateFormChange({
                ...values,
                iki_personel: value,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default KeepBothPersonnelCheckbox;
