import { ChildSpecialAgeInformationPage } from "Components/Custom/PricesAndQuotaPages";
import useChildSpecialAgeInformation from "Components/Hooks/pricesAndQuota/useChildSpecialAgeInformation";

import PageContainer from "pages/PageContainer";
const ChildSpecialAgeInformation = () => {
  const { error, isLoading, loading, isValidHotel, ...props } =
    useChildSpecialAgeInformation();

  return (
    <PageContainer title="Çocuk Özel Yaş Bilgileri">
      <ChildSpecialAgeInformationPage
        data={true}
        loading={loading}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
        error={error}
        {...props}
      />
    </PageContainer>
  );
};

export default ChildSpecialAgeInformation;
