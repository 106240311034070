import { Row } from "reactstrap";
import { PaginationTop } from "../CustomPaginationComponents";
import CustomGlobalFilter from "./CustomGlobalFilter";
import Select from "react-select";

interface CustomTableTopProps {
  isGlobalFilter: boolean;
  setGlobalFilter: (value: string) => void;
  globalFilter: string;
  pagination?: boolean;
  onSearch?: (searchValue: string) => void;
  onPageSizeChange?: (pageSize: number) => void;
  SearchPlaceholder: string;
  customPageSize: number;
  onCustomPageSizeChange: (pageSize: number) => void;
}

const CustomTableTop = ({
  isGlobalFilter,
  setGlobalFilter,
  globalFilter,
  pagination,
  onSearch = () => {},
  onPageSizeChange = () => {},
  SearchPlaceholder,
  customPageSize,
  onCustomPageSizeChange,
}: CustomTableTopProps) => {
  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  return (
    <>
      {isGlobalFilter && (
        <Row className="mb-3">
          {pagination ? (
            <PaginationTop
              onSearch={onSearch}
              onPageSizeChange={onPageSizeChange}
            />
          ) : (
            <div className="d-flex justify-content-between gap-2 mb-2">
              <CustomGlobalFilter
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                placeholder={SearchPlaceholder}
              />
              <div className="d-flex align-items-center gap-2">
                <Select
                  options={pageSizeOptions}
                  value={pageSizeOptions.find(
                    (option) => option.value === customPageSize
                  )}
                  onChange={(selected: { value: number; label: string }) =>
                    onCustomPageSizeChange(selected.value)
                  }
                  style={{
                    width: "200px",
                  }}
                />
                <p className="mb-0 fw-medium">kayıt</p>
              </div>
            </div>
          )}
        </Row>
      )}
    </>
  );
};

export default CustomTableTop;
