import { CallsDueLaterCardData } from "types/dashboard";

interface TableRow {
  order: number;
  call_due_date: string;
  caller_name: string;
  hotel: string;
  personnel: string;
  description: string;
  situation: string;
}

const useHandleTable = (value: CallsDueLaterCardData) => {
  const tableData: TableRow[] = [];
  let orderCounter = 1;

  Object.entries(value).forEach(([dateKey, call]) => {
    tableData.push({
      order: orderCounter++,
      call_due_date: call.sonra_aranacak,
      caller_name: call.musteri_isim,
      hotel: call.otel_adi,
      personnel: call.crm_kul_adi,
      description: call.aciklama,
      situation: call.arandi_mi,
    });
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "order",
      enableColumnFilter: false,
    },
    {
      header: "Aranacak Tarih",
      accessorKey: "call_due_date",
      enableColumnFilter: false,
    },
    {
      header: "Arayan Ad Soyad",
      accessorKey: "caller_name",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Otel",
      accessorKey: "hotel",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Yapan",
      accessorKey: "personnel",
      enableColumnFilter: false,
    },
    {
      header: "Açıklama",
      accessorKey: "description",
      enableColumnFilter: false,cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ maxWidth: "200px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Durum",
      accessorKey: "situation",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
