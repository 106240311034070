import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleAdditionalPageFormErrors } from "slices/myWebSite/additionalPages/reducer";
import { setToastError } from "slices/toast/reducer";
import { AddAdditionalPagesFormErrors, AdditionalPage, AdditionalPageState } from "types/myWebSite";

const useValidateForm = () => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    const errorElement = document.querySelector(".border-danger") as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AdditionalPageState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddAdditionalPagesFormErrors = {};

    if (!values.seflink) {
      errors.seflink = "Selflink boş bırakılamaz.";
    }
    if (!values.organizasyon_link_adi) {
      errors.organizasyon_link_adi = "Link adı boş bırakılamaz.";
    }
    if (!values.organizasyon_vitrin_resmi && !values.organizasyon_vitrin_resmi_onizleme) {
      errors.organizasyon_vitrin_resmi = "Vitrin resmi yükleyiniz.";
    }
    if (!values.organizasyon_baslik) {
      errors.organizasyon_baslik = "Sayfa başlığı boş bırakılamaz.";
    }
    if (!values.organizasyon_aciklama) {
      errors.organizasyon_aciklama = "Sayfa açıklaması yükleyiniz.";
    }
    if (!values.organizasyon_kart_baslik) {
      errors.organizasyon_kart_baslik = "İçerik başlığı adı boş bırakılamaz.";
    }
    if (!values.organizasyon_kart_aciklama) {
      errors.organizasyon_kart_aciklama = "İçerik açıklaması yükleyiniz.";
    }
    if (!values.organizasyon_kart_resim && !values.organizasyon_kart_resim_onizleme) {
      errors.organizasyon_kart_resim = "İçerik resmi yükleyiniz.";
    }

    dispatch(handleAdditionalPageFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    if (isScroll && !isValid) {
      scrollToError();
    }

    if (showErrorToast && !isValid) {
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));
    }

    return { isValid };
  };

  const validateFormChange = (values: AdditionalPageState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
    additionalPagesFormErrors: {},
  };
};

export default useValidateForm;








