import { useSelector } from "react-redux";
import { AddFacilityInitialState } from "slices/facility/addFacility/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { facilityFormChoices } = useSelector(
    (state: { AddFacility: AddFacilityInitialState }) => state.AddFacility
  );
  const welcomeCodeOptions: Option[] = facilityFormChoices["Karşılama_Kodu"].map(
    (choice: string) => ({
      value: choice,
      label: choice.charAt(0).toUpperCase() + choice.slice(1),
    })
  );

  const priceCalculationSupplierOptions: Option[] = facilityFormChoices[
    "Fiyat Hesaplama"
  ].map((choice: string) => ({
    value: choice,
    label: choice.charAt(0).toUpperCase() + choice.slice(1),
  }));

  return {
    welcomeCodeOptions,
    priceCalculationSupplierOptions,
  };
};

export default useHandleForm;
