export const TABLE_COLORS = {
  gray: "#d1d5db",
  red: "#fecaca",
  orange: "#fed7aa",
  amber: "#fef3c7",
  yellow: "#fef9c3",
  lime: "#ecfccb",
  green: "#dcfce7",
  emerald: "#d1fae5",
  teal: "#ccfbf1",
  sky: "#e0f2fe",
  blue: "#dbeafe",
  indigo: "#e0e7ff",
  violet: "#ede9fe",
  purple: "#f3e8ff",
  fuchsia: "#fae8ff",
  pink: "#fce7f3",
  rose: "#ffe4e6",
};
