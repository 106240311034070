
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ADD_FEATURES_DESCRIPTION } from "utils/fieldDescriptions";
import { Input, Label } from "reactstrap";
import { CreateWebSiteState, CreateWebSiteFormChoices, CreateWebSiteFormErrors } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";

interface FeaturesOptionsProps {
    values: CreateWebSiteState;
    formChoices: CreateWebSiteFormChoices;
    formErrors: CreateWebSiteFormErrors;
    validateFormChange: (values: CreateWebSiteState) => void;
    maxSelection: number;
}

interface Option {
    value: string;
    label: string;
}

const FeaturesOptions = ({
    values,
    formChoices,
    validateFormChange,
    formErrors,
    maxSelection,
}: FeaturesOptionsProps) => {
    const dispatch: Dispatch<any> = useDispatch();

    const oneCikanOzelliklerArray = values.one_cikan_ozellik || [];

    const featureOptions : Option[] = formChoices.one_cikan_ozellikler_list.map((feature) => ({
        value: feature.id.toString(),
        label: feature.name,
    }));

    const selectedCount = oneCikanOzelliklerArray.length;

    return (
        <>
            <CustomFormFieldContainer
                label="Öne Çıkan Özellikler : "
                error={formErrors.one_cikan_ozellik}
                description={ADD_FEATURES_DESCRIPTION}
                required
                orientation="vertical"
            >
                <div
                    className="bg-white rounded border p-2"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                    }}
                >
                    {featureOptions.map((option) =>{ 
                        const isChecked = oneCikanOzelliklerArray.includes(option.value);
                        const isDisabled = !isChecked && selectedCount >= maxSelection;
                    return(
                        (<div key={option.value} className="d-flex gap-2">
                            <Input
                                type="checkbox"
                                id={option.value.toString()}
                                checked={isChecked}
                                disabled={isDisabled}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const value = option.value;

                                    let newArray = [...oneCikanOzelliklerArray];

                                    if (checked) {
                                        if (!newArray.includes(value)) {
                                            newArray.push(value);
                                        }
                                    } else {
                                        newArray = newArray.filter((item) => item !== value);
                                    }

                                    dispatch(
                                        handleChangeInput({
                                            ...values,
                                            one_cikan_ozellik: newArray,
                                        })
                                    );
                                    validateFormChange({
                                        ...values,
                                        one_cikan_ozellik: newArray,
                                    });
                                }}
                            />
                            <Label for={option.value.toString()} className="fw-normal fs-14">
                                {option.label}
                            </Label>
                        </div>)
                    )})}
                </div>
            </CustomFormFieldContainer>
        </>
    );
};

export default FeaturesOptions;
