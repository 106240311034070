import { DISCOUNTS_PAGE_DESCRIPTION } from "utils/pageDescriptions";
import { CustomPageDescriptions } from "Components/Custom/UI";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import CustomDiscountCard from "./CustomDiscountCard";

const discountPages = {
  SpecialDiscount: {
    info: "İster anlık indirimler uygulayın, ister balayı indirimleri gibi seçmeli indirimler, Extra vereceğiniz indirimler çok iyi gelecek...",
    title: "Özel İndirimler",
    link: "/ozel-indirimler",
    icon: "ri-star-line",
  },
  BreakingDiscounts: {
    info: "Bugün veya yarın giriş yapacak misafirlerinize ayrıcalık tanıyın ve indirimler sağlayın...",
    title: "Son Dakika İndirimleri",
    link: "/son-dakika-indirimleri",
    icon: "ri-timer-line",
  },
  HourlyDiscounts: {
    info: "Misafirlerinize vereceğiniz saatlik indirimlerle ayrıcalık tanıyın...",
    title: "Saatlik İndirimler",
    link: "/saatlik-indirimler",
    icon: "ri-hourglass-fill",
  },
  EarlyReservationDiscounts: {
    info: "Misafirleriniz aylar sonra gidecekleri tatil için sizi tercih edecekler. Siz de onlar için bir indirim tanımlayın...",
    title: "Erken Rezervasyon İndirimleri",
    link: "/erken-rezervasyon-indirimleri",
    icon: "ri-calendar-schedule-line",
  },
  StayLongPayLessActions: {
    info: "Misafirlerinizin tesiste daha uzun süreli konaklamasını istiyorsanız, hemen çok kal az öde aksiyonu tanımlayıp ilgisini çekin...",
    title: "Çok Kal Az Öde Aksiyonları",
    link: "/cok-kal-az-ode-aksiyonlari",
    icon: "ri-key-fill",
  },
  NightRestrictions: {
    info: "Minimum gece kuralları koyun veya misafirlerinizin kaldıkları gece sayılarına göre özel fiyatlar belirleyin...",
    title: "Geceleme & Gece Kısıtlamaları",
    link: "/gece-kisitlamalari",
    icon: "ri-key-fill",
  },
  UncancellableRoomRates: {
    info: "İptal edilemez oda seçeneği ekleyin ve yaptığınız indirimlerle misafirlerinizi kaçırmayın...",
    title: "İptal Edilemez Odalar",
    link: "/iptal-edilemez-oda-oranlari",
    icon: "ri-notification-off-line",
  },
  DiscountCodes: {
    info: "Tesis için belirlediğiniz indirim kuponu sayesinde kupon kodu ile işlem yapan tüm misafirlere extra indirim yapın...",
    title: "İndirim Kuponu",
    link: "/indirim-kuponlari",
    icon: "ri-gift-2-line",
  },
  UnderTheDeskDiscounts: {
    info: "Acenteye özel belirlediğiniz deskaltı indirimler ile çağrı merkezi üzerinden gelen misafirleri memnun edin...",
    title: "Deskaltı İndirimler",
    link: "/desk-alti-indirimler",
    icon: "ri-customer-service-line",
  },
};
const DiscountsPage = () => {
  return (
    <>
      <CustomPageDescriptions descriptions={DISCOUNTS_PAGE_DESCRIPTION} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap:"12px"
        }}
      >
        <CustomDiscountCard
          color={"orange"}
          title={discountPages.SpecialDiscount.title}
          info={discountPages.SpecialDiscount.info}
          icon={discountPages.SpecialDiscount.icon}
          link={discountPages.SpecialDiscount.link}
        />
        <CustomDiscountCard
          color={"darkBlue"}
          title={discountPages.BreakingDiscounts.title}
          info={discountPages.BreakingDiscounts.info}
          icon={discountPages.BreakingDiscounts.icon}
          link={discountPages.BreakingDiscounts.link}
        />
        <CustomDiscountCard
          color={"blue"}
          title={discountPages.HourlyDiscounts.title}
          info={discountPages.HourlyDiscounts.info}
          icon={discountPages.HourlyDiscounts.icon}
          link={discountPages.HourlyDiscounts.link}
        />
        <CustomDiscountCard
          color={"lightBlue"}
          title={discountPages.EarlyReservationDiscounts.title}
          info={discountPages.EarlyReservationDiscounts.info}
          icon={discountPages.EarlyReservationDiscounts.icon}
          link={discountPages.EarlyReservationDiscounts.link}
        />
        <CustomDiscountCard
          color={"teal"}
          title={discountPages.StayLongPayLessActions.title}
          info={discountPages.StayLongPayLessActions.info}
          icon={discountPages.StayLongPayLessActions.icon}
          link={discountPages.StayLongPayLessActions.link}
        />
        <CustomDiscountCard
          color={"gray"}
          title={discountPages.NightRestrictions.title}
          info={discountPages.NightRestrictions.info}
          icon={discountPages.NightRestrictions.icon}
          link={discountPages.NightRestrictions.link}
        />
        <CustomDiscountCard
          color={"indigo"}
          title={discountPages.UncancellableRoomRates.title}
          info={discountPages.UncancellableRoomRates.info}
          icon={discountPages.UncancellableRoomRates.icon}
          link={discountPages.UncancellableRoomRates.link}
        />
        <CustomDiscountCard
          color={"rose"}
          title={discountPages.DiscountCodes.title}
          info={discountPages.DiscountCodes.info}
          icon={discountPages.DiscountCodes.icon}
          link={discountPages.DiscountCodes.link}
        />
        <CustomDiscountCard
          color={"purple"}
          title={discountPages.UnderTheDeskDiscounts.title}
          info={discountPages.UnderTheDeskDiscounts.info}
          icon={discountPages.UnderTheDeskDiscounts.icon}
          link={discountPages.UnderTheDeskDiscounts.link}
        />
        {/* <UncancellableRoomRatesCard/> */}
        {/* <DiscountCodesCard/> */}
        {/* <UnderTheDeskDiscountsCard/> */}
      </div>
    </>
  );
};

export default withPageNeedsHotel(DiscountsPage);
