import { FacilityImagesPage } from "Components/Custom/FacilityPages";
import useFacilityImages from "Components/Hooks/facility/useFacilityImages";
import PageContainer from "pages/PageContainer";

const FacilityImages = () => {
  const { loading, error, isValidHotel, isLoading } = useFacilityImages();

  return (
    <PageContainer title="Tesis Resimleri">
      <FacilityImagesPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default FacilityImages;
