import { createSlice } from "@reduxjs/toolkit";
import { TransferReservationResponse } from "types/userOperations";

export interface TransferReservationsInitialState {
  formChoices: TransferReservationResponse[];
  isLoading: boolean;
  error: string;
  transferReservationLoading: boolean;
  transferReservationError: string;
}

export const transferReservationsInitialState: TransferReservationsInitialState =
  {
    formChoices: [],
    isLoading: false,
    error: "",
    transferReservationLoading: false,
    transferReservationError: "",
  };

const transferReservationsSlice = createSlice({
  name: "transferReservations",
  initialState: transferReservationsInitialState,
  reducers: {
    handleSaveFormChoicesResponse(state, action) {
      state.formChoices = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setTransferReservationLoading(state, action) {
      state.transferReservationLoading = action.payload;
    },
    setTransferReservationError(state, action) {
      state.transferReservationError = action.payload;
    },
  },
});

export const {
  handleSaveFormChoicesResponse,
  setIsLoading,
  setError,
  setTransferReservationLoading,
  setTransferReservationError,
} = transferReservationsSlice.actions;
export default transferReservationsSlice.reducer;
