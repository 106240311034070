import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { setIsLoading, setError, resetPlaceToVisit } from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { ADD_PLACE_TO_VISIT } from "helpers/url_helper";

import { AddPlaceToVisitRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

export const addPlaceToVisit =
  (payload: AddPlaceToVisitRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ADD_PLACE_TO_VISIT, payload);

      dispatch(setToastSuccess("Gezilecek yer eklendi."));
      dispatch(resetPlaceToVisit());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
