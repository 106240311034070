import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/createNotification/reducer";
import { CreateNotificationFormErrors, CreateNotificationPayload } from "types/userOperations";

interface NotificationTitleInputProps {
  values: CreateNotificationPayload;
  formErrors: CreateNotificationFormErrors;
  validateFormChange: (values: CreateNotificationPayload) => void;
}

const NotificationTitleInput = ({
  values,
  formErrors,
  validateFormChange,
}: NotificationTitleInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Bildirim Başlığı : "
        error={formErrors.baslik}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.baslik}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                baslik: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              baslik: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      
    </>
  );
};

export default NotificationTitleInput;
