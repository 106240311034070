import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleSaveAddMoneyPointFormErrors } from "slices/reports/moneyPoints/reducer";
import { setToastError } from "slices/toast/reducer";

import { AddMoneyPointFormErrors, AddMoneyPointState } from "types/reports";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddMoneyPointState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddMoneyPointFormErrors = {};

    if (!values.operator_kodu) {
      errors.operator_kodu = "Operator kodu seçiniz.";
    }
    if (!values.parapuan_tutar) {
      errors.parapuan_tutar = "Para puan tutarını giriniz.";
    }

    dispatch(handleSaveAddMoneyPointFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddMoneyPointState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};
export default useValidateForm;
