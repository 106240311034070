import { Spinner } from "reactstrap";

interface CustomFormContainerProps {
  children: React.ReactNode;
  title?: string;
  lg?: number;
  xl?: number;
  isLoading?: boolean;
}

const CustomFormContainer = ({
  children,
  title,
  isLoading,
}: CustomFormContainerProps) => {
  return (
    <section className="d-flex flex-column gap-3 border rounded theme-bg-light">
      {title && (
        <span className="fs-16 fw-bold border py-2 px-3 bg-secondary text-white rounded-top">
          {title}
        </span>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center py-4">
          <Spinner size="sm" color="primary" />
        </div>
      ) : (
        <div className="d-flex flex-column gap-3 p-3">{children}</div>
      )}
    </section>
  );
};

export default CustomFormContainer;
