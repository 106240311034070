import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListComments, ListCommentsResponse } from "types/comments";
import { Pagination } from "types/GlobalTypes";

/*
 *Bu datalar ListCommentsTable için.
 *Tablo ile bir modal açılıp sadece "yorum_olumlu" ve "yorum_olumsuz" düzenlenebilecek.
 *Tablonun üstünde "giris_tarihi" ve "cikis_tarihi"ne göre filtereleme yapılacak.
 *Yorumlar silinebilecek.

 *@using (HotelContractRulesTable) (finans&muhasebe > kontrat kuralları) as template
 */

export interface ListCommentsInitialState {
  comments: ListComments[];
  commentsResponse: ListCommentsResponse;
  pagination: Pagination;
  start_date: string;
  end_date: string;
  personel: string;
  isLoading: boolean;
  error: string;
  updateCommentLoading: boolean;
  updateCommentError: string;
  deleteCommentLoading: boolean;
  deleteCommentError: string;
  approveCommentLoading: boolean;
  approveCommentError: string;
}

export const listCommentsInitialState: ListCommentsInitialState = {
  comments: [],
  commentsResponse: {
    data: [],
    sabit_datalar: {},
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  start_date: "",
  end_date: "",
  personel: "0",
  isLoading: false,
  error: "",
  updateCommentLoading: false,
  updateCommentError: "",
  deleteCommentLoading: false,
  deleteCommentError: "",
  approveCommentLoading: false,
  approveCommentError: "",
};

const listCommentsSlice = createSlice({
  name: "listComments",
  initialState: listCommentsInitialState,
  reducers: {
    handleSaveCommentResponse(state, action) {
      const { data, ...rest } = action.payload;
      state.commentsResponse = action.payload;
      state.comments = action.payload.data;
      state.pagination = { ...rest };
    },
    handleUpdateComment(state, action) {
      const updatedComment = action.payload;
      state.comments = state.comments.map((comment) =>
        comment.yorum_id === updatedComment.yorum_id ? updatedComment : comment
      );
    },
    handleDeleteComment(state, action) {
      console.log("Reducer received action:", action);
      const yorum_id = action.payload;
      state.comments = state.comments.filter(
        (comment) => comment.yorum_id !== yorum_id
      );
    },
    handleApproveComment(state, action) {

      const approvedComment = action.payload;
      state.comments = state.comments.map((comment) =>
        comment.yorum_id === approvedComment.yorum_id ? approvedComment : comment
      );
    },
    handleChangeDateInput(state, action) {
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
    },
    handleCommenterChange(state, action) {
      state.personel = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateCommentLoading(state, action) {
      state.updateCommentLoading = action.payload;
    },
    setUpdateCommentError(state, action) {
      state.updateCommentError = action.payload;
    },
    setDeleteCommentLoading(state, action) {
      state.deleteCommentLoading = action.payload;
    },
    setDeleteCommentError(state, action) {
      state.deleteCommentError = action.payload;
    },
    setApproveCommentLoading(state, action) {
      state.approveCommentLoading = action.payload;
    },
    setApproveCommentError(state, action) {
      state.approveCommentError = action.payload;
    },
  },
});

export const {
  handleSaveCommentResponse,
  handleUpdateComment,
  handleDeleteComment,
  handleApproveComment,
  handleChangeDateInput,
  handleCommenterChange,
  setIsLoading,
  setError,
  setUpdateCommentLoading,
  setUpdateCommentError,
  setDeleteCommentLoading,
  setDeleteCommentError,
  setApproveCommentLoading,
  setApproveCommentError
} = listCommentsSlice.actions;

export default listCommentsSlice.reducer;
