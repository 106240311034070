import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelectors from "./DateSelectors";
import ApplicableDays from "./ApplicableDays";
import Switches from "./Switches";
import SelectFields from "./SelectFields";
import AddNewActionButton from "./AddNewActionButton";
import StayAndPayNightsSelector from "./StayAndPayNightsSelector";
import UpdateActionButton from "./UpdateActionButton";

interface StayLongPayLessActionsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const StayLongPayLessActionsForm = (props: StayLongPayLessActionsFormProps) => {
  const {
    stayLongPayLessAction,
    conceptsOptions,
    paymentMethodOptions,
    addNewStayLongPayLessActionRequestPayload,
    updateStayLongPayLessActionRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addActionLoading,
    updateActionLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <DateSelectors
        values={stayLongPayLessAction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <StayAndPayNightsSelector
        values={stayLongPayLessAction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ApplicableDays
        values={stayLongPayLessAction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <Switches values={stayLongPayLessAction} />
      <SelectFields
        values={stayLongPayLessAction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        conceptsOptions={conceptsOptions}
        paymentMethodOptions={paymentMethodOptions}
      />
      {stayLongPayLessAction.id ? (
        <UpdateActionButton
          values={stayLongPayLessAction}
          requestPayload={updateStayLongPayLessActionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateActionLoading}
        />
      ) : (
        <AddNewActionButton
          values={stayLongPayLessAction}
          requestPayload={addNewStayLongPayLessActionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addActionLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default StayLongPayLessActionsForm;
