import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";

import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";

interface FacilityCalculationSupplierProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  priceCalculationSupplierOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityCalculationSupplier = ({
  values,
  formErrors,
  validateFormChange,
  priceCalculationSupplierOptions,
}: FacilityCalculationSupplierProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Fiyat Hesaplama Tedarikçi"
        // error={formErrors.fiyat_hesaplama}
      >
        <Select
          options={priceCalculationSupplierOptions}
          value={priceCalculationSupplierOptions.find(
            (option) => option.value === values.fiyat_hesaplama
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                fiyat_hesaplama: selected.value,
              })
            );
            validateFormChange({
              ...values,
              fiyat_hesaplama: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityCalculationSupplier;
