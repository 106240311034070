import { CustomFormFieldContainer } from "Components/Custom/UI";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { ChildGeneralInformation, ChildInformation } from "types/PricesQuotas";
import {
  handleChangeInput,
  handleSingleGeneralInformation,
  handleChangeChildInformationInput,
} from "slices/pricesAndQuota/childInformation/reducer";

interface ChildAgeRangesProps {
  childInformationState: ChildInformation;
  childSingleGeneralInformation: ChildGeneralInformation[];
  childMultipleGeneralInformation: ChildGeneralInformation[];
  id: number;
  otel_id: number;
  oda_id: number;
  otel_oda_id: number;
  tip: string;
  ozel_yas_id: number;
  cocuk: string;
  cocuk_kabul: string;
  cocuk_kabul2: string;
  ucretsiz_yas_baslangic: string;
  ucretsiz_yas_bitis: string;
  ucretli_yas_baslangic: string;
  ucretli_yas_bitis: string;
  getChildAgeInformation: (
    value: number,
    key: string,
    state: ChildGeneralInformation
  ) => ChildGeneralInformation;
  isClicked: string;
  setIsClicked: React.Dispatch<React.SetStateAction<string>>;
}

const ChildAgeRanges = ({
  childInformationState,
  childSingleGeneralInformation,
  childMultipleGeneralInformation,
  id,
  otel_id,
  oda_id,
  otel_oda_id,
  tip,
  ozel_yas_id,
  cocuk,
  cocuk_kabul,
  cocuk_kabul2,
  ucretsiz_yas_baslangic,
  ucretsiz_yas_bitis,
  ucretli_yas_baslangic,
  ucretli_yas_bitis,
  getChildAgeInformation,
  isClicked,
  setIsClicked,
}: ChildAgeRangesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleChildAgeChange = (value: number, key: string) => {
    isClicked === "0"
      ? dispatch(
          handleChangeChildInformationInput(
            childSingleGeneralInformation.map((child) => {
              if (child.cocuk === cocuk) {
                return getChildAgeInformation(value, key, child);
              }

              return child;
            })
          )
        )
      : dispatch(
          handleChangeChildInformationInput(
            childMultipleGeneralInformation.map((child) => {
              if (child.cocuk === cocuk) {
                return getChildAgeInformation(value, key, child);
              }

              return child;
            })
          )
        );
  };

  return (
    <>
      <div>
        <h6>{cocuk}.Çocuk Yaş Aralıkları</h6>
      </div>
      <CustomFormFieldContainer
        label="Ücretsiz Kabul Ediliyor"
        tooltipId="childFreeAgeRanges"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={cocuk_kabul === "1"}
            onChange={() => {
              dispatch(
                handleSingleGeneralInformation({
                  cocuk_bilgileri: childSingleGeneralInformation.map(
                    (child) => {
                      if (child.cocuk === cocuk) {
                        return {
                          ...child,
                          cocuk_kabul:
                            child.cocuk_kabul.toString() === "1" ? "0" : "1",
                        };
                      }
                      return child;
                    }
                  ),
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Ücretli Kabul Ediyor"
        tooltipId="childPaidAgeRanges"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={cocuk_kabul2 === "1"}
            onChange={() => {
              dispatch(
                handleSingleGeneralInformation({
                  cocuk_bilgileri: childSingleGeneralInformation.map(
                    (child) => {
                      if (child.cocuk === cocuk) {
                        return {
                          ...child,
                          cocuk_kabul2:
                            child.cocuk_kabul2.toString() === "1" ? "0" : "1",
                        };
                      }
                      return child;
                    }
                  ),
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Ücretsiz ">
        <div className="d-flex align-items-center gap-4">
          <div>
            <CustomNumberSelector
              step={0}
              min={0}
              max={0}
              value={parseInt(ucretsiz_yas_baslangic)}
              onChange={() => {}}
              disabled={true}
            />
          </div>
          -
          <div>
            <CustomNumberSelector
              step={1}
              min={0}
              max={16}
              value={parseInt(ucretsiz_yas_bitis)}
              onChange={(value) => {
                handleChildAgeChange(value, "ucretsiz_yas_bitis");
              }}
              disabled={true}
            />
          </div>
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Ücretli ">
        <div className="d-flex align-items-center gap-4 mt-2">
          <div>
            <CustomNumberSelector
              step={1}
              min={0}
              max={17}
              value={parseInt(ucretli_yas_baslangic)}
              onChange={(value) => {
                handleChildAgeChange(value, "ucretli_yas_baslangic");
              }}
              disabled={true}
            />
          </div>
          -
          <div>
            <CustomNumberSelector
              step={1}
              min={0}
              max={18}
              value={parseInt(ucretli_yas_bitis)}
              onChange={(value) => {
                handleChildAgeChange(value, "ucretli_yas_bitis");
              }}
              disabled={true}
            />
          </div>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default ChildAgeRanges;
