import { Col } from "reactstrap";

interface RowContainerProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  index: number;
}

const RowContainer = ({ title, children, index }: RowContainerProps) => {
  return (
    <div
      className="w-100 d-flex justify-content-between p-0"
      style={{ backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#fff" }}
    >
      <Col xs={3} md={4} className="border d-flex align-items-center ps-2">
        {typeof title === "string" ? (
          <p className="m-0 fs-14 fw-semibold ">{title}</p>
        ) : (
          title
        )}
      </Col>
      <Col xs={9} md={8} className="d-flex justify-content-start">
        {children}
      </Col>
    </div>
  );
};

export default RowContainer;
