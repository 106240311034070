import { CustomLoadingPage } from "Components/Custom/UI";
import AddNewThemeForm from "./AddNewThemeForm";

interface AddNewThemePageProps {
  isLoading: boolean;
}

const AddNewThemePage = ({ isLoading }: AddNewThemePageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <AddNewThemeForm />
    </>
  );
};

export default AddNewThemePage;
