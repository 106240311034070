import { CustomFormContainer } from "Components/Custom/UI";
import useHandleCounts from "./useHandleCounts";
import EmployeeCountCard from "./EmployeeCountCard";

interface EmployeeCountsProps {
  isLoading: boolean;
}

const EmployeeCounts = ({ isLoading }: EmployeeCountsProps) => {
  const { firstGroupCounts, secondGroupCounts } = useHandleCounts();

  return (
    <CustomFormContainer title="Personel CC Sayısı">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-wrap gap-2 justify-center border-bottom pb-4">
          {firstGroupCounts.map((count, index) => (
            <EmployeeCountCard
              key={index}
              ad_soyad={count.ad_soyad}
              order={count.order}
              otel_sayisi={count.otel_sayisi}
            />
          ))}
        </div>

        <div className="d-flex flex-wrap gap-2 justify-center">
          {secondGroupCounts.map((count, index) => (
            <EmployeeCountCard
              key={index}
              ad_soyad={count.ad_soyad}
              order={count.order}
              otel_sayisi={count.otel_sayisi}
            />
          ))}
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default EmployeeCounts;
