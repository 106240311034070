import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  RoomConceptMatchInitialState,
  handleSaveSupplierRooms,
  handleSaveSelectedSupplierRooms,
  handleSaveInetRooms,
} from "slices/facility/roomConceptMatch/reducer";
import {
  AddRoomMatchRequestPayload,
  RemoveRoomMatchRequestPayload,
} from "types/facility";
import { HotelInitialState } from "slices/hotel/reducer";
import {
  addRoomMatch,
  removeRoomMatch,
  getRoomConceptMatchList,
} from "slices/facility/roomConceptMatch/thunk";
import { useEffect, useState } from "react";

interface Item {
  id: string;
  text: string;
}

const useHandleList = () => {
  const { supplierRooms, selectedSupplierRooms, inetRooms } = useSelector(
    (state: { RoomConceptMatch: RoomConceptMatchInitialState }) =>
      state.RoomConceptMatch
  );

  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const selectedSupplierRoomsLength = selectedSupplierRooms.length;

  const [matchStatus, setMatchStatus] = useState(
    Array.from({ length: selectedSupplierRoomsLength }).map((_, index) => ({
      order: index + 1,
      status: selectedSupplierRooms[index].id === inetRooms[index].id,
    }))
  );

  useEffect(() => {
    const length = selectedSupplierRooms.length;
    setMatchStatus(
      Array.from({ length }).map((_, index) => ({
        order: index + 1,
        status: selectedSupplierRooms[index].id === inetRooms[index].id,
      }))
    );
  }, [selectedSupplierRooms, inetRooms]);

  const handleAddRoomMatch = (selectedSupplierRoom: Item, inetRoom: Item) => {
    const payload: AddRoomMatchRequestPayload = {
      otel_id,
      oda_id: Number(inetRoom.id),
      tedarikci_id: Number(selectedSupplierRoom.id),
      tedarikci_ad: selectedSupplierRoom.text,
    };

    dispatch(addRoomMatch(payload));
    setTimeout(() => {
      dispatch(getRoomConceptMatchList(otel_id));
    }, 1000);
  };

  const handleRemoveRoomMatch = (inetRoom: Item) => {
    const payload: RemoveRoomMatchRequestPayload = {
      otel_id,
      oda_id: Number(inetRoom.id),
      tedarikci_id: null,
      tedarikci_ad: null,
    };

    dispatch(removeRoomMatch(payload));
    setTimeout(() => {
      dispatch(getRoomConceptMatchList(otel_id));
    }, 1000);
  };

  const handleChangeSupplierRooms = (rooms: Item[]) => {
    dispatch(handleSaveSupplierRooms(rooms));
  };

  const handleChangeSelectedSupplierRooms = (rooms: Item[]) => {
    dispatch(handleSaveSelectedSupplierRooms(rooms));
  };

  const handleChangeInetRooms = (rooms: Item[]) => {
    dispatch(handleSaveInetRooms(rooms));
  };

  const checkItemIsMatched = (item: Item) => {
    const itemIsMatched = inetRooms.some(
      (inetRoom) => Number(inetRoom.id) === Number(item.id)
    );

    return {
      status: itemIsMatched,
      errorMessage: itemIsMatched ? "Önce eşleşmeyi kaldırın!" : "",
    };
  };

  return {
    supplierRooms,
    selectedSupplierRooms,
    inetRooms,
    handleChangeSupplierRooms,
    handleChangeSelectedSupplierRooms,
    handleChangeInetRooms,
    onAddRoomMatch: handleAddRoomMatch,
    onRemoveRoomMatch: handleRemoveRoomMatch,
    matchStatus,
    checkItemIsMatched,
  };
};

export default useHandleList;
