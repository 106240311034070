import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import { getComments } from "slices/comments/listComments/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

/*
  *@commenterOptions ->  value: personel, label: "Müşteri" || "Personel"
*/


const useAddComment = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  useEffect(() => {
    if (otel_id) {
      dispatch(
        getComments({
          otel_id,
          page: 1,
          per_page: 10,
          start_date: "",
          end_date: "",
          personel: "",
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    isLoading,
    error,
    isValidHotel,
  };
};

export default useAddComment;
