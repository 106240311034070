import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface IssuedInvoicesTableProps {
  isLoading: boolean;
}
const IssuedInvoicesTable = ({ isLoading }: IssuedInvoicesTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Kesilen Faturalar">
      <CustomReactTable
        fileName="Kesilen Faturalar"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
        legend={legend}
      />
    </CustomFormContainer>
  );
};

export default IssuedInvoicesTable;
