import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  updateUncancellableRoomRate,
  getUncancellableRoomRates,
} from "slices/discountsAndActions/uncancellableRoomRates/thunk";
import {
  UncancellableRoomRateRequestPayload,
  UncancellableRoomRateState,
} from "types/DiscountsAndActions";

interface UpdateRateButtonProps {
  values: UncancellableRoomRateState;
  requestPayload: UncancellableRoomRateRequestPayload;
  validateForm: (values: UncancellableRoomRateState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const UpdateRateButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: UpdateRateButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateUncancellableRoomRate = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateUncancellableRoomRate(requestPayload, () => {
          dispatch(getUncancellableRoomRates(hotelAndSelectedRoom));
          handleSubmitted(false);
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleUpdateUncancellableRoomRate}
        isLoading={isLoading}
      >
        Oda Oranı Ekle / Düzenle
      </CustomButton>
    </div>
  );
};

export default UpdateRateButton;
