import { Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import { WEEKDAY_OPTIONS } from "utils/weekdays";
import {
  handleIsAllDaysSelected,
  handleSelectedDates,
  handleSelectedDays,
} from "slices/pricesAndQuota/basicPriceEntry/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface PriceEntryDateRangeSelectorProps {
  selectedDates: {
    startDate: Date;
    endDate: Date;
  };
  selectedDays: number[];
  isAllDaysSelected: boolean;
}

const PriceEntryDateRangeSelector = ({
  selectedDates,
  selectedDays,
  isAllDaysSelected,
}: PriceEntryDateRangeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <Row className="p-2 border rounded theme-bg-light">
      <Col md={7} lg={6}>
        <Row className="align-items-end">
          <Col md={7} lg={6} className="d-flex align-items-start">
            <div className="flex-grow-1">
              <Label className="form-label">Tarih Aralığı</Label>
              <Flatpickr
                className="form-control"
                value={[selectedDates.startDate, selectedDates.endDate]}
                onChange={(dates) => {
                  const [startDate, endDate] = dates;
                  if (startDate && endDate) {
                    const stringStartDate =
                      moment(startDate).format("YYYY-MM-DD");
                    const stringEndDate = moment(endDate).format("YYYY-MM-DD");
                    dispatch(
                      handleSelectedDates({
                        startDate: stringStartDate,
                        endDate: stringEndDate,
                      })
                    );
                  }
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  showMonths: 2,
                  locale: Turkish,
                  onDayCreate,
                }}
              />
            </div>
          </Col>
          <Col md={5} lg={6}>
            <div className="h-100 d-flex align-items-start mt-4 mt-md-0">
              <div className="form-check form-switch">
                <Input
                  id="all-days-switch"
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={isAllDaysSelected}
                  onChange={(e) => {
                    dispatch(handleIsAllDaysSelected(e.target.checked));
                  }}
                />
                <Label className="form-check-label" htmlFor="all-days-switch">
                  Tüm Günler
                </Label>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      {!isAllDaysSelected && (
        <Col md={5} lg={6}>
          <div className="flex-grow-1 mt-4 mt-md-0">
            <Label
              htmlFor="choices-multiple-default"
              className="form-label text-muted"
            >
              Seçili Günler
            </Label>
            <Select
              value={selectedDays.map((day: any) => {
                return WEEKDAY_OPTIONS.find((item) => item.value === day);
              })}
              isMulti={true}
              onChange={(selectedMulti: any) => {
                dispatch(handleSelectedDays(selectedMulti));
              }}
              options={WEEKDAY_OPTIONS}
              placeholder="Gün Seçiniz..."
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default PriceEntryDateRangeSelector;
