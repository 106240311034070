import { CustomFormContainer } from "Components/Custom/UI";
import FoodAndDrinkInformationImage from "./FoodAndDrinkInformationImage";
import FoodAndDrinkInformationText from "./FoodAndDrinkInformationText";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";

interface FoodAndDrinkInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const FoodAndDrinkInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: FoodAndDrinkInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Yiyecek & İçecek Bilgileri" lg={12} xl={12}>
        <FoodAndDrinkInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <FoodAndDrinkInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default FoodAndDrinkInformationFields;
