import moment from "moment";
import FeaturedImages from "./FeaturedImages";
import { CalculatePriceForHotelImage } from "types/reservation";

interface DetailsHeaderProps {
  title: {
    name: string;
    location: string;
  };
  subtitle: {
    giris_tarihi: string;
    cikis_tarihi: string;
    yetiskin_sayisi: string;
    cocuk_sayisi: number;
    gece_sayisi: number;
    cocuk_yas: number[];
  };
  featuredImages: CalculatePriceForHotelImage[];
}

const DetailHeaderRow = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <span className="d-flex align-items-center gap-2 ">
      <span className="fs-14 fw-medium text-decoration-underline">{title}</span>
      <span className="fw-bold">{value}</span>
    </span>
  );
};

const DetailsHeader = ({
  title,
  subtitle,
  featuredImages,
}: DetailsHeaderProps) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center gap-4 theme-bg-primary py-2 px-2 text-white rounded-top">
        <span className="fs-18 fw-semibold">
          <i className="ri-hotel-line me-1 fs-20" />
          {title.name}
        </span>
        <span className="m-0">{title.location}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-2 bg-secondary bg-opacity-25 py-2 px-4 text-dark">
        <DetailHeaderRow
          title="Giriş Tarihi:"
          value={moment(subtitle.giris_tarihi).format("DD MMMM YYYY")}
        />
        <DetailHeaderRow
          title="Çıkış Tarihi:"
          value={moment(subtitle.cikis_tarihi).format("DD MMMM YYYY")}
        />
        <DetailHeaderRow
          title="Yetişkin Sayısı:"
          value={subtitle.yetiskin_sayisi}
        />
        <DetailHeaderRow
          title="Çocuk Sayısı:"
          value={subtitle.cocuk_sayisi.toString()}
        />
        <DetailHeaderRow
          title="Gece Sayısı:"
          value={subtitle.gece_sayisi.toString() + " gece"}
        />
        <DetailHeaderRow
          title="Çocuk Yaşları:"
          value={subtitle.cocuk_yas.join(", ")}
        />
      </div>
      <FeaturedImages images={featuredImages} />
    </div>
  );
};

export default DetailsHeader;
