import { CustomLoadingPage } from "Components/Custom/UI";
import UpdateGroupForm from "./UpdateGroupForm";

interface UpdateGroupPageProps {
  isLoading: boolean;
}

const UpdateGroupPage = ({ isLoading }: UpdateGroupPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />      
        <div className="d-flex flex-column gap-4">
          <UpdateGroupForm />
        </div>
    </>
  );
};

export default UpdateGroupPage;
