import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { CreateWebSiteFormChoices, CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
import Select from "react-select";

interface WebSiteThemesProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}

interface Option {
  value: string;
  label: string;
}

const WebSiteThemes = ({
  values,
  formChoices,
  validateFormChange,
  formErrors,
}: WebSiteThemesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const themeOptions = formChoices.temalar.map((theme) => ({
    value: theme.tema_id.toString(),
    label: theme.tema_adi,
  }));

  const selectedOption = themeOptions.find((option) => Number(option.value) === values.tema_id);

  // console.log("tema options : ", themeOptions)

  return (
    <>
      <CustomFormFieldContainer
        label="Temalar :"
        error={formErrors.tema_id}
        divider
        required
      >
        <Select
          options={themeOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            if (selected) {
              dispatch(handleChangeInput({ ...values, tema_id: Number(selected.value) }));
              validateFormChange({ ...values, tema_id: Number(selected.value) });
            };
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default WebSiteThemes;
