import { useSelector } from "react-redux";
import { DailyReservationsInitialState } from "slices/reports/dailyReservations/reducer";
import { CHART_COLORS } from "utils/chartColors";

const useHandleChart = () => {
  const { dailyReservationsResponse } = useSelector(
    (state: { DailyReservations: DailyReservationsInitialState }) =>
      state.DailyReservations
  );

  const { bekleyen_tutar, iptal_tutar, tamamlanan_tutar, toplam_rez } =
    dailyReservationsResponse;

  const labels = ["Bekleyen Tutar", "İptal Edilen Tutar", "Tamamlanan Tutar"];

  const series = [bekleyen_tutar, iptal_tutar, tamamlanan_tutar];
  const colors = [
    CHART_COLORS.WARNING,
    CHART_COLORS.DANGER,
    CHART_COLORS.SUCCESS,
  ];

  const title = "Toplam Rezervasyon: " + toplam_rez;

  return { labels, series, colors, title };
};

export default useHandleChart;
