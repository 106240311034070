import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveWelcomeAndCalculation,
  handleSaveUpdateWelcomeAndCalculation,
  resetUpdateWelcomeAndCalculationState,
  setIsLoading,
  setError,
  setUpdateFormLoading,
  setUpdateFormError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_WELCOME_AND_CALCULATION_LIST,
  GET_WELCOME_AND_CALCULATION,
  UPDATE_WELCOME_AND_CALCULATION,
} from "helpers/url_helper";

import { UpdateWelcomeAndCalculationRequestPayload } from "types/reports";

import { Dispatch } from "redux";

interface GetWelcomeAndCalculationParams {
  otel_id: number;
}

export const getWelcomeAndCalculationList =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_WELCOME_AND_CALCULATION_LIST);

      dispatch(handleSaveWelcomeAndCalculation(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getWelcomeAndCalculation =
  (params: GetWelcomeAndCalculationParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateFormLoading(true));
      const response = await axios.get(GET_WELCOME_AND_CALCULATION, {
        params,
      });

      dispatch(
        handleSaveUpdateWelcomeAndCalculation(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateFormError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateFormLoading(false));
    }
  };

export const updateWelcomeAndCalculationData =
  (payload: UpdateWelcomeAndCalculationRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateFormLoading(true));
      await axios.post(UPDATE_WELCOME_AND_CALCULATION, payload);

      dispatch(resetUpdateWelcomeAndCalculationState());
      dispatch(setToastSuccess("Kayıt güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateFormError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateFormLoading(false));
    }
  };
