export const BASIC_PRICE_ENTRY_DESCRIPTIONS = [
  "Bu alandan bir yıllık tarih aralığına tek seferde fiyat girişi yapabilirsiniz.",
  "Sadece giriş yaptığınız fiyatlar değiştirilecektir. Diğer fiyatlar üzerinde değişiklik yapmaz.",
  "DİKKAT: Yapacağınız düzenlemeler, daha önce girilmiş olan fiyatların üzerine yazılarak eski verilerin kaybolmasına neden olacaktır.",
];

export const BASIC_AVAILABILITY_ENTRY_DESCRIPTIONS = [
  "Bu alandan bir yıllık tarih aralığına tek seferde müsaitlik düzenlemesi yapabilirsiniz.",
  "Sadece giriş yaptığınız müsaitlikler değiştirilecektir. Diğer müsaitlikler üzerinde değişiklik yapmaz.",
  "DİKKAT: Yapacağınız düzenlemeler, daha önce girilmiş olan müsaitliklerin üzerine yazılarak eski verilerin kaybolmasına neden olacaktır.",
];

export const BASIC_QUOTA_ENTRY_DESCRIPTIONS = [
  "Bu alandan bir yıllık tarih aralığına tek seferde kontenjan girişi yapabilirsiniz.",
  "Sadece giriş yaptığınız kontenjanlar değiştirilecektir. Diğer kontenjanlar üzerinde değişiklik yapmaz.",
  "DİKKAT: Yapacağınız düzenlemeler, daha önce girilmiş olan kontenjanların üzerine yazılarak eski verilerin kaybolmasına neden olacaktır.",
];

export const SPECIAL_DISCOUNTS_DESCRIPTIONS = [
  "Bu alandaki indirimler, Otelfiyat.com misafirlerine Extra İndirim şeklinde sunulduğu için bulunduğunuz il ve ilçe sıralamalarında, misafirlerin yoğun ilgisini çekmektedir.",
  "İndirim tarihlerini ne kadar uzun tutarsanız, indirimleri kullanacak misafir sayısı da aynı oranda çoğalacaktır.",
  "Otelfiyat.com sayfa ziyaretçilerine otelinizi tercih etmeleri için mutlaka Özel indirim oranları eklemenizi tavsiye ediyoruz.",
];

export const MOBILE_DISCOUNTS_DESCRIPTIONS = [
  "Bu alandaki indirimler, Otelfiyat.com misafirlerine Extra İndirim şeklinde sunulduğu için bulunduğunuz il ve ilçe sıralamalarında, misafirlerin yoğun ilgisini çekmektedir.",
  "İndirim tarihlerini ne kadar uzun tutarsanız, indirimleri kullanacak misafir sayısı da aynı oranda çoğalacaktır.",
  "Otelfiyat.com sayfa ziyaretçilerine otelinizi tercih etmeleri için mutlaka Özel indirim oranları eklemenizi tavsiye ediyoruz.",
];

export const BREAKING_DISCOUNTS_DESCRIPTIONS = [
  "Sadece Bugün yada Yarın için rezervasyon yapmak isteyen misafirlere sunulur.",
  "Diğer tarihleri kapsamadığı için Bugün yada Yarın için doluluklarınızı hızlıca artırabileceğiniz bir indirim oranıdır.",
  "Sistemdeki tesislerin %80’i Son Dakika İndirimlerini etkin olarak kullanmaktadır.",
  "Sisteme 1 kez tanımlama yapmanız yeterlidir otomatik olarak yıl boyu o gün ve ertesi gün girişli rezervasyonlar için uzatılmaktadır.",
];

export const BREAKING_DISCOUNTS_DESCRIPTIONS_INFO = [
  "Bugün ve yarın girişli rezervasyonlarınız için aktif olduğu müddetçe konuklar bu indirimden faydalanır. ",
  "Tüm indirimlere ilave olarak uygulanır.",
];

export const HOURLY_DISCOUNTS_DESCRIPTIONS = [
  "Bu alandaki indirimler, Otelfiyat.com misafirlerine Extra İndirim şeklinde sunulduğu için bulunduğunuz il ve ilçe sıralamalarında, misafirlerin yoğun ilgisini çekmektedir.",
  "İndirim tarihlerini ne kadar uzun tutarsanız, indirimleri kullanacak misafir sayısı da aynı oranda çoğalacaktır.",
  "Otelfiyat.com sayfa ziyaretçilerine otelinizi tercih etmeleri için mutlaka Saatlik indirim oranları eklemenizi tavsiye ediyoruz.",
];

export const UNCANCELLED_ROOM_RATES_DESCRIPTIONS = [
  "İptal edilemez indirim tipini bir oran belirleyerek aktif etmeniz halinde, tüm satış ekranlarında iptal edilemez ödeme seçeneği oluşacaktır.",
  "Uygulayacağınız indirim oranı ne kadar yüksek olursa satacağınız oda sayısı artacaktır.",
  "Gireceğiniz indirim oranı 1-100 arasında olmalıdır.",
];
export const UNCANCELLED_ROOM_RATES_DESCRIPTIONS_WARNING = [
  "Yapacağınız güncelle işlemlerinde tüm iptal edilemez fiyatları değişecektir.",
];

export const EARLY_RESERVATION_DISCOUNTS_DESCRIPTIONS = [
  "Bu alana tanımlayacağınız indirimlerle İlerleyen dönemlerde gelecek rezervasyon sayılarınızı artırabilirsiniz.",
  "Misafirlerinizin karar verme sürecini Erken Rezervasyon indirimi tanımlayarak hızlandırabilirsiniz.",
  "Tesislerimiz sisteme ortalama olarak %10 ile %30 arasında değişen Erken Rezervasyon indirimleri tanımlamaktadır.",
];

export const STAY_LONG_PAY_LESS_DESCRIPTIONS = [
  "Ortalama konaklama sürenizi artırmak için, en kolay ekleyebileceğiniz indirim tanımlamasıdır.",
  "Misafirlerinizin otelinizde daha uzun geceleme yapmasını sağlar.",
  "Hediye Geceleme uyguladığı için, özellikle uzun konaklama yapacak misafirlerin, bölgenizdeki diğer tesisler arasından sizi seçmesine yardımcı olur.",
];

export const GIFTS_AND_PROMOTIONS_DESCRIPTIONS = [
  "Odalarınızda veya tesisinizde misafirleriniz için özel hediyeler sunun, tercih edilmeniz kolaylaşsın.",
];

export const NIGHT_RESTRICTIONS_DESCRIPTIONS = [
  "Geceleme Paketleri için paket tarihleri aynı olan 1 geceleme de girilmelidir.",
  "Minimum Geceleme Kuralı varsa 1 geceleme girilmemelidir.",
];

export const ADD_FACILITY_DESCRIPTIONS = [
  " Otelin daha önce açılıp açılmadığını yukarıdan LÜTFEN KONTROL EDİNİZ!",
  "Listede otel çıkıyor ise; tekrar otel açmayınız, 'Tesis Düzenle' sayfasından oteli seçip güncelleme yapınız !",
];

export const ADD_FACILITY_PAGE_MODAL_DESCRIPTION = [
  "<p>Otelin daha önce açılıp açılmadığını yukarıdan <strong>LÜTFEN KONTROL EDİNİZ!</strong></p>",
  "<p>Listede otel çıkıyor ise; tekrar otel açmayınız, <strong>'Tesis Düzenle'</strong> sayfasından oteli seçip güncelleme yapınız!</p>",
];

export const FACILITY_THEME_WARNING_DESCRIPTIONS = [
  "Bu kısım ÖNEMLİDİR. Doğru işaretleme yapıldığına Dikkat Ediniz !",
  "İşaretleme yapamadığınız temalar, tesis özellikleri ile eş zamanlı çalışmaktadır. Ekleme veya çıkarma yapmak için o temanın özelliklerini seçmeniz gerekir.",
  "Tema - Özellik Eşleşmeleri için tıklayınız.",
];

export const FACILITY_PULL_IMAGES_FORM_URL_DESCRIPTIONS = [
  "Resimleri çekmek için yazmanız gereken örnek link: https://www.etstur.com/Club-Cactus-Fleur-Beach",
];

export const FACILITY_ADD_NEW_IMAGES_FORM_DESCRIPTIONS = [
  "Aynı anda birden fazla resmi sürükleyip bırakarak resimleri kaydedebilirsiniz.",
];

export const FACILITY_ORDER_IMAGES_FORM_DESCRIPTIONS = [
  "Sıralama yapmak istediğiniz resimleri tutup, istediğiniz resmin yerine bırakınız. Sıralamayı tamamlamak için butona basmayı unutmayınız.",
];

export const FACILITY_ORDER_IMAGES_FORM_WARNING = [
  "1. Resim Otelin En Güzel Genel Görünümü ve resmin dik olmamasına dikkat ediniz.",
  "2. Resim Otel Odalarından En Güzeli x 'KAMPANYA VEYA SANATÇI RESİMLERİ VAR İSE LÜTFEN RESİM SIRASINI DEĞİŞTİRMEYİNİZ!'",
  "3-4-5. Resimler ise Otelin Farklı Yerleri İçin Seçtiğiniz En Güzel Resimler Olmalıdır.",
];

export const FACILITY_ROOMS_IMAGES_FORM_DESCRIPTIONS = [
  "1. Oda resimlerinin üst ve alt kısımlarında beyaz veya başka renkte şerit şeklinde kısımlar olmamasına, resmin tamamının odaya ait olmasına dikkat ediniz.",
  "2. Aynı anda 10 resmi sürükleyip bırakarak resimleri yükleyebilirsiniz.",
];

export const FACILITY_MANAGERS_DESCRIPTIONS = [
  "Tesisin ana yetkilisinin adını ve soyadını mutlaka doğru şekilde giriniz. Baş harfleri büyük olarak yazınız.",
  "Ana Yetkili olarak eklenen kişiye tüm mailler gönderilir.",
  "Rezervasyon olarak eklenen kişinin telefon numarası mutlaka mobil hat olmalıdır.",
  " Tesise yeni rezervasyon girildiğinde Rezervasyon yetkili telefonlarına sms ve mail adreslerine e-posta iletilmektedir.",
  "Muhasebe olarak belirlenen yetkililere, muhasebe departmanı tarafından haftalık veya aylık cari işlem mailleri iletilmektedir.",
  "Mailler ve Smsler seçilen birimlere uygun olarak gider.",
];

export const CHILD_SPECİAL_AGE_DESCRIPTIONS = [
  "Çocuk Özel Yaş Tanımlamasını yapabilmek için aşağıdaki adımları sırası ile eksiksiz bir şekilde tamamlamanız gerekmektedir.",
  "1-) Çocuk Bilgileri Girişi, 2-) Oda Bilgileri Girişi, 3-) Fiyat Bilgileri Girişi",
];

export const LIST_COMMENTS_DESCRIPTION = [
  '"Tarih ile Filtrele" butonuna bastığınızda yorumun yapılma tarihine göre filtre yapılır.',
  'Bu tarih tabloda "İşlem Tarihi" sütununa denk gelmektedir.',
];

export const ADD_COMMENT_DESCRIPTION = [
  "Yapılacak Yorumlarda Çok İyi ve İyi Seçeneklerini Seçerek Puanları Ekleyiniz.",
  "Olumlu Yorum : Otelin konseptine ve özelliklerine göre olumlu yorum detaylı yazınız",
  "Olumsuz Yorum : Çok kısa olarak tek cümle bir olumsuz nokta yazalım",
  "Giriş - Çıkış tarihi otelin konseptine uygun son 2 ayda bir tarih seçelim",
];

export const ADD_DETAILED_INFO_TO_WEBSITE_DESCRIPTIONS = [
  "Bu alandan itibaren bilgileri doldurmak zorunlu değildir. Ancak daha çok bilgi doldurmanız web sitesini daha iyi gösterecektir.",
];

export const WEBSITE_ADD_NEW_GALLERY_IMAGES_DESCRIPTIONS = [
  "Aynı anda birden fazla resmi sürükleyip bırakarak resimleri kaydedebilirsiniz.",
];

export const CATEGORIZE_FACILITY_DESCRIPTION = [
  "Bir tesis sadece bir sınıfa atanabilir.",
];

export const CHANGE_FACILITY_CATEGORY = [
  "Sınıf seçilmez ise seçili oteller boşa çıkartılır.",
];

export const ASSIGN_HOTELS_TO_USERS_DESCRIPTIONS = [
  "Bir otel sadece bir kişiye atanabilir.",
];
export const DISCOUNTS_PAGE_DESCRIPTION = [
  "Otelfiyat.com web portalı üzerinden fiyatlarınızla ilgili yapacağınız indirim ve kampanyalar ile aldığınız rezervasyon sayılarınızı kolayca artırabilirsiniz. İzmir Otelleri içinde otelinizi diğer tesisler ile kıyaslayan kullanıcılara kampanyalarınızı anlık bildirebilir, misafirlerin sizi seçmesini sağlayabilirsiniz. Bu sayede Otel Doluluk Oranlarınızı Artırabilirsiniz.",
  "İndirimler Sistem üzerinde otomasyona açıktır. İndiriminizi tanımladığınız anda aktif olmaktadır. Takıldığınız yada sormak istediğiniz bir detay olduğunda, Bölge Operasyon Sorumlunuz ile iletişime geçebilirsiniz. Yardımcı olmaktan memnuniyet duyacağız.",
];

export const DASHBOARD_PAGE_DESCRIPTION = [
  "Daha Fazla Rezervasyon almak ve bölgelerinizde sıralamanızı artırmak için yapmanız gerekenler;",
  "- Sistemde 12 Ay Tanımlı Fiyat Bulundurmak",
  "- Tüm Oda tiplerinizin ve tüm pansiyon tiplerinizin sistemde bulunmasını sağlamak",
  "- İlave Yatak ve Çocuk indirimlerini sisteme tanımlamak",
  "- Sistemdeki indirim seçeneklerinden en az 2 adet indirim tanımlaması yapmak",
  "Sistem Kullanımı ile ilgili olarak detaylı bilgi almak için bölge operasyon sorumlumuz ile irtibata geçebilirsiniz. Sizlere yardımcı olmaktan memnuniyet duyacağız.",
];
