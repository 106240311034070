import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  FACILITY_CONSTRUCTION_DATE_DESCRIPTION,
  FACILITY_DISTANCE_TO_SEA_DESCRIPTION,
  FACILITY_RENOVATION_DATE_DESCRIPTION,
  FACILITY_ROOM_COUNT_DESCRIPTION,
} from "utils/fieldDescriptions";

interface FacilityTitleProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityInfo = ({
  values,
  validateFormChange,
  formErrors,
}: FacilityTitleProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Inşa Tarihi"
        // error={formErrors.otel_insaat_tarihi}
        description={FACILITY_CONSTRUCTION_DATE_DESCRIPTION}
        divider
      >
        <Input
          type="number"
          value={values.otel_insaat_tarihi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_insaat_tarihi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_insaat_tarihi: parseInt(e.target.value),
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Yenileme Tarihi"
        // error={formErrors.otel_yenileme}
        description={FACILITY_RENOVATION_DATE_DESCRIPTION}
        divider
      >
        <Input
          type="number"
          value={values.otel_yenileme}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_yenileme: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_yenileme: parseInt(e.target.value),
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Toplam Oda Sayısı"
        // error={formErrors.otel_odasayisi}
        description={FACILITY_ROOM_COUNT_DESCRIPTION}
        divider
      >
        <Input
          type="number"
          value={values.otel_odasayisi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_odasayisi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_odasayisi: parseInt(e.target.value),
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Denize Mesafesi"
        // error={formErrors.deniz_mesafe}
        description={FACILITY_DISTANCE_TO_SEA_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          value={values.deniz_mesafe}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                deniz_mesafe: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              deniz_mesafe: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityInfo;
