import { DiscountCodesPage } from "Components/Custom/DiscountsAndActionsPages";
import useDiscountCodes from "Components/Hooks/discountsAndActions/useDiscountCodes";
import PageContainer from "pages/PageContainer";

const DiscountCodes = () => {
  const { error, isLoading } = useDiscountCodes();

  return (
    <PageContainer title="İndirim Kuponu">
      <DiscountCodesPage loading={isLoading} error={error} />
    </PageContainer>
  );
};

export default DiscountCodes;
