import {
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DiscountRelatedFields from "./DiscountRelatedFields";
import OtherFields from "./OtherFields";
import { BREAKING_DISCOUNTS_DESCRIPTIONS_INFO } from "utils/pageDescriptions";
import AddBreakingDiscountButton from "./AddBreakingDiscountButton";

interface BreakingDiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const BreakingDiscountsForm = (props: BreakingDiscountsFormProps) => {
  const {
    breakingDiscount,
    breakingDiscountsTypesOptions,
    conceptsOptions,
    paymentMethodOptions,
    addNewBreakingDiscountRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    isLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <>
      <CustomFormContainer>
        <DiscountRelatedFields
          values={breakingDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          breakingDiscountsTypesOptions={breakingDiscountsTypesOptions}
        />
        <OtherFields
          values={breakingDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          conceptsOptions={conceptsOptions}
          paymentMethodOptions={paymentMethodOptions}
        />
        <AddBreakingDiscountButton
          values={breakingDiscount}
          requestPayload={addNewBreakingDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={isLoading}
        />
      </CustomFormContainer>
      <CustomPageDescriptions
        descriptions={BREAKING_DISCOUNTS_DESCRIPTIONS_INFO}
      />
    </>
  );
};

export default BreakingDiscountsForm;
