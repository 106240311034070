import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
import Select from "react-select";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import {
  CalculatePriceForHotelSpecialDiscount,
  EnterReservationFormValues,
} from "types/reservation";
import SpecialDiscountsForm from "../../CalculatePriceForHotelPage/SpecialDiscountsForm";
import CalculateAgainRow from "./CalculateAgainRow";

interface ReservationInfoFormProps {
  formValues: EnterReservationFormValues;
  handleChangeInput: (key: string, value: any) => void;
  roomConceptTypeOptions: RoomConceptOption[];
  onCalculatePrice: () => void;
  calculatedPrice: number | undefined;
  moneyPoint: number;
  specialDiscounts: CalculatePriceForHotelSpecialDiscount[];
}

interface RoomConceptOption {
  value: string;
  label: string;
  oda_id: number;
  pansiyon_id: number;
}

const ReservationInfoForm = ({
  formValues,
  handleChangeInput,
  roomConceptTypeOptions,
  onCalculatePrice,
  calculatedPrice,
  moneyPoint,
  specialDiscounts,
}: ReservationInfoFormProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormContainer title="Rezervasyon Bilgileri">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
        }}
      >
        <CustomFormFieldContainer label="Otel Adı" divider>
          <Input
            type="text"
            value={formValues.otel_adi}
            onChange={(e) => handleChangeInput("otel_adi", e.target.value)}
            disabled={true}
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Oda/Pansiyon Tipi" divider>
          <Select
            options={roomConceptTypeOptions}
            value={roomConceptTypeOptions.find(
              (option) => option.value === formValues.oda_pansiyon_tipi
            )}
            onChange={(selectedOption: RoomConceptOption) =>
              handleChangeInput("oda_pansiyon_tipi", selectedOption.value)
            }
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Giriş Tarihi"
          error={
            formValues.giris_tarihi.length === 0
              ? "Giriş Tarihi Zorunludur!"
              : ""
          }
          divider
        >
          <Flatpickr
            className="form-control"
            value={formValues.giris_tarihi}
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="Giriş Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Çıkış Tarihi"
          divider
          error={
            formValues.cikis_tarihi.length === 0
              ? "Çıkış Tarihi Zorunludur!"
              : ""
          }
        >
          <Flatpickr
            className="form-control"
            value={formValues.cikis_tarihi}
            onChange={(date) => {
              const [endDate] = date;
              if (endDate) {
                const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                handleChangeInput("cikis_tarihi", stringEndDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().toDate(),
              onDayCreate,
            }}
            placeholder="Çıkış Tarihi"
          />
        </CustomFormFieldContainer>
        {formValues.odeme_tipi === "kapida" && (
          <>
            <CustomFormFieldContainer
              label="Giriş Saati"
              required="Kapıda Ödeme de Saat Belirtmek Zorunludur!"
              error={
                formValues.giris_saati.length === 0
                  ? "Giriş Saati Zorunludur!"
                  : ""
              }
              orientation="vertical"
            >
              <div style={{ width: "300px" }}>
                <Flatpickr
                  className="form-control"
                  value={formValues.giris_saati}
                  onChange={(date) => {
                    const [startDate] = date;
                    if (startDate) {
                      const stringStartDate = moment(startDate).format("HH:mm");

                      handleChangeInput("giris_saati", stringStartDate);
                    }
                  }}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                  }}
                  placeholder="Giriş Saati"
                />
              </div>
            </CustomFormFieldContainer>
            <div></div>
            <div
              style={{
                gridColumn: "span 2",
              }}
            >
              <strong className="text-decoration-underline mb-1">
                Misafire Mutlaka Belirtiniz:
              </strong>
              <p className="theme-text-danger fs-14">
                Misafire Mutlaka Otele Giriş yapacagı saati sorunuz. 14.00 a
                kadar giriş yapacaksa bir saat belirtmenize gerek yok.
                14.00-18.00 arasında ise gireceği saati seçim yapınız. 18.00
                sonrasında odaya girecekse misafire bu saate kadar odayı
                tutamayacagınızı belirterek ödeme tahsilatını almanız
                gerektiğini belirtiniz. Yada otel ile iletişime geçerek geç
                giriş saati için mutlaka otelden onay alıp notlara yazınız.
              </p>
              <strong className="text-decoration-underline mb-1">
                ***Mutlaka Söylüyoruz***:
              </strong>
              <p className="theme-text-danger fs-14">
                {" "}
                Odanızı giriş saatinize kadar sizin için bekletiyoruz,
                Rezervasyonunuzla ilgili iptal yada değişiklik durumunda lütfen
                bize bilgi veriniz.
              </p>
            </div>
          </>
        )}
        <CustomFormFieldContainer label="Müşteri Adı Soyadı" divider>
          <Input
            type="text"
            value={formValues.musteri_ad_soyad}
            onChange={(e) =>
              handleChangeInput("musteri_ad_soyad", e.target.value)
            }
            valid={formValues.musteri_ad_soyad.length > 0}
            invalid={formValues.musteri_ad_soyad.length === 0}
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Müşteri E-Posta"
          divider
          description="Müşteri e-postası sormak kesinlikle zorunludur. Mutlaka almaya çalışın. Gmail Ve Hotmail adreslerinde Otel giriş belgeniz gereksiz posta kutusuna düşebiliyor diye mutlaka belirtiniz."
        >
          <Input
            type="text"
            value={formValues.musteri_eposta}
            onChange={(e) =>
              handleChangeInput("musteri_eposta", e.target.value)
            }
            valid={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
              formValues.musteri_eposta
            )}
            invalid={
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                formValues.musteri_eposta
              )
            }
            placeholder="example@example.com"
            disabled={formValues.musteri_eposta === "example@example.com"}
          />
          <div className="d-flex align-items-center gap-2 mt-2">
            <Input
              id="musteri_eposta"
              type="checkbox"
              className=" cursor-pointer m-0"
              checked={formValues.musteri_eposta === "example@example.com"}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChangeInput("musteri_eposta", "example@example.com");
                } else {
                  handleChangeInput("musteri_eposta", "");
                }
              }}
            />
            <Label for="musteri_eposta" className="m-0 cursor-pointer">
              E-Posta Yok
            </Label>
          </div>
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Müşteri Telefon"
          required
          divider
          description="<span class='theme-text-danger fw-medium fs-14'> Önemli</span>"
        >
          <Input
            type="number"
            value={formValues.musteri_tel}
            onChange={(e) => handleChangeInput("musteri_tel", e.target.value)}
            valid={formValues.musteri_tel.length > 9}
            invalid={formValues.musteri_tel.length < 10}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Müşteri Telefon 2" divider>
          <Input
            type="text"
            value={formValues.musteri_tel_2}
            onChange={(e) => handleChangeInput("musteri_tel_2", e.target.value)}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Müşteri T.C. Kimlik No"
          required
          description="<span class='theme-text-danger fw-medium fs-14'> Önemli</span>"
          divider
        >
          <Input
            type="number"
            value={formValues.musteri_tc}
            onChange={(e) => {
              e.target.value.length <= 11 &&
                handleChangeInput("musteri_tc", e.target.value);
            }}
            min={11}
            max={11}
            valid={formValues.musteri_tc.length === 11}
            invalid={formValues.musteri_tc.length !== 11}
          />
        </CustomFormFieldContainer>
        <div></div>
        <CustomFormFieldContainer label="Yetişkin Sayısı" divider>
          <CustomNumberSelector
            value={formValues.yetiskin_sayisi}
            onChange={(value) => handleChangeInput("yetiskin_sayisi", value)}
            min={1}
            max={8}
            disabled
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Çocuk Sayısı" divider>
          <CustomNumberSelector
            value={formValues.cocuk_sayisi}
            onChange={(value) => handleChangeInput("cocuk_sayisi", value)}
            min={0}
            max={4}
            disabled
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="1. Çocuk Doğum Tarihi"
          divider
          error={
            formValues.cocuk_sayisi > 0 && formValues.cocuk_dogum_1.length === 0
              ? "1. Çocuğun Doğum Tarihi Zorunludur!"
              : ""
          }
        >
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_1}
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput("cocuk_dogum_1", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              onDayCreate,
              maxDate: moment().toDate(),
            }}
            placeholder="1. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="2. Çocuk Doğum Tarihi" divider>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_2}
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput("cocuk_dogum_2", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              onDayCreate,
            }}
            placeholder="2. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="3. Çocuk Doğum Tarihi" divider>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_3}
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput("cocuk_dogum_3", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              onDayCreate,
            }}
            placeholder="3. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="4. Çocuk Doğum Tarihi" divider>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_4}
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput("cocuk_dogum_4", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              onDayCreate,
            }}
            placeholder="4. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
      </div>
      <SpecialDiscountsForm
        passedProps={{
          specialDiscounts,
          moneyPoint,
        }}
        formValues={formValues}
        handleChangeForm={handleChangeInput}
      />
      <div className="d-flex flex-wrap gap-2">
        <span className="fw-semibold text-decoration-underline">
          Uygulanmış indirimler:
        </span>
        {formValues.secilen_ozel_indirimler.map((indirim, index) => (
          <span
            key={index}
            className="fw-medium bg-primary bg-opacity-10 text-primary p-1 rounded fit-content"
          >
            {indirim.ozel_indirim_isim} | %{indirim.ozel_indirim_orani}
          </span>
        ))}
      </div>
      <CalculateAgainRow
        formValues={formValues}
        onCalculatePrice={onCalculatePrice}
        calculatedPrice={calculatedPrice}
      />
    </CustomFormContainer>
  );
};

export default ReservationInfoForm;
