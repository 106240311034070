import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/addLocation/reducer";
import { AddLocationFormErrors, AddLocationState } from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface LocationNameProps {
  values: AddLocationState;
  formErrors: AddLocationFormErrors;
  validateFormChange: (values: AddLocationState) => void;
}

const LocationName = ({
  values,
  validateFormChange,
  formErrors,
}: LocationNameProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Lokasyon Adı"
        error={formErrors.lokasyon}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.lokasyon}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                lokasyon: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              lokasyon: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default LocationName;
