import { CustomButton } from "Components/Custom/UI";
import { AddCommentState } from "types/comments";

interface AddNewCommentButtonProps {
  values: AddCommentState;
  validateForm: (values: AddCommentState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onAddNewComment: () => void;
  isLoading: boolean;
}

const AddNewCommentButton = ({
  values,
  validateForm,
  handleSubmitted,
  onAddNewComment,
  isLoading,
}: AddNewCommentButtonProps) => {
  
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onAddNewComment();
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Yeni Yorum Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewCommentButton;
