import { CallsFromGeneralPage } from "Components/Custom/ReportsPages";
import useCallsFromGeneral from "Components/Hooks/reports/useCallsFromGeneral";
import PageContainer from "pages/PageContainer";

const CallsFromGeneral = () => {
  const { isLoading } = useCallsFromGeneral();

  return (
    <PageContainer title="Genelden Gelen Çağrılar">
      <CallsFromGeneralPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CallsFromGeneral;
