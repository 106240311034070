import {
    CustomDualListBox,
    CustomFormFieldContainer,
  } from "Components/Custom/UI";
  import {
    AssignHotelToUserFormErrors
  } from "types/userOperations";
import useHandleList from "./useHandleList";
  
  interface HotelsToChangeListProps {
    formErrors: AssignHotelToUserFormErrors;
  }
  
  const HotelsToChangeList = ({
    formErrors,
  }: HotelsToChangeListProps) => {
    const {
      hotelOptions,
      selectedOptions,
      handleSelectOptions,
    } = useHandleList();
  
    return (
      <div style={{ margin: "16px 0" }}>
        <CustomFormFieldContainer
          label={"Oteller : "}
          error={formErrors.otel_id}
          orientation="vertical"
          divider
          required
        >
          <CustomDualListBox
            listOptions={hotelOptions}
            selectedOptions={selectedOptions.map((option) => option.value)}
            handleSelectOptions={handleSelectOptions}
          />
        </CustomFormFieldContainer>
      </div>
    );
  };
  
  export default HotelsToChangeList;
  