import { Link } from "react-router-dom";

import { Form, FormFeedback, Input, Label } from "reactstrap";

import {
  CustomFormButton,
  CustomFormStatusMessages,
} from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";

const SendMailForm = () => {
  const { validation, forgetError, forgetSuccessMsg, isLoading } =
    useHandleForm();

  return (
    <div className="p-2">
      <CustomFormStatusMessages
        error={forgetError}
        success={forgetSuccessMsg}
      />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="mb-4">
          <Label className="form-label" htmlFor="email">
            E-posta
          </Label>
          <Input
            id="email"
            name="email"
            className="form-control"
            placeholder="E-posta adresinizi girin"
            type="email"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.email}</div>
            </FormFeedback>
          ) : null}
        </div>

        <div className="text-center mt-4">
          <CustomFormButton
            type="submit"
            variant="success"
            isLoading={isLoading}
          >
            Sıfırlama Kodu Gönder
          </CustomFormButton>
        </div>
      </Form>
      <div className="w-100 mt-4 text-center">
        <Link
          to="/giris"
          className="d-block w-100 text-center border border-primary theme-text-primary p-2 rounded text-decoration-none"
        >
          Giriş Yap
        </Link>
      </div>
    </div>
  );
};

export default SendMailForm;
