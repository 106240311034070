import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/userOperations/createNotification/reducer";
import {
  CreateNotificationFormErrors,
  CreateNotificationPayload,
} from "types/userOperations";

interface NotificationInputProps {
  values: CreateNotificationPayload;
  formErrors: CreateNotificationFormErrors;
  validateFormChange: (values: CreateNotificationPayload) => void;
}

const NotificationInput = ({
  values,
  formErrors,
  validateFormChange,
}: NotificationInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Bildirim : "
        error={formErrors.bildirim}
        orientation="vertical"
        required
      >
        <CustomFormEditor
          editorData={values.bildirim}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                bildirim: data,
              })
            );
            validateFormChange({
              ...values,
              bildirim: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default NotificationInput;
