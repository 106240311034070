import { useSelector } from "react-redux";
import { HourlyCallsChartInitialState } from "slices/reports/hourlyCallsChart/reducer";
import { CHART_COLORS } from "utils/chartColors";
import moment from "moment";

function formatData(data: any) {
  const incomingData = data?.data ? data.data : data;
  const formattedData = incomingData.map((item: [string, number]) => {
    return {
      name: moment(Number(item[0])).format("HH:mm"),
      "Çağrı Sayısı": item[1],
    };
  });

  return formattedData;
}

const useHandleChart = () => {
  const { hourlyCallsChartResponse } = useSelector(
    (state: { HourlyCallsChart: HourlyCallsChartInitialState }) =>
      state.HourlyCallsChart
  );


  const formattedData = formatData(hourlyCallsChartResponse);

  return { formattedData, colors: [CHART_COLORS.PRIMARY_BOLD] };
};

export default useHandleChart;
