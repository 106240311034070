import { createSlice } from "@reduxjs/toolkit";

import { Theme, OrderHotel } from "types/themes";

export interface OrderThemeHotelsInitialState {
  themes: Theme[];
  hotels: OrderHotel[];
  orderedHotels: OrderHotel[];
  selectedTheme: number;
  isLoading: boolean;
  error: string;
  addOrderThemeHotelLoading: boolean;
  addOrderThemeHotelError: string;
}

export const orderThemeHotelsInitialState: OrderThemeHotelsInitialState = {
  themes: [],
  hotels: [],
  orderedHotels: [],
  selectedTheme: 0,
  isLoading: false,
  error: "",
  addOrderThemeHotelLoading: false,
  addOrderThemeHotelError: "",
};

export const orderThemeHotelsSlice = createSlice({
  name: "orderThemeHotels",
  initialState: orderThemeHotelsInitialState,
  reducers: {
    handleSaveThemes(state, action) {
      state.themes = action.payload;
    },
    handleSaveHotels(state, action) {
      state.hotels = action.payload;
    },
    handleSaveOrderedHotels(state, action) {
      state.orderedHotels = action.payload;
    },
    handleSaveSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    handleResetOrderedHotelsToDefault(state) {
      state.orderedHotels = state.hotels;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setAddOrderThemeHotelLoading(state, action) {
      state.addOrderThemeHotelLoading = action.payload;
    },
    setAddOrderThemeHotelError(state, action) {
      state.addOrderThemeHotelError = action.payload;
    },
  },
});

export const {
  handleSaveThemes,
  handleSaveHotels,
  handleSaveOrderedHotels,
  handleSaveSelectedTheme,
  handleResetOrderedHotelsToDefault,
  setIsLoading,
  setError,
  setAddOrderThemeHotelLoading,
  setAddOrderThemeHotelError,
} = orderThemeHotelsSlice.actions;

export default orderThemeHotelsSlice.reducer;
