import { BreakingDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import useBreakingDiscounts from "Components/Hooks/discountsAndActions/useBreakingDiscounts";
import PageContainer from "pages/PageContainer";

const BreakingDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useBreakingDiscounts();

  return (
    <PageContainer title="Son Dakika İndirimleri">
      <BreakingDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default BreakingDiscounts;
