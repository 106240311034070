import { PAYMENT_METHODS } from "enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EarlyReservationDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { EarlyReservationDiscountsInitialState } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    earlyReservationDiscount,
    earlyReservationDiscountFormErrors,
    addDiscountLoading,
    updateDiscountLoading,
  } = useSelector(
    (state: {
      EarlyReservationDiscounts: EarlyReservationDiscountsInitialState;
    }) => state.EarlyReservationDiscounts
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<Option[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newRoomsOptions: Option[] = hotel.odalar.map((room: any) => ({
        value: room.id,
        label: room.oda_isim,
      }));

      const newConceptsOptions: Option[] = hotel.pansiyonlar.map(
        (concept: any) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setRoomsOptions(newRoomsOptions);
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const startSellDate = moment(
    earlyReservationDiscount.satis_baslangic_tarihi
  ).format("YYYY-MM-DD");

  const endSellDate = moment(
    earlyReservationDiscount.satis_bitis_tarihi
  ).format("YYYY-MM-DD");

  const startAccommodationDate = moment(
    earlyReservationDiscount.konaklama_baslangic_tarihi
  ).format("YYYY-MM-DD");

  const endAccommodationDate = moment(
    earlyReservationDiscount.konaklama_bitis_tarihi
  ).format("YYYY-MM-DD");

  const payingToHotelDate = moment(
    earlyReservationDiscount.otele_odeme_gunu
  ).format("YYYY-MM-DD");

  const addNewEarlyReservationDiscountRequestPayload: EarlyReservationDiscountRequestPayload =
    {
      otel_id,
      otel_oda_id: oda_id,
      grup_id: earlyReservationDiscount.grup_id,
      rez_tarihi: `${startSellDate} - ${endSellDate}`,
      konaklama_tarihi: `${startAccommodationDate} - ${endAccommodationDate}`,
      radio_gun: Number(earlyReservationDiscount.tum_gunler),
      gun: earlyReservationDiscount.gunler,
      oran: Number(earlyReservationDiscount.oran),
      gercek_indirim_orani: Number(
        earlyReservationDiscount.gercek_indirim_orani
      ),
      gostermelik_indirim_orani:
        earlyReservationDiscount.gostermelik_indirim_orani,
      min_gece_sayisi: earlyReservationDiscount.minimum_gece,
      kumulatif: earlyReservationDiscount.kumulatif_durum,
      odeme_gunu: payingToHotelDate,
      odeme_orani: Number(earlyReservationDiscount.otele_odeme_orani),
      tum_pansiyonlar: Number(earlyReservationDiscount.tum_otel_pansiyonlar),
      secili_pansiyonlar: earlyReservationDiscount.otel_pansiyonlar,
      tum_odeme_tipleri: earlyReservationDiscount.tum_odeme_tipleri,
      secili_odeme_tipleri: earlyReservationDiscount.odeme_tipleri,
    };

  const updateEarlyReservationDiscountRequestPayload: EarlyReservationDiscountRequestPayload =
    {
      erken_rezervasyon_id: earlyReservationDiscount.id,
      ...addNewEarlyReservationDiscountRequestPayload,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    earlyReservationDiscount,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    addDiscountLoading,
    updateDiscountLoading,
    addNewEarlyReservationDiscountRequestPayload,
    updateEarlyReservationDiscountRequestPayload,
    formErrors: earlyReservationDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
