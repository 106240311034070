interface ListItemProps {
  title: string;
  value: string | number | JSX.Element;
  isBold?: boolean;
  allRow?: boolean;
  topBorder?: boolean;
}

const ListItem = ({
  title,
  value,
  isBold,
  allRow,
  topBorder,
}: ListItemProps) => {
  return (
    <div className="d-flex align-items-center bg-white">
      <span
        className={`border border-2 fw-medium p-2 bg-light ${
          allRow ? "w-25" : "w-50"
        }  ${!topBorder ? "border-top-0" : ""}
          `}
      >
        {title}:
      </span>
      <span
        className={`border border-start-0 border-2 p-2 ${
          isBold ? "fw-bold" : ""
        } ${allRow ? "w-75" : "w-50"} ${!topBorder ? "border-top-0" : ""} `}
      >
        {value || "-"}
      </span>
    </div>
  );
};

export default ListItem;
