import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface WelcomeAndCalculationTableProps {
  isLoading: boolean;
}

const WelcomeAndCalculationTable = ({
  isLoading,
}: WelcomeAndCalculationTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Karşılama ve Fiyat Hesaplama"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default WelcomeAndCalculationTable;
