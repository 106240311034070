import { getConceptStatusMessages } from "helpers/general_helpers";
import { useState } from "react";
import { CalculatePriceForHotelConceptPrice } from "types/reservation";

const useHandleKapidaPayButton = ({
  fiyatlar,
  kontenjan,
  kapida_odeme,
  priceCalculationStatusMessages,
  mayNotExist,
}: {
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  kapida_odeme: {
    kapida_odeme_durum: "evet" | "hayir";
    kapida_komisyon_orani: string;
    kapida_indirim_orani: string;
    kapida_odeme_para_birimi_goster: "0" | "1";
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  mayNotExist?: boolean;
}) => {
  const [checked, setChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  if (mayNotExist) {
    return {
      hasPrice: false,
      stop: null,
      stopMessage: "",
      showKapidaOdemeButton: false,
      kapidaOdemeFiyatDurum: 0,
      kapidaOdemeButtonText: "",
      kapidaOdemePrice: 0,
      kapidaOdemeOverlinedPrice: 0,
      messages: [],
      quota: 0,
      checked,
      setChecked,
      isOpen,
      toggle: () => {},
    };
  } else {
    const hasPrice = fiyatlar.kapida.fiyat_durum === 1;

    //Kapıda Ödeme
    const kapidaOdeme = fiyatlar.kapida;

    const stop = kapidaOdeme.stop_durum ?? null;
    const stopMessage = kapidaOdeme.stop_durum_mesaj ?? "";

    const kapidaOdemeDurum = kapida_odeme.kapida_odeme_durum === "evet";

    const kapidaOdemeFiyatDurum = kapidaOdeme.fiyat_durum ?? 0;

    const kapidaOdemeButtonText = "Otelde Öde";

    const showKapidaOdemeButton = kapidaOdemeDurum && hasPrice;

    const kapidaOdemePrice = kapidaOdeme.kapida_indirimli ?? 0;
    const kapidaOdemeOverlinedPrice = kapidaOdeme.kapida_gostermelik ?? 0;

    const quotas = kapidaOdeme
      ? Object.values(kontenjan).map((quota) => (quota ? parseInt(quota) : 0))
      : [];

    const quota = Math.min(...quotas);

    const toggle = () => setIsOpen((prev) => !prev);

    const hasPriceStatus = kapidaOdeme.fiyat_durum === 1;
    const hasQuotaStatus = kapidaOdeme.kontenjan_durum !== 0;
    const quotaMessage = kapidaOdeme.kontenjan_durum_mesaj;
    const minNightStatus = kapidaOdeme.minimum_gece_durum > 0;

    const messages = getConceptStatusMessages(
      priceCalculationStatusMessages,
      hasPriceStatus,
      hasQuotaStatus,
      quotaMessage,
      minNightStatus,
      kapidaOdeme.minimum_gece_durum ?? 0
    );

    return {
      hasPrice,
      stop,
      stopMessage,
      showKapidaOdemeButton,
      kapidaOdemeFiyatDurum,
      kapidaOdemeButtonText,
      kapidaOdemePrice,
      kapidaOdemeOverlinedPrice,
      messages,
      quota,
      checked,
      setChecked,
      isOpen,
      toggle,
    };
  }
};

export default useHandleKapidaPayButton;
