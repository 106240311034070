import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/addEarlyReservationDefinition/reducer";
import {
  AddEarlyReservationDefinitionFormErrors,
  AddEarlyReservationDefinitionState,
} from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface DescriptionAndDateFieldsProps {
  values: AddEarlyReservationDefinitionState;
  formErrors: AddEarlyReservationDefinitionFormErrors;
  validateFormChange: (values: AddEarlyReservationDefinitionState) => void;
}

const DescriptionAndDateFields = ({
  values,
  validateFormChange,
  formErrors,
}: DescriptionAndDateFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer label="Dönem Açıklama" error={formErrors.donem}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.donem}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                donem: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              donem: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Dönem Tarih" error={formErrors.tarih}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.tarih}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                tarih: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              tarih: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DescriptionAndDateFields;
