import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  HotelContractsInitialState,
  resetHotelContractState,
} from "slices/financeAndAccounting/hotelContracts/reducer";
import {
  getHotelContracts,
  addHotelContract,
  updateHotelContract,
} from "slices/financeAndAccounting/hotelContracts/thunk";
import useValidateForm from "./useValidateForm";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandleForm = () => {
  const {
    addHotelContractFormErrors,
    updateHotelContractFormErrors,
    hotelContract,
    contractLoading,
  } = useSelector(
    (state: { HotelContracts: HotelContractsInitialState }) =>
      state.HotelContracts
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const contractId = hotelContract.kontrat_id;

  const dispatch: Dispatch<any> = useDispatch();

  const { validateForm, validateFormChange, handleSubmitted } = useValidateForm(
    contractId ? "update" : "add"
  );

  const handleGetHotelContracts = (otel_id: number) => {
    const paylaod = {
      otel_id,
      page: 1,
      per_page: 10,
      search: "",
    };
    dispatch(getHotelContracts(paylaod));
  };

  const handleAddHotelContract = () => {
    const isValid = validateForm(hotelContract);
    handleSubmitted(true);

    if (isValid) {
      const formData = new FormData();
      const { belge, s_tarih, b_tarih } = hotelContract;

      formData.append("otel_id", hotel.otel_id.toString());
      formData.append("b_tarih", b_tarih);
      formData.append("s_tarih", s_tarih);
      if (belge?.file) formData.append("belge", belge?.file, belge?.file?.name);

      belge &&
        dispatch(
          addHotelContract(formData, () =>
            handleGetHotelContracts(hotel.otel_id)
          )
        );
    }
  };

  const handleUpdateHotelContract = () => {
    const isValid = validateForm(hotelContract);
    handleSubmitted(true);

    if (isValid) {
      const formData = new FormData();
      const { belge, s_tarih, b_tarih, otel_id, kontrat_id, kontrol_durum } =
        hotelContract;

      formData.append("otel_id", otel_id.toString());
      formData.append("kontrat_id", kontrat_id.toString());
      formData.append("kontrol_durum", kontrol_durum === "0" ? "1" : "2");
      formData.append("b_tarih", b_tarih);
      formData.append("s_tarih", s_tarih);
      if (belge?.file) formData.append("belge", belge?.file, belge?.file?.name);

      dispatch(
        updateHotelContract(formData, () => handleGetHotelContracts(otel_id))
      );
    }
  };

  const handleResetHotelContractState = () => {
    dispatch(resetHotelContractState());
  };

  return {
    hotelContract,
    isLoading: contractLoading,
    addHotelContractFormErrors,
    updateHotelContractFormErrors,
    validateFormChange,
    onAddContract: handleAddHotelContract,
    onUpdateContract: handleUpdateHotelContract,
    onResetHotelContractState: handleResetHotelContractState,
  };
};

export default useHandleForm;
