import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddAddFacilityFormErrors } from "slices/facility/addFacility/reducer";
import { setToastError } from "slices/toast/reducer";

import { AddFacilityFormErrors, FacilityState } from "types/facility";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FacilityState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddFacilityFormErrors = {};

    if (!values.otel_adi) {
      errors.otel_adi = "Otel adı boş bırakılamaz.";
    }

    if (!values.yildiz) {
      errors.yildiz = "Yıldız seçimi yapınız.";
    }

    if (!values.otel_link) {
      errors.otel_link = "Otel linki boş bırakılamaz.";
    }

    if (!values.otel_genel_aciklama) {
      errors.otel_genel_aciklama = "Genel açıklama boş bırakılamaz.";
    }

    if (!values.otel_ozellikleri.length) {
      errors.otel_ozellikleri = "En az bir özellik seçiniz.";
    }

    if (!values.cocuk_ozellikleri.length) {
      errors.cocuk_ozellikleri = "En az bir özellik seçiniz.";
    }

    if (!values.otel_adres) {
      errors.otel_adres = "Otel adresi boş bırakılamaz.";
    }

    if (!values.otel_bolgesi_id) {
      errors.otel_bolgesi_id = "Otel bölgesi seçimi yapınız.";
    }

    if (!values.otel_konum_aciklama) {
      errors.otel_konum_aciklama = "Otel konum açıklama boş bırakılamaz.";
    }

    if (!values.crm_aciklamali_adres) {
      errors.crm_aciklamali_adres =
        "Otel CRM açıklamalı adresi boş bırakılamaz.";
    }

    if (!values.otel_konaklama_aciklama) {
      errors.otel_konaklama_aciklama =
        "Otel konaklama açıklama boş bırakılamaz.";
    }

    if (!values.oda_ozellikleri.length) {
      errors.oda_ozellikleri = "En az bir özellik seçiniz.";
    }

    if (!values.yiyecek_metin) {
      errors.yiyecek_metin = "Yiyecek ve içecek açıklama boş bırakılamaz.";
    }

    if (!values.yiyecek_ozellik.length) {
      errors.yiyecek_ozellik = "En az bir özellik seçiniz.";
    }

    if (!values.otel_aktivite_aciklama) {
      errors.otel_aktivite_aciklama = "Aktivite açıklama boş bırakılamaz.";
    }

    if (!values.aktiviteler.length) {
      errors.aktiviteler = "En az bir aktivite seçiniz.";
    }

    dispatch(handleAddAddFacilityFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FacilityState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
