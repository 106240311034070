import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import { FooterLink } from "types/myWebSite";
import useHandlePayload from "./useHandlePayload";
import { addFooterLink, getFooterLinks } from "slices/myWebSite/addFooterLinks/thunk";
import { handleResetAddFooterLinkFormFields } from "slices/myWebSite/addFooterLinks/reducer";

// ! useHandlePayload hookuna dönüp tekrar incele

interface AddFooterLinkButtonProps {
  values: FooterLink;
  requestPayload: FormData;
  validateForm: (values: FooterLink) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddFooterLinkButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddFooterLinkButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  // console.log("REQUEST PAYLOAD IN BUTTON: ", requestPayload)

  const { getPayload, otel_id } = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addFooterLink({id: otel_id}, getPayload(), () => {
          dispatch(handleResetAddFooterLinkFormFields());
          dispatch(getFooterLinks(otel_id));
          
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={isLoading}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};

export default AddFooterLinkButton;
