import { CustomModal } from "Components/Custom/UI";
import { useState } from "react";
import { ADD_FACILITY_PAGE_MODAL_DESCRIPTION } from "utils/pageDescriptions";

const AddFacilityPageWarningModal = () => {
  const [showModal, setShowModal] = useState(true);
  return (
    <>
      <CustomModal
        isOpen={showModal}
        handleToggle={() => setShowModal(!showModal)}
        size="lg"
        preventClose
      >
        <div className="d-flex flex-column gap-1 p-3">
          <p className="fw-bold fs-20 theme-text-danger user-select-none">
            Dikkat!
          </p>
          {ADD_FACILITY_PAGE_MODAL_DESCRIPTION.map((description, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: description }}
              className="user-select-none"
            />
          ))}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={() => setShowModal(false)}
            >
              Okudum, Anladım.
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AddFacilityPageWarningModal;
