import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface DailyReservationsTableProps {
  isLoading: boolean;
}

const DailyReservationsTable = ({ isLoading }: DailyReservationsTableProps) => {
  const { tableData, tableColumns, columnVisibility, legend } =
    useHandleTable();

  return (
    <CustomReactTable
      fileName="Günlük Satış Raporu"
      tableData={tableData}
      tableColumns={tableColumns}
      columnVisibility={columnVisibility}
      loading={isLoading}
      legend={legend}
    />
  );
};

export default DailyReservationsTable;
