import { FacilityCallsPage } from "Components/Custom/FacilityPages";
import useFacilityCalls from "Components/Hooks/facility/useFacilityCalls";
import PageContainer from "pages/PageContainer";

const FacilityCalls = () => {
  const { isLoading } = useFacilityCalls();

  return (
    <PageContainer title="Tesis Aramaları">
      <FacilityCallsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default FacilityCalls;
