import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import UserAndMoneyPointFields from "./UserAndMoneyPointFields";
import AddMoneyPointButton from "./AddMoneyPointButton";

const AddMoneyPointForm = () => {
  const {
    addMoneyPoint,
    formErrors,
    hotelUsersOptions,
    isLoading,
    addMoneyPointRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer title="Otel Parapuan Ekle">
      <UserAndMoneyPointFields
        values={addMoneyPoint}
        formErrors={formErrors}
        hotelUsersOptions={hotelUsersOptions}
        validateFormChange={validateFormChange}
      />
      <AddMoneyPointButton
        values={addMoneyPoint}
        requestPayload={addMoneyPointRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default AddMoneyPointForm;
