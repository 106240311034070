import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  FacilityCallInitialState,
  handleChangeDateInput,
} from "slices/facility/facilityCalls/reducer";
import { useSelector } from "react-redux";
import { getFacilityCalls } from "slices/facility/facilityCalls/thunk";
import { CustomButton } from "Components/Custom/UI";
const DateSelector = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const { startDate, endDate, pagination } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  const canFilter = startDate && endDate;

  const handleFilter = () => {
    dispatch(
      getFacilityCalls({
        page: 1,
        search: "",
        per_page: pagination.per_page,
        start_date: startDate,
        end_date: endDate,
      })
    );
  };

  const handleClearFilter = () => {
    dispatch(
      handleChangeDateInput({
        startDate: "",
        endDate: "",
      })
    );

    dispatch(
      getFacilityCalls({
        page: 1,
        search: "",
        per_page: pagination.per_page,
        start_date: "",
        end_date: "",
      })
    );
  };

  return (
    <div className="d-flex gap-4">
      <div
        style={{
          width: "300px",
        }}
      >
        <Flatpickr
          className="form-control"
          value={[startDate, endDate]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeDateInput({
                  startDate: stringStartDate,
                  endDate: stringEndDate,
                })
              );
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
          placeholder="Filtrelemek İçin Tarih Seçiniz..."
        />
      </div>
      <div className="d-flex gap-2">
        <CustomButton
          variant="primary"
          disabled={!canFilter}
          onClick={handleFilter}
        >
          Tarih ile Filtrele
        </CustomButton>
        <CustomButton
          variant="danger"
          disabled={!canFilter}
          onClick={handleClearFilter}
        >
          Tarih Filtresini Temizle
        </CustomButton>
      </div>
    </div>
  );
};

export default DateSelector;
