import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationTablePayload } from "types/userOperations";
import PriceProviderSelector from "./FormFields/PriceProviderSelector";
import UpdatePriceProviderButton from "./FormFields/UpdatePriceProviderButton";

interface Option {
    value: string;
    label: string;
  }

interface UpdatePriceProviderFormProps {
  values: ReservationTablePayload;
  options:  Option[];
  handleFormChange: (key: string, value: string) => void;
  handleUpdate: () => void;
}

const UpdatePriceProviderForm = ({
  values,
  options,
  handleFormChange,
  handleUpdate,
}: UpdatePriceProviderFormProps) => {
  return (
    <>
      <CustomFormContainer title="Fiyat Sağlayıcı Değişimi" >
        <span>İşlem Yapılan Rezervasyon No: <strong>{values.rez_kod}</strong></span>
        <PriceProviderSelector
          options={options}
          values={values}
          handleFormChange={handleFormChange}
        />
        <UpdatePriceProviderButton
          handleUpdate={handleUpdate}
        />
      </CustomFormContainer>
    </>
  );
};

export default UpdatePriceProviderForm;
