import { CustomButton, CustomModal } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { Input, Label, Spinner } from "reactstrap";

interface ContractInvoiceFormProps {
  openModal: boolean;
  toggleModal: () => void;
}
interface FormItemProps {
  inputId: string;
  label: string;
  children: React.ReactNode;
  orientation?: "horizontal" | "vertical";
}

const FormItem = ({
  inputId,
  label,
  orientation = "horizontal",
  children,
}: FormItemProps) => {
  return (
    <div
      style={{
        display: orientation === "horizontal" ? "grid" : "flex",
        flexDirection: orientation === "horizontal" ? "row" : "column",
        gridTemplateColumns: "150px auto",
        alignItems: orientation === "horizontal" ? "center" : "flex-start",
        gap: orientation === "horizontal" ? "0" : "1rem",
      }}
    >
      <Label className="m-0" for={inputId}>
        {label}
      </Label>
      {children}
    </div>
  );
};

const ContractInvoiceForm = ({
  openModal,
  toggleModal,
}: ContractInvoiceFormProps) => {
  const { formValues, handleFormChange, onSubmit, isLoading, error } =
    useHandleForm();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomModal isOpen={openModal} handleToggle={toggleModal}>
      <div className="d-flex flex-column gap-3 p-3 theme-bg-light rounded">
        <p className="fs-16 fw-bold border py-2 px-3 bg-secondary text-white rounded">
          Kontrat İşlemine Fatura Kes
        </p>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "400px" }}
          >
            <Spinner />
          </div>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <FormItem inputId="hotel-contract-invoice-date" label="Tarih">
              <Flatpickr
                id="hotel-contract-invoice-date"
                className="form-control"
                value={formValues.tarih}
                onChange={(date) => {
                  const [startDate] = date;

                  if (startDate) {
                    const stringStartDate =
                      moment(startDate).format("YYYY-MM-DD");

                    handleFormChange("tarih", stringStartDate);
                  }
                }}
                options={{
                  dateFormat: "Y-m-d",
                  showMonths: 1,
                  locale: Turkish,
                  onDayCreate,
                }}
              />
            </FormItem>
            <FormItem inputId="hotel-contract-invoice-amount" label="Tutar">
              <Input
                id="hotel-contract-invoice-amount"
                type="number"
                value={formValues.tutar}
                onChange={(e) => handleFormChange("tutar", e.target.value)}
              />
            </FormItem>
            <FormItem
              inputId="hotel-contract-invoice-process-name"
              label="İşlem Adı"
            >
              <Input
                id="hotel-contract-invoice-process-name"
                value={formValues.islem_adi}
                onChange={(e) => handleFormChange("islem_adi", e.target.value)}
              />
            </FormItem>

            <FormItem inputId="hotel-contract-invoice-title" label="Ünvan">
              <Input
                id="hotel-contract-invoice-title"
                value={formValues.unvan}
                onChange={(e) => handleFormChange("unvan", e.target.value)}
                disabled
              />
            </FormItem>

            <FormItem
              inputId="hotel-contract-invoice-tax-office"
              label="Vergi Dairesi"
            >
              <Input
                id="hotel-contract-invoice-tax-office"
                value={formValues.vergi_dairesi}
                onChange={(e) =>
                  handleFormChange("vergi_dairesi", e.target.value)
                }
                disabled
              />
            </FormItem>

            <FormItem
              inputId="hotel-contract-invoice-tax-number"
              label="Vergi No"
            >
              <Input
                id="hotel-contract-invoice-tax-number"
                value={formValues.vergi_no}
                onChange={(e) => handleFormChange("vergi_no", e.target.value)}
                disabled
              />
            </FormItem>

            <FormItem inputId="hotel-contract-invoice-address" label="Adres">
              <Input
                id="hotel-contract-invoice-address"
                value={formValues.adres}
                onChange={(e) => handleFormChange("adres", e.target.value)}
                disabled
              />
            </FormItem>

            <FormItem inputId="hotel-contract-invoice-mail" label="Mail">
              <Input
                id="hotel-contract-invoice-mail"
                value={formValues.mail}
                onChange={(e) => handleFormChange("mail", e.target.value)}
                disabled
              />
            </FormItem>

            <FormItem
              inputId="hotel-contract-invoice-description"
              label="Açıklama"
              orientation="vertical"
            >
              <Input
                id="hotel-contract-invoice-description"
                type="textarea"
                value={formValues.aciklama}
                onChange={(e) => handleFormChange("aciklama", e.target.value)}
                rows="4"
              />
            </FormItem>
            <div className="w-100 d-flex justify-content-end gap-2 mt-4">
              <CustomButton variant="warning" onClick={toggleModal}>
                Vazgeç
              </CustomButton>
              <CustomButton variant="success" onClick={onSubmit}>
                Fatura Kes
              </CustomButton>
            </div>
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default ContractInvoiceForm;
