import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { CreateWebSiteFormChoices, CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
import Select from "react-select";
import { HOTEL_NO_LOCATION_DESCRIPTION } from "utils/fieldDescriptions";

interface HotelNoShowOptionSelectorProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}

interface Option {
  value: string;
  label: string;
}

const HotelNoShowOptionSelector = ({
  values,
  formChoices,
  validateFormChange,
  formErrors,
}: HotelNoShowOptionSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const otelNoOptions = formChoices.otel_numara_goster.map((otelNo) => ({
    value: otelNo.value,
    label: otelNo.text,
  }));

  const selectedOption = otelNoOptions.find((option) => option.value === values.otel_numarasi_goster);

  return (
    <>
      <CustomFormFieldContainer
        label="Otel No Gösterilsin Mi? :"
        error={formErrors.otel_numarasi_goster}
        description={HOTEL_NO_LOCATION_DESCRIPTION}
        divider
        required
      >
        <Select
          options={otelNoOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            if (selected) {
              dispatch(handleChangeInput({ ...values, otel_numarasi_goster: selected.value }));
              validateFormChange({ ...values, otel_numarasi_goster: selected.value });
            };
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelNoShowOptionSelector;
