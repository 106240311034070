import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationCallForm from "./ReservationCallForm";

import { Navigate } from "react-router-dom";
import CalculatePriceForm from "./CalculatePriceForm";
import ChildrenInfo from "./ChildrenInfo";

interface ReservationCallPageProps {
  isValidCall: boolean;
  isLoading: boolean;
  params: {
    aranan: string;
    arayan: string;
    dahili: string;
  };
}

const ReservationCallPage = ({
  isValidCall,
  isLoading,
  params,
}: ReservationCallPageProps) => {
  if (!isValidCall) {
    return <Navigate to="/rezervasyon" />;
  }

  return (
    <div className="d-flex flex-column gap-4">
      <CustomLoadingPage isLoading={isLoading} />
      <ReservationCallForm params={params} />
      <CalculatePriceForm />
      <ChildrenInfo />
    </div>
  );
};

export default ReservationCallPage;
