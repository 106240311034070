import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { CreateWebSiteInitialState } from "slices/myWebSite/createWebSite/reducer";
import {  getWebSite } from "slices/myWebSite/createWebSite/thunk";

const useCreateWebSite = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const { isLoading } = useSelector(
    (state: { CreateWebSite: CreateWebSiteInitialState }) =>
      state.CreateWebSite
  );

  useEffect(() => {
    if (otel_id) {
      dispatch(getWebSite(otel_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    error,
    isValidHotel,
    isLoading,
  };
};

export default useCreateWebSite;
