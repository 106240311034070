import CreateWebSitePage from "Components/Custom/MyWebSitePages/CreateWebSitePage";
import useCreateWebSite from "Components/Hooks/myWebSite/useCreateWebSite";
import PageContainer from "pages/PageContainer";

const CreateWebSite = () => {

  const { loading, error, isValidHotel, isLoading } = useCreateWebSite();

  return (
    <PageContainer title="Web Sitesi Oluştur">
      <CreateWebSitePage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default CreateWebSite;
