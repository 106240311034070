import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { handlePersonnelRequest, setIsLoading, setError, setAnswerRequestIsLoading, setAnswerRequestError } from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { Dispatch } from "redux";
import { GET_PERSONNEL_REQUEST, POST_PERSONNEL_REQUEST } from "helpers/url_helper";
import { AnswerPersonnelRequestPeriod } from "types/personelRequest";

type SuccessCallback = () => void;

export const getPersonnelRequests =
    () => async (dispatch: Dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(GET_PERSONNEL_REQUEST);

            dispatch(handlePersonnelRequest(response.data || response));
        } catch (error) {
            handleErrors(error, (message: string) => {
                dispatch(setError(message));
                dispatch(setToastError(message));
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

export const postPersonnelRequests =
    (payload : AnswerPersonnelRequestPeriod, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
        try {
            dispatch(setAnswerRequestIsLoading(true));
            await axios.post(POST_PERSONNEL_REQUEST, payload);
            successCallback();
            dispatch(setToastSuccess("Personel talebi cevaplandı."));
        } catch (error) {
            handleErrors(error, (message: string) => {
                dispatch(setAnswerRequestError(message));
                dispatch(setToastError(message));
            });
        } finally {
            dispatch(setAnswerRequestIsLoading(false));
        }
    };