import {
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { FACILITY_ROOMS_IMAGES_FORM_DESCRIPTIONS } from "utils/pageDescriptions";
import ImageSelector from "./ImageSelector";

interface AddFacilityRoomImagesFormProps {
  selectedRoom: number;
}

const AddFacilityRoomImagesForm = ({
  selectedRoom,
}: AddFacilityRoomImagesFormProps) => {
  return (
    <>
      {selectedRoom ? (
        <CustomFormContainer title="Tesise Yeni Resim Ekleme" lg={12} xl={12}>
          <div className="d-flex flex-column gap-2 bg-white px-4 py-2 rounded">
            <CustomPageDescriptions
              descriptions={FACILITY_ROOMS_IMAGES_FORM_DESCRIPTIONS}
            />
          </div>
          <ImageSelector selectedRoom={selectedRoom} />
        </CustomFormContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddFacilityRoomImagesForm;
