import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { handleSaveNotifications, setIsLoading, setError } from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from "helpers/url_helper";
import { Dispatch } from "redux";

interface GetNotificationsParams {
  per_page: number;
  search: string;
}

type SuccessCallback = () => void;

export const getNotifications =
  (params: GetNotificationsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const response = await axios.get(GET_NOTIFICATIONS, { params });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveNotifications(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const readNotifications =
  (payload: { id: number }, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      await axios.post(READ_NOTIFICATION, payload);

      successCallback();
      dispatch(setToastSuccess("Bildirim 'okundu' olarak işaretlendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
