import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import ListItem from "../ListItem";

interface ReservationPaymentInfoProps {
  reservation: ReservationReview;
}

const ReservationAvailabilityInfo = ({
  reservation,
}: ReservationPaymentInfoProps) => {
  return (
    <CustomFormContainer title="Müsaitlik Bilgileri">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <ListItem title="Müsait mi" value={reservation.musaitlik} topBorder />
        <ListItem
          title="Görüşme Saati"
          value={reservation.musaitlik_alis_zamani}
          topBorder
        />
        <ListItem title="Görüşen Kişi" value={reservation.musaitlik_alan} />
        <div></div>
      </div>
      <div className="d-flex flex-column bg-white">
        <span className="border border-2 fw-medium p-2 bg-light">Açıklama</span>
        <span className="border border-start-0 border-2 p-2">
          {reservation.musaitlik_aciklama || "-"}
        </span>
      </div>
    </CustomFormContainer>
  );
};

export default ReservationAvailabilityInfo;
