import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ChildInformation } from "types/PricesQuotas";
import { CustomButton, CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { handleChangeInput } from "slices/pricesAndQuota/childInformation/reducer";

import { getChildInformation } from "slices/pricesAndQuota/childInformation/thunk";

interface ChildSpecialAgeSelectProps {
  childInformationState: ChildInformation;
  childSpecialAgeOptions: { value: number; label: string }[];
  isClicked: string;
  setIsClicked: React.Dispatch<React.SetStateAction<string>>;
  hotelId: number;
  roomId: number;
}

const ChildSpecialAgeSelect = ({
  childInformationState,
  childSpecialAgeOptions,
  isClicked,
  setIsClicked,
  hotelId,
  roomId,
}: ChildSpecialAgeSelectProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Çocuk Özel Yaş">
        <Select
          placeholder="Seçiniz..."
          value={
            childSpecialAgeOptions.find(
              (option) =>
                option.value ===
                childInformationState.cocuk_bilgileri[0].ozel_yas_id
            ) || childSpecialAgeOptions[0]
          }
          options={childSpecialAgeOptions}
          onChange={(selectedOption: any) => {
            const payload = {
              otel_id: hotelId,
              oda_id: roomId,
              yas_id: selectedOption.value,
              tip: "tek",
            };

            dispatch(
              getChildInformation(payload, {
                type: "yas_id",
                value: selectedOption.value,
              })
            );

            dispatch(handleChangeInput({ yas_id: selectedOption.value }));
          }}
        ></Select>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="">
        <div className="d-flex align-items-center gap-3 mt-3">
          <div>
            <CustomButton
              variant="success"
              onClick={() => {
                setIsClicked("0");
              }}
              disabled={isClicked === "0"}
            >
              2+ Yetişkin Yanında
            </CustomButton>
          </div>
          {childInformationState.oda_genel_bilgileri.tek_yani_cocuk === "1" && (
            <div>
              <CustomButton
                variant="success"
                onClick={() => {
                  setIsClicked("1");
                }}
                disabled={isClicked === "1"}
              >
                1 Yetişkin Yanında
              </CustomButton>
            </div>
          )}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default ChildSpecialAgeSelect;
