import {
  BankInstallment,
  CalculatePriceForHotelConceptPrice,
} from "types/reservation";
import ConceptPrice from "../ConceptPrice";
import ConceptInfo from "../../ConceptInfo";
import PriceCalculationStatusMessages from "../../PriceCalculationStatusMessages";
import useHandleIptalEdilemezPayButton from "../../PayButtonsHooks/useHandleIptalEdilemezPayButton";
import IptalEdilemezPayButton from "../PayButtons/IptalEdilemezPayButton";

interface IptalEdilemezPaymentProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  ayni_fiyatli_odeme_tipleri: string[];
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  gifts: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  banka_taksit: BankInstallment[];
}

const IptalEdilemezPayment = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  priceCalculationStatusMessages,
  kontenjan,
  gifts,
  onClick,
  banka_taksit,
}: IptalEdilemezPaymentProps) => {
  const {
    hasPrice,
    stop,
    stopMessage,
    showIptalEdilemezButton,
    iptalEdilemezFiyatDurum,
    iptalEdilemezPriceButtonText,
    iptalEdilemezPrice,
    iptalEdilemezOverlinedPrice,
    messages,
  } = useHandleIptalEdilemezPayButton({
    fiyatlar,
    priceCalculationStatusMessages,
  });

  return (
    <div
      className="rounded p-2 border bg-white"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        minHeight: "120px",
      }}
    >
      <ConceptInfo
        priceType={["iptal_edilemez"]}
        oda_id={oda_id}
        pansiyon_id={pansiyon_id}
        pansiyon_adi={pansiyon_adi}
        fiyatlar={fiyatlar}
        kontenjan={kontenjan}
        gifts={gifts}
      />
      <ConceptPrice
        showPrice={showIptalEdilemezButton}
        price={iptalEdilemezPrice}
        overlinedPrice={iptalEdilemezOverlinedPrice}
        banka_taksit={banka_taksit}
      />

      {hasPrice ? (
        <IptalEdilemezPayButton
          oda_id={oda_id}
          oda_adi={oda_adi}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          stop={stop}
          stopMessage={stopMessage}
          iptalEdilemezFiyatDurum={iptalEdilemezFiyatDurum}
          iptalEdilemezPrice={iptalEdilemezPrice}
          iptalEdilemezPriceButtonText={iptalEdilemezPriceButtonText}
          onClick={onClick}
          showIptalEdilemezButton={showIptalEdilemezButton}
        />
      ) : (
        <>
          <div></div>
          <PriceCalculationStatusMessages messages={messages} />
        </>
      )}
    </div>
  );
};

export default IptalEdilemezPayment;
