import { ListPlacesToVisitPage } from "Components/Custom/DefinitionsPages";
import useListPlacesToVisit from "Components/Hooks/definitions/useListPlacesToVisit";
import PageContainer from "pages/PageContainer";

const ListPlacesToVisit = () => {
  const { placesToVisitList, placeToVisit, error, isLoading } =
    useListPlacesToVisit();

  return (
    <PageContainer title="Gezilecek Yer Listele">
      <ListPlacesToVisitPage
        placesToVisitList={placesToVisitList}
        placeToVisit={placeToVisit}
        loading={isLoading}
        error={error}
      />
    </PageContainer>
  );
};

export default ListPlacesToVisit;
