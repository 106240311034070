import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  handleDeleteFromNightRestrictionsWithId,
  handleSaveNightRestrictionForEdit,
} from "slices/discountsAndActions/nightRestrictions/reducer";
import { deleteNightRestriction } from "slices/discountsAndActions/nightRestrictions/thunk";
import {
  NightRestriction,
  NightRestrictionState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { nightRestrictions } = useSelector(
    (state: any) => state.NightRestrictions
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    if (selectedRow) {
      dispatch(
        handleSaveNightRestrictionForEdit({
          ...selectedRow,
          gunler: JSON.parse(selectedRow.gunler as unknown as string),
        })
      );
      window.scrollTo(0, 0);
    }
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      gece_kisitlamalari_id: id,
    };
    dispatch(deleteNightRestriction(payload) as any);
    dispatch(handleDeleteFromNightRestrictionsWithId(id));
  };

  const tableData: NightRestrictionState[] = nightRestrictions?.map(
    (restriction: NightRestriction) => {
      return {
        id: restriction.id,
        otel_id: restriction.otel_id,
        otel_oda_id: restriction.otel_oda_id,
        baslangic_tarih: moment(restriction.baslangic_tarih).format(
          "YYYY-MM-DD"
        ),
        bitis_tarih: moment(restriction.bitis_tarih).format("YYYY-MM-DD"),
        minimum_gece: restriction.minimum_gece,
        giris_bazli: restriction.giris_bazli,
        tum_gunler: restriction.tum_gunler,
        gunler: restriction.gunler,
        yineleme: restriction.yineleme,
        toplam_geceden_hesaplama: restriction.toplam_geceden_hesaplama,
        grup_id: restriction.grup_id,
        tum_otel_pansiyonlar: restriction.tum_otel_pansiyonlar,
        otel_pansiyonlar: restriction.otel_pansiyonlar ?? [],
      };
    }
  );

  const tableColumns = [
    {
      header: "Tarih Aralığı",
      accessorKey: "tarih_araligi",
      cell: (cell: any) => {
        return (
          <>
            <span>
              {moment(cell.row.original.baslangic_tarih).format("DD.MM.YYYY")}
              <br />
              {moment(cell.row.original.bitis_tarih).format("DD.MM.YYYY")}
            </span>
          </>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Gecelemeler ve Min. Geceleme",
      accessorKey: "minimum_gece",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Bazlı",
      accessorKey: "giris_bazli",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      enableColumnFilter: false,
    },
    {
      header: "Yineleme",
      accessorKey: "yineleme",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      enableColumnFilter: false,
    },
    {
      header: "Toplam Geceden Hesapla",
      accessorKey: "toplam_geceden_hesaplama",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      accessorKey: "tum_gunler",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const allDays = cell.row.original.tum_gunler;

        return (
          <span className="d-flex gap-2 flex-wrap">
            {allDays === "1" || days.length === 7 ? (
              <CustomRowBadge value="Tüm Günler" />
            ) : (
              days.map((day: string, index: number) => (
                <CustomRowBadge key={index} value={day} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "gunler",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalar",
      accessorKey: "grup_id",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === 0} isYesNo />;
      },
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue();

        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "operations",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
