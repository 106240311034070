import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Dispatch } from "redux";
import { Input } from "reactstrap";
import { handleSelectRoom } from "slices/pricesAndQuota/childInformation/reducer";

import {
  CustomHorizontalSlidingBox,
  CustomRoomSelectorButton,
} from "Components/Custom/UI";
import { getChildInformation } from "slices/pricesAndQuota/childInformation/thunk";

interface RoomSelectorProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
}

const RoomSelector = ({ rooms }: RoomSelectorProps) => {
  const { selectedRoom } = useSelector((state: any) => state.ChildInformations);

  const dispatch: Dispatch<any> = useDispatch();
  return (
    <CustomHorizontalSlidingBox>
      {rooms.map((room) => (
        <Fragment key={`option${room.oda_id}`}>
          <Input
            id={`option${room.oda_id}`}
            type="radio"
            className="btn-check"
            name="room-selector"
            value={room.oda_id}
            onChange={() => {
              dispatch(handleSelectRoom(room.oda_id));
              dispatch<any>(
                getChildInformation({
                  otel_id: room.otel_id,
                  oda_id: room.oda_id,
                  yas_id: 0,
                  tip: "tek",
                })
              );
            }}
            checked={selectedRoom === room.oda_id}
          />
          <CustomRoomSelectorButton
            selected={selectedRoom === room.oda_id}
            label={room.oda_isim}
            htmlFor={`option${room.oda_id}`}
          />
        </Fragment>
      ))}
    </CustomHorizontalSlidingBox>
  );
};

export default RoomSelector;
