import { AdsReportPage } from "Components/Custom/AdsAndAnalyzePages";
import useAdsReport from "Components/Hooks/adsAndAnalyze/useAdsReport";
import PageContainer from "pages/PageContainer";

const AdsReport = () => {
  const { isLoading, managersLoading, summaryLoading } = useAdsReport();
  return (
    <PageContainer title="Reklam Raporları">
      <AdsReportPage
        isLoading={isLoading}
        managersLoading={managersLoading}
        summaryLoading={summaryLoading}
      />
    </PageContainer>
  );
};

export default AdsReport;
