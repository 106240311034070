import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  setFacilityManagersLoading,
  setFacilityManagersError,
  handleSaveFacilityCalls,
  handleSaveFacilityManagers,
  setUpdateCallLoading,
  setUpdateCallError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { UpdateFacilityCallRequestPayload } from "types/facility";

import {
  GET_FACILITY_CALLS,
  GET_FACILITY_MANAGERS,
  UPDATE_FACILITY_CALL,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetFacilityCallsParams {
  page: number;
  per_page: number;
  search: string;
  start_date: string;
  end_date: string;
}

type SuccessCallback = () => void;

export const getFacilityCalls =
  (params: GetFacilityCallsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FACILITY_CALLS, { params });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveFacilityCalls(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateFacilityCall =
  (
    payload: UpdateFacilityCallRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateCallLoading(true));

      await axios.post(UPDATE_FACILITY_CALL, payload);

      successCallback();
      dispatch(setToastSuccess("Arama durumu güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateCallError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateCallLoading(false));
    }
  };

export const getFacilityManagers =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(handleSaveFacilityManagers([]));
      dispatch(setFacilityManagersLoading(true));
      const response = await axios.get(GET_FACILITY_MANAGERS + otel_id);

      dispatch(handleSaveFacilityManagers(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setFacilityManagersError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setFacilityManagersLoading(false));
    }
  };
