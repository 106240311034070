import { useState } from "react";
import ViewFeaturedImagesAlbum from "./ViewFeaturedImagesAlbum";
import ViewFeaturedImagesTable from "./ViewFeaturedImagesTable";

interface ViewFeaturedImagesPageProps {
  loading: boolean;
}
const ViewFeaturedImagesPage = ({ loading }: ViewFeaturedImagesPageProps) => {
  const [index, setIndex] = useState(-1);

  const handleChangeIndex = (index: number) => {
    setIndex(index);
  };
  return (
    <>
      <ViewFeaturedImagesTable
        loading={loading}
        handleChangeIndex={handleChangeIndex}
      />
      <ViewFeaturedImagesAlbum
        index={index}
        handleChangeIndex={handleChangeIndex}
      />
    </>
  );
};

export default ViewFeaturedImagesPage;
