import { useDispatch, useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import {
  handleChangeInput,
  UpdateUserInitialState,
} from "slices/userOperations/updateUser/reducer";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { userToUpdate, updatedUser, updateUserFormErrors, updateUserLoading } =
    useSelector(
      (state: { UpdateUser: UpdateUserInitialState }) => state.UpdateUser
    );

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const updateUserRequestPayload = new FormData();

  const [selectedUserAuthorityOptions, setSelectedUserAuthorityOptions] =
    useState<string[]>([]);

  useEffect(() => {
    if (userToUpdate.yetkiler && userToUpdate.yetkiler.length > 0) {
      const selectedAuthorities = userToUpdate.yetkiler
        .filter((authority) => authority.selected === "selected")
        .map((authority) => authority.modul);

      setSelectedUserAuthorityOptions(selectedAuthorities);
    }
  }, [userToUpdate.yetkiler]);

  const handleSelectUserAuthorityOptions = (selected: string[]) => {
    setSelectedUserAuthorityOptions(selected);

    const selectedAuthorityIds = userToUpdate.yetkiler
      .filter((authority) => selected.includes(authority.modul))
      .map((authority) => authority.modul_id);

    dispatch(handleChangeInput({ yetkiler: selectedAuthorityIds }));
  };

  const userTypeOptions = userToUpdate.kullanici_tipi[0]?.map((userType) => ({
    value: userType.value,
    label: userType.name,
  }));

  const jobTypeOptions = userToUpdate.calisma_tipi.map((jobType) => ({
    value: jobType.value,
    label: jobType.name,
  }));

  const genderOptions = userToUpdate.cinsiyet.map((gender) => ({
    value: gender.value,
    label: gender.name,
  }));

  const userAuthorityOptions = userToUpdate.yetkiler.map((userAuthority) => ({
    value: userAuthority.modul,
    label: userAuthority.modul_adi,
  }));

  return {
    userTypeOptions,
    jobTypeOptions,
    genderOptions,
    userAuthorityOptions,
    selectedOptions: selectedUserAuthorityOptions,
    handleSelectOptions: handleSelectUserAuthorityOptions,
    values: updatedUser,
    formErrors: updateUserFormErrors,
    updateUserLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    updateUserRequestPayload,
  };
};

export default useHandleForm;
