import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import Select from "react-select";
import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
} from "types/PricesQuotas";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeGeneralPriceSettingsInput } from "slices/pricesAndQuota/generalPriceSettings/reducer";

interface FacilityTypeProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  facilityTypesOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}
const FacilityType = ({
  values,
  formErrors,
  validateFormChange,
  facilityTypesOptions,
}: FacilityTypeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Tesis Sınıfı">
      <CustomFormFieldContainer label="Tesis Sınıfı">
        <Select
          value={
            facilityTypesOptions.find(
              (option) => option.value === values.Tesis_sinif
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                Tesis_sinif: selectedOption.value,
              })
            );

            validateFormChange({
              ...values,
              Tesis_sinif: selectedOption.value as
                | "sehir"
                | "crm"
                | "termal"
                | "kayak"
                | "operator"
                | "islami",
            });
          }}
          options={facilityTypesOptions}
          placeholder="Tesis Sınıfı Seçiniz"
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityType;
