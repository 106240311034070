import { CustomLoadingPage } from "Components/Custom/UI";
import ListHotelsWithoutCommentsTable from "./ListHotelsWithoutCommentTable";

interface ListHotelsWithoutCommentsPageProps {
  isLoading: boolean;
}

const ListHotelsWithoutCommentsPage = ({
  isLoading,
}: ListHotelsWithoutCommentsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ListHotelsWithoutCommentsTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListHotelsWithoutCommentsPage;
