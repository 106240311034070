import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import FacilityAddress from "./FacilityAddress";
import FacilityLocation from "./FacilityLocation";

interface FacilityLocationFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  facilityLocationOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const 
FacilityLocationFields = ({
  values,
  formErrors,
  validateFormChange,
  facilityLocationOptions,
}: FacilityLocationFieldsProps) => {
  return (
    <CustomFormContainer title="Otelin Konum Bilgileri" lg={12} xl={12}>
      <FacilityAddress
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityLocation
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityLocationOptions={facilityLocationOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityLocationFields;
