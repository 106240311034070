import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AddEarlyReservationDefinitionInitialState } from "slices/definitions/addEarlyReservationDefinition/reducer";
import { getEarlyReservationDefinition } from "slices/definitions/addEarlyReservationDefinition/thunk";

const useAddEarlyReservationDefinition = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: {
      AddEarlyReservationDefinition: AddEarlyReservationDefinitionInitialState;
    }) => state.AddEarlyReservationDefinition
  );

  useEffect(() => {
    dispatch(getEarlyReservationDefinition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useAddEarlyReservationDefinition;
