import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { ForgotPasswordPage } from "Components/Custom";
import withRouter from "../../Components/Common/withRouter";

import { Router } from "types";

const ForgetPassword = (props: Router) => {
  document.title = "Reset Password | Velzon - React Admin & Dashboard Template";
  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <ForgotPasswordPage {...props} />
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(ForgetPassword);
