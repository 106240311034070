import { useState } from "react";

interface ImageContainerProps {
  id: string;
  src: string;
  alt: string;
  handleDelete: (id: string) => void;
}

const ImageContainer = ({
  id,
  src,
  alt,
  handleDelete,
}: ImageContainerProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className="position-relative rounded w-100 border rounded"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className={
          hovered
            ? "opacity-25 rounded w-100 cursor-pointer"
            : "rounded w-100 cursor-pointer"
        }
        src={src}
        alt={alt}
        height={150}
        style={{ objectFit: "cover" }}
      />
      {hovered && (
        <div className="position-absolute top-0 left-0 d-flex align-items-center justify-content-center w-100 h-100 rounded">
          <i
            className="ri-delete-bin-fill theme-text-danger cursor-pointer"
            style={{ fontSize: "2.5rem" }}
            title="Resmi Sil"
            onClick={() => {
              handleDelete(id);
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

export default ImageContainer;
