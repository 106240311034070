import { ExtensionSituationCardData } from "types/dashboard";

interface TableRow {
  order: number;
  uye_id: number;
  ad_soyad: string;
  dahili: number;
  grup_adi: string;
  tarih: string;
  ilk_islem_zamani: string;
  son_islem_zamani: string;
  cevrimici: string;
  mesgul: string;
  yemekte: string;
  disarida: string;
  cevrimdisi: string;
  gorusme: string;
}

const useHandleTable = (value: ExtensionSituationCardData) => {
  const tableData: TableRow[] = [];
  let orderCounter = 1; 

  Object.entries(value).forEach(([userId, group]) => {
    const dahiliDurumEntries = group.dahili_durum;

    if (dahiliDurumEntries && typeof dahiliDurumEntries === "object") {
      Object.entries(dahiliDurumEntries).forEach(([dateKey, durum]) => {
        tableData.push({
          order: orderCounter++,
          uye_id: group.uye_id,
          ad_soyad: group.ad_soyad,
          dahili: group.dahili,
          grup_adi: group.grup_adi,
          tarih: durum.tarih || dateKey,
          ilk_islem_zamani: durum.ilk_islem_zamani || "",
          son_islem_zamani: durum.son_islem_zamani || "",
          cevrimici: durum.cevrimici || "00:00:00",
          mesgul: durum.mesgul || "00:00:00",
          yemekte: durum.yemekte || "00:00:00",
          disarida: durum.disarida || "00:00:00",
          cevrimdisi: durum.cevrimdisi || "00:00:00",
          gorusme: durum.gorusme || "00:00:00",
        });
      });
    }
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "order",
      enableColumnFilter: false,
    },
    {
      header: "Tarih B.",
      accessorKey: "tarih",
      enableColumnFilter: false,
    },
    {
      header: "Ad Soyad",
      accessorKey: "ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ maxWidth: "200px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Dahili",
      accessorKey: "dahili",
      enableColumnFilter: false,
    },
    {
      header: "Ç. İçi",
      accessorKey: "cevrimici",
      enableColumnFilter: false,
    },
    {
      header: "Görüşme",
      accessorKey: "gorusme",
      enableColumnFilter: false,
    },
    {
      header: "Meşgul",
      accessorKey: "mesgul",
      enableColumnFilter: false,
    },
    {
      header: "Yemekte",
      accessorKey: "yemekte",
      enableColumnFilter: false,
    },
    {
      header: "Dışarıda",
      accessorKey: "disarida",
      enableColumnFilter: false,
    },
    {
      header: "Ç.Dışı",
      accessorKey: "cevrimdisi",
      enableColumnFilter: false,
    },
    {
      header: "İ İşlem T.",
      accessorKey: "ilk_islem_zamani",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ maxWidth: "400px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "S İşlem T.",
      accessorKey: "son_islem_zamani",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ maxWidth: "400px" }}>
            {value}
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
