import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";

interface CitySelectorProps {
  cityFormChoices: { [key: string]: string };
  selectedCity: number;
  handleSelectCity: (id: number) => void;
}

interface Option {
  value: string;
  label: string;
}

const CitySelector = ({
  cityFormChoices,
  selectedCity,
  handleSelectCity,
}: CitySelectorProps) => {

  const cityOptions: Option[] = Object.entries(cityFormChoices).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const selectedOptions = cityOptions.find(
  (option) => option.value === selectedCity.toString()
);

  return (
    <CustomFormFieldContainer label={"Şehirler : "} required>
      <Select
        options={cityOptions}
        value={selectedOptions}
        onChange={(selected: Option) => {
            const cityId = selected ? Number(selected.value) : 0;
            handleSelectCity(cityId);
        }}
        placeholder="Şehir seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default CitySelector;
