import { useSelector } from "react-redux";
import { InformationTechnologiesInitialState } from "slices/userOperations/informationTechnologies/reducer";

const useInformationTechnologies = () => {

  const { isLoading, error } = useSelector(
    (state: { InformationTechnologies: InformationTechnologiesInitialState }) => state.InformationTechnologies
  );


  return {
    isLoading,
    error,
  };
};

export default useInformationTechnologies;
