import { CustomButton } from "Components/Custom/UI";

interface SavePricesButtonProps {
  onSave: () => void;
  isLoading: boolean;
}

const SavePricesButton = ({ isLoading, onSave }: SavePricesButtonProps) => {
  return (
    <div className="d-flex justify-content-end mt-4 mb-2">
      <CustomButton variant="primary" onClick={onSave} isLoading={isLoading}>
        Kaydet
      </CustomButton>
    </div>
  );
};

export default SavePricesButton;
