import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { AddFooterLinkInitialState } from "slices/myWebSite/addFooterLinks/reducer";


const useHandleForm = () => {
  const {addFooterLink, isLoading, footerLinks, footerLinkFormErrors} = useSelector(
    (state: { AddFooterLink: AddFooterLinkInitialState }) => state.AddFooterLink
  );

  const addFooterRequestPayload = new FormData();

  // console.log("addFooterRequestPayload in form hook :", addFooterRequestPayload)

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    addFooterLink,
    footerLinkFormErrors,
    footerLinks,
    addFooterRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading
  };
};

export default useHandleForm;
