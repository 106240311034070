import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dispatch } from "redux";

const useEditReservation = () => {
  const [searchParams] = useSearchParams();

  const dispatch: Dispatch<any> = useDispatch();

  const reservationId = searchParams.get("rezervasyon_id");

  const isValid = reservationId ? true : false;

  return {
    isValid,
  };
};

export default useEditReservation;
