import { useSelector } from "react-redux";
import { FacilityRoomsInitialState } from "slices/facility/facilityRooms/reducer";
import useValidateForm from "./useValidateForm";
import { useEffect, useState } from "react";

interface Option {
  value: number;
  label: string;
  key: string;
}

const useHandleForm = (selectedRoom: number) => {
  const {
    facilityRoom,
    updateFacilityRoom,
    updateFacilityRoomFormErrors,
    facilityRoomImages,
    updateFacilityRoomFeaturesLoading,
  } = useSelector(
    (state: { FacilityRooms: FacilityRoomsInitialState }) => state.FacilityRooms
  );

  const [roomFeaturesOptions, setRoomFeaturesOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (facilityRoom["oda özellikleri"]) {
      const features: Option[] = Object.entries(facilityRoom["oda özellikleri"])
        .map(([key, value]) =>
          value.map((item: any) => ({
            value: item.ozellik_id,
            label: item.ozellik_adi,
            key,
          }))
        )
        .flat();

      setRoomFeaturesOptions(features);
    }
  }, [facilityRoom]);

  const updateFacilityRoomRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    updateFacilityRoom,
    facilityRoomImages,
    roomFeaturesOptions,
    updateFacilityRoomRequestPayload,
    formErrors: updateFacilityRoomFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading: updateFacilityRoomFeaturesLoading,
  };
};

export default useHandleForm;
