import { EnterReservationFormValues } from "types/reservation";
import ReservationInfoForm from "./ReservationInfoForm";
import useHandleEnterReservationPage from "./useHandleEnterReservationPage";
import CreateRoomForm from "./CreateRoomForm";
import AddedRoomList from "./AddedRoomsList";
import PaymentForm from "./PaymentForm";
import InvoiceInfoAndOtherFieldsForm from "./InvoiceInfoAndOtherFieldsForm";
import SaveReservationButton from "./SaveReservationButton";
import KapidaPaymentForm from "./KapidaPaymentForm";

interface EnterReservationPageProps {
  values: EnterReservationFormValues;
}

const EnterReservationPage = ({ values }: EnterReservationPageProps) => {
  const {
    formValues,
    handleChangeInput,
    roomConceptTypeOptions,
    employeesOptions,
    onCalculatePrice,
    calculatedPrice,
    moneyPoint,
    specialDiscounts,
    onRequestRoom,
    onRemoveAddedRoom,
    onUpdatedAddedRooms,
    installments,
    banks,
    addedRoomsInfo,
    invoiceInfoFormValues,
    reservationPaymentInfo,
    setReservationPaymentInfo,
    handleInvoiceInfoChangeInput,
  } = useHandleEnterReservationPage(values);

  return (
    <div className="d-flex flex-column gap-4">
      <ReservationInfoForm
        formValues={formValues}
        handleChangeInput={handleChangeInput}
        roomConceptTypeOptions={roomConceptTypeOptions}
        onCalculatePrice={onCalculatePrice}
        calculatedPrice={calculatedPrice}
        moneyPoint={moneyPoint}
        specialDiscounts={specialDiscounts}
      />
      {calculatedPrice ?? formValues.fiyat ? (
        <CreateRoomForm
          formValues={formValues}
          calculatedPrice={calculatedPrice ?? formValues.fiyat}
          onRequestRoom={onRequestRoom}
        />
      ) : (
        <></>
      )}
      <AddedRoomList
        formValues={formValues}
        addedRoomsInfo={addedRoomsInfo}
        onUpdatedAddedRooms={onUpdatedAddedRooms}
        onRemoveAddedRoom={onRemoveAddedRoom}
      />
      {formValues.odeme_tipi === "kapida" ? (
        <KapidaPaymentForm
          formValues={formValues}
          addedRoomsInfo={addedRoomsInfo}
        />
      ) : (
        <PaymentForm
          reservationPaymentInfo={reservationPaymentInfo}
          setReservationPaymentInfo={setReservationPaymentInfo}
          formValues={formValues}
          calculatedPrice={calculatedPrice}
          installments={installments}
          banks={banks}
          addedRoomsInfo={addedRoomsInfo}
        />
      )}
      <InvoiceInfoAndOtherFieldsForm
        employeesOptions={employeesOptions}
        formValues={invoiceInfoFormValues}
        handleChangeInput={handleInvoiceInfoChangeInput}
        paymentType={formValues.odeme_tipi}
      />
      <SaveReservationButton
        formValues={formValues}
        paymentType={formValues.odeme_tipi}
        addedRoomsInfo={addedRoomsInfo}
        reservationPaymentInfo={reservationPaymentInfo}
        invoiceInfoFormValues={invoiceInfoFormValues}
      />
    </div>
  );
};

export default EnterReservationPage;
