import { useEffect, useRef, useState } from "react";
import parse from 'html-react-parser';

const useWebSocket = (url: string) => {
  const socketRef = useRef<WebSocket | null>(null);
  const [message, setMessage] = useState<string>("");
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    const connect = () => {
      socketRef.current = new WebSocket(url);

      socketRef.current.onopen = () => {
        setIsConnected(true);
      };

      socketRef.current.onmessage = (event) => {
        if (event.data === "ping") {
          socketRef.current?.send("pong");
        } else {
          setMessage(event.data);
        }
      };

      socketRef.current.onclose = () => {
        setIsConnected(false);
        setTimeout(connect, 1000);
      };

      socketRef.current.onerror = (error) => {
        // console.log("WebSocket error:", error);
        socketRef.current?.close();
      };
    };

    connect();

    return () => {
      socketRef.current?.close();
    };
  }, [url]);

  const sendMessage = (msg: string) => {
    if (socketRef.current && isConnected) {
      socketRef.current.send(msg);
    } else {
      console.log("WebSocket bağlanamadı.");
    }
  };

  return { message, data : parse(message), sendMessage, isConnected };
};

export default useWebSocket;
