import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import {
  AddAdditionalPagesFormErrors,
  AdditionalPageState,
} from "types/myWebSite";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";
import { Input } from "reactstrap";

interface LinkNameInputProps {
  values: AdditionalPageState;
  formErrors: AddAdditionalPagesFormErrors;
  validateFormChange: (values: AdditionalPageState) => void;
}

const LinkNameInput = ({
  values,
  validateFormChange,
  formErrors,
}: LinkNameInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Açıklama :"
        orientation="horizontal"
        error={formErrors.organizasyon_aciklama}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.organizasyon_aciklama}
          onChange={(e) => {
            dispatch(
              handleChangeAddAdditionalPageInput({
                organizasyon_aciklama: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              organizasyon_aciklama: e.target.value,
            });
          }}
          style={{minHeight:'80px'}}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default LinkNameInput;
