import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveCustomerMails, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_ALL_CUSTOMER_MAILS,
  GET_FILTERED_CUSTOMER_MAILS,
} from "helpers/url_helper";

import { FilterCustomerMailsRequestPayload } from "types/reports";

import { Dispatch } from "redux";

export const getAllCustomerMails = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_ALL_CUSTOMER_MAILS);

    dispatch(handleSaveCustomerMails(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getFilteredCustomerMails =
  (payload: FilterCustomerMailsRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_FILTERED_CUSTOMER_MAILS, payload);

      dispatch(handleSaveCustomerMails(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
