import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { AddCertificateInitialState } from "slices/myWebSite/addCertificate/reducer";


const useHandleForm = () => {
  const {addCertificate, addCertificateLoading, isLoading, certificateLinkFormErrors} = useSelector(
    (state: { AddCertificate: AddCertificateInitialState }) => state.AddCertificate
  );

  const addCertificateRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    addCertificate,
    certificateLinkFormErrors,
    addCertificateRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addCertificateLoading,
    isLoading
  };
};

export default useHandleForm;
