import moment from "moment";
import { useSelector } from "react-redux";
import { SpecialDaysInitialState } from "slices/facility/listSpecialDays/reducer";

const useHandleTable = () => {
  const { specialDays } = useSelector(
    (state: { ListSpecialDays: SpecialDaysInitialState }) =>
      state.ListSpecialDays
  );

  const tableData = specialDays?.map((specialDay) => ({
    otel_adi: specialDay.otel_adi,
    kategori: specialDay.kategori.ozel_gun_kategori_adi,
    baslik: specialDay.ozel_gun_baslik,
    tarih: `${moment(specialDay.ozel_gun_baslangic).format(
      "DD.MM.YYYY"
    )} - ${moment(specialDay.ozel_gun_bitis).format("DD.MM.YYYY")}`,
    sanatci: specialDay.ozel_gun_sanatci_adi,
    web_sitesi: specialDay.oteldenal_sef_link,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Kategori",
      accessorKey: "kategori",
      enableColumnFilter: false,
    },
    {
      header: "Başlık",
      accessorKey: "baslik",
      enableColumnFilter: false,
    },
    {
      header: "Tarih Aralığı",
      accessorKey: "tarih",
      enableColumnFilter: false,
    },
    {
      header: "Sanatçı",
      accessorKey: "sanatci",
      enableColumnFilter: false,
    },
    {
      header: "Web Sitesi",
      accessorKey: "web_sitesi",
      cell: (cell: any) => {
        const url = cell.getValue() ?? "#";
        return (
          <a href={url} target="_blank" rel="noreferrer">
            Görüntüle
          </a>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
