import { OutboundCallsListPage } from "Components/Custom/ReportsPages";
import useOutboundCallsList from "Components/Hooks/reports/useOutboundCallsList";
import PageContainer from "pages/PageContainer";

const OutboundCallsList = () => {
  const { isLoading } = useOutboundCallsList();

  return (
    <PageContainer title="Dış Arama Durum (Bugün)">
      <OutboundCallsListPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default OutboundCallsList;
