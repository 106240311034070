import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import PersonnelSelector from "./FormFields/PersonnelSelector";
import TransferReservationButtons from "./FormFields/TransferReservationButtons";

const TransferReservationForm = () => {
  const {
    formState,
    personnelOptions,
    handleFormChange,
    handleSave,
    handleClearForm,
  } = useHandleForm();

  return (
    <>
      <CustomFormContainer title="Rezervasyon Aktar">
        <ReservationCodeInput
          values={formState}
          handleFormChange={handleFormChange}
        />
        <PersonnelSelector
          values={formState}
          options={personnelOptions}
          handleFormChange={handleFormChange}
        />
        <TransferReservationButtons
          handleSave={handleSave}
          handleClearForm={handleClearForm}
        />
      </CustomFormContainer>
    </>
  );
};

export default TransferReservationForm;
