import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveDefineCurrency,
  handleSaveDefineCurrencyDefault,
  setDefineCurrencyLoading,
  setDefineCurrencyError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_CURRENCY, UPDATE_CURRENCY } from "helpers/url_helper";

import { DefineCurrencyRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

export const getCurrency = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_CURRENCY);

    dispatch(handleSaveDefineCurrency(response.data || response));
    dispatch(handleSaveDefineCurrencyDefault(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateCurrency =
  (payload: DefineCurrencyRequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setDefineCurrencyLoading(true));
      await axios.post(UPDATE_CURRENCY, payload);

      dispatch(setToastSuccess("Kur güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDefineCurrencyError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDefineCurrencyLoading(false));
    }
  };
