import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  calculatePriceAgain,
  getBankInstallments,
  getEmployees,
  getRoomConceptInfo,
  requestRoom,
} from "slices/reservation/reservationCall/thunk";

import { setToastError } from "slices/toast/reducer";
import {
  BankInstallment,
  CalculatePriceForHotel,
  CalculatePriceForHotelRequestParams,
  CalculatePriceForHotelSpecialDiscount,
  CalculatePriceFormErrors,
  CreateRoomAddedRoom,
  CreateRoomFormValues,
  EnterReservationFormValues,
  EnterReservationRequesRoomPayload,
  EnterReservationRoomConcept,
  InvoiceInfoAndOtherFieldsFormValues,
  ReservationPaymentInfo,
} from "types/reservation";

interface Option {
  value: number;
  label: string;
  isSelf: boolean;
}
interface RoomConceptOption {
  value: string;
  label: string;
  oda_id: number;
  pansiyon_id: number;
}

interface BankOption {
  id: number;
  value: string;
  label: string;
}

const validateForm = (
  formValues: EnterReservationFormValues,
  dispatch: Dispatch<any>
) => {
  const errors: CalculatePriceFormErrors = {};

  if (!formValues.giris_tarihi) {
    errors.giris_tarihi = "Lütfen giriş tarihini seçiniz.";
  }
  if (!formValues.cikis_tarihi) {
    errors.cikis_tarihi = "Lütfen çıkış tarihini seçiniz.";
  }
  if (!formValues.yetiskin_sayisi) {
    errors.yetiskin_sayisi = "En az bir yetişkin seçmelisiniz.";
  }
  if (formValues.cocuk_sayisi > 3 && !formValues.cocuk_dogum_4) {
    errors.cocuk_dogum_4 = "4. çocuğun doğum tarihini seçiniz.";
  }
  if (formValues.cocuk_sayisi > 2 && !formValues.cocuk_dogum_3) {
    errors.cocuk_dogum_3 = "3. çocuğun doğum tarihini seçiniz.";
  }
  if (formValues.cocuk_sayisi > 1 && !formValues.cocuk_dogum_2) {
    errors.cocuk_dogum_2 = "2. çocuğun doğum tarihini seçiniz.";
  }
  if (formValues.cocuk_sayisi > 0 && !formValues.cocuk_dogum_1) {
    errors.cocuk_dogum_1 = "1. çocuğun doğum tarihini seçiniz.";
  }

  const isValid = Object.keys(errors).length === 0;

  !isValid && dispatch(setToastError(Object.values(errors)[0]));

  return isValid;
};

const useHandleEnterReservationPage = (values: EnterReservationFormValues) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [roomConceptTypeOptions, setRoomConceptTypeOptions] = useState<
    RoomConceptOption[]
  >([]);

  const [employeesOptions, setEmployeesOptions] = useState<Option[]>([]);

  const [installments, setInstallments] = useState<BankInstallment[]>([]);
  const [banks, setBanks] = useState<BankOption[]>([]);

  const [formValues, setFormValues] =
    useState<EnterReservationFormValues>(values);

  const [moneyPoint, setMoneyPoint] = useState<number>(0);
  const [specialDiscounts, setSpecialDiscounts] = useState<
    CalculatePriceForHotelSpecialDiscount[]
  >([]);

  useEffect(() => {
    const otel_id = values.otel_id;
    setFormValues(values);

    setMoneyPoint(
      values.parapuan_toplam_tutar ? Number(values.parapuan_toplam_tutar) : 0
    );

    setSpecialDiscounts(values.ozel_indirim);

    if (otel_id) {
      dispatch(
        getRoomConceptInfo(otel_id, (res) => {
          const response: EnterReservationRoomConcept = res;

          const index = response.oda_pansiyon_sayi;

          const options: RoomConceptOption[] = Array.from({
            length: index,
          }).map((_, i) => {
            const roomId = response.oda_id[i];
            const room = response.oda_adi[i];
            const concept = response.pansiyon_adi[i];
            const conceptId = response.pansiyon_id[i];

            return {
              value: `${roomId}-${conceptId}`,
              label: `${room.trim()}-${concept.trim()}`,
              oda_id: roomId,
              pansiyon_id: conceptId,
            };
          });

          setRoomConceptTypeOptions(options);
        })
      );

      dispatch(
        getEmployees((res) => {
          const response: Option[] = res.map((employee: any) => ({
            value: employee.id,
            label: employee.ad_soyad,
            isSelf: employee.is_self,
          }));

          setEmployeesOptions(response);
        })
      );

      dispatch(
        getBankInstallments((res) => {
          setInstallments(res);

          const bankOptions: BankOption[] = res.map(
            (bank: BankInstallment) => ({
              id: bank.id,
              value: bank.banka_kodu,
              label: bank.banka_adi,
            })
          );

          const bankOptionsFiltered = bankOptions.filter(
            (option, index, self) =>
              index ===
              self.findIndex(
                (t) => t.value === option.value && t.label === option.label
              )
          );

          setBanks(bankOptionsFiltered);
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const [calculatedPrice, setCalculatedPrice] = useState<number | undefined>(
    undefined
  );

  const handleChangeInput = (key: string, value = {}) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));

    const exceptedFields = [
      "secilen_ozel_indirimler",
      "ozel indirimler",
      "musteri_ad_soyad",
      "musteri_tel",
      "musteri_tel_2",
      "musteri_eposta",
      "musteri_tc",
      "giris_saati",
    ];
    if (!exceptedFields.includes(key)) {
      setCalculatedPrice(0);
    }
  };

  const handleCalculatePrice = () => {
    const payload: CalculatePriceForHotelRequestParams = {
      otel_id: formValues.otel_id,
      giris_tarihi: formValues.giris_tarihi,
      cikis_tarihi: formValues.cikis_tarihi,
      yetiskin_sayisi: formValues.yetiskin_sayisi,
      cocuk_sayisi: formValues.cocuk_sayisi,
      cocuk_dogum_1:
        formValues.cocuk_sayisi > 0 ? formValues.cocuk_dogum_1 : "",
      cocuk_dogum_2:
        formValues.cocuk_sayisi > 1 ? formValues.cocuk_dogum_2 : "",
      cocuk_dogum_3:
        formValues.cocuk_sayisi > 2 ? formValues.cocuk_dogum_3 : "",
      cocuk_dogum_4:
        formValues.cocuk_sayisi > 3 ? formValues.cocuk_dogum_4 : "",
      musteri_id: formValues.musteri_id,
      arama_id: formValues.arama_id,
      musteri_tel: formValues.musteri_tel,
      musteri_ad_soyad: formValues.musteri_ad_soyad,
      geri_arama_id: formValues.geri_arama_id,
      geri_arama_tipi: formValues.geri_arama_tipi,
      option: ["filterx", "filter_zincir"],
      parapuan_secim: formValues.parapuan_secim,
      ozel_indirimler: formValues.ozel_indirimler,
    };
    if (!payload.cocuk_dogum_1) {
      delete payload.cocuk_dogum_1;
    }
    if (!payload.cocuk_dogum_2) {
      delete payload.cocuk_dogum_2;
    }
    if (!payload.cocuk_dogum_3) {
      delete payload.cocuk_dogum_3;
    }
    if (!payload.cocuk_dogum_4) {
      delete payload.cocuk_dogum_4;
    }
    validateForm(formValues, dispatch) &&
      dispatch(
        calculatePriceAgain(payload, (res: CalculatePriceForHotel) => {
          handleChangeInput("ozel_indirimler", []);


          const paymentType = formValues.odeme_tipi;

          const hotel = Object.values(res.fiyatlar)[0];

          const selectedRoom = hotel.odalar.find(
            (oda) => oda.oda_id === formValues.oda_pansiyon_tipi.split("-")[0]
          )!;

          if (!selectedRoom) {
            dispatch(setToastError("Seçilen oda tipi için fiyat bulunamadı."));
            return;
          }

          const selectedConceptId = roomConceptTypeOptions.find(
            (option) => option.value === formValues.oda_pansiyon_tipi
          )?.pansiyon_id!;

          const selectedConcept = selectedRoom.pansiyonlar.find(
            (pansiyon) => Number(pansiyon.pansiyon_id) === selectedConceptId
          )!;

          const priceType =
            paymentType === "normal" || paymentType === "kismi"
              ? "normal"
              : paymentType;

          if (!selectedConcept) {
            dispatch(
              setToastError("Seçilen  pansiyon tipi için fiyat bulunamadı.")
            );
            return;
          }

          const selectedPayment = selectedConcept.fiyatlar[priceType]!;

          const selectedPaymentPrices = Object.entries(selectedPayment)
            .filter((payment) => payment[0].includes(priceType))
            .map((payment) => ({
              [payment[0].replace(priceType + "_", "")]: payment[1],
            }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

          setCalculatedPrice(selectedPaymentPrices.indirimli);

          setMoneyPoint(
            res.parapuan_toplam_tutar ? Number(res.parapuan_toplam_tutar) : 0
          );
          setSpecialDiscounts(res.ozel_indirim);
          handleChangeInput(
            "secilen_ozel_indirimler",
            selectedRoom.secilen_ozel_indirimler ?? []
          );

          setFormValues((prevValues) => ({
            ...prevValues,
            fiyat: selectedPaymentPrices.indirimli,
            ozel_indirim: res.ozel_indirim,
            parapuan_toplam_tutar: res.parapuan_toplam_tutar,
            secilen_ozel_indirimler: selectedRoom.secilen_ozel_indirimler ?? [],
            iptal_sigortasi_durum: res.iptal_sigortasi_durum,
            iptal_sigortasi_gun: res.iptal_sigortasi_gun,
            oda_id: selectedRoom.oda_id,
            oda_adi: selectedRoom.oda_adi,
            pansiyon_id: selectedConcept.pansiyon_id,
            pansiyon_adi: selectedConcept.pansiyon_adi,
            cari: res.cari,
            stop_durum: selectedPayment.stop_durum,
            ucretli_cocuk_durum: selectedPayment.ucretli_cocuk_durum,
            verilen_hediyeler: selectedConcept.verilen_hediyeler,
            otele_odeme_gunu: selectedRoom.otele_odeme_gunu,
            fiyatlar: {
              brut_tutar: selectedPaymentPrices.toplam,
              indirimli_tutar: selectedPaymentPrices.indirimli,
              maliyet_tutar: selectedPaymentPrices.maliyet,
              brut_tutar_kur: selectedPaymentPrices.brut_maliyet_kur,
              indirimli_tutar_kur: selectedPaymentPrices.indirimli_kur,
              maliyet_tutar_kur: selectedPaymentPrices.maliyet_kur,
              iptal_sigortasi_fiyat:
                selectedPaymentPrices.iptal_sigortasi_fiyat,
            },
            uygulanan_indirimler: selectedConcept.uygulanan_indirimler,
          }));
        })
      );
  };

  const [addedRooms, setAddedRooms] = useState<number[]>([]);
  const [addedRoomsInfo, setAddedRoomsInfo] = useState<CreateRoomAddedRoom[]>(
    []
  );

  const handleRemoveAddedRoom = (id: number, index: number) => {
    setAddedRoomsInfo(addedRoomsInfo.filter((room) => room.id !== id));

    setAddedRooms(addedRooms.filter((room, index) => room !== index));
  };
  const handleUpdateAddedRooms = (id: number, room: CreateRoomFormValues) => {
    setAddedRoomsInfo((prev) =>
      prev.map((prevRoom) =>
        prevRoom.id === id ? { ...prevRoom, ...room } : prevRoom
      )
    );
  };

  const handleRequestRoom = (
    oda_id: number,
    price: number,
    room: CreateRoomFormValues,
    handleResetCreateRoomForm: () => void,
    adults: number,
    children: number
  ) => {
    const payload: EnterReservationRequesRoomPayload = {
      otel_id: formValues.otel_id,
      oda_id_arr: addedRooms,
      giris: formValues.giris_tarihi,
      cikis: formValues.cikis_tarihi,
    };

    dispatch(
      requestRoom(payload, (res) => {
        const response: {
          durum: 0 | 1;
          mesaj: string;
        } = res;

        if (response.durum === 0) {
          dispatch(setToastError(response.mesaj));
        } else {
          setAddedRooms([...addedRooms, oda_id]);

          const addedRoom: CreateRoomAddedRoom = {
            id: Date.now(),
            price,
            adults,
            children,
            ...room,
            ...formValues,
          };
          setAddedRoomsInfo([...addedRoomsInfo, addedRoom]);
          handleResetCreateRoomForm();
          setCalculatedPrice(0);
        }
      })
    );
  };

  const [invoiceInfoFormValues, setInvoiceInfoFormValues] =
    useState<InvoiceInfoAndOtherFieldsFormValues>({
      invoiceName: "",
      invoiceAddress: "",
      invoiceTaxOffice: "",
      invoiceTaxNumber: "",
      description: "",
      sellingChannel: "",
      diffrentAgent: false,
      employeeId: 0,
    });

  const handleInvoiceInfoChangeInput = (key: string, value: any) => {
    setInvoiceInfoFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const [reservationPaymentInfo, setReservationPaymentInfo] =
    useState<ReservationPaymentInfo>({
      transfer: [],
      creditCard: [],
      cash: [],
    });

  return {
    formValues,
    handleChangeInput,
    roomConceptTypeOptions,
    employeesOptions,
    onCalculatePrice: handleCalculatePrice,
    calculatedPrice,
    moneyPoint,
    specialDiscounts,
    onRequestRoom: handleRequestRoom,
    onRemoveAddedRoom: handleRemoveAddedRoom,
    onUpdatedAddedRooms: handleUpdateAddedRooms,
    installments,
    banks,
    addedRoomsInfo,
    invoiceInfoFormValues,
    handleInvoiceInfoChangeInput,
    reservationPaymentInfo,
    setReservationPaymentInfo,
  };
};

export default useHandleEnterReservationPage;
