import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import CodeName from "./CodeName";
import DiscountAmountOrRate from "./DiscountAmountOrRate";
import DiscountCode from "./DiscountCode";
import UpdateCodeButton from "./UpdateCodeButton";
import AddNewCodeButton from "./AddNewCodeButton";

const DiscountCodesForm = () => {
  const {
    discountCode,
    addNewDiscountCodeRequestPayload,
    updateDiscountCodeRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addCodeLoading,
    updateCodeLoading,
  } = useHandleForm();

  return (
    <CustomFormContainer>
      <CodeName
        values={discountCode}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <DiscountAmountOrRate
        values={discountCode}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <DiscountCode
        values={discountCode}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {discountCode.id ? (
        <UpdateCodeButton
          values={discountCode}
          requestPayload={updateDiscountCodeRequestPayload}
          handleSubmitted={handleSubmitted}
          validateForm={validateForm}
          isLoading={updateCodeLoading}
        />
      ) : (
        <AddNewCodeButton
          values={discountCode}
          requestPayload={addNewDiscountCodeRequestPayload}
          handleSubmitted={handleSubmitted}
          validateForm={validateForm}
          isLoading={addCodeLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default DiscountCodesForm;
