import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ListCertificatesTableProps {
  isLoading: boolean;
}
const ListCertificatesTable = ({
  isLoading,
}: ListCertificatesTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
    />
  );
};

export default ListCertificatesTable;
