import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { TransferReservationsInitialState } from "slices/userOperations/transferReservations/reducer";
import { getFormChoices } from "slices/userOperations/transferReservations/thunk";

const useTransferReservation = () => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const { isLoading, error } = useSelector(
    (state: { TransferReservations: TransferReservationsInitialState }) => state.TransferReservations
  );

  useEffect(() => {
      dispatch(getFormChoices());
  }, [dispatch]);

  return {
    isLoading,
    error,
  };
};

export default useTransferReservation;
