import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveAdsAndHotels,
  handleSaveMatchedHotels,
  handleSaveUnmatchedHotels,
  handleSaveUnmatchedAdsGroups,
  setIsLoading,
  setMatchedHotelsLoading,
  setUnmatchedHotelsLoading,
  setUnmatchedAdsGroupsLoading,
  setMatchAdHotelLoading,
  setError,
  setMatchedHotelsError,
  setUnmatchedHotelsError,
  setUnmatchedAdsGroupsError,
  setMatchAdHotelError,
} from "./reducer";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import {
  GET_ADS_AND_HOTELS,
  GET_MATCHED_HOTELS,
  GET_UNMATCHED_HOTELS,
  GET_UNMATCHED_ADS_GROUPS,
  ADD_AD_AND_HOTEL_MATCH,
  DELETE_MATCHED_HOTEL,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface RequestPayload {
  per_page: number;
  page: number;
  search: string;
}

interface AddAdAndHotelMatchRequestPayload {
  otel_id: number;
  reklam_grup_id: number;
}

interface DeleteMatchedHotelRequestPayload {
  otel_id: number;
}

type SuccessCallback = () => void;

export const getAdsAndHotels = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_ADS_AND_HOTELS);

    const res = (response.data || response)[0];

    dispatch(handleSaveAdsAndHotels(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getMatchedHotels =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setMatchedHotelsLoading(true));
      const response = await axios.post(GET_MATCHED_HOTELS, payload);

      dispatch(
        handleSaveMatchedHotels(
          response.data.original || (response as any).original
        )
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMatchedHotelsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMatchedHotelsLoading(false));
    }
  };

export const getUnmatchedHotels =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUnmatchedHotelsLoading(true));
      const response = await axios.post(GET_UNMATCHED_HOTELS, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveUnmatchedHotels(res[0]));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUnmatchedHotelsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUnmatchedHotelsLoading(false));
    }
  };

export const getUnmatchedAdsGroups =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUnmatchedAdsGroupsLoading(true));
      const response = await axios.post(GET_UNMATCHED_ADS_GROUPS, payload);

      const res = response.data.original || (response as any).original;

      dispatch(handleSaveUnmatchedAdsGroups(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUnmatchedAdsGroupsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUnmatchedAdsGroupsLoading(false));
    }
  };

export const addAdAndHotelMatch =
  (
    payload: AddAdAndHotelMatchRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMatchAdHotelLoading(true));
      await axios.post(ADD_AD_AND_HOTEL_MATCH, payload);

      dispatch(setToastSuccess("Reklam grubu ve otel eşleştirildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMatchAdHotelError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMatchAdHotelLoading(false));
    }
  };

export const deleteMatchedHotel =
  (
    payload: DeleteMatchedHotelRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(DELETE_MATCHED_HOTEL, payload);

      successCallback();
      dispatch(setToastSuccess("Eşleştirme silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
