import { CustomButton, CustomFormFieldContainer } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { checkCard } from "slices/reservation/reservationCall/thunk";
import { CreditCardBin } from "types/GlobalTypes";
import { BankInstallment } from "types/reservation";

interface CreditCardInfoFormProps {
  paymentId?: string;
  creditCardInfo?: CreditCardFormValues;
  banks: BankOption[];
  installments: BankInstallment[];
  remainingAmount: number;
  onAddPaymentMethod: (
    formValues: CreditCardFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    onResetForm: () => void
  ) => void;
  onEditPaymentMethod?: (
    formValues: CreditCardFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    paymentId: string
  ) => void;
  onRemoveSelectedPaymentMethod: () => void;
}

interface Option {
  value: number;
  label: string;
  komisyon: number;
}

interface BankOption {
  id: number;
  value: string;
  label: string;
}

interface CreditCardFormValues {
  paymentId: string;
  bankName: string;
  amount: number;
  interestRate: number;
  cardOwner: string;
  installment: number;
  cardNumber: string;
  expirationDate: string;
  cvc: string;
}

type PaymentMethod = "cash" | "creditCard" | "transfer";

const validateExpiryDate = (date: string) => {
  const [monthStr, yearStr] = date.split("/");
  const month = parseInt(monthStr, 10);
  const year = parseInt(`20${yearStr}`, 10);

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  if (month < 1 || month > 12) {
    return false;
  }

  if (year === currentYear) {
    if (month >= currentMonth) {
      return true;
    } else {
      return false;
    }
  } else if (year > currentYear) {
    return true;
  } else {
    return false;
  }
};

const validateCardOwner = (cardOwner: string) => {
  return cardOwner.split(" ").every((name) => name.length >= 2);
};

const validateForm = (
  formValues: CreditCardFormValues,
  remainingAmount: number,
  checkCardError: string
) => {
  const errors: {
    bankName?: string;
    amount?: string;
    interestRate?: string;
    installment?: string;
    cardOwner?: string;
    cardNumber?: string;
    expirationDate?: string;
    cvc?: string;
  } = {};

  if (formValues.amount <= 0) {
    errors.amount = "Tutar 0'dan büyük olmalıdır.";
  }

  if (formValues.amount > remainingAmount) {
    // errors.amount = "Tutar kalan tutardan büyük olamaz.";
  }

  if (formValues.interestRate < 0) {
    errors.interestRate = "Vade tutarı 0'dan büyük olmalıdır.";
  }

  if (formValues.installment <= 0) {
    errors.installment = "Taksit sayısı 0'dan büyük olmalıdır.";
  }

  if (!validateCardOwner(formValues.cardOwner)) {
    errors.cardOwner = "Ad Soyad girilmesi gerekmektedir.";
  }

  if (![15, 16].includes(formValues.cardNumber.length) && !checkCardError) {
    errors.cardNumber = "Kart numarası eksik!";
  }

  if (checkCardError) {
    errors.cardNumber = checkCardError;
  }

  if (!validateExpiryDate(formValues.expirationDate)) {
    errors.expirationDate = "Son kullanma tarihi hatalı.";
  }

  if (formValues.cvc.length !== 3) {
    errors.cvc = "3 haneli olmalıdır.";
  }

  const isValid = Object.keys(errors).length === 0;

  return { errors, isValid };
};

const CreditCardInfoForm = ({
  paymentId,
  creditCardInfo,
  banks,
  installments,
  remainingAmount,
  onAddPaymentMethod,
  onEditPaymentMethod,
  onRemoveSelectedPaymentMethod,
}: CreditCardInfoFormProps) => {
  const [formValues, setFormValues] = useState<CreditCardFormValues>({
    paymentId: creditCardInfo ? creditCardInfo.paymentId : "",
    bankName: creditCardInfo ? creditCardInfo.bankName : "",
    amount: creditCardInfo ? creditCardInfo.amount : 0,
    interestRate: creditCardInfo ? creditCardInfo.interestRate : 0,
    cardOwner: creditCardInfo ? creditCardInfo.cardOwner : "",
    installment: creditCardInfo ? creditCardInfo.installment : 1,
    cardNumber: creditCardInfo ? creditCardInfo.cardNumber : "",
    expirationDate: creditCardInfo ? creditCardInfo.expirationDate : "",
    cvc: creditCardInfo ? creditCardInfo.cvc : "",
  });

  const [creditCardBin, setCreditCardBin] = useState<CreditCardBin>({
    id: 0,
    bank_id: 0,
    bank: "",
    bankName: "",
    cardBrand: "",
    cardBrandIcon: "",
    cardLength: 0,
    cardProgram: "",
    cardProgramIcon: "",
    cardType: "",
    countryCode: "",
    cvvRequired: true,
  });

  const { checkCardError } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const onInputChange = (key: string, value: string | number) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const [installmentOptions, setInstallmentOptions] = useState<Option[]>([]);

  useEffect(() => {
    const selectedBank = banks.find(
      (bank) => bank.value === formValues.bankName
    )?.value;

    const installmentsGroupedByBankCode = installments.reduce(
      (acc, installment) => {
        if (!acc[installment.banka_kodu]) {
          acc[installment.banka_kodu] = [];
        }
        acc[installment.banka_kodu].push(installment);
        return acc;
      },
      {} as { [key: string]: BankInstallment[] }
    );

    const options: Option[] =
      selectedBank && creditCardBin.cardType === "Credit Card"
        ? installmentsGroupedByBankCode[selectedBank].map((installment) => ({
            value: Number(installment.ay),
            label: `${installment.ay} Taksit = ${Math.ceil(
              (installment.komisyon * formValues.amount) / 100 +
                formValues.amount
            )} TL`,
            komisyon: installment.komisyon,
          }))
        : [];

    options.unshift({
      value: 1,
      label: "Tek Çekim = " + formValues.amount + " TL",
      komisyon: 0,
    });

    setInstallmentOptions(options);
    setFormValues({
      ...formValues,
      installment: options[0].value,
      interestRate: Math.ceil(
        (Number(options[0].komisyon) * formValues.amount) / 100
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.bankName, formValues.amount, creditCardBin.cardType]);

  useEffect(() => {
    setFormValues({ ...formValues, bankName: creditCardBin.bank });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardBin.bank]);

  const onResetForm = () => {
    setFormValues({
      paymentId: "",
      bankName: "",
      amount: 0,
      interestRate: 0,
      cardOwner: "",
      installment: 1,
      cardNumber: "",
      expirationDate: "",
      cvc: "",
    });
  };

  const { errors, isValid } = validateForm(
    formValues,
    remainingAmount,
    checkCardError
  );

  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div className="d-flex flex-column gap-3 border rounded bg-white p-2">
      <div className="d-flex flex-column gap-2">
        <span className="fw-bold">Kredi Kartı Bilgileri</span>
        {creditCardBin.bank_id ? (
          <span className="fs-14 fw-semibold theme-text-danger">
            {creditCardBin.bankName}
            {creditCardBin.cardType === "Debit Card" &&
              " : ( BANKAMATİK KARTIDIR ) Lütfen misafirden tanımlı bir email adresi alınız."}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
        }}
      >
        <CustomFormFieldContainer label="Çekilecek Tutar" error={errors.amount}>
          <Input
            type="number"
            placeholder="Çekilecek Tutar"
            value={formValues.amount}
            onChange={(e) => onInputChange("amount", Number(e.target.value))}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Vade Tutarı"
          error={errors.interestRate}
        >
          <Input
            type="number"
            placeholder="Vade Tutarı"
            value={formValues.interestRate}
            onChange={(e) =>
              onInputChange("interestRate", Number(e.target.value))
            }
            disabled
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Taksit Sayısı"
          error={errors.installment}
        >
          <Select
            options={installmentOptions}
            placeholder="Taksit Sayısı"
            value={installmentOptions.find(
              (option) => option.value === formValues.installment
            )}
            onChange={(selectedOption: Option) => {
              onInputChange("installment", selectedOption.value);

              onInputChange(
                "interestRate",
                Math.ceil(
                  (Number(selectedOption.komisyon) * formValues.amount) / 100
                )
              );
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Banka Adı" error={errors.bankName}>
          <Select
            options={banks}
            placeholder="Banka Adı"
            value={banks.find((bank) => bank.value === formValues.bankName)}
            onChange={(selectedOption: BankOption) => {
              onInputChange("bankName", creditCardBin.bank);
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Ad Soyad">
          <Input
            type="text"
            placeholder="Kart Üzerindeki Ad Soyad"
            valid={errors.cardOwner === undefined}
            invalid={errors.cardOwner !== undefined}
            value={formValues.cardOwner}
            onChange={(e) => onInputChange("cardOwner", e.target.value)}
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer
          label="Kart Numarası"
          error={errors.cardNumber}
        >
          <Input
            type="number"
            placeholder="Kart Numarası"
            value={formValues.cardNumber}
            valid={checkCardError === "" && formValues.cardNumber.length === 16}
            invalid={checkCardError !== ""}
            onChange={(e) => {
              const cardNumber = e.target.value;

              onInputChange("cardNumber", cardNumber);

              if (cardNumber.length > 5) {
                dispatch(
                  checkCard(cardNumber.slice(0, 6), (result) => {
                    setCreditCardBin(result);
                  })
                );
              } else {
                setCreditCardBin({
                  id: 0,
                  bank_id: 0,
                  bank: "",
                  bankName: "",
                  cardBrand: "",
                  cardBrandIcon: "",
                  cardLength: 0,
                  cardProgram: "",
                  cardProgramIcon: "",
                  cardType: "",
                  countryCode: "",
                  cvvRequired: true,
                });
              }
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Son Kullanma Tarihi">
          <Input
            type="text"
            placeholder="Son Kullanma Tarihi"
            value={formValues.expirationDate}
            valid={errors.expirationDate === undefined}
            invalid={errors.expirationDate !== undefined}
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, "");
              if (value.length >= 3) {
                value = value.slice(0, 2) + "/" + value.slice(2);
              }
              if (value.length > 5) {
                value = value.slice(0, 5);
              }
              onInputChange("expirationDate", value);
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="CVC">
          <Input
            type="number"
            placeholder="cvc"
            value={formValues.cvc}
            valid={formValues.cvc.length === 3}
            invalid={formValues.cvc.length !== 3}
            onChange={(e) => {
              const cvc = e.target.value;
              if (cvc.length > 3) {
                return;
              }
              onInputChange("cvc", e.target.value);
            }}
          />
        </CustomFormFieldContainer>
        <div></div>
        <div className="d-flex justify-content-end pt-2">
          {paymentId ? (
            <CustomButton
              variant="primary"
              onClick={() => {
                isValid &&
                  onEditPaymentMethod &&
                  onEditPaymentMethod(
                    formValues,
                    "creditCard",
                    formValues.amount,
                    paymentId
                  );
              }}
            >
              Ödemeyi Düzenle
            </CustomButton>
          ) : (
            <CustomButton
              variant="primary"
              onClick={() => {
                isValid &&
                  onAddPaymentMethod(
                    formValues,
                    "creditCard",
                    formValues.amount,
                    onResetForm
                  );
              }}
            >
              Kredi Kartı Ödemesi Ekle
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditCardInfoForm;
