import { CustomLoadingPage } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { CompetitorAnalysisInfoInitialState } from "slices/adsAndAnalyze/competitorAnalysisInfo/reducer";
import AnalysisInfoForm from "./AnalysisInfoForm";

interface CompetitorAnalysisInfoPageProps {
  isLoading: boolean;
}

const CompetitorAnalysisInfoPage = ({
  isLoading,
}: CompetitorAnalysisInfoPageProps) => {
  const { competitorAnalysisInfo, updateAnalysisLoading } = useSelector(
    (state: { CompetitorAnalysisInfo: CompetitorAnalysisInfoInitialState }) =>
      state.CompetitorAnalysisInfo
  );

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-wrap justify-content-between align-items-start row-gap-3">
        {competitorAnalysisInfo.map((analysisInfo) => (
          <AnalysisInfoForm
            key={analysisInfo.id}
            {...analysisInfo}
            isLoading={updateAnalysisLoading}
          />
        ))}
      </div>
    </>
  );
};

export default CompetitorAnalysisInfoPage;
