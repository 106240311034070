import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { CompetitorAnalysisInfoInitialState } from "slices/adsAndAnalyze/competitorAnalysisInfo/reducer";

import { getCompetitorAnalysisInfo } from "slices/adsAndAnalyze/competitorAnalysisInfo/thunk";

const useCompetitorAnalysisInfo = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { CompetitorAnalysisInfo: CompetitorAnalysisInfoInitialState }) =>
      state.CompetitorAnalysisInfo
  );

  useEffect(() => {
    dispatch(getCompetitorAnalysisInfo());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useCompetitorAnalysisInfo;
