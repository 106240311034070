import useHandleForm from "./useHandleForm";
import ManipulateRoomInput from "./ManipulateRoomInput";
import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import CustomInfiniteScrollBox from "Components/Custom/UI/CustomInfiniteScrollBox";
import { getFacilityRoomNames } from "slices/facility/facilityRooms/thunk";
import SimpleBar from "simplebar-react";
import { Input } from "reactstrap";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

const EditFacilityRoomsForm = () => {
  const {
    roomsOptions,
    selectedRoom,
    handleSelectRoom,
    hotelRooms,
    onAddRoom,
    passiveRooms,
    pagination,
    loading,
    updateFacilityRoomLoading,
  } = useHandleForm();

  const value = roomsOptions.find((item) => item.value === selectedRoom);

  const [search, setSearch] = useState("");

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getFacilityRoomNames({ page: 1, per_page: 100, search }));
    }, 700);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <CustomFormContainer title="Oda Ekleme ve Düzenleme" lg={12} xl={12}>
      <div className="d-flex gap-4">
        <div
          className="d-flex flex-column gap-4 theme-bg-light"
          style={{ width: "450px" }}
        >
          <CustomInfiniteScrollBox
            requestFunction={getFacilityRoomNames}
            per_page={100}
            search={search}
            pagination={pagination}
            loading={loading}
          >
            <div className="d-flex flex-column gap-2 border p-2 rounded bg-white">
              <p className="text-center fw-bold fs-18 m-0 mt-1">Oda Seçimi</p>
              <Input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Oda Ara..."
              />
              <SimpleBar style={{ height: "300px" }}>
                <div className="d-flex flex-column gap-1">
                  {roomsOptions.map((item) => (
                    <div
                      key={item.value}
                      className="p-1 rounded border fs-14 cursor-pointer"
                      onClick={() => handleSelectRoom(item.value)}
                      style={{
                        userSelect: "none",
                        backgroundColor:
                          item.value === selectedRoom
                            ? "var(--theme-primary)"
                            : "white",

                        color: item.value === selectedRoom ? "white" : "black",
                      }}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>
          </CustomInfiniteScrollBox>

          {value && (
            <div className="d-flex flex-column gap-2 border p-2 rounded bg-white">
              <p className="text-center fw-semibold fs-16 m-0 mt-1">
                {value.label}
              </p>
              <CustomButton
                classes="w-100"
                variant="success"
                onClick={onAddRoom}
                isLoading={updateFacilityRoomLoading}
              >
                Tesise Oda Ekle
              </CustomButton>
            </div>
          )}
          <div className="d-flex flex-column gap-2"></div>
        </div>
        <div
          className="d-flex flex-column gap-2 border p-2 rounded bg-white"
          style={{ width: "450px" }}
        >
          <p className="text-center fw-bold fs-18 m-0 mb-1">Aktif Odalar</p>
          {hotelRooms.length ? (
            hotelRooms.map((item) => (
              <ManipulateRoomInput
                key={item.oda_id}
                room={item}
                isLoading={updateFacilityRoomLoading}
              />
            ))
          ) : (
            <p className="text-center p-2 border rounded">
              Aktif Oda Bulunamadı...
            </p>
          )}
          {passiveRooms.length ? (
            <p className="text-center fw-bold fs-18 m-0 mt-3 mb-1">
              Pasif Odalar
            </p>
          ) : (
            <></>
          )}
          {passiveRooms.map((item) => (
            <ManipulateRoomInput
              key={item.id}
              forPassive={true}
              room={{
                oda_id: item.id,
                oda_ismi: item.oda_isim,
                aktifmi: false,
              }}
              isLoading={updateFacilityRoomLoading}
            />
          ))}
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default EditFacilityRoomsForm;
