import { UnderTheDeskDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import useUnderTheDeskDiscounts from "Components/Hooks/discountsAndActions/useUnderTheDeskDiscounts";
import PageContainer from "pages/PageContainer";

const UnderTheDeskDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useUnderTheDeskDiscounts();

  return (
    <PageContainer title="Desk Altı İndirimler">
      <UnderTheDeskDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default UnderTheDeskDiscounts;
