import { FormGroup, Input, Label } from "reactstrap";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import { CompetitorAnalysisInfoFormState } from "types/adsAndAnalyze";

interface FirstPeriodProps {
  formValues: CompetitorAnalysisInfoFormState;
  handleChangeForm: (key: string, value: number | string) => void;
}

const FirstPeriod = ({ formValues, handleChangeForm }: FirstPeriodProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const { periyot_tipi_1, id } = formValues;

  return (
    <div className="d-flex flex-column gap-2 p-1 border rounded">
      <p className="fw-medium m-0">1.Periyot</p>
      <div
        className="border rounded p-3 bg-light"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
      >
        <div className="d-flex flex-column gap-2">
          <FormGroup check>
            <Input
              id={`periyot_tipi_1_gun_${id}`}
              type="radio"
              name={`periyot_tipi_1_${id}`}
              value="gun"
              checked={periyot_tipi_1 === "gun"}
              onChange={(e) =>
                handleChangeForm("periyot_tipi_1", e.target.value)
              }
            />
            <Label for={`periyot_tipi_1_gun_${id}`} className="m-0">
              Gün
            </Label>
          </FormGroup>
          <FormGroup check>
            <Input
              id={`periyot_tipi_1_tarih_${id}`}
              type="radio"
              name={`periyot_tipi_1_${id}`}
              value="tarih"
              checked={periyot_tipi_1 === "tarih"}
              onChange={(e) =>
                handleChangeForm("periyot_tipi_1", e.target.value)
              }
            />
            <Label for={`periyot_tipi_1_tarih_${id}`} className="m-0">
              Tarih
            </Label>
          </FormGroup>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {periyot_tipi_1 === "gun" && (
            <Input
              type="number"
              value={formValues.gun_1}
              onChange={(e) => handleChangeForm("gun_1", e.target.value)}
              placeholder="Gün Sayısı"
            />
          )}

          {periyot_tipi_1 === "tarih" && (
            <Flatpickr
              className="form-control"
              value={formValues.tarih_1}
              onChange={(date) => {
                const [startDate] = date;

                if (startDate) {
                  const stringStartDate =
                    moment(startDate).format("YYYY-MM-DD");

                  handleChangeForm("tarih_1", stringStartDate);
                }
              }}
              options={{
                dateFormat: "Y-m-d",
                showMonths: 1,
                locale: Turkish,
                onDayCreate,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstPeriod;
