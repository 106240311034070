import DiscountCodesForm from "./DiscountCodesForm";
import DiscountCodesTable from "./DiscountCodesTable";
import PageContainer from "../PageContainer";

interface DiscountCodesPageProps {
  loading: boolean;
  error: string;
}

const DiscountCodesPage = ({ loading }: DiscountCodesPageProps) => {
  return (
    <PageContainer descriptions={[]} isLoading={loading} roomSelector={<></>}>
      <DiscountCodesForm />
      <DiscountCodesTable />
    </PageContainer>
  );
};

export default DiscountCodesPage;
