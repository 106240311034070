import { CustomButton } from "Components/Custom/UI";

interface ValueToChange{
    rez_kod: string;
}

interface GetReservationPaymentTableButtonProps {
    values: ValueToChange;
    handleSave: (rez_kod: string) => void
}
const GetReservationPaymentTableButton = ({
    values,
    handleSave,
}: GetReservationPaymentTableButtonProps) => {

    return (
        <div className="d-flex justify-content-end gap-4 ">
            <CustomButton
                variant="primary"
                onClick={() => {
                    handleSave(values.rez_kod);
                }}
            >
                Getir
            </CustomButton>
        </div>
    )
}

export default GetReservationPaymentTableButton;