import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { ReservationFilterPayload } from "types/reports";

interface CustomerNameInputProps {
  values: ReservationFilterPayload;
  handleFormChange: (key: string, value: string) => void;
  fieldKey: keyof ReservationFilterPayload;
}

const CustomerNameInput = ({
  values,
  handleFormChange,
  fieldKey,
}: CustomerNameInputProps) => {

  const value = values[fieldKey] as string;

  return (
    <>
      <CustomFormFieldContainer label="Müşteri Adı">
        <Input
          type="text"
          autoCorrect="on"
          value={value}
          onChange={(e) => {
            handleFormChange("musteri_adi", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CustomerNameInput;
