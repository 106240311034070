import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import GuestInfo from "../CreateRoomForm/GuestInfo";
import { CreateRoomAddedRoom } from "types/reservation";
import GuestCheckboxes from "../CreateRoomForm/GuestCheckboxes";
import ReservationNeeds from "../CreateRoomForm/ReservationNeeds";

interface CreateRoomFormFieldsProps {
  title: string;
  createRoomFormValues: CreateRoomAddedRoom;
  handleChangeInput: (key: string, value: any) => void;
  handleChangeGuestsInput: (
    key: string,
    value: any,
    tip: "yetiskin" | "cocuk",
    sira: number
  ) => void;
  onClick: () => void;
}

const CreateRoomFormFields = ({
  title,
  createRoomFormValues,
  handleChangeInput,
  handleChangeGuestsInput,
  onClick,
}: CreateRoomFormFieldsProps) => {
  const adults = Array.from(
    { length: createRoomFormValues.adults },
    (_, i) => i
  );
  const children = Array.from(
    { length: createRoomFormValues.children },
    (_, i) => i
  );

  return (
    <CustomFormContainer title={title}>
      {adults.map((_, index) => (
        <GuestInfo
          guestType="yetiskin"
          guestIndex={index}
          formValues={createRoomFormValues}
          handleChangeInput={handleChangeGuestsInput}
        />
      ))}
      {children.map((_, index) => (
        <GuestInfo
          guestType="cocuk"
          guestIndex={index}
          formValues={createRoomFormValues}
          handleChangeInput={handleChangeGuestsInput}
        />
      ))}
      <GuestCheckboxes
        formValues={createRoomFormValues}
        handleChangeInput={handleChangeInput}
      />
      <ReservationNeeds
        formValues={createRoomFormValues}
        handleChangeInput={handleChangeInput}
      />
      <div className="d-flex justify-content-end">
        <CustomButton onClick={onClick}>Değişiklikleri Kaydet</CustomButton>
      </div>
    </CustomFormContainer>
  );
};

export default CreateRoomFormFields;
