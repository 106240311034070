import { CalculatePriceForLocationPage } from "Components/Custom/ReservationPages";
import useCalculatePriceForLocation from "Components/Hooks/reservation/useCalculatePriceForLocation";
import PageContainer from "pages/PageContainer";

const CalculatePriceForLocation = () => {
  const { isLoading } = useCalculatePriceForLocation();

  return (
    <PageContainer title="Konuma Göre Fiyat Hesapla">
      <CalculatePriceForLocationPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CalculatePriceForLocation;
