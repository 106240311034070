import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveHourlyCallsChartResponse,
  setIsLoading,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_HOURLY_CALLS_CHART } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetHourlyCallsChartParams {
  date: string;
}

export const getHourlyCallsChart =
  (params: GetHourlyCallsChartParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_HOURLY_CALLS_CHART, {
        params,
      });

      dispatch(handleSaveHourlyCallsChartResponse(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
