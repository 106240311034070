import { useState } from "react";
import Select from "react-select";

interface PageSizeSelectProps {
  sizes: number[];
  onPageSizeChange: (pageSize: number) => void;
}

interface Option {
  value: number;
  label: number;
}

const PageSizeSelect = ({ sizes, onPageSizeChange }: PageSizeSelectProps) => {
  const sizesOptions: Option[] = sizes.map((size) => ({
    value: size,
    label: size,
  }));

  const [selectedSize, setSelectedSize] = useState(sizes[0]);

  const handleSelectChange = (option: Option) => {
    setSelectedSize(option.value);
    onPageSizeChange(option.value);
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <Select
        options={sizesOptions}
        value={
          sizesOptions.find((option) => option.value === selectedSize) || null
        }
        onChange={handleSelectChange}
      />
      <span className="text-gray-500 text-sm">kayıt göster</span>
    </div>
  );
};

export default PageSizeSelect;
