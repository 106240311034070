import { RoomsLackDescriptionPage } from "Components/Custom/ReportsPages";
import useRoomsLackDescription from "Components/Hooks/reports/useRoomsLackDescription";
import PageContainer from "pages/PageContainer";

const RoomsLackDescription = () => {
  const { isLoading } = useRoomsLackDescription();

  return (
    <PageContainer title="Oda Açıklaması Olmayanlar">
      <RoomsLackDescriptionPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default RoomsLackDescription;
