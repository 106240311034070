import { useSelector } from "react-redux";
import { PeritoExtensionNumbersState } from "slices/userOperations/peritoExtensionNumbers/reducer";

const useHandleCards = () => {
    
    const { teamsData } = useSelector(
        (state: { PeritoExtensionNumbers: PeritoExtensionNumbersState }) => state.PeritoExtensionNumbers
      );

    return{
        values: teamsData,
    };
}

export default useHandleCards;