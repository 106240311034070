import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import useHandlePayload from "./useHandlePayload";
import { AddUserState } from "types/userOperations";
import { addUser } from "slices/userOperations/addUser/thunk";

interface AddUserButtonProps {
  values: AddUserState;
  requestPayload: FormData;
  validateForm: (values: AddUserState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddUserButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddUserButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const getPayload = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(addUser(getPayload()));
    }
  };

  return (
    <div className="d-flex justify-content-end ">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={isLoading}
      >
        Kullanıcı Ekle
      </CustomButton>
    </div>
  );
};

export default AddUserButton;
