import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveSpecialDaysResponse,
  resetSpecialDay,
  setAddSpecialDayLoading,
  setAddSpecialDayError,
  setUpdateSpecialDayLoading,
  setUpdateSpecialDayError,
  setDeleteSpecialDayLoading,
  setDeleteSpecialDayError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  AddFacilitySpecialDayRequestPayload,
  UpdateFacilitySpecialDayRequestPayload,
} from "types/facility";

import {
  GET_FACILITY_SPECIAL_DAYS,
  ADD_FACILITY_SPECIAL_DAY,
  UPDATE_FACILITY_SPECIAL_DAY,
  DELETE_FACILITY_SPECIAL_DAY,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetFacilitySpecialDaysParams {
  otel_id: number;
  page: number;
  per_page: number;
  search: string;
}

type SuccessCallback = () => void;

export const getFacilitySpecialDays =
  (params: GetFacilitySpecialDaysParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FACILITY_SPECIAL_DAYS, { params });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveSpecialDaysResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addFacilitySpecialDay =
  (
    payload: AddFacilitySpecialDayRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddSpecialDayLoading(true));

      await axios.post(ADD_FACILITY_SPECIAL_DAY, payload);

      successCallback();
      dispatch(resetSpecialDay());
      dispatch(setToastSuccess("Özel gün eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddSpecialDayError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddSpecialDayLoading(false));
    }
  };

export const updateFacilitySpecialDay =
  (
    payload: UpdateFacilitySpecialDayRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateSpecialDayLoading(true));

      await axios.post(UPDATE_FACILITY_SPECIAL_DAY, payload);

      successCallback();
      dispatch(resetSpecialDay());
      dispatch(setToastSuccess("Özel gün güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateSpecialDayError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateSpecialDayLoading(false));
    }
  };

export const deleteFacilitySpecialDay =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteSpecialDayLoading(true));

      await axios.post(DELETE_FACILITY_SPECIAL_DAY, payload);

      successCallback();
      dispatch(setToastSuccess("Özel gün silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteSpecialDayError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteSpecialDayLoading(false));
    }
  };
