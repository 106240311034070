import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { CalculatePriceFormValues } from "types/reservation";

import { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

interface GuestNumbersProps {
  formValues: CalculatePriceFormValues;
  handleChangeForm: (key: string, value: number | string) => void;
}

const GuestNumbers = ({ formValues, handleChangeForm }: GuestNumbersProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down">
        <DropdownToggle
          className="form-control d-flex align-items-center gap-2 bg-white text-dark"
          style={{ border: "1px solid #ced4da" }}
        >
          <i className="ri-user-fill"></i>
          <span>
            {formValues.yetiskin_sayisi} Yetişkin
            {formValues.cocuk_sayisi ? "," : ""}
          </span>
          {formValues.cocuk_sayisi ? (
            <span>{formValues.cocuk_sayisi} Çocuk</span>
          ) : (
            <></>
          )}
        </DropdownToggle>
        <DropdownMenu className="p-2">
          <div className="d-flex flex-column gap-2">
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <p className="fw-medium m-0">Yetişkin Sayısı</p>
              <CustomNumberSelector
                value={formValues.yetiskin_sayisi}
                min={1}
                onChange={(value) => handleChangeForm("yetiskin_sayisi", value)}
                disabled={true}
              />
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <p className="fw-medium m-0">Çocuk Sayısı</p>
              <CustomNumberSelector
                value={formValues.cocuk_sayisi}
                max={4}
                onChange={(value) => handleChangeForm("cocuk_sayisi", value)}
                disabled={true}
              />
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default GuestNumbers;
