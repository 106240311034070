import { CustomLoadingPage } from "Components/Custom/UI";
import DefineCurrencyForm from "./DefinCurrencyForm";

interface DefineCurrencyPageProps {
  loading: boolean;
  error: string;
}

const DefineCurrencyPage = ({ loading }: DefineCurrencyPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={loading} />
      <DefineCurrencyForm />
    </>
  );
};

export default DefineCurrencyPage;
