import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface DailiyCallsPerPersonTableProps {
  isLoading: boolean;
}

const DailiyCallsPerPersonTable = ({
  isLoading,
}: DailiyCallsPerPersonTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Kişiye Gelen Günlük Çağrı"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default DailiyCallsPerPersonTable;
