import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleAssignHotelToUserFormChoices,
  handleChangeHotelAssigneeFormChoices,
  handleListAssignedHotelsFormChoices,
  handleAssignedHotelsOfUser,
  setIsLoading,
  setError,
  setAssignHotelToUserLoading,
  setAssignHotelToUserError,
  setChangeHotelAssigneeLoading,
  setChangeHotelAssigneeError,
  handleListAssignedHotels,
} from "./reducer";

import {
  GET_USERS_CITIES_HOTELS,
  ASSIGN_HOTELS_TO_USER,
  UPDATE_HOTEL_ASSIGNEES,
  GET_USER_HOTELS,
  GET_ASSIGNED_HOTELS,
} from "helpers/url_helper";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import { Dispatch } from "redux";
import {
  AssignedHotels,
  AssignHotelToUserPayload,
  ChangeHotelAssigneePayload,
  HandleAssignedHotelsPayload,
  HotelsOfUser,
  GetAssignedHotelsPayload,
} from "types/userOperations";

type SuccessCallback = () => void;

export const getHotelsUsersAndCities = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.post(GET_USERS_CITIES_HOTELS);

    dispatch(handleAssignHotelToUserFormChoices(response.data || response));
    dispatch(handleChangeHotelAssigneeFormChoices(response.data || response));
    // dispatch(handleListAssignedHotelsFormChoices(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const listUnassignedHotelsAccordingToCity =
  (payload: { sehir_id: number }) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_USERS_CITIES_HOTELS, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleAssignHotelToUserFormChoices(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

// * payload format : AssignHotelToUserPayload
export const assignHotelsToUsers =
  (payload: AssignHotelToUserPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAssignHotelToUserLoading(true));
      await axios.post(ASSIGN_HOTELS_TO_USER, payload);

      successCallback();
      dispatch(setToastSuccess("Otel personele başarıyla atandı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAssignHotelToUserError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAssignHotelToUserLoading(false));
    }
  };

// * payload format : ChangeHotelAssigneePayload
export const changeHotelAssignees =
  (payload: ChangeHotelAssigneePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeHotelAssigneeLoading(true));
      await axios.post(UPDATE_HOTEL_ASSIGNEES, payload);

      successCallback();
      dispatch(setToastSuccess("Otel sorumlusu başarıyla değiştirildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeHotelAssigneeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeHotelAssigneeLoading(false));
    }
  };

export const listAssignedHotelsOfUser =
  (payload: { personel_id: number }) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_USER_HOTELS, payload);

      if (Array.isArray(response.data) && response.data.length > 0) {
        const userData: HotelsOfUser = response.data[0];

        const assignedHotels: AssignedHotels[] = userData.otel_adlari;

        const handlePayload: HandleAssignedHotelsPayload = {
          userId: userData.id,
          assignedHotels,
        };

        dispatch(handleAssignedHotelsOfUser(handlePayload));
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getAssignedHotelsFormChoices =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_ASSIGNED_HOTELS);

      dispatch(handleListAssignedHotelsFormChoices(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getAssignedHotels =
  (payload: GetAssignedHotelsPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_ASSIGNED_HOTELS, payload);

      successCallback();
      dispatch(handleListAssignedHotels(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
