import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface UnmatchedAdGroupsTableProps {
  isLoading: boolean;
}

const UnmatchedAdGroupsTable = ({ isLoading }: UnmatchedAdGroupsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Eşleştirilmeyen Reklam Grupları">
      <CustomReactTable
        fileName="Eşleştirilmeyen Reklam Grupları"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
      />
    </CustomFormContainer>
  );
};

export default UnmatchedAdGroupsTable;
