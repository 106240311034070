import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleUserToUpdate,
  setError,
  setIsLoading,
  setUpdateUserError,
  setUpdateUserLoading,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { UPDATE_USER, GET_USER } from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const fetchUserData = (userId: number, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_USER}?uye_id=${userId}`);

    successCallback();
    dispatch(handleUserToUpdate(response.data || response));
  } catch (error) {
    dispatch(setError("Hata! Kullanıcı bulunamadı."));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateUser = (payload: FormData, navigate: (path: string) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(setUpdateUserLoading(true));
    await axios.post(UPDATE_USER, payload);

    dispatch(setToastSuccess("Kullanıcı güncellendi."));
    navigate("/kullanicilari-listele");
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setUpdateUserError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setUpdateUserLoading(false));
  }
};
