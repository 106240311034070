import { Link } from "react-router-dom";
import { Alert } from "reactstrap";

interface ForgotPasswordFormHeaderProps {
  logo: string;
  subtitle: string;
  showSubtitle: boolean;
}
const ForgotPasswordFormHeader = ({
  logo,
  subtitle,
  showSubtitle,
}: ForgotPasswordFormHeaderProps) => {
  return (
    <div className="d-flex flex-column gap-3 justify-content-center mt-2">
      <Link to="/" className="auth-logo text-center">
        <img src={logo} alt="otelfiyat.com" height={50} width={250} />
      </Link>
      {showSubtitle && (
        <Alert
          className="border-0 alert-warning text-center mb-3 mx-2"
          role="alert"
        >
          {subtitle}
        </Alert>
      )}
    </div>
  );
};

export default ForgotPasswordFormHeader;
