import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { NIGHT_RESTRICTIONS_DESCRIPTIONS } from "utils/pageDescriptions";
import NightRestrictionsForm from "./NightRestrictionsForm";
import NightRestrictionsTable from "./NightRestrictionsTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./NightRestrictionsForm/RoomSelector";

interface NightRestrictionsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const NightRestrictionsPage = (props: NightRestrictionsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={NIGHT_RESTRICTIONS_DESCRIPTIONS}
      isLoading={isLoading}
      roomSelector={<RoomSelector {...props} />}
    >
      <NightRestrictionsForm {...props} />
      <NightRestrictionsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(NightRestrictionsPage);
