import { StayLongPayLessActionState } from "types/DiscountsAndActions";

import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/discountsAndActions/stayLongPayLessActions/reducer";

import { CustomFormFieldContainer } from "Components/Custom/UI";

interface SwitchesProps {
  values: StayLongPayLessActionState;
}

const Switches = ({ values }: SwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Geceleme Fiyatı"
        tooltipId="stayLongPayLessNightPrice"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.kal_ode_geceleme === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  kal_ode_geceleme: values.kal_ode_geceleme === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Deskaltı Olarak Uygula"
        tooltipId="stayLongPayLessDiscountUnderDesk"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.desk_alti === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  desk_alti: values.desk_alti === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Özel Komisyon Oranı Kullan"
        tooltipId="stayLongPayLessUseSpecialRate"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.ozel_komisyon_orani_durum === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  ozel_komisyon_orani_durum:
                    values.ozel_komisyon_orani_durum === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Yinelemeli"
        tooltipId="stayLongPayLessRecurrence"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.yineleme === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  yineleme: values.yineleme === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Erken Rezervasyon İle Uygula"
        tooltipId="stayLongPayLessEarlyReservation"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.eb_birlikte === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  eb_birlikte: values.eb_birlikte === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default Switches;
