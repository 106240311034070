import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { CustomLoadingPage } from "Components/Custom/UI";
import FacilityManagersForm from "./FacilityManagersForm";
import FacilityManagersTable from "./FacilityManagersTable";

interface FacilityManagersPageProps {
  isLoading: boolean;
}

const FacilityManagersPage = ({ isLoading }: FacilityManagersPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <FacilityManagersForm />
        <FacilityManagersTable />
      </div>
    </>
  );
};

export default withPageNeedsHotel(FacilityManagersPage);
