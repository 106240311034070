import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ListFacilitiesByCityAndCategoryPayload } from "types/facilityCategorization";

interface Option {
  value: number;
  label: string;
}

interface CitySelectorProps {
  options: Option[];
  values: ListFacilitiesByCityAndCategoryPayload;
  handleFormChange: (key: string, value: any) => void;
}

const CitySelector = ({
  options,
  values,
  handleFormChange,
}: CitySelectorProps) => {
  const selectedValue = options.find(
    (option) => option.value === values.sehir_id
  );

  return (
    <CustomFormFieldContainer label={"Şehirler : "} divider>
      <Select
        options={options}
        value={selectedValue}
        onChange={(selectedOption: Option) => {
          handleFormChange("sehir_id", selectedOption.value);
        }}
        placeholder="Şehir seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default CitySelector;
