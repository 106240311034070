import { CustomLoadingPage } from "Components/Custom/UI";
import NotificationField from "./NotificationField";


interface NotificationsPageProps {
  isLoading: boolean;
}

const NotificationsPage = ({ isLoading }: NotificationsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <NotificationField/>
    </>
  );
};

export default NotificationsPage;
