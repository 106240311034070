import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import moment from "moment";
import { postBasicQuotaEntries } from "slices/pricesAndQuota/basicQuotaEntry/thunk";
import { IncomingQuota } from "types/PricesQuotas";

interface RequestPayload {
  otel_id: number;
  tarihler: string;
  odalar: IncomingQuota[];
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectedBasicQuotaEntryState = (state: any) => state.BasicQuotaEntry;
  const selectBasicQuotaEntryProperties = createSelector(
    selectedBasicQuotaEntryState,
    (state) => ({
      hotel: state.hotel,
      changedQuotas: state.changedQuotas,
      selectedDates: state.selectedDates,
      isLoading: state.isLoading,
    })
  );

  const { hotel, changedQuotas, selectedDates, isLoading } = useSelector(
    selectBasicQuotaEntryProperties
  );

  const handleSave = () => {
    const startDate = moment(selectedDates.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectedDates.endDate).format("YYYY-MM-DD");

    const modifiedChangedQuotas = changedQuotas.length
      ? changedQuotas.map((quota: IncomingQuota) => {
          return {
            ...quota,
          };
        })
      : [];

    const payload: RequestPayload = {
      otel_id: hotel.otel_id,
      tarihler: `${startDate} - ${endDate}`,
      odalar: modifiedChangedQuotas,
    };
    dispatch(postBasicQuotaEntries(payload));
  };

  return {
    selectedDates,
    handleSave,
    addQuotasLoading: isLoading,
  };
};

export default useHandleForm;
