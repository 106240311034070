import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategorizeFacilityInitialState } from "slices/facilityCategorization/categorizeFaclity/reducer";
import { getFacilityList } from "slices/facilityCategorization/categorizeFaclity/thunk";
import { ListFacilitiesByCityAndCategoryPayload } from "types/facilityCategorization";

interface Option {
  value: number;
  label: string;
}

interface CategoryOption {
  value: string;
  label: string;
}

const useHandleForm = (setShowTable: Dispatch<SetStateAction<boolean>>) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices } = useSelector(
    (state: { CategorizeFacility: CategorizeFacilityInitialState }) =>
      state.CategorizeFacility
  );

  const [cityOptions, setCityOptions] = useState<Option[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);

  useEffect(() => {
    if (formChoices) {
      const options = formChoices.sehirler?.map((item) => ({
        value: item.otel_lokasyon_id,
        label: item.lokasyon,
      }));

      options.unshift({ value: 0, label: "Tüm Şehirler" });

      setCityOptions(options || []);

      const categoryOpt = formChoices.otel_siniflari.map((option) => ({
        value: option.value,
        label: option.text,
      }));

      categoryOpt.unshift({ value: "", label: "Tüm Sınıflar" });

      setCategoryOptions(categoryOpt);
    }
  }, [formChoices]);

  const [formState, setFormState] =
    useState<ListFacilitiesByCityAndCategoryPayload>({
      sehir_id: 0,
      sinif: "",
    });

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  console.log("formState", formState);

  const handleGetFacilities = () => {
    const params = {
      sehir_id: formState.sehir_id,
      sinif: formState.sinif,
    };
    dispatch(
      getFacilityList(params, () => {
        // setFormState({
        //   sehir_id: 0,
        //   sinif: "",
        // });
        setShowTable(true);
      })
    );
  };

  const handleClearFilter = () => {
    setFormState({
      sehir_id: 0,
      sinif: "",
    });
    setShowTable(false);
  };

  return {
    values: formState,
    cityOptions,
    categoryOptions,
    handleFormChange,
    handleGet: handleGetFacilities,
    handleClear: handleClearFilter,
  };
};

export default useHandleForm;
