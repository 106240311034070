import { createSlice } from "@reduxjs/toolkit";
import {
  NightRestriction,
  NightRestrictionFormErrors,
  NightRestrictionState,
} from "types/DiscountsAndActions";

import moment from "moment";

export interface NightRestrictionsInitialState {
  nightRestrictions: NightRestriction[];
  selectedRoom: number;
  nightRestriction: NightRestrictionState;
  nightRestrictionFormErrors: NightRestrictionFormErrors;
  isLoading: boolean;
  error: string;
  addRestrictionLoading: boolean;
  addRestrictionError: string;
  updateRestrictionLoading: boolean;
  updateRestrictionError: string;
  deleteRestrictionLoading: boolean;
  deleteRestrictionError: string;
}

export const initialState: NightRestrictionsInitialState = {
  nightRestrictions: [],
  nightRestriction: {
    id: 0,
    otel_id: 0,
    otel_oda_id: 0,
    baslangic_tarih: moment().format("YYYY-MM-DD"),
    bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
    minimum_gece: 1,
    giris_bazli: "0",
    tum_gunler: "1",
    gunler: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    yineleme: "0",
    toplam_geceden_hesaplama: "0",
    grup_id: 0,
    tum_otel_pansiyonlar: "1",
    otel_pansiyonlar: [],
  },
  nightRestrictionFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addRestrictionLoading: false,
  addRestrictionError: "",
  updateRestrictionLoading: false,
  updateRestrictionError: "",
  deleteRestrictionLoading: false,
  deleteRestrictionError: "",
};

const nightRestrictionsSlice = createSlice({
  name: "nightRestrictions",
  initialState,
  reducers: {
    handleSaveNightRestrictions: (state, action) => {
      state.nightRestrictions = action.payload;
    },
    handleDeleteFromNightRestrictionsWithId: (state, action) => {
      state.nightRestrictions = state.nightRestrictions.filter(
        (nightRestriction) => nightRestriction.id !== action.payload
      );
    },
    handleSaveNightRestrictionForEdit: (state, action) => {
      state.nightRestriction = action.payload;
    },
    handleChangeInput: (state, action) => {
      state.nightRestriction = {
        ...state.nightRestriction,
        ...action.payload,
      };
    },
    handleAddNightRestrictionFormErrors: (state, action) => {
      state.nightRestrictionFormErrors = action.payload;
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    resetNightRestrictions: (state) => {
      state.nightRestrictions = [];
    },
    resetNightRestriction: (state) => {
      state.nightRestriction = {
        id: 0,
        otel_id: 0,
        otel_oda_id: 0,
        baslangic_tarih: moment().format("YYYY-MM-DD"),
        bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
        minimum_gece: 1,
        giris_bazli: "0",
        tum_gunler: "1",
        gunler: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        yineleme: "0",
        toplam_geceden_hesaplama: "0",
        grup_id: 0,
        tum_otel_pansiyonlar: "1",
        otel_pansiyonlar: [],
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },

    setAddRestrictionLoading: (state, action) => {
      state.addRestrictionLoading = action.payload;
    },
    setAddRestrictionError: (state, action) => {
      state.addRestrictionError = action.payload;
    },
    setUpdateRestrictionLoading: (state, action) => {
      state.updateRestrictionLoading = action.payload;
    },
    setUpdateRestrictionError: (state, action) => {
      state.updateRestrictionError = action.payload;
    },
    setDeleteRestrictionLoading: (state, action) => {
      state.deleteRestrictionLoading = action.payload;
    },
    setDeleteRestrictionError: (state, action) => {
      state.deleteRestrictionError = action.payload;
    },
  },
});

export const {
  handleSaveNightRestrictions,
  handleDeleteFromNightRestrictionsWithId,
  handleSaveNightRestrictionForEdit,
  handleChangeInput,
  handleAddNightRestrictionFormErrors,
  handleSelectRoom,
  resetNightRestrictions,
  resetNightRestriction,
  setIsLoading,
  setError,
  setAddRestrictionLoading,
  setAddRestrictionError,
  setUpdateRestrictionLoading,
  setUpdateRestrictionError,
  setDeleteRestrictionLoading,
  setDeleteRestrictionError,
} = nightRestrictionsSlice.actions;

export default nightRestrictionsSlice.reducer;
