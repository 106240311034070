import { createSlice } from "@reduxjs/toolkit";

import {
  ChildGeneralInformation,
  ChildInformation,
  ChildInformationState,
} from "types/PricesQuotas";

export interface ChildInformationInitialState {
  childInformation: ChildInformation;
  childInformationState: ChildInformationState;
  childSingleGeneralInformation: ChildGeneralInformation[];
  childMultipleGeneralInformation: ChildGeneralInformation[];
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  success: string;
}

export const initialState: ChildInformationInitialState = {
  childInformation: {
    oda_genel_bilgileri: {
      dogum_hesaplama: "0",
      tek_yani_cocuk: "0",
    },
    cocuk_yas_bilgileri: [],
    cocuk_bilgileri: [],
  },
  childInformationState: {
    oda_genel_bilgileri: {
      dogum_hesaplama: "0",
      tek_yani_cocuk: "0",
    },
    cocuk_yas_bilgileri: [],
    cocuk_bilgileri: [],
  },
  childSingleGeneralInformation: [
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "tek",
      ozel_yas_id: 0,
      cocuk: "1",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "tek",
      ozel_yas_id: 0,
      cocuk: "2",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "tek",
      ozel_yas_id: 0,
      cocuk: "3",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "tek",
      ozel_yas_id: 0,
      cocuk: "4",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
  ],
  childMultipleGeneralInformation: [
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "cift",
      ozel_yas_id: 0,
      cocuk: "1",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "cift",
      ozel_yas_id: 0,
      cocuk: "2",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "cift",
      ozel_yas_id: 0,
      cocuk: "3",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
    {
      id: 0,
      otel_id: 0,
      oda_id: 0,
      otel_oda_id: 0,
      tip: "cift",
      ozel_yas_id: 0,
      cocuk: "4",
      cocuk_kabul: 0,
      cocuk_kabul2: 0,
      ucretsiz_yas_baslangic: "0",
      ucretsiz_yas_bitis: "0",
      ucretli_yas_baslangic: "0",
      ucretli_yas_bitis: "0",
    },
  ],
  selectedRoom: 0,
  isLoading: false,
  error: "",
  success: "",
};

const childInformationSlice = createSlice({
  name: "childInformations",
  initialState,
  reducers: {
    handleSaveChildInformation(state, action) {
      state.childInformation = action.payload;
    },
    handleSaveChildInformationState(state, action) {
      state.childInformationState = action.payload;
    },
    handleChangeInput(state, action) {
      state.childInformationState = {
        ...state.childInformationState,
        ...action.payload,
      };
    },
    handleChangeChildInformationInput(state, action) {
      state.childSingleGeneralInformation = action.payload;
      state.childMultipleGeneralInformation = action.payload;
    },
    handleSingleGeneralInformation(state, action) {
      const childrenInformation = action.payload
        .cocuk_bilgileri as ChildGeneralInformation[];
      const filteredChildrenInformation = childrenInformation.filter(
        (child) => child.tip === "tek"
      );
      const duplicateState = [...state.childSingleGeneralInformation];

      const singleChildrenInformation = duplicateState.map((child) => {
        const inComingChildInfo = filteredChildrenInformation.find(
          (item) => item.cocuk === child.cocuk
        );
        if (inComingChildInfo) {
          return inComingChildInfo;
        } else {
          return child;
        }
      });

      state.childSingleGeneralInformation = singleChildrenInformation;
    },
    handleMultipleGeneralInformation(state, action) {
      const childrenInformation = action.payload
        .cocuk_bilgileri as ChildGeneralInformation[];
      const filteredChildrenInformation = childrenInformation.filter(
        (child) => child.tip === "cift"
      );
      const duplicateState = [...state.childMultipleGeneralInformation];

      const multipleChildrenInformation = duplicateState.map((child) => {
        const inComingChildInfo = filteredChildrenInformation.find(
          (item) => item.cocuk === child.cocuk
        );
        if (inComingChildInfo) {
          return inComingChildInfo;
        } else {
          return child;
        }
      });

      state.childMultipleGeneralInformation = multipleChildrenInformation;
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveChildInformation,
  handleSaveChildInformationState,
  handleSingleGeneralInformation,
  handleMultipleGeneralInformation,
  handleChangeChildInformationInput,
  handleChangeInput,
  handleSelectRoom,
  setIsLoading,
  setSuccess,
  setError,
} = childInformationSlice.actions;

export default childInformationSlice.reducer;
