import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";

import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";

interface FacilityWelcomeCodeProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  welcomeCodeOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityWelcomeCode = ({
  values,
  formErrors,
  validateFormChange,
  welcomeCodeOptions,
}: FacilityWelcomeCodeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Karşılama Kodu"
        // error={formErrors.karsilama_kodu}
        divider
      >
        <Select
          options={welcomeCodeOptions}
          value={welcomeCodeOptions.find(
            (option) => option.value === values.karsilama_kodu
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                karsilama_kodu: selected.value,
              })
            );
            validateFormChange({
              ...values,
              karsilama_kodu: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityWelcomeCode;
