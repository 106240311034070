import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/discountCodes/reducer";
import {
  DiscountCodeFormErrors,
  DiscountCodeState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface CodeNameProps {
  values: DiscountCodeState;
  formErrors: DiscountCodeFormErrors;
  validateFormChange: (values: DiscountCodeState) => void;
}

const CodeName = ({
  values,
  validateFormChange,
  formErrors,
}: CodeNameProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İndirim Kuponu Adı"
        error={formErrors.indirim_adi}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.indirim_adi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                indirim_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              indirim_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CodeName;
