import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import { getComments } from "slices/comments/listComments/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

/*
  *@commenterOptions ->  value: personel, label: "Müşteri" || "Personel"
*/
interface Option {
  value: string;
  label: string;
}

const useListComments = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, personel } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const commenterOptions: Option[] = [
    { value: "0", label: "Müşteri" },
    { value: "1", label: "Personel" },
  ];

  useEffect(() => {
    dispatch(
      getComments({
        otel_id,
        page: 1,
        per_page: 10,
        start_date: "",
        end_date: "",
        personel,
      }))
      console.log("First loading personel: ", personel)
    ;}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [otel_id]);

  return {
    isLoading,
    commenterOptions,
  };
};

export default useListComments;
