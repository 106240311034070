import { createSlice } from "@reduxjs/toolkit";

import { PhoneStatus } from "types/reports";

export interface PhoneStatusInitialState {
  phoneStatus: PhoneStatus[];
  isLoading: boolean;
  error: string;
}

const initialState: PhoneStatusInitialState = {
  phoneStatus: [],
  isLoading: false,
  error: "",
};

const phoneStatusSlice = createSlice({
  name: "phoneStatus",
  initialState,
  reducers: {
    handleSavePhoneStatus(state, action) {
      const payload = Object.values(action.payload) as PhoneStatus[];
      state.phoneStatus = payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSavePhoneStatus, setIsLoading, setError } =
  phoneStatusSlice.actions;

export default phoneStatusSlice.reducer;
