import { useSelector } from "react-redux";
import { ReviewReservationInitialState } from "slices/reservation/reviewReservation/reducer";

const useHandleRequests = () => {
  const { reservation } = useSelector(
    (state: { ReviewReservation: ReviewReservationInitialState }) =>
      state.ReviewReservation
  );

  //TODO Add cancel room and print room requests
  const handleCancelRoom = () => {
    alert("Room is canceled");
  };

  const handlePrintRoom = () => {
    alert("Room is printed");
  };

  return {
    reservation,
    onCancelRoom: handleCancelRoom,
    onPrintRoom: handlePrintRoom,
  };
};

export default useHandleRequests;
