import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";
import ContractInvoiceForm from "../ContractProceduresTable/ContractInvoiceForm";
interface HotelContractProceduresTableProps {
  isLoading: boolean;
}

const HotelContractProceduresTable = ({
  isLoading,
}: HotelContractProceduresTableProps) => {
  const { tableData, tableColumns, isOpen, toggleModal, legend } =
    useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Otel Kontrat İşlemleri">
      <CustomReactTable
        fileName="Otel Kontrat İşlemleri"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
        legend={legend}
      />
      <ContractInvoiceForm openModal={isOpen} toggleModal={toggleModal} />
    </CustomFormContainer>
  );
};

export default HotelContractProceduresTable;
