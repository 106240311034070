import UpdateGroupPage from 'Components/Custom/UserOperationsPages/UpdateGroupPage';
import useUpdateGroup from 'Components/Hooks/userOperations/useUpdateGroup';
import PageContainer from 'pages/PageContainer';

const UpdateGroup = () => {

    const { isLoading } = useUpdateGroup();

  return (
    <PageContainer title="Grup Düzenle">
        <UpdateGroupPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default UpdateGroup