import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

const COLORS = {
  yellow: "#eab308",
  darkBlue: "#4272e7",
  lightBlue: "#80AEF9",
  sky: "#0ea5e9",
  indigo: "#6366f1",
  rose: "#ec4899",
  slate: "#94a3b8",
  white: "#FFFFFF",
  warning: "#fef9c3",
  info: "#dbeafe",
  blue: "#3b82f6",
  purple: "#8b5cf6",
  orange: "#f97316",
  red: "#ef4444",
  green: "#22c55e",
  gray: "#71717a",
  teal: "#14b8a6",
};

interface CustomDiscountCardProps {
  color: keyof typeof COLORS;
  title: string;
  info: string;
  icon: string;
  link: string;
}

const CustomDiscountCard = ({
  color,
  title,
  info,
  icon,
  link,
}: CustomDiscountCardProps) => {
  return (
    <Link
      to={`${link}`}
      style={{
        textDecoration: "none",
      }}
    >
      <Card style={{ backgroundColor: `${COLORS[color]}`, height: "200px" }}>
        <CardBody
          className="gap-3 py-3 px-4"
          style={{
            color: COLORS.white,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <div className="d-flex align-items-center gap-3">
            <p style={{ color: COLORS.white }}>{info}</p>
            <i className={`${icon}`} style={{ fontSize: "36px" }} />
          </div>

          <p
            style={{
              fontSize: "20px",
              fontWeight: "500",
              margin: "0",
            }}
          >
            {title}
          </p>
        </CardBody>
      </Card>
    </Link>
  );
};

export default CustomDiscountCard;
