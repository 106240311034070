import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AddLocationRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { AddLocationInitialState } from "slices/definitions/addLocation/reducer";

const useHandleForm = () => {
  const selectedAddLocationState = (state: {
    AddLocation: AddLocationInitialState;
  }) => state.AddLocation;

  const selectAddLocationProperties = createSelector(
    selectedAddLocationState,
    (state) => ({
      location: state.location,
      addLocationFormErrors: state.addLocationFormErrors,
      isLoading: state.isLoading,
      error: state.error,
    })
  );

  const { location, addLocationFormErrors, isLoading, error } = useSelector(
    selectAddLocationProperties
  );

  const addNewLocationRequestPayload: AddLocationRequestPayload = {
    parent_id: location.parent_id,
    lokasyon: location.lokasyon,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    location,
    isLoading,
    error,
    addNewLocationRequestPayload,
    formErrors: addLocationFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
