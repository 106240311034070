import { createSlice } from "@reduxjs/toolkit";
import {
  SpecialDiscount,
  SpecialDiscountFormErrors,
  SpecialDiscountState,
  SpecialOffer,
} from "types/DiscountsAndActions";

import moment from "moment";
import { Hotel } from "types/Hotel";

export interface SpecialDiscountsInitialState {
  specialDiscounts: SpecialDiscount[];
  selectedRoom: number;
  specialOffers: SpecialOffer[];
  specialDiscount: SpecialDiscountState;
  specialDiscountFormErrors: SpecialDiscountFormErrors;
  allHotels: Partial<Hotel>[];
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
  updateDiscountLoading: boolean;
  updateDiscountError: string;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string;
  addSpecialOfferLoading: boolean;
  addSpecialOfferError: string;
}

export const initialState: SpecialDiscountsInitialState = {
  specialDiscounts: [],
  specialOffers: [],
  specialDiscount: {
    id: 0,
    grup_id: 0,
    rez_tarihi_baslangic: moment().format("YYYY-MM-DD"),
    rez_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
    konaklama_tarihi_baslangic: moment().format("YYYY-MM-DD"),
    konaklama_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
    min_gece_sayisi: 1,
    ozel_oran: 0,
    giris_gunu_durum: "0",
    giris_gunu_baslangic_tarihi: moment().format("YYYY-MM-DD"),
    giris_gunu_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
    radio_gun: "1",
    gun: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    indirim_tipi: "0",
    deskalti_durum: "0",
    maliyete_uygula: "1",
    direkt_uygula: "1",
    eb_birlikte: "1",
    kalode_birlikte: "1",
    diger_birlikte: "1",
    yalniz_bu_otel: 1,
    secili_oteller: [],
    tum_pansiyonlar: 1,
    secili_pansiyonlar: [],
    tum_odeme_tipleri: 1,
    secili_odeme_tipleri: [],
  },
  specialDiscountFormErrors: {},
  selectedRoom: 0,
  allHotels: [],
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
  updateDiscountLoading: false,
  updateDiscountError: "",
  deleteDiscountLoading: false,
  deleteDiscountError: "",
  addSpecialOfferLoading: false,
  addSpecialOfferError: "",
};

const specialDiscountsSlice = createSlice({
  name: "specialDiscounts",
  initialState,
  reducers: {
    handleSaveSpecialDiscounts(state, action) {
      state.specialDiscounts = action.payload;
    },
    handleDeleteFromSpecialDiscountsWithId(state, action) {
      state.specialDiscounts = state.specialDiscounts.filter(
        (item) => item.id !== action.payload
      );
    },
    handleSaveSpecialDiscountForEdit(state, action) {
      state.specialDiscount = action.payload;
    },
    handleSaveSpecialOffers(state, action) {
      state.specialOffers = action.payload;
    },
    handleChangeInput(state, action) {
      state.specialDiscount = {
        ...state.specialDiscount,
        ...action.payload,
      };
    },
    handleAddSpecialDiscountFormErrors(state, action) {
      state.specialDiscountFormErrors = action.payload;
    },
    handleSaveAllHotels(state, action) {
      state.allHotels = action.payload;
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
    resetSpecialDiscounts(state) {
      state.specialDiscounts = [];
    },
    resetSpecialDiscount(state) {
      state.specialDiscount = {
        grup_id: 0,
        rez_tarihi_baslangic: moment().format("YYYY-MM-DD"),
        rez_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
        konaklama_tarihi_baslangic: moment().format("YYYY-MM-DD"),
        konaklama_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
        min_gece_sayisi: 1,
        ozel_oran: 0,
        giris_gunu_durum: "0",
        giris_gunu_baslangic_tarihi: moment().format("YYYY-MM-DD"),
        giris_gunu_bitis_tarihi: moment().add(1, "months").format("YYYY-MM-DD"),
        radio_gun: "1",
        gun: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        indirim_tipi: "0",
        deskalti_durum: "0",
        maliyete_uygula: "1",
        direkt_uygula: "1",
        eb_birlikte: "1",
        kalode_birlikte: "1",
        diger_birlikte: "1",
        yalniz_bu_otel: 1,
        secili_oteller: [],
        tum_pansiyonlar: 1,
        secili_pansiyonlar: [],
        tum_odeme_tipleri: 1,
        secili_odeme_tipleri: [],
      };
    },
    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateDiscountLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateDiscountError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteDiscountLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteDiscountError = action.payload;
    },
    setAddSpecialOfferLoading(state, action) {
      state.addSpecialOfferLoading = action.payload;
    },
    setAddSpecialOfferError(state, action) {
      state.addSpecialOfferError = action.payload;
    },
  },
});

export const {
  handleSaveSpecialDiscounts,
  handleDeleteFromSpecialDiscountsWithId,
  handleSaveSpecialDiscountForEdit,
  handleSaveSpecialOffers,
  handleChangeInput,
  handleAddSpecialDiscountFormErrors,
  handleSelectRoom,
  handleSaveAllHotels,
  setIsLoading,
  setError,
  resetSpecialDiscounts,
  resetSpecialDiscount,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
  setAddSpecialOfferLoading,
  setAddSpecialOfferError,
} = specialDiscountsSlice.actions;

export default specialDiscountsSlice.reducer;
