import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import Select from "react-select";
import { ChildRate, SaveRoomPersonPriceRateState } from "types/PricesQuotas";
import { handleChangeInput } from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { getRoomPersonPriceRates } from "slices/pricesAndQuota/roomPersonPriceRates/thunk";
import PriceRateInput from "./PriceRateInput";

interface PersonRoomTypesProps {
  values: SaveRoomPersonPriceRateState;
  childOptions: { value: number; label: string }[];
  arrangeAccumodationTypes: (maxAdults: number, maxGuests: number) => string[];
  hotelId: number;
  roomId: number;
  calculateWithChild: (defaultRate: boolean) => ChildRate[];
  calculateWithOutChild: (defaultRate: boolean) => ChildRate[];
  numberOfChildren: number;
  withChild: ChildRate[];
  setWithChild: React.Dispatch<React.SetStateAction<ChildRate[]>>;
  withOutChild: ChildRate[];
  setWithOutChild: React.Dispatch<React.SetStateAction<ChildRate[]>>;
  isClicked: string;
  setIsClicked: React.Dispatch<React.SetStateAction<string>>;
}

interface Option {
  value: number;
  label: string;
}

const PersonRoom = ({
  values,
  hotelId,
  roomId,
  childOptions,
  calculateWithChild,
  calculateWithOutChild,
  numberOfChildren,
  withChild,
  setWithChild,
  withOutChild,
  setWithOutChild,
  isClicked,
  setIsClicked,
}: PersonRoomTypesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const personPerRates = values.misafir_oranlari;

  const calculatePerPersonPrice = (index: number) => {
    const kisibasi = Number(values.kisi_basi_fiyat);
    const kisiOrani = personPerRates.map((item) => item.kisi_orani)[index];

    return Math.ceil(kisibasi * Number(kisiOrani));
  };

  return (
    <>
      {Array.from({ length: values.uygulanabilecek_max_yetiskin }).map(
        (_, index) => {
          const maxAdults = index + 1;
          const roomLabel = `${maxAdults} Kişilik Oda`;

          return (
            <CustomFormFieldContainer key={index} label={roomLabel}>
              <div className="d-flex align-items-center gap-3">
                <Input
                  style={{ width: "150px" }}
                  type="number"
                  value={calculatePerPersonPrice(index)}
                  onChange={(e) => {}}
                  disabled={true}
                />
                <PriceRateInput
                  max={100}
                  min={0}
                  step={0.05}
                  value={Number(
                    personPerRates.find(
                      (item) =>
                        item.kisi_sayisi === index + 1 && item.kisi_tipi === "y"
                    )?.kisi_orani ?? 0
                  )}
                  onChange={(value) => {
                    const personIndex = personPerRates.findIndex(
                      (item) =>
                        item.kisi_sayisi === index + 1 && item.kisi_tipi === "y"
                    );

                    const hasThisPerson = personIndex !== -1;

                    const updateList = (index: number, val: number) => {
                      const dummyList = personPerRates.map((item) => ({
                        ...item,
                      }));
                      dummyList[index].kisi_orani = val.toString();

                      return dummyList;
                    };

                    const person = {
                      kisi_tipi: "y",
                      kisi_sayisi: index + 1,
                      tek_yani_cocuk: "0",
                      kisi_orani: value.toString(),
                    };

                    const newValues = hasThisPerson
                      ? updateList(personIndex, value)
                      : [person, ...personPerRates];

                    dispatch(
                      handleChangeInput({ misafir_oranlari: newValues })
                    );
                  }}
                />
              </div>
            </CustomFormFieldContainer>
          );
        }
      )}

      <CustomFormFieldContainer label="">
        <Select
          placeholder="Seçiniz..."
          value={childOptions.find(
            (option) => option.value === (values.cocuk_ozel_yas ?? 0)
          )}
          options={childOptions}
          onChange={(selected: Option) => {
            const payload = {
              otel_id: hotelId,
              oda_id: roomId,
              cocuk_ozel_yas_id: selected.value,
            };

            dispatch(
              getRoomPersonPriceRates(payload, {
                type: "misafir_oranlari",
                value: selected.value.toString(),
              })
            );
            dispatch(
              handleChangeInput({
                cocuk_ozel_yas: selected.value,
              })
            );
          }}
        ></Select>
        <div className="d-flex align-items-center gap-3 mt-3">
          <div
            className="border border-primary px-3 py-2 rounded cursor-pointer fw-medium"
            style={{
              backgroundColor:
                isClicked === "0" ? "var(--theme-primary)" : "white",
              color: isClicked === "0" ? "white" : "black",
            }}
            onClick={() => setIsClicked("0")}
          >
            2+ Yetişkin Yanında
          </div>
          {values.bir_yetiskin_yaninda_cocuk_varmi === true && (
            <div
              className="border border-primary px-3 py-2 rounded cursor-pointer fw-medium"
              style={{
                backgroundColor:
                  isClicked === "1" ? "var(--theme-primary)" : "white",
                color: isClicked === "1" ? "white" : "black",
              }}
              onClick={() => setIsClicked("1")}
            >
              1 Yetişkin Yanında
            </div>
          )}
        </div>
      </CustomFormFieldContainer>

      {isClicked === "1"
        ? withChild.map((item, index) => {
            const label =
              item.kisi_tipi === "c1"
                ? "1 Çocuk"
                : item.kisi_tipi === "c2"
                ? "2 Çocuk"
                : item.kisi_tipi === "c3"
                ? "3 Çocuk"
                : item.kisi_tipi === "c4"
                ? "4 Çocuk"
                : "";
            return (
              <CustomFormFieldContainer key={index} label={label}>
                <div className="d-flex align-items-center gap-3">
                  <Input
                    style={{ width: "150px" }}
                    type="number"
                    value={Math.ceil(
                      Number(values.kisi_basi_fiyat) * Number(item.kisi_orani)
                    )}
                    disabled={true}
                  />
                  <PriceRateInput
                    max={100}
                    min={0}
                    step={0.05}
                    value={Number(item.kisi_orani)}
                    onChange={(value) => {
                      const filteredWithChild = withChild.filter(
                        (i) => i.kisi_tipi !== item.kisi_tipi
                      );

                      const sortWithChild = [
                        ...filteredWithChild,
                        {
                          ...item,
                          kisi_orani: value.toString(),
                        },
                      ];

                      sortWithChild.sort((a, b) =>
                        a.kisi_tipi.localeCompare(b.kisi_tipi)
                      );

                      setWithChild(sortWithChild);
                    }}
                    disabled={true}
                  />
                </div>
              </CustomFormFieldContainer>
            );
          })
        : withOutChild.map((item, index) => {
            const label =
              item.kisi_tipi === "c1"
                ? "1 Çocuk"
                : item.kisi_tipi === "c2"
                ? "2 Çocuk"
                : item.kisi_tipi === "c3"
                ? "3 Çocuk"
                : item.kisi_tipi === "c4"
                ? "4 Çocuk"
                : "";
            return (
              <CustomFormFieldContainer key={index} label={label}>
                <div className="d-flex align-items-center gap-3">
                  <Input
                    style={{ width: "150px" }}
                    type="number"
                    value={Math.ceil(
                      Number(values.kisi_basi_fiyat) * Number(item.kisi_orani)
                    )}
                    disabled={true}
                  />
                  <PriceRateInput
                    max={100}
                    min={0}
                    step={0.05}
                    value={Number(item.kisi_orani)}
                    onChange={(value) => {
                      const filteredWithOutChild = withOutChild.filter(
                        (i) => i.kisi_tipi !== item.kisi_tipi
                      );

                      const sortWithOutChild = [
                        ...filteredWithOutChild,
                        {
                          ...item,
                          kisi_orani: value.toString(),
                        },
                      ];

                      sortWithOutChild.sort((a, b) =>
                        a.kisi_tipi.localeCompare(b.kisi_tipi)
                      );

                      setWithOutChild(sortWithOutChild);
                    }}
                    disabled={true}
                  />
                </div>
              </CustomFormFieldContainer>
            );
          })}
    </>
  );
};

export default PersonRoom;
