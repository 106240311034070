import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { MonthlySalesInitialState } from "slices/reports/monthlySales/reducer";
import {
  getMonthlySalesFilter,
  getMonthlySales,
} from "slices/reports/monthlySales/thunk";

const useMonthlySales = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [defaultState, setDefaultState] = useState({
    grup_id: "0",
    date: moment().format("YYYY-MM"),
  });

  const { isLoading, monthlySalesFilter } = useSelector(
    (state: { MonthlySales: MonthlySalesInitialState }) => state.MonthlySales
  );

  useEffect(() => {
    dispatch(getMonthlySalesFilter());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { gruplar, tarihler } = monthlySalesFilter;

    if (gruplar.length > 0 && tarihler.length > 0) {
      setDefaultState({
        grup_id: gruplar[0].id.toString(),
        date: tarihler[0],
      });

      const payload = {
        grup_id: gruplar[0].id,
        date: tarihler[0],
      };

      dispatch(getMonthlySales(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlySalesFilter]);

  return {
    isLoading,
    defaultState,
  };
};

export default useMonthlySales;
