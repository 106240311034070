import { UpdateFacilityRoomState } from "types/facility";
import useHandlePayload from "./useHandlePayload";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  getFacilityRoom,
  updateFacilityRoomFeatures,
} from "slices/facility/facilityRooms/thunk";
import { CustomButton } from "Components/Custom/UI";

interface UpdateFacilityRoomInfoButtonProps {
  values: UpdateFacilityRoomState;
  requestPayload: FormData;
  validateForm: (values: UpdateFacilityRoomState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  selectedRoom: number;
  isLoading: boolean;
}

const UpdateFacilityRoomInfoButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  selectedRoom,
  isLoading,
}: UpdateFacilityRoomInfoButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { getPayload, otel_id } = useHandlePayload(
    requestPayload,
    values,
    selectedRoom
  );

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateFacilityRoomFeatures(getPayload(), () => {
          dispatch(getFacilityRoom({ otel_id, oda_id: 0 }));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Oda Bilgilerini Güncelle
      </CustomButton>
    </div>
  );
};
export default UpdateFacilityRoomInfoButton;
