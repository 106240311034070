import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleAddHotelContractFormErrors,
  handleUpdateHotelContractFormErrors,
} from "slices/financeAndAccounting/hotelContracts/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  HotelContractState,
  AddHotelContractFormErrors,
  UpdateHotelContractFormErrors,
} from "types/financeAndAccounting";

const useValidateForm = (type: "add" | "update") => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = (values: HotelContractState, showErrorToast = true) => {
    const addErrors: AddHotelContractFormErrors = {};
    const updateErrors: UpdateHotelContractFormErrors = {};

    if (type === "add") {
      if (!values.b_tarih) {
        addErrors.b_tarih = "Zorunlu alan.";
      }
      if (!values.s_tarih) {
        addErrors.s_tarih = "Zorunlu alan.";
      }
      if (!values.belge) {
        addErrors.belge = "Kontrat belgesi yüklemelisiniz.";
      }
    }
    if (type === "update") {
      if (!values.b_tarih) {
        updateErrors.b_tarih = "Zorunlu alan.";
      }
      if (!values.s_tarih) {
        updateErrors.s_tarih = "Zorunlu alan.";
      }
    }

    dispatch(
      type === "add"
        ? handleAddHotelContractFormErrors(addErrors)
        : handleUpdateHotelContractFormErrors(updateErrors)
    );

    const isValid =
      Object.keys(type === "add" ? addErrors : updateErrors).length === 0;

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid:
        Object.keys(type === "add" ? addErrors : updateErrors).length === 0,
    };
  };

  const validateFormChange = (values: HotelContractState) => {
    if (isSubmitted) {
      validateForm(values, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
