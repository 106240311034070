import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface CustomSimpleBarChartProps {
  containerWidth?: number | string;
  containerHeight?: number | string;
  chartWidth?: number;
  chartHeight?: number;
  data: {
    name: string;
    [key: string]: number | string;
  }[];
  colors?: string[];
}

const CustomSimpleBarChart = ({
  containerWidth = "100%",
  containerHeight = 300,
  chartWidth = 500,
  chartHeight = 300,
  data,
  colors,
}: CustomSimpleBarChartProps) => {
  const bars = data[0] ? Object.keys(data[0]) : [];
  bars.splice(bars.indexOf("name"), 1);

  return (
    <ResponsiveContainer width={containerWidth} height={containerHeight}>
      <BarChart
        width={chartWidth}
        height={chartHeight}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {bars.map((bar, index) => (
          <Bar
            key={index}
            dataKey={bar}
            fill={colors ? colors[index] : "var(--theme-primary)"}
            activeBar={<Rectangle opacity={0.8} />}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomSimpleBarChart;
