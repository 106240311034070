import { CustomButton, CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { AddCertificateInitialState } from "slices/myWebSite/addCertificate/reducer";
import {
  deleteCertificate,
  getCertificates,
} from "slices/myWebSite/addCertificate/thunk";
import { setToastError } from "slices/toast/reducer";
import { Certificate } from "types/myWebSite";

const useHandleTable = () => {
  const { certificates } = useSelector(
    (state: { AddCertificate: AddCertificateInitialState }) =>
      state.AddCertificate
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const dispatch: Dispatch<any> = useDispatch();

  const handleDelete = (id: number) => {
    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("otel_id", otel_id.toString());
    dispatch(
      deleteCertificate(formData, () => {
        dispatch(getCertificates(otel_id));
      })
    );
  };

  const handleShowDetail = (pdfUrl: string) => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    } else {
      setToastError("Sertifika bulunamadı.");
    }
  };

  const tableData: Certificate[] = certificates?.map((certificate) => ({
    id: certificate.id,
    otel_id: certificate.otel_id,
    sertifika_adi: certificate.sertifika_adi,
    sertifika_link: certificate.sertifika_link,
    add_time: certificate.add_time,
  }));

  const tableColumns = [
    {
      header: "Sertifika Adı",
      accessorKey: "sertifika_adi",
      enableColumnFilter: false,
    },
    {
      header: "Ekleme Zamanı",
      accessorKey: "add_time",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const { id, sertifika_link } = rowData;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              title="Görüntüle"
              variant="detail"
              onClick={() => {
                handleShowDetail(sertifika_link);
              }}
            />
            <CustomRowOperationButton
              title="Sil"
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
