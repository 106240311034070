import CustomRadioButton from "Components/Custom/UI/CustomRadioButton";
import { STOPS } from "enums";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleAddChangedAvailability,
  handleRemoveChangedAvailability,
} from "slices/pricesAndQuota/basicAvailabilityEntry/reducer";

import { IncomingAvailability } from "types/PricesQuotas";

interface SelectAllAvailabilityInputProps {
  roomsIds: number[];
  conceptId: number;
  onSelect: (key: number, value: boolean) => void;
}

interface RadioInputProps {
  name: string;
  availability: STOPS;
  value: STOPS;
  onChange: (availability: STOPS) => void;
}

const RadioInput = ({
  name,
  value,
  availability,
  onChange,
}: RadioInputProps) => {
  const color =
    value === STOPS.SATISA_ACIK
      ? "var(--theme-success)"
      : value === STOPS.SOR_SAT
      ? "var(--theme-warning)"
      : value === STOPS.STOPLU
      ? "var(--theme-danger)"
      : "var(--theme-secondary)";

  return (
    <CustomRadioButton
      label=""
      name={name}
      value={value}
      checked={availability === value}
      onChange={() => {
        onChange(value);
      }}
      color={color}
      disabled={false}
    />
  );
};

const SelectAllAvailabilitiesInput = ({
  roomsIds,
  conceptId,
  onSelect,
}: SelectAllAvailabilityInputProps) => {
  const [availability, setAvailability] = useState<STOPS>(STOPS.YOK);

  const dispatch: Dispatch<any> = useDispatch();

  const handleChangeAvailability = (availability: STOPS) => {
    handleAddAvailability(availability);
    setAvailability(availability);
  };

  const handleAddAvailability = (availability: STOPS) => {
    const conceptId = name.split("-")[0];
    const roomsIds = name.split("-").slice(1);

    const changedAvailabilities: IncomingAvailability[] =
      availability !== STOPS.YOK
        ? roomsIds.map((roomId) => ({
            oda_id: parseInt(roomId),
            pansiyon_id: parseInt(conceptId),
            musaitlik: availability,
          }))
        : [];

    changedAvailabilities.forEach((availability) => {
      dispatch(handleAddChangedAvailability(availability));
    });

    onSelect(parseInt(conceptId), true);
  };

  const removeAllSelectedAvailabilities = () => {
    onSelect(conceptId, false);
    setAvailability(STOPS.YOK);

    roomsIds.forEach((roomId) => {
      dispatch(
        handleRemoveChangedAvailability({
          oda_id: roomId,
          pansiyon_id: conceptId,
        })
      );
    });
  };

  const name = `${conceptId}-${roomsIds.join("-")}`;

  return (
    <div className="w-100 d-flex flex-column align-items-start justify-content-start flex-xl-row align-items-xl-center gap-2 border p-2 h-100">
      <div className="d-flex gap-1">
        <RadioInput
          name={name}
          value={STOPS.SATISA_ACIK}
          availability={availability}
          onChange={handleChangeAvailability}
        />
        <RadioInput
          name={name}
          value={STOPS.SOR_SAT}
          availability={availability}
          onChange={handleChangeAvailability}
        />
        <RadioInput
          name={name}
          value={STOPS.STOPLU}
          availability={availability}
          onChange={handleChangeAvailability}
        />
      </div>
      {availability === STOPS.YOK ? (
        <p className="m-0 fs-12 fw-medium">Tümünü Seç</p>
      ) : (
        <div
          className="d-flex gap-1 align-items-center border p-1 rounded border-danger cursor-pointer"
          onClick={removeAllSelectedAvailabilities}
        >
          <p className="m-0 fs-10 fw-medium theme-text-danger fw-semibold">
            Kaldır
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectAllAvailabilitiesInput;
