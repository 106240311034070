import { PhoneStatusListPage } from "Components/Custom/ReportsPages";
import usePhoneStatusList from "Components/Hooks/reports/usePhoneStatusList";
import PageContainer from "pages/PageContainer";

const PhoneStatusList = () => {
  const { isLoading } = usePhoneStatusList();
  return (
    <PageContainer title="Dahili Durum Listesi">
      <PhoneStatusListPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default PhoneStatusList;
