import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleChangeFilterLogsInput,
  ListLogsInitialState,
} from "slices/userOperations/listLogs/reducer";
import { getFilteredLogs } from "slices/userOperations/listLogs/thunk";
import { FilterLogsPayload } from "types/userOperations";


const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices, filterLogs } = useSelector(
    (state: { ListLogs: ListLogsInitialState }) => state.ListLogs
  );

  const [filterForm, setFilterForm] = useState<FilterLogsPayload>({
    start_date: filterLogs.start_date,
    end_date: filterLogs.end_date,
    user_id: filterLogs.user_id,
    sayfa: filterLogs.sayfa,
    per_page: filterLogs.per_page,
    page: filterLogs.page,
    search: filterLogs.search,
  });

  const handleFormChange = (key: string, value: any ) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
      user_id: filterForm.user_id,
      sayfa: filterForm.sayfa,
      per_page: filterForm.per_page,
      page: filterForm.page,
      search: filterForm.search,
    };
    dispatch(getFilteredLogs(params));
    dispatch(handleChangeFilterLogsInput(params));
  };

  const handleResetFilter = () => {
    setFilterForm({
      start_date: "",
      end_date: "",
      user_id: 0,
      sayfa: "",
      per_page: 10,
      page: 1,
      search: "",
    });

    const params = {
      start_date: "",
      end_date: "",
      user_id: 0,
      sayfa: "",
      per_page: 10,
      page: 1,
      search: "",
    };

    dispatch(getFilteredLogs(params));
    dispatch(handleChangeFilterLogsInput(params));
  };

  const personnelOptions = formChoices.personeller.map((item) => ({
    value: item.id,
    label: item.ad_soyad,
  }));

  const pageOptions = formChoices.sayfalar.map((item) => ({
    value: item.sayfa, 
    label: item.sayfa,
  }));

  return {
    filterForm,
    personnelOptions,
    pageOptions,
    handleFormChange,
    handleFilter: handleGetFilteredList,
    handleClearFilter: handleResetFilter,
  };
};

export default useHandleForm;
