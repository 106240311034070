import { CompetitorAnalysisInfo } from "types/adsAndAnalyze";
import useHandleForm from "./useHandleForm";

import { CustomButton } from "Components/Custom/UI";
import FirstPeriod from "./FirstPeriod";
import SecondPeriod from "./SecondPeriod";
import ThirdPeriod from "./ThirdPeriod";
import GuestNumbers from "./GuestNumbers";
import ChildrenAges from "./ChildrenAges";
import { useEffect } from "react";

interface AnalysisInfoFormProps extends CompetitorAnalysisInfo {
  isLoading: boolean;
}

const AnalysisInfoForm = ({ isLoading, ...props }: AnalysisInfoFormProps) => {
  const {
    formValues,
    handleChangeForm,
    onSaveChanges,
    isSubmitting,
    setIsSubmitting,
  } = useHandleForm(props);

  const { analiz_tipi_adi } = formValues;

  useEffect(() => {
    !isLoading && setIsSubmitting(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div
      className="d-flex flex-column gap-2 border rounded p-3 bg-white"
      style={{ width: "400px" }}
    >
      <p className="fw-bold fs-18 theme-bg-primary p-2 rounded text-white">
        {analiz_tipi_adi}
      </p>
      <div className="d-flex flex-column gap-4">
        <FirstPeriod
          formValues={formValues}
          handleChangeForm={handleChangeForm}
        />
        <SecondPeriod
          formValues={formValues}
          handleChangeForm={handleChangeForm}
        />
        <ThirdPeriod
          formValues={formValues}
          handleChangeForm={handleChangeForm}
        />
        <GuestNumbers
          formValues={formValues}
          handleChangeForm={handleChangeForm}
        />
        <ChildrenAges
          formValues={formValues}
          handleChangeForm={handleChangeForm}
        />
        <div className="d-flex justify-content-end gap-4">
          <CustomButton variant="info" disabled={true}>
            Senkron Başlat
          </CustomButton>
          <CustomButton
            variant="success"
            isLoading={isSubmitting}
            onClick={onSaveChanges}
          >
            Kaydet
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default AnalysisInfoForm;
