import { CustomLoadingPage } from "Components/Custom/UI";
import ExtensionNumbersCards from "./ExtensionNumbersCards";
import ExtensionNumbersCardLegand from "./ExtensionNumbersCards/ExtensionNumbersCardLegand";

interface PeritoExtensionNumbersPageProps {
  isLoading: boolean;
}

const PeritoExtensionNumbersPage = ({ isLoading }: PeritoExtensionNumbersPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ExtensionNumbersCardLegand/>
        <ExtensionNumbersCards />
      </div>
    </>
  );
};

export default PeritoExtensionNumbersPage;
