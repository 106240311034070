import { createSlice } from "@reduxjs/toolkit";
import { OFFICE_LOCATIONS } from "enums";
import { User } from "types/authentication";

export interface LoginInitialState {
  user: User;
  error: string;
  loading: boolean;
  isUserLogout: boolean;
  errorMsg: boolean;
}

export const initialState: LoginInitialState = {
  user: {
    id: 0,
    kullanici_adi: "",
    ad_soyad: "",
    inet_kodu: "",
    email_adresi: "",
    yetki: "",
    aktiflik: "0",
    dahili: "",
    dahili_aktiflik: "0",
    profil_cep_telefon: "",
    profil_adres: OFFICE_LOCATIONS.İzmir,
    dahili_durum: 0,
    bildigi_diller: "",
    cinsiyet: "e",
    create_date: "",
    crm_durum: "0",
    dahili_pass: "",
    inet_sess_id: "",
    mola_suresi: "",
    part_time: "0",
    profil_resim: "",
    profil_resim_2: "",
    reset_code: "",
    sess_id: "",
    socket_connection_id: "",
    socket_durum: "0",
    son_giris_ip: "",
    tanimli_oteller: "", //? string[]
    user_type: "",
  },
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true;
    },
    reset_login_flag(state) {
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
    },
  },
});

export const { apiError, loginSuccess, logoutUserSuccess, reset_login_flag } =
  loginSlice.actions;

export default loginSlice.reducer;
