import AvailabilityField from "./AvailabilityField";
import CtaCtdField from "./CtaCtdField";
import ReservationField from "./ReservationField";
import QuotaField from "./QuotaField";
import PriceTypesContainer from "./PriceTypesContainer";

import useHandleForm from "./useHandleForm";
import { PriceType } from "types/PricesQuotas";

interface PriceEntryFormProps {
  priceTypes: PriceType[];
  paymentMethod: string;
  paymentMethods: string[];
  paymentMethodsMultipliers: {
    [key: string]: string;
  };
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  };
}

const PriceEntryForm = ({
  priceTypes,
  paymentMethods,
  paymentMethodsMultipliers,
  notAllowedToEditPaymentMethods,
  paymentMethod,
}: PriceEntryFormProps) => {
  const { baseFormInputValues, isReady, handleChange, handlePriceChange } =
    useHandleForm(
      priceTypes,
      paymentMethods,
      paymentMethodsMultipliers,
      notAllowedToEditPaymentMethods,
      paymentMethod
    );

  return (
    <div className="d-flex flex-column gap-2 p-2 pt-1 border shadow-sm bg-white rounded-bottom">
      <AvailabilityField values={baseFormInputValues} onChange={handleChange} />
      <CtaCtdField values={baseFormInputValues} onChange={handleChange} />
      <i
        className="ri-price-tag-3-fill fs-24 cursor-pointer theme-text-secondary"
        style={{ visibility: "hidden" }}
      ></i>
      <QuotaField values={baseFormInputValues} onChange={handleChange} />
      <ReservationField values={baseFormInputValues} onChange={handleChange} />
      <PriceTypesContainer
        priceTypes={priceTypes}
        isReady={isReady}
        values={baseFormInputValues}
        onChange={handlePriceChange}
      />
    </div>
  );
};

export default PriceEntryForm;
