import CallsFromGeneralTable from "./CallsFromGeneralTable";
import FilterForm from "./FilterForm";

interface CallsFromGeneralPageProps {
  isLoading: boolean;
}
const CallsFromGeneralPage = ({ isLoading }: CallsFromGeneralPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <CallsFromGeneralTable isLoading={isLoading} />
    </div>
  );
};

export default CallsFromGeneralPage;
