import AddLocationForm from "./AddLocationForm";

interface AddLocationPageProps {
  loading: boolean;
}

const AddLocationPage = (props: AddLocationPageProps) => {
  const { loading } = props;

  return (
    <>
      <AddLocationForm isLoading={loading} />
    </>
  );
};

export default AddLocationPage;
