import { CustomFormContainer } from "Components/Custom/UI";
import SummaryBox from "./SummaryBox";
import useHandleSummary from "./useHandleSummary";

interface AdsReportSummaryProps {
  isLoading: boolean;
}

const AdsReportSummary = ({ isLoading }: AdsReportSummaryProps) => {
  const { summary } = useHandleSummary();
  return (
    <CustomFormContainer isLoading={isLoading}>
      <p className="fs-24 fw-bold text-center">Rapor Özeti</p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1rem",
        }}
      >
        {summary.map((item) => (
          <SummaryBox items={item} />
        ))}
      </div>
    </CustomFormContainer>
  );
};

export default AdsReportSummary;
