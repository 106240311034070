import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { HourlyCallsChartInitialState } from "slices/reports/hourlyCallsChart/reducer";

import { getHourlyCallsChart } from "slices/reports/hourlyCallsChart/thunk";

const useHourlyCallsChart = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { HourlyCallsChart: HourlyCallsChartInitialState }) =>
      state.HourlyCallsChart
  );

  const params = {
    date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    dispatch(getHourlyCallsChart(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useHourlyCallsChart;
