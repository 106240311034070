import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getGiftsAndPromotions,
  addGiftsAndPromotions,
} from "slices/discountsAndActions/giftsAndPromotions/thunk";

import {
  GiftAndPromotionRequestPayload,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

interface AddNewGiftButtonProps {
  values: GiftAndPromotionState;
  requestPayload: GiftAndPromotionRequestPayload;
  validateForm: (values: GiftAndPromotionState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const AddNewGiftButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: AddNewGiftButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleSaveNewDiscount = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addGiftsAndPromotions(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getGiftsAndPromotions(hotelAndSelectedRoom));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleSaveNewDiscount}
        isLoading={isLoading}
      >
        Yeni Hediye & Promosyon Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewGiftButton;
