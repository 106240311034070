import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/stayLongPayLessActions/reducer";
import {
  StayLongPayLessActionFormErrors,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";

import Select from "react-select";

interface SelectFieldsProps {
  values: StayLongPayLessActionState;
  formErrors: StayLongPayLessActionFormErrors;
  validateFormChange: (values: StayLongPayLessActionState) => void;
  conceptsOptions: Option[];
  paymentMethodOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}
const SelectFields = ({
  values,
  formErrors,
  validateFormChange,
  conceptsOptions,
  paymentMethodOptions,
}: SelectFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Tüm Odalara Uygula">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.grup_id === 0}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  grup_id: values.grup_id === 0 ? 1 : 0,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tüm Pansiyonlara Uygula"
        tooltipId="applyAllConcepts"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_otel_pansiyonlar === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_otel_pansiyonlar:
                    values.tum_otel_pansiyonlar === "1" ? "0" : "1",
                })
              );
              dispatch(handleChangeInput({ otel_pansiyonlar: [] }));
              validateFormChange({
                ...values,
                tum_otel_pansiyonlar:
                  values.tum_otel_pansiyonlar === "1" ? "0" : "1",
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.tum_otel_pansiyonlar === "0" && (
        <CustomFormFieldContainer
          label="Seçili Pansiyonlara Uygula"
          error={formErrors.secili_pansiyonlar}
        >
          <Select
            isMulti={true}
            value={
              values.otel_pansiyonlar.map((pansiyon_id) => {
                return conceptsOptions.find(
                  (option) => option.value === pansiyon_id
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedRooms = selected.map((item) => item.value);
              dispatch(handleChangeInput({ otel_pansiyonlar: selectedRooms }));
              validateFormChange({
                ...values,
                otel_pansiyonlar: selectedRooms,
              });
            }}
            options={conceptsOptions}
            placeholder="Pansiyon Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
      <CustomFormFieldContainer
        label="Tüm Odeme Tiplerine Uygula"
        tooltipId="applyAllPaymentMethods"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_odeme_tipleri === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_odeme_tipleri:
                    values.tum_odeme_tipleri === "1" ? "0" : "1",
                })
              );
              dispatch(handleChangeInput({ odeme_tipleri: [] }));
              validateFormChange({
                ...values,
                tum_odeme_tipleri: values.tum_odeme_tipleri === "1" ? "0" : "1",
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.tum_odeme_tipleri === "0" && (
        <CustomFormFieldContainer
          label="Seçili Odeme Tiplerine Uygula"
          error={formErrors.secili_odeme_tipleri}
        >
          <Select
            isMulti={true}
            value={
              values.odeme_tipleri.map((paymentMethod) => {
                return paymentMethodOptions.find(
                  (option) => option.value === paymentMethod
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedPaymentMethods = selected.map((item) => item.value);
              dispatch(
                handleChangeInput({
                  odeme_tipleri: selectedPaymentMethods,
                })
              );
              validateFormChange({
                ...values,
                odeme_tipleri: selectedPaymentMethods,
              });
            }}
            options={paymentMethodOptions}
            placeholder="Ödeme Tipi Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default SelectFields;
