import { FacilityPullImageFormUrlState } from "types/facility";
import useHandlePayload from "./useHandlePayload";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  addFacilityImages,
  getFacilityImages,
} from "slices/facility/facilityImages/thunk";
import { CustomButton } from "Components/Custom/UI";

interface PullImagesFromUrlButtonProps {
  values: FacilityPullImageFormUrlState;
  requestPayload: FormData;
  validateForm: (values: FacilityPullImageFormUrlState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const PullImagesFromUrlButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: PullImagesFromUrlButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { otel_id, getPayload } = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addFacilityImages(getPayload(), () => {
          dispatch(getFacilityImages(otel_id));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Resimleri Çek
      </CustomButton>
    </div>
  );
};

export default PullImagesFromUrlButton;
