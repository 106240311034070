import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import PageStatusSelector from "./FormFields/PageStatusSelector";
import SelfLinkInput from "./FormFields/SelfLinkInput";
import LinkNameInput from "./FormFields/LinkNameInput";
import PageTitleInput from "./FormFields/PageTitleInput";
import PageInfoInput from "./FormFields/PageInfoInput";
import PageImage from "./FormFields/PageImage";
import ContentTitleInput from "./FormFields/ContentTitleInput";
import ContentInfoInput from "./FormFields/ContentInfoInput";
import ContentImage from "./FormFields/ContentImage";
import UpdateAdditionalPageButton from "./FormFields/Buttons/UpdateAdditionalPageButton";
import AddNewAdditionalPageButton from "./FormFields/Buttons/AddNewAdditionalPageButton";

const AddAdditionalPageForm = () => {
  const {
    pageStatusOptions,
    additionalPage,
    additionalPageFormErrors,
    addAdditionalPageLoading,
    updateAdditionalPageLoading,
    onAddNewAdditionalPage,
    onUpdateAdditionalPage,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  const { id } = additionalPage;
  
  return (
    <>
      <div className="d-flex flex-column gap-3 px-4 py-2">
        <CustomFormContainer title={id ? "Sayfayı Düzenle" : "Sayfa Ekle"}>
          <PageStatusSelector
            values={additionalPage}
            formChoices={pageStatusOptions}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <SelfLinkInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <LinkNameInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <PageTitleInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <PageInfoInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <PageImage
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <ContentTitleInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <ContentInfoInput
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <ContentImage
            values={additionalPage}
            formErrors={additionalPageFormErrors}
            validateFormChange={validateFormChange}
          />
          <div className="d-flex justify-content-end">
            {id ? (
              <UpdateAdditionalPageButton
                values={additionalPage}
                validateForm={validateForm}
                handleSubmitted={handleSubmitted}
                onUpdateAdditionalPage={onUpdateAdditionalPage}
                isLoading={updateAdditionalPageLoading}
              />
            ) : (
              <AddNewAdditionalPageButton
                values={additionalPage}
                validateForm={validateForm}
                handleSubmitted={handleSubmitted}
                onAddNewAdditionalPage={onAddNewAdditionalPage}
                isLoading={addAdditionalPageLoading}
              />
            )} 
          </div>
        </CustomFormContainer>
      </div>
    </>
  );
};
export default AddAdditionalPageForm;
