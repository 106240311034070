import {
  CustomPageDescriptions,
  CustomLoadingPage,
} from "Components/Custom/UI";
import AddFacilityForm from "./AddFacilityForm";
import { ADD_FACILITY_DESCRIPTIONS } from "utils/pageDescriptions";
import AddFacilityPageWarningModal from "./AddFacilityPageWarningModal";

interface AddFacilityPageProps {
  isLoading: boolean;
}

const AddFacilityPage = ({ isLoading }: AddFacilityPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <CustomPageDescriptions
          variant="danger"
          descriptions={ADD_FACILITY_DESCRIPTIONS}
        />
        <AddFacilityForm />
        <AddFacilityPageWarningModal />
      </div>
    </>
  );
};

export default AddFacilityPage;
