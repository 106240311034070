import { CustomButton } from "Components/Custom/UI";

interface SaveAvailabilitiesButtonProps {
  onSave: () => void;
  isLoading: boolean;
}

const SaveAvailabilitiesButton = ({
  onSave,
  isLoading,
}: SaveAvailabilitiesButtonProps) => {
  return (
    <div className="d-flex justify-content-end mt-4 mb-2">
      <CustomButton variant="primary" onClick={onSave} isLoading={isLoading}>
        Kaydet
      </CustomButton>
    </div>
  );
};

export default SaveAvailabilitiesButton;
