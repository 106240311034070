import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getHourlyDiscounts,
  addHourlyDiscount,
} from "slices/discountsAndActions/hourlyDiscounts/thunk";
import {
  HourlyDiscountRequestPayload,
  HourlyDiscountState,
} from "types/DiscountsAndActions";

interface AddNewHourlyDiscountButtonProps {
  values: HourlyDiscountState;
  requestPayload: HourlyDiscountRequestPayload;
  validateForm: (values: HourlyDiscountState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const AddNewHourlyDiscountButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: AddNewHourlyDiscountButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleSaveNewDiscount = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addHourlyDiscount(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getHourlyDiscounts(hotelAndSelectedRoom));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleSaveNewDiscount}
        isLoading={isLoading}
      >
        Yeni Saatlik İndirim Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewHourlyDiscountButton;
