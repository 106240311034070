import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface UpdateCollectionTableProps {
  isLoading: boolean;
  setShowUpdateForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCollectionTable = ({ isLoading, setShowUpdateForm }: UpdateCollectionTableProps) => {
  const { tableData, tableColumns } = useHandleTable(setShowUpdateForm);

  return(
  <>
    <CustomReactTable
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
    />
  </>
  );
};
export default UpdateCollectionTable;
