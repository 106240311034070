import { useSelector } from "react-redux";
import { AddUserInitialState } from "slices/userOperations/addUser/reducer";

const useAddUser = () => {

  const { isLoading, error } = useSelector(
    (state: { AddUser: AddUserInitialState }) => state.AddUser
  );


  return {
    isLoading,
    error,
  };
};

export default useAddUser;
