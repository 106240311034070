import { Link } from "react-router-dom";

import { Form, FormFeedback, Input, Label } from "reactstrap";

import {
  CustomFormButton,
  CustomFormStatusMessages,
} from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";

import { Router } from "types";

const ResetPasswordForm = (props: Router) => {
  const {
    validation,
    forgetError,
    forgetSuccessMsg,
    isLoading,
    passwordShow,
    setPasswordShow,
    passwordConfirmationShow,
    setPasswordConfirmationShow,
  } = useHandleForm(props);

  return (
    <div className="p-2">
      <CustomFormStatusMessages
        error={forgetError}
        success={forgetSuccessMsg}
      />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="mb-4">
          <Label className="form-label" htmlFor="code">
            Sıfırlama Kodu
          </Label>
          <Input
            id="code"
            name="code"
            className="form-control"
            placeholder="Sıfırlama kodunu girin"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.code || ""}
            invalid={
              validation.touched.code && validation.errors.code ? true : false
            }
          />
          {validation.touched.code && validation.errors.code ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.code}</div>
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-4">
          <Label className="form-label" htmlFor="password-input">
            Şifre
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              id="password-input"
              name="password"
              value={validation.values.password || ""}
              type={passwordShow ? "text" : "password"}
              className="form-control pe-5"
              placeholder="Şifre"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.password && validation.errors.password
                  ? true
                  : false
              }
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                {validation.errors.password}
              </FormFeedback>
            ) : null}
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-addon"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              <i className="ri-eye-fill align-middle"></i>
            </button>
          </div>
        </div>
        <div className="mb-4">
          <Label className="form-label" htmlFor="password-confirmation-input">
            Şifre Tekrarı
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              id="password-confirmation-input"
              name="password_confirmation"
              value={validation.values.password_confirmation || ""}
              type={passwordConfirmationShow ? "text" : "password"}
              className="form-control pe-5"
              placeholder="Şifre Tekrarı"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.password_confirmation &&
                validation.errors.password_confirmation
                  ? true
                  : false
              }
            />
            {validation.touched.password_confirmation &&
            validation.errors.password_confirmation ? (
              <FormFeedback type="invalid">
                {validation.errors.password_confirmation}
              </FormFeedback>
            ) : null}
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-confirmation-addon"
              onClick={() =>
                setPasswordConfirmationShow(!passwordConfirmationShow)
              }
            >
              <i className="ri-eye-fill align-middle"></i>
            </button>
          </div>
        </div>

        <div className="text-center mt-4">
          <CustomFormButton
            type="submit"
            variant="success"
            isLoading={isLoading}
          >
            Şifreyi Değiştir
          </CustomFormButton>
        </div>
      </Form>
      <div className="w-100 mt-4 text-center">
        <Link
          to="/giris"
          className="d-block w-100 text-center border border-primary theme-text-primary p-2 rounded text-decoration-none"
        >
          Giriş Yap
        </Link>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
