import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/nightRestrictions/reducer";
import {
  NightRestrictionFormErrors,
  NightRestrictionState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { FormGroup, Input } from "reactstrap";

interface MinimumNightsAndEntryConditionProps {
  values: NightRestrictionState;
  formErrors: NightRestrictionFormErrors;
  validateFormChange: (values: NightRestrictionState) => void;
}

const MinimumNightsAndEntryCondition = ({
  values,
  validateFormChange,
  formErrors,
}: MinimumNightsAndEntryConditionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Gecelemeler ve Min. Geceleme"
        error={formErrors.min_gece_sayisi}
        tooltipId="nightRestrictionsMinNights"
      >
        <CustomNumberSelector
          value={values.minimum_gece}
          onChange={(value) => {
            dispatch(handleChangeInput({ minimum_gece: value }));
            validateFormChange({ ...values, minimum_gece: value });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Giriş Bazlı"
        tooltipId="nightRestrictionsEntryCondition"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.giris_bazli === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  giris_bazli: values.giris_bazli === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default MinimumNightsAndEntryCondition;
