import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import useHandleForm from "./useHandleForm";
import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
  CustomMultipleFileUploader,
} from "Components/Custom/UI";
import { handleChangeHotelContractForm } from "slices/financeAndAccounting/hotelContracts/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

const ContractForm = () => {
  const {
    hotelContract,
    isLoading,
    addHotelContractFormErrors,
    updateHotelContractFormErrors,
    validateFormChange,
    onAddContract,
    onUpdateContract,
    onResetHotelContractState,
  } = useHandleForm();

  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const files = hotelContract.belge ? [hotelContract.belge] : [];

  return (
    <CustomFormContainer
      title={hotelContract.kontrat_id ? "Kontrat Düzenle" : "Kontrat Ekle"}
    >
      <CustomFormFieldContainer
        label="Tarih Aralığı"
        error={
          hotelContract.kontrat_id
            ? updateHotelContractFormErrors.b_tarih ||
              updateHotelContractFormErrors.s_tarih
            : addHotelContractFormErrors.b_tarih ||
              addHotelContractFormErrors.s_tarih
        }
      >
        <Flatpickr
          className="form-control"
          value={[hotelContract.b_tarih, hotelContract.s_tarih]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;

            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeHotelContractForm({
                  b_tarih: stringStartDate,
                  s_tarih: stringEndDate,
                })
              );

              validateFormChange({
                ...hotelContract,
                b_tarih: stringStartDate,
                s_tarih: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
      {hotelContract.kontrat_id ? (
        <CustomFormFieldContainer label="Mevcut Kontrat Dosyası">
          <a
            href={hotelContract.dosya_adi}
            target="_blank"
            rel="noreferrer"
            className="ml-2"
          >
            Kontratı Görüntüle
          </a>
        </CustomFormFieldContainer>
      ) : (
        <></>
      )}
      <CustomFormFieldContainer
        label={
          hotelContract.kontrat_id ? "Yeni Kontrat Dosyası" : "Kontrat Dosyası"
        }
        error={hotelContract.kontrat_id ? "" : addHotelContractFormErrors.belge}
      >
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(files) => {
            dispatch(handleChangeHotelContractForm({ belge: files[0] }));
            validateFormChange({ ...hotelContract, belge: files[0] });
          }}
          maxFileSize={4000000}
          allowedFileTypes={["application/pdf"]}
        />
      </CustomFormFieldContainer>
      <div className="d-flex gap-2 justify-content-end">
        {hotelContract.kontrat_id ? (
          <CustomButton variant="danger" onClick={onResetHotelContractState}>
            Düzenlemeden Çık
          </CustomButton>
        ) : (
          <></>
        )}
        <CustomButton
          variant={hotelContract.kontrat_id ? "success" : "primary"}
          onClick={hotelContract.kontrat_id ? onUpdateContract : onAddContract}
          isLoading={isLoading}
        >
          {hotelContract.kontrat_id ? "Güncelle" : "Kontrat Ekle"}
        </CustomButton>
      </div>
    </CustomFormContainer>
  );
};

export default ContractForm;
