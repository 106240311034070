import { HourlyCallsPage } from "Components/Custom/ReportsPages";
import useHourlyCallsChart from "Components/Hooks/reports/useHourlyCallsChart";
import PageContainer from "pages/PageContainer";

const HourlyCallsChart = () => {
  const { isLoading } = useHourlyCallsChart();
  return (
    <PageContainer title="Saatlik Çağrı Raporu">
      <HourlyCallsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default HourlyCallsChart;
