import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";

interface HotelFacebookLinkProps {
  values: CreateWebSiteState;
  validateFormChange: (values: CreateWebSiteState) => void;
}

const HotelFacebookLink = ({
  values,
  validateFormChange,
}: HotelFacebookLinkProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Facebook Adresi :"
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.facebook_adres}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                facebook_adres: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              facebook_adres: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelFacebookLink;
