import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  setAddDiscountLoading,
  setAddDiscountError,
  handleSaveBreakingDiscounts,
  handleSaveBreakingDiscount,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_BREAKING_DISCOUNTS,
  ADD_BREAKING_DISCOUNT,
} from "helpers/url_helper";

import { BreakingDiscountRequestPayload } from "types/DiscountsAndActions";
import { Dispatch } from "redux";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
}

type SuccessCallback = () => void;

export const getBreakingDiscounts =
  (payload: GetRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_BREAKING_DISCOUNTS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveBreakingDiscounts(response.data || response));
      dispatch(handleSaveBreakingDiscount(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addBreakingDiscount =
  (payload: BreakingDiscountRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddDiscountLoading(true));

      await axios.post(ADD_BREAKING_DISCOUNT, payload);

      successCallback();
      dispatch(setToastSuccess("Son dakika indirimi eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddDiscountLoading(false));
    }
  };
