import { CustomButton } from "Components/Custom/UI";
import { AddWebSitePayload } from "types/myWebSite";

interface AddNewWebSiteButtonProps {
  values: AddWebSitePayload;
  validateForm: (values: AddWebSitePayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onAddNewWebSite: () => void;
  isLoading: boolean;
}

const AddNewWebSiteButton = ({
  values,
  validateForm,
  handleSubmitted,
  onAddNewWebSite,
  isLoading,
}: AddNewWebSiteButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onAddNewWebSite();
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Yeni Web Sitesi Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewWebSiteButton;
