import CustomButton from "../CustomButton";
import { IControls } from "./types";

const Controls = ({ prev, initCal, next, currentYear }: IControls) => {
  return (
    <div className="controls">
      <CustomButton variant="primary" onClick={() => prev()}>
        {currentYear - 1}
      </CustomButton>
      <p
        className="cursor-pointer m-0 fs-20 fw-bold text-black"
        onClick={() => initCal()}
      >
        {currentYear}
      </p>
      <CustomButton variant="primary" onClick={() => next()}>
        {currentYear + 1}
      </CustomButton>
    </div>
  );
};

export default Controls;
