import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";

import { FormGroup, Input, Label } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/discountsAndActions/specialDiscounts/reducer";

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { DB_WEEKDAYS_OPTIONS } from "utils/weekdays";
import Select from "react-select";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface ApplicableDaysProps {
  values: SpecialDiscountState;
  formErrors: SpecialDiscountFormErrors;
  validateFormChange: (values: SpecialDiscountState) => void;
}

const ApplicableDays = ({
  values,
  validateFormChange,
  formErrors,
}: ApplicableDaysProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const showDateSelectors = values.giris_gunu_durum === "1";

  return (
    <>
      <CustomFormFieldContainer
        label="Giriş Gününe Göre Uygula"
        tooltipId="applyAccordingToArrivalDay"
      >
        <FormGroup switch>
          <Input
            type="switch"
            id="giris_gunu_uygula"
            checked={values.giris_gunu_durum === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  giris_gunu_durum: values.giris_gunu_durum === "1" ? "0" : "1",
                })
              );
              values.giris_gunu_durum === "1" &&
                dispatch(
                  handleChangeInput({
                    giris_gunu_baslangic_tarihi: null,
                    giris_gunu_bitis_tarihi: null,
                  })
                );
              if (values.giris_gunu_durum === "0") {
                dispatch(handleChangeInput({ gun: [] }));
                dispatch(handleChangeInput({ radio_gun: "1" }));
              }
              validateFormChange({
                ...values,
                giris_gunu_durum: values.giris_gunu_durum === "1" ? "0" : "1",
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {showDateSelectors ? (
        <CustomFormFieldContainer
          label="Giriş Günü Tarih Aralığı"
          error={formErrors.giris_gunu}
        >
          <Flatpickr
            className="form-control"
            value={[
              values.giris_gunu_baslangic_tarihi,
              values.giris_gunu_bitis_tarihi,
            ]}
            onChange={(dates) => {
              const [startDate, endDate] = dates;
              if (startDate && endDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");
                const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                dispatch(
                  handleChangeInput({
                    giris_gunu_baslangic_tarihi: stringStartDate,
                    giris_gunu_bitis_tarihi: stringEndDate,
                  })
                );
                validateFormChange({
                  ...values,
                  giris_gunu_baslangic_tarihi: stringStartDate,
                  giris_gunu_bitis_tarihi: stringEndDate,
                });
              }
            }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
            }}
          />
        </CustomFormFieldContainer>
      ) : (
        <>
          <CustomFormFieldContainer
            label="Uygulanabilir Günler"
            tooltipId="applicableDays"
            error={formErrors.gun}
          >
            <div className="d-flex gap-3">
              <div>
                <Input
                  type="radio"
                  id="allDays"
                  name="applicableDays"
                  checked={values.radio_gun === "1"}
                  onChange={() => {
                    dispatch(
                      handleChangeInput({
                        radio_gun: "1",
                      })
                    );
                    const allDays = DB_WEEKDAYS_OPTIONS.map(
                      (item) => item.value
                    );
                    dispatch(handleChangeInput({ gun: allDays }));
                    validateFormChange({
                      ...values,
                      radio_gun: "1",
                      gun: [],
                    });
                  }}
                />
                <Label className="ms-2" for="allDays">
                  Tüm Günler
                </Label>
              </div>
              <div>
                <Input
                  type="radio"
                  id="specificDays"
                  name="applicableDays"
                  checked={values.radio_gun === "0"}
                  onChange={() => {
                    dispatch(
                      handleChangeInput({
                        radio_gun: "0",
                      })
                    );
                  }}
                />
                <Label className="ms-2" for="specificDays">
                  Belirli Günler
                </Label>
              </div>
            </div>
            {values.radio_gun === "0" && (
              <div className="mt-2">
                <Select
                  value={values.gun.map((day: any) => {
                    return DB_WEEKDAYS_OPTIONS.find(
                      (item) =>
                        Object.keys(item.value)[0] === Object.keys(day)[0]
                    );
                  })}
                  isMulti={true}
                  onChange={(selectedMulti: any) => {
                    const selectedDays = selectedMulti.map(
                      (item: any) => item.value
                    );
                    dispatch(handleChangeInput({ gun: selectedDays }));
                    validateFormChange({ ...values, gun: selectedDays });
                  }}
                  options={DB_WEEKDAYS_OPTIONS}
                  placeholder="Gün Seçiniz..."
                />
              </div>
            )}
          </CustomFormFieldContainer>
        </>
      )}
    </>
  );
};

export default ApplicableDays;
