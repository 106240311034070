import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import ListItem from "../ListItem";

interface ReservationInfoProps {
  reservation: ReservationReview;
}

const ReservationInfo = ({ reservation }: ReservationInfoProps) => {
  const kapida = reservation.rez_tipi === "kapidaodeme";

  const showKismiOdeme = reservation.kismi_odeme_durum === "1";
  const kismiOdemeStatus =
    showKismiOdeme && reservation.konfirme_edildi_mi === "k"
      ? "İlk Onay Var, Kesin Onay Bekleniyor!"
      : "İlk Onay Yok";

  return (
    <>
      <CustomFormContainer title="Rezervasyon Bilgileri">
        {kapida && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              listStyle: "none",
            }}
          >
            <ListItem
              title="Rezervasyon Kodu"
              value={reservation.rezervasyon_kodu}
              topBorder
            />
            <ListItem
              title="Satış Kanalı"
              value={
                <span className="theme-text-danger fw-bold fs-18">
                  {reservation.satis_operator}
                </span>
              }
              topBorder
            />
            <ListItem
              title="Fiyat Hesaplama"
              value={
                <span className="theme-text-info fw-bold fs-18">
                  {reservation.fiyat_hesaplama}
                </span>
              }
              topBorder
            />
          </div>
        )}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <ListItem title="Otel Adı" value={reservation.otel_adi} topBorder />
          {kapida ? (
            <ListItem
              title="Oda ve Pansiyon Tipi"
              value={reservation.oda_adi + " - " + reservation.pansiyon_adi}
              topBorder
            />
          ) : (
            <ListItem
              title="Otelin Gerçek Telefonu"
              value={"field eklenecek"}
              topBorder
            />
          )}
          <ListItem
            title="Giriş Tarihi"
            value={reservation.giris_tarihi}
            isBold
          />
          <ListItem
            title="Çıkış Tarihi"
            value={reservation.cikis_tarihi}
            isBold
          />
          <ListItem
            title="Müşteri Adı Soyadı"
            value={reservation.musteri_ad_soyad}
          />
          <ListItem
            title="Müşteri Cep Telefonu"
            value={reservation.musteri_cep}
          />
          <ListItem
            title="Müşteri 2. Telefonu"
            value={reservation.musteri_tel}
          />
          <ListItem
            title="Müşteri E-Posta"
            value={reservation.musteri_eposta}
          />
          {kapida && (
            <>
              <ListItem
                title="Toplam Tutar"
                value={Math.ceil(Number(reservation.toplam_tutar)) + " TL"}
                isBold
              />
              <ListItem title="Ödeme Şekli" value={reservation.rez_tipi} />
            </>
          )}
          <ListItem title="Müşteri TC" value={reservation.musteri_tc_kimlik} />
          <ListItem
            title="İptal Sigortası"
            value={reservation.iptal_sigortasi}
          />
          <ListItem title="Müşteri Temsilcisi" value={reservation.satici_adi} />
          {kapida ? (
            <ListItem
              title="Otelin Gerçek Telefonu"
              value={"field eklenecek"}
            />
          ) : (
            <ListItem
              title="Farklı Acenteden Satış"
              value={
                reservation.farkli_acenteden_satis_durum === "1" ? "Var" : "Yok"
              }
            />
          )}
          {showKismiOdeme && (
            <>
              <ListItem
                title="Kısmi Ödemeli Rezervasyon Durum"
                value={kismiOdemeStatus}
                isBold
              />
              <ListItem
                title="Kısmi Ödemeli Tarihi"
                value={reservation.kismi_odeme_tarihi}
                isBold
              />
            </>
          )}
        </div>
      </CustomFormContainer>
    </>
  );
};

export default ReservationInfo;
