import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveMailOrders,
  resetMailOrderForm,
  setIsLoading,
  setMailOrderLoading,
  setError,
  setMailOrderError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_MAIL_ORDERS,
  ADD_MAIL_ORDER,
  UPDATE_MAIL_ORDER,
  DELETE_MAIL_ORDER,
} from "helpers/url_helper";

import { Dispatch } from "redux";

import {
  AddMailOrderRequestPayload,
  UpdateMailOrderRequestPayload,
  DeleteMailOrderRequestPayload,
} from "types/financeAndAccounting";

type SuccessCallback = () => void;

export const getMailOrders = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_MAIL_ORDERS);

    const res = response.data || response;

    dispatch(handleSaveMailOrders(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addMailOrder =
  (payload: AddMailOrderRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMailOrderLoading(true));
      dispatch(setMailOrderError(""));
      await axios.post(ADD_MAIL_ORDER, payload);

      successCallback();
      dispatch(resetMailOrderForm());
      dispatch(setToastSuccess("Kart bilgisi eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMailOrderError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMailOrderLoading(false));
    }
  };

export const updateMailOrder =
  (payload: UpdateMailOrderRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMailOrderLoading(true));
      dispatch(setMailOrderError(""));
      await axios.post(UPDATE_MAIL_ORDER, payload);

      successCallback();
      dispatch(resetMailOrderForm());
      dispatch(setToastSuccess("Kart bilgisi güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMailOrderError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMailOrderLoading(false));
    }
  };

export const deleteMailOrder =
  (payload: DeleteMailOrderRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMailOrderLoading(true));
      dispatch(setMailOrderError(""));
      await axios.post(DELETE_MAIL_ORDER, payload);

      successCallback();
      dispatch(setToastSuccess("Kart bilgisi silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMailOrderError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMailOrderLoading(false));
    }
  };
