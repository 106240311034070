import DateSelector from "./DateSelector";
import FacilityCallsTable from "./FacilityCallsTable";

interface FacilityCallsPageProps {
  isLoading: boolean;
}

const FacilityCallsPage = ({ isLoading }: FacilityCallsPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <DateSelector />
        <FacilityCallsTable loading={isLoading} />
      </div>
    </>
  );
};

export default FacilityCallsPage;
