import { Input } from "reactstrap";
import SearchResults from "./SearchResults";
import useHandleForm from "./useHandleForm";

const InfiniteSearchBar = () => {
  const {
    results,
    searchTerm,
    selectedHotel,
    isLoading,
    handleChangeInput,
    resetSearchTerm,
  } = useHandleForm();

  return (
    <form className="app-search p-0">
      <div className="position-relative">
        <Input
          id="search-options"
          type="text"
          autoCorrect="on"
          className="form-control fs-14"
          placeholder={selectedHotel.otel_adi || "Otel Ara..."}
          value={searchTerm}
          onChange={handleChangeInput}
          autoFocus={true}
          autoComplete="off"
          style={{ width: "250px", height: "35px" }}
        />
        <span className="mdi mdi-magnify search-widget-icon"></span>
        <span
          className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
          id="search-close-options"
        ></span>
      </div>
      <div>
        <SearchResults
          results={results}
          isLoading={isLoading}
          resetSearchTerm={resetSearchTerm}
        />
      </div>
    </form>
  );
};

export default InfiniteSearchBar;
