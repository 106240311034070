import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FACILITY_MANAGER_ROLE } from "enums";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeFacilityManagerInput } from "slices/facility/facilityManagers/reducer";
import {
  FacilityManagerState,
  FacilityManagerFormErrors,
} from "types/facility";

interface ManagerFieldsProps {
  values: FacilityManagerState;
  formErrors: FacilityManagerFormErrors;
  validateFormChange: (values: FacilityManagerState) => void;
  managerRolesOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const ManagerFields = ({
  values,
  formErrors,
  validateFormChange,
  managerRolesOptions,
}: ManagerFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Yetkili Ünvanı" error={formErrors.unvan}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.unvan}
          onChange={(e) => {
            dispatch(
              handleChangeFacilityManagerInput({
                unvan: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              unvan: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Yetkili Adı"
        error={formErrors.yetkili_adi}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.yetkili_adi}
          onChange={(e) => {
            dispatch(
              handleChangeFacilityManagerInput({
                yetkili_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              yetkili_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Telefon Numarası"
        error={formErrors.telefon}
      >
        <Input
          type="number"
          value={values.telefon}
          onChange={(e) => {
            dispatch(
              handleChangeFacilityManagerInput({
                telefon: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              telefon: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="E-Posta Adresi" error={formErrors.mail}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.mail}
          onChange={(e) => {
            dispatch(
              handleChangeFacilityManagerInput({
                mail: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              mail: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Çalıştığı Birim"
        error={formErrors.birim}
      >
        <Select
          value={
            managerRolesOptions.find(
              (option) => option.value === values.birim
            ) || null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeFacilityManagerInput({
                birim:
                  FACILITY_MANAGER_ROLE[
                    selected.value as keyof typeof FACILITY_MANAGER_ROLE
                  ],
              })
            );
            validateFormChange({
              ...values,
              birim:
                FACILITY_MANAGER_ROLE[
                  selected.value as keyof typeof FACILITY_MANAGER_ROLE
                ],
            });
          }}
          options={managerRolesOptions}
          placeholder="Çalıştığı Birim Seçiniz"
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ManagerFields;
