import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getAnsweredCalls } from "slices/reports/answeredCalls/thunk";

const useHandleForm = () => {
  const [filterForm, setFilterForm] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      date: filterForm.date,
    };
    dispatch(getAnsweredCalls(params));
  };

  const handleResetFilter = () => {
    setFilterForm({
      date: moment().format("YYYY-MM-DD"),
    });

    const params = {
      date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getAnsweredCalls(params));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
