import { createSlice } from "@reduxjs/toolkit";

import {
  HourlyDiscount,
  HourlyDiscountFormErrors,
  HourlyDiscountState,
} from "types/DiscountsAndActions";
import moment from "moment";

export interface HourlyDiscountsInitialState {
  hourlyDiscounts: HourlyDiscount[];
  hourlyDiscount: HourlyDiscountState;
  hourlyDiscountFormErrors: HourlyDiscountFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
  updateDiscountLoading: boolean;
  updateDiscountError: string;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string;
}

export const initialState: HourlyDiscountsInitialState = {
  hourlyDiscounts: [],
  hourlyDiscount: {
    otel_id: 0,
    otel_oda_id: 0,
    grup_id: 0,
    radio_gun: 1,
    gun: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    indirim_orani: 0,
    baslangic_saati: moment().add(1, "hours").format("HH:00"),
    bitis_saati: moment().add(2, "hours").format("HH:00"),
    eb_birlikte: "1",
    kalode_birlikte: "1",
    diger_birlikte: "1",
    tum_pansiyonlar: 1,
    secili_pansiyonlar: [],
    tum_odeme_tipleri: "1",
    secili_odeme_tipleri: [],
  },
  hourlyDiscountFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
  updateDiscountLoading: false,
  updateDiscountError: "",
  deleteDiscountLoading: false,
  deleteDiscountError: "",
};

const hourlyDiscountsSlice = createSlice({
  name: "hourlyDiscounts",
  initialState,
  reducers: {
    handleSaveHourlyDiscounts(state, action) {
      state.hourlyDiscounts = action.payload;
    },
    handleDeleteFromHourlyDiscountsWithId(state, action) {
      state.hourlyDiscounts = state.hourlyDiscounts.filter(
        (item) => item.id !== action.payload
      );
    },
    handleSaveHourlyDiscountForEdit(state, action) {
      state.hourlyDiscount = action.payload;
    },
    handleChangeInput(state, action) {
      state.hourlyDiscount = {
        ...state.hourlyDiscount,
        ...action.payload,
      };
    },
    handleAddHourlyDiscountFormErrors(state, action) {
      state.hourlyDiscountFormErrors = action.payload;
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetHourlyDiscounts(state) {
      state.hourlyDiscounts = [];
    },
    resetHourlyDiscount(state) {
      state.hourlyDiscount = {
        otel_id: 0,
        otel_oda_id: 0,
        grup_id: 0,
        radio_gun: 1,
        gun: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        indirim_orani: 0,
        baslangic_saati: moment().add(1, "hours").format("HH:00"),
        bitis_saati: moment().add(2, "hours").format("HH:00"),
        eb_birlikte: "1",
        kalode_birlikte: "1",
        diger_birlikte: "1",
        tum_pansiyonlar: 1,
        secili_pansiyonlar: [],
        tum_odeme_tipleri: "1",
        secili_odeme_tipleri: [],
      };
    },
    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateDiscountLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateDiscountError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteDiscountLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteDiscountError = action.payload;
    },
  },
});

export const {
  handleSaveHourlyDiscounts,
  handleDeleteFromHourlyDiscountsWithId,
  handleSaveHourlyDiscountForEdit,
  handleChangeInput,
  handleAddHourlyDiscountFormErrors,
  handleSelectRoom,
  setIsLoading,
  setError,
  resetHourlyDiscounts,
  resetHourlyDiscount,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} = hourlyDiscountsSlice.actions;

export default hourlyDiscountsSlice.reducer;
