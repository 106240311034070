import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

interface CustomBoxProps {
  title?: string;
  children: React.ReactNode;
  sm?: number;
  md?: number;
  lg?: number;
}

const CustomBox = ({
  title,
  sm = 12,
  md = 6,
  lg = 5,
  children,
}: CustomBoxProps) => {
  return (
    <Container>
      <Row>
        <Col sm={sm} md={md} lg={lg}>
          <Card className="px-2 pt-3">
            {title && (
              <CardHeader className="bg-transparent border-0 text-center">
                <h3 className="text-center theme-text-primary fs-5">{title}</h3>
              </CardHeader>
            )}
            <CardBody>{children}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomBox;
