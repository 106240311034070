import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { SaveRoomPersonPriceRateState } from "types/PricesQuotas";

interface PerPersonSwitchProps {
  values: SaveRoomPersonPriceRateState;
}

const PerPersonSwitch = ({ values }: PerPersonSwitchProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="1 Yetişkin Yanında Farklı Çocuk Oranı">
        <FormGroup switch>
          <Input
            type="switch"
            value={values.bir_yetiskin_yaninda_cocuk_varmi.toString()}
            checked={values.bir_yetiskin_yaninda_cocuk_varmi}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  bir_yetiskin_yaninda_cocuk_varmi: e.target.checked,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default PerPersonSwitch;
