import PeritoExtensionNumbersPage from 'Components/Custom/UserOperationsPages/PeritoExtensionNumbersPage';
import usePeritoExtensionNumbers from 'Components/Hooks/userOperations/usePeritoExtensionNumbers';
import PageContainer from 'pages/PageContainer';

const PeritoExtensionNumbers = () => {

    const { isLoading } = usePeritoExtensionNumbers();

  return (
    <PageContainer title="Perito Dahili Numaralar">
        <PeritoExtensionNumbersPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default PeritoExtensionNumbers