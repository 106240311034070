import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteFromStayLongPayLessActionsWithId,
  handleSaveStayLongPayLessActionForEdit,
} from "slices/discountsAndActions/stayLongPayLessActions/reducer";
import { deleteStayLongPayLessAction } from "slices/discountsAndActions/stayLongPayLessActions/thunk";
import {
  StayLongPayLessAction,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { stayLongPayLessActions } = useSelector(
    (state: any) => state.StayLongPayLessActions
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(
      handleSaveStayLongPayLessActionForEdit({
        ...selectedRow,
        gunler: selectedRow?.gunler
          ? JSON.parse(selectedRow.gunler as unknown as string)
          : [],
      })
    );
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      kal_ode_id: id,
    };
    dispatch(deleteStayLongPayLessAction(payload) as any);
    dispatch(handleDeleteFromStayLongPayLessActionsWithId(id));
  };

  const tableData: StayLongPayLessActionState[] = stayLongPayLessActions?.map(
    (action: StayLongPayLessAction) => {
      return {
        id: action.id,
        grup_id: action.grup_id,
        baslangic_tarih: action.baslangic_tarih,
        bitis_tarih: action.bitis_tarih,
        kal_gun_sayisi: action.kal_gun_sayisi,
        ode_gun_sayisi: action.ode_gun_sayisi,
        tum_gunler: action.tum_gunler,
        gunler: action.gunler,
        kal_ode_geceleme: action.kal_ode_geceleme,
        desk_alti: action.desk_alti,
        ozel_komisyon_orani_durum: action.ozel_komisyon_orani_durum,
        yineleme: action.yineleme,
        eb_birlikte: action.eb_birlikte,
        tum_otel_pansiyonlar: action.tum_otel_pansiyonlar,
        otel_pansiyonlar: action.otel_pansiyonlar,
        tum_odeme_tipleri: action.tum_odeme_tipleri,
        odeme_tipleri: action.odeme_tipleri,
      };
    }
  );

  const tableColumns = [
    {
      header: "Konaklama Tarihi",
      cell: (cell: any) => (
        <span>
          {moment(cell.row.original.baslangic_tarih).format("DD.MM.YYYY")}
          <br />
          {moment(cell.row.original.bitis_tarih).format("DD.MM.YYYY")}
        </span>
      ),
      accessorKey: "konaklama_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Kalınacak Gün Sayısı",
      accessorKey: "kal_gun_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Ödenecek Gün Sayısı",
      accessorKey: "ode_gun_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Geceleme Fiyatı",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "kal_ode_geceleme",
      enableColumnFilter: false,
    },
    {
      header: "Desk Altı",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "desk_alti",
      enableColumnFilter: false,
    },
    {
      header: "Özel Komisyon Oranı Kullan",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "ozel_komisyon_orani_durum",
      enableColumnFilter: false,
    },
    {
      header: "Yinelemeli",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "yineleme",
      enableColumnFilter: false,
    },
    {
      header: "Erken Rezervasyon Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "eb_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_gunler",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const allDays = cell.row.original.tum_gunler;

        return (
          <span className="d-flex gap-2 flex-wrap">
            {allDays === "1" ? (
              <CustomRowBadge value="Tüm Günler" />
            ) : (
              days.map((day: string, index: number) => (
                <CustomRowBadge key={index} value={day} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "gunler",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalara Uygula",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue() ?? [];

        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue() ?? [];
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
