import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveChildInformationState,
  setIsLoading,
  setError,
  handleSaveChildInformation,
  handleSingleGeneralInformation,
  handleMultipleGeneralInformation,
  handleChangeInput,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_CHILD_INFORMATION,
  POST_CHILD_INFORMATION,
} from "helpers/url_helper";
import { ChildInformationRequestPayload } from "types/PricesQuotas";
import { Dispatch } from "redux";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
  yas_id: number;
  tip: string;
}

interface Options {
  type: "yas_id";
  value: number;
}

export const getChildInformation =
  (payload: GetRequestPayload, options?: Options) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));

      const response = await axios.post(GET_CHILD_INFORMATION, {
        otel_id: payload.otel_id,
        oda_id: payload.oda_id,
        yas_id: payload.yas_id,
        tip: payload.tip,
      });
      dispatch(handleSaveChildInformation(response.data || response));
      dispatch(handleSaveChildInformationState(response.data || response));
      dispatch(handleSingleGeneralInformation(response.data || response));
      dispatch(handleMultipleGeneralInformation(response.data || response));
      if (options && options.type === "yas_id") {
        dispatch(handleChangeInput({ yas_id: options.value }));
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const saveChildInformation =
  (payload: ChildInformationRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(POST_CHILD_INFORMATION, payload);

      dispatch(setToastSuccess("Kayıt işlemi başarılı"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
