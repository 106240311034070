import ChildInformationPage from "Components/Custom/PricesAndQuotaPages/ChildInformationPage";
import useChildInformation from "Components/Hooks/pricesAndQuota/useChildInformation";
import PageContainer from "pages/PageContainer";
import { useLocation } from "react-router-dom";
const ChildInformation = () => {
  const location = useLocation();
  const { cocuk_yas_id } = location.state || {};

  const { error, loading, isLoading, ...props } =
    useChildInformation(cocuk_yas_id);

  return (
    <PageContainer title="Çocuk Bilgileri">
      <ChildInformationPage
        data={true}
        isLoading={isLoading}
        loading={loading}
        error={error}
        {...props}
      />
    </PageContainer>
  );
};

export default ChildInformation;
