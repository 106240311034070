import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface DailyCallsForHotelsTableProps {
  isLoading: boolean;
}

const DailyCallsForHotelsTable = ({
  isLoading,
}: DailyCallsForHotelsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Otellere Gelen Günlük Çağrı"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default DailyCallsForHotelsTable;
