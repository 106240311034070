import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { RegionalOperationOfficialCardData } from "types/dashboard";

interface RegionalOperationOfficialCardProps {
  values: RegionalOperationOfficialCardData;
}

const RegionalOperationOfficialCard = ({
  values,
}: RegionalOperationOfficialCardProps) => {
  const textStyle: React.CSSProperties = {
    fontSize: "12px",
    color: "grey",
    margin: "4px 0",
  };
  const highlightedTextStyle: React.CSSProperties = {
    fontSize: "16px",
    color: "purple",
    margin: "0",
    fontWeight: "500",
  };
  return (
    <>
      <CustomDashboardCard xl={4} title="Bölge Operasyon Sorumlusu">
        <div className="d-flex justify-content-between">
          <div>
            <p className="m-0 fw-semibold fs-5">{values.ad_soyad}</p>
            <p style={highlightedTextStyle}>0850 811 55 44</p>
            <p style={highlightedTextStyle}>
              {values.profil_cep_telefon} (09.00 - 19.00 arası)
            </p>
            <p style={highlightedTextStyle}>{values.email_adresi}</p>
            <p style={textStyle}>Destek Talep Edebilirsiniz.</p>
          </div>
          <i
            className="ri-discuss-line"
            style={{ fontSize: "48px", color: "purple" }}
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default RegionalOperationOfficialCard;
