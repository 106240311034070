import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveCommentResponse,
  setIsLoading,
  setError,
  setUpdateCommentLoading,
  handleUpdateComment,
  setDeleteCommentLoading,
  handleDeleteComment,
  handleApproveComment,
  setApproveCommentLoading,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  APPROVE_COMMENT,
  DELETE_COMMENT,
  GET_COMMENTS,
  UPDATE_COMMENT,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { UpdateCommentPayload } from "types/comments";

interface GetCommentsParams {
  otel_id: number;
  page: number;
  per_page: number;
  start_date: string;
  end_date: string;
  personel: string;
}

type SuccessCallback = () => void;

export const getComments =
  (params: GetCommentsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_COMMENTS, params);
      const res = (response as any).message ? response.data : response;

      console.log("getComments API response:", res);

      dispatch(handleSaveCommentResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateComment =
  (payload: UpdateCommentPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateCommentLoading(true));

      console.log("Sending API request with payload:", payload);

      const response = await axios.post(UPDATE_COMMENT, payload);
      const res = (response as any).message ? response.data : response;

      dispatch(handleUpdateComment(res));

      console.log("Dispatched handleUpdateComment with payload:", res);

      dispatch(setToastSuccess("Yorum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateCommentLoading(false));
    }
  };

export const deleteComment =
  (yorum_id: number, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteCommentLoading(true));

      const response = await axios.post(DELETE_COMMENT, {yorum_id});

      dispatch(handleDeleteComment(yorum_id));

      dispatch(setToastSuccess("Yorum başarıyla devre dışı bırakıldı."));
      successCallback();
    } catch (error) {
      dispatch(setToastError(error));
    } finally {
      dispatch(setDeleteCommentLoading(false));
    }
  };

  export const approveComment = 
  (yorum_id: number, successCallback: SuccessCallback) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setApproveCommentLoading(true));
  
        await axios.post(APPROVE_COMMENT, {yorum_id});
        console.log("Yorum onaylandı")
  
        dispatch(handleApproveComment(yorum_id));
  
        dispatch(setToastSuccess("Yorum başarıyla onaylandı."));
        successCallback();
      } catch (error) {
        dispatch(setToastError(error));
      } finally {
        dispatch(setApproveCommentLoading(false));
      }
    };
  
