import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleAddChangedPrice } from "slices/pricesAndQuota/basicPriceEntry/reducer";
import { IncomingPrice } from "types/PricesQuotas";

interface PriceInputProps {
  name: string;
  defaultValue: IncomingPrice;
  currency: string;
}

const PriceInput = ({ name, defaultValue, currency }: PriceInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleChangePrice = (price: string) => {
    const incomingPrice: IncomingPrice = {
      oda_id: parseInt(name.split("-")[0]),
      pansiyon_id: parseInt(name.split("-")[1]),
      tarih: "",
      "Para Birimi": currency,
      fiyatlar: [{ "2+0": parseInt(price) }],
    };
    dispatch(handleAddChangedPrice(incomingPrice));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    handleChangePrice(newValue);
  };

  return (
    <div className="input-group input-group-sm flex-nowrap border p-2 h-100">
      <Input
        type="number"
        className="form-control user-select-none"
        name={name}
        value={defaultValue.fiyatlar?.[0]?.["2+0"] ?? "100"}
        onChange={handleChange}
        style={{ maxWidth: "100px", height: "32px" }}
      />
      <span
        className="input-group-text fs-10 fw-medium user-select-none"
        style={{ cursor: "pointer", width: "34px", height: "32px" }}
      >
        {currency}
      </span>
    </div>
  );
};

export default PriceInput;
