import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import useHandlePagination from "./useHandlePagination";

interface ListUsersTableProps {
  isLoading: boolean;
}
const ListUsersTable = ({
  isLoading,
}: ListUsersTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomReactTable
      pagination={true}
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
      onSearch={(searchValue) => {
        handleChange("search", searchValue);
      }}
      onPageSizeChange={(pageSize) => {
        handleChange("per_page", pageSize);
      }}
      paginationBottomProps={paginationBottomProps}
      legend={legend}
    />
  );
};

export default ListUsersTable;
