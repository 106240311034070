import { WelcomeAndCalculationPage } from "Components/Custom/ReportsPages";
import useWelcomeAndCalculation from "Components/Hooks/reports/useWelcomeAndCalculation";
import PageContainer from "pages/PageContainer";

const WelcomeAndCalculation = () => {
  const { isLoading } = useWelcomeAndCalculation();

  return (
    <PageContainer title="Karşılama ve Hesaplama">
      <WelcomeAndCalculationPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default WelcomeAndCalculation;
