import { CreateWebSiteFormData } from "types/myWebSite";
import { FilePondFile } from "filepond";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandlePayload = (
  requestPayload: FormData,
  values: CreateWebSiteFormData
) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  function isFilePondFile(value: any): value is FilePondFile {
    return (
      value && value.file && typeof value.file === "object" && value.file.name
    );
  }

  function isImageKey(key: string): boolean {
    const imageKeys = [
      "vitrin_resim",
      "vitrin_resim_2",
      "vitrin_resim_3",
      "reklam_resim",
      "one_cikan_ozellik_resim_1",
      "one_cikan_ozellik_resim_2",
      "one_cikan_ozellik_resim_3",
      "genel_ozellikler_resim",
      "konaklama_bilgileri_resim",
      "spa_bilgileri_resim",
      "yeme_icme_bilgileri_resim",
      "aktivite_bilgileri_resim",
      "havuz_bilgileri_resim",
      "toplanti_salonu_bilgileri_resim",
      "ekstra_ozellikler_resim",
      "banner1_resim_1",
      "banner1_resim_2",
      "banner1_resim_3",
      "banner2_resim1",
      "banner2_resim2",
      "banner2_resim3",
    ];
    return imageKeys.includes(key);
  }

  const getPayload = () => {
    requestPayload.append("otel_id", hotel.otel_id.toString());

    Object.entries(values).forEach(([key, value]) => {
      if (key.endsWith("_onizleme")) {
        return;
      }
      if (key === "one_cikan_ozellik") {
        value.forEach((val: string) =>
          requestPayload.append("one_cikan_ozellik[]", val)
        );
      } else if (isImageKey(key)) {
        if (isFilePondFile(value)) {
          requestPayload.append(key, value.file, value.file.name);
        }
      } else {
        requestPayload.append(key, value as string);
      }
    });

    return requestPayload;
  };

  const { otel_id } = hotel;

  return { getPayload, otel_id };
};

export default useHandlePayload;
