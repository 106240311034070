import AddNewAuthorityForm from "./AddNewAuthorityForm";
import UpdateUserAuthorityForm from "./UpdateUserAuthorityForm";
import { User, UserAuthority } from "types/userOperations";


interface UserAuthoritiesPageProps {
  users: User[];
  authorities: UserAuthority[];
  selectedUsers: number[];
  selectedAuthority: number;
  selectedPermissions: { [authorityId: number]: string };
  isLoading: boolean;
  addAuthoritiesToUsersLoading: boolean;
  addAuthoritiesLoading: boolean;
  handleSelectUser: (userIds: number[]) => void;
  handleSelectAuthority: (id: number) => void;
  handleSaveAuthorities: () => void;
}

const UserAuthoritiesPage = (props: UserAuthoritiesPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <AddNewAuthorityForm {...props}/>
        <UpdateUserAuthorityForm {...props}/>
      </div>
    </>
  );
};

export default UserAuthoritiesPage;
