import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "pages/Dashboard";

//AuthenticationInner pages
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "pages/Authentication/UserProfile";

//Prices & Quotas
import {
  AdvancedEntry,
  BasicAvailabilityEntry,
  BasicPriceEntry,
  BasicQuotaEntry,
  Calendar,
} from "pages/PricesAndQuota/Calendar";
import { GeneralPriceSettings } from "pages/PricesAndQuota";
import { RoomSalesAndPaymentMethods } from "pages/PricesAndQuota";

//Discounts & Actions
import {
  SpecialDiscounts,
  MobileDiscounts,
  BreakingDiscounts,
  HourlyDiscounts,
  UncancellableRoomRates,
  EarlyReservationDiscounts,
  StayLongPayLessActions,
  UnderTheDeskDiscounts,
  GiftsAndPromotions,
  AllGiftsList,
  DiscountCodes,
  NightRestrictions,
} from "pages/DiscountsAndActions";

//Definitions
import {
  AddLocation,
  ListLocations,
  AddHotelFeature,
  AddEarlyReservationDefinition,
  AddPlaceToVisit,
  ListPlacesToVisit,
  DefineCurrency,
} from "pages/Definitions";

//Themes
import {
  AddNewTheme,
  ListThemes,
  AssignHotelToTheme,
  AssignRecommendedHotels,
  OrderThemeHotels,
} from "pages/Themes";

//Facility
import {
  AddFacility,
  UpdateFacility,
  ViewFeaturedImages,
  FacilityImages,
  FacilityRooms,
  FacilityConcepts,
  FacilityUsers,
  FacilityManagers,
  FacilitySpecialDays,
  ListSpecialDays,
  RoomConceptMatch,
  FacilityCalls,
  FacilityPartialPaymentDates,
} from "pages/Facility";
import RoomPersonPriceRates from "pages/PricesAndQuota/RoomPersonPriceRates";

import {
  MoneyPoints,
  CustomerMails,
  PhoneStatus,
  DailyReservations,
  DailyCallsCharts,
  HourlyCallsChart,
  OurOwnHotels,
  WelcomeAndCalculation,
  RoomsLackPicture,
  ForecastReport,
  AnsweredCalls,
  OutboundCallsList,
  OutboundCallsListWithNumber,
  DailyCallsPerPerson,
  EmployeeSalesAmount,
  RoomsLackDescription,
  RiskyHotels,
  PhoneStatusList,
  DailyCallsForHotels,
  CallsFromGeneral,
  MonthlySales,
  HotelSales,
} from "pages/Reports";
import ChildInformation from "pages/PricesAndQuota/ChildInformation";

import {
  HotelInvoices,
  ContractRules,
  HotelContracts,
  ArchivedContracts,
  IssuedInvoices,
  MailOrderInfo,
  CommissionPayment,
  CommissionPaymentResult,
} from "pages/FinanceAndAccounting";

import ChildSpecialAgeInformation from "pages/PricesAndQuota/ChildSpecialAgeInformation";
import {
  AdsGroups,
  AdsReport,
  CompetitorAnalysis,
  CompetitorAnalysisInfo,
} from "pages/AdsAndAnalyze";
import Reservations from "pages/Reports/Reservations";

//PersonnelRequest
import { PersonnelRequest } from "pages/PersonnelRequest";
import { ListComments, AddComment } from "pages/Comments";
import ListHotelsWithoutComments from "pages/Comments/ListHotelsWithoutComment";

//My Web Site
import { CreateWebSite, ListWebSites } from "pages/MyWebSite";
import CreateWidget from "pages/MyWebSite/CreateWidget";
import { CategorizeFacility } from "pages/FacilityCategorization";
import Discounts from "pages/DiscountsAndActions/Discounts";
import AdminDashboard from "pages/Dashboard/AdminDashboard";
import CrmDashboard from "pages/Dashboard/CrmDashboard";

//User Operations

import {
  Notifications,
  ReservationChangeApproveCancel,
  UserAuthorities,
} from "pages/UserOperations";
import AddUser from "pages/UserOperations/AddUser";
import ListUsers from "pages/UserOperations/ListUsers";
import UpdateUser from "pages/UserOperations/UpdateUser";
import PersonnelHotelAssignments from "pages/UserOperations/PersonnelHotelAssignments";
import ListLogs from "pages/UserOperations/ListLogs";
import ListGroups from "pages/UserOperations/ListGroups";
import UpdateGroup from "pages/UserOperations/UpdateGroup";
import PeritoExtensionNumbers from "pages/UserOperations/PeritoExtensionNumbers";
import TransferReservation from "pages/UserOperations/TransferReservation";
import UserIPs from "pages/UserOperations/UserIPs";
import InformationTechnologies from "pages/UserOperations/InformationTechnologies";
import AddGroup from "pages/UserOperations/AddGroup";
import CreateNewNotification from "pages/UserOperations/CreateNewNotification";

import {
  ReservationCall,
  CalculatePriceForHotel,
  CalculatePriceForLocation,
  EnterReservation,
  ReviewReservation,
  CalculatePrice,
  EditReservation,
} from "pages/Reservation";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/admin-dashboard", component: <AdminDashboard /> },
  { path: "/crm-dashboard", component: <CrmDashboard /> },
  {path: "/sayfa-bulunamadi", component: <Cover404 />},

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  //Prices & Quotas
  { path: "/takvim", component: <Calendar /> },
  {
    path: "/basit-fiyat-girisi",
    component: <BasicPriceEntry />,
  },
  { path: "/basit-kontenjan-girisi", component: <BasicQuotaEntry /> },
  { path: "/basit-musaitlik-girisi", component: <BasicAvailabilityEntry /> },
  { path: "/gelismis-giris", component: <AdvancedEntry /> },
  { path: "/genel-fiyat-ayarlari", component: <GeneralPriceSettings /> },
  {
    path: "/oda-satis-odeme-yontemleri",
    component: <RoomSalesAndPaymentMethods />,
  },
  {
    path: "/oda-kisi-fiyat-oranlari",
    component: <RoomPersonPriceRates />,
  },
  {
    path: "/cocuk-bilgileri",
    component: <ChildInformation />,
  },
  {
    path: "/cocuk-ozel-yas-bilgileri",
    component: <ChildSpecialAgeInformation />,
  },
  //Discounts & Actions
  { path: "/ozel-indirimler", component: <SpecialDiscounts /> },
  {
    path: "/mobil-indirimler",
    component: <MobileDiscounts />,
  },
  { path: "/son-dakika-indirimleri", component: <BreakingDiscounts /> },
  {
    path: "/saatlik-indirimler",
    component: <HourlyDiscounts />,
  },
  {
    path: "/iptal-edilemez-oda-oranlari",
    component: <UncancellableRoomRates />,
  },
  {
    path: "/erken-rezervasyon-indirimleri",
    component: <EarlyReservationDiscounts />,
  },
  {
    path: "cok-kal-az-ode-aksiyonlari",
    component: <StayLongPayLessActions />,
  },
  {
    path: "/desk-alti-indirimler",
    component: <UnderTheDeskDiscounts />,
  },
  {
    path: "/hediyeler-ve-promosyonlar",
    component: <GiftsAndPromotions />,
  },
  {
    path: "/tum-otellere-ait-hediyeler",
    component: <AllGiftsList />,
  },
  {
    path: "/indirim-kuponlari",
    component: <DiscountCodes />,
  },
  {
    path: "/gece-kisitlamalari",
    component: <NightRestrictions />,
  },
  {
    path: "/indirimler",
    component: <Discounts />,
  },
  //Definitions
  {
    path: "/lokasyon-ekle",
    component: <AddLocation />,
  },
  {
    path: "/lokasyon-listele",
    component: <ListLocations />,
  },
  {
    path: "/tesis-ozelligi-ekle",
    component: <AddHotelFeature />,
  },
  {
    path: "/erken-rezervasyon-tarihi-tanimlama",
    component: <AddEarlyReservationDefinition />,
  },
  {
    path: "/gezilecek-yer-ekle",
    component: <AddPlaceToVisit />,
  },
  {
    path: "/gezilecek-yer-listele",
    component: <ListPlacesToVisit />,
  },
  {
    path: "/kur-tanimlama",
    component: <DefineCurrency />,
  },
  //Themes
  {
    path: "/tema-ekle",
    component: <AddNewTheme />,
  },
  {
    path: "/tema-listele",
    component: <ListThemes />,
  },
  {
    path: "/tema-otel-ata",
    component: <AssignHotelToTheme />,
  },
  {
    path: "/tema-tavsiye-edilen-otel-atama",
    component: <AssignRecommendedHotels />,
  },
  {
    path: "/tema-otel-siralama",
    component: <OrderThemeHotels />,
  },
  {
    path: "/tesis-ekle",
    component: <AddFacility />,
  },
  {
    path: "/tesis-guncelle",
    component: <UpdateFacility />,
  },
  {
    path: "/tesis-vitrin-gorselleri",
    component: <ViewFeaturedImages />,
  },
  {
    path: "/tesis-resimleri",
    component: <FacilityImages />,
  },
  {
    path: "/tesis-odalari",
    component: <FacilityRooms />,
  },
  {
    path: "/tesis-pansiyonlari",
    component: <FacilityConcepts />,
  },
  {
    path: "/tesis-kullanicilari",
    component: <FacilityUsers />,
  },
  {
    path: "/tesis-yetkilileri",
    component: <FacilityManagers />,
  },
  {
    path: "/tesis-ozel-gunleri",
    component: <FacilitySpecialDays />,
  },
  {
    path: "/ozel-gun-listele",
    component: <ListSpecialDays />,
  },
  {
    path: "/oda-pansiyon-eslestir",
    component: <RoomConceptMatch />,
  },
  {
    path: "/tesis-aramalari",
    component: <FacilityCalls />,
  },
  {
    path: "/tesis-kismi-odeme-tarihleri",
    component: <FacilityPartialPaymentDates />,
  },
  {
    path: "/musteri-mailleri",
    component: <CustomerMails />,
  },
  {
    path: "/dahili-durum",
    component: <PhoneStatus />,
  },

  //Reports
  {
    path: "/tesis-parapuan-listesi",
    component: <MoneyPoints />,
  },
  {
    path: "/gunluk-satis-raporu",
    component: <DailyReservations />,
  },
  {
    path: "/gunluk-cagri-grafigi",
    component: <DailyCallsCharts />,
  },
  {
    path: "/saatlik-cagri-grafigi",
    component: <HourlyCallsChart />,
  },
  // {
  //   path: "/fiyati-olan-bize-ait-oteller",
  //   component: <OurOwnHotels />,
  // },
  {
    path: "/karsilama-ve-hesaplama",
    component: <WelcomeAndCalculation />,
  },
  {
    path: "/oda-resmi-olmayanlar",
    component: <RoomsLackPicture />,
  },
  {
    path: "/forecast-raporu",
    component: <ForecastReport />,
  },
  {
    path: "/cevaplanan-cagri-hareketleri",
    component: <AnsweredCalls />,
  },
  {
    path: "/dis-arama-sayilari",
    component: <OutboundCallsList />,
  },
  {
    path: "/dis-arama-listeleme",
    component: <OutboundCallsListWithNumber />,
  },
  {
    path: "/kisilere-gelen-gunluk-cagri",
    component: <DailyCallsPerPerson />,
  },
  {
    path: "/personel-satislari-listele",
    component: <EmployeeSalesAmount />,
  },
  {
    path: "/oda-aciklamasi-olmayanlar",
    component: <RoomsLackDescription />,
  },
  {
    path: "/riskli-oteller",
    component: <RiskyHotels />,
  },
  {
    path: "/dahili-durum-listesi",
    component: <PhoneStatusList />,
  },
  {
    path: "/otellere-gelen-gunluk-cagri",
    component: <DailyCallsForHotels />,
  },
  {
    path: "/genelden-gelen-cagrilar",
    component: <CallsFromGeneral />,
  },
  {
    path: "personel-aylik-satis-listesi",
    component: <MonthlySales />,
  },
  {
    path: "/otel-satislari",
    component: <HotelSales />,
  },
  {
    path: "/arsivlenmis-kontratlar",
    component: <ArchivedContracts />,
  },
  {
    path: "/rezervasyonlar",
    component: <Reservations />,
  },

  //Ads & Analyze
  {
    path: "/tesisler-reklam-gruplari",
    component: <AdsGroups />,
  },
  {
    path: "/reklam-raporu",
    component: <AdsReport />,
  },
  {
    path: "/rakip-analizi",
    component: <CompetitorAnalysis />,
  },
  {
    path: "/rakip-analizi-bilgileri",
    component: <CompetitorAnalysisInfo />,
  },

  // Finance & Accounting
  {
    path: "/otelinize-kesilen-faturalar",
    component: <HotelInvoices />,
  },
  {
    path: "/kontrat-kurallari",
    component: <ContractRules />,
  },
  {
    path: "/otel-kontrat-islemleri",
    component: <HotelContracts />,
  },
  {
    path: "/kesilen-faturalar",
    component: <IssuedInvoices />,
  },
  {
    path: "/mail-order-bilgileri",
    component: <MailOrderInfo />,
  },
  {
    path: "/komisyon-odemesi",
    component: <CommissionPayment />,
  },
  {
    path: "/komisyon-odemesi-sonuc",
    component: <CommissionPaymentResult />,
  },

  //PersonnelRequest
  { path: "/personel-talepleri", component: <PersonnelRequest /> },

  // Comments
  {
    path: "/yorumlari-listele",
    component: <ListComments />,
  },
  {
    path: "/yorum-ekle",
    component: <AddComment />,
  },
  {
    path: "/yorumsuz-oteller",
    component: <ListHotelsWithoutComments />,
  },

  // My Web Site
  {
    path: "/site-olustur",
    component: <CreateWebSite />,
  },
  {
    path: "/web-site-listesi",
    component: <ListWebSites />,
  },
  {
    path: "/widget-olustur",
    component: <CreateWidget />,
  },

  // Facility Categorization
  {
    path: "/tesis-siniflandir",
    component: <CategorizeFacility />,
  },

  // User Operations
  {
    path: "/bildirimler",
    component: <Notifications />,
  },
  {
    path: "/bildirim-olustur",
    component: <CreateNewNotification />,
  },
  { path: "/profile", component: <UserProfile /> },
  {
    path: "/kullanici-ekle",
    component: <AddUser />,
  },
  {
    path: "/kullanicilari-listele",
    component: <ListUsers />,
  },
  {
    path: "/kullanici-duzenle",
    component: <UpdateUser />,
  },
  {
    path: "/personel-otel-gorevleri",
    component: <PersonnelHotelAssignments />,
  },
  {
    path: "/yetkiler",
    component: <UserAuthorities />,
  },
  {
    path: "/loglari-listele",
    component: <ListLogs />,
  },
  {
    path: "/grup-ekle",
    component: <AddGroup />,
  },
  {
    path: "/gruplari-listele",
    component: <ListGroups />,
  },
  {
    path: "/grup-duzenle",
    component: <UpdateGroup />,
  },
  {
    path: "/perito-dahili-durum",
    component: <PeritoExtensionNumbers />,
  },
  {
    path: "/rezervasyon-onay-iptal-degisikligi",
    component: <ReservationChangeApproveCancel />,
  },
  {
    path: "/rezervasyon-aktar",
    component: <TransferReservation />,
  },
  {
    path: "/dis-ipler",
    component: <UserIPs />,
  },
  {
    path: "/bilgi-islem",
    component: <InformationTechnologies />,
  },

  //Reservation
  {
    path: "/rezervasyon-cagri",
    component: <ReservationCall />,
  },
  {
    path: "/otel-fiyat-hesaplama",
    component: <CalculatePriceForHotel />,
  },
  {
    path: "/lokasyon-fiyat-hesaplama",
    component: <CalculatePriceForLocation />,
  },
  {
    path: "/rezervasyon-girisi",
    component: <EnterReservation />,
  },
  {
    path: "/rezervasyon-incele",
    component: <ReviewReservation />,
  },
  {
    path: "/fiyat-hesapla",
    component: <CalculatePrice />,
  },
  {
    path: "/rezervasyon-duzenle",
    component: <EditReservation />,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Cover404 /> },
];

const publicRoutes: any = [
  // Authentication Page
  { path: "/cikis", component: <Logout /> },
  { path: "/giris", component: <Login /> },
  { path: "/sifremi-unuttum", component: <ForgetPassword /> },

  //AuthenticationInner pages

  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
