import { CustomPieChart } from "Components/Custom/UI/CustomCharts";
import useHandleChart from "./useHandleChart";
import { useState } from "react";
import { Collapse, Row, Col } from "reactstrap";

const DailyReservationsChart = () => {
  const { labels, series, colors, title } = useHandleChart();

  const [collapse, setCollapse] = useState<boolean>(true);

  return (
    <Row>
      <Col
        className={`d-flex flex-column border rounded theme-bg-light py-1 ${
          collapse ? "gap-4" : ""
        }`}
      >
        <div
          className="w-100 d-flex gap-3 justify-content-start align-items-center cursor-pointer mb-4"
          onClick={() => setCollapse(!collapse)}
        >
          <p className="fs-24 fw-bold m-0">Günlük Satış Raporu</p>
          <i
            className={`ri-arrow-${
              collapse ? "down" : "right"
            }-s-line fs-24 fw-bold`}
          ></i>
        </div>
        <div className="d-flex justify-content-center">
          <div
            style={{
              width: "500px",
            }}
          >
            <Collapse isOpen={collapse}>
              <CustomPieChart
                chartId="daily-reservations-chart"
                title={title}
                series={series}
                labels={labels}
                chartHeight={300}
                chartType="pie"
                legendPosition="right"
                colors={colors}
              />
            </Collapse>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DailyReservationsChart;
