import useHandleForm from "./useHandleForm";

import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";

import { CustomLoadingPage } from "Components/Custom/UI";
import PriceSettings from "./PriceSettings";
import FacilityPaymentDate from "./FacilityPaymentDate";
import FacilityType from "./FacilityType";
import FacilityPaymentTypes from "./FacilityPaymentTypes";
import SaveSettingsButton from "./SaveSettingsButton";

interface GeneralPriceSettingsPageProps {
  isLoading: boolean;
}

const GeneralPriceSettingsPage = ({
  isLoading,
}: GeneralPriceSettingsPageProps) => {
  const {
    generalPriceSettingsFormState,
    formErrors,
    workingTypesOptions,
    priceEntryMethodsOptions,
    currencyOptions,
    cancelInsuranceOptions,
    kismiPaymentDaysOptions,
    monthsOptions,
    facilityPaymentOptions,
    facilityTypesOptions,
    requestPayload,
    saveSettingsLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <PriceSettings
          values={generalPriceSettingsFormState}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          workingTypesOptions={workingTypesOptions}
          priceEntryMethodsOptions={priceEntryMethodsOptions}
          currencyOptions={currencyOptions}
          cancelInsuranceOptions={cancelInsuranceOptions}
        />
        <FacilityPaymentTypes
          values={generalPriceSettingsFormState}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          kismiPaymentDaysOptions={kismiPaymentDaysOptions}
          monthsOptions={monthsOptions}
        />
        <FacilityPaymentDate
          values={generalPriceSettingsFormState}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          facilityPaymentOptions={facilityPaymentOptions}
        />
        <FacilityType
          values={generalPriceSettingsFormState}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          facilityTypesOptions={facilityTypesOptions}
        />
        <SaveSettingsButton
          values={generalPriceSettingsFormState}
          requestPayload={requestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={saveSettingsLoading}
        />
      </div>
    </>
  );
};

export default withPageNeedsHotel(GeneralPriceSettingsPage);
