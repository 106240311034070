import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RoomsLackPictureInitialState } from "slices/reports/roomsLackPicture/reducer";
import {
  getAllRoomsLackPicture,
  getFilteredRoomsLackPicture,
} from "slices/reports/roomsLackPicture/thunk";

type Picture =
  | "resim1"
  | "resim2"
  | "resim3"
  | "resim4"
  | "resim5"
  | "resim6"
  | "resim7"
  | "resim8"
  | "resim9"
  | "resim10";

type SelectedRoomPictures = {
  [P in Picture]: string;
};

const useHandleForm = () => {
  const { filters } = useSelector(
    (state: { RoomsLackPicture: RoomsLackPictureInitialState }) =>
      state.RoomsLackPicture
  );

  const [filterForm, setFilterForm] = useState<SelectedRoomPictures>(
    {} as SelectedRoomPictures
  );

  const handleFormChange = (key: Picture, isChecked: boolean) => {
    isChecked
      ? setFilterForm((prevState) => ({
          ...prevState,
          [key]: 1,
        }))
      : setFilterForm((prevState) => {
          const state = { ...prevState };
          delete state[key];
          return state;
        });
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const payload = {
      ...filterForm,
    };
    dispatch(getFilteredRoomsLackPicture(payload));
  };

  const handleResetFilters = () => {
    setFilterForm({} as SelectedRoomPictures);

    const form = document.getElementById(
      "rooms-lack-picture-filter-form"
    ) as HTMLFormElement;

    form.reset();

    dispatch(getAllRoomsLackPicture());
  };

  return {
    filters,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilters: handleResetFilters,
  };
};

export default useHandleForm;
