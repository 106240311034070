import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddAddNewThemeFormErrors } from "slices/themes/addNewTheme/reducer";
import { setToastError } from "slices/toast/reducer";

import { AddNewThemeFormErrors, AddNewThemeState } from "types/themes";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddNewThemeState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddNewThemeFormErrors = {};

    if (!values.tema_bolge) {
      errors.tema_bolge = "Tema bölgesi seçmelisiniz.";
    }

    if (!values.durum) {
      errors.durum = "Tema türü seçmelisiniz.";
    }

    if (!values.baslik) {
      errors.baslik = "Başlık boş bırakılamaz.";
    }

    if (!values.alt_baslik) {
      errors.alt_baslik = "Alt başlık boş bırakılamaz.";
    }

    if (!values.aciklama) {
      errors.aciklama = "Açıklama boş bırakılamaz.";
    }

    if (!values.link) {
      errors.link = "Link boş bırakılamaz.";
    }

    if (!values.file) {
      errors.file = "Dosya boş bırakılamaz.";
    }

    dispatch(handleAddAddNewThemeFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddNewThemeState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
