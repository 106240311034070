import DateSelectors from "./DateSelectors";
import useHandleForm from "./useHandleForm";
import DiscountRate from "./DiscountRate";
import ApplicableDays from "./ApplicableDays";
import Offers from "./Offers";
import SwitchesSection from "./SwitchesSection";
import HotelSelectionAndOtherSwitches from "./HotelSelectionAndOtherSwitches";
import SaveNewDiscountButton from "./SaveNewDiscountButton";
import { CustomFormContainer } from "Components/Custom/UI";
import UpdateDiscountButton from "./UpdateDiscountButton";

interface DiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const DiscountsForm = (props: DiscountsFormProps) => {
  const {
    specialDiscount,
    conceptsOptions,
    paymentMethodOptions,
    addNewSpecialDiscountRequestPayload,
    updateSpecialDiscountRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addDiscountLoading,
    updateDiscountLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <DateSelectors
        values={specialDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <DiscountRate
        values={specialDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ApplicableDays
        values={specialDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <Offers
        values={specialDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <SwitchesSection values={specialDiscount} />
      <HotelSelectionAndOtherSwitches
        values={specialDiscount}
        conceptsOptions={conceptsOptions}
        paymentMethodOptions={paymentMethodOptions}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {specialDiscount.id ? (
        <UpdateDiscountButton
          values={specialDiscount}
          requestPayload={updateSpecialDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateDiscountLoading}
        />
      ) : (
        <SaveNewDiscountButton
          values={specialDiscount}
          requestPayload={addNewSpecialDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addDiscountLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default DiscountsForm;
