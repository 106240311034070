import {
  BankInstallment,
  CalculatePriceForHotelConceptPrice,
} from "types/reservation";
import ConceptPrice from "../ConceptPrice";
import ConceptInfo from "../../ConceptInfo";
import PriceCalculationStatusMessages from "../../PriceCalculationStatusMessages";
import useHandleKapidaPayButton from "../../PayButtonsHooks/useHandleKapidaPayButton";
import KapidaPayButton from "../PayButtons/KapidaPayButton";

interface KapidaPaymentProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  ayni_fiyatli_odeme_tipleri: string[];
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  kapida_odeme: {
    kapida_odeme_durum: "evet" | "hayir";
    kapida_komisyon_orani: string;
    kapida_indirim_orani: string;
    kapida_odeme_para_birimi_goster: "0" | "1";
  };
  gifts: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  banka_taksit: BankInstallment[];
}

const KapidaPayment = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  priceCalculationStatusMessages,
  kontenjan,
  kapida_odeme,
  gifts,
  onClick,
  banka_taksit,
}: KapidaPaymentProps) => {
  const {
    hasPrice,
    stop,
    stopMessage,
    showKapidaOdemeButton,
    kapidaOdemeFiyatDurum,
    kapidaOdemeButtonText,
    kapidaOdemePrice,
    kapidaOdemeOverlinedPrice,
    messages,
    quota,
    checked,
    setChecked,
    isOpen,
    toggle,
  } = useHandleKapidaPayButton({
    fiyatlar,
    kontenjan,
    kapida_odeme,
    priceCalculationStatusMessages,
  });

  return (
    <>
      <div
        className="rounded p-2 border bg-white"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          minHeight: "120px",
        }}
      >
        <ConceptInfo
          priceType={["kapida"]}
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          gifts={gifts}
        />
        <ConceptPrice
          showPrice={showKapidaOdemeButton}
          price={kapidaOdemePrice}
          overlinedPrice={kapidaOdemeOverlinedPrice}
          banka_taksit={banka_taksit}
        />
        {hasPrice ? (
          <KapidaPayButton
            stop={stop}
            stopMessage={stopMessage}
            showKapidaOdemeButton={showKapidaOdemeButton}
            kapidaOdemeFiyatDurum={kapidaOdemeFiyatDurum}
            kapidaOdemeButtonText={kapidaOdemeButtonText}
            kapidaOdemePrice={kapidaOdemePrice}
            kapidaOdemeOverlinedPrice={kapidaOdemeOverlinedPrice}
            quota={quota}
            onClick={onClick}
            toggle={toggle}
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            isOpen={isOpen}
            checked={checked}
            setChecked={setChecked}
          />
        ) : (
          <>
            <div></div>
            <PriceCalculationStatusMessages messages={messages} />
          </>
        )}
      </div>
    </>
  );
};

export default KapidaPayment;
