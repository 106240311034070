import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import ChildSpecialAgeInformationPageForm from "./ChildSpecialAgeInformationPageForm";
import ChildSpecialAgeInformationTable from "./ChildSpecialAgeInformationTable";
import { CHILD_SPECİAL_AGE_DESCRIPTIONS } from "utils/pageDescriptions";
import { CustomPageDescriptions } from "Components/Custom/UI";

interface ChildSpecialAgeInformationPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}
const ChildSpecialAgeInformationPage = (
  props: ChildSpecialAgeInformationPageProps
) => {
  return (
    <div className="d-flex flex-column gap-4">
      <ChildSpecialAgeInformationPageForm {...props} />
      <CustomPageDescriptions descriptions={CHILD_SPECİAL_AGE_DESCRIPTIONS} />
      <ChildSpecialAgeInformationTable isLoading={props.isLoading} />
    </div>
  );
};

export default withPageNeedsHotel(ChildSpecialAgeInformationPage);
