import DatePageAndUserFilterForm from "./DatePageAndUserFilterForm";
import ListLogsTable from "./ListLogsTable";

interface ListLogsPageProps {
  isLoading: boolean;
}

const ListLogsPage = ({ isLoading }: ListLogsPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <DatePageAndUserFilterForm/>
        <ListLogsTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListLogsPage;
