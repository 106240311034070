import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  AgeGeneralInformations,
  ChildSpecialAgeInformationState,
  ChildSpecialAgeInformationFormErrors,
} from "types/PricesQuotas";

export interface ChildSpecialAgeInformationsInitialState {
  childSpecialAgeInformation: {
    yas_genel_bilgileri: AgeGeneralInformations[];
    pansiyon_bilgileri: {};
    cocuk_ozel_yas_bilgileri: {
      oda_grilmismi: boolean;
      cocuk_bilgisi_girilmismi: boolean;
      fiyatlar_girilmismi: boolean;
    };
  };
  childSpecialAgeInformationState: ChildSpecialAgeInformationState;
  childSpecialAgeInformationFormErrors: ChildSpecialAgeInformationFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  saveInformationLoading: boolean;
  saveInformationError: string;
}

const initialState: ChildSpecialAgeInformationsInitialState = {
  childSpecialAgeInformation: {
    yas_genel_bilgileri: [],
    pansiyon_bilgileri: {},
    cocuk_ozel_yas_bilgileri: {
      oda_grilmismi: false,
      cocuk_bilgisi_girilmismi: false,
      fiyatlar_girilmismi: false,
    },
  },
  childSpecialAgeInformationState: {
    id: 0,
    otel_id: 0,
    ozel_yas_ismi: "",
    gun: 0,
    cocuk_ozel_yas_giris_gunu: "0",
    satis_tarihi_secim: "0",
    satis_tarihi_baslangic: moment().format("YYYY-MM-DD"),
    satis_tarihi_bitis: moment().add(7, "days").format("YYYY-MM-DD"),
  },
  childSpecialAgeInformationFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  saveInformationLoading: false,
  saveInformationError: "",
};

const childSpecialAgeInformationSlice = createSlice({
  name: "childSpecialAgeInformations",
  initialState,
  reducers: {
    handleSaveChildSpecialAgeInformation(state, action) {
      state.childSpecialAgeInformation = action.payload;
    },
    handleSaveChildSpecialAgeInformationState(state, action) {
      state.childSpecialAgeInformationState = action.payload;
    },
    handleChangeChildSpecialAgeInformationInput(state, action) {
      state.childSpecialAgeInformationState = {
        ...state.childSpecialAgeInformationState,
        ...action.payload,
      };
    },

    handleSaveChildSpecialAgeInformationForEdit(state, action) {
      state.childSpecialAgeInformationState = action.payload;
    },
    handleAddChildSpecialAgeInformationFormErrors(state, action) {
      state.childSpecialAgeInformationFormErrors = action.payload;
    },
    resetChildSpecialAgeInformation(state) {
      state.childSpecialAgeInformationState = {
        id: 0,
        otel_id: 0,
        ozel_yas_ismi: "",
        gun: 0,
        cocuk_ozel_yas_giris_gunu: "0",
        satis_tarihi_secim: "0",
        satis_tarihi_baslangic: moment().format("YYYY-MM-DD"),
        satis_tarihi_bitis: moment().add(7, "days").format("YYYY-MM-DD"),
      };
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setSaveInformationLoading(state, action) {
      state.saveInformationLoading = action.payload;
    },
    setSaveInformationError(state, action) {
      state.saveInformationError = action.payload;
    },
  },
});

export const {
  handleSaveChildSpecialAgeInformation,
  handleSaveChildSpecialAgeInformationState,
  handleSaveChildSpecialAgeInformationForEdit,
  handleChangeChildSpecialAgeInformationInput,
  handleAddChildSpecialAgeInformationFormErrors,
  handleSelectRoom,
  resetChildSpecialAgeInformation,
  setIsLoading,
  setError,
  setSaveInformationLoading,
  setSaveInformationError,
} = childSpecialAgeInformationSlice.actions;

export default childSpecialAgeInformationSlice.reducer;
