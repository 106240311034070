import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import { AddCertificatePayload } from "types/myWebSite";
import { addCertificate, getCertificates } from "slices/myWebSite/addCertificate/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import { handleResetAddCertificateFormFields } from "slices/myWebSite/addCertificate/reducer";
import { FilePondFile } from "filepond";

interface AddCertificateButtonProps {
  values: AddCertificatePayload;
  requestPayload: FormData;
  validateForm: (values: AddCertificatePayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  addCertificateLoading: boolean;
}

const AddCertificateButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  addCertificateLoading,
}: AddCertificateButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);
    console.log("values : ", values)

    if (isValid) {
      const { sertifika_adi, sertifika_link } = values;

      requestPayload.append("otel_id", otel_id.toString());
      requestPayload.append("sertifika_adi", sertifika_adi);

      const file = values.sertifika_link as FilePondFile;
      requestPayload.append("sertifika_link", file.file, file.file.name);


      sertifika_link &&
        dispatch(
          addCertificate(requestPayload, () => {
            dispatch(getCertificates(otel_id));
            dispatch(handleResetAddCertificateFormFields());
          }
          )
        );
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={addCertificateLoading}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};

export default AddCertificateButton;
