import { CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface ListLogsTableProps {
  isLoading: boolean;
}

const ListLogsTable = ({ isLoading }: ListLogsTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return(
  <>
    <CustomReactTable
      pagination={true}
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
      onSearch={(searchValue) => {
        handleChange("search", searchValue);
      }}
      onPageSizeChange={(pageSize) => {
        handleChange("per_page", pageSize);
      }}
      paginationBottomProps={paginationBottomProps}
      legend={legend}
    />
  </>
  );
};
export default ListLogsTable;
