import { createSlice } from "@reduxjs/toolkit";

import { AnsweredCall } from "types/reports";

export interface AnsweredCallsInitialState {
  answeredCalls: AnsweredCall[];
  isLoading: boolean;
  error: string;
}

const initialState: AnsweredCallsInitialState = {
  answeredCalls: [],
  isLoading: false,
  error: "",
};

const answeredCallsSlice = createSlice({
  name: "answeredCalls",
  initialState,
  reducers: {
    handleSaveAnsweredCalls(state, action) {
      state.answeredCalls = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveAnsweredCalls, setIsLoading, setError } =
  answeredCallsSlice.actions;

export default answeredCallsSlice.reducer;
