import { CustomButton, CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { ReservationChangeApproveCancelInitialState } from "slices/userOperations/reservationChangeApproveCancel/reducer";
import { Dispatch } from "redux";
import { resetReservationAmount } from "slices/userOperations/reservationChangeApproveCancel/thunk";

const useHandleTable = () => {
const dispatch: Dispatch<any> = useDispatch();
  const { reservationAmounts } = useSelector(
    (state: {
      ReservationChangeApproveCancel: ReservationChangeApproveCancelInitialState;
    }) => state.ReservationChangeApproveCancel
  );

  const onHandleReset = (id: number) => {
    dispatch(resetReservationAmount({id}));
  };

  const tableData = reservationAmounts?.map((reservation) => {
    return {
      id: reservation.id,
      rezervasyon_kodu: reservation.rezervasyon_kodu,
      kk_banka_adi: reservation.kk_banka_adi,
      kk_tutar: reservation.kk_tutar,
      kk_taksit: reservation.kk_taksit,
    };
  });

  const tableColumns = [
    {
      header: "ID",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Rez. No",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Banka Adı",
      accessorKey: "kk_banka_adi",
      enableColumnFilter: false,
    },
    {
      header: "Tutar",
      accessorKey: "kk_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Taksit",
      accessorKey: "kk_taksit",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
            <CustomButton
                variant="primary"
                onClick={() => {
                  onHandleReset(id);
                }}
              >
                Sıfırla
              </CustomButton>
            </div>
          </>
        );
      },
    },
  ];



  return { tableData, tableColumns };
};

export default useHandleTable;
