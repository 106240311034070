import { CustomFormContainer } from "Components/Custom/UI";
import UpdateUserButton from "./UpdateUserButton/UpdateUserButton";
import useHandleForm from "./useHandleForm";
import UserNameInput from "./FormFields/UserNameInput";
import UsersUserNameInput from "./FormFields/UsersUserNameInput";
import UserEmailInput from "./FormFields/UserEmailInput";
import UserInetCode from "./FormFields/UserInetCode";
import UserExtensionNumber from "./FormFields/UserExtensionNumber";
import UserGenderCheckBox from "./FormFields/UserGenderCheckBox";
import UserJobTypeCheckBox from "./FormFields/UserJobTypeCheckBox";
import UserAccountType from "./FormFields/UserAccountType";
import UserAuthorityList from "./FormFields/UserAuthorityList";

const UpdateUserForm = () => {
  const {
    userTypeOptions,
    jobTypeOptions,
    genderOptions,
    userAuthorityOptions,
    selectedOptions,
    handleSelectOptions,
    values,
    formErrors,
    updateUserLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    updateUserRequestPayload,
  } = useHandleForm();

  return (
    <div className="d-flex flex-column gap-4">
      <CustomFormContainer title="Kullanıcı Düzenle">
        <UserNameInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UsersUserNameInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserEmailInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserInetCode
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserExtensionNumber
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserGenderCheckBox
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          formChoices={genderOptions}
        />
        <UserJobTypeCheckBox
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          formChoices={jobTypeOptions}
        />
        <UserAccountType
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          formChoices={userTypeOptions}
        />
        <UserAuthorityList
          formChoices={userAuthorityOptions}
          selectedOptions={selectedOptions}
          formErrors={formErrors}
          handleSelectOptions={handleSelectOptions}
        />
        <div className="d-flex justify-content-end ">
          <UpdateUserButton
            values={values}
            requestPayload={updateUserRequestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            isLoading={updateUserLoading}
          />
        </div>
      </CustomFormContainer>
    </div>
  );
};

export default UpdateUserForm;
