import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import useHandleCards from "./useHandleCards";
import AnalysisCard from "./AnalysisCard";
import { CompetitorAnalysisFilterFormValues } from "types/adsAndAnalyze";
import useHandlePagination from "./useHandlePagination";

interface CompetitorAnalysisProps {
  isLoading: boolean;
  formValues: CompetitorAnalysisFilterFormValues;
  isEb: number;
}

const CompetitorAnalysis = ({
  formValues,
  isLoading,
  isEb,
}: CompetitorAnalysisProps) => {
  const {
    cards,
    onGetFacilityAvailabilities,
    facilityAvailabilities,
    availabilityLoading,
    updateDescriptionLoading,
    updateHotelInfoLoading,
    updateAdsStatusLoading,
    onUpdateDescription,
    onUpdateAdsDescription,
    onUpdateAdsStatus,
    onUpdateHotelInfo,
  } = useHandleCards();

  const { endOfPages, onLoadMore } = useHandlePagination(formValues);

  return (
    <CustomFormContainer title={`${isEb ? "EB" : ""} Rakip Analizi`}>
      <div className="d-flex flex-column gap-4">
        {cards.map((card, index) => (
          <AnalysisCard
            key={index}
            facilityAvailabilities={facilityAvailabilities}
            availabilityLoading={availabilityLoading}
            onGetFacilityAvailabilities={onGetFacilityAvailabilities}
            updateDescriptionLoading={updateDescriptionLoading}
            updateHotelInfoLoading={updateHotelInfoLoading}
            updateAdsStatusLoading={updateAdsStatusLoading}
            onUpdateDescription={onUpdateDescription}
            onUpdateAdsDescription={onUpdateAdsDescription}
            onUpdateAdsStatus={onUpdateAdsStatus}
            onUpdateHotelInfo={onUpdateHotelInfo}
            {...card}
          />
        ))}
        {!endOfPages && (
          <CustomButton
            variant="primary"
            onClick={onLoadMore}
            isLoading={isLoading}
            disabled={endOfPages}
          >
            Daha Fazla Göster
          </CustomButton>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default CompetitorAnalysis;
