import { useSelector } from "react-redux";

import { MonthlySalesInitialState } from "slices/reports/monthlySales/reducer";

const useHandleTable = () => {
  const { monthlySales } = useSelector(
    (state: { MonthlySales: MonthlySalesInitialState }) => state.MonthlySales
  );

  const tableData = monthlySales?.veriler?.map((monthlySale) => ({
    "Adı Soyadı": monthlySale.ad_soyad,
    "Kapıda Satış Tutarı": monthlySale.kapida_toplam_satis,
    "Satış Tutarı": monthlySale.toplam_satis,
    "Oda Sayısı": monthlySale.toplam_oda_sayisi,
    "Grup Adı": monthlySale.grup_adi,
  }));

  const tableColumns = [
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Satış Tutarı",
      accessorKey: "Kapıda Satış Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tutarı",
      accessorKey: "Satış Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Oda Sayısı",
      accessorKey: "Oda Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Grup Adı",
      accessorKey: "Grup Adı",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
