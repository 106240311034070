import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveHotels,
  handleSaveThemes,
  setAddRecommendedHotelsLoading,
  setAddRecommendedHotelsError,
  setGetRecommendedHotelsLoading,
  setGetRecommendedHotelsError,
} from "./reducer";

import {
  GET_RECOMMENDED_HOTELS,
  ADD_RECOMMENDED_HOTEL,
  GET_THEMES,
} from "helpers/url_helper";

import { AssignRecommendedHotelsRequestPayload } from "types/themes";
import { setToastSuccess, setToastError } from "slices/toast/reducer";

import { Dispatch } from "redux";

interface GetHotelsForThemePayload {
  tema_id: number;
}

type SuccessCallback = () => void;

export const getThemes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEMES);

    dispatch(handleSaveThemes(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getRecommendedHotels =
  (payload: GetHotelsForThemePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setGetRecommendedHotelsLoading(true));
      const response = await axios.get(GET_RECOMMENDED_HOTELS, {
        params: {
          tema_id: payload.tema_id,
        },
      });

      dispatch(handleSaveHotels(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setGetRecommendedHotelsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setGetRecommendedHotelsLoading(false));
    }
  };

export const addRecommendedHotel =
  (
    payload: AssignRecommendedHotelsRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddRecommendedHotelsLoading(true));
      await axios.post(ADD_RECOMMENDED_HOTEL, payload);

      successCallback();
      dispatch(setToastSuccess("Oteller eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddRecommendedHotelsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddRecommendedHotelsLoading(false));
    }
  };
