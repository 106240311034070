import { createSlice } from "@reduxjs/toolkit";

import { Theme, ThemeHotel } from "types/themes";

export interface AssignHotelToThemeInitialState {
  themes: Theme[];
  hotels: ThemeHotel[];
  selectedTheme: number;
  isLoading: boolean;
  error: string;
  addHotelToThemeLoading: boolean;
  addHotelToThemeError: string;
}

export const assignHotelToThemeInitialState: AssignHotelToThemeInitialState = {
  themes: [],
  hotels: [],
  selectedTheme: 0,
  isLoading: false,
  error: "",
  addHotelToThemeLoading: false,
  addHotelToThemeError: "",
};

export const assignHotelToThemeSlice = createSlice({
  name: "assignHotelToTheme",
  initialState: assignHotelToThemeInitialState,
  reducers: {
    handleSaveThemes(state, action) {
      state.themes = action.payload;
    },
    handleSaveHotels(state, action) {
      state.hotels = action.payload;
    },
    handleSaveSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddHotelToThemeLoading(state, action) {
      state.addHotelToThemeLoading = action.payload;
    },
    setAddHotelToThemeError(state, action) {
      state.addHotelToThemeError = action.payload;
    },
  },
});

export const {
  handleSaveThemes,
  handleSaveHotels,
  handleSaveSelectedTheme,
  setIsLoading,
  setError,
  setAddHotelToThemeLoading,
  setAddHotelToThemeError,
} = assignHotelToThemeSlice.actions;

export default assignHotelToThemeSlice.reducer;
