import { Row } from "reactstrap";
import useHandleCards from "./useHandleCards";
import { CustomLoadingPage } from "Components/Custom/UI";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import CollectionSituationCard from "./Cards/CollectionSituationCard";
import CommentsCard from "./Cards/CommentsCard";
import CommercialTableCard from "./Cards/CommercialTableCard";
import CommercialWarningCard from "./Cards/CommercialWarningCard";
import DiscountsCard from "./Cards/DiscountsCard";
import DistrictRankingGraphCard from "./Cards/DistrictRankingGraphCard";
import DistrictRankingInfoCard from "./Cards/DistrictRankingInfoCard";
import FacilityReservationOfficialCard from "./Cards/FacilityReservationOfficialCard";
import HotelInfoCard from "./Cards/HotelInfoCard";
import LastThirtyDayDailySalesCard from "./Cards/LastThirtyDayDailySalesCard";
import LastThirtyDayRoomNumbersCard from "./Cards/LastThirtyDayRoomNumbersCard";
import PageInfoCard from "./Cards/PageInfoCard";
import PriceProviderCard from "./Cards/PriceProviderCard";
import PriceWarningCard from "./Cards/PriceWarningCard";
import ProvinceRankingGraphCard from "./Cards/ProvinceRankingGraphCard";
import ProvinceRankingInfoCard from "./Cards/ProvinceRankingInfoCard";
import RegionalOperationOfficialCard from "./Cards/RegionalOperationOfficialCard";
import TotalSalesAmountsCard from "./Cards/TotalSalesAmountsCard";
import UsuableParaPuanCard from "./Cards/UsuableParaPuanCard";
import CompetitorAnalysisCard from "./Cards/CompetitorAnalysisCard";
import CustomAuthorizedFile from "Components/Custom/UI/CustomAuthorizedFile/CustomAuthorizedFile";

interface AdminDashboardPageProps {
  isLoading: boolean;
  otel_id: number;
  hotel_name: string;
}

const AdminDashboardPage = ({
  isLoading,
  otel_id,
  hotel_name,
}: AdminDashboardPageProps) => {
  const { dashboardData } = useHandleCards();
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div>
        <Row>
          <HotelInfoCard values={dashboardData.hotelInfoCard} />
        </Row>
        <Row>
          <CustomAuthorizedFile
            userId={[]}
            groupId={[]}
            yetki={["root", "admin", "user"]}
          >
            <CompetitorAnalysisCard
              values={dashboardData.competitorAnalysisCard}
            />
          </CustomAuthorizedFile>
        </Row>
        {dashboardData?.commercialTableCard[0]?.otel_adi !== "" && (
          <Row>
            <CommercialTableCard values={dashboardData.commercialTableCard} />
          </Row>
        )}
        <Row>
          <TotalSalesAmountsCard values={dashboardData.totalSalesAmountsCard} />
          <LastThirtyDayRoomNumbersCard
            values={dashboardData.lastThirtyDayRoomNumbersCard}
            totalAmount={dashboardData.totalRoomAmount}
          />
          <LastThirtyDayDailySalesCard
            values={dashboardData.lastThirtyDayDailySalesCard}
            totalAmount={dashboardData.totalSalesAmount}
          />
        </Row>
        {/* <Row>
        <PaymentAtCheckInTableCard values={[]} />
      </Row> */}
        <Row>
          <FacilityReservationOfficialCard
            values={dashboardData.facilityReservationOfficial}
            otel_id={otel_id}
          />
          <RegionalOperationOfficialCard
            values={dashboardData.regionalOperationOfficial}
          />
          <UsuableParaPuanCard values={dashboardData.usuableParaPuan} />
        </Row>
        <Row>
          <CommercialWarningCard values={dashboardData.commercialWarning} />
          <ProvinceRankingInfoCard values={dashboardData.provinceRankingInfo} />
          <DistrictRankingInfoCard values={dashboardData.districtRankingInfo} />
        </Row>
        <Row>
          <PriceWarningCard values={dashboardData.priceWarning} />
          <ProvinceRankingGraphCard
            values={dashboardData.provinceRankingGraph}
            hotel_name={hotel_name}
          />
          <DistrictRankingGraphCard
            values={dashboardData.districtRankingGraph}
            hotel_name={hotel_name}
          />
        </Row>
        <Row>
          <CollectionSituationCard values={dashboardData.collectionSituation} />
          <PriceProviderCard
            values={dashboardData.priceProvider}
            otel_id={otel_id}
          />
          <DiscountsCard otel_id={otel_id} />
        </Row>
        <Row>
          <CommentsCard values={dashboardData.comments} />
        </Row>
        <Row>
          <PageInfoCard />
        </Row>
      </div>
    </>
  );
};

export default withPageNeedsHotel(AdminDashboardPage);
