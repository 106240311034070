import ListWebSitesPage from "Components/Custom/MyWebSitePages/ListWebSitesPage";
import useListWebSites from "Components/Hooks/myWebSite/useListWebSites";
import PageContainer from "pages/PageContainer";

const ListWebSites = () => {

  const { isLoading } = useListWebSites();

  return (
    <PageContainer title="Web Sitesi Oluştur">
      <ListWebSitesPage
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default ListWebSites;
