import { EnterReservationPage } from "Components/Custom/ReservationPages";
import useEnterReservation from "Components/Hooks/reservation/useEnterReservation";

import PageContainer from "pages/PageContainer";

const EnterReservation = () => {
  const { values } = useEnterReservation();

  return (
    <PageContainer title="Rezervasyon Girişi">
      <EnterReservationPage values={values} />
    </PageContainer>
  );
};

export default EnterReservation;
