import ArchivedContractsTable from "./ArchivedContractsTable";

interface ArchivedContractsPageProps {
  isLoading: boolean;
}

const ArchivedContractsPage = ({ isLoading }: ArchivedContractsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <ArchivedContractsTable isLoading={isLoading} />
    </div>
  );
};

export default ArchivedContractsPage;
