import React, { useRef, useState } from "react";

interface CustomHorizontalSlidingBoxProps {
  children: React.ReactNode;
}

const CustomHorizontalSlidingBox = ({
  children,
}: CustomHorizontalSlidingBoxProps) => {
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [rightArrowDisable, setRightArrowDisable] = useState(false);

  const handleHorizantalScroll = (
    element: any,
    speed: any,
    distance: any,
    step: any
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;

      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
      if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
        setRightArrowDisable(true);
      } else {
        setRightArrowDisable(false);
      }
    }, speed);
  };
  return (
    <div className="d-flex gap-2 my-2 border rounded theme-bg-light">
      <button
        className="rounded-start border border-3 bg-primary bg-opacity-25 cursor-pointer"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, -10);
        }}
        disabled={arrowDisable}
      >
        <i className="ri-arrow-left-s-line fs-20 fw-bold theme-text-primary"></i>
      </button>
      <div
        className="d-flex gap-2 overflow-x-scroll pt-2 w-100"
        ref={elementRef}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {children}
      </div>
      <button
        className="rounded-end border border-3 bg-primary bg-opacity-25 cursor-pointer"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, 10);
        }}
        disabled={rightArrowDisable}
      >
        <i className="ri-arrow-right-s-line fs-20 fw-bold theme-text-primary"></i>
      </button>
    </div>
  );
};

export default CustomHorizontalSlidingBox;
