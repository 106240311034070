import { CustomLoadingPage } from "Components/Custom/UI";
import EmployeeSalesAmountTable from "./EmployeeSalesAmountTable";
import FilterForm from "./FilterForm";

interface EmployeeSalesAmountPageProps {
  isLoading: boolean;
}

const EmployeeSalesAmountPage = ({
  isLoading,
}: EmployeeSalesAmountPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <FilterForm />
        <EmployeeSalesAmountTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default EmployeeSalesAmountPage;
