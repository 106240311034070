import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeSpecialDayInput } from "slices/facility/facilitySpecialDays/reducer";
import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";
import { FACILITY_SPECIAL_DAY_DATE_DESCRIPTION } from "utils/fieldDescriptions";

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface SpecialDayDateSelectorProps {
  values: FacilitySpecialDayState;
  formErrors: FacilitySpecialDayFormErrors;
  validateFormChange: (values: FacilitySpecialDayState) => void;
}

const SpecialDayDateSelector = ({
  values,
  formErrors,
  validateFormChange,
}: SpecialDayDateSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Özel Gün Gösterim Tarih Aralığı"
        error={formErrors.ozel_gun_baslangic || formErrors.ozel_gun_bitis}
        description={FACILITY_SPECIAL_DAY_DATE_DESCRIPTION}
        divider
        required
      >
        <Flatpickr
          className="form-control"
          value={[values.ozel_gun_baslangic, values.ozel_gun_bitis]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeSpecialDayInput({
                  ozel_gun_baslangic: stringStartDate,
                  ozel_gun_bitis: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                ozel_gun_baslangic: stringStartDate,
                ozel_gun_bitis: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default SpecialDayDateSelector;
