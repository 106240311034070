import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { UpdateUserInitialState } from "slices/userOperations/updateUser/reducer";
import { fetchUserData } from "slices/userOperations/updateUser/thunk";

const useUpdateUser = () => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const { isLoading, error } = useSelector(
    (state: { UpdateUser: UpdateUserInitialState }) => state.UpdateUser
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uyeId  = queryParams.get("uye_id");

  const parsedUyeId = uyeId ? parseInt(uyeId, 10) : 0;

  useEffect(() => {
    if (parsedUyeId) {
      dispatch(fetchUserData(parsedUyeId, () => {}));
    }
  }, [dispatch, parsedUyeId]);

  return {
    isLoading,
    error,
  };
};

export default useUpdateUser;
