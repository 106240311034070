import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";
import { setIsLoading, setSuccess, setError } from "./reducer";

import { POST_BASIC_AVAILABILITY_ENTRIES } from "helpers/url_helper";

import { IncomingAvailability } from "types/PricesQuotas";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

interface RequestPayload {
  otel_id: number;
  tarihler: string;
  stoplar: IncomingAvailability[];
}

export const postBasicAvailabilityEntries =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(POST_BASIC_AVAILABILITY_ENTRIES, payload);

      dispatch(setSuccess("Müsaitlikler kaydedildi."));
      dispatch(setToastSuccess("Müsaitlikler kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
