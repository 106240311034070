import { CustomButton } from "Components/Custom/UI";
import { FilePondFile } from "filepond";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleSaveUpdateThemeDefault } from "slices/themes/listThemes/reducer";

import { getTheme, updateTheme } from "slices/themes/listThemes/thunk";
import { UpdateThemeRequestPayload, UpdateThemeState } from "types/themes";

interface UpdateThemeButtonProps {
  selectedTheme: number;
  values: UpdateThemeState;
  requestPayload: UpdateThemeRequestPayload;
  validateForm: (values: UpdateThemeState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateThemeButton = ({
  selectedTheme,
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateThemeButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateTheme = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      requestPayload.append("tema_id", values.tema_id.toString());
      requestPayload.append("tema_bolge", values.tema_bolge);
      requestPayload.append("durum", values.durum);
      requestPayload.append("baslik", values.baslik);
      requestPayload.append("alt_baslik", values.alt_baslik);
      requestPayload.append("aciklama", values.aciklama);
      requestPayload.append("link", values.link);

      const file = values.file as FilePondFile;

      file && requestPayload.append("resim", file.file, file.file.name);

      dispatch(
        updateTheme(requestPayload, () => {
          dispatch(
            getTheme({
              tema_id: selectedTheme,
            })
          );
        })
      );
    }
  };

  const resetToInitialState = () => {
    dispatch(handleSaveUpdateThemeDefault());
    window.scrollTo(0, 0);
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton variant="danger" onClick={() => resetToInitialState()}>
        Temizle
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleUpdateTheme}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateThemeButton;
