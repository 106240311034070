import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface MoneyPointsTableProps {
  isLoading: boolean;
}

const MoneyPointsTable = ({ isLoading }: MoneyPointsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <>
      {tableData && (
        <CustomReactTable
          fileName="Tesis Para Puan Listesi"
          tableData={tableData}
          tableColumns={tableColumns}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default MoneyPointsTable;
