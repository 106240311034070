import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/themes/addNewTheme/reducer";
import { AddNewThemeFormErrors, AddNewThemeState } from "types/themes";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface ThemeImageSelectorProps {
  values: AddNewThemeState;
  formErrors: AddNewThemeFormErrors;
  validateFormChange: (values: AddNewThemeState) => void;
}

const ThemeImageSelector = ({
  values,
  validateFormChange,
  formErrors,
}: ThemeImageSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.file ? [values.file] : [];

  return (
    <>
      <CustomFormFieldContainer label="Resim" error={formErrors.file}>
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeInput({ file: fileItems[0] }));
          }}
          maxFiles={1}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ThemeImageSelector;
