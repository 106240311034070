import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { RiskyHotelsInitialState } from "slices/reports/riskyHotels/reducer";
import { getRiskyHotels } from "slices/reports/riskyHotels/thunk";

const useRiskyHotels = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { RiskyHotels: RiskyHotelsInitialState }) => state.RiskyHotels
  );

  useEffect(() => {
    dispatch(getRiskyHotels());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useRiskyHotels;
