import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteFromSpecialDiscountsWithId,
  handleSaveSpecialDiscountForEdit,
} from "slices/discountsAndActions/specialDiscounts/reducer";
import { deleteSpecialDiscount } from "slices/discountsAndActions/specialDiscounts/thunk";
import {
  SpecialDiscount,
  SpecialDiscountState,
  SpecialOffer,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { specialOffers, specialDiscounts, allHotels } = useSelector(
    (state: { SpecialDiscounts: any }) => state.SpecialDiscounts
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(
      handleSaveSpecialDiscountForEdit(selectedRow as SpecialDiscountState)
    );
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      indirim_id: id,
    };
    dispatch(deleteSpecialDiscount(payload) as any);
    dispatch(handleDeleteFromSpecialDiscountsWithId(id));
  };

  const tableData: SpecialDiscountState[] = specialDiscounts?.map(
    (item: SpecialDiscount) => ({
      id: item.id,
      grup_id: item.grup_id,
      indirim_tipi: item.indirim_tipi,
      rez_tarihi_baslangic: moment(item.satis_baslangic_tarihi).toDate(),
      rez_tarihi_bitis: moment(item.satis_bitis_tarihi).toDate(),
      konaklama_tarihi_baslangic: moment(
        item.konaklama_baslangic_tarihi
      ).toDate(),
      konaklama_tarihi_bitis: moment(item.konaklama_bitis_tarihi).toDate(),
      min_gece_sayisi: item.minimum_gece,
      ozel_oran: parseInt(item.ozel_oran),
      giris_gunu_durum: item.giris_gunu,
      giris_gunu_baslangic_tarihi: item.giris_gunu_baslangic_tarihi
        ? moment(item.giris_gunu_baslangic_tarihi).toDate()
        : null,
      giris_gunu_bitis_tarihi: item.giris_gunu_bitis_tarihi
        ? moment(item.giris_gunu_bitis_tarihi).toDate()
        : null,
      radio_gun: item.tum_gunler,
      gun: JSON.parse(item.gunler),
      deskalti_durum: item.desk_alti,
      maliyete_uygula: item.maliyete_uygula,
      direkt_uygula: item.uygulanma_sekli,
      eb_birlikte: item.eb_birlikte,
      kalode_birlikte: item.kal_ode_birlikte,
      diger_birlikte: item.diger_birlikte,
      yalniz_bu_otel: item.yalniz_bu_otel === "1" ? 1 : 0,
      secili_oteller: item.tanimli_oteller
        ? item.tanimli_oteller
            .split(",")
            .map(
              (item) =>
                allHotels.find((hotel: any) => hotel.otel_adi === item)?.otel_id
            )
        : [],
      tum_pansiyonlar: item.tum_otel_pansiyonlar === "1" ? 1 : 0,
      secili_pansiyonlar: item.otel_pansiyonlar || [],
      tum_odeme_tipleri: item.tum_odeme_tipleri === "1" ? 1 : 0,
      secili_odeme_tipleri: item.odeme_tipleri || [],
    })
  );

  const tableColumns = [
    {
      header: "Kampanya Adı",
      cell: (cell: any) => {
        return (
          <span className="fw-semibold">
            {
              specialOffers.find(
                (offer: SpecialOffer) =>
                  offer.id === parseInt(cell.row.original.indirim_tipi)
              )?.indirim_isim
            }
          </span>
        );
      },
      accessorKey: "indirim_tipi",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Tarihi",
      cell: (cell: any) => {
        return (
          <span>
            {moment(cell.row.original.rez_tarihi_baslangic).format(
              "DD.MM.YYYY"
            )}
            <br />
            {moment(cell.row.original.rez_tarihi_bitis).format("DD.MM.YYYY")}
          </span>
        );
      },
      accessorKey: "rez_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihi",
      cell: (cell: any) => {
        return (
          <span>
            {moment(cell.row.original.konaklama_tarihi_baslangic).format(
              "DD.MM.YYYY"
            )}
            <br />
            {moment(cell.row.original.konaklama_tarihi_bitis).format(
              "DD.MM.YYYY"
            )}
          </span>
        );
      },
      accessorKey: "konaklama_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Minimum Gece",
      accessorKey: "min_gece_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Özel Oran",
      cell: (cell: any) => {
        return <span className="fw-medium">%{cell.getValue()}</span>;
      },
      accessorKey: "ozel_oran",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Günü Durum",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isStatus />
      ),
      accessorKey: "giris_gunu_durum",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Günü Tarihi",
      cell: (cell: any) => {
        return (
          <span>
            {cell.row.original.giris_gunu_baslangic_tarihi
              ? moment(cell.row.original.giris_gunu_baslangic_tarihi).format(
                  "DD.MM.YYYY"
                )
              : "-"}
            <br />
            {cell.row.original.giris_gunu_bitis_tarihi
              ? moment(cell.row.original.giris_gunu_bitis_tarihi).format(
                  "DD.MM.YYYY"
                )
              : "-"}
          </span>
        );
      },
      accessorKey: "giris_gunu_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "radio_gun",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const radio_gun = cell.row.original.radio_gun;

        return (
          <span className="d-flex gap-2 flex-wrap">
            {radio_gun === "1" ? (
              <CustomRowBadge value="Tüm Günler" />
            ) : (
              days.map((day: string, index: number) => (
                <CustomRowBadge key={index} value={day} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "gun",
      enableColumnFilter: false,
    },
    {
      header: "Deskaltı Durum",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "deskalti_durum",
      enableColumnFilter: false,
    },
    {
      header: "Maliyete Uygula",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "maliyete_uygula",
      enableColumnFilter: false,
    },
    {
      header: "Direkt Uygula",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "direkt_uygula",
      enableColumnFilter: false,
    },
    {
      header: "Erken Rezervasyon Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "eb_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Kalode Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "kalode_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Diğer Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "diger_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Yalnız Bu Otel",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 1} isYesNo />
      ),
      accessorKey: "yalniz_bu_otel",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Oteller",
      cell: (cell: any) => {
        const selectedHotels = cell.getValue();
        return (
          <span
            className="d-flex gap-2 flex-wrap mx-1"
            style={{
              height: selectedHotels.length < 4 ? "auto" : "6rem",
              width: "fit-content",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#aaa #f4f4f4",
            }}
          >
            {selectedHotels.length === 0 ? (
              <span>-</span>
            ) : (
              selectedHotels.map((hotel_id: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    allHotels.find((item: any) => item.otel_id === hotel_id)
                      ?.otel_adi
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_oteller",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 1} isYesNo />
      ),
      accessorKey: "tum_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 1} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            {
              <CustomRowOperationButton
                variant="delete"
                onClick={() => {
                  handleDelete(id);
                }}
              />
            }
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
