import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveEarlyReservationDiscounts,
  resetEarlyReservationDiscount,
  resetEarlyReservationDiscounts,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_EARLY_RESERVATION_DISCOUNTS,
  ADD_EARLY_RESERVATION_DISCOUNT,
  UPDATE_EARLY_RESERVATION_DISCOUNT,
  DELETE_EARLY_RESERVATION_DISCOUNT,
} from "helpers/url_helper";
import { EarlyReservationDiscountRequestPayload } from "types/DiscountsAndActions";
import { Dispatch } from "redux";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteEarlyReservationDiscountPayload {
  erken_rezervasyon_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getEarlyReservationDiscounts =
  (payload: GetRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetEarlyReservationDiscounts());
      const response = await axios.get(GET_EARLY_RESERVATION_DISCOUNTS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveEarlyReservationDiscounts(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addEarlyReservationDiscount =
  (
    payload: EarlyReservationDiscountRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddDiscountLoading(true));

      await axios.post(ADD_EARLY_RESERVATION_DISCOUNT, payload);

      successCallback();
      dispatch(resetEarlyReservationDiscount());
      dispatch(setToastSuccess("Erken rezervasyon indirimi eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddDiscountLoading(false));
    }
  };

export const updateEarlyReservationDiscount =
  (
    payload: EarlyReservationDiscountRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDiscountLoading(true));

      await axios.post(UPDATE_EARLY_RESERVATION_DISCOUNT, payload);

      successCallback();
      dispatch(resetEarlyReservationDiscount());
      dispatch(setToastSuccess("Erken rezervasyon indirimi güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDiscountLoading(false));
    }
  };

export const deleteEarlyReservationDiscount =
  (payload: DeleteEarlyReservationDiscountPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteDiscountLoading(true));
      await axios.post(DELETE_EARLY_RESERVATION_DISCOUNT, payload);

      dispatch(setToastSuccess("Erken rezervasyon indirimi silindi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteDiscountLoading(false));
    }
  };
