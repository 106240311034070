import { STOPS } from "enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSaveBaseFormInputValues,
  handleSaveColumnsInputValues,
} from "slices/pricesAndQuota/advancedEntry/reducer";
import { PriceType } from "types/PricesQuotas";

interface InputValues {
  [key: string]: number;
}

const addDefaultInputValues = (priceTypes: PriceType[]) => {
  const newInputValues: InputValues = {};
  priceTypes.forEach((priceType) => {
    newInputValues[priceType.fiyat_tipi] = 0;
  });
  newInputValues["kontenjan"] = 0;
  newInputValues["rezervasyon"] = 0;
  newInputValues["cta"] = 0;
  newInputValues["ctd"] = 0;
  newInputValues["musaitlik"] = STOPS.YOK;
  return newInputValues;
};

const useHandleForm = (
  priceTypes: PriceType[],
  paymentMethods: string[],
  paymentMethodsMultipliers: {
    [key: string]: string;
  },
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  },
  paymentMethod: string
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { baseFormInputValues, columnsInputValues } = useSelector(
    (state: any) => state.AdvancedEntry
  );

  const [isReady, setIsReady] = useState(false);

  const handlePriceChange = (
    type: string,
    value: number,
    isBasePrice: boolean
  ) => {
    const updatingPaymentMethods = paymentMethods
      .filter(
        (method) =>
          method === paymentMethod ||
          notAllowedToEditPaymentMethods[method] === false
      )
      .concat(["iptal_edilemez"]);

    if (isBasePrice) {
      const newInputValues: InputValues = {};
      priceTypes.forEach((priceType) => {
        if (priceType.baz_fiyat) {
          newInputValues[priceType.fiyat_tipi] = Math.ceil(value);
        } else {
          newInputValues[priceType.fiyat_tipi] = Math.ceil(
            value * priceType.katsayi
          );
        }
      });
      dispatch(handleSaveBaseFormInputValues(newInputValues));

      const newColumnInputValues: {
        [key: string]: InputValues;
      } = {};

      paymentMethods.forEach((paymentMethod) => {
        if (!updatingPaymentMethods.includes(paymentMethod)) {
          return;
        }

        const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

        const newMultiplier =
          paymentMethodsMultipliers[paymentMethod] &&
          notAllowedToEditPaymentMethods[paymentMethod] !== false
            ? 100 - parseFloat(paymentMethodsMultipliers[paymentMethod])
            : 100;

        Object.keys(newValues).forEach((key) => {
          Object.keys(newInputValues).forEach((inputKey) => {
            if (key.endsWith(inputKey)) {
              newValues[key] = Math.ceil(
                (newInputValues[inputKey] * newMultiplier) / 100
              );
            }
          });
        });

        newColumnInputValues[paymentMethod] = newValues;
      });

      dispatch(handleSaveColumnsInputValues(newColumnInputValues));

      return;
    }

    dispatch(
      handleSaveBaseFormInputValues({
        [type]: isNaN(value) ? 0 : value,
      })
    );

    const newColumnInputValues: {
      [key: string]: InputValues;
    } = {};

    paymentMethods.forEach((paymentMethod) => {
      if (!updatingPaymentMethods.includes(paymentMethod)) {
        return;
      }

      const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

      const newMultiplier =
        paymentMethodsMultipliers[paymentMethod] &&
        notAllowedToEditPaymentMethods[paymentMethod] !== false
          ? 100 - parseFloat(paymentMethodsMultipliers[paymentMethod])
          : 100;

      Object.keys(newValues).forEach((key) => {
        if (key.endsWith(type)) {
          newValues[key] = Math.ceil((value * newMultiplier) / 100);
        }
      });

      newColumnInputValues[paymentMethod] = newValues;
    });

    dispatch(handleSaveColumnsInputValues(newColumnInputValues));
  };

  const handleChange = (type: string, value: number) => {
    dispatch(
      handleSaveBaseFormInputValues({
        [type]: isNaN(value) ? 0 : value,
      })
    );

    const newColumnInputValues: {
      [key: string]: InputValues;
    } = {};

    if (type === "kontenjan") {
      paymentMethods.forEach((method) => {
        const newValues: InputValues = { ...columnsInputValues[method] };

        Object.keys(newValues).forEach((key) => {
          if (key.endsWith(type)) {
            newValues[key] = value;
          }
        });
        newColumnInputValues[method] = newValues;

        dispatch(handleSaveColumnsInputValues(newColumnInputValues));
      });
    } else {
      const newValues: InputValues = { ...columnsInputValues[paymentMethod] };

      Object.keys(newValues).forEach((key) => {
        if (key.endsWith(type)) {
          newValues[key] = value;
        }
      });
      newColumnInputValues[paymentMethod] = newValues;

      dispatch(handleSaveColumnsInputValues(newColumnInputValues));
    }
  };

  useEffect(() => {
    const newInputValues = addDefaultInputValues(priceTypes);
    dispatch(handleSaveBaseFormInputValues(newInputValues));

    setIsReady(true);
  }, [priceTypes, dispatch]);

  return { isReady, baseFormInputValues, handlePriceChange, handleChange };
};

export default useHandleForm;
