import { useSelector } from "react-redux";
import ChildrenInfo from "../ReservationCallPage/ChildrenInfo";
import {
  ReservationCallInitialState,
  resetReservationCallChildrenInfo,
} from "slices/reservation/reservationCall/reducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getReservationCallChildrenInfo } from "slices/reservation/reservationCall/thunk";

const HotelChildrenInfo = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { calculationForm } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  useEffect(() => {
    if (
      calculationForm.secilen.label &&
      calculationForm.secilen.category === "Lokasyon"
    ) {
      dispatch(resetReservationCallChildrenInfo());
    }

    if (
      calculationForm.secilen.label &&
      calculationForm.secilen.category === "Otel"
    ) {
      dispatch(getReservationCallChildrenInfo(calculationForm.secilen.label));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationForm.secilen]);

  return (
    <>
      <ChildrenInfo />
    </>
  );
};

export default HotelChildrenInfo;
