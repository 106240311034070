import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleSetMailOrderFormErrors } from "slices/financeAndAccounting/mailOrderInfo/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  MailOrderFormErrors,
  MailOrderState,
} from "types/financeAndAccounting";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = (values: MailOrderState, showErrorToast = true) => {
    const addErrors: MailOrderFormErrors = {};
    const updateErrors: MailOrderFormErrors = {};

    const errors = values.id ? updateErrors : addErrors;

    if (!values.kart_sahibi.includes(" ")) {
      errors.kart_sahibi = "Ad ve soyad giriniz.";
    }
    if (!values.kart_sahibi) {
      errors.kart_sahibi = "Zorunlu alan.";
    }
    if (!values.kart_kodu) {
      errors.kart_kodu = "Zorunlu alan.";
    }
    if (!values.adi) {
      errors.adi = "Zorunlu alan.";
    }
    if (isNaN(parseInt(values.kart_numarasi))) {
      errors.kart_numarasi = "Sadece rakam giriniz.";
    }
    if (values.kart_numarasi.length !== 16) {
      errors.kart_numarasi = "Kart numarası 16 haneli olmalıdır.";
    }
    if (!values.kart_numarasi) {
      errors.kart_numarasi = "Zorunlu alan.";
    }
    if (
      !values.kart_son_kullanim.includes("/") ||
      values.kart_son_kullanim.split("/").length !== 2 ||
      values.kart_son_kullanim.split("/")[0].length !== 2 ||
      values.kart_son_kullanim.split("/")[1].length !== 2 ||
      isNaN(parseInt(values.kart_son_kullanim.split("/")[0])) ||
      isNaN(parseInt(values.kart_son_kullanim.split("/")[1])) ||
      parseInt(values.kart_son_kullanim.split("/")[0]) > 12 ||
      parseInt(values.kart_son_kullanim.split("/")[0]) < 1 ||
      parseInt(values.kart_son_kullanim.split("/")[1]) < 24
    ) {
      errors.kart_son_kullanim = "Doğru formatta giriniz.";
    }
    if (!values.kart_son_kullanim) {
      errors.kart_son_kullanim = "Zorunlu alan.";
    }
    if (values.kart_cvc.length !== 3) {
      errors.kart_cvc = "3 haneli olmalıdır.";
    }
    if (!values.kart_cvc) {
      errors.kart_cvc = "Zorunlu alan.";
    }

    dispatch(handleSetMailOrderFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid,
    };
  };

  const validateFormChange = (values: MailOrderState) => {
    if (isSubmitted) {
      validateForm(values, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
