import { RoomConceptMatchPage } from "Components/Custom/FacilityPages";
import useRoomConceptMatch from "Components/Hooks/facility/useRoomConceptMatch";
import PageContainer from "pages/PageContainer";

const RoomConceptMatch = () => {
  const { loading, error, isValidHotel, isLoading } = useRoomConceptMatch();

  return (
    <PageContainer title="Tedarikçi Oda-Pansiyon Eşleştir">
      <RoomConceptMatchPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default RoomConceptMatch;
