import { useSelector } from "react-redux";

import { AnsweredCallsInitialState } from "slices/reports/answeredCalls/reducer";

const useHandleTable = () => {
  const { answeredCalls } = useSelector(
    (state: { AnsweredCalls: AnsweredCallsInitialState }) => state.AnsweredCalls
  );

  const tableData = answeredCalls?.map((answeredCall) => ({
    "Arama ID": answeredCall.arama_id,
    "Arayan Müşteri ID": answeredCall.musteri_id,
    "Arayan Müşteri": answeredCall.musteri_isim,
    "Arayan Numara": answeredCall.arayan_no,
    "Aranan Otel ID": answeredCall.otel_id,
    "Aranan Otel": answeredCall.otel_adi,
    "Aranan Numara": answeredCall.aranan_no,
    "Personel Grup": answeredCall.grup_adi,
    "Cevaplayan Personel ID": answeredCall.crm_kul_id,
    "Cevaplayan Personel": answeredCall.kullanici_adi,
    İşaretleme: answeredCall.isaret,
    Açıklama: answeredCall.aciklama,
    "Cevaplama Zamanı": answeredCall.kayit_tarihi,
    "Rezervasyon Kodu": answeredCall.rezervasyon_kodu,
    "Rezervasyon Dışı": answeredCall.rezervasyon_disi,
    "Sonra Aranacak": answeredCall.sonra_aranacak,
    "Fiyat Yok": answeredCall.fiyat_yok,
    "Fiyat Araştırıyor": answeredCall.fiyat_arastiriyor,
  }));

  const tableColumns = [
    {
      header: "Arama ID",
      accessorKey: "Arama ID",
      enableColumnFilter: false,
    },
    {
      header: "Arayan Müşteri",
      accessorKey: "Arayan Müşteri",
      enableColumnFilter: false,
    },
    {
      header: "Arayan Numara",
      accessorKey: "Arayan Numara",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Otel",
      accessorKey: "Aranan Otel",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Numara",
      accessorKey: "Aranan Numara",
      enableColumnFilter: false,
    },
    {
      header: "Personel Grup",
      accessorKey: "Personel Grup",
      enableColumnFilter: false,
    },
    {
      header: "Cevaplayan Personel",
      accessorKey: "Cevaplayan Personel",
      enableColumnFilter: false,
    },
    {
      header: "İşaretleme",
      accessorKey: "İşaretleme",
      enableColumnFilter: false,
    },
    {
      header: "Cevaplama Zamanı",
      accessorKey: "Cevaplama Zamanı",
      enableColumnFilter: false,
    },
    {
      header: "Açıklama",
      accessorKey: "Açıklama",
      cell: (cell: any) => (
        <span
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {cell.getValue()}
        </span>
      ),
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
