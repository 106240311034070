import { CategorizeFacilityPage } from "Components/Custom/FacilityCategorizationPages";
import useCategorizeFacility from "Components/Hooks/facilityCategories/useCategorizeFacility";
import PageContainer from "pages/PageContainer";

const CategorizeFacility = () => {
  const { isLoading } = useCategorizeFacility();

  return (
    <PageContainer title="Tesis Sınıflandır">
      <CategorizeFacilityPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CategorizeFacility;
