import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleAddGroupFormChoices,
  setIsLoading,
  setError,
  setAddGroupLoading,
  setAddGroupError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_ADD_GROUP_FORM_CHOICES, ADD_GROUP } from "helpers/url_helper";
import { Dispatch } from "redux";

export const getAddGroupFormChoices =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response =  await axios.get(GET_ADD_GROUP_FORM_CHOICES);
      const res = (response as any).message ? response.data : response;
  
      dispatch(handleAddGroupFormChoices(res));

    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addGroup = (payload: FormData) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAddGroupLoading(true));
    await axios.post(ADD_GROUP, payload);

    dispatch(setToastSuccess("Grup başarıyla eklendi."));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setAddGroupError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setAddGroupLoading(false));
  }
};
