import { createSlice } from "@reduxjs/toolkit";
import { AddUserFormChoices, AddUserFormErrors, AddUserState } from "types/userOperations";

export interface AddUserInitialState {
  addUserFormChoices: AddUserFormChoices;
  addUser: AddUserState;
  addUserFormErrors: AddUserFormErrors;
  isLoading: boolean;
  error: string;
  addUserLoading: boolean;
  addUserError: string;
}

export const addUserInitialState: AddUserInitialState = {
  addUserFormChoices: {
    "Cinsiyet" : [
      {label: "Bay", value: "e"},
      {label: "Bayan", value: "k"},
    ],
    "Çalışma Tipi" : [
      {label: "Full Time", value: "0"},
      {label: "Part Time", value: "1"},
    ],
    "Hesap Türü" : [
      {label: "Ana Kullanıcı", value: "root"},
      {label: "Yetkili Kullanıcı", value: "admin"},
      {label: "Standart Kullanıcı", value: "user"},
      {label: "Otel", value: "otel"},
    ],
  },
  addUser: {
    inet_kodu: "",
    ad_soyad: "",
    kullanici_adi: "",
    email_adresi: "",
    sifre: "",
    dahili: "",
    calisma_tipi: "",
    cinsiyet: "",
    yetki: "",
  },
  addUserFormErrors: {},
  isLoading: false,
  error: "",
  addUserLoading: false,
  addUserError: "",
};

export const addUserSlice = createSlice({
  name: "addUser",
  initialState: addUserInitialState,
  reducers: {
    handleAddUser(state, action) {
      state.addUser = action.payload;
    },
    handleChangeInput(state, action) {
      state.addUser = {
        ...state.addUser,
        ...action.payload,
      };
    },
    handleAddUserFormErrors(state, action) {
      state.addUserFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddUserLoading(state, action) {
      state.addUserLoading = action.payload;
    },
    setAddUserError(state, action) {
      state.addUserError = action.payload;
    },
    handleResetUserState(state) {
      state.addUser = {
        inet_kodu: "",
        ad_soyad: "",
        kullanici_adi: "",
        email_adresi: "",
        sifre: "",
        dahili: "",
        calisma_tipi: "",
        cinsiyet: "",
        yetki: "",
      };
    },
  },
});

export const {
  handleAddUser,
  handleChangeInput,
  handleAddUserFormErrors,
  setIsLoading,
  setError,
  setAddUserLoading,
  setAddUserError,
  handleResetUserState,
} = addUserSlice.actions;

export default addUserSlice.reducer;
