import { useSelector } from "react-redux";
import { AddUserInitialState } from "slices/userOperations/addUser/reducer";
import useValidateForm from "./useValidateForm";


const useHandleForm = () => {

  const {
    addUserFormChoices,
    addUser,
    addUserFormErrors,
    addUserLoading,
  } = useSelector(
    (state: { AddUser: AddUserInitialState }) => state.AddUser
  );

  const { validateForm, validateFormChange, handleSubmitted } = useValidateForm();

  const addUserRequestPayload = new FormData();


  return {
    addUserFormChoices,
    addUser,
    addUserFormErrors,
    addUserLoading,
    validateForm, 
    validateFormChange, 
    handleSubmitted,
    addUserRequestPayload
  };
};

export default useHandleForm;
