import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

interface Option {
  value: string;
  label: string;
}

interface CustomDualListBoxProps {
  listOptions: Option[];
  selectedOptions: string[];
  handleSelectOptions: (selectedOptions: string[]) => void;
}

const CustomDualListBox = ({
  listOptions,
  selectedOptions,
  handleSelectOptions,
}: CustomDualListBoxProps) => {
  return (
    <DualListBox
      options={listOptions}
      selected={selectedOptions}
      onChange={(newValue: string[]) => {
        handleSelectOptions(newValue);
      }}
      icons={{
        moveToAvailable: (
          <i className="ri-arrow-left-s-line fs-16 fw-semibold"></i>
        ),
        moveAllToAvailable: (
          <i className="ri-arrow-left-double-line fs-16 fw-semibold"></i>
        ),
        moveToSelected: (
          <i className="ri-arrow-right-s-line fs-16 fw-semibold"></i>
        ),
        moveAllToSelected: (
          <i className="ri-arrow-right-double-line fs-16 fw-semibold"></i>
        ),
      }}
      canFilter
    />
  );
};

export default CustomDualListBox;
