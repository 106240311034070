import { useSelector } from "react-redux";
import { DiscountCodeRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { DiscountCodesInitialState } from "slices/discountsAndActions/discountCodes/reducer";

const useHandleForm = () => {
  const {
    discountCode,
    discountCodeFormErrors,
    addCodeLoading,
    updateCodeLoading,
  } = useSelector(
    (state: { DiscountCodes: DiscountCodesInitialState }) => state.DiscountCodes
  );

  const addNewDiscountCodeRequestPayload: DiscountCodeRequestPayload = {
    indirim_adi: discountCode.indirim_adi,
    indirim_tipi: discountCode.indirim_tipi,
    indirim_orani:
      discountCode.indirim_tipi === "oran"
        ? Number(discountCode.indirim_orani)
        : null,
    indirim_tutari:
      discountCode.indirim_tipi === "tutar"
        ? Number(discountCode.indirim_tutari)
        : null,
    indirim_kupon_kodu: discountCode.indirim_kuponu_kodu,
  } as DiscountCodeRequestPayload;

  const updateDiscountCodeRequestPayload: DiscountCodeRequestPayload = {
    indirim_kuponu_id: discountCode.id,
    ...addNewDiscountCodeRequestPayload,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    discountCode,
    addCodeLoading,
    updateCodeLoading,
    addNewDiscountCodeRequestPayload,
    updateDiscountCodeRequestPayload,
    formErrors: discountCodeFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
