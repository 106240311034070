import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";

import { HotelInvoicesInitialState } from "slices/financeAndAccounting/hotelInvoices/reducer";

const useHandleTable = () => {
  const { hotelInvoices } = useSelector(
    (state: { HotelInvoices: HotelInvoicesInitialState }) => state.HotelInvoices
  );

  const tableData = hotelInvoices?.map((hotelInvoice) => ({
    Tarih: hotelInvoice.tarih,
    "Fatura No": hotelInvoice.fatura_numarasi,
    "Toplam Tutar": hotelInvoice.toplam,
    "Fatura Link": hotelInvoice.fatura_url,
  }));

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
    {
      header: "Fatura No",
      accessorKey: "Fatura No",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Tutar",
      accessorKey: "Toplam Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Fatura Link",
      accessorKey: "Fatura Link",
      cell: (cell: any) => {
        const link = cell.getValue();
        return (
          <CustomRowOperationButton
            variant="detail"
            onClick={() => window.open(link, "_blank")}
          />
        );
      },
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
