import { getPriceTypeLabel } from "helpers/general_helpers";

interface ColumnOtherPricesProps {
  otherPrices: {
    key: number;
    value: {
      [key: string]: string;
    };
  }[];
}

const ColumnOtherPrices = ({ otherPrices }: ColumnOtherPricesProps) => {
  const pricesColumns = otherPrices.map(({ key, value }: any) =>
    Object.keys(value)
  );

  const pricesColumnLabels = pricesColumns[0].map((column: string) => ({
    label: getPriceTypeLabel(column),
    value: column,
  }));

  return (
    <table className="table table-bordered table-striped table-hover table-sm">
      <thead>
        <tr className="fs-14">
          <th>Gece Sayısı</th>
          {pricesColumnLabels.map((label: any, index: number) => (
            <th key={index}>{label.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {otherPrices.map(({ key, value }: any) => (
          <tr key={key}>
            <td className="fs-14 fw-medium">{key}</td>
            {pricesColumnLabels.map((label: any, index: number) => (
              <td key={index} className="fs-14">
                {value[label.value] ? value[label.value] : "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ColumnOtherPrices;
