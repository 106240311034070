import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { TransferReservationPayload } from "types/userOperations";

interface Option {
  value: number;
  label: string;
}
interface PersonnelSelectorProps {
  options: Option[];
  values: TransferReservationPayload;
  handleFormChange: (key: string, value: number) => void;
}

const PersonnelSelector = ({     
  options, 
  values,
  handleFormChange, 
}: PersonnelSelectorProps) => {

  return (
    <CustomFormFieldContainer
      label="Kullanıcılar ">
      <Select
        options={options}
        value={options.find(
          (option) => option.value === values.kullanici
        )}
        onChange={(selectedOption: Option) => {
          handleFormChange('kullanici', selectedOption.value);
        }}
        placeholder="Kullanıcı Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default PersonnelSelector;
