import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { CreateWebSiteFormChoices, CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
import Select from "react-select";

interface ShowCommercialPopupSelectorProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}

interface Option {
  value: string;
  label: string;
}

const ShowCommercialPopupSelector = ({
  values,
  formChoices,
  validateFormChange,
  formErrors,
}: ShowCommercialPopupSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const popupOptions = formChoices.reklam_popup.map((popup) => ({
    value: popup.value.toString(),
    label: popup.text,
  }));

  const selectedOption = popupOptions.find((option) => option.value === values.reklam_popup);


  return (
    <>
      <CustomFormFieldContainer
        label="Reklam Popup Gösterilsin Mi? :"
        error={formErrors.reklam_popup}
        divider
        required
      >
        <Select
          options={popupOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            if (selected) {
              dispatch(handleChangeInput({ ...values, reklam_popup: selected.value }));
              validateFormChange({ ...values, reklam_popup: selected.value });
            };
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ShowCommercialPopupSelector;
