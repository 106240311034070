import { CalculatePriceForHotelPage } from "Components/Custom/ReservationPages";
import useCalculatePriceForHotel from "Components/Hooks/reservation/useCalculatePriceForHotel";
import PageContainer from "pages/PageContainer";

const CalculatePriceForHotel = () => {
  useCalculatePriceForHotel();

  return (
    <PageContainer title="Otel Fiyat Hesapla">
      <CalculatePriceForHotelPage />
    </PageContainer>
  );
};

export default CalculatePriceForHotel;
