import { useSelector } from "react-redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";

const useHandleTable = () => {
  const { unmatchedHotels } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  const tableData = unmatchedHotels?.map((unmatchedHotel) => ({
    "Otel Adı": unmatchedHotel.otel_adi,
    "Otel Yetkilisi": unmatchedHotel.otel_yetkilisi,
    Bölge: unmatchedHotel.bolge,
    İl: unmatchedHotel.sehir,
    İlçe: unmatchedHotel.ilce,
    Semt: unmatchedHotel.Semt,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel Yetkilisi",
      accessorKey: "Otel Yetkilisi",
      enableColumnFilter: false,
    },
    {
      header: "Bölge",
      accessorKey: "Bölge",
      enableColumnFilter: false,
    },
    {
      header: "İl",
      accessorKey: "İl",
      enableColumnFilter: false,
    },
    {
      header: "İlçe",
      accessorKey: "İlçe",
      enableColumnFilter: false,
    },
    {
      header: "Semt",
      accessorKey: "Semt",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
