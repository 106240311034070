import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/breakingDiscounts/reducer";
import {
  BreakingDiscountFormErrors,
  BreakingDiscountState,
} from "types/DiscountsAndActions";

import Select from "react-select";

interface OtherFieldsProps {
  values: BreakingDiscountState;
  formErrors: BreakingDiscountFormErrors;
  validateFormChange: (values: BreakingDiscountState) => void;
  conceptsOptions: HotelOption[];
  paymentMethodOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}
interface HotelOption {
  value: number;
  label: string;
}

const OtherFields = ({
  values,
  formErrors,
  validateFormChange,
  conceptsOptions,
  paymentMethodOptions,
}: OtherFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Tüm Odalara Uygula"
        //   tooltipId="applyAllRooms"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.grup_id === 0}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  grup_id: values.grup_id === 0 ? 1 : 0,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>

      <CustomFormFieldContainer
        label="Tüm Pansiyonlara Uygula"
        tooltipId="applyAllConcepts"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_pansiyonlar === 1}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_pansiyonlar: values.tum_pansiyonlar === 1 ? 0 : 1,
                })
              );
              dispatch(handleChangeInput({ secili_pansiyonlar: [] }));
              validateFormChange({
                ...values,
                tum_pansiyonlar: values.tum_pansiyonlar === 1 ? 0 : 1,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {!values.tum_pansiyonlar && (
        <CustomFormFieldContainer
          label="Seçili Pansiyonlara Uygula"
          error={formErrors.secili_pansiyonlar}
        >
          <Select
            isMulti={true}
            value={
              values.secili_pansiyonlar.map((pansiyon_id) => {
                return conceptsOptions.find(
                  (option) => Number(option.value) === Number(pansiyon_id)
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedRooms = selected.map((item) => item.value);
              dispatch(
                handleChangeInput({ secili_pansiyonlar: selectedRooms })
              );
              validateFormChange({
                ...values,
                secili_pansiyonlar: selectedRooms,
              });
            }}
            options={conceptsOptions}
            placeholder="Pansiyon Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
      <CustomFormFieldContainer
        label="Tüm Odeme Tiplerine Uygula"
        tooltipId="applyAllPaymentMethods"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_odeme_tipleri === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_odeme_tipleri:
                    values.tum_odeme_tipleri === "1" ? "0" : "1",
                })
              );
              dispatch(handleChangeInput({ secili_odeme_tipleri: [] }));
              validateFormChange({
                ...values,
                tum_odeme_tipleri: values.tum_odeme_tipleri === "1" ? "0" : "1",
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.tum_odeme_tipleri === "0" && (
        <CustomFormFieldContainer
          label="Seçili Odeme Tiplerine Uygula"
          error={formErrors.secili_odeme_tipleri}
        >
          <Select
            isMulti={true}
            value={
              values.secili_odeme_tipleri.map((paymentMethod) => {
                return paymentMethodOptions.find(
                  (option) => option.value === paymentMethod
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedPaymentMethods = selected.map((item) => item.value);
              dispatch(
                handleChangeInput({
                  secili_odeme_tipleri: selectedPaymentMethods,
                })
              );
              validateFormChange({
                ...values,
                secili_odeme_tipleri: selectedPaymentMethods,
              });
            }}
            options={paymentMethodOptions}
            placeholder="Ödeme Tipi Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default OtherFields;
