import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";

import {
  IssuedInvoicesInitialState,
  handleSaveSelectedDates,
  handleSaveSelectedManager,
} from "slices/financeAndAccounting/issuedInvoices/reducer";

import { getIssuedInvoices } from "slices/financeAndAccounting/issuedInvoices/thunk";
import moment from "moment";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { selectedManager, managers } = useSelector(
    (state: { IssuedInvoices: IssuedInvoicesInitialState }) =>
      state.IssuedInvoices
  );

  const [filterForm, setFilterForm] = useState({
    otel_id: 0,
    b_tarih: moment().subtract(1, "months").format("YYYY-MM-DD"),
    s_tarih: moment().format("YYYY-MM-DD"),
  });

  const handleFormChange = (key: string, value: string | number) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [managerOptions, setManagerOptions] = useState<Option[]>([]);

  useEffect(() => {
    const managerOptions = Object.entries(managers).map(([key, value]) => ({
      value,
      label: key,
    }));

    setManagerOptions(managerOptions);
  }, [managers]);

  const dispatch: Dispatch<any> = useDispatch();

  const handleSelectedManager = (value: string) => {
    dispatch(handleSaveSelectedManager(value));
  };

  const handleSelectedDates = (dates: [string, string]) => {
    dispatch(handleSaveSelectedDates(dates));
  };

  const handleGetFilteredList = () => {
    const params = {
      otel_id: filterForm.otel_id,
      b_tarih: filterForm.b_tarih,
      s_tarih: filterForm.s_tarih,
      yetkili_id: Number(selectedManager),
      per_page: 10,
      page: 1,
      search: "",
    };

    dispatch(getIssuedInvoices(params));
  };

  const handleResetFilter = () => {
    setFilterForm({
      otel_id: 0,
      b_tarih: moment().subtract(1, "months").format("YYYY-MM-DD"),
      s_tarih: moment().format("YYYY-MM-DD"),
    });

    const params = {
      otel_id: 0,
      b_tarih: moment().subtract(1, "months").format("YYYY-MM-DD"),
      s_tarih: moment().format("YYYY-MM-DD"),
      yetkili_id: 0,
      per_page: 10,
      page: 1,
      search: "",
    };

    dispatch(handleSaveSelectedManager("0"));
    dispatch(handleSaveSelectedDates([params.b_tarih, params.s_tarih]));
    dispatch(getIssuedInvoices(params));
  };

  return {
    filterForm,
    managerOptions,
    selectedManager,
    handleFormChange,
    handleSelectedManager,
    handleSelectedDates,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
