import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ReservationTablePayload } from "types/userOperations";

interface Option {
  value: string;
  label: string;
}
interface PriceProviderSelectorProps {
  options: Option[];
  values: ReservationTablePayload;
  handleFormChange: (key: string, value: string) => void;
}

const PriceProviderSelector = ({     
  options, 
  values,
  handleFormChange, 
}: PriceProviderSelectorProps) => {

  return (
    <CustomFormFieldContainer
      label="Fiyat Sağlayıcılar ">
      <Select
        options={options}
        value={options.find(
          (option) => option.value === values.fiyat_saglayici
        )}
        onChange={(selectedOption: Option) => {
          handleFormChange('fiyat_saglayici', selectedOption.value);
        }}
        placeholder="Fiyat Sağlayıcı Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default PriceProviderSelector;
