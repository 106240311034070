import { useSearchParams } from "react-router-dom";

import SuccessIcon from "assets/images/check.png";
import FailureIcon from "assets/images/fail.png";
import { CustomButton } from "Components/Custom/UI";

const CommissionPaymentResultPage = () => {
  const [searchParams] = useSearchParams();

  const successMessage = searchParams.get("mesaj");
  const failureMessage = searchParams.get("hata_mesaj");
  const paymentCode = searchParams.get("banka_cekim_kodu");
  const messageCode = searchParams.get("mesaj_kodu");

  const icon = paymentCode === "1" ? SuccessIcon : FailureIcon;
  const title = paymentCode === "1" ? "Ödeme Başarılı" : "Ödeme Başarısız";
  const message = messageCode === "1" ? successMessage : failureMessage;

  return (
    <div className="d-flex justify-content-center align-items-start">
      <div
        className="d-flex flex-column align-items-center gap-2 border bg-white rounded p-4"
        style={{ minWidth: 350, maxWidth: 600 }}
      >
        <img src={icon} alt="icon" width={96} height={96} />
        <p className="fw-semibold fs-20 mt-2">{title}</p>
        <p className="text-center">{message}</p>
        <CustomButton
          variant="primary"
          onClick={() => window.location.replace("/komisyon-odemesi")}
        >
          Geri Dön
        </CustomButton>
      </div>
    </div>
  );
};

export default CommissionPaymentResultPage;
