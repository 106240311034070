import CustomCollapse from "Components/Custom/UI/CustomCollapse/CustomCollapse";
import { CrmDashboardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";
import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface CallsDueLaterProps {
  values: CrmDashboardData;
}

const CallsDueLater = ({ values }: CallsDueLaterProps) => {
  const value = values.sonra_aranacaklar;
  const { tableData, tableColumns } = useHandleTable(value);
  return (
    <>
      <CustomCollapse
        title="Sonra Aranacaklar"
        bgColor={DASHBOARD_COLORS.purple}
        icon="ri-calendar-schedule-line"
      >
        <CustomReactTable tableData={tableData} tableColumns={tableColumns} />
      </CustomCollapse>
    </>
  );
};

export default CallsDueLater;
