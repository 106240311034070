import { useSelector } from "react-redux";
import { UserIPsInitialState } from "slices/userOperations/userIPs/reducer";

const useHandleTable = () => {
  const { userIPs } = useSelector(
    (state: { UserIPs: UserIPsInitialState }) => state.UserIPs
  );

  const tableData = userIPs?.map((user) => {
    return {
      dis_ip: user.dis_ip,
      kisiler: user.kisiler,
    };
  });

  const tableColumns = [
    {
      header: "IP",
      accessorKey: "dis_ip",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-start gap-2">
              Allow from <p className="fw-semibold">{value}</p>
            </div>
          </>
        );
      },
    },
    {
      header: "Kişiler",
      accessorKey: "kisiler",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div
              className="text-wrap"
              style={{ width: window.innerWidth > 1000 ? "900px" : "500px" }}
            >
              {value}
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
