import { CustomTab } from "Components/Custom/UI";
import ListAssignedHotels from "./ListAssignedHotels";
import ChangeHotelAssigneesForm from "./ChangeHotelAssigneesForm";
import AssignHotelsToUsersForm from "./AssignHotelsToUsersForm";

interface PersonnelHotelAssignmentsPageProps {
  isLoading: boolean;
}

interface Tab {
  id: number;
  title: string;
  content: React.ReactNode;
}

const PersonnelHotelAssignmentsPage = ({ isLoading }: PersonnelHotelAssignmentsPageProps) => {
  const tabs: Tab[] = [
    {
      id: 1,
      title: "Yeni Atama",
      content: <AssignHotelsToUsersForm isLoading={isLoading}/>,
    },
    {
      id: 2,
      title: "Personel Değişikliği",
      content: <ChangeHotelAssigneesForm isLoading={isLoading} />
    },
  ];

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <CustomTab tabs={tabs} />
        <ListAssignedHotels isLoading={isLoading} />
      </div>
    </>
  );
};

export default PersonnelHotelAssignmentsPage;
