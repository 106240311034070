import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getHotelsBySearchTerm } from "slices/hotelSearcBar/thunk";

const useHandleForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch: Dispatch<any> = useDispatch();

  const { searchResults, selectedHotel, isLoading } = useSelector(
    (state: any) => state.HotelSearchBar
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const searchOptions = document.getElementById(
      "search-close-options"
    ) as HTMLElement;
    const dropdown = document.getElementById("search-dropdown") as HTMLElement;

    const handleSearch = () => {
      if (searchTerm.length > 2) {
        dropdown.classList.add("show");
        searchOptions.classList.remove("d-none");

        dispatch(getHotelsBySearchTerm(searchTerm));
      } else {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    };

    document.body.addEventListener("click", (e: MouseEvent) => {
      if ((e.target as HTMLElement).getAttribute("id") !== "search-options") {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    });

    const timeoutId = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm, dispatch]);

  const resetSearchTerm = () => {
    setSearchTerm("");
  };

  return {
    results: searchResults,
    searchTerm,
    selectedHotel,
    isLoading,
    handleChangeInput,
    resetSearchTerm,
  };
};

export default useHandleForm;
