import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveHourlyDiscounts,
  resetHourlyDiscount,
  resetHourlyDiscounts,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_HOURLY_DISCOUNTS,
  ADD_HOURLY_DISCOUNT,
  UPDATE_HOURLY_DISCOUNT,
  DELETE_HOURLY_DISCOUNT,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { HourlyDiscountRequestPayload } from "types/DiscountsAndActions";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteSpecialDiscountPayload {
  saatlik_indirim_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getHourlyDiscounts =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetHourlyDiscounts());

      const response = await axios.get(GET_HOURLY_DISCOUNTS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveHourlyDiscounts(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addHourlyDiscount =
  (payload: HourlyDiscountRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddDiscountLoading(true));

      await axios.post(ADD_HOURLY_DISCOUNT, payload);

      successCallback();
      dispatch(setToastSuccess("Saatlik indirim eklendi."));
      dispatch(resetHourlyDiscount());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddDiscountLoading(false));
    }
  };

export const updateHourlyDiscount =
  (payload: HourlyDiscountRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDiscountLoading(true));

      await axios.post(UPDATE_HOURLY_DISCOUNT, payload);

      successCallback();
      dispatch(setToastSuccess("Saatlik indirim güncellendi."));
      dispatch(resetHourlyDiscount());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDiscountLoading(false));
    }
  };

export const deleteHourlyDiscount =
  (payload: DeleteSpecialDiscountPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteDiscountLoading(true));
      await axios.post(DELETE_HOURLY_DISCOUNT, payload);

      dispatch(setToastSuccess("Saatlik indirim silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteDiscountLoading(false));
    }
  };
