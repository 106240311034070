import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { AddWebSitePayload, WebSiteFormErrors } from "types/myWebSite";
import { handleChangeWebSiteInput } from "slices/myWebSite/listWebSites/reducer";

interface AddWebSiteDomainLinkProps {
  values: AddWebSitePayload;
  formErrors: WebSiteFormErrors;
  validateFormChange: (values: AddWebSitePayload) => void;
}

const AddWebSiteDomainLink = ({
  values,
  validateFormChange,
  formErrors,
}: AddWebSiteDomainLinkProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Yeni Web Site Domain Linki :"
        error={formErrors.domain_link}
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.domain_link}
          onChange={(e) => {
            dispatch(
              handleChangeWebSiteInput({
                domain_link: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              domain_link: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default AddWebSiteDomainLink;
