import { CustomFormContainer } from "Components/Custom/UI";
import AddGalleryImageButton from "./FormFields/AddGalleryImageButton";
import { AddGalleryImageFormErrors, GalleryImage } from "types/myWebSite";
import GalleryImageField from "./FormFields/GalleryImageField";

interface AddGalleryImageFormProps {
    values: GalleryImage;
    formErrors: AddGalleryImageFormErrors;
    validateFormChange: (values: GalleryImage) => void;
    validateForm: (values: GalleryImage) => { isValid: boolean };
    handleSubmitted: (isSubmitted: boolean) => void;
    onAddGalleryImage: () => void;
    addGaleryImageLoading: boolean;
}

const AddGalleryImageForm = ({
    values,
    formErrors,
    validateFormChange,
    validateForm,
    handleSubmitted,
    onAddGalleryImage,
    addGaleryImageLoading,
}: AddGalleryImageFormProps) => {

    return (
        <div className="mb-4 px-3">
            <CustomFormContainer title={`Galeri Resmi Ekle`} lg={12} xl={12}>
                <GalleryImageField
                    values={values}
                    formErrors={formErrors}
                    validateFormChange={validateFormChange}
                />
                <div className="d-flex justify-content-end">
                    <AddGalleryImageButton
                        values={values}
                        validateForm={validateForm}
                        handleSubmitted={handleSubmitted}
                        onAddGalleryImage={onAddGalleryImage}
                        addGaleryImageLoading={addGaleryImageLoading}
                    />
                </div>
            </CustomFormContainer>
        </div>
    );
};
export default AddGalleryImageForm;
