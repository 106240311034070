import { createSlice } from "@reduxjs/toolkit";

import { Theme, RecommendedHotel } from "types/themes";

export interface AssignRecommendedHotelsInitialState {
  themes: Theme[];
  hotels: RecommendedHotel[];
  selectedTheme: number;
  isLoading: boolean;
  error: string;
  addRecommendedHotelsLoading: boolean;
  addRecommendedHotelsError: string;
  getRecommendedHotelsLoading: boolean;
  getRecommendedHotelsError: string;
}

export const assignRecommendedHotelsInitialState: AssignRecommendedHotelsInitialState =
  {
    themes: [],
    hotels: [],
    selectedTheme: 0,
    isLoading: false,
    error: "",
    addRecommendedHotelsLoading: false,
    addRecommendedHotelsError: "",
    getRecommendedHotelsLoading: false,
    getRecommendedHotelsError: "",
  };

export const assignRecommendedHotelsSlice = createSlice({
  name: "assignRecommendedHotels",
  initialState: assignRecommendedHotelsInitialState,
  reducers: {
    handleSaveThemes(state, action) {
      state.themes = action.payload;
    },
    handleSaveHotels(state, action) {
      state.hotels = action.payload;
    },
    handleSaveSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddRecommendedHotelsLoading(state, action) {
      state.addRecommendedHotelsLoading = action.payload;
    },
    setAddRecommendedHotelsError(state, action) {
      state.addRecommendedHotelsError = action.payload;
    },
    setGetRecommendedHotelsLoading(state, action) {
      state.getRecommendedHotelsLoading = action.payload;
    },
    setGetRecommendedHotelsError(state, action) {
      state.getRecommendedHotelsError = action.payload;
    },
  },
});

export const {
  handleSaveThemes,
  handleSaveHotels,
  handleSaveSelectedTheme,
  setIsLoading,
  setError,
  setAddRecommendedHotelsLoading,
  setAddRecommendedHotelsError,
  setGetRecommendedHotelsLoading,
  setGetRecommendedHotelsError,
} = assignRecommendedHotelsSlice.actions;

export default assignRecommendedHotelsSlice.reducer;
