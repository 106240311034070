import { CustomLoadingPage } from "Components/Custom/UI";
import ListLocationsForm from "./ListLocationsForm";

interface ListLocationsPageProps {
  loading: boolean;
  error: string;
}

const ListLocationsPage = ({ loading }: ListLocationsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={loading} />
      <ListLocationsForm />
    </>
  );
};

export default ListLocationsPage;
