import AddFooterFormFields from "./AddFooterFormFields";
import useHandleForm from "./useHandleForm";
import ListFooterLinksTable from "./ListFooterLinksTable.tsx";

const FooterLinksForm = () => {
  const {
    addFooterLink,
    footerLinkFormErrors,
    addFooterRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading
  } = useHandleForm();

  return (
    <>
      <div className="px-4 mb-4">
        <AddFooterFormFields
          values={addFooterLink}
          formErrors={footerLinkFormErrors}
          requestPayload={addFooterRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={isLoading} 
          validateFormChange={validateFormChange}
        />
        <ListFooterLinksTable isLoading={isLoading}/>
      </div>
    </>
  );
};

export default FooterLinksForm;
