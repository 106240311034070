import { useSelector } from "react-redux";
import { AddPlaceToVisitInitialState } from "slices/definitions/addPlaceToVisit/reducer";
import AddPlaceToVisitForm from "./AddPlaceToVisitForm";

const AddPlaceToVisitPage = () => {
  const { isLoading } = useSelector(
    (state: { AddPlaceToVisit: AddPlaceToVisitInitialState }) =>
      state.AddPlaceToVisit
  );
  return (
    <>
      <AddPlaceToVisitForm isLoading={isLoading} />
    </>
  );
};

export default AddPlaceToVisitPage;
