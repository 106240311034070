import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface CrmSellerDescriptionProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const CrmSellerDescription = ({
  values,
  formErrors,
  validateFormChange,
}: CrmSellerDescriptionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="CRM Satış" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="CRM Satış Açıklama"
        error={formErrors.crm_satis_aciklama}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.crm_satis_aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                crm_satis_aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              crm_satis_aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default CrmSellerDescription;
