import { Row } from "reactstrap";

import { Hotel } from "types/PricesQuotas";
import QuotaEntryItem from "./QuotaEntryItem";
import QuotaEntryTableHeader from "./QuotaEntryItem/QuotaEntryTableHeader";
import SaveQuotasButton from "../SaveQuotasButton";

interface QuotaEntryContainerProps {
  data: Hotel;
  onSave: () => void;
  isLoading: boolean;
}
const QuotaEntryContainer = ({
  data,
  isLoading,
  onSave,
}: QuotaEntryContainerProps) => {
  return (
    <Row className="border border-2 border-white rounded overflow-hidden my-3">
      <QuotaEntryTableHeader room={data.odalar[0]} />
      {data.odalar.map((room, index) => (
        <QuotaEntryItem key={index} room={room} index={index} />
      ))}
      <SaveQuotasButton isLoading={isLoading} onSave={onSave} />
    </Row>
  );
};

export default QuotaEntryContainer;
