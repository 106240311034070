import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import {
  ChangeHotelAssigneeFormErrors,
  ChangeHotelAssigneePayload,
  UserInfo,
} from "types/userOperations";

interface NewPersonnelSelectorProps {
  values: ChangeHotelAssigneePayload;
  personnelFormChoices: UserInfo[];
  selectedUsersToChange: number[];
  handleSelectUser: (id: number) => void;
  formErrors: ChangeHotelAssigneeFormErrors;
  validateFormChange: (values: ChangeHotelAssigneePayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const NewPersonnelSelector = ({
  values,
  personnelFormChoices,
  selectedUsersToChange,
  handleSelectUser,
  formErrors,
  validateFormChange,
}: NewPersonnelSelectorProps) => {
  const personnelOptions: Option[] = personnelFormChoices.map((value) => ({
    value: String(value.id),
    label: value.ad_soyad,
  }));

  const selectedUserId = selectedUsersToChange[1];
  const selectedOption = personnelOptions.find(
    (option) => option.value === selectedUserId?.toString()
  );

  return (
    <CustomFormFieldContainer
      label={"Yeni Personel : "}
      error={formErrors.personel_id_yeni}
      required
      divider
    >
      <Select
        options={personnelOptions}
        value={selectedOption}
        onChange={(selected: Option) => {
          const personnelId = selected ? Number(selected.value) : 0;
          handleSelectUser(personnelId);
          validateFormChange({
            ...values,
            personel_id: [
              values.personel_id[0] || 0, 
              personnelId,
            ],
          });
        }}
        placeholder="Personel seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default NewPersonnelSelector;
