import { CustomFormContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Dispatch } from "redux";
import {
  handleChangePaymentTypes,
  hadleChangeUncancelableRate,
  handleChangePaymentStatus,
} from "slices/pricesAndQuota/roomSalesAndPaymentMethods/reducer";
import { RoomSalesAndPaymentMethodsState } from "types/PricesQuotas";

interface PaymentTypesProps {
  formValues: RoomSalesAndPaymentMethodsState;
  disableUncancelableRate: boolean | undefined;
}

const PaymentTypes = ({
  formValues,
  disableUncancelableRate,
}: PaymentTypesProps) => {
  const dispatch: Dispatch = useDispatch();

  const showNormal = formValues.odeme.normal !== undefined;
  const showKapida = formValues.odeme.kapida !== undefined;
  const showKaporali = formValues.odeme.kaporali !== undefined;
  const showKismi = formValues.odeme.kismi !== undefined;
  const showIptalEdilemez = formValues.odeme.iptal_edilemez !== undefined;

  return (
    <CustomFormContainer title="Odadaki Ödeme Yöntemleri">
      <div className="d-flex gap-5 justify-content-between align-items-center pe-5">
        <div className="d-flex gap-5 align-items-center">
          {showNormal && (
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={formValues.odeme.normal}
                  onChange={(e) =>
                    dispatch(
                      handleChangePaymentTypes({
                        normal: e.target.checked,
                      })
                    )
                  }
                />
                Acente Ödemeli
              </Label>
            </FormGroup>
          )}

          {showKapida && (
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={formValues.odeme.kapida}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    dispatch(
                      handleChangePaymentTypes({
                        kapida: e.target.checked,
                      })
                    );
                    if (checked) {
                      dispatch(
                        handleChangePaymentStatus({
                          kapida: false,
                        })
                      );
                    } else {
                      dispatch(
                        handleChangePaymentStatus({
                          kapida: undefined,
                        })
                      );
                    }
                  }}
                />
                Tesiste Ödemeli
              </Label>
            </FormGroup>
          )}
          {showKaporali && (
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={formValues.odeme.kaporali}
                  onChange={(e) => {
                    dispatch(
                      handleChangePaymentTypes({
                        kaporali: e.target.checked,
                      })
                    );

                    if (e.target.checked) {
                      dispatch(
                        handleChangePaymentStatus({
                          kaporali: false,
                        })
                      );
                    } else {
                      dispatch(
                        handleChangePaymentStatus({
                          kaporali: undefined,
                        })
                      );
                    }
                  }}
                />
                Kapora Ödemeli
              </Label>
            </FormGroup>
          )}
          {showKismi && (
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={formValues.odeme.kismi}
                  onChange={(e) => {
                    dispatch(
                      handleChangePaymentTypes({
                        kismi: e.target.checked,
                      })
                    );

                    if (e.target.checked) {
                      dispatch(
                        handleChangePaymentStatus({
                          kismi: false,
                        })
                      );
                    } else {
                      dispatch(
                        handleChangePaymentStatus({
                          kismi: undefined,
                        })
                      );
                    }
                  }}
                />
                Kısmi Ödemeli
              </Label>
            </FormGroup>
          )}
        </div>
        <div className="d-flex gap-3 align-items-center">
          {showIptalEdilemez && (
            <FormGroup check>
              <Label check className="text-nowrap">
                <Input
                  type="checkbox"
                  checked={formValues.odeme.iptal_edilemez}
                  onChange={(e) => {
                    dispatch(
                      handleChangePaymentTypes({
                        iptal_edilemez: e.target.checked,
                      })
                    );

                    if (e.target.checked) {
                      dispatch(
                        handleChangePaymentStatus({
                          iptal: false,
                        })
                      );
                    } else {
                      dispatch(
                        handleChangePaymentStatus({
                          iptal: undefined,
                        })
                      );
                    }
                  }}
                />
                İptal Edilemez
              </Label>
            </FormGroup>
          )}
          <InputGroup>
            <InputGroupText>%</InputGroupText>
            <Input
              style={{ width: "60px" }}
              type="number"
              value={formValues.oranlar.iptal}
              onChange={(e) =>
                dispatch(hadleChangeUncancelableRate(e.target.value))
              }
              disabled={disableUncancelableRate}
            />
          </InputGroup>
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default PaymentTypes;
