import { useSelector } from "react-redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleForm = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const specialDiscounts = calculatePriceForHotel.ozel_indirim;

  const moneyPoint = calculatePriceForHotel.parapuan_toplam_tutar;

  return { specialDiscounts, moneyPoint };
};

export default useHandleForm;
