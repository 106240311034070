import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
    handleSaveTeamsDataResponse, 
    setIsLoading, 
    setError,
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import { GET_PERITO_EXTENSION_NUMBERS} from "helpers/url_helper";
import { Dispatch } from "redux";



export const getPeritoExtensionNumbers =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_PERITO_EXTENSION_NUMBERS);
      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveTeamsDataResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

