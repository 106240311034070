import FacilityDescription from "./FacilityDescription";
import useHandleDetails from "./useHandleDetails";

const FacilityGeneralInfo = () => {
  const { generalInfo, descriptions } = useHandleDetails();

  return (
    <div className="d-flex flex-column gap-3 mt-3">
      {Object.entries(generalInfo).map(([key, value]) => (
        <div key={key} className="d-flex align-items-center gap-2">
          <span className="fw-medium text-muted fs-14 text-nowrap">
            {key}:{" "}
          </span>
          <span className="fw-semibold fs-15">{value}</span>
        </div>
      ))}
      {descriptions.map((description) => (
        <FacilityDescription
          key={description.title}
          title={description.title}
          description={description.description}
          features={description.features}
        />
      ))}
    </div>
  );
};

export default FacilityGeneralInfo;
