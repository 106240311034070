import { useDispatch } from "react-redux";
import { Fragment } from "react";
import { Dispatch } from "redux";
import { Input } from "reactstrap";
import { getFacilityRoom } from "slices/facility/facilityRooms/thunk";
import {
  CustomHorizontalSlidingBox,
  CustomRoomSelectorButton,
} from "Components/Custom/UI";

interface RoomSelectorProps {
  hotelRooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    selectedRoom: number;
    handleSelectRoom: (selectedRoom: number) => void;
  };
}

const RoomSelector = ({
  hotelRooms,
  hotelAndSelectedRoom,
}: RoomSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { selectedRoom, handleSelectRoom } = hotelAndSelectedRoom;

  return (
    <CustomHorizontalSlidingBox>
      {hotelRooms.map((room) => (
        <Fragment key={`option${room.oda_id}`}>
          <Input
            id={`option${room.oda_id}`}
            type="radio"
            className="btn-check"
            name="room-selector"
            value={room.oda_id}
            onChange={() => {
              handleSelectRoom(room.oda_id);
              dispatch(
                getFacilityRoom({
                  otel_id: room.otel_id,
                  oda_id: room.oda_id,
                })
              );
            }}
            checked={selectedRoom === room.oda_id}
          />
          <CustomRoomSelectorButton
            selected={selectedRoom === room.oda_id}
            label={room.oda_isim}
            htmlFor={`option${room.oda_id}`}
          />
        </Fragment>
      ))}
    </CustomHorizontalSlidingBox>
  );
};

export default RoomSelector;
