import { CustomTab } from "Components/Custom/UI";
import CategorizeFacilitiesForm from "./CategorizeFacilitiesForm";
import ChangeFacilityCategoryForm from "./ChangeFacilityCategoryForm";
import ListFacilitiesForm from "./ListFacilitiesForm";

interface CategorizeFacilityPageProps {
  isLoading: boolean;
}

interface Tab {
  id: number;
  title: string;
  content: React.ReactNode;
}

const CategorizeFacilityPage = ({ isLoading }: CategorizeFacilityPageProps) => {
  const tabs: Tab[] = [
    {
      id: 1,
      title: "Yeni Atama",
      content: <CategorizeFacilitiesForm isLoading={isLoading}/>,
    },
    {
      id: 2,
      title: "Sınıf Değişikliği",
      content: <ChangeFacilityCategoryForm isLoading={isLoading} />
    },
  ];

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <CustomTab tabs={tabs} />
        <ListFacilitiesForm isLoading={isLoading} />
      </div>
    </>
  );
};

export default CategorizeFacilityPage;