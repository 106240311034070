import { CustomRowBadge, CustomRowSpan } from "Components/Custom/UI";
import { BREAKING_DISCOUNTS } from "enums";
import { useSelector } from "react-redux";

import {
  BreakingDiscount,
  BreakingDiscountState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { breakingDiscounts } = useSelector(
    (state: any) => state.BreakingDiscounts
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const concepts = hotel.pansiyonlar;

  const tableData: BreakingDiscountState[] = breakingDiscounts?.map(
    (discount: BreakingDiscount) => ({
      grup_id: discount.grup_id,
      sondakika_tipi: discount.sondakika_tipi,
      indirim_orani: discount.indirim_orani,
      sondakika_aktiflik: discount.sondakika_aktiflik,
      tum_odalar: 1,
      secili_odalar: [],
      tum_pansiyonlar: discount.tum_otel_pansiyonlar,
      secili_pansiyonlar: discount.otel_pansiyonlar,
      tum_odeme_tipleri: discount.tum_odeme_tipleri,
      secili_odeme_tipleri: discount.odeme_tipleri,
    })
  );

  const tableColumns = [
    {
      header: "Durum",
      cell: (cell: any) => {
        const value = cell.getValue();
        return <CustomRowSpan condition={value === "1"} isStatus />;
      },
      accessorKey: "sondakika_aktiflik",
      enableColumnFilter: false,
    },
    {
      header: "Geçerlilik Günü",
      cell: (cell: any) => {
        const value = cell.getValue();
        const label =
          value === BREAKING_DISCOUNTS.BUGUN
            ? "Bugün"
            : value === BREAKING_DISCOUNTS.YARIN
            ? "Yarın"
            : value === BREAKING_DISCOUNTS.BUGUN_YARIN
            ? "Bugün ve Yarın"
            : value;
        return <span>{label}</span>;
      },
      accessorKey: "sondakika_tipi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      cell: (cell: any) => {
        const value = cell.getValue();
        return <span>%{value}</span>;
      },
      accessorKey: "indirim_orani",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_odeme_tipleri",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
