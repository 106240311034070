import { createSlice } from "@reduxjs/toolkit";

import { CompetitorAnalysisInfo } from "types/adsAndAnalyze";

export interface CompetitorAnalysisInfoInitialState {
  competitorAnalysisInfo: CompetitorAnalysisInfo[];
  isLoading: boolean;
  error: string;
  updateAnalysisLoading: boolean;
  updateAnalysisError: string;
}

const initialState: CompetitorAnalysisInfoInitialState = {
  competitorAnalysisInfo: [],
  isLoading: false,
  error: "",
  updateAnalysisLoading: false,
  updateAnalysisError: "",
};

const competitorAnalysisInfoSlice = createSlice({
  name: "competitorAnalysisInfo",
  initialState,
  reducers: {
    handleSaveCompetitorAnalysisInfo(state, action) {
      state.competitorAnalysisInfo = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateAnalysisLoading(state, action) {
      state.updateAnalysisLoading = action.payload;
    },
    setUpdateAnalysisError(state, action) {
      state.updateAnalysisError = action.payload;
    },
  },
});

export const {
  handleSaveCompetitorAnalysisInfo,
  setIsLoading,
  setError,
  setUpdateAnalysisLoading,
  setUpdateAnalysisError,
} = competitorAnalysisInfoSlice.actions;

export default competitorAnalysisInfoSlice.reducer;
