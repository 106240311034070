import MatchAdAndHotelForm from "./MatchAdAndHotelForm";
import MatchedHotelsTable from "./MatchedHotelsTable";
import UnmatchedAdGroupsTable from "./UnmatchedAdGroupsTable";
import UnmatchedHotelTable from "./UnmatchedHotelsTable";

interface AdsGroupsPageProps {
  isLoading: boolean;
  matchedHotelsLoading: boolean;
  unmatchedHotelsLoading: boolean;
  unmatchedAdsGroupsLoading: boolean;
  matchAdHotelLoading: boolean;
}

const AdsGroupsPage = ({
  isLoading,
  matchedHotelsLoading,
  unmatchedHotelsLoading,
  unmatchedAdsGroupsLoading,
  matchAdHotelLoading,
}: AdsGroupsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <MatchAdAndHotelForm
        isLoading={isLoading}
        matchAdHotelLoading={matchAdHotelLoading}
      />
      <MatchedHotelsTable isLoading={matchedHotelsLoading} />
      <UnmatchedHotelTable isLoading={unmatchedHotelsLoading} />
      <UnmatchedAdGroupsTable isLoading={unmatchedAdsGroupsLoading} />
    </div>
  );
};

export default AdsGroupsPage;
