import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import { CreateWebSiteState } from "types/myWebSite";
import useHandlePayload from "./useHandlePayload";
import { createWebSite, getWebSite } from "slices/myWebSite/createWebSite/thunk";

// ! useHandlePayload hookuna dönüp tekrar incele

interface CreateWebSiteButtonProps {
  values: CreateWebSiteState;
  requestPayload: FormData;
  validateForm: (values: CreateWebSiteState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const CreateWebSiteButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: CreateWebSiteButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { getPayload, otel_id } = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        createWebSite(otel_id, getPayload(), () => {
          dispatch(getWebSite(otel_id));
        })
      );
    }
  };


  return (
    <div
      style={{
        position: 'sticky',
        bottom: 50,
        zIndex: 100,
      }}
    >
      <div className="d-flex justify-content-end gap-4 mt-4">
        <CustomButton
          variant="success"
          onClick={onClick}
          isLoading={isLoading}
        >
          Web Site Oluştur
        </CustomButton>
      </div>
    </div>
  );
};

export default CreateWebSiteButton;
