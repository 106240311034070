import { UserProfilePage } from "Components/Custom";

import withRouter from "Components/Common/withRouter";

import { Router } from "types";
import PageContainer from "pages/PageContainer";

const UserProfile = (props: Router) => {
  return (
    <PageContainer title="Kullanıcı Profili">
      <UserProfilePage {...props} />
    </PageContainer>
  );
};

export default withRouter(UserProfile);
