import { createSlice } from "@reduxjs/toolkit";

export interface AllGiftsListInitialState {
  allGifts: any[];
  isLoading: boolean;
  error: string;
  success: string;
}

export const initialState: AllGiftsListInitialState = {
  allGifts: [],
  isLoading: false,
  error: "",
  success: "",
};

const allGiftsListSlice = createSlice({
  name: "allGiftsList",
  initialState,
  reducers: {
    handleSaveAllGifts(state, action) {
      state.allGifts = action.payload;
    },
    resetAllGifts(state) {
      state.allGifts = [];
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveAllGifts, resetAllGifts, setIsLoading, setError } =
  allGiftsListSlice.actions;

export default allGiftsListSlice.reducer;
