import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import ExtraInformationImage from "./ExtraInformationImage";
import ExtraInformationText from "./ExtraInformationText";
import ExtraInformationTitle from "./ExtraInformationTitle";

interface ExtraInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const ExtraInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: ExtraInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Ekstra Bilgiler" lg={12} xl={12}>
        <ExtraInformationTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <ExtraInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <ExtraInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default ExtraInformationFields;
