import { HotelSalesPage } from "Components/Custom/ReportsPages";
import useHotelSales from "Components/Hooks/reports/useHotelSales";
import PageContainer from "pages/PageContainer";

const HotelSales = () => {
  const { isLoading } = useHotelSales();
  return (
    <PageContainer title="Otel Satışları">
      <HotelSalesPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default HotelSales;
