import { Row } from "reactstrap";

import PriceEntryTableColumn from "./PriceEntryTableColumn";

import { Period, PriceType } from "types/PricesQuotas";

interface PriceEntryTableProps {
  period: Period;
  paymentMethods: string[];
  basePayMethod: boolean;
  priceTypes: PriceType[];
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  };
  otherPrices: any;
  paymentMethodsMultipliers: {
    [key: string]: string;
  };
}

const PriceEntryTable = ({
  period,
  paymentMethods,
  basePayMethod,
  priceTypes,
  notAllowedToEditPaymentMethods,
  otherPrices,
  paymentMethodsMultipliers,
}: PriceEntryTableProps) => {
  const notAllowedToEdit = Object.keys(notAllowedToEditPaymentMethods).includes(
    period.odeme_tipi
  );

  return (
    <Row className="overflow-y-auto flex-nowrap gap-2 ms-1">
      {period.sutunlar.map((column, index) => (
        <PriceEntryTableColumn
          key={index}
          period={period}
          paymentMethods={paymentMethods}
          isBasePayMethod={basePayMethod}
          payMethod={period.odeme_tipi}
          column={column}
          priceTypes={priceTypes}
          notAllowedToEdit={notAllowedToEdit}
          otherPrices={otherPrices}
          notAllowedToEditPaymentMethods={notAllowedToEditPaymentMethods}
          basePayMethod={basePayMethod}
          paymentMethodsMultipliers={paymentMethodsMultipliers}
        />
      ))}
    </Row>
  );
};

export default PriceEntryTable;
