import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  resetAfterAddNewLocation,
  setAddLocationLoading,
  setAddLocationError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_LOCATIONS, ADD_LOCATION } from "helpers/url_helper";

import { AddLocationRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

interface GetRequestPayload {
  parent_id: number;
  successCallback: (response: any) => void;
}

export const getLocations =
  ({ parent_id, successCallback }: GetRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_LOCATIONS, {
        params: {
          parent_id,
        },
      });
      successCallback(response.data || response);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addLocation =
  (payload: AddLocationRequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setAddLocationLoading(true));
      await axios.post(ADD_LOCATION, payload);

      dispatch(setToastSuccess("Lokasyon  eklendi."));
      dispatch(resetAfterAddNewLocation());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddLocationError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddLocationLoading(false));
    }
  };
