import { useSelector } from "react-redux";

import { EmployeeSalesAmountInitialState } from "slices/reports/employeeSalesAmount/reducer";

const useHandleTable = () => {
  const { employeeSalesAmount } = useSelector(
    (state: { EmployeeSalesAmount: EmployeeSalesAmountInitialState }) =>
      state.EmployeeSalesAmount
  );

  const tableData = employeeSalesAmount?.map((employeeSale) => ({
    "Grup Adı": employeeSale.grup_adi,
    "Grup ID": employeeSale.grup_id,
    "Yetkili ID": employeeSale.yetkili_id,
    uyeler: employeeSale.uyeler.map((uye) => ({
      "Grup ID": employeeSale.grup_id,
      "Grup Adı": employeeSale.grup_adi,
      "Yetkili ID": employeeSale.yetkili_id,
      "Üye ID": uye.uye_id,
      "Satıcı Adı": uye.ad_soyad,
      "Rezervasyon Sayısı": uye.rez_sayisi,
      "Toplam Tutar": uye.toplam_tutar,
      "Çalıştığı Gün Sayısı": uye.calistigi_gun_sayisi,
      "Basket Oranı": uye.basket_orani,
    })),
  }));

  const tableColumns = [
    {
      header: "Satıcı Adı",
      accessorKey: "Satıcı Adı",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Sayısı",
      accessorKey: "Rezervasyon Sayısı",
      cell: (cell: any) => <span>{cell.getValue() + " Rezervasyon"}</span>,
      enableColumnFilter: false,
    },
    {
      header: "Toplam Tutar",
      accessorKey: "Toplam Tutar",
      cell: (cell: any) => <span>{cell.getValue() + "₺"}</span>,
      enableColumnFilter: false,
    },
    {
      header: "Çalıştığı Gün Sayısı",
      accessorKey: "Çalıştığı Gün Sayısı",
      cell: (cell: any) => <span>{cell.getValue() + " Gün"}</span>,
      enableColumnFilter: false,
    },
    {
      header: "Basket Oranı",
      accessorKey: "Basket Oranı",
      cell: (cell: any) => <span>{"%" + cell.getValue()}</span>,
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
