import { useSelector } from "react-redux";

import { ForecastReportInitialState } from "slices/reports/forecastReport/reducer";
import { ForecastReportResponse } from "types/reports";
import { CHART_COLORS } from "utils/chartColors";

function formatData(response: ForecastReportResponse) {
  const formattedData = response.tarihler.map((date, index) => {
    return {
      name: date,
      "Toplam Kontenjan": response.toplam_kontenjan[index] || 0,
      "Kalan Kontenjan": response.kalan_kontenjan[index] || 0,
      "Rezervasyon Sayısı": response.rez_sayisi[index] || 0,
    };
  });

  return formattedData;
}

const colors = [
  CHART_COLORS.PRIMARY,
  CHART_COLORS.WARNING,
  CHART_COLORS.SUCCESS_BOLD,
];

const useHandleChart = () => {
  const { forecastReportResponse } = useSelector(
    (state: { ForecastReport: ForecastReportInitialState }) =>
      state.ForecastReport
  );

  const { genel_toplam, toplam_rez, toplam_kalan } = forecastReportResponse;

  const labels = ["Genel Toplam", "Toplam Rezervasyon", "Toplam Kalan"];
  const series = [genel_toplam, toplam_rez, toplam_kalan];
  const seriesColor = [
    CHART_COLORS.PRIMARY,
    CHART_COLORS.SUCCESS_BOLD,
    CHART_COLORS.WARNING,
  ];

  const formattedData = formatData(forecastReportResponse);

  return { formattedData, colors, series, labels, seriesColor };
};

export default useHandleChart;
