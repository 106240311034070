import { createSlice } from "@reduxjs/toolkit";
import { PeritoExtensionNumbersResponse } from "types/userOperations";

export interface PeritoExtensionNumbersState {
  teamsData: PeritoExtensionNumbersResponse;
  isLoading: boolean;
  error: string;
}

export const peritoExtensionNumbersState: PeritoExtensionNumbersState = {
  teamsData: {},
  isLoading: false,
  error: "",
};

const peritoExtensionNumbersSlice = createSlice({
  name: "peritoExtensionNumbers",
  initialState: peritoExtensionNumbersState,
  reducers: {
    handleSaveTeamsDataResponse(state, action) {
      state.teamsData = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveTeamsDataResponse, setIsLoading, setError } =
  peritoExtensionNumbersSlice.actions;
export default peritoExtensionNumbersSlice.reducer;
