import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleAddWebSiteFormErrors } from "slices/myWebSite/listWebSites/reducer";
import { setToastError } from "slices/toast/reducer";
import { AddWebSitePayload, WebSiteFormErrors } from "types/myWebSite";


const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddWebSitePayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: WebSiteFormErrors = {};

    if (!values.domain_link) {
      errors.domain_link = "Domain link boş bırakılamaz.";
    }
    
    if (!values.otel_id) {
      errors.otel_id = "Bir otel seçmelisiniz.";
    }

    dispatch(handleAddWebSiteFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddWebSitePayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};

export default useValidateForm;
