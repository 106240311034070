import { CustomButton } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import CitySelector from "./CitySelector";
import CategorySelector from "./CategorySelector";
import { Dispatch, SetStateAction } from "react";

interface CityAndPersonnelFilterFormProps {
  setShowTable: Dispatch<SetStateAction<boolean>>;
}
const CityAndPersonnelFilterForm = ({
  setShowTable,
}: CityAndPersonnelFilterFormProps) => {
  const {
    values,
    cityOptions,
    categoryOptions,
    handleFormChange,
    handleGet,
    handleClear,
  } = useHandleForm(setShowTable);

  return (
    <>
      <CitySelector
        options={cityOptions}
        handleFormChange={handleFormChange}
        values={values}
      />
      <CategorySelector
        options={categoryOptions}
        handleFormChange={handleFormChange}
        values={values}
      />
      <div className="d-flex justify-content-end gap-4 mt-4">
        <CustomButton variant="primary" disabled={false} onClick={handleGet}>
          Listeyi Getir
        </CustomButton>
        <CustomButton variant="danger" disabled={false} onClick={handleClear}>
          Temizle
        </CustomButton>
      </div>
    </>
  );
};

export default CityAndPersonnelFilterForm;
