import { CustomFormContainer } from "Components/Custom/UI";
import {
  AddCertificateFormErrors,
  AddCertificatePayload,
} from "types/myWebSite";
import CertificateTitle from "./CertificateTitle";
import AddCertificateButton from "./AddCertificateButton";
import CertificateImage from "./CertificateImage";

interface AddCertificateFormProps {
  values: AddCertificatePayload;
  requestPayload: FormData;
  formErrors: AddCertificateFormErrors;
  validateFormChange: (values: AddCertificatePayload) => void;
  validateForm: (values: AddCertificatePayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  addCertificateLoading: boolean;
}

const AddCertificateForm = ({
  values,
  requestPayload,
  formErrors,
  validateFormChange,
  validateForm,
  handleSubmitted,
  addCertificateLoading,
}: AddCertificateFormProps) => {
  return (
    <div className="mb-4 px-3">
      <CustomFormContainer title="Sertifika Ekle" lg={12} xl={12}>
        <CertificateTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CertificateImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <div className="d-flex justify-content-end">
          <AddCertificateButton
            values={values}
            requestPayload={requestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            addCertificateLoading={addCertificateLoading}
          />
        </div>
      </CustomFormContainer>
    </div>
  );
};

export default AddCertificateForm;
