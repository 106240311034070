import { createSlice } from "@reduxjs/toolkit";

import {
  UncancellableRoomRate,
  UncancellableRoomRateFormErrors,
  UncancellableRoomRateState,
} from "types/DiscountsAndActions";

export interface UncancellableRoomRatesInitialState {
  uncancellableRoomRates: UncancellableRoomRate;
  uncancellableRoomRate: UncancellableRoomRateState;
  uncancellableRoomRateFormErrors: UncancellableRoomRateFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addRateLoading: boolean;
  addRateError: string;
}

export const initialState: UncancellableRoomRatesInitialState = {
  uncancellableRoomRates: {
    iptal_oran: 0,
    iptal_odeme: "checked",
  },
  uncancellableRoomRate: {
    indirim_orani: "0",
    aktiflik_durum: "1",
    tum_odalara_uygulama_durum: "1",
  },
  uncancellableRoomRateFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addRateLoading: false,
  addRateError: "",
};

const uncancellableRoomRatesSlice = createSlice({
  name: "uncancellableRoomRates",
  initialState,
  reducers: {
    handleSaveUncancellableRoomRates(state, action) {
      state.uncancellableRoomRates = action.payload;
    },
    handleSaveUncancellableRoomRate(state, action) {
      state.uncancellableRoomRate = {
        indirim_orani: action.payload.iptal_oran || "0",
        aktiflik_durum: action.payload.iptal_odeme === "checked" ? "1" : "0",
        tum_odalara_uygulama_durum: "1",
      };
    },
    handleAddUncancellableRoomRateFormErrors(state, action) {
      state.uncancellableRoomRateFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.uncancellableRoomRate = {
        ...state.uncancellableRoomRate,
        ...action.payload,
      };
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddRateLoading(state, action) {
      state.addRateLoading = action.payload;
    },
    setAddRateError(state, action) {
      state.addRateError = action.payload;
    },
  },
});

export const {
  handleSaveUncancellableRoomRates,
  handleSaveUncancellableRoomRate,
  handleAddUncancellableRoomRateFormErrors,
  handleChangeInput,
  handleSelectRoom,
  setIsLoading,
  setError,
  setAddRateLoading,
  setAddRateError,
} = uncancellableRoomRatesSlice.actions;

export default uncancellableRoomRatesSlice.reducer;
