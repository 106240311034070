import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveCompetitorAnalysis,
  handleSaveCompetitorAnalysisWithPagination,
  setIsLoading,
  setError,
  handleSaveFacilityAvailabilities,
  handleUpdateHotelInfo,
  resetFacilityAvailabilities,
  setFacilityAvailabilityLoading,
  setFacilityAvailabilityError,
  setUpdateDescriptionLoading,
  setUpdateDescriptionError,
  setUpdateHotelInfoLoading,
  setUpdateHotelInfoError,
  setUpdateAdsStatusLoading,
  setUpdateAdsStatusError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_COMPETITOR_ANALYSIS,
  GET_COMPETITOR_ANALYSIS_FACILITY_AVAILABILITIES,
  UPDATE_COMPETITOR_ANALYSIS_HOTEL_DESCRIPTION,
  UPDATE_COMPETITOR_ANALYSIS_ADS_STATUS,
  UPDATE_COMPETITOR_ANALYSIS_HOTEL_INFO,
  UPDATE_EB_COMPETITOR_ANALYSIS_HOTEL_INFO,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { CompetitorAnalysisRequestParams } from "types/adsAndAnalyze";

interface SaveDescriptionRequestPayload {
  id?: number;
  otel_id?: number;
  reklam_data?: string;
  analiz_aciklama_data?: string;
}

interface UpdateAdsStatusRequestPayload {
  group_id: number;
  status: 0 | 1;
}

type SuccessCallback = () => void;

export const getCompetitorAnalysis =
  (
    params: CompetitorAnalysisRequestParams,
    successCallback?: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_COMPETITOR_ANALYSIS, { params });

      const res = response.data || response;

      successCallback && successCallback();
      dispatch(handleSaveCompetitorAnalysis(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getCompetitorAnalysisWithPagination =
  (params: CompetitorAnalysisRequestParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_COMPETITOR_ANALYSIS, { params });

      const res = response.data || response;

      dispatch(handleSaveCompetitorAnalysisWithPagination(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getFacilityAvailabilities =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setFacilityAvailabilityLoading(true));
      dispatch(resetFacilityAvailabilities());

      const response = await axios.get(
        GET_COMPETITOR_ANALYSIS_FACILITY_AVAILABILITIES + otel_id
      );

      const res = response.data || response;

      dispatch(handleSaveFacilityAvailabilities(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setFacilityAvailabilityError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setFacilityAvailabilityLoading(false));
    }
  };

export const updateDescription =
  (payload: SaveDescriptionRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDescriptionLoading(true));

      await axios.post(UPDATE_COMPETITOR_ANALYSIS_HOTEL_DESCRIPTION, payload);

      dispatch(setToastSuccess("Açıklama güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDescriptionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDescriptionLoading(false));
    }
  };

export const updateAdsStatus =
  (payload: UpdateAdsStatusRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateAdsStatusLoading(true));
      await axios.post(UPDATE_COMPETITOR_ANALYSIS_ADS_STATUS, payload);
      successCallback();

      dispatch(setToastSuccess("Reklam durumu güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setToastError(message));
        dispatch(setUpdateAdsStatusError(message));
      });
    } finally {
      dispatch(setUpdateAdsStatusLoading(false));
    }
  };

export const updateHotelInfo =
  (id: number, isEb: number) => async (dispatch: Dispatch) => {
    const url = isEb
      ? UPDATE_EB_COMPETITOR_ANALYSIS_HOTEL_INFO
      : UPDATE_COMPETITOR_ANALYSIS_HOTEL_INFO;

    try {
      dispatch(setUpdateHotelInfoLoading(true));

      const response = await axios.get(url + id);

      const res = response.data || response;

      dispatch(
        setToastSuccess(`Otel ${isEb ? "EB" : ""} bilgileri güncellendi.`)
      );
      dispatch(handleUpdateHotelInfo(res[0]));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateHotelInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateHotelInfoLoading(false));
    }
  };
