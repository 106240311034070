import { ChildrenPricing } from "types/reservation";

export const getRoomOptions = (roomTypes: []) => {
  return roomTypes.map((roomType: any) => ({
    value: roomType.id,
    label: roomType.oda_isim,
  }));
};

export const getConceptOptions = (concepts: []) => {
  return concepts.map((concept: any) => ({
    value: concept.id,
    label: concept.pansiyon_isim,
  }));
};

export const getSpecialAgeOptions = (specialAges: []) => {
  const options = specialAges.map((specialAge: any) => ({
    value: specialAge.yas_id,
    label: specialAge.ozel_yas_ismi,
  }));

  options.unshift({ value: 0, label: "Tüm Yaşlar" });

  return options;
};

export const getPriceTypeLabel = (priceType: string) => {
  let label = priceType + "";
  if (priceType.startsWith("y")) {
    const number = parseInt(priceType.slice(1));
    label = `${number} Yetişkin`;
  } else if (priceType.startsWith("c")) {
    const number = parseInt(priceType.slice(1));
    label = `${number} Çocuk`;
  } else if (priceType.startsWith("kb")) {
    label = "Kişi Başı";
  }
  return label;
};

export const generateCouponCode = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let result = "";
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertPricingStructure = (pricingStructure: string) => {
  if (pricingStructure === "") return [];
  else {
    const pricingStructureObject = JSON.parse(
      pricingStructure
    ) as ChildrenPricing;
    const firstChild =
      pricingStructureObject.d1childok === "1"
        ? `1. Çocuk: 0-${pricingStructureObject.cocuk1} yaş ücretsiz. ${pricingStructureObject.cocuk1ucretlimin} - ${pricingStructureObject.cocuk1ucretlimax} yaş ücretli.`
        : "";
    const secondChild =
      pricingStructureObject.d2childok === "1"
        ? `2. Çocuk: 0-${pricingStructureObject.cocuk2} yaş ücretsiz. ${pricingStructureObject.cocuk2ucretlimin} - ${pricingStructureObject.cocuk2ucretlimax} yaş ücretli.`
        : "";

    const thirdChild =
      pricingStructureObject.d3childok === "1"
        ? `3. Çocuk: 0-${pricingStructureObject.cocuk3} yaş ücretsiz. ${pricingStructureObject.cocuk3ucretlimin} - ${pricingStructureObject.cocuk3ucretlimax} yaş ücretli.`
        : "";

    const fourthChild =
      pricingStructureObject.d4childok === "1"
        ? `4. Çocuk: 0-${pricingStructureObject.cocuk4} yaş ücretsiz. ${pricingStructureObject.cocuk4ucretlimin} - ${pricingStructureObject.cocuk4ucretlimax} yaş ücretli.`
        : "";

    const result = [firstChild, secondChild, thirdChild, fourthChild].filter(
      (item) => item !== ""
    );

    return result;
  }
};

export const getConceptStatusMessages = (
  incomingMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[],
  priceStatus: boolean,
  quotaStatus: boolean,
  quotaMessage: string,
  minNightStatus: boolean,
  minNight: number
) => {
  const messages = [...incomingMessages];

  !priceStatus &&
    messages.push({
      status: true,
      message: "Fiyat Bilgisi Bulunamadı",
      description: "Bilgi için lütfen operasyonu arayınız.",
    });

  !quotaStatus &&
    messages.push({
      status: true,
      message: quotaMessage || "Kontenjan Yetersiz!",
      description: "Bilgi için lütfen operasyonu arayınız.",
    });

  minNightStatus &&
    messages.push({
      status: true,
      message: `Minimum ${minNight} gece!`,
      description: "Tarih aralığınızı kontrol ediniz.",
    });

  return messages;
};
