import { CustomLoadingPage } from "Components/Custom/UI";
import AddAdditionalPageForm from "./AddAdditionalPageForm";
import ListAdditionalPagesTable from "./ListAdditionalPagesTable";
import ManageGalleryImages from "./ManageGalleryImages";

interface AdditionalPagesProps {
  isLoading: boolean;
}

const AdditionalPages = ({
    isLoading
}: AdditionalPagesProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="px-4 mb-4">
        <AddAdditionalPageForm />
        <ListAdditionalPagesTable isLoading={isLoading} />
        <ManageGalleryImages/>
      </div>
    </>
  );
};

export default AdditionalPages;
