import {
    CustomFormFieldContainer,
    CustomFormEditor,
  } from "Components/Custom/UI";
  import { useDispatch } from "react-redux";
  import { Dispatch } from "redux";
  import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
  import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
  
  interface ActivityInformationTextProps {
      values: CreateWebSiteState;
      formErrors: CreateWebSiteFormErrors;
      validateFormChange: (values: CreateWebSiteState) => void;
  }
  
  const ActivityInformationText = ({
    values,
    formErrors,
    validateFormChange,
  }: ActivityInformationTextProps) => {
    const dispatch: Dispatch<any> = useDispatch();
  
    return (
      <CustomFormFieldContainer
        label="Aktivite Bilgileri Açıklaması : "
        error={formErrors.aktivite_bilgileri}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.aktivite_bilgileri}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                aktivite_bilgileri: data,
              })
            );
            validateFormChange({
              ...values,
              aktivite_bilgileri: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    );
  };
  
  export default ActivityInformationText;
  