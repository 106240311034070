import { CustomButton } from "Components/Custom/UI";
import { useState } from "react";
import {
  CreateRoomAddedRoom,
  CreateRoomFormValues,
  EnterReservationFormValues,
} from "types/reservation";
import CreateRoomFormFields from "./CreateRoomFormFields";
import useHandleForm from "./useHandleForm";

interface AddedRoomProps {
  formValues: EnterReservationFormValues;
  room: CreateRoomAddedRoom;
  index: number;
  onUpdatedAddedRooms: (id: number, room: CreateRoomFormValues) => void;
  onRemoveAddedRoom: (id: number, index: number) => void;
}

const AddedRoom = ({
  formValues,
  room,
  index,
  onUpdatedAddedRooms,
  onRemoveAddedRoom,
}: AddedRoomProps) => {
  const [showRoomInfo, setShowRoomInfo] = useState(false);
  const toggle = () => setShowRoomInfo((prev) => !prev);

  const { roomValues, handleChangeInput, handleChangeGuestsInput } =
    useHandleForm(room);

  const handleClick = () => {
    onUpdatedAddedRooms(room.id, roomValues);
    toggle();
  };

  return (
    <div key={index} className="d-flex flex-column gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <span className="fw-medium fs-14">{index + 1}. ODA</span>
        <div className="d-flex gap-5 align-items-center justify-content-between">
          <div className="d-flex gap-1">
            <span className="fs-14">Misafirler:</span>
            <span className="fw-medium fs-14">
              {room.adults} Yetişkin, {room.children} Çocuk
            </span>
          </div>
          <div className="d-flex gap-1">
            <span className="fs-14">Oda Tutarı:</span>
            <span className="fw-medium fs-14">
              {room.odeme_tipi === "kaporali"
                ? Math.ceil(
                    room.fiyatlar.indirimli_tutar - room.fiyatlar.maliyet_tutar
                  )
                : Math.ceil(room.price)}{" "}
              TL
            </span>
          </div>
          {room.odeme_tipi === "kismi" && (
            <div className="d-flex gap-1">
              <span className="fs-14">Kısmi Satış Tutarı:</span>
              <span className="fw-medium fs-14">
                {Math.ceil((room.price * room.kismi_odeme_orani) / 100)} TL
              </span>
            </div>
          )}
        </div>
        <div className="d-flex gap-2">
          <CustomButton
            variant="danger"
            onClick={() => onRemoveAddedRoom(room.id, index)}
          >
            Oda İptal
          </CustomButton>
          <CustomButton variant="info" onClick={toggle}>
            Oda Bilgileri
          </CustomButton>
        </div>
      </div>
      {showRoomInfo && (
        <CreateRoomFormFields
          title={`${index + 1}. Oda Bilgileri`}
          createRoomFormValues={roomValues}
          handleChangeInput={handleChangeInput}
          handleChangeGuestsInput={handleChangeGuestsInput}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default AddedRoom;
