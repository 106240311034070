import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ReservationCollectionPayload } from "types/userOperations";

interface Option {
  value: string;
  label: string;
  selected?: string;
}
interface PaymentStatusSelectorProps {
  options: Option[];
  values: ReservationCollectionPayload;
  handleFormChange: (key: string, value: string) => void;
}

const PaymentStatusSelector = ({
  options,
  values,
  handleFormChange,
}: PaymentStatusSelectorProps) => {
  const selectedOption =
    options.find(
      (option) => option.value === values.tahsilat_durum.toString()
    ) || options.find((option) => option.selected === "selected");

  return (
    <CustomFormFieldContainer label="">
      <Select
        options={options}
        value={selectedOption}
        onChange={(selectedOption: Option) => {
          handleFormChange("tahsilat_durum", selectedOption.value);
        }}
        placeholder=""
      />
    </CustomFormFieldContainer>
  );
};

export default PaymentStatusSelector;
