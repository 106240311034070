import { createSlice } from "@reduxjs/toolkit";

import { ReservationReview } from "types/reservation";

export interface ReviewReservationInitialState {
  reservation: ReservationReview;
  reservationOtherRooms: ReservationReview[];
  isLoading: boolean;
  error: string;
  actionLoading: boolean;
  actionError: string;
  sendConfirmationLoading: boolean;
  sendConfirmationError: string;
  sendVoucherLoading: boolean;
  sendVoucherError: string;
  getConfirmationFileLoading: boolean;
  getConfirmationFileError: string;
  getVoucherFileLoading: boolean;
  getVoucherFileError: string;
}

const initialState: ReviewReservationInitialState = {
  reservation: {
    rezervasyon_id: 0,
    rezervasyon_kodu: "",
    ana_rez_kod: "",
    satici_id: 0,
    otel_adi: "",
    otel_id: 0,
    oda_adi: "",
    oda_id: 0,
    pansiyon_adi: "",
    pansiyon_id: 0,
    giris_tarihi: "",
    cikis_tarihi: "",
    musteri_tc_kimlik: "",
    musteri_ad_soyad: "",
    musteri_cep: "",
    musteri_tel: "",
    musteri_eposta: "",
    toplam_tutar: "",
    kartla_odeme: "",
    havaleyle_odeme: "",
    fatura_unvani: "",
    fatura_adresi: "",
    vergi_dairesi: "",
    vergi_no: "",
    aciklama: "",
    request: "",
    request_acenta: "",
    konfirme_edildi_mi: "h",
    konfirme_alis_zamani: "",
    iptal_edildi_mi: "h",
    kayit_tarihi: "",
    musaitlik: "Hayır",
    musaitlik_aciklama: "",
    musaitlik_alan: 0,
    musaitlik_alis_zamani: "",
    rez_tipi: "",
    rez_durum: "",
    operator_firma: "",
    fatura_no: "",
    satis_operator: "",
    fiyat_hesaplama: "",
    karsilama_kodu: "",
    inet_no_show_durum: "0",
    iptal_sigortasi: "yok",
    satici_adi: "",
    ucretli_cocuk_durum: "",
    kaporali_satis_orani: 0,
    farkli_acenteden_satis_durum: "",
    uygulanan_indirimler: [],
    verilen_hediyeler: [],
    komisyon_faturali_rezervasyon: "0",
    fiyat_saglayici: "",
    fiyat_saglayici_confirm_durum: "",
    rezervasyon_giris_saat_farki: 0,
    fiyat_saglayici_iptal_talebi: "0",
    fiyat_saglayici_iptal_talebi_zamani: "",
    eski_rezervasyon: "0",
    inet_otel_kodu: "",
    otel_parapuan_orani: "",
    kismi_odeme_durum: "0",
    kismi_odeme_tarihi: "",
    alt_acente_satisi: "0",
    alt_acente_komisyon_tutar: "",
    kendisine_goster: 0,
    kart_goster: "h",
    misafirler: [],
    yetiskin_sayisi: 0,
    cocuk_sayisi: 0,
    kk: [],
    havale: [],
    nakit: [],
    tahsilat_bilgileri: null,
    muhasebe_bilgileri: {
      misafir_fatura_no: "",
      misafir_fatura_id: 0,
      muhasebe_tahsilat_onay: "0",
      toplam_tahsilat_tutar: "",
      toplam_otel_onaylanan_tahsilat_tutar: "",
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "TRY",
      kur: "TRY",
      toplam_cekilen_tutar: "",
      toplam_otel_brut_tutar: "",
      toplam_otel_indirimli_tutar: "",
      toplam_otel_cekilen_tutar: "",
      toplam_otel_maliyet_tutar: "",
      toplam_otel_maliyet_tutar_kur: "",
      toplam_otel_gercek_maliyet_tutar: "",
      toplam_otel_gercek_maliyet_tutar_kur: "",
      toplam_otel_komisyon_tutar: "",
      toplam_transfer_cekilen_tutar: "",
      toplam_transfer_maliyet_tutar: "",
      toplam_transfer_komisyon_tutar: "",
      otele_odeme_gunu: "",
      otel_fatura_no: "",
      otele_odeme_durum: "0",
      otele_odeme_tutar: "",
      cari_durum_result: "",
    },
    finans_bilgileri: {
      finans_id: 0,
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "TRY",
      brut_tutar: "",
      maliyet_tutar: "",
      indirimli_tutar: "",
      komisyon_tutar: "",
      indirim_tutar: "",
      iptal_sigortasi_tutar: "",
      odeme_tipi: "",
      kur: "TRY",
      kur_degeri: "",
      maliyet_tutar_kur: "",
      gercek_maliyet_tutar: "",
      gercek_maliyet_tutar_kur: "",
      fiyat_saglayici_gunluk_fiyatlar: "",
      fiyat_saglayici_rates: "",
      stop_durum: "",
      kismi_odeme_orani: "",
      fiyat_dusme: "",
      fiyat_dusme_bilgisi: "",
    },
  },
  reservationOtherRooms: [],
  isLoading: false,
  error: "",
  actionLoading: false,
  actionError: "",
  sendConfirmationLoading: false,
  sendConfirmationError: "",
  sendVoucherLoading: false,
  sendVoucherError: "",
  getConfirmationFileLoading: false,
  getConfirmationFileError: "",
  getVoucherFileLoading: false,
  getVoucherFileError: "",
};

const reviewReservationSlice = createSlice({
  name: "reviewReservation",
  initialState,
  reducers: {
    fetchReservationRequest: (state) => {
      state.isLoading = true;
    },
    fetchReservationSuccess: (state, action) => {
      state.isLoading = false;
      const [first, ...rest] = action.payload;
      state.reservation = first;
      state.reservationOtherRooms = rest;
    },
    fetchReservationFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setActionLoading: (state, action) => {
      state.actionLoading = action.payload;
    },
    setActionError: (state, action) => {
      state.actionError = action.payload;
    },
    // Send Confirmation
    sendConfirmationRequest: (state) => {
      state.sendConfirmationLoading = true;
    },
    sendConfirmationSuccess: (state) => {
      state.sendConfirmationLoading = false;
    },
    sendConfirmationFailure: (state, action) => {
      state.sendConfirmationLoading = false;
      state.sendConfirmationError = action.payload;
    },
    // Send Voucher
    sendVoucherRequest: (state) => {
      state.sendVoucherLoading = true;
    },
    sendVoucherSuccess: (state) => {
      state.sendVoucherLoading = false;
    },
    sendVoucherFailure: (state, action) => {
      state.sendVoucherLoading = false;
      state.sendVoucherError = action.payload;
    },

    // Get Confirmation File
    getConfirmationFileRequest: (state) => {
      state.getConfirmationFileLoading = true;
    },
    getConfirmationFileSuccess: (state) => {
      state.getConfirmationFileLoading = false;
    },
    getConfirmationFileFailure: (state, action) => {
      state.getConfirmationFileLoading = false;
      state.getConfirmationFileError = action.payload;
    },

    // Get Voucher File
    getVoucherFileRequest: (state) => {
      state.getVoucherFileLoading = true;
    },
    getVoucherFileSuccess: (state) => {
      state.getVoucherFileLoading = false;
    },
    getVoucherFileFailure: (state, action) => {
      state.getVoucherFileLoading = false;
      state.getVoucherFileError = action.payload;
    },
  },
});

export const {
  fetchReservationRequest,
  fetchReservationSuccess,
  fetchReservationFailure,
  setActionLoading,
  setActionError,
  sendConfirmationRequest,
  sendConfirmationSuccess,
  sendConfirmationFailure,
  sendVoucherRequest,
  sendVoucherSuccess,
  sendVoucherFailure,
  getConfirmationFileRequest,
  getConfirmationFileSuccess,
  getConfirmationFileFailure,
  getVoucherFileRequest,
  getVoucherFileSuccess,
  getVoucherFileFailure,
} = reviewReservationSlice.actions;

export default reviewReservationSlice.reducer;
