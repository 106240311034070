import React, { Dispatch } from "react";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer";

interface CommenterNameProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
}

const CommenterName = ({
  values,
  formErrors,
  validateFormChange,
}: CommenterNameProps) => {

  const dispatch: Dispatch<any> = useDispatch();
  
  return (
    <>
      <CustomFormFieldContainer
        label="Müşteri Adı - Soyadı"
        error={formErrors.musteri_adi}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.musteri_adi}
          onChange={(e) => {
            dispatch(
              handleChangeCommentInput({
                musteri_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              musteri_adi: e.target.value,
            });
          }}
          placeholder="Ad Soyad..."
        />
      </CustomFormFieldContainer>
    </>
  );
};
export default CommenterName;
