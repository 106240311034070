import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteFromEarlyReservationDiscountsWithId,
  handleSaveEarlyReservationDiscountForEdit,
} from "slices/discountsAndActions/earlyReservationDiscounts/reducer";
import { deleteEarlyReservationDiscount } from "slices/discountsAndActions/earlyReservationDiscounts/thunk";
import {
  EarlyReservationDiscount,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { earlyReservationDiscounts } = useSelector(
    (state: any) => state.EarlyReservationDiscounts
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    if (selectedRow) {
      dispatch(handleSaveEarlyReservationDiscountForEdit(selectedRow));
      window.scrollTo(0, 0);
    }
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      erken_rezervasyon_id: id,
    };
    dispatch(deleteEarlyReservationDiscount(payload) as any);
    dispatch(handleDeleteFromEarlyReservationDiscountsWithId(id));
  };

  const tableData: EarlyReservationDiscountState[] =
    earlyReservationDiscounts?.map((discount: EarlyReservationDiscount) => {
      return {
        id: discount.id,
        grup_id: discount.grup_id,
        satis_baslangic_tarihi: moment(
          discount.satis_baslangic_tarihi
        ).toDate(),
        satis_bitis_tarihi: moment(discount.satis_bitis_tarihi).toDate(),
        konaklama_baslangic_tarihi: moment(
          discount.konaklama_baslangic_tarihi
        ).toDate(),
        konaklama_bitis_tarihi: moment(
          discount.konaklama_bitis_tarihi
        ).toDate(),
        tum_gunler: discount.tum_gunler,
        gunler: JSON.parse(discount.gunler ?? "[]"),
        oran: discount.oran,
        gercek_indirim_orani: discount.gercek_indirim_orani,
        gostermelik_indirim_orani: discount.gostermelik_indirim_orani,
        minimum_gece: discount.minimum_gece,
        kumulatif_durum: discount.kumulatif_durum,
        otele_odeme_gunu: moment(discount.otele_odeme_gunu).toDate(),
        otele_odeme_orani: discount.otele_odeme_orani,
        tum_otel_pansiyonlar: discount.tum_otel_pansiyonlar,
        otel_pansiyonlar: discount.otel_pansiyonlar,
        tum_odeme_tipleri: discount.tum_odeme_tipleri,
        odeme_tipleri: discount.odeme_tipleri,
      };
    });

  const tableColumns = [
    {
      header: "Rezervasyon Tarihi",
      cell: (cell: any) => {
        return (
          <span>
            {moment(cell.row.original.satis_baslangic_tarihi).format(
              "DD.MM.YYYY"
            )}
            <br />
            {moment(cell.row.original.satis_bitis_tarihi).format("DD.MM.YYYY")}
          </span>
        );
      },
      accessorKey: "rez_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihi",
      cell: (cell: any) => {
        return (
          <span>
            {moment(cell.row.original.konaklama_baslangic_tarihi).format(
              "DD.MM.YYYY"
            )}
            <br />
            {moment(cell.row.original.konaklama_bitis_tarihi).format(
              "DD.MM.YYYY"
            )}
          </span>
        );
      },
      accessorKey: "konaklama_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Erken Rezervasyon İndirim Oranı",
      cell: (cell: any) => {
        return <span className="fw-medium">%{cell.getValue()}</span>;
      },
      accessorKey: "oran",
      enableColumnFilter: false,
    },
    {
      header: "Gerçek İndirim Oranı",
      cell: (cell: any) => {
        return <span className="fw-medium">%{cell.getValue()}</span>;
      },
      accessorKey: "gercek_indirim_orani",
      enableColumnFilter: false,
    },
    {
      header: "Göstermelik İndirim Oranı",
      cell: (cell: any) => {
        return <span className="fw-medium">%{cell.getValue()}</span>;
      },
      accessorKey: "gostermelik_indirim_orani",
      enableColumnFilter: false,
    },
    {
      header: "Minimum Gece",
      accessorKey: "minimum_gece",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_gunler",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days:
          | string
          | {
              [key: number]: string;
            }[] = Object.values(cell.getValue());

        const radio_gun = cell.row.original.tum_gunler;

        return (
          <span className="d-flex gap-2 flex-wrap">
            {radio_gun === "1" ? (
              <CustomRowBadge value="Tüm Günler" />
            ) : (
              days.map(
                (
                  day:
                    | string
                    | {
                        [key: number]: string;
                      },
                  index: number
                ) => (
                  <CustomRowBadge
                    key={index}
                    value={
                      typeof day === "string" ? day : Object.values(day)[0]
                    }
                  />
                )
              )
            )}
          </span>
        );
      },
      accessorKey: "gunler",
      enableColumnFilter: false,
    },
    {
      header: "Kümülatif Uygula",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "kumulatif_durum",
      enableColumnFilter: false,
    },
    {
      header: "Otele Ödeme Günü",
      cell: (cell: any) => {
        return <span>{moment(cell.getValue()).format("DD.MM.YYYY")}</span>;
      },
      accessorKey: "otele_odeme_gunu",
      enableColumnFilter: false,
    },
    {
      header: "Otele Ödeme Oranı",
      cell: (cell: any) => {
        return <span className="fw-medium">%{cell.getValue()}</span>;
      },
      accessorKey: "otele_odeme_orani",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "otel_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "operations",
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
