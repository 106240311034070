import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ResetReservationAmountTableProps {
  isLoading: boolean;
}

const ResetReservationAmountTable = ({ isLoading }: ResetReservationAmountTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return(
  <>
    <CustomReactTable
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
    />
  </>
  );
};
export default ResetReservationAmountTable;
