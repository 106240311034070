import { CustomButton, CustomFormFieldContainer } from "Components/Custom/UI";
import { useState } from "react";
import { Input } from "reactstrap";

interface CashPaymentInfoFormProps {
  paymentId?: string;
  cashInfo?: CashPaymentFormValues;
  remainingAmount: number;
  onAddPaymentMethod: (
    formValues: CashPaymentFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    onResetForm: () => void
  ) => void;
  onEditPaymentMethod?: (
    formValues: CashPaymentFormValues,
    paymentMethod: PaymentMethod,
    amount: number,
    paymentId: string
  ) => void;
  onRemoveSelectedPaymentMethod: () => void;
}

interface CashPaymentFormValues {
  paymentId: string;
  amount: number;
  description: string;
}

type PaymentMethod = "cash" | "creditCard" | "transfer";

const validateForm = (
  formValues: CashPaymentFormValues,
  remainingAmount: number
) => {
  const errors: {
    amount?: string;
    description?: string;
  } = {};
  if (!formValues.amount) {
    errors.amount = "Tutar girilmelidir";
  }

  if (Math.abs(formValues.amount) > remainingAmount) {
    // errors.amount = "Tutar kalan tutardan büyük olamaz.";
  }

  if (formValues.description.length < 3) {
    errors.description = "Açıklama en az 3 karakter olmalıdır.";
  }

  const isValid = Object.keys(errors).length === 0;

  return { errors, isValid };
};

const CashPaymentInfoForm = ({
  paymentId,
  cashInfo,
  remainingAmount,
  onAddPaymentMethod,
  onEditPaymentMethod,
  onRemoveSelectedPaymentMethod,
}: CashPaymentInfoFormProps) => {
  const [formValues, setFormValues] = useState<CashPaymentFormValues>({
    paymentId: cashInfo ? cashInfo.paymentId : "",
    amount: cashInfo ? cashInfo.amount : 0,
    description: cashInfo ? cashInfo.description : "",
  });

  const onInputChange = (key: string, value: string | number) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const onResetForm = () => {
    setFormValues({
      paymentId: "",
      amount: 0,
      description: "",
    });
  };

  const { errors, isValid } = validateForm(formValues, remainingAmount);

  return (
    <div className="d-flex flex-column gap-3 border rounded bg-white p-2">
      <span className="fw-bold">Nakit Ödeme Bilgileri</span>
      <span className="fw-medium fs-14 theme-text-danger">
        *İndirim Girilecek ise Tutarın başına EKSİ ( - ) konularak girilmeli,
        açıklamaya da İndirim olduğu belirtilmedir.
      </span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "1rem",
        }}
      >
        <CustomFormFieldContainer label="Tutar" error={errors.amount}>
          <Input
            type="number"
            placeholder="Tutar"
            value={formValues.amount}
            onChange={(e) => onInputChange("amount", Number(e.target.value))}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Açıklama" error={errors.description}>
          <Input
            type="text"
            placeholder="Açıklama"
            value={formValues.description}
            onChange={(e) => onInputChange("description", e.target.value)}
          />
        </CustomFormFieldContainer>
        <div>
          {paymentId ? (
            <CustomButton
              variant="success"
              onClick={() =>
                isValid &&
                onEditPaymentMethod &&
                onEditPaymentMethod(
                  formValues,
                  "cash",
                  formValues.amount,
                  paymentId
                )
              }
            >
              Ödemeyi Düzenle
            </CustomButton>
          ) : (
            <CustomButton
              variant="success"
              onClick={() =>
                isValid &&
                onAddPaymentMethod(
                  formValues,
                  "cash",
                  formValues.amount,
                  onResetForm
                )
              }
            >
              Nakit Ödeme Ekle
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default CashPaymentInfoForm;
