import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { InformationTechnologiesInitialState } from "slices/userOperations/informationTechnologies/reducer";
import { getReservationPaymentTable } from "slices/userOperations/informationTechnologies/thunk";


const useHandleForm = (setShowUpdateCollectionForm: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { InformationTechnologies: InformationTechnologiesInitialState }) =>
      state.InformationTechnologies
  );

  const [firstFormState, setFirstFormState] = useState({
    rez_kod: "",
  });

  const handleGetReservationPayment = (rez_kod: string) => {
    dispatch(
        getReservationPaymentTable(rez_kod, () => {
        setShowUpdateCollectionForm(true);
      })
    );
  };

  const handleFirstFormChange = (key: string, value: any) => {
    setFirstFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };



  return {
    firstFormState,
    isLoading,
    onGetReservation: handleGetReservationPayment,
    handleFirstFormChange,
  };
};

export default useHandleForm;
