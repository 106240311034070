import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { useSearchParams } from "react-router-dom";
import { OutboundCallsListWithNumberInitialState } from "slices/reports/outboundCallsListWithNumber/reducer";
import { getOutboundCallsWithNumber } from "slices/reports/outboundCallsListWithNumber/thunk";

const useOutboundCallsListWithNumber = () => {
  const [searchParams] = useSearchParams();
  const dahili = searchParams.get("dahili") ?? "";
  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const navigateRootPage = !dahili || !start_date || !end_date;

  const { isLoading } = useSelector(
    (state: {
      OutboundCallsListWithNumber: OutboundCallsListWithNumberInitialState;
    }) => state.OutboundCallsListWithNumber
  );

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!navigateRootPage) {
      const params = {
        start_date,
        end_date,
        dahili: parseInt(dahili),
      };

      dispatch(getOutboundCallsWithNumber(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateRootPage]);

  return {
    isLoading,
    navigateRootPage,
    startDate: start_date,
    endDate: end_date,
  };
};

export default useOutboundCallsListWithNumber;
