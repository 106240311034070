import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleAddGeneralPriceSettingFormErrors } from "slices/pricesAndQuota/generalPriceSettings/reducer";
import { setToastError } from "slices/toast/reducer";
import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
} from "types/PricesQuotas";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: GeneralPriceSettingFormState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: GeneralPriceSettingFormErrors = {};

    /// ...

    dispatch(handleAddGeneralPriceSettingFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: GeneralPriceSettingFormState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
