import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import Select from "react-select";
import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
} from "types/PricesQuotas";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeGeneralPriceSettingsInput } from "slices/pricesAndQuota/generalPriceSettings/reducer";
import { Input } from "reactstrap";

interface FacilityPaymentDateProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  facilityPaymentOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityPaymentDate = ({
  values,
  formErrors,
  validateFormChange,
  facilityPaymentOptions,
}: FacilityPaymentDateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Tesis Ödeme Günü">
      <CustomFormFieldContainer label="Tesise Ödeme Yapılacak Gün">
        <Select
          value={
            facilityPaymentOptions.find(
              (option) => option.value === values.odeme_tipi
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                odeme_tipi: selectedOption.value,
              })
            );

            validateFormChange({
              ...values,
              odeme_tipi: selectedOption.value as
                | "giriste"
                | "cikista"
                | "rez_gunu",
            });
          }}
          options={facilityPaymentOptions}
          placeholder="Ödeme Yapılacak Günü Seçiniz"
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Ödeme Gün Aralığı">
        <Input
          type="number"
          value={values.odeme_tipi_gunu}
          onChange={(e) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                odeme_tipi_gunu: parseInt(e.target.value),
              })
            );

            validateFormChange({
              ...values,
              odeme_tipi_gunu: parseInt(e.target.value),
            });
          }}
          placeholder="Ödeme Gün Aralığı"
          error={formErrors.odeme_tipi_gunu}
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityPaymentDate;
