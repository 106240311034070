import AddFacilityButton from "./AddFacilityButton";
import CrmDescriptionFields from "./CrmDescriptionFields";
import FacilityAccomodationFields from "./FacilityAccomodationFields";
import FacilityCalculationFields from "./FacilityCalculationFields";
import FacilityCompetitorAnalyse from "./FacilityCompetitorAnalyse";
import FacilityFoodFields from "./FacilityFoodFields";
import FacilityLocationFields from "./FacilityLocationFields";
import FacilityMediaFields from "./FacilityMediaFields";
import FacilityOperationalInfoFields from "./FacilityOperationalInfoFields";
import FacilityOtherFeaturesFields from "./FacilityOtherFeaturesFields";
import FacilityServicesFields from "./FacilityServicesFields";
import FacilitySwimmingFields from "./FacilitySwimmingFields";
import FacilityTheme from "./FacilityTheme";
import GeneralInfoFields from "./GeneralInfoFields";
import useHandleForm from "./useHandleForm";

const AddFacilityForm = () => {
  const {
    facilityStarsOptions,
    facilityFeaturesOptions,
    facilityChildrenOptions,
    facilityLocationOptions,
    facilityRoomsOptions,
    facilityFoodOptions,
    facilityServicesOptions,
    facilitySwimmingOptions,
    facilityThemeOptions,
    facilityThemeFeatureMatchOptions,
    facilityIslamicThemeOptions,
    addFacility,
    addFacilityRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm();

  return (
    <div className="d-flex flex-column gap-4">
      <GeneralInfoFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityStarsOptions={facilityStarsOptions}
        facilityFeaturesOptions={facilityFeaturesOptions}
        facilityChildrenOptions={facilityChildrenOptions}
      />
      <FacilityLocationFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityLocationOptions={facilityLocationOptions}
      />
      <FacilityAccomodationFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityRoomsOptions={facilityRoomsOptions}
      />
      <FacilityFoodFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityFoodOptions={facilityFoodOptions}
      />
      <FacilityServicesFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityServicesOptions={facilityServicesOptions}
      />
      <FacilitySwimmingFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilitySwimmingOptions={facilitySwimmingOptions}
      />
      <FacilityOtherFeaturesFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityTheme
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityThemeOptions={facilityThemeOptions}
        facilityThemeFeatureMatchOptions={facilityThemeFeatureMatchOptions}
        facilityIslamicThemeOptions={facilityIslamicThemeOptions}
      />
      <FacilityMediaFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityOperationalInfoFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityCalculationFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <CrmDescriptionFields
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilityCompetitorAnalyse
        values={addFacility}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddFacilityButton
        values={addFacility}
        requestPayload={addFacilityRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddFacilityForm;
