import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbs from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useState } from "react";
import useHandleGallery from "./useHandleGallery";

const PhotoGallery = () => {
  const [index, setIndex] = useState(-1);

  const handleChangeIndex = (index: number) => {
    setIndex(index);
  };

  const { galleryImages } = useHandleGallery();

  const photos = galleryImages.map((image, index) => ({
    src: image.resim_url,
    alt: `featured-image-${index}`,
    width: "250px",
    height: "162px",
  }));

  return (
    <div className="d-flex gap-2  justify-content-center flex-wrap w-100 ">
      {galleryImages.map((image, index) => (
        <img
          key={image.resim_id}
          src={image.resim_url}
          alt={`featured-${index}`}
          className="img-fluid cursor-pointer rounded border"
          style={{ width: "250px", height: "162px" }}
          onClick={() => handleChangeIndex(index)}
        />
      ))}
      <Lightbox
        slides={photos as any}
        open={index >= 0}
        index={index}
        close={() => handleChangeIndex(-1)}
        plugins={[Fullscreen, Zoom, Thumbs]}
      />
    </div>
  );
};

export default PhotoGallery;
