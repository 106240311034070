import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";

interface Option {
  value: string;
  label: string;
}

interface CategorySelectorProps {
  options: Option[];
  handleSelectCategory: (sinif: string) => void;
}

const CategorySelector = ({
  options,
  handleSelectCategory,
}: CategorySelectorProps) => {
  return (
    <CustomFormFieldContainer label={"Sınıflar : "} required divider>
      <Select
        options={options}
        onChange={(selected: Option) => {
          const sinif = selected ? selected.value : "";
          handleSelectCategory(sinif);
        }}
        placeholder="Sınıf seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default CategorySelector;
