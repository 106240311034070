import TransferReservationPage from 'Components/Custom/UserOperationsPages/TransferReservationPage';
import useTransferReservation from 'Components/Hooks/userOperations/useTransferReservation';
import PageContainer from 'pages/PageContainer';


const TransferReservation = () => {

    const { isLoading } = useTransferReservation();

  return (
    <PageContainer title="Rezervasyon Aktar">
        <TransferReservationPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default TransferReservation