import AddGroupPage from 'Components/Custom/UserOperationsPages/AddGroupPage';
import useAddGroup from 'Components/Hooks/userOperations/useAddGroup';
import PageContainer from 'pages/PageContainer';

const AddGroup = () => {

    const { isLoading } = useAddGroup();

  return (
    <PageContainer title="Grup Ekle">
        <AddGroupPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default AddGroup