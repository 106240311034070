import { Row, Spinner } from "reactstrap";
import { PaginationBottom as PaginationBottomProps } from "types/GlobalTypes";

interface IPaginationBottomProps extends PaginationBottomProps {
  loading?: boolean;
}
const PaginationBottom = ({
  total,
  current_page,
  per_page,
  prev_page_url,
  next_page_url,
  onClickNextPage = () => {},
  onClickPrevPage = () => {},
  loading = false,
}: IPaginationBottomProps) => {
  return (
    <>
      {total ? (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              <span className="fw-semibold mx-1">
                {per_page * (current_page - 1) + 1} -{" "}
                {per_page * current_page < total
                  ? per_page * current_page
                  : total}{" "}
                <span className="fw-normal">arası /</span>
              </span>
              <span className="fw-semibold mx-1">{total}</span>
              <span className="fw-normal">kayıt</span>
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 gap-2">
              <li
                className="page-item"
                style={{
                  cursor: !prev_page_url ? "not-allowed" : "pointer",
                }}
              >
                <span
                  className="page-link text-white"
                  style={{
                    opacity: !prev_page_url ? 0.7 : 1,
                    backgroundColor: "var(--theme-primary)",
                  }}
                  onClick={onClickPrevPage}
                >
                  Önceki
                </span>
              </li>
              <li className="page-item">
                <span
                  className={
                    current_page === total ? "page-link active" : "page-link"
                  }
                  style={{
                    border: "1px solid var(--theme-primary)",
                    userSelect: "none",
                  }}
                >
                  {loading ? <Spinner size="sm" /> : current_page}
                </span>
              </li>

              <li
                className="page-item"
                style={{
                  cursor: !next_page_url ? "not-allowed" : "pointer",
                }}
              >
                <span
                  className="page-link text-white"
                  style={{
                    backgroundColor: "var(--theme-primary)",
                    opacity: !next_page_url ? 0.7 : 1,
                  }}
                  onClick={onClickNextPage}
                >
                  Sonraki
                </span>
              </li>
            </ul>
          </div>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaginationBottom;
