import { WEEKDAYS } from "enums";

export const WEEKDAY_OPTIONS = [
  { value: WEEKDAYS.Pazartesi, label: "Pazartesi" },
  { value: WEEKDAYS.Salı, label: "Salı" },
  { value: WEEKDAYS.Çarşamba, label: "Çarşamba" },
  { value: WEEKDAYS.Perşembe, label: "Perşembe" },
  { value: WEEKDAYS.Cuma, label: "Cuma" },
  { value: WEEKDAYS.Cumartesi, label: "Cumartesi" },
  { value: WEEKDAYS.Pazar, label: "Pazar" },
];

export const DB_WEEKDAYS_OPTIONS = [
  {
    value: {
      "1": "Ptesi",
    },
    label: "Pazartesi",
  },
  {
    value: {
      "2": "Sali",
    },
    label: "Salı",
  },
  {
    value: {
      "3": "Carsamba",
    },
    label: "Çarşamba",
  },
  {
    value: {
      "4": "Persembe",
    },
    label: "Perşembe",
  },
  {
    value: {
      "5": "Cuma",
    },
    label: "Cuma",
  },
  {
    value: {
      "6": "Ctesi",
    },
    label: "Cumartesi",
  },
  {
    value: {
      "7": "Pazar",
    },
    label: "Pazar",
  },
];
