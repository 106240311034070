import { createSlice } from "@reduxjs/toolkit";
import {
  UnderTheDeskDiscount,
  UnderTheDeskDiscountFormErrors,
  UnderTheDeskDiscountState,
} from "types/DiscountsAndActions";

import moment from "moment";

export interface UnderTheDeskDiscountsInitialState {
  underTheDeskDiscounts: UnderTheDeskDiscount[];
  underTheDeskDiscount: UnderTheDeskDiscountState;
  underTheDeskDiscountFormErrors: UnderTheDeskDiscountFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
  updateDiscountLoading: boolean;
  updateDiscountError: string;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string;
}

export const initialState: UnderTheDeskDiscountsInitialState = {
  underTheDeskDiscounts: [],
  underTheDeskDiscount: {
    id: 0,
    grup_id: 0,
    her_zaman: "0",
    satis_baslangic_tarih: moment().format("YYYY-MM-DD"),
    satis_bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
    baslangic_tarih: moment().format("YYYY-MM-DD"),
    bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
    oran: "0",
    tum_otel_pansiyonlar: "1",
    otel_pansiyonlar: [],
    tum_odeme_tipleri: "1",
    odeme_tipleri: [],
  },
  underTheDeskDiscountFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
  updateDiscountLoading: false,
  updateDiscountError: "",
  deleteDiscountLoading: false,
  deleteDiscountError: "",
};

const underTheDeskDiscountsSlice = createSlice({
  name: "underTheDeskDiscounts",
  initialState,
  reducers: {
    handleSaveUnderTheDeskDiscounts(state, action) {
      state.underTheDeskDiscounts = action.payload;
    },
    handleDeleteFromUnderTheDeskDiscountsWithId(state, action) {
      state.underTheDeskDiscounts = state.underTheDeskDiscounts.filter(
        (discount) => discount.id !== action.payload
      );
    },
    handleSaveUnderTheDeskDiscountForEdit(state, action) {
      state.underTheDeskDiscount = action.payload;
    },
    handleChangeInput(state, action) {
      state.underTheDeskDiscount = {
        ...state.underTheDeskDiscount,
        ...action.payload,
      };
    },
    handleAddUnderTheDeskDiscountFormErrors(state, action) {
      state.underTheDeskDiscountFormErrors = action.payload;
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetUnderTheDeskDiscounts(state) {
      state.underTheDeskDiscounts = [];
    },
    resetUnderTheDeskDiscount(state) {
      state.underTheDeskDiscount = {
        id: 0,
        grup_id: 0,
        her_zaman: "0",
        satis_baslangic_tarih: moment().format("YYYY-MM-DD"),
        satis_bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
        baslangic_tarih: moment().format("YYYY-MM-DD"),
        bitis_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
        oran: "0",
        tum_otel_pansiyonlar: "1",
        otel_pansiyonlar: [],
        tum_odeme_tipleri: "1",
        odeme_tipleri: [],
      };
    },
    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateDiscountLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateDiscountError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteDiscountLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteDiscountError = action.payload;
    },
  },
});

export const {
  handleSaveUnderTheDeskDiscounts,
  handleDeleteFromUnderTheDeskDiscountsWithId,
  handleSaveUnderTheDeskDiscountForEdit,
  handleChangeInput,
  handleAddUnderTheDeskDiscountFormErrors,
  handleSelectRoom,
  setIsLoading,
  setError,
  resetUnderTheDeskDiscounts,
  resetUnderTheDeskDiscount,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} = underTheDeskDiscountsSlice.actions;

export default underTheDeskDiscountsSlice.reducer;
