import { createSlice } from "@reduxjs/toolkit";

import {
  AddHotelFeatureFormErrors,
  AddHotelFeatureState,
} from "types/definitions";

export interface AddHotelFeatureInitialState {
  hotelFeature: AddHotelFeatureState;
  addHotelFeatureFormErrors: AddHotelFeatureFormErrors;
  isLoading: boolean;
  error: string;
}

export const addHotelFeatureInitialState: AddHotelFeatureInitialState = {
  hotelFeature: {
    ozellik_adi: "",
  },
  addHotelFeatureFormErrors: {},
  isLoading: false,
  error: "",
};

export const addHotelFeatureSlice = createSlice({
  name: "addHotelFeature",
  initialState: addHotelFeatureInitialState,
  reducers: {
    handleChangeInput(state, action) {
      state.hotelFeature = {
        ...state.hotelFeature,
        ...action.payload,
      };
    },
    handleAddAddHotelFeatureFormErrors(state, action) {
      state.addHotelFeatureFormErrors = action.payload;
    },
    resetAfterAddNewHotelFeature(state) {
      state.hotelFeature = {
        ozellik_adi: "",
      };
      state.addHotelFeatureFormErrors = {};
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleChangeInput,
  handleAddAddHotelFeatureFormErrors,
  resetAfterAddNewHotelFeature,
  setIsLoading,
  setError,
} = addHotelFeatureSlice.actions;

export default addHotelFeatureSlice.reducer;
