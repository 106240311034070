import { useSelector } from "react-redux";
import { DailyReservationsInitialState } from "slices/reports/dailyReservations/reducer";
import { LoginInitialState } from "slices/auth/login/reducer";

import moment from "moment";
import { CustomTooltip } from "Components/Custom/UI";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const { dailyReservations, dailyReservationsResponse } = useSelector(
    (state: { DailyReservations: DailyReservationsInitialState }) =>
      state.DailyReservations
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki } = user;

  const dataKeyMatch: { [key: string]: string } = {
    rezervasyon_kodu: "Rez Kodu",
    kayit_tarihi: "Satış Tarihi",
    giris_tarihi: "Giriş Tarihi",
    cikis_tarihi: "Çıkış Tarihi",
    musteri_ad_soyad: "Müşteri",
    otel_adi: "Otel",
    toplam_tutar: "Rez Tutar",
    cekilen: "Çekilen",
    grup: "Grup",
    personel: "Personel",
    musaitlik: "Müsaitlik",
    durum: "Onay",
    operator_firma: "Operatör",
  };

  const hiddenColumns =
    dailyReservationsResponse.gosterilmeyecek_alanlar[yetki] ?? [];

  const columnVisibility: { [key: string]: boolean } = {};

  hiddenColumns.forEach((column) => {
    columnVisibility[dataKeyMatch[column]] = false;
  });

  const rowColors = {
    girisyapmadi: TABLE_COLORS.rose,
    success: TABLE_COLORS.green,
    kaporalisatis: TABLE_COLORS.purple,
    bugunrez: TABLE_COLORS.orange,
    kapidaodeme: TABLE_COLORS.blue,
    online: TABLE_COLORS.teal,
    warning: TABLE_COLORS.yellow,
    danger: TABLE_COLORS.red,
  };

  const tableData = dailyReservations?.map((dailyReservation) => ({
    "Rez Kodu":
      (dailyReservation.ana_rez_kodu ? dailyReservation.ana_rez_kodu : "") +
      " - " +
      dailyReservation.rezervasyon_kodu,
    "Satış Tarihi": moment(dailyReservation.kayit_tarihi).format(
      "DD.MM.YYYY HH:mm"
    ),
    "Giriş Tarihi": moment(dailyReservation.giris_tarihi).format("DD.MM.YYYY"),
    "Çıkış Tarihi": moment(dailyReservation.cikis_tarihi).format("DD.MM.YYYY"),
    Müşteri: dailyReservation.musteri_ad_soyad,
    Otel: dailyReservation.otel_adi,
    "Rez Tutar": dailyReservation.toplam_tutar,
    Çekilen: dailyReservation.cekilen,
    Grup: dailyReservation.grup,
    Personel: dailyReservation.personel,
    Müsaitlik: dailyReservation.müsaitlik,
    Onay: dailyReservation.durum,
    Operatör:
      dailyReservation.operator_firma +
      " - " +
      dailyReservation.operator_rez_kodu,
    rowColor: rowColors[dailyReservation.renk as keyof typeof rowColors],
  }));

  const tableColumns = [
    {
      header: "Rez.Kodu",
      accessorKey: "Rez Kodu",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tarihi",
      accessorKey: "Satış Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "Giriş Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "Çıkış Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Müşteri",
      accessorKey: "Müşteri",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "Otel",
      enableColumnFilter: false,
    },
    {
      header: "Rez.Tutar",
      accessorKey: "Rez Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Çekilen",
      accessorKey: "Çekilen",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "Grup",
      enableColumnFilter: false,
    },
    {
      header: "Personel",
      accessorKey: "Personel",
      enableColumnFilter: false,
    },
    {
      header: "Müsaitlik",
      accessorKey: "Müsaitlik",
      enableColumnFilter: false,
    },
    {
      header: "Onay",
      accessorKey: "Onay",
      enableColumnFilter: false,
    },
    {
      header: "Operatör",
      accessorKey: "Operatör",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const title = value.split(" - ")[0];
        const code = value.split(" - ")[1];
        return (
          <CustomTooltip
            target={"operator-tooltip-" + cell.row.index}
            title={title}
            message={code}
          />
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.rose,
      label: "Giriş Yapmadı",
    },
    {
      color: TABLE_COLORS.green,
      label: "Success",
    },
    {
      color: TABLE_COLORS.purple,
      label: "Kapora Satış",
    },
    {
      color: TABLE_COLORS.orange,
      label: "Bugün Rez",
    },
    {
      color: TABLE_COLORS.blue,
      label: "Kapıda Ödeme",
    },
    {
      color: TABLE_COLORS.teal,
      label: "Online",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Warning",
    },
    {
      color: TABLE_COLORS.red,
      label: "Danger",
    },
  ];

  return {
    tableData,
    tableColumns,
    columnVisibility,
    legend,
  };
};

export default useHandleTable;
