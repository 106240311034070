import { CustomPageDescriptions } from "Components/Custom/UI";
import { LIST_COMMENTS_DESCRIPTION } from "utils/pageDescriptions";
import DateSelector from "./DateSelector";
import ListCommentsTable from "./ListCommentsTable";
import CommenterSelector from "./CommenterSelector/CommenterSelector";

interface Option {
  value: string;
  label: string;
}

interface ListCommentsPageProps {
  isLoading: boolean;
  commenterOptions: Option[];
}

const ListCommentsPage = ({
  isLoading,
  commenterOptions,
}: ListCommentsPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <CustomPageDescriptions descriptions={LIST_COMMENTS_DESCRIPTION} />
        <div className="d-flex justify-content-between gap-8 align-items-center">
          <DateSelector />
          <CommenterSelector commenterOptions={commenterOptions} />
        </div>
        <ListCommentsTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListCommentsPage;
