import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { useState } from "react";
import { CATEGORIZE_FACILITY_DESCRIPTION } from "utils/pageDescriptions";
import useHandleForm from "./useHandleForm";
import CitySelector from "./FormFields/CitySelector";
import FacilityList from "./FormFields/FacilityList";
import CategorySelectBoxes from "./FormFields/CategorySelectBoxes";
import AssignHotelsToUserButtons from "./FormFields/AssignHotelsToUserButtons";

interface CategorizeFacilitiesFormProps {
  isLoading: boolean;
}

const CategorizeFacilitiesForm = ({
  isLoading,
}: CategorizeFacilitiesFormProps) => {
  const [selectedCityId, setSelectedCityId] = useState(0);

  const {
    values,
    cityOptions,
    facilityOptions,
    categoryOptions,
    handleFormChange,
    handleSelectCity,
    handleSave,
    handleClearForm,
  } = useHandleForm(setSelectedCityId);

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div style={{ padding: "8px 16px" }}>
        <CustomPageDescriptions
          variant="danger"
          descriptions={CATEGORIZE_FACILITY_DESCRIPTION}
        />
        <CitySelector
          options={cityOptions}
          handleSelectCity={handleSelectCity}
          selectedCityId={selectedCityId}
        />
        {selectedCityId !== 0 ? (
          <>
            <FacilityList
              values={values}
              options={facilityOptions}
              handleFormChange={handleFormChange}
            />
            <CategorySelectBoxes
              values={values}
              options={categoryOptions}
              handleFormChange={handleFormChange}
            />
            <AssignHotelsToUserButtons
              handleSave={handleSave}
              handleClearForm={handleClearForm}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CategorizeFacilitiesForm;
