import PersonnelRequestTable from "./PersonnelRequestTable"

interface PersonnelRequestPageProps {
    isLoading: boolean;
}

const PersonnelRequestPage = ({ isLoading }: PersonnelRequestPageProps) => {
    return (
        <div className="d-flex flex-column gap-4">
            <PersonnelRequestTable isLoading={isLoading} />
        </div>
    );
};

export default PersonnelRequestPage;
