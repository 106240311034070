import UserAuthoritiesPage from 'Components/Custom/UserOperationsPages/UserAuthoritiesPage';
import useUserAuthorities from 'Components/Hooks/userOperations/useUserAuthorities';
import PageContainer from 'pages/PageContainer';

const UserAuthorities = () => {

    const props = useUserAuthorities();

  return (
    <PageContainer title="Yetkiler">
      <UserAuthoritiesPage {...props}  />
    </PageContainer>
  )
}

export default UserAuthorities