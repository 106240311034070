import { Col, Label, Row } from "reactstrap";

import { CustomAvailabilityRadioInput } from "Components/Custom/UI";
import { STOPS } from "enums";

interface ColumnAvailabilityFieldProps {
  payMethod: string;
  fieldName: string;
  values: any;
  onChange: (name: string, availability: STOPS) => void;
  notAllowedToEdit: boolean;
}

const ColumnAvailabilityField = ({
  payMethod,
  values,
  onChange,
  fieldName,
  notAllowedToEdit,
}: ColumnAvailabilityFieldProps) => {
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <Label>Müsaitlik</Label>
      </Col>
      <Col xs={6}>
        <div className="d-flex gap-1">
          <CustomAvailabilityRadioInput
            name={`${payMethod}-${fieldName}`}
            value={STOPS.SATISA_ACIK}
            availability={parseInt(values[fieldName])}
            onChange={(availability: STOPS) =>
              onChange(fieldName, availability)
            }
            disabled={true}
            notAllowed={notAllowedToEdit}
          />
          <CustomAvailabilityRadioInput
            name={`${payMethod}-${fieldName}`}
            value={STOPS.SOR_SAT}
            availability={parseInt(values[fieldName])}
            onChange={(availability: STOPS) =>
              onChange(fieldName, availability)
            }
            disabled={true}
            notAllowed={notAllowedToEdit}
          />
          <CustomAvailabilityRadioInput
            name={`${payMethod}-${fieldName}`}
            value={STOPS.STOPLU}
            availability={parseInt(values[fieldName])}
            onChange={(availability: STOPS) =>
              onChange(fieldName, availability)
            }
            disabled={true}
            notAllowed={notAllowedToEdit}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ColumnAvailabilityField;
