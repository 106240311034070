import NotificationsPage from 'Components/Custom/UserOperationsPages/NotificationsPage';
import useListNotifications from 'Components/Hooks/userOperations/useListNotifications';
import PageContainer from 'pages/PageContainer';

const Notifications = () => {

    const { isLoading } = useListNotifications();

  return (
    <PageContainer title="Bildirimler">
      <NotificationsPage isLoading={isLoading} />
    </PageContainer>
  )
}

export default Notifications