import AvailabilityInput from "./AvailabilityInput";

import { Room } from "types/PricesQuotas";
import { useSelector } from "react-redux";
import { BasicAvailabilityEntryInitialState } from "slices/pricesAndQuota/basicAvailabilityEntry/reducer";
import RowContainer from "Components/Custom/PricesAndQuotaPages/CommonContainers/RowContainer";

interface AvailabilityEntryItemProps {
  room: Room;
  index: number;
  allSelected: AllSelected;
}

interface AllSelected {
  [key: number]: boolean;
}

const AvailabilityEntryItem = ({
  room,
  index,
  allSelected,
}: AvailabilityEntryItemProps) => {
  const { changedAvailabilities } = useSelector(
    (state: { BasicAvailabilityEntry: BasicAvailabilityEntryInitialState }) =>
      state.BasicAvailabilityEntry
  );

  const findAvailability = (oda_id: number, pansiyon_id: number) => {
    const availability = changedAvailabilities.find(
      (availability) =>
        availability.oda_id === oda_id &&
        availability.pansiyon_id === pansiyon_id
    );

    return availability
      ? availability
      : { oda_id: oda_id, pansiyon_id: pansiyon_id, durum: 0 };
  };

  return (
    <RowContainer title={room.oda_isim} index={index}>
      {room.pansiyonlar
        .filter((c) => c.durum === "1")
        .map((concept, index) => (
          <div
            key={index}
            className="d-flex flex-column"
            style={{ width: "200px" }}
          >
            <AvailabilityInput
              name={`${room.id}-${concept.id}`}
              allSelected={allSelected}
              defaultValue={findAvailability(room.id, concept.id) as any}
            />
          </div>
        ))}
    </RowContainer>
  );
};

export default AvailabilityEntryItem;
