import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface CustomFormEditorProps {
  editorData: string;
  handleGetEditorData: (data: string) => void;
}

const CustomFormEditor = ({
  editorData,
  handleGetEditorData,
}: CustomFormEditorProps) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["link"], // link
  ];

  return (
    <ReactQuill
      value={editorData}
      onChange={(value) => {
        handleGetEditorData(value);
      }}
      modules={{
        toolbar: toolbarOptions,
      }}
      style={{ backgroundColor: "white" }}
    />
  );
};

export default CustomFormEditor;
