import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { UpdateGroupInitialState } from "slices/userOperations/updateGroup/reducer";
import { getGroupToUpdate } from "slices/userOperations/updateGroup/thunk";

const useUpdateGroup = () => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const { isLoading, error } = useSelector(
    (state: { UpdateGroup: UpdateGroupInitialState }) => state.UpdateGroup
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId  = queryParams.get("grup_id");

  const parsedGroupId = groupId ? parseInt(groupId, 10) : 0;

  useEffect(() => {
    if (parsedGroupId) {
      dispatch(getGroupToUpdate(parsedGroupId, () => {}));
    }
  }, [dispatch, parsedGroupId]);

  return {
    isLoading,
    error,
  };
};

export default useUpdateGroup;
