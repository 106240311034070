import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { CallsFromGeneralInitialState } from "slices/reports/callsFromGeneral/reducer";
import { getCallsFromGeneral } from "slices/reports/callsFromGeneral/thunk";

const useCallsFromGeneral = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { CallsFromGeneral: CallsFromGeneralInitialState }) =>
      state.CallsFromGeneral
  );

  useEffect(() => {
    const payload = {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      phone: "",
    };

    dispatch(getCallsFromGeneral(payload));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useCallsFromGeneral;
