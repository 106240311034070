import { createSlice } from "@reduxjs/toolkit";
import {
  AddEarlyReservationDefinitionFormErrors,
  AddEarlyReservationDefinition,
  AddEarlyReservationDefinitionState,
} from "types/definitions";

export interface AddEarlyReservationDefinitionInitialState {
  earlyReservationDefinitionDefault: AddEarlyReservationDefinition;
  earlyReservationDefinition: AddEarlyReservationDefinitionState;
  addEarlyReservationDefinitionFormErrors: AddEarlyReservationDefinitionFormErrors;
  isLoading: boolean;
  error: string;
  updateDefinitionLoading: boolean;
  updateDefinitionError: string;
}

export const addEarlyReservationDefinitionInitialState: AddEarlyReservationDefinitionInitialState =
  {
    earlyReservationDefinitionDefault: {
      donem: "",
      tarih: "",
    },
    earlyReservationDefinition: {
      donem: "",
      tarih: "",
    },
    addEarlyReservationDefinitionFormErrors: {},
    isLoading: false,
    error: "",
    updateDefinitionLoading: false,
    updateDefinitionError: "",
  };

export const addEarlyReservationDefinitionSlice = createSlice({
  name: "addEarlyReservationDefinition",
  initialState: addEarlyReservationDefinitionInitialState,
  reducers: {
    handleSaveEarlyReservationDefinitionDefault(state, action) {
      state.earlyReservationDefinitionDefault = action.payload;
    },

    handleSaveEarlyReservationDefinition(state, action) {
      state.earlyReservationDefinition = action.payload;
    },
    handleAddAddEarlyReservationDefinitionFormErrors(state, action) {
      state.addEarlyReservationDefinitionFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.earlyReservationDefinition = {
        ...state.earlyReservationDefinition,
        ...action.payload,
      };
    },
    resetAfterUpdateEarlyReservationDefinition(state) {
      state.earlyReservationDefinition = {
        ...state.earlyReservationDefinitionDefault,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateDefinitionLoading(state, action) {
      state.updateDefinitionLoading = action.payload;
    },
    setUpdateDefinitionError(state, action) {
      state.updateDefinitionError = action.payload;
    },
  },
});

export const {
  handleSaveEarlyReservationDefinitionDefault,
  handleSaveEarlyReservationDefinition,
  handleAddAddEarlyReservationDefinitionFormErrors,
  handleChangeInput,
  resetAfterUpdateEarlyReservationDefinition,
  setIsLoading,
  setError,
  setUpdateDefinitionLoading,
  setUpdateDefinitionError,
} = addEarlyReservationDefinitionSlice.actions;

export default addEarlyReservationDefinitionSlice.reducer;
