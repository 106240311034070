import { CustomFormContainer } from "Components/Custom/UI";
import AnalysisRateCard from "./AnalysisRateCard";
import useHandleRates from "./useHandleRates";

interface AnalysisRatesProps {
  isLoading: boolean;
}

const AnalysisRates = ({ isLoading }: AnalysisRatesProps) => {
  const { firstRates, secondRates, thirdRates } = useHandleRates();

  return (
    <CustomFormContainer title="Rakip Analizi Oranları">
      <div className="overflow-auto">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "0.5rem",
          }}
        >
          <AnalysisRateCard {...firstRates} />
          <AnalysisRateCard {...secondRates} />
          <AnalysisRateCard {...thirdRates} />
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default AnalysisRates;
