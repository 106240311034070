import { CalendarPages } from "Components/Custom/PricesAndQuotaPages";
import PageContainer from "pages/PageContainer";

const Calendar = () => {
  return (
    <PageContainer title="Takvim">
      <CalendarPages />
    </PageContainer>
  );
};

export default Calendar;
