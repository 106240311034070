import SpecialDaysTable from "./SpecialDaysTable";

interface ListSpecialDaysPageProps {
  loading: boolean;
}

const ListSpecialDaysPage = ({ loading }: ListSpecialDaysPageProps) => {
  return <SpecialDaysTable loading={loading} />;
};

export default ListSpecialDaysPage;
