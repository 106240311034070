import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { STAY_LONG_PAY_LESS_DESCRIPTIONS } from "utils/pageDescriptions";
import StayLongPayLessActionsForm from "./StayLongPayLessForm";
import StayLongPayLessTable from "./StayLongPayLessTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./StayLongPayLessForm/RoomSelector";

interface StayLongPayLessActionsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const StayLongPayLessActionsPage = (props: StayLongPayLessActionsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={STAY_LONG_PAY_LESS_DESCRIPTIONS}
      isLoading={isLoading}
      roomSelector={<RoomSelector {...props} />}
    >
      <StayLongPayLessActionsForm {...props} />
      <StayLongPayLessTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(StayLongPayLessActionsPage);
