import Select from "react-select";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { handleSaveSelectedTheme } from "slices/themes/assignRecommendedHotels/reducer";

interface ThemeSelectorProps {
  themesOptions: Option[];
  selectedTheme: number;
  handleSelectTheme: (id: number) => void;
}

interface Option {
  value: number;
  label: string;
}

const ThemeSelector = ({
  themesOptions,
  selectedTheme,
  handleSelectTheme,
}: ThemeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer>
      <CustomFormFieldContainer label="Tema">
        <Select
          value={themesOptions.find((option) => option.value === selectedTheme)}
          onChange={(selected: Option) => {
            dispatch(handleSaveSelectedTheme(selected.value));
            handleSelectTheme(selected.value);
          }}
          options={themesOptions}
          placeholder="Tema Seçiniz"
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default ThemeSelector;
