import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveHotelInvoices, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_HOTEL_INVOICES } from "helpers/url_helper";

import { Dispatch } from "redux";

interface RequestPayload {
  otel_id: number;
  per_page: number;
  page: number;
  search: string;
}

export const getHotelInvoices =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_HOTEL_INVOICES, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveHotelInvoices(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
