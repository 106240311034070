import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

const NightRestrictionsTable = () => {
  const { tableData, tableColumns } = useHandleTable();

  return <CustomReactTable tableData={tableData} tableColumns={tableColumns} />;
};

export default NightRestrictionsTable;
