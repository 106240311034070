import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface PhoneStatusTableProps {
  isLoading: boolean;
}

const PhoneStatusTable = ({ isLoading }: PhoneStatusTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Dahili Durum"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default PhoneStatusTable;
