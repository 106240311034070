import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { AssignHotelToUserFormErrors, AssignHotelToUserPayload, UserInfo } from "types/userOperations";

interface PersonnelSelectorProps {
  values: AssignHotelToUserPayload,
  personnelFormChoices: UserInfo[];
  selectedUser: number;
  handleSelectUser: (id: number) => void;
  formErrors: AssignHotelToUserFormErrors;
  validateFormChange: (values: AssignHotelToUserPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const PersonnelSelector = ({
  values,
  personnelFormChoices,
  selectedUser,
  handleSelectUser,
  formErrors,
  validateFormChange
}: PersonnelSelectorProps) => {
  const personnelOptions: Option[] = Object.entries(personnelFormChoices).map(
    ([key, value]) => ({
      value: String(value.id),
      label: value.ad_soyad,
    })
  );

  const selectedOption = personnelOptions.find(
    (option) => option.value === selectedUser.toString()
  );

  return (
    <CustomFormFieldContainer 
      label={"Personel : "} 
      error={formErrors.personel_id}
      required
      >
      <Select
        options={personnelOptions}
        value={selectedOption}
        onChange={(selected: Option) => {
          const personnelId = selected ? Number(selected.value) : 0;
          handleSelectUser(personnelId);
          validateFormChange({ ...values, personel_id: Number(selected.value) })
        }}
        placeholder="Personel seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default PersonnelSelector;
