import PaymentDatesTable from "./PaymentDatesTable";

interface FacilityPartialPaymentDatesPageProps {
  loading: boolean;
}

const FacilityPartialPaymentDatesPage = ({
  loading,
}: FacilityPartialPaymentDatesPageProps) => {
  return (
    <>
      <PaymentDatesTable loading={loading} />
    </>
  );
};

export default FacilityPartialPaymentDatesPage;
