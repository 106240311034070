import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import NotificationRow from "./NotificationRow";
import useHandleNotifications from "./useHandleNotifications";
import { PaginationBottom, PaginationTop } from "Components/Custom/UI/CustomReactTable/CustomPaginationComponents";
import useHandlePagination from "./useHandlePagination";

/* 

  * This component displays the list of notifications
  * and filter them according to okundu_durum

*/

const NotificationField = () => {
  const {
    isLoading,
    notifications,
    markAsRead,
    showOnlyUnread,
    toggleShowOnlyUnread,
  } = useHandleNotifications();

  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer>
      <PaginationTop
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
      />
      <div style={{ padding: "20px" }}>
        <div className="d-flex justify-content-end gap-2 mb-4">
          <CustomButton variant="primary" onClick={toggleShowOnlyUnread}>
            {showOnlyUnread
              ? "Tüm Bildirimleri Göster"
              : "Sadece Okunmayanları Göster"}
          </CustomButton>
        </div>
        {notifications?.map((notification, index) => (
          <NotificationRow
            key={index}
            data={notification}
            onMarkAsRead={markAsRead}
          />
        ))}
      </div>
      <PaginationBottom {...paginationBottomProps} loading={isLoading} />
    </CustomFormContainer>
  );
};

export default NotificationField;
