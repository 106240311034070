import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListLogsInitialState } from "slices/userOperations/listLogs/reducer";
import { getFilteredLogs, getLogFilterChoices } from "slices/userOperations/listLogs/thunk";

const useListLogs = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListLogs: ListLogsInitialState }) => state.ListLogs
  );

  useEffect(() => {
    dispatch(
      getLogFilterChoices()
    );

    dispatch(
      getFilteredLogs({
        start_date: "",
        end_date: "",
        user_id: 0,
        sayfa: "",
        per_page: 10,
        page: 1,
        search: "",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListLogs;
