import { OFFICE_LOCATIONS } from "enums";

// ! ADMIN
export interface DashboardResponse {
  otel_bilgisi: HotelData;
  otel_analiz: HotelAnalysis[];
  reklam_bilgileri: CommercialInfo;
  kapida_odemeli_rezervasyonlar: PaymentAtCheckIn;
  baslik: HotelTitle[];
  cardlar: Cards;
  satis_tutari: HotelSalesAmount;
  oda_sayisi: HotelRoomAmount;
  iptal_edilen_tutar: { gunluk_iptal_tutar: number | null }[];
  Toplam_satis_tutarlari: TotalSalesAmount;
  Reklam_Bilgilendirme: string;
  Fiyat_bilgilendirme: string;
}

export interface DashboardData {
  hotelInfoCard: HotelInfoCardData;
  competitorAnalysisCard: CompetitorAnalysisCardData;
  commercialTableCard: CommercialTableCardData[];
  totalSalesAmountsCard: TotalSalesAmountsCardData;
  lastThirtyDayRoomNumbersCard: LastThirtyDayRoomNumbersCardData[];
  totalRoomAmount: number;
  lastThirtyDayDailySalesCard: LastThirtyDayDailySalesCardData[];
  totalSalesAmount: number;
  paymentAtCheckInCard: PaymentAtCheckInCardData;
  facilityReservationOfficial: FacilityReservationOfficialCardData;
  regionalOperationOfficial: RegionalOperationOfficialCardData;
  usuableParaPuan: UsuableParaPuanCardData;
  commercialWarning: CommercialWarningCardData;
  priceWarning: PriceWarningCardData;
  provinceRankingInfo: ProvinceRankingInfoCardData;
  districtRankingInfo: DistrictRankingInfoCardData;
  provinceRankingGraph: ProvinceRankingGraphCardData;
  districtRankingGraph: DistrictRankingGraphCardData;
  collectionSituation: CollectionSituationCardData;
  priceProvider: PriceProviderCardData;
  comments: CommentsCardData[];
}

// * Daily Data Card [""]
export interface HotelData {
  kapida_odeme_cari_tutar: number;
  otel_adi: string;
  kapida_odeme_durum: string;
  fiyat_saglayici: string;
  kapida_odeme: string;
  otel_aktiflik: string;
  karsilama_kodu: string;
  cc_mi: string;
  gunluk_tutar: number;
  gunluk_oda: number;
  gunluk_iptal_tutar: number;
}

export interface HotelTitle {
  otel_adi: string;
  otel_durumu: string;
  gunluk_satislar: string;
}

// * Competitor Analysis Card ["Tesis Rakip Analizi"]
export interface HotelAnalysis {
  otel_id: number;
  otel_adi: string;
  otel_aktiflik: string;
  tesis_sinif: string;
  telefon: string;
  komisyon_orani: string;
  tedarikci: string;
  fiyat_saglayici: string;
  karsilama_kodu: string;
  reklam: string;
  reklam_durum: string;
  reklam_yeni: string;
  reklam_grup_id: string;
  reklam_aciklama: string;
  kapida_durum: string;
  otel_odasayisi: number;
  l1: string;
  l2: string;
  l3: string;
  l4: number;
  lk1: number; // ! iptal
  lk2: number; // ! iptal
  lk3: number; // ! iptal
  lk4: number; // ! iptal
  lo1: number; // ! iptal
  lo2: number; // ! iptal
  lo3: number; // ! iptal
  lo4: number; // ! iptal
  id: number;
  otelfiyat: number;
  otelfiyat_link: string; // ! iptal
  tatilsepeti: ""; // ! iptal
  tatilsepeti_link: string; // ! iptal
  jolly: number; // ! iptal
  jollytur: number; // ! iptal
  jolly_link: string; // ! iptal
  odeon: number; // ! iptal
  odeon_link: string; // ! iptal
  hotels: number; // ! iptal
  hotels_link: string; // ! iptal
  otelz: string; // ! iptal
  otelz_link: string; // ! iptal
  etstur: string; // ! iptal
  etstur_link: string; // ! iptal
  odamax: string; // ! iptal
  odamax_link: string; // ! iptal
  tatilbudur: string; // ! iptal
  tatilbudur_link: string; // ! iptal
  tatilcom: string; // ! iptal
  tarih_1: string; // * PERİYOT 1 tarih
  gece_1: number; // * PERİYOT 1 gece
  // "1" kırmızı , "4" sarı, "otelfiyat_fiyat_1" > 0 ve dusuk_durum_1 !== "3" yeşil
  dusuk_durum_1: string; // * PERİYOT 1 renk için
  musaitlik_durum_1: string; // ? Belirsiz
  tarih_2: string; // * PERİYOT 2 tarih
  gece_2: number; // * PERİYOT 2 gece
  dusuk_durum_2: string; // ? Belirsiz
  musaitlik_durum_2: string; // ? Belirsiz
  tarih_3: string; // * PERİYOT 3 tarih
  gece_3: number; // * PERİYOT 3 gece
  dusuk_durum_3: string; // ? Belirsiz
  musaitlik_durum_3: string; // ? Belirsiz
  tarih_4: string; // ! iptal
  gece_4: number; // ! iptal
  dusuk_durum_4: string; // ! iptal
  musaitlik_durum_4: string; // ! iptal
  tarih_5: number; // ! iptal
  gece_5: number; // ! iptal
  dusuk_durum_5: string; // ! iptal
  musaitlik_durum_5: string; // ! iptal
  tarih_6: number; // ! iptal
  gece_6: number; // ! iptal
  dusuk_durum_6: string; // ! iptal
  musaitlik_durum_6: string; // ! iptal
  otelfiyat_fiyat_1: string; // * PERİYOT 1 fiyat
  otelfiyat_fiyat_2: string;
  otelfiyat_fiyat_3: string; // * PERİYOT 3 fiyat
  otelfiyat_fiyat_4: string; // ! iptal
  otelfiyat_fiyat_5: string; // ! iptal
  otelfiyat_fiyat_6: string; // ! iptal
  crm_otelfiyat_kar_1: string; // ! iptal
  crm_otelfiyat_kar_2: string; // ! iptal
  crm_otelfiyat_kar_3: string; // ! iptal
  crm_otelfiyat_kar_4: string; // ! iptal
  crm_otelfiyat_kar_5: string; // ! iptal
  crm_otelfiyat_kar_6: string; // ! iptal
  jolly_fiyat_1: string; // * PERİYOT 1 fiyat
  jolly_fiyat_2: string; // * PERİYOT 2 fiyat
  jolly_fiyat_3: string; // * PERİYOT 3 fiyat
  jolly_fiyat_4: string; // ! iptal
  jolly_fiyat_5: string; // ! iptal
  jolly_fiyat_6: string; // ! iptal
  odeon_fiyat_1: string; // * PERİYOT 1 fiyat
  odeon_fiyat_2: string; // * PERİYOT 2 fiyat
  odeon_fiyat_3: string; // * PERİYOT 3 fiyat
  odeon_fiyat_4: string; // ! iptal
  odeon_fiyat_5: string; // ! iptal
  odeon_fiyat_6: string; // ! iptal
  hotels_fiyat_1: string; // * PERİYOT 1 fiyat
  hotels_fiyat_2: string; // * PERİYOT 2 fiyat
  hotels_fiyat_3: string; // * PERİYOT 3 fiyat
  hotels_fiyat_4: string; // ! iptal
  hotels_fiyat_5: string; // ! iptal
  hotels_fiyat_6: string; // ! iptal
  otelz_fiyat_1: string; // * PERİYOT 1 fiyat
  otelz_fiyat_2: string; // * PERİYOT 2 fiyat
  otelz_fiyat_3: string; // * PERİYOT 3 fiyat
  otelz_fiyat_4: string; // ! iptal
  otelz_fiyat_5: string; // ! iptal
  otelz_fiyat_6: string; // ! iptal
  etstur_fiyat_1: string; // * PERİYOT 1 fiyat
  etstur_fiyat_2: string; // * PERİYOT 2 fiyat
  etstur_fiyat_3: string; // * PERİYOT 3 fiyat
  etstur_fiyat_4: string; // ! iptal
  etstur_fiyat_5: string; // ! iptal
  etstur_fiyat_6: string; // ! iptal
  odamax_fiyat_1: string; // * PERİYOT 1 fiyat
  odamax_fiyat_2: string; // * PERİYOT 2 fiyat
  odamax_fiyat_3: string; // * PERİYOT 3 fiyat
  odamax_fiyat_4: string; // ! iptal
  odamax_fiyat_5: string; // ! iptal
  odamax_fiyat_6: string; // ! iptal
  tatilsepeti_fiyat_1: string; // * PERİYOT 1 fiyat
  tatilsepeti_musaitlik_1: string; // ? Belirsiz
  tatilsepeti_fiyat_2: string; // * PERİYOT 2 fiyat
  tatilsepeti_musaitlik_2: string; // ? Belirsiz
  tatilsepeti_fiyat_3: string; // * PERİYOT 3 fiyat
  tatilsepeti_musaitlik_3: string; // ? Belirsiz
  tatilsepeti_fiyat_4: string; // ! iptal
  tatilsepeti_musaitlik_4: string; // ! iptal
  tatilsepeti_fiyat_5: string; // ! iptal
  tatilsepeti_musaitlik_5: string; // ! iptal
  tatilsepeti_fiyat_6: string; // ! iptal
  tatilsepeti_musaitlik_6: string; // ! iptal
  tatilbudur_fiyat_1: string; // * PERİYOT 1 fiyat
  tatilbudur_musaitlik_1: string; // ? Belirsiz
  tatilbudur_fiyat_2: string; // * PERİYOT 2 fiyat
  tatilbudur_musaitlik_2: string; // ? Belirsiz
  tatilbudur_fiyat_3: string; // * PERİYOT 3 fiyat
  tatilbudur_musaitlik_3: string; // ? Belirsiz
  tatilbudur_fiyat_4: string; // ! iptal
  tatilbudur_musaitlik_4: string; // ! iptal
  tatilbudur_fiyat_5: string; // ! iptal
  tatilbudur_musaitlik_5: string; // ! iptal
  tatilbudur_fiyat_6: string; // ! iptal
  tatilbudur_musaitlik_6: string; // ! iptal
  aciklama: string; // * Açıklama modalı
  aciklama_yapan: string; // * Açıklama modalı
  otelz_oda_durum: string; // ? Belirsiz
  otelz_oda_durum_tarih: string; // ? Belirsiz
  guncelleme_zamani: string; // ? Belirsiz
  guncelleme_zamani_1: string; // ? Belirsiz
  guncelleme_zamani_2: string; // ? Belirsiz
  guncelleme_zamani_3: string; // ? Belirsiz
  guncelleme_zamani_4: string; // ! iptal
  guncelleme_zamani_5: string; // ! iptal
  guncelleme_zamani_6: string; // ! iptal
  created_at: string; // ? Belirsiz
  updated_at: string; // ? Belirsiz
  sehir_id: number;
  bolge: string;
  yetkili: {
    uye_id: number;
    ad_soyad: string;
  };
  otelfiyat_url_1: string;
  odamax_url_1: string;
  hotels_url_1: string;
  otelz_url_1: string;
  etstur_url_1: string;
  otelfiyat_url_2: string;
  odamax_url_2: string;
  hotels_url_2: string;
  otelz_url_2: string;
  etstur_url_2: string;
  otelfiyat_url_3: string;
  odamax_url_3: string;
  hotels_url_3: string;
  otelz_url_3: string;
  etstur_url_3: string;
  otelfiyat_url_4: string; // ! iptal
  odamax_url_4: string; // ! iptal
  hotels_url_4: string; // ! iptal
  otelz_url_4: string; // ! iptal
  etstur_url_4: string; // ! iptal
  otelfiyat_url_5: string; // ! iptal
  odamax_url_5: string; // ! iptal
  hotels_url_5: string; // ! iptal
  otelz_url_5: string; // ! iptal
  etstur_url_5: string; // ! iptal
  otelfiyat_url_6: string; // ! iptal
  odamax_url_6: string; // ! iptal
  hotels_url_6: string; // ! iptal
  otelz_url_6: string; // ! iptal
  etstur_url_6: string; // ! iptal
}

// * Sales Amount Graphic Cards ["Son 30 Günlük Oda Sayıları / Son 30 Günlük Satış Tutarları"]
export interface HotelSalesAmount {
  gunluk_tutarlar: { [key: string]: number }[];
  bir_aylik_tutar: number;
}

export interface HotelRoomAmount {
  gunluk_oda_sayisi: { [key: string]: number }[];
  aylik_oda_sayisi: number;
}

// * Total Sales Amount Card ["Toplam Satış Tutarları"]
export interface TotalSalesAmount {
  son_bir_yil: TotalSalesAmountOption;
  tum_zamanlar: TotalSalesAmountOption;
}

export interface TotalSalesAmountOption {
  toplam_satis_tutari: number;
  toplam_oda_sayisi: number;
}

// * Commercial Table Card [""]
export interface CommercialInfo {
  islemler: string; // ? Belirsiz
  liste: CommericalInfoList[];
  yesil: number;
  kirmizi: number;
  mavi: number;
}

export interface CommericalInfoList {
  tarihler: string;
  otel_id: number;
  reklam_grup_id: string;
  otel_adi: string;
  sehir: string;
  ilce: string;
  satis: string;
  min_satis: string;
  etkilesim: number;
  maliyet: string;
  max_maliyet: string;
  tiklama: number;
  eski_tbm: string;
  absTopImpPerc: string;
  yeni_tbm: string;
  durum: string;
  tedarikci_metni: string;
  otel_danismani: {
    uye_id: number;
    ad_soyad: string;
  };
}

export interface PaymentAtCheckIn {
  rezler: []; //! bunun içeriği nedir
  iptal_tutar: number;
  bekleyen_tutar: number;
  tamamlanan_tutar: number;
  toplam_rez: number;
  tarih: string;
}

// * Info Cards
export interface Cards {
  tesis_yetkilisi: FacilityOfficial[];
  operasyon_bolge_sorumlusu: OperationOfficial[];
  parapuan: "";
  lokasyonlar: {
    il: {
      id: string;
      adi: string;
      tesis_sayisi: string;
    };
    ilce: {
      id: string;
      adi: string;
      tesis_sayisi: string;
    };
  };
  ilotelleriodasayilari: {
    otel_rez_sayisi: number;
    bolge_rez_sayisi: number;
  };
  ilceotelleriodasayilari: {
    otel_rez_sayisi: number;
    bolge_rez_sayisi: number;
  };
  il_otelleri_siralamasi: {
    tiklanma: number;
    dizi: string;
    lokasyon: string;
    sira_durum: string;
    sira: number;
    eski_sira: number;
    yeni_sira: number;
  };
  ilce_otelleri_siralamasi: {
    tiklanma: number;
    dizi: string;
    lokasyon: string;
    sira_durum: string;
    sira: number;
    eski_sira: number;
    yeni_sira: number;
  };
  yorumlar: GuestComment[];
}

export interface FacilityOfficial {
  yetkili_adi: string;
  telefon: string;
  mail: string;
}

export interface OperationOfficial {
  id: number;
  ad_soyad: string;
  profil_cep_telefon: string;
  email_adresi: string;
  profil_adres: string;
}

export interface GuestComment {
  yorum_id: number;
  rezervasyon_id: number | null;
  uye_id: number | null;
  kategori: string;
  musteri_adi: string;
  musteri_adi_gosterim: string;
  begene_ekle: string | null;
  otel_id: number;
  otel_adi: string;
  giris_tarihi: string;
  cikis_tarihi: string;
  yorum_olumlu: string;
  yorum_olumsuz: string;
  manuel_yazan: string;
  cevap1: string;
  cevap2: string;
  cevap3: string;
  cevap4: string;
  cevap5: string;
  cevap6: string;
  cevap7: string;
  tarih: string;
  tarih2: string;
  aktive_kodu: string;
  ip_adresi: string | null;
  aktiflik: string;
  silme: string;
  duzenleme_log: string;
  updated_at: string | null;
  Created_At: string | null;
  puan: number;
}

// ! CARD TYPES
export interface HotelInfoCardData {
  otel_adi: string; // from : otel_bilgisi
  otel_durumu: string; // from : baslik
  fiyat_saglayici: string; // from : otel_bilgisi
  gunluk_satislar: string; // from : baslik
  gunluk_tutar: number; // from : otel_bilgisi
  gunluk_oda: number; // from : otel_bilgisi
  gunluk_iptal_tutar: number; // from : otel_bilgisi
}

// TODO : jolly_link + lokasyon yok!
export interface CompetitorAnalysisCardData {
  otel_id: number;
  otel_adi: string;
  telefon: string;
  komisyon_orani: string;
  reklam_yeni: string;
  reklam_grup_id: string;
  reklam_aciklama: string;
  kapida_durum: string;
  otel_odasayisi: number;
  id: number;
  tarih_1: string;
  gece_1: number;
  tarih_2: string;
  gece_2: number;
  tarih_3: string;
  gece_3: number;
  otelfiyat_fiyat_1: string;
  otelfiyat_fiyat_2: string;
  otelfiyat_fiyat_3: string;
  jolly_fiyat_1: string;
  jolly_fiyat_2: string;
  jolly_fiyat_3: string;
  hotels_fiyat_1: string;
  hotels_fiyat_2: string;
  hotels_fiyat_3: string;
  otelz_fiyat_1: string;
  otelz_fiyat_2: string;
  otelz_fiyat_3: string;
  etstur_fiyat_1: string;
  etstur_fiyat_2: string;
  etstur_fiyat_3: string;
  odamax_fiyat_1: string;
  odamax_fiyat_2: string;
  odamax_fiyat_3: string;
  aciklama: string;
  yetkili: {
    uye_id: number;
    ad_soyad: string;
  };
  otelfiyat_url_1: string;
  odamax_url_1: string;
  hotels_url_1: string;
  otelz_url_1: string;
  etstur_url_1: string;

  otelfiyat_url_2: string;
  odamax_url_2: string;
  hotels_url_2: string;
  otelz_url_2: string;
  etstur_url_2: string;

  otelfiyat_url_3: string;
  odamax_url_3: string;
  hotels_url_3: string;
  otelz_url_3: string;
  etstur_url_3: string;
}

export const CompetitorAnalysisCardInitialState = {
  otel_id: 0,
  otel_adi: "",
  otel_aktiflik: "",
  tesis_sinif: "",
  telefon: "",
  komisyon_orani: "",
  tedarikci: "",
  fiyat_saglayici: "",
  karsilama_kodu: "",
  reklam: "",
  reklam_durum: "",
  reklam_yeni: "",
  reklam_grup_id: "",
  reklam_aciklama: "",
  kapida_durum: "",
  otel_odasayisi: 0,
  l1: "",
  l2: "",
  l3: "",
  l4: 0,
  id: 0,
  otelfiyat: 0,
  tarih_1: "",
  gece_1: 0,
  dusuk_durum_1: "",
  musaitlik_durum_1: "",
  tarih_2: "",
  gece_2: 0,
  dusuk_durum_2: "",
  musaitlik_durum_2: "",
  tarih_3: "",
  gece_3: 0,
  dusuk_durum_3: "",
  musaitlik_durum_3: "",
  otelfiyat_fiyat_1: "",
  otelfiyat_fiyat_2: "",
  otelfiyat_fiyat_3: "",
  jolly_fiyat_1: "",
  jolly_fiyat_2: "",
  jolly_fiyat_3: "",
  odeon_fiyat_1: "",
  odeon_fiyat_2: "",
  odeon_fiyat_3: "",
  hotels_fiyat_1: "",
  hotels_fiyat_2: "",
  hotels_fiyat_3: "",
  otelz_fiyat_1: "",
  otelz_fiyat_2: "",
  otelz_fiyat_3: "",
  etstur_fiyat_1: "",
  etstur_fiyat_2: "",
  etstur_fiyat_3: "",
  odamax_fiyat_1: "",
  odamax_fiyat_2: "",
  odamax_fiyat_3: "",
  aciklama: "",
  aciklama_yapan: "",
  otelz_oda_durum: "",
  otelz_oda_durum_tarih: "",
  guncelleme_zamani: "",
  guncelleme_zamani_1: "",
  guncelleme_zamani_2: "",
  guncelleme_zamani_3: "",
  created_at: "",
  updated_at: "",
  sehir_id: 0,
  bolge: "",
  yetkili: {
    uye_id: 0,
    ad_soyad: "",
  },
  otelfiyat_url_1: "",
  odamax_url_1: "",
  hotels_url_1: "",
  otelz_url_1: "",
  etstur_url_1: "",
  otelfiyat_url_2: "",
  odamax_url_2: "",
  hotels_url_2: "",
  otelz_url_2: "",
  etstur_url_2: "",
  otelfiyat_url_3: "",
  odamax_url_3: "",
  hotels_url_3: "",
  otelz_url_3: "",
  etstur_url_3: "",
};

export interface CommercialTableCardData {
  satir_rengi: {
    yesil: number; // from: reklam_bilgileri
    kirmizi: number; // from: reklam_bilgileri
    mavi: number; // from: reklam_bilgileri
  };
  reklam_grup_id: string; // from: reklam_bilgileri.liste
  otel_adi: string; // from: reklam_bilgileri.liste
  sehir: string; // from: reklam_bilgileri.liste
  ilce: string; // from: reklam_bilgileri.liste
  tedarikci_metni: string; // from: reklam_bilgileri.liste
  otel_danismani: string; // from: reklam_bilgileri.liste
  satis: string; // from: reklam_bilgileri.liste
  min_satis: string; // from: reklam_bilgileri.liste
  maliyet: string; // from: reklam_bilgileri.liste
  etkilesim: number; // from: reklam_bilgileri.liste
  tiklama: number; // from: reklam_bilgileri.liste
  absTopImpPerc: string; // from: reklam_bilgileri.liste
  eski_tbm: string; // from: reklam_bilgileri.liste
  yeni_tbm: string; // from: reklam_bilgileri.liste
}

export const CommercialTableCardInitialState = {
  reklam_grup_id: "", // from: reklam_bilgileri.liste
  otel_adi: "", // from: reklam_bilgileri.liste
  sehir: "", // from: reklam_bilgileri.liste
  ilce: "", // from: reklam_bilgileri.liste
  tedarikci_metni: "", // from: reklam_bilgileri.liste
  otel_danismani: {
    uye_id: 0,
    ad_soyad: "",
  }, // from: reklam_bilgileri.liste
  satis: "", // from: reklam_bilgileri.liste
  min_satis: "", // from: reklam_bilgileri.liste
  maliyet: "", // from: reklam_bilgileri.liste
  etkilesim: 0, // from: reklam_bilgileri.liste
  tiklama: 0, // from: reklam_bilgileri.liste
  absTopImpPerc: "", // from: reklam_bilgileri.liste
  eski_tbm: "", // from: reklam_bilgileri.liste
  yeni_tbm: "", // from: reklam_bilgileri.liste
};

// from: Toplam_satis_tutarlari (no need to change)
export interface TotalSalesAmountsCardData {
  son_bir_yil: TotalSalesAmountOption;
  tum_zamanlar: TotalSalesAmountOption;
}

// from: oda_sayisi (no need to change)
export interface LastThirtyDayRoomNumbersCardData {
  [key: string]: string | number;
  name: string;
}

// from: satis_tutari (no need to change)
export interface LastThirtyDayDailySalesCardData {
  [key: string]: string | number;
  name: string;
}

export interface PaymentAtCheckInCardData {}

// from: cardlar.tesis_yetkilisi (it doesn't need to be an array but no need to change)
export interface FacilityReservationOfficialCardData {
  yetkili_adi: string;
  telefon: string;
  mail: string;
}

// from: cardlar.operasyon_bolge_sorumlusu (it doesn't need to be an array but no need to change)
export interface RegionalOperationOfficialCardData {
  id: number;
  ad_soyad: string;
  profil_cep_telefon: string;
  email_adresi: string;
  profil_adres: string;
}

export interface UsuableParaPuanCardData {
  para_puan_miktari: number;
}

// from: Reklam_Bilgilendirme (no need to change)
export interface CommercialWarningCardData {
  message: string;
}

export interface ProvinceRankingInfoCardData {
  location: string; // from: cardlar.lokasyonlar.il.adi
  ranking: number; // from: cardlar.il_otelleri_siralamasi.sira
  down_or_high: "down" | "high" | "neutral"; // from: cardlar.il_otelleri_siralamasi.eski_sira/yeni_sira
  click_rate: number; // from: cardlar.il_otelleri_siralamasi.tiklanma
}

export interface DistrictRankingInfoCardData {
  location: string; // from: cardlar.lokasyonlar.ilce.adi
  ranking: number; // from: cardlar.ilce_otelleri_siralamasi.sira
  down_or_high: "down" | "high" | "neutral"; // from: cardlar.ilce_otelleri_siralamasi.eski_sira/yeni_sira
  click_rate: number; // from: cardlar.ilce_otelleri_siralamasi.tiklanma
}

// from: Fiyat_bilgilendirme (no need to change)
export interface PriceWarningCardData {
  message: string;
}

export interface ProvinceRankingGraphCardData {
  location: string; // from: cardlar.lokasyonlar.il.adi
  sales_of_province_hotels: number; // from: cardlar.ilotelleriodasayilari.bolge_rez_sayisi
  sales_of_the_hotel: number; // from: cardlar.ilotelleriodasayilari.otel_rez_sayisi
}

export interface DistrictRankingGraphCardData {
  location: string; // from: cardlar.lokasyonlar.ilce.adi
  sales_of_district_hotels: number; // from: cardlar.ilceotelleriodasayilari.bolge_rez_sayisi
  sales_of_the_hotel: number; // from: cardlar.ilceotelleriodasayilari.otel_rez_sayisi
}

export interface CollectionSituationCardData {
  collection_situation: string; // from otel_bilgisi.kapida_odeme & otel_bilgisi.kapida_odeme_durum
}

export interface PriceProviderCardData {
  provider_name: string; // from otel_bilgisi.fiyat_saglayici
}

export interface DiscountsCardData {}

export interface CommentsCardData {
  comment_id: number; // from: cardlar.yorumlar.yorum_id
  rate: number; // from: cardlar.yorumlar.puan
  commenter_name: string; // from: cardlar.yorumlar.musteri_adi
  accommodation_dates: string; // from: cardlar.yorumlar.giris_tarihi / cikis_tarihi
  positive_comment: string; // from: cardlar.yorumlar.yorum_olumlu
  negative_comment: string; // from: cardlar.yorumlar.yorum_olumsuz
}

// ! CRM
export interface CrmDashboardResponse {
  dahili_listesi: ExtensionNumberList;
  aylik_satis_listesi: {
    satis_gruplari: MonthlySalesList;
    month_name: string;
  };
  dahili_durum: ExtensionSituation;
  sonra_aranacaklar: CallsDueLater;
  personel_aktivite_sureler: {
    mesgulde_gecen_sure: string;
    yemekte_gecen_sure: string;
  };
}

export interface CrmDashboardData {
  dahili_listesi: ExtensionNumberListCardData;
  aylik_satis_listesi: {
    satis_gruplari: MonthlySalesListCardData;
    month_name: string;
  };
  dahili_durum: ExtensionSituationCardData;
  sonra_aranacaklar: CallsDueLaterCardData;
  personel_aktivite_sureler: {
    mesgulde_gecen_sure: string;
    yemekte_gecen_sure: string;
  };
}

export interface ExtensionNumberList {
  [key: string]: {
    grup_adi: string;
    uyeler: GroupMember[];
  };
}

export interface GroupMember {
  id: number;
  kullanici_adi: string;
  ad_soyad: string;
  cinsiyet: "e" | "k";
  inet_kodu: string;
  email_adresi: string;
  yetki: string;
  aktiflik: "1" | "0";
  crm_durum: "0" | "1";
  dahili: number;
  dahili_aktiflik: "1" | "0"; // ?
  part_time: "0" | "1";
  user_type: number;
  dahili_pass: string;
  sess_id: string;
  create_date: string;
  son_giris_ip: string; // ?
  dis_ip: string; // ?
  inet_sess_id: string; // ?
  bildigi_diller: string; // ?
  tanimli_oteller: string; // ?
  profil_resim: string; // ?
  profil_resim_2: string; // ?
  profil_cep_telefon: string; // ?
  profil_adres: OFFICE_LOCATIONS; // ?
  dahili_durum: 0 | 1;
  mola_suresi: string;
  socket_durum: "0" | "1";
  socket_connection_id: number; // ?
  created_at: string;
  updated_at: string;
  reset_code: number;
  grup_adi: string;
  gaciklama: string;
  gid: number;
}

export interface MonthlySalesList {
  [key: string]: {
    grup_id: number;
    grup_adi: string;
    grup_aciklama: string;
    personeller: GroupPersonnel[];
  };
}

export interface GroupPersonnel {
  uye_id: number;
  kullanici_adi: string;
  ad_soyad: string;
  yetki: string;
  aktiflik: string;
  dahili: number;
  toplam_satis: string;
  kapida_toplam_satis: string;
  toplam_oda_sayisi: number;
}

export interface ExtensionSituation {
  [key: string]: {
    uye_id: number;
    ad_soyad: string;
    dahili: number;
    grup_adi: string;
    dahili_durum: ExtensionDetail;
  };
}

export interface ExtensionDetail {
  [key: string]: {
    tarih: string;
    ilk_islem_zamani: string;
    son_islem_zamani: string;
    cevrimici: string;
    mesgul: string;
    yemekte: string;
    disarida: string;
    cevrimdisi: string;
    gorusme: string;
    hareketler: UserAction[];
  };
}

export interface UserAction {
  id: number;
  tarih: string;
  uye_id: number;
  ad_soyad: string;
  dahili_durum: number;
  dahili_durum_onceki: number;
  islem_zamani: string;
}

export interface CallsDueLater {
  [key: string]: {
    sonra_id: number;
    arama_id: number;
    sonra_aranacak: string;
    aciklama: string;
    durum: string;
    arandi_mi: string;
    musteri_id: number; // ?
    arayan_no: string;
    aranan_no: string;
    otel_id: number; // ?
    crm_kul_adi: string;
    musteri_isim: string;
    gecmis_kayit: number;
    otel_adi: string;
    otel_telefon: string;
    color: string;
  };
}

// * Card Types

export interface ExtensionNumberListCardData {
  [key: string]: {
    grup_adi: string;
    uyeler: GroupMemberCardData[];
  };
}
export interface GroupMemberCardData {
  id: number;
  ad_soyad: string;
  email_adresi: string;
  dahili: number;
}

export interface MonthlySalesListCardData {
  [key: string]: {
    grup_id: number;
    grup_adi: string;
    grup_aciklama: string;
    personeller: GroupPersonnelCardData[];
    degerlendirilen_oda_ortalaması: number;
  };
}
export interface GroupPersonnelCardData {
  uye_id: number;
  ad_soyad: string;
  toplam_satis: string;
  kapida_toplam_satis: string;
  toplam_oda_sayisi: number;
}

export interface ExtensionSituationCardData {
  [key: string]: {
    uye_id: number;
    ad_soyad: string;
    dahili: number;
    grup_adi: string;
    dahili_durum: ExtensionDetailCardData;
  };
}

export interface ExtensionDetailCardData {
  [key: string]: {
    tarih: string;
    ilk_islem_zamani: string;
    son_islem_zamani: string;
    cevrimici: string;
    mesgul: string;
    yemekte: string;
    disarida: string;
    cevrimdisi: string;
    gorusme: string;
  };
}

export interface CallsDueLaterCardData {
  [key: string]: {
    sonra_id: number;
    arama_id: number;
    sonra_aranacak: string;
    aciklama: string;
    durum: string;
    arandi_mi: string;
    musteri_id: number; // ?
    arayan_no: string;
    aranan_no: string;
    otel_id: number; // ?
    crm_kul_adi: string;
    musteri_isim: string;
    gecmis_kayit: number;
    otel_adi: string;
    otel_telefon: string;
    color: string;
  };
}
