import RoomsLackDescriptionTable from "./RoomsLackDescriptionTable";

interface RoomsLackDescriptionPageProps {
  isLoading: boolean;
}

const RoomsLackDescriptionPage = ({
  isLoading,
}: RoomsLackDescriptionPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <RoomsLackDescriptionTable isLoading={isLoading} />
    </div>
  );
};

export default RoomsLackDescriptionPage;
