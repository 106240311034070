import { FacilityUsersPage } from "Components/Custom/FacilityPages";
import useFacilityUsers from "Components/Hooks/facility/useFacilityUsers";
import PageContainer from "pages/PageContainer";

const FacilityUsers = () => {
  const { loading, error, isValidHotel, isLoading } = useFacilityUsers();

  return (
    <PageContainer title="Tesis Kullanıcıları">
      <FacilityUsersPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default FacilityUsers;
