import { HourlyDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import useHourlyDiscounts from "Components/Hooks/discountsAndActions/useHourlyDiscounts";
import PageContainer from "pages/PageContainer";

const HourlyDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useHourlyDiscounts();

  return (
    <PageContainer title="Saatlik İndirimler">
      <HourlyDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default HourlyDiscounts;
