import { CustomRowBadge, CustomRowSpan } from "Components/Custom/UI";
import moment from "moment";
import { useSelector } from "react-redux";

import { AllGiftsListItem } from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { allGifts } = useSelector((state: any) => state.AllGiftsList);

  const tableData: AllGiftsListItem[] = allGifts?.map(
    (gift: AllGiftsListItem) => {
      return {
        id: gift.id,
        otel_id: gift.otel_id,
        otel_adi: gift.otel_adi,
        otel_oda_id: gift.otel_oda_id,
        oda_adi: gift.oda_adi,
        hediye_kosulu: gift.hediye_kosulu,
        konaklama_baslangic_tarihi: gift.konaklama_baslangic_tarihi,
        konaklama_bitis_tarihi: gift.konaklama_bitis_tarihi,
        tum_gunler: gift.tum_gunler,
        gunler: gift.gunler,
        min_gece_sayisi: gift.min_gece_sayisi,
        hediye_id: gift.hediye_id,
        hediye_adi: gift.hediye_adi,
        indirim_orani: gift.indirim_orani,
        gecerli_kisi_sayisi: gift.gecerli_kisi_sayisi,
        grup_id: gift.grup_id,
      };
    }
  );

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Oda Adı",
      accessorKey: "oda_adi",
      enableColumnFilter: false,
    },
    {
      header: "Hediye Adı",
      accessorKey: "hediye_adi",
      enableColumnFilter: false,
    },
    {
      header: "Hediye Koşulu",
      accessorKey: "hediye_kosulu",
      cell: (cell: any) => {
        const criteria = cell.getValue();
        const label =
          criteria === 1
            ? "Tarih Aralığına Göre"
            : criteria === 2
            ? "Tarih Aralığı-Min. Gece Sayısına Göre"
            : criteria === 3
            ? "Min.Gece Sayısına Göre"
            : "";

        return <span className=" text-wrap"> {label} </span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihi",
      accessorKey: "konaklama_tarihi",
      cell: (cell: any) => {
        const showDate =
          cell.row.original.hediye_kosulu === 1 ||
          cell.row.original.hediye_kosulu === 2;
        return (
          <>
            {showDate ? (
              <span>
                {moment(cell.row.original.konaklama_baslangic_tarihi).format(
                  "DD.MM.YYYY"
                )}
                <br />
                {moment(cell.row.original.konaklama_bitis_tarihi).format(
                  "DD.MM.YYYY"
                )}
              </span>
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Min. Gece Sayısı",
      accessorKey: "min_gece_sayisi",
      cell: (cell: any) => {
        const showMinNight = cell.row.original.hediye_kosulu !== 1;

        const nights = cell.getValue();

        return <span>{showMinNight ? nights : "-"}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      accessorKey: "tum_gunler",
      cell: (cell: any) => {
        const showAllDays = cell.row.original.hediye_kosulu !== 3;

        return (
          <>
            {showAllDays ? (
              <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const allDays = cell.row.original.tum_gunler;

        const showAllDays = cell.row.original.hediye_kosulu !== 3;

        return (
          <>
            {showAllDays ? (
              <>
                <span className="d-flex gap-2 flex-wrap">
                  {allDays === "1" || days.length === 7 ? (
                    <CustomRowBadge value="Tüm Günler" />
                  ) : (
                    days.map((day: string, index: number) => (
                      <CustomRowBadge key={index} value={day} />
                    ))
                  )}
                </span>
              </>
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      accessorKey: "gunler",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "indirim_orani",
      cell: (cell: any) => {
        const rate = cell.getValue();
        const label = rate === 100 ? "Ücretsiz" : `%${rate}`;

        return <span>{label}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Geçerli Kişi Sayısı",
      accessorKey: "gecerli_kisi_sayisi",
      cell: (cell: any) => {
        const person = cell.getValue();
        const label = person === 99 ? "Odadaki Herkes" : person;

        return <span>{label}</span>;
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
