import {
  CustomButton,
  CustomDnDOrdering,
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import useHandleList from "./useHandleList";
import {
  FACILITY_ORDER_IMAGES_FORM_DESCRIPTIONS,
  FACILITY_ORDER_IMAGES_FORM_WARNING,
} from "utils/pageDescriptions";

const FacilityOrderImagesForm = () => {
  const {
    facilityImages,
    handleOrderItems,
    selectedImages,
    handleSelectImage,
    handleSelectAll,
    handleDeselectAll,
    handleDelete,
    handleDeleteSelectedImages,
    handleReorderImages,
    updateImagesOrderLoading,
    deleteImagesLoading,
  } = useHandleList();

  const showDeselectAllButton = selectedImages.length > 0;

  return (
    <CustomFormContainer title="Resim Sıralama" lg={12} xl={12}>
      <div className="d-flex flex-column gap-2 bg-white px-4 py-2 rounded">
        <CustomPageDescriptions
          descriptions={FACILITY_ORDER_IMAGES_FORM_DESCRIPTIONS}
        />
        <CustomPageDescriptions
          variant="danger"
          descriptions={FACILITY_ORDER_IMAGES_FORM_WARNING}
        />
      </div>
      <div className="d-flex flex-column gap-4 border rounded p-2 bg-white">
        {facilityImages.length ? (
          <>
            <CustomDnDOrdering
              items={facilityImages}
              orderKey="sira"
              photoKey="resim_url"
              handleOrderItems={handleOrderItems}
              selectedItems={selectedImages}
              handleSelectItem={handleSelectImage}
              handleDelete={handleDelete}
            />
            <div className="d-flex justify-content-end gap-2">
              <CustomButton variant="warning" onClick={handleSelectAll}>
                Tümünü Seç
              </CustomButton>
              {showDeselectAllButton && (
                <>
                  <CustomButton variant="warning" onClick={handleDeselectAll}>
                    Seçimleri Kaldır
                  </CustomButton>
                  <CustomButton
                    variant="danger"
                    onClick={handleDeleteSelectedImages}
                    isLoading={deleteImagesLoading}
                  >
                    Seçilenleri Sil
                  </CustomButton>
                </>
              )}

              <CustomButton
                variant="success"
                onClick={handleReorderImages}
                isLoading={updateImagesOrderLoading}
              >
                Sıralamayı Kaydet
              </CustomButton>
            </div>
          </>
        ) : (
          <p className="text-center p-2 border rounded">
            Sıralanacak Resim Bulunamadı...
          </p>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default FacilityOrderImagesForm;
