import { Link } from "react-router-dom";

interface LoginFormHeaderProps {
  logo: string;
}

const LoginFormHeader = ({ logo }: LoginFormHeaderProps) => {
  return (
    <div className="text-center my-2">
      <Link to="/" className="d-inline-block auth-logo">
        <img src={logo} alt="otelfiyat.com" height={50} width={250} />
      </Link>
    </div>
  );
};

export default LoginFormHeader;
