// SortableItem.tsx
import React, { useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { ItemType } from "./DragItem";

interface SortableItemProps {
  id: string;
  index: number;
  text: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  removeItem: (id: string) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  index,
  text,
  moveItem,
  removeItem,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (
      item: { id: string; index: number },
      monitor: DropTargetMonitor
    ) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      className="d-flex gap-4 align-items-center justify-content-center border rounded px-2 py-1 bg-white"
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <span className="fs-14">{text}</span>
      <i
        className="ri-arrow-right-line fs-14 fw-semibold theme-text-danger cursor-pointer"
        title="Eşleştirmeden çıkar"
        onClick={() => removeItem(id)}
      ></i>
    </div>
  );
};

export default SortableItem;
