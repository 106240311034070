import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { DefineCurrencyRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { DefineCurrencyInitialState } from "slices/definitions/defineCurrency/reducer";

const useHandleForm = () => {
  const selectedDefineCurrencyState = (state: {
    DefineCurrency: DefineCurrencyInitialState;
  }) => state.DefineCurrency;

  const selectDefineCurrencyProperties = createSelector(
    selectedDefineCurrencyState,
    (state) => ({
      defineCurrency: state.defineCurrency,
      defineCurrencyFormErrors: state.defineCurrencyFormErrors,
      isLoading: state.isLoading,
      error: state.error,
      defineCurrencyLoading: state.defineCurrencyLoading,
    })
  );

  const {
    defineCurrency,
    defineCurrencyFormErrors,
    isLoading,
    error,
    defineCurrencyLoading,
  } = useSelector(selectDefineCurrencyProperties);

  const defineCurrencyRequestPayload: DefineCurrencyRequestPayload = {
    usd: defineCurrency.usd,
    euro: defineCurrency.euro,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    defineCurrency,
    defineCurrencyLoading,
    isLoading,
    error,
    defineCurrencyRequestPayload,
    formErrors: defineCurrencyFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
