import { DefineCurrencyPage } from "Components/Custom/DefinitionsPages";
import useDefineCurrency from "Components/Hooks/definitions/useDefineCurrency";
import PageContainer from "pages/PageContainer";

const DefineCurrency = () => {
  const { error, isLoading } = useDefineCurrency();

  return (
    <PageContainer title="Kur Tanımlama">
      <DefineCurrencyPage loading={isLoading} error={error} />
    </PageContainer>
  );
};

export default DefineCurrency;
