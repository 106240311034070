import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import GetReservationTableButton from "./FormFields/GetReservationTableButton";
import UpdatePriceProviderForm from "./UpdatePriceProviderForm";
const ReservationChangeApproveCancelForm = () => {
  const {
    showUpdateForm,
    values,
    options,
    onGetReservation,
    handleFormChange,
    handleUpdate,
  } = useHandleForm();

  return (
    <>
      <CustomFormContainer title="Rezervasyon Tablosu">
        <ReservationCodeInput
          values={values}
          handleFormChange={handleFormChange}
        />
        <GetReservationTableButton
          handleSave={onGetReservation}
          values={values}
        />
        {showUpdateForm && (
          <div className="p-3">
            <UpdatePriceProviderForm
              values={values}
              options={options}
              handleFormChange={handleFormChange}
              handleUpdate={handleUpdate}
            />
          </div>
        )}
      </CustomFormContainer>
    </>
  );
};

export default ReservationChangeApproveCancelForm;
