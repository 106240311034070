import React from "react";

//import Scss
import "./assets/scss/themes.scss";
import "./assets/scss/custom-theme.scss";
import "bootstrap/dist/css/bootstrap.min.css";

//import Route
import Route from "./Routes";
import { useAxios } from "Components/Hooks";
import { CustomToast } from "Components/Custom/UI";

function App() {
  useAxios();
  return (
    <React.Fragment>
      <Route />
      <CustomToast />
    </React.Fragment>
  );
}

export default App;
