import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Hotel, IncomingAvailability } from "types/PricesQuotas";

export interface BasicAvailabilityEntryInitialState {
  hotel: Hotel;
  isLoading: boolean;
  error: string;
  success: string;
  changedAvailabilities: IncomingAvailability[];
  selectedDates: {
    startDate: string;
    endDate: string;
  };
}

export const initialState: BasicAvailabilityEntryInitialState = {
  hotel: {
    otel_id: 0,
    otel_adi: "",
    currency: "",
    odalar: [],
  },
  isLoading: false,
  error: "",
  success: "",
  changedAvailabilities: [],
  selectedDates: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(1, "months").format("YYYY-MM-DD"),
  },
};

const basicAvailabilityEntrySlice = createSlice({
  name: "basicAvailabilityEntry",
  initialState,
  reducers: {
    handleSaveHotel(state, action) {
      state.hotel = action.payload;
    },
    handleSelectedDates(state, action) {
      const dates = action.payload;
      state.selectedDates = {
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
    },
    handleAddChangedAvailability(state, action) {
      const filteredAvailabilities = state.changedAvailabilities.filter(
        (availability) =>
          `${availability.oda_id}-${availability.pansiyon_id}` !==
          `${action.payload.oda_id}-${action.payload.pansiyon_id}`
      );
      state.changedAvailabilities = [...filteredAvailabilities, action.payload];
    },

    handleRemoveChangedAvailability(state, action) {
      state.changedAvailabilities = state.changedAvailabilities.filter(
        (availability) =>
          `${availability.oda_id}-${availability.pansiyon_id}` !==
          `${action.payload.oda_id}-${action.payload.pansiyon_id}`
      );
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    resetAll(state) {
      state.selectedDates = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "months").format("YYYY-MM-DD"),
      };
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.hotel = {
        otel_id: 0,
        otel_adi: "",
        currency: "",
        odalar: [],
      };
      state.changedAvailabilities = [];
    },
  },
});

export const {
  handleSaveHotel,
  handleSelectedDates,
  handleAddChangedAvailability,
  handleRemoveChangedAvailability,
  setIsLoading,
  setError,
  setSuccess,
  resetAll,
} = basicAvailabilityEntrySlice.actions;

export default basicAvailabilityEntrySlice.reducer;
