import { useSelector } from "react-redux";

import { AdsReportInitialState } from "slices/adsAndAnalyze/adsReport/reducer";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const { adsReport } = useSelector(
    (state: { AdsReport: AdsReportInitialState }) => state.AdsReport
  );

  const rowColors = {
    0: TABLE_COLORS.yellow,
    1: TABLE_COLORS.red,
    2: TABLE_COLORS.green,
  };

  const tableData = adsReport?.map((adReport) => ({
    "Otel Adı": adReport.otel_adi,
    "Otel Sınıf": adReport.tesis_sinif,
    Satış: adReport.satis,
    Maliyet: adReport.maliyet,
    Etkileşim: adReport.etkilesim,
    Tıklama: adReport.tiklama,
    "Reklam Grup Durum": adReport.durum,
    "Eski TBM": adReport.eski_tbm,
    "Yeni TBM": adReport.yeni_tbm,
    rowColor: rowColors[adReport.durum as unknown as keyof typeof rowColors],
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel Sınıf",
      accessorKey: "Otel Sınıf",
      enableColumnFilter: false,
    },
    {
      header: "Satış",
      accessorKey: "Satış",
      enableColumnFilter: false,
    },
    {
      header: "Maliyet",
      accessorKey: "Maliyet",
      enableColumnFilter: false,
    },
    {
      header: "Etkileşim",
      accessorKey: "Etkileşim",
      enableColumnFilter: false,
    },
    {
      header: "Tıklama",
      accessorKey: "Tıklama",
      enableColumnFilter: false,
    },
    {
      header: "Reklam Grup Durum",
      accessorKey: "Reklam Grup Durum",
      enableColumnFilter: false,
    },
    {
      header: "Eski TBM",
      accessorKey: "Eski TBM",
      enableColumnFilter: false,
    },
    {
      header: "Yeni TBM",
      accessorKey: "Yeni TBM",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.yellow,
      label: "0",
    },
    {
      color: TABLE_COLORS.red,
      label: "1",
    },
    {
      color: TABLE_COLORS.green,
      label: "2",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
  };
};

export default useHandleTable;
