import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddUnderTheDeskDiscountFormErrors } from "slices/discountsAndActions/underTheDeskDiscounts/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  UnderTheDeskDiscountFormErrors,
  UnderTheDeskDiscountState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: UnderTheDeskDiscountState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: UnderTheDeskDiscountFormErrors = {};

    if (!values.satis_baslangic_tarih || !values.satis_bitis_tarih) {
      errors.satis_tarihi =
        "Satış başlangıç ve bitiş tarihleri boş bırakılamaz.";
    }

    if (!values.baslangic_tarih || !values.bitis_tarih) {
      errors.konaklama_tarihi =
        "Konaklama başlangıç ve bitiş tarihleri boş bırakılamaz.";
    }

    if (!values.oran || values.oran === "0") {
      errors.oran = "Oran boş bırakılamaz.";
    }

    if (Number(values.oran) > 100) {
      errors.oran = "Oran 100'den büyük olamaz.";
    }

    if (
      values.tum_otel_pansiyonlar === "0" &&
      values.otel_pansiyonlar.length === 0
    ) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }
    if (values.tum_odeme_tipleri === "0" && values.odeme_tipleri.length === 0) {
      errors.secili_odeme_tipleri = "En az bir ödeme tipi seçmelisiniz.";
    }

    dispatch(handleAddUnderTheDeskDiscountFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: UnderTheDeskDiscountState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
