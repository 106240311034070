import { z } from "zod";

import { useCallback, useEffect, useState } from "react";
import { CreditCard, CreditCardInstallment } from "types/GlobalTypes";

type MakePayment = (
  card: CreditCard,
  installment: CreditCardInstallment
) => void;

let load = false;

const useHandleCard = (
  makePayment: MakePayment,
  formId: string,
  installments: CreditCardInstallment[]
) => {
  const schema = z
    .object({
      cardOwner: z.string().min(2, { message: "*Zorunlu alan." }),
      cardNumber: z
        .string()
        .min(16, { message: "Kart numarası 16 haneli olmalıdır." }),
      expiryDate: z.string().min(1, { message: "*Zorunlu alan." }),
      cvc: z.string().min(3, { message: "3 haneli olmalıdır." }),
    })
    .refine((data) => !isNaN(Number(data.cardNumber)), {
      message: "Kart numarası sadece rakamlardan oluşmalıdır.",
      path: ["cardNumber"],
    })
    .refine((data) => !isNaN(Number(data.cvc)), {
      message: "Geçersiz güvenlik kodu.",
      path: ["cvc"],
    })
    .refine(
      (data) => {
        const arr = data.cardOwner.split(" ");
        return arr.length >= 2 && arr.every((word) => word.length > 1);
      },
      {
        message: "Geçersiz ad soyad.",
        path: ["cardOwner"],
      }
    )
    .refine(
      (data) => {
        const [month, year] = data.expiryDate.split("/");
        const currentYear = new Date().getFullYear().toString().slice(2, 4);
        const currentMonth = new Date().getMonth() + 1;

        if (
          Number(month) &&
          Number(year) &&
          year?.length === 2 &&
          month?.length === 2
        ) {
          if (year < currentYear) {
            return false;
          } else if (year === currentYear && Number(month) < currentMonth) {
            return false;
          } else if (Number(month) > 12) return false;
          else return true;
        } else return false;
      },
      { message: "Geçersiz tarih.", path: ["expiryDate"] }
    );

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [state, setState] = useState({
    cardOwner: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    focus: "",
  });

  const [errors, setErrors] = useState<CreditCard>({
    cardOwner: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });

  const limitCharacters = (value: string, limit: number) => {
    return value.slice(0, limit);
  };

  const validateForm = (submitted = false) => {
    if (submitted || isSubmitted) {
      const formData = new FormData(
        document.getElementById(formId) as HTMLFormElement
      );

      const cardData: CreditCard = {
        cardOwner: formData.get("cardOwner") as string,
        cardNumber: formData.get("cardNumber") as string,
        expiryDate: formData.get("expiryDate") as string,
        cvc: formData.get("cvc") as string,
      };

      const result = schema.safeParse(cardData);

      if (!result.success) {
        setErrors(
          result.error.errors.reduce(
            (acc: any, error: any) => {
              acc[error.path[0]] = error.message;
              return acc;
            },
            {
              cardOwner: "",
              cardNumber: "",
              expiryDate: "",
              cvc: "",
            }
          )
        );
        return false;
      } else {
        setErrors({
          cardOwner: "",
          cardNumber: "",
          expiryDate: "",
          cvc: "",
        });
        return cardData;
      }
    } else return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    validateForm();

    if (name === "cardNumber") {
      const cardNumber = limitCharacters(value, 16);
      setState((prev) => ({ ...prev, [name]: cardNumber }));
    } else if (name === "expiryDate") {
      const expiryDate = limitCharacters(value, 5);
      let newValue = expiryDate;

      if (value.length === 2 && !value.includes("/")) {
        newValue = value + "/";
        setState((prev) => ({ ...prev, [name]: newValue }));
        return;
      }
      if (value.length === 3 && value.includes("/")) {
        newValue = value.slice(0, 2);
        setState((prev) => ({ ...prev, [name]: newValue }));
        return;
      }
      setState((prev) => ({ ...prev, [name]: newValue }));
    } else if (name === "cvc") {
      const cvc = limitCharacters(value, 3);
      setState((prev) => ({ ...prev, [name]: cvc }));
    } else setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (
    e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    setState((prev) => ({ ...prev, focus: e.target.name }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const cardData = validateForm(true);
      setIsSubmitted(true);

      if (cardData) {
        makePayment(cardData, selectedInstallment);
      }
    } catch (error) {
      alert("Submitting form failed!");
    }
  };

  const [selectedInstallment, setSelectedInstallment] =
    useState<CreditCardInstallment>({
      paymentId: 0,
      month: 0,
      amount: 0,
      total: 0,
    });

  useEffect(() => {
    const notSelected = !selectedInstallment.paymentId;
    const installmentsExist = installments.length > 0;

    if (notSelected && installmentsExist) {
      setSelectedInstallment(installments[0]);
    }
  }, [installments, selectedInstallment]);

  const handleSelectInstallment = (installment: CreditCardInstallment) => {
    setSelectedInstallment(installment);
  };

  const resetSelectedInstallment = () => {
    setSelectedInstallment({
      paymentId: 0,
      month: 0,
      amount: 0,
      total: 0,
    });
  };

  const getCardInstallments = useCallback(
    (
      cardNumber: string,
      getInstallments: (bin: string) => void,
      resetInstallments: () => void,
      isPaste?: boolean
    ) => {
      const bin = cardNumber.slice(0, 6);

      if (isPaste) {
        bin.length > 5 && getInstallments(bin);
        return;
      } else if (cardNumber.length < 6) {
        load && resetInstallments();
        load && resetSelectedInstallment();
        load = false;
      } else {
        !load && getInstallments(bin);
        load = true;
      }
    },
    []
  );

  const isSubmitButtonDisabled =
    !selectedInstallment.paymentId ||
    Object.values(errors).some((error) => error);

  return {
    isSubmitted,
    handleSubmit,
    errors,
    state,
    handleInputChange,
    handleInputFocus,
    selectedInstallment,
    onSelectedInstallment: handleSelectInstallment,
    getCardInstallments,
    isSubmitButtonDisabled,
  };
};

export default useHandleCard;
