import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
import ActivityInformationImage from "./ActivityInformationImage";
import ActivityInformationText from "./ActivityInformationText";

interface ActivityInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const ActivityInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: ActivityInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Aktivite Bilgileri" lg={12} xl={12}>
        <ActivityInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <ActivityInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default ActivityInformationFields;
