import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateNotificationInitialState } from "slices/userOperations/createNotification/reducer";
import { getCreateNotificationFormChoices } from "slices/userOperations/createNotification/thunk";

const useCreateNewNotification = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { CreateNotification: CreateNotificationInitialState }) => state.CreateNotification
  );
  
  useEffect(() => {
    dispatch(
        getCreateNotificationFormChoices()
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useCreateNewNotification;
