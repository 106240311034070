import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  StayLongPayLessActionFormErrors,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";

import { handleChangeInput } from "slices/discountsAndActions/stayLongPayLessActions/reducer";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface DateSelectorsProps {
  values: StayLongPayLessActionState;
  formErrors: StayLongPayLessActionFormErrors;
  validateFormChange: (values: StayLongPayLessActionState) => void;
}

const DateSelectors = ({
  values,
  formErrors,
  validateFormChange,
}: DateSelectorsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Konaklama Tarih Aralığı"
        error={formErrors.konaklama_tarihi}
      >
        <Flatpickr
          className="form-control"
          value={[values.baslangic_tarih, values.bitis_tarih]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeInput({
                  baslangic_tarih: stringStartDate,
                  bitis_tarih: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                baslangic_tarih: stringStartDate,
                bitis_tarih: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateSelectors;
