import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeSpecialDayInput } from "slices/facility/facilitySpecialDays/reducer";
import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";

interface SpecialDayDescriptionProps {
  values: FacilitySpecialDayState;
  formErrors: FacilitySpecialDayFormErrors;
  validateFormChange: (values: FacilitySpecialDayState) => void;
}

const SpecialDayDescription = ({
  values,
  formErrors,
  validateFormChange,
}: SpecialDayDescriptionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Özel Gün Açıklama"
      error={formErrors.ozel_gun_aciklama}
      required
      divider
      orientation="vertical"
    >
      <CustomFormEditor
        editorData={values.ozel_gun_aciklama}
        handleGetEditorData={(data) => {
          dispatch(
            handleChangeSpecialDayInput({
              ozel_gun_aciklama: data,
            })
          );
          validateFormChange({
            ...values,
            ozel_gun_aciklama: data,
          });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default SpecialDayDescription;
