import { createSlice } from "@reduxjs/toolkit";

import { MonthlySales } from "types/reports";

export interface MonthlySalesInitialState {
  monthlySalesFilter: MonthlySales;
  monthlySales: MonthlySales;
  isLoading: boolean;
  error: string;
  filterLoading: boolean;
  filterError: string;
}

const initialState: MonthlySalesInitialState = {
  monthlySalesFilter: {
    tarihler: [],
    gruplar: [],
    veriler: [],
  },
  monthlySales: {
    tarihler: [],
    gruplar: [],
    veriler: [],
  },
  isLoading: false,
  error: "",
  filterLoading: false,
  filterError: "",
};

const monthlySalesSlice = createSlice({
  name: "monthlySales",
  initialState,
  reducers: {
    handleSaveMonthlySales(state, action) {
      state.monthlySales = action.payload;
    },
    handleSaveMonthlySalesFilter(state, action) {
      state.monthlySalesFilter = action.payload;
    },
    resetMonthlySales(state) {
      state.monthlySales = {
        tarihler: [],
        gruplar: [],
        veriler: [],
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setFilterLoading(state, action) {
      state.filterLoading = action.payload;
    },
    setFilterError(state, action) {
      state.filterError = action.payload;
    },
  },
});

export const {
  handleSaveMonthlySales,
  handleSaveMonthlySalesFilter,
  resetMonthlySales,
  setIsLoading,
  setError,
  setFilterLoading,
  setFilterError,
} = monthlySalesSlice.actions;

export default monthlySalesSlice.reducer;
