import { FacilityConceptsPage } from "Components/Custom/FacilityPages";
import useFacilityConcepts from "Components/Hooks/facility/useFacilityConcepts";
import PageContainer from "pages/PageContainer";

const FacilityConcepts = () => {
  const { loading, error, isValidHotel, isLoading } = useFacilityConcepts();

  return (
    <PageContainer title="Tesis Pansiyonları">
      <FacilityConceptsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default FacilityConcepts;
