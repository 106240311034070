import { createSlice } from "@reduxjs/toolkit";

import { DailyCallForHotel } from "types/reports";

export interface DailyCallsForHotelsInitialState {
  dailyCallsForHotels: DailyCallForHotel[];
  isLoading: boolean;
  error: string;
}

const initialState: DailyCallsForHotelsInitialState = {
  dailyCallsForHotels: [],
  isLoading: false,
  error: "",
};

const dailyCallsForHotelsSlice = createSlice({
  name: "dailyCallsForHotels",
  initialState,
  reducers: {
    handleSaveDailyCallsForHotels(state, action) {
      state.dailyCallsForHotels = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveDailyCallsForHotels, setIsLoading, setError } =
  dailyCallsForHotelsSlice.actions;

export default dailyCallsForHotelsSlice.reducer;
