import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/userOperations/createNotification/reducer";
import {
  CreateNotificationFormErrors,
  CreateNotificationPayload,
  CreateNotificationFormChoices,
} from "types/userOperations";

interface MembersToSendNotificationListProps {
  values: CreateNotificationPayload;
  formChoices: CreateNotificationFormChoices;
  formErrors: CreateNotificationFormErrors;
  validateFormChange: (values: CreateNotificationPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const MembersToSendNotificationList = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: MembersToSendNotificationListProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  /* Backend returns duplicate data, I had to remove duplicates by mapping them*/
  const uniqueUsersMap = new Map();
  Object.entries(formChoices.kullanicilar).forEach(([key, value]) => {
    if (!uniqueUsersMap.has(value.id)) {
      uniqueUsersMap.set(value.id, value);
    }
  });

  const options: Option[] = Array.from(uniqueUsersMap.values()).map(
    (value) => ({
      value: String(value.id),
      label: value.ad_soyad,
    })
  );

  useEffect(() => {
    const uniqueSelectedMap = new Map();
    (values.kullanici_idler || []).forEach((id) => {
      if (!uniqueSelectedMap.has(id)) {
        const member = formChoices.kullanicilar[id];
        uniqueSelectedMap.set(
          id,
          member
            ? {
                value: String(id),
                label: member.ad_soyad,
              }
            : {
                value: String(id),
                label: "",
              }
        );
      }
    });
    const selected = Array.from(uniqueSelectedMap.values());
    setSelectedOptions(selected);
  }, [formChoices.kullanicilar, values.kullanici_idler]);

  const handleSelectOptions = (selected: string[]) => {
    const uniqueSelected = Array.from(new Set(selected));
    const memberIds = uniqueSelected.map((id) => parseInt(id));

    dispatch(
      handleChangeInput({
        kullanici_idler: memberIds,
      })
    );
  };

  return (
    <div style={{ margin: "16px 0" }}>
      <CustomFormFieldContainer
        label={"Bildirilecekler : "}
        error={formErrors.kullanici_idler}
        orientation="vertical"
        required
        divider
      >
        <CustomDualListBox
          listOptions={options}
          selectedOptions={selectedOptions.map((option) => option.value)}
          handleSelectOptions={handleSelectOptions}
        />
      </CustomFormFieldContainer>
    </div>
  );
};

export default MembersToSendNotificationList;
