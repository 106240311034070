import { Spinner } from "reactstrap";

import { InfiniteSearchBar } from "Components/Custom";

import useHandleHotelSearchByName from "./useHandleHotelSearchByName";

const HotelSearchByName = () => {
  const { isLoading } = useHandleHotelSearchByName();

  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      {isLoading ? (
        <Spinner color="primary" className="me-2" />
      ) : (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <InfiniteSearchBar />
        </div>
      )}
    </div>
  );
};

export default HotelSearchByName;
