import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { CommissionPaymentInitialState } from "slices/financeAndAccounting/commissionPayment/reducer";
import { getCommissionPayment } from "slices/financeAndAccounting/commissionPayment/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useCommissionPayment = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { CommissionPayment: CommissionPaymentInitialState }) =>
      state.CommissionPayment
  );

  const { hotel, loading, error } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  useEffect(() => {
    if (otel_id) {
      dispatch(getCommissionPayment(otel_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading: isLoading || loading,
    error,
    isValidHotel,
  };
};

export default useCommissionPayment;
