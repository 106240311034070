import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { useState } from "react";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import GetReservationCollectionTableButton from "./FormFields/GetReservationCollectionTableButton";
import UpdatePaymentStatusTable from "./UpdatePaymentStatusTable";

const ReservationCollectionForm = () => {
  const [showUpdateCollectionForm, setShowUpdateCollectionForm] =
    useState(false);

  const { firstFormState, isLoading, onGetReservation, handleFirstFormChange } =
    useHandleForm(setShowUpdateCollectionForm);

  return (
    <>
      <CustomFormContainer title="Tahsilat Tablosu">
        <ReservationCodeInput
          values={firstFormState}
          handleFormChange={handleFirstFormChange}
        />
        <GetReservationCollectionTableButton
          handleSave={onGetReservation}
          values={firstFormState}
        />
        {showUpdateCollectionForm && (
          <div className="p-3">
            <CustomFormContainer title="Tahsilat Durumu Değişikliği">
              <span>
                İşlem Yapılan Rezervasyon No:{" "}
                <strong>{firstFormState.rez_kod}</strong>
              </span>
              <UpdatePaymentStatusTable
                isLoading={isLoading}
                setShowUpdateForm={setShowUpdateCollectionForm}
              />
            </CustomFormContainer>
          </div>
        )}
      </CustomFormContainer>
    </>
  );
};

export default ReservationCollectionForm;
