import { createSlice } from "@reduxjs/toolkit";
import {
  GeneralPriceSettings,
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
} from "types/PricesQuotas";

export interface GeneralPriceSettingsInitialState {
  generalPriceSettings: GeneralPriceSettings;
  generalPriceSettingsFormState: GeneralPriceSettingFormState;
  generalPriceSettingFormErrors: GeneralPriceSettingFormErrors;
  isLoading: boolean;
  error: string;
  saveSettingsLoading: boolean;
  saveSettingsError: string;
}

export const initialState: GeneralPriceSettingsInitialState = {
  generalPriceSettings: {
    calisma_sekli: "net",
    fiyat_giris_yontemi: "kisibasifiyat",
    para_birimi: "TRY",
    sabit_kur_degeri_durum: "0",
    komisyon_orani: "",
    komisyon_kumulatif_durum: "0",
    ozel_komisyon_orani: "",
    kickback_orani: "",
    kickback_kumulatif_durum: "0",
    indirim_orani: 0,
    gostermelik_indirim_orani: 0,
    iptal_sigortasi_durum: "0",
    iptal_sigortasi_gun: 0,
    kismi_odeme: "0",
    kismi_odeme_donemi_baslangic: "",
    kismi_odeme_donemi_bitis: "",
    kapida_odeme_donemi_baslangic: "",
    kapida_odeme_donemi_bitis: "",
    kaporali_odeme_donemi_baslangic: "",
    kaporali_odeme_donemi_bitis: "",
    kismi_odeme_gun_once: "0",
    kismi_odeme_gun_once_gun: 3,
    kismi_odeme_cekim_tarihi: "",
    kapida_odeme: "hayir",
    kapida_odeme_para_birimi_goster: "0",
    kapida_odeme_komisyon_orani: 0,
    kapida_komisyon_orani: "",
    Tesis_sinif: "sehir",
    odeme_tipi_gunu: 0,
    odeme_tipi: "giriste",
    sabit_doviz_USD: "",
    sabit_doviz_EUR: "",
    sabit_doviz_GBP: "",
    kaporali_satis: "hayir",
    kaporali_indirim_orani: 0,
    kaporali_satis_orani: "0",
    ozel_komisyona_bagli_indirim_orani: 0,
  },
  generalPriceSettingsFormState: {
    calisma_sekli: "net",
    fiyat_giris_yontemi: "kisibasifiyat",
    para_birimi: "TRY",
    sabit_kur_degeri_durum: false,
    sabit_doviz_USD: null,
    sabit_doviz_EUR: null,
    sabit_doviz_GBP: null,
    komisyon_orani: 0,
    komisyon_kumulatif_durum: false,
    ozel_komisyon_orani: 0,
    ozel_komisyona_bagli_indirim_orani: 0,
    ozel_komisyon_durum: false,
    ozel_komisyona_bagli_indirim_durum: false,
    kickback_orani: 0,
    kickback_kumulatif_durum: false,
    indirim_orani: 0,
    gostermelik_indirim_orani: 0,
    iptal_sigortasi_durum: "0",
    iptal_sigortasi_gun: 0,
    kismi_odeme: false,
    kismi_odeme_gun_once: 0,
    kismi_odeme_gun_once_gun: 0,
    kismi_odeme_cekim_tarihi: "",
    kismi_baslangic_donemi: "",
    kismi_bitis_donemi: "",
    kaporali_satis: "hayir",
    kaporali_satis_orani: "",
    kaporali_satis_baslangic_donemi: "",
    kaporali_satis_bitis_donemi: "",
    kaporali_indirim_orani: 0,
    kapida_odeme: "hayir",
    kapida_odeme_komisyon_orani: 0,
    kapida_odeme_para_birimi_goster: false,
    kapida_baslangic_donemi: "",
    kapida_bitis_donemi: "",
    kapida_odeme_indirim_orani: 0,
    kapida_komisyon_orani: 0,
    odeme_tipi: "giriste",
    odeme_tipi_gunu: 0,
    Tesis_sinif: "sehir",
  },
  generalPriceSettingFormErrors: {},
  isLoading: false,
  error: "",
  saveSettingsLoading: false,
  saveSettingsError: "",
};

const generalPriceSettingsSlice = createSlice({
  name: "generalPriceSetting",
  initialState,
  reducers: {
    handleGeneralPriceSettings(state, action) {
      const payload: GeneralPriceSettings = action.payload;
      state.generalPriceSettings = payload;

      state.generalPriceSettingsFormState = {
        calisma_sekli: payload.calisma_sekli,
        fiyat_giris_yontemi: payload.fiyat_giris_yontemi,
        para_birimi: payload.para_birimi,
        sabit_kur_degeri_durum: payload.sabit_kur_degeri_durum === "1",
        sabit_doviz_USD: parseFloat(payload.sabit_doviz_USD ?? 0),
        sabit_doviz_EUR: parseFloat(payload.sabit_doviz_EUR ?? 0),
        sabit_doviz_GBP: parseFloat(payload.sabit_doviz_GBP ?? 0),
        komisyon_orani: parseInt(payload.komisyon_orani ?? 0),
        komisyon_kumulatif_durum: payload.komisyon_kumulatif_durum === "1",
        ozel_komisyon_orani: parseInt(payload.ozel_komisyon_orani ?? 0),
        ozel_komisyona_bagli_indirim_orani:
          payload.ozel_komisyona_bagli_indirim_orani ?? 0,
        ozel_komisyon_durum: payload.ozel_komisyon_orani ? true : false,
        ozel_komisyona_bagli_indirim_durum:
          payload.ozel_komisyona_bagli_indirim_orani ? true : false,
        kickback_orani: parseInt(payload.kickback_orani ?? 0),
        kickback_kumulatif_durum: payload.kickback_kumulatif_durum === "1",
        indirim_orani: payload.indirim_orani,
        gostermelik_indirim_orani: payload.gostermelik_indirim_orani,
        iptal_sigortasi_durum: payload.iptal_sigortasi_durum,
        iptal_sigortasi_gun: payload.iptal_sigortasi_gun,
        kismi_odeme: payload.kismi_odeme === "1",
        kismi_odeme_gun_once: payload.kismi_odeme_gun_once === "1" ? 1 : 0,
        kismi_odeme_gun_once_gun: payload.kismi_odeme_gun_once_gun,
        kismi_odeme_cekim_tarihi: payload.kismi_odeme_cekim_tarihi,
        kismi_baslangic_donemi: payload.kismi_odeme_donemi_baslangic,
        kismi_bitis_donemi: payload.kismi_odeme_donemi_bitis,
        kaporali_satis: payload.kaporali_satis,
        kaporali_satis_orani: payload.kaporali_satis_orani,
        kaporali_satis_baslangic_donemi:
          payload.kaporali_odeme_donemi_baslangic,
        kaporali_satis_bitis_donemi: payload.kaporali_odeme_donemi_bitis,
        kaporali_indirim_orani: payload.kaporali_indirim_orani,
        kapida_odeme: payload.kapida_odeme,
        kapida_odeme_komisyon_orani: payload.kapida_odeme_komisyon_orani,
        kapida_odeme_para_birimi_goster:
          payload.kapida_odeme_para_birimi_goster === "1",
        kapida_baslangic_donemi: payload.kapida_odeme_donemi_baslangic,
        kapida_bitis_donemi: payload.kapida_odeme_donemi_bitis,
        kapida_odeme_indirim_orani: parseInt(
          payload.kapida_komisyon_orani ?? 0
        ),
        kapida_komisyon_orani: parseInt(payload.kapida_komisyon_orani ?? 0),
        odeme_tipi: payload.odeme_tipi,
        odeme_tipi_gunu: payload.odeme_tipi_gunu,
        Tesis_sinif: payload.Tesis_sinif,
      };
    },
    handleChangeGeneralPriceSettingsInput(state, action) {
      state.generalPriceSettingsFormState = {
        ...state.generalPriceSettingsFormState,
        ...action.payload,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSaveSettingsLoading(state, action) {
      state.saveSettingsLoading = action.payload;
    },
    setSaveSettingsError(state, action) {
      state.saveSettingsError = action.payload;
    },
    handleAddGeneralPriceSettingFormErrors(state, action) {
      state.generalPriceSettingFormErrors = action.payload;
    },
  },
});

export const {
  handleGeneralPriceSettings,
  handleChangeGeneralPriceSettingsInput,
  setIsLoading,
  setError,
  setSaveSettingsLoading,
  setSaveSettingsError,
  handleAddGeneralPriceSettingFormErrors,
} = generalPriceSettingsSlice.actions;

export default generalPriceSettingsSlice.reducer;
