import DailyCallsChart from "./DailyCallsCharts";
import FilterForm from "./FilterForm";

interface DailyCallsChartsPageProps {
  isLoading: boolean;
}

const DailyCallsChartsPage = ({ isLoading }: DailyCallsChartsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <DailyCallsChart isLoading={isLoading} />
    </div>
  );
};

export default DailyCallsChartsPage;
