import { SaveRoomPersonPriceRateState } from "types/PricesQuotas";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/pricesAndQuota/roomPersonPriceRates/reducer";

interface PricePerPersonProps {
  values: SaveRoomPersonPriceRateState;
}

const PricePerPerson = ({ values }: PricePerPersonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Kişi Başı Fiyatı"
        tooltipId="personPriceRate"
        description="(Örnek)"
      >
        <Input
          type="number"
          value={values.kisi_basi_fiyat}
          onChange={(e) => {
            dispatch(handleChangeInput({ kisi_basi_fiyat: e.target.value }));
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PricePerPerson;
