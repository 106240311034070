import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveIssuedInvoices, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_ISSUED_INVOICES } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetIssuedInvoicesParams {
  per_page: number;
  page: number;
  search: string;
  otel_id: number;
  yetkili_id: number;
  b_tarih: string;
  s_tarih: string;
}

export const getIssuedInvoices =
  (params: GetIssuedInvoicesParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_ISSUED_INVOICES, {
        params,
      });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveIssuedInvoices(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
