import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setToastError } from "slices/toast/reducer";
import { handleUpdateUserFormErrors } from "slices/userOperations/updateUser/reducer";
import { UpdateUserFormErrors, UpdateUserPayload } from "types/userOperations";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: UpdateUserPayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: UpdateUserFormErrors = {};

    if (!values.ad_soyad) {
      errors.ad_soyad = "Ad Soyad boş bırakılamaz.";
    }
    if (!values.dahili) {
      errors.dahili = "Dahili boş bırakılamaz.";
    }
    if (!values.email_adresi) {
      errors.email_adresi = "Email adresi boş bırakılamaz.";
    }
    if (!values.kullanici_adi) {
      errors.kullanici_adi = "Kullanıcı adı boş bırakılamaz.";
    }
    if (values.yetkiler.length <= 0) {
      errors.yetkiler = "En az bir yetki ekleyiniz.";
    }

    dispatch(handleUpdateUserFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: UpdateUserPayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
