import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/listPlacesToVisit/reducer";
import {
  UpdatePlaceToVisitFormErrors,
  UpdatePlaceToVisitState,
} from "types/definitions";
import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface PlaceToVisitContentProps {
  values: UpdatePlaceToVisitState;
  formErrors: UpdatePlaceToVisitFormErrors;
  validateFormChange: (values: UpdatePlaceToVisitState) => void;
}

const PlaceToVisitContent = ({
  values,
  validateFormChange,
  formErrors,
}: PlaceToVisitContentProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İçerik"
        error={formErrors.aciklama}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                ...values,
                aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PlaceToVisitContent;
