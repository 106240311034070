import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { handleChangeInput } from "slices/userOperations/updateGroup/reducer";
import {
  UpdateGroupFormErrors,
  UpdateGroupPayload,
} from "types/userOperations";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface GroupManagerSelectorProps {
  values: UpdateGroupPayload;
  groupManagerOptions: {
    value: number;
    label: string;
  }[];
  selectedManagerToChange: number;
  formErrors: UpdateGroupFormErrors;
  validateFormChange: (values: UpdateGroupPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const GroupManagerSelector = ({
  values,
  groupManagerOptions,
  selectedManagerToChange,
  formErrors,
  validateFormChange,
}: GroupManagerSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const managerOptions: Option[] = groupManagerOptions.map((manager) => ({
    value: String(manager.value),
    label: manager.label,
  }));

  const selectedOption = managerOptions.find(
    (option) => option.value === selectedManagerToChange?.toString()
  );

  return (
    <CustomFormFieldContainer
      label={"Grup Yöneticisi "}
      error={formErrors.grup_uyeleri_id}
      required
    >
      <Select
        options={managerOptions}
        value={selectedOption}
        onChange={(selected: Option) => {
          const managerId = selected ? Number(selected.value) : 0;
          dispatch(
            handleChangeInput({
              yetkili_id : managerId,
            })
          );
          validateFormChange({
            ...values,
            yetkili_id : managerId
          });
        }}
        placeholder="Yönetici seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default GroupManagerSelector;
