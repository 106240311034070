import { PERITO_COLORS } from "utils/peritoColors";

const legendCardProps = {
    "Aktif Hazırda": PERITO_COLORS.green,
    Görüşmede: PERITO_COLORS.red,
    Cevaplanan: PERITO_COLORS.teal,
    Kapatan: PERITO_COLORS.black,
    Çalıyor: PERITO_COLORS.blue,
    "Yemekte/Dışarıda": PERITO_COLORS.yellow,
    "Çevrimdışı/Kapalı": PERITO_COLORS.gray,
  };
  
  const ExtensionNumbersCardLegand = () => {
    return (
      <div className="d-flex align-items-center gap-4 py-3 px-4 bg-white rounded border">
        {Object.entries(legendCardProps).map(([tag, color]) => (
          <div key={tag}>
            <span style={{color:'white', 
                          background:color,
                          borderRadius:'2px',  
                          padding:'2px 12px'   
            }}>
                {tag}
            </span>
          </div>
        ))}
      </div>
    );
  };

  export default ExtensionNumbersCardLegand;