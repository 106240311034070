import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddUncancellableRoomRateFormErrors } from "slices/discountsAndActions/uncancellableRoomRates/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  UncancellableRoomRateFormErrors,
  UncancellableRoomRateState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: UncancellableRoomRateState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: UncancellableRoomRateFormErrors = {};

    if (!values.indirim_orani || values.indirim_orani === "0") {
      errors.indirim_orani = "İndirim oranı alanı boş bırakılamaz.";
    }

    if (Number(values.indirim_orani) > 100) {
      errors.indirim_orani = "İndirim oranı 100'den büyük olamaz.";
    }

    dispatch(handleAddUncancellableRoomRateFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: UncancellableRoomRateState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
