import CrmDashboardPage from "Components/Custom/DashboardPages/CrmDashboardPage";
import useCrmDashboard from "Components/Hooks/dashboard/useCrmDashboard";
import PageContainer from "pages/PageContainer";

const CrmDashboard = () => {
  const {
    isLoading,
  } = useCrmDashboard();

  return (
    <PageContainer title="Crm">
      <CrmDashboardPage isLoading={isLoading}/>
    </PageContainer>
  );
};

export default CrmDashboard;
