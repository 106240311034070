import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddStayLongPayLessActionFormErrors } from "slices/discountsAndActions/stayLongPayLessActions/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  StayLongPayLessActionFormErrors,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: StayLongPayLessActionState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: StayLongPayLessActionFormErrors = {};

    if (!values.baslangic_tarih || !values.bitis_tarih) {
      errors.konaklama_tarihi = "Başlangıç ve bitiş tarihleri boş bırakılamaz.";
    }

    if (values.tum_gunler === "0" && values.gunler.length === 0) {
      errors.gun = "En az bir gün seçilmelidir.";
    }
    if (values.kal_gun_sayisi === 0) {
      errors.kal_gece = "Kalınacak gece sayısı 0 olamaz.";
    }
    if (values.ode_gun_sayisi === 0) {
      errors.ode_gece = "Ödenecek gece sayısı 0 olamaz.";
    }
    if (values.kal_gun_sayisi < values.ode_gun_sayisi) {
      errors.ode_gece =
        "Ödenecek gece sayısı, kalınacak gece sayısından büyük olamaz.";
    }

    if (
      values.tum_otel_pansiyonlar === "0" &&
      values.otel_pansiyonlar.length === 0
    ) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }
    if (values.tum_odeme_tipleri === "0" && values.odeme_tipleri.length === 0) {
      errors.secili_odeme_tipleri = "En az bir ödeme tipi seçmelisiniz.";
    }

    dispatch(handleAddStayLongPayLessActionFormErrors(errors));
    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: StayLongPayLessActionState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
