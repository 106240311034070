import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
import {
  ReservationChangeApproveCancelPayload,
} from "types/userOperations";

interface Option {
    value: string;
    label: string;
};

interface OperationOptionsProps {
  values: ReservationChangeApproveCancelPayload;
  formChoices: Option[];  
  handleFormChange: (key: string, value: string) => void;
}

const OperationOptions = ({
  values,
  formChoices,
  handleFormChange,
}: OperationOptionsProps) => {

  return (
    <>
      <CustomFormFieldContainer
        label="İşlem "
        required
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {formChoices.map((option) => (
            <div
              key={option.value}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 12px 8px 12px",
                gap: "2px",
              }}
            >
              <Input
                id={option.value}
                checked={values.islem === option.value}
                type="radio"
                autoCorrect="on"
                value={option.value}
                onChange={(e) => {
                    handleFormChange('islem', e.target.value);
                }}
              />
              <Label
                check
                className="text-xs fw-normal"
                style={{
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
                htmlFor={option.value}
              >
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default OperationOptions;
