import { BasicPriceEntryPage } from "Components/Custom/PricesAndQuotaPages";
import useBasicPriceEntry from "Components/Hooks/pricesAndQuota/useBasicPriceEntry";
import PageContainer from "pages/PageContainer";

const BasicPriceEntry = () => {
  const { pageData, error, loading, savingPrice } = useBasicPriceEntry();

  const isValidHotel =
    !!pageData.otel_id &&
    pageData.odalar.length > 0 &&
    pageData.odalar[0].pansiyonlar.length > 0;

  return (
    <PageContainer title="Basit Fiyat Girişi">
      <BasicPriceEntryPage
        isValidHotel={isValidHotel}
        loading={loading}
        error={error}
        data={true}
        pageData={pageData}
        savingPrice={savingPrice}
      />
    </PageContainer>
  );
};

export default BasicPriceEntry;
