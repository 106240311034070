interface CalendarItem {
  id: number;
  link: string;
  class: string;
  icon: string;
  title: string;
  subtitle: string;
}
export const CALENDAR_ITEMS: CalendarItem[] = [
  {
    id: 1,
    link: "/basit-fiyat-girisi",
    icon: "price-tag-2-fill",
    title: "Basit Fiyat Girişi",
    class: "bg-primary shadow-none bg-opacity-50",
    subtitle: "Tek Seferde Fiyat Girişi",
  },
  {
    id: 2,
    link: "/basit-kontenjan-girisi",
    icon: "calendar-event-fill",
    class: "bg-warning shadow-none bg-opacity-50",
    title: "Basit Kontenjan Girişi",
    subtitle: "Tek Seferde Kontenjan Girişi",
  },
  {
    id: 3,
    link: "/basit-musaitlik-girisi",
    icon: "calendar-check-fill",
    class: "bg-success shadow-none bg-opacity-50",
    title: "Basit Müsaitlik Girişi",
    subtitle: "Tek Seferde Müsaitlik Girişi",
  },
  {
    id: 4,
    link: "/gelismis-giris",
    icon: "settings-3-fill",
    class: "bg-danger shadow-none bg-opacity-50",
    title: "Gelişmiş Giriş",
    subtitle: "Gelişmiş Fiyat - Kontenjan Ayarları",
  },
];
