import { useSelector } from "react-redux";

import { DailyCallsPerPersonInitialState } from "slices/reports/dailyCallsPerPerson/reducer";

const useHandleTable = () => {
  const { dailyCallsPerPerson } = useSelector(
    (state: { DailyCallsPerPerson: DailyCallsPerPersonInitialState }) =>
      state.DailyCallsPerPerson
  );

  const tableData = dailyCallsPerPerson?.map((dailyCall) => ({
    "Kullanıcı ID": dailyCall.crm_kul_id,
    "Adı Soyadı": dailyCall.uye_ad_soyad,
    "Grup Adı": dailyCall.grup_adi,
    "Cevaplanan Çağrı Sayısı": dailyCall.aranma_sayi,
    "Giden Çağrı Sayısı": dailyCall.arama_sayi,
    "Takip Edilen Cevapsız Çağrı": dailyCall.cevapsiz_sayi,
    "Rezervasyon Sayısı": dailyCall.rezervasyon_sayisi,
    "Rezervasyon Miktarı": dailyCall.toplam_cekilen,
    "Sorsat Sayısı": dailyCall.sorsat_sayi,
    "Basket Oranı": dailyCall.basket_orani,
  }));

  const tableColumns = [
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Grup Adı",
      accessorKey: "Grup Adı",
      enableColumnFilter: false,
    },
    {
      header: "Cevaplanan Çağrı Sayısı",
      accessorKey: "Cevaplanan Çağrı Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Giden Çağrı Sayısı",
      accessorKey: "Giden Çağrı Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Takip Edilen Cevapsız Çağrı",
      accessorKey: "Takip Edilen Cevapsız Çağrı",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Sayısı",
      accessorKey: "Rezervasyon Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Miktarı",
      accessorKey: "Rezervasyon Miktarı",
      enableColumnFilter: false,
    },
    {
      header: "Sorsat Sayısı",
      accessorKey: "Sorsat Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Basket Oranı",
      accessorKey: "Basket Oranı",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
