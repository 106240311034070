import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import { NotificationState } from "types/userOperations";

export interface NotificationsInitialState {
  notifications: NotificationState[];
  notification: NotificationState;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

export const notificationsInitialState: NotificationsInitialState = {
    notifications: [],
    notification: {
      id: 0,
      tarih: "",
      okundu_durum: "",
      baslik: "",
      bildirim: "",
      tipi: "",
    },
    pagination: {
      current_page: 0,
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [],
      next_page_url: null,
      path: "",
      per_page: 10,
      prev_page_url: null,
      to: 0,
      total: 0,
    },
    isLoading: false,
    error: "",
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: notificationsInitialState,
  reducers: {
    handleSaveNotifications(state, action) {
      const { data, ...rest } = action.payload;
      state.notifications = data; 
      state.pagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveNotifications, setIsLoading, setError } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
