import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AddHotelFeatureRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { AddHotelFeatureInitialState } from "slices/definitions/addHotelFeature/reducer";

const useHandleForm = () => {
  const selectedAddHotelFeatureState = (state: {
    AddHotelFeature: AddHotelFeatureInitialState;
  }) => state.AddHotelFeature;

  const selectAddHotelFeatureProperties = createSelector(
    selectedAddHotelFeatureState,
    (state) => ({
      hotelFeature: state.hotelFeature,
      addHotelFeatureFormErrors: state.addHotelFeatureFormErrors,
      isLoading: state.isLoading,
      error: state.error,
    })
  );

  const { hotelFeature, addHotelFeatureFormErrors, isLoading, error } =
    useSelector(selectAddHotelFeatureProperties);

  const addNewHotelFeatureRequestPayload: AddHotelFeatureRequestPayload = {
    ozellik_adi: hotelFeature.ozellik_adi,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    hotelFeature,
    isLoading,
    error,
    addNewHotelFeatureRequestPayload,
    formErrors: addHotelFeatureFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
