import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { PhoneStatusListInitialState } from "slices/reports/phoneStatusList/reducer";
import { getPhoneStatusList } from "slices/reports/phoneStatusList/thunk";

const usePhoneStatusList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { PhoneStatusList: PhoneStatusListInitialState }) =>
      state.PhoneStatusList
  );

  useEffect(() => {
    const payload = {
      date: moment().format("YYYY-MM-DD"),
    };
    dispatch(getPhoneStatusList(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default usePhoneStatusList;
