import { createSlice } from "@reduxjs/toolkit";

import {
  GiftAndPromotion,
  GiftAndPromotionFormErrors,
  GiftAndPromotionListItem,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

import moment from "moment";

export interface GiftsAndPromotionsInitialState {
  giftsAndPromotionsList: GiftAndPromotionListItem[];
  giftsAndPromotions: GiftAndPromotion[];
  giftsAndPromotion: GiftAndPromotionState;
  giftsAndPromotionFormErrors: GiftAndPromotionFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addPromotionLoading: boolean;
  addPromotionError: string;
  updatePromotionLoading: boolean;
  updatePromotionError: string;
  deletePromotionLoading: boolean;
  deletePromotionError: string;
}

export const initialState: GiftsAndPromotionsInitialState = {
  giftsAndPromotionsList: [],
  giftsAndPromotions: [],
  giftsAndPromotion: {
    id: 0,
    otel_id: 0,
    otel_oda_id: 0,
    hediye_kosulu: 1,
    konaklama_baslangic_tarihi: moment().format("YYYY-MM-DD"),
    konaklama_bitis_tarihi: moment().add(1, "month").format("YYYY-MM-DD"),
    tum_gunler: "1",
    gunler: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    min_gece_sayisi: 0,
    hediye_id: 0,
    indirim_orani: 100,
    gecerli_kisi_sayisi: 99,
    grup_id: 0,
  },
  giftsAndPromotionFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addPromotionLoading: false,
  addPromotionError: "",
  updatePromotionLoading: false,
  updatePromotionError: "",
  deletePromotionLoading: false,
  deletePromotionError: "",
};

const giftsAndPromotionsSlice = createSlice({
  name: "giftsAndPromotions",
  initialState,
  reducers: {
    handleSaveGiftsAndPromotionsList: (state, action) => {
      state.giftsAndPromotionsList = action.payload;
    },
    handleSaveGiftsAndPromotions: (state, action) => {
      state.giftsAndPromotions = action.payload;
    },
    handleDeleteGiftsAndPromotionWithId: (state, action) => {
      state.giftsAndPromotions = state.giftsAndPromotions.filter(
        (giftAndPromotion) => giftAndPromotion.id !== action.payload
      );
    },
    handleSaveGiftsAndPromotionForEdit: (state, action) => {
      state.giftsAndPromotion = action.payload;
    },
    handleChangeInput: (state, action) => {
      state.giftsAndPromotion = {
        ...state.giftsAndPromotion,
        ...action.payload,
      };
    },
    handleAddGiftsAndPromotionFormErrors: (state, action) => {
      state.giftsAndPromotionFormErrors = action.payload;
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    resetGiftsAndPromotions: (state) => {
      state.giftsAndPromotions = [];
    },
    resetGiftsAndPromotion: (state) => {
      state.giftsAndPromotion = {
        id: 0,
        otel_id: 0,
        otel_oda_id: 0,
        hediye_kosulu: 1,
        konaklama_baslangic_tarihi: moment().format("YYYY-MM-DD"),
        konaklama_bitis_tarihi: moment().add(1, "month").format("YYYY-MM-DD"),
        tum_gunler: "1",
        gunler: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        min_gece_sayisi: 0,
        hediye_id: 0,
        indirim_orani: 100,
        gecerli_kisi_sayisi: 99,
        grup_id: 0,
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddPromotionLoading: (state, action) => {
      state.addPromotionLoading = action.payload;
    },
    setAddPromotionError: (state, action) => {
      state.addPromotionError = action.payload;
    },
    setUpdatePromotionLoading: (state, action) => {
      state.updatePromotionLoading = action.payload;
    },
    setUpdatePromotionError: (state, action) => {
      state.updatePromotionError = action.payload;
    },
    setDeletePromotionLoading: (state, action) => {
      state.deletePromotionLoading = action.payload;
    },
    setDeletePromotionError: (state, action) => {
      state.deletePromotionError = action.payload;
    },
  },
});

export const {
  handleSaveGiftsAndPromotionsList,
  handleSaveGiftsAndPromotions,
  handleDeleteGiftsAndPromotionWithId,
  handleSaveGiftsAndPromotionForEdit,
  handleChangeInput,
  handleAddGiftsAndPromotionFormErrors,
  resetGiftsAndPromotions,
  resetGiftsAndPromotion,
  handleSelectRoom,
  setIsLoading,
  setError,
  setAddPromotionLoading,
  setAddPromotionError,
  setUpdatePromotionLoading,
  setUpdatePromotionError,
  setDeletePromotionLoading,
  setDeletePromotionError,
} = giftsAndPromotionsSlice.actions;

export default giftsAndPromotionsSlice.reducer;
