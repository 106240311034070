import { useEffect } from "react";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";

const useDiscounts = () => {
  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  useEffect(() => {
    if (otel_id) {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    error,
    loading,
    isValidHotel,
  };
};

export default useDiscounts;
