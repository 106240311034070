import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { DailyReservationsInitialState } from "slices/reports/dailyReservations/reducer";
import { getDailyReservations } from "slices/reports/dailyReservations/thunk";

const useDailyReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { DailyReservations: DailyReservationsInitialState }) =>
      state.DailyReservations
  );

  const params = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    dispatch(getDailyReservations(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useDailyReservations;
