import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "react";
import {
  PersonnelHotelAssignmentsInitialState,
  handleSelectedUsersToChange,
} from "slices/userOperations/personnelHotelAssignments/reducer";
import { listAssignedHotelsOfUser } from "slices/userOperations/personnelHotelAssignments/thunk";
import useValidateForm from "./useValidateForm";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    changeHotelAssignee,
    changeHotelAssigneeFormChoices,
    selectedUsersToChange,
    selectedHotelsToChange,
    changeHotelAssigneeLoading,
    changeHotelAssigneeFormErrors,
  } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const changeeHotelAssigneePayload = new FormData();

  const handleSelectCurrentUser = (id: number) => {
    dispatch(handleSelectedUsersToChange({ index: 0, userId: id }));
    dispatch(listAssignedHotelsOfUser({ personel_id: id }));
  };

  const handleSelectNewUser = (id: number) => {
    dispatch(handleSelectedUsersToChange({ index: 1, userId: id }));
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    handleSelectCurrentUser,
    handleSelectNewUser,
    changeeHotelAssigneePayload,
    changeHotelAssignee,
    changeHotelAssigneeFormChoices,
    selectedUsersToChange,
    selectedHotelsToChange,
    changeHotelAssigneeLoading,
    formErrors: changeHotelAssigneeFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
