import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { useSelector } from "react-redux";

import SendMailForm from "./SendMailForm";
import ForgotPasswordFormHeader from "./ForgotPasswordFormHeader";
import ResetPasswordForm from "./ResetPasswordForm";

import logoLight from "../../../../assets/images/logo-light.png";

import { Router } from "types";

const ForgotPasswordPage = (props: Router) => {
  const { isResetCodeSent } = useSelector((state: any) => state.ForgetPassword);
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Card className="mt-4">
            <CardBody className="p-4">
              <ForgotPasswordFormHeader
                logo={logoLight}
                subtitle="Şifre sıfırlama için e-posta adresinizi girin."
                showSubtitle={!isResetCodeSent}
              />
              {isResetCodeSent ? (
                <ResetPasswordForm {...props} />
              ) : (
                <SendMailForm />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
