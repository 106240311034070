import { createSlice } from "@reduxjs/toolkit";

import { PhoneStatusList } from "types/reports";

export interface PhoneStatusListInitialState {
  phoneStatusList: PhoneStatusList[];
  isLoading: boolean;
  error: string;
}

const initialState: PhoneStatusListInitialState = {
  phoneStatusList: [],
  isLoading: false,
  error: "",
};

const phoneStatusListSlice = createSlice({
  name: "phoneStatusList",
  initialState,
  reducers: {
    handleSavePhoneStatusList(state, action) {
      state.phoneStatusList = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSavePhoneStatusList, setIsLoading, setError } =
  phoneStatusListSlice.actions;

export default phoneStatusListSlice.reducer;
