import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { handleSaveUserIpsResponse, setIsLoading, setError } from "./reducer";
import { setToastError } from "slices/toast/reducer";
import { GET_USER_IPS } from "helpers/url_helper";
import { Dispatch } from "redux";

export const getUserIPs = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_USER_IPS);
    const res = (response as any).message ? response.data : response;

    dispatch(handleSaveUserIpsResponse(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
