import { useDispatch, useSelector } from "react-redux";
import { NotificationsInitialState } from "slices/userOperations/notifications/reducer";
import useFormatNotificationData from "./useFormatNotificationData";
import { UnknownNotification } from "types/userOperations";
import { Dispatch, useMemo, useState } from "react";
import {
  getNotifications,
  readNotifications,
} from "slices/userOperations/notifications/thunk";

/* 

  * This hook gets notifications and formats them, 
  * dispatches readNotification function,
  * and filters form with local states according to their "read" situation

*/

const useHandleNotifications = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { notifications, isLoading } = useSelector(
    (state: { Notifications: NotificationsInitialState }) => state.Notifications
  );

  const { onTypeObject, onTypeString, onTypeHTMLTable, onTypeHTMLLink } =
    useFormatNotificationData();

  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);

  const filteredNotifications = useMemo(() => {
    if (showOnlyUnread) {
      return notifications?.filter(
        (notification) => notification.okundu_durum === "0"
      );
    }
    return notifications;
  }, [notifications, showOnlyUnread]);

  const formattedNotifications = filteredNotifications?.map((notification) => {
    switch (notification.baslik) {
      case "Otomatik İşlem Bildirimi":
        return onTypeObject(notification);
      case "Fiyatı bitecek olan odalarınız mevcut!":
      case "Yeni Sorsat Talebi":
      case "Yetkilisi Olduğun Otele Sorsat Talebi":
        return onTypeHTMLTable(notification);
      case "Yeni Bir Rezervasyon Eklendi":
        return onTypeHTMLLink(notification);
      case "Rezervasyon iptal edildi":
      case "Rezervasyonda düzenleme yapıldı":
      case "Önemli Duyuru2":
        return onTypeString(notification);
      default:
        const unknownNotification: UnknownNotification = {
          ...notification,
          contentType: "unknown",
        };
        return unknownNotification;
    }
  });

  const markAsRead = (id: number) => {
    dispatch(
      readNotifications({ id }, () => {
        dispatch(
          getNotifications({
            per_page: 10,
            search: "",
          })
        );
      })
    );
  };

  const toggleShowOnlyUnread = () => {
    setShowOnlyUnread(prev => !prev);
  };


  return {
    isLoading,
    notifications: formattedNotifications,
    markAsRead,
    showOnlyUnread,
    toggleShowOnlyUnread,
  };
};

export default useHandleNotifications;
