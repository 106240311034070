import { useSelector } from "react-redux";
import { UpdateFacilityInitialState } from "slices/facility/updateFacility/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { facilityFormChoices } = useSelector(
    (state: { UpdateFacility: UpdateFacilityInitialState }) =>
      state.UpdateFacility
  );
  const welcomeCodeOptions: Option[] = facilityFormChoices[
    "Karşılama_Kodu"
  ].map((choice: string) => ({
    value: choice,
    label: choice.charAt(0).toUpperCase() + choice.slice(1),
  }));

  const priceCalculationSupplierOptions: Option[] = facilityFormChoices[
    "Fiyat Hesaplama"
  ].map((choice: string) => ({
    value: choice,
    label: choice.charAt(0).toUpperCase() + choice.slice(1),
  }));

  return {
    welcomeCodeOptions,
    priceCalculationSupplierOptions,
  };
};

export default useHandleForm;
