import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ListGalleryImagesTableProps {
  isLoading: boolean;
}

const ListGalleryImagesTable = ({
  isLoading,
}: ListGalleryImagesTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  return (
    <>
      <CustomReactTable
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
      />
    </>
  );
};

export default ListGalleryImagesTable;
