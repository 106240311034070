import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeUpdateFacilityUserFormInput } from "slices/facility/facilityUsers/reducer";
import { FacilityUserState, FacilityUserFormErrors } from "types/facility";
import { FACILITY_USER_USERNAME_DESCRIPTION } from "utils/fieldDescriptions";

interface UserFieldsProps {
  values: FacilityUserState;
  formErrors: FacilityUserFormErrors;
  validateFormChange: (values: FacilityUserState) => void;
}

const UserFields = ({
  values,
  formErrors,
  validateFormChange,
}: UserFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="INET Kodu" error={formErrors.inet_kodu}>
        <Input
          type="text"
          value={values.inet_kodu}
          onChange={(e) => {
            dispatch(
              handleChangeUpdateFacilityUserFormInput({
                inet_kodu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              inet_kodu: e.target.value,
            });
          }}
          disabled={!!values.id}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Kullanıcı Adı"
        error={formErrors.kullanici_adi}
        description={FACILITY_USER_USERNAME_DESCRIPTION}
      >
        <Input
          type="text"
          value={values.kullanici_adi}
          onChange={(e) => {
            dispatch(
              handleChangeUpdateFacilityUserFormInput({
                kullanici_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              kullanici_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Kullanıcı Aktif mi?">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.aktiflik === "1"}
            onChange={() => {
              dispatch(
                handleChangeUpdateFacilityUserFormInput({
                  aktiflik: values.aktiflik === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default UserFields;
