import { Card, CardBody, Col, Spinner } from "reactstrap";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface CustomDashboardCardProps {
  children: React.ReactNode;
  color?: keyof typeof DASHBOARD_COLORS;
  isGradient?: boolean;
  title?: string;
  titleInfo?: JSX.Element;
  titleInfoLocation?: "right" | "left";
  textColor?: string;
  lg?: number;
  xl?: number;
  isLoading?: boolean;
}

const getContrastingTextColor = (bgColor: string): string => {
  const color = bgColor.replace("#", "");
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  // YIQ formulu
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#FFFFFF";
};

const lightenColor = (color: string, percent: number): string => {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;

  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const CustomDashboardCard = ({
  children,
  color = "white",
  isGradient = false,
  title,
  titleInfo,
  textColor,
  lg = 3,
  xl = 3,
  isLoading = false,
  titleInfoLocation = "right",
}: CustomDashboardCardProps) => {
  const colorCode = DASHBOARD_COLORS[color];

  let backgroundStyle: React.CSSProperties = {};

  if (isGradient) {
    const lighterColor = lightenColor(colorCode, 20);
    backgroundStyle.background = `radial-gradient(circle, ${colorCode} 0%, ${lighterColor} 100%)`;
  } else {
    backgroundStyle.backgroundColor = colorCode;
  }

  const textColorAccToBackground =
    textColor || getContrastingTextColor(colorCode);

  return (
    <Col lg={lg} xl={xl}>
      <Card style={backgroundStyle}>
        <CardBody
          className="d-flex flex-column gap-3 py-3 px-4"
          style={{ color: textColorAccToBackground }}
        >
          {titleInfoLocation === "right" ? (
            <div className="d-flex justify-content-between">
              {title && (
                <h6 className="card-title" style={{ fontSize: "14px" }}>
                  {title}
                </h6>
              )}
              {titleInfo && (
                <h6 style={{ opacity: 0.7, fontSize: "16px" }}>{titleInfo}</h6>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-start gap-2">
              {titleInfo && (
                <h6 style={{ opacity: 0.7, fontSize: "16px" }}>{titleInfo}</h6>
              )}
              {title && (
                <h6 className="card-title" style={{ fontSize: "16px" }}>
                  {title}
                </h6>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </div>
          ) : (
            children
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomDashboardCard;
