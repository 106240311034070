import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import ListItem from "../ListItem";

interface ReservationPaymentInfoProps {
  reservation: ReservationReview;
}

const ReservationInvoiceInfo = ({
  reservation,
}: ReservationPaymentInfoProps) => {
  const {
    fatura_unvani,
    fatura_no,
    fatura_adresi,
    vergi_dairesi,
    vergi_no,
    aciklama,
    muhasebe_bilgileri,
    finans_bilgileri,
  } = reservation;

  const customerInvoiceNumber = muhasebe_bilgileri
    ? muhasebe_bilgileri.misafir_fatura_no
    : "";

  return (
    <CustomFormContainer title="Fatura Bilgileri">
      {customerInvoiceNumber ? (
        <span className="theme-text-danger fw-bold">
          Fatura No: {customerInvoiceNumber}
        </span>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <ListItem title="Fatura Ünvanı" value={fatura_unvani} topBorder />
        <ListItem title="Fatura No" value={fatura_no} topBorder />
        <ListItem title="Fatura Adresi" value={fatura_adresi} />
        <ListItem title="Vergi Dairesi" value={vergi_dairesi} />
        <ListItem title="Vergi No" value={vergi_no} />
        <ListItem title="Açıklama" value={aciklama} />
        <ListItem
          title="Otele Ödeme Günü"
          value={muhasebe_bilgileri?.otele_odeme_gunu}
        />
        <ListItem
          title="Orjinal Maliyet Tutar"
          value={
            muhasebe_bilgileri?.toplam_otel_maliyet_tutar_kur +
            " " +
            finans_bilgileri?.kur
          }
        />
        {reservation.komisyon_faturali_rezervasyon === "1" && (
          <p className="theme-text-danger fw-bold mt-3">
            Tam Ücret Ödemeli (Komisyon Faturalı)
          </p>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default ReservationInvoiceInfo;
