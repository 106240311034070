import { ReservationReviewCreditCard } from "types/reservation";

const useHandlePayment = () => {
  const handleGetCreditCardPayment = (card: ReservationReviewCreditCard) => {};
  const handleFalseCreditCardInfo = (card: ReservationReviewCreditCard) => {};

  return {
    onGetCreditCardPayment: handleGetCreditCardPayment,
    onFalseCreditCardInfo: handleFalseCreditCardInfo,
  };
};

export default useHandlePayment;
