import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import PlaceToVisitTitle from "./PlacesToVisitTitle";
import PlaceToVisitContent from "./PlaceToVisitContent";
import PlaceToVisitImage from "./PlaceToVisitImage";
import UpdatePlaceToVisitButton from "./UpdatePlaceToVisitButton";

const UpdatePlaceToVisitForm = () => {
  const {
    placeToVisit,
    updatePlaceToVisitRequestPayload,
    updatePlaceToVisitLoading,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();
  return (
    <CustomFormContainer>
      <PlaceToVisitTitle
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <PlaceToVisitContent
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <PlaceToVisitImage
        values={placeToVisit}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdatePlaceToVisitButton
        values={placeToVisit}
        requestPayload={updatePlaceToVisitRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={updatePlaceToVisitLoading}
      />
    </CustomFormContainer>
  );
};

export default UpdatePlaceToVisitForm;
