import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveHotels,
  handleSaveThemes,
  setAddHotelToThemeLoading,
  setAddHotelToThemeError,
} from "./reducer";

import {
  GET_HOTELS_FOR_THEME,
  ADD_HOTEL_TO_THEME,
  GET_THEMES,
} from "helpers/url_helper";

import { AssignHotelToThemeRequestPayload } from "types/themes";

import { Dispatch } from "redux";
import { setToastSuccess, setToastError } from "slices/toast/reducer";

interface GetHotelsForThemePayload {
  tema_id: number;
}
type SuccessCallback = () => void;

export const getThemes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEMES);

    dispatch(handleSaveThemes(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getHotelsForTheme =
  (payload: GetHotelsForThemePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_HOTELS_FOR_THEME, {
        params: {
          tema_id: payload.tema_id,
        },
      });

      dispatch(handleSaveHotels(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addHotelToTheme =
  (
    payload: AssignHotelToThemeRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddHotelToThemeLoading(true));
      await axios.post(ADD_HOTEL_TO_THEME, payload);

      successCallback();
      dispatch(setToastSuccess("Otel temaya eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddHotelToThemeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddHotelToThemeLoading(false));
    }
  };
