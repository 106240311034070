import ListCommentsPage from "Components/Custom/CommentsPages/ListCommentsPage";
import useListComments from "Components/Hooks/comments/useListComments";
import PageContainer from "pages/PageContainer";

const ListComments = () => {
    const { isLoading, commenterOptions } = useListComments();

    return (
        <PageContainer title={"Personel Yorumlarını Listele"}>
            <ListCommentsPage isLoading={isLoading} commenterOptions={commenterOptions} />
        </PageContainer>
    )
}

export default ListComments;