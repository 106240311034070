import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import useHandleForm from "./useHandleForm";
import Select from "react-select";
import { Collapse, Input, Label } from "reactstrap";
import { useState } from "react";

interface Option {
  label: string;
  value: number | string;
}
const FilterForm = () => {
  const {
    filterForm,
    handleFormChange,
    handleWelcomeCodeChange,
    staffOptions,
    providerOptions,
    calculateDateTypeOptions,
    cityOptions,
    districtOptions,
    welcomeCodeOptions,
    isLoading,
    onGetFilteredList,
    onGetDistricts,
    onResetFilter,
    onResetDistricts,
  } = useHandleForm();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <CustomFormContainer>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer bg-secondary py-1 px-3 text-white rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="fs-18 fw-bold m-0 ">Sonuçları Filtrele</p>
        <i className={`ri-arrow-${isOpen ? "up" : "down"}-s-line fs-24`}></i>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column gap-2">
          <CustomFormFieldContainer label="Tarih">
            <Flatpickr
              className="form-control"
              value={[filterForm.baslangic_tarihi, filterForm.bitis_tarihi]}
              onChange={(dates) => {
                const [startDate, endDate] = dates;

                if (startDate && endDate) {
                  const stringStartDate =
                    moment(startDate).format("YYYY-MM-DD");
                  const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                  handleFormChange("baslangic_tarihi", stringStartDate);
                  handleFormChange("bitis_tarihi", stringEndDate);
                }
              }}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                showMonths: 2,
                locale: Turkish,
                onDayCreate,
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Personel">
            <Select
              options={staffOptions}
              value={staffOptions.find(
                (option) => option.value === filterForm.personel_id
              )}
              onChange={(selectedOption: Option) => {
                handleFormChange("personel_id", String(selectedOption.value));
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Fiyat Sağlayıcı">
            <Select
              options={providerOptions}
              value={providerOptions.find(
                (option) => option.value === filterForm.fiyat_saglayici
              )}
              onChange={(selectedOption: Option) => {
                handleFormChange(
                  "fiyat_saglayici",
                  String(selectedOption.value)
                );
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Hesaplama Tarih Tipi">
            <Select
              options={calculateDateTypeOptions}
              value={calculateDateTypeOptions.find(
                (option) => option.value === filterForm.hesaplama_tarih_tipi
              )}
              onChange={(selectedOption: Option) => {
                handleFormChange(
                  "hesaplama_tarih_tipi",
                  String(selectedOption.value)
                );
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Şehir">
            <Select
              options={cityOptions}
              value={cityOptions.find(
                (option) => option.value === filterForm.sehir_id
              )}
              onChange={(selectedOption: Option) => {
                handleFormChange("sehir_id", String(selectedOption.value));
                onGetDistricts(Number(selectedOption.value));
                onResetDistricts();
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="İlçe">
            <Select
              options={districtOptions}
              value={districtOptions.find(
                (option) => option.value === filterForm.ilce_id
              )}
              onChange={(selectedOption: Option) => {
                handleFormChange("ilce_id", String(selectedOption.value));
              }}
            />
          </CustomFormFieldContainer>
          <CustomFormFieldContainer
            label="Karşılama Kodu"
            orientation="vertical"
          >
            <div className="d-flex gap-3">
              {welcomeCodeOptions.map((welcomeCodeOption, index) => (
                <div key={index} className="d-flex gap-2">
                  <Input
                    key={index}
                    id={"karsilama_kodu_" + index}
                    className="cursor-pointer"
                    type="checkbox"
                    checked={filterForm.karsilama_kodu.includes(
                      String(welcomeCodeOption.value)
                    )}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      handleWelcomeCodeChange(
                        String(welcomeCodeOption.value),
                        isChecked
                      );
                    }}
                    value={welcomeCodeOption.value}
                  />
                  <Label
                    className="cursor-pointer m-0 fw-normal"
                    for={"karsilama_kodu_" + index}
                  >
                    {welcomeCodeOption.label}
                  </Label>
                </div>
              ))}
            </div>
          </CustomFormFieldContainer>
          <div className="w-100 d-flex justify-content-end gap-2 mt-4">
            <CustomButton variant="warning" onClick={onResetFilter}>
              Filtreyi Temizle
            </CustomButton>
            <CustomButton
              variant="success"
              onClick={onGetFilteredList}
              isLoading={isLoading}
            >
              Sorgula
            </CustomButton>
          </div>
        </div>
      </Collapse>
    </CustomFormContainer>
  );
};

export default FilterForm;
