import CustomerMailsTable from "./CustomerMailsTable";
import FilterForm from "./FilterForm";

interface CustomerMailsPageProps {
  isLoading: boolean;
}

const CustomerMailsPage = ({ isLoading }: CustomerMailsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <CustomerMailsTable isLoading={isLoading} />
    </div>
  );
};

export default CustomerMailsPage;
