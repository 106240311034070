interface CustomRoomSelectorButtonProps {
  selected: boolean;
  label: string;
  htmlFor: string;
}

const CustomRoomSelectorButton = ({
  selected,
  label,
  htmlFor,
}: CustomRoomSelectorButtonProps) => {
  const buttonStyle = {
    backgroundColor: selected
      ? "var(--theme-success)"
      : "var(--theme-secondary)",
    color: selected ? "white" : "black",
    border: selected
      ? "1px solid var(--theme-success)"
      : "1px solid var(--theme-secondary)",
  };

  return (
    <label className="btn text-nowrap" style={buttonStyle} htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default CustomRoomSelectorButton;
