import moment from "moment";
import { useSelector } from "react-redux";
import { GiftAndPromotionRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { GiftsAndPromotionsInitialState } from "slices/discountsAndActions/giftsAndPromotions/reducer";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    giftsAndPromotionsList,
    giftsAndPromotion,
    giftsAndPromotionFormErrors,
    addPromotionLoading,
    updatePromotionLoading,
  } = useSelector(
    (state: { GiftsAndPromotions: GiftsAndPromotionsInitialState }) =>
      state.GiftsAndPromotions
  );

  const promotionOptions: Option[] = giftsAndPromotionsList.map(
    (promotion) => ({
      value: promotion.id,
      label: promotion.hediye_adi,
    })
  );

  promotionOptions.unshift({ value: 0, label: "Seçiniz" });

  const promotionRateOptions: Option[] = Array.from(
    { length: 99 },
    (_, i) => i + 1
  ).map((rate) => ({
    value: rate,
    label: `%${rate}`,
  }));

  promotionRateOptions.unshift({ value: 100, label: "Ücretsiz" });

  const promotionPersonOptions: Option[] = Array.from(
    { length: 10 },
    (_, i) => i + 1
  ).map((person) => ({
    value: person,
    label: `${person} Kişi`,
  }));

  promotionPersonOptions.unshift({ value: 99, label: "Odadaki Herkes" });

  const startDate = moment(giftsAndPromotion.konaklama_baslangic_tarihi).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(giftsAndPromotion.konaklama_bitis_tarihi).format(
    "YYYY-MM-DD"
  );

  const addNewGiftsAndPromotionRequestPayload: GiftAndPromotionRequestPayload =
    {
      otel_id,
      otel_oda_id: oda_id,
      hediye_kosulu: giftsAndPromotion.hediye_kosulu,
      konaklama_tarihi: `${startDate} - ${endDate}`,
      radio_gun: Number(giftsAndPromotion.tum_gunler),
      gun: giftsAndPromotion.gunler,
      min_gece_sayisi: giftsAndPromotion.min_gece_sayisi,
      hediye_id: giftsAndPromotion.hediye_id,
      indirim_orani: giftsAndPromotion.indirim_orani,
      gecerli_kisi_sayisi: giftsAndPromotion.gecerli_kisi_sayisi,
      grup_id: giftsAndPromotion.grup_id,
    };

  const updateGiftsAndPromotionRequestPayload: GiftAndPromotionRequestPayload =
    {
      hid: giftsAndPromotion.id,
      ...addNewGiftsAndPromotionRequestPayload,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    giftsAndPromotion,
    promotionOptions,
    promotionRateOptions,
    promotionPersonOptions,
    addPromotionLoading,
    updatePromotionLoading,
    addNewGiftsAndPromotionRequestPayload,
    updateGiftsAndPromotionRequestPayload,
    formErrors: giftsAndPromotionFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
