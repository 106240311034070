import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import * as Yup from "yup";

import { userResetPassword } from "slices/thunks";

import { Router } from "types";

const useHandleForm = (props: Router) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectLayoutState = (state: any) => state.ForgetPassword;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    forgetError: state.forgetError,
    forgetSuccessMsg: state.forgetSuccessMsg,
    resetEmail: state.resetEmail,
  }));

  // Inside your component
  const { forgetError, forgetSuccessMsg, resetEmail } = useSelector(
    selectLayoutProperties
  );

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfirmationShow, setPasswordConfirmationShow] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("Kod alanı boş bırakılamaz")
        .min(6, "Kod en az 6 karakter olmalıdır"),
      password: Yup.string()
        .required("Şifre alanı boş bırakılamaz")
        .min(8, "Şifre en az 8 karakter olmalıdır"),
      password_confirmation: Yup.string()
        .required("Şifre tekrarı alanı boş bırakılamaz")
        .min(8, "Şifre tekrarı en az 8 karakter olmalıdır")
        .oneOf([Yup.ref("password"), ""], "Şifreler eşleşmiyor"),
    }),
    onSubmit: (values) => {
      dispatch(userResetPassword(values, resetEmail, props.router.navigate));
      setIsLoading(true);
    },
  });

  useEffect(() => {
    if (forgetError || forgetSuccessMsg) {
      setIsLoading(false);
    }
  }, [forgetError, forgetSuccessMsg]);

  return {
    validation,
    forgetError,
    forgetSuccessMsg,
    isLoading,
    passwordShow,
    setPasswordShow,
    passwordConfirmationShow,
    setPasswordConfirmationShow,
  };
};

export default useHandleForm;
