import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbs from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { SlideImage } from "yet-another-react-lightbox/types";

interface CustomPhotoAlbumProps {
  images: {
    src: string;
    alt: string;
  }[];
  index: number;
  handleChangeIndex?: (index: number) => void;
}

const CustomPhotoAlbum = ({
  images = [],
  index,
  handleChangeIndex = () => {},
}: CustomPhotoAlbumProps) => {
  const photo: SlideImage[] = images.map((image) => ({
    src: image.src,
    alt: image.alt,
  }));

  return (
    <Lightbox
      slides={photo}
      open={index >= 0}
      index={index}
      close={() => handleChangeIndex(-1)}
      plugins={[Fullscreen, Zoom, Thumbs]}
      animation={{
        fade: 0.3,
      }}
    />
  );
};

export default CustomPhotoAlbum;
