import { CustomButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import {
  getGeneralPriceSettings,
  saveGeneralPriceSettings,
} from "slices/pricesAndQuota/generalPriceSettings/thunk";
import {
  GeneralPriceSettingFormState,
  GeneralPriceSettingRequestPayload,
  Months,
} from "types/PricesQuotas";

interface SaveSettingsButtonProps {
  values: GeneralPriceSettingFormState;
  requestPayload: GeneralPriceSettingRequestPayload;
  validateForm: (values: GeneralPriceSettingFormState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;

  isLoading: boolean;
}
type TMonthsMap = { [key in Months]: number };

const MonthsMap: TMonthsMap = {
  "": 0,
  Ocak: 1,
  Şubat: 2,
  Mart: 3,
  Nisan: 4,
  Mayıs: 5,
  Haziran: 6,
  Temmuz: 7,
  Ağustos: 8,
  Eylül: 9,
  Ekim: 10,
  Kasım: 11,
  Aralık: 12,
} as const;

type MonthPeriod = [Months, Months]; // A tuple representing the start and end months

function isPeriodValid(period1: MonthPeriod, period2: MonthPeriod): boolean {
  const [first1, last1] = period1;
  const [first2, last2] = period2;

  const start1 = MonthsMap[first1];
  const end1 = MonthsMap[last1];
  const start2 = MonthsMap[first2];
  const end2 = MonthsMap[last2];

  //first rule:take start1 and end1, make ana array, starts from start1 and ends at end1
  const firstPeriod =
    start1 < start2
      ? Array.from({ length: end1 - start1 + 1 }, (_, i) => i + start1)
      : Array.from({ length: end2 - start2 + 1 }, (_, i) => i + start2);

  const secondPeriodStart = start1 < start2 ? start2 : start1;
  const secondPeriodEnd = secondPeriodStart === start2 ? end2 : end1;

  //if first period includes start2 or end2, return false
  const isNonConflicting =
    !firstPeriod.includes(secondPeriodStart) &&
    !firstPeriod.includes(secondPeriodEnd);

  return isNonConflicting;
}

const SaveSettingsButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: SaveSettingsButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const handleSaveSettings = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    const periods = [
      {
        status: requestPayload.kismi_odeme === "1",
        period: [
          values.kismi_baslangic_donemi,
          requestPayload.kismi_bitis_donemi,
        ],
      },
      {
        status: requestPayload.kaporali_satis === "evet",
        period: [
          requestPayload.kaporali_satis_baslangic_donemi,
          requestPayload.kaporali_satis_bitis_donemi,
        ],
      },
      {
        status: requestPayload.kapida_odeme === "evet",
        period: [
          values.kapida_baslangic_donemi,
          requestPayload.kapida_bitis_donemi,
        ],
      },
    ];

    const selectedPeriods = periods.filter((period) => period.status);

    const validPeriods =
      selectedPeriods.length === 3
        ? false
        : selectedPeriods.length === 2
        ? isPeriodValid(
            selectedPeriods[0].period as MonthPeriod,
            selectedPeriods[1].period as MonthPeriod
          )
        : true;

    console.log("selectedPeriods", selectedPeriods);

    if (isValid) {
      if (!validPeriods) {
        alert(
          "Seçilen ödeme tiplerinin satış dönemleri birbirleriyle çakışmamalıdır."
        );
        return;
      }
      dispatch(
        saveGeneralPriceSettings(requestPayload, () => {
          dispatch(getGeneralPriceSettings(otel_id));
          handleSubmitted(false);
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleSaveSettings}
        isLoading={isLoading}
      >
        Ayarları Kaydet
      </CustomButton>
    </div>
  );
};

export default SaveSettingsButton;
