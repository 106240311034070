import { createSlice } from "@reduxjs/toolkit";
import {
  MobileDiscount,
  MobileDiscountFormErrors,
  MobileDiscountState,
} from "types/DiscountsAndActions";

import moment from "moment";
import { Hotel } from "types/Hotel";

export interface MobileDiscountsInitialState {
  mobileDiscounts: MobileDiscount[];
  selectedRoom: number;
  mobileDiscount: MobileDiscountState;
  mobileDiscountFormErrors: MobileDiscountFormErrors;
  allHotels: Partial<Hotel>[];
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
  updateDiscountLoading: boolean;
  updateDiscountError: string;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string;
}

export const initialState: MobileDiscountsInitialState = {
  mobileDiscounts: [],
  mobileDiscount: {
    id: 0,
    grup_id: 0,
    rez_tarihi_baslangic: moment().format("YYYY-MM-DD"),
    rez_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
    konaklama_tarihi_baslangic: moment().format("YYYY-MM-DD"),
    konaklama_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
    min_gece_sayisi: 1,
    ozel_oran: 0,
    radio_gun: 1,
    gun: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    maliyete_uygula: "1",
    eb_birlikte: "1",
    kalode_birlikte: "1",
    diger_birlikte: "1",
    yalniz_bu_otel: 1,
    secili_oteller: [],
    tum_pansiyonlar: 1,
    secili_pansiyonlar: [],
    tum_odeme_tipleri: 1,
    secili_odeme_tipleri: [],
  },
  mobileDiscountFormErrors: {},
  selectedRoom: 0,
  allHotels: [],
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
  updateDiscountLoading: false,
  updateDiscountError: "",
  deleteDiscountLoading: false,
  deleteDiscountError: "",
};

const mobileDiscountsSlice = createSlice({
  name: "mobileDiscounts",
  initialState,
  reducers: {
    handleSaveMobileDiscounts: (state, action) => {
      state.mobileDiscounts = action.payload;
    },
    handleDeleteFromMobileDiscountsWithId: (state, action) => {
      state.mobileDiscounts = state.mobileDiscounts.filter(
        (discount) => discount.id !== action.payload
      );
    },
    handleSaveMobileDiscountForEdit: (state, action) => {
      state.mobileDiscount = action.payload;
    },
    handleChangeInput(state, action) {
      state.mobileDiscount = {
        ...state.mobileDiscount,
        ...action.payload,
      };
    },
    handleAddMobileDiscountFormErrors: (state, action) => {
      state.mobileDiscountFormErrors = action.payload;
    },
    handleSaveAllHotels: (state, action) => {
      state.allHotels = action.payload;
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetMobileDiscounts: (state) => {
      state.mobileDiscounts = [];
    },
    resetMobileDiscount: (state) => {
      state.mobileDiscount = {
        id: 0,
        grup_id: 0,
        rez_tarihi_baslangic: moment().format("YYYY-MM-DD"),
        rez_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
        konaklama_tarihi_baslangic: moment().format("YYYY-MM-DD"),
        konaklama_tarihi_bitis: moment().add(1, "months").format("YYYY-MM-DD"),
        min_gece_sayisi: 1,
        ozel_oran: 0,
        radio_gun: 1,
        gun: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        maliyete_uygula: "1",
        eb_birlikte: "1",
        kalode_birlikte: "1",
        diger_birlikte: "1",
        yalniz_bu_otel: 1,
        secili_oteller: [],
        tum_pansiyonlar: 1,
        secili_pansiyonlar: [],
        tum_odeme_tipleri: 1,
        secili_odeme_tipleri: [],
      };
    },
    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateDiscountLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateDiscountError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteDiscountLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteDiscountError = action.payload;
    },
  },
});

export const {
  handleSaveMobileDiscounts,
  handleDeleteFromMobileDiscountsWithId,
  handleSaveMobileDiscountForEdit,
  handleChangeInput,
  handleAddMobileDiscountFormErrors,
  handleSaveAllHotels,
  handleSelectRoom,
  setIsLoading,
  setError,
  resetMobileDiscounts,
  resetMobileDiscount,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} = mobileDiscountsSlice.actions;

export default mobileDiscountsSlice.reducer;
