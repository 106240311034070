import { Col, Label, Row } from "reactstrap";
import InputField from "../../InputField";

interface ColumnReservationFieldProps {
  date: string;
  values: any;
  onChange: (name: string, value: any) => void;
  notAllowedToEdit: boolean;
}

const ColumnReservationField = ({
  date,
  values,
  onChange,
  notAllowedToEdit,
}: ColumnReservationFieldProps) => {
  const fieldName = `${date}-rezervasyon`;
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <Label className="m-0">Rezervasyon</Label>
      </Col>
      <Col xs={6}>
        <InputField
          identifier="ODA"
          value={values[fieldName]}
          isEditable={false}
          type={fieldName}
          isBasePrice={false}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

export default ColumnReservationField;
