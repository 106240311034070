import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/userOperations/addGroup/reducer";
import {
  AddGroupFormErrors,
  AddGroupPayload,
  AddGroupResponse,
} from "types/userOperations";

interface GroupMembersListProps {
  values: AddGroupPayload;
  formChoices: AddGroupResponse;
  formErrors: AddGroupFormErrors;
  validateFormChange: (values: AddGroupPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const GroupMembersList = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: GroupMembersListProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const options: Option[] = Object.entries(formChoices.uyeler).map(
    ([key, value]) => ({
      value: String(value.id),
      label: value.ad_soyad,
    })
  );

  useEffect(() => {
    const selected = (values.grup_uyeleri_id || []).map((id) => {
      const member = formChoices.uyeler[id];
      return member
        ? { value: String(id), label: member.ad_soyad }
        : { value: String(id), label: "" };
    });

    setSelectedOptions(selected);
  }, [formChoices.uyeler, values.grup_uyeleri_id]);

  const handleSelectOptions = (selected: string[]) => {
    const memberIds = selected.map((id) => parseInt(id));
    dispatch(
      handleChangeInput({
        grup_uyeleri_id: memberIds,
      })
    );
  };

  return (
    <div style={{ margin: "16px 0" }}>
      <CustomFormFieldContainer
        label={"Grup Üyeleri "}
        error={formErrors.grup_uyeleri_id}
        orientation="vertical"
        required
      >
        <CustomDualListBox
          listOptions={options}
          selectedOptions={selectedOptions.map((option) => option.value)}
          handleSelectOptions={handleSelectOptions}
        />
      </CustomFormFieldContainer>
    </div>
  );
};

export default GroupMembersList;
