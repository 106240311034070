import axios from "axios";
import Cookies from "js-cookie";
import { Dispatch } from "redux";

import { LOGIN_USER, LOGOUT_USER } from "helpers/url_helper";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";
import { handleErrors } from "helpers/axios_helpers";
import { resetHotel } from "slices/hotel/reducer";
import { User } from "types/authentication";

interface UserForm {
  username: string;
  password: string;
}

interface RequestData {
  kullanici_adi: string;
  password: string;
}

type LoginUser = (
  user: UserForm,
  navigate: any
) => (dispatch: Dispatch) => Promise<void>;

type LogoutUser = () => (dispatch: Dispatch) => Promise<void>;

type ResetLoginFlag = () => (dispatch: Dispatch) => Promise<void>;

export const loginUser: LoginUser = (user, navigate) => async (dispatch) => {
  const requestData: RequestData = {
    kullanici_adi: user.username,
    password: user.password,
  };

  interface Response {
    message: string;
    status: number;
    success: boolean;
    token: string;
    user: User;
    yetkiler: string[];
  }

  try {
    dispatch(reset_login_flag());

    const response: Response = await axios.post(LOGIN_USER, requestData);

    const { token, user, yetkiler } = response;

    const yetkiler_encoded = btoa(JSON.stringify(yetkiler));

    if (token && user) {
      Cookies.set("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("auth", yetkiler_encoded);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      dispatch(loginSuccess(user));

      if (user.yetki === "otel" && user.tanimli_oteller !== null) {
        const hotelId = user.tanimli_oteller[0];
        console.log("hotelId");
        navigate(`/?otel_id=${hotelId}`);
      }
      navigate("/");
    }
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};

export const logoutUser: LogoutUser = () => async (dispatch) => {
  try {
    await axios.post(LOGOUT_USER, {});

    dispatch(logoutUserSuccess(true));
    dispatch(resetHotel());
    Cookies.remove("token");
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("hotel");
    window.location.reload();
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};

export const resetLoginFlag: ResetLoginFlag = () => async (dispatch) => {
  try {
    dispatch(reset_login_flag());
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};
