import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";

import FacilityGoogleMapsCoordinates from "./FacilityGoogleMapsCoordinates";

interface FacilityMediaFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityMediaFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityMediaFieldsProps) => {
  return (
    <CustomFormContainer title="Medya Bilgileri" lg={12} xl={12}>
      <FacilityGoogleMapsCoordinates
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
    </CustomFormContainer>
  );
};

export default FacilityMediaFields;
