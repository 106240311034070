import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import { handleSelectHotel } from "slices/hotelSearcBar/reducer";
import { getHotel } from "slices/thunks";
import { SearchedHotel } from "types/hotelSearchBar";

import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

const useHandleHotelSearchByName = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [searchParams] = useSearchParams();

  const selectLayoutState = (state: any) => state.Hotel;

  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    hotel: state.hotel,
    error: state.error,
    success: state.success,
    isLoading: state.isLoading,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const { selectedHotel } = useSelector((state: any) => state.HotelSearchBar);

  // Inside your component
  const { hotel, success, isLoading } = useSelector(selectLayoutProperties);

  useEffect(() => {
    if (success) {
      setIsOpen(false);

      const selectedHotel: SearchedHotel = {
        otel_id: hotel.otel_id,
        otel_adi: hotel.otel_adi,
      };

      dispatch(handleSelectHotel(selectedHotel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, hotel]);

  useEffect(() => {
    const idfromStorage = sessionStorage.getItem("hotel");
    const idFromUrl = searchParams.get("otel_id") ?? "";

    const idFromCookies = Cookies.get("hotel") ?? "";

    if (idFromUrl) {
      dispatch(getHotel(idFromUrl));
      sessionStorage.setItem("hotel", idFromUrl);
    } else if (idfromStorage) {
      dispatch(getHotel(idfromStorage));
      window.history.pushState({}, "", `?otel_id=${idfromStorage}`);
    } else if (idFromCookies) {
      dispatch(getHotel(idFromCookies));
      sessionStorage.setItem("hotel", idFromCookies);
      window.history.pushState({}, "", `?otel_id=${idFromCookies}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpen,
    setIsOpen,
    selectedHotel,
    isLoading,
  };
};

export default useHandleHotelSearchByName;
