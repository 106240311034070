import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveEarlyReservationDefinition,
  handleSaveEarlyReservationDefinitionDefault,
  setUpdateDefinitionLoading,
  setUpdateDefinitionError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_EARLY_RESERVATION_DEFINITION,
  UPDATE_EARLY_RESERVATION_DEFINITION,
} from "helpers/url_helper";

import { UpdateEarlyReservationDefinitionRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getEarlyReservationDefinition =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_EARLY_RESERVATION_DEFINITION);

      dispatch(handleSaveEarlyReservationDefinition(response.data || response));
      dispatch(
        handleSaveEarlyReservationDefinitionDefault(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateEarlyReservationDefinition =
  (
    payload: UpdateEarlyReservationDefinitionRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDefinitionLoading(true));

      await axios.post(UPDATE_EARLY_RESERVATION_DEFINITION, payload);

      successCallback();
      dispatch(setToastSuccess("Erken rezervasyon tanımı güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDefinitionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDefinitionLoading(false));
    }
  };
