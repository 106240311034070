import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveMatchRoomConceptList,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_ROOM_CONCEPT_MATCH_LIST,
  ROOM_CONCEPT_MATCH,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import {
  AddRoomMatchRequestPayload,
  RemoveRoomMatchRequestPayload,
  AddConceptMatchRequestPayload,
  RemoveConceptMatchRequestPayload,
} from "types/facility";

export const getRoomConceptMatchList =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_ROOM_CONCEPT_MATCH_LIST + id);

      dispatch(handleSaveMatchRoomConceptList(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addRoomMatch =
  (payload: AddRoomMatchRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ROOM_CONCEPT_MATCH, payload);

      dispatch(setToastSuccess("Odalar eşleştirildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const removeRoomMatch =
  (payload: RemoveRoomMatchRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ROOM_CONCEPT_MATCH, payload);

      dispatch(setToastSuccess("Oda eşleştirmesi kaldırıldı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addConceptMatch =
  (payload: AddConceptMatchRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ROOM_CONCEPT_MATCH, payload);

      dispatch(setToastSuccess("Pansiyonlar eşleştirildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const removeConceptMatch =
  (payload: RemoveConceptMatchRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ROOM_CONCEPT_MATCH, payload);

      dispatch(setToastSuccess("Pansiyon eşleştirmesi kaldırıldı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
