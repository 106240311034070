import { useState } from "react";
import AnalysisRates from "./AnalysisRates";
import CompetitorAnalysis from "./CompetitorAnalysis";
import EmployeeBestPriceRates from "./EmployeeBestPriceRates";
import EmployeeCounts from "./EmployeeCounts";
import FilterForm from "./FilterForm";
import { CustomLoadingPage } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";

interface CompetitorAnalysisPageProps {
  isLoading: boolean;
}

const CompetitorAnalysisPage = ({ isLoading }: CompetitorAnalysisPageProps) => {
  const { isEb } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const [formValues, setFormValues] = useState({
    hotelId: 0,
    region: 0,
    facilityResponsible: "",
    welcomeType: "",
    hotelPriceStatus: "",
    quotationStatus: "",
    adStatus: "",
    provider: "",
    paymentType: "",
    roomNumber: 0,
    isEb,
  });

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <p className="fs-20 fw-bold border py-2 px-3 theme-bg-primary text-white rounded text-center m-0">
          {`${isEb ? "EB" : ""} Rakip Analizi`}
        </p>
        <FilterForm formValues={formValues} setFormValues={setFormValues} />
        <EmployeeBestPriceRates isLoading={isLoading} />
        <EmployeeCounts isLoading={isLoading} />
        <AnalysisRates isLoading={isLoading} />
        <CompetitorAnalysis
          isLoading={isLoading}
          formValues={formValues}
          isEb={isEb}
        />
      </div>
    </>
  );
};

export default CompetitorAnalysisPage;
