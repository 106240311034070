import { ListSpecialDaysPage } from "Components/Custom/FacilityPages";
import useListSpecialDays from "Components/Hooks/facility/useListSpecialDays";
import PageContainer from "pages/PageContainer";

const ListSpecialDays = () => {
  const { isLoading } = useListSpecialDays();

  return (
    <PageContainer title="Özel Gün Listele">
      <ListSpecialDaysPage loading={isLoading} />
    </PageContainer>
  );
};

export default ListSpecialDays;
