import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Input, Label } from "reactstrap";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FACILITY_THEME_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityIslamicThemeProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  facilityIslamicThemeOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityIslamicTheme = ({
  values,
  formErrors,
  validateFormChange,
  facilityIslamicThemeOptions,
}: FacilityIslamicThemeProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      {" "}
      <CustomFormFieldContainer
        label="Otel İslami Teması"
        // error={formErrors.otel_islami_tema_id}
        description={FACILITY_THEME_DESCRIPTION}
        // required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityIslamicThemeOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={"islamic-theme-" + option.value}
                checked={values.otel_islami_tema_id.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.otel_islami_tema_id, parseInt(value)]
                    : values.otel_islami_tema_id.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      otel_islami_tema_id: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    otel_islami_tema_id: newValues,
                  });
                }}
              />
              <Label
                for={"islamic-theme-" + option.value}
                className="fw-normal fs-14"
              >
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityIslamicTheme;
