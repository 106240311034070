import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { Dispatch } from "redux";

import {
  setIsLoading,
  setError,
  setSaveRateLoading,
  setSaveRateError,
  handleSaveRoomPersonPriceRate,
  handleSaveRoomPersonPriceRateState,
  handleSaveRoomPersonPriceRateStateInSelect,
  handleResetState,
  handleChangeInput,
} from "./reducer";

import {
  GET_ROOM_PERSON_PRICE_RATES,
  POST_ROOM_PERSON_PRICE_RATES,
} from "helpers/url_helper";

import { SaveRoomPersonPriceRates } from "types/PricesQuotas";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
  cocuk_ozel_yas_id: number;
}

interface Options {
  type: "misafir_oranlari" | "konaklama_cesitleri";
  value: string;
}

type SuccessCallback = () => void;

export const getRoomPersonPriceRates =
  (payload: GetRequestPayload, options?: Options) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(handleResetState());
      const response = await axios.post(GET_ROOM_PERSON_PRICE_RATES, {
        otel_id: payload.otel_id,
        oda_id: payload.oda_id,
        cocuk_ozel_yas_id: payload.cocuk_ozel_yas_id,
      });

      const res = response.data || response;

      options
        ? dispatch(handleSaveRoomPersonPriceRateStateInSelect(res))
        : dispatch(handleSaveRoomPersonPriceRateState(res));

      dispatch(
        handleChangeInput({
          cocuk_ozel_yas_ozel_katsayi: payload.cocuk_ozel_yas_id,
        })
      );
      dispatch(
        handleChangeInput({ cocuk_ozel_yas: payload.cocuk_ozel_yas_id })
      );

      dispatch(handleSaveRoomPersonPriceRate(res));

      if (options && options.type === "konaklama_cesitleri") {
        dispatch(handleChangeInput({ detayli_oda_kontenjan: true }));
        dispatch(handleChangeInput({ ozel_katsayi: true }));

        dispatch(
          handleChangeInput({ cocuk_ozel_yas_ozel_katsayi: options.value })
        );
      }
      if (options && options.type === "misafir_oranlari") {
        dispatch(handleChangeInput({ cocuk_ozel_yas: options.value }));
        dispatch(handleChangeInput({ ozel_katsayi: false }));
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const saveRoomPersonPriceRates =
  (payload: SaveRoomPersonPriceRates, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSaveRateLoading(true));
      await axios.post(POST_ROOM_PERSON_PRICE_RATES, payload);

      successCallback();
      dispatch(setToastSuccess("Oranlar kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveRateError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveRateLoading(false));
    }
  };
