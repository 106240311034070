import { CommissionPaymentResultPage } from "Components/Custom/FinanceAndAccountingPages";
import PageContainer from "pages/PageContainer";

const CommissionPaymentResult = () => {
  return (
    <PageContainer title="Komisyon Ödeme Sonucu">
      <CommissionPaymentResultPage />
    </PageContainer>
  );
};

export default CommissionPaymentResult;
