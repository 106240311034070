import { CustomFormContainer } from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import useHandleForm from "./useHandleForm";
import FacilityStatusAndShowPriceList from "./FacilityStatusAndShowPriceList";
import FacilityIdFields from "./FacilityIdFields";
import FacilitySupplierAndInetFields from "./FacilitySupplierAndInetFields";

interface FacilityOperationalInfoFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityOperationalInfoFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityOperationalInfoFieldsProps) => {
  const { statusOptions, showPriceListOptions, supplierOptions } =
    useHandleForm();
  return (
    <CustomFormContainer title="Operasyonel Bilgiler" lg={12} xl={12}>
      <FacilityStatusAndShowPriceList
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        statusOptions={statusOptions}
        showPriceListOptions={showPriceListOptions}
      />
      <FacilityIdFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <FacilitySupplierAndInetFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        supplierOptions={supplierOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityOperationalInfoFields;
