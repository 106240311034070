import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface MonthlySalesTableProps {
  isLoading: boolean;
}

const MonthlySalesTable = ({ isLoading }: MonthlySalesTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Personel Aylık Satış Listesi"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default MonthlySalesTable;
