import React from "react";
import useHandleForm from "./useHandleForm";
import {
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import ManagerFields from "./ManagerFields";
import UpdateManagerButton from "./UpdateManagerButton";
import AddManagerButton from "./AddManagerButton";
import { FACILITY_MANAGERS_DESCRIPTIONS } from "utils/pageDescriptions";

const FacilityManagersForm = () => {
  const {
    facilityManager,
    managerRolesOptions,
    formErrors,
    addFacilityManagerRequestPayload,
    updateFacilityManagerRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addManagerLoading,
    updateManagerLoading,
  } = useHandleForm();

  const { id } = facilityManager;
  return (
    <>
      <CustomPageDescriptions descriptions={FACILITY_MANAGERS_DESCRIPTIONS} />
      <CustomFormContainer title={id ? "Yetkili Güncelle" : "Yetkili Ekle"}>
        <ManagerFields
          values={facilityManager}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          managerRolesOptions={managerRolesOptions}
        />
        {id ? (
          <UpdateManagerButton
            values={facilityManager}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            requestPayload={updateFacilityManagerRequestPayload}
            isLoading={updateManagerLoading}
          />
        ) : (
          <AddManagerButton
            values={facilityManager}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            requestPayload={addFacilityManagerRequestPayload}
            isLoading={addManagerLoading}
          />
        )}
      </CustomFormContainer>
    </>
  );
};

export default FacilityManagersForm;
