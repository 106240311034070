import { useSelector } from "react-redux";
import { CreateWebSiteInitialState } from "slices/myWebSite/createWebSite/reducer";
import useValidateForm from "./useValidateForm";

const useHandleForm = () => {
  const {imageUrl, isLoading, createWebSite, createWebSiteFormChoices, createWebSiteFormErrors} = useSelector(
    (state: { CreateWebSite: CreateWebSiteInitialState }) => state.CreateWebSite
  );

  const createWebSiteRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();



  return {
    imageUrl,
    createWebSite,
    createWebSiteFormChoices,
    createWebSiteFormErrors,
    createWebSiteRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading
  };
};

export default useHandleForm;
