import { CustomLoadingPage } from "Components/Custom/UI";
import AddUserForm from "./AddUserForm";

interface AddUserPageProps {
  isLoading: boolean;
}

const AddUserPage = ({ isLoading }: AddUserPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <AddUserForm />
      </div>
    </>
  );
};

export default AddUserPage;
