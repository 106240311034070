import { useSelector } from "react-redux";
import { MoneyPointsInitialState } from "slices/reports/moneyPoints/reducer";

const useHandleTable = () => {
  const { moneyPoints } = useSelector(
    (state: { MoneyPoints: MoneyPointsInitialState }) => state.MoneyPoints
  );

  const tableData = moneyPoints?.map((moneyPoint) => ({
    ID: moneyPoint.id,
    "Otel ID": moneyPoint.otel_id,
    Otel: moneyPoint.otel_adi,
    "Kullanıcı Adı": moneyPoint.kullanici_adi,
    "Otel Yetkili": moneyPoint.uye_adi,
    "Fiyat Sağlayıcı": moneyPoint.fiyat_saglayici,
    Lokasyon: moneyPoint.sehir,
    "Kazanılan Puan": moneyPoint.parapuan,
    "Harcanan Puan": moneyPoint.harcanan,
    "Kalan Puan": moneyPoint.kalan_parapuan,
    "Puan Oranı": moneyPoint.otel_parapuan_orani,
    Tarih: moneyPoint.uyelik_tarihi,
  }));

  const tableColumns = [
    {
      header: "Otel",
      accessorKey: "Otel",
      enableColumnFilter: false,
    },
    {
      header: "Kullanıcı Adı",
      accessorKey: "Kullanıcı Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel Yetkilisi",
      accessorKey: "Otel Yetkilisi",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Sağlayıcı",
      accessorKey: "Fiyat Sağlayıcı",
      enableColumnFilter: false,
    },
    {
      header: "Lokasyon",
      accessorKey: "Lokasyon",
      enableColumnFilter: false,
    },
    {
      header: "Kazanılan Puan",
      accessorKey: "Kazanılan Puan",
      enableColumnFilter: false,
    },
    {
      header: "Harcanan Puan",
      accessorKey: "Harcanan Puan",
      enableColumnFilter: false,
    },
    {
      header: "Kalan Puan",
      accessorKey: "Kalan Puan",
      enableColumnFilter: false,
    },
    {
      header: "Puan Oranı",
      accessorKey: "Puan Oranı",
      enableColumnFilter: false,
    },
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
