import { createSlice } from "@reduxjs/toolkit";
import {
  AssignHotelToUserFormErrors,
  AssignHotelToUserPayload,
  ChangeHotelAssigneeFormErrors,
  ChangeHotelAssigneePayload,
  GetAssignedHotelsResponse,
  GetHotelsUsersAndCitiesResponse,
  ListAssignedHotelsResponse,
} from "types/userOperations";

export interface PersonnelHotelAssignmentsInitialState {
  assignHotelToUser: AssignHotelToUserPayload;
  assignHotelToUserFormChoices: GetHotelsUsersAndCitiesResponse;
  changeHotelAssignee: ChangeHotelAssigneePayload;
  changeHotelAssigneeFormChoices: GetHotelsUsersAndCitiesResponse;
  selectedCity: number;
  selectedUser: number;
  selectedUsersToChange: number[];
  selectedHotels: number[];
  selectedHotelsToChange: number[];
  assignedHotels: ListAssignedHotelsResponse;
  listAssignedHotelsFormChoices: GetAssignedHotelsResponse;
  assignHotelToUserFormErrors: AssignHotelToUserFormErrors;
  changeHotelAssigneeFormErrors: ChangeHotelAssigneeFormErrors;
  isLoading: boolean;
  error: string;
  assignHotelToUserLoading: boolean;
  assignHotelToUserError: string;
  changeHotelAssigneeLoading: boolean;
  changeHotelAssigneeError: string;
}

export const personnelHotelAssignmentsInitialState: PersonnelHotelAssignmentsInitialState =
  {
    assignHotelToUser: {
      personel_id: 0,
      otel_id: [],
      ana_yetkili_otel_id: 0,
    },
    assignHotelToUserFormChoices: {
      atanmamis_oteller: {},
      personeller: [],
      sehirler: {},
    },
    changeHotelAssignee: {
      statu: "personel_degisikligi",
      otel_id: [],
      personel_id: [0, 0], //Initialize with two element (0 -> old, 1-> new assignee)
      iki_personel: "hayir",
    },
    changeHotelAssigneeFormChoices: {
      atanmamis_oteller: {},
      personeller: [],
      sehirler: {},
    },
    selectedCity: 0,
    selectedUser: 0,
    selectedUsersToChange: [0, 0],
    selectedHotels: [],
    selectedHotelsToChange: [],
    assignedHotels: {
      sehirdeki_oteller: [],
      atanmis_oteller: [],
    },
    listAssignedHotelsFormChoices: {
      sehir: [],
      personel: [],
    },
    assignHotelToUserFormErrors: {},
    changeHotelAssigneeFormErrors: {},
    isLoading: false,
    error: "",
    assignHotelToUserLoading: false,
    assignHotelToUserError: "",
    changeHotelAssigneeLoading: false,
    changeHotelAssigneeError: "",
  };

export const personnelHotelAssignmentsSlice = createSlice({
  name: "personnelHotelAssignments",
  initialState: personnelHotelAssignmentsInitialState,
  reducers: {
    handleAssignHotelToUser(state, action) {
      state.assignHotelToUser = action.payload;
    },
    handleAssignHotelToUserFormChoices(state, action) {
      state.assignHotelToUserFormChoices = action.payload;
    },
    handleChangeHotelAssignee(state, action) {
      state.changeHotelAssignee = action.payload;
    },
    handleChangeHotelAssigneeFormChoices(state, action) {
      state.changeHotelAssigneeFormChoices = action.payload;
    },
    handleSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },
    handleSelectedUser(state, action) {
      state.selectedUser = action.payload;
      state.assignHotelToUser.personel_id = action.payload;
    },
    handleSelectedUsersToChange(state, action) {
      const { index, userId } = action.payload;
      if (index === 0 || index === 1) {
        state.selectedUsersToChange[index] = userId;
        state.changeHotelAssignee.personel_id[index] = userId;
      }
    },
    handleSelectedHotels(state, action) {
      state.selectedHotels = action.payload;
      state.assignHotelToUser.otel_id = action.payload;
    },
    handleSelectedHotelsToChange(state, action) {
      state.selectedHotelsToChange = action.payload;
      state.changeHotelAssignee.otel_id = action.payload;
    },
    handleAssignHotelToUserFormErrors(state, action) {
      state.assignHotelToUserFormErrors = action.payload;
    },
    handleChangeHotelAssigneeFormErrors(state, action) {
      state.changeHotelAssigneeFormErrors = action.payload;
    },
    handleAssignedHotelsOfUser(state, action) {
      const { userId, assignedHotels } = action.payload;
      const user = state.changeHotelAssigneeFormChoices.personeller.find(
        (user) => user.id === userId
      );
      if (user) {
        user.bagli_oteller = assignedHotels;
        user.otel_sayisi = assignedHotels.length;
      }
    },
    handleListAssignedHotels(state, action) {
      state.assignedHotels = action.payload;
    },
    handleListAssignedHotelsFormChoices(state, action) {
      state.listAssignedHotelsFormChoices = action.payload;
    },
    resetListAssignedHotelsInputs(state) {
      state.assignedHotels = {
        sehirdeki_oteller: [],
        atanmis_oteller: [],
      };
      state.selectedCity = 0;
      state.selectedUser = 0;
    },
    resetAssignHotelToUserForm(state) {
      state.assignHotelToUser = {
        personel_id: 0,
        otel_id: [],
        ana_yetkili_otel_id: 0,
      };

      state.selectedCity = 0;
      state.selectedHotels = [];
      state.selectedUser = 0;
      state.assignHotelToUserFormErrors = {};
    },
    resetChangeHotelAssigneeForm(state) {
      state.changeHotelAssignee = {
        statu: "personel_degisikligi",
        otel_id: [],
        personel_id: [0, 0],
        iki_personel: "hayir",
      };
      state.selectedUsersToChange = [0, 0];
      state.selectedHotelsToChange = [];
      state.changeHotelAssigneeFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAssignHotelToUserLoading(state, action) {
      state.assignHotelToUserLoading = action.payload;
    },
    setAssignHotelToUserError(state, action) {
      state.assignHotelToUserError = action.payload;
    },
    setChangeHotelAssigneeLoading(state, action) {
      state.changeHotelAssigneeLoading = action.payload;
    },
    setChangeHotelAssigneeError(state, action) {
      state.changeHotelAssigneeError = action.payload;
    },
  },
});

export const {
  handleAssignHotelToUser,
  handleAssignHotelToUserFormChoices,
  handleChangeHotelAssignee,
  handleChangeHotelAssigneeFormChoices,
  handleSelectedCity,
  handleSelectedUser,
  handleSelectedUsersToChange,
  handleSelectedHotels,
  handleSelectedHotelsToChange,
  handleAssignHotelToUserFormErrors,
  handleChangeHotelAssigneeFormErrors,
  handleAssignedHotelsOfUser,
  handleListAssignedHotels,
  handleListAssignedHotelsFormChoices,
  resetListAssignedHotelsInputs,
  resetAssignHotelToUserForm,
  resetChangeHotelAssigneeForm,
  setIsLoading,
  setError,
  setAssignHotelToUserLoading,
  setAssignHotelToUserError,
  setChangeHotelAssigneeLoading,
  setChangeHotelAssigneeError,
} = personnelHotelAssignmentsSlice.actions;

export default personnelHotelAssignmentsSlice.reducer;
