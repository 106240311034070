import { AnalysisEmployeeRate } from "types/adsAndAnalyze";
import FirstPlaceImg from "assets/images/competitor-analysis/crown.png";
import SecondPlaceImg from "assets/images/competitor-analysis/clapping.png";
import ThirdPlaceImg from "assets/images/competitor-analysis/like.png";

const PriceRateCard = ({
  uye_id,
  ad_soyad,
  gunluk_oran,
  islem_tarihi,
  aylik_oran,
  otel_sayisi,
}: AnalysisEmployeeRate) => {
  const placementImage =
    parseInt(aylik_oran) >= 95
      ? FirstPlaceImg
      : parseInt(aylik_oran) >= 90
      ? SecondPlaceImg
      : ThirdPlaceImg;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-start gap-4 border rounded bg-white position-relative py-3"
      style={{ width: "200px" }}
    >
      <div className="badge bg-primary position-absolute top-0 start-0 m-1">
        {otel_sayisi} Otel
      </div>
      <img src={placementImage} alt="placement" width={50} height={50} />
      <p className="text-center fw-medium m-0">{ad_soyad}</p>
      <div className="d-flex w-100 justify-content-between px-3">
        <div className="d-flex flex-column gap-1">
          <p className="fs-13 m-0 text-decoration-underline">Günlük Oran</p>
          <p className="text-center fw-semibold m-0">{gunluk_oran}</p>
        </div>
        <div>
          <p className="fs-13 m-0 text-decoration-underline">Aylık Oran</p>
          <p className="text-center fw-bold m-0">{aylik_oran}</p>
        </div>
      </div>
    </div>
  );
};

export default PriceRateCard;
