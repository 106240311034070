import { Input, Label } from "reactstrap";
import useHandleForm from "./useHandleForm";
import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

const FilterForm = () => {
  const { filters, handleFormChange, onGetFilteredList, onResetFilters } =
    useHandleForm();

  return (
    <CustomFormContainer>
      <form
        id="rooms-lack-picture-filter-form"
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",

          gap: "1rem",
        }}
      >
        <CustomFormFieldContainer
          label="Seçili Resimler"
          orientation="vertical"
        >
          <div
            style={{
              flexGrow: 1,
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",

              gap: "1rem",
            }}
          >
            {filters.map((filter) => (
              <div
                key={filter.name}
                className="d-flex align-items-center gap-2"
              >
                <Input
                  className="m-0"
                  id={filter.name}
                  type="checkbox"
                  name={filter.name}
                  value={filter.value}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleFormChange(filter.name as any, isChecked);
                  }}
                />
                <Label className="m-0 cursor-pointer" for={filter.name}>
                  {filter.text}
                </Label>
              </div>
            ))}
          </div>
        </CustomFormFieldContainer>

        <div className="d-flex gap-2 flex-wrap align-items-end">
          <CustomFormFieldContainer label="" orientation="vertical">
            <CustomButton variant="warning" onClick={onResetFilters}>
              Filtrleri Sıfırla
            </CustomButton>
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="" orientation="vertical">
            <CustomButton variant="success" onClick={onGetFilteredList}>
              Filtrele
            </CustomButton>
          </CustomFormFieldContainer>
        </div>
      </form>
    </CustomFormContainer>
  );
};

export default FilterForm;
