import { CalculatePricePage } from "Components/Custom/ReservationPages";
import PageContainer from "pages/PageContainer";

const CalculatePrice = () => {
  return (
    <PageContainer title="Fiyat Hesapla">
      <CalculatePricePage />
    </PageContainer>
  );
};

export default CalculatePrice;
