import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { CreateNotificationInitialState } from "slices/userOperations/createNotification/reducer";


const useHandleForm = () => {

  const {
    formChoices,
    createNotification,
    createNotificationFormErrors,
    createNotificationLoading,
  } = useSelector(
    (state: { CreateNotification: CreateNotificationInitialState }) => state.CreateNotification
  );

  const { validateForm, validateFormChange, handleSubmitted } = useValidateForm();

  const createNotificationRequestPayload = new FormData();


  return {
    formChoices,
    values: createNotification,
    formErrors: createNotificationFormErrors,
    isLoading: createNotificationLoading,
    validateForm, 
    validateFormChange, 
    handleSubmitted,
    requestPayload: createNotificationRequestPayload
  };
};

export default useHandleForm;
