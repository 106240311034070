import { useState } from "react";
import { CreateRoomAddedRoom } from "types/reservation";

const useHandleForm = (room: CreateRoomAddedRoom) => {
  const clone = { ...room };
  const [roomValues, setRoomValues] = useState<CreateRoomAddedRoom>(clone);

  const handleChangeInput = (key: string, value: any) => {
    setRoomValues((prev) => ({ ...prev, [key]: value }));
  };
  const handleChangeGuestsInput = (
    key: string,
    value: any,
    tip: "yetiskin" | "cocuk",
    sira: number
  ) => {
    setRoomValues((prev) => ({
      ...prev,
      guests: prev.guests.map((guest) => {
        if (guest.tip === tip && guest.sira === sira) {
          return { ...guest, [key]: value };
        }
        return guest;
      }),
    }));
  };

  return { roomValues, handleChangeInput, handleChangeGuestsInput };
};

export default useHandleForm;
