import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { FilterLogsPayload } from "types/userOperations";

interface Option {
  value: number;
  label: string;
}
interface UserSelectorProps {
  options: Option[];
  values: FilterLogsPayload;
  handleFormChange: (key: string, value: number) => void;
}

const UserSelector = ({
  options, 
  values,
  handleFormChange, 
}: UserSelectorProps) => {
  return (
    <CustomFormFieldContainer label="Kullanıcı Seç :">
      <Select
        options={options}
        value={options.find(
          (option) => option.value === values.user_id
        )}
        onChange={(selectedOption: Option) => {
          handleFormChange('user_id', selectedOption.value);
        }}
        placeholder="Kullanıcı Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default UserSelector;
