import DailyCallsForHotelsPage from "Components/Custom/ReportsPages/DailyCallsForHotelsPage";
import useDailyCallsForHotels from "Components/Hooks/reports/useDailyCallsForHotels";
import PageContainer from "pages/PageContainer";

const DailyCallsForHotels = () => {
  const { isLoading } = useDailyCallsForHotels();

  return (
    <PageContainer title="Otellere Gelen Günlük Çağrı">
      <DailyCallsForHotelsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default DailyCallsForHotels;
