import { createSlice } from "@reduxjs/toolkit";

import { RoomLackDescription } from "types/reports";

export interface RoomsLackDescriptionInitialState {
  roomsLackDescription: RoomLackDescription[];
  isLoading: boolean;
  error: string;
}

const initialState: RoomsLackDescriptionInitialState = {
  roomsLackDescription: [],
  isLoading: false,
  error: "",
};

const roomsLackDescriptionSlice = createSlice({
  name: "roomsLackDescription",
  initialState,
  reducers: {
    handleSaveRoomsLackDescription(state, action) {
      state.roomsLackDescription = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveRoomsLackDescription, setIsLoading, setError } =
  roomsLackDescriptionSlice.actions;

export default roomsLackDescriptionSlice.reducer;