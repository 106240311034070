import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dispatch } from "redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { calculatePriceForHotel } from "slices/reservation/reservationCall/thunk";
import { CalculatePriceForHotelRequestParams } from "types/reservation";

const useHandleHotelSearch = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { reservationCall, calculationForm } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const hasCustomer = aranan && arayan && dahili && reservationCall.arama_id;

  const handleCalculatePriceForHotel = (otel_id: string) => {
    const payload: CalculatePriceForHotelRequestParams = {
      giris_tarihi: moment(calculationForm.giris_tarihi, "D MMMM Y").format(
        "YYYY-MM-DD"
      ),
      cikis_tarihi: moment(calculationForm.cikis_tarihi, "D MMMM Y").format(
        "YYYY-MM-DD"
      ),
      yetiskin_sayisi: calculationForm.yetiskin_sayisi,
      cocuk_sayisi: calculationForm.cocuk_sayisi,
      cocuk_dogum_1:
        calculationForm.cocuk_sayisi > 0 ? calculationForm.cocuk_dogum_1 : "",
      cocuk_dogum_2:
        calculationForm.cocuk_sayisi > 1 ? calculationForm.cocuk_dogum_2 : "",
      cocuk_dogum_3:
        calculationForm.cocuk_sayisi > 2 ? calculationForm.cocuk_dogum_3 : "",
      cocuk_dogum_4:
        calculationForm.cocuk_sayisi > 3 ? calculationForm.cocuk_dogum_4 : "",
      musteri_id: hasCustomer ? reservationCall.musteri_id : 0,
      arama_id: hasCustomer ? reservationCall.arama_id : 0,
      musteri_tel: hasCustomer ? reservationCall.musteri_tel : "",
      musteri_ad_soyad: hasCustomer ? reservationCall.musteri_ad_soyad : "",
      geri_arama_id: hasCustomer ? reservationCall.geri_arama_id : 0,
      geri_arama_tipi: hasCustomer ? reservationCall.geri_arama_tipi : "",
      otel_id: Number(otel_id),
      option: ["filterx", "filter_zincir"],
      parapuan_secim: calculationForm.parapuan_secim,
      ozel_indirimler: calculationForm.ozel_indirimler,
    };

    if (!payload.cocuk_dogum_1) {
      delete payload.cocuk_dogum_1;
    }
    if (!payload.cocuk_dogum_2) {
      delete payload.cocuk_dogum_2;
    }
    if (!payload.cocuk_dogum_3) {
      delete payload.cocuk_dogum_3;
    }
    if (!payload.cocuk_dogum_4) {
      delete payload.cocuk_dogum_4;
    }

    dispatch(
      calculatePriceForHotel(payload, () => {
        navigate(
          hasCustomer
            ? `/otel-fiyat-hesaplama?id=${payload.otel_id}&aranan=${aranan}&arayan=${arayan}&dahili=${dahili}`
            : `/otel-fiyat-hesaplama?id=${payload.otel_id}`
        );
      })
    );
  };

  return { handleCalculatePriceForHotel };
};

export default useHandleHotelSearch;
