// DragItem.tsx
import React from "react";
import { useDrag } from "react-dnd";

export const ItemType = "BOX_ITEM";

interface DragItemProps {
  id: string;
  text: string;
}

const DragItem: React.FC<DragItemProps> = ({ id, text }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, text },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      className="border rounded px-2 py-1 bg-white fs-14"
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        whiteSpace: "nowrap",
      }}
    >
      {text}
    </div>
  );
};

export default DragItem;
