import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import {
  ReservationData,
  ReservationFilterPayload,
  ReservationFormChoices,
  ReservationsResponse,
} from "types/reports";

export interface ReservationsInitialState {
  reservationsResponse: ReservationsResponse;
  reservations: ReservationData[];
  reservationsFormChoices: ReservationFormChoices;
  filterReservations: ReservationFilterPayload;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: ReservationsInitialState = {
  reservationsResponse: {
    rezler: [],
    iptal_tutar: 0,
    bekleyen_tutar: 0,
    tamamlanan_tutar: 0,
    toplam_rez: 0,
    gosterilmeyecek_alanlar: {},
  },
  reservations: [],
  reservationsFormChoices: {
    tarih_turu: [],
    acente_list: [],
    otel_list: [],
    gosterilmeyecek_alanlar: {},
  },
  filterReservations: {
    tarih_turu: "", 
    otel_id: 0, // otelci giriş yapıyorsa bu gönderilecek
    start_date: "", 
    end_date: "",
    acente_id: "", // acente ise gönderilecek
    rezervasyon_kodu: "", 
    inet_kodu: "", 
    musteri_adi: "", 
    otel_list: [],
    per_page: 10,
    page: 1,
    search: "",
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    handleSaveReservationsResponse(state, action) {
      state.reservationsResponse = action.payload;
      const {data, ...rest} = action.payload.rezler;
      state.reservations = data;
      state.pagination = { ...rest };
    },
    handleSaveReservationFormChoices(state, action) {
      state.reservationsFormChoices = action.payload;
    },
    handleChangeReservationFilterChoices(state, action) {
      state.filterReservations = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveReservationsResponse,
  handleSaveReservationFormChoices,
  handleChangeReservationFilterChoices,
  setIsLoading,
  setError,
} = reservationsSlice.actions;

export default reservationsSlice.reducer;
