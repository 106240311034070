import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { AddAdditionalPagesFormErrors, AdditionalPageState } from "types/myWebSite";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";
import { Input } from "reactstrap";

interface ContentInfoInputProps {
  values: AdditionalPageState;
  formErrors: AddAdditionalPagesFormErrors;
  validateFormChange: (values: AdditionalPageState) => void;
}

const ContentInfoInput = ({
  values,
  validateFormChange,
  formErrors,
}: ContentInfoInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İçerik Açıklaması :"
        orientation="horizontal"
        error={formErrors.organizasyon_kart_aciklama}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.organizasyon_kart_aciklama}
          onChange={(e) => {
            dispatch(
              handleChangeAddAdditionalPageInput({
                organizasyon_kart_aciklama: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              organizasyon_kart_aciklama: e.target.value,
            });
          }}
          style={{ minHeight: "80px" }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ContentInfoInput;
