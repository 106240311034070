import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";
import { handleChangeInput } from "slices/discountsAndActions/specialDiscounts/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface DateSelectorsProps {
  values: SpecialDiscountState;
  formErrors: SpecialDiscountFormErrors;
  validateFormChange: (values: SpecialDiscountState) => void;
}

const DateSelectors = ({
  values,
  formErrors,
  validateFormChange,
}: DateSelectorsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Rezervasyon Tarih Aralığı"
        error={formErrors.rez_tarihi}
      >
        <Flatpickr
          className="form-control"
          value={[values.rez_tarihi_baslangic, values.rez_tarihi_bitis]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeInput({
                  rez_tarihi_baslangic: stringStartDate,
                  rez_tarihi_bitis: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                rez_tarihi_baslangic: stringStartDate,
                rez_tarihi_bitis: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Konaklama Tarih Aralığı"
        error={formErrors.konaklama_tarihi}
      >
        <Flatpickr
          className="form-control"
          value={[
            values.konaklama_tarihi_baslangic,
            values.konaklama_tarihi_bitis,
          ]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeInput({
                  konaklama_tarihi_baslangic: stringStartDate,
                  konaklama_tarihi_bitis: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                konaklama_tarihi_baslangic: stringStartDate,
                konaklama_tarihi_bitis: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateSelectors;
