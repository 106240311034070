import { BankInstallment } from "types/reservation";

interface ShowInstallmentAmountsProps {
  basePrice: number;
  installments: BankInstallment[];
}

const ShowInstallmentAmounts = ({
  basePrice,
  installments,
}: ShowInstallmentAmountsProps) => {
  const url = "https://crm.otelfiyat.com/img/";

  const installmentsGroupedByBankCode = installments.reduce(
    (acc, installment) => {
      if (!acc[installment.icon]) {
        acc[installment.icon] = [];
      }
      acc[installment.icon].push(installment);
      return acc;
    },
    {} as { [key: string]: BankInstallment[] }
  );

  return (
    <div className="d-flex flex-wrap gap-5 p-2 bg-white rounded">
      {Object.entries(installmentsGroupedByBankCode).map(
        ([bankCode, installments], index) => (
          <div key={index} className="d-flex flex-column gap-4">
            <img
              src={`${url}${bankCode}`}
              alt="bank"
              style={{ width: "90px", height: "40px" }}
            />
            <div className="d-flex flex-column gap-2">
              {installments.map((installment, index) => (
                <span key={index} className="fw-medium fs-13 text-nowrap">
                  {installment.ay} Taksit ={" "}
                  {Math.ceil(
                    (installment.komisyon * basePrice) / 100 + basePrice
                  )}{" "}
                  TL
                </span>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ShowInstallmentAmounts;
