import CustomCollapse from "Components/Custom/UI/CustomCollapse/CustomCollapse";
import { CrmDashboardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";
import useHandleTable from "./useHandleTable";
import CustomSimpleTable from "Components/Custom/UI/CustomSimpleTable/CustomSimpleTable";

interface MonthlySalesOfPersonnelProps {
  values: CrmDashboardData;
}

const MonthlySalesOfPersonnel = ({ values }: MonthlySalesOfPersonnelProps) => {
  const value = values.aylik_satis_listesi;

  const processedGroups = Object.entries(value.satis_gruplari).map(
    ([key, group]: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { tableData, tableColumns } = useHandleTable(group.personeller, group.degerlendirilen_oda_ortalaması);
      return {
        key,
        grup_adi: group.grup_adi,
        grup_aciklama: group.grup_aciklama,
        degerlendirilen_oda_ortalaması: group.degerlendirilen_oda_ortalaması,
        tableData,
        tableColumns,
      };
    }
  );

  return (
    <>
      <CustomCollapse
        title={`${value.month_name} Ayı Personel Satışları`}
        bgColor={DASHBOARD_COLORS.purple}
        icon="ri-wallet-3-line"
      >
        <div className="d-flex flex-column gap-3">
          {processedGroups.map((group) => (
            <CustomCollapse
              key={group.key}
              title={`${group.grup_adi} ${group.grup_aciklama}`}
              bgColor={DASHBOARD_COLORS.orange}
              isBorder={false}
            >
              <CustomSimpleTable
                tableData={group.tableData}
                tableColumns={group.tableColumns}
              />
              <div className="p-3" style={{ fontSize: "14px" }}>
                <strong>Değerlendirilen Grup Satış Oda Ortalaması :</strong>{" "}
                {group.degerlendirilen_oda_ortalaması}
              </div>
            </CustomCollapse>
          ))}
        </div>
      </CustomCollapse>
    </>
  );
};

export default MonthlySalesOfPersonnel;
