import { useState } from "react";
import { CreateRoomFormValues } from "types/reservation";

const useHandleForm = () => {
  const [createRoomFormValues, setCreateRoomFormValues] =
    useState<CreateRoomFormValues>({
      guests: [
        //8 yetişkin ve 4 çocuk
        {
          tip: "yetiskin",
          sira: 1,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 2,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 3,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 4,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 5,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 6,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 7,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 8,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 1,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 2,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 3,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 4,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
      ],
      honeymoon: false,
      noSmoking: false,
      differentBed: false,
      guestNeeds: "",
      agentNeeds: "",
    });

  const handleChangeInput = (key: string, value: any) => {
    setCreateRoomFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeGuestInput = (
    key: string,
    value: any,
    tip: "yetiskin" | "cocuk",
    sira: number
  ) => {
    setCreateRoomFormValues((prevState) => {
      const otherGuests = prevState.guests.filter(
        (guest) => guest.tip !== tip || guest.sira !== sira
      );
      const selectedGuest = {
        ...prevState.guests.find(
          (guest) => guest.tip === tip && guest.sira === sira
        )!,
      };
      const modifiedGuest = {
        ...selectedGuest,
        [key]: value,
      };

      return {
        ...prevState,
        guests: [...otherGuests, modifiedGuest],
      };
    });
  };

  const handleResetCreateRoomForm = () => {
    setCreateRoomFormValues({
      guests: [
        {
          tip: "yetiskin",
          sira: 1,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 2,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 3,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 4,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 5,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 6,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 7,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "yetiskin",
          sira: 8,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 1,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 2,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 3,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
        {
          tip: "cocuk",
          sira: 4,
          ad_soyad: "",
          cinsiyet: "e",
          dogum_tarihi: "",
          tc_kimlik_no: "",
        },
      ],
      honeymoon: false,
      noSmoking: false,
      differentBed: false,
      guestNeeds: "",
      agentNeeds: "",
    });
  };

  return {
    createRoomFormValues,
    handleChangeInput,
    handleChangeGuestInput,
    handleResetCreateRoomForm,
  };
};

export default useHandleForm;
