import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddDefineCurrencyFormErrors } from "slices/definitions/defineCurrency/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  DefineCurrencyFormErrors,
  DefineCurrencyState,
} from "types/definitions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: DefineCurrencyState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: DefineCurrencyFormErrors = {};

    if (!values.usd) {
      errors.usd = "USD boş bırakılamaz.";
    }

    if (!values.euro) {
      errors.euro = "EUR boş bırakılamaz.";
    }

    dispatch(handleAddDefineCurrencyFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: DefineCurrencyState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
