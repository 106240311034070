import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddSpecialDayFormErrors } from "slices/facility/facilitySpecialDays/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FacilitySpecialDayState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: FacilitySpecialDayFormErrors = {};

    if (!values.kategori_id) {
      errors.kategori_id = "Özel gün kategori adı boş bırakılamaz.";
    }

    if (!values.ozel_gun_baslik) {
      errors.ozel_gun_baslik = "Özel gün başlık boş bırakılamaz.";
    }

    if (!values.ozel_gun_baslangic) {
      errors.ozel_gun_baslangic = "Özel gün başlangıç tarihi boş bırakılamaz.";
    }

    if (!values.ozel_gun_bitis) {
      errors.ozel_gun_bitis = "Özel gün bitiş tarihi boş bırakılamaz.";
    }

    if (!values.ozel_gun_aciklama) {
      errors.ozel_gun_aciklama = "Özel gün açıklama boş bırakılamaz.";
    }

    if (!values.resim && !values.id) {
      errors.ozel_gun_resim = "Resim eklemelisiniz.";
    }

    dispatch(handleAddSpecialDayFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FacilitySpecialDayState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};

export default useValidateForm;
