import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface OutboundCallsListTableProps {
  isLoading: boolean;
  selectedDates: {
    start_date: string;
    end_date: string;
  };
}

const OutboundCallsListTable = ({
  isLoading,
  selectedDates,
}: OutboundCallsListTableProps) => {
  const { tableData, tableColumns } = useHandleTable(selectedDates);

  return (
    <CustomReactTable
      fileName="Dış Aramalar"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default OutboundCallsListTable;
