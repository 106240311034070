import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface CustomerMailsTableProps {
  isLoading: boolean;
}

const CustomerMailsTable = ({ isLoading }: CustomerMailsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Müşteri E-Postaları"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default CustomerMailsTable;
