import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AddFacilityInitialState } from "slices/facility/addFacility/reducer";
import { getFacilityFormChoices } from "slices/facility/addFacility/thunk";

const useAddFacility = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { AddFacility: AddFacilityInitialState }) => state.AddFacility
  );

  useEffect(() => {
    dispatch(getFacilityFormChoices());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useAddFacility;
