import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { AddGroupInitialState } from "slices/userOperations/addGroup/reducer";
import { getAddGroupFormChoices } from "slices/userOperations/addGroup/thunk";

const useAddGroup = () => {

  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { AddGroup: AddGroupInitialState }) => state.AddGroup
  );

  useEffect(() => {
    dispatch(getAddGroupFormChoices());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useAddGroup;
