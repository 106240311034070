import { CustomFormContainer } from "Components/Custom/UI";
import {
  CreateWebSiteState,
  CreateWebSiteFormErrors,
} from "types/myWebSite";
import BannerOneTitle from "./BannerOneTitle";
import BannerOneImageOne from "./BannerOneImageOne";
import BannerOneImageTwo from "./BannerOneImageTwo";
import BannerOneImageThree from "./BannerOneImageThree";
import BannerOneExplanation from "./BannerOneExplanation";

interface BannerOneFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const BannerOneFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: BannerOneFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Banner-1 Bilgileri" lg={12} xl={12}>
        <BannerOneTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <BannerOneImageOne
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerOneImageTwo
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerOneImageThree
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerOneExplanation
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default BannerOneFields;
