import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";

import { OutboundCallsListInitialState } from "slices/reports/outboundCallsList/reducer";

const useHandleTable = (selectedDates: {
  start_date: string;
  end_date: string;
}) => {
  const { outboundCalls } = useSelector(
    (state: { OutboundCallsList: OutboundCallsListInitialState }) =>
      state.OutboundCallsList
  );

  const tableData = outboundCalls?.map((outboundCall) => ({
    "Adı Soyadı": outboundCall.ad_soyad,
    Dahili: outboundCall.dahili,
    "Dış Arama Sayısı (Unique)": outboundCall.sayi,
  }));

  const tableColumns = [
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "Dahili",
      enableColumnFilter: false,
    },
    {
      header: "Dış Arama Sayısı (Unique)",
      accessorKey: "Dış Arama Sayısı (Unique)",
      enableColumnFilter: false,
    },
    {
      header: "İşlem",
      accessorKey: "Dahili",
      cell: (cell: any) => {
        const params = new URLSearchParams();
        params.append("dahili", cell.getValue());
        params.append("start_date", selectedDates.start_date);
        params.append("end_date", selectedDates.end_date);

        return (
          <CustomRowOperationButton
            variant="detail"
            onClick={() =>
              window.open(
                `${
                  window.location.origin
                }/dis-arama-listeleme?${params.toString()}`,
                "_blank",
                "rel=noopener noreferrer"
              )
            }
          />
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
