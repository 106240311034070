import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveListWebSites,
  handleSaveListHotelsWithoutWebSite,
  resetWebSite,
  setIsLoading,
  setError,
  setAddWebSiteLoading,
  setAddWebSiteError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_WEB_SITE_LIST,
  ADD_WEB_SITE,
  GET_HOTELS_WITHOUT_WEB_SITE,
} from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getWebSitesList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_WEB_SITE_LIST);

    const res = (response as any).message ? response.data : response;

    dispatch(handleSaveListWebSites(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getHotelsWithoutWebSites = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_HOTELS_WITHOUT_WEB_SITE);

    const res = (response as any).message ? response.data : response;

    // console.log("First data from thunk, hotelsWithoutWebsite : ", res);

    dispatch(handleSaveListHotelsWithoutWebSite(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addWebSite =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddWebSiteLoading(true));

      await axios.post(ADD_WEB_SITE, payload);

      successCallback();
      dispatch(resetWebSite());
      dispatch(setToastSuccess("Web sitesi eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddWebSiteError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddWebSiteLoading(false));
    }
  };
