import { getConceptStatusMessages } from "helpers/general_helpers";
import moment from "moment";
import { useState } from "react";
import {
  CalculatePriceForHotelConceptPrice,
  CalculatePriceForHotelProvider,
} from "types/reservation";

const useHandleNormalPayButton = ({
  fiyatlar,
  tedarikci,
  giris_tarihi,
  iptal_sigortasi_gun,
  kismi_odeme,
  priceCalculationStatusMessages,
  mayNotExist,
}: {
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  tedarikci: CalculatePriceForHotelProvider;
  giris_tarihi: string;
  iptal_sigortasi_gun: string;
  kismi_odeme: {
    kismi_odeme: 0 | 1;
    kismi_odeme_orani: number;
    kismi_odeme_tarihi: string;
    iptal_sigortasi_durum: "1" | "0";
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  mayNotExist?: boolean;
}) => {
  const [modal, setModal] = useState(false);

  if (mayNotExist) {
    return {
      hasPrice: false,
      stop: null,
      stopMessage: "",
      showPayNowButton: false,
      payNowFiyatDurum: 0,
      payNowPriceButtonText: "",
      payNowPrice: 0,
      payNowOverlinedPrice: 0,
      cancellationDate: "",
      kismiOdeme: 0 as 0 | 1,
      kismiOdemeOrani: 0,
      kismiOdemeTarihi: "",
      kismiOdemeButtonText: "",
      kismiOdemePrice: 0,
      iptalSigortasiFiyat: 0,
      modal: false,
      toggle: () => {},
      messages: [],
    };
  } else {
    const hasPrice = fiyatlar.normal.fiyat_durum === 1;

    const priceTypes = Object.keys(fiyatlar ?? {});

    //Normal Ödeme
    const normal = fiyatlar.normal;

    const stop = normal.stop_durum ?? 0;
    const stopMessage = normal.stop_durum_mesaj;

    const showPayNowButton =
      !!normal.normal_indirimli &&
      normal.fiyat_durum === 1 &&
      normal.periyot_durum === 1 &&
      tedarikci.tedarikci === "inet";

    const payNowFiyatDurum = normal.fiyat_durum ?? 0;
    const payNowPriceButtonText = priceTypes.includes("kapida")
      ? "Şimdi Öde"
      : priceTypes.includes("kaporali")
      ? "Tamamını Öde"
      : "Rezervasyon Yap";
    const payNowPrice = normal.normal_indirimli ?? 0;
    const payNowOverlinedPrice = normal.normal_gostermelik ?? 0;

    const cancellationDate = moment(giris_tarihi)
      .subtract(iptal_sigortasi_gun, "days")
      .format("DD MMMM YYYY");

    //Kısmi Ödeme
    const kismiOdeme = kismi_odeme.kismi_odeme;
    const kismiOdemeOrani = kismi_odeme.kismi_odeme_orani;
    const kismiOdemeTarihi = kismi_odeme.kismi_odeme_tarihi
      ? moment(kismi_odeme.kismi_odeme_tarihi).format("DD MMMM YYYY")
      : "";
    const kismiOdemeButtonText = "Ön Ödeme ile Rezervasyon";

    const kismiOdemePrice =
      Math.ceil((normal.normal_indirimli * kismiOdemeOrani) / 100) ?? 0;
    const iptalSigortasiFiyat = normal.iptal_sigortasi_fiyat ?? 0;

    const toggle = () => {
      setModal((prev) => !prev);
    };

    const hasPriceStatus = normal.fiyat_durum === 1;
    const hasQuotaStatus = normal.kontenjan_durum !== 0;
    const quotaMessage = normal.kontenjan_durum_mesaj;
    const minNightStatus = normal.minimum_gece_durum > 0;

    const messages = getConceptStatusMessages(
      priceCalculationStatusMessages,
      hasPriceStatus,
      hasQuotaStatus,
      quotaMessage,
      minNightStatus,
      normal.minimum_gece_durum ?? 0
    );

    return {
      hasPrice,
      stop,
      stopMessage,
      showPayNowButton,
      payNowFiyatDurum,
      payNowPriceButtonText,
      payNowPrice,
      payNowOverlinedPrice,
      cancellationDate,
      kismiOdeme,
      kismiOdemeOrani,
      kismiOdemeTarihi,
      kismiOdemeButtonText,
      kismiOdemePrice,
      iptalSigortasiFiyat,
      modal,
      toggle,
      messages,
    };
  }
};

export default useHandleNormalPayButton;
