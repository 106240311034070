import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { GetAssignedHotelsPayload } from "types/userOperations";

interface PersonnelSelectorProps {
  options: { value: number; label: string }[];
  values: GetAssignedHotelsPayload;
  handleFormChange: (key: string, value: string) => void;
}

interface Option {
  value: string;
  label: string;
}

const PersonnelSelector = ({
  options,
  values,
  handleFormChange,
}: PersonnelSelectorProps) => {
  return (
    <>
      <CustomFormFieldContainer label="Personel Seçiniz">
        <Select
          options={options}
          value={options.find(
            (option) => Number(option.value) === values.personel_id
          )}
          onChange={(selectedOption: Option) => {
            handleFormChange("personel_id", String(selectedOption.value));
          }}
          placeholder="Personel seçiniz..."
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PersonnelSelector;
