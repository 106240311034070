import { Dispatch, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsInitialState } from 'slices/userOperations/notifications/reducer';
import { getNotifications } from 'slices/userOperations/notifications/thunk';

const useListNotifications = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { Notifications: NotificationsInitialState }) =>
      state.Notifications
  );


  useEffect(() => {
      dispatch(
        getNotifications({
          per_page: 10,
          search: "",
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    isLoading,
    error
  }
}

export default useListNotifications;