import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePondFile, FilePondInitialFile } from "filepond";
import { setToastError } from "slices/toast/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

type UploadableFileTypes =
  | "image/png"
  | "image/jpeg"
  | "image/jpg"
  | "image/gif"
  | "image/svg+xml"
  | "image/webp"
  | "application/pdf";

interface CustomMultipleFileUploaderProps {
  files: (string | FilePondInitialFile | Blob)[];
  handleAddFiles: (fileItems: FilePondFile[]) => void;
  allowMultiple?: boolean;
  maxFiles?: number;
  maxFileSize?: number;
  allowedFileTypes?: UploadableFileTypes[];
}

const CustomMultipleFileUploader = ({
  files,
  handleAddFiles,
  allowMultiple = true,
  maxFiles = 1,
  maxFileSize = 2000000,
  allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"],
}: CustomMultipleFileUploaderProps) => {
  const dispatch: Dispatch = useDispatch();

  const handleFileUpload = (fileItems: FilePondFile[]) => {
    handleAddFiles(fileItems);
  };

  const beforeAddFile = (file: FilePondFile) => {
    if (file.fileType === "text/html") return false;
    if (file.fileSize > maxFileSize) {
      dispatch(
        setToastError(
          ` ${maxFileSize / 1000000} MB'dan büyük dosya yükleyemezsiniz.`
        )
      );
      return false;
    } else if (
      !allowedFileTypes.includes(file.fileType as UploadableFileTypes)
    ) {
      const allowedTypes = allowedFileTypes.map((type) => {
        return type.split("/")[1];
      });
      
      dispatch(
        setToastError(
          `Yalnızca ${allowedTypes.join(
            ", "
          )} formatındaki dosyaları yükleyebilirsiniz.`
        )
      );

      return false;
    }

    return true;
  };

  return (
    <FilePond
      files={files as any[]}
      onupdatefiles={handleFileUpload}
      allowMultiple={allowMultiple}
      maxFiles={maxFiles}
      acceptedFileTypes={allowedFileTypes}
      name="files"
      className="filepond filepond-input-multiple"
      beforeAddFile={(file) => beforeAddFile(file)}
      labelIdle='Sürükleyip bırakın ya da <br/> <span class="filepond--label-action theme-text-primary">Dosya Seçin</span>'
    />
  );
};

export default CustomMultipleFileUploader;
