import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { UpdateThemeRequestPayload } from "types/themes";
import useValidateForm from "./useValidateForm";
import { ListThemesInitialState } from "slices/themes/listThemes/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const selectedListThemesState = (state: {
    ListThemes: ListThemesInitialState;
  }) => state.ListThemes;

  const selectListThemesProperties = createSelector(
    selectedListThemesState,
    (state) => ({
      theme: state.theme,
      updateTheme: state.updateTheme,
      selectedTheme: state.selectedTheme,
      updateThemeFormErrors: state.updateThemeFormErrors,
      updateThemeLoading: state.updateThemeLoading,
    })
  );

  const {
    theme,
    updateTheme,
    updateThemeFormErrors,
    selectedTheme,
    updateThemeLoading,
  } = useSelector(selectListThemesProperties);

  const regionOptions: Option[] = theme.bolge_options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const statusOptions: Option[] = theme.tema_tur_options.map((option) => ({
    value: option.value,
    label: option.text,
  }));

  const updateThemeRequestPayload: UpdateThemeRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    updateTheme,
    selectedTheme,
    regionOptions,
    statusOptions,
    isLoading: updateThemeLoading,
    updateThemeRequestPayload,
    formErrors: updateThemeFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
