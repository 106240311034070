import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";
import ContractInvoiceForm from "./ContractInvoiceForm";

interface ContractProceduresTableProps {
  isLoading: boolean;
}

const ContractProceduresTable = ({
  isLoading,
}: ContractProceduresTableProps) => {
  const { tableData, tableColumns, isOpen, toggleModal, legend } =
    useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Kontrat İşlemleri">
      <CustomReactTable
        fileName="Kontrat İşlemleri"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
        legend={legend}
      />
      <ContractInvoiceForm openModal={isOpen} toggleModal={toggleModal} />
    </CustomFormContainer>
  );
};

export default ContractProceduresTable;
