import { CustomDasboardBarChart } from "Components/Custom/UI/CustomCharts";
import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface LastThirtyDayDailySalesCardProps {
  values: {
    [key: string]: string | number;
    name: string;
  }[];
  totalAmount: number;
}

const LastThirtyDayDailySalesCard = ({ values, totalAmount }: LastThirtyDayDailySalesCardProps) => {
  const title_info = (
    <>
      {totalAmount.toString()} ₺ 
    </>
  );
  return (
    <>
    <CustomDashboardCard
        xl={4}
        title="Son 30 Günlük Satış Tutarları"
        titleInfo={title_info}
      >
        <CustomDasboardBarChart data={values} bar_fill={DASHBOARD_COLORS.orange} />
      </CustomDashboardCard>
    </>
  );
};

export default LastThirtyDayDailySalesCard;
