import UserIPsPage from 'Components/Custom/UserOperationsPages/UserIPsPage';
import useUserIPs from 'Components/Hooks/userOperations/useUserIPs';
import PageContainer from 'pages/PageContainer';

const UserIPs = () => {

    const { isLoading } = useUserIPs();

  return (
    <PageContainer title="Personel IPleri">
        <UserIPsPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default UserIPs