import { createSlice } from "@reduxjs/toolkit";

import {
  AddPlaceToVisitFormErrors,
  AddPlaceToVisitState,
} from "types/definitions";

export interface AddPlaceToVisitInitialState {
  placeToVisit: AddPlaceToVisitState;
  addPlaceToVisitFormErrors: AddPlaceToVisitFormErrors;
  isLoading: boolean;
  error: string;
}

export const addPlaceToVisitInitialState: AddPlaceToVisitInitialState = {
  placeToVisit: {
    yer_adi: "",
    aciklama: "",
    file: "",
  },
  addPlaceToVisitFormErrors: {},
  isLoading: false,
  error: "",
};

export const addPlaceToVisitSlice = createSlice({
  name: "addPlaceToVisit",
  initialState: addPlaceToVisitInitialState,
  reducers: {
    handleAddAddPlaceToVisitFormErrors(state, action) {
      state.addPlaceToVisitFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.placeToVisit = {
        ...state.placeToVisit,
        ...action.payload,
      };
    },
    resetPlaceToVisit(state) {
      state.placeToVisit = {
        yer_adi: "",
        aciklama: "",
        file: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleAddAddPlaceToVisitFormErrors,
  handleChangeInput,
  resetPlaceToVisit,
  setIsLoading,
  setError,
} = addPlaceToVisitSlice.actions;

export default addPlaceToVisitSlice.reducer;
