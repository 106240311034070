import { FOREIGN_MARKET, MAN_ALLOWANCE, OTHER_OPERATOR } from "enums";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const manAllowanceOptions: Option[] = [
    {
      value: MAN_ALLOWANCE["Bay Konaklama Kabul Ediyor"],
      label: "Kabul Ediyor",
    },
    {
      value: MAN_ALLOWANCE["Bay Konaklama Kabul Etmiyor"],
      label: "Kabul Etmiyor",
    },
    {
      value: MAN_ALLOWANCE["Bay Konaklama Gösterme"],
      label: "Gösterme",
    },
  ];

  const foreignMarketOptions: Option[] = [
    {
      value:
        FOREIGN_MARKET[
          "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılsın"
        ],
      label: "Fiyatından Satılsın",
    },
    {
      value:
        FOREIGN_MARKET[
          "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılmasın"
        ],
      label: "Fiyatından Satılmasın",
    },
    {
      value: FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"],
      label: "Gösterme",
    },
  ];

  const otherOperatorOptions: Option[] = [
    {
      value:
        OTHER_OPERATOR[
          "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber satabilirsiniz"
        ],
      label: "Ekran görüntüsüyle beraber satabilirsiniz",
    },
    {
      value:
        OTHER_OPERATOR[
          "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber sorsat yapın"
        ],
      label: "Ekran görüntüsüyle beraber sorsat yapın",
    },
    {
      value: OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"],
      label: "Gösterme",
    },
  ];

  return {
    manAllowanceOptions,
    foreignMarketOptions,
    otherOperatorOptions,
  };
};

export default useHandleForm;
