import { CustomButton } from "Components/Custom/UI";
import { FacilitySpecialDayState } from "types/facility";

interface AddNewSpecialDayButtonProps {
  values: FacilitySpecialDayState;
  validateForm: (values: FacilitySpecialDayState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onAddNewSpecialDay: () => void;
  isLoading: boolean;
}

const AddNewSpecialDayButton = ({
  values,
  validateForm,
  handleSubmitted,
  onAddNewSpecialDay,
  isLoading,
}: AddNewSpecialDayButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onAddNewSpecialDay();
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Yeni Özel Gün Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewSpecialDayButton;
