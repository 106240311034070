import { EarlyReservationDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import useEarlyReservationDiscounts from "Components/Hooks/discountsAndActions/useEarlyReservationDiscounts";
import PageContainer from "pages/PageContainer";

const EarlyReservationDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useEarlyReservationDiscounts();

  return (
    <PageContainer title="Erken Rezervasyon İndirimleri">
      <EarlyReservationDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default EarlyReservationDiscounts;
