import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";

import { getMonthlySales } from "slices/reports/monthlySales/thunk";
import {
  MonthlySalesInitialState,
  resetMonthlySales,
} from "slices/reports/monthlySales/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = (defaultState: { grup_id: string; date: string }) => {
  const { monthlySalesFilter } = useSelector(
    (state: { MonthlySales: MonthlySalesInitialState }) => state.MonthlySales
  );

  const [monthOptions, setMonthOptions] = useState<Option[]>([]);
  const [groupOptions, setGroupOptions] = useState<Option[]>([]);

  useEffect(() => {
    const monthOptions = monthlySalesFilter.tarihler.map((month) => ({
      value: month,
      label: month,
    }));

    const groupOptions = monthlySalesFilter.gruplar.map((group) => ({
      value: group.id.toString(),
      label: group.grup_adi,
    }));

    setMonthOptions(monthOptions);
    setGroupOptions(groupOptions);
  }, [monthlySalesFilter]);

  const [filterForm, setFilterForm] = useState({
    grup_id: defaultState.grup_id,
    date: defaultState.date,
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const payload = {
      grup_id: Number(filterForm.grup_id),
      date: filterForm.date,
    };
    dispatch(getMonthlySales(payload));
  };

  const handleResetFilter = () => {
    setFilterForm({
      grup_id: defaultState.grup_id,
      date: defaultState.date,
    });

    const payload = {
      grup_id: Number(defaultState.grup_id),
      date: defaultState.date,
    };

    dispatch(resetMonthlySales());
    dispatch(getMonthlySales(payload));
  };

  return {
    monthOptions,
    groupOptions,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
