import { AssignHotelToThemePage } from "Components/Custom/ThemesPages";
import useAssignHotelToTheme from "Components/Hooks/themes/useAssignHotelToTheme";
import PageContainer from "pages/PageContainer";

const AssignHotelToTheme = () => {
  const props = useAssignHotelToTheme();

  return (
    <PageContainer title="Tema Otel Ata">
      <AssignHotelToThemePage {...props} />
    </PageContainer>
  );
};

export default AssignHotelToTheme;
