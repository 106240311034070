import { createSlice } from "@reduxjs/toolkit";

import { FacilityImage } from "types/facility";
import { Pagination } from "types/GlobalTypes";

export interface ViewFeaturedImagesInitialState {
  images: FacilityImage[];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

export const viewFeaturedImagesInitialState: ViewFeaturedImagesInitialState = {
  images: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const viewFeaturedImagesSlice = createSlice({
  name: "viewFeaturedImages",
  initialState: viewFeaturedImagesInitialState,
  reducers: {
    handleSaveImages(state, action) {
      const { data, ...rest } = action.payload;

      state.images = data;
      state.pagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveImages, setIsLoading, setError } =
  viewFeaturedImagesSlice.actions;

export default viewFeaturedImagesSlice.reducer;
