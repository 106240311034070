import { createSlice } from "@reduxjs/toolkit";
import {
  CreateNotificationFormChoices,
  CreateNotificationFormErrors,
  CreateNotificationPayload,
} from "types/userOperations";

export interface CreateNotificationInitialState {
  formChoices: CreateNotificationFormChoices;
  createNotification: CreateNotificationPayload;
  createNotificationFormErrors: CreateNotificationFormErrors;
  isLoading: boolean;
  error: string;
  createNotificationLoading: boolean;
  createNotificationError: string;
}

export const createNotificationInitialState: CreateNotificationInitialState = {
  formChoices: {
    bildirim_tipi: [],
    kullanicilar: [],
    results: [],
  },
  createNotification: {
    tipi: "",
    baslik: "",
    bildirim: "",
    kullanici_idler: [],
  },
  createNotificationFormErrors: {},
  isLoading: false,
  error: "",
  createNotificationLoading: false,
  createNotificationError: "",
};

export const createNotificationSlice = createSlice({
  name: "createNotification",
  initialState: createNotificationInitialState,
  reducers: {
    handleFormChoices(state, action) {
      state.formChoices = action.payload;
    },
    handleCreateNotification(state, action) {
      state.createNotification = action.payload;
    },
    handleChangeInput(state, action) {
      state.createNotification = {
        ...state.createNotification,
        ...action.payload,
      };
    },
    handleCreateNotificationFormErrors(state, action) {
      state.createNotificationFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCreateNotificationLoading(state, action) {
      state.createNotificationLoading = action.payload;
    },
    setCreateNotificationError(state, action) {
      state.createNotificationError = action.payload;
    },
    handleResetCreateNotificationState(state) {
      state.createNotification = {
        tipi: "",
        baslik: "",
        bildirim: "",
        kullanici_idler: [],
      };
    },
  },
});

export const {
  handleFormChoices,
  handleCreateNotification,
  handleChangeInput,
  handleCreateNotificationFormErrors,
  setIsLoading,
  setError,
  setCreateNotificationLoading,
  setCreateNotificationError,
  handleResetCreateNotificationState,
} = createNotificationSlice.actions;

export default createNotificationSlice.reducer;
