import { createSlice } from "@reduxjs/toolkit";

import {
  Theme,
  ThemeDetails,
  UpdateThemeState,
  UpdateThemeFormErrors,
} from "types/themes";

export interface ListThemesInitialState {
  themes: Theme[];
  theme: ThemeDetails;
  updateTheme: UpdateThemeState;
  updateThemeFormErrors: UpdateThemeFormErrors;
  selectedTheme: number;
  isLoading: boolean;
  error: string;
  getThemeLoading: boolean;
  getThemeError: string;
  updateThemeLoading: boolean;
  updateThemeError: string;
}

export const listThemesInitialState: ListThemesInitialState = {
  themes: [],
  theme: {
    tema_tur_options: [],
    bolge_options: [],
    details: {
      tema_id: 0,
      ust_tema_id: 0,
      tema_bolge: "",
      durum: "",
      baslik: "",
      alt_baslik: "",
      aciklama: "",
      link: "",
      resim: "",
    },
  },
  updateTheme: {
    tema_id: 0,
    tema_bolge: "",
    durum: "",
    baslik: "",
    alt_baslik: "",
    aciklama: "",
    link: "",
    resim: "",
    file: "",
  },

  updateThemeFormErrors: {},
  selectedTheme: 0,
  isLoading: false,
  error: "",
  getThemeLoading: false,
  getThemeError: "",
  updateThemeLoading: false,
  updateThemeError: "",
};

export const listThemesSlice = createSlice({
  name: "listThemes",
  initialState: listThemesInitialState,
  reducers: {
    handleSaveThemes(state, action) {
      state.themes = action.payload;
    },
    handleSaveThemeDetails(state, action) {
      state.theme = action.payload;
    },
    handleSaveUpdateThemeDefault(state) {
      state.updateTheme = {
        tema_id: state.theme.details.tema_id,
        tema_bolge: state.theme.details.tema_bolge,
        durum: state.theme.details.durum,
        baslik: state.theme.details.baslik,
        alt_baslik: state.theme.details.alt_baslik,
        aciklama: state.theme.details.aciklama,
        link: state.theme.details.link,
        resim: state.theme.details.resim,
        file: "",
      };
    },
    handleChangeInput(state, action) {
      state.updateTheme = {
        ...state.updateTheme,
        ...action.payload,
      };
    },
    handleAddUpdateThemeFormErrors(state, action) {
      state.updateThemeFormErrors = action.payload;
    },
    handleSaveSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    resetUpdateThemeAfterSave(state) {
      state.updateTheme = {
        tema_id: 0,
        tema_bolge: "",
        durum: "",
        baslik: "",
        alt_baslik: "",
        aciklama: "",
        link: "",
        resim: "",
        file: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setGetThemeLoading(state, action) {
      state.getThemeLoading = action.payload;
    },
    setGetThemeError(state, action) {
      state.getThemeError = action.payload;
    },
    setUpdateThemeLoading(state, action) {
      state.updateThemeLoading = action.payload;
    },
    setUpdateThemeError(state, action) {
      state.updateThemeError = action.payload;
    },
  },
});

export const {
  handleSaveThemes,
  handleSaveThemeDetails,
  handleSaveUpdateThemeDefault,
  handleChangeInput,
  handleAddUpdateThemeFormErrors,
  handleSaveSelectedTheme,
  resetUpdateThemeAfterSave,
  setIsLoading,
  setError,
  setGetThemeLoading,
  setGetThemeError,
  setUpdateThemeLoading,
  setUpdateThemeError,
} = listThemesSlice.actions;

export default listThemesSlice.reducer;
