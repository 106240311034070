import { useDispatch, useSelector } from "react-redux";
import { UserAuthoritiesInitialState } from "slices/userOperations/authorities/reducer";
import { Dispatch, useState } from "react";
import { AddNewAuthorityPayload } from "types/userOperations";
import {
  addNewAuthority,
  getAuthoritiesAndUsers,
} from "slices/userOperations/authorities/thunk";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { selectedAuthority } = useSelector(
    (state: { UserAuthorities: UserAuthoritiesInitialState }) =>
      state.UserAuthorities
  );

  const [newAuthorityName, setNewAuthorityName] = useState("");

  const handleAddNewAuthority = () => {
    const payload: AddNewAuthorityPayload = {
      ust_yetki_id: selectedAuthority,
      yeni_yetki_adi: newAuthorityName,
    };

    dispatch(
      addNewAuthority(payload, () => {
        dispatch(getAuthoritiesAndUsers());
        setNewAuthorityName("");
      })
    );
  };

  return {
    handleAddNewAuthority,
    newAuthorityName,
    setNewAuthorityName,
  };
};

export default useHandleForm;
