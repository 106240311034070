import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CommentsCardData } from "types/dashboard";

interface CommentRowProps {
  comment: CommentsCardData;
}

const CommentRow = ({ comment }: CommentRowProps) => {
  const iconStyle: React.CSSProperties = {
    fontSize: "56px",
    lineHeight: "56px",
    opacity: "0.5",
  };
  const highlightedStyle: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "700",
    opacity: "0.5",
  };
  const textStyle: React.CSSProperties = {
    fontSize: "14px",
    margin: "0",
    maxWidth: "440px",
  };
  return (
    <div className="d-flex align-items-center gap-5">
      <div style={highlightedStyle}>{comment.rate}</div>
      <div>
        <p className="mb-2" style={highlightedStyle}>
          {comment.commenter_name}
        </p>
        <p className="m-0" style={textStyle}>
          {comment.accommodation_dates}
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-3 align-items-center">
          <i className="ri-emotion-happy-line" style={iconStyle} />
          <p style={textStyle}>{comment.positive_comment}</p>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <i className="ri-emotion-unhappy-line" style={iconStyle} />
          <p style={textStyle}>{comment.negative_comment}</p>
        </div>
      </div>
    </div>
  );
};

interface CommentsCardSliderProps {
  comments: CommentsCardData[];
}
const CommentsCardSlider = ({ comments }: CommentsCardSliderProps) => {
  const sliderStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const slideStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0"
  };
  return (
    <>
      <Swiper
        style={sliderStyle}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation]}
      >
        {comments.map((comment, index) => (
          <SwiperSlide key={index} style={slideStyle}>
            <CommentRow comment={comment} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CommentsCardSlider;
