import {
  BankInstallment,
  CalculatePriceForHotelSpecialDiscount,
} from "types/reservation";
import parse from "html-react-parser";
import moment from "moment";
import BankInstallmentsWithNoInterestTooltip from "./BankInstallmentsWithNoInterestTooltip";

interface RoomInfoProps {
  oda_adi: string;
  oda_aciklama: string;
  oda_genisligi: number;
  gostermelik_indirim_orani: number;
  kullanilabilir_ozel_indirimler: CalculatePriceForHotelSpecialDiscount[];
  secilen_ozel_indirim: CalculatePriceForHotelSpecialDiscount[];
  yetiskin_sayisi: number;
  cocuk_sayisi: number;
  giris_tarihi: string;
  cikis_tarihi: string;
  toplam_gece: number;
  minimum_gece_durum: 0 | 1;
  banka_taksit: BankInstallment[];
}

const RoomInfo = ({
  oda_adi,
  oda_aciklama,
  oda_genisligi,
  gostermelik_indirim_orani,
  secilen_ozel_indirim,
  yetiskin_sayisi,
  cocuk_sayisi,
  giris_tarihi,
  cikis_tarihi,
  toplam_gece,
  banka_taksit,
}: RoomInfoProps) => {
  return (
    <div className="d-flex flex-column justify-content-between gap-2 py-2">
      <div className="d-flex flex-column gap-1">
        <span className="fw-bold">{oda_adi}</span>
        <span className="fs-13">
          {parse(typeof oda_aciklama === "string" ? oda_aciklama : "")}
        </span>
      </div>
      <div className="d-flex bg-white">
        <div
          className="d-flex flex-column gap-2 border rounded-start p-1"
          style={{ width: "30%" }}
        >
          <span className="fs-13">
            <span className="fw-medium">Oda Genişliği:</span> {oda_genisligi} m
            <sup>2</sup>
          </span>

          <div className="d-flex gap-1 fs-13 fw-medium">
            <span> {moment(giris_tarihi).format("DD MMMM")} -</span>
            <span> {moment(cikis_tarihi).format("DD MMMM")},</span>
            <span>{toplam_gece} Gece</span>
          </div>
          <div className="d-flex gap-1 fs-13 fw-medium">
            <span> {yetiskin_sayisi} Yetişkin,</span>
            <span> {cocuk_sayisi ? `${cocuk_sayisi} Çocuk` : ""}</span>
          </div>
        </div>
        <div className="d-flex flex-column gap-2 flex-grow-1 border rounded-end border-start-0 p-1 px-2">
          {gostermelik_indirim_orani ? (
            <span className="fs-15">
              Bugüne özel indirim:{" "}
              <span className="fw-medium fs-18">
                %{gostermelik_indirim_orani}
              </span>
            </span>
          ) : (
            <></>
          )}
          <div className="d-flex flex-column gap-1 fs-13">
            <div className="d-flex justify-content-between">
              <span className="fw-semibold text-decoration-underline">
                Uygulanmış indirimler:
              </span>
              <BankInstallmentsWithNoInterestTooltip
                bankInstallments={banka_taksit}
              />
            </div>
            <div className="d-flex flex-wrap gap-1">
              {secilen_ozel_indirim.map((indirim, index) => (
                <span
                  key={index}
                  className="fw-medium bg-primary bg-opacity-10 text-primary p-1 rounded"
                >
                  {indirim.ozel_indirim_isim} | %{indirim.ozel_indirim_orani}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomInfo;
