import CreateWidgetPage from "Components/Custom/MyWebSitePages/CreateWidgetPage";
import useCreateWidget from "Components/Hooks/myWebSite/useCreateWidget";
import PageContainer from "pages/PageContainer";

const CreateWidget = () => {

  const { loading, error, isValidHotel, isLoading } = useCreateWidget();

  return (
    <PageContainer title="Web Sitesi Oluştur">
      <CreateWidgetPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default CreateWidget;
