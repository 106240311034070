import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelectors from "./DateSelectors";
import MinNightAndDiscountRate from "./MinNightAndDiscountRate";
import ApplicableDays from "./ApplicableDays";
import DiscountSwitches from "./DiscountSwitches";
import HotelSelectionAndOtherSwitches from "./HotelSelectionAndOtherSwitches";
import UpdateMobileDiscountButton from "./UpdateMobileDiscountButton";
import AddNewMobileDiscountButton from "./AddNewMobileDiscountButton";

interface MobileDiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const MobileDiscountsForm = (props: MobileDiscountsFormProps) => {
  const {
    mobileDiscount,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    addNewMobileDiscountRequestPayload,
    updateMobileDiscountRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addDiscountLoading,
    updateDiscountLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <DateSelectors
        values={mobileDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <MinNightAndDiscountRate
        values={mobileDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ApplicableDays
        values={mobileDiscount}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <DiscountSwitches values={mobileDiscount} />
      <HotelSelectionAndOtherSwitches
        values={mobileDiscount}
        roomsOptions={roomsOptions}
        conceptsOptions={conceptsOptions}
        paymentMethodOptions={paymentMethodOptions}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {mobileDiscount.id ? (
        <UpdateMobileDiscountButton
          values={mobileDiscount}
          requestPayload={updateMobileDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateDiscountLoading}
        />
      ) : (
        <AddNewMobileDiscountButton
          values={mobileDiscount}
          requestPayload={addNewMobileDiscountRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addDiscountLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default MobileDiscountsForm;
