import { Button } from "reactstrap";

interface StopButtonsProps {
  stop: 0 | 1 | 2 | 3;
  message: string;
  description: string;
}

const StopButtons = ({ stop, message, description }: StopButtonsProps) => {
  const classes =
    "flex-grow-1 bg-opacity-10 " +
    (stop === 2 ? "bg-danger text-danger" : "") +
    (stop === 3 ? "bg-primary text-primary" : "");

  return (
    <div className="d-flex flex-column gap-2">
      <Button
        className={classes}
        color={stop === 2 ? "danger" : "primary"}
        disabled
      >
        {message}
      </Button>
      <span className="flex-grow-1 text-center fs-12 text-muted">
        {description}
      </span>
    </div>
  );
};

export default StopButtons;
