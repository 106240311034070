import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { ReservationFilterPayload } from "types/reports";

interface ReservationCodeInputProps {
  values: ReservationFilterPayload;
  handleFormChange: (key: string, value: string) => void;
  fieldKey: keyof ReservationFilterPayload;
}

const ReservationCodeInput = ({
  values,
  handleFormChange,
  fieldKey
}: ReservationCodeInputProps) => {

  const value = values[fieldKey] as string;

  return (
    <>
      <CustomFormFieldContainer label="Rezervasyon Kodu">
        <Input
          type="text"
          autoCorrect="on"
          value={value}
          onChange={(e) => {
            handleFormChange(fieldKey, e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ReservationCodeInput;
