import {
  CreateRoomFormValues,
  EnterReservationFormValues,
} from "types/reservation";
import GuestInfo from "./GuestInfo";
import GuestCheckboxes from "./GuestCheckboxes";
import ReservationNeeds from "./ReservationNeeds";
import {
  CustomButton,
  CustomFormContainer,
  CustomModal,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { useState } from "react";
import { Dispatch } from "redux";
import { setToastError } from "slices/toast/reducer";
import { useDispatch } from "react-redux";

interface CreateRoomFormProps {
  formValues: EnterReservationFormValues;
  calculatedPrice: number;
  onRequestRoom: (
    oda_id: number,
    price: number,
    room: CreateRoomFormValues,
    handleResetCreateRoomForm: () => void,
    adults: number,
    children: number
  ) => void;
}

const validateCreateRoomForm = (
  reservationInfo: EnterReservationFormValues,
  formValues: CreateRoomFormValues,
  dispatch: Dispatch<any>,
  adults: number,
  children: number
) => {
  const errors: any = {};

  const selectedGuests = formValues.guests.filter((guest) => {
    if (guest.tip === "yetiskin") {
      return guest.sira <= adults;
    }
    if (guest.tip === "cocuk") {
      return guest.sira <= children;
    }
    return false;
  });

  selectedGuests.forEach((guest) => {
    if (!guest.ad_soyad) {
      errors.ad_soyad = `${guest.sira}. ${
        guest.tip === "yetiskin" ? "Yetişkin" : "Çocuk"
      } ad soyad alanı boş bırakılamaz.`;
    }

    if (!guest.dogum_tarihi) {
      errors.dogum_tarihi = `${guest.sira}. ${
        guest.tip === "yetiskin" ? "Yetişkin" : "Çocuk"
      } doğum tarihi alanı boş bırakılamaz.`;
    }

    if (!guest.tc_kimlik_no && guest.tip === "yetiskin") {
      errors.tc_kimlik_no = "Misafir TC kimlik no alanı boş bırakılamaz.";
    }
  });

  if (!reservationInfo.musteri_ad_soyad) {
    errors.musteri_ad_soyad = "Müşteri ad soyad alanı boş bırakılamaz.";
  }

  if (!reservationInfo.musteri_eposta) {
    errors.musteri_eposta = "Müşteri e-posta alanı boş bırakılamaz.";
  }

  if (!reservationInfo.musteri_tel) {
    errors.musteri_tel = "Müşteri telefon alanı boş bırakılamaz.";
  }

  if (!reservationInfo.musteri_tc) {
    errors.musteri_tc = "Müşteri TC kimlik no alanı boş bırakılamaz.";
  }

  const isValid = Object.keys(errors).length === 0;

  !isValid && dispatch(setToastError(Object.values(errors)[0]));

  return isValid;
};

const CreateRoomForm = ({
  onRequestRoom,
  formValues,
  calculatedPrice,
}: CreateRoomFormProps) => {
  const {
    createRoomFormValues,
    handleChangeInput,
    handleChangeGuestInput,
    handleResetCreateRoomForm,
  } = useHandleForm();

  const adults = Array.from(
    { length: formValues.yetiskin_sayisi },
    (_, i) => i
  );
  const children = Array.from({ length: formValues.cocuk_sayisi }, (_, i) => i);

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => setOpenModal(!openModal);

  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <CustomFormContainer title="Oda Ekle">
      {adults.map((_, index) => (
        <GuestInfo
          guestType="yetiskin"
          guestIndex={index}
          formValues={createRoomFormValues}
          handleChangeInput={handleChangeGuestInput}
        />
      ))}
      {children.map((_, index) => (
        <GuestInfo
          guestType="cocuk"
          guestIndex={index}
          formValues={createRoomFormValues}
          handleChangeInput={handleChangeGuestInput}
        />
      ))}
      <GuestCheckboxes
        formValues={createRoomFormValues}
        handleChangeInput={handleChangeInput}
      />
      <ReservationNeeds
        formValues={createRoomFormValues}
        handleChangeInput={handleChangeInput}
      />
      <div className="d-flex justify-content-end">
        <CustomButton
          classes="w-25"
          variant="primary"
          onClick={() => {
            const isValid = validateCreateRoomForm(
              formValues,
              createRoomFormValues,
              dispatch,
              formValues.yetiskin_sayisi,
              formValues.cocuk_sayisi
            );

            setIsSubmitted(true);

            if (isValid && isSubmitted) {
              toggle();
              setIsSubmitted(false);
            }
          }}
        >
          Oda Oluştur
        </CustomButton>
      </div>
      <CustomModal
        isOpen={openModal}
        handleToggle={toggle}
        title="Oda Eklensin mi?"
        message="Oda eklemek istediğinize emin misiniz?"
        approveButtonLabel="Evet"
        approveCallback={() => {
          toggle();
          onRequestRoom(
            Number(formValues.oda_id),
            calculatedPrice,
            createRoomFormValues,
            handleResetCreateRoomForm,
            formValues.yetiskin_sayisi,
            formValues.cocuk_sayisi
          );
        }}
        cancelButtonLabel="Hayır"
        cancelCallback={toggle}
      />
    </CustomFormContainer>
  );
};

export default CreateRoomForm;
