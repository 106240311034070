import { Dispatch, useState } from "react";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { useDispatch } from "react-redux";
import { handleAddCommentFormErrors } from "slices/comments/addComment/reducer";
import { setToastError } from "slices/toast/reducer";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddCommentState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddCommentFormErrors = {};

    if (!values.cikis_tarihi || !values.giris_tarihi) {
      errors.cikis_tarihi = "Konaklama Tarihleri boş bırakılamaz.";
    }
    if (!values.musteri_adi) {
      errors.musteri_adi = "Müşteri adı boş bırakılamaz.";
    }
    if (!values.kategori) {
      errors.kategori = "Kategori boş bırakılamaz.";
    }
    if (
      !values.cevap1 ||
      !values.cevap2 ||
      !values.cevap3 ||
      !values.cevap4 ||
      !values.cevap5 ||
      !values.cevap6
    ) {
      errors.cevap1 = "Otel Puanları boş bırakılamaz.";
    }
    for (let i = 1; i <= 6; i++) {
      const fieldName = `cevap${i}` as keyof AddCommentState;
      if (!values[fieldName]) {
        errors[fieldName] = "Bu alan boş bırakılamaz.";
      }
    }

    dispatch(handleAddCommentFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddCommentState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  const handleSubmitted = (submitted: boolean) => {
    setIsSubmitted(submitted);
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted,
    isSubmitted,
  };
};

export default useValidateForm;
