import FilterForm from "./FilterForm";
import PhoneStatusTable from "./PhoneStatusTable";

interface PhoneStatusPageProps {
  isLoading: boolean;
}

const PhoneStatusPage = ({ isLoading }: PhoneStatusPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <PhoneStatusTable isLoading={isLoading} />
    </div>
  );
};

export default PhoneStatusPage;
