import { createSlice } from "@reduxjs/toolkit";

import {
  AdsAndHotels,
  MatchedHotel,
  UnmatchedHotel,
  UnmatchedAdsGroup,
} from "types/adsAndAnalyze";
import { Pagination } from "types/GlobalTypes";

export interface AdsGroupsInitialState {
  adsAndHotels: AdsAndHotels;
  matchedHotels: MatchedHotel[];
  unmatchedHotels: UnmatchedHotel[];
  unmatchedAdsGroups: UnmatchedAdsGroup[];
  isLoading: boolean;
  error: string;
  matchedHotelsLoading: boolean;
  unmatchedHotelsLoading: boolean;
  unmatchedAdsGroupsLoading: boolean;
  matchAdHotelLoading: boolean;
  matchedHotelsError: string;
  unmatchedHotelsError: string;
  unmatchedAdsGroupsError: string;
  matchAdHotelError: string;
  matchedHotelPagination: Pagination;
  unmatchedHotelPagination: Pagination;
  unmatchedAdsGroupPagination: Pagination;
}

const initialState: AdsGroupsInitialState = {
  adsAndHotels: {
    reklam: [],
    oteller: [],
  },
  matchedHotels: [],
  unmatchedHotels: [],
  unmatchedAdsGroups: [],
  isLoading: false,
  error: "",
  matchedHotelsLoading: false,
  unmatchedHotelsLoading: false,
  unmatchedAdsGroupsLoading: false,
  matchAdHotelLoading: false,
  matchedHotelsError: "",
  unmatchedHotelsError: "",
  unmatchedAdsGroupsError: "",
  matchAdHotelError: "",
  matchedHotelPagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  unmatchedHotelPagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  unmatchedAdsGroupPagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
};

const adsGroupsSlice = createSlice({
  name: "adsGroups",
  initialState,
  reducers: {
    handleSaveAdsAndHotels(state, action) {
      state.adsAndHotels = action.payload;
    },
    handleSaveMatchedHotels(state, action) {
      const { veriler, ...rest } = action.payload;
      state.matchedHotels = veriler;
      state.matchedHotelPagination = rest;
    },
    handleSaveUnmatchedHotels(state, action) {
      const { data, ...rest } = action.payload;
      state.unmatchedHotels = data;
      state.unmatchedHotelPagination = rest;
    },
    handleSaveUnmatchedAdsGroups(state, action) {
      const { veriler, ...rest } = action.payload;
      state.unmatchedAdsGroups = veriler;
      state.unmatchedAdsGroupPagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMatchedHotelsLoading(state, action) {
      state.matchedHotelsLoading = action.payload;
    },
    setUnmatchedHotelsLoading(state, action) {
      state.unmatchedHotelsLoading = action.payload;
    },
    setUnmatchedAdsGroupsLoading(state, action) {
      state.unmatchedAdsGroupsLoading = action.payload;
    },
    setMatchAdHotelLoading(state, action) {
      state.matchAdHotelLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setMatchedHotelsError(state, action) {
      state.matchedHotelsError = action.payload;
    },
    setUnmatchedHotelsError(state, action) {
      state.unmatchedHotelsError = action.payload;
    },
    setUnmatchedAdsGroupsError(state, action) {
      state.unmatchedAdsGroupsError = action.payload;
    },
    setMatchAdHotelError(state, action) {
      state.matchAdHotelError = action.payload;
    },
  },
});

export const {
  handleSaveAdsAndHotels,
  handleSaveMatchedHotels,
  handleSaveUnmatchedHotels,
  handleSaveUnmatchedAdsGroups,
  setIsLoading,
  setMatchedHotelsLoading,
  setUnmatchedHotelsLoading,
  setUnmatchedAdsGroupsLoading,
  setMatchAdHotelLoading,
  setError,
  setMatchedHotelsError,
  setUnmatchedHotelsError,
  setUnmatchedAdsGroupsError,
  setMatchAdHotelError,
} = adsGroupsSlice.actions;

export default adsGroupsSlice.reducer;
