import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

const HotelGroup = () => {
  const { tableData, tableColumns } = useHandleTable();

  return (
    <CustomReactTable
      fileName="Zincir Otel Bilgileri"
      tableData={tableData}
      tableColumns={tableColumns}
    />
  );
};

export default HotelGroup;
