import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CompetitorAnalysisFilterFormValues } from "types/adsAndAnalyze";

interface Option {
  value: string;
  label: string;
}

interface FilterFormProps {
  formValues: CompetitorAnalysisFilterFormValues;
  setFormValues: (formValues: CompetitorAnalysisFilterFormValues) => void;
}

const FilterForm = ({ formValues, setFormValues }: FilterFormProps) => {
  const {
    formOptions,
    handleFormChange,
    isLoading,
    onGetCompetitorAnalysis,
    onResetForm,
    onChangeQuery,
    loadOptions,
  } = useHandleForm(formValues, setFormValues);

  return (
    <CustomFormContainer title="Filtreleme">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "1rem",
        }}
      >
        <Select
          options={formOptions.isEbOptions}
          value={
            formOptions.isEbOptions.find(
              (option) => Number(option.value) === formValues.isEb
            ) || null
          }
          onChange={(selectedOption: Option) => {
            handleFormChange("isEb", Number(selectedOption.value));
          }}
          isSearchable
        />
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={[
            {
              value: 0,
              label: "Tüm Oteller",
            },
          ]}
          onInputChange={onChangeQuery}
          onChange={(selectedOption: any) => {
            handleFormChange("hotelId", selectedOption.value);
          }}
          placeholder="Aradığınız Oteli Yazın..."
        />
        <Select
          options={formOptions.regionsOptions}
          value={
            formOptions.regionsOptions.find(
              (region) => region.value === formValues.region.toString()
            ) || null
          }
          onChange={(selectedOption: Option) => {
            handleFormChange("region", selectedOption.value);
          }}
          placeholder="İl/İlçe"
          isSearchable
        />
        <Select
          value={
            formOptions.facilityResponsiblesOptions.find(
              (facilityResponsible) =>
                facilityResponsible.value === formValues.facilityResponsible
            ) || null
          }
          options={formOptions.facilityResponsiblesOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("facilityResponsible", selectedOption.value);
          }}
          placeholder="Tesis Sorumlusu"
          isSearchable
        />
        <Select
          value={
            formOptions.welcomeTypeOptions.find(
              (welcomeType) => welcomeType.value === formValues.welcomeType
            ) || null
          }
          options={formOptions.welcomeTypeOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("welcomeType", selectedOption.value);
          }}
          placeholder="Karşılama Türü"
          isSearchable
        />
        <Select
          value={
            formOptions.hotelPriceStatusOptions
              .map((hotelPriceStatus) => hotelPriceStatus.options)
              .flat()
              .find(
                (hotelPriceStatus) =>
                  hotelPriceStatus.value === formValues.hotelPriceStatus
              ) || null
          }
          options={formOptions.hotelPriceStatusOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("hotelPriceStatus", selectedOption.value);
          }}
          placeholder="Otel Fiyat Durumu"
          isSearchable
        />
        <Select
          value={
            formOptions.quotationStatusOptions
              .map((quotationStatus) => quotationStatus.options)
              .flat()
              .find(
                (quotationStatus) =>
                  quotationStatus.value === formValues.quotationStatus
              ) || null
          }
          options={formOptions.quotationStatusOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("quotationStatus", selectedOption.value);
          }}
          placeholder="Kontenjan Durumu"
          isSearchable
        />
        <Select
          value={
            formOptions.adStatusOptions.find(
              (adStatus) => adStatus.value === formValues.adStatus
            ) || null
          }
          options={formOptions.adStatusOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("adStatus", selectedOption.value);
          }}
          placeholder="Reklam Durumu"
          isSearchable
        />
        <Select
          value={
            formOptions.providersOptions.find(
              (provider) => provider.value === formValues.provider
            ) || null
          }
          options={formOptions.providersOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("provider", selectedOption.value);
          }}
          placeholder="Fiyat Sağlayıcı"
          isSearchable
        />
        <Select
          value={
            formOptions.paymentTypesOptions.find(
              (paymentType) => paymentType.value === formValues.paymentType
            ) || null
          }
          options={formOptions.paymentTypesOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("paymentType", selectedOption.value);
          }}
          placeholder="Ödeme Türü"
          isSearchable
        />
        <Select
          value={
            formOptions.roomNumbersOptions.find(
              (roomNumber) =>
                roomNumber.value === formValues.roomNumber.toString()
            ) || null
          }
          options={formOptions.roomNumbersOptions}
          onChange={(selectedOption: Option) => {
            handleFormChange("roomNumber", selectedOption.value);
          }}
          placeholder="Oda Sayısı"
          isSearchable
        />
        <div className="d-flex gap-2">
          <CustomButton
            variant="warning"
            onClick={onResetForm}
            classes="flex-grow-1"
          >
            Sıfırla
          </CustomButton>
          <CustomButton
            variant="success"
            onClick={onGetCompetitorAnalysis}
            isLoading={isLoading}
            classes="flex-grow-1"
          >
            Filtrele
          </CustomButton>
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default FilterForm;
