import { ReservationCallHotel } from "types/reservation";
import parse from "html-react-parser";
import { CustomFormContainer } from "Components/Custom/UI";

interface ReservationCallHeaderProps {
  hotelInfo: ReservationCallHotel | null;
}

const ReservationCallHeader = ({ hotelInfo }: ReservationCallHeaderProps) => {
  return (
    <>
      {hotelInfo && (
        <div className="d-flex flex-column gap-2">
          <div
            className="gap-2"
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            <p
              className="fw-bold fs-20 m-0 theme-bg-success py-1 px-4 rounded"
              style={{ width: "fit-content" }}
            >
              {hotelInfo.otel_adi}
            </p>
            <div className="d-flex gap-2">
              <div className="d-flex gap-2 align-items-center theme-bg-danger py-1 px-2 rounded">
                <p className="m-0 fs-14 text-white text-nowrap">Karşılama:</p>
                <p className="m-0 fs-18 fw-semibold text-white text-nowrap">
                  {hotelInfo.karsilama_kodu}
                </p>
              </div>
              <div className="d-flex gap-2 align-items-center theme-bg-warning py-1 px-2 rounded">
                <p className="m-0 fs-14 text-white text-nowrap">Hesaplama:</p>
                <p className="m-0 fs-18 fw-semibold text-white text-nowrap">
                  {hotelInfo.fiyat_hesaplama}
                </p>
              </div>
              {hotelInfo.otel_dahili ? (
                <div className="d-flex gap-2 align-items-center theme-bg-primary py-1 px-2 rounded">
                  <p className="m-0 fs-14 text-white text-nowrap">
                    Otel Yönlendirme:
                  </p>
                  <p className="m-0 fs-18 fw-semibold text-white text-nowrap">
                    {hotelInfo.otel_dahili}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {hotelInfo.crm_aciklama ? (
            <CustomFormContainer>
              {parse(hotelInfo.crm_aciklama)}
            </CustomFormContainer>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default ReservationCallHeader;
