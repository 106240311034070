import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/giftsAndPromotions/reducer";
import {
  GiftAndPromotionFormErrors,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

import Select from "react-select";
import { FormGroup, Input } from "reactstrap";

interface SelectFieldsProps {
  values: GiftAndPromotionState;
  formErrors: GiftAndPromotionFormErrors;
  validateFormChange: (values: GiftAndPromotionState) => void;
  promotionOptions: Option[];
  promotionRateOptions: Option[];
  promotionPersonOptions: Option[];
}

interface Option {
  value: number;
  label: string;
}
const SelectFields = ({
  values,
  formErrors,
  validateFormChange,
  promotionOptions,
  promotionRateOptions,
  promotionPersonOptions,
}: SelectFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Verilecek Hediye - Promosyon"
        error={formErrors.hediye_id}
      >
        <div className="d-flex gap-2 justify-content-between">
          <div className=" flex-grow-1">
            <Select
              value={
                values.hediye_id
                  ? promotionOptions.find(
                      (option) => option.value === values.hediye_id
                    )
                  : null
              }
              onChange={(selected: Option) => {
                const id = selected ? selected.value : 0;
                dispatch(handleChangeInput({ hediye_id: id }));
                validateFormChange({ ...values, hediye_id: id });
              }}
              options={promotionOptions}
              placeholder="Promosyon Seçiniz"
            />
          </div>
          <div className=" flex-grow-1">
            <Select
              value={
                promotionRateOptions.find(
                  (option) => option.value === values.indirim_orani
                ) || null
              }
              onChange={(selected: Option) => {
                const rate = selected ? selected.value : 0;
                dispatch(handleChangeInput({ indirim_orani: rate }));
              }}
              options={promotionRateOptions}
              placeholder="İndirim Oranı Seçiniz"
            />
          </div>
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Geçerli Kişi Sayısı">
        <Select
          value={
            promotionPersonOptions.find(
              (option) => option.value === values.gecerli_kisi_sayisi
            ) || null
          }
          onChange={(selected: Option) => {
            const numberOfPerson = selected ? selected.value : 0;
            dispatch(
              handleChangeInput({ gecerli_kisi_sayisi: numberOfPerson })
            );
          }}
          options={promotionPersonOptions}
          placeholder="Kişi Sayısı Seçiniz"
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tüm Odalara Uygula"
        tooltipId="applyAllRooms"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.grup_id === 0}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  grup_id: values.grup_id === 0 ? 1 : 0,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default SelectFields;
