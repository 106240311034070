import {  FooterLink } from "types/myWebSite";
import { FilePondFile } from "filepond";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandlePayload = (
  requestPayload: FormData,
  values: FooterLink
) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const getPayload = () => {
    requestPayload.append("otel_id", hotel.otel_id.toString());

    Object.entries(values).forEach(([key, value]) => {
      if (key === "resim") {
        if (value) {
          const file = value as FilePondFile;
          requestPayload.append(key, file.file, file.file.name);
        } else {
          requestPayload.append(key, "");
        }
      } else if (
        ["otel_fiyat_id", "acante2_id", "resim_onizlemesi"].includes(key)
      ) {
      } else if (key === "otel_odasayisi") {
        requestPayload.append("otel_oda_sayisi", value.toString());
      } else if (value instanceof Array) {
        const newValue: {
          [key: string]: boolean;
        } = {};
        value.forEach((item) => {
          newValue[item] = true;
        });
        requestPayload.append(key, JSON.stringify(newValue));
      } else {
        if (key !== "otel_id"){requestPayload.append(key, value);}
      }
    });

    console.log("req paload from payload hook :", requestPayload)

    return requestPayload;
  };

  const { otel_id } = hotel;

  return { getPayload, otel_id };
};

export default useHandlePayload;
