import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { HotelInitialState } from "slices/hotel/reducer";
import { HotelContractsInitialState } from "slices/financeAndAccounting/hotelContracts/reducer";
import { getHotelContracts } from "slices/financeAndAccounting/hotelContracts/thunk";

const useHotelContracts = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const { isLoading } = useSelector(
    (state: { HotelContracts: HotelContractsInitialState }) =>
      state.HotelContracts
  );

  useEffect(() => {
    if (otel_id) {
      const payload = {
        otel_id,
        per_page: 10,
        page: 1,
        search: "",
      };
      dispatch(getHotelContracts(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    error,
    isValidHotel,
    isLoading,
  };
};

export default useHotelContracts;
