import DailyReservationsChart from "./DailyReservationsChart";
import DailyReservationsTable from "./DailyReservationsTable";
import FilterForm from "./FilterForm";

interface DailyReservationsPageProps {
  isLoading: boolean;
}

const DailyReservationsPage = ({ isLoading }: DailyReservationsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <DailyReservationsChart />
      <FilterForm />
      <DailyReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default DailyReservationsPage;
