import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/hourlyDiscounts/reducer";
import {
  HourlyDiscountState,
  HourlyDiscountFormErrors,
} from "types/DiscountsAndActions";

interface DiscountRateAndSwitchesProps {
  values: HourlyDiscountState;
  formErrors: HourlyDiscountFormErrors;
  validateFormChange: (values: HourlyDiscountState) => void;
}

const DiscountRateAndSwitches = ({
  values,
  formErrors,
  validateFormChange,
}: DiscountRateAndSwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İndirim Oranı"
        error={formErrors.indirim_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeInput({ indirim_orani: parseInt(e.target.value) })
              );
              validateFormChange({
                ...values,
                indirim_orani: parseInt(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Birlikte Kullanılabilecek İndirimler"
        tooltipId="discountsCanBeUsedTogether"
      >
        <div className="d-flex flex-column gap-3 border p-2 bg-white rounded">
          <FormGroup switch>
            <Input
              id="eb_birlikte"
              type="switch"
              checked={values.eb_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    eb_birlikte: values.eb_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="eb_birlikte">Erken Rezervasyon</Label>
          </FormGroup>
          <FormGroup switch>
            <Input
              id="kalode_birlikte"
              type="switch"
              checked={values.kalode_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    kalode_birlikte: values.kalode_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="kalode_birlikte">Kal-Öde</Label>
          </FormGroup>
          <FormGroup switch>
            <Input
              id="diger_birlikte"
              type="switch"
              checked={values.diger_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    diger_birlikte: values.diger_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="diger_birlikte">Diğer İndirimler</Label>
          </FormGroup>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountRateAndSwitches;
