import { Label } from "reactstrap";

interface CategoryHotelRowProps {
  values: { [key: string]: string[] };
  title: string;
}

const CategoryHotelRow = ({ values, title }: CategoryHotelRowProps) => {
  return (
    <>
      <Label>{title}</Label>
      <table className="bg-white table table-bordered p-12">
        <tbody>
          {Object.entries(values).map(([categoryName, hotelNames], index) => (
            <tr key={index}>
              <td
                style={{
                  verticalAlign: "middle",
                  width: "200px",
                  fontWeight: "bold",
                }}
              >
                {categoryName}
              </td>
              <td>
                <ul
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "40px",
                  }}
                >
                  {hotelNames.map((hotelName, idx) => (
                    <li key={idx}> {hotelName}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CategoryHotelRow;