import { createSlice } from "@reduxjs/toolkit";
import {
  UserAuthority,
  User,
  GetAuthoritiesAndUsersResponse,
  DetailedUserAuthority,
  GetAuthoritiesOfSelectedUserResponse,
} from "types/userOperations";

export interface UserAuthoritiesInitialState {
  users: User[];
  authorities: UserAuthority[];
  userAuthorities: DetailedUserAuthority[];
  selectedUsers: number[];
  selectedAuthority: number;
  selectedPermissions: { [authorityId: number]: string };
  isLoading: boolean;
  error: string;
  addAuthoritiesToUsersLoading: boolean;
  addAuthoritiesToUsersError: string;
  addAuthoritiesLoading: boolean;
  addAuthoritiesError: string;
}

export const userAuthoritiesInitialState: UserAuthoritiesInitialState = {
  users: [],
  authorities: [],
  userAuthorities: [],
  selectedUsers: [],
  selectedAuthority: 0,
  selectedPermissions: {},
  isLoading: false,
  error: "",
  addAuthoritiesToUsersLoading: false,
  addAuthoritiesToUsersError: "",
  addAuthoritiesLoading: false,
  addAuthoritiesError: "",
};

export const userAuthoritiesSlice = createSlice({
  name: "userAuthorities",
  initialState: userAuthoritiesInitialState,
  reducers: {
    handleSaveUsersAndAuthorities(state, action) {
      const payload = action.payload as GetAuthoritiesAndUsersResponse;

      /* I converted the "kullanicilar" object to an array. "yetkiler" is already an array  */
      const usersData: User[] = Object.keys(payload.kullanicilar).map((id) => ({
        id: id,
        name: payload.kullanicilar[id],
      }));

      state.authorities = payload.yetkiler;
      state.users = usersData;

    },
    handleSaveUsers(state, action) {
      state.users = action.payload;
    },
    handleSaveAuthorities(state, action) {
      state.authorities = action.payload;
    },
    handleSaveUserAuthorities(state, action) {
      const payload = action.payload as GetAuthoritiesOfSelectedUserResponse;
    
      state.userAuthorities = payload.yetkiler;

      state.selectedPermissions = {};
    
      payload.yetkiler.forEach((authority) => {
        if (authority.yetkisi) {
          state.selectedPermissions[authority.id] = authority.yetkisi;
        }
      });
      
    },
    handleSelectedUsers(state, action) {
      state.selectedUsers = action.payload;
    },
    handleSelectedAuthority(state, action) {
      state.selectedAuthority = action.payload;
    },
    handleSetPermissionForAuthority(state, action) {
      const { authorityId, permission } = action.payload;
      state.selectedPermissions[authorityId] = permission;
    },
    resetSelectedPermissions(state) {
      state.selectedPermissions = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddAuthoritiesToUsersLoading(state, action) {
      state.addAuthoritiesToUsersLoading = action.payload;
    },
    setAddAuthoritiesToUsersError(state, action) {
      state.addAuthoritiesToUsersError = action.payload;
    },
    setAddAuthoritiesLoading(state, action) {
      state.addAuthoritiesLoading = action.payload;
    },
    setAddAuthoritiesError(state, action) {
      state.addAuthoritiesError = action.payload;
    },
  },
});

export const {
  handleSaveUsersAndAuthorities,
  handleSaveUsers,
  handleSaveAuthorities,
  handleSelectedUsers,
  handleSelectedAuthority,
  handleSaveUserAuthorities,
  handleSetPermissionForAuthority,
  resetSelectedPermissions,
  setIsLoading,
  setError,
  setAddAuthoritiesToUsersLoading,
  setAddAuthoritiesToUsersError,
  setAddAuthoritiesLoading,
  setAddAuthoritiesError,
} = userAuthoritiesSlice.actions;

export default userAuthoritiesSlice.reducer;
