import HeaderContainer from "Components/Custom/PricesAndQuotaPages/CommonContainers/HeaderContainer";
import { Room } from "types/PricesQuotas";

interface QuotaEntryTableHeaderProps {
  room: Room;
}

const QuotaEntryTableHeader = ({ room }: QuotaEntryTableHeaderProps) => {
  if (!room) {
    return null;
  }
  return <HeaderContainer concepts={room.pansiyonlar} title="Kontenjanlar" />;
};

export default QuotaEntryTableHeader;
