import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { AddGalleryImageFormErrors, GalleryImage } from "types/myWebSite";
import { handleChangeAddGalleryImageInput } from "slices/myWebSite/additionalPages/reducer";


interface GalleryImageFieldProps {
  values: GalleryImage;
  formErrors: AddGalleryImageFormErrors;
  validateFormChange: (values: GalleryImage) => void;
}

const GalleryImageField = ({
  values,
  validateFormChange,
  formErrors,
}: GalleryImageFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.resim ? [values.resim] : [];

  return (
    <>
      <CustomFormFieldContainer
        label="Galeri Resmi : "
        error={formErrors.resim}
        required
      >
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeAddGalleryImageInput({resim: fileItems[0]}));
            validateFormChange({ ...values, resim: fileItems[0] });
          }}
          maxFiles={1}
          // maxFiles={10}
          maxFileSize={10000000}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default GalleryImageField;
