import { createSlice } from "@reduxjs/toolkit";

import { DailyCallsChartsResponse } from "types/reports";

export interface DailyCallsChartsInitialState {
  dailyCallsChartsResponse: DailyCallsChartsResponse;
  isLoading: boolean;
  error: string;
}

const initialState: DailyCallsChartsInitialState = {
  dailyCallsChartsResponse: {
    secilen_gun: [],
    cevaplanan_toplam_cagri: [],
    giden_cagri_istek: [],
    sonra_aranacak: [],
    fiyat_arastiriyor: [],
    fiyat_yok_dolu: [],
    rezervasyon_disi: [],
    cevaplanan_saatlik_cagri: [],
  },
  isLoading: false,
  error: "",
};

const dailyCallsChartsSlice = createSlice({
  name: "dailyCallsCharts",
  initialState,
  reducers: {
    handleSaveDailyCallsChartsResponse(state, action) {
      state.dailyCallsChartsResponse = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveDailyCallsChartsResponse, setIsLoading, setError } =
  dailyCallsChartsSlice.actions;

export default dailyCallsChartsSlice.reducer;
