import { createSlice } from "@reduxjs/toolkit";

import { OutboundCall } from "types/reports";

export interface OutboundCallsListInitialState {
  outboundCalls: OutboundCall[];
  isLoading: boolean;
  error: string;
}

const initialState: OutboundCallsListInitialState = {
  outboundCalls: [],
  isLoading: false,
  error: "",
};

const outboundCallsSlice = createSlice({
  name: "outboundCalls",
  initialState,
  reducers: {
    handleSaveOutboundCalls(state, action) {
      state.outboundCalls = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveOutboundCalls, setIsLoading, setError } =
  outboundCallsSlice.actions;

export default outboundCallsSlice.reducer;
