import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";

import { Button, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { addSpecialOffer } from "slices/discountsAndActions/specialDiscounts/thunk";
import { useHttpRequest } from "Components/Hooks";
import { GET_SPECIAL_OFFER } from "helpers/url_helper";
import Select from "react-select";
import {
  handleChangeInput,
  handleSaveSpecialOffers,
} from "slices/discountsAndActions/specialDiscounts/reducer";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface OffersProps {
  values: SpecialDiscountState;
  formErrors: SpecialDiscountFormErrors;
  validateFormChange: (values: SpecialDiscountState) => void;
}

interface Option {
  value: string;
  label: string;
}

interface Offer {
  id: number;
  indirim_isim: string;
}

const Offers = ({ values, validateFormChange, formErrors }: OffersProps) => {
  const [newOffer, setNewOffer] = useState("");
  const [options, setOptions] = useState<Option[]>([]);

  const dispatch: Dispatch<any> = useDispatch();

  const handleAddOffer = () => {
    if (!newOffer) return;
    dispatch(addSpecialOffer(newOffer));
    setNewOffer("");
  };

  const { isLoading, httpRequest } = useHttpRequest();

  useEffect(() => {
    httpRequest({
      config: {
        method: "get",
        url: GET_SPECIAL_OFFER,
      },
      successCallback: (response) => {
        const newOptions: Option[] =
          response.data ||
          response.map((item: Offer) => {
            return {
              value: item.id.toString(),
              label: item.indirim_isim,
            };
          });
        setOptions(newOptions);
        dispatch(handleSaveSpecialOffers(response.data || response));
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomFormFieldContainer label="Yeni Kampanya Adı">
        <InputGroup>
          <Input
            placeholder="Yeni Kampanya Adı Belirleyin..."
            value={newOffer}
            onChange={(e) => setNewOffer(e.target.value)}
          />
          <InputGroupText>
            <Button color="primary" className="btn-sm" onClick={handleAddOffer}>
              Ekle
            </Button>
          </InputGroupText>
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Kampanya Adı"
        error={formErrors.indirim_tipi}
      >
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Select
            value={
              options.find((item) => item.value === values.indirim_tipi) || null
            }
            onChange={(selected: Option) => {
              dispatch(
                handleChangeInput({
                  indirim_tipi: selected.value,
                })
              );
              validateFormChange({
                ...values,
                indirim_tipi: selected.value,
              });
            }}
            options={options}
            placeholder="Kampanya Seçiniz..."
          />
        )}
      </CustomFormFieldContainer>
    </>
  );
};

export default Offers;
