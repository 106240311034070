import { CustomFormContainer } from "Components/Custom/UI";
import useHandleList from "./useHandleList";
import ImageContainer from "./ImageContainer";

interface ViewRoomImagesProps {
  selectedRoom: number;
}

const ViewRoomImages = ({ selectedRoom }: ViewRoomImagesProps) => {
  const { facilityRoomImages, handelDeleteImage } = useHandleList(selectedRoom);

  if (!selectedRoom) {
    return <></>;
  }
  return (
    <CustomFormContainer title="Oda Resimleri" lg={12} xl={12}>
      <div className="d-flex flex-column gap-2 bg-white px-4 py-2 rounded">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "1rem",
          }}
        >
          {Object.entries(facilityRoomImages).map(([key, value]) => {
            return (
              <>
                {value ? (
                  <ImageContainer
                    key={key}
                    id={key}
                    src={value}
                    alt={value}
                    handleDelete={handelDeleteImage}
                  />
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </div>
    </CustomFormContainer>
  );
};

export default ViewRoomImages;
