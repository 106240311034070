import { createSlice } from "@reduxjs/toolkit";

import {
  FacilityImage,
  FacilityPullImageFormUrlState,
  FacilityPullImageFormUrlErrors,
  FacilityImageFile,
} from "types/facility";

export interface FacilityImagesInitialState {
  facilityImages: FacilityImage[];
  orderedFacilityImages: FacilityImage[];
  facilityPullImageFormUrl: FacilityPullImageFormUrlState;
  facilityPullImageFormUrlErrors: FacilityPullImageFormUrlErrors;
  facilityImageFiles: FacilityImageFile[];
  selectedImages: string[];
  isLoading: boolean;
  error: string;
  addImagesLoading: boolean;
  addImagesError: string;
  updateImagesOrderLoading: boolean;
  updateImagesOrderError: string;
  deleteImagesLoading: boolean;
  deleteImagesError: string;
}

export const facilityImagesInitialState: FacilityImagesInitialState = {
  facilityImages: [],
  orderedFacilityImages: [],
  facilityPullImageFormUrl: {
    url: "",
  },
  facilityPullImageFormUrlErrors: {},
  facilityImageFiles: [],
  selectedImages: [],
  isLoading: false,
  error: "",
  addImagesLoading: false,
  addImagesError: "",
  updateImagesOrderLoading: false,
  updateImagesOrderError: "",
  deleteImagesLoading: false,
  deleteImagesError: "",
};

const facilityImagesSlice = createSlice({
  name: "facilityImages",
  initialState: facilityImagesInitialState,
  reducers: {
    handleSaveFacilityImages(state, action) {
      const payload = [...action.payload];
      const sortedPayload = payload.sort(
        (a: FacilityImage, b: FacilityImage) => a.sira - b.sira
      );
      state.facilityImages = sortedPayload;
    },
    handleSaveOrderedFacilityImages(state, action) {
      const payload = [...action.payload];
      const sortedPayload = payload.sort(
        (a: FacilityImage, b: FacilityImage) => a.sira - b.sira
      );

      state.orderedFacilityImages = sortedPayload;
    },
    handleChangeFacilityPullImageFormUrlInput(state, action) {
      state.facilityPullImageFormUrl = {
        ...state.facilityPullImageFormUrl,
        ...action.payload,
      };
    },
    handleAddFacilityPullImageFormUrlErrors(state, action) {
      state.facilityPullImageFormUrlErrors = action.payload;
    },
    handleChangeFacilityImageFilesInput(state, action) {
      state.facilityImageFiles = action.payload;
    },
    handleSelectAllImages(state) {
      state.selectedImages = state.orderedFacilityImages.map(
        (img) => img.resim_url
      );
    },
    handleDeselectAllImages(state) {
      state.selectedImages = [];
    },
    handleResetOrderedFacilityImagesToDefault(state) {
      state.orderedFacilityImages = state.facilityImages;
    },
    handleChangeSelectedImages(state, action) {
      const image = action.payload;
      if (state.selectedImages.includes(image)) {
        state.selectedImages = state.selectedImages.filter(
          (img) => img !== image
        );
      } else {
        state.selectedImages = [...state.selectedImages, image];
      }
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddImagesLoading(state, action) {
      state.addImagesLoading = action.payload;
    },
    setAddImagesError(state, action) {
      state.addImagesError = action.payload;
    },
    setUpdateImagesOrderLoading(state, action) {
      state.updateImagesOrderLoading = action.payload;
    },
    setUpdateImagesOrderError(state, action) {
      state.updateImagesOrderError = action.payload;
    },
    setDeleteImagesLoading(state, action) {
      state.deleteImagesLoading = action.payload;
    },
    setDeleteImagesError(state, action) {
      state.deleteImagesError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityImages,
  handleSaveOrderedFacilityImages,
  handleChangeFacilityPullImageFormUrlInput,
  handleAddFacilityPullImageFormUrlErrors,
  handleChangeFacilityImageFilesInput,
  handleSelectAllImages,
  handleDeselectAllImages,
  handleResetOrderedFacilityImagesToDefault,
  handleChangeSelectedImages,
  setIsLoading,
  setError,
  setAddImagesLoading,
  setAddImagesError,
  setUpdateImagesOrderLoading,
  setUpdateImagesOrderError,
  setDeleteImagesLoading,
  setDeleteImagesError,
} = facilityImagesSlice.actions;

export default facilityImagesSlice.reducer;
