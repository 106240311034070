import { CustomLoadingPage } from "Components/Custom/UI";
import { PlaceToVisit } from "types/definitions";
import UpdatePlaceToVisitForm from "./UpdatePlaceToVisitForm";
import PlaceToVisitSelector from "./PlaceToVisitSelector";

interface ListPlacesToVisitPageProps {
  placesToVisitList: PlaceToVisit[];
  placeToVisit: PlaceToVisit;
  loading: boolean;
  error: string;
}

const ListPlacesToVisitPage = ({
  placesToVisitList,
  placeToVisit,
  loading,
}: ListPlacesToVisitPageProps) => {
  const { id } = placeToVisit;
  return (
    <>
      <CustomLoadingPage isLoading={loading} />
      <div className="d-flex flex-column gap-3">
        <PlaceToVisitSelector placesToVisitList={placesToVisitList} />
        {id ? <UpdatePlaceToVisitForm /> : <></>}
      </div>
    </>
  );
};

export default ListPlacesToVisitPage;
