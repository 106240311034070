import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import {
  approveComment,
  deleteComment,
  getComments,
  updateComment,
} from "slices/comments/listComments/thunk";
import { setToastError } from "slices/toast/reducer";
import { UpdateCommentPayload } from "types/comments";
import { useNavigate } from "react-router-dom";

/*
 * Since we do not have a single "comment" object in the Redux store to represent the selected comment,
 * we are using local state in this hook to manage the visibility of the update comment modal for each comment.
 *
 * We maintain an object `showUpdateCommentModal` where keys are `yorum_id`s and values are booleans indicating
 * whether the modal for that comment is open.
 *
 * This allows us to independently control the modal visibility for each comment when rendering the modals within
 * the table cells, without conflicts arising from shared state variables.
 */

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { start_date, end_date, pagination, personel } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const [showUpdateCommentModal, setShowUpdateCommentModal] = useState<{
    [key: number]: boolean;
  }>({});

  const handleToggleUpdateCommentModal = (yorum_id: number) => {
    setShowUpdateCommentModal((prevState) => ({
      ...prevState,
      [yorum_id]: !prevState[yorum_id],
    }));
  };

  const handleUpdateCommentModal = (payload: UpdateCommentPayload) => {
    try {
      dispatch(
        updateComment(payload, () => {
          setShowUpdateCommentModal((prevState) => ({
            ...prevState,
            [payload.yorum_id]: false,
          }));
          dispatch(
            getComments({
              otel_id: 0,
              page: 1,
              per_page: pagination.per_page,
              start_date: start_date,
              end_date: end_date,
              personel: personel,
            })
          );
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  /* 
    TODO : bu linke gidecek: /rezervasyon-incele?rezervasyon_id=1064008
   */
  const handleShowDetail = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleApproveComment = (yorum_id: number) => {
    dispatch(
      approveComment(yorum_id, () => {
        dispatch(
          getComments({
            otel_id: 0,
            page: 1,
            per_page: pagination.per_page,
            start_date: start_date,
            end_date: end_date,
            personel: personel,
          })
        );
      })
    );
  };

  const handleDeleteComment = (yorum_id: number) => {
    dispatch(
      deleteComment(yorum_id, () => {
        setShowUpdateCommentModal((prevState) => ({
          ...prevState,
          [yorum_id]: false,
        }));
      })
    );
    dispatch(
      getComments({
        otel_id: 0,
        page: 1,
        per_page: pagination.per_page,
        start_date: start_date,
        end_date: end_date,
        personel: personel,
      })
    );
  };

  return {
    showUpdateCommentModal,
    setShowUpdateCommentModal,
    onToggleUpdateCommentModal: handleToggleUpdateCommentModal,
    onUpdateComment: handleUpdateCommentModal,
    onShowDetail: handleShowDetail,
    onApproveComment: handleApproveComment,
    onDeleteComment: handleDeleteComment,
  };
};

export default useHandleRequests;
