import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { saveReservation } from "slices/reservation/reservationCall/thunk";
import { setToastError } from "slices/toast/reducer";
import {
  CashInfoRequest,
  CreateRoomAddedRoom,
  CreditCardInfoRequest,
  DeferredReservationRequest,
  EnterReservationFormValues,
  InvoiceInfoAndOtherFieldsFormValues,
  NormalReservationRequest,
  OnArrivalReservationRequest,
  PartialReservationRequest,
  ReservationPaymentInfo,
  ReservationRequestRoom,
  TransferInfoRequest,
} from "types/reservation";

interface SaveReservationButtonProps {
  formValues: EnterReservationFormValues;
  paymentType: string;
  addedRoomsInfo: CreateRoomAddedRoom[];
  reservationPaymentInfo: ReservationPaymentInfo;
  invoiceInfoFormValues: InvoiceInfoAndOtherFieldsFormValues;
}

type ValidatePayload = (
  payload:
    | NormalReservationRequest
    | OnArrivalReservationRequest
    | PartialReservationRequest
    | DeferredReservationRequest
    | null,
  reservationPaymentInfo: ReservationPaymentInfo,
  addedRoomsInfo: CreateRoomAddedRoom[],
  popError: (message: string) => void
) => boolean;

type CreatePayload = (
  formValues: EnterReservationFormValues,
  paymentType: string,
  addedRoomsInfo: CreateRoomAddedRoom[],
  reservationPaymentInfo: ReservationPaymentInfo,
  invoiceInfoFormValues: InvoiceInfoAndOtherFieldsFormValues
) =>
  | NormalReservationRequest
  | PartialReservationRequest
  | OnArrivalReservationRequest
  | DeferredReservationRequest
  | null;

const getAddedPaymentsTotalAmount = (
  reservationPaymentInfo: ReservationPaymentInfo
) => {
  const { creditCard, transfer, cash } = reservationPaymentInfo;

  const creditCardTotalAmount = creditCard.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );

  const transferTotalAmount = transfer.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );

  const cashTotalAmount = cash.reduce(
    (acc, payment) => acc + Math.abs(payment.amount),
    0
  );

  return creditCardTotalAmount + transferTotalAmount + cashTotalAmount;
};

const validatePayload: ValidatePayload = (
  payload,
  reservationPaymentInfo,
  addedRoomsInfo,
  popError
) => {
  const errors = [];
  if (!payload) {
    return false;
  }

  if (payload.odalar.length === 0) {
    errors.push("En az bir oda eklemelisiniz.");
  }
  if (payload.eklenen_odeme.length === 0) {
    errors.push("En az bir ödeme eklemelisiniz.");
  }
  if (["kaporali"].includes(payload.odeme_tipi)) {
    if (
      totalKaporaliPrice(addedRoomsInfo) >
      getAddedPaymentsTotalAmount(reservationPaymentInfo)
    )
      errors.push("Ödeme tutarı toplam kapora tutarından az olamaz.");
  } else {
    if (
      payload.toplam_rezervasyon_tutari >
      getAddedPaymentsTotalAmount(reservationPaymentInfo)
    )
      errors.push("Ödeme tutarı toplam rezervasyon tutarından az olamaz.");
  }

  errors.length > 0 && popError(errors[0]);

  return errors.length === 0;
};

const getGuestFields = (
  guests: CreateRoomAddedRoom["guests"],
  type: "yetiskin" | "cocuk",
  number: number,
  field: keyof CreateRoomAddedRoom["guests"][number]
) => {
  return guests
    .filter((guest) => guest.tip === type && guest.sira <= number)
    .map((guest) => guest[field]);
};

const getAddedPayments = (reservationPaymentInfo: ReservationPaymentInfo) => {
  const { creditCard, transfer, cash } = reservationPaymentInfo;

  const creditCardPayments: CreditCardInfoRequest[] = creditCard.map(
    (payment) =>
      ({
        kk_tutar: payment.amount,
        kk_vade_tutar: payment.interestRate,
        kk_ad_soyad: payment.cardOwner,
        kk_banka_adi: payment.bankName,
        kk_taksit: payment.installment,
        kk_no: payment.cardNumber,
        kk_tarih: payment.expirationDate,
        kk_cvc: payment.cvc,
        eklenen_odemenin_tipi: "kredikartiodemesi",
      } as CreditCardInfoRequest)
  );

  const transferPayments: TransferInfoRequest[] = transfer.map(
    (payment) =>
      ({
        havale_tutar: payment.amount,
        havale_banka_adi: payment.bankName,
        eklenen_odemenin_tipi: "havaleodemesi",
      } as TransferInfoRequest)
  );

  const cashPayments: CashInfoRequest[] = cash.map(
    (payment) =>
      ({
        nakit_cekilecek_tutar: payment.amount,
        nakit_aciklama: payment.description,
        eklenen_odemenin_tipi: "nakitodemesi",
      } as CashInfoRequest)
  );

  return [...creditCardPayments, ...transferPayments, ...cashPayments];
};

const totalReservationPrice = (addedRoomsInfo: CreateRoomAddedRoom[]) => {
  return Math.ceil(
    addedRoomsInfo.reduce((acc, room) => acc + room.fiyat, 0) ?? 0
  );
};

const totalKaporaliPrice = (addedRoomsInfo: CreateRoomAddedRoom[]) => {
  const indirimliFiyat = addedRoomsInfo.reduce(
    (acc, room) => acc + room.fiyatlar.indirimli_tutar,
    0
  );
  const maliyetFiyat = addedRoomsInfo.reduce(
    (acc, room) => acc + room.fiyatlar.maliyet_tutar,
    0
  );

  return indirimliFiyat - maliyetFiyat;
};

const createPayload: CreatePayload = (
  formValues,
  paymentType,
  addedRoomsInfo,
  reservationPaymentInfo,
  invoiceInfoFormValues
) => {
  const requestRooms: ReservationRequestRoom[] = addedRoomsInfo.map((room) => ({
    otel_adi: room.otel_adi,
    tedarikci: room.tedarikci.tedarikci,
    otel_id: room.otel_id,
    karsilama_kodu: room.karsilama_kodu,
    giris_tarihi: moment(room.giris_tarihi, "DD MMMM YYYY").format(
      "YYYY-MM-DD"
    ),
    cikis_tarihi: moment(room.cikis_tarihi, "DD MMMM YYYY").format(
      "YYYY-MM-DD"
    ),
    musteri_ad_soyad: room.musteri_ad_soyad,
    musteri_cep: room.musteri_tel_2,
    musteri_eposta:
      room.musteri_eposta === "example@example.com" ? "" : room.musteri_eposta,
    musteri_tel: room.musteri_tel,
    musteri_tc_kimlik: room.musteri_tc,
    odeme_tipi: "normal",
    rez_tipi: "normal",
    pp_check: 0, //eklenecek
    pp_tutar: 0, //eklenecek
    pp_kullanildi_mi: "h", //eklenecek
    yetiskin_sayisi: room.yetiskin_sayisi,
    yetiskin_ad_soyad: getGuestFields(
      room.guests,
      "yetiskin",
      room.yetiskin_sayisi,
      "ad_soyad"
    ) as string[],
    yetiskin_cinsiyet: getGuestFields(
      room.guests,
      "yetiskin",
      room.yetiskin_sayisi,
      "cinsiyet"
    ) as string[],
    yetiskin_dogum: getGuestFields(
      room.guests,
      "yetiskin",
      room.yetiskin_sayisi,
      "dogum_tarihi"
    ) as string[],
    yetiskin_tc_kimlik: getGuestFields(
      room.guests,
      "yetiskin",
      room.yetiskin_sayisi,
      "tc_kimlik_no"
    ) as string[],
    cocuk_sayisi: room.cocuk_sayisi,
    cocuk_yasi_1: room.cocuk_sayisi > 0 ? room.cocuk_dogum_1 : "",
    cocuk_yasi_2: room.cocuk_sayisi > 1 ? room.cocuk_dogum_2 : "",
    cocuk_yasi_3: room.cocuk_sayisi > 2 ? room.cocuk_dogum_3 : "",
    cocuk_yasi_4: room.cocuk_sayisi > 3 ? room.cocuk_dogum_4 : "",
    cocuk_ad_soyad: getGuestFields(
      room.guests,
      "cocuk",
      room.cocuk_sayisi,
      "ad_soyad"
    ) as string[],
    cocuk_cinsiyet: getGuestFields(
      room.guests,
      "cocuk",
      room.cocuk_sayisi,
      "cinsiyet"
    ) as string[],
    cocuk_dogum: getGuestFields(
      room.guests,
      "cocuk",
      room.cocuk_sayisi,
      "dogum_tarihi"
    ) as string[],
    tutar: room.fiyat,
    data: "room.data", //eklenecek
    oda_adi: room.oda_adi,
    pansiyon_adi: room.pansiyon_adi,
    oda_id: parseInt(room.oda_id),
    pansiyon_id: parseInt(room.pansiyon_id),
    fiyat_hesaplama: room.fiyat_hesaplama,
    brut_tutar: room.fiyatlar.brut_tutar,
    indirimli_tutar: room.fiyatlar.indirimli_tutar,
    maliyet_tutar: room.fiyatlar.maliyet_tutar,
    brut_tutar_kur: room.fiyatlar.brut_tutar_kur,
    indirimli_tutar_kur: room.fiyatlar.indirimli_tutar_kur,
    maliyet_tutar_kur: room.fiyatlar.maliyet_tutar_kur,
    komisyon_orani: parseInt(room.komisyon_orani ?? 0),
    indirim_orani: parseInt(room.indirim_orani ?? 0),
    para_birimi: room.para_birimi,
    kur: room.kur,
    kur_degeri: room.kur_degeri,
    komisyon_tutar: ((parseInt(room.komisyon_orani) ?? 0) * room.fiyat) / 100,
    indirim_tutar: ((parseInt(room.indirim_orani) ?? 0) * room.fiyat) / 100,
    cari_odeme_tip: room.cari.odeme_tipi,
    cari_odeme_gun: room.cari.odeme_tipi_gunu,
    stop_durum: room.stop_durum,
    ucretli_cocuk_durum: room.ucretli_cocuk_durum,
    uygulanan_indirimler: JSON.parse(room.uygulanan_indirimler ?? "[]"),
    kismi_odeme_durum: 0,
    verilen_hediyeler: room.verilen_hediyeler ?? "",
    otele_odeme_gunu: room.otele_odeme_gunu,
    eb_durum: null, //eklenecek
    eb_otele_odeme_gunu: null, //eklenecek
    eb_otele_odeme_orani: null, //eklenecek
    fiyat_saglayici: room.fiyat_saglayici,
    fiyat_saglayici_gunluk_fiyatlar: "", //eklenecek
    fiyat_saglayici_rates: "", //eklenecek
    iptal_sigortasi: room.iptal_sigortasi_durum === "1" ? "var" : "yok",
    iptal_sigortasi_tutar: room.fiyatlar.iptal_sigortasi_fiyat ?? 0,
    request: `${room.guestNeeds} ${room.honeymoon ? "Balayı Çiftiyiz" : ""} ${
      room.noSmoking ? "Sigara Kullanmıyorum" : ""
    } ${room.differentBed ? "Ayrı Yatak Ricadır" : ""}`,
    request_acenta: room.agentNeeds,
  }));

  const requestOtherFields = {
    satis_operator: "OtelFiyat",
    komisyon_faturali_rezervasyon_durum: "", //eklenecek
    eklenen_odeme: getAddedPayments(reservationPaymentInfo),
    toplam_rezervasyon_tutari: totalReservationPrice(addedRoomsInfo),
    giris_tarihi: moment(addedRoomsInfo[0].giris_tarihi, "DD MMMM YYYY").format(
      "YYYY-MM-DD"
    ),
    cikis_tarihi: moment(addedRoomsInfo[0].cikis_tarihi, "DD MMMM YYYY").format(
      "YYYY-MM-DD"
    ),
    cari_odeme_gun: addedRoomsInfo[0].cari.odeme_tipi_gunu,
    cari_odeme_tip: addedRoomsInfo[0].cari.odeme_tipi,
    komisyon_orani: parseInt(addedRoomsInfo[0].komisyon_orani ?? 0),
    indirim_orani: parseInt(addedRoomsInfo[0].indirim_orani ?? 0),
    para_birimi: addedRoomsInfo[0].para_birimi,
    acente_komisyon_orani: addedRoomsInfo[0].acente_komisyon_orani,
    acente_komisyon_tutar:
      totalReservationPrice(addedRoomsInfo) *
        (parseInt(addedRoomsInfo[0].acente_komisyon_orani ?? 0) / 100) +
      "",
    fatura_bilgileri: {
      fatura_unvani: invoiceInfoFormValues.invoiceName,
      fatura_adresi: invoiceInfoFormValues.invoiceAddress,
      vergi_dairesi: invoiceInfoFormValues.invoiceTaxOffice,
      vergi_no: invoiceInfoFormValues.invoiceTaxNumber,
      aciklama: invoiceInfoFormValues.description,
    },
    rezervasyon_yapan: invoiceInfoFormValues.employeeId,
  };

  const normalPaymentPayload: NormalReservationRequest = {
    odalar: requestRooms.map((room) => ({
      ...room,
      odeme_tipi: "normal",
      rez_tipi: "normal",
    })),
    odeme_tipi: "normal",
    farkli_acenteden_satis_durum: invoiceInfoFormValues.diffrentAgent
      ? "1"
      : "0",
    ...requestOtherFields,
  };

  const kismiPaymentPayload: PartialReservationRequest = {
    odalar: requestRooms.map((room) => ({
      ...room,
      kismi_odeme_durum: 1,
      kismi_odeme_orani: formValues.kismi_odeme_orani,
      kismi_odeme_tutar: Math.ceil(
        (formValues.kismi_odeme_orani * room.indirimli_tutar) / 100
      ),
      kismi_odeme_tarihi: formValues.kismi_odeme_tarihi,
    })),
    odeme_tipi: "normal",
    farkli_acenteden_satis_durum: invoiceInfoFormValues.diffrentAgent
      ? "1"
      : "0",
    ...requestOtherFields,
  };

  const kapidaPaymentPayload: OnArrivalReservationRequest = {
    odalar: requestRooms.map((room) => ({
      ...room,
      odeme_tipi: "kapida",
      rez_tipi: "kapida",
      kapida_odeme: "evet",
      otele_kapida_odeme: 1,
      kapida_odeme_tutar: totalReservationPrice(addedRoomsInfo),
      kapida_odeme_komisyon_orani: formValues.kapida_odeme_komisyon_orani,
    })),
    odeme_tipi: "kapida",
    ...requestOtherFields,
  };

  const kaporaliPaymentPayload: DeferredReservationRequest = {
    odalar: requestRooms.map((room) => ({
      ...room,
      odeme_tipi: "kaporali",
      rez_tipi: "kaporali",
      kaporali_satis: "evet",
      kaporali_fiyat: totalKaporaliPrice(addedRoomsInfo),
      kaporali_satis_orani: formValues.kaporali_satis_orani,
    })),
    odeme_tipi: "kaporali",
    farkli_acenteden_satis_durum: invoiceInfoFormValues.diffrentAgent
      ? "1"
      : "0",
    ...requestOtherFields,
  };

  const payload =
    paymentType === "normal"
      ? normalPaymentPayload
      : paymentType === "kismi"
      ? kismiPaymentPayload
      : paymentType === "kapida"
      ? kapidaPaymentPayload
      : paymentType === "kaporali"
      ? kaporaliPaymentPayload
      : null;

  return payload;
};

const useHandlePayload = ({
  formValues,
  paymentType,
  addedRoomsInfo,
  reservationPaymentInfo,
  invoiceInfoFormValues,
}: SaveReservationButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const handleValidatePayload = () => {
    if (!addedRoomsInfo.length) {
      dispatch(setToastError("En az bir oda eklemelisiniz."));
      return;
    }

    const payload = createPayload(
      formValues,
      paymentType,
      addedRoomsInfo,
      reservationPaymentInfo,
      invoiceInfoFormValues
    );

    const isValid = validatePayload(
      payload,
      reservationPaymentInfo,
      addedRoomsInfo,
      (message) => dispatch(setToastError(message))
    );

    return isValid;
  };

  const handleSaveReservation = () => {
    const payload = createPayload(
      formValues,
      paymentType,
      addedRoomsInfo,
      reservationPaymentInfo,
      invoiceInfoFormValues
    );

    dispatch(
      saveReservation(payload, (id: number) => {
        setTimeout(() => {
          navigate(`/rezervasyon-incele?rezervasyon_id=${id}`);
        }, 1000);
      })
    );
  };

  return {
    onSaveReservation: handleSaveReservation,
    onValidatePayload: handleValidatePayload,
  };
};

export default useHandlePayload;
