import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ArchivedContractsInitialState } from "slices/financeAndAccounting/archivedContracts/reducer";
import { getArchivedContracts } from "slices/financeAndAccounting/archivedContracts/thunk";

const useArchivedContracts = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ArchivedContracts: ArchivedContractsInitialState }) =>
      state.ArchivedContracts
  );

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 10,
      search: "",
      otel_id: 0,
    };
    dispatch(getArchivedContracts(payload));
  }, [dispatch]);

  return {
    isLoading,
  };
};

export default useArchivedContracts;
