import moment from "moment";
import { useState } from "react";
import { Tooltip } from "reactstrap";

interface ConceptQuotaTooltipProps {
  tooltipId: string;
  quota: {
    [key: string]: string;
  };
}

const ConceptQuotaTooltip = ({
  tooltipId,
  quota,
}: ConceptQuotaTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const dailyQuota = Object.entries(quota).map(([key, value]) => {
    return {
      date: key,
      quota: value,
    };
  });

  const quotas = dailyQuota.map((item) => Number(item.quota));

  const minQuota = Math.min(...quotas);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className="cursor-pointer">
      <div
        id={tooltipId}
        className="py-1 px-2 rounded border d-flex gap-2 align-items-center bg-success bg-opacity-10"
        style={{ backgroundColor: "#f8fafc", width: "fit-content" }}
      >
        <span className="fs-14 fw-semibold text-decoration-underline text-success">
          Kontenjan: {minQuota}
        </span>
      </div>
      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={toggle}
        popperClassName="border rounded"
        style={{
          maxWidth: "500px",
          width: "fit-content",
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        <div className="d-flex flex-column gap-2 p-2">
          <span className="fw-medium fs-14 text-decoration-underline">
            Günlük Kontenjanlar
          </span>
          {dailyQuota.map((item) => (
            <div
              key={item.date}
              className="d-flex gap-4 aling-items-end border-bottom pb-1"
            >
              <span>{moment(item.date).format("DD MMMM")}</span>
              <span className="fw-medium">{item.quota} Oda</span>
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
};

export default ConceptQuotaTooltip;
