import { createSlice } from "@reduxjs/toolkit";

import { FOREIGN_MARKET, MAN_ALLOWANCE, OTHER_OPERATOR } from "enums";

import {
  Facility,
  UpdateFacilityState,
  UpdateFacilityFormErrors,
  FacilityFormChoices,
} from "types/facility";

export interface UpdateFacilityInitialState {
  facility: Facility;
  updateFacility: UpdateFacilityState;
  updateFacilityFormErrors: UpdateFacilityFormErrors;
  facilityFormChoices: FacilityFormChoices;
  isLoading: boolean;
  error: string;
  updateFacilityLoading: boolean;
  updateFacilityError: string;
}

export const updateFacilityInitialState: UpdateFacilityInitialState = {
  facility: {
    otel_adi: "",
    telefon: "",
    otel_aktiflik: "1",
    micro_logo: "",
    yildiz: "3",
    oteldenal_sef_link: "",
    otel_insaa: "",
    otel_yenileme: "",
    otel_odasayisi: "",
    denize_mesafe: "",
    one_cikan_ozellik1: "",
    one_cikan_ozellik2: "",
    one_cikan_ozellik3: "",
    one_cikan_ozellik4: "",
    otel_genel_aciklama: "",
    "Otel Özellikleri": [],
    "Cocuk Özellikleri": [],
    "Otel Konum Bilgileri": "",
    konum: {
      Lokasyonlar: {},
    },
    mevcut_konum_id: 0,
    "Otel Konum Aciklama": "",
    "Crm Açıklamalı Adres": "",
    "Otel Konaklama Açıklama": "",
    "Oda Özellikleri": [],
    "Yiyecek Aciklama": "",
    "Yiyecek Ozellikleri": [],
    "Aktivite Aciklama": "",
    "Aktivite Ozellik": [],
    "Havuz, Deniz ve Plaj Aciklama": "",
    "Havuz Özellikleri": [],
    "Termal & Spa": "",
    toplanti: "",
    "Balayi Konsepti": "",
    "İnternet Kullanımı": "",
    "Otel Temalar": [],
    "İslami Oteller": [],
    "Google map x kordinatı": "",
    "Google map y kordinatı": "",
    fiyat_listesi_goster: "e",
    site_logo_acilis: "h",
    acente_bilgisi_goster: "e",
    fiyat_saglayici: "", //? Seçenekler gerekli
    tedarikci: "", //? Seçenekler gerekli
    inet_kodu: "",
    inet_durum: "1",
    karsilama_kodu: "",
    fiyat_hesaplama: "",
    "Crm Satış Açıklamaları": "",
    "Crm Operasyon Aciklama": "",
    "Bay Konaklama": MAN_ALLOWANCE["Bay Konaklama Gösterme"],
    "Bay Konaklama Açıklaması": "",
    "Dış Pazar Konaklamalarına İç Pazar":
      FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"],
    "Dış Pazar Konaklama Açıklaması": "",
    "Başka Operatörde uygun görürseniz":
      OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"],
    "Başka Operatör Açıklaması": "",
    "Tesis Rakip Analiz Verileri": {
      JollyTur: "",
      "Coral Travel ID": "",
      "Otelz ID": "",
      "Hotels ID": "",
      "ETS Tur Url": "",
      "Tatilsepeti  Url": "",
      "Tatil.com  Url": "",
      "Tatilbudur  Url": "",
      "Odamax  Url": "",
    },
  },
  updateFacility: {
    otel_adi: "",
    telefon: "",
    resim: "",
    resim_onizlemesi: "",
    yildiz: "",
    otel_link: "",
    otel_insaat_tarihi: 0,
    otel_yenileme: 0,
    otel_odasayisi: 0,
    deniz_mesafe: "",
    one_cikan_ozellik1: "",
    one_cikan_ozellik2: "",
    one_cikan_ozellik3: "",
    one_cikan_ozellik4: "",
    otel_genel_aciklama: "",
    otel_ozellikleri: [],
    cocuk_ozellikleri: [],
    otel_adres: "",
    otel_bolgesi_id: "",
    otel_konum_aciklama: "",
    crm_aciklamali_adres: "",
    otel_konaklama_aciklama: "",
    oda_ozellikleri: [],
    yiyecek_metin: "",
    yiyecek_ozellik: [],
    otel_aktivite_aciklama: "",
    aktiviteler: [],
    txt_havuz: "",
    havuz_deniz_plaj: [],
    txt_termal: "",
    txt_toplanti: "",
    txt_balayi: "",
    txt_internet: "",
    otel_tema_id: [],
    otel_islami_tema_id: [],
    google_map_x: "",
    google_map_y: "",
    aktifmi: "1",
    fiyat_listesi_goster: "e",
    acente_bilgisini_goster: "e",
    otel_fiyat_id: 0,
    acante2_id: 0,
    site_logo_acilis: "h",
    fiyat_saglayici: "",
    tedarikci: "",
    inet_durum: "1",
    inet_operator_kodu: "",
    karsilama_kodu: "",
    fiyat_hesaplama: "",
    crm_satis_aciklama: "",
    crm_operasyon_aciklamasi: "",
    bay_konaklama: MAN_ALLOWANCE["Bay Konaklama Gösterme"],
    bay_konaklama_aciklama: "",
    dis_pazar_fiyat:
      FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"],
    dis_pazar_aciklama: "",
    baska_operatorde:
      OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"],
    baska_operator_aciklama: "",
    jollytur: "",
    odeon: "",
    otel_z: "",
    hotels: "",
    ets_tur_url: "",
    tatil_sepeti_url: "",
    tatil_com_url: "",
    tatil_budur_url: "",
    odamax_url: "",
  },
  facilityFormChoices: {
    Karşılama_Kodu: [],
    "Fiyat Hesaplama": [],
    tedarikçi: [],
    "Fiyat Sağlayıcısı": [],
    "Otel Değerlendirme Yıldızı": [],
    "Otel Özellikleri": {},
    "Çocuklara Özel": {},
    "Oda Özellikleri": {},
    "Yiyecek İçecek": {},
    Aktiviteler: {},
    "Havuz, Deniz ve Plaj": {},
    "Otel Teması": {},
    "İslami Otel Teması": {},
    Lokasyonlar: {},
    "Tema - Özellik Eşleşmesi": {},
  },
  updateFacilityFormErrors: {},
  isLoading: false,
  error: "",
  updateFacilityLoading: false,
  updateFacilityError: "",
};

const updateFacilitySlice = createSlice({
  name: "updateFacility",
  initialState: updateFacilityInitialState,
  reducers: {
    handleSaveFacility(state, action) {
      state.facility = action.payload;
    },
    handleSaveFacilityForUpdate(state, action) {
      const payload = action.payload as Facility;

      const updateFacility: UpdateFacilityState = {
        otel_adi: payload.otel_adi,
        telefon: payload.telefon,
        aktifmi: payload.otel_aktiflik,
        resim: "",
        resim_onizlemesi: payload.micro_logo,
        yildiz: payload.yildiz.includes("5")
          ? "5"
          : payload.yildiz.includes("4")
          ? "4"
          : payload.yildiz.includes("3")
          ? "3"
          : payload.yildiz.includes("Butik")
          ? "0"
          : "",
        otel_link: payload.oteldenal_sef_link,
        otel_insaat_tarihi: parseInt(payload.otel_insaa),
        otel_yenileme: parseInt(payload.otel_yenileme),
        otel_odasayisi: parseInt(payload.otel_odasayisi),
        deniz_mesafe: payload.denize_mesafe,
        one_cikan_ozellik1: payload.one_cikan_ozellik1,
        one_cikan_ozellik2: payload.one_cikan_ozellik2,
        one_cikan_ozellik3: payload.one_cikan_ozellik3,
        one_cikan_ozellik4: payload.one_cikan_ozellik4,
        otel_genel_aciklama: payload.otel_genel_aciklama,
        otel_ozellikleri: payload["Otel Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        cocuk_ozellikleri: payload["Cocuk Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        otel_adres: payload["Otel Konum Bilgileri"],
        otel_bolgesi_id: payload.mevcut_konum_id.toString(),
        otel_konum_aciklama: payload["Otel Konum Aciklama"],
        crm_aciklamali_adres: payload["Crm Açıklamalı Adres"],
        otel_konaklama_aciklama: payload["Otel Konaklama Açıklama"],
        oda_ozellikleri: payload["Oda Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        yiyecek_metin: payload["Yiyecek Aciklama"],
        yiyecek_ozellik: payload["Yiyecek Ozellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        otel_aktivite_aciklama: payload["Aktivite Aciklama"],
        aktiviteler: payload["Aktivite Ozellik"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        txt_havuz: payload["Havuz, Deniz ve Plaj Aciklama"],
        havuz_deniz_plaj: payload["Havuz Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        txt_termal: payload["Termal & Spa"],
        txt_toplanti: payload.toplanti,
        txt_balayi: payload["Balayi Konsepti"],
        txt_internet: payload["İnternet Kullanımı"],
        otel_tema_id: payload["Otel Temalar"]
          .filter((item) => item.aktiflik !== null)
          .map((item) => item.tema_id),
        otel_islami_tema_id: payload["İslami Oteller"]
          .filter((item) => item.aktiflik !== null)
          .map((item) => item.tema_id),
        google_map_x: payload["Google map x kordinatı"],
        google_map_y: payload["Google map y kordinatı"],
        fiyat_listesi_goster: payload.fiyat_listesi_goster,
        acente_bilgisini_goster: payload.acente_bilgisi_goster,
        otel_fiyat_id: 0,
        acante2_id: 0,
        site_logo_acilis: payload.site_logo_acilis,
        tedarikci: payload.tedarikci,
        fiyat_saglayici: payload.fiyat_saglayici,
        inet_durum: payload.inet_durum,
        inet_operator_kodu: payload.inet_kodu,
        karsilama_kodu: payload.karsilama_kodu,
        fiyat_hesaplama: payload.fiyat_hesaplama,
        crm_satis_aciklama: payload["Crm Satış Açıklamaları"],
        crm_operasyon_aciklamasi: payload["Crm Operasyon Aciklama"],
        bay_konaklama:
          MAN_ALLOWANCE[
            payload["Bay Konaklama"].toString() as keyof typeof MAN_ALLOWANCE
          ],
        bay_konaklama_aciklama: payload["Bay Konaklama Açıklaması"],
        dis_pazar_fiyat:
          FOREIGN_MARKET[
            payload[
              "Dış Pazar Konaklamalarına İç Pazar"
            ].toString() as keyof typeof FOREIGN_MARKET
          ],
        dis_pazar_aciklama: payload["Dış Pazar Konaklama Açıklaması"],
        baska_operatorde:
          OTHER_OPERATOR[
            payload[
              "Başka Operatörde uygun görürseniz"
            ].toString() as keyof typeof OTHER_OPERATOR
          ],
        baska_operator_aciklama: payload["Başka Operatör Açıklaması"],
        jollytur: payload["Tesis Rakip Analiz Verileri"].JollyTur,
        odeon: payload["Tesis Rakip Analiz Verileri"]["Coral Travel ID"],
        otel_z: payload["Tesis Rakip Analiz Verileri"]["Otelz ID"],
        hotels: payload["Tesis Rakip Analiz Verileri"]["Hotels ID"],
        ets_tur_url: payload["Tesis Rakip Analiz Verileri"]["ETS Tur Url"],
        tatil_sepeti_url:
          payload["Tesis Rakip Analiz Verileri"]["Tatilsepeti  Url"],
        tatil_com_url: payload["Tesis Rakip Analiz Verileri"]["Tatil.com  Url"],
        tatil_budur_url:
          payload["Tesis Rakip Analiz Verileri"]["Tatilbudur  Url"],
        odamax_url: payload["Tesis Rakip Analiz Verileri"]["Odamax  Url"],
      };
      state.updateFacility = updateFacility;
    },
    handleSaveFacilityFormChoices(state, action) {
      state.facilityFormChoices = action.payload;
    },
    handleAddUpdateFacilityFormErrors(state, action) {
      state.updateFacilityFormErrors = action.payload;
    },
    handleChangeInput(state, action) {
      state.updateFacility = {
        ...state.updateFacility,
        ...action.payload,
      };
    },
    handleResetFacilityState(state, action) {
      const payload = action.payload as Facility;

      const updateFacility: UpdateFacilityState = {
        otel_adi: payload.otel_adi,
        telefon: payload.telefon,
        aktifmi: payload.otel_aktiflik,
        resim: "",
        resim_onizlemesi: payload.micro_logo,
        yildiz: payload.yildiz.includes("5")
          ? "5"
          : payload.yildiz.includes("4")
          ? "4"
          : payload.yildiz.includes("3")
          ? "3"
          : payload.yildiz.includes("Butik")
          ? "0"
          : "",
        otel_link: payload.oteldenal_sef_link,
        otel_insaat_tarihi: parseInt(payload.otel_insaa),
        otel_yenileme: parseInt(payload.otel_yenileme),
        otel_odasayisi: parseInt(payload.otel_odasayisi),
        deniz_mesafe: payload.denize_mesafe,
        one_cikan_ozellik1: payload.one_cikan_ozellik1,
        one_cikan_ozellik2: payload.one_cikan_ozellik2,
        one_cikan_ozellik3: payload.one_cikan_ozellik3,
        one_cikan_ozellik4: payload.one_cikan_ozellik4,
        otel_genel_aciklama: payload.otel_genel_aciklama,
        otel_ozellikleri: payload["Otel Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        cocuk_ozellikleri: payload["Cocuk Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        otel_adres: payload["Otel Konum Bilgileri"],
        otel_bolgesi_id: payload.mevcut_konum_id.toString(),
        otel_konum_aciklama: payload["Otel Konum Aciklama"],
        crm_aciklamali_adres: payload["Crm Açıklamalı Adres"],
        otel_konaklama_aciklama: payload["Otel Konaklama Açıklama"],
        oda_ozellikleri: payload["Oda Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        yiyecek_metin: payload["Yiyecek Aciklama"],
        yiyecek_ozellik: payload["Yiyecek Ozellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        otel_aktivite_aciklama: payload["Aktivite Aciklama"],
        aktiviteler: payload["Aktivite Ozellik"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        txt_havuz: payload["Havuz, Deniz ve Plaj Aciklama"],
        havuz_deniz_plaj: payload["Havuz Özellikleri"]
          .filter((item) => item.aktiflik === "1")
          .map((item) => item.ozellik_id),
        txt_termal: payload["Termal & Spa"],
        txt_toplanti: payload.toplanti,
        txt_balayi: payload["Balayi Konsepti"],
        txt_internet: payload["İnternet Kullanımı"],
        otel_tema_id: payload["Otel Temalar"]
          .filter((item) => item.aktiflik !== null)
          .map((item) => item.tema_id),
        // otel_islami_tema_id: payload["İslami Otel Temaları"]
        //   .filter((item) => item.aktiflik !== null)
        //   .map((item) => item.tema_id),
        otel_islami_tema_id: [],
        google_map_x: payload["Google map x kordinatı"],
        google_map_y: payload["Google map y kordinatı"],
        fiyat_listesi_goster: payload.fiyat_listesi_goster,
        acente_bilgisini_goster: payload.acente_bilgisi_goster,
        otel_fiyat_id: 0,
        acante2_id: 0,
        site_logo_acilis: payload.site_logo_acilis,
        tedarikci: payload.tedarikci,
        fiyat_saglayici: payload.fiyat_saglayici,
        inet_durum: payload.inet_durum,
        inet_operator_kodu: payload.inet_kodu,
        karsilama_kodu: payload.karsilama_kodu,
        fiyat_hesaplama: payload.fiyat_hesaplama,
        crm_satis_aciklama: payload["Crm Satış Açıklamaları"],
        crm_operasyon_aciklamasi: payload["Crm Operasyon Aciklama"],
        bay_konaklama: MAN_ALLOWANCE["Bay Konaklama Gösterme"], // gelen data değişmeli
        bay_konaklama_aciklama: payload["Bay Konaklama Açıklaması"],
        dis_pazar_fiyat:
          FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"], // gelen data değişmeli
        dis_pazar_aciklama: payload["Dış Pazar Konaklama Açıklaması"],
        baska_operatorde:
          OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"], // gelen data değişmeli
        baska_operator_aciklama: payload["Başka Operatör Açıklaması"],
        jollytur: payload["Tesis Rakip Analiz Verileri"].JollyTur,
        odeon: payload["Tesis Rakip Analiz Verileri"]["Coral Travel ID"],
        otel_z: payload["Tesis Rakip Analiz Verileri"]["Otelz ID"],
        hotels: payload["Tesis Rakip Analiz Verileri"]["Hotels ID"],
        ets_tur_url: payload["Tesis Rakip Analiz Verileri"]["ETS Tur Url"],
        tatil_sepeti_url:
          payload["Tesis Rakip Analiz Verileri"]["Tatilsepeti  Url"],
        tatil_com_url: payload["Tesis Rakip Analiz Verileri"]["Tatil.com  Url"],
        tatil_budur_url:
          payload["Tesis Rakip Analiz Verileri"]["Tatilbudur  Url"],
        odamax_url: payload["Tesis Rakip Analiz Verileri"]["Odamax  Url"],
      };
      state.updateFacility = updateFacility;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUpdateFacilityLoading: (state, action) => {
      state.updateFacilityLoading = action.payload;
    },
    setUpdateFacilityError: (state, action) => {
      state.updateFacilityError = action.payload;
    },
  },
});

export const {
  handleSaveFacility,
  handleSaveFacilityForUpdate,
  handleSaveFacilityFormChoices,
  handleAddUpdateFacilityFormErrors,
  handleChangeInput,
  handleResetFacilityState,
  setIsLoading,
  setError,
  setUpdateFacilityLoading,
  setUpdateFacilityError,
} = updateFacilitySlice.actions;

export default updateFacilitySlice.reducer;
