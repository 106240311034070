import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSelector } from "reselect";
import {
  setToastError,
  setToastSuccess,
  setToastWarning,
} from "slices/toast/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

const CustomToast = () => {
  const selectedToastState = (state: any) => state.Toast;
  const dispatch: Dispatch<any> = useDispatch();

  const selectedToastProperties = createSelector(
    selectedToastState,
    (state) => ({
      success: state.success,
      warning: state.warning,
      error: state.error,
    })
  );

  const { success, warning, error } = useSelector(selectedToastProperties);

  const successnotify = () => {
    toast(success, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });

    dispatch(setToastSuccess(""));
  };

  const warningnotify = () => {
    toast("Warning! Something went wrong try again", {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-warning text-white",
    });

    dispatch(setToastWarning(""));
  };
  const errornotify = () => {
    toast(error, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

    dispatch(setToastError(""));
  };

  useEffect(() => {
    if (success) {
      successnotify();
    }
    if (warning) {
      warningnotify();
    }
    if (error) {
      errornotify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, warning, error]);

  return <ToastContainer />;
};

export default CustomToast;
