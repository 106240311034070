import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import useHandleRequests from "./useHandleRequests";
import UpdateCommentModal from "./TableModals/UpdateCommentModal";
import { TABLE_COLORS } from "utils/tableColors";

/* 
  *If a comment's aktiflik === 1, it's active. A comment can be
  *approved with "approve" button.

  *If  a comment's aktiflik === 0, it's inactive. A comment is inactive by 
  *default. To be able to delete it, it's sil_durum must be equal to 0 too.
*/

const useHandleTable = () => {
  const { comments } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const {
    showUpdateCommentModal,
    onToggleUpdateCommentModal,
    onUpdateComment,
    onShowDetail,
    onApproveComment,
    onDeleteComment,
  } = useHandleRequests();

  const tableData = comments?.map((comment) => {

    let rowColor = "";
    if (comment.silme === "1") {
      rowColor = TABLE_COLORS.red;
    } else if (comment.aktiflik === "0") {
      rowColor = TABLE_COLORS.yellow;
    } else if (comment.aktiflik === "1") {
      rowColor = TABLE_COLORS.green;
    }
    return {
      musteri_adi: comment.musteri_adi,
      otel_adi: comment.otel_adi,
      kategori: comment.kategori,
      personel_adi: comment.personel_ad_soyad,
      islem_tarihi: comment.tarih,
      konaklama_tarihleri: `${comment.giris_tarihi} - ${comment.cikis_tarihi}`,
      puan: comment.cevap7,
      yorum_olumlu: comment.yorum_olumlu,
      yorum_olumsuz: comment.yorum_olumsuz,
      yorum_id: comment.yorum_id,
      rezervasyon_id: comment.rezervasyon_id,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "İşlem Tarihi",
      accessorKey: "islem_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Kategori",
      accessorKey: "kategori",
      enableColumnFilter: false,
    },
    {
      header: "Müşteri Adı",
      accessorKey: "musteri_adi",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihleri",
      accessorKey: "konaklama_tarihleri",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "100px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Personel",
      accessorKey: "personel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Ortalama Puan",
      accessorKey: "puan",
      enableColumnFilter: false,
    },
    {
      header: "Olumlu Yorum",
      accessorKey: "yorum_olumlu",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "300px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Olumsuz Yorum",
      accessorKey: "yorum_olumsuz",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div
            className="text-wrap"
            style={{ width: "300px", background: "danger" }}
          >
            {value}
          </div>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "yorum_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        const rezervasyonId = cell.row.original.rezervasyon_id;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => {
                  onToggleUpdateCommentModal(id);
                }}
                title="Yorumu güncelle"
              />
{                
    /* 
    TODO : bu butonun gösterimi rezervasyon_id ye göre koşullu olacak
    */
}
              
  {rezervasyonId && (<CustomRowOperationButton
                variant="info"
                onClick={() => {
                  onShowDetail(rezervasyonId);
                }}
                title="Rezervasyon detayı görüntüle"
              />)}

              <CustomRowOperationButton
                variant="check"
                onClick={() => {
                  onApproveComment(id);
                }}
                title="Yorumu onayla"
              />
              <CustomRowOperationButton
                variant="delete"
                onClick={() => {
                  onDeleteComment(id);
                }}
                title="Yorumu devre dışı bırak"
              />
            </div>
            <UpdateCommentModal
              yorum_id={id}
              isOpen={showUpdateCommentModal[id] || false}
              handleToggle={() => onToggleUpdateCommentModal(id)}
              updateComment={(olumlu_yorum, olumsuz_yorum) =>
                onUpdateComment({
                  yorum_id: id,
                  yorum_olumlu: olumlu_yorum,
                  yorum_olumsuz: olumsuz_yorum,
                })
              }
              deleteComment={() => {
                onDeleteComment(id);
              }}
            />
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.blue,
      label: "Aktif",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Aktif Değil",
    },
    {
      color: TABLE_COLORS.red,
      label: "Silindi",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
