import { CustomLoadingPage } from "Components/Custom/UI";
import UpdateUserForm from "./UpdateUserForm";

interface UpdateUserPageProps {
  isLoading: boolean;
}

const UpdateUserPage = ({ isLoading }: UpdateUserPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />      
        <div className="d-flex flex-column gap-4">
          <UpdateUserForm />
        </div>
    </>
  );
};

export default UpdateUserPage;
