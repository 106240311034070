import { CustomButton } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { Dispatch } from "redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { setToastError } from "slices/toast/reducer";
import { EnterReservationFormValues } from "types/reservation";
import moment from "moment";

interface CalculateAgainRowProps {
  onCalculatePrice: () => void;
  formValues: EnterReservationFormValues;
  calculatedPrice: number | undefined;
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validateCalculateAgainForm = (
  formValues: EnterReservationFormValues,
  setFormErrors: (errors: string[]) => void
) => {
  const errors = [];

  if (!formValues.oda_pansiyon_tipi)
    errors.push("Oda pansiyon tipi seçilmelidir.");
  if (!formValues.giris_tarihi) errors.push("Giriş tarihi seçilmelidir.");
  if (!formValues.cikis_tarihi) errors.push("Çıkış tarihi seçilmelidir.");
  if (!formValues.musteri_ad_soyad)
    errors.push("Müşteri ad soyadı girilmelidir.");
  if (!formValues.musteri_eposta)
    errors.push("Müşteri e-postası girilmelidir.");
  if (formValues.musteri_eposta && !emailRegex.test(formValues.musteri_eposta))
    errors.push("Geçerli bir e-posta adresi girilmelidir.");
  if (!formValues.musteri_tel) errors.push("Müşteri telefonu girilmelidir.");
  if (!formValues.musteri_tc || formValues.musteri_tc.length !== 11)
    errors.push("11 haneli TC kimlik numarası girilmelidir.");
  if (formValues.cocuk_sayisi > 3 && !formValues.cocuk_dogum_4)
    errors.push("4. çocuğun doğum tarihi girilmelidir.");
  if (
    formValues.cocuk_sayisi > 3 &&
    moment().diff(moment(formValues.cocuk_dogum_4), "years") > 12
  ) {
    errors.push("4. çocuk 12 yaşından küçük olmalıdır.");
  }
  if (formValues.cocuk_sayisi > 2 && !formValues.cocuk_dogum_3)
    errors.push("3. çocuğun doğum tarihi girilmelidir.");
  if (
    formValues.cocuk_sayisi > 2 &&
    moment().diff(moment(formValues.cocuk_dogum_3), "years") > 12
  )
    errors.push("3. çocuk 12 yaşından küçük olmalıdır.");
  if (formValues.cocuk_sayisi > 1 && !formValues.cocuk_dogum_2)
    errors.push("2. çocuğun doğum tarihi girilmelidir.");
  if (
    formValues.cocuk_sayisi > 1 &&
    moment().diff(moment(formValues.cocuk_dogum_2), "years") > 12
  )
    errors.push("2. çocuk 12 yaşından küçük olmalıdır.");
  if (formValues.cocuk_sayisi > 0 && !formValues.cocuk_dogum_1)
    errors.push("1. çocuğun doğum tarihi girilmelidir.");
  if (
    formValues.cocuk_sayisi > 0 &&
    moment().diff(moment(formValues.cocuk_dogum_1), "years") > 12
  )
    errors.push("1. çocuk 12 yaşından küçük olmalıdır.");

  const isFormValid = errors.length === 0;

  setFormErrors(errors);

  return { isFormValid, errors };
};

const CalculateAgainRow = ({
  formValues,
  onCalculatePrice,
  calculatedPrice,
}: CalculateAgainRowProps) => {
  const { calculatePriceLoading } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  useEffect(() => {
    isSubmitted && validateCalculateAgainForm(formValues, setFormErrors);
  }, [formValues, isSubmitted]);

  //Kısmi Ödeme
  const kismiOdemeAmount =
    formValues.kismi_odeme === 1
      ? Math.ceil(
          ((calculatedPrice ?? formValues.fiyat) *
            formValues.kismi_odeme_orani) /
            100
        )
      : 0;

  const kismiOdemeAmountBeforeCheckin =
    Math.ceil(formValues.fiyat) - kismiOdemeAmount;

  //Kaporalı Ödeme
  const kaporaliOdemeAmount =
    formValues.odeme_tipi === "kaporali"
      ? Math.ceil(
          (calculatedPrice ?? formValues.fiyat) -
            formValues.fiyatlar.maliyet_tutar
        )
      : 0;

  const kaporaliOdemeAmountBeforeCheckin =
    Math.ceil(formValues.fiyat) - kaporaliOdemeAmount;

  return (
    <div className="d-flex align-items-start gap-5 border rounded bg-white p-2">
      <div className="d-flex flex-column">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
          }}
        >
          <span className="fw-bold fs-16">Rezervasyon Tutarı:</span>
          <div className="d-flex gap-5">
            <span className="px-3 py-2 border theme-bg-light fw-bold rounded fs-20">
              {Math.ceil(calculatedPrice ?? formValues.fiyat)} TL
            </span>
            {formValues.odeme_tipi === "kapida" && (
              <Label check>
                <Input
                  type="checkbox"
                  checked={formValues.odeme_tipi === "kapida"}
                  disabled
                />
                <span className="fs-16 ms-2">Kapıda Ödeme</span>
              </Label>
            )}
            <CustomButton
              onClick={() => {
                const { isFormValid } = validateCalculateAgainForm(
                  formValues,
                  setFormErrors
                );
                setIsSubmitted(true);

                if (isFormValid) {
                  onCalculatePrice();
                  setIsSubmitted(false);
                } else {
                  dispatch(setToastError(formErrors[formErrors.length - 1]));
                }
              }}
              variant="primary"
              isLoading={calculatePriceLoading}
            >
              Tekrar Fiyat Hesapla
            </CustomButton>
          </div>
        </div>
        {formValues.odeme_tipi === "kismi" && calculatedPrice !== 0 && (
          <div className="d-flex flex-column">
            <div
              className="py-3 my-3 border-bottom border-top"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gap: "1rem",
              }}
            >
              <span className="fw-medium fs-16">Kısmi Satış Tutarı:</span>
              <span className="px-3 py-2 border theme-bg-light fw-bold rounded fs-16 d-flex flex-column gap-2">
                <span>{kismiOdemeAmount} TL</span>
                <span className="text-danger text-12">
                  Not: Kısmi Ödemeli Satışlarda Çekilecek Tutar, Kısmi Tutardan
                  Fazla veya Eksik Olamaz!
                </span>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gap: "1rem",
              }}
            >
              <span className="fw-medium fs-16">
                Giriş Öncesi Ödenecek Tutar:
              </span>
              <span className="px-3 py-2 border theme-bg-light fw-bold rounded fs-16 d-flex flex-column gap-2">
                <span>{kismiOdemeAmountBeforeCheckin} TL</span>
                <span className="text-danger text-12">
                  Önemli: Geriye kalan rakam giriş öncesi tarafımızca
                  alınacağını "Mutlaka" belirtiniz.
                </span>
              </span>
            </div>
          </div>
        )}
        {formValues.odeme_tipi === "kaporali" && calculatedPrice !== 0 && (
          <div className="d-flex flex-column">
            <div
              className="py-3 my-3 border-bottom border-top"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gap: "1rem",
              }}
            >
              <span className="fw-medium fs-16">Kaporalı Satış Tutarı:</span>
              <span className="px-3 py-2 border theme-bg-light fw-bold rounded fs-16 d-flex flex-column gap-2">
                <span>{kaporaliOdemeAmount} TL</span>
                <span className="text-danger fs-14">
                  Not: Kaporalı Satışlarda Çekilecek Tutar, Kapora Tutarından
                  Fazla veya Eksik Olamaz!
                </span>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gap: "1rem",
              }}
            >
              <span className="fw-medium fs-16 text-no-wrap">
                Otelde Ödenecek Tutar:
              </span>
              <span className="px-3 py-2 border theme-bg-light fw-bold rounded fs-16 d-flex flex-column gap-2">
                <span>{kaporaliOdemeAmountBeforeCheckin} TL</span>
                <span className="text-danger fs-14">
                  Önemli:Geriye kalan bu rakamın otelde tek çekim yada nakit
                  olarak alınacağını eger taksit istiyorsa tüm ödemeyi telefonda
                  şimdi tamamlayarak peşin fiyatına taksit yaptırabileceğini
                  "Mutlaka" belirtiniz.
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculateAgainRow;
