import { ArchivedContractsPage } from "Components/Custom/FinanceAndAccountingPages";
import useArchivedContracts from "Components/Hooks/financeAndAccounting/useArchivedContracts";
import PageContainer from "pages/PageContainer";

const ArchivedContracts = () => {
  const { isLoading } = useArchivedContracts();
  return (
    <PageContainer title="Arşivlenen Kontratlar">
      <ArchivedContractsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ArchivedContracts;
