import { CommissionPaymentPage } from "Components/Custom/FinanceAndAccountingPages";
import useCommissionPayment from "Components/Hooks/financeAndAccounting/useCommissionPayment";
import PageContainer from "pages/PageContainer";

const CommissionPayment = () => {
  const { loading, error, isValidHotel } = useCommissionPayment();
  return (
    <PageContainer title="Komisyon Ödemesi">
      <CommissionPaymentPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
      />
    </PageContainer>
  );
};

export default CommissionPayment;
