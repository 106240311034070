import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/underTheDeskDiscounts/reducer";
import {
  UnderTheDeskDiscountFormErrors,
  UnderTheDeskDiscountState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface DiscountRateProps {
  values: UnderTheDeskDiscountState;
  formErrors: UnderTheDeskDiscountFormErrors;
  validateFormChange: (values: UnderTheDeskDiscountState) => void;
}

const DiscountRate = ({
  values,
  validateFormChange,
  formErrors,
}: DiscountRateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Desk Altı İndirim Oranı"
        error={formErrors.oran}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.oran}
            onChange={(e) => {
              dispatch(handleChangeInput({ oran: e.target.value }));
              validateFormChange({
                ...values,
                oran: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountRate;
