import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { DailyReservationsResponse, DailyReservation } from "types/reports";

export interface DailyReservationsInitialState {
  dailyReservationsResponse: DailyReservationsResponse;
  dailyReservations: DailyReservation[];
  isLoading: boolean;
  error: string;
}

const initialState: DailyReservationsInitialState = {
  dailyReservationsResponse: {
    rezler: [],
    iptal_tutar: 0,
    bekleyen_tutar: 0,
    tamamlanan_tutar: 0,
    toplam_rez: 0,
    tarih: `${moment().format("YYYY-MM-DD")} - ${moment().format(
      "YYYY-MM-DD"
    )}`,
    gosterilmeyecek_alanlar: {},
  },
  dailyReservations: [],
  isLoading: false,
  error: "",
};

const dailyReservationsSlice = createSlice({
  name: "dailyReservations",
  initialState,
  reducers: {
    handleSaveDailyReservationsResponse(state, action) {
      state.dailyReservationsResponse = action.payload;
      state.dailyReservations = action.payload.rezler;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveDailyReservationsResponse, setIsLoading, setError } =
  dailyReservationsSlice.actions;

export default dailyReservationsSlice.reducer;
