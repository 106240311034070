import { createSlice } from "@reduxjs/toolkit";
import {
  AddWebSitePayload,
  HotelsWithoutWebSiteResponse,
  ListWebSitesState,
  WebSiteFormErrors,
} from "types/myWebSite";

export interface ListWebSitesInitialState {
  webSites: ListWebSitesState[];
  hotelsWithoutWebSite: HotelsWithoutWebSiteResponse[];
  webSite: AddWebSitePayload;
  webSiteFormErrors: WebSiteFormErrors;
  isLoading: boolean;
  error: string;
  addWebSiteLoading: boolean;
  addWebSiteError: string;
}

export const listWebSitesInitialState: ListWebSitesInitialState = {
  webSites: [],
  hotelsWithoutWebSite: [],
  webSite: {
    otel_id: 0,
    domain_link: "",
    temax_type: "1",
  },
  webSiteFormErrors: {},
  isLoading: false,
  error: "",
  addWebSiteLoading: false,
  addWebSiteError: "",
};

const listWebSitesSlice = createSlice({
  name: "listWebSites",
  initialState: listWebSitesInitialState,
  reducers: {
    handleSaveListWebSites(state, action) {
      state.webSites = action.payload;
    },
    handleSaveListHotelsWithoutWebSite(state, action) {
      state.hotelsWithoutWebSite = action.payload;
    },
    handleChangeWebSiteInput(state, action) {
      state.webSite = {
        ...state.webSite,
        ...action.payload,
      };
    },
    handleAddWebSiteFormErrors(state, action) {
      state.webSiteFormErrors = action.payload;
    },
    resetWebSite(state) {
      state.webSite = {
        otel_id: 0,
        domain_link: "",
        temax_type: "1",
      };
      state.webSiteFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setAddWebSiteLoading(state, action) {
      state.addWebSiteLoading = action.payload;
    },
    setAddWebSiteError(state, action) {
      state.addWebSiteError = action.payload;
    },
  },
});

export const {
  handleSaveListWebSites,
  handleSaveListHotelsWithoutWebSite,
  handleChangeWebSiteInput,
  handleAddWebSiteFormErrors,
  resetWebSite,
  setIsLoading,
  setError,
  setAddWebSiteLoading,
  setAddWebSiteError,
} = listWebSitesSlice.actions;

export default listWebSitesSlice.reducer;
