import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { TotalSalesAmountsCardData } from "types/dashboard";

interface TotalSalesColumnProps {
  title: string;
  salesValue: number;
  roomValue: number;
}

const TotalSalesColumn = ({
  title,
  salesValue,
  roomValue,
}: TotalSalesColumnProps) => {
  const titleStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "grey",
    margin: "0",
  };

  const valueStyle: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "500",
    margin: "18px 0 0 0",
  };

  return (
    <div>
      <p style={titleStyle}>{title}</p>
      <p style={valueStyle}>{salesValue} ₺</p>
      <p style={titleStyle}>Toplam Satış Tutarı</p>
      <p style={valueStyle}>{roomValue}</p>
      <p style={titleStyle}>Toplam Oda Sayısı</p>
    </div>
  );
};

interface TotalSalesAmountsCardProps {
  values: TotalSalesAmountsCardData;
}

const TotalSalesAmountsCard = ({ values }: TotalSalesAmountsCardProps) => {
  return (
    <>
      <CustomDashboardCard xl={4} title="Toplam Satış Tutarları" >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
            alignItems: "center",
          }}
        >
          <TotalSalesColumn
            title={"Tüm Zamanlar"}
            salesValue={values.tum_zamanlar.toplam_satis_tutari}
            roomValue={values.tum_zamanlar.toplam_oda_sayisi}
          />
          <div
            style={{
              borderLeft: "1px solid grey",
              height: "100%",
              width: "16px",
              margin: "0 auto",
            }}
          ></div>
          <TotalSalesColumn
            title={"Son 1 Yıl"}
            salesValue={values.son_bir_yil.toplam_satis_tutari}
            roomValue={values.son_bir_yil.toplam_oda_sayisi}
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default TotalSalesAmountsCard;
