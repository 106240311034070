import {
  ChartContainer,
  CustomSimpleBarChart,
} from "Components/Custom/UI/CustomCharts";
import useHandleChart from "./useHandleChart";

interface DailyCallsChartProps {
  isLoading: boolean;
}

const DailyCallsChart = ({ isLoading }: DailyCallsChartProps) => {
  const { formattedData, colors } = useHandleChart();

  return (
    <ChartContainer
      noData={formattedData.length === 0}
      containerHeight={300}
      containerWidth="100%"
      isLoading={isLoading}
    >
      <CustomSimpleBarChart data={formattedData} colors={colors} />
    </ChartContainer>
  );
};

export default DailyCallsChart;
