import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { setIsLoading, setError, handleSaveSearchResults } from "./reducer";

import { GET_HOTEL_SUMMARY } from "helpers/url_helper";

export const getHotelsBySearchTerm =
  (searchTerm: string) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_HOTEL_SUMMARY, {
        params: { search: searchTerm },
      });

      dispatch(handleSaveSearchResults(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
