import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeWebSiteInput } from "slices/myWebSite/listWebSites/reducer";
import { AddWebSitePayload, WebSiteFormErrors } from "types/myWebSite";

interface HotelsWithoutWebSiteSelectorProps {
  values: AddWebSitePayload;
  formErrors: WebSiteFormErrors;
  validateFormChange: (values: AddWebSitePayload) => void;
  hotelsWithoutWebSiteOptions: Option[];
}

interface Option {
  value: number;
  label: string;
}

const HotelsWithoutWebSiteSelector = ({
  values,
  formErrors,
  validateFormChange,
  hotelsWithoutWebSiteOptions,
}: HotelsWithoutWebSiteSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <CustomFormFieldContainer
      label="Otel Seçiniz : "
      error={formErrors.domain_link}
      divider
      required
    >
      <Select
        value={
            hotelsWithoutWebSiteOptions.find(
            (option) => option.value === values.otel_id
          ) || null
        }
        onChange={(selected: Option) => {
          dispatch(
            handleChangeWebSiteInput({
                otel_id: selected.value,
            })
          );

          validateFormChange({ ...values, otel_id: selected.value });
        }}
        options={hotelsWithoutWebSiteOptions}
        placeholder="Otel Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default HotelsWithoutWebSiteSelector;
