import { InputGroup, InputGroupText } from "reactstrap";
import { CalculatePriceFormValues } from "types/reservation";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
interface ChildrenAgesProps {
  formValues: CalculatePriceFormValues;
  handleChangeForm: (key: string, value: number | string) => void;
}

const ChildrenAges = ({ formValues, handleChangeForm }: ChildrenAgesProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const numberOfChildren = formValues.cocuk_sayisi;
  return (
    <>
      {numberOfChildren > 0 && (
        <InputGroup>
          <InputGroupText>1. Çocuk</InputGroupText>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_1}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeForm("cocuk_dogum_1", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 1,
              locale: Turkish,
              onDayCreate,
              maxDate: moment().subtract(1, "days").toDate(),
            }}
            placeholder="1. Çocuk Doğum Tarihi"
          />
        </InputGroup>
      )}
      {numberOfChildren > 1 && (
        <InputGroup>
          <InputGroupText>2. Çocuk</InputGroupText>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_2}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeForm("cocuk_dogum_2", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 1,
              locale: Turkish,
              onDayCreate,
              maxDate: moment().subtract(1, "days").toDate(),
            }}
            placeholder="2. Çocuk Doğum Tarihi"
          />
        </InputGroup>
      )}
      {numberOfChildren > 2 && (
        <InputGroup>
          <InputGroupText>3. Çocuk</InputGroupText>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_3}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeForm("cocuk_dogum_3", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 1,
              locale: Turkish,
              onDayCreate,
              maxDate: moment().subtract(1, "days").toDate(),
            }}
            placeholder="3. Çocuk Doğum Tarihi"
          />
        </InputGroup>
      )}
      {numberOfChildren > 3 && (
        <InputGroup>
          <InputGroupText>4. Çocuk</InputGroupText>
          <Flatpickr
            className="form-control"
            value={formValues.cocuk_dogum_4}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeForm("cocuk_dogum_4", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 1,
              locale: Turkish,
              onDayCreate,
              maxDate: moment().subtract(1, "days").toDate(),
            }}
            placeholder="4. Çocuk Doğum Tarihi"
          />
        </InputGroup>
      )}
    </>
  );
};

export default ChildrenAges;
