import { CustomFormContainer } from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import FacilityMainTheme from "./FacilityMainTheme";

interface FacilityThemeProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityThemeOptions: Option[];
  facilityThemeFeatureMatchOptions: {
    key: string;
    value: string[];
  }[];
  facilityIslamicThemeOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityTheme = ({
  values,
  formErrors,
  validateFormChange,
  facilityThemeOptions,
  facilityThemeFeatureMatchOptions,
  facilityIslamicThemeOptions,
}: FacilityThemeProps) => {
  return (
    <CustomFormContainer title="Tema Seçimi" lg={12} xl={12}>
      <FacilityMainTheme
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityThemeFeatureMatchOptions={facilityThemeFeatureMatchOptions}
        facilityThemeOptions={facilityThemeOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityTheme;
