import { useSelector } from "react-redux";
import { ReservationsInitialState } from "slices/reports/reservations/reducer";
import { ANALYSIS_COLORS } from "utils/analysisColors";
import { XCircle, Clock, CheckCircle, List, Icon } from "feather-icons-react";

const useHandleReservationData = () => {
  const { reservationsResponse } = useSelector(
    (state: { Reservations: ReservationsInitialState }) => state.Reservations
  );


  const cards = [
    {
      title: "İptal Tutar",
      value: reservationsResponse.iptal_tutar,
      color: ANALYSIS_COLORS.red,
      Icon: <XCircle size={40} color="#fff" />,
    },
    {
      title: "Bekleyen Tutar",
      value: reservationsResponse.bekleyen_tutar,
      color: ANALYSIS_COLORS.yellow,
      Icon: <Clock size={40} color="#fff" />,
    },
    {
      title: "Tamamlanan Tutar",
      value: reservationsResponse.tamamlanan_tutar,
      color: ANALYSIS_COLORS.green,
      Icon: <CheckCircle size={40} color="#fff" />,
    },
    {
      title: "Toplam Rezervasyon",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: <List size={40} color="#fff" />,
    },
  ];

  return {
    cards,
  };
};

export default useHandleReservationData;
