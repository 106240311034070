import { PAYMENT_METHODS } from "enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StayLongPayLessActionRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { StayLongPayLessActionsInitialState } from "slices/discountsAndActions/stayLongPayLessActions/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    stayLongPayLessAction,
    stayLongPayLessActionFormErrors,
    addActionLoading,
    updateActionLoading,
  } = useSelector(
    (state: { StayLongPayLessActions: StayLongPayLessActionsInitialState }) =>
      state.StayLongPayLessActions
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newConceptsOptions: Option[] = hotel.pansiyonlar.map(
        (concept: any) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const startDate = moment(stayLongPayLessAction.baslangic_tarih).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(stayLongPayLessAction.bitis_tarih).format(
    "YYYY-MM-DD"
  );

  const addNewStayLongPayLessActionRequestPayload: StayLongPayLessActionRequestPayload =
    {
      otel_id,
      otel_oda_id: oda_id,
      grup_id: stayLongPayLessAction.grup_id,
      konaklama_tarihi: `${startDate} - ${endDate}`,
      kal_gece: stayLongPayLessAction.kal_gun_sayisi,
      ode_gece: stayLongPayLessAction.ode_gun_sayisi,
      radio_gun: parseInt(stayLongPayLessAction.tum_gunler),
      gun: stayLongPayLessAction.gunler,
      kal_ode_geceleme: stayLongPayLessAction.kal_ode_geceleme,
      desk_alti: stayLongPayLessAction.desk_alti,
      ozel_komisyon_orani_durum:
        stayLongPayLessAction.ozel_komisyon_orani_durum,
      yineleme: stayLongPayLessAction.yineleme,
      eb_birlikte: stayLongPayLessAction.eb_birlikte,
      tum_pansiyonlar: stayLongPayLessAction.tum_otel_pansiyonlar,
      secili_pansiyonlar: stayLongPayLessAction.otel_pansiyonlar,
      tum_odeme_tipleri: stayLongPayLessAction.tum_odeme_tipleri,
      secili_odeme_tipleri: stayLongPayLessAction.odeme_tipleri,
    };

  const updateStayLongPayLessActionRequestPayload: StayLongPayLessActionRequestPayload =
    {
      kal_ode_id: stayLongPayLessAction.id,
      ...addNewStayLongPayLessActionRequestPayload,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    stayLongPayLessAction,
    conceptsOptions,
    paymentMethodOptions,
    addActionLoading,
    updateActionLoading,
    addNewStayLongPayLessActionRequestPayload,
    updateStayLongPayLessActionRequestPayload,
    formErrors: stayLongPayLessActionFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
