import { CustomFormFieldContainer } from "Components/Custom/UI";
import ScoreFieldRow from "./ScoreFieldRow";
import { AddCommentFormErrors, AddCommentState, ScoreField } from "types/comments";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer";


type CevapFieldName = 'cevap1' | 'cevap2' | 'cevap3' | 'cevap4' | 'cevap5' | 'cevap6';

interface CommentScoreFieldProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
  commentQuestions: ScoreField[];
  commentPuanlama: Record<string, number>;
}

const CommentScoreField = ({
  values,
  formErrors,
  validateFormChange,
  commentQuestions,
  commentPuanlama,
}: CommentScoreFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();


  const options = [
    { label: "Çok İyi", value: commentPuanlama["çok iyi"] },
    { label: "İyi", value: commentPuanlama["iyi"] },
    { label: "Orta", value: commentPuanlama["orta"] },
    { label: "Kötü", value: commentPuanlama["kötü"] },
  ];

  return (
    <CustomFormFieldContainer label="Oteli Puanla" orientation="vertical">
      <div className="row">
        {commentQuestions.map((question, index) => {
          const fieldIndex = index + 1;
          const fieldName = `cevap${fieldIndex}` as CevapFieldName;
          return (
            <div className="col-md-6" key={question.soru_id}>
              <ScoreFieldRow
                label={question.soru}
                error={formErrors[fieldName] || ""}
                name={fieldName}
                value={values[fieldName]}
                options={options}
                onChange={(value: any) => {
                  dispatch(handleChangeCommentInput({ [fieldName]: value }));
                  validateFormChange({ ...values, [fieldName]: value });
                }}
              />
            </div>
          );
        })}
      </div>
    </CustomFormFieldContainer>
  );
};

export default CommentScoreField;
