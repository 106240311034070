import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input, Label } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/updateUser/reducer";
import { UpdateUserFormErrors, UpdateUserPayload } from "types/userOperations";

interface UserAccountTypeProps {
  values: UpdateUserPayload;
  formChoices: {
    value: string;
    label: string;
  }[];
  formErrors: UpdateUserFormErrors;
  validateFormChange: (values: UpdateUserPayload) => void;
}

const UserAccountType = ({
  values,
  formChoices = [],
  formErrors,
  validateFormChange,
}: UserAccountTypeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Hesap Türü"
        error={formErrors.kullanici_tipi}
        divider
        required
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {formChoices.map((option) => {
            const id = `radio-id-${option.value}`
            return (
              <div
                key={option.value}
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Input
                  id={id}
                  checked={values.kullanici_tipi === option.value}
                  type="radio"
                  autoCorrect="on"
                  value={option.value}
                  onChange={(e) => {
                    dispatch(
                      handleChangeInput({
                        kullanici_tipi: e.target.value,
                      })
                    );
                    validateFormChange({
                      ...values,
                      kullanici_tipi: e.target.value,
                    });
                  }}
                />
                <Label
                  check
                  className="text-xs fw-normal"
                  style={{
                    fontSize: "14px",
                    marginLeft: "4px",
                  }}
                  htmlFor={id}
                >
                  {option.label}
                </Label>
              </div>
            );
          })}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default UserAccountType;
