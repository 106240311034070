import AnsweredCallsTable from "./AnsweredCallsTable";
import FilterForm from "./FilterForm";

interface AnsweredCallsPageProps {
  isLoading: boolean;
}

const AnsweredCallsPage = ({ isLoading }: AnsweredCallsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <AnsweredCallsTable isLoading={isLoading} />
    </div>
  );
};

export default AnsweredCallsPage;
