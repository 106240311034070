import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { CollectionSituationCardData } from "types/dashboard";

interface CollectionSituationCardProps {
  values: CollectionSituationCardData;
}

const CollectionSituationCard = ({ values }: CollectionSituationCardProps) => {
  return (
    <>
      <CustomDashboardCard xl={4} title="Tahsilat Durumu" color="blue">
        <div className="d-flex justify-content-between align-items-start">
          <div >
            <p>Kapıda Ödeme</p>
            <p style={{fontWeight:'500', fontSize: "20px"}}>{values.collection_situation}</p>
          </div>
          <i
            className="ri-money-dollar-circle-line"
            style={{ fontSize: "48px" }}
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default CollectionSituationCard;
