import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { HotelInfoCardData } from "types/dashboard";

interface HotelInfoCardProps {
  values: HotelInfoCardData;
}

interface IconWithTextProps {
  iconClass: string;
  text: string;
  description: string;
  width: string;
  height: string;
}

const IconWithText = ({
  iconClass,
  text,
  description,
  width,
  height,
}: IconWithTextProps) => (
  <div className="d-flex gap-3 align-items-center">
    <i className={iconClass} style={{ fontSize: width, lineHeight: height, opacity: 0.7 }} />
    <div>
      <p className="fs-5 m-0">{text}</p>
      <p className="m-0" style={{ fontSize: "0.9rem", opacity: 0.7 }}>{description}</p>
    </div>
  </div>
);

const HotelInfoCard = ({ values }: HotelInfoCardProps) => {
  return (
    <>
      <CustomDashboardCard color="darkBlue" xl={12} isGradient={true}>
        <div className="d-flex align-items-end gap-2">
          <h5>
            {values.otel_adi} | {values.otel_durumu} | {values.fiyat_saglayici}{" "}
            |
          </h5>
          <h6>{values.gunluk_satislar}</h6>
        </div>

        <div className="d-flex justify-content-between">
          <IconWithText
            iconClass="ri-shopping-cart-2-fill"
            text={`${values.gunluk_tutar === null ? "0" : values.gunluk_tutar} ₺`}
            description={"Satış Tutarı"}
            width="56px"
            height="56px"
          />
          <IconWithText
            iconClass="ri-hotel-bed-fill"
            text={`${values.gunluk_oda === null ? "0" : values.gunluk_oda} adet`}
            description={"Oda Sayısı"}
            width="56px"
            height="56px"
          />
          <IconWithText
            iconClass="ri-shopping-cart-2-line"
            text={`${values.gunluk_iptal_tutar === null ? "0" : values.gunluk_iptal_tutar} ₺`}
            description={"İptal Edilen Tutar"}
            width="56px"
            height="56px"
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default HotelInfoCard;
