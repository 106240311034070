import { CustomButton } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { AssignHotelToUserPayload } from "types/userOperations";
import { assignHotelsToUsers } from "slices/userOperations/personnelHotelAssignments/thunk";
import { resetAssignHotelToUserForm } from "slices/userOperations/personnelHotelAssignments/reducer";

interface AssignHotelsToUserButtonProps {
  values: AssignHotelToUserPayload;
  validateForm: (values: AssignHotelToUserPayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AssignHotelsToUserButton = ({
  values,
  validateForm,
  handleSubmitted,
  isLoading,
}: AssignHotelsToUserButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const onClick = () => {
    const {isValid} = validateForm(values);

    handleSubmitted(true);

    if (isValid) {
      dispatch(assignHotelsToUsers(values, () => {
        dispatch(resetAssignHotelToUserForm());
      }))
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Otelleri Kullanıcıya Ata
      </CustomButton>
    </div>
  );
};

export default AssignHotelsToUserButton;
