import { useSelector } from "react-redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleInfo = () => {
  const { childrenInfo, childrenInfoLoading } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  return { childrenInfo, childrenInfoLoading };
};

export default useHandleInfo;
