import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { handleBookings, isValidMonthsOption } from "./utils";
import Controls from "./Controls";
import Year from "./Year";
import Key from "./Key";
import { ICalendarPropTypes, IControls, blockedDaysType, IYear } from "./types";

import "./styles.css";

dayjs.extend(isBetween);

const CustomAvailabilityCalendar = ({
  bookings = [],
  showNumberOfMonths = 12,
  showKey = true,
  showCurrentYear = true,
  showControls = true,
}: ICalendarPropTypes): JSX.Element => {
  const initialMonth = 1;
  const initialPage = 1;
  const totalCalendarMonths = 12;
  const _showNumberOfMonths = isValidMonthsOption(showNumberOfMonths)
    ? showNumberOfMonths
    : totalCalendarMonths;
  const _year = dayjs().year();
  const [activeYear, setActiveYear] = useState(_year);
  const [bookedDates, setBookedDates] = useState<blockedDaysType>([]);

  const [availableDates, setAvailableDates] = useState<blockedDaysType>([]);
  const [lastRoomsDates, setLastRoomsDates] = useState<blockedDaysType>([]);
  const [unavailableDates, setUnavailableDates] = useState<blockedDaysType>([]);
  const [noQuotaDates, setNoQuotaDates] = useState<blockedDaysType>([]);

  const [monthsFrom, setMonthsFrom] = useState(initialMonth);
  const [page, setPage] = useState(initialPage);

  const totalPages = totalCalendarMonths / _showNumberOfMonths;

  const resetCalendarYear = () => {
    setMonthsFrom(initialMonth);
    setPage(initialPage);
  };

  const goToPage = useCallback(
    (_page: number): void => {
      const _monthsFrom = _page * _showNumberOfMonths - _showNumberOfMonths + 1;
      setMonthsFrom(_monthsFrom);
      setPage(_page);
    },
    [_showNumberOfMonths]
  );

  const findActivePage = useCallback(() => {
    const now = dayjs();
    const _month = now.month() + 1;
    let _page = 1;
    for (let i = 1; i <= totalPages; i++) {
      const found = _month <= i * _showNumberOfMonths;
      _page = i;
      if (found) break;
    }

    goToPage(_page);
  }, [goToPage, _showNumberOfMonths, totalPages]);

  useEffect(() => {
    if (_showNumberOfMonths !== totalCalendarMonths) {
      findActivePage();
    }
  }, [findActivePage, _showNumberOfMonths]);

  const initCal = useCallback(() => {
    const now = dayjs();
    const _year = now.year();
    setActiveYear(_year);
    if (_showNumberOfMonths !== totalCalendarMonths) findActivePage();
    else resetCalendarYear();
  }, [findActivePage, _showNumberOfMonths]);

  const prev = useCallback(() => {
    const isFirstPage = page === 1;

    if (isFirstPage) {
      const _previousYear = dayjs(`${activeYear}`).subtract(1, "year").year();
      setActiveYear(_previousYear);

      if (_showNumberOfMonths === totalCalendarMonths) {
        resetCalendarYear();
        return;
      }

      const nxtStartingMonth = totalCalendarMonths - _showNumberOfMonths + 1;
      const nxtPage = totalPages;

      setMonthsFrom(nxtStartingMonth);
      setPage(nxtPage);
      return;
    }

    const nxtStartingMonth = monthsFrom - _showNumberOfMonths;
    const nxtPage = page - 1;
    setMonthsFrom(nxtStartingMonth);
    setPage(nxtPage);
  }, [page, _showNumberOfMonths, monthsFrom, totalPages, activeYear]);

  const next = useCallback(() => {
    const isLastPage = page === totalPages;
    if (isLastPage) {
      const _nextYear = dayjs(`${activeYear}`).add(1, "year").year();
      setActiveYear(_nextYear);
      resetCalendarYear();
      return;
    }

    const nxtStartingMonth = page * _showNumberOfMonths + 1;
    const nxtPage = page + 1;
    setMonthsFrom(nxtStartingMonth);
    setPage(nxtPage);
  }, [page, totalPages, _showNumberOfMonths, activeYear]);

  const configControls: IControls = {
    prev,
    next,
    initCal,
    currentYear: activeYear,
  };

  useEffect(() => {
    const {
      bookedDays,
      lastRoomsDays,
      availableDays,
      unavailableDays,
      noQuotaDays,
    } = handleBookings({
      bookings,
      year: activeYear,
    });

    setBookedDates(bookedDays);
    setAvailableDates(availableDays);
    setLastRoomsDates(lastRoomsDays);
    setUnavailableDates(unavailableDays);
    setNoQuotaDates(noQuotaDays);
  }, [bookings, activeYear]);

  const configYear: IYear = {
    showNumberOfMonths: _showNumberOfMonths,
    bookedDates,
    availableDates,
    lastRoomsDates,
    unavailableDates,
    noQuotaDates,
    activeYear,
    monthsFrom,
  };

  const shouldRender = {
    key: showKey,
    currentYear: showCurrentYear,
    controls: showControls,
  };

  return (
    <section className="calendar">
      <div className="wrap">
        {!shouldRender.controls && !shouldRender.currentYear ? null : (
          <div className="controlWrap">
            {shouldRender.controls && <Controls {...configControls} />}
          </div>
        )}
        <Year {...configYear} />
        {shouldRender.key && <Key />}
      </div>
    </section>
  );
};

export default CustomAvailabilityCalendar;
