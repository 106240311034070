import { CustomButton } from "Components/Custom/UI";

interface FilterLogsButtonsProps {

    handleFilter: () => void,
    handleClearFilter: () => void
}
const FilterLogsButtons = ({
    handleFilter,
    handleClearFilter 
}: FilterLogsButtonsProps) => {

    return (
        <div className="d-flex justify-content-end gap-4 mt-4">
            <CustomButton
                variant="primary"
                onClick={handleFilter}
            >
                Logları Getir
            </CustomButton>
            <CustomButton
                variant="danger"
                onClick={handleClearFilter}
            >
                Temizle
            </CustomButton>
        </div>
    )
}

export default FilterLogsButtons;