import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  EarlyReservationDiscountFormErrors,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";

import { handleChangeInput } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface PayingToHotelProps {
  values: EarlyReservationDiscountState;
  formErrors: EarlyReservationDiscountFormErrors;
  validateFormChange: (values: EarlyReservationDiscountState) => void;
}

const PayingToHotel = ({
  values,
  formErrors,
  validateFormChange,
}: PayingToHotelProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Ödeme Günü"
        error={formErrors.odeme_gunu}
      >
        <Flatpickr
          className="form-control"
          value={[values.otele_odeme_gunu]}
          onChange={(dates) => {
            const [startDate] = dates;
            if (startDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");

              dispatch(
                handleChangeInput({
                  otele_odeme_gunu: stringStartDate,
                })
              );
              validateFormChange({
                ...values,
                otele_odeme_gunu: stringStartDate,
              });
            }
          }}
          options={{
            mode: "single",
            dateFormat: "Y-m-d",
            showMonths: 1,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Ödeme Oranı"
        error={formErrors.odeme_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.otele_odeme_orani}
            onChange={(e) => {
              dispatch(
                handleChangeInput({ otele_odeme_orani: e.target.value })
              );
              validateFormChange({
                ...values,
                otele_odeme_orani: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default PayingToHotel;
