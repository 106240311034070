const Key = () => {
  return (
    <div className="d-flex gap-5">
      <div className="d-flex gap-2 align-items-center">
        <span className="p-3 theme-bg-primary" />
        <span>Bugün</span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <span className="p-3 theme-bg-success" />
        <span>Müsait</span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <span className="p-3 theme-bg-warning" />
        <span>Son Odalar, Arayınız</span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <span className="p-3 theme-bg-danger" />
        <span>Müsait Değil</span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <span className="p-3 theme-bg-info" />
        <span>Kontenjan Yok</span>
      </div>
    </div>
  );
};

export default Key;
