import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { FACILITY_LOGO_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityLogoProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityLogo = ({
  values,
  validateFormChange,
  formErrors,
}: FacilityLogoProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.resim ? [values.resim] : [];

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Logo"
        description={FACILITY_LOGO_DESCRIPTION}
        divider
      >
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeInput({ resim: fileItems[0] }));
          }}
          maxFiles={1}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityLogo;
