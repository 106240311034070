import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { GeneralPriceSettingsInitialState } from "slices/pricesAndQuota/generalPriceSettings/reducer";
import { getGeneralPriceSettings } from "slices/pricesAndQuota/generalPriceSettings/thunk";

const useGeneralPriceSettings = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const rooms = hotel.odalar;

  const { isLoading } = useSelector(
    (state: { GeneralPriceSettings: GeneralPriceSettingsInitialState }) =>
      state.GeneralPriceSettings
  );

  useEffect(() => {
    if (otel_id) {
      dispatch(getGeneralPriceSettings(otel_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = rooms.length > 0;

  return {
    error,
    loading,
    isLoading,
    isValidHotel,
  };
};

export default useGeneralPriceSettings;
