import { CompetitorAnalysisInfoPage } from "Components/Custom/AdsAndAnalyzePages";
import useCompetitorAnalysisInfo from "Components/Hooks/adsAndAnalyze/useCompetitorAnalysisInfo";
import PageContainer from "pages/PageContainer";

const CompetitorAnalysisInfo = () => {
  const { isLoading } = useCompetitorAnalysisInfo();

  return (
    <PageContainer title="Rakip Analizleri">
      <CompetitorAnalysisInfoPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CompetitorAnalysisInfo;
