import { useSelector } from "react-redux";
import { UncancellableRoomRateRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { UncancellableRoomRatesInitialState } from "slices/discountsAndActions/uncancellableRoomRates/reducer";

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    uncancellableRoomRate,
    uncancellableRoomRateFormErrors,
    addRateLoading,
  } = useSelector(
    (state: { UncancellableRoomRates: UncancellableRoomRatesInitialState }) =>
      state.UncancellableRoomRates
  );

  const updateUncancellableRoomRatePayload: UncancellableRoomRateRequestPayload =
    {
      otel_id,
      oda_id,
      indirim_orani: uncancellableRoomRate.indirim_orani,
      aktiflik_durum: uncancellableRoomRate.aktiflik_durum,
      tum_odalara_uygulama_durum:
        uncancellableRoomRate.tum_odalara_uygulama_durum,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    uncancellableRoomRate,
    uncancellableRoomRateFormErrors,
    isLoading: addRateLoading,
    updateUncancellableRoomRatePayload,
    formErrors: uncancellableRoomRateFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
