import { CustomButton, CustomDnDOrdering } from "Components/Custom/UI";
import useHandleList from "./useHandleList";

const OrderingList = () => {
  const { orderedHotels, handleOrderItems, handleSave, isLoading } =
    useHandleList();

  return (
    <div
      className="d-flex flex-column gap-4 border border-2 border-white theme-bg-light rounded p-2"
      style={{ maxWidth: "600px" }}
    >
      <p className="text-center">
        Sıralamak istediğiniz otelleri sürükleyip bırakarak sıralayabilirsiniz.
      </p>

      <CustomDnDOrdering
        items={orderedHotels}
        labelKey="otel_adi"
        orderKey="sira"
        handleOrderItems={handleOrderItems}
      />
      <div className="d-flex justify-content-end gap-2">
        <CustomButton
          variant="success"
          onClick={handleSave}
          isLoading={isLoading}
        >
          Sıralamayı Kaydet
        </CustomButton>
      </div>
    </div>
  );
};

export default OrderingList;
