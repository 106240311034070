import { CustomFormContainer } from "Components/Custom/UI";
import WebSiteThemes from "./WebSiteThemes";
import { CreateWebSiteFormChoices, CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import WebSiteDomain from "./WebSiteDomain";
import HotelPhoneNumber from "./HotelPhoneNumber";
import HotelMail from "./HotelMail";
import HotelAddress from "./HotelAddress";
import HotelGoogleXCoordinates from "./HotelGoogleXCoordinates";
import HotelGoogleYCoordinates from "./HotelGoogleYCoordinates";
import HotelFacebookLink from "./HotelFacebookLink";
import HotelInstagramLink from "./HotelInstagramLink";
import HotelWelcomingSentence from "./HotelWelcomingSentence";
import HotelSliderImage from "./HotelSliderImage";
import HotelSliderImageTwo from "./HotelSliderImageTwo";
import HotelSliderImageThree from "./HotelSliderImageThree";
import HotelNoShowOptionSelector from "./HotelNoShowOptionSelector";

interface WebSiteGeneralInfoFieldsProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const WebSiteGeneralInfoFields = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
  imageUrl,
}: WebSiteGeneralInfoFieldsProps) => {
  return (
    <div className="mb-4" style={{background: 'coral'}}>
      <CustomFormContainer title="Web Sitem Genel Bilgiler" lg={12} xl={12}>
        <WebSiteThemes
          values={values}
          formChoices={formChoices}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <WebSiteDomain
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelPhoneNumber
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelNoShowOptionSelector
          values={values}
          formChoices={formChoices}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelMail
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelAddress
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelGoogleXCoordinates
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelGoogleYCoordinates
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelFacebookLink
          values={values}
          validateFormChange={validateFormChange}
        />
        <HotelInstagramLink
          values={values}
          validateFormChange={validateFormChange}
        />
        <HotelWelcomingSentence
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <HotelSliderImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <HotelSliderImageTwo
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <HotelSliderImageThree
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
      </CustomFormContainer>
    </div>
  );
};

export default WebSiteGeneralInfoFields;
