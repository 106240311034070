import useHandleForm from "./useHandleForm";
import { CustomFormContainer } from "Components/Custom/UI";
import DescriptionAndDateFields from "./DescriptionAndDateFields";
import UpdateEarlyReservationDefinitionButton from "./UpdateEarlyReservationDefinitionButton";

const AddEarlyReservationDefinitionForm = () => {
  const {
    earlyReservationDefinition,
    updateEarlyReservationDefinitionRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm();
  return (
    <CustomFormContainer>
      <DescriptionAndDateFields
        values={earlyReservationDefinition}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateEarlyReservationDefinitionButton
        values={earlyReservationDefinition}
        requestPayload={updateEarlyReservationDefinitionRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default AddEarlyReservationDefinitionForm;
