import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Select from "react-select";

import {
  handleSelectMail,
  handleChangeUpdateFacilityUserFormInput,
} from "slices/facility/facilityUsers/reducer";
import { FacilityUserFormErrors, FacilityUserState } from "types/facility";

interface SelectMailSelectorProps {
  values?: FacilityUserState;
  formErrors?: FacilityUserFormErrors;
  validateFormChange?: (values: FacilityUserState) => void;
  selectedMail: string;
  userMailsOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const SelectMailSelector = ({
  values,
  formErrors,
  validateFormChange,
  selectedMail,
  userMailsOptions,
}: SelectMailSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer label="E-Posta Adresi" error={formErrors?.mail}>
      <Select
        value={
          userMailsOptions.find((option) => option.value === selectedMail) ||
          null
        }
        onChange={(selected: Option) => {
          dispatch(handleSelectMail(selected.value));
          values &&
            dispatch(
              handleChangeUpdateFacilityUserFormInput({
                ...values,
                mail: selected.value,
              })
            );

          validateFormChange &&
            values &&
            validateFormChange({ ...values, mail: selected.value });
        }}
        options={userMailsOptions}
        placeholder="E-Posta Adresi Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default SelectMailSelector;
