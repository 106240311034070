import { Input, InputGroup, InputGroupText, Spinner } from "reactstrap";

import styles from "./CustomCreditCard.module.scss";
import CustomButton from "../CustomButton";

import {
  CreditCard,
  CreditCardBrand,
  CreditCardInstallment,
} from "types/GlobalTypes";

interface CreditCardFormProps {
  isSubmitted: boolean;
  errors: CreditCard;
  state: CreditCard & { focus: string };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  isSubmitButtonDisabled: boolean;
  installmentsLoading: boolean;
  cardBrand: CreditCardBrand;
  invalidCard: string;
  selectedInstallment: CreditCardInstallment;
  makePaymentLoading: boolean;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

const CreditCardForm = ({
  isSubmitted,
  errors,
  state,
  handleInputChange,
  handleInputFocus,
  isSubmitButtonDisabled,
  installmentsLoading,
  cardBrand,
  invalidCard,
  selectedInstallment,
  makePaymentLoading,
  onPaste,
}: CreditCardFormProps) => {
  return (
    <div className={styles.container__form}>
      <div className="d-flex flex-column gap-1">
        <Input
          id="cardOwner"
          className="flex-1"
          type="text"
          placeholder="Kart Sahibi Adı Soyadı"
          name="cardOwner"
          value={state.cardOwner}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          invalid={!!errors.cardOwner}
          valid={isSubmitted && !errors.cardOwner}
        />
        <span className={styles.container__form__error}>
          {errors.cardOwner || ""}
        </span>
      </div>
      <div className="d-flex flex-column gap-1">
        <InputGroup>
          <Input
            id="cardNumber"
            type="number"
            placeholder="Kart Numarası"
            name="cardNumber"
            value={state.cardNumber}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onPaste={onPaste}
            invalid={isSubmitted && (!!errors.cardNumber || !!invalidCard)}
            valid={isSubmitted && !errors.cardNumber}
          />
          {installmentsLoading ? (
            <InputGroupText className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" size="sm" />
            </InputGroupText>
          ) : (
            <>
              {cardBrand.showBrand && (
                <InputGroupText className="d-flex gap-2">
                  {cardBrand.cardBrandLogo ? (
                    <img
                      src={cardBrand.cardBrandLogo}
                      alt="visa"
                      style={{ width: "3rem" }}
                    />
                  ) : (
                    <></>
                  )}
                  {cardBrand.cardProgramLogo ? (
                    <img
                      src={cardBrand.cardProgramLogo}
                      alt="mastercard"
                      style={{ width: "3rem" }}
                    />
                  ) : (
                    <></>
                  )}
                </InputGroupText>
              )}
            </>
          )}
        </InputGroup>
        <span className={styles.container__form__error}>
          {invalidCard || errors.cardNumber || ""}
        </span>
      </div>
      <div className="d-flex flex-column gap-1">
        <Input
          id="expiryDate"
          type="text"
          placeholder="AA/YY"
          name="expiryDate"
          value={state.expiryDate}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          invalid={!!errors.expiryDate}
          valid={isSubmitted && !errors.expiryDate}
        />
        <span className={styles.container__form__error}>
          {errors.expiryDate || ""}
        </span>
      </div>
      <div className="d-flex flex-column gap-1">
        <Input
          id="cvc"
          type="number"
          placeholder="Güvenlik Kodu"
          name="cvc"
          value={state.cvc}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          invalid={!!errors.cvc}
          valid={isSubmitted && !errors.cvc}
        />

        <span className={styles.container__form__error}>
          {errors.cvc || ""}
        </span>
      </div>
      <div className={styles.container__button}>
        <CustomButton
          variant="success"
          type="submit"
          disabled={installmentsLoading || isSubmitButtonDisabled}
          isLoading={makePaymentLoading}
        >
          <span className="fw-semibold fs-16">Ödeme Yap</span>
          {selectedInstallment.paymentId ? (
            <span className="fw-bold ms-2 fs-14">
              ({selectedInstallment.total} TL)
            </span>
          ) : (
            <></>
          )}
        </CustomButton>
      </div>
    </div>
  );
};

export default CreditCardForm;
