import { ReservationCallPage } from "Components/Custom/ReservationPages";
import useReservationCall from "Components/Hooks/reservation/useReservationCall";
import PageContainer from "pages/PageContainer";

const ReservationCall = () => {
  const { validCall, params, isLoading } = useReservationCall();

  return (
    <PageContainer title="Rezervasyon Çağrı">
      <ReservationCallPage
        isValidCall={validCall}
        params={params}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default ReservationCall;
