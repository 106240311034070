import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
// import { EnterReservationGuestInfo } from "types/reservation";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { CreateRoomFormValues } from "types/reservation";

interface GuestInfoProps {
  guestType: "yetiskin" | "cocuk";
  guestIndex: number;
  formValues: CreateRoomFormValues;
  handleChangeInput: (
    key: string,
    value: any,
    tip: "yetiskin" | "cocuk",
    sira: number
  ) => void;
}

const GuestInfo = ({
  guestType,
  guestIndex,
  formValues,
  handleChangeInput,
}: GuestInfoProps) => {
  const title =
    guestType === "yetiskin"
      ? `${guestIndex + 1}. Yetişkin `
      : `${guestIndex + 1}. Çocuk `;

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const values = formValues.guests.find(
    (guest) => guest.tip === guestType && guest.sira === guestIndex + 1
  )!;

  return (
    <CustomFormContainer>
      <span className="fw-medium fs-14 text-decoration-underline theme-text-primary">
        {title}
      </span>
      <div
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}
      >
        <CustomFormFieldContainer label="Ad Soyad" divider>
          <Input
            type="text"
            value={values.ad_soyad}
            valid={values.ad_soyad.length > 0}
            invalid={values.ad_soyad.length === 0}
            onChange={(e) =>
              handleChangeInput(
                "ad_soyad",
                e.target.value,
                guestType,
                guestIndex + 1
              )
            }
            placeholder="Ad Soyad"
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Doğum Tarihi"
          divider
          error={
            values.dogum_tarihi.length === 0
              ? "Doğum tarihi boş bırakılamaz."
              : ""
          }
        >
          <Flatpickr
            className="form-control"
            value={
              values.dogum_tarihi
                ? moment(values.dogum_tarihi).toDate()
                : undefined
            }
            onChange={(date) => {
              const [startDate] = date;
              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleChangeInput(
                  "dogum_tarihi",
                  stringStartDate,
                  guestType,
                  guestIndex + 1
                );
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              onDayCreate,
              maxDate: moment().toDate(),
            }}
            placeholder="Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Cinsiyet" divider>
          <div className="d-flex gap-5">
            <div className="d-flex gap-2">
              <Input
                id={title + "erkek"}
                type="radio"
                name={title + "cinsiyet"}
                checked={values.cinsiyet === "e"}
                onChange={() =>
                  handleChangeInput("cinsiyet", "e", guestType, guestIndex + 1)
                }
              />
              <Label for={title + "erkek"} className="form-check-label">
                Erkek
              </Label>
            </div>
            <div className="d-flex gap-2">
              <Input
                id={title + "kadın"}
                name={title + "cinsiyet"}
                type="radio"
                checked={values.cinsiyet === "k"}
                onChange={() =>
                  handleChangeInput("cinsiyet", "k", guestType, guestIndex + 1)
                }
              />
              <Label for={title + "kadın"} className="form-check-label">
                Kadın
              </Label>
            </div>
          </div>
        </CustomFormFieldContainer>

        {values.tip === "yetiskin" && (
          <CustomFormFieldContainer label="TC Kimlik No" divider>
            <Input
              type="number"
              min={11}
              max={11}
              valid={values.tc_kimlik_no.length === 11}
              invalid={
                values.tc_kimlik_no.length !== 11 && values.tip === "yetiskin"
              }
              value={values.tc_kimlik_no}
              onChange={(e) =>
                e.target.value.length < 12 &&
                handleChangeInput(
                  "tc_kimlik_no",
                  e.target.value,
                  guestType,
                  guestIndex + 1
                )
              }
              placeholder="TC Kimlik No"
            />
          </CustomFormFieldContainer>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default GuestInfo;
