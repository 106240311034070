import { CustomFormFieldContainer } from "Components/Custom/UI";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer";

interface CommentDateSelectorProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
}

const CommentDateSelector = ({
  values,
  formErrors,
  validateFormChange,
}: CommentDateSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Konaklama Tarihleri"
        error={formErrors.giris_tarihi || formErrors.cikis_tarihi}
        divider
        required
      >
        <Flatpickr
          className="form-control"
          value={[values.giris_tarihi, values.cikis_tarihi]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");
              dispatch(
                handleChangeCommentInput({
                  giris_tarihi: stringStartDate,
                  cikis_tarihi: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                giris_tarihi: stringStartDate,
                cikis_tarihi: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
          placeholder="Konaklama Tarihi Seçiniz..."
        />
      </CustomFormFieldContainer>
    </>
  );
};
export default CommentDateSelector;
