import moment from "moment";
import "moment/locale/tr";

import { Column, OtherPrice, Period, PriceType } from "types/PricesQuotas";
import ColumnAvailabilityField from "./ColumnAvailabilityField";
import ColumnCtaCtdField from "./ColumnCtaCtdField";
import ColumnReservationField from "./ColumnReservationField";
import ColumnQuotaField from "./ColumnQuotaField";
import ColumnPricesContainer from "./ColumnPricesContainer";
import { CustomModal } from "Components/Custom/UI";
import ColumnOtherPrices from "./ColumnOtherPrices";
import { useState } from "react";
import useHandleForm from "../useHandleForm";

interface PriceEntryTableColumnProps {
  column: Column;
  payMethod: string;
  paymentMethods: string[];
  isBasePayMethod: boolean;
  period: Period;
  priceTypes: PriceType[];
  notAllowedToEdit: boolean;
  otherPrices: {
    [key: string]: OtherPrice[];
  } | null;
  basePayMethod: boolean;
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  };
  paymentMethodsMultipliers: {
    [key: string]: string;
  };
}

const PriceEntryTableColumn = ({
  period,
  column,
  payMethod,
  paymentMethods,
  isBasePayMethod,
  priceTypes,
  notAllowedToEdit,
  otherPrices,
  basePayMethod,
  notAllowedToEditPaymentMethods,
  paymentMethodsMultipliers,
}: PriceEntryTableColumnProps) => {
  const {
    columnInputValues,
    columnPrices,
    isReady,
    handleChange,
    handlePriceChange,
  } = useHandleForm({
    period,
    priceTypes,
    notAllowedToEditPaymentMethods,
    basePayMethod,
    paymentMethodsMultipliers,
    date: column.tarih,
    paymentMethods,
  });
  const date = moment(column.tarih).format("DD.MM.YYYY ddd");

  const dateKey = moment(column.tarih).format("YYYY-MM-DD");

  const otherPrice = otherPrices ? otherPrices[dateKey] : null;

  const arr = otherPrice
    ? otherPrice.filter((item) => item.fiyat_tipi === payMethod)
    : [];

  const prices = arr.map((item) => ({
    key: item.geceleme_gunu,
    value: item.fiyat,
  }));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="d-flex flex-column gap-1 border p-2 pt-0 shadow-sm bg-white rounded"
      style={{ width: "250px" }}
    >
      <div className="w-100 p-2">
        <p className="fw-bold  m-0">{date}</p>
      </div>
      {isReady && (
        <div className="d-flex flex-column gap-2">
          <ColumnAvailabilityField
            payMethod={payMethod}
            fieldName={`${column.tarih}-musaitlik`}
            values={columnInputValues}
            onChange={handleChange}
            notAllowedToEdit={false}
          />
          <ColumnCtaCtdField
            payMethod={payMethod}
            date={column.tarih}
            values={columnInputValues}
            onChange={handleChange}
            notAllowedToEdit={false}
          />

          <i
            className={`ri-price-tag-3-fill fs-24 cursor-pointer ${
              arr.length ? "theme-text-primary" : "theme-text-secondary"
            }`}
            title="Diğer Gecelemeler"
            onClick={() => arr.length && setIsOpen(!isOpen)}
          ></i>

          {arr.length ? (
            <CustomModal
              isOpen={isOpen}
              handleToggle={() => setIsOpen(!isOpen)}
              title="Diğer Gecelemeler"
              size="lg"
            >
              <ColumnOtherPrices otherPrices={prices} />
            </CustomModal>
          ) : (
            <></>
          )}
          {isBasePayMethod && (
            <>
              <ColumnQuotaField
                date={column.tarih}
                values={columnInputValues}
                onChange={handleChange}
                notAllowedToEdit={notAllowedToEdit}
              />
              <ColumnReservationField
                date={column.tarih}
                values={columnInputValues}
                onChange={handleChange}
                notAllowedToEdit={notAllowedToEdit}
              />
            </>
          )}
          <ColumnPricesContainer
            date={column.tarih}
            values={columnPrices}
            onChange={handlePriceChange}
            priceTypes={priceTypes}
            notAllowedToEdit={notAllowedToEdit}
            availability={parseInt(
              columnInputValues[`${column.tarih}-musaitlik`]
            )}
          />
        </div>
      )}
    </div>
  );
};

export default PriceEntryTableColumn;
