import DailiyCallsPerPersonTable from "./DailyCallsPerPersonTable";
import FilterForm from "./FilterForm";

interface DailyCallsPerPersonPageProps {
  isLoading: boolean;
}

const DailyCallsPerPersonPage = ({
  isLoading,
}: DailyCallsPerPersonPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <DailiyCallsPerPersonTable isLoading={isLoading} />
    </div>
  );
};

export default DailyCallsPerPersonPage;
