import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveFooterLinks,
  setIsLoading,
  setError,
  setAddFooterLinkLoading,
  setAddFooterLinkError,
  setDeleteFooterLinkLoading,
  setDeleteFooterLinkError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_FOOTER_LINKS,
  DELETE_FOOTER_LINK,
  ADD_FOOTER_LINK,
} from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getFooterLinks = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_FOOTER_LINKS, {
      params: {
        otel_id: id,
      },
    });

    dispatch(handleSaveFooterLinks(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteFooterLink =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteFooterLinkLoading(true));

      console.log("payload of delete footer : ", payload);

      await axios.post(DELETE_FOOTER_LINK, payload);

      successCallback();
      dispatch(setToastSuccess("Footer linki silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteFooterLinkError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteFooterLinkLoading(false));
    }
  };

export const addFooterLink =
  (
    params: { id: number },
    payload: FormData,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddFooterLinkLoading(true));

      // console.log("payload in add footer : ", payload);

      await axios.post(`${ADD_FOOTER_LINK}?otel_id=${params.id}`, payload);

      successCallback();
      dispatch(setToastSuccess("Footer linki eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddFooterLinkError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddFooterLinkLoading(false));
    }
  };
