import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { Link } from "react-router-dom";
import { FacilityReservationOfficialCardData } from "types/dashboard";

interface FacilityReservationOfficialCardProps {
  values: FacilityReservationOfficialCardData;
  otel_id: number;
}

const FacilityReservationOfficialCard = ({
  values,
  otel_id,
}: FacilityReservationOfficialCardProps) => {
  const textStyle: React.CSSProperties = {
    fontSize: "12px",
    color: "grey",
    margin: "4px 0",
  };
  const highlightedTextStyle: React.CSSProperties = {
    fontSize: "16px",
    color: "orange",
    margin: "0",
    fontWeight: "500",
  };
  return (
    <>
      <CustomDashboardCard xl={4} title="Tesis Rezervasyon Yetkilisi">
        <Link
          to={`/tesis-yetkilileri?otel_id=${otel_id}`}
          style={{ textDecoration: "none" }}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <p className="m-0 fw-semibold fs-5 text-dark">{values.yetkili_adi}</p>
              <p style={highlightedTextStyle}>{values.telefon}</p>
              <p style={highlightedTextStyle}>{values.mail}</p>
              <p>{""}</p>
              <p style={textStyle}>
                Rezervasyon yetkililerine SMS & Mail gönderilir.
              </p>
            </div>
            <i
              className="ri-account-pin-box-line"
              style={{ fontSize: "48px", color: "orange" }}
            />
          </div>
        </Link>
      </CustomDashboardCard>
    </>
  );
};

export default FacilityReservationOfficialCard;
