import { AddCommentState, Category } from "types/comments";
import AddNewCommentButton from "./AddNewCommentButton";
import CommentCategorySelector from "./CommentCategorySelector";
import CommentDateSelector from "./CommentDateSelector";
import CommenterName from "./CommenterName";
import NegativeCommentField from "./NegativeCommentField";
import PositiveCommentField from "./PositiveCommentField";
import useHandleForm from "./useHandleForm";
import { CustomFormContainer } from "Components/Custom/UI";
import CommentScoreField from "./CommentScoreField";

/*
 *This component renders form fields and give them props from useHandleForm()
 */

/*
    ? ASK : Tarih girerken bugünden sonraki tarihler girilebilecek mi?
*/

const AddCommentForm = () => {
  const {
    comment,
    commentCategoryOptions,
    commentQuestions,
    commentPuanlama,
    formErrors,
    addCommentLoading,
    onAddNewComment,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer title="Yorum Ekle" lg={12} xl={12}>
      <div className="d-flex flex-column gap-4">
        <CommentDateSelector
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CommenterName
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CommentCategorySelector
          commentCategoryOptions={commentCategoryOptions}
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CommentScoreField
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          commentQuestions={commentQuestions}
          commentPuanlama={commentPuanlama}
        />
        <PositiveCommentField
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <NegativeCommentField
          values={comment}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <AddNewCommentButton
          values={comment}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          onAddNewComment={onAddNewComment}
          isLoading={addCommentLoading}
        />
      </div>
    </CustomFormContainer>
  );
};

export default AddCommentForm;
