import AddUserPage from 'Components/Custom/UserOperationsPages/AddUserPage';
import useAddUser from 'Components/Hooks/userOperations/useAddUser';
import PageContainer from 'pages/PageContainer';

const AddUser = () => {

    const { isLoading } = useAddUser();

  return (
    <PageContainer title="Kullanıcı Ekle">
        <AddUserPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default AddUser