import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { CompetitorAnalysisCardData } from "types/dashboard";
import AnalysisCard from "./AnalysisCard";
import useHandleCard from "./useHandleCard";

interface CompetitorAnalysisCardProps {
  values: CompetitorAnalysisCardData;
}

const CompetitorAnalysisCard = ({ values }: CompetitorAnalysisCardProps) => {
  const {
    onGetFacilityAvailabilities,
    facilityAvailabilities,
    availabilityLoading,
    updateDescriptionLoading,
    updateHotelInfoLoading,
    updateAdsStatusLoading,
    onUpdateDescription,
    onUpdateAdsDescription,
    onUpdateAdsStatus,
    onUpdateHotelInfo,
  } = useHandleCard();

  return (
    <>
      <CustomDashboardCard xl={12} title="Tesis Rakip Analizi">
        <AnalysisCard
          facilityAvailabilities={facilityAvailabilities}
          availabilityLoading={availabilityLoading}
          onGetFacilityAvailabilities={onGetFacilityAvailabilities}
          updateDescriptionLoading={updateDescriptionLoading}
          updateHotelInfoLoading={updateHotelInfoLoading}
          updateAdsStatusLoading={updateAdsStatusLoading}
          onUpdateDescription={onUpdateDescription}
          onUpdateAdsDescription={onUpdateAdsDescription}
          onUpdateAdsStatus={onUpdateAdsStatus}
          onUpdateHotelInfo={onUpdateHotelInfo}
          {...values}
        />
      </CustomDashboardCard>
    </>
  );
};

export default CompetitorAnalysisCard;
