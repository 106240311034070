import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { ReservationFilterPayload } from "types/reports";

interface HotelListProps {
  options: { value: string; label: string }[];
  selectedHotelOptions: string[];
  handleSelectHotelOptions: (selected: string[]) => void;
  fieldKey: keyof ReservationFilterPayload;
}

const HotelList = ({
  options,
  selectedHotelOptions,
  handleSelectHotelOptions,
  fieldKey,
}: HotelListProps) => {
  return (
    <>
      <CustomFormFieldContainer label="Otel" orientation="vertical">
        <CustomDualListBox
          listOptions={options}
          selectedOptions={selectedHotelOptions}
          handleSelectOptions={handleSelectHotelOptions}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelList;
