import { CustomFormContainer } from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";
import Select from "react-select";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleCommenterChange } from "slices/comments/listComments/reducer";

interface Option {
  value: string;
  label: string;
}
interface CommenterSelectorProps {
  commenterOptions: Option[];
}

const CommenterSelector = ({ commenterOptions }: CommenterSelectorProps) => {
  const { personel, handlePersonelOrCustomer } = useHandleForm();

  const dispatch: Dispatch<any> = useDispatch();
  return (
    <CustomFormContainer>
      <Select
        value={commenterOptions.find((option) => option.value === personel)}
        onChange={(selected: Option) => {
          if (selected.value) {
            dispatch(handleCommenterChange(selected.value));
            console.log("CommenterSelector, selected value : ", selected.value);
            handlePersonelOrCustomer(selected.value);
          }
        }}
        options={commenterOptions}
        placeholder="Yorum Yapan"
      />
    </CustomFormContainer>
  );
};

export default CommenterSelector;
