import { BankInstallment } from "types/reservation";
import ConceptPriceCard from "./ConceptPriceCard";
import useHandlePrices from "./useHandlePrices";

interface ConceptPricesProps {
  oda_id: string;
  oda_adi: string;
  giris_tarihi: string;
  iptal_sigortasi_durum: "1" | "0";
  iptal_sigortasi_gun: string;
  odeme_sekli: string[];
  banka_taksit: BankInstallment[];
}

const ConceptPrices = ({
  oda_id,
  oda_adi,
  giris_tarihi,
  iptal_sigortasi_durum,
  iptal_sigortasi_gun,
  odeme_sekli,
  banka_taksit,
}: ConceptPricesProps) => {
  const {
    conceptPrices,
    provider,
    kismi_odeme,
    kapida_odeme,
    kaporali_odeme,
    priceCalculationStatusMessages,
  } = useHandlePrices(oda_id);

  return (
    <div className="d-flex flex-column gap-3">
      {conceptPrices.map((concept) => (
        <ConceptPriceCard
          key={concept.pansiyon_id}
          {...concept}
          provider={provider}
          kismi_odeme={kismi_odeme}
          kapida_odeme={kapida_odeme}
          kaporali_odeme={kaporali_odeme}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          oda_id={oda_id}
          oda_adi={oda_adi}
          giris_tarihi={giris_tarihi}
          iptal_sigortasi_durum={iptal_sigortasi_durum}
          iptal_sigortasi_gun={iptal_sigortasi_gun}
          gifts={concept.verilen_hediyeler}
          odeme_sekli={odeme_sekli}
          banka_taksit={banka_taksit}
        />
      ))}
    </div>
  );
};

export default ConceptPrices;
