import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input, Label } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/updateUser/reducer";
import {
  UpdateUserFormErrors,
  UpdateUserPayload,
} from "types/userOperations";

interface UserGenderCheckBoxProps {
  values: UpdateUserPayload;
  formChoices: {
    value: string;
    label: string;
  }[];
  formErrors: UpdateUserFormErrors;
  validateFormChange: (values: UpdateUserPayload) => void;
}

const UserGenderCheckBox = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: UserGenderCheckBoxProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Cinsiyet"
        error={formErrors.cinsiyet}
        divider
        required
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {formChoices.map((option) => (
            <div
              key={option.value}
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Input
                id={option.value}
                checked={values.cinsiyet === option.value}
                type="radio"
                autoCorrect="on"
                value={option.value}
                onChange={(e) => {
                  dispatch(
                    handleChangeInput({
                      cinsiyet: e.target.value,
                    })
                  );
                  validateFormChange({
                    ...values,
                    cinsiyet: e.target.value,
                  });
                }}
              />
              <Label
                check
                className="text-xs fw-normal"
                style={{
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
                htmlFor={option.value}
              >
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default UserGenderCheckBox;
