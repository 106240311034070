import { Spinner } from "reactstrap";
import CustomButton from "../CustomButton";

interface CustomFormButtonProps {
  type: "button" | "submit" | "reset";
  variant: "success" | "danger" | "primary" | "secondary" | "warning";
  isLoading: boolean;
  onClick?: () => void;
  children: React.ReactNode | string;
}
const CustomFormButton = ({
  type,
  variant,
  isLoading,
  children,
  onClick,
}: CustomFormButtonProps) => {
  return (
    <CustomButton
      classes="w-100"
      variant={variant}
      type={type}
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading && (
        <Spinner size="sm" className="me-2">
          Loading...
        </Spinner>
      )}
      {children}
    </CustomButton>
  );
};

export default CustomFormButton;
