import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import {
  AddAdditionalPagesFormErrors,
  AdditionalPageState,
} from "types/myWebSite";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";

interface SelfLinkInputProps {
  values: AdditionalPageState;
  formErrors: AddAdditionalPagesFormErrors;
  validateFormChange: (values: AdditionalPageState) => void;
}

const SelfLinkInput = ({
  values,
  validateFormChange,
  formErrors,
}: SelfLinkInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Selflink :"
        error={formErrors.seflink}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.seflink}
          onChange={(e) => {
            dispatch(
              handleChangeAddAdditionalPageInput({
                seflink: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              seflink: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default SelfLinkInput;
