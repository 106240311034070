import { CustomDnDOrdering } from "Components/Custom/UI";
import useHandleList from "./useHandleList";
import CustomDragDropBox from "Components/Custom/UI/CustomDragDropBox";

const ConceptMatchList = () => {
  const {
    supplierConcepts,
    selectedSupplierConcepts,
    inetConcepts,
    handleChangeSupplierConcepts,
    handleChangeSelectedSupplierConcepts,
    handleChangeInetConcepts,
    onAddConceptMatch,
    onRemoveConceptMatch,
    checkItemIsMatched,
    matchStatus,
  } = useHandleList();

  const selectedSupplierConceptsLength = selectedSupplierConcepts.length;
  const matchedInetConcepts = inetConcepts.filter((inetConcept) =>
    selectedSupplierConcepts.some(
      (selectedSupplierConcept) => selectedSupplierConcept.id === inetConcept.id
    )
  );
  return (
    <div className="d-flex flex-column gap-2">
      <p className="fw-semibold fs-20 m-0 p-1 flex-grow-1">Pansiyon Eşleştir</p>
      <div className="d-flex gap-2">
        <div className="d-flex flex-column gap-1">
          <p className="text-center fw-medium m-0 p-1 rounded-top border border-primary theme-text-primary bg-primary bg-opacity-10">
            INET Pansiyon Listesi
          </p>
          <div
            className="d-flex flex-column gap-1 px-1 py-2 border border-2 rounded-bottom theme-bg-light"
            style={{ minHeight: "200px", minWidth: "350px" }}
          >
            <CustomDnDOrdering
              items={inetConcepts}
              labelKey="text"
              orderKey="order"
              handleOrderItems={handleChangeInetConcepts}
              numbersOfFixedItems={matchedInetConcepts.length}
            />
          </div>
        </div>
        {selectedSupplierConceptsLength ? (
          <div className="d-flex flex-column gap-1">
            <p className="text-center fw-medium m-0 p-1 border border-primary theme-text-primary bg-primary bg-opacity-10  rounded-top">
              -
            </p>
            <div className="d-flex flex-column gap-2 px-1 py-2 border border-2 rounded-bottom theme-bg-light rounded">
              {Array.from({ length: selectedSupplierConceptsLength }).map(
                (_, index) => (
                  <div
                    key={index}
                    className="px-2 py-1 rounded border fs-14"
                    style={
                      matchStatus[index]?.status
                        ? {
                            backgroundColor: "green",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "red",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => {
                      !matchStatus[index]?.status
                        ? onAddConceptMatch(
                            selectedSupplierConcepts[index],
                            inetConcepts[index]
                          )
                        : onRemoveConceptMatch(inetConcepts[index]);
                    }}
                    title={
                      matchStatus[index]?.status
                        ? "Eşleşmeyi Kaldır"
                        : "Eşleştir"
                    }
                  >
                    <i className="ri-arrow-left-right-line fw-semibold text-white"></i>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <CustomDragDropBox
          draggableListTitle="Tedarikçi Pansiyon Listesi"
          sortableListTitle="Tedarikçi Seçilen Pansiyonlar"
          draggableItems={supplierConcepts}
          sortableItems={selectedSupplierConcepts}
          setDraggableItems={handleChangeSupplierConcepts}
          setSortableItems={handleChangeSelectedSupplierConcepts}
          checkItemIsRemovable={checkItemIsMatched}
        />
      </div>
    </div>
  );
};

export default ConceptMatchList;
