import { createSlice } from "@reduxjs/toolkit";

import { HourlyCallsChartResponse } from "types/reports";

export interface HourlyCallsChartInitialState {
  hourlyCallsChartResponse: HourlyCallsChartResponse;
  isLoading: boolean;
  error: string;
}

const initialState: HourlyCallsChartInitialState = {
  hourlyCallsChartResponse: {
    data: [],
    max: "0",
    min: "0",
  },
  isLoading: false,
  error: "",
};

const hourlyCallsChartSlice = createSlice({
  name: "hourlyCallsChart",
  initialState,
  reducers: {
    handleSaveHourlyCallsChartResponse(state, action) {
      state.hourlyCallsChartResponse = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveHourlyCallsChartResponse, setIsLoading, setError } =
  hourlyCallsChartSlice.actions;

export default hourlyCallsChartSlice.reducer;
