import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteGiftsAndPromotionWithId,
  handleSaveGiftsAndPromotionForEdit,
} from "slices/discountsAndActions/giftsAndPromotions/reducer";
import { deleteGiftsAndPromotions } from "slices/discountsAndActions/giftsAndPromotions/thunk";
import {
  GiftAndPromotion,
  GiftAndPromotionListItem,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { giftsAndPromotions, giftsAndPromotionsList } = useSelector(
    (state: any) => state.GiftsAndPromotions
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(
      handleSaveGiftsAndPromotionForEdit({
        ...selectedRow,
        gunler: selectedRow?.gunler ? Object.values(selectedRow?.gunler) : [],
      })
    );
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      hediye_id: id,
    };
    dispatch(deleteGiftsAndPromotions(payload) as any);
    dispatch(handleDeleteGiftsAndPromotionWithId(id));
  };

  const tableData: GiftAndPromotionState[] = giftsAndPromotions?.map(
    (gift: GiftAndPromotion) => {
      return {
        id: gift.id,
        otel_id: gift.otel_id,
        otel_oda_id: gift.otel_oda_id,
        hediye_kosulu: gift.hediye_kosulu,
        konaklama_baslangic_tarihi: gift.konaklama_baslangic_tarihi,
        konaklama_bitis_tarihi: gift.konaklama_bitis_tarihi,
        tum_gunler: gift.tum_gunler,
        gunler: gift.gunler,
        min_gece_sayisi: gift.min_gece_sayisi,
        hediye_id: gift.hediye_id,
        indirim_orani: gift.indirim_orani,
        gecerli_kisi_sayisi: gift.gecerli_kisi_sayisi,
        grup_id: gift.grup_id,
      };
    }
  );

  console.log("tableData", tableData);

  const tableColumns = [
    {
      header: "Hediye Adı",
      accessorKey: "hediye_id",
      cell: (cell: any) => {
        const gift = giftsAndPromotionsList.find(
          (gift: GiftAndPromotionListItem) => gift.id === cell.getValue()
        );
        return <span>{gift ? gift.hediye_adi : ""}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Hediye Koşulu",
      accessorKey: "hediye_kosulu",
      cell: (cell: any) => {
        const criteria = cell.getValue();
        const label =
          criteria === 1
            ? "Tarih Aralığına Göre"
            : criteria === 2
            ? "Tarih Aralığı-Min. Gece Sayısına Göre"
            : criteria === 3
            ? "Min.Gece Sayısına Göre"
            : "";

        return <span className=" text-wrap"> {label} </span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Konaklama Tarihi",
      accessorKey: "konaklama_tarihi",
      cell: (cell: any) => {
        const showDate =
          cell.row.original.hediye_kosulu === 1 ||
          cell.row.original.hediye_kosulu === 2;
        return (
          <>
            {showDate ? (
              <span>
                {moment(cell.row.original.konaklama_baslangic_tarihi).format(
                  "DD.MM.YYYY"
                )}
                <br />
                {moment(cell.row.original.konaklama_bitis_tarihi).format(
                  "DD.MM.YYYY"
                )}
              </span>
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Min. Gece Sayısı",
      accessorKey: "min_gece_sayisi",
      cell: (cell: any) => {
        const showMinNight = cell.row.original.hediye_kosulu !== 1;

        const nights = cell.getValue();

        return <span>{showMinNight ? nights : "-"}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      accessorKey: "tum_gunler",
      cell: (cell: any) => {
        const showAllDays = cell.row.original.hediye_kosulu !== 3;

        return (
          <>
            {showAllDays ? (
              <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const allDays = cell.row.original.tum_gunler;

        const showAllDays = cell.row.original.hediye_kosulu !== 3;

        return (
          <>
            {showAllDays ? (
              <>
                <span className="d-flex gap-2 flex-wrap">
                  {allDays === "1" || days.length === 7 ? (
                    <CustomRowBadge value="Tüm Günler" />
                  ) : (
                    days.map((day: string, index: number) => (
                      <CustomRowBadge key={index} value={day} />
                    ))
                  )}
                </span>
              </>
            ) : (
              <span> - </span>
            )}
          </>
        );
      },
      accessorKey: "gunler",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "indirim_orani",
      cell: (cell: any) => {
        const rate = cell.getValue();
        const label = rate === 100 ? "Ücretsiz" : `%${rate}`;

        return <span>{label}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Geçerli Kişi Sayısı",
      accessorKey: "gecerli_kisi_sayisi",
      cell: (cell: any) => {
        const person = cell.getValue();
        const label = person === 99 ? "Odadaki Herkes" : person;

        return <span>{label}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
