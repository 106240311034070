import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveStayLongPayLessActions,
  resetStayLongPayLessAction,
  resetStayLongPayLessActions,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_STAY_LONG_PAY_LESS_ACTIONS,
  ADD_STAY_LONG_PAY_LESS_ACTIONS,
  UPDATE_STAY_LONG_PAY_LESS_ACTIONS,
  DELETE_STAY_LONG_PAY_LESS_ACTIONS,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { StayLongPayLessActionRequestPayload } from "types/DiscountsAndActions";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteStayLongPayLessActionPayload {
  kal_ode_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getStayLongPayLessActions =
  (payload: RequestPayload) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetStayLongPayLessActions());
      const response = await axios.get(GET_STAY_LONG_PAY_LESS_ACTIONS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveStayLongPayLessActions(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addStayLongPayLessAction =
  (
    payload: StayLongPayLessActionRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddDiscountLoading(true));

      await axios.post(ADD_STAY_LONG_PAY_LESS_ACTIONS, payload);

      successCallback();
      dispatch(resetStayLongPayLessAction());
      dispatch(setToastSuccess("İndirim eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddDiscountLoading(false));
    }
  };

export const updateStayLongPayLessAction =
  (
    payload: StayLongPayLessActionRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDiscountLoading(true));

      await axios.post(UPDATE_STAY_LONG_PAY_LESS_ACTIONS, payload);

      successCallback();
      dispatch(resetStayLongPayLessAction());
      dispatch(setToastSuccess("İndirim güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDiscountLoading(false));
    }
  };

export const deleteStayLongPayLessAction =
  (payload: DeleteStayLongPayLessActionPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteDiscountLoading(true));
      await axios.post(DELETE_STAY_LONG_PAY_LESS_ACTIONS, payload);

      dispatch(setToastSuccess("İndirim silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteDiscountLoading(false));
    }
  };
