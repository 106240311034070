import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityPartialPaymentDatesInitialState } from "slices/facility/facilityPartialPaymentDates/reducer";
import { getFacilityPartialPaymentDates } from "slices/facility/facilityPartialPaymentDates/thunk";

const useFacilityPartialPaymentDates = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: {
      FacilityPartialPaymentDates: FacilityPartialPaymentDatesInitialState;
    }) => state.FacilityPartialPaymentDates
  );

  useEffect(() => {
    dispatch(
      getFacilityPartialPaymentDates({
        page: 1,
        per_page: 10,
        search: "",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useFacilityPartialPaymentDates;
