import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveNightRestrictions,
  resetNightRestriction,
  resetNightRestrictions,
  setAddRestrictionLoading,
  setAddRestrictionError,
  setUpdateRestrictionLoading,
  setUpdateRestrictionError,
  setDeleteRestrictionLoading,
  setDeleteRestrictionError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_NIGHT_RESTRICTIONS,
  ADD_NIGHT_RESTRICTION,
  UPDATE_NIGHT_RESTRICTION,
  DELETE_NIGHT_RESTRICTION,
} from "helpers/url_helper";

import { NightRestrictionRequestPayload } from "types/DiscountsAndActions";
import { Dispatch } from "redux";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteNightRestrictionPayload {
  gece_kisitlamalari_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getNightRestrictions =
  (payload: GetRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetNightRestrictions());
      const response = await axios.get(GET_NIGHT_RESTRICTIONS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveNightRestrictions(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addNightRestriction =
  (payload: NightRestrictionRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddRestrictionLoading(true));

      await axios.post(ADD_NIGHT_RESTRICTION, payload);

      successCallback();
      dispatch(resetNightRestriction());
      dispatch(setToastSuccess("Gece kısıtlaması eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddRestrictionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddRestrictionLoading(false));
    }
  };

export const updateNightRestriction =
  (payload: NightRestrictionRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: any) => {
    try {
      dispatch(setUpdateRestrictionLoading(true));

      await axios.post(UPDATE_NIGHT_RESTRICTION, payload);

      successCallback();
      dispatch(resetNightRestriction());
      dispatch(setToastSuccess("Gece kısıtlaması güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateRestrictionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateRestrictionLoading(false));
    }
  };

export const deleteNightRestriction =
  (payload: DeleteNightRestrictionPayload) => async (dispatch: any) => {
    try {
      dispatch(setDeleteRestrictionLoading(true));

      await axios.post(DELETE_NIGHT_RESTRICTION, payload);

      dispatch(setToastSuccess("Gece kısıtlaması silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteRestrictionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteRestrictionLoading(false));
    }
  };
