import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveCertificates,
  setIsLoading,
  setError,
  setAddCertificateLoading,
  setAddCertificateError,
  setDeleteCertificateLinkLoading,
  setDeleteCertificateLinkError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_CERTIFICATES,
  ADD_CERTIFICATE,
  DELETE_CERTIFICATE,
} from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getCertificates = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_CERTIFICATES, {
      params: {
        otel_id: id,
      },
    });

    dispatch(handleSaveCertificates(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteCertificate =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteCertificateLinkLoading(true));

      // console.log("payload of delete footer : ", payload);

      await axios.post(DELETE_CERTIFICATE, payload);

      successCallback();
      dispatch(setToastSuccess("Sertifika silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteCertificateLinkError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteCertificateLinkLoading(false));
    }
  };

export const addCertificate =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddCertificateLoading(true));

      // console.log("payload in add CERTIFICATE : ", payload);

      await axios.post(ADD_CERTIFICATE, payload);

      successCallback();
      dispatch(setToastSuccess("Sertifika eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddCertificateError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddCertificateLoading(false));
    }
  };
