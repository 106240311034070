import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSelectedAuthority,
  handleSelectedUsers,
  resetSelectedPermissions,
  UserAuthoritiesInitialState,
} from "slices/userOperations/authorities/reducer";
import {
  addAuthorityToUsers,
  getAuthoritiesAndUsers,
  getAuthoritiesOfSelectedUser,
} from "slices/userOperations/authorities/thunk";
import { AddUserAuthoritiesPayload } from "types/userOperations";

const useUserAuthorities = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    users,
    authorities,
    selectedUsers,
    selectedAuthority,
    selectedPermissions,
    isLoading,
    addAuthoritiesToUsersLoading,
    addAuthoritiesLoading,
  } = useSelector(
    (state: { UserAuthorities: UserAuthoritiesInitialState }) =>
      state.UserAuthorities
  );

  useEffect(() => {
    dispatch(getAuthoritiesAndUsers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSelectUser = (userIds: number[]) => {
    const firstUsersId = userIds[0];
    dispatch(handleSelectedUsers(userIds));
    dispatch(getAuthoritiesOfSelectedUser(firstUsersId));
  };

  const handleSelectAuthority = (id: number) => {
    dispatch(handleSelectedAuthority(id));
  };

  const handleSaveAuthorities = () => {
    const yetkiler: { [key: string]: string } = {};

    Object.entries(selectedPermissions).forEach(([authorityId, permission]) => {
      yetkiler[authorityId] = permission;
    });

    const payload: AddUserAuthoritiesPayload = {
      kullanicilar: selectedUsers,
      yetkiler,
    };

    dispatch(
      addAuthorityToUsers(payload, () => {
        dispatch(handleSelectedUsers([]));
        dispatch(resetSelectedPermissions());
        dispatch(getAuthoritiesAndUsers());
      })
    );
  };

  return {
    users,
    authorities,
    selectedUsers,
    selectedAuthority,
    selectedPermissions,
    isLoading,
    addAuthoritiesToUsersLoading,
    addAuthoritiesLoading,
    handleSelectUser,
    handleSelectAuthority,
    handleSaveAuthorities,
  };
};

export default useUserAuthorities;
