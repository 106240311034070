import { createSlice } from "@reduxjs/toolkit";

import { HotelInvoice } from "types/financeAndAccounting";
import { Pagination } from "types/GlobalTypes";

export interface HotelInvoicesInitialState {
  hotelInvoices: HotelInvoice[];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: HotelInvoicesInitialState = {
  hotelInvoices: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const hotelInvoicesSlice = createSlice({
  name: "hotelInvoices",
  initialState,
  reducers: {
    handleSaveHotelInvoices(state, action) {
      const { data, ...rest } = action.payload;
      state.hotelInvoices = data;
      state.pagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveHotelInvoices, setIsLoading, setError } =
  hotelInvoicesSlice.actions;

export default hotelInvoicesSlice.reducer;
