import { useSelector } from "react-redux";
import { PhoneStatusInitialState } from "slices/reports/phoneStatus/reducer";

const useHandleTable = () => {
  const { phoneStatus } = useSelector(
    (state: { PhoneStatus: PhoneStatusInitialState }) => state.PhoneStatus
  );

  const status = phoneStatus?.map((phoneStatus) => {
    return {
      ...phoneStatus,
      dahili_durum: Object.values(phoneStatus.dahili_durum),
    };
  });

  const extendedStatus = status
    ?.map((phoneStatus) => {
      return phoneStatus.dahili_durum.map((dahili_durum) => {
        return {
          ...phoneStatus,
          dahili_durum,
        };
      });
    })
    .flat();

  const tableData = extendedStatus?.map((status) => ({
    Tarih: status.dahili_durum.tarih,
    "Adı Soyadı": status.ad_soyad,
    "Grup Adı": status.grup_adi,
    Dahili: status.dahili,
    "Çevrim İçi": status.dahili_durum.cevrimici,
    Görüşme: status.dahili_durum.gorusme,
    Meşgul: status.dahili_durum.mesgul,
    Yemekte: status.dahili_durum.yemekte,
    Dışarıda: status.dahili_durum.disarida,
    "Çevrim Dışı": status.dahili_durum.cevrimdisi,
    "İlk İşlem Zamanı": status.dahili_durum.ilk_islem_zamani,
    "Son İşlem Zamanı": status.dahili_durum.son_islem_zamani,
  }));

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Grup Adı",
      accessorKey: "Grup Adı",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "Dahili",
      enableColumnFilter: false,
    },
    {
      header: "Çevrim İçi",
      accessorKey: "Çevrim İçi",
      enableColumnFilter: false,
    },
    {
      header: "Görüşme",
      accessorKey: "Görüşme",
      enableColumnFilter: false,
    },
    {
      header: "Meşgul",
      accessorKey: "Meşgul",
      enableColumnFilter: false,
    },
    {
      header: "Yemekte",
      accessorKey: "Yemekte",
      enableColumnFilter: false,
    },
    {
      header: "Dışarıda",
      accessorKey: "Dışarıda",
      enableColumnFilter: false,
    },
    {
      header: "Çevrim Dışı",
      accessorKey: "Çevrim Dışı",
      enableColumnFilter: false,
    },
    {
      header: "İlk İşlem Zamanı",
      accessorKey: "İlk İşlem Zamanı",
      enableColumnFilter: false,
    },
    {
      header: "Son İşlem Zamanı",
      accessorKey: "Son İşlem Zamanı",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
