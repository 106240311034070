import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  updateFacilityUser,
  getFacilityUser,
} from "slices/facility/facilityUsers/thunk";

import {
  UpdateFacilityUserRequestPayload,
  FacilityUserState,
} from "types/facility";

import {
  FacilityUsersInitialState,
  resetUpdateFacilityUserForm,
} from "slices/facility/facilityUsers/reducer";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { CustomButton } from "Components/Custom/UI";
interface UpdateUserButtonProps {
  values: FacilityUserState;
  requestPayload: UpdateFacilityUserRequestPayload;
  validateForm: (values: FacilityUserState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateUserButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateUserButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityUserResponse } = useSelector(
    (state: { FacilityUsers: FacilityUsersInitialState }) => state.FacilityUsers
  );

  const { kullanici_bilgileri } = facilityUserResponse;

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const handleUpdateUser = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateFacilityUser(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getFacilityUser(otel_id));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="danger"
        onClick={() => {
          dispatch(resetUpdateFacilityUserForm(kullanici_bilgileri));
        }}
      >
        Temizle
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleUpdateUser}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateUserButton;
