import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/userOperations/addGroup/reducer";
import { AddGroupFormErrors, AddGroupPayload, AddGroupResponse } from "types/userOperations";

interface GroupManagerSelectorProps {
  values: AddGroupPayload,
  formChoices: AddGroupResponse;
  formErrors: AddGroupFormErrors;
  validateFormChange: (values: AddGroupPayload) => void;
}

interface Option {
  value: string;
  label: string;
}

const GroupManagerSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange
}: GroupManagerSelectorProps) => {
  
  const dispatch: Dispatch<any> = useDispatch();

  const options: Option[] = Object.entries(formChoices.yetkililer).map(
    ([key, value]) => ({
      value: String(value.id),
      label: value.ad_soyad,
    })
  );

  const selectedOption = options.find(
    (option) => option.value === values.yonetici_id.toString()
  );

  return (
    <CustomFormFieldContainer 
      label={"Personel : "} 
      error={formErrors.yonetici_id}
      required
      >
      <Select
        options={options}
        value={selectedOption}
        onChange={(selected: Option) => {
          const managerId = selected ? Number(selected.value) : 0;
          dispatch(
            handleChangeInput({
              yonetici_id: managerId,
            })
          );
          validateFormChange({ ...values, yonetici_id: Number(selected.value) })
        }}
        placeholder="Grup yöneticisi seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default GroupManagerSelector;
