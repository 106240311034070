import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSaveLocations,
  handleSaveSelectedLocations,
  ListLocationsInitialState,
} from "slices/definitions/listLocations/reducer";
import { getLocations } from "slices/definitions/listLocations/thunk";
import { Location } from "types/definitions";

const useListLocations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { ListLocations: ListLocationsInitialState }) => state.ListLocations
  );

  useEffect(() => {
    dispatch(
      getLocations({
        parent_id: -1,
        successCallback: (response: Location[]) => {
          dispatch(handleSaveLocations(response));
          dispatch(
            handleSaveSelectedLocations([
              {
                key: -1,
                value: [...response],
              },
            ])
          );
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useListLocations;
