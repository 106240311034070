import { EmployeeSalesAmountPage } from "Components/Custom/ReportsPages";
import useEmployeeSalesAmount from "Components/Hooks/reports/useEmployeeSalesAmount";
import PageContainer from "pages/PageContainer";

const EmployeeSalesAmount = () => {
  const { isLoading } = useEmployeeSalesAmount();

  return (
    <PageContainer title="Personel Satış Rakamları">
      <EmployeeSalesAmountPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default EmployeeSalesAmount;
