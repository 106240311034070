import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  setIsLoading,
  setError,
  setSaveInformationLoading,
  setSaveInformationError,
  handleSaveChildSpecialAgeInformation,
  resetChildSpecialAgeInformation,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_CHILD_SPECIAL_AGE_INFORMATION,
  UPDATE_CHILD_SPECIAL_AGE_INFORMATION,
  POST_CHILD_SPECIAL_AGE_INFORMATION,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  ChildSpecialAgeInformationRequestPayload,
  DeleteChildSpecialAgeInformation,
  UpdateChildSpecialAgeInformation,
} from "types/PricesQuotas";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
}
type SuccessCallback = () => void;

export const getChildSpecialAgeInformation =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_CHILD_SPECIAL_AGE_INFORMATION, {
        otel_id: payload.otel_id,
        oda_id: payload.oda_id,
      });

      dispatch(handleSaveChildSpecialAgeInformation(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateChildSpecialAgeInformation =
  (
    payload: UpdateChildSpecialAgeInformation,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSaveInformationLoading(true));

      await axios.post(UPDATE_CHILD_SPECIAL_AGE_INFORMATION, payload);

      successCallback();
      dispatch(setToastSuccess("Çocuk özel yaş bilgisi güncellendi."));
      dispatch(resetChildSpecialAgeInformation());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveInformationError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveInformationLoading(false));
    }
  };

export const deleteChildSpecialAgeInformation =
  (
    payload: DeleteChildSpecialAgeInformation,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      await axios.post(UPDATE_CHILD_SPECIAL_AGE_INFORMATION, payload);

      successCallback();
      dispatch(setToastSuccess("Çocuk özel yaş bilgisi silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setToastError(message));
      });
    } finally {
    }
  };

export const postChildSpecialAgeInformation =
  (
    payload: ChildSpecialAgeInformationRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSaveInformationLoading(true));
      await axios.post(POST_CHILD_SPECIAL_AGE_INFORMATION, payload);

      dispatch(setToastSuccess("Çocuk özel yaş bilgisi eklendi."));
      successCallback();
      dispatch(resetChildSpecialAgeInformation());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveInformationError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveInformationLoading(false));
    }
  };
