import { useSelector } from "react-redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleTable = () => {
  const { reservationCall } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const { sonuc_musteri_gecmisi } = reservationCall;

  const tableData = sonuc_musteri_gecmisi.map((history) => ({
    Tarih: history.kayit_tarihi,
    "Otel Adı": history.otel_adi,
    "Oda Tipi": history.oda_isim,
    "Pansiyon Tipi": history.pansiyon_isim,
    "Giriş Tarihi": history.giris_tarihi,
    "Çıkış Tarihi": history.cikis_tarihi,
    "Yetişkin Sayısı": history.yetiskin_sayisi,
    "Çocuk Sayısı": history.cocuk_sayisi,
    "Çocuk Yaşı 1": history.cocuk_yasi_1,
    "Çocuk Yaşı 2": history.cocuk_yasi_2,
    "Çocuk Yaşı 3": history.cocuk_yasi_3,
    "Çocuk Yaşı 4": history.cocuk_yasi_4,
    Fiyat: history.otel_fiyat,
    Personel: history.personel,
    "Rezervasyon ID": history.rezervasyon_id,
    "Rezervasyon Kodu": history.rezervasyon_kodu,
    Operatör: history.operator,
    Açıklama: history.aciklama,
  }));

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Oda Tipi",
      accessorKey: "Oda Tipi",
      enableColumnFilter: false,
    },
    {
      header: "Pansiyon",
      accessorKey: "Pansiyon Tipi",
      enableColumnFilter: false,
    },
    {
      header: "Giriş T",
      accessorKey: "Giriş Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış T",
      accessorKey: "Çıkış Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Y",
      accessorKey: "Yetişkin Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Çocuk",
      accessorKey: "Çocuk Sayısı",
      cell: (cell: any) => {
        const childrenCount = cell.getValue() ? Number(cell.getValue()) : 0;
        const firstChild = cell.row.original["Çocuk Yaşı 1"];
        const secondChild = cell.row.original["Çocuk Yaşı 2"];
        const thirdChild = cell.row.original["Çocuk Yaşı 3"];
        const fourthChild = cell.row.original["Çocuk Yaşı 4"];
        return (
          <div className="d-flex flex-column gap-2">
            <span>{cell.getValue() ?? 0}</span>
            {firstChild && childrenCount > 0 && <span> {firstChild}</span>}
            {secondChild && childrenCount > 1 && <span> {secondChild}</span>}
            {thirdChild && childrenCount > 2 && <span> {thirdChild}</span>}
            {fourthChild && childrenCount > 3 && <span> {fourthChild}</span>}
          </div>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Fiyat",
      accessorKey: "Fiyat",
      enableColumnFilter: false,
    },
    {
      header: "Personel",
      accessorKey: "Personel",
      enableColumnFilter: false,
    },
    {
      header: "Oper",
      accessorKey: "Operatör",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Açıklama",
      cell: (cell: any) => {
        return (
          <div
            style={{
              width: "200px",
            }}
          >
            <p className="text-wrap">{cell.getValue()}</p>
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
