import { useSelector } from "react-redux";
import { GeneralPriceSettingsInitialState } from "slices/pricesAndQuota/generalPriceSettings/reducer";
import useValidateForm from "./useValidateForm";
import { HotelInitialState } from "slices/hotel/reducer";
import { GeneralPriceSettingRequestPayload, Months } from "types/PricesQuotas";

interface Option {
  value: string;
  label: string;
}

interface MonthsOption {
  label: string;
  value: Months;
}

const useHandleForm = () => {
  const {
    generalPriceSettings,
    generalPriceSettingsFormState,
    generalPriceSettingFormErrors,
    saveSettingsLoading,
  } = useSelector(
    (state: { GeneralPriceSettings: GeneralPriceSettingsInitialState }) =>
      state.GeneralPriceSettings
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const workingTypesOptions: Option[] = [
    { value: "net", label: "Net Fiyat" },
    { value: "komisyonlu", label: "Komisyonlu Fiyat" },
    {
      value: "komisyon_faturali",
      label: "Tam Ücret Ödemeli (Komisyon Faturalı)",
    },
  ];

  const priceEntryMethodsOptions: Option[] = [
    { value: "odafiyati", label: "Oda Fiyatı" },
    { value: "kisibasifiyat", label: "Kişi Başı Fiyat" },
  ];

  const currencyOptions: Option[] = [
    { value: "TRY", label: "TRY, Türk Lirası" },
    { value: "USD", label: "USD, Amerikan Doları" },
    { value: "EUR", label: "EUR, Euro" },
    { value: "GBP", label: "GBP, İngiliz Sterlini" },
  ];

  const cancelInsuranceOptions: Option[] = [
    { value: "3", label: "3 Gün" },
    { value: "4", label: "4 Gün" },
    { value: "5", label: "5 Gün" },
    { value: "6", label: "6 Gün" },
    { value: "7", label: "7 Gün" },
    { value: "8", label: "8 Gün" },
    { value: "9", label: "9 Gün" },
    { value: "10", label: "10 Gün" },
    { value: "11", label: "11 Gün" },
    { value: "12", label: "12 Gün" },
    { value: "13", label: "13 Gün" },
    { value: "14", label: "14 Gün" },
    { value: "15", label: "15 Gün" },
    { value: "16", label: "16 Gün" },
    { value: "17", label: "17 Gün" },
    { value: "18", label: "18 Gün" },
    { value: "19", label: "19 Gün" },
    { value: "20", label: "20 Gün" },
    { value: "21", label: "21 Gün" },
    { value: "22", label: "22 Gün" },
    { value: "23", label: "23 Gün" },
    { value: "24", label: "24 Gün" },
    { value: "25", label: "25 Gün" },
    { value: "26", label: "26 Gün" },
    { value: "27", label: "27 Gün" },
    { value: "28", label: "28 Gün" },
    { value: "29", label: "29 Gün" },
    { value: "30", label: "30 Gün" },
  ];

  const kismiPaymentDaysOptions: Option[] = [
    { value: "3", label: "3 Gün" },
    { value: "5", label: "5 Gün" },
    { value: "7", label: "7 Gün" },
    { value: "10", label: "10 Gün" },
    { value: "15", label: "15 Gün" },
  ];

  const monthsOptions: MonthsOption[] = [
    { value: "Ocak", label: "Ocak" },
    { value: "Şubat", label: "Şubat" },
    { value: "Mart", label: "Mart" },
    { value: "Nisan", label: "Nisan" },
    { value: "Mayıs", label: "Mayıs" },
    { value: "Haziran", label: "Haziran" },
    { value: "Temmuz", label: "Temmuz" },
    { value: "Ağustos", label: "Ağustos" },
    { value: "Eylül", label: "Eylül" },
    { value: "Ekim", label: "Ekim" },
    { value: "Kasım", label: "Kasım" },
    { value: "Aralık", label: "Aralık" },
  ];

  const facilityPaymentOptions: Option[] = [
    { value: "giriste", label: "Girişte veya Girişten .. Gün Önce" },
    { value: "cikista", label: "Çıkışta veya Çıkıştan .. Gün Sonra" },
    { value: "rez_gunu", label: "Rezervasyon Gününde" },
  ];

  const facilityTypesOptions: Option[] = [
    { value: "sehir", label: "Şehir Oteli" },
    { value: "crm", label: "CRM Yaz Oteli" },
    { value: "termal", label: "Termal Oteli" },
    { value: "kayak", label: "Kayak Oteli" },
    { value: "operator", label: "Operatör Oteli" },
    { value: "islami", label: "İslami Otel" },
  ];

  const updateGeneralPriceSettingsRequestPayload: GeneralPriceSettingRequestPayload =
    {
      ...generalPriceSettingsFormState,
      otel_id: otel_id,
      sabit_kur_degeri_durum:
        generalPriceSettingsFormState.sabit_kur_degeri_durum ? "1" : "0",
      komisyon_kumulatif_durum:
        generalPriceSettingsFormState.komisyon_kumulatif_durum ? "1" : "0",
      kickback_kumulatif_durum:
        generalPriceSettingsFormState.kickback_kumulatif_durum ? "1" : "0",
      kismi_odeme: generalPriceSettingsFormState.kismi_odeme ? "1" : "0",
      kapida_odeme_para_birimi_goster:
        generalPriceSettingsFormState.kapida_odeme_para_birimi_goster
          ? "1"
          : "0",
      kismi_odeme_gun_once:
        generalPriceSettingsFormState.kismi_odeme_gun_once === 0 ? "0" : "1",
      kismi_baslangic_donemi: generalPriceSettingsFormState.kismi_odeme
        ? generalPriceSettingsFormState.kismi_baslangic_donemi
        : "",
      kismi_bitis_donemi: generalPriceSettingsFormState.kismi_odeme
        ? generalPriceSettingsFormState.kismi_bitis_donemi
        : "",
      kapida_baslangic_donemi:
        generalPriceSettingsFormState.kapida_odeme === "evet"
          ? generalPriceSettingsFormState.kapida_baslangic_donemi
          : "",
      kapida_bitis_donemi:
        generalPriceSettingsFormState.kapida_odeme === "evet"
          ? generalPriceSettingsFormState.kapida_bitis_donemi
          : "",
      kaporali_satis_baslangic_donemi:
        generalPriceSettingsFormState.kaporali_satis === "evet"
          ? generalPriceSettingsFormState.kaporali_satis_baslangic_donemi
          : "",
      kaporali_satis_bitis_donemi:
        generalPriceSettingsFormState.kaporali_satis === "evet"
          ? generalPriceSettingsFormState.kaporali_satis_bitis_donemi
          : "",
    };

  return {
    generalPriceSettingsFormState,
    formErrors: generalPriceSettingFormErrors,
    generalPriceSettings,
    workingTypesOptions,
    priceEntryMethodsOptions,
    currencyOptions,
    cancelInsuranceOptions,
    kismiPaymentDaysOptions,
    monthsOptions,
    facilityPaymentOptions,
    facilityTypesOptions,
    requestPayload: updateGeneralPriceSettingsRequestPayload,
    saveSettingsLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
