import { AddCommentPage } from "Components/Custom/CommentsPages";
import useAddComment from "Components/Hooks/comments/useAddComment";
import PageContainer from "pages/PageContainer";

const AddComment = () => {
  const { isLoading, loading, error, isValidHotel } = useAddComment();

  return (
    <PageContainer title={"Personel Yorumlarını Listele"}>
      <AddCommentPage
        data={true}
        isLoading={isLoading}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
      />
    </PageContainer>
  );
};

export default AddComment;
