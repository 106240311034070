import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelectors from "./DateSelectors";
import DiscountRate from "./DiscountRate";
import SelectFields from "./SelectFields";
import ApplicationCriteria from "./ApplicationCriteria";
import UpdateDiscountButton from "./UpdateDiscountButton";
import AddNewDiscountButton from "./AddNewDiscountButton";

interface UnderTheDeskDiscountsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const UnderTheDeskDiscountsForm = (props: UnderTheDeskDiscountsFormProps) => {
  const {
    underTheDeskDiscount,
    conceptsOptions,
    paymentMethodOptions,
    addNewUnderTheDeskDiscountRequestPayload,
    updateUnderTheDeskDiscountRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addDiscountLoading,
    updateDiscountLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <>
      <CustomFormContainer>
        <ApplicationCriteria
          values={underTheDeskDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <DateSelectors
          values={underTheDeskDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <DiscountRate
          values={underTheDeskDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <SelectFields
          values={underTheDeskDiscount}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          conceptsOptions={conceptsOptions}
          paymentMethodOptions={paymentMethodOptions}
        />
        {underTheDeskDiscount.id ? (
          <UpdateDiscountButton
            values={underTheDeskDiscount}
            requestPayload={updateUnderTheDeskDiscountRequestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            hotelAndSelectedRoom={props.hotelAndSelectedRoom}
            isLoading={updateDiscountLoading}
          />
        ) : (
          <AddNewDiscountButton
            values={underTheDeskDiscount}
            requestPayload={addNewUnderTheDeskDiscountRequestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            hotelAndSelectedRoom={props.hotelAndSelectedRoom}
            isLoading={addDiscountLoading}
          />
        )}
      </CustomFormContainer>
    </>
  );
};

export default UnderTheDeskDiscountsForm;
