import { CustomFormContainer } from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import useHandleForm from "./useHandleForm";
import FacilityWelcomeCode from "./FacilityWelcomeCode";
import FacilityCalculationSupplier from "./FacilityCalculationSupplier";

interface FacilityCalculationFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityCalculationFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityCalculationFieldsProps) => {
  const { welcomeCodeOptions, priceCalculationSupplierOptions } =
    useHandleForm();

  return (
    <CustomFormContainer title="Fiyat Hesaplama" lg={12} xl={12}>
      <FacilityWelcomeCode
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        welcomeCodeOptions={welcomeCodeOptions}
      />
      <FacilityCalculationSupplier
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        priceCalculationSupplierOptions={priceCalculationSupplierOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityCalculationFields;
