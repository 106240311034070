import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import ContractForm from "./ContractForm";
import HotelContractsTable from "./HotelContractsTable";

interface HotelContractsPageProps {
  isLoading: boolean;
}

const HotelContractsPage = ({ isLoading }: HotelContractsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <ContractForm />
      <HotelContractsTable isLoading={isLoading} />
    </div>
  );
};

export default withPageNeedsHotel(HotelContractsPage);
