import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  success: string;
  warning: string;
  error: string;
}

export const initialState: InitialState = {
  success: "",
  warning: "",
  error: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToastSuccess(state, action) {
      state.success = action.payload;
    },
    setToastWarning(state, action) {
      state.warning = action.payload;
    },
    setToastError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setToastSuccess, setToastWarning, setToastError } =
  toastSlice.actions;

export default toastSlice.reducer;
