import FilterForm from "./FilterForm";
import ReservationsTable from "./ReservationsTable";
import RezervationData from "./RezervationData";


interface ReservationsPageProps {
  isLoading: boolean;
}

const ReservationsPage = ({ isLoading }: ReservationsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <ReservationsTable isLoading={isLoading} />
      <RezervationData/>
    </div>
  );
};

export default ReservationsPage;
