import {
  BankInstallment,
  CalculatePriceForHotelConceptPrice,
  CalculatePriceForHotelProvider,
} from "types/reservation";
import ConceptInfo from "../../ConceptInfo";
import useHandleNormalPayButton from "../../PayButtonsHooks/useHandleNormalPayButton";
import useHandleIptalEdilemezPayButton from "../../PayButtonsHooks/useHandleIptalEdilemezPayButton";
import useHandleKapidaPayButton from "../../PayButtonsHooks/useHandleKapidaPayButton";
import useHandleKaporaliPayButton from "../../PayButtonsHooks/useHandleKaporaliPayButton";
import ConceptPrice from "../ConceptPrice";
import NormalPayButton from "../PayButtons/NormalPayButton";
import KapidaPayButton from "../PayButtons/KapidaPayButton";
import KaporaliPayButton from "../PayButtons/KaporaliPayButton";
import IptalEdilemezPayButton from "../PayButtons/IptalEdilemezPayButton";

interface SamePricePaymentsProps {
  ayni_fiyatli_odeme_tipleri: string[];
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: {
    [key: string]: string;
  };
  gifts: string;

  tedarikci: CalculatePriceForHotelProvider;
  giris_tarihi: string;
  iptal_sigortasi_gun: string;
  iptal_sigortasi_durum: "1" | "0";
  kismi_odeme: {
    kismi_odeme: 0 | 1;
    kismi_odeme_orani: number;
    kismi_odeme_tarihi: string;
    iptal_sigortasi_durum: "1" | "0";
  };
  kapida_odeme: {
    kapida_odeme_durum: "evet" | "hayir";
    kapida_komisyon_orani: string;
    kapida_indirim_orani: string;
    kapida_odeme_para_birimi_goster: "0" | "1";
  };
  kaporali_odeme: {
    kaporali_satis_durum: "evet" | "hayir";
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  banka_taksit: BankInstallment[];
}

const SamePricePayments = ({
  ayni_fiyatli_odeme_tipleri,
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  kontenjan,
  gifts,
  tedarikci,
  giris_tarihi,
  iptal_sigortasi_gun,
  iptal_sigortasi_durum,
  kismi_odeme,
  kapida_odeme,
  kaporali_odeme,
  priceCalculationStatusMessages,
  onClick,
  banka_taksit,
}: SamePricePaymentsProps) => {
  //normal ödeme
  const normal = useHandleNormalPayButton({
    fiyatlar,
    tedarikci,
    giris_tarihi,
    iptal_sigortasi_gun,
    kismi_odeme,
    priceCalculationStatusMessages,
    mayNotExist:
      !ayni_fiyatli_odeme_tipleri.includes("normal") ||
      fiyatlar?.normal?.fiyat_durum !== 1,
  });

  //iptal edilemez ödeme
  const iptalEdilemez = useHandleIptalEdilemezPayButton({
    fiyatlar,
    priceCalculationStatusMessages,
    mayNotExist:
      !ayni_fiyatli_odeme_tipleri.includes("iptal_edilemez") ||
      fiyatlar?.iptal_edilemez?.fiyat_durum !== 1,
  });

  //kapıda ödeme
  const kapida = useHandleKapidaPayButton({
    fiyatlar,
    kontenjan,
    kapida_odeme,
    priceCalculationStatusMessages,
    mayNotExist:
      !ayni_fiyatli_odeme_tipleri.includes("kapida") ||
      fiyatlar?.kapida?.fiyat_durum !== 1,
  });

  //   kapora ödeme
  const kaporali = useHandleKaporaliPayButton({
    fiyatlar,
    kaporali_odeme,
    priceCalculationStatusMessages,
    mayNotExist:
      !ayni_fiyatli_odeme_tipleri.includes("kaporali") ||
      fiyatlar?.kaporali?.fiyat_durum !== 1,
  });

  const prices = ayni_fiyatli_odeme_tipleri
    .map((odeme_tipi) => {
      switch (odeme_tipi) {
        case "normal":
          return {
            type: "normal",
            hasPrice: fiyatlar?.normal?.fiyat_durum === 1,
            normal,
          };
        case "kapida":
          return {
            type: "kapida",
            hasPrice: fiyatlar?.kapida?.fiyat_durum === 1,
            kapida,
          };
        case "kaporali":
          return {
            type: "kaporali",
            hasPrice: fiyatlar?.kaporali?.fiyat_durum === 1,
            kaporali,
          };
        case "iptal_edilemez":
          return {
            type: "iptal_edilemez",
            hasPrice: fiyatlar?.iptal_edilemez?.fiyat_durum === 1,
            iptalEdilemez,
          };
        default:
          return null;
      }
    })
    .filter((price) => price !== null);

  const basePrice = prices[0];

  const showPrice = basePrice !== null;

  const price =
    basePrice?.type === "normal"
      ? basePrice?.normal?.payNowPrice
      : basePrice?.type === "kapida"
      ? basePrice?.kapida?.kapidaOdemePrice
      : basePrice?.type === "kaporali"
      ? basePrice?.kaporali?.kaporaliOdemePrice
      : basePrice?.type === "iptal_edilemez"
      ? basePrice?.iptalEdilemez?.iptalEdilemezPrice
      : 0;

  const overlinedPrice =
    basePrice?.type === "normal"
      ? basePrice?.normal?.payNowOverlinedPrice
      : basePrice?.type === "kapida"
      ? basePrice?.kapida?.kapidaOdemeOverlinedPrice
      : basePrice?.type === "kaporali"
      ? basePrice?.kaporali?.kaporaliOdemeOverlinedPrice
      : basePrice?.type === "iptal_edilemez"
      ? basePrice?.iptalEdilemez?.iptalEdilemezOverlinedPrice
      : 0;

  return (
    <div
      className="rounded p-2 border bg-white"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        minHeight: "120px",
      }}
    >
      <ConceptInfo
        priceType={ayni_fiyatli_odeme_tipleri}
        oda_id={oda_id}
        pansiyon_id={pansiyon_id}
        pansiyon_adi={pansiyon_adi}
        fiyatlar={fiyatlar}
        kontenjan={kontenjan}
        gifts={gifts}
        kismi_odeme={kismi_odeme}
      />
      <ConceptPrice
        showPrice={showPrice}
        price={price ?? 0}
        overlinedPrice={overlinedPrice ?? 0}
        normal={{
          status:
            ayni_fiyatli_odeme_tipleri.includes("normal") &&
            fiyatlar?.normal?.fiyat_durum === 1,
          kismiOdeme: normal.kismiOdeme,
          kismiOdemePrice: normal.kismiOdemePrice,
          iptal_sigortasi_durum,
          iptalSigortasiFiyat: normal.iptalSigortasiFiyat,
        }}
        kaporali={{
          status:
            ayni_fiyatli_odeme_tipleri.includes("kaporali") &&
            fiyatlar?.kaporali?.fiyat_durum === 1,
          kaporaAmount: kaporali.kaporaAmount,
        }}
        banka_taksit={banka_taksit}
      />
      <div className="d-flex flex-column gap-2">
        {prices.map((price) => {
          if (price?.type === "normal") {
            return (
              <NormalPayButton
                key={price.type}
                oda_id={oda_id}
                oda_adi={oda_adi}
                pansiyon_id={pansiyon_id}
                pansiyon_adi={pansiyon_adi}
                stop={normal.stop}
                stopMessage={normal.stopMessage}
                payNowFiyatDurum={normal.payNowFiyatDurum}
                payNowPrice={normal.payNowPrice}
                payNowPriceButtonText={normal.payNowPriceButtonText}
                kismiOdeme={normal.kismiOdeme}
                kismiOdemePrice={normal.kismiOdemePrice}
                kismiOdemeTarihi={normal.kismiOdemeTarihi}
                kismiOdemeButtonText={normal.kismiOdemeButtonText}
                onClick={onClick}
                modal={normal.modal}
                toggle={normal.toggle}
                showPayNowButton={normal.showPayNowButton}
                iptal_sigortasi_durum={iptal_sigortasi_durum}
                cancellationDate={normal.cancellationDate}
              />
            );
          }
          if (price?.type === "kapida") {
            return (
              <KapidaPayButton
                stop={kapida.stop}
                stopMessage={kapida.stopMessage}
                showKapidaOdemeButton={kapida.showKapidaOdemeButton}
                kapidaOdemeFiyatDurum={kapida.kapidaOdemeFiyatDurum}
                kapidaOdemeButtonText={kapida.kapidaOdemeButtonText}
                kapidaOdemePrice={kapida.kapidaOdemePrice}
                kapidaOdemeOverlinedPrice={kapida.kapidaOdemeOverlinedPrice}
                oda_id={oda_id}
                oda_adi={oda_adi}
                checked={kapida.checked}
                isOpen={kapida.isOpen}
                onClick={onClick}
                pansiyon_adi={pansiyon_adi}
                pansiyon_id={pansiyon_id}
                quota={kapida.quota}
                setChecked={kapida.setChecked}
                toggle={kapida.toggle}
              />
            );
          }
          if (price?.type === "kaporali") {
            return (
              <div id={"kaporali" + pansiyon_id}>
                <KaporaliPayButton
                  key={price.type}
                  oda_id={oda_id}
                  oda_adi={oda_adi}
                  pansiyon_id={pansiyon_id}
                  pansiyon_adi={pansiyon_adi}
                  stop={kaporali.stop}
                  stopMessage={kaporali.stopMessage}
                  showKaporaliOdemeButton={kaporali.showKaporaliOdemeButton}
                  kaporaliOdemeFiyatDurum={kaporali.kaporaliOdemeFiyatDurum}
                  kaporaliOdemeButtonText={kaporali.kaporaliOdemeButtonText}
                  kaporaliOdemePrice={kaporali.kaporaliOdemePrice}
                  kaporaAmount={kaporali.kaporaAmount}
                  onClick={onClick}
                />
              </div>
            );
          }
          if (price?.type === "iptal_edilemez") {
            return (
              <IptalEdilemezPayButton
                key={price.type}
                oda_id={oda_id}
                oda_adi={oda_adi}
                pansiyon_id={pansiyon_id}
                pansiyon_adi={pansiyon_adi}
                stop={iptalEdilemez.stop}
                stopMessage={iptalEdilemez.stopMessage}
                iptalEdilemezFiyatDurum={iptalEdilemez.iptalEdilemezFiyatDurum}
                iptalEdilemezPrice={iptalEdilemez.iptalEdilemezPrice}
                iptalEdilemezPriceButtonText={
                  iptalEdilemez.iptalEdilemezPriceButtonText
                }
                showIptalEdilemezButton={iptalEdilemez.showIptalEdilemezButton}
                onClick={onClick}
              />
            );
          }
          return <></>;
        })}
      </div>
    </div>
  );
};

export default SamePricePayments;
