import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";

import { FacilityRoomImageFile } from "types/facility";
import { handleChangeFacilityRoomImageFilesInput } from "slices/facility/facilityRooms/reducer";
import {
  addFacilityRoomImages,
  getFacilityRoom,
} from "slices/facility/facilityRooms/thunk";
import { CustomButton } from "Components/Custom/UI";

interface AddNewImagesButtonProps {
  facilityRoomImageFiles: FacilityRoomImageFile[];
  selectedRoom: number;
  isLoading: boolean;
}

const AddNewImagesButton = ({
  facilityRoomImageFiles,
  selectedRoom,
  isLoading,
}: AddNewImagesButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const onClick = () => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_id", selectedRoom.toString());

    facilityRoomImageFiles.forEach((image) => {
      formData.append("yeni_oda_resimler[]", image.file, image.file.name);
    });

    dispatch(
      addFacilityRoomImages(formData, () => {
        dispatch(handleChangeFacilityRoomImageFilesInput([]));
        dispatch(getFacilityRoom({ otel_id, oda_id: selectedRoom }));
      })
    );
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        variant="success"
        disabled={!facilityRoomImageFiles.length}
        onClick={onClick}
        isLoading={isLoading}
      >
        Resimleri Yükle
      </CustomButton>
    </div>
  );
};

export default AddNewImagesButton;
