import { useSelector } from "react-redux";
import { ViewFeaturedImagesInitialState } from "slices/facility/viewFeaturedImages/reducer";

const useHandleAlbum = () => {
  const { images } = useSelector(
    (state: { ViewFeaturedImages: ViewFeaturedImagesInitialState }) =>
      state.ViewFeaturedImages
  );

  const album = images.map((image) => ({
    src: image.resim_url,
    alt: image.otel_adi,
  }));

  return { album };
};

export default useHandleAlbum;
