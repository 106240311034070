import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";

import { resetPassword } from "slices/thunks";

import { Router } from "types";

import * as Yup from "yup";

const useHandleForm = (props: Router) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectLayoutState = (state: any) => state.Profile;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    error: state.error,
    success: state.success,
  }));

  // Inside your component
  const { error, success } = useSelector(selectLayoutProperties);

  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfirmationShow, setPasswordConfirmationShow] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Şifre alanı boş bırakılamaz")
        .min(4, "Şifre en az 4 karakter olmalıdır"),
      password: Yup.string()
        .required("Şifre alanı boş bırakılamaz")
        .min(8, "Şifre en az 8 karakter olmalıdır"),
      password_confirmation: Yup.string()
        .required("Şifre tekrarı alanı boş bırakılamaz")
        .min(8, "Şifre tekrarı en az 8 karakter olmalıdır")
        .oneOf([Yup.ref("password"), ""], "Şifreler eşleşmiyor"),
    }),
    onSubmit: (values) => {
      dispatch(resetPassword(values, props.router.navigate));
      setIsLoading(true);
    },
  });

  useEffect(() => {
    if (error || success) {
      setIsLoading(false);
    }
  }, [error, success]);

  return {
    validation,
    oldPasswordShow,
    setOldPasswordShow,
    passwordShow,
    setPasswordShow,
    passwordConfirmationShow,
    setPasswordConfirmationShow,
    isLoading,
    error,
    success,
  };
};

export default useHandleForm;
