import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { EmployeeSalesAmountInitialState } from "slices/reports/employeeSalesAmount/reducer";
import { getEmployeeSalesAmount } from "slices/reports/employeeSalesAmount/thunk";

const useEmployeeSalesAmount = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { EmployeeSalesAmount: EmployeeSalesAmountInitialState }) =>
      state.EmployeeSalesAmount
  );

  useEffect(() => {
    const params = {
      start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getEmployeeSalesAmount(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useEmployeeSalesAmount;
