import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/breakingDiscounts/reducer";
import {
  BreakingDiscountState,
  BreakingDiscountFormErrors,
} from "types/DiscountsAndActions";

import Select from "react-select";

interface DiscountRelatedFieldsProps {
  values: BreakingDiscountState;
  formErrors: BreakingDiscountFormErrors;
  validateFormChange: (values: BreakingDiscountState) => void;
  breakingDiscountsTypesOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const DiscountRelatedFields = ({
  values,
  formErrors,
  validateFormChange,
  breakingDiscountsTypesOptions,
}: DiscountRelatedFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İndirimin Geçerli Olacağı Gün"
        tooltipId="breakingDiscountsbreakingDiscountsTypesOptions"
        error={formErrors.sondakika_tipi}
      >
        <Select
          value={
            breakingDiscountsTypesOptions.find(
              (option) => option.value === values.sondakika_tipi
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeInput({ sondakika_tipi: selectedOption.value })
            );
          }}
          options={breakingDiscountsTypesOptions}
          placeholder="İndirimin Geçerli Olacağı Gün Seçiniz..."
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="İndirim Oranı"
        error={formErrors.indirim_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeInput({ indirim_orani: parseInt(e.target.value) })
              );
              validateFormChange({
                ...values,
                indirim_orani: parseInt(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Kampanya Aktiflik"
        tooltipId="breakingDiscountsDiscountStatus"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.sondakika_aktiflik === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  yalniz_bu_otel: values.sondakika_aktiflik === "1" ? "0" : "1",
                })
              );
              dispatch(
                handleChangeInput({
                  sondakika_aktiflik:
                    values.sondakika_aktiflik === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountRelatedFields;
