import CustomCollapse from "Components/Custom/UI/CustomCollapse/CustomCollapse";
import { DASHBOARD_COLORS } from "utils/dashboardColors";
import useWebSocket from "./useWebSocket ";
import { CrmDashboardData } from "types/dashboard";
// import x from "../../../../../../assets/images/aktif.jpg"

interface PersonnelActivityProps {
  values: CrmDashboardData;
}

const PersonnelActivity = ({ values }: PersonnelActivityProps) => {
  const { data } = useWebSocket("wss://oteld.com:9502");

  return (
    <>
      <CustomCollapse
        title="Personel Aktivite"
        bgColor={DASHBOARD_COLORS.purple}
        icon="ri-team-line"
        isOpen={true}
      >
        <div
          className="d-flex justify-content-between border rounded px-4 py-2 fs-14 mb-3"
          style={{ backgroundColor: "#eae9e9" }}
        >
          <div>
            <strong>Toplam Meşgul Sürem :</strong> {values.personel_aktivite_sureler.mesgulde_gecen_sure}
          </div>
          <div>
            <strong>Toplam Yemekte Sürem :</strong> {values.personel_aktivite_sureler.yemekte_gecen_sure}
          </div>
        </div>
        <div>
          {data &&
          (typeof data === "string"
            ? data.trim()
            : Array.isArray(data)
            ? data.length > 0
            : true) ? (
            <div>{data}</div>
          ) : (
            <div>Yükleniyor...</div>
          )}
        </div>
      </CustomCollapse>
    </>
  );
};

export default PersonnelActivity;
