import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AddNewSpecialDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { PAYMENT_METHODS } from "enums";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const selectSpecialDiscountsState = (state: any) => state.SpecialDiscounts;
  const selectSpecialDiscountsProperties = createSelector(
    selectSpecialDiscountsState,
    (state) => ({
      specialDiscount: state.specialDiscount,
      specialDiscountFormErrors: state.specialDiscountFormErrors,
      addDiscountLoading: state.addDiscountLoading,
      updateDiscountLoading: state.updateDiscountLoading,
    })
  );

  const selectHotelState = (state: any) => state.Hotel;
  const selectHotelProperties = createSelector(selectHotelState, (state) => ({
    hotel: state.hotel,
  }));

  const {
    specialDiscount,
    specialDiscountFormErrors,
    addDiscountLoading,
    updateDiscountLoading,
  } = useSelector(selectSpecialDiscountsProperties);

  const { hotel } = useSelector(selectHotelProperties);

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<Option[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newRoomsOptions: Option[] = hotel.odalar.map((room: any) => ({
        value: room.id,
        label: room.oda_isim,
      }));

      const newConceptsOptions: Option[] = hotel.pansiyonlar.map(
        (concept: any) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setRoomsOptions(newRoomsOptions);
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const startReservationDate = moment(
    specialDiscount.rez_tarihi_baslangic
  ).format("YYYY-MM-DD");
  const endReservationDate = moment(specialDiscount.rez_tarihi_bitis).format(
    "YYYY-MM-DD"
  );
  const startAccommodationDate = moment(
    specialDiscount.konaklama_tarihi_baslangic
  ).format("YYYY-MM-DD");
  const endAccommodationDate = moment(
    specialDiscount.konaklama_tarihi_bitis
  ).format("YYYY-MM-DD");

  const startEntryDate = moment(
    specialDiscount.giris_gunu_baslangic_tarihi
  ).format("YYYY-MM-DD");
  const endEntryDate = moment(specialDiscount.giris_gunu_bitis_tarihi).format(
    "YYYY-MM-DD"
  );

  const addNewSpecialDiscountRequestPayload: AddNewSpecialDiscountRequestPayload =
    {
      otel_id,
      otel_oda_id: oda_id,
      grup_id: specialDiscount.grup_id,
      rez_tarihi: `${startReservationDate} - ${endReservationDate}`,
      konaklama_tarihi: `${startAccommodationDate} - ${endAccommodationDate}`,
      min_gece_sayisi: specialDiscount.min_gece_sayisi,
      ozel_oran: specialDiscount.ozel_oran,
      giris_gunu_durum: specialDiscount.giris_gunu_durum,
      giris_gunu_tarihi: `${startEntryDate} - ${endEntryDate}`,
      radio_gun: Number(specialDiscount.radio_gun),
      gun: specialDiscount.gun,
      indirim_tipi: specialDiscount.indirim_tipi,
      deskalti_durum: specialDiscount.deskalti_durum,
      maliyete_uygula: specialDiscount.maliyete_uygula,
      direkt_uygula: specialDiscount.direkt_uygula,
      eb_birlikte: specialDiscount.eb_birlikte,
      kalode_birlikte: specialDiscount.kalode_birlikte,
      diger_birlikte: specialDiscount.diger_birlikte,
      yalniz_bu_otel: specialDiscount.yalniz_bu_otel,
      secili_oteller: specialDiscount.secili_oteller,
      tum_pansiyonlar: specialDiscount.tum_pansiyonlar,
      secili_pansiyonlar: specialDiscount.secili_pansiyonlar,
      tum_odeme_tipleri: specialDiscount.tum_odeme_tipleri,
      secili_odeme_tipleri: specialDiscount.secili_odeme_tipleri,
    };

  const updateSpecialDiscountRequestPayload: AddNewSpecialDiscountRequestPayload =
    {
      id: specialDiscount.id,
      otel_id,
      otel_oda_id: oda_id,
      grup_id: specialDiscount.grup_id,
      rez_tarihi: `${startReservationDate} - ${endReservationDate}`,
      konaklama_tarihi: `${startAccommodationDate} - ${endAccommodationDate}`,
      min_gece_sayisi: specialDiscount.min_gece_sayisi,
      ozel_oran: specialDiscount.ozel_oran,
      giris_gunu_durum: specialDiscount.giris_gunu_durum,
      giris_gunu_tarihi: `${startEntryDate} - ${endEntryDate}`,
      radio_gun: Number(specialDiscount.radio_gun),
      gun: specialDiscount.gun,
      indirim_tipi: specialDiscount.indirim_tipi,
      deskalti_durum: specialDiscount.deskalti_durum,
      maliyete_uygula: specialDiscount.maliyete_uygula,
      direkt_uygula: specialDiscount.direkt_uygula,
      eb_birlikte: specialDiscount.eb_birlikte,
      kalode_birlikte: specialDiscount.kalode_birlikte,
      diger_birlikte: specialDiscount.diger_birlikte,
      yalniz_bu_otel: specialDiscount.yalniz_bu_otel,
      secili_oteller: specialDiscount.secili_oteller,
      tum_pansiyonlar: specialDiscount.tum_pansiyonlar,
      secili_pansiyonlar: specialDiscount.secili_pansiyonlar,
      tum_odeme_tipleri: specialDiscount.tum_odeme_tipleri,
      secili_odeme_tipleri: specialDiscount.secili_odeme_tipleri,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    specialDiscount,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    addDiscountLoading,
    updateDiscountLoading,
    addNewSpecialDiscountRequestPayload,
    updateSpecialDiscountRequestPayload,
    formErrors: specialDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
