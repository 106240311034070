const AvailabilitiesLegend = () => {
  return (
    <div className="d-flex align-items-center">
      <div
        className="rounded-circle theme-bg-success"
        style={{ width: "20px", height: "20px" }}
      ></div>
      <p className="m-0 ms-1 me-3 fs-12">Satışa Açık</p>
      <div
        className="rounded-circle theme-bg-warning"
        style={{ width: "20px", height: "20px" }}
      ></div>
      <p className="m-0 ms-1 me-3 fs-12">Sor. Sat</p>
      <div
        className="rounded-circle theme-bg-danger"
        style={{ width: "20px", height: "20px" }}
      ></div>
      <p className="m-0 ms-1 me-3 fs-12">Satışa Kapalı</p>
    </div>
  );
};

export default AvailabilitiesLegend;
