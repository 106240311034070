import { CustomDualListBox, CustomFormFieldContainer } from "Components/Custom/UI";
import { UpdateUserFormErrors } from "types/userOperations";

interface UserAuthorityListProps {
  formChoices: {
    value: string;
    label: string;
  }[];
  selectedOptions: string[];
  formErrors: UpdateUserFormErrors;
  handleSelectOptions: (selected: string[]) => void;
}

const UserAuthorityList = ({
  formChoices,
  selectedOptions,
  formErrors,
  handleSelectOptions,
}: UserAuthorityListProps) => {

  return (
    <>
      <CustomFormFieldContainer
        label="Kullanıcı Yetkileri "
        error={formErrors.yetkiler}
        orientation="vertical"
        required
      >
        <CustomDualListBox
          listOptions={formChoices}
          selectedOptions={selectedOptions}
          handleSelectOptions={handleSelectOptions}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UserAuthorityList;
