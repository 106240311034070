import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import CustomButton from "../CustomButton";

interface CustomExcelExportProps {
  fileData: any[];
  fileName: string;
}

const CustomExcelExport = ({ fileData, fileName }: CustomExcelExportProps) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add the title row
    const titleRow = worksheet.addRow([fileName]);
    titleRow.font = { bold: true, size: 16 };
    worksheet.mergeCells(
      "A1",
      `${String.fromCharCode(64 + Object.keys(fileData[0]).length)}1`
    );
    titleRow.alignment = { vertical: "middle", horizontal: "center" };

    // Add the header row with styling
    const headerRow = worksheet.addRow(Object.keys(fileData[0]));
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4F81BD" },
      };
      cell.alignment = { vertical: "middle", horizontal: "left" };
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    });

    // Add data rows with borders
    fileData.forEach((dataItem) => {
      const row = worksheet.addRow(Object.values(dataItem));
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "left" };
      });
    });

    // Set column widths
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    // Create a buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <div>
      <CustomButton disabled={(fileData??[]).length === 0} onClick={exportToExcel}>
        İndir (.xlsx)
      </CustomButton>
    </div>
  );
};

export default CustomExcelExport;
