import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import GetReservationPaymentTableButton from "./FormFields/GetReservationPaymentTableButton";
import UpdateCollectionTable from "./UpdateCollectionTable";
import { useState } from "react";

const ReservationPaymentForm = () => {
  const [showUpdateCollectionForm, setShowUpdateCollectionForm] =
    useState(false);

  const { firstFormState, isLoading, onGetReservation, handleFirstFormChange } =
    useHandleForm(setShowUpdateCollectionForm);

  return (
    <>
      <CustomFormContainer title="Ödeme Tablosu">
        <ReservationCodeInput
          values={firstFormState}
          handleFormChange={handleFirstFormChange}
        />
        <GetReservationPaymentTableButton
          handleSave={onGetReservation}
          values={firstFormState}
        />
        {showUpdateCollectionForm && (
          <div className="p-3">
            <CustomFormContainer title="Taksit İşlemleri">
              <span>
                İşlem Yapılan Rezervasyon No:{" "}
                <strong>{firstFormState.rez_kod}</strong>
              </span>
              <UpdateCollectionTable
                isLoading={isLoading}
                setShowUpdateForm={setShowUpdateCollectionForm}
              />
            </CustomFormContainer>
          </div>
        )}
      </CustomFormContainer>
    </>
  );
};

export default ReservationPaymentForm;
