import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveUncancellableRoomRates,
  handleSaveUncancellableRoomRate,
  setAddRateLoading,
  setAddRateError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_UNCANCELLABLE_ROOM_RATES,
  UPDATE_UNCANCELLABLE_ROOM_RATE,
} from "helpers/url_helper";

import { UncancellableRoomRateRequestPayload } from "types/DiscountsAndActions";
import { Dispatch } from "redux";

interface GetRequestPayload {
  otel_id: number;
  oda_id: number;
}

type SuccessCallback = () => void;

export const getUncancellableRoomRates =
  (payload: GetRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_UNCANCELLABLE_ROOM_RATES, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveUncancellableRoomRates(response.data || response));
      dispatch(handleSaveUncancellableRoomRate(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateUncancellableRoomRate =
  (
    payload: UncancellableRoomRateRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddRateLoading(true));

      await axios.post(UPDATE_UNCANCELLABLE_ROOM_RATE, payload);

      successCallback();
      dispatch(setToastSuccess("Oran güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddRateError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddRateLoading(false));
    }
  };
