import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Collapse,
} from "reactstrap";
import withRouter from "./withRouter";

//redux
import {
  changeSidebarTheme,
  changeLeftsidebarSizeType,
  changeSidebarImageType,
  //   changeLayoutTheme,
  changeLayoutThemeColor,
  // resetValue
} from "../../slices/thunks";

import { useSelector, useDispatch } from "react-redux";

//import Constant
import {
  //   LAYOUT_THEME,
  LAYOUT_SIDEBAR_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_IMAGE_TYPES,
  LAYOUT_THEME_COLOR,
} from "../constants/layout";

//SimpleBar
import SimpleBar from "simplebar-react";
import classnames from "classnames";

//import Images
import img01 from "../../assets/images/sidebar/img-1.jpg";
import img02 from "../../assets/images/sidebar/img-2.jpg";
import img03 from "../../assets/images/sidebar/img-3.jpg";
import img04 from "../../assets/images/sidebar/img-4.jpg";
import { createSelector } from "reselect";

const RightSidebar = (props: any) => {
  const dispatch: any = useDispatch();

  const [show, setShow] = useState<boolean>(false);

  function tog_show() {
    setShow(!show);
    dispatch(changeSidebarTheme("gradient"));
  }

  useEffect(() => {
    const sidebarColorDark = document.getElementById(
      "sidebar-color-dark"
    ) as HTMLInputElement;
    const sidebarColorLight = document.getElementById(
      "sidebar-color-light"
    ) as HTMLInputElement;

    if (show && sidebarColorDark && sidebarColorLight) {
      sidebarColorDark.checked = false;
      sidebarColorLight.checked = false;
    }
  }, [show]);

  const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutThemeType: layout.layoutThemeType,
      layoutType: layout.layoutType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      preloader: layout.preloader,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
      layoutThemeColorType: layout.layoutThemeColorType,
    })
  );
  // Inside your component
  const {
    // layoutThemeType,
    layoutType,
    leftSidebarType,
    leftsidbarSizeType,
    leftSidebarImageType,
    preloader,
    sidebarVisibilitytype,
    layoutThemeColorType,
  } = useSelector(selectLayoutProperties);

  // open offcanvas
  const [open, setOpen] = useState(false);
  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const pathName = props.router.location.pathname;

  useEffect(() => {
    const preloader = document.getElementById("preloader") as HTMLElement;

    if (preloader) {
      preloader.style.opacity = "1";
      preloader.style.visibility = "visible";

      setTimeout(function () {
        preloader.style.opacity = "0";
        preloader.style.visibility = "hidden";
      }, 1000);
    }
  }, [pathName]);

  //Sidebar User Profile Avatar
  const [sidebarAvatar, setSidebarAvatar] = useState<boolean>(false);

  useEffect(() => {
    handleChangeSidebarAvatar(sidebarAvatar);
  }, [sidebarAvatar]);

  const handleChangeSidebarAvatar = (value: boolean) => {
    setSidebarAvatar(value);

    if (value) {
      document.documentElement.setAttribute("data-sidebar-user-show", "");
    } else {
      document.documentElement.removeAttribute("data-sidebar-user-show");
    }
  };

  return (
    <React.Fragment>
      <button
        onClick={() => toTop()}
        className="btn btn-danger btn-icon"
        id="back-to-top"
      >
        <i className="ri-arrow-up-line"></i>
      </button>

      {preloader === "enable" && (
        <div id="preloader">
          <div id="status">
            <div
              className="spinner-border text-primary avatar-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <div>
        <div>
          <div
            onClick={toggleLeftCanvas}
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle d-flex align-items-center justify-content-center"
          >
            <i className="ri-paint-brush-line fs-24"></i>
          </div>
        </div>
        <Offcanvas
          isOpen={open}
          toggle={toggleLeftCanvas}
          direction="end"
          className="offcanvas-end border-0"
        >
          <OffcanvasHeader
            className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
            toggle={toggleLeftCanvas}
          >
            <span className="m-0 me-2 text-white">Tema Ayarları</span>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <SimpleBar className="h-100">
              <div className="p-4">
                <div>
                  {/* <h6 className="mt-4 mb-1 fw-semibold text-uppercase">Tema</h6>
                <p className="text-muted">Uygulama temasını seçin.</p>

                <div className="row">
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme01"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.DEFAULT}
                        checked={layoutThemeType === LAYOUT_THEME.DEFAULT}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme01"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/default.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Default
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme02"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.SAAS}
                        checked={layoutThemeType === LAYOUT_THEME.SAAS}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme02"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo//saas.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">Sass</h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme03"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.CORPORATE}
                        checked={layoutThemeType === LAYOUT_THEME.CORPORATE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme03"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo//corporate.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Corporate
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme04"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.GALAXY}
                        checked={layoutThemeType === LAYOUT_THEME.GALAXY}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme04"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo//galaxy.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">Galaxy</h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme05"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.MATERIAL}
                        checked={layoutThemeType === LAYOUT_THEME.MATERIAL}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme05"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo//material.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Material
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme06"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.CREATIVE}
                        checked={layoutThemeType === LAYOUT_THEME.CREATIVE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme06"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/creative.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Creative
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme07"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.MINIMAL}
                        checked={layoutThemeType === LAYOUT_THEME.MINIMAL}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme07"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/minimal.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Minimal
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme08"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.MODERN}
                        checked={layoutThemeType === LAYOUT_THEME.MODERN}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme08"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/modern.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">Modern</h5>
                  </div>

                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme09"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.INTERACTIVE}
                        checked={layoutThemeType === LAYOUT_THEME.INTERACTIVE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme09"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/interactive.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Interactive
                    </h5>
                  </div>

                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme10"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.CLASSIC}
                        checked={layoutThemeType === LAYOUT_THEME.CLASSIC}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme10"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/classic.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Classic
                    </h5>
                  </div>

                  <div className="col-6">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-theme11"
                        name="data-theme"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_THEME.VINTAGE}
                        checked={layoutThemeType === LAYOUT_THEME.VINTAGE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(changeLayoutTheme(e.target.value));
                          }
                        }}
                      />
                      <label
                        className="form-check-label p-0"
                        htmlFor="customizer-theme11"
                      >
                        <img
                          src="https://themesbrand.com/velzon/assets/images/demo/vintage.png"
                          alt=""
                          className="img-fluid"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center fw-medium mt-2">
                      Vintage
                    </h5>
                  </div>
                </div> */}
                </div>

                <div className="mb-3">
                  {(layoutType === "vertical" ||
                    (layoutType === "semibox" &&
                      sidebarVisibilitytype === "show")) && (
                    <div id="sidebar-size">
                      <h6 className="mt-4 mb-1 fw-semibold text-uppercase">
                        Menü Çubuğu Boyutu
                      </h6>
                      <p className="text-muted"> Menü çubuğu boyutunu seçin.</p>

                      <div className="row">
                        <div className="col-4">
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-size"
                              id="sidebar-size-default"
                              value={LEFT_SIDEBAR_SIZE_TYPES.DEFAULT}
                              checked={
                                leftsidbarSizeType ===
                                LEFT_SIDEBAR_SIZE_TYPES.DEFAULT
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeLeftsidebarSizeType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-md w-100"
                              htmlFor="sidebar-size-default"
                            >
                              <span className="d-flex gap-1 h-100">
                                <span className="flex-shrink-0">
                                  <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                    <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                  </span>
                                </span>
                                <span className="flex-grow-1">
                                  <span className="d-flex h-100 flex-column">
                                    <span className="bg-light d-block p-1"></span>
                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                          </div>
                          <h5 className="fs-13 text-center mt-2">Varsayılan</h5>
                        </div>

                        <div className="col-4">
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-size"
                              id="sidebar-size-compact"
                              value={LEFT_SIDEBAR_SIZE_TYPES.COMPACT}
                              checked={
                                leftsidbarSizeType ===
                                LEFT_SIDEBAR_SIZE_TYPES.COMPACT
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeLeftsidebarSizeType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-md w-100"
                              htmlFor="sidebar-size-compact"
                            >
                              <span className="d-flex gap-1 h-100">
                                <span className="flex-shrink-0">
                                  <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                    <span className="d-block p-1 bg-primary-subtle rounded mb-2"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                  </span>
                                </span>
                                <span className="flex-grow-1">
                                  <span className="d-flex h-100 flex-column">
                                    <span className="bg-light d-block p-1"></span>
                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                          </div>
                          <h5 className="fs-13 text-center mt-2">Kompakt</h5>
                        </div>

                        <div className="col-4">
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-size"
                              id="sidebar-size-small"
                              value={LEFT_SIDEBAR_SIZE_TYPES.SMALLICON}
                              checked={
                                leftsidbarSizeType ===
                                LEFT_SIDEBAR_SIZE_TYPES.SMALLICON
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeLeftsidebarSizeType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-md w-100"
                              htmlFor="sidebar-size-small"
                            >
                              <span className="d-flex gap-1 h-100">
                                <span className="flex-shrink-0">
                                  <span className="bg-light d-flex h-100 flex-column gap-1">
                                    <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                  </span>
                                </span>
                                <span className="flex-grow-1">
                                  <span className="d-flex h-100 flex-column">
                                    <span className="bg-light d-block p-1"></span>
                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                          </div>
                          <h5 className="fs-13 text-center mt-2">
                            Küçük (Simge Görünümü)
                          </h5>
                        </div>

                        <div className="col-4">
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-size"
                              id="sidebar-size-small-hover"
                              value={LEFT_SIDEBAR_SIZE_TYPES.SMALLHOVER}
                              checked={
                                leftsidbarSizeType ===
                                LEFT_SIDEBAR_SIZE_TYPES.SMALLHOVER
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeLeftsidebarSizeType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-md w-100"
                              htmlFor="sidebar-size-small-hover"
                            >
                              <span className="d-flex gap-1 h-100">
                                <span className="flex-shrink-0">
                                  <span className="bg-light d-flex h-100 flex-column gap-1">
                                    <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                  </span>
                                </span>
                                <span className="flex-grow-1">
                                  <span className="d-flex h-100 flex-column">
                                    <span className="bg-light d-block p-1"></span>
                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                          </div>
                          <h5 className="fs-13 text-center mt-2">
                            Küçük (Üzerine Gelince Büyüyen)
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  {(layoutType === "vertical" ||
                    layoutType === "twocolumn" ||
                    (layoutType === "semibox" &&
                      sidebarVisibilitytype === "show")) && (
                    <>
                      <div id="sidebar-color">
                        <h6 className="mt-4 mb-1 fw-semibold text-uppercase">
                          Menü Rengi
                        </h6>
                        <p className="text-muted">Menü çubuğu rengini seçin.</p>

                        <div className="row mb-3">
                          <div className="col-4">
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-light"
                                value={LAYOUT_SIDEBAR_TYPES.LIGHT}
                                checked={
                                  leftSidebarType === LAYOUT_SIDEBAR_TYPES.LIGHT
                                }
                                onChange={(e) => {
                                  setShow(false);
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-md w-100"
                                htmlFor="sidebar-color-light"
                              >
                                <span className="d-flex gap-1 h-100">
                                  <span className="flex-shrink-0">
                                    <span className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                      <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                  </span>
                                  <span className="flex-grow-1">
                                    <span className="d-flex h-100 flex-column">
                                      <span className="bg-light d-block p-1"></span>
                                      <span className="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                            <h5 className="fs-13 text-center mt-2">Açık</h5>
                          </div>
                          <div className="col-4">
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-dark"
                                value={LAYOUT_SIDEBAR_TYPES.DARK}
                                checked={
                                  leftSidebarType === LAYOUT_SIDEBAR_TYPES.DARK
                                }
                                onChange={(e) => {
                                  setShow(false);
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-md w-100"
                                htmlFor="sidebar-color-dark"
                              >
                                <span className="d-flex gap-1 h-100">
                                  <span className="flex-shrink-0">
                                    <span className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                      <span className="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                      <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                    </span>
                                  </span>
                                  <span className="flex-grow-1">
                                    <span className="d-flex h-100 flex-column">
                                      <span className="bg-light d-block p-1"></span>
                                      <span className="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                            <h5 className="fs-13 text-center mt-2">Koyu</h5>
                          </div>

                          <div className="col-4">
                            <button
                              className={classnames(
                                "btn btn-link avatar-md w-100 p-0 overflow-hidden border ",
                                { collapsed: !show, active: show === true }
                              )}
                              type="button"
                              data-bs-target="#collapseBgGradient"
                              data-bs-toggle="collapse"
                              aria-controls="collapseBgGradient"
                              onClick={tog_show}
                            >
                              <span className="d-flex gap-1 h-100">
                                <span className="flex-shrink-0">
                                  <span className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                    <span className="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                    <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                                  </span>
                                </span>
                                <span className="flex-grow-1">
                                  <span className="d-flex h-100 flex-column">
                                    <span className="bg-light d-block p-1"></span>
                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                  </span>
                                </span>
                              </span>
                            </button>
                            <h5 className="fs-13 text-center mt-2">Renkli</h5>
                          </div>
                        </div>
                        <Collapse
                          isOpen={show}
                          className="collapse"
                          id="collapseBgGradient"
                        >
                          <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-gradient"
                                value={LAYOUT_SIDEBAR_TYPES.GRADIENT}
                                checked={
                                  leftSidebarType ===
                                  LAYOUT_SIDEBAR_TYPES.GRADIENT
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-xs rounded-circle"
                                htmlFor="sidebar-color-gradient"
                              >
                                <span className="avatar-title rounded-circle bg-vertical-gradient"></span>
                              </label>
                            </div>
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-gradient-2"
                                value={LAYOUT_SIDEBAR_TYPES.GRADIENT_2}
                                checked={
                                  leftSidebarType ===
                                  LAYOUT_SIDEBAR_TYPES.GRADIENT_2
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-xs rounded-circle"
                                htmlFor="sidebar-color-gradient-2"
                              >
                                <span className="avatar-title rounded-circle bg-vertical-gradient-2"></span>
                              </label>
                            </div>
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-gradient-3"
                                value={LAYOUT_SIDEBAR_TYPES.GRADIENT_3}
                                checked={
                                  leftSidebarType ===
                                  LAYOUT_SIDEBAR_TYPES.GRADIENT_3
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-xs rounded-circle"
                                htmlFor="sidebar-color-gradient-3"
                              >
                                <span className="avatar-title rounded-circle bg-vertical-gradient-3"></span>
                              </label>
                            </div>
                            <div className="form-check sidebar-setting card-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="data-sidebar"
                                id="sidebar-color-gradient-4"
                                value={LAYOUT_SIDEBAR_TYPES.GRADIENT_4}
                                checked={
                                  leftSidebarType ===
                                  LAYOUT_SIDEBAR_TYPES.GRADIENT_4
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(
                                      changeSidebarTheme(e.target.value)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="form-check-label p-0 avatar-xs rounded-circle"
                                htmlFor="sidebar-color-gradient-4"
                              >
                                <span className="avatar-title rounded-circle bg-vertical-gradient-4"></span>
                              </label>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div id="sidebar-img">
                        <h6 className="mt-4 mb-1 fw-semibold text-uppercase">
                          Menü Çubuğu Arka Planı
                        </h6>
                        <p className="text-muted">
                          Menü çubuğu arka planını seçin.
                        </p>

                        <div className="d-flex gap-2 flex-wrap img-switch">
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-image"
                              id="sidebarimg-none"
                              value={LEFT_SIDEBAR_IMAGE_TYPES.NONE}
                              checked={
                                leftSidebarImageType ===
                                LEFT_SIDEBAR_IMAGE_TYPES.NONE
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeSidebarImageType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-sm h-auto"
                              htmlFor="sidebarimg-none"
                            >
                              <span className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                <i className="ri-close-fill fs-20"></i>
                              </span>
                            </label>
                          </div>

                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-image"
                              id="sidebarimg-01"
                              value={LEFT_SIDEBAR_IMAGE_TYPES.IMG1}
                              checked={
                                leftSidebarImageType ===
                                LEFT_SIDEBAR_IMAGE_TYPES.IMG1
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeSidebarImageType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-sm h-auto"
                              htmlFor="sidebarimg-01"
                            >
                              <img
                                src={img01}
                                alt=""
                                className="avatar-md w-auto object-fit-cover"
                              />
                            </label>
                          </div>

                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-image"
                              id="sidebarimg-02"
                              value={LEFT_SIDEBAR_IMAGE_TYPES.IMG2}
                              checked={
                                leftSidebarImageType ===
                                LEFT_SIDEBAR_IMAGE_TYPES.IMG2
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeSidebarImageType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-sm h-auto"
                              htmlFor="sidebarimg-02"
                            >
                              <img
                                src={img02}
                                alt=""
                                className="avatar-md w-auto object-fit-cover"
                              />
                            </label>
                          </div>
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-image"
                              id="sidebarimg-03"
                              value={LEFT_SIDEBAR_IMAGE_TYPES.IMG3}
                              checked={
                                leftSidebarImageType ===
                                LEFT_SIDEBAR_IMAGE_TYPES.IMG3
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeSidebarImageType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-sm h-auto"
                              htmlFor="sidebarimg-03"
                            >
                              <img
                                src={img03}
                                alt=""
                                className="avatar-md w-auto object-fit-cover"
                              />
                            </label>
                          </div>
                          <div className="form-check sidebar-setting card-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="data-sidebar-image"
                              id="sidebarimg-04"
                              value={LEFT_SIDEBAR_IMAGE_TYPES.IMG4}
                              checked={
                                leftSidebarImageType ===
                                LEFT_SIDEBAR_IMAGE_TYPES.IMG4
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch(
                                    changeSidebarImageType(e.target.value)
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-check-label p-0 avatar-sm h-auto"
                              htmlFor="sidebarimg-04"
                            >
                              <img
                                src={img04}
                                alt=""
                                className="avatar-md w-auto object-fit-cover"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="mb-3">
                  <div id="sidebar-color">
                    <h6 className="mt-4 mb-1 fw-semibold text-uppercase">
                      Tema Rengi
                    </h6>
                    <p className="text-muted">Tema renginizi seçin.</p>
                    <div className="d-flex flex-wrap gap-2">
                      <div className="form-check sidebar-setting card-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="data-theme-colors"
                          id="themeColor-01"
                          value={LAYOUT_THEME_COLOR.DEFAULT}
                          checked={
                            layoutThemeColorType === LAYOUT_THEME_COLOR.DEFAULT
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(changeLayoutThemeColor(e.target.value));
                            }
                          }}
                        />
                        <label
                          className="form-check-label avatar-xs p-0"
                          htmlFor="themeColor-01"
                        ></label>
                      </div>
                      <div className="form-check sidebar-setting card-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="data-theme-colors"
                          id="themeColor-02"
                          value={LAYOUT_THEME_COLOR.GREEN}
                          checked={
                            layoutThemeColorType === LAYOUT_THEME_COLOR.GREEN
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(changeLayoutThemeColor(e.target.value));
                            }
                          }}
                        />
                        <label
                          className="form-check-label avatar-xs p-0"
                          htmlFor="themeColor-02"
                        ></label>
                      </div>
                      <div className="form-check sidebar-setting card-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="data-theme-colors"
                          id="themeColor-03"
                          value={LAYOUT_THEME_COLOR.PURPLE}
                          checked={
                            layoutThemeColorType === LAYOUT_THEME_COLOR.PURPLE
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(changeLayoutThemeColor(e.target.value));
                            }
                          }}
                        />
                        <label
                          className="form-check-label avatar-xs p-0"
                          htmlFor="themeColor-03"
                        ></label>
                      </div>
                      <div className="form-check sidebar-setting card-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="data-theme-colors"
                          id="themeColor-04"
                          value={LAYOUT_THEME_COLOR.BLUE}
                          checked={
                            layoutThemeColorType === LAYOUT_THEME_COLOR.BLUE
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(changeLayoutThemeColor(e.target.value));
                            }
                          }}
                        />
                        <label
                          className="form-check-label avatar-xs p-0"
                          htmlFor="themeColor-04"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RightSidebar);
