import React from "react";
import { Row } from "reactstrap";
import { PaginationBottom as PaginationBottomProps } from "types/GlobalTypes";
import { PaginationBottom } from "../CustomPaginationComponents";

interface CustomTableBottomProps {
  numberOfTotalRows: number;
  numberOfPageRows: number;
  data: any;
  getState: any;
  getCanPreviousPage: any;
  previousPage: any;
  getPageOptions: any;
  setPageIndex: any;
  getCanNextPage: any;
  nextPage: any;
  pagination?: boolean;
  paginationBottomProps?: PaginationBottomProps;
  loading?: boolean;
}

const CustomTableBottom = ({
  numberOfTotalRows,
  numberOfPageRows,
  data,
  getState,
  getCanPreviousPage,
  previousPage,
  getPageOptions,
  setPageIndex,
  getCanNextPage,
  nextPage,
  pagination = false,
  paginationBottomProps = {
    total: 0,
    current_page: 0,
    per_page: 0,
    prev_page_url: "",
    next_page_url: "",
    onClickNextPage: () => {},
    onClickPrevPage: () => {},
  },
  loading = false,
}: CustomTableBottomProps) => {
  const showPaginationButtons = getCanPreviousPage() || getCanNextPage();

  const pageOptions = getPageOptions();

  const { pageIndex } = getState().pagination;

  const pageStart =
    getState().pagination.pageIndex * getState().pagination.pageSize + 1;

  const pageEnd =
    getState().pagination.pageIndex * getState().pagination.pageSize +
      getState().pagination.pageSize >
    data.length
      ? data.length
      : getState().pagination.pageIndex * getState().pagination.pageSize +
        getState().pagination.pageSize;

  return (
    <>
      {pagination ? (
        <PaginationBottom {...paginationBottomProps} loading={loading} />
      ) : (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            {data.length > 0 && (
              <div className="text-muted">
                {showPaginationButtons && (
                  <>
                    <span className="fw-semibold ms-1">
                      {pageStart}-
                      {pageEnd > numberOfPageRows && pageIndex === 0
                        ? numberOfPageRows
                        : numberOfTotalRows < pageEnd
                        ? numberOfTotalRows
                        : pageEnd}
                    </span>{" "}
                    {" / "}
                  </>
                )}
                <span className="fw-semibold">
                  {data.length < numberOfTotalRows
                    ? data.length
                    : numberOfTotalRows}
                </span>{" "}
                kayıt gösteriliyor
              </div>
            )}
          </div>
          {showPaginationButtons ? (
            <div className="col-sm-auto">
              <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 flex-wrap gap-1 row-gap-2">
                <li className="page-item">
                  <span
                    className="page-link text-white cursor-pointer"
                    style={{
                      backgroundColor: "var(--theme-primary)",
                      opacity: !getCanPreviousPage() ? 0.7 : 1,
                    }}
                    onClick={getCanPreviousPage() ? previousPage : () => {}}
                  >
                    Önceki
                  </span>
                </li>
                {pageOptions.map((item: any, key: number) => (
                  <React.Fragment key={key}>
                    <li className="page-item">
                      <div
                        className="page-link"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            getState().pagination.pageIndex === item
                              ? "var(--theme-primary)"
                              : "",
                          color:
                            getState().pagination.pageIndex === item
                              ? "white"
                              : "darkslategray",
                        }}
                        onClick={() => setPageIndex(item)}
                      >
                        {item + 1}
                      </div>
                    </li>
                  </React.Fragment>
                ))}
                <li className="page-item">
                  <span
                    className="page-link text-white cursor-pointer"
                    style={{
                      backgroundColor: "var(--theme-primary)",
                      opacity: !getCanNextPage() ? 0.7 : 1,
                    }}
                    onClick={getCanNextPage() ? nextPage : () => {}}
                  >
                    Sonraki
                  </span>
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
        </Row>
      )}
    </>
  );
};

export default CustomTableBottom;
