import { useDispatch } from "react-redux";
import styles from "./CustomInfiniteScroll.module.scss";

import { Spinner } from "reactstrap";
import { Dispatch } from "redux";
import { Pagination } from "types/GlobalTypes";

interface GetParams {
  page: number;
  per_page: number;
  search: string;
}

interface CustomInfiniteScrollBoxProps {
  requestFunction: (params: GetParams) => void;
  per_page?: number;
  search?: string;
  pagination: Pagination;
  loading: boolean;
  children: React.ReactNode;
}

const CustomInfiniteScrollBox = ({
  requestFunction = () => {},
  per_page = 100,
  search = "",
  pagination,
  loading,
  children,
}: CustomInfiniteScrollBoxProps) => {
  const { last_page, current_page } = pagination;

  const dispatch: Dispatch<any> = useDispatch();

  const handleLoadMore = () => {
    last_page > current_page &&
      dispatch(
        requestFunction({
          page: current_page + 1,
          per_page: per_page,
          search: search,
        })
      );
  };

  const endOfPages = last_page === current_page;

  return (
    <div className="w-100">
      {children}
      <div className={styles.container}>
        {!endOfPages ? (
          <div className="btn btn-primary w-100" onClick={handleLoadMore}>
            {loading ? (
              <Spinner color="white" size="sm" />
            ) : (
              "Daha fazla göster"
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CustomInfiniteScrollBox;
