import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveOutboundCallsWithNumber,
  setIsLoading,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_OUTBOUND_CALLS_WITH_NUMBER } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetOutboundCallsWithNumberParams {
  start_date: string;
  end_date: string;
  dahili: number;
}

export const getOutboundCallsWithNumber =
  (params: GetOutboundCallsWithNumberParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_OUTBOUND_CALLS_WITH_NUMBER, {
        params,
      });

      dispatch(handleSaveOutboundCallsWithNumber(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
