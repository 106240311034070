import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddHourlyDiscountFormErrors } from "slices/discountsAndActions/hourlyDiscounts/reducer";

import { setToastError } from "slices/toast/reducer";

import {
  HourlyDiscountFormErrors,
  HourlyDiscountState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: HourlyDiscountState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: HourlyDiscountFormErrors = {};

    if (!values.gun) {
      errors.gun = "Gün alanı boş bırakılamaz.";
    }

    if (!values.baslangic_saati) {
      errors.indirim_saat_araligi = "Başlangıç saati alanı boş bırakılamaz.";
    }

    if (!values.bitis_saati) {
      errors.indirim_saat_araligi = "Bitiş saati alanı boş bırakılamaz.";
    }

    if (values.baslangic_saati && values.bitis_saati) {
      const startTime = values.baslangic_saati.split(":");
      const endTime = values.bitis_saati.split(":");

      if (parseInt(startTime[0]) > parseInt(endTime[0])) {
        errors.indirim_saat_araligi =
          "Başlangıç saati bitiş saatinden büyük olamaz.";
      }
    }

    if (!values.indirim_orani) {
      errors.indirim_orani = "İndirim oranı alanı boş bırakılamaz.";
    }

    if (!values.tum_pansiyonlar && values.secili_pansiyonlar.length === 0) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }

    if (!values.tum_odeme_tipleri && values.secili_odeme_tipleri.length === 0) {
      errors.secili_odeme_tipleri = "En az bir ödeme tipi seçmelisiniz.";
    }

    dispatch(handleAddHourlyDiscountFormErrors(errors));
    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: HourlyDiscountState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};
export default useValidateForm;
