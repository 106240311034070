import { useState } from "react";
import { Collapse } from "reactstrap";

interface PaymentItemProps {
  title: string;
  children: React.ReactNode;
}

const PaymentItem = ({ title, children }: PaymentItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div className="d-flex flex-column gap-4 border rounded bg-white">
      <div
        className="d-flex align-items-center gap-2 p-3 rounded bg-dark bg-opacity-10"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <span className="fw-bold fs-18">{title}</span>
      </div>
      <Collapse className="p-3" isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

export default PaymentItem;
