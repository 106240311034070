import { createSlice } from "@reduxjs/toolkit";
import {
  CommercialTableCardInitialState,
  CompetitorAnalysisCardInitialState,
  DashboardData,
  DashboardResponse,
} from "types/dashboard";

export interface DashboardInitialState {
  dashboardData: DashboardData;
  isLoading: boolean;
  error: string;
  // facilityAvailabilities: CompetitorAnalysisFacilityAvailability[];
  availabilityLoading: boolean;
  availabilityError: string;
  updateDescriptionLoading: boolean;
  updateDescriptionError: string;
  updateHotelInfoLoading: boolean;
  updateHotelInfoError: string;
  updateAdsStatusLoading: boolean;
  updateAdsStatusError: string;
}

export const dashboardInitialState: DashboardInitialState = {
  dashboardData: {
    hotelInfoCard: {
      otel_adi: "",
      otel_durumu: "",
      fiyat_saglayici: "",
      gunluk_satislar: "",
      gunluk_tutar: 0,
      gunluk_oda: 0,
      gunluk_iptal_tutar: 0,
    },
    competitorAnalysisCard: {
      otel_id: 0,
      otel_adi: "",
      telefon: "",
      komisyon_orani: "",
      reklam_yeni: "",
      reklam_grup_id: "",
      reklam_aciklama: "",
      kapida_durum: "",
      otel_odasayisi: 0,
      id: 0,
      tarih_1: "",
      gece_1: 0,
      tarih_2: "",
      gece_2: 0,
      tarih_3: "",
      gece_3: 0,
      otelfiyat_fiyat_1: "",
      otelfiyat_fiyat_2: "",
      otelfiyat_fiyat_3: "",
      jolly_fiyat_1: "",
      jolly_fiyat_2: "",
      jolly_fiyat_3: "",
      hotels_fiyat_1: "",
      hotels_fiyat_2: "",
      hotels_fiyat_3: "",
      otelz_fiyat_1: "",
      otelz_fiyat_2: "",
      otelz_fiyat_3: "",
      etstur_fiyat_1: "",
      etstur_fiyat_2: "",
      etstur_fiyat_3: "",
      odamax_fiyat_1: "",
      odamax_fiyat_2: "",
      odamax_fiyat_3: "",
      aciklama: "",
      yetkili: {
        uye_id: 0,
        ad_soyad: "",
      },
      otelfiyat_url_1: "",
      odamax_url_1: "",
      hotels_url_1: "",
      otelz_url_1: "",
      etstur_url_1: "",
      otelfiyat_url_2: "",
      odamax_url_2: "",
      hotels_url_2: "",
      otelz_url_2: "",
      etstur_url_2: "",
      otelfiyat_url_3: "",
      odamax_url_3: "",
      hotels_url_3: "",
      otelz_url_3: "",
      etstur_url_3: "",
    },
    commercialTableCard: [],
    totalSalesAmountsCard: {
      son_bir_yil: {
        toplam_satis_tutari: 0,
        toplam_oda_sayisi: 0,
      },
      tum_zamanlar: {
        toplam_satis_tutari: 0,
        toplam_oda_sayisi: 0,
      },
    },
    lastThirtyDayRoomNumbersCard: [],
    totalRoomAmount: 0,
    lastThirtyDayDailySalesCard: [],
    totalSalesAmount: 0,
    paymentAtCheckInCard: {},
    facilityReservationOfficial: {
      yetkili_adi: "",
      telefon: "",
      mail: "",
    },
    regionalOperationOfficial: {
      id: 0,
      ad_soyad: "",
      profil_cep_telefon: "",
      email_adresi: "",
      profil_adres: "",
    },
    usuableParaPuan: {
      para_puan_miktari: 0,
    },
    commercialWarning: {
      message: "",
    },
    priceWarning: {
      message: "",
    },
    provinceRankingInfo: {
      location: "",
      ranking: 0,
      down_or_high: "down",
      click_rate: 0,
    },
    districtRankingInfo: {
      location: "",
      ranking: 0,
      down_or_high: "down",
      click_rate: 0,
    },
    provinceRankingGraph: {
      location: "",
      sales_of_province_hotels: 0,
      sales_of_the_hotel: 0,
    },
    districtRankingGraph: {
      location: "",
      sales_of_district_hotels: 0,
      sales_of_the_hotel: 0,
    },
    collectionSituation: {
      collection_situation: "",
    },
    priceProvider: {
      provider_name: "",
    },
    comments: [],
  },
  isLoading: false,
  error: "",
  // facilityAvailabilities: [],
  availabilityLoading: false,
  availabilityError: "",
  updateDescriptionLoading: false,
  updateDescriptionError: "",
  updateHotelInfoLoading: false,
  updateHotelInfoError: "",
  updateAdsStatusLoading: false,
  updateAdsStatusError: "",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    handleSaveDashboard(state, action) {
      const payload = action.payload as DashboardResponse;

      const hotel_info = payload.otel_bilgisi;
      const hotel_info_title = Array.isArray(payload.baslik) && payload.baslik.length > 0
        ? payload?.baslik[0]
        : { otel_adi: "", otel_durumu: "", gunluk_satislar: "" };
      const competitor_analysis = Array.isArray(payload.otel_analiz) && payload.otel_analiz.length > 0
        ? payload.otel_analiz[0]
        : CompetitorAnalysisCardInitialState;
      const commercial_info = payload.reklam_bilgileri;
      const commercial_list = Array.isArray(payload.reklam_bilgileri?.liste) && payload.reklam_bilgileri.liste.length > 0
        ? payload.reklam_bilgileri.liste[0]
        : CommercialTableCardInitialState;
      const room_amount = payload.oda_sayisi.gunluk_oda_sayisi.map((item) => {
        const key = Object.keys(item)[0];
        return {
          name: key,
          value: item[key],
        };
      });
      const sales_amount = payload.satis_tutari.gunluk_tutarlar.map((item) => {
        const key = Object.keys(item)[0];
        return {
          name: key,
          value: item[key],
        };
      });
      const cards = payload.cardlar;
      const facility_official = Array.isArray( cards.tesis_yetkilisi) && cards.tesis_yetkilisi.length > 0
        ? cards.tesis_yetkilisi[0]
        : { yetkili_adi: "", telefon: "", mail: "" };
      const operation_official = Array.isArray(cards.operasyon_bolge_sorumlusu) && cards.operasyon_bolge_sorumlusu.length > 0
        ? cards.operasyon_bolge_sorumlusu[0]
        : {
            id: 0,
            ad_soyad: "",
            profil_cep_telefon: "",
            email_adresi: "",
            profil_adres: "",
          };
      const parapuan = Number(cards.parapuan);
      const province_ranking = cards.il_otelleri_siralamasi;
      const district_ranking = cards.ilce_otelleri_siralamasi;
      const province_sales = cards.ilceotelleriodasayilari;
      const district_sales = cards.ilotelleriodasayilari;
      const guest_comments = cards.yorumlar.map((comment) => ({
        comment_id: comment.yorum_id,
        rate: Math.floor
        (comment.puan),
        commenter_name: comment.musteri_adi,
        accommodation_dates: `${comment.giris_tarihi} - ${comment.cikis_tarihi}`,
        positive_comment: comment.yorum_olumlu,
        negative_comment: comment.yorum_olumsuz,
      }))

      const dashboard: DashboardData = {
        hotelInfoCard: {
          otel_adi: hotel_info.otel_adi,
          otel_durumu: hotel_info_title.otel_durumu,
          fiyat_saglayici: hotel_info.fiyat_saglayici,
          gunluk_satislar: hotel_info_title.gunluk_satislar,
          gunluk_tutar: hotel_info.gunluk_tutar,
          gunluk_oda: hotel_info.gunluk_oda,
          gunluk_iptal_tutar: hotel_info.gunluk_iptal_tutar,
        },
        competitorAnalysisCard: {
          otel_id: competitor_analysis.otel_id,
          otel_adi: competitor_analysis.otel_adi,
          telefon: competitor_analysis.telefon,
          komisyon_orani: competitor_analysis.komisyon_orani,
          reklam_yeni: competitor_analysis.reklam_yeni,
          reklam_grup_id: competitor_analysis.reklam_grup_id,
          reklam_aciklama: competitor_analysis.reklam_aciklama,
          kapida_durum: competitor_analysis.kapida_durum,
          otel_odasayisi: competitor_analysis.otel_odasayisi,
          id: competitor_analysis.id,
          tarih_1: competitor_analysis.tarih_1,
          gece_1: competitor_analysis.gece_1,
          tarih_2: competitor_analysis.tarih_2,
          gece_2: competitor_analysis.gece_2,
          tarih_3: competitor_analysis.tarih_3,
          gece_3: competitor_analysis.gece_3,
          otelfiyat_fiyat_1: competitor_analysis.otelfiyat_fiyat_1,
          otelfiyat_fiyat_2: competitor_analysis.otelfiyat_fiyat_2,
          otelfiyat_fiyat_3: competitor_analysis.otelfiyat_fiyat_3,
          jolly_fiyat_1: competitor_analysis.jolly_fiyat_1,
          jolly_fiyat_2: competitor_analysis.jolly_fiyat_2,
          jolly_fiyat_3: competitor_analysis.jolly_fiyat_3,
          hotels_fiyat_1: competitor_analysis.hotels_fiyat_1,
          hotels_fiyat_2: competitor_analysis.hotels_fiyat_2,
          hotels_fiyat_3: competitor_analysis.hotels_fiyat_3,
          otelz_fiyat_1: competitor_analysis.otelz_fiyat_1,
          otelz_fiyat_2: competitor_analysis.otelz_fiyat_2,
          otelz_fiyat_3: competitor_analysis.otelz_fiyat_3,
          etstur_fiyat_1: competitor_analysis.etstur_fiyat_1,
          etstur_fiyat_2: competitor_analysis.etstur_fiyat_2,
          etstur_fiyat_3: competitor_analysis.etstur_fiyat_3,
          odamax_fiyat_1: competitor_analysis.odamax_fiyat_1,
          odamax_fiyat_2: competitor_analysis.odamax_fiyat_2,
          odamax_fiyat_3: competitor_analysis.odamax_fiyat_3,
          aciklama: competitor_analysis.aciklama,
          yetkili: competitor_analysis.yetkili,
          otelfiyat_url_1: competitor_analysis.otelfiyat_url_1,
          odamax_url_1: competitor_analysis.odamax_url_1,
          hotels_url_1: competitor_analysis.hotels_url_1,
          otelz_url_1: competitor_analysis.otelz_url_1,
          etstur_url_1: competitor_analysis.etstur_url_1,
          otelfiyat_url_2: competitor_analysis.otelfiyat_url_2,
          odamax_url_2: competitor_analysis.odamax_url_2,
          hotels_url_2: competitor_analysis.hotels_url_2,
          otelz_url_2: competitor_analysis.otelz_url_2,
          etstur_url_2: competitor_analysis.etstur_url_2,
          otelfiyat_url_3: competitor_analysis.otelfiyat_url_3,
          odamax_url_3: competitor_analysis.odamax_url_3,
          hotels_url_3: competitor_analysis.hotels_url_3,
          otelz_url_3: competitor_analysis.otelz_url_3,
          etstur_url_3: competitor_analysis.etstur_url_3,
        },
        commercialTableCard: [{
          satir_rengi: {
            yesil: commercial_info.yesil,
            kirmizi: commercial_info.kirmizi,
            mavi: commercial_info.mavi,
          },
          reklam_grup_id: commercial_list.reklam_grup_id,
          otel_adi: commercial_list.otel_adi,
          sehir: commercial_list.sehir,
          ilce: commercial_list.ilce,
          tedarikci_metni: commercial_list.tedarikci_metni,
          otel_danismani: commercial_list.otel_danismani.ad_soyad,
          satis: commercial_list.satis,
          min_satis: commercial_list.min_satis,
          maliyet: commercial_list.maliyet,
          etkilesim: commercial_list.etkilesim,
          tiklama: commercial_list.tiklama,
          absTopImpPerc: commercial_list.absTopImpPerc,
          eski_tbm: commercial_list.eski_tbm,
          yeni_tbm: commercial_list.yeni_tbm,
        }],
        totalSalesAmountsCard: {
          son_bir_yil: payload.Toplam_satis_tutarlari.son_bir_yil,
          tum_zamanlar: payload.Toplam_satis_tutarlari.tum_zamanlar,
        },
        lastThirtyDayRoomNumbersCard: room_amount,
        totalRoomAmount: payload.oda_sayisi.aylik_oda_sayisi,
        lastThirtyDayDailySalesCard: sales_amount,
        totalSalesAmount: payload.satis_tutari.bir_aylik_tutar,
        paymentAtCheckInCard: {}, // !!!!!!!!!!! NO BACKEND RESPONSE YET
        facilityReservationOfficial: {
          yetkili_adi: facility_official.yetkili_adi,
          telefon: facility_official.telefon,
          mail: facility_official.mail,
        },
        regionalOperationOfficial: {
          id: operation_official.id,
          ad_soyad: operation_official.ad_soyad,
          profil_cep_telefon: operation_official.profil_cep_telefon,
          email_adresi: operation_official.email_adresi,
          profil_adres: operation_official.profil_adres,
        },
        usuableParaPuan: {
          para_puan_miktari: parapuan,
        },
        commercialWarning: {
          message: payload.Reklam_Bilgilendirme,
        },
        priceWarning: {
          message: payload.Fiyat_bilgilendirme,
        },
        provinceRankingInfo: {
          location: cards.lokasyonlar.il.adi,
          ranking: province_ranking.sira,
          down_or_high:
            province_ranking.yeni_sira > province_ranking.eski_sira
              ? "high"
              : province_ranking.yeni_sira < province_ranking.eski_sira
              ? "down"
              : "neutral",
          click_rate: province_ranking.tiklanma,
        },
        districtRankingInfo: {
          location: cards.lokasyonlar.ilce.adi,
          ranking: district_ranking.sira,
          down_or_high:
            district_ranking.yeni_sira > district_ranking.eski_sira
              ? "high"
              : district_ranking.yeni_sira < district_ranking.eski_sira
              ? "down"
              : "neutral",
          click_rate: district_ranking.tiklanma,
        },
        provinceRankingGraph: {
          location: cards.lokasyonlar.il.adi,
          sales_of_province_hotels: province_sales.bolge_rez_sayisi,
          sales_of_the_hotel: province_sales.otel_rez_sayisi,
        },
        districtRankingGraph: {
          location: cards.lokasyonlar.ilce.adi,
          sales_of_district_hotels: district_sales.bolge_rez_sayisi,
          sales_of_the_hotel: district_sales.otel_rez_sayisi,
        },
        collectionSituation: {
          collection_situation:
            hotel_info.fiyat_saglayici.charAt(0).toUpperCase() +
            hotel_info.fiyat_saglayici.slice(1),
        },
        priceProvider: {
          provider_name:
            hotel_info.kapida_odeme === "evet" &&
            hotel_info.kapida_odeme_durum === "0"
              ? "Tahsilat Problemi Var"
              : "Tahsilat Problemi Yok",
        },
        comments: guest_comments,
      };
      state.dashboardData = dashboard;
      // console.log("dashboard : ", state.dashboardData)
      // console.log("payload : ", payload)
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    handleSaveFacilityAvailabilities(state, action) {
      // state.facilityAvailabilities = action.payload;
    },
    handleUpdateHotelInfo(state, action) {
      // const payload = action.payload;

      // const index = state.dashboardData.findIndex(
      //   (hotel) => hotel.id === payload.id
      // );

      // const prevData = [...state.competitorAnalysisResponse.data];

      // prevData.splice(index, 1, payload);

      // state.dashboardData = prevData;
    },
    resetFacilityAvailabilities(state) {
      // state.facilityAvailabilities = [];
    },
    setFacilityAvailabilityLoading(state, action) {
      state.availabilityLoading = action.payload;
    },
    setFacilityAvailabilityError(state, action) {
      state.availabilityError = action.payload;
    },
    setUpdateDescriptionLoading(state, action) {
      state.updateDescriptionLoading = action.payload;
    },
    setUpdateDescriptionError(state, action) {
      state.updateDescriptionError = action.payload;
    },
    setUpdateHotelInfoLoading(state, action) {
      state.updateHotelInfoLoading = action.payload;
    },
    setUpdateHotelInfoError(state, action) {
      state.updateHotelInfoError = action.payload;
    },
    setUpdateAdsStatusLoading(state, action) {
      state.updateAdsStatusLoading = action.payload;
    },
    setUpdateAdsStatusError(state, action) {
      state.updateAdsStatusError = action.payload;
    },
  },
});

export const {
  handleSaveDashboard,
  setIsLoading,
  setError,
  handleSaveFacilityAvailabilities,
  handleUpdateHotelInfo,
  resetFacilityAvailabilities,
  setFacilityAvailabilityLoading,
  setFacilityAvailabilityError,
  setUpdateDescriptionLoading,
  setUpdateDescriptionError,
  setUpdateHotelInfoLoading,
  setUpdateHotelInfoError,
  setUpdateAdsStatusLoading,
  setUpdateAdsStatusError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
