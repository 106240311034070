import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/uncancellableRoomRates/reducer";
import {
  UncancellableRoomRateState,
  UncancellableRoomRateFormErrors,
} from "types/DiscountsAndActions";

interface DiscountRateProps {
  values: UncancellableRoomRateState;
  formErrors: UncancellableRoomRateFormErrors;
  validateFormChange: (values: UncancellableRoomRateState) => void;
}

const DiscountRate = ({
  values,
  formErrors,
  validateFormChange,
}: DiscountRateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İndirim Oranı"
        error={formErrors.indirim_orani}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.indirim_orani}
            onChange={(e) => {
              dispatch(handleChangeInput({ indirim_orani: e.target.value }));
              validateFormChange({
                ...values,
                indirim_orani: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountRate;
