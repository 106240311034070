import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import useHandleList from "./useHandleList";
import {
  AssignHotelToUserFormErrors,
  AssignHotelToUserPayload,
} from "types/userOperations";
import MainAuthorityOptions from "./MainAuthorityOptions";

interface UnassignedHotelsListProps {
  formErrors: AssignHotelToUserFormErrors;
  validateFormChange: (values: AssignHotelToUserPayload) => void;
}

const UnassignedHotelsList = ({
  formErrors,
  validateFormChange,
}: UnassignedHotelsListProps) => {
  const {
    hotelOptions,
    selectedOptions,
    handleSelectOptions,
  } = useHandleList();

  return (
    <div style={{ margin: "16px 0" }}>
      <CustomFormFieldContainer
        label={"Oteller : "}
        error={formErrors.otel_id}
        orientation="vertical"
        divider
        required
      >
        <CustomDualListBox
          listOptions={hotelOptions}
          selectedOptions={selectedOptions.map((option) => option.value)}
          handleSelectOptions={handleSelectOptions}
        />
        {selectedOptions.length > 0 ? (
          <MainAuthorityOptions
            values={selectedOptions}
            validateFormChange={validateFormChange}
            maxSelection={1}
          />
        ) : (
          <></>
        )}
      </CustomFormFieldContainer>
    </div>
  );
};

export default UnassignedHotelsList;
