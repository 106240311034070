import { ViewFeaturedImagesPage } from "Components/Custom/FacilityPages";
import useViewFeaturedImages from "Components/Hooks/facility/useViewFeaturedImages";
import PageContainer from "pages/PageContainer";

const ViewFeaturedImages = () => {
  const { isLoading } = useViewFeaturedImages();

  return (
    <PageContainer title="Tesis Vitrin Görselleri">
      <ViewFeaturedImagesPage loading={isLoading} />
    </PageContainer>
  );
};

export default ViewFeaturedImages;
