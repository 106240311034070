import React, { useState } from "react";
import useHandleAvailabilities from "./useHandleAvailabilities";

import { CustomAvailabilityCalendar } from "Components/Custom/UI";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FacilityAvailabilities = () => {
  const { availabilities } = useHandleAvailabilities();

  const [open, setOpen] = useState("1");

  const toggleAccordion = (id: string) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  return (
    <div>
      <Accordion open={open} toggle={toggleAccordion}>
        <div className="d-flex flex-column gap-2">
          {availabilities.map((availability, index) => (
            <div key={index}>
              {Object.entries(availability).map(([key, value]) => (
                <AccordionItem key={key}>
                  <AccordionHeader targetId={index.toString()}>
                    <span className="text-uppercase fw-bold text-center">
                      {key} Ödeme
                    </span>
                  </AccordionHeader>
                  <AccordionBody accordionId={index.toString()}>
                    <div key={key} className="d-flex flex-column gap-4">
                      {Object.entries(value).map(([key, value]) => (
                        <div
                          key={key}
                          className="d-flex flex-column gap-2 border p-2 theme-bg-light rounded"
                        >
                          <span className="text-uppercase text-success bg-success bg-opacity-25 fw-bold text-center">
                            {key}
                          </span>
                          <div className=" overflow-auto">
                            <CustomAvailabilityCalendar bookings={value} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionBody>
                </AccordionItem>
              ))}
            </div>
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default FacilityAvailabilities;
