interface Tooltip {
  target: string;
  title: string;
  message: string;
  component?: JSX.Element;
}

interface Tooltips {
  [key: string]: Tooltip;
}

export const childSpecialAgeTooltip: Tooltip = {
  target: "child-special-age-select-tooltip",
  title: "Özel Yaş Aralığı Kuralı",
  message:
    "Belirlenen tarihlerde çocuklara özel yaş aralığı varsa kullanılır.\nÇocuk bilgileri kısmından eklenen özel yaş kuralı, buradan belirlenen tarihlere tanımlanır.\nDeğişiklik yapıldığı anda fiyatlar, özel yaş aralığının kişi oranlarına uygun oalrak yeniden hesaplanır.",
};

export const ctaTooltip: Tooltip = {
  target: "cta",
  title: "Giriş Gününe Kapalı",
  message:
    "Giriş günü belirlenen tarihlere denk geldiğinde fiyat hesaplanması istenmiyor ise 'CTA - Giriş Gününe Kapalı' seçeneği işaretlenir.",
};

export const ctdTooltip: Tooltip = {
  target: "ctd",
  title: "Çıkış Gününe Kapalı",
  message:
    "Çıkış günü belirlenen tarihlere denk geldiğinde fiyat hesaplanması istenmiyor ise 'CTD - Çıkış Gününe Kapalı' seçeneği işaretlenir.",
};

export const applyAccordingToArrivalDayTooltip: Tooltip = {
  target: "apply-according-to-arrival-day",
  title: "Giriş Gününe Göre Uygula",
  message:
    "Giriş Gününe Görew Uygula seçili ise ve rezervasyonun giriş günü tutuyorsa tüm günlere Özel İndirim uygulanır.",
};

export const applicableDaysTooltip: Tooltip = {
  target: "applicable-days",
  title: "Giriş Bazlı",
  message:
    "Uygulanabilir günler 'Tüm Günler' ise o periyottaki tüm günlerde özel indirim uygulanır. 'Bazı Günler' ise seçilen günlerde uygulanır.",
};

export const underDeskTooltip: Tooltip = {
  target: "under-desk-tooltip",
  title: "Deskaltı Olarak Uygula",
  message:
    "Deskaltı Olarak Uygula seçili ise misafirler otelfiyat.com'da fiyat hesaplandığında Özel İndirim uygulanmaz.",
};

export const applyToCostTooltip: Tooltip = {
  target: "apply-to-cost-tooltip",
  title: "Maliyete Uygula",
  message:
    "İndirimin maliyete etki edip etmeyeceği durumlarda bu seçenek kullanılır. Sadece satış fiyatına etki edecek bir indirimde bu seçenek işaretlenmemelidir. Otel tarafından indirim uygulanıyorsa seçilmelidir.",
};

export const applyDirectlyTooltip: Tooltip = {
  target: "apply-directly-tooltip",
  title: "Direkt Uygula",
  message: "",
};

export const discountsCanBeUsedTogetherTooltip: Tooltip = {
  target: "discounts-can-be-used-together-tooltip",
  title: "Birlikte Kullanılabilecek İndirimler",
  message:
    "İndirim seçilen indirimlerle birlikte uygulanır.Örnek: Erken Rezervasyon indirimi seçili ise Özel İndirim Erken Rezervasyon İndiriminin üzerine uygulanır.",
};

export const onlyThisHotelTooltip: Tooltip = {
  target: "only-this-hotel-tooltip",
  title: "Yalnız Bu Otele Uygula",
  message:
    "Yalnız Bu Otele Uygula seçili ise sadece seçili otelin seçili odasına uygulanır. Seçili değil ise tüm kayıtlı otellerin seçili odalarına  uygulanır.",
};

export const applyAllRoomsTooltip: Tooltip = {
  target: "apply-all-rooms-tooltip",
  title: "Tüm Odalara Uygula",
  message:
    "Tüm Odalara Uygula seçili ise seçili otelin tüm odaların kayıtlı tüm odalarına aynı kriterler uygulanır. Seçili değil ise sadece seçili odaaya bu minimum geceleme kriterleri uygulanır.",
};

export const applyAllConceptsTooltip: Tooltip = {
  target: "apply-to-all-concepts-tooltip",
  title: "Tüm Konseptlere Uygula",
  message:
    "Tüm Konseptlere Uygula seçili ise seçili otelin tüm pansiyonlarına aynı kriterler uygulanır. Seçili değil ise sadece seçili pansiyona bu minimum geceleme kriterleri uygulanır.",
};

export const applyAllPaymentMethodsTooltip: Tooltip = {
  target: "apply-to-all-payment-methods-tooltip",
  title: "Tüm Ödeme Yöntemlerine Uygula",
  message:
    "Tüm Ödeme Yöntemlerine Uygula seçili ise seçili otelin tüm ödeme yöntemlerine aynı kriterler uygulanır. Seçili değil ise sadece seçili ödeme yöntemine bu minimum geceleme kriterleri uygulanır.",
};

export const breakingDiscountsbreakingDiscountsTypesOptionsTooltip: Tooltip = {
  target: "breaking-discounts-types-options-tooltip",
  title: "Geçerli Olacağı Gün",
  message:
    "Son dakika indirimi, seçili odalar için diğer tüm indirimlere ilave olarak uygulanır. Son dakika indirimi aktif olduğu müddetçe seçili kritere göre konuklar bu indirimden faydalanır.",
};
export const breakingDiscountsDiscountStatusTooltip: Tooltip = {
  target: "breaking-discounts-discount-status-tooltip",
  title: "Kampanya Aktiflik",
  message:
    "Son dakika indirimi aktif olduğu müddetçe seçili kritere göre konuklar bu indirimden faydalanır.",
};
export const earlyReservationDiscountsMinNightsTooltip: Tooltip = {
  target: "early-reservation-discounts-min-nights-tooltip",
  title: "Minimum Geceleme",
  message:
    "Misafirlerin Erken Rezervasyon İndiriminden faydalanabilmesi için belirlenen minimum geceleme sayısıdır.",
};
export const uncancellableRoomRatesStatusTooltip: Tooltip = {
  target: "uncancellable-room-rates-status-tooltip",
  title: "İptal Edilemez Oda Aktiflik",
  message: "İptal Edilemez oda ödeme seçeneğini kapatıp açar.",
};

export const uncancellableRoomRatesApplyToAllRoomsTooltip: Tooltip = {
  target: "uncancellable-room-rates-apply-to-all-rooms-tooltip",
  title: "Tüm Odalara Uygula",
  message: "Otelin kayıtlı tüm odalarına aynı kriterler uygulanır.",
};

export const earlyReservationDiscountsCumulativeTooltip: Tooltip = {
  target: "early-reservation-discounts-cumulative-tooltip",
  title: "Kumulatif Uygula",
  message:
    "Kumulatif Uygula seçili ise, Kümülatif olan diğer indirim oranları ile toplanır ve maliyetten toplam indirim oranı olarak düşürülür. Seçili değil isemaliyetten girilen indirim oranı düşürülür.",
};

export const stayLongPayLessApplicableDaysTooltip: Tooltip = {
  target: "stay-long-pay-less-applicable-days-tooltip",
  title: "Uygulanabilir Günler",
  message:
    "Uygulanabilir günler 'Tüm Günler' ise o periyottaki tüm günlerde Kal-Öde Aksiyonu uygulanır. 'Bazı Günler' ise seçilen günlere Kal-Öde Aksiyonu uygulanır.",
};

export const stayLongPayLessNightPriceTooltip: Tooltip = {
  target: "stay-long-pay-less-night-price-tooltip",
  title: "Geceleme Fiyatı",
  message:
    "Kal-Öde, belirlenen geceleme fiyatını alarak hesaplamaya girer. Örneğin: Kal-Öde 3 kal 2 ödedir. Takvimde 1 gece fiyatı 100₺, 3 gece fiyatı 150₺. Eğer bu seçenek işaretlenirse hesaplama 3 gece fiyatından yapılır. İşaretlenmezse 1 gece fiyatından yapılır.",
};

export const stayLongPayLessDiscountUnderDeskTooltip: Tooltip = {
  target: "stay-long-pay-less-discount-under-desk-tooltip",
  title: "Deskaltı Olarak Uygula",
  message:
    "Deskaltı Olarak Uygula seçili ise misafirler otelfiyat.com'da fiyat hesaplandığında Kal-Öde Aksiyonu uygulanmaz.",
};

export const stayLongPayLessUseSpecialRateTooltip: Tooltip = {
  target: "stay-long-pay-less-use-special-rate-tooltip",
  title: "Özel Komisyon Oranı Kullan",
  message:
    "Özel Komisyon Oranı Kullan seçildiğinde GEnel Fiyat Ayarlarında girilmiş olan Özel Komisyon Oranı sadece Kal-Öde'li günlerde fiyat hesaplandığında fiyata uygulanır.",
};

export const stayLongPayLessRecurrenceTooltip: Tooltip = {
  target: "stay-long-pay-less-recurrence-tooltip",
  title: "Yinelemeli",
  message:
    "Yinelemeli seçili ise, indirimler tekrar edilerek uygulanır. Seçili değil ise, sadece bir kez Kal-Öde Aksiyonu uygulanır.",
};

export const stayLongPayLessEarlyReservationTooltip: Tooltip = {
  target: "stay-long-pay-less-early-reservation-tooltip",
  title: "Erken Rezervasyon ile Birlikte Uygula",
  message:
    "Kal-Öde Aksiyonu Erken Rezervasyon İndirimi ile Birlikte Uygulanır. Erken Rezervasyon ile Uygula Evet İse Kal - Öde Aksiyonu Erken Rezervasyon indiriminin üzerine uygulanır.",
};

export const underTheDeskApplicationCriteriaTooltip: Tooltip = {
  target: "under-the-desk-application-criteria-tooltip",
  title: "Uygulama Kriteri",
  message:
    "Her zaman seçili ise satış ve konaklama tarihleri için süre sınırlaması olmaz, Tarih aralığında seçili ise satış ve konaklama tarih aralığı belirtilmelidir.",
};

export const giftPromotionApplicationCriteriaTooltip: Tooltip = {
  target: "gift-promotion-application-criteria-tooltip",
  title: "Uygulama Kriteri",
  message:
    "Uygulanacak hediye koşulu 'Tarih Aralığına Göre' ise belirlenen tarih aralığında tüm günlerde veya seçili günlerde, 'Tarih Aralığına Göre Minimum Geceleme Şartıyla' ise belirlenen tarih aralığında belirlenecek minimun gece sayısına göre, 'Minimum Geceleme Şartıyla' ise tarih kısıtlaması olmaksızın belirlenecek minimun gece sayısına göre indirim uygulanır.",
};

export const nightRestrictionsMinNightsTooltip: Tooltip = {
  target: "night-restrictions-min-nights-tooltip",
  title: "Gecelemeler ve Minimum Geceleme",
  message: "Geceleme ve minimum geceleme uygulanacak geceleme kısıtlaması.",
};

export const nightRestrictionsEntryConditionTooltip: Tooltip = {
  target: "night-restrictions-entry-condition-tooltip",
  title: "Giriş Bazlı",
  message:
    "Örnek: 19 Ekim-22 Ekim tarihlerinde minimum 4 gece, 24 Ekim-26 Ekim tarihlerinde minimum 3 gece konaklama şartı olan otelde, Giriş Bazlı seçili ise 19 Ekim - 25 Ekim tarihlerinde konaklama isteyen misafire minimum gece uyarısı çıkar. Giriş Bazlı seçili değil ise 19 Ekim - 25 Ekim tarihlerinde konaklama isteyen misafire minimum gece uyarısı çıkmaz.",
};

export const nightRestrictionsApplicableDaysTooltip: Tooltip = {
  target: "night-restrictions-applicable-days-tooltip",
  title: "Uygulanabilir Günler",
  message:
    "Uygulanabilir Günler 'Tüm Günler' ise  periyottaki tüm günlerde Gece Kısıtlaması uygulanır. 'Bazı Günler' ise seçili günlere Gece Kısıtlaması uygulanır.",
};

export const nightRestrictionsRecurrenceTooltip: Tooltip = {
  target: "night-restrictions-recurrence-tooltip",
  title: "Yinelemeli",
  message:
    "Örnek: 3 Geceleme tanımlanmış bir periyot içerisinde 5 gecelik fiyat hesaplanıyor. Yinelemeli seçili ise rezervasyonun tamamı 3 geceleme fiyatından alınır. Yinelemeli seçili değil ise 3 gecesi 3geceleme fiyatından, 2 gecesi 1 geceleme fiyatından alınır.",
};

export const nightRestrictionsApplyToAllRoomsTooltip: Tooltip = {
  target: "night-restrictions-apply-to-all-rooms-tooltip",
  title: "Tüm Odalara Uygula",
  message:
    "'Tüm Odalara Uygula' seçili ise otelin kayıtlı tüm odalarına aynı kriterler uygulanır, seçili değilse sadece seçili olan odaya bu kriterler uygulanır.",
};

export const nightRestrictionsApplyToAllConceptsTooltip: Tooltip = {
  target: "night-restrictions-apply-to-all-concepts-tooltip",
  title: "Tüm Pansiyonlara Uygula",
  message:
    "Minimum Geceleme Kısıtlamasında kullanılmak üzere 'Tüm Pansiyonlara Uygula' seçili ise tüm kayıtlı pansiyonlarına aynı kriterler uygulanır, seçili değil ise sadece seçili olan pansiyona uygulanır.",
};

export const detailedRoomQuotaSettingsTooltip: Tooltip = {
  target: "detailed-room-quota-settings-tooltip",
  title: "Detaylı Ayarlar",
  message:
    "Oda konaklama çeşitlerinde izin verilmeyen kişi sayıları mevcutsa veya özel katsayı varsa kullanılır. Var işaretlendiğinde açılan konaklama çeşitlerinden izin verilmeyenlerin işaretlerini kaldırınız. Özel Katsayı seçeneği Var seçili iken Konaklama çeşitlerine özel oranlar girebilirsiniz. Konuk sayıları değiştirildiğinde veya Detaylı ayarlar Yok seçildiğinde konaklama çeşitlerinde tümü işaretli ve özel katsayı yok kabul edilecektir.",
};

export const ChildBirthdayDateTooltip: Tooltip = {
  target: "child-birthday-date-tooltip",
  title: "Çocuk Doğum Tarihi",
  message:
    "Tesisin çocuk fiyatını doğum gününe veya doğum yılına göre belirlemesi durumudur",
};

export const childFreeAgeRangesTooltip: Tooltip = {
  target: "child-age-ranges-tooltip",
  title: "Ücretsiz Çocuk Kabulü",
  message:
    "Ücretsiz Kabul Ediliyor seçeneği seçili değil ise, çocuk yaş aralıkları ücretli sayılır ve ücretli çocuk oranı dikkate alınır.",
};

export const childPaidAgeRangesTooltip: Tooltip = {
  target: "child-paid-age-ranges-tooltip",
  title: "Ücretli Çocuk Kabulü",
  message:
    "Ücretli Kabul Ediliyor seçeneği şeçili değil ise, ücretsiz yaş aralığı sonrasındaki yaşlar yetişkin olarak dikkate alınır.",
};

export const personPriceRateTooltip: Tooltip = {
  target: "person-price-rate-tooltip",
  title: "Örnek Fiyat",
  message:
    "Bu alana girilen fiyat  sadece örnek teşkil eder. Girdiğiniz kişi oranlarının fiyat karşılıklarını görmenize ve buna göre karar vermenize yardımcı olur.",
};

export const TOOLTIPS: Tooltips = {
  applyAccordingToArrivalDay: applyAccordingToArrivalDayTooltip,
  applicableDays: applicableDaysTooltip,
  underDesk: underDeskTooltip,
  applyToCost: applyToCostTooltip,
  applyDirectly: applyDirectlyTooltip,
  discountsCanBeUsedTogether: discountsCanBeUsedTogetherTooltip,
  onlyThisHotel: onlyThisHotelTooltip,
  applyAllRooms: applyAllRoomsTooltip,
  applyAllConcepts: applyAllConceptsTooltip,
  applyAllPaymentMethods: applyAllPaymentMethodsTooltip,
  breakingDiscountsbreakingDiscountsTypesOptions:
    breakingDiscountsbreakingDiscountsTypesOptionsTooltip,
  breakingDiscountsDiscountStatus: breakingDiscountsDiscountStatusTooltip,
  uncancellableRoomRatesApplyToAllRooms:
    uncancellableRoomRatesApplyToAllRoomsTooltip,
  uncancellableRoomRatesStatus: uncancellableRoomRatesStatusTooltip,
  earlyReservationDiscountsMinNights: earlyReservationDiscountsMinNightsTooltip,
  earlyReservationDiscountsCumulative:
    earlyReservationDiscountsCumulativeTooltip,
  stayLongPayLessApplicableDays: stayLongPayLessApplicableDaysTooltip,
  stayLongPayLessNightPrice: stayLongPayLessNightPriceTooltip,
  stayLongPayLessDiscountUnderDesk: stayLongPayLessDiscountUnderDeskTooltip,
  stayLongPayLessUseSpecialRate: stayLongPayLessUseSpecialRateTooltip,
  stayLongPayLessRecurrence: stayLongPayLessRecurrenceTooltip,
  stayLongPayLessEarlyReservation: stayLongPayLessEarlyReservationTooltip,
  underTheDeskApplicationCriteria: underTheDeskApplicationCriteriaTooltip,
  giftPromotionApplicationCriteria: giftPromotionApplicationCriteriaTooltip,
  nightRestrictionsMinNights: nightRestrictionsMinNightsTooltip,
  nightRestrictionsEntryCondition: nightRestrictionsEntryConditionTooltip,
  nightRestrictionsApplicableDays: nightRestrictionsApplicableDaysTooltip,
  nightRestrictionsRecurrence: nightRestrictionsRecurrenceTooltip,
  nightRestrictionsApplyToAllRooms: nightRestrictionsApplyToAllRoomsTooltip,
  nightRestrictionsApplyToAllConcepts:
    nightRestrictionsApplyToAllConceptsTooltip,
  detailedRoomQuotaSettingsTooltip: detailedRoomQuotaSettingsTooltip,
  childBirthdayDate: ChildBirthdayDateTooltip,
  childFreeAgeRanges: childFreeAgeRangesTooltip,
  childPaidAgeRanges: childPaidAgeRangesTooltip,
  personPriceRate: personPriceRateTooltip,
};
