import { Room } from "types/PricesQuotas";
import PriceInput from "./PriceInput";
import { useSelector } from "react-redux";
import { BasicPriceEntryInitialState } from "slices/pricesAndQuota/basicPriceEntry/reducer";
import RowContainer from "Components/Custom/PricesAndQuotaPages/CommonContainers/RowContainer";

interface PriceEntryItemProps {
  room: Room;
  currency: string;
  index: number;
}

const PriceEntryItem = ({ room, currency, index }: PriceEntryItemProps) => {
  const { changedPrices } = useSelector(
    (state: { BasicPriceEntry: BasicPriceEntryInitialState }) =>
      state.BasicPriceEntry
  );

  const findPrice = (oda_id: number, pansiyon_id: number) => {
    const price = changedPrices.find(
      (price) => price.oda_id === oda_id && price.pansiyon_id === pansiyon_id
    );

    return price
      ? price
      : {
          oda_id: oda_id,
          pansiyon_id: pansiyon_id,
          tarih: "",
          "Para Birimi": currency,
          fiyatlar: [
            {
              "2+0": 100,
            },
          ],
        };
  };

  return (
    <RowContainer title={room.oda_isim} index={index}>
      {room.pansiyonlar
        .filter((c) => c.durum === "1")
        .map((concept, index) => (
          <div
            key={index}
            className="d-flex flex-column"
            style={{ width: "200px" }}
          >
            <PriceInput
              name={`${room.id}-${concept.id}`}
              defaultValue={findPrice(room.id, concept.id)}
              currency={currency}
            />
          </div>
        ))}
    </RowContainer>
  );
};

export default PriceEntryItem;
