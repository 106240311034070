import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeUpdateFacilityRoomFormInput } from "slices/facility/facilityRooms/reducer";
import {
  UpdateFacilityRoomState,
  UpdateFacilityRoomFormErrors,
} from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface FacilityRoomNameAndAreaFieldsProps {
  values: UpdateFacilityRoomState;
  formErrors: UpdateFacilityRoomFormErrors;
  validateFormChange: (values: UpdateFacilityRoomState) => void;
}

const FacilityRoomNameAndAreaFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityRoomNameAndAreaFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Adı"
        error={formErrors.oda_isim}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.oda_isim}
          onChange={(e) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                oda_isim: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              oda_isim: e.target.value,
            });
          }}
          disabled
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Metrekare"
        error={formErrors.metrekare}
        divider
        required
      >
        <Input
          type="number"
          value={values.metrekare}
          onChange={(e) => {
            dispatch(
              handleChangeUpdateFacilityRoomFormInput({
                metrekare: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              metrekare: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityRoomNameAndAreaFields;
