import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/discountCodes/reducer";
import {
  DiscountCodeFormErrors,
  DiscountCodeState,
} from "types/DiscountsAndActions";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";

interface DiscountAmountOrRateProps {
  values: DiscountCodeState;
  formErrors: DiscountCodeFormErrors;
  validateFormChange: (values: DiscountCodeState) => void;
}

interface Option {
  value: string;
  label: string;
}

const DiscountAmountOrRate = ({
  values,
  formErrors,
  validateFormChange,
}: DiscountAmountOrRateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const discountType = values.indirim_tipi;
  const isRate = discountType === "oran";
  const isAmount = discountType === "tutar";

  const discountTypesOptions: Option[] = [
    { value: "oran", label: "%" },
    { value: "tutar", label: "TL" },
  ];

  return (
    <CustomFormFieldContainer
      label="İndirim"
      error={formErrors.indirim_orani_veya_tutarı}
    >
      <div className="d-flex gap-1">
        <div className="flex-grow-1">
          <InputGroup>
            <InputGroupText>
              <i className={isAmount ? "" : "ri-percent-line"}>
                {isAmount ? "₺" : ""}
              </i>
            </InputGroupText>
            <Input
              type="text"
              value={
                isRate
                  ? values.indirim_orani || ""
                  : isAmount
                  ? values.indirim_tutari || ""
                  : ""
              }
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    ...values,
                    indirim_orani: isRate ? e.target.value : "",
                    indirim_tutari: isAmount ? e.target.value : "",
                  })
                );

                validateFormChange({
                  ...values,
                  indirim_orani: isRate ? e.target.value : "",
                  indirim_tutari: isAmount ? e.target.value : "",
                });
              }}
            />
          </InputGroup>
        </div>
        <div className="flex-grow-1">
          <Select
            value={
              values.indirim_tipi
                ? discountTypesOptions.find(
                    (option) => option.value === values.indirim_tipi
                  )
                : null
            }
            onChange={(selected: Option) => {
              const discountType = selected ? selected.value : "";
              dispatch(handleChangeInput({ indirim_tipi: discountType }));
            }}
            options={discountTypesOptions}
            placeholder="İndirim Tipi"
          />
        </div>
      </div>
    </CustomFormFieldContainer>
  );
};

export default DiscountAmountOrRate;
