import { CustomFormContainer } from "Components/Custom/UI";
import ReservationCard from "./ReservationCard";
import useHandleReservationData from "./useHandleReservationData";

const RezervationData = () => {
  const { cards } = useHandleReservationData();

  return (
    <>
      <CustomFormContainer title="Rezervasyon Verileri">
        <div className="d-flex flex-wrap gap-4">
          {cards.map((card, index) => (
            <ReservationCard
              key={index}
              title={card.title}
              value={card.value.toString()}
              color={card.color}
              cardIcon={card.Icon}
            />
          ))}
        </div>
      </CustomFormContainer>
    </>
  );
};

export default RezervationData;
