import { CustomerMailsPage } from "Components/Custom/ReportsPages";
import useCustomerMails from "Components/Hooks/reports/useCustomerMails";
import PageContainer from "pages/PageContainer";

const CustomerMails = () => {
  const { isLoading } = useCustomerMails();

  return (
    <PageContainer title="Müşteri E-Postaları">
      <CustomerMailsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CustomerMails;
