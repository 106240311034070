import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  WelcomeAndCalculationInitialState,
  handleChangeInput,
  resetUpdateWelcomeAndCalculationState,
  handleSetDefaultToUpdateWelcomeAndCalculationState,
} from "slices/reports/welcomeAndCalculation/reducer";
import {
  getWelcomeAndCalculationList,
  updateWelcomeAndCalculationData,
} from "slices/reports/welcomeAndCalculation/thunk";
import { UpdateWelcomeAndCalculationRequestPayload } from "types/reports";

const useHandleForm = () => {
  const {
    updateWelcomeAndCalculation,
    updateWelcomeAndCalculationState,
    selectedHotel,
    updateFormLoading,
    updateFormError,
  } = useSelector(
    (state: { WelcomeAndCalculation: WelcomeAndCalculationInitialState }) =>
      state.WelcomeAndCalculation
  );

  const keyMatches: {
    [key: string]: string;
  } = {
    otel_adi: "Otel Adı",
    karsilama_kodu: "Karşılama Kodu",
    fiyat_hesaplama: "Fiyat Hesaplama",
    yetkililik: "Yetkililik",
    reklam: "Reklam",
    sorunlu_reklam: "Sorunlu Reklam",
  };

  const dispatch: Dispatch<any> = useDispatch();

  const onChangeInput = (key: string, value: string) => {
    dispatch(handleChangeInput({ [key]: value }));
  };

  const handleUpdateWelcomeAndCalculation = () => {
    const updateWelcomeAndCalculationRequestPayload: UpdateWelcomeAndCalculationRequestPayload =
      {
        otel_id: updateWelcomeAndCalculationState.otel_id,
        karsilama_kodu: updateWelcomeAndCalculationState.karsilama_kodu,
        fiyat_hesaplama: updateWelcomeAndCalculationState.fiyat_hesaplama,
        yetkililik: updateWelcomeAndCalculationState.yetkililik,
        reklam: updateWelcomeAndCalculationState.reklam,
        sorunlu_reklam: updateWelcomeAndCalculationState.sorunlu_reklam,
      };

    dispatch(
      updateWelcomeAndCalculationData(updateWelcomeAndCalculationRequestPayload)
    );

    setTimeout(() => {
      dispatch(getWelcomeAndCalculationList());
    }, 1000);
  };

  const handleEndEditing = () => {
    dispatch(resetUpdateWelcomeAndCalculationState());
  };

  const handleClearForm = () => {
    dispatch(handleSetDefaultToUpdateWelcomeAndCalculationState());
    window.scrollTo(0, 0);
  };

  return {
    updateWelcomeAndCalculation,
    updateWelcomeAndCalculationState,
    onChangeInput,
    keyMatches,
    selectedHotel,
    onUpdate: handleUpdateWelcomeAndCalculation,
    onEndEditing: handleEndEditing,
    onClearForm: handleClearForm,
    isLoading: updateFormLoading,
    error: updateFormError,
  };
};

export default useHandleForm;
