import { RoomSalesAndPaymentMethodsPage } from "Components/Custom/PricesAndQuotaPages";
import useRoomSalesAndPaymentMethods from "Components/Hooks/roomSalesAndPaymentMethods/useSalesAndPaymentMethods";
import PageContainer from "pages/PageContainer";

const RoomSalesAndPaymentMethods = () => {
  const { loading, error, isLoading, isValidHotel, ...props } =
    useRoomSalesAndPaymentMethods();

  return (
    <PageContainer title="Oda Satış ve Ödeme Yöntemleri">
      <RoomSalesAndPaymentMethodsPage
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        isValidHotel={isValidHotel}
        {...props}
      />
    </PageContainer>
  );
};

export default RoomSalesAndPaymentMethods;
