import { CustomFormContainer } from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import FacilityGoogleMapsCoordinates from "./FacilityGoogleMapsCoordinates";

interface FacilityMediaFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityMediaFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityMediaFieldsProps) => {
  return (
    <CustomFormContainer title="Medya Bilgileri" lg={12} xl={12}>
      <FacilityGoogleMapsCoordinates
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
    </CustomFormContainer>
  );
};

export default FacilityMediaFields;
