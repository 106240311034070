import { Alert } from "reactstrap";

interface CustomFormStatusMessagesProps {
  error: string;
  success?: string;
}

const CustomFormStatusMessages = ({
  error,
  success,
}: CustomFormStatusMessagesProps) => {
  return (
    <>
      {error && (
        <Alert color="danger" className="mt-2">
          {error}
        </Alert>
      )}
      {success && (
        <Alert color="success" className="mt-2">
          {success}
        </Alert>
      )}
    </>
  );
};

export default CustomFormStatusMessages;
