import { Col, Row } from "reactstrap";

import PriceEntryForm from "./PriceEntryForm";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import PriceEntryTable from "./PriceEntryTable";

import { Periods } from "types/PricesQuotas";
import { PAYMENT_METHODS } from "enums";
import PriceEntryFormForSpecificPayments from "./PriceEntryFormForSpecificPayments";
import AvailabilitiesLegend from "../../BasicAvailabilityEntryPage/AvailabilitiesLegend";
import { useMemo } from "react";

interface PeriodsContainerProps {
  data: Periods;
  concept: string;
  roomName: string;
}

const PeriodsContainer = ({
  data,
  concept,
  roomName,
}: PeriodsContainerProps) => {
  const paymentMethods = data.veriler.map((period) => period.odeme_tipi);
  const paymentMethodsMultipliers = data.odeme_sekli.oranlar;
  const showRoomName = data.veriler.length > 1;

  const otherPrices = data.geceleme_data;

  const paymentTypeLabel = {
    normal: "İptal Edilebilir Oda Fiyatları",
    kapida: "Tesiste Ödemeli Oda Fiyatları",
    iptal_edilemez: "İptal Edilemez Oda Fiyatları",
    kaporali: "Kaporalı Ödeme",
  };

  const sortedPriceTypes = useMemo(() => {
    const priceTypes = [...data.fiyat_tipleri];
    return priceTypes.sort((a, b) => {
      if (a.baz_fiyat) return -1;
      if (b.baz_fiyat) return 1;
      return 0;
    });
  }, [data]);

  return (
    <>
      {showRoomName && <p className="h4 text-start my-3">{roomName}</p>}
      <div className="d-flex flex-column gap-5">
        {data.veriler.map((period, index) => (
          <Row className="border p-3 theme-bg-light rounded">
            <div className="d-flex gap-2 mb-2 p-0 align-items-center justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <p
                  className={`py-1 px-3 rounded m-0 fw-semibold fs-14 border ${
                    period.odeme_tipi === "normal"
                      ? "theme-text-success border-success bg-success bg-opacity-10"
                      : period.odeme_tipi === "kapida"
                      ? "theme-text-primary border-primary bg-primary bg-opacity-10"
                      : period.odeme_tipi === "iptal_edilemez"
                      ? "theme-text-danger border-danger bg-danger bg-opacity-10"
                      : period.odeme_tipi === "kaporali"
                      ? "theme-text-warning border-warning bg-warning bg-opacity-10"
                      : ""
                  }`}
                >
                  {paymentTypeLabel[period.odeme_tipi as PAYMENT_METHODS]}
                </p>
                {index === 0 && (
                  <p className="py-1 px-3 rounded m-0 fw-semibold fs-14 border theme-text-primary border-primary bg-primary bg-opacity-10">
                    Baz Ödeme Tipi
                  </p>
                )}
              </div>
              <AvailabilitiesLegend />
            </div>
            <Col className="p-0" lg={5} xl={3}>
              <div className="theme-bg-primary border border-primary shadow-sm rounded-top w-100 p-2">
                <p className=" fw-bold text-white m-0">{concept}</p>
              </div>
              {index === 0 ? (
                <PriceEntryForm
                  priceTypes={sortedPriceTypes}
                  paymentMethod={period.odeme_tipi}
                  paymentMethods={paymentMethods}
                  paymentMethodsMultipliers={paymentMethodsMultipliers}
                  notAllowedToEditPaymentMethods={data.odeme_sekli.fiyat_durum}
                />
              ) : (
                <PriceEntryFormForSpecificPayments
                  priceTypes={sortedPriceTypes}
                  paymentMethods={paymentMethods}
                  paymentMethod={period.odeme_tipi}
                  notAllowedToEditPaymentMethods={data.odeme_sekli.fiyat_durum}
                />
              )}
            </Col>
            <Col className="mt-3 mt-lg-0" lg={7} xl={9}>
              <PriceEntryTable
                period={period}
                paymentMethods={paymentMethods}
                basePayMethod={index === 0}
                priceTypes={sortedPriceTypes}
                notAllowedToEditPaymentMethods={data.odeme_sekli.fiyat_durum}
                otherPrices={otherPrices}
                paymentMethodsMultipliers={paymentMethodsMultipliers}
              />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};

export default withPageNeedsHotel(PeriodsContainer);
