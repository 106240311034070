import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setToastError } from "slices/toast/reducer";
import { handleChangeHotelAssigneeFormErrors } from "slices/userOperations/personnelHotelAssignments/reducer";
import { ChangeHotelAssigneeFormErrors, ChangeHotelAssigneePayload } from "types/userOperations";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };


  const validateForm = (
    values: ChangeHotelAssigneePayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: ChangeHotelAssigneeFormErrors = {};

    if (!values.otel_id || values.otel_id.length === 0) {
      errors.otel_id = "En az bir adet otel seçimi yapınız.";
    }

    if (values.personel_id[0] === 0) {
        errors.personel_id_mevcut = "Mevcut personel seçimini yapınız.";
    }

    if (values.personel_id[1] === 0) {
        errors.personel_id_yeni = "Yeni personel seçimini yapınız.";
    }

    dispatch(handleChangeHotelAssigneeFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: ChangeHotelAssigneePayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;