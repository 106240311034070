import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/discountCodes/reducer";
import {
  DiscountCodeFormErrors,
  DiscountCodeState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { useEffect } from "react";
import { generateCouponCode } from "helpers/general_helpers";

interface DiscountCodeProps {
  values: DiscountCodeState;
  formErrors: DiscountCodeFormErrors;
  validateFormChange: (values: DiscountCodeState) => void;
}

const DiscountCode = ({
  values,
  validateFormChange,
  formErrors,
}: DiscountCodeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    const defaultCode = generateCouponCode();

    !values.indirim_kuponu_kodu &&
      dispatch(
        handleChangeInput({
          ...values,
          indirim_kuponu_kodu: defaultCode,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomFormFieldContainer
      label="İndirim Kuponu Kodu"
      error={formErrors.indirim_kuponu_kodu}
    >
      <Input
        type="text"
        value={values.indirim_kuponu_kodu}
        onChange={(e) => {
          dispatch(
            handleChangeInput({
              ...values,
              indirim_kuponu_kodu: e.target.value,
            })
          );
          validateFormChange({
            ...values,
            indirim_kuponu_kodu: e.target.value,
          });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default DiscountCode;
