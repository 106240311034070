import { useSelector } from "react-redux";
import { TABLE_COLORS } from "utils/tableColors";
import { ListWebSitesInitialState } from "slices/myWebSite/listWebSites/reducer";
import { ListWebSitesState } from "types/myWebSite";

const useHandleTable = () => {
  const { webSites } = useSelector(
    (state: { ListWebSites: ListWebSitesInitialState }) => state.ListWebSites
  );

  const tableData: ListWebSitesState[] = webSites?.map((webSite) => {
    let rowColor = "";
    if (webSite.aktiflik === "0") {
      rowColor = TABLE_COLORS.red;
    } else {
      rowColor = TABLE_COLORS.green;
    }

    return {
      otel_adi: webSite.otel_adi,
      id: webSite.id,
      otel_id: webSite.otel_id,
      domain_link: webSite.domain_link,
      aktiflik: webSite.aktiflik,
      aktiflik_text: webSite.aktiflik_text,
      temax_type: webSite.temax_type,
      temax_type_text: webSite.temax_type_text,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Otel Id",
      accessorKey: "otel_id",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Web Site Id",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Domain Link",
      accessorKey: "domain_link",
      enableColumnFilter: false,
    },
    {
      header: "Aktiflik Durumu",
      accessorKey: "aktiflik_text",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.red,
      label: "Pasif",
    },
    {
      color: TABLE_COLORS.green,
      label: "Aktif",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
