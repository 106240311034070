import { createSlice } from "@reduxjs/toolkit";
import {
  Location,
  AddLocationFormErrors,
  AddLocationState,
} from "types/definitions";

export interface AddLocationInitialState {
  selectedLocations: { key: number; value: Location[] }[];
  selectedLocationOptions: { value: number; label: string }[];
  locations: Location[];
  location: AddLocationState;
  addLocationFormErrors: AddLocationFormErrors;
  isLoading: boolean;
  error: string;
  addLocationLoading: boolean;
  addLocationError: string;
}

export const addLocationInitialState: AddLocationInitialState = {
  selectedLocations: [
    {
      key: -1,
      value: [],
    },
  ],
  selectedLocationOptions: [
    {
      value: -1,
      label: "Üst Bölge Seçiniz.",
    },
  ],
  locations: [],
  location: {
    parent_id: -1,
    lokasyon: "",
  },
  addLocationFormErrors: {},
  isLoading: false,
  error: "",
  addLocationLoading: false,
  addLocationError: "",
};

export const addLocationSlice = createSlice({
  name: "addLocation",
  initialState: addLocationInitialState,
  reducers: {
    handleSaveSelectedLocations(state, action) {
      state.selectedLocations = action.payload;
    },
    handleSaveSelectedLocationOptions(state, action) {
      const { index, value } = action.payload;

      if (state.selectedLocationOptions.length >= index) {
        state.selectedLocationOptions = state.selectedLocationOptions.slice(
          0,
          index
        );
        state.selectedLocationOptions.push(value);
      } else {
        state.selectedLocationOptions.push(value);
      }
    },
    handleSaveLocations(state, action) {
      state.locations = action.payload;
    },
    handleChangeInput(state, action) {
      state.location = {
        ...state.location,
        ...action.payload,
      };
    },
    handleAddAddLocationFormErrors(state, action) {
      state.addLocationFormErrors = action.payload;
    },
    resetAfterAddNewLocation(state) {
      state.selectedLocations = state.selectedLocations.slice(0, 1);
      state.selectedLocationOptions = [
        {
          value: -1,
          label: "Üst Bölge Seçiniz.",
        },
      ];
      state.location = {
        parent_id: -1,
        lokasyon: "",
      };
      state.addLocationFormErrors = {};
      state.isLoading = false;
      state.error = "";
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddLocationLoading(state, action) {
      state.addLocationLoading = action.payload;
    },
    setAddLocationError(state, action) {
      state.addLocationError = action.payload;
    },
  },
});

export const {
  handleSaveSelectedLocations,
  handleSaveSelectedLocationOptions,
  handleSaveLocations,
  handleChangeInput,
  handleAddAddLocationFormErrors,
  resetAfterAddNewLocation,
  setIsLoading,
  setError,
  setAddLocationLoading,
  setAddLocationError,
} = addLocationSlice.actions;

export default addLocationSlice.reducer;
