import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelectors from "./DateSelectors";
import MinimumNightsAndEntryCondition from "./MinimumNightsAndEntryCondition";
import ApplicableDays from "./ApplicableDays";
import RestrictionsSwitches from "./RestrictionsSwitches";
import UpdateRestrictionButton from "./UpdateRestrictionButton";
import AddNewRestrictionButton from "./AddNewRestrictionButton";

interface NightRestrictionsFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const NightRestrictionsForm = (props: NightRestrictionsFormProps) => {
  const {
    nightRestriction,
    conceptsOptions,
    addNewNightRestrictionRequestPayload,
    updateNightRestrictionRequestPayload,
    formErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
    addRestrictionLoading,
    updateRestrictionLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  const updating = nightRestriction.id !== 0;
  return (
    <CustomFormContainer>
      <DateSelectors
        values={nightRestriction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <MinimumNightsAndEntryCondition
        values={nightRestriction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ApplicableDays
        values={nightRestriction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <RestrictionsSwitches
        values={nightRestriction}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        conceptsOptions={conceptsOptions}
      />
      {updating ? (
        <UpdateRestrictionButton
          values={nightRestriction}
          requestPayload={updateNightRestrictionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={updateRestrictionLoading}
        />
      ) : (
        <AddNewRestrictionButton
          values={nightRestriction}
          requestPayload={addNewNightRestrictionRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          hotelAndSelectedRoom={props.hotelAndSelectedRoom}
          isLoading={addRestrictionLoading}
        />
      )}
    </CustomFormContainer>
  );
};

export default NightRestrictionsForm;
