import { createSlice } from "@reduxjs/toolkit";
import {
  UpdateGroupFormErrors,
  UpdateGroupPayload,
  UpdateGroupState,
} from "types/userOperations";

export interface UpdateGroupInitialState {
  groupToUpdate: UpdateGroupState;
  updatedGroup: UpdateGroupPayload;
  updateGroupFormErrors: UpdateGroupFormErrors;
  isLoading: boolean;
  error: string;
  updateGroupLoading: boolean;
  updateGroupError: string;
}

export const updateGroupInitialState: UpdateGroupInitialState = {
  groupToUpdate: {
    grup_id: "",
    grup_adi: "",
    yetkili_id: 0,
    yetkililer: [],
    uyeler: [],
    grup_uyeleri_id: [],
  },
  updatedGroup: {
    grup_id: 0,
    grup_adi: "",
    yetkili_id: 0,
    grup_uyeleri_id: [],
  },
  updateGroupFormErrors: {},
  isLoading: false,
  error: "",
  updateGroupLoading: false,
  updateGroupError: "",
};

export const updateGroupSlice = createSlice({
  name: "updateGroup",
  initialState: updateGroupInitialState,
  reducers: {
    handleGroupToUpdate(state, action) {
      state.groupToUpdate = action.payload;

      const payload = action.payload as UpdateGroupState;

      const updatedGroup: UpdateGroupPayload = {
        grup_id: Number(payload.grup_id),
        grup_adi: payload.grup_adi,
        yetkili_id: payload.yetkili_id,
        grup_uyeleri_id: payload.grup_uyeleri_id,
      };
      state.updatedGroup = updatedGroup;
    },
    handleChangeInput(state, action) {
      state.updatedGroup = {
        ...state.updatedGroup,
        ...action.payload,
      };
    },
    handleUpdateGroupFormErrors(state, action) {
      state.updateGroupFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateGroupLoading(state, action) {
      state.updateGroupLoading = action.payload;
    },
    setUpdateGroupError(state, action) {
      state.updateGroupError = action.payload;
    },
    handleResetGroupState(state) {
      state.groupToUpdate = {
        grup_id: "",
        grup_adi: "",
        yetkili_id: 0,
        yetkililer: [],
        uyeler: [],
        grup_uyeleri_id: [],
      };
    },
  },
});

export const {
  handleGroupToUpdate,
  handleChangeInput,
  handleUpdateGroupFormErrors,
  setIsLoading,
  setError,
  setUpdateGroupLoading,
  setUpdateGroupError,
  handleResetGroupState,
} = updateGroupSlice.actions;

export default updateGroupSlice.reducer;
