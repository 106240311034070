import { useDispatch } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import { Dispatch } from "redux";
import { handleSelectedDates } from "slices/pricesAndQuota/basicAvailabilityEntry/reducer";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface AvailabilityEntryDateRangeSelectorProps {
  selectedDates: {
    startDate: Date;
    endDate: Date;
  };
}

const AvailabilityEntryDateRangeSelector = ({
  selectedDates,
}: AvailabilityEntryDateRangeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <Row className="p-2 border rounded theme-bg-light">
      <Col md={4} className="d-flex align-items-start">
        <div className="flex-grow-1">
          <Label className="form-label">Tarih Aralığı</Label>
          <Flatpickr
            className="form-control"
            value={[selectedDates.startDate, selectedDates.endDate]}
            onChange={(dates) => {
              const [startDate, endDate] = dates;
              if (startDate && endDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");
                const stringEndDate = moment(endDate).format("YYYY-MM-DD");
                dispatch(
                  handleSelectedDates({
                    startDate: stringStartDate,
                    endDate: stringEndDate,
                  })
                );
              }
            }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AvailabilityEntryDateRangeSelector;
