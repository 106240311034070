import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { InformationTechnologiesInitialState } from "slices/userOperations/informationTechnologies/reducer";
import {
  getReservationTable,
  updatePriceProvider,
} from "slices/userOperations/informationTechnologies/thunk";
import { ReservationTablePayload } from "types/userOperations";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { reservations } = useSelector(
    (state: { InformationTechnologies: InformationTechnologiesInitialState }) =>
      state.InformationTechnologies
  );

  const [formState, setFormState] = useState<ReservationTablePayload>({
    rez_kod: "",
    fiyat_saglayici: "",
  });

  const [showUpdatePriceProviderForm, setShowUpdatePriceProviderForm] =
    useState(false);

  const handleGetReservation = (rez_kod: string) => {
    dispatch(
      getReservationTable(rez_kod, () => {
        setShowUpdatePriceProviderForm(true);
      })
    );
  };

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleResetForm = () => {
    setFormState({
      rez_kod: "",
      fiyat_saglayici: "",
    });
    setShowUpdatePriceProviderForm(false);
  };

  const handleUpdatePriceProvider = () => {
    const params = {
      rez_kod: formState.rez_kod,
      fiyat_saglayici: formState.fiyat_saglayici,
    };

    dispatch(
      updatePriceProvider(params, () => {
        handleResetForm();
      })
    );
  };

  const priceProviderOptions = reservations.map((item) => ({
    value: item.value,
    label: item.text,
  }));

  return {
    showUpdateForm: showUpdatePriceProviderForm,
    values: formState,
    options: priceProviderOptions,
    onGetReservation: handleGetReservation,
    handleFormChange,
    handleUpdate: handleUpdatePriceProvider,
  };
};

export default useHandleForm;
