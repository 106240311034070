import { FacilityManagersPage } from "Components/Custom/FacilityPages";
import useFacilityManagers from "Components/Hooks/facility/useFacilityManagers";
import PageContainer from "pages/PageContainer";

const FacilityManagers = () => {
  const { loading, error, isValidHotel, isLoading } = useFacilityManagers();

  return (
    <PageContainer title="Tesis Yetkilileri">
      <FacilityManagersPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default FacilityManagers;
