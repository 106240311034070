import { CustomFormContainer } from "Components/Custom/UI";
import {
  CreateWebSiteState,
  CreateWebSiteFormChoices,
  CreateWebSiteFormErrors,
} from "types/myWebSite";
import ShowCommercialPopupSelector from "./ShowCommercialPopupSelector";
import CommercialPopupTitle from "./CommercialPopupTitle";
import CommercialPopupImage from "./CommercialPopupImage";
import CommercialPopupDates from "./CommercialPopupDates";

interface CommercialPopupFieldsProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const CommercialPopupFields = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
  imageUrl,
}: CommercialPopupFieldsProps) => {

  const isReklamPopup = values.reklam_popup;

  return (
    <div className="mb-4">
      <CustomFormContainer title="Reklam Popup" lg={12} xl={12}>
        <ShowCommercialPopupSelector
          values={values}
          formChoices={formChoices}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        {isReklamPopup === "1" ?
        <>
        <CommercialPopupTitle
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CommercialPopupImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <CommercialPopupDates
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        /> 
        </>
        : <></>}
      </CustomFormContainer>
    </div>
  );
};
export default CommercialPopupFields;
