import { CustomLoadingPage, CustomTab } from "Components/Custom/UI";
import CreateWebSiteForm from "./CreateWebSiteForm";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { Router } from "types";
import FooterLinksForm from "./FooterLinksForm";
import CertificatesForm from "./CertificatesForm";
import AdditionalPages from "./AdditionalPages";

interface CreateWebSitePageProps extends Router {
  isLoading: boolean;
}

interface Tab {
  id: number;
  title: string;
  content: React.ReactNode;
}

const CreateWebSitePage = ({isLoading}: CreateWebSitePageProps) => {
  const tabs: Tab[] = [
    {
      id: 1,
      title: "Web Sitesi Oluştur",
      content: <CreateWebSiteForm />,
    },
    {
      id: 2,
      title: "Footer Linkleri",
      content: <FooterLinksForm/>,
    },
    {
      id: 3,
      title: "Sertifikalar",
      content: <CertificatesForm/>,
    },
    {
      id: 4,
      title: "Ek Sayfalar",
      content: <AdditionalPages isLoading={isLoading}/>,
    },
  ];

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <CustomTab tabs={tabs} />
    </>
  );
};

export default withPageNeedsHotel(CreateWebSitePage);
