import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { TABLE_COLORS } from "utils/tableColors";
import { ListUsersInitialState } from "slices/userOperations/listUsers/reducer";
import useHandleRequests from "./useHandleRequests";

/* 

*/

const useHandleTable = () => {
  const { users } = useSelector(
    (state: { ListUsers: ListUsersInitialState }) => state.ListUsers
  );
  
  const { onUpdate, onChangeUserStatus } = useHandleRequests();

  const tableData = users?.map((user) => {
    let rowColor = "";
    if (user.aktiflik === "0") {
      rowColor = TABLE_COLORS.red;
    } else {
      rowColor = TABLE_COLORS.green;
    }

    return {
      inet_kodu: user.inet_kodu, //kod
      id: user.id,
      aktiflik: user.aktiflik, //durum
      ad_soyad: user.ad_soyad,
      dahili: user.dahili,
      yetki: user.yetki, // hesap tipi
      grup_adi: user.grup_adi,
      email_adresi: user.email_adresi,
      kullanici_adi: user.kullanici_adi,
      son_giris_ip: user.son_giris_ip,
      yetkiler: user.yetkiler,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Kod",
      accessorKey: "inet_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Id",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "aktiflik",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return <div>{value === "1" ? "Aktif" : "Pasif"}</div>;
      },
    },
    {
      header: "Ad Soyad",
      accessorKey: "ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "dahili",
      enableColumnFilter: false,
    },
    {
      header: "Hesap Tipi",
      accessorKey: "yetki",
      enableColumnFilter: false,
    },
    {
      header: "Grup Adı",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
    },
    {
      header: "E-posta Adresi",
      accessorKey: "email_adresi",
      enableColumnFilter: false,
    },
    {
      header: "Kullanıcı Adı",
      accessorKey: "kullanici_adi",
      enableColumnFilter: false,
    },
    {
      header: "Son Giriş",
      accessorKey: "son_giris_ip",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "160px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Yetkiler",
      accessorKey: "yetkiler",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ width: "160px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        const userAktiflik = cell.row.original.aktiflik;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => onUpdate(id)}
                title="Duzenle"
              />
              <CustomRowOperationButton
                variant={userAktiflik === "1" ? "uncheck" : "check"}
                onClick={() => {
                  onChangeUserStatus(userAktiflik, id);
                }}
                title={userAktiflik === "1" ? "Pasifleştir" : "Aktifleştir"}
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.red,
      label: "Pasif",
    },
    {
      color: TABLE_COLORS.green,
      label: "Aktif",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
