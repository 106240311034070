import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ViewFeaturedImagesInitialState } from "slices/facility/viewFeaturedImages/reducer";
import { getFeaturedImages } from "slices/facility/viewFeaturedImages/thunk";

const useViewFeaturedImages = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { ViewFeaturedImages: ViewFeaturedImagesInitialState }) =>
      state.ViewFeaturedImages
  );

  useEffect(() => {
    dispatch(getFeaturedImages({ page: 1, per_page: 10, search: "" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useViewFeaturedImages;
