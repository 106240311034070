import ListUsersPage from 'Components/Custom/UserOperationsPages/ListUsersPage';
import useListUsers from 'Components/Hooks/userOperations/useListUsers';
import PageContainer from 'pages/PageContainer';

const ListUsers = () => {

    const { isLoading } = useListUsers();

  return (
    <PageContainer title="Kullanıcı Ekle">
        <ListUsersPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default ListUsers