import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveHotels,
  handleSaveOrderedHotels,
  handleSaveThemes,
  setAddOrderThemeHotelLoading,
  setAddOrderThemeHotelError,
} from "./reducer";

import {
  GET_ORDER_THEME_HOTELS,
  ADD_ORDER_THEME_HOTELS,
  GET_THEMES,
} from "helpers/url_helper";

import { OrderThemeHotelsRequestPayload } from "types/themes";
import { setToastSuccess, setToastError } from "slices/toast/reducer";

import { Dispatch } from "redux";

interface GetOrderThemeHotelsPayload {
  tema_id: number;
}

export const getThemes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEMES);

    dispatch(handleSaveThemes(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getOrderThemeHotels =
  (payload: GetOrderThemeHotelsPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_ORDER_THEME_HOTELS, {
        params: {
          tema_id: payload.tema_id,
        },
      });

      dispatch(handleSaveHotels(response.data || response));
      dispatch(handleSaveOrderedHotels(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addOrderThemeHotels =
  (payload: OrderThemeHotelsRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAddOrderThemeHotelLoading(true));
      await axios.post(ADD_ORDER_THEME_HOTELS, payload);

      dispatch(setToastSuccess("Otel sıralaması güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddOrderThemeHotelError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddOrderThemeHotelLoading(false));
    }
  };
