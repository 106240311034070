import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleFormChoices,
  setIsLoading,
  setError,
  setCreateNotificationLoading,
  setCreateNotificationError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_CREATE_NOTIFICATION_FORM_CHOICES, CREATE_NOTIFICATION } from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getCreateNotificationFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_CREATE_NOTIFICATION_FORM_CHOICES);
    const res = (response as any).message ? response.data : response;

    dispatch(handleFormChoices(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const createNotification = (payload: FormData, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
  try {
    dispatch(setCreateNotificationLoading(true));
    await axios.post(CREATE_NOTIFICATION, payload);

    successCallback();
    dispatch(setToastSuccess("Bildirim başarıyla oluşturuldu."));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setCreateNotificationError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setCreateNotificationLoading(false));
  }
};
