import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { setIsLoading, setError, handleSaveAllGifts } from "./reducer";
import { setToastError } from "slices/toast/reducer";

import { GET_ALL_GIFTS_LIST } from "helpers/url_helper";

import { Dispatch } from "redux";

export const getAllGifts = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_ALL_GIFTS_LIST);

    dispatch(handleSaveAllGifts(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
