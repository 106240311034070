import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  AdsReport,
  AdsReportSummary,
  AdsReportManagers,
  AdsReportsOldDate,
} from "types/adsAndAnalyze";
import { Pagination } from "types/GlobalTypes";

export interface AdsReportInitialState {
  adsReport: AdsReport[];
  oldDates: AdsReportsOldDate[];
  selectedDates: {
    sdate: string;
    edate: string;
  };
  selectedManager: number;
  adsReportSummary: AdsReportSummary;
  adsReportManagers: AdsReportManagers;
  pagination: Pagination;
  isLoading: boolean;
  summaryLoading: boolean;
  managersLoading: boolean;
  error: string;
  summaryError: string;
  managersError: string;
}

const initialState: AdsReportInitialState = {
  adsReport: [],
  oldDates: [],
  selectedDates: {
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().add(5, "days").format("YYYY-MM-DD"),
  },
  selectedManager: 0,
  adsReportSummary: {
    toplam_oran: 0,
    toplam_satis: 0,
    toplam_maliyet: 0,
    crm_oran: 0,
    termal_oran: 0,
    sehir_oran: 0,
    kayak_oran: 0,
    operator_oran: 0,
    islami_oran: 0,
    crm_satis: 0,
    termal_satis: 0,
    sehir_satis: 0,
    kayak_satis: 0,
    operator_satis: 0,
    islami_satis: 0,
    crm_maliyet: 0,
    termal_maliyet: 0,
    sehir_maliyet: 0,
    kayak_maliyet: 0,
    operator_maliyet: 0,
    islami_maliyet: 0,
  },
  adsReportManagers: {},
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  summaryLoading: false,
  managersLoading: false,
  error: "",
  summaryError: "",
  managersError: "",
};

const adsReportSlice = createSlice({
  name: "adsReport",
  initialState,
  reducers: {
    handleSaveAdsReport(state, action) {
      const { data, ...rest } = action.payload.raporlar;
      const { eski_tarihler } = action.payload;

      state.adsReport = data;
      state.oldDates = eski_tarihler;
      state.pagination = rest;
    },
    handleSaveAdsReportSummary(state, action) {
      state.adsReportSummary = action.payload;
    },
    handleSaveAdsReportManagers(state, action) {
      state.adsReportManagers = action.payload;
    },
    handleChangeSelectedDates(state, action) {
      state.selectedDates = action.payload;
    },
    handleSaveSelectedManager(state, action) {
      state.selectedManager = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSummaryLoading(state, action) {
      state.summaryLoading = action.payload;
    },
    setManagersLoading(state, action) {
      state.managersLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSummaryError(state, action) {
      state.summaryError = action.payload;
    },
    setManagersError(state, action) {
      state.managersError = action.payload;
    },
  },
});

export const {
  handleSaveAdsReport,
  handleSaveAdsReportSummary,
  handleSaveAdsReportManagers,
  handleChangeSelectedDates,
  handleSaveSelectedManager,
  setIsLoading,
  setSummaryLoading,
  setManagersLoading,
  setError,
  setSummaryError,
  setManagersError,
} = adsReportSlice.actions;

export default adsReportSlice.reducer;
