import useHandleForm from "./useHandleForm";
import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { Label } from "reactstrap";
import { ASSIGN_HOTELS_TO_USERS_DESCRIPTIONS } from "utils/pageDescriptions";
import CurrentPersonnelSelector from "./CurrentPersonnelSelector";
import NewPersonnelSelector from "./NewPersonnelSelector";
import HotelsToChangeList from "./HotelsToChangeList";
import KeepBothPersonnelCheckbox from "./KeepBothPersonnelCheckbox";
import ChangeHotelAssigneeButton from "./ChangeHotelAssigneeButton";

interface ChangeHotelAssigneesFormProps {
  isLoading: boolean;
}

const ChangeHotelAssigneesForm = ({
  isLoading,
}: ChangeHotelAssigneesFormProps) => {
  const {
    handleSelectCurrentUser,
    handleSelectNewUser,
    changeHotelAssignee,
    changeHotelAssigneeFormChoices,
    selectedUsersToChange,
    changeHotelAssigneeLoading,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div style={{ padding: "8px 16px" }}>
        <CustomPageDescriptions
          variant="danger"
          descriptions={ASSIGN_HOTELS_TO_USERS_DESCRIPTIONS}
        />
        <CurrentPersonnelSelector
          values={changeHotelAssignee}
          personnelFormChoices={changeHotelAssigneeFormChoices.personeller}
          selectedUsersToChange={selectedUsersToChange}
          handleSelectUser={handleSelectCurrentUser}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        {selectedUsersToChange[0] > 0 ? (
          <>
            <HotelsToChangeList formErrors={formErrors} />
            <NewPersonnelSelector
              values={changeHotelAssignee}
              personnelFormChoices={changeHotelAssigneeFormChoices.personeller}
              selectedUsersToChange={selectedUsersToChange}
              handleSelectUser={handleSelectNewUser}
              formErrors={formErrors}
              validateFormChange={validateFormChange}
            />
            <KeepBothPersonnelCheckbox
              values={changeHotelAssignee}
              validateFormChange={validateFormChange}
            />
            <ChangeHotelAssigneeButton
              values={changeHotelAssignee}
              validateForm={validateForm}
              handleSubmitted={handleSubmitted}
              isLoading={changeHotelAssigneeLoading}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ChangeHotelAssigneesForm;
