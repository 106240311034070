import { PriceType } from "types/PricesQuotas";
import useHandleForm from "./useHandleForm";
import AvailabilityField from "./AvailabilityField";
import CtaCtdField from "./CtaCtdField";
import PriceTypesContainer from "./PriceTypesContainer";

interface PriceEntryFormForSpecificPaymentsProps {
  priceTypes: PriceType[];
  paymentMethods: string[];
  paymentMethod: string;
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  };
}

const PriceEntryFormForSpecificPayments = ({
  priceTypes,
  paymentMethods,
  paymentMethod,
  notAllowedToEditPaymentMethods,
}: PriceEntryFormForSpecificPaymentsProps) => {
  const { formInputValues, handleChange, handlePriceChange, isReady } =
    useHandleForm(priceTypes, paymentMethods, paymentMethod);

  const notAllowedToEdit = Object.keys(notAllowedToEditPaymentMethods).includes(
    paymentMethod
  );

  return (
    <div className="d-flex flex-column gap-2 p-2 pt-1 border shadow-sm bg-white rounded-bottom">
      <AvailabilityField
        values={formInputValues}
        onChange={handleChange}
        notAllowedToEdit={false}
      />
      <CtaCtdField
        values={formInputValues}
        onChange={handleChange}
        notAllowedToEdit={false}
      />
      <i
        className="ri-price-tag-3-fill fs-24 cursor-pointer theme-text-secondary"
        style={{ visibility: "hidden" }}
      ></i>
      <PriceTypesContainer
        priceTypes={priceTypes}
        isReady={isReady}
        values={formInputValues}
        onChange={handlePriceChange}
        notAllowedToEdit={notAllowedToEdit}
      />
    </div>
  );
};

export default PriceEntryFormForSpecificPayments;
