import { Row, Col, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { handleSelectedDates } from "slices/pricesAndQuota/basicQuotaEntry/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface QuotaEntryDateRangeSelectorProps {
  selectedDates: {
    startDate: Date;
    endDate: Date;
  };
}

const QuotaEntryDateRangeSelector = ({
  selectedDates,
}: QuotaEntryDateRangeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <Row className="p-2 border rounded theme-bg-light">
      <Col md={7} lg={6}>
        <Row className="align-items-end">
          <Col md={7} lg={6} className="d-flex align-items-start">
            <div className="flex-grow-1">
              <Label className="form-label">Tarih Aralığı</Label>
              <Flatpickr
                className="form-control"
                value={[selectedDates.startDate, selectedDates.endDate]}
                onChange={(dates) => {
                  const [startDate, endDate] = dates;
                  if (startDate && endDate) {
                    const stringStartDate =
                      moment(startDate).format("YYYY-MM-DD");
                    const stringEndDate = moment(endDate).format("YYYY-MM-DD");
                    dispatch(
                      handleSelectedDates({
                        startDate: stringStartDate,
                        endDate: stringEndDate,
                      })
                    );
                  }
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  showMonths: 2,
                  locale: Turkish,
                  onDayCreate,
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuotaEntryDateRangeSelector;
