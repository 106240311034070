import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacilityImages,
  handleSaveOrderedFacilityImages,
  setAddImagesLoading,
  setAddImagesError,
  setUpdateImagesOrderLoading,
  setUpdateImagesOrderError,
  setDeleteImagesLoading,
  setDeleteImagesError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_FACILITY_IMAGES,
  ADD_FACILITY_IMAGES,
  DELETE_FACILITY_IMAGES,
  UPDATE_FACILITY_IMAGES_ORDER,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface DeleteFacilityImagesPayload {
  otel_id: number;
  resim_adi: string[];
}

interface UpdateFacilityImagesOrderPayload {
  otel_id: number;
  data: {
    resim_adi: string;
    sira: number;
  }[];
}

type SuccessCallback = () => void;

export const getFacilityImages = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_FACILITY_IMAGES + id);

    dispatch(handleSaveFacilityImages(response.data || response));
    dispatch(handleSaveOrderedFacilityImages(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addFacilityImages =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddImagesError(""));
      dispatch(setAddImagesLoading(true));

      await axios.post(ADD_FACILITY_IMAGES, payload);

      successCallback();
      dispatch(setToastSuccess("Tesis resmi eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddImagesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddImagesLoading(false));
    }
  };

export const updateFacilityImagesOrder =
  (payload: UpdateFacilityImagesOrderPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateImagesOrderError(""));
      dispatch(setUpdateImagesOrderLoading(true));

      await axios.post(UPDATE_FACILITY_IMAGES_ORDER, payload);

      dispatch(setToastSuccess("Tesis resmi sıralaması güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateImagesOrderError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateImagesOrderLoading(false));
    }
  };

export const deleteFacilityImages =
  (payload: DeleteFacilityImagesPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteImagesError(""));
      dispatch(setDeleteImagesLoading(true));

      await axios.delete(DELETE_FACILITY_IMAGES, { data: payload });

      successCallback();
      dispatch(setToastSuccess("Tesis resmi silindi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteImagesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteImagesLoading(false));
    }
  };
