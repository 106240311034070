import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveAdditionalPages,
  handleSaveGalleryImages,
  setIsLoading,
  setError,
  setAddAdditionalPageLoading,
  setAddAdditionalPageError,
  setUpdateAdditionalPageLoading,
  setUpdateAdditionalPageError,
  setDeleteAdditionalPageLoading,
  setDeleteAdditionalPageError,
  setAddGalleryImageLoading,
  setAddGalleryImageError,
  setDeleteGalleryImagesLoading,
  setDeleteGalleryImagesError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_ADDITIONAL_PAGES,
  UPDATE_ADDITIONAL_PAGE,
  ADD_ADDITIONAL_PAGE,
  DELETE_ADDITIONAL_PAGE,
  GET_GALLERY_IMAGES,
  ADD_GALLERY_IMAGE,
  DELETE_GALLERY_IMAGE,
} from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = (res?: any) => void;

export const getAdditionalPages =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const response = await axios.get(GET_ADDITIONAL_PAGES, {
        params: {
          otel_id: id,
        },
      });

      dispatch(handleSaveAdditionalPages(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getGalleryImages =
  (otel_id: number, sayfa_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_GALLERY_IMAGES}?otel_id=${otel_id}&sayfa_id=${sayfa_id}`
      );

      console.log("gallery images data in thunk: ", response);

      dispatch(handleSaveGalleryImages(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addAdditionalPage =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddAdditionalPageLoading(true));

      await axios.post(ADD_ADDITIONAL_PAGE, payload);

      successCallback();
      dispatch(setToastSuccess("Ek sayfa başarıyla eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddAdditionalPageError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddAdditionalPageLoading(false));
    }
  };

export const updateAdditionalPage =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateAdditionalPageLoading(true));

      await axios.post(UPDATE_ADDITIONAL_PAGE, payload);

      successCallback();
      dispatch(setToastSuccess("Ek sayfa başarıyla güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateAdditionalPageError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateAdditionalPageLoading(false));
    }
  };

export const deleteAdditionalPage =
  (
    payload: { otel_id: number; id: number },
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteAdditionalPageLoading(true));

      await axios.post(DELETE_ADDITIONAL_PAGE, payload);

      successCallback();
      dispatch(setToastSuccess("Ek sayfa silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteAdditionalPageError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteAdditionalPageLoading(false));
    }
  };

export const addNewGalleryImage =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddGalleryImageLoading(true));

      await axios.post(ADD_GALLERY_IMAGE, payload);

      successCallback();
      dispatch(setToastSuccess("Galeri resmi başarıyla eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddGalleryImageError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddGalleryImageLoading(false));
    }
  };

export const deleteGalleryImage =
  (
    payload: {
      otel_id: number;
      sayfa_id: number;
      resim_id: number;
    },
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteGalleryImagesLoading(true));

      await axios.post(DELETE_GALLERY_IMAGE, payload);

      successCallback();
      dispatch(setToastSuccess("Galeri resmi  silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteGalleryImagesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteGalleryImagesLoading(false));
    }
  };
