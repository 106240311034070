import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import useHandleList from "./useHandleList";

interface AuthorityAssigneeListProps {
  handleSelectUser: (userIds: number[]) => void;
}

const AuthorityAssigneeList = ({
  handleSelectUser,
}: AuthorityAssigneeListProps) => {
  const { userOptions, selectedOptions, handleSelectOptions } = useHandleList(handleSelectUser);

  return (
    <CustomFormFieldContainer
      label="Kullanıcı Seçin "
      description="En az bir kullanıcı eklemelisiniz."
      required
      divider
      orientation="vertical"
    >
      <CustomDualListBox
        listOptions={userOptions}
        selectedOptions={selectedOptions}
        handleSelectOptions={handleSelectOptions}
      />
    </CustomFormFieldContainer>
  );
};

export default AuthorityAssigneeList;
