import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityRoomsInitialState } from "slices/facility/facilityRooms/reducer";
import {
  deleteFacilityRoomImage,
  getFacilityRoom,
} from "slices/facility/facilityRooms/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandleList = (selectedRoom: number) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { facilityRoomImages, deleteFacilityRoomImageLoading } = useSelector(
    (state: { FacilityRooms: FacilityRoomsInitialState }) => state.FacilityRooms
  );
  const { otel_id } = hotel;

  const handelDeleteImage = (id: string) => {
    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_id", selectedRoom.toString());
    formData.append("oda_resim_sil", JSON.stringify(true));
    formData.append("oda_resim_sil_no", id);

    dispatch(
      deleteFacilityRoomImage(formData, () => {
        dispatch(getFacilityRoom({ otel_id, oda_id: selectedRoom }));
      })
    );
  };

  return {
    facilityRoomImages,
    handelDeleteImage,
    isLoading: deleteFacilityRoomImageLoading,
  };
};

export default useHandleList;
