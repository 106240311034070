import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ListFacilitiesByCityAndCategoryPayload } from "types/facilityCategorization";

interface Option {
  value: string;
  label: string;
}

interface CategorySelectorProps {
  options: Option[];
  values: ListFacilitiesByCityAndCategoryPayload;
  handleFormChange: (key: string, value: any) => void;
}

const CategorySelector = ({
  options,
  values,
  handleFormChange,
}: CategorySelectorProps) => {
  const selectedValue = options.find((option) => option.value === values.sinif);

  return (
    <CustomFormFieldContainer label={"Sınıflar : "}>
      <Select
        options={options}
        value={selectedValue}
        onChange={(selectedOption: Option) => {
          handleFormChange("sinif", selectedOption.value);
        }}
        placeholder="Sınıf seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default CategorySelector;
