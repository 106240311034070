import { CustomPieChart } from "Components/Custom/UI/CustomCharts";
import { AnalysisRate, AnalysisRateEmployeeInfo } from "types/adsAndAnalyze";
import { ANALYSIS_COLORS } from "utils/analysisColors";

const AnalysisRateCardRow = ({
  title,
  value,
  color,
}: {
  title: string;
  value: string | number;
  color: string;
}) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <span
        className="rounded-circle"
        style={{ width: 12, height: 12, backgroundColor: color }}
      ></span>
      <span className="fs-14">{title}:</span>
      <span className="fw-semibold fs-14">{value}</span>
    </div>
  );
};
const EmployeeAnalysisRateBadge = ({
  color,
  text,
}: {
  color: string;
  text: number;
}) => {
  return (
    <span
      className="d-flex align-items-center justify-content-center fs-12 rounded-pill text-white"
      style={{
        backgroundColor: color,
        width: 40,
        height: 20,
      }}
    >
      {text}
    </span>
  );
};
const EmployeeAnalysisRate = ({
  personel_isim,
  personel_oran,
  iyi_fiyatli_otel,
  kotu_fiyatli_otel,
  supheli_fiyatli_otel,
  degerlendirilmemis_oran,
  reklam,
  degerlendirilmis_otel,
}: AnalysisRateEmployeeInfo) => {
  return (
    <div className="d-flex justify-content-between align-items-start gap-2 py-2 border-bottom">
      <div className="d-flex flex-column gap-1">
        <span className="fs-14 fw-medium">{personel_isim}</span>
        <span className="fs-12">{personel_oran}</span>
      </div>
      <div className="d-flex gap-2">
        <EmployeeAnalysisRateBadge
          color={ANALYSIS_COLORS.blue}
          text={degerlendirilmis_otel}
        />
        <EmployeeAnalysisRateBadge
          text={iyi_fiyatli_otel}
          color={ANALYSIS_COLORS.green}
        />
        <EmployeeAnalysisRateBadge
          text={supheli_fiyatli_otel}
          color={ANALYSIS_COLORS.orange}
        />
        <EmployeeAnalysisRateBadge
          text={kotu_fiyatli_otel}
          color={ANALYSIS_COLORS.red}
        />
        <EmployeeAnalysisRateBadge
          text={reklam}
          color={ANALYSIS_COLORS.purple}
        />
        <EmployeeAnalysisRateBadge
          text={degerlendirilmemis_oran}
          color={ANALYSIS_COLORS.slate}
        />
      </div>
    </div>
  );
};

const AnalysisRateCard = ({
  kirmizi_yuzde_oran,
  yesil_yuzde_oran,
  toplam_otel,
  yesil_otel,
  kirmizi_otel,
  supheli_fiyatli_otel,
  reklam,
  degerlendirilmis_otel,
  degerlendirilmemis_otel,
  operasyon_yetkili_bilgileri,
}: AnalysisRate) => {
  const labels = ["İyi Fiyatlı Otel", "Kötü Fiyatlı Otel"];
  const series = [Number(yesil_yuzde_oran), Number(kirmizi_yuzde_oran)];
  const seriesColor = [ANALYSIS_COLORS.green, ANALYSIS_COLORS.red];

  return (
    <div className="d-flex flex-column gap-4 p-2 bg-white rounded border">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "1rem",
        }}
      >
        <div className="d-flex flex-column gap-2">
          <AnalysisRateCardRow
            title="Toplam Otel"
            value={toplam_otel}
            color={ANALYSIS_COLORS.gray}
          />
          <AnalysisRateCardRow
            title="İyi Fiyatlı Otel"
            value={yesil_otel}
            color={ANALYSIS_COLORS.green}
          />
          <AnalysisRateCardRow
            title="Kötü Fiyatlı Otel"
            value={kirmizi_otel}
            color={ANALYSIS_COLORS.red}
          />
          <AnalysisRateCardRow
            title="Şüpheli Fiyatlı Otel"
            value={supheli_fiyatli_otel}
            color={ANALYSIS_COLORS.orange}
          />
          <AnalysisRateCardRow
            title="Reklamı Açık Otel"
            value={reklam}
            color={ANALYSIS_COLORS.purple}
          />
          <AnalysisRateCardRow
            title="Değerlendirilmiş Otel"
            value={degerlendirilmis_otel}
            color={ANALYSIS_COLORS.blue}
          />
          <AnalysisRateCardRow
            title="Değerlendirilmemiş Otel"
            value={degerlendirilmemis_otel}
            color={ANALYSIS_COLORS.slate}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <CustomPieChart
            chartId="forecast-report-chart"
            series={series}
            labels={labels}
            chartHeight={200}
            chartType="pie"
            showLegend={false}
            legendPosition="bottom"
            colors={seriesColor}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-1">
        {operasyon_yetkili_bilgileri.map((employee, index) => (
          <EmployeeAnalysisRate key={index} {...employee} />
        ))}
      </div>
    </div>
  );
};

export default AnalysisRateCard;
