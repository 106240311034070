import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
    setAddUserLoading,
    setAddUserError,
    handleResetUserState,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { ADD_USER } from "helpers/url_helper";
import { Dispatch } from "redux";


export const addUser =
  (payload: FormData) => async (dispatch: Dispatch) => {
    
    try {
      dispatch(setAddUserLoading(true));
      await axios.post(ADD_USER, payload);

      dispatch(setToastSuccess("Kullanıcı eklendi"));
      dispatch(handleResetUserState());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddUserError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddUserLoading(false));
    }
  };
