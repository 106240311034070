import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  resetUpdateThemeAfterSave,
  handleSaveThemes,
  handleSaveThemeDetails,
  handleSaveUpdateThemeDefault,
  setGetThemeLoading,
  setGetThemeError,
  setUpdateThemeLoading,
  setUpdateThemeError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_THEMES, GET_THEME, UPDATE_THEME } from "helpers/url_helper";

import { UpdateThemeRequestPayload } from "types/themes";

import { Dispatch } from "redux";

interface GetThemePayload {
  tema_id: number;
}

type SuccessCallback = () => void;

export const getThemes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_THEMES);

    dispatch(handleSaveThemes(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getTheme =
  (payload: GetThemePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setGetThemeLoading(true));
      const response = await axios.get(GET_THEME, {
        params: {
          tema_id: payload.tema_id,
        },
      });

      dispatch(handleSaveThemeDetails(response.data || response));
      dispatch(handleSaveUpdateThemeDefault());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setGetThemeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setGetThemeLoading(false));
    }
  };

export const updateTheme =
  (payload: UpdateThemeRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateThemeLoading(true));
      await axios.post(UPDATE_THEME, payload);

      successCallback();
      dispatch(resetUpdateThemeAfterSave());
      dispatch(setToastSuccess("Tema güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateThemeError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateThemeLoading(false));
    }
  };
