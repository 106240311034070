import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  updateFacilityManager,
  getFacilityManagers,
} from "slices/facility/facilityManagers/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  UpdateFacilityManagerRequestPayload,
  FacilityManagerState,
} from "types/facility";

import {
  FacilityManagersInitialState,
  handleSaveFacilityManagerForEdit,
  resetFacilityManager,
} from "slices/facility/facilityManagers/reducer";
import { CustomButton } from "Components/Custom/UI";

interface UpdateManagerButtonProps {
  values: FacilityManagerState;
  requestPayload: UpdateFacilityManagerRequestPayload;
  validateForm: (values: FacilityManagerState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateManagerButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateManagerButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityManagers } = useSelector(
    (state: { FacilityManagers: FacilityManagersInitialState }) =>
      state.FacilityManagers
  );

  const selectedManager = facilityManagers.find(
    (manager) => manager.id === values.id
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const handleUpdateManager = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateFacilityManager(requestPayload, () => {
          dispatch(getFacilityManagers(otel_id));
        })
      );
    }
  };
  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="secondary"
        onClick={() => {
          dispatch(resetFacilityManager());
        }}
      >
        Düzenlemeden Çık
      </CustomButton>
      <CustomButton
        variant="danger"
        onClick={() => {
          dispatch(handleSaveFacilityManagerForEdit(selectedManager));
        }}
      >
        Temizle
      </CustomButton>
      <CustomButton
        variant="primary"
        onClick={handleUpdateManager}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateManagerButton;
