import { Room } from "types/PricesQuotas";
import HeaderContainer from "../../CommonContainers/HeaderContainer";

interface PriceEntryTableHeaderProps {
  room: Room;
}

const PriceEntryTableHeader = ({ room }: PriceEntryTableHeaderProps) => {
  if (!room) {
    return null;
  }
  return <HeaderContainer concepts={room.pansiyonlar} />;
};

export default PriceEntryTableHeader;
