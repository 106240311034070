import GoldMedal from "assets/images/competitor-analysis/gold-medal.png";
import SilverMedal from "assets/images/competitor-analysis/silver-medal.png";
import BronzeMedal from "assets/images/competitor-analysis/bronze-medal.png";
import OrdinaryMedal from "assets/images/competitor-analysis/ordinary-medal.png";

interface EmployeeCountCardProps {
  ad_soyad: string;
  otel_sayisi: number;
  order: number;
}

const EmployeeCountCard = ({
  ad_soyad,
  otel_sayisi,
  order,
}: EmployeeCountCardProps) => {
  const medal =
    order === 1
      ? GoldMedal
      : order === 2
      ? SilverMedal
      : order === 3
      ? BronzeMedal
      : OrdinaryMedal;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-start gap-2 border rounded bg-white position-relative py-3"
      style={{ width: "200px" }}
    >
      <img src={medal} alt="placement" width={50} height={50} />
      <p className="text-center fw-medium m-0 mt-1">{ad_soyad}</p>
      <p className="text-center fw-bold m-0 fs-20">{otel_sayisi}</p>
    </div>
  );
};

export default EmployeeCountCard;
