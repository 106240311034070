import {
  CustomDualListBox,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { CategorizeFacilityPayload } from "types/facilityCategorization";

interface Option {
  value: string;
  label: string;
}
interface FacilityListProps {
  values: CategorizeFacilityPayload;
  options: Option[];
  handleFormChange: (key: string, value: any) => void;
}

const FacilityList = ({
  values,
  options,
  handleFormChange,
}: FacilityListProps) => {

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const selected = (values.otel_idleri || []).map((id) => {
      const member = options[id];
      return member
        ? { value: id.toString(), label: member.label }
        : { value: id.toString(), label: "" };
    });

    setSelectedOptions(selected);
  }, [options, values.otel_idleri]);

  const handleSelectOptions = (selected: string[]) => {
    const hotelIds = selected.map((id) => Number(id));
    handleFormChange('otel_idleri', hotelIds);
  };

  return (
    <div style={{ margin: "16px 0" }}>
      <CustomFormFieldContainer
        label={"Tesisler : "}
        orientation="vertical"
        required
        divider
      >
        <CustomDualListBox
          listOptions={options}
          selectedOptions={selectedOptions.map((option) => option.value)}
          handleSelectOptions={handleSelectOptions}
        />
      </CustomFormFieldContainer>
    </div>
  );
};

export default FacilityList;
