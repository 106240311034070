import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  handleAddAllAccommodationTypes,
  handleChangeInput,
} from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { SaveRoomPersonPriceRateState } from "types/PricesQuotas";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useMemo } from "react";

interface SwitchesSectionProps {
  values: SaveRoomPersonPriceRateState;
  arrangeAccumodationTypes: (maxAdults: number, maxGuests: number) => string[];
}
const SwitchesSection = ({
  values,
  arrangeAccumodationTypes,
}: SwitchesSectionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const accumodationTypes = useMemo(
    () =>
      arrangeAccumodationTypes(
        values.uygulanabilecek_max_yetiskin,
        values.uygulanabilecek_max_konuk
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.uygulanabilecek_max_yetiskin, values.uygulanabilecek_max_konuk]
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Detaylı Oda / Kontenjan Ayarları"
        tooltipId="detailedRoomQuotaSettingsTooltip"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.detayli_oda_kontenjan}
            onChange={(e) => {
              const isChecked = e.target.checked;
              dispatch(
                handleChangeInput({
                  detayli_oda_kontenjan: isChecked,
                })
              );
              !isChecked &&
                dispatch(
                  handleChangeInput({
                    ozel_katsayi: false,
                  })
                );

              dispatch(handleAddAllAccommodationTypes(accumodationTypes));

              dispatch(
                handleChangeInput({
                  konaklama_cesitleri: {
                    ...values.konaklama_cesitleri,
                    ...accumodationTypes.reduce((acc, item) => {
                      acc[item] = 0;
                      return acc;
                    }, {} as { [key: string]: number }),
                  },
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.detayli_oda_kontenjan === true && (
        <CustomFormFieldContainer label="Özel Katsayı">
          <FormGroup switch>
            <Input
              type="switch"
              checked={values.ozel_katsayi}
              onChange={(e) => {
                dispatch(
                  handleChangeInput({
                    ozel_katsayi: e.target.checked,
                  })
                );
              }}
            />
          </FormGroup>
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default SwitchesSection;
