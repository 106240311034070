import { PhoneStatusPage } from "Components/Custom/ReportsPages";
import usePhoneStatus from "Components/Hooks/reports/usePhoneStatus";
import PageContainer from "pages/PageContainer";

const PhoneStatus = () => {
  const { isLoading } = usePhoneStatus();
  return (
    <PageContainer title="Dahili Durum">
      <PhoneStatusPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default PhoneStatus;
