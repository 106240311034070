import { CustomButton } from "Components/Custom/UI";

interface SaveChildInformationButtonProps {
  onSave: () => void;
}

const SaveChildInformationButton = ({
  onSave,
}: SaveChildInformationButtonProps) => {
  return (
    <div className="d-flex justify-content-end">
      <CustomButton
        variant="success"
        onClick={() => {
          onSave();
        }}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};
export default SaveChildInformationButton;
