import { useSelector } from "react-redux";
import { UpdateFacilityInitialState } from "slices/facility/updateFacility/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { facilityFormChoices } = useSelector(
    (state: { UpdateFacility: UpdateFacilityInitialState }) =>
      state.UpdateFacility
  );

  const showPriceListOptions: Option[] = [
    { value: "e", label: "Göster" },
    { value: "h", label: "Gösterme" },
  ];

  const showHotelLogoOptions: Option[] = [
    { value: "e", label: "Göster" },
    { value: "h", label: "Gösterme" },
  ];

  const showHotelAgentOptions: Option[] = [
    { value: "e", label: "Göster" },
    { value: "h", label: "Gösterme" },
  ];

  const supplierOptions: Option[] = facilityFormChoices.tedarikçi.map(
    (choice: string) => ({
      value: choice,
      label: choice.charAt(0).toUpperCase() + choice.slice(1),
    })
  );

  const priceProviderOptions: Option[] = facilityFormChoices[
    "Fiyat Sağlayıcısı"
  ].map((choice: string) => ({
    value: choice,
    label: choice.charAt(0).toUpperCase() + choice.slice(1),
  }));

  return {
    showPriceListOptions,
    showHotelLogoOptions,
    showHotelAgentOptions,
    supplierOptions,
    priceProviderOptions,
  };
};

export default useHandleForm;
