import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { setIsLoading, setError } from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_LOCATIONS, DELETE_LOCATION } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetRequestPayload {
  parent_id: number;
  successCallback: (response: any) => void;
}

interface DeleteRequestPayload {
  id: number;
}

export const getLocations =
  ({ parent_id, successCallback }: GetRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_LOCATIONS, {
        params: {
          parent_id,
        },
      });
      successCallback(response.data || response);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const deleteLocation =
  ({ id }: DeleteRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(DELETE_LOCATION, {
        id,
      });

      dispatch(setToastSuccess("Lokasyon silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
