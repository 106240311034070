import AdsReportSummary from "./AdsReportSummary";
import AdsReportTable from "./AdsReportTable";
import FilterForm from "./FilterForm";

interface AdsReportPageProps {
  isLoading: boolean;
  managersLoading: boolean;
  summaryLoading: boolean;
}

const AdsReportPage = ({ isLoading, summaryLoading }: AdsReportPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <AdsReportTable isLoading={isLoading} />
      <AdsReportSummary isLoading={summaryLoading} />
    </div>
  );
};

export default AdsReportPage;
