import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FACILITY_ADDRESS_DESCRIPTION } from "utils/fieldDescriptions";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";

interface FacilityAddressProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityAddress = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityAddressProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer
        label="Otel Adresi"
        error={formErrors.otel_adres}
        description={FACILITY_ADDRESS_DESCRIPTION}
        orientation="vertical"
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.otel_adres}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_adres: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_adres: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityAddress;
