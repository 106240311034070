import { useSelector } from "react-redux";

import { PhoneStatusListInitialState } from "slices/reports/phoneStatusList/reducer";

const useHandleTable = () => {
  const { phoneStatusList } = useSelector(
    (state: { PhoneStatusList: PhoneStatusListInitialState }) =>
      state.PhoneStatusList
  );

  const tableData = phoneStatusList?.map((phoneStatus) => ({
    "Üye ID": phoneStatus.uye_id,
    "Adı Soyadı": phoneStatus.personel_adsoyad,
    Dahili: phoneStatus.personel_dahili,
    Durum: phoneStatus.dahili_durum_ad,
    Tarih: phoneStatus.tarih,
    Başlangıç: phoneStatus.islem_zamani,
    "Önceki Durum": phoneStatus.dahili_durum_onceki_ad,
    "Önceki Süre Dakika": phoneStatus.dakika,
    "Önceki Süre Saniye": phoneStatus.saniye,
  }));

  const tableColumns = [
    {
      header: "Üye ID",
      accessorKey: "Üye ID",
      enableColumnFilter: false,
    },
    {
      header: "Adı Soyadı",
      accessorKey: "Adı Soyadı",
      enableColumnFilter: false,
    },
    {
      header: "Dahili",
      accessorKey: "Dahili",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "Durum",
      enableColumnFilter: false,
    },
    {
      header: "Tarih",
      accessorKey: "Tarih",
      enableColumnFilter: false,
    },
    {
      header: "Başlangıç",
      accessorKey: "Başlangıç",
      enableColumnFilter: false,
    },
    {
      header: "Önceki Durum",
      accessorKey: "Önceki Durum",
      enableColumnFilter: false,
    },
    {
      header: "Önceki Süre",
      accessorKey: "Önceki Süre Dakika",
      cell: (cell: any) => {
        const minutes = cell.row.original["Önceki Süre Dakika"];
        const seconds = cell.row.original["Önceki Süre Saniye"];

        return (
          <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {`${minutes ? minutes + " dakika" : ""} ${
              seconds ? seconds + " saniye" : ""
            }`}
          </span>
        );
      },

      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
