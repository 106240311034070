import { createSlice } from "@reduxjs/toolkit";

import { RiskyHotel } from "types/reports";

export interface RiskyHotelsInitialState {
  riskyHotels: RiskyHotel[];
  isLoading: boolean;
  error: string;
}

const initialState: RiskyHotelsInitialState = {
  riskyHotels: [],
  isLoading: false,
  error: "",
};

const riskyHotelsSlice = createSlice({
  name: "riskyHotels",
  initialState,
  reducers: {
    handleSaveRiskyHotels(state, action) {
      state.riskyHotels = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveRiskyHotels, setIsLoading, setError } =
  riskyHotelsSlice.actions;

export default riskyHotelsSlice.reducer;
