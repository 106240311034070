import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { BREAKING_DISCOUNTS_DESCRIPTIONS } from "utils/pageDescriptions";
import BreakingDiscountsForm from "./BreakingDiscountsForm";
import BreakingDiscountsTable from "./BreakingDiscountsTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./BreakingDiscountsForm/RoomSelector";

interface BreakingDiscountsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const BreakingDiscountsPage = (props: BreakingDiscountsPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={BREAKING_DISCOUNTS_DESCRIPTIONS}
      roomSelector={<RoomSelector {...props} />}
      isLoading={isLoading}
    >
      <BreakingDiscountsForm {...props} />
      <BreakingDiscountsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(BreakingDiscountsPage);
