import { CustomButton } from "Components/Custom/UI";
import {  GalleryImage } from "types/myWebSite";

interface AddGalleryImageButtonProps {
  values: GalleryImage;
  validateForm: (values: GalleryImage) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onAddGalleryImage: () => void;
  addGaleryImageLoading: boolean;
}

const AddGalleryImageButton = ({
  values,
  validateForm,
  handleSubmitted,
  onAddGalleryImage,
  addGaleryImageLoading,
}: AddGalleryImageButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);
    console.log("values : ", values)

    if (isValid) {
      onAddGalleryImage();
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={addGaleryImageLoading}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};

export default AddGalleryImageButton;
