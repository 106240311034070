import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { Input } from "reactstrap";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

const FilterForm = () => {
  const { filterForm, handleFormChange, onGetFilteredList } = useHandleForm();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormContainer>
      <div className="d-flex gap-4 flex-wrap align-items-end">
        <CustomFormFieldContainer label="Tarih" orientation="vertical">
          <Flatpickr
            className="form-control"
            value={filterForm.tarih}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleFormChange("tarih", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Rezervasyon Kodu"
          orientation="vertical"
        >
          <Input
            value={filterForm.rezervasyon_kodu}
            onChange={(e) =>
              handleFormChange("rezervasyon_kodu", e.target.value)
            }
            placeholder="Rezervasyon Kodu"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Müşteri Adı" orientation="vertical">
          <Input
            value={filterForm.musteri_ad_soyad}
            onChange={(e) =>
              handleFormChange("musteri_ad_soyad", e.target.value)
            }
            placeholder="Müşteri Adı"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="" orientation="vertical">
          <CustomButton variant="success" onClick={onGetFilteredList}>
            Filtrele
          </CustomButton>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default FilterForm;
