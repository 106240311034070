import React, { Dispatch, useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsInitialState } from "slices/userOperations/notifications/reducer";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "slices/userOperations/notifications/thunk";

const NotificationDropdown = () => {
  /* ----- Store Connection to UserOperations>Notifications for Notifications starts -----*/
  const dispatch: Dispatch<any> = useDispatch();

  const notifications = useSelector(
    (state: { Notifications: NotificationsInitialState }) =>
      state.Notifications.notifications
  );

  useEffect(() => {
    dispatch(
      getNotifications({
        per_page: 10,
        search: "",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unreadNotifications = notifications?.filter(
    (notification: any) => notification.okundu_durum !== "1"
  );

  const topFourUnreadNotifications = unreadNotifications?.slice(0, 4) || [];

  const navigate = useNavigate();

  const handleNavigateToPage = (url: string) => {
    navigate(`/${url}`);
  };

  const totalNotificationNumber = unreadNotifications?.length ?? 0;

  /* -----Store Connection to useroperations>notifications for Notifications ends --------*/

  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] =
    useState<boolean>(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const hasNotifications = unreadNotifications?.length > 0;

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle d-flex justify-content-center align-items-center"
        >
          <i className="bx bx-bell fs-22"></i>
          {hasNotifications && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {totalNotificationNumber}

              <span className="visually-hidden">okunmayan mesaj</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    Bildirimler
                  </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  {hasNotifications && (
                    <span className="badge bg-light-subtle fs-13 text-body">
                      {" "}
                      {unreadNotifications?.length ?? 0} Yeni
                    </span>
                  )}
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Tümü ({unreadNotifications?.length ?? 0})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Mesajlar
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Uyarılar
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                <div className="my-3 d-flex flex-column align-items-center">
                  <div className="w-100">
                    {topFourUnreadNotifications.map((notification) => (
                      <div
                        key={notification.id}
                        className="bg-light rounded px-2 py-1 mb-2 "
                      >
                        <p className="m-0">
                          <span className="text-danger me-2">*</span>

                          {notification.baslik}
                        </p>
                      </div>
                    ))}
                  </div>
                  <button
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light fw-semibold"
                    onClick={() => handleNavigateToPage("bildirimler")}
                  >
                    Tüm Bildirimleri Gör{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light fw-semibold"
                    onClick={() => handleNavigateToPage("bildirim-olustur")}
                  >
                    Bildirim Oluştur{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>

            <TabPane tabId="2" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                <div className="my-3 text-center">
                  <button
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light"
                    onClick={() => handleNavigateToPage("mesajlar")}
                  >
                    Tüm Mesajları Gör{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>
            <TabPane tabId="3" className="p-4">
              <div className="w-25 w-sm-50 pt-3 mx-auto">
                <img src={bell} className="img-fluid" alt="user-pic" />
              </div>
              <div className="text-center pb-5 mt-2">
                <h6 className="fs-18 fw-semibold lh-base">
                  {" "}
                  Hiçbir uyarı bulunamadı{" "}
                </h6>
              </div>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
