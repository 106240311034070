import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
  Months,
} from "types/PricesQuotas";

import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeGeneralPriceSettingsInput } from "slices/pricesAndQuota/generalPriceSettings/reducer";

interface KapidaPaymentTypeProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  monthsOptions: MonthsOption[];
  checkPaymentTypes: (
    kismi: boolean,
    kapida: boolean,
    kaporali: boolean
  ) => boolean;
}

interface MonthsOption {
  value: Months;
  label: string;
}

const KapidaPaymentType = ({
  values,
  formErrors,
  validateFormChange,
  monthsOptions,
  checkPaymentTypes,
}: KapidaPaymentTypeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Kapıda Ödeme">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.kapida_odeme === "evet"}
            onChange={(e) => {
              if (e.target.checked) {
                const valid = checkPaymentTypes(
                  values.kismi_odeme,
                  true,
                  values.kaporali_satis === "evet"
                );

                valid &&
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_odeme: e.target.checked ? "evet" : "hayir",
                    })
                  );
              } else {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    kapida_odeme: e.target.checked ? "evet" : "hayir",
                    kapida_baslangic_donemi: "",
                    kapida_bitis_donemi: "",
                  })
                );
              }
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.kapida_odeme === "evet" && (
        <>
          <CustomFormFieldContainer label="Kapıda Ödeme Komisyon Oranı">
            <InputGroup>
              <InputGroupText>%</InputGroupText>
              <Input
                type="number"
                value={values.kapida_odeme_komisyon_orani}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_odeme_komisyon_orani: parseInt(e.target.value),
                    })
                  );

                  validateFormChange({
                    ...values,
                    kapida_odeme_komisyon_orani: parseInt(e.target.value),
                  });
                }}
              />
            </InputGroup>
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Kapıda Ödeme Periyodu">
            <div className="d-flex align-items-center gap-3">
              <Select
                className="flex-grow-1"
                value={
                  monthsOptions.find(
                    (option) => option.value === values.kapida_baslangic_donemi
                  ) || null
                }
                onChange={(selectedOption: MonthsOption) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_baslangic_donemi: selectedOption.value,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kapida_baslangic_donemi: selectedOption.value,
                  });
                }}
                options={monthsOptions}
                placeholder="Periyod Seçiniz"
              />
              <Select
                className="flex-grow-1"
                value={
                  monthsOptions.find(
                    (option) => option.value === values.kapida_bitis_donemi
                  ) || null
                }
                onChange={(selectedOption: MonthsOption) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_bitis_donemi: selectedOption.value,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kapida_bitis_donemi: selectedOption.value,
                  });
                }}
                options={monthsOptions}
                placeholder="Periyod Seçiniz"
              />
            </div>
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Kapıda Ödeme Para Birimi Göster">
            <FormGroup switch>
              <Input
                type="switch"
                checked={values.kapida_odeme_para_birimi_goster}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_odeme_para_birimi_goster: e.target.checked,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kapida_odeme_para_birimi_goster: e.target.checked,
                  });
                }}
              />
            </FormGroup>
          </CustomFormFieldContainer>
          <CustomFormFieldContainer label="Kapıda Ödeme İndirim Oranı">
            <InputGroup>
              <InputGroupText>%</InputGroupText>
              <Input
                type="number"
                value={values.kapida_odeme_indirim_orani}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kapida_odeme_indirim_orani: parseInt(e.target.value),
                      kapida_komisyon_orani: parseInt(e.target.value),
                    })
                  );

                  validateFormChange({
                    ...values,
                    kapida_odeme_indirim_orani: parseInt(e.target.value),
                    kapida_komisyon_orani: parseInt(e.target.value),
                  });
                }}
              />
            </InputGroup>
          </CustomFormFieldContainer>
        </>
      )}
    </>
  );
};

export default KapidaPaymentType;
