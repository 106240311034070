import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AssignRecommendedHotelsInitialState } from "slices/themes/assignRecommendedHotels/reducer";

import {
  getRecommendedHotels,
  getThemes,
} from "slices/themes/assignRecommendedHotels/thunk";

const useAssignRecommendedHotels = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    themes,
    hotels,
    selectedTheme,
    isLoading,
    getRecommendedHotelsLoading,
  } = useSelector(
    (state: { AssignRecommendedHotels: AssignRecommendedHotelsInitialState }) =>
      state.AssignRecommendedHotels
  );

  const handleSelectTheme = (id: number) => {
    dispatch(getRecommendedHotels({ tema_id: id }));
  };

  useEffect(() => {
    dispatch(getThemes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themesOptions = themes.map((theme) => ({
    value: theme.tema_id,
    label: theme.baslik,
  }));

  return {
    hotels,
    selectedTheme,
    handleSelectTheme,
    themesOptions,
    isLoading: isLoading || getRecommendedHotelsLoading,
  };
};

export default useAssignRecommendedHotels;
