import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { handleSelectedHotels, PersonnelHotelAssignmentsInitialState } from "slices/userOperations/personnelHotelAssignments/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { assignHotelToUserFormChoices, selectedHotels } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const hotels = assignHotelToUserFormChoices.atanmamis_oteller;
  const [hotelOptions, setHotelOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = Object.entries(hotels).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    setHotelOptions(options);
  }, [hotels]);

  useEffect(() => {
    const selected = selectedHotels.map((id) => {
      const hotel = Object.entries(hotels).find(
        ([key]) => key === id.toString()
      );
      return hotel
        ? { value: hotel[0], label: hotel[1] }
        : { value: id.toString(), label: " " };
    });
    setSelectedOptions(selected);
  }, [selectedHotels, hotels]);

  const handleSelectOptions = (selected: string[]) => {
    const hotelIds = selected.map((id) => parseInt(id));
    dispatch(handleSelectedHotels(hotelIds));
  };

  return {
    hotelOptions,
    selectedOptions,
    handleSelectOptions,
  };
};

export default useHandleList;
