import { AnsweredCallsPage } from "Components/Custom/ReportsPages";
import useAnsweredCalls from "Components/Hooks/reports/useAnsweredCalls";
import PageContainer from "pages/PageContainer";

const AnsweredCalls = () => {
  const { isLoading } = useAnsweredCalls();

  return (
    <PageContainer title="Cevaplanan Çağrı Hareketleri">
      <AnsweredCallsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default AnsweredCalls;
