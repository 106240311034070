import { createSlice } from "@reduxjs/toolkit";
import {
  FacilityConcept,
  //   FacilityConceptName,
  //   UpdateFacilityConceptFormErrors,
  //   UpdateFacilityConceptState,
} from "types/facility";

export interface FacilityConceptsInitialState {
  facilityConcepts: FacilityConcept;
  isLoading: boolean;
  error: string;
  addConceptLoading: boolean;
  addConceptError: string;
  updateConceptLoading: boolean;
  updateConceptError: string;
}

const initialState: FacilityConceptsInitialState = {
  facilityConcepts: {
    datalar: [],
    mevcut_pansiyonlar: [],
  },
  isLoading: false,
  error: "",
  addConceptLoading: false,
  addConceptError: "",
  updateConceptLoading: false,
  updateConceptError: "",
};

const facilityConceptsSlice = createSlice({
  name: "facilityConcepts",
  initialState,
  reducers: {
    handleSaveFacilityConcepts: (state, action) => {
      state.facilityConcepts = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddConceptLoading: (state, action) => {
      state.addConceptLoading = action.payload;
    },
    setAddConceptError: (state, action) => {
      state.addConceptError = action.payload;
    },
    setUpdateConceptLoading: (state, action) => {
      state.updateConceptLoading = action.payload;
    },
    setUpdateConceptError: (state, action) => {
      state.updateConceptError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityConcepts,
  setIsLoading,
  setError,
  setAddConceptLoading,
  setAddConceptError,
  setUpdateConceptLoading,
  setUpdateConceptError,
} = facilityConceptsSlice.actions;

export default facilityConceptsSlice.reducer;
