import {
  CustomRowBadge,
  CustomRowOperationButton,
  CustomRowSpan,
} from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  handleDeleteFromHourlyDiscountsWithId,
  handleSaveHourlyDiscountForEdit,
} from "slices/discountsAndActions/hourlyDiscounts/reducer";
import { deleteHourlyDiscount } from "slices/discountsAndActions/hourlyDiscounts/thunk";
import { HourlyDiscount, HourlyDiscountState } from "types/DiscountsAndActions";

const useHandleTable = () => {
  const { hourlyDiscounts } = useSelector(
    (state: any) => state.HourlyDiscounts
  );

  const { hotel } = useSelector((state: any) => state.Hotel);

  const otel_id = hotel.otel_id;

  const concepts = hotel.pansiyonlar;

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row: any) => row.id === id);

    dispatch(handleSaveHourlyDiscountForEdit(selectedRow));
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload = {
      otel_id,
      saatlik_indirim_id: id,
    };
    dispatch(deleteHourlyDiscount(payload) as any);
    dispatch(handleDeleteFromHourlyDiscountsWithId(id));
  };

  const tableData: HourlyDiscountState[] = hourlyDiscounts?.map(
    (discount: HourlyDiscount) => {
      return {
        id: discount.id,
        otel_id: discount.otel_id,
        otel_oda_id: discount.otel_oda_id,
        grup_id: discount.grup_id,
        radio_gun: discount.tum_gunler === "1" ? 1 : 0,
        gun: JSON.parse(discount.gunler),
        indirim_orani: discount.indirim_orani,
        baslangic_saati: discount.baslangic_saati,
        bitis_saati: discount.bitis_saati,
        eb_birlikte: discount.eb_birlikte,
        kalode_birlikte: discount.kal_ode_birlikte,
        diger_birlikte: discount.diger_birlikte,
        tum_pansiyonlar: parseInt(discount.tum_otel_pansiyonlar),
        secili_pansiyonlar: discount.otel_pansiyonlar ?? [],
        tum_odeme_tipleri: discount.tum_odeme_tipleri,
        secili_odeme_tipleri: discount.odeme_tipleri ?? [],
      };
    }
  );

  const tableColumns = [
    {
      header: "Başlangıç - Bitiş Saati",
      cell: (cell: any) => (
        <div className="d-flex flex-column gap-1">
          <span>{cell.row.original.baslangic_saati}</span>
          <span>{cell.row.original.bitis_saati}</span>
        </div>
      ),
      accessorKey: "saat_araligi",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      cell: (cell: any) => (
        <span className="fw-medium">%{cell.row.original.indirim_orani}</span>
      ),
      accessorKey: "indirim_orani",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Günler",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 1} isYesNo />
      ),
      accessorKey: "radio_gun",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Günler",
      cell: (cell: any) => {
        const days: string[] = Object.values(cell.getValue());

        const radio_gun = cell.row.original.radio_gun;

        return (
          <span className="d-flex gap-2 flex-wrap">
            {radio_gun === 1 ? (
              <CustomRowBadge value="Tüm Günler" />
            ) : (
              days.map((day: string, index: number) => (
                <CustomRowBadge key={index} value={day} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "gun",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Odalar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 0} isYesNo />
      ),
      accessorKey: "grup_id",
      enableColumnFilter: false,
    },

    {
      header: "Tüm Pansiyonlar",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === 1} isYesNo />
      ),
      accessorKey: "tum_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Pansiyonlar",
      cell: (cell: any) => {
        const selectedConcepts = cell.getValue();
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedConcepts.length === 0 ? (
              <span>-</span>
            ) : (
              selectedConcepts.map((concept: string, index: number) => (
                <CustomRowBadge
                  key={index}
                  value={
                    concepts.find((item: any) => item.id === parseInt(concept))
                      ?.pansiyon_isim
                  }
                />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_pansiyonlar",
      enableColumnFilter: false,
    },
    {
      header: "Tüm Ödeme Tipleri",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />
      ),
      accessorKey: "tum_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Seçili Ödeme Tipleri",
      cell: (cell: any) => {
        const selectedPaymentTypes = cell.getValue();
        const allPaymentTypesSelected =
          cell.row.original.tum_odeme_tipleri === "1";
        return (
          <span className="d-flex gap-2 flex-wrap">
            {selectedPaymentTypes.length === 0 || allPaymentTypesSelected ? (
              <span>-</span>
            ) : (
              selectedPaymentTypes.map((paymentType: string, index: number) => (
                <CustomRowBadge key={index} value={paymentType} />
              ))
            )}
          </span>
        );
      },
      accessorKey: "secili_odeme_tipleri",
      enableColumnFilter: false,
    },
    {
      header: "Erken Rezervasyon Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "eb_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Kalode Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "kalode_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "Diğer Birlikte",
      cell: (cell: any) => (
        <CustomRowSpan condition={cell.getValue() === "1"} isTrueFalse />
      ),
      accessorKey: "diger_birlikte",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "operations",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
