import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveWebSite,
  handleSaveWebSiteForUpdate,
  handleSaveImageUrl,
  handleSaveCreateWebSiteFormChoices,
  setIsLoading,
  setError,
  setCreateWebSiteLoading,
  setCreateWebSiteError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_WEB_SITE, CREATE_WEB_SITE } from "helpers/url_helper";
import { Dispatch } from "redux";
import { WebSiteDataResponse } from "types/myWebSite";

type SuccessCallback = () => void;

export const getWebSite = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const res = await axios.get(GET_WEB_SITE, {
      params: {
        otel_id: id,
      },
    });

    const response = (res.data || res) as WebSiteDataResponse;

    const otelData = response.otel_data;
    const image_url = response.image_url;
    const formChoices = {
      temalar: response.temalar,
      otel_numara_goster: response.otel_numara_goster,
      reklam_popup: response.reklam_popup,
      one_cikan_ozellikler_list: response.one_cikan_ozellikler_list,
    };

    dispatch(handleSaveWebSite(otelData));
    dispatch(handleSaveWebSiteForUpdate(otelData));
    dispatch(handleSaveCreateWebSiteFormChoices(formChoices));
    dispatch(handleSaveImageUrl(image_url));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const createWebSite =
  (id: number, payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setCreateWebSiteLoading(true));

      // console.log("payload of createWebSite : ", payload);

      await axios.post(`${CREATE_WEB_SITE}?otel_id=${id}`, payload);

      successCallback();
      dispatch(setToastSuccess("Web Sitesi oluşturuldu."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCreateWebSiteError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCreateWebSiteLoading(false));
    }
  };
