import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

interface ScoreFieldRowProps {
  label: string;
  error: string;
  name: string;
  value: string;
  options: { label: string; value: number }[];
  onChange: (value: string) => void;
  required?: boolean;
}

const ScoreFieldRow = ({
  label,
  error,
  name,
  value,
  options,
  onChange,
  required = true,
}: ScoreFieldRowProps) => {
  return (
    <div>
      <Row>
        <Col md={4}>
          <Label className="form-label text-nowrap">
            {label}
            {required && <span className="theme-text-danger">*</span>}
          </Label>
        </Col>
        {options.map((option, index) => (
          <Col md={2} key={`${label}-${index}`}>
            <FormGroup check>
              <Input
                type="radio"
                name={name}
                id={`${label}-${index}`}
                value={option.value.toString()}
                checked={value === option.value.toString()}
                onChange={(e) => onChange(e.target.value)}
              />
              <Label check className="text-xs fw-normal" style={{ fontSize: '14px' }} htmlFor={`${label}-${index}`}>
                {option.label}
              </Label>
            </FormGroup>
          </Col>
        ))}
      </Row>
      {error && <div className="text-danger">{error}</div>}
    </div>
  );
};
export default ScoreFieldRow;
