import {
  GiftAndPromotionFormErrors,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";

import { Input, Label } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/discountsAndActions/giftsAndPromotions/reducer";

import { DB_WEEKDAYS_OPTIONS } from "utils/weekdays";
import Select from "react-select";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface ApplicableDaysProps {
  values: GiftAndPromotionState;
  formErrors: GiftAndPromotionFormErrors;
  validateFormChange: (values: GiftAndPromotionState) => void;
}

const ApplicableDays = ({
  values,
  validateFormChange,
  formErrors,
}: ApplicableDaysProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Uygulanabilir Günler"
      tooltipId="applicableDays"
      error={formErrors.gun}
    >
      <div className="d-flex gap-3">
        <div>
          <Input
            type="radio"
            id="allDays"
            name="applicableDays"
            checked={values.tum_gunler === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_gunler: values.tum_gunler === "1" ? "0" : "1",
                })
              );
              const allDays = DB_WEEKDAYS_OPTIONS.map((item) => item.value);
              dispatch(handleChangeInput({ gun: allDays }));
              validateFormChange({
                ...values,
                tum_gunler: values.tum_gunler === "1" ? "0" : "1",
                gunler: allDays as any,
              });
            }}
          />
          <Label className="ms-2" for="allDays">
            Tüm Günler
          </Label>
        </div>
        <div>
          <Input
            type="radio"
            id="specificDays"
            name="applicableDays"
            checked={values.tum_gunler === "0"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_gunler: values.tum_gunler === "0" ? "1" : "0",
                })
              );
            }}
          />
          <Label className="ms-2" for="specificDays">
            Belirli Günler
          </Label>
        </div>
      </div>
      {values.tum_gunler === "0" && (
        <div className="mt-2">
          <Select
            value={values.gunler.map((day: any) => {
              return DB_WEEKDAYS_OPTIONS.find(
                (item) => Object.keys(item.value)[0] === Object.keys(day)[0]
              );
            })}
            isMulti={true}
            onChange={(selectedMulti: any) => {
              const selectedDays = selectedMulti.map((item: any) => item.value);
              dispatch(handleChangeInput({ gunler: selectedDays }));
              validateFormChange({ ...values, gunler: selectedDays });
            }}
            options={DB_WEEKDAYS_OPTIONS}
            placeholder="Gün Seçiniz..."
          />
        </div>
      )}
    </CustomFormFieldContainer>
  );
};

export default ApplicableDays;
