import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NightRestrictionRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { NightRestrictionsInitialState } from "slices/discountsAndActions/nightRestrictions/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    nightRestriction,
    nightRestrictionFormErrors,
    addRestrictionLoading,
    updateRestrictionLoading,
  } = useSelector(
    (state: { NightRestrictions: NightRestrictionsInitialState }) =>
      state.NightRestrictions
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (hotel) {
      const newConceptsOptions: Option[] = hotel.pansiyonlar.map((concept) => ({
        value: concept.id + "",
        label: concept.pansiyon_isim,
      }));

      setConceptsOptions(newConceptsOptions);
    }
  }, [hotel]);

  const startDate = moment(nightRestriction.baslangic_tarih).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(nightRestriction.bitis_tarih).format("YYYY-MM-DD");

  const addNewNightRestrictionRequestPayload: NightRestrictionRequestPayload = {
    otel_id,
    otel_oda_id: oda_id,
    konaklama_tarihi: `${startDate} - ${endDate}`,
    min_gece_sayisi: nightRestriction.minimum_gece,
    giris_bazli: Number(nightRestriction.giris_bazli),
    radio_gun: nightRestriction.tum_gunler,
    gun: nightRestriction.gunler,
    yineleme: Number(nightRestriction.yineleme),
    toplam_gece: nightRestriction.toplam_geceden_hesaplama,
    grup_id: nightRestriction.grup_id,
    tum_pansiyonlar: Number(nightRestriction.tum_otel_pansiyonlar),
    secili_pansiyonlar: nightRestriction.otel_pansiyonlar,
  };

  const updateNightRestrictionRequestPayload: NightRestrictionRequestPayload = {
    gece_kisitlamalari_id: nightRestriction.id,
    ...addNewNightRestrictionRequestPayload,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    nightRestriction,
    conceptsOptions,
    addRestrictionLoading,
    updateRestrictionLoading,
    addNewNightRestrictionRequestPayload,
    updateNightRestrictionRequestPayload,
    formErrors: nightRestrictionFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
