import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import useHandleForm from "./useHandleForm";

interface FilterFormProps {
  defaultDates: {
    startDate: string;
    endDate: string;
  };
}

const FilterForm = ({ defaultDates }: FilterFormProps) => {
  const { filterForm, handleFormChange, onGetFilteredList, onResetFilter } =
    useHandleForm(defaultDates);

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormContainer>
      <div className="d-flex gap-4 flex-wrap align-items-end">
        <CustomFormFieldContainer label="Tarih" orientation="vertical">
          <Flatpickr
            className="form-control"
            value={[filterForm.start_date, filterForm.end_date]}
            onChange={(dates) => {
              const [startDate, endDate] = dates;

              if (startDate && endDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");
                const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                handleFormChange("start_date", stringStartDate);
                handleFormChange("end_date", stringEndDate);
              }
            }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
            }}
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer label="" orientation="vertical">
          <div className="d-flex gap-2">
            <CustomButton variant="warning" onClick={onResetFilter}>
              Filtreyi Temizle
            </CustomButton>
            <CustomButton variant="success" onClick={onGetFilteredList}>
              Filtrele
            </CustomButton>
          </div>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default FilterForm;
