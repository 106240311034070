import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { logoutUser } from "../../slices/thunks";
import withRouter from "../../Components/Common/withRouter";
import Cookies from "js-cookie";

const Logout = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const tokenFromCookie = Cookies.get("token");

  const [token, setToken] = useState(tokenFromCookie);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    token && dispatch(logoutUser());
    setToken(undefined);

    setTimeout(() => {
      setIsLoggedOut(true);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedOut) {
      window.location.reload();
    }
  }, [isLoggedOut]);

  if (!token) {
    return <Navigate to="/giris" />;
  }

  return <></>;
};

export default withRouter(Logout);
