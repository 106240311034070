import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ChildSpecialAgeInformationTableProps {
  isLoading: boolean;
}

const ChildSpecialAgeInformationTable = ({
  isLoading,
}: ChildSpecialAgeInformationTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();
  return (
    <CustomReactTable
      tableData={tableData}
      tableColumns={tableColumns}
      legend={legend}
      loading={isLoading}
    />
  );
};

export default ChildSpecialAgeInformationTable;
