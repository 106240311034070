import {
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import UrlField from "./UrlField";
import useHandleForm from "./useHandleForm";
import { FACILITY_PULL_IMAGES_FORM_URL_DESCRIPTIONS } from "utils/pageDescriptions";
import PullImagesFromUrlButton from "./PullImagesFromUrlButton";

const FacilityPullImageFormUrlForm = () => {
  const {
    facilityPullImageFormUrl,
    facilityImagesRequestPayload,
    isLoading,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <>
      <CustomFormContainer title="Tesise Resim Çekme" lg={12} xl={12}>
        <div className="d-flex flex-column gap-2 bg-white px-4 py-2 rounded">
          <CustomPageDescriptions
            descriptions={FACILITY_PULL_IMAGES_FORM_URL_DESCRIPTIONS}
          />
        </div>
        <UrlField
          values={facilityPullImageFormUrl}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <PullImagesFromUrlButton
          values={facilityPullImageFormUrl}
          requestPayload={facilityImagesRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={isLoading}
        />
      </CustomFormContainer>
    </>
  );
};

export default FacilityPullImageFormUrlForm;
