import { CustomLoadingPage } from "Components/Custom/UI";
import AddEarlyReservationDefinitionForm from "./AddEarlyReservationDefinitionForm";

interface AddEarlyReservationDefinitionPageProps {
  isLoading: boolean;
}

const AddEarlyReservationDefinitionPage = ({
  isLoading,
}: AddEarlyReservationDefinitionPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <AddEarlyReservationDefinitionForm />
    </>
  );
};

export default AddEarlyReservationDefinitionPage;
