import FilterForm from "./FilterForm";
import OutboundCallsListWithNumberTable from "./OutboundCallsListWithNumberTable";

interface OutboundCallsListWithNumberPageProps {
  isLoading: boolean;
  defaultDates: {
    startDate: string;
    endDate: string;
  };
}

const OutboundCallsListWithNumberPage = ({
  isLoading,
  defaultDates,
}: OutboundCallsListWithNumberPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm defaultDates={defaultDates} />
      <OutboundCallsListWithNumberTable isLoading={isLoading} />
    </div>
  );
};

export default OutboundCallsListWithNumberPage;
