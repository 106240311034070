import RoomSelector from "./RoomSelector";
import { CustomFormContainer } from "Components/Custom/UI";
import ChildSpecialAgeInformationFields from "./ChildSpecialAgeInformationFields";
import useHandleForm from "./useHandleForm";
import AddNewChildSpecialAgeInformationButton from "./AddNewChildSpecialAgeInformationButton";
import UpdateChildSpecialAgeInformationButton from "./UpdateChildSpecialAgeInformationButton";

interface ChildSpecialAgeInformationPageFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const ChildSpecialAgeInformationPageForm = (
  props: ChildSpecialAgeInformationPageFormProps
) => {
  const {
    childSpecialAgeInformationState,
    nightCountOptions,
    formErrors,
    handleSave,
    handleUpdate,
    isLoading,
    validateFormChange,
  } = useHandleForm(props.hotelAndSelectedRoom);

  const updatedChildSpecialAgeInformationState = {
    ...childSpecialAgeInformationState,
    yas_genel_bilgileri: null,
    cocuk_ozel_yas_bilgileri: null,
  };

  return (
    <>
      <RoomSelector {...props} />
      <CustomFormContainer>
        <ChildSpecialAgeInformationFields
          childSpecialAgeInformationState={
            updatedChildSpecialAgeInformationState
          }
          nightCountOptions={nightCountOptions}
          validateFormChange={validateFormChange}
          formErrors={formErrors}
        />
        {childSpecialAgeInformationState.id ? (
          <UpdateChildSpecialAgeInformationButton
            onUpdate={handleUpdate}
            isLoading={isLoading}
          />
        ) : (
          <AddNewChildSpecialAgeInformationButton
            isLoading={isLoading}
            onSave={handleSave}
          />
        )}
      </CustomFormContainer>
    </>
  );
};

export default ChildSpecialAgeInformationPageForm;
