import { Row } from "reactstrap";
import { Hotel } from "types/PricesQuotas";
import AvailabilityEntryItem from "./AvailabilityEntryItem";
import AvailabilityEntryTableHeader from "./AvailabilityEntryTableHeader";
import SelectAllAvailabilities from "./SelectAllAvailabilities";
import { useState } from "react";
import SaveAvailabilitiesButton from "../SaveAvailabilitiesButton";

interface AvailabiltyEntryContainerProps {
  data: Hotel;
  onSave: () => void;
  isLoading: boolean;
}

interface AllSelected {
  [key: number]: boolean;
}

const AvailabiltyEntryContainer = ({
  data,
  onSave,
  isLoading,
}: AvailabiltyEntryContainerProps) => {
  const [allSelected, setAllSelected] = useState<AllSelected>({});

  const handleAddAllSelected = (key: number, value: boolean) => {
    setAllSelected((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const changeOtherInputsAfterSelectAll = (key: number, value: boolean) => {
    handleAddAllSelected(key, value);
  };

  return (
    <Row className="border border-2 border-white overflow-hidden rounded my-3">
      <AvailabilityEntryTableHeader
        room={data.odalar[0]}
        onLoad={handleAddAllSelected}
      />
      <SelectAllAvailabilities
        data={data}
        onSelect={changeOtherInputsAfterSelectAll}
      />
      {data.odalar.map((room, index) => (
        <AvailabilityEntryItem
          key={index}
          room={room}
          index={index}
          allSelected={allSelected}
        />
      ))}
      <SaveAvailabilitiesButton isLoading={isLoading} onSave={onSave} />
    </Row>
  );
};

export default AvailabiltyEntryContainer;
