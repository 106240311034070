import { STOPS } from "enums";
import CustomRadioButton from "../CustomRadioButton";

interface CustomAvailabilityRadioInputProps {
  name: string;
  availability: STOPS;
  value: STOPS;
  onChange: (availability: STOPS) => void;
  notAllowed: boolean;
  disabled?: boolean;
}

const CustomAvailabilityRadioInput = ({
  name,
  value,
  availability,
  onChange,
  notAllowed,
}: CustomAvailabilityRadioInputProps) => {
  const color =
    value === STOPS.SATISA_ACIK
      ? "var(--theme-success)"
      : value === STOPS.SOR_SAT
      ? "var(--theme-warning)"
      : value === STOPS.STOPLU
      ? "var(--theme-danger)"
      : "var(--theme-secondary)";

  return (
    <CustomRadioButton
      label=""
      name={name}
      value={value}
      checked={availability === value}
      onChange={() => {
        onChange(value);
      }}
      color={color}
      disabled={notAllowed}
    />
  );
};

export default CustomAvailabilityRadioInput;
