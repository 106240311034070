import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  RoomConceptMatchInitialState,
  handleSaveSupplierConcepts,
  handleSaveSelectedSupplierConcepts,
  handleSaveInetConcepts,
} from "slices/facility/roomConceptMatch/reducer";

import {
  AddConceptMatchRequestPayload,
  RemoveConceptMatchRequestPayload,
} from "types/facility";

import { HotelInitialState } from "slices/hotel/reducer";
import {
  addConceptMatch,
  removeConceptMatch,
  getRoomConceptMatchList,
} from "slices/facility/roomConceptMatch/thunk";
import { useEffect, useState } from "react";

interface Item {
  id: string;
  text: string;
}

const useHandleList = () => {
  const { supplierConcepts, selectedSupplierConcepts, inetConcepts } =
    useSelector(
      (state: { RoomConceptMatch: RoomConceptMatchInitialState }) =>
        state.RoomConceptMatch
    );

  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const selectedSupplierConceptsLength = selectedSupplierConcepts.length;

  const [matchStatus, setMatchStatus] = useState(
    Array.from({ length: selectedSupplierConceptsLength }).map((_, index) => ({
      order: index + 1,
      status: selectedSupplierConcepts[index].id === inetConcepts[index].id,
    }))
  );

  useEffect(() => {
    const length = selectedSupplierConcepts.length;
    setMatchStatus(
      Array.from({ length }).map((_, index) => ({
        order: index + 1,
        status: selectedSupplierConcepts[index].id === inetConcepts[index].id,
      }))
    );
  }, [selectedSupplierConcepts, inetConcepts]);

  const handleAddConceptMatch = (
    selectedSupplierConcept: Item,
    inetConcept: Item
  ) => {
    const payload: AddConceptMatchRequestPayload = {
      otel_id,
      pansiyon_id: Number(inetConcept.id),
      tedarikci_id: Number(selectedSupplierConcept.id),
      tedarikci_ad: selectedSupplierConcept.text,
    };

    dispatch(addConceptMatch(payload));
    setTimeout(() => {
      dispatch(getRoomConceptMatchList(otel_id));
    }, 1000);
  };

  const handleRemoveConceptMatch = (inetConcept: Item) => {
    const payload: RemoveConceptMatchRequestPayload = {
      otel_id,
      pansiyon_id: Number(inetConcept.id),
      tedarikci_id: null,
      tedarikci_ad: null,
    };

    dispatch(removeConceptMatch(payload));
    setTimeout(() => {
      dispatch(getRoomConceptMatchList(otel_id));
    }, 1000);
  };

  const handleChangeSupplierConcepts = (concepts: Item[]) => {
    dispatch(handleSaveSupplierConcepts(concepts));
  };

  const handleChangeSelectedSupplierConcepts = (concepts: Item[]) => {
    dispatch(handleSaveSelectedSupplierConcepts(concepts));
  };

  const handleChangeInetConcepts = (concepts: Item[]) => {
    dispatch(handleSaveInetConcepts(concepts));
  };

  const checkItemIsMatched = (item: Item) => {
    const itemIsMatched = inetConcepts.some(
      (inetConcept) => Number(inetConcept.id) === Number(item.id)
    );

    return {
      status: itemIsMatched,
      errorMessage: itemIsMatched ? "Önce eşleşmeyi kaldırın!" : "",
    };
  };

  return {
    supplierConcepts,
    selectedSupplierConcepts,
    inetConcepts,
    handleChangeSupplierConcepts,
    handleChangeSelectedSupplierConcepts,
    handleChangeInetConcepts,
    onAddConceptMatch: handleAddConceptMatch,
    onRemoveConceptMatch: handleRemoveConceptMatch,
    matchStatus,
    checkItemIsMatched,
  };
};

export default useHandleList;
