import { createSlice } from "@reduxjs/toolkit";
import {
  RoomSalesAndPaymentMethods,
  RoomSalesAndPaymentMethodsState,
} from "types/PricesQuotas";

export interface RoomSalesAndPaymentMethodsInitialState {
  roomSalesAndPaymentMethod: RoomSalesAndPaymentMethods;
  roomSalesAndPaymentMethodState: RoomSalesAndPaymentMethodsState;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addMethodLoading: boolean;
  addMethodError: string;
}

const initialState: RoomSalesAndPaymentMethodsInitialState = {
  selectedRoom: 0,
  roomSalesAndPaymentMethod: {
    odeme: {
      normal: false,
      iptal_edilemez: false,
      kapida: undefined,
      kaporali: undefined,
      kismi: undefined,
    },
    fiyat_durum: {
      kapida: undefined,
      kaporali: undefined,
      kismi: undefined,
      iptal: undefined,
    },
    oranlar: {
      iptal: "",
    },
  },
  roomSalesAndPaymentMethodState: {
    odeme: {
      normal: false,
      iptal_edilemez: false,
      kapida: undefined,
      kaporali: undefined,
      kismi: undefined,
    },
    fiyat_durum: {
      kapida: undefined,
      kaporali: undefined,
      kismi: undefined,
      iptal: undefined,
    },
    oranlar: {
      iptal: "",
    },
  },
  isLoading: false,
  error: "",
  addMethodLoading: false,
  addMethodError: "",
};

const roomSalesAndPaymentMethodsSlice = createSlice({
  name: "roomSalesAndPaymentMethods",
  initialState,
  reducers: {
    handleSaveRoomSalesAndPaymentMethods(state, action) {
      state.roomSalesAndPaymentMethod = action.payload;
    },
    handleSaveRoomSalesAndPaymentMethodsState(state, action) {
      state.roomSalesAndPaymentMethodState = action.payload;
    },

    resetRoomSalesAndPaymentMethodsState(state) {
      state.roomSalesAndPaymentMethodState = state.roomSalesAndPaymentMethod;
    },
    handleChangePaymentTypes(state, action) {
      state.roomSalesAndPaymentMethodState = {
        ...state.roomSalesAndPaymentMethodState,
        odeme: {
          ...state.roomSalesAndPaymentMethodState.odeme,
          ...action.payload,
        },
      };
    },
    handleChangePaymentStatus(state, action) {
      state.roomSalesAndPaymentMethodState = {
        ...state.roomSalesAndPaymentMethodState,
        fiyat_durum: {
          ...state.roomSalesAndPaymentMethodState.fiyat_durum,
          ...action.payload,
        },
      };
    },
    hadleChangeUncancelableRate(state, action) {
      state.roomSalesAndPaymentMethodState = {
        ...state.roomSalesAndPaymentMethodState,
        oranlar: {
          iptal: action.payload,
        },
      };
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddMethodLoading(state, action) {
      state.addMethodLoading = action.payload;
    },
    setAddMethodError(state, action) {
      state.addMethodError = action.payload;
    },
  },
});

export const {
  handleSaveRoomSalesAndPaymentMethods,
  handleSaveRoomSalesAndPaymentMethodsState,
  resetRoomSalesAndPaymentMethodsState,
  handleChangePaymentTypes,
  handleChangePaymentStatus,
  hadleChangeUncancelableRate,
  handleSelectRoom,
  setIsLoading,
  setError,
  setAddMethodLoading,
  setAddMethodError,
} = roomSalesAndPaymentMethodsSlice.actions;

export default roomSalesAndPaymentMethodsSlice.reducer;
