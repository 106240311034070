import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddUserInitialState } from "slices/userOperations/addUser/reducer";
import { getUsers } from "slices/userOperations/listUsers/thunk";

const useAddUser = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { AddUser: AddUserInitialState }) => state.AddUser
  );

  
  useEffect(() => {
    dispatch(
      getUsers({
        page: 1,
        per_page: 10,
        search :""
      })
    );
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useAddUser;
