import { useSelector } from "react-redux";
import { AddFacilityInitialState } from "slices/facility/addFacility/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { facilityFormChoices } = useSelector(
    (state: { AddFacility: AddFacilityInitialState }) => state.AddFacility
  );
  const statusOptions: Option[] = [
    { value: "1", label: "Aktif" },
    { value: "0", label: "Pasif" },
  ];
  const showPriceListOptions: Option[] = [
    { value: "e", label: "Göster" },
    { value: "h", label: "Gösterme" },
  ];
  const supplierOptions: Option[] = facilityFormChoices.tedarikçi.map(
    (choice: string) => ({
      value: choice,
      label: choice.charAt(0).toUpperCase() + choice.slice(1),
    })
  );

  const welcomeCodeOptions: Option[] = facilityFormChoices[
    "Karşılama_Kodu"
  ].map((choice: string) => ({
    value: choice,
    label: choice.charAt(0).toUpperCase() + choice.slice(1),
  }));

  return {
    statusOptions,
    showPriceListOptions,
    supplierOptions,
    welcomeCodeOptions,
  };
};

export default useHandleForm;
