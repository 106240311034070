import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dispatch } from "redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import {
  getReservationCall,
  getReservationCallChildrenInfo,
} from "slices/reservation/reservationCall/thunk";

const useReservationCall = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const validCall = !!arayan && !!dahili && !!aranan;

  const params = {
    aranan,
    arayan,
    dahili,
  };

  useEffect(() => {
    if (validCall) {
      const params = {
        aranan,
        arayan,
        dahili,
      };

      dispatch(
        getReservationCall(params, (otel_adi?: string) => {
          otel_adi && dispatch(getReservationCallChildrenInfo(otel_adi));
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { validCall, params, isLoading };
};

export default useReservationCall;
