import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleAddSpecialDiscountFormErrors } from "slices/discountsAndActions/specialDiscounts/reducer";
import { setToastError } from "slices/toast/reducer";
import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: SpecialDiscountState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: SpecialDiscountFormErrors = {};

    if (!values.rez_tarihi_baslangic || !values.rez_tarihi_bitis) {
      errors.rez_tarihi =
        "Rezervasyon tarihi başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (!values.konaklama_tarihi_baslangic || !values.konaklama_tarihi_bitis) {
      errors.konaklama_tarihi =
        "Konaklama tarihi başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (!values.min_gece_sayisi) {
      errors.min_gece_sayisi = "Minimum gece sayısı alanı boş bırakılamaz.";
    }
    if (!values.min_gece_sayisi || values.min_gece_sayisi < 1) {
      errors.min_gece_sayisi = "Minimum gece sayısı 1'den küçük olamaz.";
    }

    if (!values.ozel_oran) {
      errors.ozel_oran = "İndirim oranı alanı boş bırakılamaz.";
    }

    if (!values.ozel_oran || values.ozel_oran < 0) {
      errors.ozel_oran = "İndirim oranı 0'dan küçük olamaz.";
    }

    if (values.ozel_oran > 100) {
      errors.ozel_oran = "İndirim oranı 100'den büyük olamaz.";
    }

    if (
      values.giris_gunu_durum === "1" &&
      (!values.giris_gunu_baslangic_tarihi || !values.giris_gunu_bitis_tarihi)
    ) {
      errors.giris_gunu =
        "Giriş günü başlangıç ve bitiş alanları boş bırakılamaz.";
    }

    if (
      values.giris_gunu_durum === "0" &&
      values.radio_gun === "0" &&
      values.gun.length === 0
    ) {
      errors.gun = "Günlerden en az biri seçilmelidir.";
    }

    if (values.indirim_tipi === "0") {
      errors.indirim_tipi = "İndirim tipi alanı boş bırakılamaz.";
    }
    if (values.yalniz_bu_otel === 0 && values.secili_oteller.length === 0) {
      errors.secili_oteller = "En az bir otel seçmelisiniz.";
    }

    if (
      values.tum_pansiyonlar === 0 &&
      values.secili_pansiyonlar.length === 0
    ) {
      errors.secili_pansiyonlar = "En az bir pansiyon seçmelisiniz.";
    }

    if (
      values.tum_odeme_tipleri === 0 &&
      values.secili_odeme_tipleri.length === 0
    ) {
      errors.secili_odeme_tipleri = "En az bir ödeme tipi seçmelisiniz.";
    }

    dispatch(handleAddSpecialDiscountFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: SpecialDiscountState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
