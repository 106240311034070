import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetChildSpecialAgeInformation } from "slices/pricesAndQuota/childSpecialAgeInformation/reducer";

interface UpdateChildSpecialAgeInformationButtonProps {
  onUpdate: () => void;
  isLoading: boolean;
}
const UpdateChildSpecialAgeInformationButton = ({
  onUpdate,
  isLoading,
}: UpdateChildSpecialAgeInformationButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="secondary"
        onClick={() => dispatch(resetChildSpecialAgeInformation())}
      >
        İptal
      </CustomButton>
      <CustomButton variant="success" isLoading={isLoading} onClick={onUpdate}>
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateChildSpecialAgeInformationButton;
