import { CustomAvailabilityRadioInput } from "Components/Custom/UI";
import { STOPS } from "enums";
import { Col, Label, Row } from "reactstrap";

interface AvailabilityFieldProps {
  values: any;
  onChange: (name: string, availability: STOPS) => void;
  notAllowedToEdit: boolean;
}

const AvailabilityField = ({
  values,
  onChange,
  notAllowedToEdit,
}: AvailabilityFieldProps) => {
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <Label>Müsaitlik</Label>
      </Col>
      <Col xs={6}>
        <div className="d-flex gap-1">
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.SATISA_ACIK}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={!notAllowedToEdit}
            notAllowed={false}
          />
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.SOR_SAT}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={!notAllowedToEdit}
            notAllowed={false}
          />
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.STOPLU}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={!notAllowedToEdit}
            notAllowed={false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AvailabilityField;
