import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";

import { handleChangeInput } from "slices/discountsAndActions/specialDiscounts/reducer";
import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface DiscountRateProps {
  values: SpecialDiscountState;
  formErrors: SpecialDiscountFormErrors;
  validateFormChange: (values: SpecialDiscountState) => void;
}

const DiscountRate = ({
  values,
  validateFormChange,
  formErrors,
}: DiscountRateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Minimum Gece"
        error={formErrors.min_gece_sayisi}
      >
        <CustomNumberSelector
          value={values.min_gece_sayisi}
          onChange={(value) => {
            dispatch(handleChangeInput({ min_gece_sayisi: value }));
            validateFormChange({ ...values, min_gece_sayisi: value });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="İndirim Oranı"
        error={formErrors.ozel_oran}
      >
        <InputGroup>
          <InputGroupText>
            <i className="ri-percent-line"></i>
          </InputGroupText>
          <Input
            type="number"
            min={0}
            max={100}
            value={values.ozel_oran}
            onChange={(e) => {
              dispatch(
                handleChangeInput({ ozel_oran: parseInt(e.target.value) })
              );
              validateFormChange({
                ...values,
                ozel_oran: parseInt(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountRate;
