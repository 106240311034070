import { createSlice } from "@reduxjs/toolkit";

import { DailyCallPerPerson } from "types/reports";

export interface DailyCallsPerPersonInitialState {
  dailyCallsPerPerson: DailyCallPerPerson[];
  isLoading: boolean;
  error: string;
}

const initialState: DailyCallsPerPersonInitialState = {
  dailyCallsPerPerson: [],
  isLoading: false,
  error: "",
};

const dailyCallsPerPersonSlice = createSlice({
  name: "dailyCallsPerPerson",
  initialState,
  reducers: {
    handleSaveDailyCallsPerPerson(state, action) {
      state.dailyCallsPerPerson = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveDailyCallsPerPerson, setIsLoading, setError } =
  dailyCallsPerPersonSlice.actions;

export default dailyCallsPerPersonSlice.reducer;