import axios from "axios";

import {
  setIsLoading,
  setError,
  setPeriodsLoading,
  setPeriodsError,
  handleSavePeriods,
  handleSavePeriodsPeriod,
} from "./reducer";
import { handleErrors } from "helpers/axios_helpers";
import { GET_PERIODS, POST_PERIODS } from "helpers/url_helper";
import { Period } from "types/PricesQuotas";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

interface SavePeriodsRequestPayload {
  otel_id: number;
  oda_id: number;
  pansiyon_id: number;
  geceleme_gunu: number;
  cocuk_ozel_yas_id: number;
  veriler: Period[];
}

interface RequestPayload {
  otel_id: number;
  oda_id: number;
  pansiyon_id: number;
  tarihler: string;
  tum_gunler: number;
  gunler: number[];
  geceleme_gunu: number;
  cocuk_ozel_yas_id: number;
}

type SuccessCaalback = () => void;

export const savePeriods =
  (payload: SavePeriodsRequestPayload, successCallback: SuccessCaalback) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(POST_PERIODS, payload);

      successCallback();
      dispatch(handleSavePeriodsPeriod(payload.veriler));
      dispatch(setToastSuccess("Periyodlar kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getPeriods =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setPeriodsLoading(true));
      const response = await axios.post(GET_PERIODS, payload);

      const res = response.data || response;

      dispatch(handleSavePeriods(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setPeriodsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setPeriodsLoading(false));
    }
  };
