import { CustomButton } from "Components/Custom/UI";
import { FilePondFile } from "filepond";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addPlaceToVisit } from "slices/definitions/addPlaceToVisit/thunk";

import {
  AddPlaceToVisitRequestPayload,
  AddPlaceToVisitState,
} from "types/definitions";

interface AddPlaceToVisitButtonProps {
  values: AddPlaceToVisitState;
  requestPayload: AddPlaceToVisitRequestPayload;
  validateForm: (values: AddPlaceToVisitState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddPlaceToVisitButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddPlaceToVisitButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddPlaceToVisit = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      requestPayload.append("yer_adi", values.yer_adi);
      requestPayload.append("aciklama", values.aciklama);

      const file = values.file as FilePondFile;
      requestPayload.append("file", file.file, file.file.name);

      dispatch(addPlaceToVisit(requestPayload));
    }
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton
        variant="success"
        onClick={handleAddPlaceToVisit}
        isLoading={isLoading}
      >
        Yeni Yer Ekle
      </CustomButton>
    </div>
  );
};

export default AddPlaceToVisitButton;
