import { useEffect, useState } from "react";

const CustomColumnFilter = ({ column }: { column: any; table: any }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Ara..."
        className="border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="search"
      style={{
        appearance: "none",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        borderRadius: "4px",
        padding: "0.375rem 0.75rem",
        fontSize: ".875rem",
        height: "32px",
        marginTop: "0.3rem",
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default CustomColumnFilter;
