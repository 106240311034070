import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { MailOrderInfoInitialState } from "slices/financeAndAccounting/mailOrderInfo/reducer";
import { getMailOrders } from "slices/financeAndAccounting/mailOrderInfo/thunk";

const useMailOrderInfo = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, mailOrderLoading } = useSelector(
    (state: { MailOrderInfo: MailOrderInfoInitialState }) => state.MailOrderInfo
  );

  useEffect(() => {
    dispatch(getMailOrders());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    mailOrderLoading,
  };
};

export default useMailOrderInfo;
