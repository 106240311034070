import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetRoomSalesAndPaymentMethodsState } from "slices/pricesAndQuota/roomSalesAndPaymentMethods/reducer";
import {
  getRoomSalesAndPaymentMethods,
  saveRoomSalesAndPaymentMethods,
} from "slices/pricesAndQuota/roomSalesAndPaymentMethods/thunk";
import {
  RoomSalesAndPaymentMethods,
  RoomSalesAndPaymentMethodsRequestPayload,
  RoomSalesAndPaymentMethodsState,
} from "types/PricesQuotas";

interface SaveMethodsAndStatusButtonProps {
  otel_id: number;
  oda_id: number;
  formValues: RoomSalesAndPaymentMethodsState;
  initialValues: RoomSalesAndPaymentMethods;
  isLoading: boolean;
}

const SaveMethodsAndStatusButton = ({
  otel_id,
  oda_id,
  formValues,
  initialValues,
  isLoading,
}: SaveMethodsAndStatusButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const initalPaymentStatus = Object.keys(initialValues.fiyat_durum).map(
    (key) => key as keyof typeof initialValues.fiyat_durum
  );

  const fiyat_durum = initalPaymentStatus.reduce((acc, key) => {
    acc[key] = formValues.fiyat_durum[key] ?? false;
    return acc;
  }, {} as { [key in keyof typeof initialValues.fiyat_durum]: boolean });

  const payload: RoomSalesAndPaymentMethodsRequestPayload = {
    otel_id,
    oda_id,
    odeme_sekli: formValues.odeme,
    fiyat_durum,
    oranlar: formValues.oranlar,
  };

  const handleSave = () => {
    dispatch(
      saveRoomSalesAndPaymentMethods(payload, () => {
        dispatch(getRoomSalesAndPaymentMethods({ otel_id, oda_id }));
      })
    );
  };

  const handleReset = () => {
    dispatch(resetRoomSalesAndPaymentMethodsState());
  };

  return (
    <div className="d-flex justify-content-end gap-2">
      <CustomButton variant="warning" onClick={handleReset}>
        Formu Sıfırla
      </CustomButton>
      <CustomButton
        variant="primary"
        onClick={handleSave}
        isLoading={isLoading}
      >
        Kaydet
      </CustomButton>
    </div>
  );
};

export default SaveMethodsAndStatusButton;
