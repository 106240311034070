import { Col } from "reactstrap";
import { Concept } from "types/PricesQuotas";
interface HeaderContainerProps {
  concepts: Concept[];
  title?: string;
}

const HeaderContainer = ({ concepts, title }: HeaderContainerProps) => {
  return (
    <div className="w-100 d-flex justify-content-between bg-secondary bg-opacity-25 fw-medium p-0 rounded-top">
      <Col xs={3} md={4}>
        <div className="d-flex align-items-center p-2 border h-100">
          <p className="m-0 fs-14 fw-semibold">Oda</p>
        </div>
      </Col>
      <Col xs={9} lg={8} className="d-flex justify-content-start">
        {title ? (
          <div className="d-flex align-items-center p-2 h-100">
            <p className="m-0 fs-14 fw-semibold">{title}</p>
          </div>
        ) : (
          concepts
            .filter((c) => c.durum === "1")
            .map((concept, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-center p-2 border"
                style={{ width: "200px" }}
              >
                <p className="m-0 fs-14 fw-semibold text-wrap text-center">
                  {concept.pansiyon_isim}
                </p>
              </div>
            ))
        )}
      </Col>
    </div>
  );
};

export default HeaderContainer;
