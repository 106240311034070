import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AddNewThemeRequestPayload } from "types/themes";
import useValidateForm from "./useValidateForm";
import { AddNewThemeInitialState } from "slices/themes/addNewTheme/reducer";

const useHandleForm = () => {
  const selectedAddNewThemeState = (state: {
    AddNewTheme: AddNewThemeInitialState;
  }) => state.AddNewTheme;

  const selectAddNewThemeProperties = createSelector(
    selectedAddNewThemeState,
    (state) => ({
      themeRegionOptions: state.themeRegionOptions,
      themeTypesOptions: state.themeTypesOptions,
      theme: state.theme,
      addNewThemeFormErrors: state.addNewThemeFormErrors,
      isLoading: state.isLoading,
      error: state.error,
    })
  );

  const {
    themeRegionOptions,
    themeTypesOptions,
    theme,
    addNewThemeFormErrors,
    isLoading,
    error,
  } = useSelector(selectAddNewThemeProperties);

  const addNewThemeRequestPayload: AddNewThemeRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    themeRegionOptions,
    themeTypesOptions,
    theme,
    isLoading,
    error,
    addNewThemeRequestPayload,
    formErrors: addNewThemeFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
