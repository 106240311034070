import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ReservationReview } from "types/reservation";
import {
  sendConfirmation,
  sendVoucher,
  getConfirmationFile,
  getVoucherFile,
  cancelReservation,
} from "slices/reservation/reviewReservation/thunk";

const useHandleActions = (reservation: ReservationReview) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { musteri_tel, rezervasyon_id } = reservation;

  const handleCancelReservation = () => {
    dispatch(
      cancelReservation({
        rez_id: rezervasyon_id,
        cepNo: musteri_tel,
      })
    );
  };

  const handleApproveReservation = () => {
    console.log("approve");
  };

  const handleSendConfirmation = () => {
    dispatch(sendConfirmation(rezervasyon_id));
  };

  const handleSendVoucher = () => {
    dispatch(sendVoucher(rezervasyon_id));
  };

  //TODO: implement send invoice
  const handleSendInvoice = () => {
    alert("send invoice");
  };

  const handleGetConfirmationFile = () => {
    dispatch(getConfirmationFile(rezervasyon_id));
  };

  const handleGetVoucherFile = () => {
    dispatch(getVoucherFile(rezervasyon_id));
  };

  //TODO: implement get invoice file
  const handleGetInvoiceFile = () => {
    alert("get invoice");
  };

  return {
    onCancelReservation: handleCancelReservation,
    onApproveReservation: handleApproveReservation,
    onSendConfirmation: handleSendConfirmation,
    onSendVoucher: handleSendVoucher,
    onSendInvoice: handleSendInvoice,
    onGetConfirmationFile: handleGetConfirmationFile,
    onGetVoucherFile: handleGetVoucherFile,
    onGetInvoiceFile: handleGetInvoiceFile,
  };
};

export default useHandleActions;
