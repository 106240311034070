import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import UpdateThemeSelectors from "./UpdateThemeSelectors";
import UpdateThemeTitleAndSubtitle from "./UpdateThemeTitleAndSubtitle";
import UpdateThemeContentAndLink from "./UpdateThemeContentAndLink";
import UpdateThemeImageSelector from "./UpdateThemeImageSelector";
import UpdateThemeButton from "./UpdateThemeButton";

const UpdateThemeForm = () => {
  const {
    updateTheme,
    selectedTheme,
    regionOptions,
    statusOptions,
    updateThemeRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm();
  return (
    <CustomFormContainer>
      <UpdateThemeSelectors
        values={updateTheme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        regionOptions={regionOptions}
        statusOptions={statusOptions}
      />
      <UpdateThemeTitleAndSubtitle
        values={updateTheme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateThemeContentAndLink
        values={updateTheme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateThemeImageSelector
        values={updateTheme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateThemeButton
        selectedTheme={selectedTheme}
        values={updateTheme}
        requestPayload={updateThemeRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default UpdateThemeForm;
