import axios from "axios";

import {
  GET_BASIC_PRICE_ENTRY_PAGE_DATA,
  POST_BASIC_PRICE_ENTRIES,
} from "helpers/url_helper";
import { handleErrors } from "helpers/axios_helpers";
import {
  setIsLoading,
  setError,
  handleSaveHotel,
  resetAll,
  setAddPricesLoading,
  setAddPricesError,
} from "./reducer";
import { IncomingPrice } from "types/PricesQuotas";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { Dispatch } from "redux";

interface RequestPayload {
  otel_id: number;
  tarihler: string;
  tum_gunler: number;
  gunler: number[];
  gelen_fiyatlar: IncomingPrice[];
}

interface GetBasicPriceEntryPageDataPayload {
  otel_id: number;
}

export const postBasicPriceEntries =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAddPricesLoading(true));
      await axios.post(POST_BASIC_PRICE_ENTRIES, payload);

      dispatch(resetAll());
      dispatch(setToastSuccess("Fiyatlar kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddPricesError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddPricesLoading(false));
    }
  };

export const getBasicPriceEntryPageData =
  (payload: GetBasicPriceEntryPageDataPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(
        GET_BASIC_PRICE_ENTRY_PAGE_DATA,
        payload
      );

      dispatch(handleSaveHotel(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
