import { UpdateFacilityState } from "types/facility";
import useHandlePayload from "./useHandlePayload";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  getFacility,
  updateFacility,
} from "slices/facility/updateFacility/thunk";
import {
  handleResetFacilityState,
  UpdateFacilityInitialState,
} from "slices/facility/updateFacility/reducer";
import { useSelector } from "react-redux";
import { CustomButton } from "Components/Custom/UI";

interface UpdateFacilityButtonProps {
  values: UpdateFacilityState;
  requestPayload: FormData;
  validateForm: (values: UpdateFacilityState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateFacilityButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateFacilityButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facility } = useSelector(
    (state: { UpdateFacility: UpdateFacilityInitialState }) =>
      state.UpdateFacility
  );

  const { getPayload, otel_id } = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateFacility(getPayload(), () => {
          dispatch(getFacility(otel_id));
        })
      );
    }
  };

  const handleResetForm = () => {
    dispatch(handleResetFacilityState(facility));
    window.scrollTo(0, 0);
  };

  return (
    <div
      style={{
        position: "sticky",
        bottom: 50,
        zIndex: 100,
      }}
    >
      <div className="d-flex justify-content-end gap-4 mt-4">
        <CustomButton
          classes="flex-grow-1"
          variant="danger"
          onClick={handleResetForm}
        >
          Formu Sıfırla
        </CustomButton>

        <CustomButton
          classes="flex-grow-1"
          variant="success"
          onClick={onClick}
          isLoading={isLoading}
        >
          Tesis Düzenle
        </CustomButton>
      </div>
    </div>
  );
};

export default UpdateFacilityButton;
