import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/myWebSite/addFooterLinks/reducer";
import { FooterLink, FooterLinkFormErrors } from "types/myWebSite";

interface FooterLinkUrlProps {
  values: FooterLink;
  formErrors: FooterLinkFormErrors;
  validateFormChange: (values: FooterLink) => void;
}

const FooterLinkUrl = ({
  values,
  formErrors,
  validateFormChange,
}:  
FooterLinkUrlProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Footer Link Url : "
      error={formErrors.link}
      orientation="horizontal"
      required
    >
      <Input
        type="text"
        autoCorrect="on"
        value={values.link}
        onChange={(e) => {
          dispatch(
            handleChangeInput({
              link: e.target.value,
            })
          );
            validateFormChange({
              ...values,
              link: e.target.value,
            });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default FooterLinkUrl;
