import UpdateUserPage from 'Components/Custom/UserOperationsPages/UpdateUserPage';
import useUpdateUser from 'Components/Hooks/userOperations/useUpdateUser';
import PageContainer from 'pages/PageContainer';

const UpdateUser = () => {

    const { isLoading } = useUpdateUser();

  return (
    <PageContainer title="Kullanıcı Düzenle">
        <UpdateUserPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default UpdateUser