import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ChildInformation } from "types/PricesQuotas";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FormGroup, Input } from "reactstrap";
import { handleChangeInput } from "slices/pricesAndQuota/childInformation/reducer";

interface ChildCalculateSwitchProps {
  childInformationState: ChildInformation;
}

const ChildCalculateSwitch = ({
  childInformationState,
}: ChildCalculateSwitchProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Doğum Yılına Göre Hesapla">
        <FormGroup switch>
          <Input
            type="switch"
            checked={
              childInformationState.oda_genel_bilgileri.dogum_hesaplama ===
              "yil"
            }
            onChange={() => {
              dispatch(
                handleChangeInput({
                  oda_genel_bilgileri: {
                    ...childInformationState.oda_genel_bilgileri,
                    dogum_hesaplama:
                      childInformationState.oda_genel_bilgileri
                        .dogum_hesaplama === "yil"
                        ? "0"
                        : "yil",
                  },
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Doğum Gününe Göre Hesapla"
        tooltipId="childBirthdayDate"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={
              childInformationState.oda_genel_bilgileri.dogum_hesaplama ===
              "gun"
            }
            onChange={() => {
              dispatch(
                handleChangeInput({
                  oda_genel_bilgileri: {
                    ...childInformationState.oda_genel_bilgileri,
                    dogum_hesaplama:
                      childInformationState.oda_genel_bilgileri
                        .dogum_hesaplama === "gun"
                        ? "0"
                        : "gun",
                  },
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="1 Yetişkin Yanında Farklı Yaş Aralığı">
        <FormGroup switch>
          <Input
            type="switch"
            checked={
              childInformationState.oda_genel_bilgileri.tek_yani_cocuk === "1"
            }
            onChange={() => {
              dispatch(
                handleChangeInput({
                  oda_genel_bilgileri: {
                    ...childInformationState.oda_genel_bilgileri,
                    tek_yani_cocuk:
                      childInformationState.oda_genel_bilgileri
                        .tek_yani_cocuk === "1"
                        ? "0"
                        : "1",
                  },
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default ChildCalculateSwitch;
