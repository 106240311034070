import { useEffect, useState } from "react";
import OtelFiyat from "assets/images/competitor-analysis/otelfiyat_logo.png";
import Otelz from "assets/images/competitor-analysis/otelz_logo.png";
import Hotels from "assets/images/competitor-analysis/hotels_logo.png";
import Etstur from "assets/images/competitor-analysis/etstur_logo.png";
import Jolly from "assets/images/competitor-analysis/jolly_logo2.png";
import Odamax from "assets/images/competitor-analysis/odamax_logo.png";
import { FormGroup, Input, Spinner } from "reactstrap";
import { CompetitorAnalysisCardData } from "types/dashboard";
import FacilityAvailabilityModal from "./Modals/FacilityAvailabilityModal";
import SaveDescriptionModal from "./Modals/SaveDescriptionModal";

interface CompetitorAnalysisFacilityAvailability {
  [key: string]: {
    [key: string]: { tarih: string; durum: number }[];
  };
}

interface AnalysisCardProps extends CompetitorAnalysisCardData {
  onGetFacilityAvailabilities: (otel_id: number) => void;
  facilityAvailabilities: CompetitorAnalysisFacilityAvailability[];
  availabilityLoading: boolean;
  updateDescriptionLoading: boolean;
  updateHotelInfoLoading: boolean;
  updateAdsStatusLoading: boolean;
  onUpdateDescription: (id: number, analiz_aciklama_data: string) => void;
  onUpdateAdsDescription: (otel_id: number, reklam_data: string) => void;
  onUpdateAdsStatus: (
    group_id: number,
    status: 0 | 1,
    successCallback: SuccessCallback
  ) => void;
  onUpdateHotelInfo: (id: number) => void;
}

type SuccessCallback = () => void;

const AnalysisCard = ({
  otel_id,
  otel_adi,
  telefon,
  komisyon_orani,
  reklam_yeni,
  reklam_grup_id,
  reklam_aciklama,
  kapida_durum,
  otel_odasayisi,
  id,
  tarih_1, // * PERİYOT 1 tarih
  gece_1, // * PERİYOT 1 gece
  tarih_2, // * PERİYOT 2 tarih
  gece_2, // * PERİYOT 2 gece
  tarih_3, // * PERİYOT 3 tarih
  gece_3, // * PERİYOT 3 gece
  otelfiyat_fiyat_1, // * PERİYOT 1 fiyat
  otelfiyat_fiyat_2, // * PERİYOT 2 fiyat
  otelfiyat_fiyat_3, // * PERİYOT 3 fiyat
  jolly_fiyat_1, // * PERİYOT 1 fiyat
  jolly_fiyat_2, // * PERİYOT 2 fiyat
  jolly_fiyat_3, // * PERİYOT 3 fiyat
  hotels_fiyat_1, // * PERİYOT 1 fiyat
  hotels_fiyat_2, // * PERİYOT 2 fiyat
  hotels_fiyat_3, // * PERİYOT 3 fiyat
  otelz_fiyat_1, // * PERİYOT 1 fiyat
  otelz_fiyat_2, // * PERİYOT 2 fiyat
  otelz_fiyat_3, // * PERİYOT 3 fiyat
  etstur_fiyat_1, // * PERİYOT 1 fiyat
  etstur_fiyat_2, // * PERİYOT 2 fiyat
  etstur_fiyat_3, // * PERİYOT 3 fiyat
  odamax_fiyat_1, // * PERİYOT 1 fiyat
  odamax_fiyat_2, // * PERİYOT 2 fiyat
  odamax_fiyat_3, // * PERİYOT 3 fiyat
  aciklama, // * Açıklama modalı
  yetkili,
  otelfiyat_url_1,
  odamax_url_1,
  hotels_url_1,
  otelz_url_1,
  etstur_url_1,
  otelfiyat_url_2,
  odamax_url_2,
  hotels_url_2,
  otelz_url_2,
  etstur_url_2,
  otelfiyat_url_3,
  odamax_url_3,
  hotels_url_3,
  otelz_url_3,
  etstur_url_3,
  onGetFacilityAvailabilities,
  facilityAvailabilities,
  availabilityLoading,
  updateDescriptionLoading,
  updateHotelInfoLoading,
  updateAdsStatusLoading,
  onUpdateDescription,
  onUpdateAdsDescription,
  onUpdateAdsStatus,
  onUpdateHotelInfo,
}: AnalysisCardProps) => {
  const firstBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_1),
    parseFloat(otelz_fiyat_1),
    parseFloat(hotels_fiyat_1),
    parseFloat(etstur_fiyat_1),
    parseFloat(jolly_fiyat_1),
    parseFloat(odamax_fiyat_1)
  );

  const secondBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_2),
    parseFloat(otelz_fiyat_2),
    parseFloat(hotels_fiyat_2),
    parseFloat(etstur_fiyat_2),
    parseFloat(jolly_fiyat_2),
    parseFloat(odamax_fiyat_2)
  );

  const thirdBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_3),
    parseFloat(otelz_fiyat_3),
    parseFloat(hotels_fiyat_3),
    parseFloat(etstur_fiyat_3),
    parseFloat(jolly_fiyat_3),
    parseFloat(odamax_fiyat_3)
  );

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [adsModal, setAdsModal] = useState(false);

  const toggleAdsModal = () => setAdsModal(!adsModal);

  const [descriptionModal, setDescriptionModal] = useState(false);

  const toggleDescriptionModal = () => setDescriptionModal(!descriptionModal);

  const [isAdsOpen, setIsAdsOpen] = useState(
    reklam_yeni === "acik" ? true : false
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !updateHotelInfoLoading && setIsLoading(false);
  }, [updateHotelInfoLoading]);

  const [adsLoading, setAdsLoading] = useState(false);

  useEffect(() => {
    !updateAdsStatusLoading && setAdsLoading(false);
  }, [updateAdsStatusLoading]);

  useEffect(() => {
    setIsAdsOpen(reklam_yeni === "acik" ? true : false);
  }, [reklam_yeni]);

  return (
    <>
      <div
        className="d-flex flex-column border"
        style={{
          backgroundColor: isLoading ? "var(--theme-light)" : "white",
        }}
      >
        <div className="d-flex border">
          <div className="flex-grow-1 d-flex justify-content-start px-1 align-items-center gap-2 border">
            {adsLoading ? (
              <Spinner size="sm" />
            ) : (
              <FormGroup switch>
                <Input
                  type="switch"
                  className="cursor-pointer"
                  checked={isAdsOpen}
                  onChange={() => {
                    onUpdateAdsStatus(
                      Number(reklam_grup_id),
                      isAdsOpen ? 0 : 1,
                      () => setIsAdsOpen((prevState) => !prevState)
                    );

                    setAdsLoading(true);
                  }}
                  title={isAdsOpen ? "Reklamı Kapat" : "Reklamı Aç"}
                />
              </FormGroup>
            )}
            <i
              className="ri-hotel-bed-fill text-success fs-18 cursor-pointer"
              title="Müsaitlik Durumu"
              onClick={() => {
                onGetFacilityAvailabilities(otel_id);
                toggle();
              }}
            ></i>
            <i
              className="ri-advertisement-fill fs-18 cursor-pointer"
              title="Reklam Açıklama"
              onClick={toggleAdsModal}
            ></i>
            <i
              className="ri-file-text-fill fs-18 cursor-pointer"
              title="Açıklama"
              onClick={toggleDescriptionModal}
            ></i>
            {isLoading ? (
              <Spinner size="sm" color="success" />
            ) : (
              <i
                className="ri-restart-fill fs-18 cursor-pointer text-primary"
                title="Yenile"
                onClick={() => {
                  setIsLoading(true);
                  onUpdateHotelInfo(id);
                }}
              ></i>
            )}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {otel_adi}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {otel_odasayisi} oda
          </div>
          {/* <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {lokasyon}
          </div> */}
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {telefon}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            Komisyon Oranı: %{komisyon_orani}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            Kapıda: {kapida_durum}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {yetkili.ad_soyad}
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
          <div className="d-flex flex-column">
            <span className="border text-center fs-11 py-2">
              {tarih_1} / {gece_1} gece
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_url_1} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_url_1} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_url_1} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={etstur_url_1} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_1} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_1} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_1) === firstBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {isNaN(Number(jolly_fiyat_1))
                  ? jolly_fiyat_1
                  : Math.ceil(Number(jolly_fiyat_1))}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <span className="border text-center fs-11 py-2">
              {tarih_2} / {gece_2} gece
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_url_2} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_url_2} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_url_2} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={etstur_url_2} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_2} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_2} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_2) === secondBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {isNaN(Number(jolly_fiyat_2))
                  ? jolly_fiyat_2
                  : Math.ceil(Number(jolly_fiyat_2))}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <span className="border text-center fs-11 py-2">
              {tarih_3} / {gece_3} gece
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_url_3} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_url_3} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_url_3} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={etstur_url_3} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_3} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_url_2} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_3) === thirdBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {isNaN(Number(jolly_fiyat_3))
                  ? jolly_fiyat_3
                  : Math.ceil(Number(jolly_fiyat_3))}
              </span>
            </div>
          </div>
        </div>
      </div>
      <FacilityAvailabilityModal
        modal={modal}
        toggle={toggle}
        facilityAvailabilities={facilityAvailabilities}
        isLoading={availabilityLoading}
      />
      <SaveDescriptionModal
        modal={adsModal}
        toggle={toggleAdsModal}
        description={reklam_aciklama}
        saveDescription={(description) =>
          onUpdateAdsDescription(otel_id, description)
        }
        isLoading={updateDescriptionLoading}
      />
      <SaveDescriptionModal
        modal={descriptionModal}
        toggle={toggleDescriptionModal}
        description={aciklama}
        saveDescription={(description) => onUpdateDescription(id, description)}
        isLoading={updateDescriptionLoading}
      />
    </>
  );
};

export default AnalysisCard;
