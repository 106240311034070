import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  Key,
  ReactPortal,
} from "react";
import { Col, Label, Row, Table } from "reactstrap";

interface Option {
  label: string;
  value: string;
}

interface ReservationInfoFields {
  commenterInfo: Option[];
  scores: Option[];
}

interface ReservationInfoRowProps {
  values: ReservationInfoFields;
}

const ReservationInfoRow = ({ values }: ReservationInfoRowProps) => {
  return (
    <>
      <Row className="m-2">
        {values.commenterInfo.map((info) => (
          <Col md={6} key={info.label}>
            <div className="d-flex">
              <Label className="text-xs mx-2" style={{ fontSize: "14px" }}>
                {info.label} :
              </Label>
              <p className="text-xs fw-normal" style={{ fontSize: "14px" }}>
                {info.value}
              </p>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="m-2">
        <Col>
          <Table bordered size="sm">
            <thead>
              <tr>
                {values.scores.map((score) => (
                  <th
                    key={score.label}
                    className="text-xs text-center"
                    style={{ fontSize: "14px" }}
                  >
                    {score.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
                <tr>
                {values.scores.map((score) => (
                  <td
                    key={score.label}
                    className="text-xs  text-center"
                  >
                    {score.value}
                  </td>
                ))}
                </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ReservationInfoRow;
