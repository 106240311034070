import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { DailyCallsPerPersonInitialState } from "slices/reports/dailyCallsPerPerson/reducer";
import { getDailyCallsPerPerson } from "slices/reports/dailyCallsPerPerson/thunk";

const useDailyCallsPerPerson = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { DailyCallsPerPerson: DailyCallsPerPersonInitialState }) =>
      state.DailyCallsPerPerson
  );

  useEffect(() => {
    const params = {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getDailyCallsPerPerson(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useDailyCallsPerPerson;
