import { CustomLoadingPage } from "Components/Custom/UI";
import ExtensionNumberSituation from "./Cards/ExtensionNumberSituation/ExtensionNumberSituation";
import PersonnelActivity from "./Cards/PersonnelActivity/PersonnelActivity";
import CallsDueLater from "./Cards/CallsDueLater/CallsDueLater";
import useHandleCards from "./useHandleCards";
import ExtensionLists from "./Cards/ExtensionLists/ExtensionLists";
import MonthlySalesOfPersonnel from "./Cards/MonthlySalesOfPersonnel/MonthlySalesOfPersonnel";

interface CrmDashboardPageProps {
  isLoading: boolean;
}

const CrmDashboardPage = ({ isLoading }: CrmDashboardPageProps) => {
    const { values } = useHandleCards();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-3">
        <ExtensionLists values={values} />
        <MonthlySalesOfPersonnel values={values} />
        <ExtensionNumberSituation values={values}/>
        <PersonnelActivity values={values}/>
        <CallsDueLater values={values}/>
      </div>
    </>
  );
};

export default CrmDashboardPage;
