import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleSavePlaceToVisitForUpdate,
  ListPlacesToVisitInitialState,
} from "slices/definitions/listPlacesToVisit/reducer";
import { PlaceToVisit } from "types/definitions";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = (placesToVisitList: PlaceToVisit[]) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { placeToVisit } = useSelector(
    (state: { ListPlacesToVisit: ListPlacesToVisitInitialState }) =>
      state.ListPlacesToVisit
  );

  const options: Option[] = placesToVisitList.map((place) => ({
    value: place.id,
    label: place.yer_adi,
  }));

  const handleSelectPlaceToVisit = (id: number) => {
    dispatch(handleSavePlaceToVisitForUpdate(id));
  };

  return {
    options,
    placeToVisit,
    handleSelectPlaceToVisit,
  };
};

export default useHandleForm;
