import { handleChangeUpdateFacilityRoomFormInput } from "slices/facility/facilityRooms/reducer";
import {
  UpdateFacilityRoomFormErrors,
  UpdateFacilityRoomState,
} from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { Input, Label } from "reactstrap";

interface FacilityFeaturesProps {
  values: UpdateFacilityRoomState;
  formErrors: UpdateFacilityRoomFormErrors;
  validateFormChange: (values: UpdateFacilityRoomState) => void;
  roomFeaturesOptions: Option[];
}

interface Option {
  value: number;
  label: string;
}
const FacilityFeatures = ({
  values,
  formErrors,
  validateFormChange,
  roomFeaturesOptions,
}: FacilityFeaturesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const groupedRoomFeatures = roomFeaturesOptions.reduce(
    (acc: any, item: any) => {
      if (!acc[item.key]) {
        acc[item.key] = [];
      }
      acc[item.key].push(item);
      return acc;
    },
    {}
  );

  const groupKeys: { [key: string]: string } = {
    zemin: "Zemin",
    genel: "Genel",
    medya: "Medya",
    banyo: "Banyo",
    yeme_içme: "Yeme İçme",
    dış_mekan: "Dış Mekan",
  };

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Özellikleri"
        error={formErrors.oda_ozellikleri}
        divider
        required
        orientation="vertical"
      >
        <div className="d-flex flex-column gap-5 bg-white rounded border p-2">
          {Object.keys(groupedRoomFeatures).map((groupKey: string) => (
            <div key={groupKey} className="w-100">
              <p className="fs-18 fw-medium text-decoration-underline mb-3">
                {groupKeys[groupKey] || groupKey}
              </p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              >
                {groupedRoomFeatures[groupKey].map((option: any) => (
                  <div key={option.value} className="d-flex gap-2">
                    <Input
                      type="checkbox"
                      id={"room-feature-" + option.value}
                      checked={values.oda_ozellikleri.includes(option.value)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        const value = option.value;

                        const newValues = checked
                          ? [...values.oda_ozellikleri, value]
                          : values.oda_ozellikleri.filter(
                              (item) => item !== value
                            );

                        dispatch(
                          handleChangeUpdateFacilityRoomFormInput({
                            ...values,
                            oda_ozellikleri: newValues,
                          })
                        );
                        validateFormChange({
                          ...values,
                          oda_ozellikleri: newValues,
                        });
                      }}
                    />
                    <Label
                      for={"room-feature-" + option.value}
                      className="fw-normal fs-14"
                    >
                      {option.label}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityFeatures;
