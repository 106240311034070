import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
    handleSaveCategorizeFacilityFormChoices,
    handleSaveCitySelectionResponse,
    handleSaveCategorySelectionResponse,
    handleSaveFacilityListResponse,
    setIsLoading,
    setError,
    setCategorizeFacilityLoading,
    setCategorizeFacilityError,
    setChangeFacilityCategoryLoading,
    setChangeFacilityCategoryError,
    setFacilityListLoading,
    setFacilityListError,
} from "./reducer";

import {
  GET_CATEGORIZE_FACILITY_FORM_CHOICES,
  GET_CITY_SELECTION_RESPONSE,
  CATEGORIZE_FACILITY,
  GET_CATEGORY_SELECTION_RESPONSE,
  CHANGE_FACILITY_CATEGORY,
  LIST_FACILITIES,
} from "helpers/url_helper";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import { Dispatch } from "redux";
import { CategorizeFacilityPayload, ChangeFacilityCategoryPayload, ListFacilitiesByCityAndCategoryPayload } from "types/facilityCategorization";

type SuccessCallback = () => void;

export const getCategorizeFacilityFormChoices =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_CATEGORIZE_FACILITY_FORM_CHOICES);

      dispatch(
        handleSaveCategorizeFacilityFormChoices(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getCitySelectionResponse =
  (sehir_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_CITY_SELECTION_RESPONSE}?sehir_id=${sehir_id}`
      );

      dispatch(handleSaveCitySelectionResponse(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const categorizeFacilities =
  (payload: CategorizeFacilityPayload, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
    try {
      dispatch(setCategorizeFacilityLoading(true));
      await axios.post(CATEGORIZE_FACILITY, payload);

      successCallback();
      dispatch(setToastSuccess("Tesis başarıyla sınıflandırıldı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setCategorizeFacilityError(message));
      });
    } finally {
      dispatch(setCategorizeFacilityLoading(false));
    }
  };

  export const getCategorySelectionResponse =
    (sinif: string) => async (dispatch: Dispatch) => {
      try {
        dispatch(setIsLoading(true));
        const response = await axios.get(
          `${GET_CATEGORY_SELECTION_RESPONSE}?sinif=${sinif}`
        );
  
        dispatch(handleSaveCategorySelectionResponse(response.data || response));
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setIsLoading(false));
      }
    };
  
  export const changeCategoryOfFacilities =
    (payload: ChangeFacilityCategoryPayload, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
      try {
        dispatch(setChangeFacilityCategoryLoading(true));
        await axios.post(CHANGE_FACILITY_CATEGORY, payload);
  
        successCallback();
        dispatch(setToastSuccess("Tesis sınıfı başarıyla değiştirildi."));
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setError(message));
          dispatch(setChangeFacilityCategoryError(message));
        });
      } finally {
        dispatch(setChangeFacilityCategoryLoading(false));
      }
    };

    export const getFacilityList =
    (payload: ListFacilitiesByCityAndCategoryPayload, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
      try {
        dispatch(setFacilityListLoading(true));
        const response =  await axios.post(LIST_FACILITIES, payload);
  
        dispatch(handleSaveFacilityListResponse(response.data || response));
        successCallback();
        dispatch(setToastSuccess("Tesisler başarıyla listelendi."));
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setError(message));
          dispatch(setFacilityListError(message));
        });
      } finally {
        dispatch(setFacilityListLoading(false));
      }
    };