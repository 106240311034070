import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  fetchReservationFailure,
  fetchReservationRequest,
  fetchReservationSuccess,
  setActionLoading,
  setActionError,
  sendConfirmationRequest,
  sendConfirmationSuccess,
  sendConfirmationFailure,
  sendVoucherRequest,
  sendVoucherSuccess,
  sendVoucherFailure,
  getConfirmationFileRequest,
  getConfirmationFileSuccess,
  getConfirmationFileFailure,
  getVoucherFileRequest,
  getVoucherFileSuccess,
  getVoucherFileFailure,
} from "./reducer";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import {
  GET_REVIEW_RESERVATION,
  REVIEW_CANCEL_RESERVATION,
  SEND_CONFIRMATION,
  SEND_VOUCHER,
  GET_CONFIRMATION_FILE,
  GET_VOUCHER_FILE,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { ReservationReview } from "types/reservation";

interface CancelReservationRequestPayload {
  rez_id: number;
  cepNo: string;
}

export const getReservationReview =
  (reservationId: string) => async (dispatch: Dispatch) => {
    dispatch(fetchReservationRequest());
    try {
      const response = await axios.get(GET_REVIEW_RESERVATION, {
        params: {
          rezervasyon_id: reservationId,
        },
      });

      const res: ReservationReview[] = response.data || response;

      dispatch(fetchReservationSuccess(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(fetchReservationFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const cancelReservation =
  (payload: CancelReservationRequestPayload) => async (dispatch: Dispatch) => {
    dispatch(setActionLoading(true));
    try {
      await axios.post(REVIEW_CANCEL_RESERVATION, payload);

      dispatch(setToastError("Rezervasyon iptal edildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setActionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setActionLoading(false));
    }
  };

export const sendConfirmation =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(sendConfirmationRequest());
    try {
      await axios.post(SEND_CONFIRMATION, {
        rezervasyon_id,
      });

      dispatch(sendConfirmationSuccess());
      dispatch(setToastSuccess("Konfirme gönderildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(sendConfirmationFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const sendVoucher =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(sendVoucherRequest());
    try {
      await axios.post(SEND_VOUCHER, {
        rezervasyon_id,
      });

      dispatch(sendVoucherSuccess());
      dispatch(setToastSuccess("Voucher gönderildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(sendVoucherFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const getConfirmationFile =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(getConfirmationFileRequest());
    try {
      const response = await axios.get(GET_CONFIRMATION_FILE, {
        params: {
          rezervasyon_id,
        },
      });

      const res: { konfirme: string } = response.data || response;

      window.open(res.konfirme, "_blank", "noopener noreferrer");

      dispatch(getConfirmationFileSuccess());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(getConfirmationFileFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const getVoucherFile =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(getVoucherFileRequest());
    try {
      const response = await axios.get(GET_VOUCHER_FILE, {
        params: {
          rezervasyon_id,
        },
      });

      const res: { voucher: string } = response.data || response;

      window.open(res.voucher, "_blank", "noopener noreferrer");

      dispatch(getVoucherFileSuccess());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(getVoucherFileFailure(message));
        dispatch(setToastError(message));
      });
    }
  };
