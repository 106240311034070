import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import useHandleForm from "./useHandleForm";
import { Input } from "reactstrap";
import Select from "react-select";
import { CONTRACT_TYPE } from "enums";

interface AddHotelContractRuleFormProps {
  isLoading: boolean;
}

interface Option {
  label: string;
  value: string;
}

const AddHotelContractRuleForm = ({
  isLoading,
}: AddHotelContractRuleFormProps) => {
  const {
    formValues,
    handleFormChange,
    onAddContractRule,
    contratTypeOptions,
    formErrors,
    validateFormChange,
  } = useHandleForm();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const contratType = formValues.kontrat_tipi;

  const tekrarli = contratType === CONTRACT_TYPE.Tekrarli;
  //   const aylik = contratType === CONTRACT_TYPE.Aylik;
  const odaBasi = contratType === CONTRACT_TYPE["Oda Basi"];
  const sezonSonu = contratType === CONTRACT_TYPE["Sezon Sonu"];
  const tekSeferlik = contratType === CONTRACT_TYPE["Tek Seferlik"];

  const placeholder = tekrarli || tekSeferlik || odaBasi ? "Tutar" : "Oran";

  const showLimit1 = tekrarli || sezonSonu || odaBasi;
  const showLimit2 = sezonSonu;

  return (
    <CustomFormContainer title="Kontrat Kuralı Ekle">
      <div className="d-flex gap-4 flex-wrap align-items-start">
        <CustomFormFieldContainer
          label="Kural Adı"
          orientation="vertical"
          error={formErrors.adi}
        >
          <Input
            type="text"
            value={formValues.adi}
            onChange={(e) => {
              handleFormChange("adi", e.target.value);
              validateFormChange({ ...formValues, adi: e.target.value });
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Tarih"
          orientation="vertical"
          error={formErrors.baslangic_tarihi}
        >
          <Flatpickr
            className="form-control"
            value={formValues.baslangic_tarihi}
            onChange={(date) => {
              const [startDate] = date;

              if (startDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");

                handleFormChange("baslangic_tarihi", stringStartDate);
                validateFormChange({
                  ...formValues,
                  baslangic_tarihi: stringStartDate,
                });
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              showMonths: 1,
              locale: Turkish,
              onDayCreate,
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Kontrat Tipi" orientation="vertical">
          <Select
            value={contratTypeOptions.find(
              (option) => option.value === formValues.kontrat_tipi
            )}
            options={contratTypeOptions}
            onChange={(option: Option) =>
              handleFormChange("kontrat_tipi", option.value)
            }
            styles={{
              control: (styles: any) => ({
                ...styles,
                width: "200px",
              }),
            }}
          />
        </CustomFormFieldContainer>
        {showLimit1 && (
          <CustomFormFieldContainer
            label="Limit"
            orientation="vertical"
            error={formErrors.limit1}
          >
            <Input
              type="number"
              value={formValues.limit1}
              onChange={(e) => {
                handleFormChange("limit1", e.target.value);
                validateFormChange({ ...formValues, limit1: e.target.value });
              }}
              placeholder="Limit"
            />
          </CustomFormFieldContainer>
        )}
        {showLimit2 && (
          <CustomFormFieldContainer
            label="Limit 2"
            orientation="vertical"
            error={formErrors.limit2}
          >
            <Input
              type="number"
              value={formValues.limit2}
              onChange={(e) => {
                handleFormChange("limit2", e.target.value);
                validateFormChange({ ...formValues, limit2: e.target.value });
              }}
              placeholder="Limit 2"
            />
          </CustomFormFieldContainer>
        )}
        <CustomFormFieldContainer
          label="Veri"
          orientation="vertical"
          error={formErrors.veri}
        >
          <Input
            type="number"
            value={formValues.veri}
            onChange={(e) => {
              handleFormChange("veri", e.target.value);
              validateFormChange({ ...formValues, veri: e.target.value });
            }}
            placeholder={placeholder}
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer label="-" orientation="vertical">
          <CustomButton
            variant="success"
            onClick={onAddContractRule}
            isLoading={isLoading}
          >
            Kural Ekle
          </CustomButton>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default AddHotelContractRuleForm;
