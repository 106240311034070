import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveOutboundCalls, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_OUTBOUND_CALLS } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetOutboundCallsParams {
  start_date: string;
  end_date: string;
}

export const getOutboundCalls =
  (params: GetOutboundCallsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_OUTBOUND_CALLS, {
        params,
      });

      dispatch(handleSaveOutboundCalls(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
