import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeSpecialDayInput } from "slices/facility/facilitySpecialDays/reducer";
import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";
import { FACILITY_SPECIAL_DAY_CATEGORY_DESCRIPTION } from "utils/fieldDescriptions";

interface SpecialDayCategorySelectorProps {
  values: FacilitySpecialDayState;
  formErrors: FacilitySpecialDayFormErrors;
  validateFormChange: (values: FacilitySpecialDayState) => void;
  specialDaysCategoriesOptions: Option[];
}

interface Option {
  value: number;
  label: string;
}

const SpecialDayCategorySelector = ({
  values,
  formErrors,
  validateFormChange,
  specialDaysCategoriesOptions,
}: SpecialDayCategorySelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <CustomFormFieldContainer
      label="Özel Gün Kategori"
      error={formErrors.kategori_id}
      description={FACILITY_SPECIAL_DAY_CATEGORY_DESCRIPTION}
      divider
      required
    >
      <Select
        value={
          specialDaysCategoriesOptions.find(
            (option) => option.value === values.kategori_id
          ) || null
        }
        onChange={(selected: Option) => {
          dispatch(
            handleChangeSpecialDayInput({
              kategori_id: selected.value,
            })
          );

          validateFormChange({ ...values, kategori_id: selected.value });
        }}
        options={specialDaysCategoriesOptions}
        placeholder="Özel Gün Kategori Seçiniz"
      />
    </CustomFormFieldContainer>
  );
};

export default SpecialDayCategorySelector;
