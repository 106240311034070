import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AddPlaceToVisitRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { AddPlaceToVisitInitialState } from "slices/definitions/addPlaceToVisit/reducer";

const useHandleForm = () => {
  const selectedAddPlaceToVisitState = (state: {
    AddPlaceToVisit: AddPlaceToVisitInitialState;
  }) => state.AddPlaceToVisit;

  const selectAddPlaceToVisitProperties = createSelector(
    selectedAddPlaceToVisitState,
    (state) => ({
      placeToVisit: state.placeToVisit,
      addPlaceToVisitFormErrors: state.addPlaceToVisitFormErrors,
      isLoading: state.isLoading,
      error: state.error,
    })
  );

  const { placeToVisit, addPlaceToVisitFormErrors, isLoading, error } =
    useSelector(selectAddPlaceToVisitProperties);

  const addNewPlaceToVisitRequestPayload: AddPlaceToVisitRequestPayload =
    new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    placeToVisit,
    isLoading,
    error,
    addNewPlaceToVisitRequestPayload,
    formErrors: addPlaceToVisitFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
