import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { FACILITY_NAME_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityTitleProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityTitle = ({
  values,
  validateFormChange,
  formErrors,
}: FacilityTitleProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Adı"
        error={formErrors.otel_adi}
        description={FACILITY_NAME_DESCRIPTION}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.otel_adi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                otel_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityTitle;
