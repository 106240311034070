import { CustomButton } from "Components/Custom/UI";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Dispatch } from "redux";
import { PersonnelRequestInitialState } from "slices/personnelRequest/reducer";
import { setToastError } from "slices/toast/reducer";

interface SavePersonnelRequestModalProps {
    request_id: number;
    isOpen: boolean;
    handleToggle: () => void;
    saveAnswer: (answer: string) => void;
}

const PersonnelRequestModal = ({
    request_id,
    isOpen,
    handleToggle,
    saveAnswer,
}: SavePersonnelRequestModalProps) => {

    const { personnelRequest } = useSelector(
        (state: { PersonnelRequest: PersonnelRequestInitialState }) => state.PersonnelRequest
    );

    const requestAnswer = personnelRequest.find(
        (answerPersonnelRequest) => answerPersonnelRequest.id === request_id 
    )! //Bu datanın geleceği kesin

    const [answer, setAnswer] = useState(
        requestAnswer.talep_cevap ?? ""
    );

    const dispatch: Dispatch<any> = useDispatch();

    const handleSave = () => {
        if (!answer) {
            dispatch(setToastError("Cevap kısmı boş olamaz!"));
            return;
        } else {
            saveAnswer(answer);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} handleToggle={handleToggle}>
                <ModalHeader>Talebi Cevapla</ModalHeader>
                <ModalBody>
                    <Input
                        type="textarea"
                        placeholder="Talep cevabı"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                    <ModalFooter>
                        <CustomButton classes="mt-2 me-2" variant="danger" onClick={handleToggle}>
                            Vazgeç
                        </CustomButton>
                        <CustomButton classes="mt-2"
                            variant="success"
                            onClick={() => handleSave()}
                            disabled={!answer}
                        >
                            Kaydet
                        </CustomButton>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </>
    )
};

export default PersonnelRequestModal;

