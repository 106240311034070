import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import CurrencyFields from "./CurrencyFields";
import UpdateCurrenciesButton from "./UpdateCurrenciesButton";

const DefineCurrencyForm = () => {
  const {
    defineCurrency,
    defineCurrencyLoading,
    defineCurrencyRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer>
      <CurrencyFields
        values={defineCurrency}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateCurrenciesButton
        values={defineCurrency}
        requestPayload={defineCurrencyRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={defineCurrencyLoading}
      />
    </CustomFormContainer>
  );
};

export default DefineCurrencyForm;
