import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandlePrices = (oda_id: string) => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const hotel = Object.values(calculatePriceForHotel.fiyatlar)[0];
  const provider = calculatePriceForHotel.tedarikci;

  const kismi_odeme = {
    kismi_odeme: hotel.kismi_odeme,
    kismi_odeme_orani: hotel.kismi_odeme_orani,
    kismi_odeme_tarihi: hotel.kismi_odeme_tarihi,
    iptal_sigortasi_durum: hotel.iptal_sigortasi_durum,
  };
  const kapida_odeme = {
    kapida_odeme_durum: hotel.kapida_odeme_durum,
    kapida_komisyon_orani: hotel.kapida_komisyon_orani,
    kapida_indirim_orani: hotel.kapida_indirim_orani,
    kapida_odeme_para_birimi_goster: hotel.kapida_odeme_para_birimi_goster,
  };
  const kaporali_odeme = {
    kaporali_satis_durum: hotel.kaporali_satis_durum,
    kaporali_satis_orani: hotel.kaporali_satis_orani,
    kaporali_indirim_orani: hotel.kaporali_indirim_orani,
  };

  const room = hotel.odalar.find((room) => room.oda_id === oda_id);

  const conceptPrices = room?.pansiyonlar || [];

  const validPrices = conceptPrices.length > 0 ? conceptPrices : [];

  const priceCalculationStatusMessages = [
    {
      status: room?.misafir_bilgileri ? true : false,
      message: room?.misafir_bilgileri,
      description: "Bilgi için lütfen operasyonu arayınız.",
    },
    {
      status: room?.oda_cocuk_kabul === 0 ? true : false,
      message: "Çocuk kabul edilmemektedir.",
      description: "Bilgi için lütfen operasyonu arayınız.",
    },
  ];

  return {
    conceptPrices: validPrices,
    room,
    provider,
    kismi_odeme,
    kapida_odeme,
    kaporali_odeme,
    priceCalculationStatusMessages,
  };
};

export default useHandlePrices;
