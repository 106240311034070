import useHandleCard from "./useHandleCard";

import styles from "./CustomCreditCard.module.scss";
import Cards from "react-credit-cards-2";
import "./Card.scss";
import {
  CreditCard,
  CreditCardBrand,
  CreditCardInstallment,
} from "types/GlobalTypes";
import InstallmentsList from "./InstallmentsList";
import CreditCardForm from "./CreditCardForm";

interface CustomCreditCardProps {
  formId: string;
  getInstallments: (bin: string) => void;
  resetInstallments: () => void;
  makePayment: (card: CreditCard, installment: CreditCardInstallment) => void;
  makePaymentLoading: boolean;
  hasInstallments: boolean;
  installments: CreditCardInstallment[];
  installmentsLoading: boolean;
  cardBrand: CreditCardBrand;
  invalidCard: string;
}

const CustomCreditCard = ({
  formId,
  getInstallments,
  resetInstallments,
  makePayment,
  makePaymentLoading,
  hasInstallments,
  installments,
  installmentsLoading,
  cardBrand,
  invalidCard,
}: CustomCreditCardProps) => {
  const {
    isSubmitted,
    handleSubmit,
    errors,
    state,
    handleInputChange,
    handleInputFocus,
    isSubmitButtonDisabled,

    getCardInstallments,
    selectedInstallment,
    onSelectedInstallment,
  } = useHandleCard(makePayment, formId, installments);

  return (
    <form className="position-relative" id={formId} onSubmit={handleSubmit}>
      <div className={styles.container}>
        <div className="d-flex flex-column gap-3 align-items-center">
          <Cards
            number={state.cardNumber}
            name={state.cardOwner}
            expiry={state.expiryDate}
            cvc={state.cvc}
            focused={state.focus as any}
            placeholders={{
              name: "Kart Sahibi",
            }}
            locale={{ valid: "AA/YY" }}
            callback={(
              type: { issuer: string; maxLength: number },
              isValid: boolean
            ) => {
              getCardInstallments(
                state.cardNumber,
                getInstallments,
                resetInstallments
              );
            }}
          />
          <InstallmentsList
            hasInstallments={hasInstallments}
            installments={installments}
            selectedInstallment={selectedInstallment}
            onSelectedInstallment={onSelectedInstallment}
          />
        </div>
        <CreditCardForm
          isSubmitted={isSubmitted}
          errors={errors}
          state={state}
          handleInputChange={handleInputChange}
          handleInputFocus={handleInputFocus}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          installmentsLoading={installmentsLoading}
          cardBrand={cardBrand}
          invalidCard={invalidCard}
          selectedInstallment={selectedInstallment}
          makePaymentLoading={makePaymentLoading}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            const paste = e.clipboardData.getData("text");
            getCardInstallments(
              paste,
              getInstallments,
              resetInstallments,
              true
            );
          }}
        />
      </div>
    </form>
  );
};

export default CustomCreditCard;
