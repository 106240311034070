import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import HotelsWithoutWebSiteSelector from "./HotelsWithoutWebSiteSelector";
import AddWebSiteDomainLink from "./AddWebSiteDomainLink";
import AddNewWebSiteButton from "./AddNewWebSiteButton";

const AddWebSiteForm = () => {
  const {
    webSite,
    hotelsWithoutWebSiteOptions,
    formErrors,
    addWebSiteLoading,
    handleAddNewWebSite,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer title={"Web Sitesi Ekle"} xl={12} lg={12}>
      <HotelsWithoutWebSiteSelector
        values={webSite}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        hotelsWithoutWebSiteOptions={hotelsWithoutWebSiteOptions}
      />
      <AddWebSiteDomainLink
        values={webSite}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddNewWebSiteButton
        values={webSite}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        onAddNewWebSite={handleAddNewWebSite}
        isLoading={addWebSiteLoading}
      />
    </CustomFormContainer>
  );
};

export default AddWebSiteForm;
