import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  resetPlaceToVisit,
  handleSavePlacesToVisitList,
  setUpdatePlaceToVisitLoading,
  setUpdatePlaceToVisitError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_PLACES_TO_VISIT, UPDATE_PLACE_TO_VISIT } from "helpers/url_helper";

import { UpdatePlaceToVisitRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

export const getPlacesToVisit = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_PLACES_TO_VISIT);

    dispatch(handleSavePlacesToVisitList(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updatePlaceToVisit =
  (payload: UpdatePlaceToVisitRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdatePlaceToVisitLoading(true));
      await axios.post(UPDATE_PLACE_TO_VISIT, payload);

      dispatch(setToastSuccess("Gezilecek yer güncellendi."));
      dispatch(resetPlaceToVisit());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdatePlaceToVisitError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdatePlaceToVisitLoading(false));
    }
  };
