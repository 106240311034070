import { CustomButton } from "Components/Custom/UI";
import { FacilitySpecialDayState } from "types/facility";

interface UpdateSpecialDayButtonProps {
  values: FacilitySpecialDayState;
  validateForm: (values: FacilitySpecialDayState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onUpdateSpecialDay: () => void;
  isLoading: boolean;
}

const UpdateSpecialDayButton = ({
  values,
  validateForm,
  handleSubmitted,
  onUpdateSpecialDay,
  isLoading,
}: UpdateSpecialDayButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onUpdateSpecialDay();
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Özel Günü Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateSpecialDayButton;
