import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";

interface FacilityInetSelectFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  supplierOptions: Option[];
  priceProviderOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityInetSelectFields = ({
  values,
  formErrors,
  validateFormChange,
  supplierOptions,
  priceProviderOptions,
}: FacilityInetSelectFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Fiyat Sağlayıcısı"
        // error={formErrors.fiyat_saglayici}
        divider
      >
        <Select
          options={priceProviderOptions}
          value={priceProviderOptions.find(
            (option) => option.value === values.fiyat_saglayici
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                fiyat_saglayici: selected.value,
              })
            );
            validateFormChange({
              ...values,
              fiyat_saglayici: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tedarikçi"
        // error={formErrors.tedarikci}
        divider
      >
        <Select
          options={supplierOptions}
          value={supplierOptions.find(
            (option) => option.value === values.tedarikci
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                tedarikci: selected.value,
              })
            );
            validateFormChange({
              ...values,
              tedarikci: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="iNET Durum"
        // error={formErrors.inet_durum}
        divider
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.inet_durum === "1"}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  inet_durum: values.inet_durum === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="iNET Operatör Kodu"
        // error={formErrors.inet_operator_kodu}
      >
        <Input
          type="text"
          value={values.inet_operator_kodu}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                inet_operator_kodu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              inet_operator_kodu: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityInetSelectFields;
