import { convertPricingStructure } from "helpers/general_helpers";
import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleTable = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );
  const groupHotels =
    Object.keys(calculatePriceForHotel.zincir_oteller).length > 0
      ? Object.values(calculatePriceForHotel.zincir_oteller)
      : [];

  const tableData = groupHotels?.map((hotel) => ({
    "Otel Adı": hotel.otel_adi,
    Bölge: hotel.otel_mevkii.slice(0, -4),
    "Yeme İçme": hotel.otel_pansiyon_tipi.split("|").join(", ").slice(1, -2),
    "İndirim Oranı": hotel.otel_indirim_orani,
    "Çizgili Fiyat": hotel.otel_cizgili_fiyat,
    "Vitrin Fiyatı": hotel.otel_vitrin_fiyat,
    "Çocuk Açıklama": convertPricingStructure(hotel.otel_ucretsiz_cocuk),
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Bölge",
      accessorKey: "Bölge",
      enableColumnFilter: false,
    },
    {
      header: "Yeme İçme",
      accessorKey: "Yeme İçme",
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "İndirim Oranı",
      enableColumnFilter: false,
    },
    {
      header: "Çizgili Fiyat",
      accessorKey: "Çizgili Fiyat",
      enableColumnFilter: false,
    },
    {
      header: "Vitrin Fiyatı",
      accessorKey: "Vitrin Fiyatı",
      enableColumnFilter: false,
    },
    {
      header: "Çocuk Açıklama",
      accessorKey: "Çocuk Açıklama",
      cell: (cell: any) => {
        const value = cell.getValue() as [string, string, string, string];
        return (
          <div>
            {value[0] && <p>{value[0]}</p>}
            {value[1] && <p>{value[1]}</p>}
            {value[2] && <p>{value[2]}</p>}
            {value[3] && <p>{value[3]}</p>}
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
