import ReactApexChart from "react-apexcharts";
import { CHART_COLORS } from "utils/chartColors";

interface CustomPieChartProps {
  chartId: string;
  series: number[];
  labels: string[];
  chartHeight?: number;
  chartType?: string;
  showLegend?: boolean;
  legendPosition?: string;
  colors?: string[];
  title?: string;
  dataLabel?: boolean;
}

const getRandomUniqueColors = (count: number) => {
  const colors = Object.values(CHART_COLORS);

  if (count > colors.length) {
    return colors;
  }

  const shuffledColors = [...colors].sort(() => 0.5 - Math.random());
  return shuffledColors.slice(0, count);
};

const CustomPieChart = ({
  chartId,
  series,
  labels,
  chartHeight = 333,
  chartType = "pie",
  showLegend = true,
  legendPosition = "bottom",
  colors,
  title,
  dataLabel = true,
}: CustomPieChartProps) => {
  const chartColors = colors ?? getRandomUniqueColors(labels.length);

  const options: any = {
    labels,
    chart: {
      height: chartHeight,
      type: chartType,
    },
    legend: {
      show: showLegend,
      position: legendPosition,
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: dataLabel,
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartColors,
    title: {
      text: title,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
  };
  return (
    <ReactApexChart
      dir="ltr"
      options={options}
      series={series}
      id={chartId}
      type={chartType}
      height={chartHeight}
      className="apex-charts"
    />
  );
};

export default CustomPieChart;
