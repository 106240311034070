import { useSelector } from "react-redux";

import { ArchivedContractsInitialState } from "slices/financeAndAccounting/archivedContracts/reducer";

const useHandleTable = () => {
  const { archivedContracts } = useSelector(
    (state: { ArchivedContracts: ArchivedContractsInitialState }) =>
      state.ArchivedContracts
  );

  const tableData = archivedContracts?.map((archivedContract) => ({
    "Kontrat ID": archivedContract.id,
    "Otel Adı": archivedContract.otel_adi,
    Yetkilisi: archivedContract.ad_soyad,
    "Başlangıç Tarihi": archivedContract.baslangic_tarihi,
    "Bitiş Tarihi": archivedContract.bitis_tarihi,
    "Kontrat Durumu":
      archivedContract.kontrol_durum === "1" ? "Onaylandı" : "Onaylanmadı",
    Kontrat: archivedContract.url,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Yetkilisi",
      accessorKey: "Yetkilisi",
      enableColumnFilter: false,
    },
    {
      header: "Başlangıç Tarihi",
      accessorKey: "Başlangıç Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Bitiş Tarihi",
      accessorKey: "Bitiş Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Kontrat Durumu",
      accessorKey: "Kontrat Durumu",
      enableColumnFilter: false,
    },
    {
      header: "Kontrat Görüntüle",
      accessorKey: "Kontrat",
      cell: (cell: any) => {
        const link = cell.getValue();
        return (
          <a href={link} target="_blank" rel="noreferrer">
            Görüntüle
          </a>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
