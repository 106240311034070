import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacilityUserResponse,
  handleSaveUpdateFacilityUserState,
  handleSaveUserMails,
  setAddUserLoading,
  setAddUserError,
  setUpdateUserLoading,
  setUpdateUserError,
  setResetPasswordLoading,
  setResetPasswordError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_FACILITY_USER, FACILITY_USER } from "helpers/url_helper";

import { Dispatch } from "redux";
import {
  FacilityUserResponse,
  AddFacilityUserRequestPayload,
  UpdateFacilityUserRequestPayload,
  ResetFacilityUserPasswordRequestPayload,
} from "types/facility";

type SuccessCallback = () => void;

export const getFacilityUser =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(`${GET_FACILITY_USER}${otel_id}`);

      const data: FacilityUserResponse = response.data || response;

      const { kullanici_bilgileri, mailler } = data;

      dispatch(handleSaveFacilityUserResponse(data));
      dispatch(handleSaveUpdateFacilityUserState(kullanici_bilgileri));
      dispatch(handleSaveUserMails(mailler));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addFacilityUser =
  (payload: AddFacilityUserRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddUserLoading(true));

      await axios.post(FACILITY_USER, payload);

      successCallback();
      dispatch(setToastSuccess("Kullanıcı eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddUserError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddUserLoading(false));
    }
  };

export const updateFacilityUser =
  (
    payload: UpdateFacilityUserRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateUserLoading(true));

      await axios.post(FACILITY_USER, payload);

      successCallback();
      dispatch(setToastSuccess("Kullanıcı güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateUserError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateUserLoading(false));
    }
  };

export const resetFacilityUserPassword =
  (payload: ResetFacilityUserPasswordRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setResetPasswordLoading(true));
      await axios.post(FACILITY_USER, payload);

      dispatch(setToastSuccess("Yeni şifre e-posta adresinize gönderildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setResetPasswordError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setResetPasswordLoading(false));
    }
  };
