import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import CrmOperationSelectFields from "./CrmOperationSelectFields";

interface CrmOperationFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const CrmOperationFields = ({
  values,
  formErrors,
  validateFormChange,
}: CrmOperationFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="CRM Operasyon" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="CRM Operasyon Açıklama"
        error={formErrors.crm_operasyon_aciklamasi}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.crm_operasyon_aciklamasi}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                crm_operasyon_aciklamasi: data,
              })
            );
            validateFormChange({
              ...values,
              crm_operasyon_aciklamasi: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CrmOperationSelectFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
    </CustomFormContainer>
  );
};

export default CrmOperationFields;
