import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/themes/listThemes/reducer";

import { UpdateThemeFormErrors, UpdateThemeState } from "types/themes";

interface Option {
  value: string;
  label: string;
}

interface UpdateThemeSelectorsProps {
  values: UpdateThemeState;
  formErrors: UpdateThemeFormErrors;
  validateFormChange: (values: UpdateThemeState) => void;
  regionOptions: Option[];
  statusOptions: Option[];
}

const UpdateThemeSelectors = ({
  values,
  validateFormChange,
  formErrors,
  regionOptions,
  statusOptions,
}: UpdateThemeSelectorsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Tema Bölgesi"
        error={formErrors.tema_bolge}
      >
        <Select
          value={
            regionOptions.find(
              (option) => option.value === values.tema_bolge
            ) || null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                tema_bolge: selected.value,
              })
            );
            validateFormChange({ ...values, tema_bolge: selected.value });
          }}
          options={regionOptions}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Durum" error={formErrors.durum}>
        <Select
          value={
            statusOptions.find((option) => option.value === values.durum) ||
            null
          }
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                durum: selected.value,
              })
            );
            validateFormChange({ ...values, durum: selected.value });
          }}
          options={statusOptions}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UpdateThemeSelectors;
