import useHandleForm from "./useHandleForm";
import AddUserButton from "./AddUserButton/AddUserButton";
import UserInfoFields from "./UserInfoFields";
import UserAuthorityFields from "./UserAuthorityFields";

const AddUserForm = () => {
  const {
    addUserLoading,
    addUser,
    addUserFormChoices,
    addUserFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addUserRequestPayload,
  } = useHandleForm();

  return (
    <div className="d-flex flex-column gap-4">
      <UserInfoFields
        values={addUser}
        formErrors={addUserFormErrors}
        formChoices={addUserFormChoices}
        validateFormChange={validateFormChange}
      />
      {/* {(addUser.yetki === "root" || addUser.yetki === "admin") && (
        <UserAuthorityFields
          values={addUser}
          formChoices={addUserFormChoices}
          formErrors={addUserFormErrors}
          validateFormChange={validateFormChange}
        />
      )} */}
      <div className="d-flex justify-content-end">
        <AddUserButton
          values={addUser}
          requestPayload={addUserRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={addUserLoading}
        />
      </div>
    </div>
  );
};

export default AddUserForm;
