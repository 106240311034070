import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Col, Input, Label, Row } from "reactstrap";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FACILITY_THEME_DESCRIPTION } from "utils/fieldDescriptions";
import { FACILITY_THEME_WARNING_DESCRIPTIONS } from "utils/pageDescriptions";
import { useState } from "react";
import FacilityThemeFeatureMatchModal from "./FacilityThemeFeatureMatchModal";

interface FacilityMainThemeProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityThemeOptions: Option[];
  facilityThemeFeatureMatchOptions: {
    key: string;
    value: string[];
  }[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityMainTheme = ({
  values,
  formErrors,
  validateFormChange,
  facilityThemeOptions,
  facilityThemeFeatureMatchOptions,
}: FacilityMainThemeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Teması"
        error={formErrors.otel_tema_id}
        description={FACILITY_THEME_DESCRIPTION}
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityThemeOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={"main-theme-" + option.value}
                checked={values.otel_tema_id.includes(parseInt(option.value))}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.otel_tema_id, parseInt(value)]
                    : values.otel_tema_id.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      otel_tema_id: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    otel_tema_id: newValues,
                  });
                }}
              />
              <Label
                for={"main-theme-" + option.value}
                className="fw-normal fs-14"
              >
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
      <Row className="border rounded overflow-hidden mb-3">
        <Col
          xs={0}
          md={1}
          className={`d-flex justify-content-center align-items-center theme-bg-danger`}
        >
          <i className={`ri-error-warning-line text-white fw-medium fs-36`}></i>
        </Col>
        <Col
          lg={11}
          className="d-flex justify-content-end gap-5 theme-bg-light p-2 ps-3"
        >
          <div className="d-flex flex-grow-1 flex-column gap-1">
            {FACILITY_THEME_WARNING_DESCRIPTIONS.map((row, index) => (
              <p
                key={index}
                className={`fw-medium m-0 fs-14 ${
                  index === FACILITY_THEME_WARNING_DESCRIPTIONS.length - 1
                    ? "theme-text-primary underline cursor-pointer"
                    : ""
                }`}
                onClick={() => {
                  index === FACILITY_THEME_WARNING_DESCRIPTIONS.length - 1 &&
                    setShowModal(true);
                }}
              >
                {row}
              </p>
            ))}
          </div>
        </Col>
      </Row>
      <FacilityThemeFeatureMatchModal
        showModal={showModal}
        setShowModal={setShowModal}
        facilityThemeFeatureMatchOptions={facilityThemeFeatureMatchOptions}
      />
    </>
  );
};

export default FacilityMainTheme;
