import CustomCollapse from "Components/Custom/UI/CustomCollapse/CustomCollapse";
import { CrmDashboardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";
import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface ExtensionNumberSituationProps {
  values: CrmDashboardData;
}

const ExtensionNumberSituation = ({
  values,
}: ExtensionNumberSituationProps) => {
  const value = values.dahili_durum;

  const { tableData, tableColumns } = useHandleTable(value);
  return (
    <>
      <CustomCollapse
        title="Dahili Durum"
        bgColor={DASHBOARD_COLORS.purple}
        icon="ri-history-line"
      >
        <CustomReactTable
          tableData={tableData}
          tableColumns={tableColumns}
        />
      </CustomCollapse>
    </>
  );
};

export default ExtensionNumberSituation;
