import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Periods } from "types/PricesQuotas";

interface InputValues {
  [key: string]: number;
}

interface ColumnInputValues {
  [key: string]: InputValues;
}
export interface AdvancedEntryInitialState {
  isLoading: boolean;
  error: string;
  selectedRoom: number;
  selectedConcept: number;
  selectedDates: {
    startDate: string;
    endDate: string;
  };
  selectedDays: number[];
  isAllDaysSelected: boolean;
  nights: number;
  periods: Periods;
  childSpeacialAgeId: number;
  baseFormInputValues: InputValues;
  columnsInputValues: ColumnInputValues;
  periodsLoading: boolean;
  periodsError: string;
}

export const initialState: AdvancedEntryInitialState = {
  isLoading: false,
  error: "",
  periodsLoading: false,
  periodsError: "",
  selectedRoom: 0,
  selectedConcept: 0,
  selectedDates: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(1, "months").format("YYYY-MM-DD"),
  },
  selectedDays: [],
  isAllDaysSelected: true,
  nights: 1,
  periods: {
    ozel_yas_araligi: [],
    fiyat_tipleri: [],
    veriler: [],
    odeme_sekli: {
      odeme: [],
      fiyat_durum: {},
      oranlar: {},
    },
    rezervasyonlar: [{ rez_sayisi: 0 }],
    geceleme_data: {},
  },
  childSpeacialAgeId: 0,
  baseFormInputValues: {},
  columnsInputValues: {
    0: {},
  },
};

const advancedEntrySlice = createSlice({
  name: "advancedEntry",
  initialState,
  reducers: {
    handleSavePeriods(state, action) {
      state.periods = action.payload;
    },
    handleSavePeriodsPeriod(state, action) {
      state.periods.veriler = action.payload;
    },
    handleSaveSelectedRoom(state, action) {
      state.selectedRoom = action.payload;
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSaveSelectedConcept(state, action) {
      state.selectedConcept = action.payload;
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSelectedDates(state, action) {
      const dates = action.payload;
      state.selectedDates = {
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSelectedDays(state, action) {
      state.selectedDays = action.payload
        ? action.payload.map((day: any) => day.value)
        : [];
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleIsAllDaysSelected(state, action) {
      state.isAllDaysSelected = action.payload;

      if (state.isAllDaysSelected) {
        state.selectedDays = [];
      }
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSaveNights(state, action) {
      state.nights = action.payload;
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSaveChilSpecialAgeId(state, action) {
      state.childSpeacialAgeId = action.payload;
      state.periods = {
        ozel_yas_araligi: state.periods.ozel_yas_araligi,
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
    handleSaveBaseFormInputValues(state, action) {
      state.baseFormInputValues = {
        ...state.baseFormInputValues,
        ...action.payload,
      };
    },
    handleSaveColumnsInputValues(state, action) {
      delete state.columnsInputValues[0];
      state.columnsInputValues = {
        ...state.columnsInputValues,
        ...action.payload,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setPeriodsLoading(state, action) {
      state.periodsLoading = action.payload;
    },
    setPeriodsError(state, action) {
      state.periodsError = action.payload;
    },
    resetAll(state) {
      state.selectedDays = [];
      state.isAllDaysSelected = true;
      state.selectedDates = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "months").format("YYYY-MM-DD"),
      };
      state.isLoading = false;
      state.error = "";
      state.periods = {
        ozel_yas_araligi: [],
        fiyat_tipleri: [],
        veriler: [],
        odeme_sekli: {
          odeme: [],
          fiyat_durum: {},
          oranlar: {},
        },
        rezervasyonlar: [{ rez_sayisi: 0 }],
        geceleme_data: {},
      };
    },
  },
});

export const {
  handleSavePeriods,
  handleSavePeriodsPeriod,
  handleSaveSelectedRoom,
  handleSaveSelectedConcept,
  handleSelectedDates,
  handleSelectedDays,
  handleIsAllDaysSelected,
  handleSaveNights,
  handleSaveChilSpecialAgeId,
  handleSaveBaseFormInputValues,
  handleSaveColumnsInputValues,
  setIsLoading,
  setError,
  setPeriodsLoading,
  setPeriodsError,
  resetAll,
} = advancedEntrySlice.actions;

export default advancedEntrySlice.reducer;
