import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/listPlacesToVisit/reducer";
import {
  UpdatePlaceToVisitFormErrors,
  UpdatePlaceToVisitState,
} from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface PlaceToVisitTitleProps {
  values: UpdatePlaceToVisitState;
  formErrors: UpdatePlaceToVisitFormErrors;
  validateFormChange: (values: UpdatePlaceToVisitState) => void;
}

const PlaceToVisitTitle = ({
  values,
  validateFormChange,
  formErrors,
}: PlaceToVisitTitleProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Başlık" error={formErrors.yer_adi}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.yer_adi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                yer_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              yer_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PlaceToVisitTitle;
