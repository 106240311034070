import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resetEmail: "",
  isResetCodeSent: false,
  forgetSuccessMsg: null,
  forgetError: null,
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
    userForgetPasswordSuccess(state, action) {
      state.forgetSuccessMsg = action.payload;
    },
    userForgetPasswordError(state, action) {
      state.forgetError = action.payload;
    },
    setIsResetCodeSent(state, action) {
      state.isResetCodeSent = action.payload;
    },
    setResetEmail(state, action) {
      state.resetEmail = action.payload;
    },
  },
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setIsResetCodeSent,
  setResetEmail,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
