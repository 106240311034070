import { Button, Input, InputGroup, InputGroupText, Label } from "reactstrap";

interface PriceRateInputProps {
  label?: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
}
const PriceRateInput = ({
  label,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
}: PriceRateInputProps) => {
  return (
    <div className="w-50">
      {label && <Label className="form-label">{label}</Label>}
      <div className="d-flex align-items-center gap-1">
        <InputGroup>
          <Button
            style={{ width: "40px" }}
            onClick={() => {
              if (value > min) {
                const res = (value - step).toFixed(2);
                onChange(Number(res));
              }
            }}
          >
            -
          </Button>
          <InputGroupText>%</InputGroupText>
          <Input
            style={{ width: "80px", textAlign: "center" }}
            type="number"
            value={value}
            onChange={(e) => {
              onChange(Number(e.target.value));
            }}
          />
          <Button
            style={{ width: "40px" }}
            onClick={() => {
              if (value < max) {
                const res = (value + step).toFixed(2);
                onChange(Number(res));
              }
            }}
          >
            +
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default PriceRateInput;
