import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/themes/addNewTheme/reducer";
import { AddNewThemeFormErrors, AddNewThemeState } from "types/themes";
import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Input } from "reactstrap";

interface ThemeContentAndLinkProps {
  values: AddNewThemeState;
  formErrors: AddNewThemeFormErrors;
  validateFormChange: (values: AddNewThemeState) => void;
}

const ThemeContentAndLink = ({
  values,
  validateFormChange,
  formErrors,
}: ThemeContentAndLinkProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="İçerik"
        error={formErrors.aciklama}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Link" error={formErrors.link}>
        <Input
          type="text"
          value={values.link}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                link: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              link: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ThemeContentAndLink;
