import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { EARLY_RESERVATION_DISCOUNTS_DESCRIPTIONS } from "utils/pageDescriptions";
import EarlyReservationDiscountsForm from "./EarlyReservationDiscountsForm";
import EarlyReservationDiscountsTable from "./EarlyReservationDiscountsTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./EarlyReservationDiscountsForm/RoomSelector";

interface EarlyReservationDiscountsPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const EarlyReservationDiscountsPage = (
  props: EarlyReservationDiscountsPageProps
) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={EARLY_RESERVATION_DISCOUNTS_DESCRIPTIONS}
      isLoading={isLoading}
      roomSelector={<RoomSelector {...props} />}
    >
      <EarlyReservationDiscountsForm {...props} />
      <EarlyReservationDiscountsTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(EarlyReservationDiscountsPage);
