import { Input, Label } from "reactstrap";
import {
  CalculatePriceForHotelSpecialDiscount,
  CalculatePriceFormValues,
  EnterReservationFormValues,
} from "types/reservation";

interface SpecialDiscountRowProps
  extends CalculatePriceForHotelSpecialDiscount {
  formValues: CalculatePriceFormValues | EnterReservationFormValues;
  handleChangeForm: (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => void;
}

const SpecialDiscountRow = ({
  formValues,
  handleChangeForm,
  ozel_indirim_id,
  ozel_indirim_orani,
  ozel_indirim_isim,
}: SpecialDiscountRowProps) => {
  return (
    <div className="d-flex flex-column">
      <div className="pb-1 border-bottom">
        <Input
          id={ozel_indirim_id}
          value={ozel_indirim_id}
          className="cursor-pointer"
          type="checkbox"
          checked={formValues.ozel_indirimler.includes(ozel_indirim_id)}
          onChange={(e) => {
            const isChecked = e.target.checked;

            const specialDiscounts = isChecked
              ? [...formValues.ozel_indirimler, ozel_indirim_id]
              : formValues.ozel_indirimler.filter(
                  (item) => item !== ozel_indirim_id
                );

            handleChangeForm("ozel_indirimler", specialDiscounts);
          }}
        />
        <Label
          for={ozel_indirim_id}
          className="m-0 ms-2 fw-semibold fs-14 cursor-pointer"
        >
          {ozel_indirim_isim} | %{ozel_indirim_orani} İndirim
        </Label>
      </div>
    </div>
  );
};

export default SpecialDiscountRow;
