import { IssuedInvoicesPage } from "Components/Custom/FinanceAndAccountingPages";
import useIssuedInvoices from "Components/Hooks/financeAndAccounting/useIssuedInvoices";
import PageContainer from "pages/PageContainer";

const IssuedInvoices = () => {
  const { isLoading } = useIssuedInvoices();

  return (
    <PageContainer title="Kesilen Faturalar">
      <IssuedInvoicesPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default IssuedInvoices;
