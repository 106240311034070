import { createSlice } from "@reduxjs/toolkit";

import { ForecastReportResponse } from "types/reports";

export interface ForecastReportInitialState {
  forecastReportResponse: ForecastReportResponse;
  isLoading: boolean;
  error: string;
}

const initialState: ForecastReportInitialState = {
  forecastReportResponse: {
    tarihler: [],
    kalan_kontenjan: [],
    rez_sayisi: [],
    toplam_kontenjan: [],
    genel_toplam: 0,
    toplam_rez: 0,
    toplam_kalan: 0,
  },
  isLoading: false,
  error: "",
};

const forecastReportSlice = createSlice({
  name: "forecastReport",
  initialState,
  reducers: {
    handleSaveForecastReportResponse(state, action) {
      const {
        tarihler,
        kalan_kontenjan,
        rez_sayisi,
        toplam_kontenjan,
        genel_toplam,
        toplam_rez,
        toplam_kalan,
      } = action.payload;
      state.forecastReportResponse = {
        tarihler: tarihler ?? [],
        kalan_kontenjan: kalan_kontenjan ?? [],
        rez_sayisi: rez_sayisi ?? [],
        toplam_kontenjan: toplam_kontenjan ?? [],
        genel_toplam: genel_toplam ?? 0,
        toplam_rez: toplam_rez ?? 0,
        toplam_kalan: toplam_kalan ?? 0,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveForecastReportResponse, setIsLoading, setError } =
  forecastReportSlice.actions;

export default forecastReportSlice.reducer;
