import { CompetitorAnalysisPage } from "Components/Custom/AdsAndAnalyzePages";
import useCompetitorAnalysis from "Components/Hooks/adsAndAnalyze/useCompetitorAnalysis";
import PageContainer from "pages/PageContainer";

const CompetitorAnalysis = () => {
  const { isLoading } = useCompetitorAnalysis();

  return (
    <PageContainer title="Rakip Analizi">
      <CompetitorAnalysisPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default CompetitorAnalysis;
