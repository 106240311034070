import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface HotelContractsTableProps {
  isLoading: boolean;
}

const HotelContractsTable = ({ isLoading }: HotelContractsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Otel Kontrat İşlemleri">
      <CustomReactTable
        fileName="Otel Kontrat İşlemleri"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
      />
    </CustomFormContainer>
  );
};

export default HotelContractsTable;
