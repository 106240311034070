import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { LoginInitialState } from "slices/auth/login/reducer";
import { handleChangeReservationFilterChoices, ReservationsInitialState } from "slices/reports/reservations/reducer";
import { getReservations } from "slices/reports/reservations/thunk";
import { ReservationFilterPayload } from "types/reports";

const useHandleForm = () => {
  const { isLoading, reservationsFormChoices, filterReservations } = useSelector(
    (state: { Reservations: ReservationsInitialState }) =>
      state.Reservations
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki } = user;

  const hiddenFormFields =reservationsFormChoices.gosterilmeyecek_alanlar[yetki] ?? [];
  
  const allFormKeys = [
    'tarih_turu',
    'start_date',
    'end_date',
    'rezervasyon_kodu',
    'inet_kodu',
    'musteri_adi',
    'otel_list',
  ];

  const fieldVisibility: { [key: string]: boolean } = {};

  allFormKeys.forEach((key) => {
    fieldVisibility[key] = !hiddenFormFields.includes(key);
  });
  
  
  const [filterForm, setFilterForm] = useState<ReservationFilterPayload>({
    tarih_turu: filterReservations.tarih_turu,
    otel_id: filterReservations.otel_id,
    start_date: filterReservations.start_date,
    end_date: filterReservations.end_date,
    acente_id: filterReservations.acente_id,
    rezervasyon_kodu: filterReservations.rezervasyon_kodu,
    inet_kodu: filterReservations.inet_kodu,
    musteri_adi: filterReservations.musteri_adi,
    otel_list: filterReservations.otel_list,
    per_page: filterReservations.per_page,
    page: filterReservations.page,
    search: filterReservations.search,
  });

  const handleFormChange = (key: string, value: any ) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      tarih_turu: filterForm.tarih_turu,
      otel_id: filterForm.otel_id,
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
      acente_id: filterForm.acente_id,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
      inet_kodu: filterForm.inet_kodu,
      musteri_adi: filterForm.musteri_adi,
      otel_list: filterForm.otel_list,
      per_page: filterForm.per_page,
      page: filterForm.page,
      search: filterForm.search,
    };
    dispatch(getReservations(params));
    dispatch(handleChangeReservationFilterChoices(params));
  };

  const handleResetFilter = () => {
    setFilterForm({
      tarih_turu: "",
      otel_id: 0,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: "",
      acente_id: "",
      rezervasyon_kodu: "",
      inet_kodu: "",
      musteri_adi: "",
      otel_list: [],
      per_page: 10,
      page: 1,
      search: "",
    });

    setSelectedHotelOptions([]);

    const params = {
      tarih_turu: "",
      otel_id: 0,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: "",
      acente_id: "",
      rezervasyon_kodu: "",
      inet_kodu: "",
      musteri_adi: "",
      otel_list: [],
      per_page: 10,
      page: 1,
      search: "",
    };

    dispatch(getReservations(params));
    dispatch(handleChangeReservationFilterChoices(params));
  };

  const dateTypeOptions = reservationsFormChoices.tarih_turu.map((item) => ({
    value: item.value,
    label: item.text,
  }));

  const hotelOptions = reservationsFormChoices.otel_list.map((item) => ({
    value: item.otel_id.toString(),
    label: item.otel_adi,
  }));

  const [selectedHotelOptions, setSelectedHotelOptions] = useState<string[]>([]);

  const handleSelectHotelOptions = (selected: string[]) => {
    const selectedNumbers = selected.map((id) => Number(id));
    setSelectedHotelOptions(selected);
    handleFormChange("otel_list", selectedNumbers);
  };

  // const agencyOptions = reservationsFormChoices.acente_list.map((item) => ({
  //   value: item.otel_id,
  //   label: item.otel_adi,
  // }));

  return {
    fieldVisibility,
    dateTypeOptions,
    hotelOptions,
    isLoading,
    filterForm,
    selectedHotelOptions,
    handleFormChange,
    onSelectHotels : handleSelectHotelOptions,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;