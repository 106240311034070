import { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { getReservationCall } from "slices/reservation/reservationCall/thunk";

const useCalculatePriceForHotel = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { calculatePriceForHotel, calculatePriceLoading, reservationCall } =
    useSelector(
      (state: { ReservationCall: ReservationCallInitialState }) =>
        state.ReservationCall
    );

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const hasReservationCall = !!reservationCall.arama_id;

  useEffect(() => {
    if (aranan && arayan && dahili && !hasReservationCall) {
      dispatch(getReservationCall({ aranan, arayan, dahili }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { calculatePriceForHotel, calculatePriceLoading };
};

export default useCalculatePriceForHotel;
