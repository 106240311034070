import { CustomTooltip } from "Components/Custom/UI";
import { Col, Input, Label, Row } from "reactstrap";
import { ctaTooltip, ctdTooltip } from "utils/tooltips";

interface CtaCtdFieldProps {
  values: any;
  onChange: (name: string, value: any) => void;
}

const CtaCtdField = ({ values, onChange: handleChange }: CtaCtdFieldProps) => {
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-input mt-0"
            id="cta"
            checked={values["cta"] === 1}
            onChange={(e) => handleChange("cta", e.target.checked ? 1 : 0)}
          />
          <Label className="m-0 fs-14" htmlFor="cta">
            CTA
            <CustomTooltip
              title={ctaTooltip.title}
              message={ctaTooltip.message}
              target={ctaTooltip.target}
            />
          </Label>
        </div>
      </Col>
      <Col xs={6}>
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-input mt-0"
            id="ctd"
            checked={values["ctd"] === 1}
            onChange={(e) => handleChange("ctd", e.target.checked ? 1 : 0)}
          />
          <Label className="m-0 fs-14" htmlFor="ctd">
            CTD
            <CustomTooltip
              title={ctdTooltip.title}
              message={ctdTooltip.message}
              target={ctdTooltip.target}
            />
          </Label>
        </div>
      </Col>
    </Row>
  );
};

export default CtaCtdField;
