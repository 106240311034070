import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import EditFacilityRoomsForm from "./EditFacilityRoomsForm";
import UpdateFacilityRoomInfoForm from "./UpdateFacilityRoomInfoForm";
import AddFacilityRoomImagesForm from "./AddFacilityRoomImagesForm";
import ViewRoomImages from "./ViewRoomImages";
import { CustomLoadingPage } from "Components/Custom/UI";

interface FacilityRoomsPageProps {
  isLoading: boolean;
  hotelRooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    selectedRoom: number;
    handleSelectRoom: (selectedRoom: number) => void;
  };
}

const FacilityRoomsPage = (props: FacilityRoomsPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={props.isLoading} />
      <div className="d-flex flex-column gap-4">
        <EditFacilityRoomsForm />
        <UpdateFacilityRoomInfoForm {...props} />
        <AddFacilityRoomImagesForm
          selectedRoom={props.hotelAndSelectedRoom.selectedRoom}
        />
        <ViewRoomImages
          selectedRoom={props.hotelAndSelectedRoom.selectedRoom}
        />
      </div>
    </>
  );
};

export default withPageNeedsHotel(FacilityRoomsPage);
