import useHandleTable from "./useHandleTable";
import { CustomReactTable } from "Components/Custom/UI";

interface RiskyHotelsTableProps {
  isLoading: boolean;
}

const RiskyHotelsTable = ({ isLoading }: RiskyHotelsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  return (
    <CustomReactTable
      fileName="Riskli Oteller"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default RiskyHotelsTable;
