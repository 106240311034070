import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FormGroup, Input } from "reactstrap";

interface FacilityStatusProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  statusOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityStatus = ({
  values,
  validateFormChange,
  formErrors,
  statusOptions,
}: FacilityStatusProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otelfiyat Aktiflik"
        // error={formErrors.aktifmi}
        divider
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.aktifmi === "1"}
            onChange={(e) => {
              const value = e.target.checked ? "1" : "0";
              dispatch(
                handleChangeInput({
                  ...values,
                  aktifmi: value,
                })
              );
              validateFormChange({
                ...values,
                aktifmi: value,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityStatus;
