import { createSlice } from "@reduxjs/toolkit";

import { FacilitySpecialDay } from "types/facility";

import { Pagination } from "types/GlobalTypes";

export interface SpecialDaysInitialState {
  specialDays: FacilitySpecialDay[];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

export const specialDaysInitialState: SpecialDaysInitialState = {
  specialDays: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const facilitySpecialDaysSlice = createSlice({
  name: "facilitySpecialDays",
  initialState: specialDaysInitialState,
  reducers: {
    handleSaveSpecialDays(state, action) {
      const { data, ...rest } = action.payload;
      state.specialDays = data;
      state.pagination = rest;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { handleSaveSpecialDays, setIsLoading, setError } =
  facilitySpecialDaysSlice.actions;

export default facilitySpecialDaysSlice.reducer;
