import PersonnelHotelRow from "./PersonnelHotelRow";
import useHandleTable from "./useHandleTable";

type ShowTableState = "show_data" | "no_data" | "default";
interface AssignedHotelsTableProps {
  showTableData: ShowTableState;
}

const AssignedHotelsTable = ({ showTableData }: AssignedHotelsTableProps) => {
  const { hotelsOfPersonnelInTheCity } = useHandleTable();

  return (
    <>
      {showTableData === "show_data" && (
        <PersonnelHotelRow values={hotelsOfPersonnelInTheCity} />
      )}
      {showTableData === "no_data" && <>Seçimlerinize uygun otel bulunamadı.</>}
      {showTableData === "default" && <></>}
    </>
  );
};

export default AssignedHotelsTable;
