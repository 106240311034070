import { createSlice } from "@reduxjs/toolkit";
import {
  UpdateUserFormErrors,
  UpdateUserPayload,
  UpdateUserState,
} from "types/userOperations";

export interface UpdateUserInitialState {
  userToUpdate: UpdateUserState;
  updatedUser: UpdateUserPayload;
  updateUserFormErrors: UpdateUserFormErrors;
  isLoading: boolean;
  error: string;
  updateUserLoading: boolean;
  updateUserError: string;
}

export const updateUserInitialState: UpdateUserInitialState = {
  userToUpdate: {
    uye_id: "",
    kullanici_adi: "",
    ad_soyad: "",
    email_adresi: "",
    dahili: 0,
    kullanici_tipi: [],
    calisma_tipi: [],
    cinsiyet: [],
    yetkiler: [],
  },
  updatedUser: {
    id: "",
    kullanici_adi: "",
    ad_soyad: "",
    email_adresi: "",
    dahili: 0,
    kullanici_tipi: "",
    calisma_tipi: "",
    cinsiyet: "",
    yetkiler: [],
  },
  updateUserFormErrors: {},
  isLoading: false,
  error: "",
  updateUserLoading: false,
  updateUserError: "",
};

export const updateUserSlice = createSlice({
  name: "updateUser",
  initialState: updateUserInitialState,
  reducers: {
    handleUserToUpdate(state, action) {
      state.userToUpdate = action.payload;

      const payload = action.payload as UpdateUserState;

      const updatedUser: UpdateUserPayload = {
        id: payload.uye_id,
        kullanici_adi: payload.kullanici_adi,
        ad_soyad: payload.ad_soyad,
        email_adresi: payload.email_adresi,
        dahili: payload.dahili,
        kullanici_tipi:
          payload.kullanici_tipi[0].find(
            (option) => option.checked === "checked"
          )?.value || "",
        calisma_tipi:
          payload.calisma_tipi.find((option) => option.checked === "checked")
            ?.value || "",
        cinsiyet:
          payload.cinsiyet.find((option) => option.checked === "checked")
            ?.value || "",
        yetkiler: payload.yetkiler
          .filter((option) => option.selected === "selected")
          .map((option) => option.modul_id),
      };
      state.updatedUser = updatedUser;
    },
    handleChangeInput(state, action) {
      state.updatedUser = {
        ...state.updatedUser,
        ...action.payload,
      };
    },
    handleUpdateUserFormErrors(state, action) {
      state.updateUserFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateUserLoading(state, action) {
      state.updateUserLoading = action.payload;
    },
    setUpdateUserError(state, action) {
      state.updateUserError = action.payload;
    },
    handleResetUserState(state) {
      state.userToUpdate = {
        uye_id: "",
        kullanici_adi: "",
        ad_soyad: "",
        email_adresi: "",
        dahili: 0,
        kullanici_tipi: [],
        calisma_tipi: [],
        cinsiyet: [],
        yetkiler: [],
      };
    },
  },
});

export const {
  handleUserToUpdate,
  handleChangeInput,
  handleUpdateUserFormErrors,
  setIsLoading,
  setError,
  setUpdateUserLoading,
  setUpdateUserError,
  handleResetUserState,
} = updateUserSlice.actions;

export default updateUserSlice.reducer;
