import { PAYMENT_METHODS } from "enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UnderTheDeskDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { UnderTheDeskDiscountsInitialState } from "slices/discountsAndActions/underTheDeskDiscounts/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    underTheDeskDiscount,
    underTheDeskDiscountFormErrors,
    addDiscountLoading,
    updateDiscountLoading,
  } = useSelector(
    (state: { UnderTheDeskDiscounts: UnderTheDeskDiscountsInitialState }) =>
      state.UnderTheDeskDiscounts
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newConceptsOptions: Option[] = hotel.pansiyonlar.map(
        (concept: any) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      setConceptsOptions(newConceptsOptions);

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];

      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const startDate = moment(underTheDeskDiscount.baslangic_tarih).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(underTheDeskDiscount.bitis_tarih).format("YYYY-MM-DD");

  const sellStartDate = moment(
    underTheDeskDiscount.satis_baslangic_tarih
  ).format("YYYY-MM-DD");
  const sellEndDate = moment(underTheDeskDiscount.satis_bitis_tarih).format(
    "YYYY-MM-DD"
  );

  const addNewUnderTheDeskDiscountRequestPayload: UnderTheDeskDiscountRequestPayload =
    {
      otel_id,
      otel_oda_id: oda_id,
      grup_id: underTheDeskDiscount.grup_id,
      her_zaman: underTheDeskDiscount.her_zaman,
      satis_tarihi: `${sellStartDate} - ${sellEndDate}`,
      konaklama_tarihi: `${startDate} - ${endDate}`,
      oran: Number(underTheDeskDiscount.oran),
      tum_pansiyonlar: Number(underTheDeskDiscount.tum_otel_pansiyonlar),
      secili_pansiyonlar: underTheDeskDiscount.otel_pansiyonlar,
      tum_odeme_tipleri: underTheDeskDiscount.tum_odeme_tipleri,
      secili_odeme_tipleri: underTheDeskDiscount.odeme_tipleri,
    };

  const updateUnderTheDeskDiscountRequestPayload: UnderTheDeskDiscountRequestPayload =
    {
      desk_alti_id: underTheDeskDiscount.id,
      ...addNewUnderTheDeskDiscountRequestPayload,
    };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    underTheDeskDiscount,
    conceptsOptions,
    paymentMethodOptions,
    addDiscountLoading,
    updateDiscountLoading,
    addNewUnderTheDeskDiscountRequestPayload,
    updateUnderTheDeskDiscountRequestPayload,
    formErrors: underTheDeskDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
