import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getNightRestrictions,
  addNightRestriction,
} from "slices/discountsAndActions/nightRestrictions/thunk";
import {
  NightRestrictionRequestPayload,
  NightRestrictionState,
} from "types/DiscountsAndActions";

interface AddNewRestrictionButtonProps {
  values: NightRestrictionState;
  requestPayload: NightRestrictionRequestPayload;
  validateForm: (values: NightRestrictionState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const AddNewRestrictionButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: AddNewRestrictionButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddNewRestriction = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addNightRestriction(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getNightRestrictions(hotelAndSelectedRoom));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleAddNewRestriction}
        isLoading={isLoading}
      >
        Yeni Gece Kısıtlaması Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewRestrictionButton;
