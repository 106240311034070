import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { UpdatePlaceToVisitRequestPayload } from "types/definitions";
import useValidateForm from "./useValidateForm";
import { ListPlacesToVisitInitialState } from "slices/definitions/listPlacesToVisit/reducer";

const useHandleForm = () => {
  const selectedListPlacesToVisitState = (state: {
    ListPlacesToVisit: ListPlacesToVisitInitialState;
  }) => state.ListPlacesToVisit;

  const selectListPlacesToVisitProperties = createSelector(
    selectedListPlacesToVisitState,
    (state) => ({
      placeToVisit: state.placeToVisit,
      updatePlaceToVisitFormErrors: state.updatePlaceToVisitFormErrors,
      isLoading: state.isLoading,
      error: state.error,
      updatePlaceToVisitLoading: state.updatePlaceToVisitLoading,
    })
  );

  const {
    placeToVisit,
    updatePlaceToVisitFormErrors,
    isLoading,
    error,
    updatePlaceToVisitLoading,
  } = useSelector(selectListPlacesToVisitProperties);

  const updatePlaceToVisitRequestPayload: UpdatePlaceToVisitRequestPayload =
    new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    placeToVisit,
    isLoading,
    error,
    updatePlaceToVisitRequestPayload,
    updatePlaceToVisitLoading,
    formErrors: updatePlaceToVisitFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
