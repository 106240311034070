import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";
import {
  setIsLoading,
  setError,
  handleSaveSpecialDiscounts,
  resetSpecialDiscount,
  resetSpecialDiscounts,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
  setAddSpecialOfferLoading,
  setAddSpecialOfferError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  ADD_SPECIAL_OFFER,
  GET_SPECIAL_OFFER,
  GET_SPECIAL_DISCOUNTS,
  SAVE_SPECIAL_DISCOUNT,
  UPDATE_SPECIAL_DISCOUNT,
  DELETE_SPECIAL_DISCOUNT,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { AddNewSpecialDiscountRequestPayload } from "types/DiscountsAndActions";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
}

interface DeleteSpecialDiscountPayload {
  indirim_id: number;
  otel_id: number;
}

type SuccessCallback = () => void;

export const getSpecialDiscounts =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(resetSpecialDiscounts());
      dispatch(resetSpecialDiscount());
      const response = await axios.get(GET_SPECIAL_DISCOUNTS, {
        params: {
          otel_id: payload.otel_id,
          oda_id: payload.oda_id,
        },
      });

      dispatch(handleSaveSpecialDiscounts(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addSpecialOffer = (payload: string) => async (dispatch: any) => {
  try {
    dispatch(setAddSpecialOfferLoading(true));
    await axios.post(ADD_SPECIAL_OFFER, {
      indirim_isim: payload,
    });

    dispatch(setToastSuccess("Özel indirim eklendi."));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setAddSpecialOfferError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setAddSpecialOfferLoading(false));
  }
};

export const getSpecialOffer = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await axios.get(GET_SPECIAL_OFFER);
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const saveSpecialDiscount =
  (
    payload: AddNewSpecialDiscountRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddDiscountLoading(true));

      await axios.post(SAVE_SPECIAL_DISCOUNT, payload);

      successCallback();
      dispatch(setToastSuccess("Özel indirim kaydedildi."));
      dispatch(resetSpecialDiscount());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddDiscountLoading(false));
    }
  };

export const updateSpecialDiscount =
  (
    payload: AddNewSpecialDiscountRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateDiscountLoading(true));

      await axios.post(UPDATE_SPECIAL_DISCOUNT, payload);

      successCallback();
      dispatch(setToastSuccess("Özel indirim güncellendi."));
      dispatch(resetSpecialDiscount());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateDiscountLoading(false));
    }
  };

export const deleteSpecialDiscount =
  (payload: DeleteSpecialDiscountPayload) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setDeleteDiscountLoading(true));
      await axios.post(DELETE_SPECIAL_DISCOUNT, payload);

      dispatch(setToastSuccess("Özel indirim silindi."));
      dispatch(resetSpecialDiscount());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteDiscountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteDiscountLoading(false));
    }
  };
