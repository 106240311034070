import KapidaPaymentModal from "../KapidaPaymentModal";
import ConceptButtonContainer from "../ConceptButtonContainer";
import { CustomButton } from "Components/Custom/UI";
import ButtonTooltip from "../ButtonTooltip";

interface KapidaPayButtonProps {
  stop: 0 | 1 | 2 | 3 | null;
  stopMessage: string;
  showKapidaOdemeButton: boolean;
  kapidaOdemeFiyatDurum: number;
  kapidaOdemeButtonText: string;
  kapidaOdemePrice: number;
  kapidaOdemeOverlinedPrice: number;
  quota: number;
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  isOpen: boolean;
  toggle: () => void;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const KapidaPayButton = ({
  stop,
  stopMessage,
  showKapidaOdemeButton,
  kapidaOdemeFiyatDurum,
  kapidaOdemeButtonText,
  kapidaOdemePrice,
  kapidaOdemeOverlinedPrice,
  quota,
  onClick,
  toggle,
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  isOpen,
  checked,
  setChecked,
}: KapidaPayButtonProps) => {
  return (
    <>
      <ConceptButtonContainer
        tooltipId={"kapida-" + pansiyon_id}
        showTooltip={true}
        showButton={showKapidaOdemeButton}
        text={
          stop === 1
            ? `<span class='fw-medium'>Son Odalar</span><br/><span>Son <strong>${quota} Adet</strong> Kapıda Ödeme Rezervasyonu Kalmıştır.</span>`
            : `<span>Son <strong>${quota} Adet</strong> Kapıda Ödeme Rezervasyonu Kalmıştır.</span>`
        }
        stop={stop}
        stopMessage={stopMessage}
      >
        <CustomButton
          classes="w-100"
          variant={
            stop === 1 ? "warning" : kapidaOdemeFiyatDurum ? "info" : "warning"
          }
          disabled={kapidaOdemeFiyatDurum === 0}
          onClick={() => {
            toggle();
          }}
        >
          {kapidaOdemeButtonText}
        </CustomButton>
        <ButtonTooltip
          target={"kapida-" + pansiyon_id}
          title="Misafiri Bilgilendiriniz!"
          description="<span></span>Rezervasyonunuz Otele Girişte, Tek Çekim Yada Nakit Tahsilat yapılarak gerçekleşecektir.</span><br/><span>Eğer Ödemenizi Taksitlendirmek istiyorsanız,  Şimdi Öde Butonundan İşleminizi Tamamlayınız.</span>"
        />
      </ConceptButtonContainer>
      <KapidaPaymentModal
        oda_id={oda_id}
        oda_adi={oda_adi}
        pansiyon_id={pansiyon_id}
        pansiyon_adi={pansiyon_adi}
        kapidaOdemePrice={kapidaOdemePrice}
        isOpen={isOpen}
        toggle={toggle}
        checked={checked}
        setChecked={setChecked}
        onClick={onClick}
      />
    </>
  );
};

export default KapidaPayButton;
