import CreateNewNotificationPage from 'Components/Custom/UserOperationsPages/CreateNewNotificationPage';
import useCreateNewNotification from 'Components/Hooks/userOperations/useCreateNewNotification';
import PageContainer from 'pages/PageContainer';

const CreateNewNotification = () => {

    const { isLoading } = useCreateNewNotification();

  return (
    <PageContainer title="Bildirim Oluştur">
        <CreateNewNotificationPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default CreateNewNotification