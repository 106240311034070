import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AssignHotelToThemeRequestPayload, ThemeHotel } from "types/themes";

import { useEffect, useMemo, useState } from "react";
import {
  addHotelToTheme,
  getHotelsForTheme,
} from "slices/themes/assignHotelToTheme/thunk";
import { AssignHotelToThemeInitialState } from "slices/themes/assignHotelToTheme/reducer";
import { useSelector } from "react-redux";

const useHandleList = (hotels: ThemeHotel[], selectedTheme: number) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { addHotelToThemeLoading } = useSelector(
    (state: { AssignHotelToTheme: AssignHotelToThemeInitialState }) =>
      state.AssignHotelToTheme
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const hotelsListOptions = hotels.map((hotel) => ({
    value: hotel.value.toString(),
    label: hotel.text,
  }));

  const assignedHotels = useMemo(() => {
    return hotels
      .filter((hotel) => hotel.selected)
      .map((hotel) => hotel.value.toString());
  }, [hotels]);

  useEffect(() => {
    setSelectedOptions(assignedHotels);
  }, [assignedHotels]);

  const handleSaveAssignedHotels = () => {
    const payload: AssignHotelToThemeRequestPayload = {
      tema_id: selectedTheme,
      oteller: selectedOptions.map((option) => parseInt(option)),
    };

    dispatch(
      addHotelToTheme(payload, () => {
        dispatch(getHotelsForTheme({ tema_id: selectedTheme }));
      })
    );
  };

  return {
    selectedOptions,
    setSelectedOptions,
    hotelsListOptions,
    handleSaveAssignedHotels,
    isLoading: addHotelToThemeLoading,
  };
};

export default useHandleList;
