import {
  ChartContainer,
  CustomSimpleLineChart,
} from "Components/Custom/UI/CustomCharts";
import useHandleChart from "./useHandleChart";

interface HourlyCallsChartProps {
  isLoading: boolean;
}

const HourlyCallsChart = ({ isLoading }: HourlyCallsChartProps) => {
  const { formattedData, colors } = useHandleChart();

  return (
    <ChartContainer
      noData={formattedData.length === 0}
      containerHeight={300}
      containerWidth="100%"
      isLoading={isLoading}
    >
      <CustomSimpleLineChart data={formattedData} colors={colors} />
    </ChartContainer>
  );
};

export default HourlyCallsChart;
