import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface UserIPsTableProps {
  isLoading: boolean;
}

const UserIPsTable = ({ isLoading }: UserIPsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();

  return(
  <>
    <CustomReactTable
      loading={isLoading}
      tableData={tableData}
      tableColumns={tableColumns}
    />
  </>
  );
};
export default UserIPsTable;
