import { CalculatePriceForHotelConceptPrice } from "types/reservation";
import ConceptInfo from "./ConceptPrices/ConceptInfo";
import PriceCalculationStatusMessages from "./ConceptPrices/PriceCalculationStatusMessages";

interface ConceptCardWithoutPriceProps {
  odeme_tipi: string[];
  oda_id: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: { [key: string]: string };
  gifts: string;
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
}

const ConceptCardWithoutPrice = ({
  odeme_tipi,
  oda_id,
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  kontenjan,
  gifts,
  priceCalculationStatusMessages,
}: ConceptCardWithoutPriceProps) => {
  return (
    <div
      className="rounded p-2 border bg-white"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        minHeight: "120px",
      }}
    >
      <ConceptInfo
        priceType={odeme_tipi}
        oda_id={oda_id}
        pansiyon_id={pansiyon_id}
        pansiyon_adi={pansiyon_adi}
        fiyatlar={fiyatlar}
        kontenjan={kontenjan}
        gifts={gifts}
        noPrice={true}
      />
      <div></div>
      <PriceCalculationStatusMessages
        messages={priceCalculationStatusMessages}
      />
    </div>
  );
};

export default ConceptCardWithoutPrice;
