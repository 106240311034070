import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  addDiscountCode,
  getDiscountCodes,
} from "slices/discountsAndActions/discountCodes/thunk";

import {
  DiscountCodeRequestPayload,
  DiscountCodeState,
} from "types/DiscountsAndActions";

interface AddNewCodeButtonProps {
  values: DiscountCodeState;
  requestPayload: DiscountCodeRequestPayload;
  validateForm: (values: DiscountCodeState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddNewCodeButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddNewCodeButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleSaveNewCode = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addDiscountCode(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getDiscountCodes());
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleSaveNewCode}
        isLoading={isLoading}
      >
        Yeni İndirim Kodu Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewCodeButton;
