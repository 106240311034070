import { useSelector } from "react-redux";
import { CrmDashboardInitialState } from "slices/dashboard/crm/reducer";

const useHandleCards = () => {
  const { crmDashboardData } = useSelector(
    (state: { CrmDashboard: CrmDashboardInitialState }) => state.CrmDashboard
  );


  return {
   values: crmDashboardData
  };
};

export default useHandleCards;
