import { CustomButton } from "Components/Custom/UI";
import { AdditionalPageState } from "types/myWebSite";

interface UpdateAdditionalPageButtonProps {
  values: AdditionalPageState;
  validateForm: (values: AdditionalPageState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onUpdateAdditionalPage: () => void;
  isLoading: boolean;
}

const UpdateAdditionalPageButton = ({
  values,
  validateForm,
  handleSubmitted,
  onUpdateAdditionalPage,
  isLoading,
}: UpdateAdditionalPageButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onUpdateAdditionalPage();
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Ek Sayfayı Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateAdditionalPageButton;
