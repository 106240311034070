export const PERITO_COLORS = {
    red: "#ef4444",
    yellow: "#eab308",
    green: "#22c55e",
    teal: "#14b8a6",
    blue: "#3b82f6",
    gray: "#71717a",
    black: "#141414",
    white: "#ffffff"
  };
  