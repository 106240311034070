import React, { Dispatch } from "react";
import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { useDispatch } from "react-redux";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer";
import { Input } from "reactstrap";

interface NegativeCommentFieldProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
}

const NegativeCommentField = ({
  values,
  formErrors,
  validateFormChange,
}: NegativeCommentFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div>
      <CustomFormFieldContainer
        label="Olumsuz Yorum"
        error={formErrors.musteri_adi}
        orientation="vertical"
        divider
      >
        <Input
          style={{ minHeight: "100px" }}
          type="text"
          autoCorrect="on"
          value={values.yorum_olumsuz}
          onChange={(e: { target: { value: any } }) => {
            dispatch(
              handleChangeCommentInput({
                yorum_olumsuz: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              yorum_olumsuz: e.target.value,
            });
          }}
        />
        {/* <CustomFormEditor
          editorData={values.yorum_olumsuz}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeCommentInput({
                yorum_olumsuz: data,
              })
            );
            validateFormChange({
              ...values,
              yorum_olumsuz: data,
            });
          }}
        /> */}
      </CustomFormFieldContainer>
    </div>
  );
};
export default NegativeCommentField;
