import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";
import { getCompetitorAnalysisWithPagination } from "slices/adsAndAnalyze/competitorAnalysis/thunk";
import {
  CompetitorAnalysisFilterFormValues,
  CompetitorAnalysisRequestParams,
} from "types/adsAndAnalyze";

const useHandlePagination = (
  formValues: CompetitorAnalysisFilterFormValues
) => {
  const { pagination } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );
  const { last_page, current_page } = pagination;

  const dispatch: Dispatch<any> = useDispatch();

  const handleLoadMore = () => {
    const params: CompetitorAnalysisRequestParams = {
      page: current_page + 1,
      per_page: 10,
      ...formValues,
    };

    Object.keys(params).forEach((key) => {
      const typedKey = key as keyof CompetitorAnalysisRequestParams;
      if (!params[typedKey] || params[typedKey] === "0") {
        delete params[typedKey];
      }
    });
    last_page > current_page &&
      dispatch(getCompetitorAnalysisWithPagination(params));
  };

  const endOfPages = last_page === current_page;

  return {
    endOfPages,
    onLoadMore: handleLoadMore,
  };
};

export default useHandlePagination;
