import PriceEntryDateRangeSelector from "./PriceEntryDateRangeSelector";
import PriceEntryContainer from "./PriceEntryContainer";

import { Hotel } from "types/PricesQuotas";
import useHandleForm from "./useHandleForm";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import {
  CustomPageDescriptions,
  CustomLoadingPage,
} from "Components/Custom/UI";
import { BASIC_PRICE_ENTRY_DESCRIPTIONS } from "utils/pageDescriptions";
import { useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { resetAll } from "slices/pricesAndQuota/basicPriceEntry/reducer";

interface BasicPriceEntryPageProps {
  pageData: Hotel;
  savingPrice: boolean;
}

const BasicPriceEntryPage = ({
  pageData,
  savingPrice,
}: BasicPriceEntryPageProps) => {
  const { handleSave, isLoading, ...props } = useHandleForm();

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(resetAll());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomLoadingPage isLoading={savingPrice} />
      <CustomPageDescriptions descriptions={BASIC_PRICE_ENTRY_DESCRIPTIONS} />
      <PriceEntryDateRangeSelector {...props} />
      <PriceEntryContainer
        data={pageData}
        onSave={handleSave}
        isLoading={isLoading}
      />
    </>
  );
};

export default withPageNeedsHotel(BasicPriceEntryPage);
