import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCommentInitialState, handleChangeCommentInput } from "slices/comments/addComment/reducer";
import { HotelInitialState } from "slices/hotel/reducer";
import useValidateForm from "./useValidateForm";
import { addComment, getCommentQuestions, getComments } from "slices/comments/addComment/thunk";
import { AddCommentRequestPayload, AddCommentState } from "types/comments";


/* 
    *Handles form state, validation, and interactions with the Redux store.

    *In this module we need to manage: 
        *- giris_date-cikis_date, 
        *- customer_name, 
        *- kategori, 
        *- scores (cevap1-6),
        *- olumlu_yorum,
        *- olumsuz_yorum
*/

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {

  const dispatch: Dispatch<any> = useDispatch();

  const {
    comment,
    commentCategories,
    commentQuestions,
    commentPuanlama,
    addCommentFormErrors,
    addCommentLoading,
  } = useSelector((state: { AddComment: AddCommentInitialState }) => state.AddComment)!;


  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );


  const otel_id = hotel.otel_id;

  const [commentCategoryOptions, setCommentCategoryOptions] = useState<Option[]>([]);

  // console.log("Comment Categories from state:", commentCategories);

  useEffect(() => {
    dispatch(getCommentQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (commentCategories && commentCategories.length > 0) {
      const options = commentCategories.map((category) => ({
        value: category,
        label: category,
      }));
      setCommentCategoryOptions(options);
    }
  }, [commentCategories]);

  const handleFormChange = (field: keyof AddCommentState, value: any) => {
    dispatch(handleChangeCommentInput({ [field]: value }));
  };

  const currentDate = new Date().toISOString().split('T')[0];

  const handleAddNewComment = () => {
    const payload: AddCommentRequestPayload = {
      ...comment,
      otel_id: otel_id,
      otel_adi: hotel.otel_adi,
      tarih: currentDate,
      musteri_adi_gosterim: comment.musteri_adi_gosterim || "true",
    };

    dispatch(
      addComment(payload, () => {
        dispatch(
          getComments({
            otel_id: otel_id,
            page: 1,
            per_page: 10,
            start_date: "",
            end_date: "",
            personel: "",
          })
        );
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } = useValidateForm();

  return {
    comment,
    commentCategoryOptions,
    commentQuestions,
    commentPuanlama,
    formErrors: addCommentFormErrors,
    addCommentLoading,
    onAddNewComment: handleAddNewComment,
    validateForm,
    validateFormChange,
    handleSubmitted,
    handleFormChange,
  };
};

export default useHandleForm;
