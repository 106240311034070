import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { setIsLoading, setError, handleSaveImages } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_FEATURED_IMAGES } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetFeaturedImagesParams {
  page: number;
  per_page: number;
  search: string;
}

export const getFeaturedImages =
  (params: GetFeaturedImagesParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FEATURED_IMAGES, { params });

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveImages(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
