import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationChangeApproveCancelForm from "./ReservationChangeApproveCancelForm";


interface ReservationChangeApproveCancelPageProps {
  isLoading: boolean;
}

const ReservationChangeApproveCancelPage = ({ isLoading }: ReservationChangeApproveCancelPageProps) => {
  return (
    <>
    <CustomLoadingPage isLoading={isLoading}/>
      <div className="d-flex flex-column gap-4">
        <ReservationChangeApproveCancelForm/>
      </div>
    </>
  );
};

export default ReservationChangeApproveCancelPage;
