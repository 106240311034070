import { CustomButton } from "Components/Custom/UI";

interface ChangeApproveCancelButtonsProps {

    handleSave: () => void,
    handleClearForm: () => void
}
const ChangeApproveCancelButtons = ({
    handleSave,
    handleClearForm 
}: ChangeApproveCancelButtonsProps) => {

    return (
        <div className="d-flex justify-content-end gap-4 mt-4">
            <CustomButton
                variant="primary"
                onClick={handleSave}
            >
                Kaydet
            </CustomButton>
            <CustomButton
                variant="danger"
                onClick={handleClearForm}
            >
                Temizle
            </CustomButton>
        </div>
    )
}

export default ChangeApproveCancelButtons;