import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getFilteredPhoneStatus } from "slices/reports/phoneStatus/thunk";
import { FilterActivityStatusRequestPayload } from "types/reports";

const useHandleForm = () => {
  const [filterForm, setFilterForm] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const payload: FilterActivityStatusRequestPayload = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
    };

    dispatch(getFilteredPhoneStatus(payload));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
  };
};

export default useHandleForm;
