import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetEarlyReservationDiscount } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";
import {
  getEarlyReservationDiscounts,
  updateEarlyReservationDiscount,
} from "slices/discountsAndActions/earlyReservationDiscounts/thunk";

import {
  EarlyReservationDiscountRequestPayload,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";

interface UpdateEarlyReservationDiscountButtonProps {
  values: EarlyReservationDiscountState;
  requestPayload: EarlyReservationDiscountRequestPayload;
  validateForm: (values: EarlyReservationDiscountState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const UpdateEarlyReservationDiscountButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  hotelAndSelectedRoom,
  isLoading,
}: UpdateEarlyReservationDiscountButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateDiscount = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        updateEarlyReservationDiscount(requestPayload, () => {
          dispatch(getEarlyReservationDiscounts(hotelAndSelectedRoom));
          handleSubmitted(false);
        })
      );
    }
  };
  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="danger"
        onClick={() => {
          dispatch(resetEarlyReservationDiscount());
        }}
      >
        İptal
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleUpdateDiscount}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateEarlyReservationDiscountButton;
