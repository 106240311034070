import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import OperationOptions from "./FormFields/OperationOptions";
import ChangeApproveCancelButtons from "./FormFields/ChangeApproveCancelButtons";
import ResetReservationAmountTable from "./ResetReservationAmountTable";

const ReservationChangeApproveCancelForm = () => {
  const {
    resetReservationAmountLoading,
    showResetAmount,
    formState,
    operationOptions,
    handleFormChange,
    handleSave,
    handleClearForm,
  } = useHandleForm();

  return (
    <>
      <CustomFormContainer title="Onay İptal Kaldır">
        <ReservationCodeInput
          values={formState}
          handleFormChange={handleFormChange}
        />
        <OperationOptions
          values={formState}
          formChoices={operationOptions}
          handleFormChange={handleFormChange}
        />
        <ChangeApproveCancelButtons
          handleSave={handleSave}
          handleClearForm={handleClearForm}
        />
      </CustomFormContainer>
      {showResetAmount && 
      (<>
      <CustomFormContainer title="Tutar Sıfırlama İşlemi">
        <span>İşlem Yapılan Rezervasyon No: {formState.rez_kodu}</span>
        <ResetReservationAmountTable isLoading={resetReservationAmountLoading}/>
      </CustomFormContainer>
      </>
    )}
    </>
  );
};

export default ReservationChangeApproveCancelForm;
