import CategoryHotelRow from "./CategoryHotelRow";
import useHandleTable from "./useHandleTable";

const FacilitiesTable = () => {
  const { hotelsOfCategory } = useHandleTable();
  return (
    <>
      <>
        <CategoryHotelRow
          values={hotelsOfCategory}
          title={"Seçili Sınıftaki Oteller :"}
        />
      </>
    </>
  );
};

export default FacilitiesTable;
