// import { CustomRowOperationButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
// import useHandleRequests from "./useHandleRequests";
import { TABLE_COLORS } from "utils/tableColors";
import { ListLogsInitialState } from "slices/userOperations/listLogs/reducer";


const useHandleTable = () => {
  const { logs } = useSelector(
    (state: { ListLogs: ListLogsInitialState }) => state.ListLogs
  );

  const tableData = logs?.map((log) => {
    let rowColor = "";
    if (log.islem === "sil") {
      rowColor = TABLE_COLORS.red;
    } else if (log.islem === "duzenle") {
      rowColor = TABLE_COLORS.yellow;
    } else if (log.islem === "ekle") {
      rowColor = TABLE_COLORS.green;
    }

    return {
      id: log.id,
      tarih: log.time,
      kullanici: log.user_name,
      islem: log.islem,
      otel: log.otel_id, //burada otel_adi dönmeli ama backendde yok
      sayfa: log.sayfa,
      sorgu: log.sorgu,
      tablo: log.tablo,
      ip: log.ip,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "İd",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Tarih",
      accessorKey: "tarih",
      enableColumnFilter: false,
    },
    {
      header: "Kullanıcı",
      accessorKey: "kullanici",
      enableColumnFilter: false,
    },
    {
      header: "İşlem",
      accessorKey: "islem",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "otel",
      enableColumnFilter: false,
    },
    {
      header: "Sayfa",
      accessorKey: "sayfa",
      enableColumnFilter: false,
    },
    // {
    //   header: "Sorgu",
    //   accessorKey: "sorgu",
    //   enableColumnFilter: false,
    // },
    {
      header: "Tablo",
      accessorKey: "tablo",
      enableColumnFilter: false,
    },
    {
      header: "İp",
      accessorKey: "ip",
      enableColumnFilter: false,
    },
    // {
    //   header: "İşlemler",
    //   accessorKey: "yorum_id",
    //   enableColumnFilter: false,
    //   cell: (cell: any) => {
    //     const id = cell.getValue();
    //     return (
    //       <>
    //         <div className="d-flex justify-content-center gap-2">
    //           <CustomRowOperationButton
    //             variant="info"
    //             onClick={() => {
    //                 onToggleUpdateCommentModal(id);
    //             }}
    //             title="Log Detayı Görüntüle"
    //           />
    //         </div>
    //         {/* <UpdateCommentModal
    //           yorum_id={id}
    //           isOpen={showUpdateCommentModal[id] || false}
    //           handleToggle={() => onToggleUpdateCommentModal(id)}
    //           updateComment={(olumlu_yorum, olumsuz_yorum) =>
    //             onUpdateComment({
    //               yorum_id: id,
    //               yorum_olumlu: olumlu_yorum,
    //               yorum_olumsuz: olumsuz_yorum,
    //             })
    //           }
    //           deleteComment={() => {
    //             onDeleteComment(id);
    //           }}
    //         /> */}
    //       </>
    //     );
    //   },
    // },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "Ekleme İşlemi",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Düzenleme İşlemi",
    },
    {
      color: TABLE_COLORS.red,
      label: "Silme İşlemi",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
