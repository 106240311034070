import { FacilityState } from "types/facility";
import useHandlePayload from "./useHandlePayload";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { addFacility } from "slices/facility/addFacility/thunk";
import { handleResetFacilityState } from "slices/facility/addFacility/reducer";
import { CustomButton } from "Components/Custom/UI";

interface AddFacilityButtonProps {
  values: FacilityState;
  requestPayload: FormData;
  validateForm: (values: FacilityState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddFacilityButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddFacilityButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const getPayload = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(addFacility(getPayload()));
    }
  };

  const handleResetForm = () => {
    dispatch(handleResetFacilityState());
    window.scrollTo(0, 0);
  };

  return (
    <div
      style={{
        position: "sticky",
        bottom: 50,
        zIndex: 100,
      }}
    >
      <div className="d-flex justify-content-end gap-4 mt-4">
        <CustomButton
          classes="flex-grow-1"
          variant="danger"
          onClick={() => handleResetForm()}
        >
          Formu Temizle
        </CustomButton>
        <CustomButton
          classes="flex-grow-1"
          variant="success"
          onClick={onClick}
          isLoading={isLoading}
        >
          Tesis Ekle
        </CustomButton>
      </div>
    </div>
  );
};

export default AddFacilityButton;
