import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  setIsLoading,
  setError,
  handleSaveRoomSalesAndPaymentMethods,
  handleSaveRoomSalesAndPaymentMethodsState,
  setAddMethodLoading,
  setAddMethodError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_ROOM_SALES_AND_PAYMENT_METHODS,
  POST_ROOM_SALES_AND_PAYMENT_METHODS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { RoomSalesAndPaymentMethodsRequestPayload } from "types/PricesQuotas";
interface RequestPayload {
  otel_id: number;
  oda_id: number;
}

type SuccessCallback = () => void;

export const getRoomSalesAndPaymentMethods =
  (payload: RequestPayload) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_ROOM_SALES_AND_PAYMENT_METHODS, {
        otel_id: payload.otel_id,
        oda_id: payload.oda_id,
      });

      const res = response.data || response;

      dispatch(handleSaveRoomSalesAndPaymentMethods(res));
      dispatch(handleSaveRoomSalesAndPaymentMethodsState(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const saveRoomSalesAndPaymentMethods =
  (
    payload: RoomSalesAndPaymentMethodsRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAddMethodLoading(true));

      await axios.post(POST_ROOM_SALES_AND_PAYMENT_METHODS, payload);

      successCallback();
      dispatch(setToastSuccess("Ödeme yöntemleri kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddMethodError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddMethodLoading(false));
    }
  };
