import { useSelector } from "react-redux";
import { RoomSalesAndPaymentMethodsInitialState } from "slices/pricesAndQuota/roomSalesAndPaymentMethods/reducer";

const useHandlForm = () => {
  const {
    roomSalesAndPaymentMethodState,
    roomSalesAndPaymentMethod,
    addMethodLoading,
  } = useSelector(
    (state: {
      RoomSalesAndPaymentMethods: RoomSalesAndPaymentMethodsInitialState;
    }) => state.RoomSalesAndPaymentMethods
  );

  const disableUncancelableRate =
    !roomSalesAndPaymentMethodState.odeme.iptal_edilemez ||
    roomSalesAndPaymentMethodState.fiyat_durum.iptal;

  return {
    initialValues: roomSalesAndPaymentMethod,
    formValues: roomSalesAndPaymentMethodState,
    disableUncancelableRate,
    isLoading: addMethodLoading,
  };
};

export default useHandlForm;
