import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import { postBasicAvailabilityEntries } from "slices/pricesAndQuota/basicAvailabilityEntry/thunk";
import { IncomingAvailability } from "types/PricesQuotas";

interface RequestPayload {
  otel_id: number;
  tarihler: string;
  stoplar: IncomingAvailability[];
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectedBasicAvailabilityEntryState = (state: any) =>
    state.BasicAvailabilityEntry;
  const selectBasicAvailabilityEntryProperties = createSelector(
    selectedBasicAvailabilityEntryState,
    (state) => ({
      hotel: state.hotel,
      changedAvailabilities: state.changedAvailabilities,
      selectedDates: state.selectedDates,
      isLoading: state.isLoading,
    })
  );

  const { hotel, changedAvailabilities, selectedDates, isLoading } =
    useSelector(selectBasicAvailabilityEntryProperties);

  const handleSave = () => {
    const startDate = moment(selectedDates.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectedDates.endDate).format("YYYY-MM-DD");

    const payload: RequestPayload = {
      otel_id: hotel.otel_id,
      tarihler: `${startDate} - ${endDate}`,
      stoplar: changedAvailabilities,
    };

    dispatch(postBasicAvailabilityEntries(payload));
  };

  return {
    selectedDates,
    handleSave,
    addAvailabilitiesLoading: isLoading,
  };
};

export default useHandleForm;
