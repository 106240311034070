import { CustomLoadingPage } from "Components/Custom/UI";
import UpdateFacilityForm from "./UpdateFacilityForm";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";

interface UpdateFacilityPageProps {
  isLoading: boolean;
}

const UpdateFacilityPage = ({ isLoading }: UpdateFacilityPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <UpdateFacilityForm />
    </>
  );
};

export default withPageNeedsHotel(UpdateFacilityPage);
