interface CustomRowBadgeProps {
  value: string | number;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}
const CustomRowBadge = ({
  value,
  variant = "primary",
}: CustomRowBadgeProps) => {
  return <span className={`badge bg-${variant}`}>{value}</span>;
};

export default CustomRowBadge;
