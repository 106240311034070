import useHandleDetails from "./useHandleDetails";
import DetailsHeader from "./DetailsHeader";
import ContentContainer from "../ContentContainer";
import useHandleTabs from "./useHandleTabs";
import parse from "html-react-parser";

const FacilityDetails = () => {
  const { title, subtitle, featuredImages, crm_aciklama, crmCheckboxes } =
    useHandleDetails();
  const { tabButtons, tabContents } = useHandleTabs();

  return (
    <div className="d-flex flex-column gap-2 border rounded pb-3 overflow-hidden bg-white">
      <DetailsHeader
        title={title}
        subtitle={subtitle}
        featuredImages={featuredImages}
      />
      {crm_aciklama ? (
        <div className="p-3 bg-danger bg-opacity-25">{parse(crm_aciklama)}</div>
      ) : (
        <></>
      )}
      {crmCheckboxes.length > 0 ? (
        <ul className="d-flex flex-column gap-2 p-3 theme-bg-success">
          {crmCheckboxes.map((checkbox, index) => (
            <li
              key={index}
              className="w-100 d-flex justify-content-center align-items-center gap-2"
            >
              <i className="ri-check-double-fill fs-20"></i>
              <span className="fw-medium text-center">{checkbox}</span>
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      <ContentContainer tabButtons={tabButtons} tabContents={tabContents} />
    </div>
  );
};

export default FacilityDetails;
