import axios from "axios";
import config from "../../config";
import Cookies from "js-cookie";

const useAxios = () => {
  const { api } = config;

  // default
  axios.defaults.baseURL = api.API_URL;
  // content type
  axios.defaults.headers["Accept"] = "application/json";

  // Authorization
  const token = Cookies.get("token");
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  // intercepting to capture errors
  axios.interceptors.response.use(
    function (response) {
      return response.data ? response.data : response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      return Promise.reject(error);
    }
  );

  const get = async (url: string) => {
    return await axios.get(url);
  };

  const post = async (url: string, data: any) => {
    return await axios.post(url, data);
  };

  const put = async (url: string, data: any) => {
    return await axios.put(url, data);
  };

  const del = async (url: string) => {
    return await axios.delete(url);
  };

  return { get, post, put, del };
};

export default useAxios;
