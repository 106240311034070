import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import ShowInstallmentAmounts from "../../CommonComponents/ShowInstallmentAmounts";
import {
  BankInstallment,
  CashInfo,
  CreateRoomAddedRoom,
  CreditCardInfo,
  EnterReservationFormValues,
  ReservationPaymentInfo,
  TransferInfo,
} from "types/reservation";
import { useEffect, useState } from "react";
import CreditCardInfoForm from "./CreditCardInfoForm";
import TransferInfoForm from "./TransferInfoForm";
import CashPaymentInfoForm from "./CashPaymentInfoForm";

import { v4 as uuidv4 } from "uuid";

interface PaymentFormProps {
  reservationPaymentInfo: ReservationPaymentInfo;
  setReservationPaymentInfo: (value: ReservationPaymentInfo) => void;
  formValues: EnterReservationFormValues;
  calculatedPrice: number | undefined;
  installments: BankInstallment[];
  banks: BankOption[];
  addedRoomsInfo: CreateRoomAddedRoom[];
}

interface BankOption {
  id: number;
  value: string;
  label: string;
}

type PaymentMethod = "cash" | "creditCard" | "transfer";

const PaymentForm = ({
  reservationPaymentInfo,
  setReservationPaymentInfo,
  formValues,
  calculatedPrice,
  installments,
  banks,
  addedRoomsInfo,
}: PaymentFormProps) => {
  const totalRoom = addedRoomsInfo.length;
  const totalAmount = Math.ceil(
    addedRoomsInfo.reduce((acc, room) => acc + room.price, 0)
  );

  const [remainingAmount, setRemainingAmount] = useState<number>(totalAmount);

  const kaporaliPayment = formValues.odeme_tipi === "kaporali";

  const kaporaliMaliyetAmount = Math.ceil(
    addedRoomsInfo.reduce((acc, room) => acc + room.fiyatlar.maliyet_tutar, 0)
  );

  const kaporaliPaymentAmount = kaporaliPayment
    ? totalAmount - kaporaliMaliyetAmount
    : 0;

  const [kaporaliRemainingAmount, setKaporaliRemainingAmount] =
    useState<number>(kaporaliPaymentAmount);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(undefined);

  const handleRemoveSelectedPaymentMethod = () => {
    setSelectedPaymentMethod(undefined);
  };

  useEffect(() => {
    const addedPayment =
      reservationPaymentInfo.creditCard.reduce(
        (acc, payment) => acc + payment.amount,
        0
      ) +
      reservationPaymentInfo.transfer.reduce(
        (acc, payment) => acc + payment.amount,
        0
      ) +
      reservationPaymentInfo.cash.reduce(
        (acc, payment) => acc + Math.abs(payment.amount),
        0
      );

    setRemainingAmount(totalAmount - addedPayment);
  }, [reservationPaymentInfo, totalAmount]);

  useEffect(() => {
    const addedKaporaliPayment =
      reservationPaymentInfo.creditCard.reduce(
        (acc, payment) => acc + payment.amount,
        0
      ) +
      reservationPaymentInfo.transfer.reduce(
        (acc, payment) => acc + payment.amount,
        0
      ) +
      reservationPaymentInfo.cash.reduce(
        (acc, payment) => acc + Math.abs(payment.amount),
        0
      );

    setKaporaliRemainingAmount(kaporaliPaymentAmount - addedKaporaliPayment);
  }, [reservationPaymentInfo, kaporaliPaymentAmount]);

  const addedCreditCard = reservationPaymentInfo.creditCard.length;
  const addedTransfer = reservationPaymentInfo.transfer.length;
  const addedCash = reservationPaymentInfo.cash.length;

  const handleAddPaymentMethod = (
    formValues: TransferInfo | CreditCardInfo | CashInfo,
    paymentMethod: PaymentMethod,
    amount: number,
    onResetForm: () => void
  ) => {
    const newPayment = {
      ...formValues,
      paymentId: uuidv4(),
    };

    setReservationPaymentInfo({
      ...reservationPaymentInfo,
      [paymentMethod]: [...reservationPaymentInfo[paymentMethod], newPayment],
    });

    setRemainingAmount((prev) => prev - amount);

    setSelectedPaymentMethod(undefined);

    onResetForm();
  };

  const handleRemovePaymentMethod = (
    paymentMethod: PaymentMethod,
    paymentId: string
  ) => {
    setReservationPaymentInfo({
      ...reservationPaymentInfo,
      [paymentMethod]: reservationPaymentInfo[paymentMethod].filter(
        (payment) => payment.paymentId !== paymentId
      ),
    });
  };

  const [editingCashPayment, setEditingCashPayment] = useState("");
  const [editingTransferPayment, setEditingTransferPayment] = useState("");
  const [editingCreditCardPayment, setEditingCreditCardPayment] = useState("");

  const handleEditPaymentMethod = (
    formValues: TransferInfo | CreditCardInfo | CashInfo,
    paymentMethod: PaymentMethod,
    amount: number,
    paymentId: string
  ) => {
    const otherPayments = reservationPaymentInfo[paymentMethod].filter(
      (payment) => payment.paymentId !== paymentId
    );
    setReservationPaymentInfo({
      ...reservationPaymentInfo,
      [paymentMethod]: [...otherPayments, formValues],
    });

    setRemainingAmount((prev) => prev - amount);

    paymentMethod === "cash" && setEditingCashPayment("");
    paymentMethod === "transfer" && setEditingTransferPayment("");
    paymentMethod === "creditCard" && setEditingCreditCardPayment("");
  };

  const kismiPayment = formValues.odeme_tipi === "kismi";
  const kismiPaymentAmount = kismiPayment
    ? Math.ceil(totalAmount * (formValues.kismi_odeme_orani / 100))
    : 0;

  return (
    <CustomFormContainer title="Taksit Seçenekleri">
      <ShowInstallmentAmounts
        basePrice={
          kismiPayment
            ? kismiPaymentAmount
            : kaporaliPayment
            ? kaporaliPaymentAmount
            : totalAmount
        }
        installments={installments}
      />
      {!!totalAmount && (
        <div className="d-flex flex-column gap-2 bg-white p-2 border rounded">
          <span className="fw-medium fs-14">
            TOPLAM {totalRoom} ODA İÇİN REZERVASYON YAPILACAK
          </span>

          <div className="d-flex justify-content-between align-items-center py-3 border-top border-bottom">
            {kaporaliPayment ? (
              <span className="fw-medium fs-14 py-2 px-3 rounded theme-bg-orange">
                KAPORA TUTARI: {kaporaliPaymentAmount} TL
              </span>
            ) : (
              <span className="fw-medium fs-14 py-2 px-3 rounded theme-bg-info">
                REZERVASYON TUTARI: {totalAmount} TL
              </span>
            )}
            {kismiPayment && (
              <span className="fw-medium fs-14 py-2 px-3 rounded theme-bg-orange">
                KISMİ TUTAR: {kismiPaymentAmount} TL
              </span>
            )}
            {kaporaliPayment ? (
              <span
                className="fw-medium fs-14 py-2 px-3 rounded"
                style={{
                  backgroundColor:
                    kaporaliRemainingAmount === 0
                      ? "var(--theme-success)"
                      : "var(--theme-warning)",
                }}
              >
                KALAN TUTAR: {kaporaliRemainingAmount} TL
              </span>
            ) : (
              <span
                className="fw-medium fs-14 py-2 px-3 rounded"
                style={{
                  backgroundColor:
                    remainingAmount === 0
                      ? "var(--theme-success)"
                      : "var(--theme-warning)",
                }}
              >
                KALAN TUTAR: {remainingAmount} TL
              </span>
            )}

            <div className="d-flex gap-2 justify-content-center align-items-center">
              <span className="fw-medium">Ödeme Yöntemi:</span>
              <CustomButton
                variant="primary"
                onClick={() => {
                  if (remainingAmount) {
                    selectedPaymentMethod === "creditCard"
                      ? setSelectedPaymentMethod(undefined)
                      : setSelectedPaymentMethod("creditCard");
                  }
                }}
                disabled={remainingAmount === 0}
              >
                Kredi Kartı
              </CustomButton>
              <CustomButton
                variant="success"
                onClick={() => {
                  if (remainingAmount) {
                    selectedPaymentMethod === "transfer"
                      ? setSelectedPaymentMethod(undefined)
                      : setSelectedPaymentMethod("transfer");
                  }
                }}
                disabled={remainingAmount === 0}
              >
                Havale
              </CustomButton>
              <CustomButton
                variant="warning"
                onClick={() => {
                  if (remainingAmount) {
                    selectedPaymentMethod === "cash"
                      ? setSelectedPaymentMethod(undefined)
                      : setSelectedPaymentMethod("cash");
                  }
                }}
                disabled={remainingAmount === 0}
              >
                Nakit/İndirim
              </CustomButton>
            </div>
          </div>
          {formValues.odeme_tipi === "kismi" && (
            <span className="fw-medium fs-13 theme-text-danger">
              KISMİ ÖDEMELİ REZERVASYONLARDA: Rezervasyon tutarının tamamı kadar
              ödeme girilmelidir. Kalan Tutar sıfırlanacak şekilde ödeme
              giriniz. Girilen Ödemelerden, "Kısmi ödeme tutarı" tahsil
              edilecektir.
            </span>
          )}
        </div>
      )}

      <>
        {selectedPaymentMethod === "creditCard" && (
          <CreditCardInfoForm
            banks={banks}
            installments={installments}
            remainingAmount={remainingAmount}
            onAddPaymentMethod={handleAddPaymentMethod}
            onRemoveSelectedPaymentMethod={handleRemoveSelectedPaymentMethod}
          />
        )}
        {selectedPaymentMethod === "transfer" && (
          <TransferInfoForm
            remainingAmount={remainingAmount}
            onAddPaymentMethod={handleAddPaymentMethod}
            onRemoveSelectedPaymentMethod={handleRemoveSelectedPaymentMethod}
          />
        )}
        {selectedPaymentMethod === "cash" && (
          <CashPaymentInfoForm
            remainingAmount={remainingAmount}
            onAddPaymentMethod={handleAddPaymentMethod}
            onRemoveSelectedPaymentMethod={handleRemoveSelectedPaymentMethod}
          />
        )}
      </>
      <div className="d-flex flex-column gap-2">
        {addedCreditCard ? (
          reservationPaymentInfo.creditCard.map((creditCard, index) => (
            <div key={index} className="d-flex flex-column gap-2">
              <div
                className="bg-white p-2 border rounded"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <span className="fw-medium fs-14">Kredi Kartı Ödemesi</span>
                <span className="fw-medium fs-14">{creditCard.amount} TL</span>
                <div className="d-flex gap-2">
                  <CustomButton
                    variant="info"
                    onClick={() =>
                      editingCreditCardPayment === creditCard.paymentId
                        ? setEditingCreditCardPayment("")
                        : setEditingCreditCardPayment(creditCard.paymentId)
                    }
                  >
                    Ödeme Bilgileri
                  </CustomButton>
                  <CustomButton
                    variant="danger"
                    onClick={() =>
                      handleRemovePaymentMethod(
                        "creditCard",
                        creditCard.paymentId
                      )
                    }
                  >
                    Ödeme İptal
                  </CustomButton>
                </div>
              </div>
              {editingCreditCardPayment === creditCard.paymentId && (
                <CreditCardInfoForm
                  paymentId={creditCard.paymentId}
                  creditCardInfo={creditCard}
                  banks={banks}
                  installments={installments}
                  remainingAmount={remainingAmount}
                  onAddPaymentMethod={handleAddPaymentMethod}
                  onEditPaymentMethod={handleEditPaymentMethod}
                  onRemoveSelectedPaymentMethod={
                    handleRemoveSelectedPaymentMethod
                  }
                />
              )}
            </div>
          ))
        ) : (
          <></>
        )}
        {addedTransfer ? (
          reservationPaymentInfo.transfer.map((transfer, index) => (
            <div key={index} className="d-flex flex-column gap-2">
              <div
                className="bg-white p-2 border rounded"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <span className="fw-medium fs-14">Havale Ödemesi</span>
                <span className="fw-medium fs-14">{transfer.amount} TL</span>
                <div className="d-flex gap-2">
                  <CustomButton
                    variant="info"
                    onClick={() =>
                      editingTransferPayment === transfer.paymentId
                        ? setEditingTransferPayment("")
                        : setEditingTransferPayment(transfer.paymentId)
                    }
                  >
                    Ödeme Bilgileri
                  </CustomButton>
                  <CustomButton
                    variant="danger"
                    onClick={() =>
                      handleRemovePaymentMethod("transfer", transfer.paymentId)
                    }
                  >
                    Ödeme İptal
                  </CustomButton>
                </div>
              </div>
              {editingTransferPayment === transfer.paymentId && (
                <TransferInfoForm
                  paymentId={transfer.paymentId}
                  transferInfo={transfer}
                  remainingAmount={remainingAmount}
                  onAddPaymentMethod={handleAddPaymentMethod}
                  onEditPaymentMethod={handleEditPaymentMethod}
                  onRemoveSelectedPaymentMethod={
                    handleRemoveSelectedPaymentMethod
                  }
                />
              )}
            </div>
          ))
        ) : (
          <></>
        )}
        {addedCash ? (
          reservationPaymentInfo.cash.map((cash, index) => (
            <div
              key={index}
              className="bg-white p-2 border rounded d-flex flex-column gap-2"
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <span className="fw-medium fs-14">Nakit/İndirim Ödemesi</span>
                <span className="fw-medium fs-14">{cash.amount} TL</span>
                <div className="d-flex gap-2">
                  <CustomButton
                    variant="info"
                    onClick={() =>
                      editingCashPayment === cash.paymentId
                        ? setEditingCashPayment("")
                        : setEditingCashPayment(cash.paymentId)
                    }
                  >
                    Ödeme Bilgileri
                  </CustomButton>
                  <CustomButton
                    variant="danger"
                    onClick={() =>
                      handleRemovePaymentMethod("cash", cash.paymentId)
                    }
                  >
                    Ödeme İptal
                  </CustomButton>
                </div>
              </div>
              {editingCashPayment === cash.paymentId && (
                <CashPaymentInfoForm
                  paymentId={cash.paymentId}
                  cashInfo={cash}
                  remainingAmount={remainingAmount}
                  onAddPaymentMethod={handleAddPaymentMethod}
                  onEditPaymentMethod={handleEditPaymentMethod}
                  onRemoveSelectedPaymentMethod={
                    handleRemoveSelectedPaymentMethod
                  }
                />
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </CustomFormContainer>
  );
};

export default PaymentForm;
