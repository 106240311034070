import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveRiskyHotels, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_RISKY_HOTELS } from "helpers/url_helper";

import { Dispatch } from "redux";

export const getRiskyHotels = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_RISKY_HOTELS);

    dispatch(handleSaveRiskyHotels(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
