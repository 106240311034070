import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveFormChoicesResponse,
  handleSaveReservationAmountsResponse,
  setIsLoading,
  setError,
  setChangeApproveCancelLoading,
  setChangeApproveCancelError,
  setResetReservationAmountLoading,
  setResetReservationAmountError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_FORM_CHOICES,
  CHANGE_APPROVE_CANCEL,
  GET_RESERVATION_AMOUNT,
  RESET_RESERVATION_AMOUNT,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { ReservationChangeApproveCancelPayload, ResetReservationAmountPayload } from "types/userOperations";

export const getFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_FORM_CHOICES);
    const res = (response as any).message ? response.data : response;

    dispatch(handleSaveFormChoicesResponse(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const changeReservationApproveCancel =
  (payload: ReservationChangeApproveCancelPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeApproveCancelLoading(true));
      await axios.post(CHANGE_APPROVE_CANCEL, payload);

      dispatch(setToastSuccess("İşlem başarıyla gerçekleştirildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeApproveCancelError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeApproveCancelLoading(false));
    }
  };

export const getReservationAmount =
  (rez_kodu: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_RESERVATION_AMOUNT}?rez_kodu=${rez_kodu}`
      );
      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveReservationAmountsResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const resetReservationAmount =
  (payload: ResetReservationAmountPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setResetReservationAmountLoading(true));
      await axios.post(RESET_RESERVATION_AMOUNT, payload);
      
      dispatch(setToastSuccess("İşlem başarıyla gerçekleştirildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setResetReservationAmountError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setResetReservationAmountLoading(false));
    }
  };