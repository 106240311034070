import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddUpdateFacilityRoomFormErrors } from "slices/facility/facilityRooms/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  UpdateFacilityRoomState,
  UpdateFacilityRoomFormErrors,
} from "types/facility";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: UpdateFacilityRoomState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: UpdateFacilityRoomFormErrors = {};

    if (!values.oda_isim) {
      errors.oda_isim = "Oda adı boş bırakılamaz.";
    }

    if (!values.aciklama) {
      errors.aciklama = "Açıklama boş bırakılamaz.";
    }

    if (!values.metrekare) {
      errors.metrekare = "Metrekare boş bırakılamaz.";
    }

    dispatch(handleAddUpdateFacilityRoomFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: UpdateFacilityRoomState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
