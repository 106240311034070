export enum OFFICE_LOCATIONS {
  Ankara = "Ankara",
  İzmir = "İzmir",
  Alsancak = "Alsancak",
}

export enum WEEKDAYS {
  Pazartesi = 1,
  Salı = 2,
  Çarşamba = 3,
  Perşembe = 4,
  Cuma = 5,
  Cumartesi = 6,
  Pazar = 7,
}

export enum STOPS {
  YOK = -1,
  SATISA_ACIK = 0,
  SOR_SAT = 1,
  STOPLU = 2,
  KONTENJAN_YETERSIZ = 3,
}

export enum PAYMENT_METHODS {
  normal = "normal",
  kapida = "kapida",
  iptal_edilemez = "iptal_edilemez",
  kaporali = "kaporali",
}

export enum WORK_TYPE_OPTIONS {
  NET_FIYAT = "net",
  KOMİSYONLU_FIYAT = "komisyonlu",
  TAM_UCRET_ODEMELI_KOMISYON_FATURALI = "komisyon_faturali",
}

export enum PRICE_ENTRY_OPTIONS {
  ODA_FIYATI = "odafiyati",
  KISI_BASI_FIYAT = "kisibasifiyat",
}

export enum CURRENCY_WORK_WITH {
  TL = "TRY",
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
}

export enum CANCELLATIOIN_GUARANTEE_OPTIONS {
  UC_GUN = 3,
  DORT_GUN = 4,
  BES_GUN = 5,
  ALTI_GUN = 6,
  YEDI_GUN = 7,
  SEKIZ_GUN = 8,
  DOKUZ_GUN = 9,
  ON_GUN = 10,
  ON_BIR_GUN = 11,
  ON_IKI_GUN = 12,
  ON_UC_GUN = 13,
  ON_DORT_GUN = 14,
  ON_BES_GUN = 15,
  ON_ALTI_GUN = 16,
  ON_YEDI_GUN = 17,
  ON_SEKIZ_GUN = 18,
  ON_DOKUZ_GUN = 19,
  YIRMI_GUN = 20,
  YIRMI_BIR_GUN = 21,
  YIRMI_IKI_GUN = 22,
  YIRMI_UC_GUN = 23,
  YIRMI_DORT_GUN = 24,
  YIRMI_BES_GUN = 25,
  YIRMI_ALTI_GUN = 26,
  YIRMI_YEDI_GUN = 27,
  YIRMI_SEKIZ_GUN = 28,
  YIRMI_DOKUZ_GUN = 29,
  OTUZ_GUN = 30,
}

export enum HOTEL_CLASS {
  SEHIR_OTELI = "Şehir Oteli",
  CRM_YAZ_OTELI = "CRM Yaz Oteli",
  TERMAL_OTEL = "Termal Otelİ",
  KAYAK_OTELI = "Kayak Oteli",
  OPERATOR_OTELI = "Operatör Oteli",
  ISLAMI_OTEL = "İslami Otel",
}

export enum HOTEL_PAYMENT_DAY {
  GIRISTE = "giriste",
  CIKISTA = "cikista",
  REZ_GUNU = "rez_gunu",
}

export enum PARTIAL_PAYMENT {
  UC_GUN = "3 Gün",
  BES_GUN = "5 Gün",
  YEDI_GUN = "7 Gün",
  ON_GUN = "10 Gün",
  ON_BES_GUN = "15 Gün",
}

export enum PAYMENT_PERIOD_FIRST {
  OCAK = "Ocak",
  SUBAT = "Şubat",
  MART = "Mart",
  NISAN = "Nisan",
  MAYIS = "Mayıs",
  HAZIRAN = "Haziran",
  TEMMUZ = "Temmuz",
  AGUSTOS = "Ağustos",
  EYLUL = "Eylül",
  EKIM = "Ekim",
  KASIM = "Kasım",
  ARALIK = "Aralık",
}
export enum PAYMENT_PERIOD_SECOND {
  OCAK = "Ocak",
  SUBAT = "Şubat",
  MART = "Mart",
  NISAN = "Nisan",
  MAYIS = "Mayıs",
  HAZIRAN = "Haziran",
  TEMMUZ = "Temmuz",
  AGUSTOS = "Ağustos",
  EYLUL = "Eylül",
  EKIM = "Ekim",
  KASIM = "Kasım",
  ARALIK = "Aralık",
}

export enum BREAKING_DISCOUNTS {
  BUGUN_YARIN = "bugun_yarin",
  YARIN = "yarin",
  BUGUN = "bugun",
}

//Facility

export enum MAN_ALLOWANCE {
  "Bay Konaklama Kabul Ediyor" = "Bay Konaklama Kabul Ediyor",
  "Bay Konaklama Kabul Etmiyor" = "Bay Konaklama Kabul Etmiyor",
  "Bay Konaklama Gösterme" = "Bay Konaklama Gösterme",
}

export enum FOREIGN_MARKET {
  "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılsın" = "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılsın",
  "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılmasın" = "Dış Pazar Konaklamalarına İç Pazar Fiyatından Satılmasın",
  "Dış Pazar Konaklamalarına İç Pazar Gösterme" = "Dış Pazar Konaklamalarına İç Pazar Gösterme",
}

export enum OTHER_OPERATOR {
  "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber satabilirsiniz" = "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber satabilirsiniz",
  "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber sorsat yapın" = "Başka Operatörde uygun görürseniz Ekran görüntüsüyle beraber sorsat yapın",
  "Başka Operatörde uygun görürseniz Gösterme" = "Başka Operatörde uygun görürseniz Gösterme",
}

export enum FACILITY_MANAGER_ROLE {
  ana_yetkili = "ana_yetkili",
  rezervasyon = "rezervasyon",
  muhasebe = "muhasebe",
  bilgi_islem = "bilgi_islem",
  otel_genel_iletisim = "otel_genel_iletisim",
}

export enum CONTRACT_TYPE {
  "Tekrarli" = "Tekrarli",
  "Sezon Sonu" = "Sezon Sonu",
  "Tek Seferlik" = "Tek Seferlik",
  "Aylik" = "Aylik",
  "Oda Basi" = "Oda Basi",
}

export enum NIGHT_COUNT {
  BIR_GECE = 1,
  IKI_GECE = 2,
  UC_GECE = 3,
  DORT_GECE = 4,
  BES_GECE = 5,
  ALTI_GECE = 6,
  YEDI_GECE = 7,
  SEKIZ_GECE = 8,
  DOKUZ_GECE = 9,
  ON_GECE = 10,
}

