import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/themes/listThemes/reducer";
import { UpdateThemeFormErrors, UpdateThemeState } from "types/themes";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface UpdateThemeImageSelectorProps {
  values: UpdateThemeState;
  formErrors: UpdateThemeFormErrors;
  validateFormChange: (values: UpdateThemeState) => void;
}

const UpdateThemeImageSelector = ({
  values,
  validateFormChange,
  formErrors,
}: UpdateThemeImageSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.file ? [values.file] : [];

  const imageUrl = values.resim
    ? `${process.env.REACT_APP_BASE_IMAGE_URL}${values.resim}`
    : null;

  return (
    <>
      <CustomFormFieldContainer label="Resim Yükle">
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeInput({ file: fileItems[0] }));
          }}
          maxFiles={1}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Mevcut Resim">
        {imageUrl ? (
          <div className="border rounded bg-white" style={{ height: "200px" }}>
            <img
              src={imageUrl}
              alt="Resim"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        ) : (
          <p>Resim Bulunamadı</p>
        )}
      </CustomFormFieldContainer>
    </>
  );
};

export default UpdateThemeImageSelector;
