import { useSelector } from "react-redux";
import { PersonnelHotelAssignmentsInitialState } from "slices/userOperations/personnelHotelAssignments/reducer";
import { HotelsInCity } from "types/userOperations";


const useHandleTable = ( ) => {
  const { assignedHotels } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const hotelsOfPersonnelInTheCity: { [key: string]: string[] } = {};

  if (
    assignedHotels.sehirdeki_oteller.length > 0 &&
    assignedHotels.atanmis_oteller.length > 0
  ) {
    // İki seçenek de boş döndüyse
    const hotelsInTheCityMap: { [otel_id: number]: HotelsInCity } = {};
    assignedHotels.sehirdeki_oteller.forEach((hotel) => {
      hotelsInTheCityMap[hotel.otel_id] = hotel;
    });

    assignedHotels.atanmis_oteller.forEach((hotel) => {
      if (hotelsInTheCityMap[hotel.otel_id]) {
        const personnelName =
          hotelsInTheCityMap[hotel.otel_id].otel_sorumlusu_personel;
        const hotelName = hotel.otel_adi;

        if (hotelsOfPersonnelInTheCity[personnelName]) {
          hotelsOfPersonnelInTheCity[personnelName].push(hotelName);
        } else {
          hotelsOfPersonnelInTheCity[personnelName] = [hotelName];
        }
      }
    });
  } else if (assignedHotels.sehirdeki_oteller.length > 0) {
    // sehirdeki_oteller dolu, atanmis_oteller boş
    assignedHotels.sehirdeki_oteller.forEach((hotel) => {
      const personnelName = hotel.otel_sorumlusu_personel;
      const hotelName = hotel.otel_adi;

      if (hotelsOfPersonnelInTheCity[personnelName]) {
        hotelsOfPersonnelInTheCity[personnelName].push(hotelName);
      } else {
        hotelsOfPersonnelInTheCity[personnelName] = [hotelName];
      }
    });
  } else if (assignedHotels.atanmis_oteller.length > 0) {
    // atanmis_oteller dolu, sehirdeki_oteller boş
    assignedHotels.atanmis_oteller.forEach((hotel) => {
      const personnelName = String(hotel.otel_sorumlusu_personel); 
      const hotelName = hotel.otel_adi;

      if (hotelsOfPersonnelInTheCity[personnelName]) {
        hotelsOfPersonnelInTheCity[personnelName].push(hotelName);
      } else {
        hotelsOfPersonnelInTheCity[personnelName] = [hotelName];
      }
    });
  } else {

  }

  return {
    hotelsOfPersonnelInTheCity,
  };
};

export default useHandleTable;
