import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";

interface FacilityOperationalSelectFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  showPriceListOptions: Option[];
  showHotelLogoOptions: Option[];
  showHotelAgentOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityOperationalSelectFields = ({
  values,
  formErrors,
  validateFormChange,
  showPriceListOptions,
  showHotelLogoOptions,
  showHotelAgentOptions,
}: FacilityOperationalSelectFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Fiyat Listesi Göster"
        // error={formErrors.fiyat_listesi_goster}
        divider
      >
        <Select
          options={showPriceListOptions}
          value={showPriceListOptions.find(
            (option) => option.value === values.fiyat_listesi_goster
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                fiyat_listesi_goster: selected.value === "e" ? "e" : "h",
              })
            );
            validateFormChange({
              ...values,
              fiyat_listesi_goster: selected.value === "e" ? "e" : "h",
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Otelfiyat Logosu"
        // error={formErrors.site_logo_acilis}
        divider
      >
        <Select
          options={showHotelLogoOptions}
          value={showHotelLogoOptions.find(
            (option) => option.value === values.site_logo_acilis
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                site_logo_acilis: selected.value === "e" ? "e" : "h",
              })
            );
            validateFormChange({
              ...values,
              site_logo_acilis: selected.value === "e" ? "e" : "h",
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Otelfiyat Acente Bilgisi"
        // error={formErrors.acente_bilgisini_goster}
        divider
      >
        <Select
          options={showHotelAgentOptions}
          value={showHotelAgentOptions.find(
            (option) => option.value === values.acente_bilgisini_goster
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                acente_bilgisini_goster: selected.value === "e" ? "e" : "h",
              })
            );
            validateFormChange({
              ...values,
              acente_bilgisini_goster: selected.value === "e" ? "e" : "h",
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityOperationalSelectFields;
