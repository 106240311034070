import { useSelector } from "react-redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";

const useHandleTable = () => {
  const { unmatchedAdsGroups } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  const tableData = unmatchedAdsGroups?.map((unmatchedAdsGroup) => ({
    "Reklam Grup Adı": unmatchedAdsGroup.reklam_grup_adi,
    "Kampanya Adı": unmatchedAdsGroup.kampanya_adi,
    "Reklam Grup Durum": unmatchedAdsGroup.reklam_grup_durum,
    "Kampanya Durum": unmatchedAdsGroup.kampanya_durum,
  }));

  const tableColumns = [
    {
      header: "Reklam Grup Adı",
      accessorKey: "Reklam Grup Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kampanya Adı",
      accessorKey: "Kampanya Adı",
      enableColumnFilter: false,
    },
    {
      header: "Reklam Grup Durum",
      accessorKey: "Reklam Grup Durum",
      enableColumnFilter: false,
    },
    {
      header: "Kampanya Durum",
      accessorKey: "Kampanya Durum",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
