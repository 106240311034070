import { CustomFormContainer, CustomLoadingPage } from "Components/Custom/UI";
import CityAndCategoryFilterForm from "./CityAndCategoryFilterForm";
import FacilitiesTable from "./FacilitiesTable";
import { useState } from "react";


interface ListAssignedHotelsProps {
  isLoading: boolean;
}

const ListAssignedHotels = ({ isLoading }: ListAssignedHotelsProps) => {

  const [showTable, setShowTable] = useState(false);

  return (
    <>
      <CustomFormContainer title="Tesisleri Listele">
        <CustomLoadingPage isLoading={isLoading} />
        <CityAndCategoryFilterForm setShowTable={setShowTable}/>
        {showTable && <FacilitiesTable/>}
      </CustomFormContainer>
    </>
  );
};

export default ListAssignedHotels;