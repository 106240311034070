import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface FacilityIdFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityIdFields = ({
  values,
  validateFormChange,
  formErrors,
}: FacilityIdFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otelfiyat ID"
        error={formErrors.otel_fiyat_id}
        divider
      >
        <Input
          type="number"
          value={values.otel_fiyat_id}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_fiyat_id: parseInt(e.target.value),
              })
            );
            validateFormChange({
              ...values,
              otel_fiyat_id: parseInt(e.target.value),
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Acente2 ID"
        error={formErrors.acante2_id}
        divider
      >
        <Input
          type="number"
          value={values.acante2_id}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                acante2_id: parseInt(e.target.value),
              })
            );
            validateFormChange({
              ...values,
              acante2_id: parseInt(e.target.value),
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityIdFields;
