import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/myWebSite/addFooterLinks/reducer";
import { FooterLink, FooterLinkFormErrors } from "types/myWebSite";

interface FooterLinkTitleProps {
  values: FooterLink;
  formErrors: FooterLinkFormErrors;
  validateFormChange: (values: FooterLink) => void;
}

const FooterLinkTitle = ({
  values,
  formErrors,
  validateFormChange,
}: FooterLinkTitleProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Footer Link Başlık : "
      error={formErrors.title}
      orientation="horizontal"
      required
      divider
    >
      <Input
        type="text"
        autoCorrect="on"
        value={values.title}
        onChange={(e) => {
          dispatch(
            handleChangeInput({
              title: e.target.value,
            })
          );
            validateFormChange({
              ...values,
              title: e.target.value,
            });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default FooterLinkTitle;
