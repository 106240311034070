import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
import { ChangeFacilityCategoryPayload } from "types/facilityCategorization";

interface Option {
  value: string;
  label: string;
}
interface CategorySelectBoxesProps {
  values: ChangeFacilityCategoryPayload;
  options: Option[];
  handleFormChange: (key: string, value: any) => void;
}

const CategorySelectBoxes = ({
  values,
  options,
  handleFormChange,
}: CategorySelectBoxesProps) => {
  return (
    <>
      <CustomFormFieldContainer
        label="Sınıf : "
        divider
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {options.map((option) => {
            return (
              <div key={option.value} className="d-flex gap-2">
                <Input
                  type="radio"
                  id={option.value}
                  name="categoryOptions"
                  onChange={() => {
                    handleFormChange("sinif", option.value);
                  }}
                />
                <Label
                  for={option.value}
                  className="fw-normal fs-14"
                >
                  {option.label}
                </Label>
              </div>
            );
          })}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default CategorySelectBoxes;
