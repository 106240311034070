import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { handleSaveDashboard, setIsLoading, setError } from "./reducer";
import { setToastError } from "slices/toast/reducer";
import {
  GET_DASHBOARD_DATA,
} from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getDashboardData = (otelId: number, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_DASHBOARD_DATA}/${otelId}`);

    const res = (response as any).message ? response.data : response;
    // console.log("res : ", response)
    successCallback();
    dispatch(handleSaveDashboard(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

