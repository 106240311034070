import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddDiscountCodeFormErrors } from "slices/discountsAndActions/discountCodes/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  DiscountCodeFormErrors,
  DiscountCodeState,
} from "types/DiscountsAndActions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: DiscountCodeState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: DiscountCodeFormErrors = {};

    if (!values.indirim_adi) {
      errors.indirim_adi = "İndirim adı boş bırakılamaz.";
    }

    if (values.indirim_tipi === "oran" && !values.indirim_orani) {
      errors.indirim_orani_veya_tutarı = "İndirim oranı boş bırakılamaz.";
    }
    if (values.indirim_tipi === "tutar" && !values.indirim_tutari) {
      errors.indirim_orani_veya_tutarı = "İndirim tutarı boş bırakılamaz.";
    }
    if (!values.indirim_kuponu_kodu) {
      errors.indirim_kuponu_kodu = "İndirim kuponu kodu boş bırakılamaz.";
    }

    dispatch(handleAddDiscountCodeFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: DiscountCodeState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
