import { Col, Label, Row } from "reactstrap";
import InputField from "../InputField";

interface QuotaFieldProps {
  values: any;
  onChange: (name: string, value: any) => void;
}

const QuotaField = ({ values, onChange }: QuotaFieldProps) => {
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <Label className="m-0">Kontenjan</Label>
      </Col>
      <Col xs={6}>
        <InputField
          identifier="ODA"
          value={values["kontenjan"]}
          isEditable={true}
          type="kontenjan"
          isBasePrice={false}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

export default QuotaField;
