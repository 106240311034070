import { useSelector } from "react-redux";

import { DailyCallsForHotelsInitialState } from "slices/reports/dailyCallsForHotels/reducer";

const useHandleTable = () => {
  const { dailyCallsForHotels } = useSelector(
    (state: { DailyCallsForHotels: DailyCallsForHotelsInitialState }) =>
      state.DailyCallsForHotels
  );

  const tableData = dailyCallsForHotels?.map((dailyCall) => ({
    "Otel Adı": dailyCall.otel_adi,
    "Otel İli": dailyCall.otel_ili,
    "Fiyat Hesaplama": dailyCall.fiyat_hesaplama,
    "Karşılama Kodu": dailyCall.karsilama_kodu,
    Telefon: dailyCall.aranan_no,
    "Aranma Sayısı": dailyCall.sayi,
    "Rezervasyon Sayısı": dailyCall.sayi,
    "Fiyat Durumu": dailyCall.fiyat_var_mi,
    "Kapıda Ödeme": dailyCall.kapida_odeme,
    "Kaporalı Satış": dailyCall.kaporali_satis,
    "Kısmi Ödeme": dailyCall.kismi_odeme,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Otel İli",
      accessorKey: "Otel İli",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Hesaplama",
      accessorKey: "Fiyat Hesaplama",
      enableColumnFilter: false,
    },
    {
      header: "Karşılama Kodu",
      accessorKey: "Karşılama Kodu",
      enableColumnFilter: false,
    },
    {
      header: "Telefon",
      accessorKey: "Telefon",
      enableColumnFilter: false,
    },
    {
      header: "Aranma Sayısı",
      accessorKey: "Aranma Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Rezervasyon Sayısı",
      accessorKey: "Rezervasyon Sayısı",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Durumu",
      accessorKey: "Fiyat Durumu",
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Ödeme",
      accessorKey: "Kapıda Ödeme",
      enableColumnFilter: false,
    },
    {
      header: "Kaporalı Satış",
      accessorKey: "Kaporalı Satış",
      enableColumnFilter: false,
    },
    {
      header: "Kısmi Ödeme",
      accessorKey: "Kısmi Ödeme",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
