import useHandleForm from "./useHandleForm";
import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";

import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FOREIGN_MARKET, MAN_ALLOWANCE, OTHER_OPERATOR } from "enums";
import { Input } from "reactstrap";

interface CrmOperationSelectFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

interface Option {
  value: string;
  label: string;
}

const CrmOperationSelectFields = ({
  values,
  formErrors,
  validateFormChange,
}: CrmOperationSelectFieldsProps) => {
  const { manAllowanceOptions, foreignMarketOptions, otherOperatorOptions } =
    useHandleForm();

  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Bay Konaklama"
        // error={formErrors.bay_konaklama}
        divider
      >
        <Select
          options={manAllowanceOptions}
          value={manAllowanceOptions.find(
            (option) => option.value === values.bay_konaklama
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                bay_konaklama:
                  MAN_ALLOWANCE[selected.value as keyof typeof MAN_ALLOWANCE],
              })
            );
            validateFormChange({
              ...values,
              bay_konaklama:
                MAN_ALLOWANCE[selected.value as keyof typeof MAN_ALLOWANCE],
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Bay Konaklama Açıklaması"
        // error={formErrors.bay_konaklama_aciklama}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.bay_konaklama_aciklama}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                bay_konaklama_aciklama: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              bay_konaklama_aciklama: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Dış Pazar Konaklamalarına İç Pazar"
        // error={formErrors.dis_pazar_fiyat}
        divider
      >
        <Select
          options={foreignMarketOptions}
          value={foreignMarketOptions.find(
            (option) => option.value === values.dis_pazar_fiyat
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                dis_pazar_fiyat:
                  FOREIGN_MARKET[selected.value as keyof typeof FOREIGN_MARKET],
              })
            );
            validateFormChange({
              ...values,
              dis_pazar_fiyat:
                FOREIGN_MARKET[selected.value as keyof typeof FOREIGN_MARKET],
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Dış Pazar Konaklama Açıklaması"
        // error={formErrors.dis_pazar_aciklama}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.dis_pazar_aciklama}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                dis_pazar_aciklama: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              dis_pazar_aciklama: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Başka Operatörde uygun görürseniz"
        // error={formErrors.baska_operatorde}
        divider
      >
        <Select
          options={otherOperatorOptions}
          value={otherOperatorOptions.find(
            (option) => option.value === values.baska_operatorde
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                baska_operatorde:
                  OTHER_OPERATOR[selected.value as keyof typeof OTHER_OPERATOR],
              })
            );
            validateFormChange({
              ...values,
              baska_operatorde:
                OTHER_OPERATOR[selected.value as keyof typeof OTHER_OPERATOR],
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Başka Operatör Açıklaması"
        // error={formErrors.baska_operator_aciklama}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.baska_operator_aciklama}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                baska_operator_aciklama: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              baska_operator_aciklama: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CrmOperationSelectFields;
