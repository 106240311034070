import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeInput } from "slices/discountsAndActions/uncancellableRoomRates/reducer";
import {
  UncancellableRoomRateState,
  UncancellableRoomRateFormErrors,
} from "types/DiscountsAndActions";

interface SwitchesProps {
  values: UncancellableRoomRateState;
  formErrors: UncancellableRoomRateFormErrors;
  validateFormChange: (values: UncancellableRoomRateState) => void;
}

const Switches = ({ values }: SwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer
        label="İptal Edilemez Oda Aktiflik"
        tooltipId="uncancellableRoomRatesStatus"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.aktiflik_durum === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  aktiflik_durum: values.aktiflik_durum === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tüm Odalara Uygula"
        tooltipId="uncancellableRoomRatesApplyToAllRooms"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_odalara_uygulama_durum === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_odalara_uygulama_durum:
                    values.tum_odalara_uygulama_durum === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default Switches;
