import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { CustomPageDescriptions } from "Components/Custom/UI";
import {
  UNCANCELLED_ROOM_RATES_DESCRIPTIONS,
  UNCANCELLED_ROOM_RATES_DESCRIPTIONS_WARNING,
} from "utils/pageDescriptions";
import UncancellableRoomRatesForm from "./UncancellableRoomRatesForm";
import UncancellableRoomRatesTable from "./UncancellableRoomRatesTable";
import PageContainer from "../PageContainer";
import RoomSelector from "./UncancellableRoomRatesForm/RoomSelector";

interface UncancellableRoomRatesPageProps {
  data: boolean;
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  isLoading: boolean;
}

const UncancellableRoomRatesPage = (props: UncancellableRoomRatesPageProps) => {
  const { isLoading } = props;

  return (
    <PageContainer
      descriptions={UNCANCELLED_ROOM_RATES_DESCRIPTIONS}
      isLoading={isLoading}
      roomSelector={<RoomSelector {...props} />}
    >
      <UncancellableRoomRatesForm {...props} />
      <CustomPageDescriptions
        variant="danger"
        descriptions={UNCANCELLED_ROOM_RATES_DESCRIPTIONS_WARNING}
      />
      <UncancellableRoomRatesTable />
    </PageContainer>
  );
};

export default withPageNeedsHotel(UncancellableRoomRatesPage);
