import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { handleChangeInput } from "slices/facility/addFacility/reducer";
import Select from "react-select";
import {
  FACILITY_CRM_LOCATION_DESCRIPTION,
  FACILITY_LOCATION_DESCRIPTION,
  FACILITY_LOCATION_ID_DESCRIPTION,
} from "utils/fieldDescriptions";

interface FacilityLocationProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityLocationOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityLocation = ({
  values,
  formErrors,
  validateFormChange,
  facilityLocationOptions,
}: FacilityLocationProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer
        label="Otel Bölgesi"
        error={formErrors.otel_bolgesi_id}
        description={FACILITY_LOCATION_ID_DESCRIPTION}
        divider
        required
      >
        <Select
          options={facilityLocationOptions}
          value={facilityLocationOptions.find(
            (option) => option.value === values.otel_bolgesi_id.toString()
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                otel_bolgesi_id: selected.value,
              })
            );
            validateFormChange({
              ...values,
              otel_bolgesi_id: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Otel Konum Açıklama"
        error={formErrors.otel_konum_aciklama}
        orientation="vertical"
        description={FACILITY_LOCATION_DESCRIPTION}
        required
        divider
      >
        <CustomFormEditor
          editorData={values.otel_konum_aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                otel_konum_aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              otel_konum_aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="CRM Açıklamalı Adres"
        error={formErrors.crm_aciklamali_adres}
        orientation="vertical"
        description={FACILITY_CRM_LOCATION_DESCRIPTION}
        required
      >
        <CustomFormEditor
          editorData={values.crm_aciklamali_adres}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                crm_aciklamali_adres: data,
              })
            );
            validateFormChange({
              ...values,
              crm_aciklamali_adres: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityLocation;
