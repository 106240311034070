import ListLogsPage from 'Components/Custom/UserOperationsPages/ListLogsPage';
import useListLogs from 'Components/Hooks/userOperations/useListLogs';
import PageContainer from 'pages/PageContainer';

const ListLogs = () => {

    const { isLoading } = useListLogs();

  return (
    <PageContainer title="Logları Listele">
        <ListLogsPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default ListLogs