import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";

import { FacilityUsersInitialState } from "slices/facility/facilityUsers/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  AddFacilityUserRequestPayload,
  UpdateFacilityUserRequestPayload,
} from "types/facility";
import { useEffect, useState } from "react";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const {
    updateFacilityUser,
    updateFacilityUserFormErrors,
    username,
    userMails,
    selectedMail,
    addUserLoading,
    updateUserLoading,
    resetPasswordLoading,
  } = useSelector(
    (state: { FacilityUsers: FacilityUsersInitialState }) => state.FacilityUsers
  );

  const [userMailsOptions, setUserMailsOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (userMails) {
      const options = userMails.map((mail) => ({
        value: mail.mail,
        label: mail.mail,
      }));
      setUserMailsOptions(options);
    }
  }, [userMails]);

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const addFacilityUserRequestPayload: AddFacilityUserRequestPayload = {
    otel_id: hotel.otel_id,
    aktiflik: updateFacilityUser.aktiflik === "1" ? 1 : 0,
    inet_kodu: updateFacilityUser.inet_kodu,
    mail: updateFacilityUser.mail,
    username: updateFacilityUser.kullanici_adi,
    yeni_kullanicimi: 1,
  };

  const updateFacilityUserRequestPayload: UpdateFacilityUserRequestPayload = {
    id: updateFacilityUser.id,
    inet_kodu: updateFacilityUser.inet_kodu,
    aktiflik: updateFacilityUser.aktiflik === "1" ? 1 : 0,
    username: updateFacilityUser.kullanici_adi,
  };

  const needUserMail = !userMails.length;

  return {
    updateFacilityUser,
    selectedMail,
    userMailsOptions,
    formErrors: updateFacilityUserFormErrors,
    addUserLoading,
    updateUserLoading,
    resetPasswordLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addFacilityUserRequestPayload,
    updateFacilityUserRequestPayload,
    needUserMail,
    username,
  };
};

export default useHandleForm;
