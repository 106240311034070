import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
} from "reactstrap";
import SimpleBar from "simplebar-react";

interface CustomSelectBoxProps {
  inputId: string;
  options: Option[];
  onSelect: (value: number | string) => void;
  placeholder?: string;
  boxWidth?: string;
  defaultOption?: Option;
}

interface Option {
  value: number | string;
  label: string;
}

const CustomSelectBox = ({
  inputId,
  options,
  onSelect,
  placeholder = "Seçiniz...",
  boxWidth = "400px",
  defaultOption = {
    value: 0,
    label: "",
  },
}: CustomSelectBoxProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>(defaultOption);

  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const toogleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleFilterOptions = (text: string) => {
    const result = options.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase())
    );

    setFilteredOptions(result);
  };

  const handleSelectOption = (option: Option) => {
    onSelect(option.value);
    setSelectedOption(option);
    const input = document.getElementById(inputId) as HTMLInputElement;
    input.value = "";
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <Dropdown isOpen={isOpen} toggle={toogleDropdown} direction="down">
      <InputGroup>
        <DropdownToggle
          className="d-flex align-items-center justify-content-center p-0 bg-white"
          style={{
            width: boxWidth,
            border: "none",
          }}
        >
          <Input
            id={inputId}
            type="text"
            placeholder={selectedOption.label || placeholder}
            onChange={(e) => handleFilterOptions(e.target.value)}
            autoComplete="off"
          />
        </DropdownToggle>
      </InputGroup>
      <DropdownMenu>
        <SimpleBar
          style={{
            width: boxWidth,
            maxHeight: "200px",
          }}
        >
          <div className="d-flex flex-column">
            {filteredOptions.length ? (
              filteredOptions.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.label}
                </DropdownItem>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Seçenek Bulunamadı</span>
              </div>
            )}
          </div>
        </SimpleBar>
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomSelectBox;
