import { useSelector } from "react-redux";

import { AdsReportInitialState } from "slices/adsAndAnalyze/adsReport/reducer";

const useHandleSummary = () => {
  const { adsReportSummary } = useSelector(
    (state: { AdsReport: AdsReportInitialState }) => state.AdsReport
  );

  const summary: { [key: string]: number }[] = [];

  const sales = {
    "Toplam Satış": adsReportSummary.toplam_satis,
    "CRM Satış": adsReportSummary.crm_satis,
    "Termal Satış": adsReportSummary.termal_satis,
    "Şehir Satış": adsReportSummary.sehir_satis,
    "Kayak Satış": adsReportSummary.kayak_satis,
    "Operator Satış": adsReportSummary.operator_satis,
    "İslami Satış": adsReportSummary.islami_satis,
  };

  const costs = {
    "Toplam Maliyet": adsReportSummary.toplam_maliyet,
    "CRM Maliyet": adsReportSummary.crm_maliyet,
    "Termal Maliyet": adsReportSummary.termal_maliyet,
    "Şehir Maliyet": adsReportSummary.sehir_maliyet,
    "Kayak Maliyet": adsReportSummary.kayak_maliyet,
    "Operator Maliyet": adsReportSummary.operator_maliyet,
    "İslami Maliyet": adsReportSummary.islami_maliyet,
  };

  const rates = {
    "Toplam Oran": adsReportSummary.toplam_oran,
    "CRM Oran": adsReportSummary.crm_oran,
    "Termal Oran": adsReportSummary.termal_oran,
    "Şehir Oran": adsReportSummary.sehir_oran,
    "Kayak Oran": adsReportSummary.kayak_oran,
    "Operator Oran": adsReportSummary.operator_oran,
    "İslami Oran": adsReportSummary.islami_oran,
  };

  summary.push(sales, costs, rates);

  return { summary };
};

export default useHandleSummary;
