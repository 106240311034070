import { RecommendedHotel } from "types/themes";
import useHandleList from "./useHandleList";
import { CustomButton, CustomDualListBox } from "Components/Custom/UI";

interface AssigningListsProps {
  hotels: RecommendedHotel[];
  selectedTheme: number;
}

const AssigningLists = ({ hotels, selectedTheme }: AssigningListsProps) => {
  const {
    selectedOptions,
    setSelectedOptions,
    hotelsListOptions,
    handleSaveAssignRecommendedHotels,
    isLoading,
  } = useHandleList(hotels, selectedTheme);

  return (
    <div className="d-flex flex-column gap-4 border border-2 border-white bg-secondary bg-opacity-10 rounded p-2">
      <p className="text-center fs-20 fw-medium">Otel Seçin</p>
      <CustomDualListBox
        listOptions={hotelsListOptions}
        selectedOptions={selectedOptions}
        handleSelectOptions={(assignedHotels) => {
          setSelectedOptions(assignedHotels);
        }}
      />
      <div className="d-flex justify-content-end gap-2">
        <CustomButton variant="danger" onClick={() => setSelectedOptions([])}>
          Temizle
        </CustomButton>
        <CustomButton
          variant="success"
          onClick={handleSaveAssignRecommendedHotels}
          isLoading={isLoading}
        >
          Otelleri Ata
        </CustomButton>
      </div>
    </div>
  );
};

export default AssigningLists;
