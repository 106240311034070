// List Comments
export interface ListComments {
  yorum_id: number;
  musteri_adi: string;
  otel_adi: string;
  kategori: string;
  personel_ad_soyad: string;
  personel: string;
  giris_tarihi: string;
  cikis_tarihi: string;
  yorum_olumlu: string;
  yorum_olumsuz: string;
  cevap1: string;
  cevap2: string;
  cevap3: string;
  cevap4: string;
  cevap5: string;
  cevap6: string;
  cevap7: string;
  tarih: string;
  aktiflik: string;
  silme: string;
  rezervasyon_id : string;
}

export interface ListCommentsResponse {
  data: ListComments[];
  sabit_datalar: { [key: string]: number };
}

export interface ListCommentsState {
  musteri_adi: string;
  otel_adi: string;
  kategori: string;
  personel: string;
  giris_tarihi: string;
  yorum_olumlu: string;
  yorum_olumsuz: string;
  cevap7: string;
  tarih: string;
  yorum_id: number;
}

// Update and Delete Comment
export interface UpdateCommentPayload {
  yorum_id: number;
  yorum_olumlu: string;
  yorum_olumsuz: string;
}

//Add Comment
export enum Category {
  IS_GEZISI = "İş Gezisi",
  CIFTLERE_OZEL = "Çiftlere Özel",
  COCUKLU_AILE = "Çocuklu Aile",
  ARKADAS_GRUBU = "Arkadaş Grubu",
  DIGER = "Diğer",
}

export enum QuestionTitle {
  PERSONEL_ILETISIMI = "Personel İletişimi",
  TEMIZLIK = "Temizlik",
  KONUM_CEVRE = "Konum - Çevre",
  RESTORANT_YEMEK = "Restorant - Yemek",
  ODALAR = "Odalar",
  FIYAT_KALITE = "Fiyat - Kalite",
}

export interface AddComment {
  kategori: string;
  musteri_adi: string; // "Ad Soyad"
  otel_id: number;
  otel_adi: string;
  giris_tarihi: string; // "Giriş-Çıkış"
  cikis_tarihi: string; // "Giriş-Çıkış"
  yorum_olumlu: string; // "Olumlu Yorum"
  yorum_olumsuz: string; // "Olumsuz Yorum"
  manuel_yazan: string;
  cevap1: string; // "Personel İletişimi" // 10 => Çok iyi 8 => iyi 6 => orta 4 => kötü
  cevap2: string; // "Temizlik"
  cevap3: string; // "Konum - Çevre"
  cevap4: string; // "Restorant - Yemek"
  cevap5: string; // "Odalar"
  cevap6: string; // "Fiyat - Kalite"
  tarih: string;
}

export interface AddCommentState {
  kategori: string;
  musteri_adi: string;
  musteri_adi_gosterim: string;
  otel_id: number;
  otel_adi: string;
  giris_tarihi: string;
  cikis_tarihi: string;
  yorum_olumlu: string;
  yorum_olumsuz: string;
  manuel_yazan: string;
  cevap1: string;
  cevap2: string;
  cevap3: string;
  cevap4: string;
  cevap5: string;
  cevap6: string;
  tarih: string;
}

export interface AddCommentFormErrors {
  kategori?: string;
  musteri_adi?: string;
  musteri_adi_gosterim?: string;
  otel_id?: string;
  otel_adi?: string;
  giris_tarihi?: string;
  cikis_tarihi?: string;
  yorum_olumlu?: string;
  yorum_olumsuz?: string;
  manuel_yazan?: string;
  cevap1?: string;
  cevap2?: string;
  cevap3?: string;
  cevap4?: string;
  cevap5?: string;
  cevap6?: string;
  tarih?: string;
}

export interface AddCommentRequestPayload {
  kategori: string;
  musteri_adi: string;
  musteri_adi_gosterim: string;
  otel_id: number;
  otel_adi: string;
  giris_tarihi: string;
  cikis_tarihi: string;
  yorum_olumlu: string;
  yorum_olumsuz: string;
  manuel_yazan: string;
  cevap1: string;
  cevap2: string;
  cevap3: string;
  cevap4: string;
  cevap5: string;
  cevap6: string;
  tarih: string;
}

export interface AddCommentScoreFields {
  soru_id: number;
  soru: QuestionTitle;
  soru_kodu: string;
  soru_olumlu_cevap: string;
  soru_olumsuz_cevap: string;
  durum: string;
}

export interface AddCommentQuestions {
  sorular: ScoreField[];
  kategori: string[],
  puanlama: Record<string, number>
}

export interface ScoreField {
  soru_id: number,
  soru: string,
  soru_kodu: string,
  soru_olumlu_cevap: string,
  soru_olumsuz_cevap: string,
  durum: string,
}

//List Hotels Without Comment

export interface ListHotelsWithoutComments {
  otel_adi: string;
}

export interface ListHotelsWithoutCommentsResponse {
  data: ListHotelsWithoutComments[];
  sabit_datalar: { [key: string]: number };
}