import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomButton } from "Components/Custom/UI";
import { AddGroupPayload } from "types/userOperations";
import { addGroup } from "slices/userOperations/addGroup/thunk";
import useHandlePayload from "../useHandlePayload";

interface AddGroupButtonProps {
  values: AddGroupPayload;
  requestPayload: FormData;
  validateForm: (values: AddGroupPayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddGroupButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddGroupButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const getPayload = useHandlePayload(requestPayload, values);

  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(addGroup(getPayload()));
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        classes="flex-grow-1"
        variant="success"
        onClick={onClick}
        isLoading={isLoading}
      >
        Grup Ekle
      </CustomButton>
    </div>
  );
};

export default AddGroupButton;
