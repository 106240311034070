import { UserAuthority } from "types/userOperations";
import { Label } from "reactstrap";
import Select from "react-select";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface UserAuthoritySelectorProps {
  authorities: UserAuthority[];
  selectedAuthority: number;
  handleSelectAuthority: (id: number) => void;
}

interface Option {
  value: number;
  label: string;
}

const UserAuthoritySelector = ({
  authorities,
  selectedAuthority,
  handleSelectAuthority,
}: UserAuthoritySelectorProps) => {
  const authorityOptions: Option[] = authorities.map((authority) => ({
    value: authority.id,
    label: authority.adi,
  }));

  const selectedOption = authorityOptions.find(
    (option) => selectedAuthority === option.value
  );

  return (
    <CustomFormFieldContainer label="Ana Yetki Seçiniz " required>
      <Select
        options={authorityOptions}
        value={selectedOption || null}
        onChange={(selected: Option | null) => {
          if (selected) {
            handleSelectAuthority(selected.value);
          }
        }}
        placeholder="Ana yetkiyi seçiniz..."
      />
    </CustomFormFieldContainer>
  );
};

export default UserAuthoritySelector;
