import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";

interface FacilitySupplierAndInetFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  supplierOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilitySupplierAndInetFields = ({
  values,
  validateFormChange,
  formErrors,
  supplierOptions,
}: FacilitySupplierAndInetFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Tedariçi"
        error={formErrors.tedarikci}
        divider
      >
        <Select
          options={supplierOptions}
          value={supplierOptions.find(
            (option) => option.value === values.tedarikci
          )}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                tedarikci: selected.value,
              })
            );
            validateFormChange({
              ...values,
              tedarikci: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="iNET Durum"
        error={formErrors.inet_durum}
        divider
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.inet_durum === "1"}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  ...values,
                  inet_durum: e.target.value === "1" ? "1" : "0",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="iNET Operatör Kodu"
        error={formErrors.inet_operator_kodu}
      >
        <Input
          type="text"
          value={values.inet_operator_kodu}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                inet_operator_kodu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              inet_operator_kodu: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilitySupplierAndInetFields;
