import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PeritoExtensionNumbersState } from "slices/userOperations/peritoExtensionNumbers/reducer";
import { getPeritoExtensionNumbers } from "slices/userOperations/peritoExtensionNumbers/thunk";

const usePeritoExtensionNumbers = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { PeritoExtensionNumbers: PeritoExtensionNumbersState }) => state.PeritoExtensionNumbers
  );

  useEffect(() => {
    dispatch(getPeritoExtensionNumbers());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default usePeritoExtensionNumbers;
