import { ReservationCallChildrenInfoRoom } from "types/reservation";

const ChildrenInfoRow = ({
  oda_adi,
  cocuk_1,
  cocuk_2,
  cocuk_3,
  cocuk_4,
}: ReservationCallChildrenInfoRoom) => {
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
      <div className="d-flex align-items-center gap-2">
        <span className="fw-semibold fs-14">{oda_adi}</span>
        {cocuk_1 ? <span className="fs-13">1.Çocuk: {cocuk_1} / </span> : <></>}
        {cocuk_2 ? <span className="fs-13">2.Çocuk: {cocuk_2} / </span> : <></>}
        {cocuk_3 ? <span className="fs-13">3.Çocuk: {cocuk_3} / </span> : <></>}
        {cocuk_4 ? <span className="fs-13">4.Çocuk: {cocuk_4}</span> : <></>}
      </div>
    </div>
  );
};

export default ChildrenInfoRow;
