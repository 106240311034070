import AddGalleryImageForm from "./AddGalleryImageForm";
import useHandleForm from "./useHandleForm";
import ListGalleryImagesTable from "./ListGalleryImagesTable";

const ManageGalleryImages = () => {
    const {
        addGalleryImage,
        addGalleryImageFormErrors,
        validateForm,
        validateFormChange,
        handleSubmitted,
        isLoading,
        addGalleryImageLoading,
        onAddGalleryImage
    } = useHandleForm();

    const { sayfa_id } = addGalleryImage;
    return (
        <>
            {sayfa_id ?
                <div className="d-flex flex-column gap-3 px-4 py-2">
                    <AddGalleryImageForm
                        values={addGalleryImage}
                        formErrors={addGalleryImageFormErrors}
                        validateFormChange={validateFormChange}
                        validateForm={validateForm}
                        handleSubmitted={handleSubmitted}
                        onAddGalleryImage={onAddGalleryImage}
                        addGaleryImageLoading={addGalleryImageLoading} />
                    <ListGalleryImagesTable isLoading={isLoading} />
                </div>
                : <></>
            }
        </>
    );
};

export default ManageGalleryImages;
