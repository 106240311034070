import Select from "react-select";
import useHandleForm from "./useHandleForm";
import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface Option {
  value: string;
  label: string;
}

interface FilterFormProps {
  defaultState: {
    grup_id: string;
    date: string;
  };
}

const FilterForm = ({ defaultState }: FilterFormProps) => {
  const {
    monthOptions,
    groupOptions,
    filterForm,
    handleFormChange,
    onGetFilteredList,
    onResetFilter,
  } = useHandleForm(defaultState);
  return (
    <CustomFormContainer>
      <div className="d-flex gap-4 flex-wrap align-items-end">
        <CustomFormFieldContainer label="Tarih" orientation="vertical">
          <Select
            options={monthOptions}
            value={monthOptions.find(
              (option) => option.value === filterForm.date
            )}
            onChange={(selectedOption: Option) => {
              handleFormChange("date", selectedOption.value);
            }}
            styles={{
              control: (styles: any) => ({
                ...styles,
                width: "200px",
              }),
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Grup" orientation="vertical">
          <Select
            options={groupOptions}
            value={groupOptions.find(
              (option) => option.value === filterForm.grup_id
            )}
            onChange={(selectedOption: Option) => {
              handleFormChange("grup_id", selectedOption.value);
            }}
            styles={{
              control: (styles: any) => ({
                ...styles,
                width: "200px",
              }),
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="" orientation="vertical">
          <div className="d-flex gap-2">
            <CustomButton variant="warning" onClick={onResetFilter}>
              Filtreyi Temizle
            </CustomButton>
            <CustomButton variant="success" onClick={onGetFilteredList}>
              Filtrele
            </CustomButton>
          </div>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default FilterForm;
