import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { HotelSalesInitialState } from "slices/reports/hotelSales/reducer";
import {
  getHotelSales,
  getHotelSaleFilters,
} from "slices/reports/hotelSales/thunk";
import { GetFilteredHotelSalesRequestPayload } from "types/reports";

const useHotelSales = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { HotelSales: HotelSalesInitialState }) => state.HotelSales
  );

  useEffect(() => {
    const payload: GetFilteredHotelSalesRequestPayload = {
      baslangic_tarihi: moment().subtract(1, "months").format("YYYY-MM-DD"),
      bitis_tarihi: moment().format("YYYY-MM-DD"),
      personel_id: 0,
      fiyat_saglayici: "",
      hesaplama_tarih_tipi: "kayit_tarihi",
      sehir_id: 0,
      ilce_id: 0,
      karsilama_kodu: [],
    };

    dispatch(getHotelSales(payload));
    dispatch(getHotelSaleFilters());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useHotelSales;
