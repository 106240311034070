import { useDispatch, useSelector } from "react-redux";
import { AdditionalPagesInitialState, resetAddGalleryImageFormFields } from "slices/myWebSite/additionalPages/reducer";
import useValidateForm from "./useValidateForm";
import { Dispatch } from "react";
import { AddGalleryImagePayload } from "types/myWebSite";
import { FilePondFile } from "filepond";
import { addNewGalleryImage, getGalleryImages } from "slices/myWebSite/additionalPages/thunk";
import { HotelInitialState } from "slices/hotel/reducer";


const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    addGalleryImage,
    isLoading,
    addGalleryImageFormErrors,
    addGalleryImageLoading
  } = useSelector(
    (state: { AdditionalPages: AdditionalPagesInitialState }) => state.AdditionalPages
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const handleAddGalleryImage = () => {
    const payload: AddGalleryImagePayload = new FormData();

    payload.append("otel_id", otel_id.toString());
    payload.append("sayfa_id", addGalleryImage.sayfa_id.toString());

    const file = addGalleryImage.resim as FilePondFile;
    payload.append("resim", file.file, file.file.name);

    dispatch(
      addNewGalleryImage(payload, () => {
        dispatch(
          getGalleryImages(otel_id, addGalleryImage.sayfa_id)
        );
        dispatch(
          resetAddGalleryImageFormFields()
        );
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    addGalleryImage,
    addGalleryImageFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
    addGalleryImageLoading,
    onAddGalleryImage: handleAddGalleryImage,
  };
};

export default useHandleForm;
