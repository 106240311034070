import { createSlice } from "@reduxjs/toolkit";
import { FOREIGN_MARKET, MAN_ALLOWANCE, OTHER_OPERATOR } from "enums";

import {
  FacilityState,
  AddFacilityFormErrors,
  FacilityFormChoices,
} from "types/facility";

export interface AddFacilityInitialState {
  facilityFormChoices: FacilityFormChoices;
  addFacility: FacilityState;
  addFacilityFormErrors: AddFacilityFormErrors;
  isLoading: boolean;
  error: string;
  addFacilityLoading: boolean;
  addFacilityError: string;
}

export const addFacilityInitialState: AddFacilityInitialState = {
  facilityFormChoices: {
    Karşılama_Kodu: [],
    "Fiyat Hesaplama": [],
    tedarikçi: [],
    "Fiyat Sağlayıcısı": [],
    "Otel Değerlendirme Yıldızı": [],
    "Otel Özellikleri": {},
    "Çocuklara Özel": {},
    "Oda Özellikleri": {},
    "Yiyecek İçecek": {},
    Aktiviteler: {},
    "Havuz, Deniz ve Plaj": {},
    "Otel Teması": {},
    "İslami Otel Teması": {},
    Lokasyonlar: {},
    "Tema - Özellik Eşleşmesi": {},
  },
  addFacility: {
    otel_adi: "",
    telefon: "",
    resim: "",
    yildiz: "",
    otel_link: "",
    otel_insaat_tarihi: 0,
    otel_yenileme: 0,
    otel_odasayisi: 0,
    deniz_mesafe: "",
    one_cikan_ozellik1: "",
    one_cikan_ozellik2: "",
    one_cikan_ozellik3: "",
    one_cikan_ozellik4: "",
    otel_genel_aciklama: "",
    otel_ozellikleri: [],
    cocuk_ozellikleri: [],
    otel_adres: "",
    otel_bolgesi_id: "",
    otel_konum_aciklama: "",
    crm_aciklamali_adres: "",
    otel_konaklama_aciklama: "",
    oda_ozellikleri: [],
    yiyecek_metin: "",
    yiyecek_ozellik: [],
    otel_aktivite_aciklama: "",
    aktiviteler: [],
    txt_havuz: "",
    havuz_deniz_plaj: [],
    txt_termal: "",
    txt_toplanti: "",
    txt_balayi: "",
    txt_internet: "",
    otel_tema_id: [],
    google_map_x: "",
    google_map_y: "",
    aktifmi: "1",
    fiyat_listesi_goster: "e",
    acente_bilgisini_goster: "e",
    otel_fiyat_id: 0,
    acante2_id: 0,
    tedarikci: "",
    inet_durum: "1",
    inet_operator_kodu: "",
    karsilama_kodu: "",
    fiyat_hesaplama: "",
    crm_satis_aciklama: "",
    crm_operasyon_aciklamasi: "",
    bay_konaklama: MAN_ALLOWANCE["Bay Konaklama Gösterme"],
    bay_konaklama_aciklama: "",
    dis_pazar_fiyat:
      FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"],
    dis_pazar_aciklama: "",
    baska_operatorde:
      OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"],
    baska_operator_aciklama: "",
    jollytur: "",
    odeon: "",
    otel_z: "",
    hotels: "",
    ets_tur_url: "",
    tatil_sepeti_url: "",
    tatil_com_url: "",
    tatil_budur_url: "",
    odamax_url: "",
  },
  addFacilityFormErrors: {},
  isLoading: false,
  error: "",
  addFacilityLoading: false,
  addFacilityError: "",
};

export const addFacilitySlice = createSlice({
  name: "addFacility",
  initialState: addFacilityInitialState,
  reducers: {
    handleSaveFacilityChoices(state, action) {
      state.facilityFormChoices = action.payload;
    },
    handleChangeInput(state, action) {
      state.addFacility = {
        ...state.addFacility,
        ...action.payload,
      };
    },
    handleAddAddFacilityFormErrors(state, action) {
      state.addFacilityFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddFacilityLoading(state, action) {
      state.addFacilityLoading = action.payload;
    },
    setAddFacilityError(state, action) {
      state.addFacilityError = action.payload;
    },
    handleResetFacilityState(state) {
      state.addFacility = {
        otel_adi: "",
        telefon: "",
        resim: "",
        yildiz: "",
        otel_link: "",
        otel_insaat_tarihi: 0,
        otel_yenileme: 0,
        otel_odasayisi: 0,
        deniz_mesafe: "",
        one_cikan_ozellik1: "",
        one_cikan_ozellik2: "",
        one_cikan_ozellik3: "",
        one_cikan_ozellik4: "",
        otel_genel_aciklama: "",
        otel_ozellikleri: [],
        cocuk_ozellikleri: [],
        otel_adres: "",
        otel_bolgesi_id: "",
        otel_konum_aciklama: "",
        crm_aciklamali_adres: "",
        otel_konaklama_aciklama: "",
        oda_ozellikleri: [],
        yiyecek_metin: "",
        yiyecek_ozellik: [],
        otel_aktivite_aciklama: "",
        aktiviteler: [],
        txt_havuz: "",
        havuz_deniz_plaj: [],
        txt_termal: "",
        txt_toplanti: "",
        txt_balayi: "",
        txt_internet: "",
        otel_tema_id: [],
        google_map_x: "",
        google_map_y: "",
        aktifmi: "1",
        fiyat_listesi_goster: "e",
        acente_bilgisini_goster: "e",
        otel_fiyat_id: 0,
        acante2_id: 0,
        tedarikci: "",
        inet_durum: "1",
        inet_operator_kodu: "",
        karsilama_kodu: "",
        fiyat_hesaplama: "",
        crm_satis_aciklama: "",
        crm_operasyon_aciklamasi: "",
        bay_konaklama: MAN_ALLOWANCE["Bay Konaklama Gösterme"],
        bay_konaklama_aciklama: "",
        dis_pazar_fiyat:
          FOREIGN_MARKET["Dış Pazar Konaklamalarına İç Pazar Gösterme"],
        dis_pazar_aciklama: "",
        baska_operatorde:
          OTHER_OPERATOR["Başka Operatörde uygun görürseniz Gösterme"],
        baska_operator_aciklama: "",
        jollytur: "",
        odeon: "",
        otel_z: "",
        hotels: "",
        ets_tur_url: "",
        tatil_sepeti_url: "",
        tatil_com_url: "",
        tatil_budur_url: "",
        odamax_url: "",
      };
    },
  },
});

export const {
  handleSaveFacilityChoices,
  handleChangeInput,
  handleAddAddFacilityFormErrors,
  setIsLoading,
  setError,
  setAddFacilityLoading,
  setAddFacilityError,
  handleResetFacilityState,
} = addFacilitySlice.actions;

export default addFacilitySlice.reducer;
