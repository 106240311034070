import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ListThemesInitialState } from "slices/themes/listThemes/reducer";

import { getThemes } from "slices/themes/listThemes/thunk";

const useListThemes = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { themes, updateTheme, isLoading, getThemeLoading } = useSelector(
    (state: { ListThemes: ListThemesInitialState }) => state.ListThemes
  );

  const themesOptions = themes.map((theme) => ({
    value: theme.tema_id,
    label: theme.baslik,
  }));

  useEffect(() => {
    dispatch(getThemes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    updateTheme,
    themesOptions,
    isLoading: isLoading || getThemeLoading,
  };
};

export default useListThemes;
