import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { UsuableParaPuanCardData } from "types/dashboard";


interface UsuableParaPuanCardProps {
  values: UsuableParaPuanCardData;
}

const UsuableParaPuanCard = ({ values }: UsuableParaPuanCardProps) => {

  return (
    <>
      <CustomDashboardCard xl={4} title="Kullanılabilir Para Puan" color="blue" >
      <div
          className="d-flex justify-content-between"
          style={{
            cursor: "pointer",
          }}
        >
          <div>
            <p className="mb-8 fw-semibold fs-3">{values.para_puan_miktari} ₺</p>
            <a href="/" style={{textDecoration:'none', color:'white', fontSize: "14px"}}>Detay sayfasına git...</a>
          </div>
          <i
            className="ri-gift-fill"
            style={{ fontSize: "48px", color: "white" }}
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default UsuableParaPuanCard;
