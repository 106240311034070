import axios from "axios";

import {
  handleGeneralPriceSettings,
  setIsLoading,
  setError,
  setSaveSettingsLoading,
  setSaveSettingsError,
} from "./reducer";
import { handleErrors } from "helpers/axios_helpers";

import {
  GET_GENERAL_PRICE_SETTINGS,
  POST_GENERAL_PRICE_SETTINGS,
} from "helpers/url_helper";
import { GeneralPriceSettingRequestPayload } from "types/PricesQuotas";
import { setToastError, setToastSuccess } from "slices/toast/reducer";

type SuccessCallback = () => void;

export const getGeneralPriceSettings =
  (otel_id: number) => async (dispatch: any) => {
    dispatch(setIsLoading(true));
    try {
      const response = await axios.get(
        `${GET_GENERAL_PRICE_SETTINGS}/${otel_id}`
      );

      const res = response.data || response;

      dispatch(handleGeneralPriceSettings(res));
    } catch (error) {
      dispatch(setError(handleErrors(error)));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const saveGeneralPriceSettings =
  (
    payload: GeneralPriceSettingRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: any) => {
    dispatch(setSaveSettingsLoading(true));
    try {
      await axios.post(POST_GENERAL_PRICE_SETTINGS, payload);

      successCallback();

      dispatch(setToastSuccess("Ayarlar kaydedildi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveSettingsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveSettingsLoading(false));
    }
  };
