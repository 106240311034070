import {
    CustomFormFieldContainer,
    CustomFormEditor,
  } from "Components/Custom/UI";
  import { useDispatch } from "react-redux";
  import { Dispatch } from "redux";
  import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";
  import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
  
  interface GeneralInformationTextProps {
      values: CreateWebSiteState;
      formErrors: CreateWebSiteFormErrors;
      validateFormChange: (values: CreateWebSiteState) => void;
  }
  
  const GeneralInformationText = ({
    values,
    formErrors,
    validateFormChange,
  }: GeneralInformationTextProps) => {
    const dispatch: Dispatch<any> = useDispatch();
  
    return (
      <CustomFormFieldContainer
        label="Genel Bilgiler Açıklaması : "
        error={formErrors.genel_ozellikler}
        orientation="vertical"
      >
        <CustomFormEditor
          editorData={values.genel_ozellikler}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                genel_ozellikler: data,
              })
            );
            validateFormChange({
              ...values,
              genel_ozellikler: data,
            });
          }}
        />
      </CustomFormFieldContainer>
    );
  };
  
  export default GeneralInformationText;
  