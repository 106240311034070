import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import Select from "react-select";
import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
} from "types/PricesQuotas";

import { handleChangeGeneralPriceSettingsInput } from "slices/pricesAndQuota/generalPriceSettings/reducer";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";

interface PriceSettingsProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  workingTypesOptions: Option[];
  priceEntryMethodsOptions: Option[];
  currencyOptions: Option[];
  cancelInsuranceOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const PriceSettings = ({
  values,
  formErrors,
  validateFormChange,
  workingTypesOptions,
  priceEntryMethodsOptions,
  currencyOptions,
  cancelInsuranceOptions,
}: PriceSettingsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Genel Fiyat Ayarlamaları">
      <CustomFormFieldContainer label="Çalışma Şekli">
        <Select
          value={workingTypesOptions.find(
            (option) => option.value === values.calisma_sekli
          )}
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                calisma_sekli: selectedOption.value as
                  | "net"
                  | "komisyonlu"
                  | "komisyon_faturali",
              })
            );

            validateFormChange({
              ...values,
              calisma_sekli: selectedOption.value as
                | "net"
                | "komisyonlu"
                | "komisyon_faturali",
            });
          }}
          options={workingTypesOptions}
          placeholder="Çalışma Şekli Seçiniz..."
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Fiyat Giriş Yöntemi">
        <Select
          value={
            priceEntryMethodsOptions.find(
              (item) => item.value === values.fiyat_giris_yontemi
            ) || null
          }
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                fiyat_giris_yontemi: selectedOption.value as
                  | "odafiyati"
                  | "kisibasifiyat",
              })
            );

            validateFormChange({
              ...values,
              fiyat_giris_yontemi: selectedOption.value as
                | "odafiyati"
                | "kisibasifiyat",
            });
          }}
          options={priceEntryMethodsOptions}
          placeholder="Fiyat Giriş Yöntemi Seçiniz..."
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Çalışılacak Para Birimi">
        <Select
          value={currencyOptions.find(
            (item) => item.value === values.para_birimi
          )}
          onChange={(selectedOption: Option) => {
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                para_birimi: selectedOption.value as
                  | "TRY"
                  | "USD"
                  | "EUR"
                  | "GBP",
              })
            );
            dispatch(
              handleChangeGeneralPriceSettingsInput({
                sabit_kur_degeri_durum: false,
              })
            );

            validateFormChange({
              ...values,
              para_birimi: selectedOption.value as
                | "TRY"
                | "USD"
                | "EUR"
                | "GBP",
            });
          }}
          options={currencyOptions}
          placeholder="Para Birimi Seçiniz..."
        />
      </CustomFormFieldContainer>
      {values.para_birimi !== "TRY" && (
        <CustomFormFieldContainer label="Sabit Kur">
          <div className="d-flex align-items-center gap-5">
            <FormGroup switch>
              <Input
                type="switch"
                checked={values.sabit_kur_degeri_durum}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      sabit_kur_degeri_durum: e.target.checked,
                    })
                  );

                  validateFormChange({
                    ...values,
                    sabit_kur_degeri_durum: e.target.checked,
                  });
                }}
              />
            </FormGroup>
            {values.sabit_kur_degeri_durum && (
              <InputGroup>
                <Input
                  type="number"
                  value={
                    values.para_birimi === "USD"
                      ? values.sabit_doviz_USD ?? 0
                      : values.para_birimi === "EUR"
                      ? values.sabit_doviz_EUR ?? 0
                      : values.para_birimi === "GBP"
                      ? values.sabit_doviz_GBP ?? 0
                      : 0
                  }
                  onChange={(e) => {
                    dispatch(
                      handleChangeGeneralPriceSettingsInput({
                        sabit_doviz_USD:
                          values.para_birimi === "USD"
                            ? parseFloat(e.target.value)
                            : values.sabit_doviz_USD,
                        sabit_doviz_EUR:
                          values.para_birimi === "EUR"
                            ? parseFloat(e.target.value)
                            : values.sabit_doviz_EUR,
                        sabit_doviz_GBP:
                          values.para_birimi === "GBP"
                            ? parseFloat(e.target.value)
                            : values.sabit_doviz_GBP,
                      })
                    );
                  }}
                />
                <InputGroupText>
                  {values.para_birimi === "USD"
                    ? "USD"
                    : values.para_birimi === "EUR"
                    ? "EUR"
                    : values.para_birimi === "GBP"
                    ? "GBP"
                    : "TRY"}
                </InputGroupText>
              </InputGroup>
            )}
          </div>
        </CustomFormFieldContainer>
      )}
      <CustomFormFieldContainer label="Komisyon Oranı">
        <div className="d-flex align-items-center gap-5">
          <InputGroup>
            <InputGroupText>%</InputGroupText>
            <Input
              type="number"
              value={values.komisyon_orani}
              onChange={(e) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    komisyon_orani: parseInt(e.target.value),
                  })
                );

                validateFormChange({
                  ...values,
                  komisyon_orani: parseInt(e.target.value),
                });
              }}
            />
          </InputGroup>
          <div className="d-flex align-items-center gap-1">
            <FormGroup switch className="m-0">
              <Input
                type="switch"
                checked={values.komisyon_kumulatif_durum}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      komisyon_kumulatif_durum: e.target.checked,
                    })
                  );

                  validateFormChange({
                    ...values,
                    komisyon_kumulatif_durum: e.target.checked,
                  });
                }}
              />
            </FormGroup>
            <span className="text-nowrap fw-medium me-3">Kümülatif</span>
          </div>
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Özel Komisyon Oranı">
        <div className="d-flex align-items-center gap-5">
          <FormGroup switch className="m-0">
            <Input
              type="switch"
              checked={values.ozel_komisyon_durum}
              onChange={(e) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    ozel_komisyon_durum: e.target.checked,
                  })
                );

                !e.target.checked &&
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      ozel_komisyon_orani: 0,
                    })
                  );

                validateFormChange({
                  ...values,
                  ozel_komisyon_durum: e.target.checked,
                });
              }}
            />
          </FormGroup>
          {values.ozel_komisyon_durum && (
            <InputGroup>
              <InputGroupText>%</InputGroupText>
              <Input
                type="number"
                value={values.ozel_komisyon_orani}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      ozel_komisyon_orani: parseInt(e.target.value),
                    })
                  );

                  validateFormChange({
                    ...values,
                    ozel_komisyon_orani: parseInt(e.target.value),
                  });
                }}
              />
            </InputGroup>
          )}
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Özel Komisyona Bağlı İndirim Oranı">
        <div className="d-flex align-items-center gap-5">
          <FormGroup switch className="m-0">
            <Input
              type="switch"
              checked={values.ozel_komisyona_bagli_indirim_durum}
              onChange={(e) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    ozel_komisyona_bagli_indirim_durum: e.target.checked,
                  })
                );

                !e.target.checked &&
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      ozel_komisyona_bagli_indirim_orani: 0,
                    })
                  );

                validateFormChange({
                  ...values,
                  ozel_komisyona_bagli_indirim_durum: e.target.checked,
                });
              }}
            />
          </FormGroup>
          {values.ozel_komisyona_bagli_indirim_durum && (
            <InputGroup>
              <InputGroupText>%</InputGroupText>
              <Input
                type="number"
                value={values.ozel_komisyona_bagli_indirim_orani}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      ozel_komisyona_bagli_indirim_orani: parseInt(
                        e.target.value
                      ),
                    })
                  );

                  validateFormChange({
                    ...values,
                    ozel_komisyona_bagli_indirim_orani: parseInt(
                      e.target.value
                    ),
                  });
                }}
              />
            </InputGroup>
          )}
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Kickback Oranı">
        <div className="d-flex align-items-center gap-5">
          <InputGroup>
            <InputGroupText>%</InputGroupText>
            <Input
              type="number"
              value={values.kickback_orani}
              onChange={(e) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    kickback_orani: parseInt(e.target.value),
                  })
                );

                validateFormChange({
                  ...values,
                  kickback_orani: parseInt(e.target.value),
                });
              }}
            />
          </InputGroup>
          <div className="d-flex align-items-center gap-1">
            <FormGroup switch className="m-0">
              <Input
                type="switch"
                checked={values.kickback_kumulatif_durum}
                onChange={(e) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kickback_kumulatif_durum: e.target.checked,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kickback_kumulatif_durum: e.target.checked,
                  });
                }}
              />
            </FormGroup>
            <span className="text-nowrap fw-medium me-3">Kümülatif</span>
          </div>
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="İndirim Oranı"
        description="Komisyon Oranından Fazla Olamaz"
      >
        <InputGroup>
          <InputGroupText>%</InputGroupText>
          <Input
            type="number"
            value={values.indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeGeneralPriceSettingsInput({
                  indirim_orani: parseInt(e.target.value),
                })
              );

              validateFormChange({
                ...values,
                indirim_orani: parseInt(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="Göstermelik İndirim Oranı">
        <InputGroup>
          <InputGroupText>%</InputGroupText>
          <Input
            type="number"
            value={values.gostermelik_indirim_orani}
            onChange={(e) => {
              dispatch(
                handleChangeGeneralPriceSettingsInput({
                  gostermelik_indirim_orani: parseInt(e.target.value),
                })
              );

              validateFormChange({
                ...values,
                gostermelik_indirim_orani: parseInt(e.target.value),
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="İptal Garanti Güvencesi">
        <div className="d-flex align-items-center gap-5">
          <FormGroup switch>
            <Input
              type="switch"
              checked={values.iptal_sigortasi_durum === "1"}
              onChange={(e) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    iptal_sigortasi_durum: e.target.checked ? "1" : "0",
                  })
                );

                validateFormChange({
                  ...values,
                  iptal_sigortasi_durum: e.target.checked ? "1" : "0",
                });
              }}
            />
          </FormGroup>
          {values.iptal_sigortasi_durum && (
            <Select
              value={cancelInsuranceOptions.find(
                (item) => parseInt(item.value) === values.iptal_sigortasi_gun
              )}
              onChange={(selectedOption: Option) => {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    iptal_sigortasi_gun: parseInt(selectedOption.value),
                  })
                );

                validateFormChange({
                  ...values,
                  iptal_sigortasi_gun: parseInt(selectedOption.value),
                });
              }}
              options={cancelInsuranceOptions}
              placeholder="İptal Sigortası Günü Seçiniz..."
            />
          )}
        </div>
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default PriceSettings;
