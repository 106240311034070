import { createSlice } from "@reduxjs/toolkit";

import {
  CompetitorAnalysisResponse,
  CompetitorAnalysisFacilityAvailability,
} from "types/adsAndAnalyze";
import { Pagination } from "types/GlobalTypes";

export interface CompetitorAnalysisInitialState {
  competitorAnalysisResponse: CompetitorAnalysisResponse;
  isEb: number;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
  facilityAvailabilities: CompetitorAnalysisFacilityAvailability[];
  availabilityLoading: boolean;
  availabilityError: string;
  updateDescriptionLoading: boolean;
  updateDescriptionError: string;
  updateHotelInfoLoading: boolean;
  updateHotelInfoError: string;
  updateAdsStatusLoading: boolean;
  updateAdsStatusError: string;
}

const initialState: CompetitorAnalysisInitialState = {
  competitorAnalysisResponse: {
    data: [],
    oranlar: {
      oran_tablo_1: {
        toplam_otel: 0,
        yesil_yuzde_oran: "100",
        kirmizi_yuzde_oran: "0",
        yesil_otel: 0,
        kirmizi_otel: 0,
        supheli_fiyatli_otel: 0,
        reklam: 0,
        degerlendirilmemis_otel: 0,
        degerlendirilmis_otel: 0,
        operasyon_yetkili_bilgileri: [],
      },
      oran_tablo_2: {
        toplam_otel: 0,
        yesil_yuzde_oran: "100",
        kirmizi_yuzde_oran: "0",
        yesil_otel: 0,
        kirmizi_otel: 0,
        supheli_fiyatli_otel: 0,
        reklam: 0,
        degerlendirilmemis_otel: 0,
        degerlendirilmis_otel: 0,
        operasyon_yetkili_bilgileri: [],
      },
      oran_tablo_3: {
        toplam_otel: 0,
        yesil_yuzde_oran: "100",
        kirmizi_yuzde_oran: "0",
        yesil_otel: 0,
        kirmizi_otel: 0,
        supheli_fiyatli_otel: 0,
        reklam: 0,
        degerlendirilmemis_otel: 0,
        degerlendirilmis_otel: 0,
        operasyon_yetkili_bilgileri: [],
      },
    },
    bolgeler: [],
    tesis_sorumlulari: {},
    karsilastirma_kodu: [],
    otel_fiyat_statu: {
      "1.Tarih": [],
      "2.Tarih": [],
      "3.Tarih": [],
    },
    Kontenjan_Bilgileri: {
      "1.Tarih": [],
      "2.Tarih": [],
      "3.Tarih": [],
    },
    reklam_statu: [],
    fiyat_saglayicilar: [],
    personel_oran: [],
    odeme_tipleri: [],
    oda_sayi: [],
    personel_cc_sayisi: [],
    ilk_uc_personel_cc_sayisi: [],
  },
  isEb: 0,
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
  facilityAvailabilities: [],
  availabilityLoading: false,
  availabilityError: "",
  updateDescriptionLoading: false,
  updateDescriptionError: "",
  updateHotelInfoLoading: false,
  updateHotelInfoError: "",
  updateAdsStatusLoading: false,
  updateAdsStatusError: "",
};

const competitorAnalysisSlice = createSlice({
  name: "competitorAnalysis",
  initialState,
  reducers: {
    handleSaveCompetitorAnalysis(state, action) {
      const {
        veriler: { data, ...rest },
        ...restData
      } = action.payload;

      state.competitorAnalysisResponse = { ...restData, data };
      state.pagination = rest;
    },
    handleSaveCompetitorAnalysisWithPagination(state, action) {
      const {
        veriler: { data, ...rest },
        ...restData
      } = action.payload;

      const prevData = state.competitorAnalysisResponse.data;

      state.competitorAnalysisResponse = {
        ...restData,
        data: [...prevData, ...data],
      };
      state.pagination = rest;
    },
    handleChangeIsEb(state, action) {
      state.isEb = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    handleSaveFacilityAvailabilities(state, action) {
      state.facilityAvailabilities = action.payload;
    },
    handleUpdateHotelInfo(state, action) {
      const payload = action.payload;

      const index = state.competitorAnalysisResponse.data.findIndex(
        (hotel) => hotel.id === payload.id
      );

      const prevData = [...state.competitorAnalysisResponse.data];

      prevData.splice(index, 1, payload);

      state.competitorAnalysisResponse.data = prevData;
    },
    resetFacilityAvailabilities(state) {
      state.facilityAvailabilities = [];
    },
    setFacilityAvailabilityLoading(state, action) {
      state.availabilityLoading = action.payload;
    },
    setFacilityAvailabilityError(state, action) {
      state.availabilityError = action.payload;
    },
    setUpdateDescriptionLoading(state, action) {
      state.updateDescriptionLoading = action.payload;
    },
    setUpdateDescriptionError(state, action) {
      state.updateDescriptionError = action.payload;
    },
    setUpdateHotelInfoLoading(state, action) {
      state.updateHotelInfoLoading = action.payload;
    },
    setUpdateHotelInfoError(state, action) {
      state.updateHotelInfoError = action.payload;
    },
    setUpdateAdsStatusLoading(state, action) {
      state.updateAdsStatusLoading = action.payload;
    },
    setUpdateAdsStatusError(state, action) {
      state.updateAdsStatusError = action.payload;
    },
  },
});

export const {
  handleSaveCompetitorAnalysis,
  handleSaveCompetitorAnalysisWithPagination,
  handleChangeIsEb,
  setIsLoading,
  setError,
  handleSaveFacilityAvailabilities,
  handleUpdateHotelInfo,
  resetFacilityAvailabilities,
  setFacilityAvailabilityLoading,
  setFacilityAvailabilityError,
  setUpdateDescriptionLoading,
  setUpdateDescriptionError,
  setUpdateHotelInfoLoading,
  setUpdateHotelInfoError,
  setUpdateAdsStatusLoading,
  setUpdateAdsStatusError,
} = competitorAnalysisSlice.actions;

export default competitorAnalysisSlice.reducer;
