import FilterForm from "./FilterForm";
import MonthlySalesTable from "./MonthlySalesTable";

interface MonthlySalesPageProps {
  isLoading: boolean;
  defaultState: {
    grup_id: string;
    date: string;
  };
}

const MonthlySalesPage = ({
  isLoading,
  defaultState,
}: MonthlySalesPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm defaultState={defaultState} />
      <MonthlySalesTable isLoading={isLoading} />
    </div>
  );
};

export default MonthlySalesPage;
