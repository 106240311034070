import { StayLongPayLessActionsPage } from "Components/Custom/DiscountsAndActionsPages";
import useStayLongPayLessActions from "Components/Hooks/discountsAndActions/useStayLongPayLessActions";
import PageContainer from "pages/PageContainer";

const StayLongPayLessActions = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useStayLongPayLessActions();

  return (
    <PageContainer title="Çok Kal Az Öde Aksiyonları">
      <StayLongPayLessActionsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default StayLongPayLessActions;
