import { createSlice } from "@reduxjs/toolkit";
import {
  AddAdditionalPagesFormErrors,
  AddGalleryImageFormErrors,
  AdditionalPage,
  AdditionalPageState,
  GalleryImage,
  UpdateAdditionalPageState,
} from "types/myWebSite";

export interface AdditionalPagesInitialState {
  additionalPages: AdditionalPage[];
  additionalPage : AdditionalPageState;
  additionalPageFormErrors: AddAdditionalPagesFormErrors;
  additionalPageToUpdate: UpdateAdditionalPageState;
  galleryImages: GalleryImage[];
  addGalleryImage: GalleryImage;
  addGalleryImageFormErrors: AddGalleryImageFormErrors;
  isLoading: boolean;
  error: string;
  addAdditionalPageLoading: boolean;
  addAdditionalPageError: string;
  updateAdditionalPageLoading: boolean;
  updateAdditionalPageError: string;
  deleteAdditionalPageLoading: boolean;
  deleteAdditionalPageError: string;
  addGalleryImageLoading: boolean;
  addGalleryImageError: string;
  deleteGalleryImagesLoading: boolean;
  deleteGalleryImagesError: string;
}

export const additionalPagesInitialState: AdditionalPagesInitialState = {
  additionalPages: [],
  additionalPage: {
    id: 0,
    otel_id: 0,
    seflink: "",
    organizasyon_sayfasi_acik: 0,
    organizasyon_link_adi: "",
    organizasyon_vitrin_resmi: "",
    organizasyon_baslik: "",
    organizasyon_aciklama: "",
    organizasyon_kart_baslik: "",
    organizasyon_kart_aciklama: "",
    organizasyon_kart_resim: "",
    add_time: "",
    update_time: "",
    organizasyon_vitrin_resmi_onizleme: "",
    organizasyon_kart_resim_onizleme: ""
  },
  additionalPageFormErrors: {},
  additionalPageToUpdate: {
    id: 0,
    otel_id: 0,
    seflink: "",
    organizasyon_sayfasi_acik: 0,
    organizasyon_link_adi: "",
    organizasyon_vitrin_resmi: "",
    organizasyon_baslik: "",
    organizasyon_aciklama: "",
    organizasyon_kart_baslik: "",
    organizasyon_kart_aciklama: "",
    organizasyon_kart_resim: "",
    add_time: "",
    update_time: "",
    organizasyon_vitrin_resmi_onizleme: "",
    organizasyon_kart_resim_onizleme: ""
  },
  galleryImages: [],
  addGalleryImage: {
    id: 0,
    sayfa_id: 0,
    otel_id: 0,
    resim: "",
    add_time: "",
  },
  addGalleryImageFormErrors: {},
  isLoading: false,
  error: "",
  addAdditionalPageLoading: false,
  addAdditionalPageError: "",
  updateAdditionalPageLoading: false,
  updateAdditionalPageError: "",
  deleteAdditionalPageLoading: false,
  deleteAdditionalPageError: "",
  addGalleryImageLoading: false,
  addGalleryImageError: "",
  deleteGalleryImagesLoading: false,
  deleteGalleryImagesError: "",
};

const additionalPagesSlice = createSlice({
  name: "additionalPages",
  initialState: additionalPagesInitialState,
  reducers: {
    handleSaveAdditionalPages(state, action) {
      state.additionalPages = action.payload;
    },
    handleSaveAdditionalPageToUpdate(state, action) {
      const payload = action.payload as AdditionalPageState;

      const additionalPage : UpdateAdditionalPageState = {
        id: payload.id,
        otel_id: payload.otel_id,
        seflink: payload.seflink,
        organizasyon_sayfasi_acik: payload.organizasyon_sayfasi_acik,
        organizasyon_link_adi: payload.organizasyon_link_adi,
        organizasyon_vitrin_resmi: "",
        organizasyon_baslik: payload.organizasyon_baslik,
        organizasyon_aciklama: payload.organizasyon_aciklama,
        organizasyon_kart_baslik: payload.organizasyon_kart_baslik,
        organizasyon_kart_aciklama: payload.organizasyon_kart_aciklama,
        organizasyon_kart_resim: "",
        add_time: payload.add_time,
        update_time: payload.update_time,
        organizasyon_vitrin_resmi_onizleme: payload.organizasyon_vitrin_resmi.toString(),
        organizasyon_kart_resim_onizleme: payload.organizasyon_kart_resim.toString()
      }

      state.additionalPage = additionalPage;
    },
    handleSaveGalleryImages(state, action) {
      state.galleryImages = action.payload;
    },
    handleSaveGalleryImage(state, action) {
      state.addGalleryImage = action.payload;
    },
    handleChangeAddAdditionalPageInput(state, action) {
      state.additionalPage = {
        ...state.additionalPage,
        ...action.payload,
      };
    },
    handleChangeUpdateAdditionalPageInput(state, action) {
      state.additionalPageToUpdate = {
        ...state.additionalPageToUpdate,
        ...action.payload,
      };
    },
    handleChangeAddGalleryImageInput(state, action) {
      state.addGalleryImage = {
        ...state.addGalleryImage,
        ...action.payload,
      };
    },
    resetAddAdditionalPageFormFields(state) {
      state.additionalPage = {
        id: 0,
        otel_id: 0,
        seflink: "",
        organizasyon_sayfasi_acik: 0,
        organizasyon_link_adi: "",
        organizasyon_vitrin_resmi: "",
        organizasyon_baslik: "",
        organizasyon_aciklama: "",
        organizasyon_kart_baslik: "",
        organizasyon_kart_aciklama: "",
        organizasyon_kart_resim: "",
        add_time: "",
        update_time: "",
        organizasyon_vitrin_resmi_onizleme: "",
        organizasyon_kart_resim_onizleme: ""
      };
    },
    resetUpdateAdditionalPageFormFields(state) {
      state.additionalPageToUpdate = {
        id: 0,
        otel_id: 0,
        seflink: "",
        organizasyon_sayfasi_acik: 0,
        organizasyon_link_adi: "",
        organizasyon_vitrin_resmi: "",
        organizasyon_baslik: "",
        organizasyon_aciklama: "",
        organizasyon_kart_baslik: "",
        organizasyon_kart_aciklama: "",
        organizasyon_kart_resim: "",
        add_time: "",
        update_time: "",
        organizasyon_vitrin_resmi_onizleme: "",
        organizasyon_kart_resim_onizleme: ""
      };
    },
    resetAddGalleryImageFormFields(state) {
      state.addGalleryImage = {
        id: 0,
        sayfa_id: 0,
        otel_id: 0,
        resim: "",
        add_time: "",
      };
    },
    handleAdditionalPageFormErrors(state, action) {
      state.additionalPageFormErrors = action.payload;
    },
    handleAddGalleryImageFormErrors(state, action) {
      state.addGalleryImageFormErrors = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddAdditionalPageLoading: (state, action) => {
      state.addAdditionalPageLoading = action.payload;
    },
    setAddAdditionalPageError: (state, action) => {
      state.addAdditionalPageError = action.payload;
    },
    setUpdateAdditionalPageLoading: (state, action) => {
      state.updateAdditionalPageLoading = action.payload;
    },
    setUpdateAdditionalPageError: (state, action) => {
      state.updateAdditionalPageError = action.payload;
    },
    setDeleteAdditionalPageLoading: (state, action) => {
      state.deleteAdditionalPageLoading = action.payload;
    },
    setDeleteAdditionalPageError: (state, action) => {
      state.deleteAdditionalPageError = action.payload;
    },
    setAddGalleryImageLoading: (state, action) => {
      state.addGalleryImageLoading = action.payload;
    },
    setAddGalleryImageError: (state, action) => {
      state.addGalleryImageError = action.payload;
    },
    setDeleteGalleryImagesLoading: (state, action) => {
      state.deleteGalleryImagesLoading = action.payload;
    },
    setDeleteGalleryImagesError: (state, action) => {
      state.deleteGalleryImagesError = action.payload;
    },
  },
});

export const {
    handleSaveAdditionalPages,
    handleSaveAdditionalPageToUpdate,
    handleSaveGalleryImages,
    handleSaveGalleryImage,
    handleChangeAddAdditionalPageInput,
    handleChangeUpdateAdditionalPageInput,
    handleChangeAddGalleryImageInput,
    resetAddAdditionalPageFormFields,
    resetUpdateAdditionalPageFormFields,
    resetAddGalleryImageFormFields,
    handleAdditionalPageFormErrors,
    handleAddGalleryImageFormErrors,
    setIsLoading,
    setError,
    setAddAdditionalPageLoading,
    setAddAdditionalPageError,
    setUpdateAdditionalPageLoading,
    setUpdateAdditionalPageError,
    setDeleteAdditionalPageLoading,
    setDeleteAdditionalPageError,
    setAddGalleryImageLoading,
    setAddGalleryImageError,
    setDeleteGalleryImagesLoading,
    setDeleteGalleryImagesError,
} = additionalPagesSlice.actions;

export default additionalPagesSlice.reducer;
