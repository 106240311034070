import { CustomLoadingPage } from "Components/Custom/UI";
import AddWebSiteForm from "./AddWebSiteForm";
import ListWebSitesTable from "./ListWebSitesTable";

interface ListWebSitesPageProps {
  isLoading: boolean;
}

const ListWebSitesPage = ({
  isLoading,
}: ListWebSitesPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <AddWebSiteForm />
        <ListWebSitesTable isLoading={isLoading} />
      </div>
    </>
  );
};

export default ListWebSitesPage;
