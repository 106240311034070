import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ReservationPaymentTablePayload } from "types/userOperations";

interface Option {
  value: string;
  label: string;
  selected?: string;
}
interface CollectionSelectorProps {
  options: Option[];
  values: ReservationPaymentTablePayload;
  handleFormChange: (key: string, value: string) => void;
}

const CollectionSelector = ({
  options,
  values,
  handleFormChange,
}: CollectionSelectorProps) => {
  const selectedOption =
    options.find((option) => option.value === values.kk_taksit.toString()) ||
    options.find((option) => option.selected === "selected");
    
  return (
    <CustomFormFieldContainer label="">
      <Select
        options={options}
        value={selectedOption}
        onChange={(selectedOption: Option) => {
          handleFormChange("kk_taksit", selectedOption.value);
        }}
        placeholder=""
      />
    </CustomFormFieldContainer>
  );
};

export default CollectionSelector;
