import React from "react";
import styles from "./CustomRadioButton.module.scss";
import { STOPS } from "enums";

interface CustomRadioButtonProps {
  label: string;
  name: string;
  value: STOPS;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color: string;
  disabled: boolean;
}

const RadioButton = ({
  label,
  name,
  value,
  checked,
  onChange,
  color,
  disabled,
}: CustomRadioButtonProps) => {
  return (
    <label className={styles["custom-radio-button"]}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        data-custom-colors={color}
      />
      <span
        className={styles["custom-radio"]}
        style={{
          borderColor: color,
          backgroundColor: checked ? color : "transparent",
          border: checked ? "none" : `4px solid ${color}`,
          opacity: disabled ? 0.5 : 1,
        }}
      ></span>
      {label}
    </label>
  );
};

export default RadioButton;
