import { useSelector } from "react-redux";
import { DashboardInitialState } from "slices/dashboard/reducer";

const useHandleCards = () => {
  const { dashboardData } = useSelector(
    (state: { Dashboard: DashboardInitialState }) => state.Dashboard
  );


  return {
    dashboardData
  };
};

export default useHandleCards;
