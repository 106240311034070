import { CustomButton } from "Components/Custom/UI";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { InformationTechnologiesInitialState } from "slices/userOperations/informationTechnologies/reducer";
import { updateCollection } from "slices/userOperations/informationTechnologies/thunk";
import {
  ReservationPaymentTablePayload,
  ReservationPaymentTableResponse,
} from "types/userOperations";
import CollectionSelector from "./CollectionSelector";

const useHandleTable = (
  setShowUpdateCollectionForm: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { reservationPayments } = useSelector(
    (state: { InformationTechnologies: InformationTechnologiesInitialState }) =>
      state.InformationTechnologies
  );

  const [formStates, setFormStates] = useState(() => {
    const initialFormStates: Record<number, ReservationPaymentTablePayload> =
      {};
    reservationPayments.forEach((reservation) => {
      initialFormStates[reservation.id] = {
        id: reservation.id,
        kk_taksit: parseInt(reservation.kk_taksit?.[0]?.value || "0", 10),
      };
    });
    return initialFormStates;
  });

  const handleFormChange = (id: number, key: string, value: any) => {
    setFormStates((prevStates: any) => ({
      ...prevStates,
      [id]: {
        ...prevStates[id],
        [key]: value,
      },
    }));
  };

  const handleResetForm = (id: number) => {
    setFormStates((prevStates: any) => ({
      ...prevStates,
      [id]: {
        id,
        kk_taksit: 0,
      },
    }));
    setShowUpdateCollectionForm(false);
  };

  const handleUpdateCollection = (id: number) => {
    const params = formStates[id];
    if (!params) return;

    dispatch(
      updateCollection(params, () => {
        handleResetForm(id);
      })
    );
  };

  const tableData = reservationPayments?.map((reservation) => {
    return {
      id: reservation.id,
      rezervasyon_kodu: reservation.rezervasyon_kodu,
      kk_banka_adi: reservation.kk_banka_adi,
      kk_tutar: reservation.kk_tutar,
      kk_taksit: reservation.kk_taksit,
    };
  });

  const tableColumns = [
    {
      header: "ID",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Rez. No",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Banka Adı",
      accessorKey: "kk_banka_adi",
      enableColumnFilter: false,
    },
    {
      header: "Tutar",
      accessorKey: "kk_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Taksit",
      accessorKey: "kk_taksit",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const reservation: ReservationPaymentTableResponse = cell.row.original;
        const collectionOptions = reservation.kk_taksit.map((item) => ({
          value: item.value,
          label: item.text,
          selected: item.selected,
        }));
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CollectionSelector
                options={collectionOptions}
                values={formStates[reservation.id]}
                handleFormChange={(key, value) =>
                  handleFormChange(reservation.id, key, value)
                }
              />
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const reservationId: number = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomButton
                variant="primary"
                onClick={() => {
                  handleUpdateCollection(reservationId);
                }}
              >
                Güncelle
              </CustomButton>
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
