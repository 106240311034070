import { OutboundCallsListWithNumberPage } from "Components/Custom/ReportsPages";
import useOutboundCallsListWithNumber from "Components/Hooks/reports/useOutboundCallsListWithNumber";
import PageContainer from "pages/PageContainer";

import { Navigate } from "react-router-dom";

const OutboundCallsListWithNumber = () => {
  const { isLoading, navigateRootPage, startDate, endDate } =
    useOutboundCallsListWithNumber();

  if (navigateRootPage) {
    return <Navigate to="/dis-arama-sayilari" replace />;
  }

  return (
    <PageContainer title="Dış Arama Durum (Bugün)">
      <OutboundCallsListWithNumberPage
        isLoading={isLoading}
        defaultDates={{
          startDate,
          endDate,
        }}
      />
    </PageContainer>
  );
};

export default OutboundCallsListWithNumber;
