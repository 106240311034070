import { CustomPieChart } from "Components/Custom/UI/CustomCharts";
import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { ProvinceRankingGraphCardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface ProvinceRankingGraphCardProps {
  values: ProvinceRankingGraphCardData;
  hotel_name: string;
}

const ProvinceRankingGraphCard = ({
  values,
  hotel_name,
}: ProvinceRankingGraphCardProps) => {

  const labels = [values.location, hotel_name];
  const series = [values.sales_of_province_hotels, values.sales_of_the_hotel];
  const seriesColor = [DASHBOARD_COLORS.orange, DASHBOARD_COLORS.purple];

  return (
    <>
      <CustomDashboardCard xl={4} title={`${values.location} Otelleri İçinde`}>
        <div style={{ fontSize: "14px" }}>
          <div className="d-flex m-0 justify-content-between">
            <div style={{ color: DASHBOARD_COLORS.gray }}>
              Son 30 Günde Satılan Toplam Oda Sayısı :{" "}
            </div>
            <CustomPieChart
              chartId="forecast-report-chart"
              series={series}
              labels={labels}
              chartType="donut"
              showLegend={false}
              colors={seriesColor}
              dataLabel={false}
              chartHeight={180}
            />
          </div>

          <div className="d-flex justify-content-start gap-2 align-items-center mb-2">
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: DASHBOARD_COLORS.orange,
              }}
            ></div>
            <div style={{ color: DASHBOARD_COLORS.orange, fontWeight: "500" }}>
              {values.location} Otelleri : {values.sales_of_province_hotels}
            </div>
          </div>
          <div className="d-flex justify-content-start gap-2 align-items-center">
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: DASHBOARD_COLORS.purple,
              }}
            ></div>
            <div style={{ color: DASHBOARD_COLORS.purple, fontWeight: "500" }}>
              {hotel_name} : {values.sales_of_the_hotel}
            </div>
          </div>
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default ProvinceRankingGraphCard;
