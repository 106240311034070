import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { HotelInvoicesInitialState } from "slices/financeAndAccounting/hotelInvoices/reducer";
import { getHotelInvoices } from "slices/financeAndAccounting/hotelInvoices/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useHotelInvoices = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { HotelInvoices: HotelInvoicesInitialState }) => state.HotelInvoices
  );

  const { hotel, loading, error } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  useEffect(() => {
    if (otel_id) {
      const payload = {
        otel_id,
        page: 1,
        per_page: 10,
        search: "",
      };

      dispatch(getHotelInvoices(payload));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    isLoading,
    loading,
    error,
    isValidHotel,
  };
};

export default useHotelInvoices;
