import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";

interface HotelAddressProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}

const HotelAddress = ({
  values,
  validateFormChange,
  formErrors,
}: HotelAddressProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Adresi :"
        error={formErrors.otel_adres}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.otel_adres}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                otel_adres: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              otel_adres: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelAddress;
