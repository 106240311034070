import { createSlice } from "@reduxjs/toolkit";
import {
  ListHotelsWithoutComments,
  ListHotelsWithoutCommentsResponse,
} from "types/comments";
import { Pagination } from "types/GlobalTypes";

/*
    *Commented areas can be used for add comment button if needed
*/

export interface ListHotelsWithoutCommentInitialState {
  hotels: ListHotelsWithoutComments[];
  hotelsResponse: ListHotelsWithoutCommentsResponse;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
  addCommentLoading: boolean;
  addCommentError: string;
}

export const listHotelsWithoutCommentInitialState: ListHotelsWithoutCommentInitialState =
  {
    hotels: [],
    hotelsResponse: {
      data: [],
      sabit_datalar: {},
    },
    pagination: {
      current_page: 0,
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [],
      next_page_url: null,
      path: "",
      per_page: 10,
      prev_page_url: null,
      to: 0,
      total: 0,
    },
    isLoading: false,
    error: "",
    addCommentLoading: false,
    addCommentError: "",
  };

const listHotelsWithoutCommentSlice = createSlice({
  name: "listHotelsWithoutComment",
  initialState: listHotelsWithoutCommentInitialState,
  reducers: {
    handleSaveHotelsWithoutCommentResponse(state, action) {
      const { data, ...rest } = action.payload;
      state.hotelsResponse = action.payload;
      state.hotels = action.payload.data;
      state.pagination = { ...rest };
    },
    // handleAddComment(state, action) {
    //   const updatedComment = action.payload;
    //   state.hotels = state.hotels.map((hotel) =>
    //     hotel.yorum_id === updatedComment.yorum_id ? updatedComment : comment
    //   );
    // },
    // },
    // handleChangeDateInput(state, action) {
    //   state.start_date = action.payload.start_date;
    //   state.end_date = action.payload.end_date;
    // },
    // handleCommenterChange(state, action) {
    //   state.personel = action.payload;
    // },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddCommentLoading(state, action) {
      state.addCommentLoading = action.payload;
    },
    setaddCommentError(state, action) {
      state.addCommentError = action.payload;
    },
    // setApproveCommentLoading(state, action) {
    //   state.approveCommentLoading = action.payload;
    // },
    // setApproveCommentError(state, action) {
    //   state.approveCommentError = action.payload;
    // },
  },
});

export const {
  handleSaveHotelsWithoutCommentResponse,
  setIsLoading,
  setError,
  setAddCommentLoading,
  setaddCommentError,
} = listHotelsWithoutCommentSlice.actions;

export default listHotelsWithoutCommentSlice.reducer;
