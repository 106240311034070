import { useEffect } from "react";

import axios from "axios";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { useGetUser } from "Components/Hooks";
import Cookies from "js-cookie";

interface AuthProtectedProps {
  children: React.ReactNode;
}

const AuthProtected = ({ children }: AuthProtectedProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { userInfo, loading } = useGetUser();

  const { user, token, authorities } = userInfo;

  useEffect(() => {
    if (user && !loading && token && authorities) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else if (!user && loading && !token) {
      localStorage.removeItem("user");
      localStorage.removeItem("auth");
      Cookies.remove("token");
    }
  }, [token, user, loading, authorities, dispatch]);

  /*
    Navigate is un-auth access protected routes via url
  */

  if (!user && loading && !token && !authorities) {
    return <Navigate to={{ pathname: "/giris" }} />;
  }

  const decoded_authorities = atob(authorities);
  const authoritiesArray = JSON.parse(decoded_authorities);

  // * Assuming authoritiesArray includes routes that cannot be entered

  // eslint-disable-next-line no-restricted-globals
  const current_path = location.pathname;
  const isRestricted = authoritiesArray.includes(current_path);

  if (isRestricted) {
    return <Navigate to="/sayfa-bulunamadi" />;
  }

  return <>{children}</>;
};

export default AuthProtected;
