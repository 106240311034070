import { CustomReactTable } from "Components/Custom/UI";
import React from "react";
import useHandleTable from "./useHandleTable";

const FacilityManagersTable = () => {
  const { tableData, tableColumns } = useHandleTable();

  return <CustomReactTable tableData={tableData} tableColumns={tableColumns} />;
};

export default FacilityManagersTable;
