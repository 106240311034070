interface CustomTableLegendProps {
  legend: {
    color: string;
    label: string;
  }[];
}

const CustomTableLegend = ({ legend }: CustomTableLegendProps) => {
  return (
    <div className="d-flex gap-2">
      {legend.map((item, index) => {
        return (
          <div
            key={index}
            className="d-flex flex-wrap align-items-center justify-content-center py-2 px-3 rounded"
            style={{
              backgroundColor: item.color,
            }}
          >
            <span className="fw-medium fs-13 text-nowrap">{item.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CustomTableLegend;
