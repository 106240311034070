import { createSlice } from "@reduxjs/toolkit";

import { Location } from "types/definitions";

export interface ListLocationsInitialState {
  locations: Location[];
  selectedLocations: { key: number; value: Location[] }[];
  selectedLocationOptions: { value: number; label: string }[];
  locationsDontHaveChild: number[];
  isLoading: boolean;
  error: string;
}

export const listLocationsInitialState: ListLocationsInitialState = {
  locations: [],
  selectedLocations: [
    {
      key: -1,
      value: [],
    },
  ],
  selectedLocationOptions: [
    {
      value: 0,
      label: "Üst Bölge Seçiniz.",
    },
  ],
  locationsDontHaveChild: [],
  isLoading: false,
  error: "",
};

export const listLocationsSlice = createSlice({
  name: "listLocations",
  initialState: listLocationsInitialState,
  reducers: {
    handleSaveSelectedLocations(state, action) {
      state.selectedLocations = action.payload;
    },

    handleDeleteLastSelectedLocation(state) {
      state.selectedLocations = state.selectedLocations.slice(
        0,
        state.selectedLocations.length - 1
      );
      state.selectedLocationOptions = state.selectedLocationOptions.slice(
        0,
        state.selectedLocationOptions.length - 1
      );
    },
    handleSaveSelectedLocationOptions(state, action) {
      const { index, value } = action.payload;

      if (state.selectedLocationOptions.length >= index) {
        state.selectedLocationOptions = state.selectedLocationOptions.slice(
          0,
          index
        );
        state.selectedLocationOptions.push(value);
      } else {
        state.selectedLocationOptions.push(value);
      }
    },
    handleSaveLocations(state, action) {
      state.locations = action.payload;
    },
    handleAddToLocationsDontHaveChild(state, action) {
      state.locationsDontHaveChild = [
        ...state.locationsDontHaveChild,
        action.payload,
      ];
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveSelectedLocations,
  handleDeleteLastSelectedLocation,
  handleSaveSelectedLocationOptions,
  handleSaveLocations,
  handleAddToLocationsDontHaveChild,
  setIsLoading,
  setError,
} = listLocationsSlice.actions;

export default listLocationsSlice.reducer;
