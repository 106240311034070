import { FacilityPullImageFormUrlState } from "types/facility";
import { useSelector } from "react-redux";
import { HotelInitialState } from "slices/hotel/reducer";

const useHandlePayload = (
  requestPayload: FormData,
  values: FacilityPullImageFormUrlState
) => {
  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const getPayload = () => {
    requestPayload.append("otel_id", otel_id.toString());
    requestPayload.append("url", values.url);
    requestPayload.append("medya_turu", "genel");

    return requestPayload;
  };

  return { otel_id, getPayload };
};

export default useHandlePayload;
