import FilterForm from "./FilterForm";
import HourlyCallsChart from "./HourlyCallsChart";

interface HourlyCallsProps {
  isLoading: boolean;
}

const HourlyCallsPage = ({ isLoading }: HourlyCallsProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <HourlyCallsChart isLoading={isLoading} />
    </div>
  );
};

export default HourlyCallsPage;
