import { BREAKING_DISCOUNTS, PAYMENT_METHODS } from "enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BreakingDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { BreakingDiscountsInitialState } from "slices/discountsAndActions/breakingDiscounts/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}
interface HotelOption {
  value: number;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const { breakingDiscount, breakingDiscountFormErrors, addDiscountLoading } =
    useSelector(
      (state: { BreakingDiscounts: BreakingDiscountsInitialState }) =>
        state.BreakingDiscounts
    );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<HotelOption[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<HotelOption[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newRoomsOptions: HotelOption[] = hotel.odalar.map((room) => ({
        value: room.id,
        label: room.oda_isim,
      }));

      const newConceptsOptions: HotelOption[] = hotel.pansiyonlar.map(
        (concept) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setRoomsOptions(newRoomsOptions);
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const breakingDiscountsTypesOptions: Option[] = [
    {
      value: BREAKING_DISCOUNTS.BUGUN,
      label: "Bugün",
    },
    {
      value: BREAKING_DISCOUNTS.YARIN,
      label: "Yarın",
    },
    {
      value: BREAKING_DISCOUNTS.BUGUN_YARIN,
      label: "Bugün ve Yarın",
    },
  ];

  const addNewBreakingDiscountRequestPayload: BreakingDiscountRequestPayload = {
    otel_id,
    otel_oda_id: oda_id,
    grup_id: breakingDiscount.grup_id,
    sondakika_tipi: breakingDiscount.sondakika_tipi,
    indirim_orani: Number(breakingDiscount.indirim_orani),
    sondakika_aktiflik: breakingDiscount.sondakika_aktiflik,
    tum_otel_pansiyonlar: breakingDiscount.tum_pansiyonlar,
    otel_pansiyonlar: breakingDiscount.secili_pansiyonlar,
    tum_odeme_tipleri: breakingDiscount.tum_odeme_tipleri,
    odeme_tipleri: breakingDiscount.secili_odeme_tipleri,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    breakingDiscount,
    breakingDiscountsTypesOptions,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    isLoading: addDiscountLoading,
    addNewBreakingDiscountRequestPayload,
    formErrors: breakingDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
