import { CustomFormFieldContainer } from "Components/Custom/UI";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeGuestNumbersInput } from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { SaveRoomPersonPriceRateState } from "types/PricesQuotas";
import { useEffect } from "react";

interface RoomMaxPeopleCountProps {
  values: SaveRoomPersonPriceRateState;
  hotelId: number;
  roomId: number;
}

const RoomMaxPeopleCount = ({
  values,
  hotelId,
  roomId,
}: RoomMaxPeopleCountProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (
      values.uygulanabilecek_max_konuk - values.uygulanabilecek_max_yetiskin >
      4
    ) {
      dispatch(
        handleChangeGuestNumbersInput({
          uygulanabilecek_max_yetiskin: values.uygulanabilecek_max_konuk - 4,
        })
      );
    } else if (
      values.uygulanabilecek_max_yetiskin > values.uygulanabilecek_max_konuk
    ) {
      dispatch(
        handleChangeGuestNumbersInput({
          uygulanabilecek_max_yetiskin: values.uygulanabilecek_max_konuk,
        })
      );
    }
  }, [
    values.uygulanabilecek_max_konuk,
    values.uygulanabilecek_max_yetiskin,
    dispatch,
  ]);

  const handleGuestChange = (value: number) => {
    if (value - values.uygulanabilecek_max_yetiskin > 4) {
      dispatch(
        handleChangeGuestNumbersInput({
          uygulanabilecek_max_yetiskin: value - 4,
        })
      );
    }
    dispatch(
      handleChangeGuestNumbersInput({ uygulanabilecek_max_konuk: value })
    );
  };

  const handleAdultChange = (value: number) => {
    if (value > values.uygulanabilecek_max_konuk) {
      dispatch(
        handleChangeGuestNumbersInput({ uygulanabilecek_max_konuk: value })
      );
    } else if (values.uygulanabilecek_max_konuk - value > 4) {
      dispatch(
        handleChangeGuestNumbersInput({ uygulanabilecek_max_konuk: value + 4 })
      );
    }
    dispatch(
      handleChangeGuestNumbersInput({ uygulanabilecek_max_yetiskin: value })
    );
  };

  return (
    <>
      <CustomFormFieldContainer label="Bu odada uyuyabilecek maksimum yetişkin sayısı">
        <CustomNumberSelector
          min={1}
          max={8}
          value={values.uygulanabilecek_max_yetiskin}
          onChange={(value) => {
            dispatch(
              handleChangeGuestNumbersInput({
                uygulanabilecek_max_yetiskin: value,
              })
            );
            handleAdultChange(value);
          }}
          disabled={true}
        />
      </CustomFormFieldContainer>
      <div>
        <CustomFormFieldContainer
          label="Bu odada uyuyabilecek maksimum konuk sayısı (çocuklar dahil)
"
        >
          <CustomNumberSelector
            min={1}
            max={8}
            value={values.uygulanabilecek_max_konuk}
            onChange={(value) => {
              dispatch(
                handleChangeGuestNumbersInput({
                  uygulanabilecek_max_konuk: value,
                })
              );
              handleGuestChange(value);
            }}
            disabled={true}
          />
        </CustomFormFieldContainer>
      </div>
    </>
  );
};

export default RoomMaxPeopleCount;
