import { useState } from "react";
import axios, { AxiosResponse } from "axios";

import { handleErrors } from "helpers/axios_helpers";

interface HttpRequestConfig {
  method: "get" | "post" | "put" | "delete";
  url: string;
  data?: any;
}

interface HttpRequest {
  successCallback?: (data: any) => void;
  errorCallback?: (message: string) => void;
  config: HttpRequestConfig;
}

const useHttpRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<any>();

  const httpRequest = async ({
    config,
    successCallback = () => {},
    errorCallback = () => {},
  }: HttpRequest) => {
    setIsLoading(true);
    setError("");
    setData("");

    try {
      setIsLoading(true);
      const response: AxiosResponse = await axios(config);
      setData(response.data || response);
      successCallback(response.data || response);
    } catch (err) {
      handleErrors(err, (message: string) => {
        setError(message);
        errorCallback(message);
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, data, httpRequest };
};

export default useHttpRequest;
