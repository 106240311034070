import { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
} from "reactstrap";
import {
  CreateRoomAddedRoom,
  EnterReservationFormValues,
  InvoiceInfoAndOtherFieldsFormValues,
  ReservationPaymentInfo,
} from "types/reservation";
import useHandlePayload from "./useHandlePayload";
import { CustomButton, CustomModal } from "Components/Custom/UI";
import moment from "moment";

interface SaveReservationButtonProps {
  formValues: EnterReservationFormValues;
  paymentType: string;
  addedRoomsInfo: CreateRoomAddedRoom[];
  reservationPaymentInfo: ReservationPaymentInfo;
  invoiceInfoFormValues: InvoiceInfoAndOtherFieldsFormValues;
}

interface ApprovalTextProps {
  hotelName: string;
  checkInDate: string;
  checkOutDate: string;
  paymentType: string;
  rooms: {
    paymentType: string;
    roomNameAndConcept: string;
    adultCount: number;
    childCount: number;
  }[];
  totalAmount: number;
  partialAmount: number;
  partialPaymentDate: string;
  entryTime: string;
  kaporaliPayment: number;
}

const ApprovalText = ({
  hotelName,
  checkInDate,
  checkOutDate,
  paymentType,
  rooms,
  partialAmount,
  partialPaymentDate,
  totalAmount,
  entryTime,
  kaporaliPayment,
}: ApprovalTextProps) => {
  const roomText = rooms.map((room, index) => {
    const type =
      room.paymentType === "normal" ||
      room.paymentType === "kismi" ||
      room.paymentType === "kapida"
        ? ""
        : room.paymentType === "iptal_edilemez"
        ? "İptal Edilemez"
        : "";

    return `${type} ${index + 1}. Oda ${room.roomNameAndConcept} konseptinde, ${
      room.adultCount
    } yetişkin${room.childCount ? " " + room.childCount + " çocuk;" : ";"} `;
  });

  return (
    <div className="d-flex flex-column gap-3 py-3 px-4">
      <span>
        <strong>{hotelName}</strong> otelinde,{" "}
        <strong>{moment(checkInDate).format("DD MMMM YYYY")}</strong> giriş,
        <strong> {moment(checkOutDate).format("DD MMMM YYYY")}</strong> çıkış
        olarak,
      </span>
      <span>{roomText} konaklamanız için;</span>
      {paymentType === "kaporali" && (
        <span>
          Toplam tutar <strong>{totalAmount} TL</strong>'nin{" "}
          <strong>{kaporaliPayment} TL</strong>'lik kısmını tahsil ederek
          rezervasyonunuzu oluşturuyorum.
        </span>
      )}
      {paymentType === "kismi" ? (
        <span>
          Toplam tutar <strong>{totalAmount} TL</strong>'nin{" "}
          <strong>{partialAmount} TL</strong>'lik kısmını tahsil ederek
          rezervasyonunuzu oluşturuyorum.
        </span>
      ) : !["kapida", "kaporali"].includes(paymentType) ? (
        <span>
          Toplam tutar <strong>{totalAmount} TL</strong>'yi tahsil ederek
          rezervasyonunuzu oluşturuyorum.
        </span>
      ) : (
        <></>
      )}
      {paymentType === "kismi" && (
        <span>
          Kalan tutarı {partialPaymentDate} tarihine kadar ödemeniz
          gerekmektedir.
        </span>
      )}

      {paymentType === "kapida" && (
        <span>
          Otelde ödeme şeklinde <strong>{totalAmount} TL</strong> olarak
          rezervasyonunuzu oluşturuyorum.
        </span>
      )}

      <span>
        Ayrıca otele giriş belgenizi cep telefonunuza SMS olarak gönderiyorum.
        Onaylıyor musunuz?
      </span>
      {paymentType === "iptal_edilemez" && (
        <span>
          İPTAL EDİLEMEZ ODALARDA HERHANGİ BİR İPTAL TALEBİNİZDE İADE YA DA
          TARİH DEĞİŞİKLİĞİ YAPILMAMAKTADIR
        </span>
      )}
      {(paymentType === "kismi" || paymentType === "kaporali") && (
        <span>
          Ön ödemeli rezervasyonlarda, rezervasyonun iptali durumunda ön ödeme
          tutarı iade edilmemektedir.
        </span>
      )}
      {paymentType === "kapida" && (
        <span>
          Odayı size özel ayırdığımız için rezervasyonun iptali durumunda lütfen
          bize bilgi veriniz.
        </span>
      )}
      <span>Otele giriş saati {entryTime}'dır bilginize.</span>
    </div>
  );
};

const SaveReservationButton = (props: SaveReservationButtonProps) => {
  const [checked, setChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  const { onSaveReservation, onValidatePayload } = useHandlePayload(props);

  const room = props.addedRoomsInfo[0];

  const roomsInfo = props.addedRoomsInfo.map((r) => {
    return {
      paymentType: r.odeme_tipi,
      roomNameAndConcept: r.oda_adi + " " + r.pansiyon_adi,
      adultCount: r.yetiskin_sayisi,
      childCount: r.cocuk_sayisi,
    };
  });

  const totalAmount = Math.ceil(
    props.addedRoomsInfo.reduce((acc, room) => acc + room.fiyat, 0)
  );

  const kismiPayment = Math.ceil(
    (totalAmount * props.formValues.kismi_odeme_orani) / 100
  );
  const kismiPaymentDate = moment(props.formValues.kismi_odeme_tarihi).format(
    "DD MMMM YYYY"
  );

  const kaporaliPayment =
    totalAmount -
    Math.ceil(
      props.addedRoomsInfo.reduce(
        (acc, room) => acc + room.fiyatlar.maliyet_tutar,
        0
      )
    );

  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const toggleCheck = () => setIsCheckOpen((prev) => !prev);

  return (
    <div className="d-flex flex-column gap-2">
      <FormGroup check>
        <Input
          id="saveReservationButton"
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />

        <Label for="saveReservationButton" className="fs-14 theme-text-danger">
          Fatura Bilgisi Soruldu Mu?
        </Label>
      </FormGroup>
      {room && room.odeme_tipi === "kismi" ? (
        <span className="fs-14 theme-text-danger fw-bold">
          Önemli Bilgi: Müşteriye KISMİ Ödemeli rezervasyonlarda rezervasyon
          iptalinde ve/veya konaklama yapılmaması durumunda Kısmi Ödeme
          Tutarının iade edilmeyeceği bilgisi verilmelidir!!!!
        </span>
      ) : (
        <></>
      )}
      <div>
        <Button
          color="primary"
          onClick={
            checked
              ? () => {
                  const isValid = onValidatePayload();
                  if (isValid) toggle();
                }
              : () => {
                  const isValid = onValidatePayload();
                  if (isValid) toggleCheck();
                }
          }
        >
          Onay Metnini Oku
        </Button>
      </div>
      <CustomModal
        isOpen={isCheckOpen}
        title="Uyarı!"
        message="'Fatura Bilgisi Soruldu Mu?' onay kutucuğunu işaretlemeniz gerekmektedir!"
        handleToggle={toggleCheck}
        approveCallback={toggleCheck}
        approveButtonLabel="Tamam"
      />
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Onay Metni</ModalHeader>
        {room && (
          <ApprovalText
            hotelName={room.otel_adi}
            checkInDate={room.giris_tarihi}
            checkOutDate={room.cikis_tarihi}
            paymentType={room.odeme_tipi}
            rooms={roomsInfo}
            partialAmount={kismiPayment}
            partialPaymentDate={kismiPaymentDate}
            totalAmount={totalAmount}
            entryTime="14:00"
            kaporaliPayment={kaporaliPayment}
          />
        )}
        <div className="d-flex gap-4 py-2 px-2">
          <CustomButton classes="flex-grow-1" variant="danger" onClick={toggle}>
            Vazgeç
          </CustomButton>
          <CustomButton
            classes="flex-grow-1"
            variant="success"
            onClick={() => {
              toggle();
              onSaveReservation();
            }}
          >
            Rezervasyonu Kaydet
          </CustomButton>
        </div>
      </Modal>
    </div>
  );
};

export default SaveReservationButton;
