import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveContractProcedures,
  handleSaveHotelContractInvoice,
  handleSaveHotelContractProcedures,
  handleSaveHotelContractRules,
  resetHotelContractInvoice,
  setIsLoading,
  setError,
  setHotelContractRuleLoading,
  setHotelContractRuleError,
  setHotelContractInvoiceLoading,
  setHotelContractInvoiceError,
  setAddContractRuleLoading,
  setAddContractRuleError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_CONTRACT_PROCEDURES,
  GET_CONTRACT_INVOICE,
  GET_HOTEL_CONTRACT_PROCEDURES,
  GET_HOTEL_CONTRACT_RULES,
  ADD_HOTEL_CONTRACT_RULE,
  DELETE_HOTEL_CONTRACT_RULE,
} from "helpers/url_helper";

import { Dispatch } from "redux";

import { AddContractRuleRequestPayload } from "types/financeAndAccounting";

interface RequestPayload {
  otel_id: number;
  per_page: number;
  page: number;
  search: string;
}

interface GetHotelContractRulesRequestPayload {
  otel_id: number;
  kontrat_id: number;
}

type SuccessCallback = () => void;

export const getContractProcedures =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_CONTRACT_PROCEDURES, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveContractProcedures(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getHotelContractProcedures =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_HOTEL_CONTRACT_PROCEDURES, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveHotelContractProcedures(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getHotelContractRules =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setHotelContractRuleLoading(true));
      const response = await axios.get(GET_HOTEL_CONTRACT_RULES + otel_id);

      const res = response.data || response;

      dispatch(handleSaveHotelContractRules(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setHotelContractRuleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setHotelContractRuleLoading(false));
    }
  };

export const getHotelContractInvoice =
  (payload: GetHotelContractRulesRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(resetHotelContractInvoice());
      dispatch(setHotelContractInvoiceError(""));
      dispatch(setHotelContractInvoiceLoading(true));
      const response = await axios.post(GET_CONTRACT_INVOICE, payload);

      const res = response.data || response;

      dispatch(handleSaveHotelContractInvoice(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setHotelContractInvoiceError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setHotelContractInvoiceLoading(false));
    }
  };

export const deleteHotelContractRule =
  (kural_id: number, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setHotelContractRuleLoading(true));
      await axios.post(DELETE_HOTEL_CONTRACT_RULE, { kural_id });

      dispatch(setToastSuccess("Kural pasif hale getirildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setHotelContractRuleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setHotelContractRuleLoading(false));
    }
  };

export const addHotelContractRule =
  (payload: AddContractRuleRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddContractRuleLoading(true));
      await axios.post(ADD_HOTEL_CONTRACT_RULE, payload);

      dispatch(setToastSuccess("Kural başarıyla eklendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddContractRuleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddContractRuleLoading(false));
    }
  };
