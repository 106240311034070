import useHandleTable from "./useHandleTable";
import useHandlePagination from "./useHandlePagination";
import { CustomReactTable } from "Components/Custom/UI";

interface FacilityCallsTableProps {
  loading: boolean;
}

const FacilityCallsTable = ({ loading }: FacilityCallsTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomReactTable
      pagination={true}
      loading={loading}
      tableData={tableData}
      tableColumns={tableColumns}
      onSearch={(searchValue) => {
        handleChange("search", searchValue);
      }}
      onPageSizeChange={(pageSize) => {
        handleChange("per_page", pageSize);
      }}
      paginationBottomProps={paginationBottomProps}
    />
  );
};

export default FacilityCallsTable;
