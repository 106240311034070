import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/definitions/defineCurrency/reducer";
import {
  DefineCurrencyFormErrors,
  DefineCurrencyState,
} from "types/definitions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input, InputGroup, InputGroupText } from "reactstrap";

interface CurrencyFieldsProps {
  values: DefineCurrencyState;
  formErrors: DefineCurrencyFormErrors;
  validateFormChange: (values: DefineCurrencyState) => void;
}

const CurrencyFields = ({
  values,
  validateFormChange,
  formErrors,
}: CurrencyFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer label="USD" error={formErrors.usd}>
        <InputGroup>
          <InputGroupText>₺</InputGroupText>
          <Input
            type="number"
            value={values.usd}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  ...values,
                  usd: e.target.value,
                })
              );
              validateFormChange({
                ...values,
                usd: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer label="EURO" error={formErrors.euro}>
        <InputGroup>
          <InputGroupText>₺</InputGroupText>
          <Input
            type="number"
            value={values.euro}
            onChange={(e) => {
              dispatch(
                handleChangeInput({
                  ...values,
                  euro: e.target.value,
                })
              );
              validateFormChange({
                ...values,
                euro: e.target.value,
              });
            }}
          />
        </InputGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default CurrencyFields;
