import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/updateUser/reducer";
import { UpdateUserFormErrors, UpdateUserPayload } from "types/userOperations";

interface UserNameInputProps {
  values: UpdateUserPayload;
  formErrors: UpdateUserFormErrors;
  validateFormChange: (values: UpdateUserPayload) => void;
}

const UserNameInput = ({
  values,
  formErrors,
  validateFormChange,
}: UserNameInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Ad Soyad"
        error={formErrors.ad_soyad}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.ad_soyad}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ad_soyad: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ad_soyad: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>     
    </>
  );
};

export default UserNameInput;
