import { createSlice } from "@reduxjs/toolkit";
import { Hotel, Room, Concept } from "types/Hotel";

export interface HotelInitialState {
  hotel: Hotel;
  passiveRooms: Room[];
  passiveConcepts: Concept[];
  error: string;
  success: boolean;
  loading: boolean;
}

export const initialState: HotelInitialState = {
  hotel: {
    otel_id: 0,
    otel_adi: "",
    currency: "",
    fiyat_listesi_goster: "",
    odalar: [],
    pansiyonlar: [],
    inet_kodu: {
      otel_id: 0,
      inet_kodu: "",
    },
    provider: "",
  },
  passiveRooms: [],
  passiveConcepts: [],
  error: "",
  success: false,
  loading: false,
};

const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    getHotelError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getHotelSuccess(state, action) {
      const payload = action.payload as Hotel;
      const activeHotel: Hotel = {
        ...payload,
        odalar: payload.odalar.filter((item) => item.sil_durum === "0"),
        pansiyonlar: payload.pansiyonlar.filter((item) => item.durum === "1"),
      };
      state.hotel = activeHotel;
      state.success = true;
    },
    handleSavePassiveRooms(state, action) {
      const payload = action.payload as Hotel;
      const passiveRooms = payload.odalar.filter(
        (item) => item.sil_durum === "1"
      );
      state.passiveRooms = passiveRooms;
    },
    handleSavePassiveConcepts(state, action) {
      const payload = action.payload as Hotel;
      const passiveConcepts = payload.pansiyonlar.filter(
        (item) => item.durum === "0"
      );
      state.passiveConcepts = passiveConcepts;
    },
    resetHotel(state) {
      state.hotel = {
        otel_id: 0,
        otel_adi: "",
        currency: "",
        fiyat_listesi_goster: "",
        odalar: [],
        pansiyonlar: [],
        inet_kodu: {
          otel_id: 0,
          inet_kodu: "",
        },
        provider: "",
      };
      state.passiveRooms = [];
      state.success = false;
      state.error = "";
      state.loading = false;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  getHotelError,
  getHotelSuccess,
  handleSavePassiveRooms,
  handleSavePassiveConcepts,
  resetHotel,
  setIsLoading,
} = hotelSlice.actions;

export default hotelSlice.reducer;
