import { createSlice } from "@reduxjs/toolkit";
import { BREAKING_DISCOUNTS } from "enums";

import {
  BreakingDiscount,
  BreakingDiscountFormErrors,
  BreakingDiscountState,
} from "types/DiscountsAndActions";

export interface BreakingDiscountsInitialState {
  breakingDiscounts: BreakingDiscount[];
  breakingDiscount: BreakingDiscountState;
  breakingDiscountFormErrors: BreakingDiscountFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addDiscountLoading: boolean;
  addDiscountError: string;
}

export const initialState: BreakingDiscountsInitialState = {
  breakingDiscounts: [],
  breakingDiscount: {
    otel_id: 0,
    otel_oda_id: 0,
    grup_id: 0,
    sondakika_tipi: BREAKING_DISCOUNTS.BUGUN,
    indirim_orani: 0,
    sondakika_aktiflik: "1",
    tum_pansiyonlar: 1,
    secili_pansiyonlar: [],
    tum_odeme_tipleri: "1",
    secili_odeme_tipleri: [],
  },
  breakingDiscountFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addDiscountLoading: false,
  addDiscountError: "",
};

const breakingDiscountsSlice = createSlice({
  name: "breakingDiscounts",
  initialState,
  reducers: {
    handleSaveBreakingDiscounts: (state, action) => {
      state.breakingDiscounts = action.payload;
    },
    handleSaveBreakingDiscount: (state, action) => {
      state.breakingDiscount =
        action.payload.length > 0
          ? {
              otel_id: action.payload[0].otel_id,
              otel_oda_id: action.payload[0].otel_oda_id,
              grup_id: action.payload[0].grup_id,
              sondakika_tipi: action.payload[0].sondakika_tipi,
              indirim_orani: Number(action.payload[0].indirim_orani),
              sondakika_aktiflik: action.payload[0].sondakika_aktiflik,
              tum_pansiyonlar: Number(action.payload[0].tum_otel_pansiyonlar),
              secili_pansiyonlar: action.payload[0].otel_pansiyonlar,
              tum_odeme_tipleri: action.payload[0].tum_odeme_tipleri,
              secili_odeme_tipleri: action.payload[0].odeme_tipleri,
            }
          : {
              otel_id: 0,
              otel_oda_id: 0,
              grup_id: 0,
              sondakika_tipi: BREAKING_DISCOUNTS.BUGUN,
              indirim_orani: 0,
              sondakika_aktiflik: "1",
              tum_pansiyonlar: 1,
              secili_pansiyonlar: [],
              tum_odeme_tipleri: "1",
              secili_odeme_tipleri: [],
            };
    },
    handleChangeInput(state, action) {
      state.breakingDiscount = {
        ...state.breakingDiscount,
        ...action.payload,
      };
    },
    handleAddBreakingDiscountFormErrors: (state, action) => {
      state.breakingDiscountFormErrors = action.payload;
    },
    handleSelectRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddDiscountLoading(state, action) {
      state.addDiscountLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addDiscountError = action.payload;
    },
    resetAll(state) {
      state.breakingDiscounts = [];
      state.breakingDiscount = {
        otel_id: 0,
        otel_oda_id: 0,
        grup_id: 0,
        sondakika_tipi: BREAKING_DISCOUNTS.BUGUN,
        indirim_orani: 0,
        sondakika_aktiflik: "1",
        tum_pansiyonlar: 1,
        secili_pansiyonlar: [],
        tum_odeme_tipleri: "1",
        secili_odeme_tipleri: [],
      };
      state.breakingDiscountFormErrors = {};
      state.selectedRoom = 0;
      state.isLoading = false;
      state.error = "";
    },
  },
});

export const {
  handleSaveBreakingDiscounts,
  handleSaveBreakingDiscount,
  handleChangeInput,
  handleAddBreakingDiscountFormErrors,
  handleSelectRoom,
  setIsLoading,
  setError,
  setAddDiscountLoading,
  setAddDiscountError,
  resetAll,
} = breakingDiscountsSlice.actions;

export default breakingDiscountsSlice.reducer;
