import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { CustomFormContainer, CustomLoadingPage } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import UserFields from "./UserFields";
import UpdateUserButton from "./UpdateUserButton";
import AddNewUserButton from "./AddNewUserButton";
import SelectMailSelector from "./SelectMailSelector";
import ResetPasswordButton from "./ResetPasswordButton";

interface FacilityUsersPageProps {
  isLoading: boolean;
}

const FacilityUsersPage = ({ isLoading }: FacilityUsersPageProps) => {
  const {
    updateFacilityUser,
    selectedMail,
    userMailsOptions,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addFacilityUserRequestPayload,
    updateFacilityUserRequestPayload,
    needUserMail,
    username,
    addUserLoading,
    updateUserLoading,
    resetPasswordLoading,
  } = useHandleForm();

  const { id } = updateFacilityUser;

  if (needUserMail) {
    return (
      <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center bg-transparent">
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
          <p className="text-center fs-20">
            Tesis kullanıcısı eklemek için önce tesis yetkilisi ekleyin.
          </p>
          <a href="/tesis-yetkilileri" className="btn btn-primary">
            Tesis Yetkilileri Sayfasına Git
          </a>
        </div>
      </div>
    );
  }
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <CustomFormContainer
          title={id ? "Kullanıcı Güncelle" : "Kullanıcı Ekle"}
        >
          <UserFields
            values={updateFacilityUser}
            formErrors={formErrors}
            validateFormChange={validateFormChange}
          />
          {id ? (
            <UpdateUserButton
              values={updateFacilityUser}
              validateForm={validateForm}
              handleSubmitted={handleSubmitted}
              requestPayload={updateFacilityUserRequestPayload}
              isLoading={updateUserLoading}
            />
          ) : (
            <>
              <SelectMailSelector
                values={updateFacilityUser}
                formErrors={formErrors}
                validateFormChange={validateFormChange}
                selectedMail={selectedMail}
                userMailsOptions={userMailsOptions}
              />
              <AddNewUserButton
                values={updateFacilityUser}
                validateForm={validateForm}
                handleSubmitted={handleSubmitted}
                requestPayload={addFacilityUserRequestPayload}
                isLoading={addUserLoading}
              />
            </>
          )}
        </CustomFormContainer>
        {id ? (
          <CustomFormContainer title="Yeni Şifre Gönder">
            <SelectMailSelector
              selectedMail={selectedMail}
              userMailsOptions={userMailsOptions}
            />
            <ResetPasswordButton
              selectedMail={selectedMail}
              username={username}
              isLoading={resetPasswordLoading}
            />
          </CustomFormContainer>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withPageNeedsHotel(FacilityUsersPage);
