import { useSelector } from "react-redux";
import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";

const useHandleRates = () => {
  const { competitorAnalysisResponse } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const rates = competitorAnalysisResponse.oranlar;

  const firstRates = rates.oran_tablo_1;
  const secondRates = rates.oran_tablo_2;
  const thirdRates = rates.oran_tablo_3;

  return { firstRates, secondRates, thirdRates };
};

export default useHandleRates;
