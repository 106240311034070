import { Hotel } from "types/PricesQuotas";
import SelectAllAvailabilitiesInput from "./SelectAllAvailabilitiesInput";
import RowContainer from "Components/Custom/PricesAndQuotaPages/CommonContainers/RowContainer";
import AvailabilitiesLegend from "../../AvailabilitiesLegend";

interface SelectAllAvailabilitiesProps {
  data: Hotel;
  onSelect: (key: number, value: boolean) => void;
}

const SelectAllAvailabilities = ({
  data,
  onSelect,
}: SelectAllAvailabilitiesProps) => {
  const roomsIds = data.odalar.map((room) => room.id);

  return (
    <RowContainer title={<AvailabilitiesLegend />} index={0}>
      {data.odalar[0].pansiyonlar
        .filter((c) => c.durum === "1")
        .map((concept, index) => {
          return (
            <div key={index} className="d-flex" style={{ width: "200px" }}>
              <SelectAllAvailabilitiesInput
                roomsIds={roomsIds}
                conceptId={concept.id}
                onSelect={onSelect}
              />
            </div>
          );
        })}
    </RowContainer>
  );
};

export default SelectAllAvailabilities;
