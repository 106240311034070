import { useSelector } from "react-redux";

import { RiskyHotelsInitialState } from "slices/reports/riskyHotels/reducer";

const useHandleTable = () => {
  const { riskyHotels } = useSelector(
    (state: { RiskyHotels: RiskyHotelsInitialState }) => state.RiskyHotels
  );

  const tableData = riskyHotels?.map((riskyHotel) => ({
    "Otel ID": riskyHotel.otel_id,
    "Otel Adı": riskyHotel.otel_adi,
    "Ödenmiş Tutar": riskyHotel.toplam_risk_tutar,
    "Ödenecek Tutar": riskyHotel.toplam_odenecek_tutar,
    "Satış Tutarı": riskyHotel.toplam_odame_kalan_tutar,
    "Kısmi Satış Tutarı": riskyHotel.toplam_kismi_odeme_maliyet_tutar,
    "Gerçekleşen Satış Tutarı": riskyHotel.toplam_odame_gercek_kalan_tutar,
    "Kalan Tutar": riskyHotel.toplam_risk_kalan_tutar,
  }));

  const tableColumns = [
    {
      header: "Otel ID",
      accessorKey: "Otel ID",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Ödenmiş Tutar",
      accessorKey: "Ödenmiş Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Ödenecek Tutar",
      accessorKey: "Ödenecek Tutar",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tutarı",
      accessorKey: "Satış Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Kısmi Satış Tutarı",
      accessorKey: "Kısmi Satış Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Gerçekleşen Satış Tutarı",
      accessorKey: "Gerçekleşen Satış Tutarı",
      enableColumnFilter: false,
    },
    {
      header: "Kalan Tutar",
      accessorKey: "Kalan Tutar",
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
