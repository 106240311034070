import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { Spinner } from "reactstrap";
import ChildInformationForm from "./ChildInformationPageForm";

interface ChildInformationPageProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
  loading: boolean;
}

const ChildInformationPage = (props: ChildInformationPageProps) => {
  const { loading } = props;

  return (
    <>
      {loading ? (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center bg-transparent">
          <Spinner animation="border" color="primary" />
        </div>
      ) : (
        <>
          <ChildInformationForm {...props} />
        </>
      )}
    </>
  );
};

export default withPageNeedsHotel(ChildInformationPage);
