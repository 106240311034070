import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";

import { handleChangeInput } from "slices/discountsAndActions/stayLongPayLessActions/reducer";
import {
  StayLongPayLessActionFormErrors,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface StayAndPayNightsSelectorProps {
  values: StayLongPayLessActionState;
  formErrors: StayLongPayLessActionFormErrors;
  validateFormChange: (values: StayLongPayLessActionState) => void;
}

const StayAndPayNightsSelector = ({
  values,
  validateFormChange,
  formErrors,
}: StayAndPayNightsSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Kalınan Gece Sayısı"
        error={formErrors.kal_gece}
      >
        <CustomNumberSelector
          value={values.kal_gun_sayisi}
          onChange={(value) => {
            dispatch(handleChangeInput({ kal_gun_sayisi: value }));
            validateFormChange({ ...values, kal_gun_sayisi: value });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Ödenecek Gece Sayısı"
        error={formErrors.ode_gece}
      >
        <CustomNumberSelector
          value={values.ode_gun_sayisi}
          onChange={(value) => {
            dispatch(handleChangeInput({ ode_gun_sayisi: value }));
            validateFormChange({ ...values, ode_gun_sayisi: value });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default StayAndPayNightsSelector;
