import { Col, Label, Row } from "reactstrap";

import { CustomAvailabilityRadioInput } from "Components/Custom/UI";
import { STOPS } from "enums";

interface AvailabilityFieldProps {
  values: any;
  onChange: (name: string, availability: STOPS) => void;
}

const AvailabilityField = ({ values, onChange }: AvailabilityFieldProps) => {
  return (
    <Row className="d-flex justify-content-between align-items-baseline">
      <Col xs={6}>
        <Label htmlFor="musaitlik">Müsaitlik</Label>
      </Col>
      <Col xs={6}>
        <div className="d-flex gap-1">
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.SATISA_ACIK}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={true}
            notAllowed={false}
          />
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.SOR_SAT}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={true}
            notAllowed={false}
          />
          <CustomAvailabilityRadioInput
            name="musaitlik"
            value={STOPS.STOPLU}
            availability={values["musaitlik"]}
            onChange={(availability: STOPS) =>
              onChange("musaitlik", availability)
            }
            disabled={true}
            notAllowed={false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AvailabilityField;
