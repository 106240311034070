import UserIPsTable from "./UserIPsTable";


interface UserIPsPageProps {
  isLoading: boolean;
}

const UserIPsPage = ({ isLoading }: UserIPsPageProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <UserIPsTable isLoading={isLoading}/>
      </div>
    </>
  );
};

export default UserIPsPage;
