import { MobileDiscountState } from "types/DiscountsAndActions";

import { FormGroup, Input, Label } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/discountsAndActions/mobileDiscounts/reducer";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface DiscountSwitchesProps {
  values: MobileDiscountState;
}

const DiscountSwitches = ({ values }: DiscountSwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Maliyete Uygula" tooltipId="applyToCost">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.maliyete_uygula === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  maliyete_uygula: values.maliyete_uygula === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Birlikte Kullanılabilecek İndirimler"
        tooltipId="discountsCanBeUsedTogether"
      >
        <div className="d-flex flex-column gap-3 border p-2 bg-white rounded">
          <FormGroup switch>
            <Input
              id="eb_birlikte"
              type="switch"
              checked={values.eb_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    eb_birlikte: values.eb_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="eb_birlikte">Erken Rezervasyon</Label>
          </FormGroup>
          <FormGroup switch>
            <Input
              id="kalode_birlikte"
              type="switch"
              checked={values.kalode_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    kalode_birlikte: values.kalode_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="kalode_birlikte">Kal-Öde</Label>
          </FormGroup>
          <FormGroup switch>
            <Input
              id="diger_birlikte"
              type="switch"
              checked={values.diger_birlikte === "1"}
              onChange={() => {
                dispatch(
                  handleChangeInput({
                    diger_birlikte: values.diger_birlikte === "1" ? "0" : "1",
                  })
                );
              }}
            />
            <Label for="diger_birlikte">Diğer İndirimler</Label>
          </FormGroup>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default DiscountSwitches;
