import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

interface FacilityOtherFeaturesFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const FacilityOtherFeaturesFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityOtherFeaturesFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormContainer title="Termal & Spa" lg={12} xl={12}>
        <CustomFormFieldContainer
          label="Termal & Spa Açıklama"
          error={formErrors.txt_termal}
          orientation="vertical"
        >
          <CustomFormEditor
            editorData={values.txt_termal}
            handleGetEditorData={(data) => {
              dispatch(
                handleChangeInput({
                  txt_termal: data,
                })
              );
              validateFormChange({
                ...values,
                txt_termal: data,
              });
            }}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
      <CustomFormContainer title="Toplantı Salonu" lg={12} xl={12}>
        <CustomFormFieldContainer
          label="Toplantı Salonu Açıklama"
          error={formErrors.txt_toplanti}
          orientation="vertical"
        >
          <CustomFormEditor
            editorData={values.txt_toplanti}
            handleGetEditorData={(data) => {
              dispatch(
                handleChangeInput({
                  txt_toplanti: data,
                })
              );
              validateFormChange({
                ...values,
                txt_toplanti: data,
              });
            }}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
      <CustomFormContainer title="Balayı Konsepti" lg={12} xl={12}>
        <CustomFormFieldContainer
          label="Balayı Konsepti Açıklama"
          error={formErrors.txt_balayi}
          orientation="vertical"
        >
          <CustomFormEditor
            editorData={values.txt_balayi}
            handleGetEditorData={(data) => {
              dispatch(
                handleChangeInput({
                  txt_balayi: data,
                })
              );
              validateFormChange({
                ...values,
                txt_balayi: data,
              });
            }}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
      <CustomFormContainer title="İnternet Kullanımı" lg={12} xl={12}>
        <CustomFormFieldContainer
          label="İnternet Kullanımı Açıklama"
          error={formErrors.txt_internet}
          orientation="vertical"
        >
          <CustomFormEditor
            editorData={values.txt_internet}
            handleGetEditorData={(data) => {
              dispatch(
                handleChangeInput({
                  txt_internet: data,
                })
              );
              validateFormChange({
                ...values,
                txt_internet: data,
              });
            }}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
    </>
  );
};

export default FacilityOtherFeaturesFields;
