import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSaveCallsFromGeneral, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_CALLS_FROM_GENERAL } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetCallsFromGeneralPayload {
  start_date: string;
  end_date: string;
  phone: string;
}

export const getCallsFromGeneral =
  (payload: GetCallsFromGeneralPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_CALLS_FROM_GENERAL, payload);

      dispatch(handleSaveCallsFromGeneral(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
