import { CustomFormContainer, CustomReactTable } from "Components/Custom/UI";
import useHandlePagination from "./useHandlePagination";
import useHandleTable from "./useHandleTable";

interface UnmatchedHotelTableProps {
  isLoading: boolean;
}
const UnmatchedHotelTable = ({ isLoading }: UnmatchedHotelTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <CustomFormContainer title="Eşleştirilmeyen Oteller">
      <CustomReactTable
        fileName="Eşleştirilmeyen Oteller"
        pagination={true}
        loading={isLoading}
        tableData={tableData}
        tableColumns={tableColumns}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
      />
    </CustomFormContainer>
  );
};

export default UnmatchedHotelTable;
