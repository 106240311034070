import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from "recharts";
  
  interface CustomDasboardBarChartProps {
    containerWidth?: number | string;
    containerHeight?: number | string;
    chartWidth?: number;
    chartHeight?: number;
    data: {
      name: string;
      [key: string]: number | string;
    }[];
    colors?: string[];
    bar_fill?: string;
  }
  
  const CustomDasboardBarChart = ({
    containerWidth = "100%",
    containerHeight = 200,
    chartWidth = 500,
    chartHeight = 200,
    data,
    colors,
    bar_fill,
  }: CustomDasboardBarChartProps) => {
    const bars = data[0] ? Object.keys(data[0]) : [];
    bars.splice(bars.indexOf("name"), 1);
  
    return (
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <BarChart
          width={chartWidth}
          height={chartHeight}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"/>
          <YAxis width={24} />
          <Tooltip />
          {bars.map((bar, index) => (
            <Bar
              key={index}
              dataKey={bar}
              fill={bar_fill}
              activeBar={<Rectangle opacity={0.8} />}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };
  
  export default CustomDasboardBarChart;
  