import { useState } from "react";
import FilterForm from "./FilterForm";
import OutboundCallsListTable from "./OutboundCallsListTable";
import moment from "moment";

interface OutboundCallsListPageProps {
  isLoading: boolean;
}

interface SelectedDates {
  start_date: string;
  end_date: string;
}

const OutboundCallsListPage = ({ isLoading }: OutboundCallsListPageProps) => {
  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const getSelectedDates = (start: string, end: string) => {
    setSelectedDates({
      start_date: start,
      end_date: end,
    });
  };

  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm onSelectDates={getSelectedDates} />
      <OutboundCallsListTable
        isLoading={isLoading}
        selectedDates={selectedDates}
      />
    </div>
  );
};

export default OutboundCallsListPage;
