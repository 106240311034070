import FilterForm from "./FilterForm";
import PhoneStatusListTable from "./PhoneStatusListTable";

interface PhoneStatusListPageProps {
  isLoading: boolean;
}

const PhoneStatusListPage = ({ isLoading }: PhoneStatusListPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <PhoneStatusListTable isLoading={isLoading} />
    </div>
  );
};

export default PhoneStatusListPage;
