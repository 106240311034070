import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { SpecialDaysInitialState } from "slices/facility/listSpecialDays/reducer";
import { getSpecialDays } from "slices/facility/listSpecialDays/thunk";

const useListSpecialDays = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { ListSpecialDays: SpecialDaysInitialState }) =>
      state.ListSpecialDays
  );

  useEffect(() => {
    dispatch(
      getSpecialDays({
        page: 1,
        per_page: 10,
        search: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useListSpecialDays;
