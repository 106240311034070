import { Input } from "reactstrap";
import useHandleForm from "./useHandleForm";
import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import Select from "react-select";

interface MailOrderFormProps {
  isLoading: boolean;
}

interface Option {
  label: string;
  value: string;
}

const MailOrderForm = ({ isLoading }: MailOrderFormProps) => {
  const {
    mailOrder,
    formErrors,
    banksOptions,
    validateFormChange,
    handleChange,
    onAddMailOrder,
    onUpdateMailOrder,
    onResetForm,
  } = useHandleForm();

  const formStatus = mailOrder.id ? "update" : "add";
  return (
    <CustomFormContainer
      title={formStatus === "add" ? "Kart Ekle" : "Kart Güncelle"}
    >
      <div className="d-flex gap-4 flex-wrap align-items-start">
        <CustomFormFieldContainer
          label="Kart Sahibi"
          orientation="vertical"
          error={formErrors.kart_sahibi}
        >
          <Input
            type="text"
            value={mailOrder.kart_sahibi}
            onChange={(e) => {
              handleChange("kart_sahibi", e.target.value);
              validateFormChange({ ...mailOrder, adi: e.target.value });
            }}
            placeholder="Adı Soyadı"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Banka Adı"
          orientation="vertical"
          error={formErrors.adi}
        >
          <Select
            options={banksOptions}
            value={
              banksOptions.find(
                (option) => option.value === mailOrder.kart_kodu
              ) || null
            }
            onChange={(selectedOption: Option) => {
              handleChange("adi", selectedOption.label);
              handleChange("kart_kodu", selectedOption.value);
              validateFormChange({
                ...mailOrder,
                adi: selectedOption.label,
                kart_kodu: selectedOption.value,
              });
            }}
            placeholder="Banka Seçiniz..."
            styles={{
              control: (styles: any) => ({
                ...styles,
                width: "200px",
              }),
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Kart Numarası"
          orientation="vertical"
          error={formErrors.kart_numarasi}
        >
          <Input
            type="text"
            value={mailOrder.kart_numarasi}
            onChange={(e) => {
              handleChange("kart_numarasi", e.target.value);
              validateFormChange({
                ...mailOrder,
                kart_numarasi: e.target.value,
              });
            }}
            placeholder="1111222233334444"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Kart Son Kullanım"
          orientation="vertical"
          error={formErrors.kart_son_kullanim}
        >
          <Input
            type="text"
            value={mailOrder.kart_son_kullanim}
            onChange={(e) => {
              handleChange("kart_son_kullanim", e.target.value);
              validateFormChange({
                ...mailOrder,
                kart_son_kullanim: e.target.value,
              });
            }}
            placeholder="MM/YY"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Kart CVC"
          orientation="vertical"
          error={formErrors.kart_cvc}
        >
          <Input
            type="number"
            value={mailOrder.kart_cvc}
            onChange={(e) => {
              handleChange("kart_cvc", e.target.value);
              validateFormChange({ ...mailOrder, kart_cvc: e.target.value });
            }}
            placeholder="123"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Otel Ödeme" orientation="vertical">
          <Input
            type="checkbox"
            checked={mailOrder.otel_odeme === "1"}
            onChange={(e) => {
              handleChange("otel_odeme", e.target.checked ? "1" : "0");
              validateFormChange({
                ...mailOrder,
                otel_odeme: e.target.checked ? "1" : "0",
              });
            }}
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="-" orientation="vertical">
          {formStatus === "add" ? (
            <CustomButton
              variant="primary"
              onClick={onAddMailOrder}
              isLoading={isLoading}
            >
              Kart Ekle
            </CustomButton>
          ) : (
            <div className="d-flex gap-2">
              <CustomButton
                variant="warning"
                onClick={onResetForm}
                isLoading={isLoading}
              >
                Düzenlemeden Çık
              </CustomButton>
              <CustomButton
                variant="success"
                onClick={onUpdateMailOrder}
                isLoading={isLoading}
              >
                Güncelle
              </CustomButton>
            </div>
          )}
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default MailOrderForm;
