import { CustomButton } from "Components/Custom/UI";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { Dispatch } from "redux";
import { setToastError } from "slices/toast/reducer";

interface SaveDescriptionModalProps {
  modal: boolean;
  toggle: () => void;
  description: string;
  saveDescription: (description: string) => void;
  isLoading: boolean;
}

const SaveDescriptionModal = ({
  modal,
  toggle,
  description,
  saveDescription,
  isLoading,
}: SaveDescriptionModalProps) => {
  const [value, setValue] = useState(description);

  const dispatch: Dispatch<any> = useDispatch();

  const handleSave = () => {
    if (!value) {
      dispatch(setToastError("Açıklaması boş olamaz!"));
      return;
    } else {
      saveDescription(value);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Açıklama Düzenle</ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </ModalBody>
      <ModalFooter className="border-0 d-flex gap-2">
        <CustomButton variant="danger" onClick={toggle}>
          Vazgeç
        </CustomButton>
        <CustomButton
          variant="success"
          onClick={() => {
            handleSave();
            toggle();
          }}
          disabled={!value}
          isLoading={isLoading}
        >
          Kaydet
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
};

export default SaveDescriptionModal;
