import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { GET_HOTEL_SUMMARY } from "helpers/url_helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  CompetitorAnalysisInitialState,
  handleChangeIsEb,
} from "slices/adsAndAnalyze/competitorAnalysis/reducer";
import { getCompetitorAnalysis } from "slices/adsAndAnalyze/competitorAnalysis/thunk";
import { setToastError } from "slices/toast/reducer";
import {
  CompetitorAnalysisFilterFormValues,
  CompetitorAnalysisRequestParams,
} from "types/adsAndAnalyze";

interface Option {
  value: string;
  label: string;
}

interface GroupedOption {
  label: string;
  options: Option[];
}

interface FormOptions {
  isEbOptions: Option[];
  regionsOptions: Option[];
  facilityResponsiblesOptions: Option[];
  welcomeTypeOptions: Option[];
  hotelPriceStatusOptions: GroupedOption[];
  quotationStatusOptions: GroupedOption[];
  adStatusOptions: Option[];
  providersOptions: Option[];
  paymentTypesOptions: Option[];
  roomNumbersOptions: Option[];
}

const useHandleForm = (
  formValues: CompetitorAnalysisFilterFormValues,
  setFormValues: (formValues: CompetitorAnalysisFilterFormValues) => void
) => {
  const { competitorAnalysisResponse, isEb, isLoading } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const [formOptions, setFormOptions] = useState<FormOptions>({
    isEbOptions: [],
    regionsOptions: [],
    facilityResponsiblesOptions: [],
    welcomeTypeOptions: [],
    hotelPriceStatusOptions: [],
    quotationStatusOptions: [],
    adStatusOptions: [],
    providersOptions: [],
    paymentTypesOptions: [],
    roomNumbersOptions: [],
  });

  useEffect(() => {
    const isEbOptions = [
      { value: "0", label: "Tüm Tarihler" },
      { value: "1", label: "EB Tarihler" },
    ];

    const regionsOptions = competitorAnalysisResponse.bolgeler.map(
      (region) => ({
        value: region.ilce_id + "",
        label: `${region.sehir_adi} - ${region.ilce_adi}`,
      })
    );

    const facilityResponsiblesOptions = Object.entries(
      competitorAnalysisResponse.tesis_sorumlulari
    ).map(([key, value]) => ({
      value: key,
      label: value,
    }));

    const welcomeTypeOptions = Object.entries(
      competitorAnalysisResponse.karsilastirma_kodu
    ).map(([key, value]) => ({
      value: key,
      label: value + "",
    }));

    const hotelPriceStatusOptions = Object.entries(
      competitorAnalysisResponse.otel_fiyat_statu
    ).map(([key, value]) => {
      return {
        label: key,
        options: Object.entries(value).map(([key, value]) => ({
          value: key + "",
          label: value + "",
        })),
      };
    });

    const quotationStatusOptions = Object.entries(
      competitorAnalysisResponse.Kontenjan_Bilgileri
    ).map(([key, value]) => {
      return {
        label: key,
        options: Object.entries(value).map(([key, value]) => ({
          value: key + "",
          label: value + "",
        })),
      };
    });

    const adStatusOptions = Object.entries(
      competitorAnalysisResponse.reklam_statu
    ).map(([key, value]) => ({
      value: key,
      label: value + "",
    }));

    const providersOptions = Object.entries(
      competitorAnalysisResponse.fiyat_saglayicilar
    ).map(([key, value]) => ({
      value: value + "",
      label: key,
    }));

    const paymentTypesOptions = Object.entries(
      competitorAnalysisResponse.odeme_tipleri
    ).map(([key, value]) => ({
      value: value + "",
      label: key,
    }));

    const roomNumbersOptions = Object.entries(
      competitorAnalysisResponse.oda_sayi
    ).map(([key, value]) => ({
      value: value + "",
      label: key,
    }));

    regionsOptions.unshift({ value: "0", label: "Tüm Lokasyonlar" });
    facilityResponsiblesOptions.unshift({ value: "", label: "Tüm Sorumlular" });
    welcomeTypeOptions.unshift({ value: "", label: "Tüm Karşılaştırmalar" });
    adStatusOptions.unshift({ value: "", label: "Tüm Reklam Durumları" });
    providersOptions.unshift({ value: "", label: "Tüm Fiyat Sağlayıcılar" });
    paymentTypesOptions.unshift({ value: "", label: "Tüm Ödeme Tipleri" });
    roomNumbersOptions.unshift({ value: "0", label: "Tüm Oda Sayıları" });
    hotelPriceStatusOptions.unshift({
      label: "Tüm Durumlar",
      options: [{ value: "", label: "Tüm Durumlar" }],
    });
    quotationStatusOptions.unshift({
      label: "Tüm Kontenjanlar",
      options: [{ value: "", label: "Tüm Kontenjanlar" }],
    });

    setFormOptions({
      isEbOptions,
      regionsOptions,
      facilityResponsiblesOptions,
      welcomeTypeOptions,
      hotelPriceStatusOptions,
      quotationStatusOptions,
      adStatusOptions,
      providersOptions,
      paymentTypesOptions,
      roomNumbersOptions,
    });
  }, [competitorAnalysisResponse]);

  const handleFormChange = (key: string, value: string | number) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetCompetitorAnalysis = () => {
    const params: CompetitorAnalysisRequestParams = {
      otel_id: formValues.hotelId,
      ilce_id: formValues.region,
      tedarikci: formValues.provider,
      oda_sayisi: formValues.roomNumber,
      reklam_statu: formValues.adStatus,
      yetkili_id: formValues.facilityResponsible,
      kontenjan_sorgu: formValues.quotationStatus,
      karsilama_hesaplama: formValues.welcomeType,
      otel_fiyat_statu: formValues.hotelPriceStatus,
      odeme_tipleri: formValues.paymentType,
      eb: formValues.isEb,
      per_page: 10,
      page: 1,
    };

    Object.keys(params).forEach((key) => {
      const typedKey = key as keyof CompetitorAnalysisRequestParams;
      if (!params[typedKey] || params[typedKey] === "0") {
        delete params[typedKey];
      }
    });

    dispatch(
      getCompetitorAnalysis(params, () => {
        dispatch(handleChangeIsEb(formValues.isEb));
      })
    );
  };

  const handleResetForm = () => {
    setFormValues({
      hotelId: 0,
      region: 0,
      facilityResponsible: "",
      welcomeType: "",
      hotelPriceStatus: "",
      quotationStatus: "",
      adStatus: "",
      provider: "",
      paymentType: "",
      roomNumber: 0,
      isEb,
    });

    dispatch(
      getCompetitorAnalysis({
        eb: isEb,
        per_page: 10,
        page: 1,
      })
    );
  };

  const [query, setQuery] = useState("");

  const loadOptions = async () => {
    try {
      const response = await axios.get(GET_HOTEL_SUMMARY, {
        params: { search: query },
      });
      const res = (response as any).data || response;

      const options = res.map((hotel: any) => ({
        value: hotel.otel_id,
        label: hotel.otel_adi,
      }));
      options.unshift({ value: 0, label: "Tüm Oteller" });

      return options;
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setToastError(message));
      });
    }
  };
  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  return {
    formOptions,
    formValues,
    handleFormChange,
    isLoading,
    onGetCompetitorAnalysis: handleGetCompetitorAnalysis,
    onResetForm: handleResetForm,
    loadOptions,
    onChangeQuery: handleChangeQuery,
  };
};

export default useHandleForm;
