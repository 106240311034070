import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { FacilityImageFile } from "types/facility";
import {
  addFacilityImages,
  getFacilityImages,
} from "slices/facility/facilityImages/thunk";
import { handleChangeFacilityImageFilesInput } from "slices/facility/facilityImages/reducer";
import { CustomButton } from "Components/Custom/UI";

interface AddNewImageButtonProps {
  facilityImageFiles: FacilityImageFile[];
  isLoading: boolean;
}

const AddNewImageButton = ({
  facilityImageFiles,
  isLoading,
}: AddNewImageButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const onClick = () => {
    const formData = new FormData();

    formData.append("otel_id", otel_id.toString());
    formData.append("medya_turu", "genel");

    facilityImageFiles.forEach((image) => {
      formData.append("resim[]", image.file, image.file.name);
    });

    dispatch(
      addFacilityImages(formData, () => {
        dispatch(handleChangeFacilityImageFilesInput([]));
        dispatch(getFacilityImages(otel_id));
      })
    );
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton
        variant="success"
        disabled={!facilityImageFiles.length}
        onClick={onClick}
        isLoading={isLoading}
      >
        Resimleri Yükle
      </CustomButton>
    </div>
  );
};

export default AddNewImageButton;
