import { createSlice } from "@reduxjs/toolkit";

import {
  HotelSaleFilter,
  HotelSale,
  HotelSaleFilterLocation,
} from "types/reports";

export interface HotelSalesInitialState {
  hotelSaleFilter: HotelSaleFilter;
  hotelSales: HotelSale[];
  districts: HotelSaleFilterLocation[];
  isLoading: boolean;
  districtsLoading: boolean;
  error: string;
  districtsError: string;
}

const initialState: HotelSalesInitialState = {
  hotelSaleFilter: {
    personel_listesi: [],
    fiyat_saglayici: [],
    hesaplama_tarih_tipi: [],
    sehirler: [],
    karsilama_kodlari: [],
  },
  hotelSales: [],
  districts: [],
  isLoading: false,
  districtsLoading: false,
  error: "",
  districtsError: "",
};

const hotelSalesSlice = createSlice({
  name: "hotelSales",
  initialState,
  reducers: {
    handleSaveHotelSalesFilter(state, action) {
      state.hotelSaleFilter = action.payload;
    },
    handleSaveHotelSales(state, action) {
      state.hotelSales = action.payload;
    },
    handleSaveDistricts(state, action) {
      state.districts = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDistrictsLoading(state, action) {
      state.districtsLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDistrictsError(state, action) {
      state.districtsError = action.payload;
    },
  },
});

export const {
  handleSaveHotelSalesFilter,
  handleSaveHotelSales,
  handleSaveDistricts,
  setIsLoading,
  setDistrictsLoading,
  setError,
  setDistrictsError,
} = hotelSalesSlice.actions;

export default hotelSalesSlice.reducer;
