import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { Dispatch } from "redux";
import { handleChangeSpecialDayInput } from "slices/facility/facilitySpecialDays/reducer";
import {
  FacilitySpecialDayFormErrors,
  FacilitySpecialDayState,
} from "types/facility";
import {
  FACILITY_SPECIAL_DAY_SINGER_NAME_DESCRIPTION,
  FACILITY_SPECIAL_DAY_TITLE_DESCRIPTION,
} from "utils/fieldDescriptions";

interface SpecialDayTitleAndSingerNameProps {
  values: FacilitySpecialDayState;
  formErrors: FacilitySpecialDayFormErrors;
  validateFormChange: (values: FacilitySpecialDayState) => void;
}

const SpecialDayTitleAndSingerName = ({
  values,
  formErrors,
  validateFormChange,
}: SpecialDayTitleAndSingerNameProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <>
      <CustomFormFieldContainer
        label="Özel Gün Başlığı"
        error={formErrors.ozel_gun_baslik}
        description={FACILITY_SPECIAL_DAY_TITLE_DESCRIPTION}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.ozel_gun_baslik}
          onChange={(e) => {
            dispatch(
              handleChangeSpecialDayInput({
                ozel_gun_baslik: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ozel_gun_baslik: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Özel Gün Sanatçı Adı"
        error={formErrors.ozel_gun_sanatci_adi}
        description={FACILITY_SPECIAL_DAY_SINGER_NAME_DESCRIPTION}
        divider
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.ozel_gun_sanatci_adi}
          onChange={(e) => {
            dispatch(
              handleChangeSpecialDayInput({
                ozel_gun_sanatci_adi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              ozel_gun_sanatci_adi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default SpecialDayTitleAndSingerName;
