import InformationTechnologiesPage from 'Components/Custom/UserOperationsPages/InformationTechnologiesPage';
import useInformationTechnologies from 'Components/Hooks/userOperations/useInformationTechnologies';
import PageContainer from 'pages/PageContainer';

const InformationTechnologies = () => {

    const { isLoading } = useInformationTechnologies();

  return (
    <PageContainer title="Bilgi İşlem">
        <InformationTechnologiesPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default InformationTechnologies