export const DASHBOARD_COLORS = {
    
    yellow: "#eab308",
    darkBlue: "#4272e7",
    lightBlue: "#80AEF9",
    sky: "#0ea5e9",
    indigo: "#6366f1",
    rose: "#ec4899",
    slate: "#94a3b8",

    white: "#FFFFFF",
    warning: "#fef9c3",
    info: "#dbeafe",
    blue: "#3b82f6",
    purple: "#8b5cf6",
    orange: "#f97316",
    red: "#ef4444",
    green: "#22c55e",
    gray: "#71717a",
    teal: "#14b8a6",
  };
  