import { createSlice } from "@reduxjs/toolkit";

import { FacilityPartialPaymentDate } from "types/facility";
import { Pagination } from "types/GlobalTypes";

export interface FacilityPartialPaymentDatesInitialState {
  facilityPartialPaymentDates: FacilityPartialPaymentDate[];
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: FacilityPartialPaymentDatesInitialState = {
  facilityPartialPaymentDates: [],
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const facilityPartialPaymentDatesSlice = createSlice({
  name: "facilityPartialPaymentDates",
  initialState,
  reducers: {
    handleSaveFacilityPartialPaymentDates(state, action) {
      const { data, ...rest } = action.payload;
      state.facilityPartialPaymentDates = data;
      state.pagination = rest;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveFacilityPartialPaymentDates, setIsLoading, setError } =
  facilityPartialPaymentDatesSlice.actions;

export default facilityPartialPaymentDatesSlice.reducer;
