import ConceptButtonContainer from "../ConceptButtonContainer";
import { CustomButton } from "Components/Custom/UI";

interface IptalEdilemezPayButtonProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  stop: 0 | 1 | 2 | 3 | null;
  stopMessage: string;
  iptalEdilemezFiyatDurum: number;
  iptalEdilemezPrice: number;
  iptalEdilemezPriceButtonText: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  showIptalEdilemezButton: boolean;
}

const IptalEdilemezPayButton = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  stop,
  stopMessage,
  iptalEdilemezFiyatDurum,
  iptalEdilemezPrice,
  iptalEdilemezPriceButtonText,
  onClick,
  showIptalEdilemezButton,
}: IptalEdilemezPayButtonProps) => {
  return (
    <ConceptButtonContainer
      tooltipId={"iptal-" + pansiyon_id}
      showButton={showIptalEdilemezButton}
      text={stop === 1 ? "<span class='fw-medium'>Son Odalar</span>" : ""}
      stop={stop}
      stopMessage={stopMessage}
    >
      <CustomButton
        classes="w-100"
        variant={
          stop === 1
            ? "warning"
            : iptalEdilemezFiyatDurum
            ? "primary"
            : "warning"
        }
        disabled={iptalEdilemezFiyatDurum === 0}
        onClick={() =>
          onClick({
            oda_id,
            oda_adi,
            pansiyon_id,
            pansiyon_adi,
            odeme_tipi: "iptal_edilemez",
            fiyat: iptalEdilemezPrice,
          })
        }
      >
        {iptalEdilemezPriceButtonText}
      </CustomButton>
    </ConceptButtonContainer>
  );
};

export default IptalEdilemezPayButton;
