import { AssignRecommendedHotelsPage } from "Components/Custom/ThemesPages";
import useAssignRecommendedHotels from "Components/Hooks/themes/useAssignRecommendedHotels";
import PageContainer from "pages/PageContainer";

const AssignRecommendedHotels = () => {
  const props = useAssignRecommendedHotels();

  return (
    <PageContainer title="Tema Tavsiye Edilen Otel Atama">
      <AssignRecommendedHotelsPage {...props} />
    </PageContainer>
  );
};

export default AssignRecommendedHotels;
