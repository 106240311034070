import ReservationChangeApproveCancelPage from 'Components/Custom/UserOperationsPages/ReservationChangeApproveCancelPage';
import useReservationChangeApproveCancel from 'Components/Hooks/userOperations/useReservationChangeApproveCancel';
import PageContainer from 'pages/PageContainer';


const ReservationChangeApproveCancel = () => {

    const { isLoading } = useReservationChangeApproveCancel();

  return (
    <PageContainer title="Rezervasyon Onay İptal Değişikliği">
        <ReservationChangeApproveCancelPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default ReservationChangeApproveCancel