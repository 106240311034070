import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationInfo from "./ReservationInfo";
import useHandleData from "./useHandleData";
import ReservationHeader from "./ReservationHeader";
import ReservationRoomsInfo from "./ReservationRoomsInfo";
import ReservationPaymentInfo from "./ReservationPaymentInfo";
import ReservationInvoiceInfo from "./ReservationInvoiceInfo";
import ReservationAvailabilityInfo from "./ReservationAvailabilityInfo";
import ReservationActions from "./ReservationActions";

interface ReviewReservationPageProps {
  isLoading: boolean;
}

const ReviewReservationPage = ({ isLoading }: ReviewReservationPageProps) => {
  const { reservation, reservationOtherRooms } = useHandleData();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ReservationHeader reservation={reservation} />
        <ReservationInfo reservation={reservation} />
        <ReservationRoomsInfo
          reservation={reservation}
          reservationOtherRooms={reservationOtherRooms}
        />
        <ReservationPaymentInfo reservation={reservation} />
        <ReservationInvoiceInfo reservation={reservation} />
        <ReservationActions reservation={reservation} />
        <ReservationAvailabilityInfo reservation={reservation} />
      </div>
    </>
  );
};

export default ReviewReservationPage;
