import { createSlice } from "@reduxjs/toolkit";

import {
  HotelContractRule,
  ContractProcedure,
  HotelContractInvoice,
  AddContractRuleFormErrors,
} from "types/financeAndAccounting";
import { Pagination } from "types/GlobalTypes";

export interface ContractRulesInitialState {
  contractProcedures: ContractProcedure[];
  hotelContractInvoice: HotelContractInvoice;
  hotelContractProcedures: ContractProcedure[];
  hotelContractRules: HotelContractRule[];
  pagination: Pagination;
  addHotelContractRuleFormErrors: AddContractRuleFormErrors;
  isLoading: boolean;
  error: string;
  addContractRuleLoading: boolean;
  hotelContractInvoiceLoading: boolean;
  addContractRuleError: string;
  hotelContractRuleLoading: boolean;
  hotelContractRuleError: string;
  hotelContractInvoiceError: string;
}

const initialState: ContractRulesInitialState = {
  contractProcedures: [],
  hotelContractInvoice: {
    tarih: "",
    tutar: "",
    islem_adi: "",
    unvan: "",
    vergi_dairesi: "",
    vergi_no: "",
    adres: "",
    mail: "",
    aciklama: "",
  },
  hotelContractProcedures: [],
  hotelContractRules: [],
  addHotelContractRuleFormErrors: {},

  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
  addContractRuleLoading: false,
  addContractRuleError: "",
  hotelContractRuleLoading: false,
  hotelContractRuleError: "",
  hotelContractInvoiceLoading: false,
  hotelContractInvoiceError: "",
};

const contractRulesSlice = createSlice({
  name: "contractRules",
  initialState,
  reducers: {
    handleSaveContractProcedures(state, action) {
      const { data, ...rest } = action.payload;
      state.contractProcedures = data;
      state.pagination = rest;
    },
    handleSaveHotelContractInvoice(state, action) {
      state.hotelContractInvoice = action.payload;
    },
    handleSaveHotelContractProcedures(state, action) {
      const { data, ...rest } = action.payload;
      state.hotelContractProcedures = data;
      state.pagination = rest;
    },
    handleSaveHotelContractRules(state, action) {
      state.hotelContractRules = action.payload;
    },
    handleAddContractRuleFormErrors(state, action) {
      state.addHotelContractRuleFormErrors = action.payload;
    },
    resetHotelContractInvoice(state) {
      state.hotelContractInvoice = {
        tarih: "",
        tutar: "",
        islem_adi: "",
        unvan: "",
        vergi_dairesi: "",
        vergi_no: "",
        adres: "",
        mail: "",
        aciklama: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddContractRuleLoading(state, action) {
      state.addContractRuleLoading = action.payload;
    },
    setAddContractRuleError(state, action) {
      state.addContractRuleError = action.payload;
    },
    setHotelContractRuleLoading(state, action) {
      state.hotelContractRuleLoading = action.payload;
    },
    setHotelContractRuleError(state, action) {
      state.hotelContractRuleError = action.payload;
    },
    setHotelContractInvoiceLoading(state, action) {
      state.hotelContractInvoiceLoading = action.payload;
    },
    setHotelContractInvoiceError(state, action) {
      state.hotelContractInvoiceError = action.payload;
    },
  },
});

export const {
  handleSaveContractProcedures,
  handleSaveHotelContractInvoice,
  handleSaveHotelContractProcedures,
  handleSaveHotelContractRules,
  handleAddContractRuleFormErrors,
  resetHotelContractInvoice,
  setIsLoading,
  setError,
  setAddContractRuleLoading,
  setAddContractRuleError,
  setHotelContractRuleLoading,
  setHotelContractRuleError,
  setHotelContractInvoiceLoading,
  setHotelContractInvoiceError,
} = contractRulesSlice.actions;

export default contractRulesSlice.reducer;
