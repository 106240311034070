import { useState } from "react";

import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

interface Tab {
  id: number;
  title: string;
  content: React.ReactNode;
}

interface CustomTabProps {
  tabs: Tab[];
}

interface TabContainerProps {
  children: React.ReactNode;
  tabId: number;
}

interface NavItemContainerProps {
  tabId: number;
  tabTitle: string;
  activeTab: number;
  onClick: (tabId: number) => void;
}

const TabContainer = ({ children, tabId }: TabContainerProps) => {
  return (
    <TabPane tabId={tabId}>
      <Row>
        <Col sm={12}>{children}</Col>
      </Row>
    </TabPane>
  );
};

const NavItemContainer = ({
  tabId,
  activeTab,
  tabTitle,
  onClick,
}: NavItemContainerProps) => {
  return (
    <NavItem className="cursor-pointer">
      <NavLink
        className={activeTab === tabId ? "active" : ""}
        onClick={() => onClick(tabId)}
      >
        {tabTitle}
      </NavLink>
    </NavItem>
  );
};

const CustomTab = ({ tabs }: CustomTabProps) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs>
        {tabs.map((tab) => (
          <NavItemContainer
            key={tab.id}
            tabId={tab.id}
            tabTitle={tab.title}
            activeTab={activeTab}
            onClick={toggleTab}
          />
        ))}
      </Nav>
      <TabContent
        className="py-4 px-2 border border-top-0 rounded-bottom bg-white"
        activeTab={activeTab}
      >
        {tabs.map((tab) => (
          <TabContainer key={tab.id} tabId={tab.id}>
            {tab.content}
          </TabContainer>
        ))}
      </TabContent>
    </div>
  );
};

export default CustomTab;
