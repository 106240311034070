import { FacilitySpecialDaysPage } from "Components/Custom/FacilityPages";
import useFacilitySpecialDays from "Components/Hooks/facility/useFacilitySpecialDays";
import PageContainer from "pages/PageContainer";

const FacilitySpecialDays = () => {
  const { isLoading, loading, error, isValidHotel } = useFacilitySpecialDays();

  return (
    <PageContainer title="Tesis Özel Günleri">
      <FacilitySpecialDaysPage
        data={true}
        loading={loading}
        isLoading={isLoading}
        error={error}
        isValidHotel={isValidHotel}
      />
    </PageContainer>
  );
};

export default FacilitySpecialDays;
