import { CustomHorizontalSlidingBox } from "Components/Custom/UI";
import { CalculatePriceForHotelImage } from "types/reservation";

interface FeaturedImagesProps {
  images: CalculatePriceForHotelImage[];
}

const FeaturedImages = ({ images }: FeaturedImagesProps) => {
  return (
    <CustomHorizontalSlidingBox>
      {images.map((image, index) => (
        <img
          key={image.resim_id}
          src={image.resim_url}
          alt={`featured-${index}`}
          className="img-fluid"
          style={{ width: "250px", height: "162px" }}
        />
      ))}
    </CustomHorizontalSlidingBox>
  );
};

export default FeaturedImages;
