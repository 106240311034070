import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveDailyCallsForHotels,
  setIsLoading,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_DAILY_CALLS_FOR_HOTELS } from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetDailyCallsForHotelsParams {
  date: string;
}

export const getDailyCallsForHotels =
  (params: GetDailyCallsForHotelsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_DAILY_CALLS_FOR_HOTELS, {
        params,
      });

      dispatch(handleSaveDailyCallsForHotels(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
