import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import useHandleForm from "./useHandleForm";
import FacilityOperationalSelectFields from "./FacilityOperationalSelectFields";
import FacilityInetSelectFields from "./FacilityInetSelectFields";

interface FacilityOperationalInfoFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityOperationalInfoFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityOperationalInfoFieldsProps) => {
  const {
    showPriceListOptions,
    showHotelLogoOptions,
    showHotelAgentOptions,
    supplierOptions,
    priceProviderOptions,
  } = useHandleForm();
  return (
    <CustomFormContainer title="Operasyonel Bilgiler" lg={12} xl={12}>
      <FacilityOperationalSelectFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        showPriceListOptions={showPriceListOptions}
        showHotelLogoOptions={showHotelLogoOptions}
        showHotelAgentOptions={showHotelAgentOptions}
      />
      <FacilityInetSelectFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        supplierOptions={supplierOptions}
        priceProviderOptions={priceProviderOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityOperationalInfoFields;
