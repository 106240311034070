import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import GroupNameInput from "./FormFields/GroupNameInput";
import GroupManagerSelector from "./FormFields/GroupManagerSelector";
import GroupMembersList from "./FormFields/GroupMembersList";
import UpdateGroupButton from "./UpdateGroupButton/UpdateGroupButton";

const UpdateGroupForm = () => {
  const {
    groupManagerOptions,
    selectedManager,
    groupMemberOptions,
    selectedOptions,
    handleSelectOptions,
    values,
    formErrors,
    updateGroupLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    updateGroupRequestPayload,
  } = useHandleForm();

  return (
    <div className="d-flex flex-column gap-4">
      <CustomFormContainer title="Grup Düzenle">
        <GroupNameInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <GroupManagerSelector
          values={values}
          groupManagerOptions={groupManagerOptions}
          selectedManagerToChange={selectedManager}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <GroupMembersList
          formChoices={groupMemberOptions}
          selectedOptions={selectedOptions}
          formErrors={formErrors}
          handleSelectOptions={handleSelectOptions}
        />
        <UpdateGroupButton
          values={values}
          requestPayload={updateGroupRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={updateGroupLoading}
        />
      </CustomFormContainer>
    </div>
  );
};

export default UpdateGroupForm;
