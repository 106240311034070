import FilterForm from "./FilterForm";
import IssuedInvoicesTable from "./IssuedInvoicesTable";

interface IssuedInvoicesPageProps {
  isLoading: boolean;
}

const IssuedInvoicesPage = ({ isLoading }: IssuedInvoicesPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <IssuedInvoicesTable isLoading={isLoading} />
    </div>
  );
};

export default IssuedInvoicesPage;
