import { AddFacilityFormErrors, FacilityState } from "types/facility";

import CrmSellerDescription from "./CrmSellerDescription";
import CrmOperationFields from "./CrmOperationFields";

interface CrmDescriptionFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
}

const CrmDescriptionFields = ({
  values,
  formErrors,
  validateFormChange,
}: CrmDescriptionFieldsProps) => {
  return (
    <>
      <CrmSellerDescription
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <CrmOperationFields
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
    </>
  );
};

export default CrmDescriptionFields;
