import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";

import useHandleForm from "./useHandleForm";
import FacilityWelcomeCode from "./FacilityWelcomeCode";
import FacilityCalculationSupplier from "./FacilityCalculationSupplier";

interface FacilityCalculationFieldsProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityCalculationFields = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityCalculationFieldsProps) => {
  const { welcomeCodeOptions, priceCalculationSupplierOptions } =
    useHandleForm();

  return (
    <CustomFormContainer title="Fiyat Hesaplama" lg={12} xl={12}>
      <FacilityWelcomeCode
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        welcomeCodeOptions={welcomeCodeOptions}
      />
      <FacilityCalculationSupplier
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        priceCalculationSupplierOptions={priceCalculationSupplierOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityCalculationFields;
