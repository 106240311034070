import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { handleSaveCrmDashboard, setIsLoading, setError } from "./reducer";
import { setToastError } from "slices/toast/reducer";
import {
  GET_CRM_DASHBOARD_DATA,
} from "helpers/url_helper";
import { Dispatch } from "redux";

export const getCrmDashboardData = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_CRM_DASHBOARD_DATA);

    const res = (response as any).message ? response.data : response;
    
    dispatch(handleSaveCrmDashboard(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

