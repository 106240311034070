import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddUpdateFacilityUserFormErrors } from "slices/facility/facilityUsers/reducer";
import { setToastError } from "slices/toast/reducer";

import { FacilityUserFormErrors, FacilityUserState } from "types/facility";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FacilityUserState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: FacilityUserFormErrors = {};

    if (!values.inet_kodu) {
      errors.inet_kodu = "İnet kodu boş bırakılamaz.";
    }

    if (!values.kullanici_adi) {
      errors.kullanici_adi = "Kullanıcı adı boş bırakılamaz.";
    }

    if (!values.mail && !values.id) {
      errors.mail = "Mail boş bırakılamaz.";
    }

    dispatch(handleAddUpdateFacilityUserFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FacilityUserState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};

export default useValidateForm;
