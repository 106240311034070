import axios from "axios";
import { Dispatch } from "redux";

import { GET_HOTEL } from "helpers/url_helper";
import { handleErrors } from "helpers/axios_helpers";
import {
  getHotelError,
  getHotelSuccess,
  handleSavePassiveRooms,
  handleSavePassiveConcepts,
  resetHotel,
  setIsLoading,
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import { Hotel } from "types/Hotel";
import Cookies from "js-cookie";

export const getHotel = (hotelId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(resetHotel());
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_HOTEL}/${hotelId}`);

    const hotel = (response.data || response) as Hotel;

    dispatch(getHotelSuccess(hotel));
    dispatch(handleSavePassiveRooms(hotel));
    dispatch(handleSavePassiveConcepts(hotel));

    sessionStorage.setItem("hotel", hotel.otel_id.toString());
    Cookies.set("hotel", hotel.otel_id.toString());
    window.history.pushState({}, "", `?otel_id=${hotel.otel_id}`);
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(getHotelError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
