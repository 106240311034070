import { createSlice } from "@reduxjs/toolkit";

import { MatchRoomConceptList } from "types/facility";

interface Item {
  id: string;
  text: string;
  order?: number;
}
export interface RoomConceptMatchInitialState {
  matchRoomConceptList: MatchRoomConceptList;
  supplierRooms: Item[];
  selectedSupplierRooms: Item[];
  inetRooms: Item[];
  supplierConcepts: Item[];
  selectedSupplierConcepts: Item[];
  inetConcepts: Item[];
  isLoading: boolean;
  error: string;
}

export const roomConceptMatchInitialState: RoomConceptMatchInitialState = {
  matchRoomConceptList: {
    tedarikci_oda_listesi: [],
    tedarikci_pansiyon_adi: [],
    secilmis_odalar: [],
    secilmis_pansiyon: [],
    inet_oda_bilgisi: {},
    inet_pansiyon_bilgisi: {},
  },
  supplierRooms: [],
  selectedSupplierRooms: [],
  inetRooms: [],
  supplierConcepts: [],
  selectedSupplierConcepts: [],
  inetConcepts: [],

  isLoading: false,
  error: "",
};

const roomConceptMatchSlice = createSlice({
  name: "roomConceptMatch",
  initialState: roomConceptMatchInitialState,
  reducers: {
    handleSaveMatchRoomConceptList(state, action) {
      state.matchRoomConceptList = action.payload;

      const rooms = state.matchRoomConceptList.tedarikci_oda_listesi;

      const draggableItems: Item[] = rooms.map((room) => ({
        id: room.tedarikci_id.toString(),
        text: room.tedarikci_adi,
      }));

      state.supplierRooms = draggableItems;

      const selectedRooms = state.matchRoomConceptList.secilmis_odalar.filter(
        (room) => room.tedarikci_oda_id || room.tedarikci_oda_adi
      );

      selectedRooms.sort((a, b) => a.id - b.id);

      const selectedItems: Item[] = selectedRooms.map((room, index) => ({
        id: room.id.toString(),
        text: room.tedarikci_oda_adi ?? "",
        order: index + 1,
      }));

      state.selectedSupplierRooms = selectedItems;

      const inetRooms = state.matchRoomConceptList.inet_oda_bilgisi;

      const inetItems = Object.entries(inetRooms).map(([key, value]) => ({
        id: value,
        text: key,
      }));

      const inetItemsHaveSuppliers = inetItems.filter((item) =>
        selectedItems.some(
          (selectedItem) => Number(selectedItem.id) === item.id
        )
      );
      const inetItemsWithoutSuppliers = inetItems.filter(
        (item) =>
          !selectedItems.some(
            (selectedItem) => Number(selectedItem.id) === item.id
          )
      );
      inetItemsHaveSuppliers.sort((a, b) => a.id - b.id);
      inetItemsWithoutSuppliers.sort((a, b) => a.id - b.id);

      const combinedInetItems = [
        ...inetItemsHaveSuppliers,
        ...inetItemsWithoutSuppliers,
      ];

      const inetItemsWithOrder: Item[] = combinedInetItems.map(
        (item, index) => ({
          id: item.id.toString(),
          text: item.text,
          order: index + 1,
        })
      );

      state.inetRooms = inetItemsWithOrder;

      const concepts = state.matchRoomConceptList.tedarikci_pansiyon_adi;

      const draggableConcepts: Item[] = concepts.map((concept) => ({
        id: concept.tedarikci_id.toString(),
        text: concept.tedarikci_adi,
      }));

      state.supplierConcepts = draggableConcepts;

      const selectedConcepts =
        state.matchRoomConceptList.secilmis_pansiyon.filter(
          (concept) =>
            concept.tedarikci_pansiyon_id || concept.tedarikci_pansiyon_adi
        );

      selectedConcepts.sort((a, b) => a.id - b.id);

      const selectedConceptItems: Item[] = selectedConcepts.map(
        (concept, index) => ({
          id: concept.id.toString(),
          text: concept.tedarikci_pansiyon_adi ?? "",
          order: index + 1,
        })
      );

      state.selectedSupplierConcepts = selectedConceptItems;

      const inetConcepts = state.matchRoomConceptList.inet_pansiyon_bilgisi;

      const inetConceptItems = Object.entries(inetConcepts).map(
        ([key, value]) => ({
          id: value,
          text: key,
        })
      );

      const inetConceptItemsHaveSuppliers = inetConceptItems.filter((item) =>
        selectedConceptItems.some(
          (selectedItem) => Number(selectedItem.id) === item.id
        )
      );

      const inetConceptItemsWithoutSuppliers = inetConceptItems.filter(
        (item) =>
          !selectedConceptItems.some(
            (selectedItem) => Number(selectedItem.id) === item.id
          )
      );

      inetConceptItemsHaveSuppliers.sort((a, b) => a.id - b.id);
      inetConceptItemsWithoutSuppliers.sort((a, b) => a.id - b.id);

      const combinedInetConceptItems = [
        ...inetConceptItemsHaveSuppliers,
        ...inetConceptItemsWithoutSuppliers,
      ];

      const inetConceptItemsWithOrder: Item[] = combinedInetConceptItems.map(
        (item, index) => ({
          id: item.id.toString(),
          text: item.text,
          order: index + 1,
        })
      );

      state.inetConcepts = inetConceptItemsWithOrder;
    },
    handleSaveSupplierRooms(state, action) {
      state.supplierRooms = action.payload;
    },
    handleSaveSelectedSupplierRooms(state, action) {
      state.selectedSupplierRooms = action.payload;
    },
    handleSaveInetRooms(state, action) {
      state.inetRooms = action.payload;
    },
    handleSaveSupplierConcepts(state, action) {
      state.supplierConcepts = action.payload;
    },
    handleSaveSelectedSupplierConcepts(state, action) {
      state.selectedSupplierConcepts = action.payload;
    },
    handleSaveInetConcepts(state, action) {
      state.inetConcepts = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveMatchRoomConceptList,
  handleSaveSupplierRooms,
  handleSaveSelectedSupplierRooms,
  handleSaveInetRooms,
  handleSaveSupplierConcepts,
  handleSaveSelectedSupplierConcepts,
  handleSaveInetConcepts,
  setIsLoading,
  setError,
} = roomConceptMatchSlice.actions;

export default roomConceptMatchSlice.reducer;
