import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import useHandleHotelSearch from "./useHandleHotelSearch";
import { CustomButton } from "Components/Custom/UI";

const useHandleTable = () => {
  const { calculatePriceForLocation } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const { handleCalculatePriceForHotel } = useHandleHotelSearch();

  const tableData = calculatePriceForLocation?.map((location) => ({
    "Otel ID": location.otel_id,
    "Otel Adı": location.otel_adi,
    "Yeme İçme": location.odalar.map((oda) => oda.pansiyonlar).flat(),
    "İndirim Oranı": location.gostermelik_indirim_orani,
    "İndirimli Fiyat": location.en_dusuk_fiyat
      ? Object.entries(location.en_dusuk_fiyat).find(
          ([key]) => key.includes("indirimli") && !key.includes("indirimli_kur")
        )?.[1]
      : 0,
    "Göstermelik Fiyat": location.en_dusuk_fiyat
      ? Object.entries(location.en_dusuk_fiyat).find(
          ([key]) =>
            key.includes("gostermelik") && !key.includes("gostermelik_kur")
        )?.[1]
      : 0,
  }));

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Yeme İçme",
      accessorKey: "Yeme İçme",
      cell: (cell: any) => {
        const value = cell.getValue();

        const uniqueArray = value
          .filter(
            (item: any, index: any, self: any) =>
              index ===
              self.findIndex((t: any) => t.pansiyon_id === item.pansiyon_id)
          )
          .map((item: any) => item.pansiyon_adi);

        return <span>{uniqueArray.join(", ")}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "İndirim Oranı",
      accessorKey: "İndirim Oranı",
      cell: (cell: any) => {
        const value = cell.getValue();
        return <span className="fw-medium">%{value}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Fiyat",
      accessorKey: "Fiyat",
      cell: (cell: any) => {
        const indirimliFiyat = cell.row.original["İndirimli Fiyat"];
        const gostermelikFiyat = cell.row.original["Göstermelik Fiyat"];

        const indirimliFiyatValue = indirimliFiyat
          ? Math.ceil(indirimliFiyat)
          : 0;

        const gostermelikFiyatValue = gostermelikFiyat
          ? Math.ceil(gostermelikFiyat)
          : 0;

        return (
          <div className="d-flex flex-column gap-1">
            <span className="theme-text-success text-decoration-line-through">
              {gostermelikFiyatValue} TL
            </span>
            <span className="theme-text-success fw-bold">
              {indirimliFiyatValue} TL
            </span>
          </div>
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Otel ID",
      cell: (cell: any) => {
        const otelId = cell.getValue();

        return (
          <CustomButton
            variant="success"
            onClick={() => handleCalculatePriceForHotel(otelId)}
          >
            <i className="ri-find-replace-line"></i> Fiyat Hesapla
          </CustomButton>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
