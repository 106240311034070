import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";

import { handleChangeInput } from "slices/discountsAndActions/giftsAndPromotions/reducer";
import {
  GiftAndPromotionFormErrors,
  GiftAndPromotionState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";

interface MinimumNightsProps {
  values: GiftAndPromotionState;
  formErrors: GiftAndPromotionFormErrors;
  validateFormChange: (values: GiftAndPromotionState) => void;
}

const MinimumNights = ({
  values,
  validateFormChange,
  formErrors,
}: MinimumNightsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Minimum Gece"
        error={formErrors.min_gece_sayisi}
      >
        <CustomNumberSelector
          value={values.min_gece_sayisi}
          onChange={(value) => {
            dispatch(handleChangeInput({ min_gece_sayisi: value }));
            validateFormChange({ ...values, min_gece_sayisi: value });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default MinimumNights;
