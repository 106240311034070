import { CustomLoadingPage } from "Components/Custom/UI";
import CreateNewNotificationForm from "./CreateNewNotificationForm";

interface CreateNewNotificationPageProps {
  isLoading: boolean;
}

const CreateNewNotificationPage = ({ isLoading }: CreateNewNotificationPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <CreateNewNotificationForm />
      </div>
    </>
  );
};

export default CreateNewNotificationPage;
