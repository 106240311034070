import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListHotelsWithoutCommentInitialState } from "slices/comments/listHotelsWithoutComment/reducer";
import { getHotelsWithoutComment } from "slices/comments/listHotelsWithoutComment/thunk";

/*
  *@commenterOptions ->  value: personel, label: "Müşteri" || "Personel"
*/


const useListComments = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListHotelsWithoutComment: ListHotelsWithoutCommentInitialState}) => state.ListHotelsWithoutComment
  );


  useEffect(() => {
    dispatch(
      getHotelsWithoutComment({
        page: 1,
        per_page: 10,
        search: ""
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListComments;
