import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AddNewThemeInitialState } from "slices/themes/addNewTheme/reducer";
import {
  getThemeRegionOptions,
  getThemeTypesOptions,
} from "slices/themes/addNewTheme/thunk";

const useAddNewTheme = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { AddNewTheme: AddNewThemeInitialState }) => state.AddNewTheme
  );

  useEffect(() => {
    dispatch(getThemeRegionOptions());
    dispatch(getThemeTypesOptions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useAddNewTheme;
