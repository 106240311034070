import {
  SpecialDiscountFormErrors,
  SpecialDiscountState,
} from "types/DiscountsAndActions";

import { FormGroup, Input, Spinner } from "reactstrap";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  handleChangeInput,
  handleSaveAllHotels,
} from "slices/discountsAndActions/specialDiscounts/reducer";

import Select from "react-select";
import { useEffect, useState } from "react";
import { useHttpRequest } from "Components/Hooks";
import { GET_HOTEL_SUMMARY } from "helpers/url_helper";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useSelector } from "react-redux";

interface HotelSelectionAndOtherSwitchesProps {
  values: SpecialDiscountState;
  formErrors: SpecialDiscountFormErrors;
  validateFormChange: (values: SpecialDiscountState) => void;
  conceptsOptions: Option[];
  paymentMethodOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const HotelSelectionAndOtherSwitches = ({
  values,
  formErrors,
  validateFormChange,
  conceptsOptions,
  paymentMethodOptions,
}: HotelSelectionAndOtherSwitchesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [options, setOptions] = useState<Option[]>([]);

  const { data, isLoading, httpRequest } = useHttpRequest();

  const { hotel } = useSelector((state: any) => state.Hotel);

  const hotelId = hotel?.otel_id;

  const sel = [
    hotelId,
    ...values.secili_oteller.filter((otel_id) => otel_id !== hotelId),
  ];

  useEffect(() => {
    httpRequest({
      config: {
        method: "get",
        url: GET_HOTEL_SUMMARY,
      },
      successCallback: (response) => {
        dispatch(handleSaveAllHotels(response.data || response));
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const newOptions: Option[] = data.map((item: any) => {
        return {
          value: item.otel_id,
          label: item.otel_adi,
        };
      });
      setOptions(newOptions);
    }
  }, [data]);

  return (
    <>
      <CustomFormFieldContainer
        label="Yalnız Bu Otele Uygula"
        tooltipId="onlyThisHotel"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.yalniz_bu_otel === 1}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  yalniz_bu_otel: values.yalniz_bu_otel === 1 ? 0 : 1,
                })
              );
              dispatch(handleChangeInput({ secili_oteller: [] }));
              validateFormChange({
                ...values,
                yalniz_bu_otel: values.yalniz_bu_otel === 1 ? 0 : 1,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {!values.yalniz_bu_otel && (
        <CustomFormFieldContainer
          label="Seçili Oteller Uygula"
          error={formErrors.secili_oteller}
        >
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Select
              isMulti={true}
              value={
                sel.map((otel_id) => {
                  return options.find((option) => option.value === otel_id);
                }) || []
              }
              onChange={(selected: Option[]) => {
                const selectedHotels = selected.map((item) => item.value);
                dispatch(handleChangeInput({ secili_oteller: selectedHotels }));
                validateFormChange({
                  ...values,
                  secili_oteller: selectedHotels,
                });
              }}
              options={options}
              placeholder="Otel Seçiniz..."
            />
          )}
        </CustomFormFieldContainer>
      )}
      <CustomFormFieldContainer
        label="Tüm Odalara Uygula"
        tooltipId="applyAllRooms"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.grup_id === 0}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  grup_id: values.grup_id === 0 ? 1 : 0,
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Tüm Pansiyonlara Uygula"
        tooltipId="applyAllConcepts"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_pansiyonlar === 1}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_pansiyonlar: values.tum_pansiyonlar === 1 ? 0 : 1,
                })
              );
              dispatch(handleChangeInput({ secili_pansiyonlar: [] }));
              validateFormChange({
                ...values,
                tum_pansiyonlar: values.tum_pansiyonlar === 1 ? 0 : 1,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {!values.tum_pansiyonlar && (
        <CustomFormFieldContainer
          label="Seçili Pansiyonlara Uygula"
          error={formErrors.secili_pansiyonlar}
        >
          <Select
            isMulti={true}
            value={
              values.secili_pansiyonlar.map((pansiyon_id) => {
                return conceptsOptions.find(
                  (option) => option.value === pansiyon_id
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedRooms = selected.map((item) => item.value);
              dispatch(
                handleChangeInput({ secili_pansiyonlar: selectedRooms })
              );
              validateFormChange({
                ...values,
                secili_pansiyonlar: selectedRooms,
              });
            }}
            options={conceptsOptions}
            placeholder="Pansiyon Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
      <CustomFormFieldContainer
        label="Tüm Odeme Tiplerine Uygula"
        tooltipId="applyAllPaymentMethods"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.tum_odeme_tipleri === 1}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  tum_odeme_tipleri: values.tum_odeme_tipleri === 1 ? 0 : 1,
                })
              );
              dispatch(handleChangeInput({ secili_odeme_tipleri: [] }));
              validateFormChange({
                ...values,
                tum_odeme_tipleri: values.tum_odeme_tipleri === 1 ? 0 : 1,
              });
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.tum_odeme_tipleri === 0 && (
        <CustomFormFieldContainer
          label="Seçili Odeme Tiplerine Uygula"
          error={formErrors.secili_odeme_tipleri}
        >
          <Select
            isMulti={true}
            value={
              values.secili_odeme_tipleri.map((paymentMethod) => {
                return paymentMethodOptions.find(
                  (option) => option.value === paymentMethod
                );
              }) || []
            }
            onChange={(selected: Option[]) => {
              const selectedPaymentMethods = selected.map((item) => item.value);
              dispatch(
                handleChangeInput({
                  secili_odeme_tipleri: selectedPaymentMethods,
                })
              );
              validateFormChange({
                ...values,
                secili_odeme_tipleri: selectedPaymentMethods,
              });
            }}
            options={paymentMethodOptions}
            placeholder="Ödeme Tipi Seçiniz..."
          />
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default HotelSelectionAndOtherSwitches;
