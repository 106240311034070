import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";
import {
  getFacilityAvailabilities,
  updateAdsStatus,
  updateDescription,
  updateHotelInfo,
} from "slices/adsAndAnalyze/competitorAnalysis/thunk";

type SuccessCallback = () => void;

const useHandleCard = () => {
  const {
    competitorAnalysisResponse,
    facilityAvailabilities,
    availabilityLoading,
    updateDescriptionLoading,
    updateHotelInfoLoading,
    updateAdsStatusLoading,
    isEb,
  } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const dispatch: Dispatch<any> = useDispatch();

  const cards = competitorAnalysisResponse.data;

  const handleGetFacilityAvailabilities = (otel_id: number) => {
    dispatch(getFacilityAvailabilities(otel_id));
  };

  const handleUpdateDescription = (
    id: number,
    analiz_aciklama_data: string
  ) => {
    dispatch(updateDescription({ id, analiz_aciklama_data }));
  };

  const handleUpdateAdsDescription = (otel_id: number, reklam_data: string) => {
    dispatch(updateDescription({ otel_id, reklam_data }));
  };

  const handleUpdateAdsStatus = (
    group_id: number,
    status: 0 | 1,
    successCallback: SuccessCallback
  ) => {
    const payload = {
      group_id,
      status,
    };
    dispatch(updateAdsStatus(payload, successCallback));
  };

  const handleUpdateHotelInfo = (id: number) => {
    dispatch(updateHotelInfo(id, isEb));
  };

  return {
    cards,
    facilityAvailabilities,
    availabilityLoading,
    updateDescriptionLoading,
    updateHotelInfoLoading,
    updateAdsStatusLoading,
    onGetFacilityAvailabilities: handleGetFacilityAvailabilities,
    onUpdateDescription: handleUpdateDescription,
    onUpdateAdsDescription: handleUpdateAdsDescription,
    onUpdateAdsStatus: handleUpdateAdsStatus,
    onUpdateHotelInfo: handleUpdateHotelInfo,
  };
};

export default useHandleCard;
