import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { FilterLogsPayload } from "types/userOperations";

interface DateSelectorProps {
  values: FilterLogsPayload;
  handleFormChange: (key: string, value: string) => void;
}

const DateSelector = ({ values, handleFormChange }: DateSelectorProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormFieldContainer label="Filtrelemek İçin Tarih Gir :">
      <div className="d-flex gap-4">
        <div
          style={{
            width: "300px",
          }}
        >
          <Flatpickr
            className="form-control"
            value={[values.start_date, values.end_date]}
            onChange={(dates) => {
              const [startDate, endDate] = dates;
              if (startDate && endDate) {
                const stringStartDate = moment(startDate).format("YYYY-MM-DD");
                const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                handleFormChange("start_date", stringStartDate);
                handleFormChange("end_date", stringEndDate);
              }
            }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              showMonths: 2,
              locale: Turkish,
              onDayCreate,
            }}
            placeholder="Filtrelemek İçin Tarih Seçiniz..."
          />
        </div>
      </div>
    </CustomFormFieldContainer>
  );
};

export default DateSelector;
