import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import {
  AddAdditionalPagesFormErrors,
  UpdateAdditionalPageState,
} from "types/myWebSite";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";

interface ContentImageProps {
  values: UpdateAdditionalPageState;
  formErrors: AddAdditionalPagesFormErrors;
  validateFormChange: (values: UpdateAdditionalPageState) => void;
}

const ContentImage = ({
  values,
  validateFormChange,
  formErrors,
}: ContentImageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.organizasyon_kart_resim
    ? [values.organizasyon_kart_resim]
    : [];

  const imageUrl = values.organizasyon_kart_resim_onizleme;

  const { id } = values;

  return (
    <>
      <CustomFormFieldContainer
        label="İçerik Resmi : "
        error={formErrors.organizasyon_kart_resim}
        required
        divider
        orientation={id ? "vertical" : "horizontal"}
      >
        <div className="d-flex w-100 gap-5">
          {id ? (
            imageUrl ? (
              <div
                className="flex-grow-1"
                style={{ height: "200px" }}
              >
                <img
                  src={imageUrl}
                  alt="Resim"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : (
              <p>Resim Bulunamadı</p>
            )
          ) : (
            <></>
          )}
          <div className="flex-grow-1">
            <CustomMultipleFileUploader
              files={files as any}
              handleAddFiles={(fileItems) => {
                dispatch(
                  handleChangeAddAdditionalPageInput({
                    organizasyon_kart_resim: fileItems[0],
                  })
                );
                validateFormChange({
                  ...values,
                  organizasyon_kart_resim: fileItems[0],
                });
              }}
              maxFiles={1}
              maxFileSize={10000000}
            />
          </div>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default ContentImage;
