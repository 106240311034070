import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setToastSuccess } from "slices/toast/reducer";

const SendSmsButton = () => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div className="d-flex align-items-center gap-4 p-5">
      <span className="fw-semibold">Havale SMS At</span>
      <CustomButton
        variant="primary"
        onClick={() => {
          dispatch(setToastSuccess("SMS gönderildi"));
        }}
      >
        SMS Gönder
      </CustomButton>
    </div>
  );
};

export default SendSmsButton;
