import moment from "moment";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { EnterReservationFormValues } from "types/reservation";

type HandleGoToEnterReservationPage = (payload: {
  odeme_tipi: string;
  fiyat: number;
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
}) => void;

const useHandlePayloadForEnterReservation = () => {
  const { reservationCall, calculationForm, calculatePriceForHotel } =
    useSelector(
      (state: { ReservationCall: ReservationCallInitialState }) =>
        state.ReservationCall
    );

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const hasCustomer = aranan && arayan && dahili && reservationCall.musteri_id;

  const handleGoToEnterReservationPage: HandleGoToEnterReservationPage = ({
    odeme_tipi,
    fiyat,
    oda_id,
    oda_adi,
    pansiyon_id,
    pansiyon_adi,
  }) => {
    const hotel = Object.values(calculatePriceForHotel.fiyatlar)[0];

    const selectedRoom = hotel.odalar.find((oda) => oda.oda_id === oda_id)!;

    const selectedConcept = selectedRoom.pansiyonlar.find(
      (pansiyon) => pansiyon.pansiyon_id === pansiyon_id
    )!;

    const paymentType = odeme_tipi === "kismi" ? "normal" : odeme_tipi;

    const selectedPayment = selectedConcept.fiyatlar[paymentType]!;

    const selectedPaymentPrices = Object.entries(selectedPayment)
      .filter((payment) => payment[0].includes(paymentType))
      .map((payment) => ({
        [payment[0].replace(paymentType + "_", "")]: payment[1],
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const secilen_ozel_indirimler = selectedRoom.secilen_ozel_indirimler || [];

    const values: EnterReservationFormValues = {
      otel_id: parseInt(hotel.otel_id),
      otel_adi: hotel.otel_adi,
      giris_tarihi: moment(calculationForm.giris_tarihi, "DD MMMM YYYY").format(
        "YYYY-MM-DD"
      ),
      cikis_tarihi: moment(calculationForm.cikis_tarihi, "DD MMMM YYYY").format(
        "YYYY-MM-DD"
      ),
      giris_saati: "",
      musteri_ad_soyad: hasCustomer ? reservationCall.musteri_ad_soyad : "",
      musteri_tel:
        hasCustomer && reservationCall.musteri_tel
          ? reservationCall.musteri_tel
          : "",
      musteri_tel_2:
        hasCustomer && reservationCall.musteri_tel_2
          ? reservationCall.musteri_tel_2
          : "",
      musteri_eposta:
        hasCustomer && reservationCall.musteri_eposta
          ? reservationCall.musteri_eposta
          : "",
      musteri_tc: hasCustomer ? reservationCall.musteri_tc ?? "" : "",
      yetiskin_sayisi: calculationForm.yetiskin_sayisi,
      cocuk_sayisi: calculationForm.cocuk_sayisi,
      cocuk_dogum_1: calculationForm.cocuk_dogum_1 || "",
      cocuk_dogum_2: calculationForm.cocuk_dogum_2 || "",
      cocuk_dogum_3: calculationForm.cocuk_dogum_3 || "",
      cocuk_dogum_4: calculationForm.cocuk_dogum_4 || "",
      parapuan_secim: calculationForm.parapuan_secim,
      ozel_indirimler: calculationForm.ozel_indirimler,
      // New values
      odeme_tipi,
      fiyat,
      oda_pansiyon_tipi: `${oda_id}-${pansiyon_id}`,
      arama_id: hasCustomer ? reservationCall.arama_id : 0,
      musteri_id: hasCustomer ? reservationCall.musteri_id : 0,
      geri_arama_id: hasCustomer ? reservationCall.geri_arama_id : 0,
      geri_arama_tipi: hasCustomer ? reservationCall.geri_arama_tipi : "",
      ozel_indirim: calculatePriceForHotel.ozel_indirim,
      parapuan_toplam_tutar: calculatePriceForHotel.parapuan_toplam_tutar,
      secilen_ozel_indirimler,
      iptal_sigortasi_durum: calculatePriceForHotel.iptal_sigortasi_durum,
      iptal_sigortasi_gun: calculatePriceForHotel.iptal_sigortasi_gun,

      //Values for Normal Reservation
      oda_id,
      oda_adi,
      pansiyon_id,
      pansiyon_adi,
      tedarikci: calculatePriceForHotel.tedarikci,
      karsilama_kodu: hotel.karsilama_kodu,
      fiyat_hesaplama: hotel.fiyat_hesaplama,
      para_birimi: hotel.para_birimi,
      kur: hotel.kur,
      kur_degeri: hotel.kur_degeri,
      komisyon_orani: hotel.komisyon_orani,
      indirim_orani: hotel.indirim_orani,
      cari: calculatePriceForHotel.cari,
      stop_durum: selectedPayment.stop_durum,
      ucretli_cocuk_durum: selectedPayment.ucretli_cocuk_durum,
      verilen_hediyeler: selectedConcept.verilen_hediyeler,
      otele_odeme_gunu: selectedRoom.otele_odeme_gunu,
      fiyat_saglayici: hotel.fiyat_saglayici,
      fiyatlar: {
        brut_tutar: selectedPaymentPrices.toplam,
        indirimli_tutar: selectedPaymentPrices.indirimli,
        maliyet_tutar: selectedPaymentPrices.maliyet,
        brut_tutar_kur: selectedPaymentPrices.brut_maliyet_kur,
        indirimli_tutar_kur: selectedPaymentPrices.indirimli_kur,
        maliyet_tutar_kur: selectedPaymentPrices.maliyet_kur,
        iptal_sigortasi_fiyat: selectedPaymentPrices.iptal_sigortasi_fiyat,
      },
      acente_komisyon_orani: hotel.acente_komisyon_orani,
      uygulanan_indirimler: selectedConcept.uygulanan_indirimler,

      //Values for Kismi Reservation
      kismi_odeme: hotel.kismi_odeme,
      kismi_odeme_orani: hotel.kismi_odeme_orani,
      kismi_odeme_tarihi: hotel.kismi_odeme_tarihi,

      //Values for Kapida Reservation
      kapida_odeme_komisyon_orani: Number(hotel.kapida_komisyon_orani || "0"),

      //Values for Kapora Reservation
      kaporali_satis_orani: Number(hotel.kaporali_satis_orani || "0"),
    };

    localStorage.setItem("enterReservationFormValues", JSON.stringify(values));

    window.open(
      `/rezervasyon-girisi?odeme_tipi=${odeme_tipi}&fiyat=${Math.ceil(fiyat)}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return {
    onClick: handleGoToEnterReservationPage,
  };
};

export default useHandlePayloadForEnterReservation;
