import { createSlice } from "@reduxjs/toolkit";

import { CustomerMail } from "types/reports";

export interface CustomerMailsInitialState {
  customerMails: CustomerMail[];
  isLoading: boolean;
  error: string;
}

const initialState: CustomerMailsInitialState = {
  customerMails: [],
  isLoading: false,
  error: "",
};

const customerMailsSlice = createSlice({
  name: "customerMails",
  initialState,
  reducers: {
    handleSaveCustomerMails(state, action) {
      state.customerMails = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveCustomerMails, setIsLoading, setError } =
  customerMailsSlice.actions;

export default customerMailsSlice.reducer;
