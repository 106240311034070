import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityPartialPaymentDatesInitialState } from "slices/facility/facilityPartialPaymentDates/reducer";
import { getFacilityPartialPaymentDates } from "slices/facility/facilityPartialPaymentDates/thunk";
import { PaginationBottom } from "types/GlobalTypes";

let firstRender = true;

interface GetFacilityPartialPaymentDatesParams {
  page: number;
  per_page: number;
  search: string;
}

const useHandlePagination = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [paginationTop, setPaginationTop] = useState({
    per_page: 10,
    search: "",
  });

  const { pagination } = useSelector(
    (state: {
      FacilityPartialPaymentDates: FacilityPartialPaymentDatesInitialState;
    }) => state.FacilityPartialPaymentDates
  );

  const handleClickNextPage = () => {
    const params: GetFacilityPartialPaymentDatesParams = {
      ...paginationTop,
      page: pagination.current_page + 1,
    };

    pagination.next_page_url &&
      dispatch(getFacilityPartialPaymentDates(params));
  };

  const handleClickPrevPage = () => {
    const params: GetFacilityPartialPaymentDatesParams = {
      ...paginationTop,
      page: pagination.current_page - 1,
    };

    pagination.prev_page_url &&
      dispatch(getFacilityPartialPaymentDates(params));
  };

  const paginationBottomProps: PaginationBottom = {
    total: pagination.total,
    current_page: pagination.current_page,
    per_page: pagination.per_page,
    prev_page_url: pagination.prev_page_url,
    next_page_url: pagination.next_page_url,
    onClickNextPage: handleClickNextPage,
    onClickPrevPage: handleClickPrevPage,
  };

  const handleChange = (key: string, value: number | string) => {
    if (firstRender) {
      firstRender = false;
      return;
    }
    setPaginationTop((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    const params: GetFacilityPartialPaymentDatesParams = {
      ...paginationTop,
      [key]: value,
      page: 1,
    };

    dispatch(getFacilityPartialPaymentDates(params));
  };

  return { handleChange, paginationBottomProps };
};

export default useHandlePagination;
