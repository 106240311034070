import { CustomButton } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  addFacilityUser,
  getFacilityUser,
} from "slices/facility/facilityUsers/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  AddFacilityUserRequestPayload,
  FacilityUserState,
} from "types/facility";

interface AddNewUserButtonProps {
  values: FacilityUserState;
  requestPayload: AddFacilityUserRequestPayload;
  validateForm: (values: FacilityUserState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddNewUserButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddNewUserButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );
  const { otel_id } = hotel;

  const handleAddNewUser = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(
        addFacilityUser(requestPayload, () => {
          handleSubmitted(false);
          dispatch(getFacilityUser(otel_id));
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end mt-4 gap-2">
      <CustomButton
        variant="success"
        onClick={handleAddNewUser}
        isLoading={isLoading}
      >
        Yeni Kullanıcı Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewUserButton;
