import { CustomFormFieldContainer } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { PERSONNEL_ASSIGNEE_MAIN_AUTHORITY_OPTION_DESCRIPTION } from "utils/fieldDescriptions";
import { Input, Label } from "reactstrap";
import { AssignHotelToUserPayload } from "types/userOperations";
import {
  handleAssignHotelToUser,
  PersonnelHotelAssignmentsInitialState,
} from "slices/userOperations/personnelHotelAssignments/reducer";

// * It is not a selector because in future we might give user to main authority of more than one hotel
interface MainAuthorityOptionsProps {
  values: { value: string; label: string }[];
  validateFormChange: (values: AssignHotelToUserPayload) => void;
  maxSelection: number;
}

const MainAuthorityOptions = ({
  values,
  validateFormChange,
  maxSelection,
}: MainAuthorityOptionsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { assignHotelToUser } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const handleCheckboxChange = (hotelId: string, isChecked: boolean) => {
    const numericHotelId = Number(hotelId);

    const updatedFormValues: AssignHotelToUserPayload = {
      ...assignHotelToUser,
      ana_yetkili_otel_id: isChecked ? numericHotelId : 0,
    };

    dispatch(handleAssignHotelToUser(updatedFormValues));

    validateFormChange(updatedFormValues);
  };

  return (
    <div style={{ margin: "32px 0" }}>
      <CustomFormFieldContainer
        label="Ana Yetkili Olarak Ata : "
        description={PERSONNEL_ASSIGNEE_MAIN_AUTHORITY_OPTION_DESCRIPTION}
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {values.map((option) => {
            const id = `authority-${option.value}`;
            const hotelId = Number(option.value);
            const isChecked = assignHotelToUser.ana_yetkili_otel_id === hotelId;
            const isDisabled =
              assignHotelToUser.ana_yetkili_otel_id !== 0 &&
              assignHotelToUser.ana_yetkili_otel_id !== hotelId &&
              maxSelection === 1;

            return (
              <div key={option.value} className="d-flex gap-2">
                <Input
                  type="checkbox"
                  id={id}
                  checked={isChecked}
                  disabled={isDisabled}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleCheckboxChange(option.value, isChecked);
                  }}
                />
                <Label
                  htmlFor={id}
                  className="fw-normal fs-14"
                >
                  {option.label}
                </Label>
              </div>
            );
          })}
        </div>
      </CustomFormFieldContainer>
    </div>
  );
};

export default MainAuthorityOptions;
