import {
  GeneralPriceSettingFormErrors,
  GeneralPriceSettingFormState,
  Months,
} from "types/PricesQuotas";

import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeGeneralPriceSettingsInput } from "slices/pricesAndQuota/generalPriceSettings/reducer";

import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

interface KismiPaymentTypeProps {
  values: GeneralPriceSettingFormState;
  formErrors: GeneralPriceSettingFormErrors;
  validateFormChange: (values: GeneralPriceSettingFormState) => void;
  kismiPaymentDaysOptions: Option[];
  monthsOptions: MonthsOption[];
  onAddPayment: (paymentType: string) => void;
  checkPaymentTypes: (
    kismi: boolean,
    kapida: boolean,
    kaporali: boolean
  ) => boolean;
}

interface Option {
  value: string;
  label: string;
}

interface MonthsOption {
  value: Months;
  label: string;
}

const KismiPaymentType = ({
  values,
  formErrors,
  validateFormChange,
  kismiPaymentDaysOptions,
  monthsOptions,
  checkPaymentTypes,
}: KismiPaymentTypeProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();
  return (
    <>
      <CustomFormFieldContainer label="Kısmi Ödeme">
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.kismi_odeme}
            onChange={(e) => {
              if (e.target.checked) {
                const valid = checkPaymentTypes(
                  true,
                  values.kapida_odeme === "evet",
                  values.kaporali_satis === "evet"
                );

                valid &&
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kismi_odeme: e.target.checked,
                    })
                  );
              } else {
                dispatch(
                  handleChangeGeneralPriceSettingsInput({
                    kismi_odeme: e.target.checked,
                    kismi_baslangic_donemi: "",
                    kismi_bitis_donemi: "",
                  })
                );
              }
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
      {values.kismi_odeme && (
        <>
          <CustomFormFieldContainer label="Kısmi Ödeme Günü">
            <div className="d-flex align-items-center gap-5">
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={values.kismi_odeme_gun_once === 1}
                  onChange={(e) => {
                    dispatch(
                      handleChangeGeneralPriceSettingsInput({
                        kismi_odeme_gun_once: e.target.checked ? 1 : 0,
                      })
                    );
                  }}
                />
              </FormGroup>

              <div className="d-flex align-items-center gap-2">
                <span>Çekim</span>
                <Select
                  className="flex-grow-1"
                  value={
                    kismiPaymentDaysOptions.find(
                      (option) =>
                        parseInt(option.value) ===
                        values.kismi_odeme_gun_once_gun
                    ) || null
                  }
                  onChange={(selectedOption: Option) => {
                    dispatch(
                      handleChangeGeneralPriceSettingsInput({
                        kismi_odeme_gun_once_gun: parseInt(
                          selectedOption.value
                        ),
                      })
                    );

                    validateFormChange({
                      ...values,
                      kismi_odeme_gun_once_gun: parseInt(selectedOption.value),
                    });
                  }}
                  options={kismiPaymentDaysOptions}
                  placeholder="Gün Seçiniz"
                />
                <span>Önce</span>
              </div>
            </div>
          </CustomFormFieldContainer>
          {values.kismi_odeme_gun_once === 0 && (
            <CustomFormFieldContainer label="Kısmi Ödeme Çekim Günü">
              <Flatpickr
                className="form-control"
                value={values.kismi_odeme_cekim_tarihi}
                onChange={(date) => {
                  const [startDate] = date;

                  if (startDate) {
                    const stringStartDate =
                      moment(startDate).format("YYYY-MM-DD");

                    dispatch(
                      handleChangeGeneralPriceSettingsInput({
                        kismi_odeme_cekim_tarihi: stringStartDate,
                      })
                    );
                  }
                }}
                options={{
                  dateFormat: "Y-m-d",
                  showMonths: 1,
                  locale: Turkish,
                  onDayCreate,
                }}
              />
            </CustomFormFieldContainer>
          )}
          <CustomFormFieldContainer label="Kısmi Ödeme Periyodu">
            <div className="d-flex align-items-center gap-3">
              <Select
                className="flex-grow-1"
                value={
                  monthsOptions.find(
                    (option) => option.value === values.kismi_baslangic_donemi
                  ) || null
                }
                onChange={(selectedOption: MonthsOption) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kismi_baslangic_donemi: selectedOption.value,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kismi_baslangic_donemi: selectedOption.value,
                  });
                }}
                options={monthsOptions}
                placeholder="Periyod Seçiniz"
              />
              <Select
                className="flex-grow-1"
                value={
                  monthsOptions.find(
                    (option) => option.value === values.kismi_bitis_donemi
                  ) || null
                }
                onChange={(selectedOption: MonthsOption) => {
                  dispatch(
                    handleChangeGeneralPriceSettingsInput({
                      kismi_bitis_donemi: selectedOption.value,
                    })
                  );

                  validateFormChange({
                    ...values,
                    kismi_bitis_donemi: selectedOption.value,
                  });
                }}
                options={monthsOptions}
                placeholder="Periyod Seçiniz"
              />
            </div>
          </CustomFormFieldContainer>
        </>
      )}
    </>
  );
};

export default KismiPaymentType;
