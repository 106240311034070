import { createSlice } from "@reduxjs/toolkit";

import { EmployeeSale } from "types/reports";

export interface EmployeeSalesAmountInitialState {
  employeeSalesAmount: EmployeeSale[];
  isLoading: boolean;
  error: string;
}

const initialState: EmployeeSalesAmountInitialState = {
  employeeSalesAmount: [],
  isLoading: false,
  error: "",
};

const employeeSalesAmountSlice = createSlice({
  name: "employeeSalesAmount",
  initialState,
  reducers: {
    handleSaveEmployeeSalesAmount(state, action) {
      state.employeeSalesAmount = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveEmployeeSalesAmount, setIsLoading, setError } =
  employeeSalesAmountSlice.actions;

export default employeeSalesAmountSlice.reducer;
