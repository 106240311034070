import { createSlice } from "@reduxjs/toolkit";
import {
  ReservationChangeApproveCancelResponse,
  ResetReservationAmountResponse,
} from "types/userOperations";

export interface ReservationChangeApproveCancelInitialState {
  formChoices: ReservationChangeApproveCancelResponse[];
  reservationAmounts: ResetReservationAmountResponse[];
  isLoading: boolean;
  error: string;
  changeApproveCancelLoading: boolean;
  changeApproveCancelError: string;
  resetReservationAmountLoading: boolean;
  resetReservationAmountError: string;
}

export const reservationChangeApproveCancelInitialState: ReservationChangeApproveCancelInitialState =
  {
    formChoices: [],
    reservationAmounts: [],
    isLoading: false,
    error: "",
    changeApproveCancelLoading: false,
    changeApproveCancelError: "",
    resetReservationAmountLoading: false,
    resetReservationAmountError: "",
  };

const reservationChangeApproveCancelSlice = createSlice({
  name: "reservationChangeApproveCancel",
  initialState: reservationChangeApproveCancelInitialState,
  reducers: {
    handleSaveFormChoicesResponse(state, action) {
      const { islem } = action.payload;
      state.formChoices = islem;
    },
    handleSaveReservationAmountsResponse(state, action) {
      state.reservationAmounts = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setChangeApproveCancelLoading(state, action) {
      state.changeApproveCancelLoading = action.payload;
    },
    setChangeApproveCancelError(state, action) {
      state.changeApproveCancelError = action.payload;
    },
    setResetReservationAmountLoading(state, action) {
      state.resetReservationAmountLoading = action.payload;
    },
    setResetReservationAmountError(state, action) {
      state.resetReservationAmountError = action.payload;
    },
  },
});

export const {
  handleSaveFormChoicesResponse,
  handleSaveReservationAmountsResponse,
  setIsLoading,
  setError,
  setChangeApproveCancelLoading,
  setChangeApproveCancelError,
  setResetReservationAmountLoading,
  setResetReservationAmountError,
} = reservationChangeApproveCancelSlice.actions;
export default reservationChangeApproveCancelSlice.reducer;
