import { CustomLoadingPage } from "Components/Custom/UI";
import TransferReservationForm from "./TransferReservationForm";


interface TransferReservationPageProps {
  isLoading: boolean;
}

const TransferReservationPage = ({ isLoading }: TransferReservationPageProps) => {
  return (
    <>
    <CustomLoadingPage isLoading={isLoading}/>
      <div className="d-flex flex-column gap-4">
        <TransferReservationForm/>
      </div>
    </>
  );
};

export default TransferReservationPage;
