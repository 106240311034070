import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DateSelector from "./FormFields/DateSelector";
import PageTitleSelector from "./FormFields/PageTitleSelector";
import UserSelector from "./FormFields/UserSelector";
import FilterLogsButtons from "./FormFields/FilterLogsButtons";

const DatePageAndUserFilterForm = () => {
  const {
    filterForm,
    personnelOptions,
    pageOptions,
    handleFormChange,
    handleFilter,
    handleClearFilter,
  } = useHandleForm();

  return (
    <>
      <CustomFormContainer title="Sonuçları Filtrele">
        <DateSelector 
          values={filterForm} 
          handleFormChange={handleFormChange} 
        />
        <PageTitleSelector
          options={pageOptions}
          values={filterForm}
          handleFormChange={handleFormChange}
        />
        <UserSelector
          options={personnelOptions}
          values={filterForm}
          handleFormChange={handleFormChange}
        />
        <FilterLogsButtons
          handleFilter={handleFilter}
          handleClearFilter={handleClearFilter}
        />
      </CustomFormContainer>
    </>
  );
};

export default DatePageAndUserFilterForm;
