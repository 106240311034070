import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/discountsAndActions/earlyReservationDiscounts/reducer";
import {
  EarlyReservationDiscountFormErrors,
  EarlyReservationDiscountState,
} from "types/DiscountsAndActions";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { FormGroup, Input } from "reactstrap";

interface MinNightsProps {
  values: EarlyReservationDiscountState;
  formErrors: EarlyReservationDiscountFormErrors;
  validateFormChange: (values: EarlyReservationDiscountState) => void;
}

const MinNights = ({
  values,
  validateFormChange,
  formErrors,
}: MinNightsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Minimum Gece"
        tooltipId="earlyReservationDiscountsMinNights"
        error={formErrors.min_gece_sayisi}
      >
        <CustomNumberSelector
          value={values.minimum_gece}
          onChange={(value) => {
            dispatch(handleChangeInput({ minimum_gece: value }));
            validateFormChange({ ...values, minimum_gece: value });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Kumulatif Uygula"
        tooltipId="earlyReservationDiscountsCumulative"
      >
        <FormGroup switch>
          <Input
            type="switch"
            checked={values.kumulatif_durum === "1"}
            onChange={() => {
              dispatch(
                handleChangeInput({
                  kumulatif_durum: values.kumulatif_durum === "1" ? "0" : "1",
                })
              );
            }}
          />
        </FormGroup>
      </CustomFormFieldContainer>
    </>
  );
};

export default MinNights;
