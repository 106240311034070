import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { DailyCallsForHotelsInitialState } from "slices/reports/dailyCallsForHotels/reducer";
import { getDailyCallsForHotels } from "slices/reports/dailyCallsForHotels/thunk";

const useDailyCallsForHotels = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { DailyCallsForHotels: DailyCallsForHotelsInitialState }) =>
      state.DailyCallsForHotels
  );

  useEffect(() => {
    const params = {
      date: moment().format("YYYY-MM-DD"),
    };

    dispatch(getDailyCallsForHotels(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useDailyCallsForHotels;
