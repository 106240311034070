import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { RoomsLackDescriptionInitialState } from "slices/reports/roomsLackDescription/reducer";
import { getRoomsLackDescription } from "slices/reports/roomsLackDescription/thunk";

const useRoomsLackDescription = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { RoomsLackDescription: RoomsLackDescriptionInitialState }) =>
      state.RoomsLackDescription
  );

  useEffect(() => {
    dispatch(getRoomsLackDescription());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useRoomsLackDescription;
