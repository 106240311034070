import PersonnelHotelAssignmentsPage from 'Components/Custom/UserOperationsPages/PersonnelHotelAssignmentsPage';
import usePersonnelHotelAssignments from 'Components/Hooks/userOperations/usePersonnelHotelAssignments';
import PageContainer from 'pages/PageContainer';


const PersonnelHotelAssignments = () => {

    const { isLoading } = usePersonnelHotelAssignments();

  return (
    <PageContainer title="Personel Otel Görevleri">
        <PersonnelHotelAssignmentsPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default PersonnelHotelAssignments