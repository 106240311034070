import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  handleResetOrderedHotelsToDefault,
  handleSaveOrderedHotels,
  OrderThemeHotelsInitialState,
} from "slices/themes/orderThemeHotels/reducer";
import {
  addOrderThemeHotels,
  getOrderThemeHotels,
} from "slices/themes/orderThemeHotels/thunk";
import { OrderThemeHotelsRequestPayload } from "types/themes";

const useHandleList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { orderedHotels, selectedTheme, addOrderThemeHotelLoading } =
    useSelector(
      (state: { OrderThemeHotels: OrderThemeHotelsInitialState }) =>
        state.OrderThemeHotels
    );
console.log("orderedHotels : ", orderedHotels)
  const handleOrderItems = (items: any[]) => {
    dispatch(handleSaveOrderedHotels(items));
  };

  const handleSave = () => {
    const payload: OrderThemeHotelsRequestPayload = {
      tema_id: selectedTheme,
      oteller: orderedHotels.map((hotel) => ({
        otel_id: hotel.otel_id,
        sira: hotel.sira,
      })),
    };

    dispatch(addOrderThemeHotels(payload));

    setTimeout(() => {
      dispatch(getOrderThemeHotels({ tema_id: selectedTheme }));
    }, 1000);
  };

  const handleResetChanges = () => {
    dispatch(handleResetOrderedHotelsToDefault());
  };

  return {
    orderedHotels,
    handleOrderItems,
    handleSave,
    handleResetChanges,
    isLoading: addOrderThemeHotelLoading,
  };
};

export default useHandleList;
