import { createSlice } from "@reduxjs/toolkit";

import { SearchedHotel } from "types/hotelSearchBar";

interface InitialState {
  searchResults: SearchedHotel[];
  selectedHotel: SearchedHotel;
  isLoading: boolean;
  error: string;
}

export const initialState: InitialState = {
  searchResults: [],
  selectedHotel: {
    otel_id: "",
    otel_adi: "",
  },
  isLoading: false,
  error: "",
};

const hotelSearchBarSlice = createSlice({
  name: "hotelSearchBar",
  initialState,
  reducers: {
    handleSaveSearchResults(state, action) {
      state.searchResults = action.payload;
    },
    handleSelectHotel(state, action) {
      state.selectedHotel = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveSearchResults,
  handleSelectHotel,
  setIsLoading,
  setError,
} = hotelSearchBarSlice.actions;

export default hotelSearchBarSlice.reducer;
