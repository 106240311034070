import { createSlice } from "@reduxjs/toolkit";
import {
  AddCommentFormErrors,
  AddCommentState,
  Category,
  ListComments,
  ListCommentsResponse,
  ScoreField,
} from "types/comments";

import { Pagination } from "types/GlobalTypes";

export interface AddCommentInitialState {
  comments: ListComments[];
  addCommentResponse: ListCommentsResponse;
  commentCategories: string[];
  commentQuestions: ScoreField[];
  commentPuanlama: Record<string, number>;
  comment: AddCommentState;
  pagination: Pagination;
  addCommentFormErrors: AddCommentFormErrors;
  isLoading: boolean;
  error: string;
  addCommentLoading: boolean;
  addCommentError: string;
}

export const addCommentInitialState: AddCommentInitialState = {
  comments: [],
  addCommentResponse: {
    data: [],
    sabit_datalar: {},
  },
  commentCategories: [],
  commentQuestions: [],
  commentPuanlama: {},
  comment: {
    kategori: "",
    musteri_adi: "",
    musteri_adi_gosterim: "true",
    otel_id: 0,
    otel_adi: "",
    giris_tarihi: "",
    cikis_tarihi: "",
    yorum_olumlu: "",
    yorum_olumsuz: "",
    manuel_yazan: "",
    cevap1: "",
    cevap2: "",
    cevap3: "",
    cevap4: "",
    cevap5: "",
    cevap6: "",
    tarih: "",
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  addCommentFormErrors: {
    musteri_adi_gosterim: ""
  },
  isLoading: false,
  error: "",
  addCommentLoading: false,
  addCommentError: "",
};

const addCommentSlice = createSlice({
  name: "addComment",
  initialState: addCommentInitialState,
  reducers: {
    handleSaveCommentResponse(state, action) {
      const { data, sabit_datalar, ...rest } = action.payload;
      // console.log("Sabit Datalar : ", sabit_datalar)
      state.addCommentResponse = action.payload;
      state.comment = data;
      state.commentCategories = sabit_datalar;
      state.pagination = { ...rest };
    },
    handleSaveCommentQuestions(state, action) {
      const { kategori, puanlama, sorular } = action.payload;
      console.log("Reducer received data:", action.payload);
      state.commentCategories = kategori;
      state.commentQuestions = sorular ? Object.values(sorular) : []; //I'll map it, so it need to be an array
      state.commentPuanlama = puanlama;
    },
    handleChangeCommentInput(state, action) {
      console.log("Comment Input is changing : ", action.payload);
      state.comment = {
        ...state.comment,
        ...action.payload,
      };
    },
    handleAddCommentFormErrors(state, action) {
      state.addCommentFormErrors = action.payload;
    },
    resetComment(state) {
      state.comment = {
        kategori: Category.DIGER,
        musteri_adi: "",
        musteri_adi_gosterim: "true",
        otel_id: 0,
        otel_adi: "",
        giris_tarihi: "",
        cikis_tarihi: "",
        yorum_olumlu: "",
        yorum_olumsuz: "",
        manuel_yazan: "admin",
        cevap1: "",
        cevap2: "",
        cevap3: "",
        cevap4: "",
        cevap5: "",
        cevap6: "",
        tarih: "",
      };
      state.addCommentFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddCommentLoading(state, action) {
      state.addCommentLoading = action.payload;
    },
    setAddCommentError(state, action) {
      state.addCommentError = action.payload;
    },
  },
});

export const {
  handleSaveCommentResponse,
  handleSaveCommentQuestions,
  handleChangeCommentInput,
  handleAddCommentFormErrors,
  resetComment,
  setIsLoading,
  setError,
  setAddCommentLoading,
  setAddCommentError,
} = addCommentSlice.actions;

export default addCommentSlice.reducer;
