import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getCompetitorAnalysisInfo,
  updateCompetitorAnalysisInfo,
} from "slices/adsAndAnalyze/competitorAnalysisInfo/thunk";

import {
  CompetitorAnalysisInfo,
  CompetitorAnalysisInfoFormState,
} from "types/adsAndAnalyze";

const useHandleForm = (defaultValues: CompetitorAnalysisInfo) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formValues, setFormValues] = useState<CompetitorAnalysisInfoFormState>(
    {
      id: defaultValues.id,
      analiz_tipi: defaultValues.analiz_tipi,
      analiz_tipi_adi: defaultValues.analiz_tipi_adi,
      senkron_tipi: defaultValues.senkron_tipi,
      yetiskin_sayisi: defaultValues.yetiskin_sayisi,
      cocuk_sayisi: defaultValues.cocuk_sayisi,
      cocuk_yasi_1: defaultValues.cocuk_yasi_1 || 0,
      cocuk_yasi_2: defaultValues.cocuk_yasi_2 || 0,
      cocuk_yasi_3: defaultValues.cocuk_yasi_3 || 0,
      cocuk_yasi_4: defaultValues.cocuk_yasi_4 || 0,
      periyot_tipi_1: defaultValues.periyot_tipi_1,
      periyot_1_fark: defaultValues.periyot_1_fark,
      periyot_tipi_2: defaultValues.periyot_tipi_2,
      periyot_2_fark: defaultValues.periyot_2_fark,
      periyot_tipi_3: defaultValues.periyot_tipi_3,
      periyot_3_fark: defaultValues.periyot_3_fark,
      gun_1:
        defaultValues.periyot_tipi_1 === "gun" ? defaultValues.periyot_1 : "0",
      gun_2:
        defaultValues.periyot_tipi_2 === "gun" ? defaultValues.periyot_2 : "0",
      gun_3:
        defaultValues.periyot_tipi_3 === "gun" ? defaultValues.periyot_3 : "0",
      tarih_1:
        defaultValues.periyot_tipi_1 === "tarih" ? defaultValues.periyot_1 : "",
      tarih_2:
        defaultValues.periyot_tipi_2 === "tarih" ? defaultValues.periyot_2 : "",
      tarih_3:
        defaultValues.periyot_tipi_3 === "tarih" ? defaultValues.periyot_3 : "",
    }
  );

  const handleChangeForm = (key: string, value: string | number) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveChanges = () => {
    const payload: CompetitorAnalysisInfo = {
      id: formValues.id,
      analiz_tipi: formValues.analiz_tipi,
      analiz_tipi_adi: formValues.analiz_tipi_adi,
      senkron_tipi: formValues.senkron_tipi,
      yetiskin_sayisi: formValues.yetiskin_sayisi,
      cocuk_sayisi: formValues.cocuk_sayisi,
      cocuk_yasi_1: formValues.cocuk_sayisi > 0 ? formValues.cocuk_yasi_1 : 0,
      cocuk_yasi_2: formValues.cocuk_yasi_2 > 1 ? formValues.cocuk_yasi_2 : 0,
      cocuk_yasi_3: formValues.cocuk_yasi_3 > 2 ? formValues.cocuk_yasi_3 : 0,
      cocuk_yasi_4: formValues.cocuk_yasi_4 > 3 ? formValues.cocuk_yasi_4 : 0,
      periyot_tipi_1: formValues.periyot_tipi_1,
      periyot_1_fark: formValues.periyot_1_fark,
      periyot_1:
        formValues.periyot_tipi_1 === "gun"
          ? formValues.gun_1
          : formValues.tarih_1,
      periyot_tipi_2: formValues.periyot_tipi_2,
      periyot_2_fark: formValues.periyot_2_fark,
      periyot_2:
        formValues.periyot_tipi_2 === "gun"
          ? formValues.gun_2
          : formValues.tarih_2,
      periyot_tipi_3: formValues.periyot_tipi_3,
      periyot_3_fark: formValues.periyot_3_fark,
      periyot_3:
        formValues.periyot_tipi_3 === "gun"
          ? formValues.gun_3
          : formValues.tarih_3,
    };
    setIsSubmitting(true);

    dispatch(
      updateCompetitorAnalysisInfo(payload, () => {
        dispatch(getCompetitorAnalysisInfo());
      })
    );
  };

  return {
    formValues,
    handleChangeForm,
    onSaveChanges: handleSaveChanges,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useHandleForm;
