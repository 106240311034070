import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveCommentResponse,
  resetComment,
  setIsLoading,
  setError,
  setAddCommentLoading,
  setAddCommentError,
  handleSaveCommentQuestions,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { AddCommentRequestPayload } from "types/comments";

import { ADD_COMMENTS, GET_ADD_COMMENT_QUESTIONS, GET_COMMENTS } from "helpers/url_helper";

import { Dispatch } from "redux";

type SuccessCallback = () => void;

interface GetCommentsParams {
  otel_id: number;
  page: number;
  per_page: number;
  start_date: string;
  end_date: string;
  personel: string;
}

export const addComment =
  (payload: AddCommentRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddCommentLoading(true));

      await axios.post(ADD_COMMENTS, payload);

      successCallback();
      dispatch(resetComment());
      dispatch(setToastSuccess("Yorum başarıyla eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddCommentError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddCommentLoading(false));
    }
  };

  export const getComments =
  (params: GetCommentsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_COMMENTS, params);
      const res = (response as any).message ? response.data : response;

      // console.log("getComments API response:", res);

      dispatch(handleSaveCommentResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

 export const getCommentQuestions =
 () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_ADD_COMMENT_QUESTIONS);
    const res = (response as any).message ? response.data : response;

    // console.log("getCommentQuestions API response:", res);

    dispatch(handleSaveCommentQuestions(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};