import { Container } from "reactstrap";

interface PageContainerProps {
  title: string;
  children: React.ReactNode;
}

const PageContainer = ({ title, children }: PageContainerProps) => {
  document.title = `${title} | Yönetim Paneli`;
  return (
    <div
      className="page-content"
      style={{
        backgroundColor: `var(--theme-page-bg)`,
        minHeight: "96vh",
        paddingTop: "60px",
      }}
    >
      <Container fluid>{children}</Container>
    </div>
  );
};

export default PageContainer;
