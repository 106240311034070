import { handleChangeInput } from "slices/facility/updateFacility/reducer";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import {
  CustomFormFieldContainer,
  CustomFormEditor,
} from "Components/Custom/UI";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FACILITY_GENERAL_DESCRIPTION } from "utils/fieldDescriptions";

interface FacilityGeneralDescriptionProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
}

const FacilityGeneralDescription = ({
  values,
  formErrors,
  validateFormChange,
}: FacilityGeneralDescriptionProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Genel Açıklama"
      error={formErrors.otel_genel_aciklama}
      orientation="vertical"
      description={FACILITY_GENERAL_DESCRIPTION}
      required
      divider
    >
      <CustomFormEditor
        editorData={values.otel_genel_aciklama}
        handleGetEditorData={(data) => {
          dispatch(
            handleChangeInput({
              otel_genel_aciklama: data,
            })
          );
          validateFormChange({
            ...values,
            otel_genel_aciklama: data,
          });
        }}
      />
    </CustomFormFieldContainer>
  );
};

export default FacilityGeneralDescription;
