import axios from "axios";

import {
  profileSuccess,
  profileError,
  resetProfileFlagChange,
} from "./reducer";

import { RESET_PASSWORD_PROFILE, UPDATE_PROFILE } from "helpers/url_helper";
import { handleErrors } from "helpers/axios_helpers";

interface ResetPasswordFormData {
  oldPassword: string;
  password: string;
  password_confirmation: string;
}

interface UpdateProfileFormData {
  email: string;
  phoneNumber: string;
  office: string;
}

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword =
  (formData: ResetPasswordFormData, navigate: any) => async (dispatch: any) => {
    const data = {
      oldpassword: formData.oldPassword,
      newpassword: formData.password,
      password_confirmation: formData.password_confirmation,
    };

    dispatch(profileError(""));
    dispatch(profileSuccess(""));

    try {
      const response = await axios.post(RESET_PASSWORD_PROFILE, data);

      if (response) {
        dispatch(profileError(""));
        dispatch(profileSuccess("Şifre değiştirildi."));
        setTimeout(() => {
          dispatch(profileError(""));
          dispatch(profileSuccess(""));
          navigate("/cikis");
        }, 1000);
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(profileError(message));
        dispatch(profileSuccess(""));
      });
    }
  };

export const updateProfile =
  (formData: UpdateProfileFormData) => async (dispatch: any) => {
    const data = {
      email_adresi: formData.email,
      ceptelefonu: formData.phoneNumber,
      adres: formData.office,
    };

    dispatch(profileError(""));
    dispatch(profileSuccess(""));

    try {
      const response = await axios.post(UPDATE_PROFILE, data);

      if (response) {
        dispatch(profileSuccess("Profil güncellendi."));

        setTimeout(() => {
          dispatch(profileError(""));
          dispatch(profileSuccess(""));
        }, 3000);
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(profileError(message));
        dispatch(profileSuccess(""));
      });
    }
  };
