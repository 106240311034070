import { createSlice } from "@reduxjs/toolkit";

import {
  DiscountCode,
  DiscountCodeState,
  DiscountCodeFormErrors,
} from "types/DiscountsAndActions";

export interface DiscountCodesInitialState {
  discountCodes: DiscountCode[];
  discountCode: DiscountCodeState;
  discountCodeFormErrors: DiscountCodeFormErrors;
  isLoading: boolean;
  error: string;
  addCodeLoading: boolean;
  addCodeError: string;
  updateCodeLoading: boolean;
  updateCodeError: string;
  deleteCodeLoading: boolean;
  deleteCodeError: string;
}

export const initialState: DiscountCodesInitialState = {
  discountCodes: [],
  discountCode: {
    id: 0,
    indirim_adi: "",
    indirim_orani: "",
    indirim_tutari: "",
    indirim_tipi: "oran",
    indirim_kuponu_kodu: "",
  },
  discountCodeFormErrors: {},
  isLoading: false,
  error: "",
  addCodeLoading: false,
  addCodeError: "",
  updateCodeLoading: false,
  updateCodeError: "",
  deleteCodeLoading: false,
  deleteCodeError: "",
};

const discountCodesSlice = createSlice({
  name: "discountCodes",
  initialState,
  reducers: {
    handleSaveDiscountCodes(state, action) {
      state.discountCodes = action.payload;
    },
    handleDeleteDiscountCodeWithId(state, action) {
      state.discountCodes = state.discountCodes.filter(
        (discountCode) => discountCode.id !== action.payload
      );
    },
    handleSaveDiscountCodeForEdit(state, action) {
      state.discountCode = action.payload;
    },
    handleChangeInput(state, action) {
      state.discountCode = {
        ...state.discountCode,
        ...action.payload,
      };
    },
    handleAddDiscountCodeFormErrors(state, action) {
      state.discountCodeFormErrors = action.payload;
    },
    resetDiscountCodes(state) {
      state.discountCodes = [];
    },
    resetDiscountCode(state) {
      state.discountCode = {
        id: 0,
        indirim_adi: "",
        indirim_orani: "",
        indirim_tutari: "",
        indirim_tipi: "oran",
        indirim_kuponu_kodu: "",
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddCodeLoading(state, action) {
      state.addCodeLoading = action.payload;
    },
    setAddCodeError(state, action) {
      state.addCodeError = action.payload;
    },
    setUpdateCodeLoading(state, action) {
      state.updateCodeLoading = action.payload;
    },
    setUpdateCodeError(state, action) {
      state.updateCodeError = action.payload;
    },
    setDeleteCodeLoading(state, action) {
      state.deleteCodeLoading = action.payload;
    },
    setDeleteCodeError(state, action) {
      state.deleteCodeError = action.payload;
    },
  },
});

export const {
  handleSaveDiscountCodes,
  handleDeleteDiscountCodeWithId,
  handleSaveDiscountCodeForEdit,
  handleChangeInput,
  handleAddDiscountCodeFormErrors,
  resetDiscountCodes,
  resetDiscountCode,
  setIsLoading,
  setError,
  setAddCodeLoading,
  setAddCodeError,
  setUpdateCodeLoading,
  setUpdateCodeError,
  setDeleteCodeLoading,
  setDeleteCodeError,
} = discountCodesSlice.actions;

export default discountCodesSlice.reducer;
