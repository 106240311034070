import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveUsersAndAuthorities,
  handleSaveUserAuthorities,
  setIsLoading,
  setError,
  setAddAuthoritiesToUsersLoading,
  setAddAuthoritiesToUsersError,
  setAddAuthoritiesLoading,
  setAddAuthoritiesError,
} from "./reducer";

import {
  GET_AUTHORITIES_AND_USERS,
  ADD_AUTHORITY_TO_USER,
  ADD_AUTHORITY
} from "helpers/url_helper";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import { Dispatch } from "redux";
import { AddNewAuthorityPayload, AddUserAuthoritiesPayload } from "types/userOperations";

type SuccessCallback = () => void;

export const getAuthoritiesAndUsers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_AUTHORITIES_AND_USERS);
    
    dispatch(handleSaveUsersAndAuthorities(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAuthoritiesOfSelectedUser = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${GET_AUTHORITIES_AND_USERS}?id=${id}`);

    dispatch(handleSaveUserAuthorities(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addAuthorityToUsers = (
  payload: AddUserAuthoritiesPayload,
  successCallback: SuccessCallback
) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAddAuthoritiesToUsersLoading(true));

    await axios.post(ADD_AUTHORITY_TO_USER, payload);

    successCallback();
    dispatch(setToastSuccess("Yetkiler kullanıcılara eklendi."));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setAddAuthoritiesToUsersError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setAddAuthoritiesToUsersLoading(false));
  }
}

export const addNewAuthority = (
  payload: AddNewAuthorityPayload,
  successCallback: SuccessCallback
) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAddAuthoritiesLoading(true));

    await axios.post(ADD_AUTHORITY, payload);

    successCallback();
    dispatch(setToastSuccess("Yeni yetki başarıyla eklendi."));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setAddAuthoritiesError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setAddAuthoritiesLoading(false));
  }
}
