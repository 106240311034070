import {
  CustomFormContainer,
  CustomFormEditor,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import {
  FACILITY_ACCOMMODATION_DESCRIPTION,
  FACILITY_ROOM_FEATURES_DESCRIPTION,
} from "utils/fieldDescriptions";
import { handleChangeInput } from "slices/facility/addFacility/reducer";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Input, Label } from "reactstrap";

interface FacilityAccomodationFieldsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityRoomsOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityAccomodationFields = ({
  values,
  formErrors,
  validateFormChange,
  facilityRoomsOptions,
}: FacilityAccomodationFieldsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormContainer title="Konaklama Bilgileri" lg={12} xl={12}>
      <CustomFormFieldContainer
        label="Konaklama Bilgileri"
        error={formErrors.otel_konaklama_aciklama}
        orientation="vertical"
        description={FACILITY_ACCOMMODATION_DESCRIPTION}
        required
        divider
      >
        <CustomFormEditor
          editorData={values.otel_konaklama_aciklama}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeInput({
                otel_konaklama_aciklama: data,
              })
            );
            validateFormChange({
              ...values,
              otel_konaklama_aciklama: data,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Oda Özellikleri"
        error={formErrors.oda_ozellikleri}
        description={FACILITY_ROOM_FEATURES_DESCRIPTION}
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityRoomsOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.oda_ozellikleri.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.oda_ozellikleri, parseInt(value)]
                    : values.oda_ozellikleri.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      oda_ozellikleri: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    oda_ozellikleri: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default FacilityAccomodationFields;
