import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleResetFacilityState,
  handleSaveFacilityChoices,
  setAddFacilityLoading,
  setAddFacilityError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { GET_FACILITY_FORM_CHOICES, ADD_FACILITY } from "helpers/url_helper";

import { Dispatch } from "redux";

export const getFacilityFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_FACILITY_FORM_CHOICES);

    dispatch(handleSaveFacilityChoices(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addFacility =
  (payload: FormData) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAddFacilityLoading(true));
      await axios.post(ADD_FACILITY, payload);

      dispatch(setToastSuccess("Tesis eklendi"));
      dispatch(handleResetFacilityState());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddFacilityError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddFacilityLoading(false));
    }
  };
