import { ContractRulesPage } from "Components/Custom/FinanceAndAccountingPages";
import useContractRules from "Components/Hooks/financeAndAccounting/useContractRules";
import PageContainer from "pages/PageContainer";

const ContractRules = () => {
  const { isLoading, hotelContractRuleLoading, addContractRuleLoading } =
    useContractRules();

  return (
    <PageContainer title="Kontrat Kuralları">
      <ContractRulesPage
        isLoading={isLoading}
        hotelContractRuleLoading={hotelContractRuleLoading}
        addContractRuleLoading={addContractRuleLoading}
      />
    </PageContainer>
  );
};

export default ContractRules;
