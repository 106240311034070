import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { PriceProviderCardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface PriceProviderCardProps {
  values: PriceProviderCardData;
  otel_id: number;
}

const PriceProviderCard = ({ values, otel_id }: PriceProviderCardProps) => {
  return (
    <>
      <CustomDashboardCard xl={4} title="Fiyat Sağlayıcı">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p>ID : {otel_id}</p>
            <p style={{ fontWeight: "500", fontSize: "20px" }}>
              {values.provider_name}
            </p>
          </div>
          <i
            className="ri-price-tag-3-line"
            style={{ fontSize: "48px", color: DASHBOARD_COLORS.orange }}
          />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default PriceProviderCard;
