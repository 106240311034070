import { createSlice } from "@reduxjs/toolkit";

import { OutboundCallWithNumber } from "types/reports";

export interface OutboundCallsListWithNumberInitialState {
  outboundCallsWithNumber: OutboundCallWithNumber[];
  isLoading: boolean;
  error: string;
}

const initialState: OutboundCallsListWithNumberInitialState = {
  outboundCallsWithNumber: [],
  isLoading: false,
  error: "",
};

const outboundCallsWithNumberSlice = createSlice({
  name: "outboundCallsWithNumber",
  initialState,
  reducers: {
    handleSaveOutboundCallsWithNumber(state, action) {
      state.outboundCallsWithNumber = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveOutboundCallsWithNumber, setIsLoading, setError } =
  outboundCallsWithNumberSlice.actions;

export default outboundCallsWithNumberSlice.reducer;
