import { CONTRACT_TYPE } from "enums";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleAddContractRuleFormErrors } from "slices/financeAndAccounting/contractRules/reducer";

import { setToastError } from "slices/toast/reducer";
import {
  AddContractRuleRequestPayload,
  AddContractRuleFormErrors,
} from "types/financeAndAccounting";

const useValidateForm = (formValues: AddContractRuleRequestPayload) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const contratType = formValues.kontrat_tipi;

  const tekrarli = contratType === CONTRACT_TYPE.Tekrarli;
  // const aylik = contratType === CONTRACT_TYPE.Aylik;
  const odaBasi = contratType === CONTRACT_TYPE["Oda Basi"];
  const sezonSonu = contratType === CONTRACT_TYPE["Sezon Sonu"];
  // const tekSeferlik = contratType === CONTRACT_TYPE["Tek Seferlik"];

  const validateForm = (
    values: AddContractRuleRequestPayload,
    showErrorToast = true
  ) => {
    const errors: AddContractRuleFormErrors = {};

    if (!values.adi) {
      errors.adi = "Zorunlu alan.";
    }
    if (!values.baslangic_tarihi) {
      errors.baslangic_tarihi = "Zorunlu alan.";
    }
    if (tekrarli || odaBasi || sezonSonu) {
      if (!values.limit1) {
        errors.limit1 = "Zorunlu alan.";
      }
    }
    if (sezonSonu) {
      if (!values.limit2) {
        errors.limit2 = "Zorunlu alan.";
      }
    }

    if (!values.veri) {
      errors.veri = "Zorunlu alan.";
    }

    dispatch(handleAddContractRuleFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddContractRuleRequestPayload) => {
    if (isSubmitted) {
      validateForm(values, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
