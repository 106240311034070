import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CategorizeFacilityInitialState } from "slices/facilityCategorization/categorizeFaclity/reducer";
import { getCategorizeFacilityFormChoices } from "slices/facilityCategorization/categorizeFaclity/thunk";

const useCategorizeFacility = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { CategorizeFacility: CategorizeFacilityInitialState }) =>
      state.CategorizeFacility
  );

  useEffect(() => {
    dispatch(getCategorizeFacilityFormChoices());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useCategorizeFacility;
