import { CommercialTableCardData } from "types/dashboard";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = (values: CommercialTableCardData[]) => {
  const rowColors = {
    mavi: TABLE_COLORS.blue,
    kirmizi: TABLE_COLORS.red,
    yesil: TABLE_COLORS.green,
  };

  const tableData = values.map((value) => {
    const activeColorKey = (
      Object.keys(value.satir_rengi) as Array<keyof typeof value.satir_rengi>
    ).find((key) => value.satir_rengi[key] === 1);
    return {
      satir_rengi: {
        yesil: value.satir_rengi.yesil,
        kirmizi: value.satir_rengi.kirmizi,
        mavi: value.satir_rengi.mavi,
      },
      otel_info: [
        value.otel_adi,
        `${value.sehir} / ${value.ilce}`,
        `(${value.tedarikci_metni})`,
        `(${value.otel_danismani})`,
      ],
      reklam_grup_id: value.reklam_grup_id,
      satis: value.satis,
      min_satis: value.min_satis,
      maliyet: value.maliyet,
      etkilesim: value.etkilesim,
      tiklama: value.tiklama,
      absTopImpPerc: value.absTopImpPerc,
      eski_tbm: value.eski_tbm,
      yeni_tbm: value.yeni_tbm,
      rowColor: activeColorKey ? rowColors[activeColorKey] : TABLE_COLORS.gray,
    };
  });

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_info",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div style={{ minWidth: "200px" }}>
            {value.map((item: any, index: number) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        );
      },
    },
    {
      header: "Satış",
      accessorKey: "satis",
      enableColumnFilter: false,
    },
    {
      header: "Min. Satış",
      accessorKey: "min_satis",
      enableColumnFilter: false,
    },
    {
      header: "Maliyet",
      accessorKey: "maliyet",
      enableColumnFilter: false,
    },
    {
      header: "Etkileşim",
      accessorKey: "etkilesim",
      enableColumnFilter: false,
    },
    {
      header: "Tıklama",
      accessorKey: "tiklama",
      enableColumnFilter: false,
    },
    {
      header: "Göst. (Mutlak En Yüksek) Yüzdesi",
      accessorKey: "absTopImpPerc",
      enableColumnFilter: false,
    },
    {
      header: "Eski TBM",
      accessorKey: "eski_tbm",
      enableColumnFilter: false,
    },
    {
      header: "Yeni TBM",
      accessorKey: "yeni_tbm",
      enableColumnFilter: false,
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
