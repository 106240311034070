import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import MoneyPointsTable from "./MoneyPointsTable";
import AddMoneyPointForm from "./AddMoneyPointForm";

interface MoneyPointsPageProps {
  isLoading: boolean;
}

const MoneyPointsPage = ({ isLoading }: MoneyPointsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <AddMoneyPointForm />
      <MoneyPointsTable isLoading={isLoading} />
    </div>
  );
};

export default withPageNeedsHotel(MoneyPointsPage);
