import { CustomFormContainer } from "Components/Custom/UI";
import { UpdateFacilityFormErrors, UpdateFacilityState } from "types/facility";
import FacilityMainTheme from "./FacilityMainTheme";
import FacilityIslamicTheme from "./FacilityIslamicTheme";

interface FacilityThemeProps {
  values: UpdateFacilityState;
  formErrors: UpdateFacilityFormErrors;
  validateFormChange: (values: UpdateFacilityState) => void;
  facilityThemeOptions: Option[];
  facilityThemeFeatureMatchOptions: {
    key: string;
    value: string[];
  }[];
  facilityIslamicThemeOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityTheme = ({
  values,
  formErrors,
  validateFormChange,
  facilityThemeOptions,
  facilityThemeFeatureMatchOptions,
  facilityIslamicThemeOptions,
}: FacilityThemeProps) => {
  return (
    <CustomFormContainer title="Tema Seçimi" lg={12} xl={12}>
      <FacilityMainTheme
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityThemeFeatureMatchOptions={facilityThemeFeatureMatchOptions}
        facilityThemeOptions={facilityThemeOptions}
      />
      <FacilityIslamicTheme
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        facilityIslamicThemeOptions={facilityIslamicThemeOptions}
      />
    </CustomFormContainer>
  );
};

export default FacilityTheme;
