import { useState } from "react";

interface ImageContainerProps {
  src: string;
  alt: string;
  selected: boolean;
  handleSelectItem: (item: string) => void;
  handleDelete: (items: string[]) => void;
}

const ImageContainer = ({
  src,
  alt,
  selected,
  handleSelectItem,
  handleDelete,
}: ImageContainerProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="position-relative rounded w-100"
      style={
        selected
          ? { border: "2px solid #28a745", scale: "1.05", padding: ".5rem" }
          : { border: "2px solid #e1e1e1", scale: "1", padding: ".6rem" }
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className={
          hovered
            ? "opacity-25 rounded w-100 cursor-pointer"
            : selected
            ? "opacity-25 rounded w-100 cursor-pointer"
            : "rounded w-100 cursor-pointer"
        }
        src={src}
        alt={alt}
        height={150}
      />
      {(hovered || selected) && (
        <div className=" position-absolute top-0 left-0 d-flex gap-2 align-items-start justify-content-center w-100 pt-2">
          <i
            className="ri-delete-bin-fill theme-text-danger cursor-pointer"
            style={{ fontSize: "2.5rem" }}
            title="Resmi Sil"
            onClick={() => {
              const imageUrl = src;
              handleDelete([imageUrl]);
            }}
          ></i>
          {selected ? (
            <i
              className="ri-checkbox-circle-fill theme-text-success cursor-pointer"
              style={{ fontSize: "2.5rem" }}
              onClick={() => {
                handleSelectItem(src);
              }}
            ></i>
          ) : (
            <i
              className="ri-checkbox-circle-line theme-text-success cursor-pointer"
              style={{ fontSize: "2.5rem" }}
              title="Resmi Seç"
              onClick={() => {
                handleSelectItem(src);
              }}
            ></i>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageContainer;
