import { useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import QuotaEntryDateRangeSelector from "./QuotaEntryDateRangeSelector";

import { Hotel } from "types/PricesQuotas";
import useHandleForm from "./useHandleForm";

import {
  handleSaveHotel,
  resetAll,
} from "slices/pricesAndQuota/basicQuotaEntry/reducer";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import QuotaEntryContainer from "./QuotaEntryContainer";
import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { BASIC_QUOTA_ENTRY_DESCRIPTIONS } from "utils/pageDescriptions";

interface BasicQuotaEntryPageProps {
  data: Hotel;
  isLoading: boolean;
}

const BasicQuotaEntryPage = ({ data, isLoading }: BasicQuotaEntryPageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  dispatch(handleSaveHotel(data));
  const { handleSave, addQuotasLoading, ...props } = useHandleForm();

  useEffect(() => {
    dispatch(resetAll());
  }, [dispatch]);

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <CustomPageDescriptions descriptions={BASIC_QUOTA_ENTRY_DESCRIPTIONS} />
      <QuotaEntryDateRangeSelector {...props} />
      <QuotaEntryContainer
        data={data}
        onSave={handleSave}
        isLoading={addQuotasLoading}
      />
    </>
  );
};

export default withPageNeedsHotel(BasicQuotaEntryPage);
