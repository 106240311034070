import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveDistricts,
  handleSaveHotelSalesFilter,
  handleSaveHotelSales,
  setIsLoading,
  setDistrictsLoading,
  setError,
  setDistrictsError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_HOTEL_SALES,
  GET_HOTEL_SALE_FILTERS,
  GET_HOTEL_SALE_FILTER_DISTRICTS,
} from "helpers/url_helper";

import { GetFilteredHotelSalesRequestPayload } from "types/reports";

import { Dispatch } from "redux";

interface GetHotelSaleFilterDistrictsParams {
  sehir_id: number;
}

export const getHotelSaleFilterDistricts =
  (params: GetHotelSaleFilterDistrictsParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setDistrictsLoading(true));
      const response = await axios.get(GET_HOTEL_SALE_FILTER_DISTRICTS, {
        params,
      });

      dispatch(handleSaveDistricts(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDistrictsError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDistrictsLoading(false));
    }
  };

export const getHotelSaleFilters = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_HOTEL_SALE_FILTERS);

    dispatch(handleSaveHotelSalesFilter(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getHotelSales =
  (payload: GetFilteredHotelSalesRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_HOTEL_SALES, payload);

      dispatch(handleSaveHotelSales(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
