import ChildrenInfo from "../../ReservationCallPage/ChildrenInfo";

const FacilityChildrenInfo = () => {
  return (
    <div className="d-flex px-1">
      <ChildrenInfo />
    </div>
  );
};

export default FacilityChildrenInfo;
