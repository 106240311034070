interface CustomRowOperationButtonProps {
  variant:
    | "add"
    | "update"
    | "delete"
    | "detail"
    | "check"
    | "uncheck"
    | "info";

  onClick: () => void;
  title?: string;
}

const CustomRowOperationButton = ({
  variant,
  title,
  onClick,
}: CustomRowOperationButtonProps) => {
  const icon = {
    add: { icon: "add-circle-fill", color: "success" },
    update: { icon: "pencil-fill", color: "primary" },
    delete: { icon: "delete-bin-6-line", color: "danger" },
    detail: { icon: "eye-line", color: "primary" },
    check: { icon: "check-fill", color: "success" },
    uncheck: { icon: "close-fill", color: "danger" },
    info: { icon: "information-line", color: "info" },
  };

  return (
    <i
      className={`d-flex align-items-center justify-content-center ri-${icon[variant].icon} theme-text-${icon[variant].color} fw-bold border p-1 rounded bg-${icon[variant].color} bg-opacity-25`}
      style={{
        width: "1.7rem",
        height: "1.7rem",
        cursor: "pointer",
      }}
      title={title}
      onClick={onClick}
    ></i>
  );
};
export default CustomRowOperationButton;
