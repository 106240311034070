import { useEffect, useState } from 'react';
import AuthorityRow from './AuthorityRow';
import { UserAuthority } from 'types/userOperations';

interface AuthorityRowFieldProps {
    topAuthority: UserAuthority;
    allAuthorities: UserAuthority[];
    selectedPermissions: { [key: number]: string };
    onPermissionChange: (authorityId: number, permission: string) => void;
}

interface HierarchicalAuthority extends UserAuthority {
  children?: HierarchicalAuthority[];
}

const AuthorityRowField = ({
    topAuthority,
    allAuthorities,
    selectedPermissions,
    onPermissionChange,
} : AuthorityRowFieldProps) => {

  const [hierarchicalAuthorities, setHierarchicalAuthorities] = useState<HierarchicalAuthority[]>([]);

  //authority groups
  useEffect(() => {
    const hierarchy = buildAuthorityHierarchy(topAuthority, allAuthorities);
    setHierarchicalAuthorities(hierarchy);
  }, [topAuthority, allAuthorities]);


  //dividing authority groups
  const buildAuthorityHierarchy = (
    parentAuthority: UserAuthority,
    authorities: UserAuthority[]
  ): HierarchicalAuthority[] => {
    const authorityMap: { [key: number]: HierarchicalAuthority } = {};
    authorities.forEach((authority) => {
      authorityMap[authority.id] = { ...authority, children: [] };
    });

    const buildHierarchy = (authority: HierarchicalAuthority): HierarchicalAuthority => {
      const children = authorities
        .filter((auth) => auth.ust_yetki === authority.id)
        .map((child) => buildHierarchy(authorityMap[child.id]));
      return { ...authority, children };
    };

    return [buildHierarchy(authorityMap[parentAuthority.id])];
  };

   const renderAuthorities = (authorities: HierarchicalAuthority[], level: number = 0) => {
    return authorities.map((authority) => (
      <div key={authority.id}>
        <AuthorityRow
          authority={authority}
          level={level}
          selectedPermission={selectedPermissions[authority.id]}
          onPermissionChange={onPermissionChange}
        />
        {authority.children && renderAuthorities(authority.children, level + 1)}
      </div>
    ));
  };

  return (
    <div
      className="rounded border mb-4"
      style={{ borderRadius: '8px', border: '1px solid #ccc', marginBottom: '8px', maxWidth: '1000px', margin: '0 auto' }}
    >
      {renderAuthorities(hierarchicalAuthorities)}
    </div>
  );
};

export default AuthorityRowField;
