import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleSaveColumnsInputValues } from "slices/pricesAndQuota/advancedEntry/reducer";
import { Column, Period, PriceType } from "types/PricesQuotas";

const addDefaultInputValues = (inputs: Column) => {
  const newInputValues: any = {};

  const date = inputs.tarih;
  newInputValues[`${date}-rezervasyon`] = inputs.rezervasyon;
  newInputValues[`${date}-musaitlik`] = inputs.musaitlik;
  newInputValues[`${date}-kontenjan`] = inputs.kontenjan;
  newInputValues[`${date}-cta`] = inputs["giris-cikislar"]["cta"];
  newInputValues[`${date}-ctd`] = inputs["giris-cikislar"]["ctd"];

  inputs.fiyatlar.map(
    (price) => (newInputValues[`${date}-${price.fiyat_tipi}`] = price.fiyat)
  );

  return newInputValues;
};

interface UseHandleFormProps {
  period: Period;
  priceTypes: PriceType[];
  notAllowedToEditPaymentMethods: {
    [key: string]: boolean;
  };
  basePayMethod: boolean;
  paymentMethodsMultipliers: {
    [key: string]: string;
  };
  date: string;
  paymentMethods: string[];
}

const parsePrices = (values: any, priceTypes: PriceType[], date: string) => {
  const valueArray = Object.keys(values)
    .map((key) => {
      return {
        type: key,
        value: values[key],
      };
    })
    .filter(
      (item) =>
        !item.type.includes("rezervasyon") &&
        !item.type.includes("musaitlik") &&
        !item.type.includes("kontenjan") &&
        !item.type.includes("cta") &&
        !item.type.includes("ctd")
    );

  return priceTypes.map((priceType) => {
    const found = valueArray.find(
      (item) => item.type === `${date}-${priceType.fiyat_tipi}`
    );
    return {
      ...priceType,
      fiyat: found ? found.value : 0,
    };
  });
};

const useHandleForm = ({
  period,
  priceTypes,
  notAllowedToEditPaymentMethods,
  basePayMethod,
  paymentMethodsMultipliers,
  date,
  paymentMethods,
}: UseHandleFormProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const payMethod = period.odeme_tipi;

  const { columnsInputValues } = useSelector(
    (state: any) => state.AdvancedEntry
  );

  const [isReady, setIsReady] = useState(false);

  const [columnPrices, setColumnPrices] = useState<PriceType[]>([]);

  const handlePriceChange = (
    type: string,
    value: number,
    isBasePrice: boolean
  ) => {
    const updatingPaymentMethods = Object.keys(
      notAllowedToEditPaymentMethods
    ).concat(["iptal_edilemez", payMethod]);

    if (isBasePrice) {
      const newInputValues: any = {};

      priceTypes.forEach((priceType) => {
        if (priceType.baz_fiyat) {
          newInputValues[`${date}-${priceType.fiyat_tipi}`] = Math.ceil(value);
        } else {
          newInputValues[`${date}-${priceType.fiyat_tipi}`] = Math.ceil(
            value * priceType.katsayi
          );
        }
      });

      const newInputValuesObject = basePayMethod
        ? updatingPaymentMethods.reduce((acc: any, curr: string) => {
            if (
              paymentMethodsMultipliers[curr] &&
              notAllowedToEditPaymentMethods[curr] !== false
            ) {
              const newMultiplier = paymentMethodsMultipliers[curr];
              const newInputValuesWithMultiplier = Object.keys(
                newInputValues
              ).reduce((acc: any, key: string) => {
                acc[key] = Math.ceil(
                  (newInputValues[key] * (100 - parseInt(newMultiplier))) / 100
                );
                return acc;
              }, {});

              acc[curr] = {
                ...columnsInputValues[curr],
                ...newInputValuesWithMultiplier,
              };
            } else {
              acc[curr] = {
                ...columnsInputValues[curr],
                ...newInputValues,
              };
            }

            return acc;
          }, {})
        : {
            [payMethod]: {
              ...columnsInputValues[payMethod],
              ...newInputValues,
            },
          };

      dispatch(handleSaveColumnsInputValues(newInputValuesObject));

      return;
    }

    const newInputValues = basePayMethod
      ? updatingPaymentMethods.reduce((acc: any, curr: string) => {
          if (
            paymentMethodsMultipliers[curr] &&
            notAllowedToEditPaymentMethods[curr] !== false
          ) {
            const newMultiplier = paymentMethodsMultipliers[curr];
            acc[curr] = Object.keys(columnsInputValues[curr]).reduce(
              (acc: any, key: string) => {
                if (key === type) {
                  acc[key] = Math.ceil(
                    (value * (100 - parseInt(newMultiplier))) / 100
                  );
                } else {
                  acc[key] = columnsInputValues[curr][key];
                }
                return acc;
              },
              {}
            );
          } else
            acc[curr] = {
              ...columnsInputValues[curr],
              [type]: value,
            };
          return acc;
        }, {})
      : {
          [payMethod]: {
            ...columnsInputValues[payMethod],
            [type]: isNaN(value) ? 0 : value,
          },
        };

    dispatch(handleSaveColumnsInputValues(newInputValues));
  };

  const handleChange = (type: string, value: number) => {
    const newInputValues =
      type.includes("rezervasyon") || type.includes("kontenjan")
        ? paymentMethods.reduce((acc: any, curr: string) => {
            acc[curr] = {
              ...columnsInputValues[curr],
              [type]: isNaN(value) ? 0 : value,
            };
            return acc;
          }, {})
        : {
            [payMethod]: {
              ...columnsInputValues[payMethod],
              [type]: isNaN(value) ? 0 : value,
            },
          };

    dispatch(handleSaveColumnsInputValues(newInputValues));
  };

  useEffect(() => {
    if (!period.sutunlar?.length) return;
    const columnPrices: any = [];

    period.sutunlar.forEach((column: Column) => {
      const prices = addDefaultInputValues(column);
      columnPrices.push(prices);
    });
    const payType = period.odeme_tipi;

    const allPrices = columnPrices.reduce((acc: any, curr: any) => {
      Object.keys(curr).forEach((key) => {
        acc[key] = curr[key];
      });
      return acc;
    }, {});

    dispatch(handleSaveColumnsInputValues({ [payType]: allPrices }));
    setIsReady(true);
  }, [period, dispatch]);

  const columnInputValues = columnsInputValues[period.odeme_tipi];

  useEffect(() => {
    columnInputValues &&
      setColumnPrices(parsePrices(columnInputValues, priceTypes, date));
  }, [columnInputValues, priceTypes, date]);

  return {
    isReady,
    columnInputValues,
    columnPrices,
    handlePriceChange,
    handleChange,
  };
};

export default useHandleForm;
