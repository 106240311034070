import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonnelHotelAssignmentsInitialState } from "slices/userOperations/personnelHotelAssignments/reducer";
import { getAssignedHotelsFormChoices, getHotelsUsersAndCities } from "slices/userOperations/personnelHotelAssignments/thunk";

const usePersonnelHotelAssignments = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState }) => state.PersonnelHotelAssignments
  );

  useEffect(() => {
    dispatch(getHotelsUsersAndCities());
    dispatch(getAssignedHotelsFormChoices());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default usePersonnelHotelAssignments;
