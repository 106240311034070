import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  AssignRecommendedHotelsRequestPayload,
  RecommendedHotel,
} from "types/themes";

import { useEffect, useMemo, useState } from "react";
import {
  addRecommendedHotel,
  getRecommendedHotels,
} from "slices/themes/assignRecommendedHotels/thunk";
import { AssignRecommendedHotelsInitialState } from "slices/themes/assignRecommendedHotels/reducer";
import { useSelector } from "react-redux";

const useHandleList = (hotels: RecommendedHotel[], selectedTheme: number) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { addRecommendedHotelsLoading } = useSelector(
    (state: { AssignRecommendedHotels: AssignRecommendedHotelsInitialState }) =>
      state.AssignRecommendedHotels
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const hotelsListOptions = hotels.map((hotel) => ({
    value: hotel.otel_id.toString(),
    label: hotel.otel_adi,
  }));

  const assignedHotels = useMemo(() => {
    return hotels
      .filter((hotel) => hotel.selected)
      .map((hotel) => hotel.otel_id.toString());
  }, [hotels]);

  useEffect(() => {
    setSelectedOptions(assignedHotels);
  }, [assignedHotels]);

  const handleSaveAssignRecommendedHotels = () => {
    const payload: AssignRecommendedHotelsRequestPayload = {
      tema_id: selectedTheme,
      oteller: selectedOptions.map((option) => parseInt(option)),
    };

    dispatch(
      addRecommendedHotel(payload, () => {
        dispatch(getRecommendedHotels({ tema_id: selectedTheme }));
      })
    );
  };

  return {
    selectedOptions,
    setSelectedOptions,
    hotelsListOptions,
    handleSaveAssignRecommendedHotels,
    isLoading: addRecommendedHotelsLoading,
  };
};

export default useHandleList;
