import { useSelector } from "react-redux";
import { DailyCallsChartsInitialState } from "slices/reports/dailyCallsCharts/reducer";
import { DailyCallsChartsResponse } from "types/reports";
import { CHART_COLORS } from "utils/chartColors";

function formatData(data: DailyCallsChartsResponse) {
  const formattedData = data.secilen_gun.map((date, index) => {
    return {
      name: date,
      "Cevaplanan Toplam Çağrı": data.cevaplanan_toplam_cagri[index] || 0,
      "Toplam Giden Çağrı İsteği": data.giden_cagri_istek[index] || 0,
      "Sonra Aranacak": data.sonra_aranacak[index] || 0,
      "Fiyat Araştırıyor": data.fiyat_arastiriyor[index] || 0,
      "Fiyat Yok/Otel Dolu": data.fiyat_yok_dolu[index] || 0,
      "Rezervasyon Dışı": data.rezervasyon_disi[index] || 0,
      "Cevaplanan Saatlik Çağrı": data.cevaplanan_saatlik_cagri[index] || 0,
    };
  });

  return formattedData;
}

const colors = [
  CHART_COLORS.SUCCESS_BOLD,
  CHART_COLORS.PRIMARY,
  CHART_COLORS.INFO,
  CHART_COLORS.WARNING,
  CHART_COLORS.DANGER,
  CHART_COLORS.DANGER_LIGHT,
  CHART_COLORS.SUCCESS,
];

const useHandleChart = () => {
  const { dailyCallsChartsResponse } = useSelector(
    (state: { DailyCallsCharts: DailyCallsChartsInitialState }) =>
      state.DailyCallsCharts
  );

  const formattedData = formatData(dailyCallsChartsResponse);

  return { formattedData, colors };
};

export default useHandleChart;
