import { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import {
  getReservationCall,
  calculatePriceForLocation as calculatePriceForLocationAction,
} from "slices/reservation/reservationCall/thunk";

const useCalculatePriceForLocation = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    calculatePriceForLocation,
    reservationCall,
    calculatePriceLoading: isLoading,
  } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const hasReservationCall = !!reservationCall.arama_id;

  const hasCalculatedPrice = calculatePriceForLocation.length > 0;

  const parameters = sessionStorage.getItem(
    "customerLocationCalculationParameters"
  )
    ? JSON.parse(
        sessionStorage.getItem("customerLocationCalculationParameters")!
      )
    : "";

  useEffect(() => {
    if (aranan && arayan && dahili && !hasReservationCall) {
      dispatch(getReservationCall({ aranan, arayan, dahili }));
    }

    if (!hasCalculatedPrice && parameters) {
      dispatch(calculatePriceForLocationAction(parameters, () => {}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
};

export default useCalculatePriceForLocation;
