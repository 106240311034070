import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";

interface CustomSimpleLineChartProps {
  containerWidth?: number | string;
  containerHeight?: number | string;
  chartWidth?: number;
  chartHeight?: number;
  data: {
    name: string;
    [key: string]: number | string;
  }[];
  colors?: string[];
}

const CustomSimpleLineChart = ({
  containerWidth = "100%",
  containerHeight = 300,
  chartWidth = 500,
  chartHeight = 300,
  data,
  colors,
}: CustomSimpleLineChartProps) => {
  const lines = data[0] ? Object.keys(data[0]) : [];
  lines.splice(lines.indexOf("name"), 1);

  return (
    <ResponsiveContainer width={containerWidth} height={containerHeight}>
      <LineChart
        width={chartWidth}
        height={chartHeight}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {lines.map((line, index) => (
          <Line
            type="monotone"
            dataKey={line}
            stroke={colors ? colors[index] : "var(--theme-primary)"}
            strokeWidth={2}
            activeDot={{ r: 5 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomSimpleLineChart;
