import { NightRestrictionsPage } from "Components/Custom/DiscountsAndActionsPages";
import useNightRestrictions from "Components/Hooks/discountsAndActions/useNightRestrictions";
import PageContainer from "pages/PageContainer";

const NightRestrictions = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useNightRestrictions();

  return (
    <PageContainer title="Gece Kısıtlamaları">
      <NightRestrictionsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default NightRestrictions;
