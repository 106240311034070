import MailOrderForm from "./MailOrderForm";
import MailOrderInfoTable from "./MailOrderInfoTable";

interface MailOrderInfoPageProps {
  isLoading: boolean;
  formIsLoading: boolean;
}

const MailOrderInfoPage = ({
  isLoading,
  formIsLoading,
}: MailOrderInfoPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <MailOrderForm isLoading={formIsLoading} />
      <MailOrderInfoTable isLoading={isLoading} />
    </div>
  );
};

export default MailOrderInfoPage;
