import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacilityConcepts,
  setAddConceptLoading,
  setAddConceptError,
  setUpdateConceptLoading,
  setUpdateConceptError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  ADD_FACILITY_CONCEPT,
  GET_FACILITY_CONCEPTS,
  UPDATE_FACILITY_CONCEPT,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import {
  AddFacilityConceptRequestPayload,
  UpdateFacilityConceptRequestPayload,
} from "types/facility";

type SuccessCallback = () => void;

export const getFacilityConcepts =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FACILITY_CONCEPTS + id);

      const data = response.data || response;

      dispatch(handleSaveFacilityConcepts(data));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addFacilityConcept =
  (
    payload: AddFacilityConceptRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddConceptError(""));
      dispatch(setAddConceptLoading(true));

      await axios.post(ADD_FACILITY_CONCEPT, payload);

      successCallback();
      dispatch(setToastSuccess("Pansiyon eklendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddConceptError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddConceptLoading(false));
    }
  };

export const updateFacilityConcept =
  (
    payload: UpdateFacilityConceptRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateConceptError(""));
      dispatch(setUpdateConceptLoading(true));

      await axios.post(UPDATE_FACILITY_CONCEPT, payload);

      successCallback();
      dispatch(setToastSuccess("Pansiyon güncellendi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateConceptError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateConceptLoading(false));
    }
  };
