import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  FacilityCallInitialState,
  handleChangeUpdateFacilityCallInput,
} from "slices/facility/facilityCalls/reducer";

import {
  getFacilityManagers,
  updateFacilityCall,
  getFacilityCalls,
} from "slices/facility/facilityCalls/thunk";
import { UpdateFacilityCallRequestPayload } from "types/facility";

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityCall, startDate, endDate, pagination } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  const [showFacilityManagersModal, setShowFacilityManagersModal] =
    useState(false);
  const [showUpdateFacilityCallModal, setShowUpdateFacilityCallModal] =
    useState(false);
  const [showDetailedInfoModal, setShowDetailedInfoModal] = useState(false);

  const handleToggleDetailedInfoModal = () => {
    setShowDetailedInfoModal((prevState: boolean) => !prevState);
  };

  const handleGetFacilityManagers = (id: number) => {
    dispatch(getFacilityManagers(id));
  };

  const handleShowUpdateFacilityCallModal = (
    otel_id: number,
    status: "approve" | "reject"
  ) => {
    setShowUpdateFacilityCallModal((prevState: boolean) => !prevState);

    dispatch(
      handleChangeUpdateFacilityCallInput({
        operasyon_durum: status === "approve" ? "1" : "2",
      })
    );
    dispatch(
      handleChangeUpdateFacilityCallInput({
        otel_id,
      })
    );
  };

  const handleShowFacilityManagersModal = () => {
    setShowFacilityManagersModal((prevState: boolean) => !prevState);
  };

  const handleApproveFacilityCall = (description: string) => {
    const payload: UpdateFacilityCallRequestPayload = {
      ...facilityCall,
      operasyon_durum_aciklama: description,
    };
    dispatch(
      updateFacilityCall(payload, () => {
        setShowUpdateFacilityCallModal(false);
        dispatch(
          getFacilityCalls({
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: "",
            start_date: startDate,
            end_date: endDate,
          })
        );
      })
    );
  };

  const handleRejectFacilityCall = (description: string) => {
    const payload: UpdateFacilityCallRequestPayload = {
      ...facilityCall,
      operasyon_durum_aciklama: description,
    };

    dispatch(
      updateFacilityCall(payload, () => {
        setShowUpdateFacilityCallModal(false);
        dispatch(
          getFacilityCalls({
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: "",
            start_date: startDate,
            end_date: endDate,
          })
        );
      })
    );
  };

  return {
    showFacilityManagersModal,
    showUpdateFacilityCallModal,
    showDetailedInfoModal,
    setShowFacilityManagersModal,
    setShowUpdateFacilityCallModal,
    setShowDetailedInfoModal,
    onGetManagers: handleGetFacilityManagers,
    onToggleDetailedInfoModal: handleToggleDetailedInfoModal,
    onToggleUpdateFacilityCallModal: handleShowUpdateFacilityCallModal,
    onToggleFacilityManagersModal: handleShowFacilityManagersModal,
    onApproveFacilityCall: handleApproveFacilityCall,
    onRejectFacilityCall: handleRejectFacilityCall,
  };
};

export default useHandleRequests;
