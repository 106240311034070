import { useMemo } from "react";
import CustomSimpleTableContainer from "Components/Custom/UI/CustomSimpleTable/CustomSimpleTableContainer";

interface Column {
  header: string;
  cell?: (cell: any) => JSX.Element;
  accessorKey: string;
  enableColumnFilter: boolean;
}

interface CustomSimpleTableProps {
  tableData: any[];
  tableColumns: Column[];
}

const CustomSimpleTable = ({ tableData, tableColumns }: CustomSimpleTableProps) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);

  return (
    <CustomSimpleTableContainer
      columns={columns || []}
      data={tableData || []}
      thClass="bg-secondary text-secondary bg-opacity-10 fs-12 text-start border border-2 cursor-pointer"
      tableClass="border border-2 rounded p-1"
      divClass="table-responsive"
    />
  );
};

export default CustomSimpleTable;
