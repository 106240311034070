import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveReservationsResponse,
  setIsLoading,
  setError,
  handleSaveReservationFormChoices,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import { GET_RESERVATION_FORM_CHOICES, GET_RESERVATIONS } from "helpers/url_helper";

import { Dispatch } from "redux";
import { ReservationFilterPayload } from "types/reports";

export const getReservations = (payload: ReservationFilterPayload) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.post(GET_RESERVATIONS, payload);

    dispatch(handleSaveReservationsResponse(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getReservationFormChoices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_RESERVATION_FORM_CHOICES);

    dispatch(handleSaveReservationFormChoices(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
