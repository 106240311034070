import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface AllGiftsListPageProps {
  loading: boolean;
  error: string;
}

const AllGiftsListPage = ({ loading }: AllGiftsListPageProps) => {
  const { tableData, tableColumns } = useHandleTable();
  return (
    <CustomReactTable
      tableData={tableData}
      tableColumns={tableColumns}
      loading={loading}
    />
  );
};

export default AllGiftsListPage;
