import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getFilteredCustomerMails } from "slices/reports/customerMails/thunk";
import { FilterCustomerMailsRequestPayload } from "types/reports";

const useHandleForm = () => {
  const [filterForm, setFilterForm] = useState({
    tarih: moment().subtract(1, "days").format("YYYY-MM-DD") || "",
    rezervasyon_kodu: "",
    musteri_ad_soyad: "",
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const payload: FilterCustomerMailsRequestPayload = {
      tarih: filterForm.tarih,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
      musteri_adi: filterForm.musteri_ad_soyad,
    };

    dispatch(getFilteredCustomerMails(payload));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
  };
};

export default useHandleForm;
