import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { FacilityConceptItem } from "types/facility";

interface ManipulateConceptInputProps {
  concept: FacilityConceptItem;
  isLoading: boolean;
  forPassive?: boolean;
  onUpdateConcept: (newConceptName: string, pansiyon_id: number) => void;
  onRemoveConcept: (pansiyon_id: number, conceptName: string) => void;
}

const ManipulateConceptInput = ({
  concept,
  isLoading,
  onUpdateConcept,
  onRemoveConcept,
}: ManipulateConceptInputProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [conceptName, setConceptName] = useState(concept.pansiyon_isim);

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setConceptName(concept.pansiyon_isim);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !isLoading && setLoading(false);
  }, [isLoading]);

  return (
    <div className="d-flex justify-content-between align-items-center gap-2 p-2 rounded theme-bg-light border">
      <Input
        className="fs-14"
        type="text"
        autoCorrect="on"
        value={conceptName}
        onChange={(e) => setConceptName(e.target.value)}
        disabled={!isEditing}
      />

      <div className="d-flex gap-3 py-1 px-2  rounded shadow-sm bg-white">
        {loading ? (
          <i className="ri-loader-2-line animate-spin fs-18"></i>
        ) : isEditing ? (
          <>
            <i
              className="ri-save-fill theme-text-success fs-18 cursor-pointer"
              title="Kaydet"
              onClick={() => {
                setLoading(true);
                onUpdateConcept(conceptName, concept.id);
                setIsEditing(false);
              }}
            ></i>
            <i
              className="ri-close-fill theme-text-danger fs-18 cursor-pointer"
              title="İptal"
              onClick={cancelEditing}
            ></i>
          </>
        ) : (
          <>
            <i
              className="ri-pencil-fill theme-text-primary fs-18 cursor-pointer"
              title="Düzenle"
              onClick={startEditing}
            ></i>
            <i
              className="ri-delete-bin-6-fill theme-text-danger fs-18 cursor-pointer"
              title="Kaldır"
              onClick={() => {
                setLoading(true);
                onRemoveConcept(concept.id, concept.pansiyon_isim);
              }}
            ></i>
          </>
        )}
      </div>
    </div>
  );
};

export default ManipulateConceptInput;
