import { CalculatePriceForHotelFacilityFeature } from "types/reservation";

import parse from "html-react-parser";

interface FacilityDescriptionProps {
  title: string;
  description: string;
  features: CalculatePriceForHotelFacilityFeature[];
}

const FacilityDescription = ({
  title,
  description,
  features,
}: FacilityDescriptionProps) => {
  return (
    <>
      {description ? (
        <div className="d-flex flex-column gap-1 mt-3 border p-1 rounded bg-white">
          <span className="fw-semibold fs-15 theme-text-primary">{title}</span>
          <span className="fs-14">{parse(description)}</span>
          <div className="d-flex flex-wrap gap-2 pt-1">
            {features.map((feature, index) => (
              <span
                key={index}
                className="fw-semibold text-muted px-3 py-2 rounded border theme-bg-light fs-13"
              >
                {feature.ozellik_adi}
              </span>
            ))}
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default FacilityDescription;
