import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import useValidateForm from "./useValidateForm";
import { UpdateFacilityInitialState } from "slices/facility/updateFacility/reducer";
import { useEffect, useState } from "react";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const selectedUpdateFacilityState = (state: {
    UpdateFacility: UpdateFacilityInitialState;
  }) => state.UpdateFacility;

  const selectUpdateFacilityProperties = createSelector(
    selectedUpdateFacilityState,
    (state) => ({
      facilityFormChoices: state.facilityFormChoices,
      updateFacility: state.updateFacility,
      updateFacilityFormErrors: state.updateFacilityFormErrors,
      updateFacilityLoading: state.updateFacilityLoading,
    })
  );

  const {
    facilityFormChoices,
    updateFacility,
    updateFacilityFormErrors,
    updateFacilityLoading,
  } = useSelector(selectUpdateFacilityProperties);

  const updateFacilityRequestPayload = new FormData();

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const [facilityStarsOptions, setFacilityStarsOptions] = useState<Option[]>(
    []
  );
  const [facilityFeaturesOptions, setFacilityFeaturesOptions] = useState<
    Option[]
  >([]);
  const [facilityChildrenOptions, setFacilityChildrenOptions] = useState<
    Option[]
  >([]);
  const [facilityLocationOptions, setFacilityLocationOptions] = useState<
    Option[]
  >([]);
  const [facilityRoomsOptions, setFacilityRoomsOptions] = useState<Option[]>(
    []
  );
  const [facilityFoodOptions, setFacilityFoodOptions] = useState<Option[]>([]);
  const [facilityServicesOptions, setFacilityServicesOptions] = useState<
    Option[]
  >([]);
  const [facilitySwimmingOptions, setFacilitySwimmingOptions] = useState<
    Option[]
  >([]);
  const [facilityThemeOptions, setFacilityThemeOptions] = useState<Option[]>(
    []
  );
  const [
    facilityThemeFeatureMatchOptions,
    setFacilityThemeFeatureMatchOptions,
  ] = useState<
    {
      value: string[];
      key: string;
    }[]
  >([]);
  const [facilityIslamicThemeOptions, setFacilityIslamicThemeOptions] =
    useState<Option[]>([]);

  useEffect(() => {
    if (Object.keys(facilityFormChoices["Otel Değerlendirme Yıldızı"])) {
      const starsOptions = facilityFormChoices[
        "Otel Değerlendirme Yıldızı"
      ].map((star) => ({
        label: star,
        value: star.includes("5")
          ? "5"
          : star.includes("4")
          ? "4"
          : star.includes("3")
          ? "3"
          : star.includes("Butik")
          ? "0"
          : "",
      }));

      starsOptions.unshift({ label: "Seçiniz", value: "" });
      setFacilityStarsOptions(starsOptions);
    }

    if (Object.keys(facilityFormChoices["Otel Özellikleri"])) {
      const featuresOptions = Object.entries(
        facilityFormChoices["Otel Özellikleri"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityFeaturesOptions(featuresOptions);
    }

    if (Object.keys(facilityFormChoices["Çocuklara Özel"])) {
      const childrenOptions = Object.entries(
        facilityFormChoices["Çocuklara Özel"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityChildrenOptions(childrenOptions);
    }

    if (Object.keys(facilityFormChoices.Lokasyonlar)) {
      const locationOptions = Object.entries(
        facilityFormChoices.Lokasyonlar
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityLocationOptions(locationOptions);
    }

    if (Object.keys(facilityFormChoices["Oda Özellikleri"])) {
      const roomsOptions = Object.entries(
        facilityFormChoices["Oda Özellikleri"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityRoomsOptions(roomsOptions);
    }

    if (Object.keys(facilityFormChoices["Yiyecek İçecek"])) {
      const foodOptions = Object.entries(
        facilityFormChoices["Yiyecek İçecek"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityFoodOptions(foodOptions);
    }

    if (Object.keys(facilityFormChoices.Aktiviteler)) {
      const servicesOptions = Object.entries(
        facilityFormChoices.Aktiviteler
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityServicesOptions(servicesOptions);
    }

    if (Object.keys(facilityFormChoices["Havuz, Deniz ve Plaj"])) {
      const swimmingOptions = Object.entries(
        facilityFormChoices["Havuz, Deniz ve Plaj"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilitySwimmingOptions(swimmingOptions);
    }

    if (Object.keys(facilityFormChoices["Otel Teması"])) {
      const themeOptions = Object.entries(
        facilityFormChoices["Otel Teması"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityThemeOptions(themeOptions);
    }

    if (Object.keys(facilityFormChoices["Tema - Özellik Eşleşmesi"])) {
      const themeOptions = Object.entries(
        facilityFormChoices["Tema - Özellik Eşleşmesi"]
      ).map(([key, value]) => ({
        key,
        value,
      }));

      setFacilityThemeFeatureMatchOptions(themeOptions);
    }

    if (Object.keys(facilityFormChoices["İslami Otel Teması"])) {
      const themeOptions = Object.entries(
        facilityFormChoices["İslami Otel Teması"]
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));

      setFacilityIslamicThemeOptions(themeOptions);
    }
  }, [facilityFormChoices]);

  const statusOptions: Option[] = [
    { value: "1", label: "Aktif" },
    { value: "0", label: "Pasif" },
  ];

  return {
    statusOptions,
    facilityStarsOptions,
    facilityFeaturesOptions,
    facilityChildrenOptions,
    facilityLocationOptions,
    facilityRoomsOptions,
    facilityFoodOptions,
    facilityServicesOptions,
    facilitySwimmingOptions,
    facilityThemeOptions,
    facilityThemeFeatureMatchOptions,
    facilityIslamicThemeOptions,
    updateFacility,
    isLoading: updateFacilityLoading,
    updateFacilityRequestPayload,
    formErrors: updateFacilityFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
