import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddFacilityManagerFormErrors } from "slices/facility/facilityManagers/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  FacilityManagerFormErrors,
  FacilityManagerState,
} from "types/facility";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: FacilityManagerState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: FacilityManagerFormErrors = {};

    if (!values.yetkili_adi) {
      errors.yetkili_adi = "Yetkili adı boş bırakılamaz.";
    }
    if (!values.unvan) {
      errors.unvan = "Ünvan boş bırakılamaz.";
    }

    if (!values.mail) {
      errors.mail = "Mail boş bırakılamaz.";
    }

    if (values.mail && !emailRegex.test(values.mail)) {
      errors.mail = "Geçerli bir mail adresi giriniz.";
    }

    if (!values.telefon) {
      errors.telefon = "Telefon boş bırakılamaz.";
    }

    if (values.telefon && values.telefon.length < 10) {
      errors.telefon = "Telefon numarası en az 10 haneli olmalıdır.";
    }

    if (!values.birim) {
      errors.birim = "Birim boş bırakılamaz.";
    }
    dispatch(handleAddFacilityManagerFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: FacilityManagerState) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
  };
};

export default useValidateForm;
