import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { DistrictRankingInfoCardData } from "types/dashboard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface DistrictRankingInfoCardProps {
  values: DistrictRankingInfoCardData;
}

const DistrictRankingInfoCard = ({ values }: DistrictRankingInfoCardProps) => {
  const textStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "grey",
    maxWidth: "200px",
  };

  const highlightedTextStyle: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "600",
  };
  const title_info = (
    <>
      <i className="ri-global-line" />
    </>
  );

  const downOrHigh = () => {
    switch (values.down_or_high) {
      case "down":
        return (
          <>
            <i
              className="ri-arrow-down-line"
              style={{ color: DASHBOARD_COLORS.red }}
            />
          </>
        );
      case "high":
        return (
          <>
            <i
              className="ri-arrow-up-line"
              style={{ color: DASHBOARD_COLORS.green }}
            />
          </>
        );
      default:
        return (
          <>
            <i
              className="ri-alarm-warning-line"
              style={{ color: DASHBOARD_COLORS.orange }}
            />
          </>
        );
    }
  };
  return (
    <>
      <CustomDashboardCard
        xl={4}
        title={`${values.location} Otelleri Sıralaması`}
        titleInfo={title_info}
      >
        <span className="d-flex m-0 justify-content-between gap-3">
          <div style={textStyle}>
            {values.location} Otelleri sayfasındaki sıranız:
          </div>
          <div style={highlightedTextStyle}>
            {values.ranking} {downOrHigh()}
          </div>
        </span>
        <hr className="m-0" />
        <span className="d-flex m-0 justify-content-between gap-3">
          <div style={textStyle}>
            {values.location} Otelleri Linkinin son 30 günde aldığı tıklama
            sayısı:
          </div>
          <div style={highlightedTextStyle}>{values.click_rate}</div>
        </span>
      </CustomDashboardCard>
    </>
  );
};

export default DistrictRankingInfoCard;
