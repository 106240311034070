import { useSelector } from "react-redux";
import { CustomerMailsInitialState } from "slices/reports/customerMails/reducer";

import moment from "moment";

const useHandleTable = () => {
  const { customerMails } = useSelector(
    (state: { CustomerMails: CustomerMailsInitialState }) => state.CustomerMails
  );

  const tableData = customerMails?.map((customerMail) => ({
    "Rezervasyon ID": customerMail.rezervasyon_id,
    "Rezervasyon Kodu": customerMail.rezervasyon_kodu,
    "Satış Tarihi": customerMail.satis_tarihi,
    "Otel Adı": customerMail.otel_adi,
    "Giriş Tarihi": moment(customerMail.giris_tarihi).format("DD.MM.YYYY"),
    "Çıkış Tarihi": moment(customerMail.cikis_tarihi).format("DD.MM.YYYY"),
    "Müşteri Adı": customerMail.musteri_ad_soyad,
    "Müşteri Tel": customerMail.musteri_tel,
    "Müşteri E-Posta": customerMail.musteri_eposta,
    "Konfirme Edildi Mi":
      customerMail.konfirme_edildi_mi === "e" ? "Evet" : "Hayır",
    "İptal Edildi Mi": customerMail.iptal_edildi_mi === "e" ? "Evet" : "Hayır",
    "Satıcı Adı": customerMail.satici_adi,
    "Grup Adı": customerMail.grup_adi,
    "Müsaitlik Adı": customerMail.musaitlik_ad_soyad,
  }));

  const tableColumns = [
    {
      header: "Rez. Kodu",
      accessorKey: "Rezervasyon Kodu",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tarihi",
      accessorKey: "Satış Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "Giriş Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "Çıkış Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Müşteri Adı",
      accessorKey: "Müşteri Adı",
      enableColumnFilter: false,
    },
    {
      header: "Müşteri E-Posta",
      accessorKey: "Müşteri E-Posta",
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
