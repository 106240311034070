import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomerMailsInitialState } from "slices/reports/customerMails/reducer";
import { getAllCustomerMails } from "slices/reports/customerMails/thunk";

const useCustomerMails = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { CustomerMails: CustomerMailsInitialState }) => state.CustomerMails
  );

  useEffect(() => {
    dispatch(getAllCustomerMails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useCustomerMails;
