import { CustomRowOperationButton, CustomRowSpan } from "Components/Custom/UI";
import { CustomButton } from "Components/Custom/UI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import {
  ChildSpecialAgeInformationsInitialState,
  handleSaveChildSpecialAgeInformationForEdit,
} from "slices/pricesAndQuota/childSpecialAgeInformation/reducer";
import {
  deleteChildSpecialAgeInformation,
  getChildSpecialAgeInformation,
} from "slices/pricesAndQuota/childSpecialAgeInformation/thunk";
import {
  ChildSpecialAgeInformationState,
  DeleteChildSpecialAgeInformation,
} from "types/PricesQuotas";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const { childSpecialAgeInformation, selectedRoom } = useSelector(
    (state: {
      ChildSpecialAgeInformations: ChildSpecialAgeInformationsInitialState;
    }) => state.ChildSpecialAgeInformations
  );

  const { hotel } = useSelector((state: any) => state.Hotel);
  const otel_id = hotel.otel_id;
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const handleClickUpdate = (id: number) => {
    const selectedRow = tableData.find((row) => row.id === id);

    dispatch(
      handleSaveChildSpecialAgeInformationForEdit(
        selectedRow as ChildSpecialAgeInformationState
      )
    );
    window.scrollTo(0, 0);
  };

  const handleDelete = (id: number) => {
    const payload: DeleteChildSpecialAgeInformation = {
      otel_id,
      sil: "1",
      yas_id: id,
    };
    const getPayload = {
      otel_id,
      oda_id: selectedRoom,
    };

    dispatch(deleteChildSpecialAgeInformation(payload, () => {}));
    dispatch(getChildSpecialAgeInformation(getPayload));
  };

  const handleAdvanceCalendarButtonClick = (
    id: number,
    selectedConcept: string
  ) => {
    const selectedData = childSpecialAgeInformation.yas_genel_bilgileri.find(
      (data) => data.id === id
    );

    if (selectedData) {
      const { id, satis_tarihi_baslangic, satis_tarihi_bitis, gun } =
        selectedData;

      navigate("/gelismis-giris", {
        state: {
          cocuk_yas_id: id,
          satis_tarihi_baslangic,
          satis_tarihi_bitis,
          gun,
          selectedConcept,
        },
      });
    }
  };

  const handleRoomPersonPriceRateButtonClick = (id: number) => {
    const childSpecialAgeId =
      childSpecialAgeInformation.yas_genel_bilgileri.find(
        (data) => data.id === id
      )?.id;

    navigate("/oda-kisi-fiyat-oranlari", {
      state: { cocuk_yas_id: childSpecialAgeId },
    });
  };

  const handleChildInformationButton = (id: number) => {
    const childSpecialAgeId =
      childSpecialAgeInformation.yas_genel_bilgileri.find(
        (data) => data.id === id
      )?.id;

    navigate("/cocuk-bilgileri", {
      state: { cocuk_yas_id: childSpecialAgeId },
    });
  };

  const tableData = childSpecialAgeInformation.yas_genel_bilgileri.map(
    (data) => ({
      id: data.id,
      ozel_yas_ismi: data.ozel_yas_ismi,
      gun: data.gun,
      cocuk_ozel_yas_giris_gunu: data.cocuk_ozel_yas_giris_gunu,
      satis_tarihi_baslangic: data.satis_tarihi_baslangic,
      satis_tarihi_bitis: data.satis_tarihi_bitis,
      otel_id: data.otel_id,
      satis_tarihi_secim: data.satis_tarihi_secim,
      cocuk_bilgisi: data.cocuk_bilgisi,
      oda_bilgisi: data.oda_bilgisi,
      fiyat_bilgisi: data.fiyat_bilgisi,
    })
  );

  const tableColumns = [
    {
      header: "Özel Yaş İsmi",
      cell: (cell: any) => cell.getValue(),
      accessorKey: "ozel_yas_ismi",
      enableColumnFilter: false,
    },
    {
      header: "Gün",
      cell: (cell: any) => cell.getValue(),
      accessorKey: "gun",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Gününde Uygula",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      accessorKey: "cocuk_ozel_yas_giris_gunu",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tarihi Seçimi",
      cell: (cell: any) => {
        return <CustomRowSpan condition={cell.getValue() === "1"} isYesNo />;
      },
      accessorKey: "satis_tarihi_secim",
      enableColumnFilter: false,
    },
    {
      header: "Satış Tarih Aralığı",
      cell: (cell: any) => {
        return (
          <div className="d-flex align-items-center gap-5">
            <span>
              {moment(cell.row.original.satis_tarihi_baslangic).format(
                "DD.MM.YYYY"
              )}
              -
              {moment(cell.row.original.satis_tarihi_bitis).format(
                "DD.MM.YYYY"
              )}
            </span>
          </div>
        );
      },
      accessorKey: "cocuk_ozel_yas_giris_gunu",
      enableColumnFilter: false,
    },
    {
      header: "Çocuk Bilgileri",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        const showButton = cell.getValue();
        return (
          <>
            {showButton ? (
              <CustomButton
                variant="success"
                onClick={() => {
                  handleChildInformationButton(id);
                }}
              >
                Çocuk Bilgilerine Git
              </CustomButton>
            ) : (
              <CustomButton
                variant="danger"
                onClick={() => {
                  handleChildInformationButton(id);
                }}
              >
                Çocuk Bilgileri Eksik
              </CustomButton>
            )}
          </>
        );
      },
      accessorKey: "cocuk_bilgisi",
      enableColumnFilter: false,
    },
    {
      header: "Oda Kişi Fiyat Oranları",
      cell: (cell: any) => {
        const id = cell.row.original.id;

        const showButton = cell.getValue();
        return (
          <>
            {showButton ? (
              <CustomButton
                variant="success"
                onClick={() => {
                  handleRoomPersonPriceRateButtonClick(id);
                }}
              >
                Oda Kişi Fiyat Oranlarına Git
              </CustomButton>
            ) : (
              <CustomButton
                variant="danger"
                onClick={() => {
                  handleRoomPersonPriceRateButtonClick(id);
                }}
              >
                Oda Kişi Fiyat Oranları Eksik
              </CustomButton>
            )}
          </>
        );
      },
      accessorKey: "oda_bilgisi",
      enableColumnFilter: false,
    },
    {
      header: "Gelişmiş Takvim",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        const selectedConcept = Object.keys(
          childSpecialAgeInformation.pansiyon_bilgileri
        )[1];

        const showButton = cell.getValue();

        return (
          <>
            {showButton ? (
              <CustomButton
                variant="success"
                onClick={() => {
                  handleAdvanceCalendarButtonClick(id, selectedConcept);
                }}
              >
                Gelişmiş Takvime Git
              </CustomButton>
            ) : (
              <CustomButton
                variant="danger"
                onClick={() => {
                  handleAdvanceCalendarButtonClick(id, selectedConcept);
                }}
              >
                Gelişmiş Takvim Eksik
              </CustomButton>
            )}
          </>
        );
      },
      accessorKey: "fiyat_bilgisi",
      enableColumnFilter: false,
    },

    {
      header: "İşlemler",
      cell: (cell: any) => {
        const id = cell.row.original.id;
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
      accessorKey: "id",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.red,
      label: "Bilgiler Eksik",
    },

    {
      color: TABLE_COLORS.green,
      label: "Bilgiler Tamamlanmış",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
