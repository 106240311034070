import ListGroupsPage from 'Components/Custom/UserOperationsPages/ListGroupsPages';
import useListGroups from 'Components/Hooks/userOperations/useListGroups';
import PageContainer from 'pages/PageContainer';

const ListGroups = () => {

    const { isLoading } = useListGroups();

  return (
    <PageContainer title="Grupları Listele">
        <ListGroupsPage isLoading={isLoading}/>
    </PageContainer>
  )
}

export default ListGroups