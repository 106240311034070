interface CustomRowSpanProps {
  condition: boolean;
  isTrueFalse?: boolean;
  isYesNo?: boolean;
  isStatus?: boolean;
}

const CustomRowSpan = ({
  condition,
  isTrueFalse,
  isYesNo,
  isStatus,
}: CustomRowSpanProps) => {
  const color = condition ? "success" : "danger";

  return (
    <span className={`text-${color}`}>
      {isStatus && (condition ? "Aktif" : "Pasif")}
      {isYesNo && (condition ? "Evet" : "Hayır")}
      {isTrueFalse && (condition ? "Var" : "Yok")}
    </span>
  );
};

export default CustomRowSpan;
