import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { PriceWarningCardData } from "types/dashboard";

interface PriceWarningCardProps {
  values: PriceWarningCardData;
}

const PriceWarningCard = ({ values }: PriceWarningCardProps) => {
  const title_info = (
    <>
      <i className="ri-error-warning-fill" /> 
    </>
  );
  const textStyle: React.CSSProperties = {
    fontSize: "16px",
  };
  return (
    <>
      <CustomDashboardCard
        xl={4}
        title="Fiyat Bilgilendirme"
        color="info"
        titleInfo={title_info}
        titleInfoLocation="left"
      >
        <div style={textStyle}>{values.message}</div>
      </CustomDashboardCard>
    </>
  );
};

export default PriceWarningCard;
