import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveHotelContracts,
  resetHotelContractState,
  setIsLoading,
  setError,
  setContractLoading,
  setContractError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  GET_HOTEL_CONTRACTS,
  ADD_HOTEL_CONTRACT,
  UPDATE_HOTEL_CONTRACT,
  DELETE_HOTEL_CONTRACT,
  APPROVE_HOTEL_CONTRACT,
} from "helpers/url_helper";
import { Dispatch } from "redux";

interface RequestPayload {
  otel_id: number;
  per_page: number;
  page: number;
  search: string;
}

type SuccessCallback = () => void;

export const getHotelContracts =
  (payload: RequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_HOTEL_CONTRACTS, payload);

      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveHotelContracts(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addHotelContract =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setContractLoading(true));
      dispatch(setContractError(""));
      await axios.post(ADD_HOTEL_CONTRACT, payload);

      dispatch(setToastSuccess("Yeni kontrat eklendi."));
      successCallback();
      dispatch(resetHotelContractState());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setContractError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setContractLoading(false));
    }
  };

export const updateHotelContract =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setContractLoading(true));
      dispatch(setContractError(""));

      await axios.post(UPDATE_HOTEL_CONTRACT, payload);

      dispatch(setToastSuccess("Kontrat güncellendi."));
      successCallback();
      dispatch(resetHotelContractState());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setContractError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setContractLoading(false));
    }
  };

export const deleteHotelContract =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setContractLoading(true));
      dispatch(setContractError(""));

      await axios.post(DELETE_HOTEL_CONTRACT, payload);

      dispatch(setToastSuccess("Kontrat silindi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setContractError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setContractLoading(false));
    }
  };

export const approveHotelContract =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setContractLoading(true));
      dispatch(setContractError(""));

      await axios.post(APPROVE_HOTEL_CONTRACT, payload);

      dispatch(setToastSuccess("Kontrat onaylandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setContractError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setContractLoading(false));
    }
  };
