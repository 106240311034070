import { useState } from "react";
import { Tooltip } from "reactstrap";
import parse from "html-react-parser";

interface ButtonTooltipProps {
  target: string;
  title: string;
  description: string;
}

const ButtonTooltip = ({ target, title, description }: ButtonTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen((prev) => !prev);

  return (
    <Tooltip
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
      popperClassName="border rounded"
      style={{
        maxWidth: "400px",
        width: "fit-content",
        backgroundColor: "#fef9c3",
        color: "#000",
        border: "1px solid #e0a800",
      }}
    >
      <div className="d-flex flex-column gap-2 py-2 rounded">
        <span className="fs-14 fw-medium pb-2 border-bottom">{title}</span>

        <div className="fs-13">{parse(description)}</div>
      </div>
    </Tooltip>
  );
};

export default ButtonTooltip;
