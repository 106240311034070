import { useEffect, useState } from "react";
import {
  CompetitorAnalysis,
  CompetitorAnalysisFacilityAvailability,
} from "types/adsAndAnalyze";

import OtelFiyat from "assets/images/competitor-analysis/otelfiyat_logo.png";
import Otelz from "assets/images/competitor-analysis/otelz_logo.png";
import Hotels from "assets/images/competitor-analysis/hotels_logo.png";
import Etstur from "assets/images/competitor-analysis/etstur_logo.png";
import Tatilsepeti from "assets/images/competitor-analysis/tatilsepeti_logo.png";
import Jolly from "assets/images/competitor-analysis/jolly_logo2.png";
import Tatilbudur from "assets/images/competitor-analysis/tatilbudur_logo.png";
import Odamax from "assets/images/competitor-analysis/odamax_logo.png";
import FacilityAvailabilityModal from "./FacilityAvailabilityModal";
import SaveDescriptionModal from "./SaveDecriptionModal";
import { FormGroup, Input, Spinner } from "reactstrap";

interface AnalysisCardProps extends CompetitorAnalysis {
  onGetFacilityAvailabilities: (otel_id: number) => void;
  facilityAvailabilities: CompetitorAnalysisFacilityAvailability[];
  availabilityLoading: boolean;
  updateDescriptionLoading: boolean;
  updateHotelInfoLoading: boolean;
  updateAdsStatusLoading: boolean;
  onUpdateDescription: (id: number, analiz_aciklama_data: string) => void;
  onUpdateAdsDescription: (otel_id: number, reklam_data: string) => void;
  onUpdateAdsStatus: (
    group_id: number,
    status: 0 | 1,
    successCallback: SuccessCallback
  ) => void;
  onUpdateHotelInfo: (id: number) => void;
}

type SuccessCallback = () => void;

const AnalysisCard = ({
  id,
  otel_id,
  otel_adi,
  otel_odasayisi,
  lokasyon,
  telefon,
  komisyon_orani,
  kapida_odeme,
  ad_soyad,
  tarih_1,
  gece_1,
  otelfiyat_fiyat_1,
  otelz_fiyat_1,
  otelz_oran_1,
  hotels_fiyat_1,
  hotels_oran_1,
  etstur_fiyat_1,
  etstur_oran_1,
  tatilsepeti_fiyat_1,
  tatilsepeti_oran_1,
  jolly_fiyat_1,
  jolly_oran_1,
  tatilbudur_fiyat_1,
  tatilbudur_oran_1,
  odamax_fiyat_1,
  odamax_oran_1,
  tarih_2,
  gece_2,
  otelfiyat_fiyat_2,
  otelz_fiyat_2,
  otelz_oran_2,
  hotels_fiyat_2,
  hotels_oran_2,
  etstur_fiyat_2,
  etstur_oran_2,
  tatilsepeti_fiyat_2,
  tatilsepeti_oran_2,
  jolly_fiyat_2,
  jolly_oran_2,
  tatilbudur_fiyat_2,
  tatilbudur_oran_2,
  odamax_fiyat_2,
  odamax_oran_2,
  tarih_3,
  gece_3,
  otelfiyat_fiyat_3,
  otelz_fiyat_3,
  otelz_oran_3,
  hotels_fiyat_3,
  hotels_oran_3,
  etstur_fiyat_3,
  etstur_oran_3,
  tatilsepeti_fiyat_3,
  tatilsepeti_oran_3,
  jolly_fiyat_3,
  jolly_oran_3,
  tatilbudur_fiyat_3,
  tatilbudur_oran_3,
  odamax_fiyat_3,
  odamax_oran_3,
  crm_otelfiyat_kar_1,
  crm_otelfiyat_kar_2,
  crm_otelfiyat_kar_3,
  ets_1,
  ets_2,
  ets_3,
  otelfiyat_1,
  otelfiyat_2,
  otelfiyat_3,
  odamax_1,
  odamax_2,
  odamax_3,
  tatilbudur_1,
  tatilbudur_2,
  tatilbudur_3,
  tatilsepeti_1,
  tatilsepeti_2,
  tatilsepeti_3,
  otelz_1,
  otelz_2,
  otelz_3,
  hotels_1,
  hotels_2,
  hotels_3,
  jollytur_1,
  jollytur_2,
  jollytur_3,
  reklam_yeni,
  reklam_grup_id,
  reklam_aciklama,
  aciklama,
  aciklama_yapan,
  onGetFacilityAvailabilities,
  facilityAvailabilities,
  availabilityLoading,
  updateDescriptionLoading,
  updateHotelInfoLoading,
  updateAdsStatusLoading,
  onUpdateDescription,
  onUpdateAdsDescription,
  onUpdateAdsStatus,
  onUpdateHotelInfo,
}: AnalysisCardProps) => {
  const firstBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_1),
    parseFloat(otelz_fiyat_1),
    parseFloat(hotels_fiyat_1),
    parseFloat(etstur_fiyat_1),
    parseFloat(tatilsepeti_fiyat_1),
    parseFloat(jolly_fiyat_1),
    parseFloat(tatilbudur_fiyat_1),
    parseFloat(odamax_fiyat_1)
  );

  const hideFirstRatesRow = [
    otelz_oran_1,
    hotels_oran_1,
    etstur_oran_1,
    tatilsepeti_oran_1,
    jolly_oran_1,
    tatilbudur_oran_1,
    odamax_oran_1,
  ].every((rate) => rate === "0.00" || !rate);

  const secondBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_2),
    parseFloat(otelz_fiyat_2),
    parseFloat(hotels_fiyat_2),
    parseFloat(etstur_fiyat_2),
    parseFloat(tatilsepeti_fiyat_2),
    parseFloat(jolly_fiyat_2),
    parseFloat(tatilbudur_fiyat_2),
    parseFloat(odamax_fiyat_2)
  );

  const hideSecondRatesRow = [
    otelz_oran_2,
    hotels_oran_2,
    etstur_oran_2,
    tatilsepeti_oran_2,
    jolly_oran_2,
    tatilbudur_oran_2,
    odamax_oran_2,
  ].every((rate) => rate === "0.00" || !rate);

  const thirdBestPrice = Math.min(
    parseFloat(otelfiyat_fiyat_3),
    parseFloat(otelz_fiyat_3),
    parseFloat(hotels_fiyat_3),
    parseFloat(etstur_fiyat_3),
    parseFloat(tatilsepeti_fiyat_3),
    parseFloat(jolly_fiyat_3),
    parseFloat(tatilbudur_fiyat_3),
    parseFloat(odamax_fiyat_3)
  );

  const hideThirdRatesRow = [
    otelz_oran_3,
    hotels_oran_3,
    etstur_oran_3,
    tatilsepeti_oran_3,
    jolly_oran_3,
    tatilbudur_oran_3,
    odamax_oran_3,
  ].every((rate) => rate === "0.00" || !rate);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [adsModal, setAdsModal] = useState(false);

  const toggleAdsModal = () => setAdsModal(!adsModal);

  const [descriptionModal, setDescriptionModal] = useState(false);

  const toggleDescriptionModal = () => setDescriptionModal(!descriptionModal);

  const [isAdsOpen, setIsAdsOpen] = useState(
    reklam_yeni === "acik" ? true : false
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !updateHotelInfoLoading && setIsLoading(false);
  }, [updateHotelInfoLoading]);

  const [adsLoading, setAdsLoading] = useState(false);

  useEffect(() => {
    !updateAdsStatusLoading && setAdsLoading(false);
  }, [updateAdsStatusLoading]);

  useEffect(() => {
    setIsAdsOpen(reklam_yeni === "acik" ? true : false);
  }, [reklam_yeni]);

  return (
    <>
      <div
        className="d-flex flex-column border"
        style={{
          backgroundColor: isLoading ? "var(--theme-light)" : "white",
        }}
      >
        <div className="d-flex border">
          <div className="flex-grow-1 d-flex justify-content-start px-1 align-items-center gap-2 border">
            {adsLoading ? (
              <Spinner size="sm" />
            ) : (
              <FormGroup switch>
                <Input
                  type="switch"
                  className="cursor-pointer"
                  checked={isAdsOpen}
                  onChange={() => {
                    onUpdateAdsStatus(reklam_grup_id, isAdsOpen ? 0 : 1, () =>
                      setIsAdsOpen((prevState) => !prevState)
                    );

                    setAdsLoading(true);
                  }}
                  title={isAdsOpen ? "Reklamı Kapat" : "Reklamı Aç"}
                />
              </FormGroup>
            )}
            <i
              className="ri-hotel-bed-fill text-success fs-18 cursor-pointer"
              title="Müsaitlik Durumu"
              onClick={() => {
                onGetFacilityAvailabilities(otel_id);
                toggle();
              }}
            ></i>
            <i
              className="ri-advertisement-fill fs-18 cursor-pointer"
              title="Reklam Açıklama"
              onClick={toggleAdsModal}
            ></i>
            <i
              className="ri-file-text-fill fs-18 cursor-pointer"
              title="Açıklama"
              onClick={toggleDescriptionModal}
            ></i>
            {isLoading ? (
              <Spinner size="sm" color="success" />
            ) : (
              <i
                className="ri-restart-fill fs-18 cursor-pointer text-primary"
                title="Yenile"
                onClick={() => {
                  setIsLoading(true);
                  onUpdateHotelInfo(id);
                }}
              ></i>
            )}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {otel_adi}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {otel_odasayisi} oda
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {lokasyon}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {telefon}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            Komisyon Oranı: %{komisyon_orani}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            Kapıda: {kapida_odeme}
          </div>
          <div className="flex-grow-1 d-flex border py-2 justify-content-center fs-14 fw-medium">
            {ad_soyad}
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
          <div className="d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <span className="border text-center fs-11 py-2">
                {tarih_1} / {gece_1} gece
              </span>
              <span className="border text-center fs-11 py-2">
                %{crm_otelfiyat_kar_1}
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_1} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_1} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_1} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={ets_1} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilsepeti_1} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilsepeti}
                    alt="tatilsepeti"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={jollytur_1} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilbudur_1} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilbudur}
                    alt="tatilbudur"
                    width={48}
                    height={18}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_1} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_1) === firstBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilsepeti_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(jolly_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilbudur_fiyat_1))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_1))}
              </span>
            </div>
            {!hideFirstRatesRow && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                }}
              >
                <span className="border text-center fs-11 py-2"> </span>
                <span className="border text-center fs-11 py-2">
                  {otelz_oran_1 === "0.00" ? "-" : otelz_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {hotels_oran_1 === "0.00" ? "-" : hotels_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {etstur_oran_1 === "0.00" ? "-" : etstur_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilsepeti_oran_1 === "0.00" ? "-" : tatilsepeti_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {jolly_oran_1 === "0.00" ? "-" : jolly_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilbudur_oran_1 === "0.00" ? "-" : tatilbudur_oran_1}
                </span>
                <span className="border text-center fs-11 py-2">
                  {odamax_oran_1 === "0.00" ? "-" : odamax_oran_1}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <span className="border text-center fs-11 py-2">
                {tarih_2} / {gece_2} gece
              </span>
              <span className="border text-center fs-11 py-2">
                %{crm_otelfiyat_kar_2}
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_2} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_2} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_2} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={ets_2} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilsepeti_2} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilsepeti}
                    alt="tatilsepeti"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={jollytur_2} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilbudur_2} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilbudur}
                    alt="tatilbudur"
                    width={48}
                    height={18}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_2} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_2) === secondBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilsepeti_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(jolly_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilbudur_fiyat_2))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_2))}
              </span>
            </div>
            {!hideSecondRatesRow && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                }}
              >
                <span className="border text-center fs-11 py-2"> </span>
                <span className="border text-center fs-11 py-2">
                  {otelz_oran_2 === "0.00" ? "-" : otelz_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {hotels_oran_2 === "0.00" ? "-" : hotels_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {etstur_oran_2 === "0.00" ? "-" : etstur_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilsepeti_oran_2 === "0.00" ? "-" : tatilsepeti_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {jolly_oran_2 === "0.00" ? "-" : jolly_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilbudur_oran_2 === "0.00" ? "-" : tatilbudur_oran_2}
                </span>
                <span className="border text-center fs-11 py-2">
                  {odamax_oran_2 === "0.00" ? "-" : odamax_oran_2}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <span className="border text-center fs-11 py-2">
                {tarih_3} / {gece_3} gece
              </span>
              <span className="border text-center fs-11 py-2">
                %{crm_otelfiyat_kar_3}
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span className="border py-2 text-center">
                <a href={otelfiyat_3} target="_blank" rel="noreferrer">
                  <img
                    src={OtelFiyat}
                    alt="otel_fiyat"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={otelz_3} target="_blank" rel="noreferrer">
                  <img src={Otelz} alt="otelz" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={hotels_3} target="_blank" rel="noreferrer">
                  <img src={Hotels} alt="hotels" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={ets_3} target="_blank" rel="noreferrer">
                  <img src={Etstur} alt="etstur" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilsepeti_3} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilsepeti}
                    alt="tatilsepeti"
                    width={48}
                    height={24}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={jollytur_3} target="_blank" rel="noreferrer">
                  <img src={Jolly} alt="jolly" width={48} height={24} />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={tatilbudur_3} target="_blank" rel="noreferrer">
                  <img
                    src={Tatilbudur}
                    alt="tatilbudur"
                    width={48}
                    height={18}
                  />
                </a>
              </span>
              <span className="border py-2 text-center">
                <a href={odamax_3} target="_blank" rel="noreferrer">
                  <img src={Odamax} alt="odamax" width={48} height={16} />
                </a>
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <span
                className="border text-center fs-11 py-2"
                style={{
                  backgroundColor:
                    parseFloat(otelfiyat_fiyat_3) === thirdBestPrice
                      ? "var(--theme-success)"
                      : "var(--theme-danger)",
                }}
              >
                {Math.ceil(Number(otelfiyat_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(otelz_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(hotels_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(etstur_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilsepeti_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(jolly_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(tatilbudur_fiyat_3))}
              </span>
              <span className="border text-center fs-11 py-2">
                {Math.ceil(Number(odamax_fiyat_3))}
              </span>
            </div>
            {!hideThirdRatesRow && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                }}
              >
                <span className="border text-center fs-11 py-2"> </span>
                <span className="border text-center fs-11 py-2">
                  {otelz_oran_3 === "0.00" ? "-" : otelz_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {hotels_oran_3 === "0.00" ? "-" : hotels_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {etstur_oran_3 === "0.00" ? "-" : etstur_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilsepeti_oran_3 === "0.00" ? "-" : tatilsepeti_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {jolly_oran_3 === "0.00" ? "-" : jolly_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {tatilbudur_oran_3 === "0.00" ? "-" : tatilbudur_oran_3}
                </span>
                <span className="border text-center fs-11 py-2">
                  {odamax_oran_3 === "0.00" ? "-" : odamax_oran_3}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <FacilityAvailabilityModal
        modal={modal}
        toggle={toggle}
        facilityAvailabilities={facilityAvailabilities}
        isLoading={availabilityLoading}
      />
      <SaveDescriptionModal
        modal={adsModal}
        toggle={toggleAdsModal}
        description={reklam_aciklama}
        saveDescription={(description) =>
          onUpdateAdsDescription(otel_id, description)
        }
        isLoading={updateDescriptionLoading}
      />
      <SaveDescriptionModal
        modal={descriptionModal}
        toggle={toggleDescriptionModal}
        description={aciklama}
        saveDescription={(description) => onUpdateDescription(id, description)}
        isLoading={updateDescriptionLoading}
      />
    </>
  );
};

export default AnalysisCard;
