import { Spinner } from "reactstrap";

interface ChartContainerProps {
  containerHeight: number;
  containerWidth: number | string;
  isLoading: boolean;
  noData: boolean;
  children: React.ReactNode;
}

const ChartContainer = ({
  containerHeight,
  containerWidth,
  noData,
  isLoading,
  children,
}: ChartContainerProps) => {
  return (
    <div
      className="d-flex flex-wrap gap-2 "
      style={{
        width: containerWidth,
        height: containerHeight + 40,
      }}
    >
      <div
        className="w-100 h-100 shadow-sm rounded p-2 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "var(--theme-light)",
        }}
      >
        {isLoading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner color="primary" />
          </div>
        ) : noData ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span className="text-muted fw-medium ">
              Gösterilecek veri bulunamadı.
            </span>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default ChartContainer;
