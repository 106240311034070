import { createSlice } from "@reduxjs/toolkit";
import { ListGroupsResponse } from "types/userOperations";

export interface ListGroupsInitialState {
  groups: ListGroupsResponse[];
  isLoading: boolean;
  error: string;
  updateGroupLoading: boolean;
  updateGroupError: string;
  changeGroupStatusLoading: boolean;
  changeGroupStatusError: string;
}

export const listGroupsInitialState: ListGroupsInitialState = {
  groups: [],
  isLoading: false,
  error: "",
  updateGroupLoading: false,
  updateGroupError: "",
  changeGroupStatusLoading: false,
  changeGroupStatusError: "",
};

const listGroupsSlice = createSlice({
  name: "listGroups",
  initialState: listGroupsInitialState,
  reducers: {
    handleSaveGroupsResponse(state, action) {
      state.groups = action.payload;
    },
    handleUpdateGroup(state, action) {
      const updatedGroup = action.payload;
      state.groups = state.groups.map((group) =>
        group.grup_id === updatedGroup.id ? updatedGroup : group
      );
    },
    handleChangeGroupStatus(state, action) {
      const { id } = action.payload;
      
      state.groups = state.groups.map((group) =>{
        if (group.grup_id === id) {
          const newStatus = group.grup_aktiflik === '0' ? '1' : '0'; 
          return { ...group, grup_aktiflik: newStatus };
        }
        return group;
      });
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateGroupLoading(state, action) {
      state.updateGroupLoading = action.payload;
    },
    setUpdateGroupError(state, action) {
      state.updateGroupError = action.payload;
    },
    setChangeGroupStatusLoading(state, action) {
      state.changeGroupStatusLoading = action.payload;
    },
    setChangeGroupStatusError(state, action) {
      state.changeGroupStatusError = action.payload;
    },
  },
});

export const {
  handleSaveGroupsResponse,
  handleUpdateGroup,
  handleChangeGroupStatus,
  setIsLoading,
  setError,
  setUpdateGroupLoading,
  setUpdateGroupError,
  setChangeGroupStatusLoading,
  setChangeGroupStatusError,
} = listGroupsSlice.actions;

export default listGroupsSlice.reducer;
