import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CommissionPaymentInitialState,
  resetCommissionPaymentCardInfo,
} from "slices/financeAndAccounting/commissionPayment/reducer";
import {
  getCardInfo,
  makeCommissionPayment,
} from "slices/financeAndAccounting/commissionPayment/thunk";
import {
  CreditCard,
  CreditCardBrand,
  CreditCardInstallment,
} from "types/GlobalTypes";
import { HotelInitialState } from "slices/hotel/reducer";
import { MakeCommissionPaymentRequestPayload } from "types/financeAndAccounting";

const useHandlePayment = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    commissionPaymentCardInfo,
    cardInfoLoading,
    cardInfoError,
    makeCommissionPaymentLoading,
    makeCommissionPaymentError,
  } = useSelector(
    (state: { CommissionPayment: CommissionPaymentInitialState }) =>
      state.CommissionPayment
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const getInstallments = (bin: string) => {
    dispatch(getCardInfo({ bin: Number(bin), otel_id }));
  };

  const resetInstallments = () => {
    dispatch(resetCommissionPaymentCardInfo());
  };

  const hasInstallments = commissionPaymentCardInfo.installment;

  const paymentInstallments = commissionPaymentCardInfo.installment_details;

  const installments: CreditCardInstallment[] = Object.entries(
    paymentInstallments
  ).map(([key, value]) => {
    return {
      paymentId: Number(key),
      month: Number(key),
      amount: value.monthly_amount,
      total: value.total_amount,
    };
  });

  const showBrand =
    !!commissionPaymentCardInfo.cardBrand ||
    !!commissionPaymentCardInfo.cardProgram;

  const cardBrand: CreditCardBrand = {
    showBrand,
    cardBrand: commissionPaymentCardInfo.cardBrand,
    cardBrandLogo: commissionPaymentCardInfo.cardBrandIcon,
    cardProgram: commissionPaymentCardInfo.cardProgram,
    cardProgramLogo: commissionPaymentCardInfo.cardProgramIcon,
  };

  const makePayment = (
    card: CreditCard,
    installment: CreditCardInstallment
  ) => {
    const payload: MakeCommissionPaymentRequestPayload = {
      otel_id,
      kart_ad_soyad: card.cardOwner,
      cardnumber: card.cardNumber,
      cardexpiredatemonth: card.expiryDate.split("/")[0],
      cardexpiredateyear: card.expiryDate.split("/")[1],
      cardcvv2: card.cvc,
      banka: commissionPaymentCardInfo.bank,
      taksit_sayisi: String(installment.month),
      eposta: "",
    };

    dispatch(makeCommissionPayment(payload));
  };

  return {
    getInstallments,
    resetInstallments,
    hasInstallments,
    installments,
    cardBrand,
    makePayment,
    isLoading: cardInfoLoading,
    error: cardInfoError ? "Geçersiz Kart Numarası" : "",
    makeCommissionPaymentLoading,
    makeCommissionPaymentError,
  };
};

export default useHandlePayment;
