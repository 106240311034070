import { GeneralPriceSettingsPage } from "Components/Custom/PricesAndQuotaPages";

import PageContainer from "pages/PageContainer";

import useGeneralPriceSettings from "Components/Hooks/pricesAndQuota/useGeneralPriceSettings";

const GeneralPriceSettings = () => {
  const { loading, error, isLoading, isValidHotel } = useGeneralPriceSettings();

  return (
    <PageContainer title="Genel Fiyat Ayarları">
      <GeneralPriceSettingsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default GeneralPriceSettings;
