import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  handleSaveFacilityManagers,
  resetFacilityManager,
  setAddManagerLoading,
  setAddManagerError,
  setUpdateManagerLoading,
  setUpdateManagerError,
  setDeleteManagerLoading,
  setDeleteManagerError,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import {
  AddFacilityManagerRequestPayload,
  UpdateFacilityManagerRequestPayload,
} from "types/facility";

import {
  GET_FACILITY_MANAGERS,
  ADD_FACILITY_MANAGER,
  UPDATE_FACILITY_MANAGER,
  DELETE_FACILITY_MANAGER,
} from "helpers/url_helper";

import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getFacilityManagers =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_FACILITY_MANAGERS + otel_id);

      dispatch(handleSaveFacilityManagers(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addFacilityManager =
  (
    payload: AddFacilityManagerRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddManagerLoading(true));
      await axios.post(ADD_FACILITY_MANAGER, payload);

      successCallback();
      dispatch(resetFacilityManager());
      dispatch(setToastSuccess("Tesis yetkilisi eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddManagerError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddManagerLoading(false));
    }
  };

export const updateFacilityManager =
  (
    payload: UpdateFacilityManagerRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateManagerLoading(true));
      await axios.post(UPDATE_FACILITY_MANAGER, payload);

      successCallback();
      dispatch(resetFacilityManager());
      dispatch(setToastSuccess("Tesis yetkilisi güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateManagerError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateManagerLoading(false));
    }
  };

export const deleteFacilityManager =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setDeleteManagerLoading(true));
      await axios.post(DELETE_FACILITY_MANAGER, { id });

      dispatch(setToastSuccess("Tesis yetkilisi silindi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setDeleteManagerError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setDeleteManagerLoading(false));
    }
  };
