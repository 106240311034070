import { SaveRoomPersonPriceRateState } from "types/PricesQuotas";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import Select from "react-select";
import { Dispatch } from "redux";
import {
  handleChangeInput,
  handleChangeSelectAccomodationType,
  PersonPriceRatesInitialState,
} from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getRoomPersonPriceRates } from "slices/pricesAndQuota/roomPersonPriceRates/thunk";
import { useMemo } from "react";
interface RoomAccomodationTypesProps {
  values: SaveRoomPersonPriceRateState;

  childOptions: { value: number; label: string }[];
  arrangeAccumodationTypes: (maxAdults: number, maxGuests: number) => string[];
  hotelId: number;
  roomId: number;
}

const RoomAccomodationTypes = ({
  hotelId,
  roomId,
  values,
  childOptions,
  arrangeAccumodationTypes,
}: RoomAccomodationTypesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const accumodationTypes = useMemo(
    () =>
      arrangeAccumodationTypes(
        values.uygulanabilecek_max_yetiskin,
        values.uygulanabilecek_max_konuk
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.uygulanabilecek_max_yetiskin, values.uygulanabilecek_max_konuk]
  );

  const { checkedAccommodationTypes } = useSelector(
    (state: { RoomPersonPriceRates: PersonPriceRatesInitialState }) =>
      state.RoomPersonPriceRates
  );

  return (
    <>
      {values.detayli_oda_kontenjan && (
        <CustomFormFieldContainer
          label="Oda Konaklama Çeşitleri"
          divider
          orientation="vertical"
        >
          {values.ozel_katsayi === true && (
            <CustomFormFieldContainer label="">
              <Select
                placeholder="Seçiniz..."
                value={childOptions.find(
                  (option) =>
                    option.value === (values.cocuk_ozel_yas_ozel_katsayi ?? 0)
                )}
                options={childOptions}
                onChange={(selected: any) => {
                  const payload = {
                    otel_id: hotelId,
                    oda_id: roomId,
                    cocuk_ozel_yas_id: selected.value,
                  };
                  dispatch(
                    getRoomPersonPriceRates(payload, {
                      type: "konaklama_cesitleri",
                      value: selected.value,
                    })
                  );
                }}
              ></Select>
            </CustomFormFieldContainer>
          )}
          <div
            className="p-2"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "0.5rem",
            }}
          >
            {accumodationTypes.map((type, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-center align-items-center border rounded p-2 bg-white"
                >
                  <Input
                    type="checkbox"
                    checked={checkedAccommodationTypes.includes(type)}
                    onChange={(e) => {
                      dispatch(handleChangeSelectAccomodationType(type));
                    }}
                    id={`konaklama_cesitleri_${index}`}
                    name={type}
                  />
                  <label
                    htmlFor={`konaklama_cesitleri_${index}`}
                    className="form-label m-0 ms-2"
                  >
                    {type}
                  </label>
                  {values.ozel_katsayi && (
                    <Input
                      type="number"
                      id={`ozel_katsayi_${index}`}
                      name={`ozel_katsayi_${index}`}
                      value={values.konaklama_cesitleri[type]}
                      className="ms-2 bg-light"
                      onChange={(e) => {
                        dispatch(
                          handleChangeInput({
                            konaklama_cesitleri: {
                              ...values.konaklama_cesitleri,
                              [type]: e.target.value.toString(),
                            },
                          })
                        );
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </CustomFormFieldContainer>
      )}
    </>
  );
};

export default RoomAccomodationTypes;
