import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import moment from "moment";

import {
  ReservationCallInitialState,
  handleCalculationFormChange,
} from "slices/reservation/reservationCall/reducer";
import { calculatePriceForHotel as calculatePriceForHotelAction } from "slices/reservation/reservationCall/thunk";

const useHandleDetails = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  useEffect(() => {
    const hasCalculatedPrice = !!calculatePriceForHotel.otel_adi;

    const parameters = sessionStorage.getItem("customerCalculationParameters")
      ? JSON.parse(sessionStorage.getItem("customerCalculationParameters")!)
      : "";

    if (!hasCalculatedPrice && parameters) {
      dispatch(
        handleCalculationFormChange({
          giris_tarihi: moment(parameters.giris_tarihi).format("D MMMM Y"),
          cikis_tarihi: moment(parameters.cikis_tarihi).format("D MMMM Y"),
          yetiskin_sayisi: parameters.yetiskin_sayisi,
          cocuk_sayisi: parameters.cocuk_sayisi,
          cocuk_dogum_1: parameters.cocuk_dogum_1,
          cocuk_dogum_2: parameters.cocuk_dogum_2,
          cocuk_dogum_3: parameters.cocuk_dogum_3,
          cocuk_dogum_4: parameters.cocuk_dogum_4,
        })
      );

      dispatch(calculatePriceForHotelAction(parameters, () => {}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    otel_adi,
    otel_mevkii,
    fiyatlar,
    vitrin,
    crm_aciklama,
    crm_aciklama_oper_checkboxlar,
  } = calculatePriceForHotel;

  const location = otel_mevkii.map((item) => item.lokasyon);

  const title = { name: otel_adi, location: location.join(" - ") };

  const request = Object.values(fiyatlar)[0].request;

  const featuredImages = vitrin;

  const subtitle = {
    giris_tarihi: request.giris_tarihi,
    cikis_tarihi: request.cikis_tarihi,
    yetiskin_sayisi: request.yetiskin_sayisi,
    cocuk_sayisi: request.cocuk_sayisi,
    gece_sayisi: request.toplam_gece,
    cocuk_yas: request.cocuk_yaslari ?? [],
  };

  const crmCheckboxes = crm_aciklama_oper_checkboxlar
    .split("|")
    .filter((checkbox) => checkbox.length > 1);

  return { title, subtitle, featuredImages, crm_aciklama, crmCheckboxes };
};

export default useHandleDetails;
