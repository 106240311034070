import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormChoices, CreateWebSiteFormErrors } from "types/myWebSite";
import FeaturesOptions from "./FeaturesOptions";

interface FeaturedFieldsProps {
  values: CreateWebSiteState;
  formChoices: CreateWebSiteFormChoices;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
}
const FeaturedFields = ({
  values,
  formChoices,
  formErrors,
  validateFormChange
}: FeaturedFieldsProps) => {

  return (
    <div className="mb-4">
      <CustomFormContainer title="Öne Çıkan Özellikler (3 adet)" lg={12} xl={12}>
        <FeaturesOptions
          values={values}
          formChoices={formChoices}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          maxSelection={3}
        />
      </CustomFormContainer>
    </div>
  );
};

export default FeaturedFields;
