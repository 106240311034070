import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import { useState } from "react";
import { Collapse } from "reactstrap";
import DateTypeSelector from "./FormFields/DateTypeSelector";
import DateInput from "./FormFields/DateInput";
import FilterReservationsButtons from "./FormFields/FilterReservationsButtons";
import ReservationCodeInput from "./FormFields/ReservationCodeInput";
import InetCodeInput from "./FormFields/InetCodeInput";
import CustomerNameInput from "./FormFields/CustomerNameInput";
import HotelList from "./FormFields/HotelList";

const FilterForm = () => {
  const {
    fieldVisibility,
    isLoading,
    filterForm,
    dateTypeOptions,
    hotelOptions,
    selectedHotelOptions,
    handleFormChange,
    onSelectHotels,
    onGetFilteredList,
    onResetFilter,
  } = useHandleForm();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <CustomFormContainer>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer bg-secondary py-1 px-3 text-white rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="fs-18 fw-bold m-0 ">Sonuçları Filtrele</p>
        <i className={`ri-arrow-${isOpen ? "up" : "down"}-s-line fs-24`}></i>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column gap-2">
        {fieldVisibility["tarih_turu"] && (
            <DateTypeSelector
              fieldKey="tarih_turu"
              options={dateTypeOptions}
              values={filterForm}
              handleFormChange={handleFormChange}
            />
          )}
          {fieldVisibility["otel_list"] && (
            <HotelList
              fieldKey="otel_list"
              options={hotelOptions}
              selectedHotelOptions={selectedHotelOptions}
              handleSelectHotelOptions={onSelectHotels}
            />
          )}
          {(fieldVisibility["start_date"] || fieldVisibility["end_date"]) && (
            <DateInput
              fieldKeyStart="start_date"
              fieldKeyEnd="end_date"
              values={filterForm}
              handleFormChange={handleFormChange}
            />
          )}
          {fieldVisibility["rezervasyon_kodu"] && (
            <ReservationCodeInput
              fieldKey="rezervasyon_kodu"
              values={filterForm}
              handleFormChange={handleFormChange}
            />
          )}
          {fieldVisibility["inet_kodu"] && (
            <InetCodeInput
              fieldKey="inet_kodu"
              values={filterForm}
              handleFormChange={handleFormChange}
            />
          )}
          {fieldVisibility["musteri_adi"] && (
            <CustomerNameInput
              fieldKey="musteri_adi"
              values={filterForm}
              handleFormChange={handleFormChange}
            />
          )}
          <FilterReservationsButtons
            onResetFilter={onResetFilter}
            onGetFilteredList={onGetFilteredList}
            isLoading={isLoading}
          />
        </div>
      </Collapse>
    </CustomFormContainer>
  );
};

export default FilterForm;
