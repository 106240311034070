import { createSlice } from "@reduxjs/toolkit";
import {
    AddCertificateFormErrors,
    AddCertificatePayload,
    Certificate,
} from "types/myWebSite";

export interface AddCertificateInitialState {
  addCertificate: AddCertificatePayload;
  certificates: Certificate[];
  certificateLinkFormErrors: AddCertificateFormErrors;
  isLoading: boolean;
  error: string;
  addCertificateLoading: boolean;
  addCertificateError: string;
  deleteLoading: boolean;
  deleteCertificateError: string;
}

export const addCertificateInitialState: AddCertificateInitialState = {
  addCertificate: {
    otel_id: 0,
    sertifika_adi: "",
    sertifika_link: "",
  },
  certificates: [],
  certificateLinkFormErrors: {},
  isLoading: false,
  error: "",
  addCertificateLoading: false,
  addCertificateError: "",
  deleteLoading: false,
  deleteCertificateError: "",
};

const addCertificateSlice = createSlice({
  name: "addCertificate",
  initialState: addCertificateInitialState,
  reducers: {
    handleSaveCertificates(state, action) {
      state.certificates = action.payload;

      console.log("Certificates first states : ", action.payload);
    },
    handleChangeInput(state, action) {
      state.addCertificate = {
        ...state.addCertificate,
        ...action.payload,
      };
    },
    handleCertificateLinkFormErrors(state, action) {
      state.certificateLinkFormErrors = action.payload;
    },
    handleResetAddCertificateFormFields(state){
       state.addCertificate = {
        otel_id: 0,
        sertifika_adi: "",
        sertifika_link: "",
       };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddCertificateLoading(state, action) {
      state.addCertificateLoading = action.payload;
    },
    setAddCertificateError(state, action) {
      state.addCertificateError = action.payload;
    },
    setDeleteCertificateLinkLoading(state, action) {
      state.deleteLoading = action.payload;
    },
    setDeleteCertificateLinkError(state, action) {
      state.deleteCertificateError = action.payload;
    },
  },
});

export const {
    handleSaveCertificates,
    handleChangeInput,
    handleCertificateLinkFormErrors,
    handleResetAddCertificateFormFields,
    setIsLoading,
    setError,
    setAddCertificateLoading,
    setAddCertificateError,
    setDeleteCertificateLinkLoading,
    setDeleteCertificateLinkError,
} = addCertificateSlice.actions;

export default addCertificateSlice.reducer;
