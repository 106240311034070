import { CustomFormContainer } from "Components/Custom/UI";
import { FormGroup, Input, Label } from "reactstrap";
import { CreateRoomFormValues } from "types/reservation";

interface GuestCheckboxesProps {
  formValues: CreateRoomFormValues;
  handleChangeInput: (key: string, value: any) => void;
}

const GuestCheckboxes = ({
  formValues,
  handleChangeInput,
}: GuestCheckboxesProps) => {
  return (
    <CustomFormContainer>
      <div className="d-flex gap-5">
        <FormGroup check>
          <Input
            id="honeymoonCheckbox"
            name="honeymoon"
            type="checkbox"
            checked={formValues.honeymoon}
            onChange={(e) => handleChangeInput("honeymoon", e.target.checked)}
          />

          <Label for="honeymoonCheckbox">Balayı Çiftiyiz</Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id="noSmokingCheckbox"
            name="noSmoking"
            type="checkbox"
            checked={formValues.noSmoking}
            onChange={(e) => handleChangeInput("noSmoking", e.target.checked)}
          />
          <Label for="noSmokingCheckbox">Sigara Kullanmıyorum</Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id="differentBedCheckbox"
            name="differentBed"
            type="checkbox"
            checked={formValues.differentBed}
            onChange={(e) =>
              handleChangeInput("differentBed", e.target.checked)
            }
          />
          <Label for="differentBedCheckbox">Ayrı Yatak Ricadır </Label>
        </FormGroup>
      </div>
    </CustomFormContainer>
  );
};

export default GuestCheckboxes;
