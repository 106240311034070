import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import WidgetLocationSelector from "./FormFields/WidgetLocationSelector";
import WidgetUrl from "./FormFields/WidgetUrl";
import CreateWidgetButton from "./FormFields/CreateWidgetButton";

// formChoices: Option[];
// interface Option {
//     value: string;
//     label: string;
//   }

const WidgetForm = () => {
  const { values, formChoices, requestPayload, isLoading } = useHandleForm();

  return (
    <>
      <div className="px-4 mb-4">
        <CustomFormContainer title="Widget Oluştur" lg={12} xl={12}>
          <WidgetLocationSelector values={values} formChoices={formChoices} />
          <WidgetUrl values={values} />
          <div className="d-flex justify-content-end">
            <CreateWidgetButton
              values={values}
              requestPayload={requestPayload}
            />
          </div>
        </CustomFormContainer>
      </div>
    </>
  );
};

export default WidgetForm;
