import useHandlePayment from "./useHandlePayment";
import CustomCreditCard from "Components/Custom/UI/CustomCreditCard";
import { CreditCard, CreditCardInstallment } from "types/GlobalTypes";

const PaymentForm = () => {
  const {
    getInstallments,
    resetInstallments,
    hasInstallments,
    installments,
    cardBrand,
    isLoading,
    error,
    makePayment,
    makeCommissionPaymentLoading,
  } = useHandlePayment();

  return (
    <>
      <CustomCreditCard
        formId="commission-payment-form"
        makePayment={(card: CreditCard, installment: CreditCardInstallment) => {
          makePayment(card, installment);
        }}
        getInstallments={getInstallments}
        resetInstallments={resetInstallments}
        hasInstallments={hasInstallments}
        installments={installments}
        installmentsLoading={isLoading}
        makePaymentLoading={makeCommissionPaymentLoading}
        cardBrand={cardBrand}
        invalidCard={error}
      />
    </>
  );
};

export default PaymentForm;
