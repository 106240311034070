import { useDispatch, useSelector } from "react-redux";
import { Dispatch, SetStateAction, useState } from "react";
import { CategorizeFacilityInitialState } from "slices/facilityCategorization/categorizeFaclity/reducer";
import { CategorizeFacilityPayload } from "types/facilityCategorization";
import {
  changeCategoryOfFacilities,
  getCategorySelectionResponse,
} from "slices/facilityCategorization/categorizeFaclity/thunk";

const useHandleForm = (
  setSelectedCategory: Dispatch<SetStateAction<string>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { formChoices, changeFacilityCategory } = useSelector(
    (state: { CategorizeFacility: CategorizeFacilityInitialState }) =>
      state.CategorizeFacility
  );

  const [formState, setFormState] = useState<CategorizeFacilityPayload>({
    otel_idleri: [],
    sinif: "",
  });

  const handleSelectCategory = (sinif: string) => {
    setSelectedCategory(sinif);
    dispatch(getCategorySelectionResponse(sinif));
  };

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeFacilityCategory = () => {
    const params = {
      otel_idleri: formState.otel_idleri,
      sinif: formState.sinif,
    };

    dispatch(
      changeCategoryOfFacilities(params, () => {
        setFormState({
          otel_idleri: [],
          sinif: "",
        });
        setSelectedCategory("");
      })
    );
  };

  const handleResetForm = () => {
    setFormState({
      otel_idleri: [],
      sinif: "",
    });
    setSelectedCategory("");
  };

  const categoryOptions = formChoices.otel_siniflari?.map((item) => ({
    value: item.value,
    label: item.text,
  }));

  console.log("categoryOptions : ", categoryOptions);

  const facilityOptions = changeFacilityCategory.map((item) => ({
    value: item.otel_id.toString(),
    label: item.otel_adi,
  }));

  return {
    values: formState,
    categoryOptions,
    facilityOptions,
    handleFormChange,
    handleSelectCategory,
    handleSave: handleChangeFacilityCategory,
    handleClearForm: handleResetForm,
  };
};

export default useHandleForm;
