import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { BasicPriceEntryInitialState } from "slices/pricesAndQuota/basicPriceEntry/reducer";
import { getBasicPriceEntryPageData } from "slices/pricesAndQuota/basicPriceEntry/thunk";

const useBasicPriceEntry = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { hotel: pageData, isLoading } = useSelector(
    (state: { BasicPriceEntry: BasicPriceEntryInitialState }) =>
      state.BasicPriceEntry
  );

  const otel_id = hotel.otel_id;

  useEffect(() => {
    if (otel_id) {
      dispatch(
        getBasicPriceEntryPageData({
          otel_id,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  return {
    error,
    loading,
    savingPrice: isLoading,
    pageData,
  };
};

export default useBasicPriceEntry;
