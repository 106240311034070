import { DailyCallsPerPersonPage } from "Components/Custom/ReportsPages";
import useDailyCallsPerPerson from "Components/Hooks/reports/useDailyCallsPerPerson";
import PageContainer from "pages/PageContainer";

const DailyCallsPerPerson = () => {
  const { isLoading } = useDailyCallsPerPerson();

  return (
    <PageContainer title="Kişilere Gelen Günlük Çağrı">
      <DailyCallsPerPersonPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default DailyCallsPerPerson;
