import { CustomFormFieldContainer } from "Components/Custom/UI";
import {
  AddAdditionalPagesFormErrors,
  AdditionalPage,
  AdditionalPageState,
} from "types/myWebSite";
import Select from "react-select";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";

interface PageStatusSelectorProps {
  values: AdditionalPageState;
  formChoices: Option[];
  formErrors: AddAdditionalPagesFormErrors;
  validateFormChange: (values: AdditionalPageState) => void;
}

interface Option {
  value: string;
  label: string;
}

const PageStatusSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: PageStatusSelectorProps) => {

    
  const dispatch: Dispatch<any> = useDispatch();
  
  const selectedOption = formChoices.find(
    (option) => option.value === values.organizasyon_sayfasi_acik.toString()
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Sayfa Durumu :"
        error={formErrors.organizasyon_sayfasi_acik}
        divider
        required
      >
        <Select
          options={formChoices}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            dispatch(
                handleChangeAddAdditionalPageInput({
                  organizasyon_sayfasi_acik: selected.value,
                })
              );
    
              validateFormChange({ ...values, organizasyon_sayfasi_acik: Number(selected.value) });
            }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PageStatusSelector;
