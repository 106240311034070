import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { Link } from "react-router-dom";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface DiscountsCardProps {
  otel_id: number;
}

const DiscountsCard = ({ otel_id }: DiscountsCardProps) => {
  return (
    <>
      <CustomDashboardCard
        xl={4}
        title="Daha fazla rezervasyon almak için fiyatlarınıza indirim tanımlayabilirsiniz"
        color="purple"
      >
        <div className="d-flex justify-content-between align-items-center">
          <Link
            style={{
              fontWeight: "500",
              fontSize: "20px",
              color: DASHBOARD_COLORS.white,
              textDecoration: "none",
            }}
            to={"/indirimler"}
          >
            İndirimler...
          </Link>
          <i className="ri-star-smile-fill" style={{ fontSize: "48px" }} />
        </div>
      </CustomDashboardCard>
    </>
  );
};

export default DiscountsCard;
