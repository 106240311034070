import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import { HotelInitialState } from "slices/hotel/reducer";
import { getChildSpecialAgeInformation } from "slices/pricesAndQuota/childSpecialAgeInformation/thunk";
import {
  ChildSpecialAgeInformationsInitialState,
  handleSelectRoom,
} from "slices/pricesAndQuota/childSpecialAgeInformation/reducer";

const useChildSpecialAgeInformation = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );
  const { selectedRoom, isLoading } = useSelector(
    (state: {
      ChildSpecialAgeInformations: ChildSpecialAgeInformationsInitialState;
    }) => state.ChildSpecialAgeInformations
  );

  const otel_id = hotel.otel_id;
  const rooms = hotel.odalar.map((room) => ({
    otel_id: room.otel_id,
    oda_id: room.id,
    oda_isim: room.oda_isim,
  }));

  const hotelAndSelectedRoom = {
    otel_id,
    oda_id: selectedRoom,
  };

  useEffect(() => {
    if (otel_id && rooms.length > 0) {
      dispatch(handleSelectRoom(selectedRoom || rooms[0].oda_id));
      dispatch(
        getChildSpecialAgeInformation({
          otel_id,
          oda_id: selectedRoom || rooms[0].oda_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = rooms.length > 0;

  return {
    error,
    isLoading,
    loading,
    rooms,
    hotelAndSelectedRoom,
    isValidHotel,
  };
};

export default useChildSpecialAgeInformation;
