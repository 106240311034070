import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";

import {
  setIsLoading,
  setError,
  resetAfterAddNewHotelFeature,
} from "./reducer";

import { setToastError, setToastSuccess } from "slices/toast/reducer";

import { ADD_HOTEL_FEATURE } from "helpers/url_helper";

import { AddHotelFeatureRequestPayload } from "types/definitions";

import { Dispatch } from "redux";

export const addHotelFeature =
  (payload: AddHotelFeatureRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(ADD_HOTEL_FEATURE, payload);

      dispatch(setToastSuccess("Tesis özelliği eklendi."));
      dispatch(resetAfterAddNewHotelFeature());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
