import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
  CustomSelectBox,
} from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import useHandleForm from "./useHandleForm";
import Select from "react-select";
import { SearchCriteria } from "./useHandleForm";

interface Option {
  value: number | SearchCriteria;
  label: string;
}

const FilterForm = () => {
  const {
    filterForm,
    searchCriteria,
    handleFormChange,
    handleSearchCriteriaChange,
    handleChangeSelectedOldDates,
    oldDatesOptions,
    managerOptions,
    searchCriteriaOptions,
    onGetFilteredList,
    onResetFilter,
  } = useHandleForm();
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormContainer>
      <div className="d-flex gap-4 flex-wrap align-items-end">
        <CustomFormFieldContainer label="Arama Kriteri" orientation="vertical">
          <Select
            options={searchCriteriaOptions}
            value={searchCriteriaOptions.find(
              (option) => option.value === searchCriteria
            )}
            onChange={(selectedOption: Option) => {
              typeof selectedOption.value !== "number" &&
                handleSearchCriteriaChange(selectedOption.value);
            }}
            placeholder="Arama Kriteri Seçiniz..."
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer label="Tarih" orientation="vertical">
          {searchCriteria === "anlik" ? (
            <Flatpickr
              className="form-control"
              value={[filterForm.sdate, filterForm.edate]}
              onChange={(dates) => {
                const [startDate, endDate] = dates;

                if (startDate && endDate) {
                  const stringStartDate =
                    moment(startDate).format("YYYY-MM-DD");
                  const stringEndDate = moment(endDate).format("YYYY-MM-DD");

                  handleFormChange("sdate", stringStartDate);
                  handleFormChange("edate", stringEndDate);
                }
              }}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                showMonths: 2,
                locale: Turkish,
                onDayCreate,
              }}
            />
          ) : (
            <CustomSelectBox
              inputId="select-old-date-input"
              options={oldDatesOptions}
              defaultOption={oldDatesOptions[0]}
              onSelect={(value) => {
                handleFormChange("oldDates", value);
                typeof value === "string" &&
                  handleChangeSelectedOldDates(
                    value.split(" - ")[0],
                    value.split(" - ")[1]
                  );
              }}
              placeholder="Tarih Seçiniz..."
              boxWidth="250px"
            />
          )}
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Tesis Sorumlusu"
          orientation="vertical"
        >
          <Select
            options={managerOptions}
            value={managerOptions.find(
              (option) => option.value === filterForm.yetkili_id
            )}
            onChange={(selectedOption: Option) => {
              handleFormChange("yetkili_id", selectedOption.value);
            }}
            placeholder="Tesis Sorumlusu Seçiniz..."
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer label="" orientation="vertical">
          <div className="d-flex gap-2">
            <CustomButton variant="warning" onClick={onResetFilter}>
              Filtreyi Temizle
            </CustomButton>
            <CustomButton variant="success" onClick={onGetFilteredList}>
              Otelleri Getir
            </CustomButton>
          </div>
        </CustomFormFieldContainer>
      </div>
    </CustomFormContainer>
  );
};

export default FilterForm;
