import { Col, Input, Label } from "reactstrap";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleSaveChilSpecialAgeId } from "slices/pricesAndQuota/advancedEntry/reducer";
import { CustomTooltip } from "Components/Custom/UI";

import { childSpecialAgeTooltip } from "utils/tooltips";

interface ChildSpecialAgeSelectorProps {
  childSpeacialAgeId: number;
  childSpecialAgeOptions: {
    value: number;
    label: string;
  }[];
}

const ChildSpecialAgeSelector = ({
  childSpeacialAgeId,
  childSpecialAgeOptions,
}: ChildSpecialAgeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div className="px-4 py-2 mb-2 theme-bg-light border border-2 rounded">
      <Col md={4}>
        <div className="flex-grow-1 mb-4">
          <Label
            className="form-label"
            htmlFor="child-special-age-select-input"
          >
            Özel Yaş Aralığı
            <CustomTooltip
              title={childSpecialAgeTooltip.title}
              message={childSpecialAgeTooltip.message}
              target={childSpecialAgeTooltip.target}
            />
          </Label>
          <Input
            id="room-select-input"
            name="room"
            type="select"
            value={childSpeacialAgeId}
            onChange={(e) => {
              dispatch(handleSaveChilSpecialAgeId(parseInt(e.target.value)));
            }}
          >
            {childSpecialAgeOptions.map((childSpecialAgeOption) => (
              <option
                key={childSpecialAgeOption.value}
                value={childSpecialAgeOption.value}
              >
                {childSpecialAgeOption.label}
              </option>
            ))}
          </Input>
        </div>
      </Col>
    </div>
  );
};

export default ChildSpecialAgeSelector;
