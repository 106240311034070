import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface RoomsLackPictureTableProps {
  isLoading: boolean;
}

const RoomsLackPictureTable = ({ isLoading }: RoomsLackPictureTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  return (
    <CustomReactTable
      fileName="Resmi Eksik Odalar"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default RoomsLackPictureTable;
