import { Row } from "reactstrap";

import { Hotel } from "types/PricesQuotas";
import PriceEntryItem from "./PriceEntryItem";
import PriceEntryTableHeader from "./PriceEntryTableHeader";
import SavePricesButton from "../SavePricesButton";

interface PriceEntryContainerProps {
  data: Hotel;
  isLoading: boolean;
  onSave: () => void;
}

const PriceEntryContainer = ({
  data,
  isLoading,
  onSave,
}: PriceEntryContainerProps) => {
  return (
    <Row className="border border-2 border-white overflow-hidden rounded my-3">
      <PriceEntryTableHeader room={data.odalar[0]} />
      {data.odalar.map((room, index) => (
        <PriceEntryItem
          key={index}
          room={room}
          currency={data.currency}
          index={index}
        />
      ))}
      <SavePricesButton isLoading={isLoading} onSave={onSave} />
    </Row>
  );
};

export default PriceEntryContainer;
