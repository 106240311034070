import { CustomFormContainer } from "Components/Custom/UI";
import {
  AddUserFormChoices,
  AddUserFormErrors,
  AddUserState,
} from "types/userOperations";
import UserGeneralInformation from "./UserGeneralInformation";
import UserAuthorityAndJobType from "./UserAuthorityAndJobType";
import UserCodeAndExtension from "./UserCodeAndExtension";

interface UserInfoFieldsProps {
  values: AddUserState;
  formChoices: AddUserFormChoices;
  formErrors: AddUserFormErrors;
  validateFormChange: (values: AddUserState) => void;
}

const UserInfoFields = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: UserInfoFieldsProps) => {
  return (
    <>
      <CustomFormContainer title="Kullanıcı Ekle">
        <UserGeneralInformation
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserCodeAndExtension
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <UserAuthorityAndJobType
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          formChoices={formChoices}
        />
      </CustomFormContainer>
    </>
  );
};

export default UserInfoFields;
