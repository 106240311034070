import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonnelHotelAssignmentsInitialState,
  resetListAssignedHotelsInputs,
} from "slices/userOperations/personnelHotelAssignments/reducer";
import { getAssignedHotels } from "slices/userOperations/personnelHotelAssignments/thunk";
import { GetAssignedHotelsPayload } from "types/userOperations";

interface Option {
  value: number;
  label: string;
}
type ShowTableState = "show_data" | "no_data" | "default";

const useHandleForm = ( setShowTableData: Dispatch<SetStateAction<ShowTableState>>) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { listAssignedHotelsFormChoices } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const [cityOptions, setCityOptions] = useState<Option[]>([]);
  const [personnelOptions, setPersonnelOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (listAssignedHotelsFormChoices) {
      const cityOpt = listAssignedHotelsFormChoices.sehir.map((option) => ({
        value: option.id,
        label: option.sehir,
      }));

      cityOpt.unshift({ value: 0, label: "Şehir Seç" });

      setCityOptions(cityOpt || []);

      const personnelOpt = listAssignedHotelsFormChoices.personel.map((option) => ({
        value: option.id,
        label: option.ad_soyad,
      }));;

      personnelOpt.unshift({ value: 0, label: "Personel Seç" });

      setPersonnelOptions(personnelOpt);
    }
  }, [listAssignedHotelsFormChoices]);





  const [filterForm, setFilterForm] = useState<GetAssignedHotelsPayload>({
    sehir_id: 0,
    personel_id: 0,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleGetFilteredList = () => {
    const params = {
      sehir_id: filterForm.sehir_id,
      personel_id: filterForm.personel_id,
    };
    dispatch(getAssignedHotels(params, () => {
      setShowTableData("show_data");
    }));
  };

  const handleClearFilter = () => {
    setFilterForm({
      sehir_id: 0,
      personel_id: 0,
    });
    dispatch(resetListAssignedHotelsInputs());
    setShowTableData("default");
  };

  return {
    filterForm,
    cityOptions,
    personnelOptions,
    handleFormChange,
    handleGetFilteredList,
    handleClearFilter,
  };
};

export default useHandleForm;
