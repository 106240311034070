import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { CommentsCardData } from "types/dashboard";
import CommentsCardSlider from "./CommentsCardSlider";


interface CommentsCardProps {
  values: CommentsCardData[];
}

const CommentsCard = ({ values }: CommentsCardProps) => {
  return (
    <>
      <CustomDashboardCard xl={12} title="Son Gelen Yorumlar">
        <CommentsCardSlider comments={values}/>
      </CustomDashboardCard>
    </>
  );
};

export default CommentsCard;
