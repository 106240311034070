import { useSelector } from "react-redux";
import { CategorizeFacilityInitialState } from "slices/facilityCategorization/categorizeFaclity/reducer";

const useHandleTable = () => {
  const { facilityList } = useSelector(
    (state: { CategorizeFacility: CategorizeFacilityInitialState }) =>
        state.CategorizeFacility
  );

  const hotelsOfCategory : {[key: string] : string[]} = {};

  facilityList.forEach((hotel) => {
    const facilityCategory = hotel.otel_sinif;
    const hotelName = hotel.otel_adi;

    if(hotelsOfCategory[facilityCategory]){
      hotelsOfCategory[facilityCategory].push(hotelName);
    } else {
      hotelsOfCategory[facilityCategory] = [hotelName];
    }
  })


  return {
    hotelsOfCategory,
  };
};

export default useHandleTable;