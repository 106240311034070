import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import {
  getReservationCall,
  updateReservationCall,
} from "slices/reservation/reservationCall/thunk";
import { setToastError } from "slices/toast/reducer";
import { UpdateReservationCallRequestPayload } from "types/reservation";

interface Option {
  value: number;
  label: string;
}

interface Params {
  aranan: string;
  arayan: string;
  dahili: string;
}

const useHandleForm = (params: Params) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { reservationCall } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const [formValues, setFormValues] =
    useState<UpdateReservationCallRequestPayload>({
      musteri_id: reservationCall.musteri_id,
      arama_id: reservationCall.arama_id,
      musteri_ad_soyad: reservationCall.musteri_ad_soyad,
      musteri_tel: reservationCall.musteri_tel,
      musteri_tel_2: reservationCall.musteri_tel_2,
      musteri_eposta: reservationCall.musteri_eposta,
      rezervasyon_disi: "hayir",
      rezervasyon_disi_islem: "",
      aciklama_islemler: "",
      sonra_aranacak_tarih: "",
      sonra_aranacak_saat: "",
      aciklama_musteri: "",
      onay: true,
      aranan_otel_telefon: reservationCall.aranan_otel_telefon,
      otel_id: 0,
      otel_adi: "",
    });

  useEffect(() => {
    setFormValues({
      musteri_id: reservationCall.musteri_id,
      arama_id: reservationCall.arama_id,
      musteri_ad_soyad: reservationCall.musteri_ad_soyad,
      musteri_tel: reservationCall.musteri_tel,
      musteri_tel_2: reservationCall.musteri_tel_2,
      musteri_eposta: reservationCall.musteri_eposta,
      aranan_otel_telefon: reservationCall.aranan_otel_telefon,
      rezervasyon_disi: "hayir",
      rezervasyon_disi_islem: "",
      aciklama_islemler: "",
      sonra_aranacak_tarih: "",
      sonra_aranacak_saat: "",
      aciklama_musteri: "",
      onay: true,
      otel_id: 0,
      otel_adi: "",
      uye_id: 0,
    });
  }, [reservationCall]);

  const handleFormChange = (key: string, value: string | number) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSaveCallInfo = () => {
    const payload: UpdateReservationCallRequestPayload = {
      musteri_id: formValues.musteri_id,
      arama_id: formValues.arama_id,
      musteri_ad_soyad: formValues.musteri_ad_soyad,
      musteri_tel: formValues.musteri_tel,
      musteri_tel_2: formValues.musteri_tel_2,
      musteri_eposta: formValues.musteri_eposta,
      rezervasyon_disi: formValues.rezervasyon_disi,
      rezervasyon_disi_islem: formValues.rezervasyon_disi_islem,
      aciklama_islemler: formValues.aciklama_islemler,
      sonra_aranacak_tarih: formValues.sonra_aranacak_tarih,
      sonra_aranacak_saat: formValues.sonra_aranacak_saat,
      aciklama_musteri: formValues.aciklama_musteri,
      onay: true,
      aranan_otel_telefon: formValues.aranan_otel_telefon,
      uye_id: formValues.uye_id,
      otel_id:
        formValues.otel_id === 0 && reservationCall.otel_sorgu.length === 1
          ? reservationCall.otel_sorgu[0].otel_id
          : formValues.otel_id,
      otel_adi:
        formValues.otel_adi === "" && reservationCall.otel_sorgu.length === 1
          ? reservationCall.otel_sorgu[0].otel_adi
          : formValues.otel_adi,
    };

    if (payload.otel_id === 0) {
      dispatch(setToastError("Lütfen otel seçiniz."));
      return;
    }
    if (payload.otel_adi === "") {
      dispatch(setToastError("Lütfen otel seçiniz."));
      return;
    }

    if (
      payload.rezervasyon_disi_islem === "rezervasyon-disi" &&
      payload.aciklama_islemler === ""
    ) {
      dispatch(setToastError("Lütfen işlem açıklaması giriniz."));
      return;
    }

    if (
      payload.rezervasyon_disi_islem === "sonra-ara" &&
      payload.aciklama_islemler === ""
    ) {
      dispatch(setToastError("Lütfen işlem açıklaması giriniz."));
      return;
    }

    if (
      payload.rezervasyon_disi_islem === "sonra-ara" &&
      (payload.sonra_aranacak_saat === "" ||
        payload.sonra_aranacak_tarih === "")
    ) {
      dispatch(setToastError("Lütfen sonra aranacak tarih ve saat giriniz."));
      return;
    }

    if (payload.rezervasyon_disi_islem === "rezervasyon-disi") {
      payload.sonra_aranacak_tarih = "";
      payload.sonra_aranacak_saat = "";
    }

    if (payload.uye_id === 0) {
      delete payload.uye_id;
    }
    dispatch(
      updateReservationCall(payload, () => {
        dispatch(getReservationCall(params));
      })
    );
  };

  const resetForm = () => {
    setFormValues({
      musteri_id: reservationCall.musteri_id,
      arama_id: reservationCall.arama_id,
      musteri_ad_soyad: reservationCall.musteri_ad_soyad,
      musteri_tel: reservationCall.musteri_tel,
      musteri_tel_2: reservationCall.musteri_tel_2,
      musteri_eposta: reservationCall.musteri_eposta,
      rezervasyon_disi: "hayir",
      rezervasyon_disi_islem: "",
      aciklama_islemler: "",
      sonra_aranacak_tarih: "",
      sonra_aranacak_saat: "",
      aciklama_musteri: "",
      onay: true,
      aranan_otel_telefon: reservationCall.aranan_otel_telefon,
      otel_id: 0,
      otel_adi: "",
      uye_id: 0,
    });

    window.scrollTo(0, 0);
  };

  const [hotelsOptions, setHotelsOptions] = useState<Option[]>([]);
  const [personOptions, setPersonOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (reservationCall.otel_sorgu.length > 1) {
      const options = reservationCall.otel_sorgu.map((hotel) => ({
        value: hotel.otel_id,
        label: hotel.otel_adi,
      }));

      setHotelsOptions(options);
    }

    const personOptions = reservationCall.uyeler.map((person) => ({
      value: person.id,
      label: person.ad_soyad,
    }));

    setPersonOptions(personOptions);
  }, [reservationCall]);

  return {
    reservationCall,
    formValues,
    hotelsOptions,
    personOptions,
    handleFormChange,
    onSaveCallInfo: handleSaveCallInfo,
    onResetForm: resetForm,
  };
};

export default useHandleForm;
