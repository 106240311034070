import { HotelInvoicesPage } from "Components/Custom/FinanceAndAccountingPages";
import useHotelInvoices from "Components/Hooks/financeAndAccounting/useHotelInvoices";
import PageContainer from "pages/PageContainer";

const HotelInvoices = () => {
  const { isLoading, loading, error, isValidHotel } = useHotelInvoices();
  return (
    <PageContainer title="Otelinize Kesilen Faturalar">
      <HotelInvoicesPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default HotelInvoices;
