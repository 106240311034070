import { CustomFormContainer } from "Components/Custom/UI";
import {
  CreateRoomAddedRoom,
  CreateRoomFormValues,
  EnterReservationFormValues,
} from "types/reservation";
import AddedRoom from "./AddedRoom";
interface AddedRoomListProps {
  formValues: EnterReservationFormValues;
  addedRoomsInfo: CreateRoomAddedRoom[];
  onUpdatedAddedRooms: (id: number, room: CreateRoomFormValues) => void;
  onRemoveAddedRoom: (id: number, index: number) => void;
}

const AddedRoomList = ({
  formValues,
  addedRoomsInfo,
  onUpdatedAddedRooms,
  onRemoveAddedRoom,
}: AddedRoomListProps) => {
  const roomNumber = addedRoomsInfo.length;
  const totalAmount = addedRoomsInfo.reduce((acc, room) => acc + room.price, 0);

  const totalKismiAmount = addedRoomsInfo
    .filter((room) => room.kismi_odeme === 1)
    .reduce(
      (acc, room) => acc + (room.price * room.kismi_odeme_orani) / 100,
      0
    );

  const totalKaporaliAmount = addedRoomsInfo.reduce(
    (acc, room) =>
      acc + (room.fiyatlar.indirimli_tutar - room.fiyatlar.maliyet_tutar),
    0
  );

  return (
    <CustomFormContainer title="Oda Bilgileri">
      <div className="d-flex justify-content-between gap-5">
        <span className=" fw-medium fs-14">
          TOPLAM {roomNumber} ODA İÇİN REZERVASYON YAPILACAK
        </span>
        <div className="d-flex gap-5 me-5">
          {formValues.odeme_tipi === "kaporali" ? (
            <span className=" fw-medium fs-14">
              TOPLAM KAPORA TUTARI : {Math.ceil(totalKaporaliAmount)} TL
            </span>
          ) : (
            <span className=" fw-medium fs-14">
              TOPLAM REZERVASYON TUTARI : {Math.ceil(totalAmount)} TL
            </span>
          )}
          {formValues.odeme_tipi === "kismi" && (
            <span className=" fw-medium fs-14">
              TOPLAM KISMİ TUTAR : {Math.ceil(totalKismiAmount)} TL
            </span>
          )}
        </div>
      </div>
      <span className="fw-medium fs-12 theme-text-danger">
        Rezervasyon bilgileri girildikten sonra Oda Oluştur butonu ile oda
        oluşturmak zorunludur. Oluşturulan oda tutarlarının toplamı aşağıdaki
        Ödeme Bilgileri kısmından çekim yapılmak üzere eklenmelidir.
      </span>

      <div className="d-flex flex-column gap-3">
        {addedRoomsInfo.map((room, index) => (
          <AddedRoom
            key={index}
            formValues={formValues}
            room={room}
            index={index}
            onUpdatedAddedRooms={onUpdatedAddedRooms}
            onRemoveAddedRoom={onRemoveAddedRoom}
          />
        ))}
      </div>
    </CustomFormContainer>
  );
};

export default AddedRoomList;
