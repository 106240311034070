import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ContractRulesInitialState } from "slices/financeAndAccounting/contractRules/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

import {
  getContractProcedures,
  getHotelContractProcedures,
  getHotelContractRules,
} from "slices/financeAndAccounting/contractRules/thunk";

const useContractRules = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, hotelContractRuleLoading, addContractRuleLoading } =
    useSelector(
      (state: { ContractRules: ContractRulesInitialState }) =>
        state.ContractRules
    );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (otel_id) {
        const payload = {
          otel_id,
          page: 1,
          per_page: 10,
          search: "",
        };

        dispatch(getHotelContractProcedures(payload));
        dispatch(getHotelContractRules(otel_id));
      } else {
        const payload = {
          otel_id: 0,
          page: 1,
          per_page: 10,
          search: "",
        };

        dispatch(getContractProcedures(payload));
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  return {
    isLoading,
    hotelContractRuleLoading,
    addContractRuleLoading,
  };
};

export default useContractRules;
