import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleGallery = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const galleryImages = calculatePriceForHotel.gallery;

  return { galleryImages };
};

export default useHandleGallery;
