import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { HotelInitialState } from "slices/hotel/reducer";
import { AddFooterLinkInitialState } from "slices/myWebSite/addFooterLinks/reducer";
import { deleteFooterLink, getFooterLinks } from "slices/myWebSite/addFooterLinks/thunk";
import { FooterLink } from "types/myWebSite";


const useHandleTable = () => {
    const {footerLinks} = useSelector(
        (state: { AddFooterLink: AddFooterLinkInitialState }) => state.AddFooterLink
      );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const dispatch: Dispatch<any> = useDispatch();

  const handleDelete = (id: number) => {
    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("otel_id", otel_id.toString())
    dispatch(
        deleteFooterLink(formData, () => {
        dispatch(
            getFooterLinks(otel_id)
        );
      })
    );
  };

  const tableData: FooterLink[] = footerLinks?.map(
    (footerLink) => ({
      title: footerLink.title,
      link: footerLink.link,
      id: footerLink.id,
      otel_id : footerLink.otel_id,
      add_time: footerLink.add_time,
    })
  );

  const tableColumns = [
    {
      header: "Başlık",
      accessorKey: "title",
      enableColumnFilter: false,
    },
    {
        header: "Link",
        accessorKey: "link",
        enableColumnFilter: false,
      },
      {
        header: "Ekleme Zamanı",
        accessorKey: "add_time",
        enableColumnFilter: false,
      },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
