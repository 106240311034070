import { Col, Label, Row } from "reactstrap";
import InputField from "../InputField";
import { PriceType } from "types/PricesQuotas";
import { getPriceTypeLabel } from "helpers/general_helpers";

interface PriceTypesContainerProps {
  priceTypes: PriceType[];
  isReady: boolean;
  values: any;
  onChange: (type: string, value: number, isBasePrice: boolean) => void;
  notAllowedToEdit: boolean;
}

const PriceTypesContainer = ({
  priceTypes,
  isReady,
  values,
  onChange,
  notAllowedToEdit,
}: PriceTypesContainerProps) => {
  return (
    <div className="d-flex flex-column gap-2 p-2">
      {priceTypes.map((priceType, index) => (
        <Row
          key={index}
          className="d-flex justify-content-between align-items-baseline"
        >
          {isReady ? (
            <>
              <Col xs={6}>
                <Label className="m-0">
                  {getPriceTypeLabel(priceType.fiyat_tipi)}
                </Label>
              </Col>
              <Col xs={6}>
                <InputField
                  identifier="TRY"
                  value={values[priceType.fiyat_tipi]}
                  isEditable={priceType.fiyat_girilebilir}
                  type={priceType.fiyat_tipi}
                  isBasePrice={priceType.baz_fiyat}
                  onChange={onChange}
                  notAllowedToEdit={notAllowedToEdit}
                />
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      ))}
    </div>
  );
};

export default PriceTypesContainer;
