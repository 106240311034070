import { useSelector } from "react-redux";
import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";

const useHandleCounts = () => {
  const { competitorAnalysisResponse } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  const { personel_cc_sayisi, ilk_uc_personel_cc_sayisi } =
    competitorAnalysisResponse;

  const firstGroupCounts = ilk_uc_personel_cc_sayisi.map((count) => {
    return {
      ad_soyad: count.ad_soyad,
      otel_sayisi: count.total,
      order: 0,
    };
  });

  firstGroupCounts.sort((a, b) => b.otel_sayisi - a.otel_sayisi);

  const secondGroupCounts = personel_cc_sayisi.map((count) => {
    return {
      ad_soyad: count.ad_soyad,
      otel_sayisi: count.total,
      order: 0,
    };
  });

  secondGroupCounts.sort((a, b) => b.otel_sayisi - a.otel_sayisi);

  if (firstGroupCounts && firstGroupCounts.length) {
    let order = 1;
    firstGroupCounts[0].order = order;
    for (let i = 1; i < firstGroupCounts.length; i++) {
      if (
        firstGroupCounts[i].otel_sayisi === firstGroupCounts[i - 1].otel_sayisi
      ) {
        firstGroupCounts[i].order = firstGroupCounts[i - 1].order;
      } else {
        firstGroupCounts[i].order = ++order;
      }
    }
  }

  if (secondGroupCounts && secondGroupCounts.length) {
    let order = 1;
    secondGroupCounts[0].order = order;
    for (let i = 1; i < secondGroupCounts.length; i++) {
      if (
        secondGroupCounts[i].otel_sayisi ===
        secondGroupCounts[i - 1].otel_sayisi
      ) {
        secondGroupCounts[i].order = secondGroupCounts[i - 1].order;
      } else {
        secondGroupCounts[i].order = ++order;
      }
    }
  }

  return { firstGroupCounts, secondGroupCounts };
};

export default useHandleCounts;
