import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  WelcomeAndCalculationInitialState,
  handleSelectHotel,
} from "slices/reports/welcomeAndCalculation/reducer";

import { getWelcomeAndCalculation } from "slices/reports/welcomeAndCalculation/thunk";

import parse from "html-react-parser";

const useHandleTable = () => {
  const { welcomeAndCalculation } = useSelector(
    (state: { WelcomeAndCalculation: WelcomeAndCalculationInitialState }) =>
      state.WelcomeAndCalculation
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (otel_id: number) => {
    dispatch(handleSelectHotel(otel_id));
    dispatch(
      getWelcomeAndCalculation({
        otel_id,
      })
    );
    window.scrollTo(0, 0);
  };

  const tableData = welcomeAndCalculation?.map((welcomeAndCalculation) => ({
    "Otel ID": welcomeAndCalculation.otel_id,
    "Üye Ad Soyad": welcomeAndCalculation.uye_ad_soyad,
    "Otel Adı": welcomeAndCalculation.otel_adi,
    İl: welcomeAndCalculation.il_adi,
    İlçe: welcomeAndCalculation.ilce_adi,
    "Otel Sınıfı": welcomeAndCalculation.otel_sinif,
    "Otel Telefon": welcomeAndCalculation.telefon,
    Tedarikçi: welcomeAndCalculation.tedarikci,
    "Karşılama Kodu": welcomeAndCalculation.karsilama_kodu,
    "Fiyat Hesaplama": welcomeAndCalculation.fiyat_hesaplama,
    "Fiyat Gösterimi": welcomeAndCalculation.fiyat_listesi_goster,
    "Acente Ödemeli": welcomeAndCalculation.acenta_odemeli,
    "Kapıda Ödeme Kalan Oda":
      welcomeAndCalculation.kapida_odeme_kisitlama_kalan_oda,
    "Kısmi Ödeme": welcomeAndCalculation.kismi_odeme,
    "Kaporalı Ödeme": welcomeAndCalculation.kaporali_odeme,
    "Vitrin Fiyatı": welcomeAndCalculation.vitrin_fiyat,
  }));

  const tableColumns = [
    {
      header: "Otel ID",
      accessorKey: "Otel ID",
      enableColumnFilter: false,
    },
    {
      header: "Üye Ad Soyad",
      accessorKey: "Üye Ad Soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "İl",
      accessorKey: "İl",
      enableColumnFilter: false,
    },
    {
      header: "İlçe",
      accessorKey: "İlçe",
      enableColumnFilter: false,
    },
    {
      header: "Otel Sınıfı",
      accessorKey: "Otel Sınıfı",
      enableColumnFilter: false,
    },
    {
      header: "Otel Telefon",
      accessorKey: "Otel Telefon",
      enableColumnFilter: false,
    },
    {
      header: "Tedarikçi",
      accessorKey: "Tedarikçi",
      enableColumnFilter: false,
    },
    {
      header: "Karşılama Kodu",
      accessorKey: "Karşılama Kodu",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Hesaplama",
      accessorKey: "Fiyat Hesaplama",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Gösterimi",
      accessorKey: "Fiyat Gösterimi",
      enableColumnFilter: false,
    },
    {
      header: "Acente Ödemeli",
      accessorKey: "Acente Ödemeli",
      cell: (cell: any) => {
        return <span>{parse(cell.getValue())}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "Kapıda Ödeme Kalan Oda",
      accessorKey: "Kapıda Ödeme Kalan Oda",
      enableColumnFilter: false,
    },
    {
      header: "Kısmi Ödeme",
      accessorKey: "Kısmi Ödeme",
      enableColumnFilter: false,
    },
    {
      header: "Kaporalı Ödeme",
      accessorKey: "Kaporalı Ödeme",
      enableColumnFilter: false,
    },
    {
      header: "Vitrin Fiyatı",
      accessorKey: "Vitrin Fiyatı",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "Otel ID",
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return {
    tableData,
    tableColumns,
  };
};

export default useHandleTable;
