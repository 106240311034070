import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { CompetitorAnalysisInitialState } from "slices/adsAndAnalyze/competitorAnalysis/reducer";

import { getCompetitorAnalysis } from "slices/adsAndAnalyze/competitorAnalysis/thunk";
import { CompetitorAnalysisRequestParams } from "types/adsAndAnalyze";

const useCompetitorAnalysis = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, isEb } = useSelector(
    (state: { CompetitorAnalysis: CompetitorAnalysisInitialState }) =>
      state.CompetitorAnalysis
  );

  useEffect(() => {
    const getCompetitorAnalysisRequestParams: CompetitorAnalysisRequestParams =
      {
        eb: isEb,
        page: 1,
        per_page: 10,
      };

    dispatch(getCompetitorAnalysis(getCompetitorAnalysisRequestParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useCompetitorAnalysis;
