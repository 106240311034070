import { CustomRowOperationButton } from "Components/Custom/UI";
import { CONTRACT_TYPE } from "enums";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { ContractRulesInitialState } from "slices/financeAndAccounting/contractRules/reducer";
import { getHotelContractInvoice } from "slices/financeAndAccounting/contractRules/thunk";
import { TABLE_COLORS } from "utils/tableColors";

const rowColors = {
  [CONTRACT_TYPE.Aylik]: TABLE_COLORS.blue,
  [CONTRACT_TYPE["Oda Basi"]]: TABLE_COLORS.red,
  [CONTRACT_TYPE["Sezon Sonu"]]: TABLE_COLORS.purple,
  [CONTRACT_TYPE["Tek Seferlik"]]: TABLE_COLORS.gray,
  [CONTRACT_TYPE.Tekrarli]: TABLE_COLORS.yellow,
};

const useHandleTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((prevState) => !prevState);

  const { contractProcedures } = useSelector(
    (state: { ContractRules: ContractRulesInitialState }) => state.ContractRules
  );

  const dispatch: Dispatch<any> = useDispatch();

  const getHotelContractInvoiceById = (otel_id: number, kontrat_id: number) => {
    dispatch(getHotelContractInvoice({ otel_id, kontrat_id }));
  };

  const tableData = contractProcedures?.map((contractProcedure) => ({
    "Kural ID": contractProcedure.kural_id,
    "Otel ID": contractProcedure.otel_id,
    "Otel Adı": contractProcedure.otel_adi,
    Adı: contractProcedure.adi,
    "Kontrat Tipi": contractProcedure.kontrat_tipi,
    Tutar: contractProcedure.tutar,
    "İşlem Tarihi": contractProcedure.guncelleme_tarihi,
    "Fatura Tarihi": contractProcedure.fatura_tarihi,
    Durum: contractProcedure.durum,
    rowColor: rowColors[contractProcedure.kontrat_tipi as CONTRACT_TYPE],
  }));

  const tableColumns = [
    {
      header: "Kural ID",
      accessorKey: "Kural ID",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "Otel Adı",
      enableColumnFilter: false,
    },
    {
      header: "Adı",
      accessorKey: "Adı",
      enableColumnFilter: false,
    },
    {
      header: "Kontrat Tipi",
      accessorKey: "Kontrat Tipi",
      enableColumnFilter: false,
    },
    {
      header: "Tutar",
      accessorKey: "Tutar",
      enableColumnFilter: false,
    },
    {
      header: "İşlem Tarihi",
      accessorKey: "İşlem Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Fatura Tarihi",
      accessorKey: "Fatura Tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Durum",
      accessorKey: "Durum",
      enableColumnFilter: false,
    },
    {
      header: "İşlem",
      accessorKey: "Kural ID",
      cell: (cell: any) => {
        const id = cell.getValue();
        const otel_id = cell.row.original["Otel ID"];
        return (
          <CustomRowOperationButton
            variant="update"
            onClick={() => {
              getHotelContractInvoiceById(otel_id, id);
              toggleModal();
            }}
          />
        );
      },
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.blue,
      label: "Aylık",
    },
    {
      color: TABLE_COLORS.red,
      label: "Oda Başı Prim",
    },
    {
      color: TABLE_COLORS.purple,
      label: "Sezon Sonu Kotası",
    },
    {
      color: TABLE_COLORS.gray,
      label: "Tek Seferlik",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Tekrarlı Kota",
    },
  ];

  return {
    tableData,
    tableColumns,
    isOpen,
    toggleModal,
    legend,
  };
};

export default useHandleTable;
