import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";

import { ChildSpecialAgeInformationsInitialState } from "slices/pricesAndQuota/childSpecialAgeInformation/reducer";
import { NIGHT_COUNT } from "enums";
import {
  UpdateChildSpecialAgeInformation,
  ChildSpecialAgeInformationRequestPayload,
} from "types/PricesQuotas";
import {
  getChildSpecialAgeInformation,
  postChildSpecialAgeInformation,
  updateChildSpecialAgeInformation,
} from "slices/pricesAndQuota/childSpecialAgeInformation/thunk";
import useValidateForm from "./useValidateForm";

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetChildSpecialAgeInformation = () => {
    dispatch(getChildSpecialAgeInformation({ otel_id, oda_id }));
  };

  const selectChildSpecialAgeInformationState = (state: {
    ChildSpecialAgeInformations: ChildSpecialAgeInformationsInitialState;
  }) => state.ChildSpecialAgeInformations;

  const selectChildSpecialAgeInformationProperties = createSelector(
    selectChildSpecialAgeInformationState,
    (state) => ({
      childSpecialAgeInformation: state.childSpecialAgeInformation,
      childSpecialAgeInformationState: state.childSpecialAgeInformationState,
      childSpecialAgeInformationFormErrors:
        state.childSpecialAgeInformationFormErrors,
      selectedRoom: state.selectedRoom,
      isLoading: state.saveInformationLoading,
      error: state.saveInformationError,
    })
  );

  const {
    childSpecialAgeInformationState,
    childSpecialAgeInformation,
    childSpecialAgeInformationFormErrors,
    isLoading,
    error,
  } = useSelector(selectChildSpecialAgeInformationProperties);

  const nightCountOptions = [
    {
      value: NIGHT_COUNT.BIR_GECE,
      label: "1 Gece",
    },
    {
      value: NIGHT_COUNT.IKI_GECE,
      label: "2 Gece",
    },
    {
      value: NIGHT_COUNT.UC_GECE,
      label: "3 Gece",
    },
    {
      value: NIGHT_COUNT.DORT_GECE,
      label: "4 Gece",
    },
    {
      value: NIGHT_COUNT.BES_GECE,
      label: "5 Gece",
    },
    {
      value: NIGHT_COUNT.ALTI_GECE,
      label: "6 Gece",
    },
    {
      value: NIGHT_COUNT.YEDI_GECE,
      label: "7 Gece",
    },
    {
      value: NIGHT_COUNT.SEKIZ_GECE,
      label: "8 Gece",
    },
    {
      value: NIGHT_COUNT.DOKUZ_GECE,
      label: "9 Gece",
    },
    {
      value: NIGHT_COUNT.ON_GECE,
      label: "10 Gece",
    },
  ];

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const handleSave = () => {
    const { isValid } = validateForm(childSpecialAgeInformationState);
    handleSubmitted(true);

    if (isValid) {
      const addNewChildSpecialAgeInformation: ChildSpecialAgeInformationRequestPayload =
        {
          otel_id: otel_id,
          yas_isim: childSpecialAgeInformationState.ozel_yas_ismi,
          yas_gun: childSpecialAgeInformationState.gun,
          giris_gununde_uygula:
            childSpecialAgeInformationState.cocuk_ozel_yas_giris_gunu,
          satis_tarihi_secim:
            childSpecialAgeInformationState.satis_tarihi_secim,
          yas_sdate: childSpecialAgeInformationState.satis_tarihi_baslangic,
          yas_edate: childSpecialAgeInformationState.satis_tarihi_bitis,
        };
      dispatch(
        postChildSpecialAgeInformation(addNewChildSpecialAgeInformation, () => {
          handleGetChildSpecialAgeInformation();
        })
      );
    }
  };
  const handleUpdate = () => {
    const updateChildSpecialAgeInfo: UpdateChildSpecialAgeInformation = {
      otel_id: otel_id,
      oda_id: oda_id,
      yas_isim: childSpecialAgeInformationState.ozel_yas_ismi,
      yas_id: childSpecialAgeInformationState.id,
      yas_gun: childSpecialAgeInformationState.gun,
      giris_gununde_uygula:
        childSpecialAgeInformationState.cocuk_ozel_yas_giris_gunu,
      satis_tarihi_secim: childSpecialAgeInformationState.satis_tarihi_secim,
      yas_sdate: childSpecialAgeInformationState.satis_tarihi_baslangic,
      yas_edate: childSpecialAgeInformationState.satis_tarihi_bitis,
    };
    dispatch(
      updateChildSpecialAgeInformation(updateChildSpecialAgeInfo, () => {
        handleGetChildSpecialAgeInformation();
      })
    );
  };

  return {
    childSpecialAgeInformationState,
    childSpecialAgeInformation,
    nightCountOptions,
    formErrors: childSpecialAgeInformationFormErrors,
    handleSave,
    handleUpdate,
    isLoading,
    error,
    validateFormChange,
  };
};
export default useHandleForm;
