import { MailOrderInfoPage } from "Components/Custom/FinanceAndAccountingPages";
import useMailOrderInfo from "Components/Hooks/financeAndAccounting/useMailOrderInfo";
import PageContainer from "pages/PageContainer";

const MailOrderInfo = () => {
  const { isLoading, mailOrderLoading } = useMailOrderInfo();
  return (
    <PageContainer title="Mail Order Bilgileri">
      <MailOrderInfoPage
        isLoading={isLoading}
        formIsLoading={mailOrderLoading}
      />
    </PageContainer>
  );
};

export default MailOrderInfo;
