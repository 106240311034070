import { createSlice } from "@reduxjs/toolkit";

import {
  MoneyPointsResponse,
  MoneyPointsHotelUser,
  MoneyPoint,
  AddMoneyPointState,
  AddMoneyPointFormErrors,
} from "types/reports";

export interface MoneyPointsInitialState {
  moneyPointsResponse: MoneyPointsResponse;
  hotelUsers: MoneyPointsHotelUser[];
  moneyPoints: MoneyPoint[];
  addMoneyPoint: AddMoneyPointState;
  addMoneyPointFormErrors: AddMoneyPointFormErrors;
  isLoading: boolean;
  error: string;
  addMoneyPointLoading: boolean;
  addMoneyPointError: string;
}

const initialState: MoneyPointsInitialState = {
  moneyPointsResponse: {
    otel_kullanicilari: [],
    data: [],
  },
  moneyPoints: [],
  hotelUsers: [],
  addMoneyPoint: {
    operator_kodu: "",
    parapuan_tutar: "",
  },
  addMoneyPointFormErrors: {},
  isLoading: false,
  error: "",
  addMoneyPointLoading: false,
  addMoneyPointError: "",
};

const moneyPointsSlice = createSlice({
  name: "moneyPoints",
  initialState,
  reducers: {
    handleSaveMoneyPointsResponse(state, action) {
      state.moneyPointsResponse = action.payload;
      state.moneyPoints = action.payload.data;
      state.hotelUsers = action.payload.otel_kullanicilari;
    },
    handleChangeAddMoneyPointFormInput(state, action) {
      state.addMoneyPoint = {
        ...state.addMoneyPoint,
        ...action.payload,
      };
    },
    handleSaveAddMoneyPointFormErrors(state, action) {
      state.addMoneyPointFormErrors = action.payload;
    },
    resetAddMoneyPointFormState(state) {
      state.addMoneyPoint = {
        operator_kodu: "",
        parapuan_tutar: "",
      };
      state.addMoneyPointFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddMoneyPointLoading(state, action) {
      state.addMoneyPointLoading = action.payload;
    },
    setAddMoneyPointError(state, action) {
      state.addMoneyPointError = action.payload;
    },
  },
});

export const {
  handleSaveMoneyPointsResponse,
  handleChangeAddMoneyPointFormInput,
  handleSaveAddMoneyPointFormErrors,
  resetAddMoneyPointFormState,
  setIsLoading,
  setError,
  setAddMoneyPointLoading,
  setAddMoneyPointError,
} = moneyPointsSlice.actions;

export default moneyPointsSlice.reducer;
