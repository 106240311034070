import {
  CustomDasboardBarChart,
} from "Components/Custom/UI/CustomCharts";
import CustomDashboardCard from "Components/Custom/UI/CustomDashboardCard";
import { DASHBOARD_COLORS } from "utils/dashboardColors";

interface LastThirtyDayRoomNumbersCardProps {
  values: {
    [key: string]: string | number;
    name: string;
  }[];
  totalAmount: number;
}

const LastThirtyDayRoomNumbersCard = ({
  values,
  totalAmount,
}: LastThirtyDayRoomNumbersCardProps) => {
  const title_info = (
    <>
      {totalAmount.toString()} <i className="ri-hotel-bed-fill" />
    </>
  );

  return (
    <>
      <CustomDashboardCard
        xl={4}
        title="Son 30 Günlük Oda Sayıları"
        titleInfo={title_info}
      >
        <CustomDasboardBarChart data={values} bar_fill={DASHBOARD_COLORS.purple} />
      </CustomDashboardCard>
    </>
  );
};

export default LastThirtyDayRoomNumbersCard;
