import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveAdsReport,
  handleSaveAdsReportSummary,
  handleSaveAdsReportManagers,
  setIsLoading,
  setSummaryLoading,
  setManagersLoading,
  setError,
  setSummaryError,
  setManagersError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_ADS_REPORT,
  GET_ADS_REPORT_SUMMARY,
  GET_ADS_REPORT_MANAGERS,
} from "helpers/url_helper";

import { Dispatch } from "redux";

interface GetAdsReportRequestParams {
  sdate: string;
  edate: string;
  yetkili_id: number;
  search: string;
  per_page: number;
  page: number;
}

interface GetAdsReportSummaryRequestPayload {
  sdate: string;
  edate: string;
}

export const getAdsReport =
  (params: GetAdsReportRequestParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_ADS_REPORT, { params });

      const res = response.data || response;

      dispatch(handleSaveAdsReport(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getAdsReportSummary =
  (payload: GetAdsReportSummaryRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSummaryLoading(true));
      const response = await axios.post(GET_ADS_REPORT_SUMMARY, payload);

      dispatch(handleSaveAdsReportSummary(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSummaryError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSummaryLoading(false));
    }
  };

export const getAdsReportManagers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setManagersLoading(true));
    const response = await axios.get(GET_ADS_REPORT_MANAGERS);

    dispatch(handleSaveAdsReportManagers(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setManagersError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setManagersLoading(false));
  }
};
