import { createSlice } from "@reduxjs/toolkit";

import {
  FacilitySpecialDay,
  FacilitySpecialDayState,
  FacilitySpecialDayResponse,
  FacilitySpecialDayFormErrors,
} from "types/facility";

import { Pagination } from "types/GlobalTypes";

export interface FacilitySpecialDaysInitialState {
  specialDaysResponse: FacilitySpecialDayResponse;
  specialDays: FacilitySpecialDay[];
  specialDaysCategories: { [key: string]: number };
  specialDay: FacilitySpecialDayState;
  pagination: Pagination;
  specialDaysFormErrors: FacilitySpecialDayFormErrors;
  isLoading: boolean;
  error: string;
  addSpecialDayLoading: boolean;
  addSpecialDayError: string;
  updateSpecialDayLoading: boolean;
  updateSpecialDayError: string;
  deleteSpecialDayLoading: boolean;
  deleteSpecialDayError: string;
}

export const facilitySpecialDaysInitialState: FacilitySpecialDaysInitialState =
  {
    specialDaysResponse: {
      data: [],
      sabit_datalar: {},
    },
    specialDays: [],
    specialDaysCategories: {},
    specialDay: {
      id: 0,
      otel_id: 0,
      kategori_id: 0,
      ozel_gun_baslik: "",
      ozel_gun_sanatci_adi: "",
      ozel_gun_baslangic: "",
      ozel_gun_bitis: "",
      ozel_gun_aciklama: "",
      ozel_gun_resim: "",
      ozel_gun_resim_yol: "",
      resim: "",
    },
    pagination: {
      current_page: 0,
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [],
      next_page_url: null,
      path: "",
      per_page: 10,
      prev_page_url: null,
      to: 0,
      total: 0,
    },
    specialDaysFormErrors: {},
    isLoading: false,
    error: "",
    addSpecialDayLoading: false,
    addSpecialDayError: "",
    updateSpecialDayLoading: false,
    updateSpecialDayError: "",
    deleteSpecialDayLoading: false,
    deleteSpecialDayError: "",
  };

const facilitySpecialDaysSlice = createSlice({
  name: "facilitySpecialDays",
  initialState: facilitySpecialDaysInitialState,
  reducers: {
    handleSaveSpecialDaysResponse(state, action) {
      const { data, sabit_datalar, ...rest } = action.payload;

      state.specialDaysResponse = action.payload;
      state.specialDays = data;
      state.specialDaysCategories = sabit_datalar;
      state.pagination = { ...rest };
    },
    handleSaveSpecialDayForEdit(state, action) {
      state.specialDay = action.payload;
      state.specialDaysFormErrors = {};
    },
    handleChangeSpecialDayInput(state, action) {
      state.specialDay = {
        ...state.specialDay,
        ...action.payload,
      };
    },
    handleAddSpecialDayFormErrors(state, action) {
      state.specialDaysFormErrors = action.payload;
    },
    resetSpecialDay(state) {
      state.specialDay = {
        id: 0,
        otel_id: 0,
        kategori_id: 0,
        ozel_gun_baslik: "",
        ozel_gun_sanatci_adi: "",
        ozel_gun_baslangic: "",
        ozel_gun_bitis: "",
        ozel_gun_aciklama: "",
        ozel_gun_resim: "",
        ozel_gun_resim_yol: "",
        resim: "",
      };
      state.specialDaysFormErrors = {};
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },

    setAddSpecialDayLoading(state, action) {
      state.addSpecialDayLoading = action.payload;
    },
    setAddSpecialDayError(state, action) {
      state.addSpecialDayError = action.payload;
    },
    setUpdateSpecialDayLoading(state, action) {
      state.updateSpecialDayLoading = action.payload;
    },
    setUpdateSpecialDayError(state, action) {
      state.updateSpecialDayError = action.payload;
    },
    setDeleteSpecialDayLoading(state, action) {
      state.deleteSpecialDayLoading = action.payload;
    },
    setDeleteSpecialDayError(state, action) {
      state.deleteSpecialDayError = action.payload;
    },
  },
});

export const {
  handleSaveSpecialDaysResponse,
  handleSaveSpecialDayForEdit,
  handleChangeSpecialDayInput,
  handleAddSpecialDayFormErrors,
  resetSpecialDay,
  setIsLoading,
  setError,
  setAddSpecialDayLoading,
  setAddSpecialDayError,
  setUpdateSpecialDayLoading,
  setUpdateSpecialDayError,
  setDeleteSpecialDayLoading,
  setDeleteSpecialDayError,
} = facilitySpecialDaysSlice.actions;

export default facilitySpecialDaysSlice.reducer;
