import { CustomButton } from "Components/Custom/UI";

interface SaveQuotasButtonProps {
  isLoading: boolean;
  onSave: () => void;
}

const SaveQuotasButton = ({ isLoading, onSave }: SaveQuotasButtonProps) => {
  return (
    <div className="d-flex justify-content-end mt-4 mb-2">
      <CustomButton variant="primary" onClick={onSave} isLoading={isLoading}>
        Kaydet
      </CustomButton>
    </div>
  );
};

export default SaveQuotasButton;
