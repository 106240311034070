import WebSiteGeneralInfoFields from "./WebSiteGeneralInfoFields";
import FeaturedFields from "./FeaturedFields";
import CommercialPopupFields from "./CommercialPopupFields";
import BannerOneFields from "./BannerOneFields";
import BannerTwoFields from "./BannerTwoFields";
import GeneralInformationFields from "./GeneralInformationFields";
import AccomodationInformationFields from "./AccomodationInformationFields";
import SpaInformationFields from "./SpaInformationFields";
import FoodAndDrinkInformationFields from "./FoodAndDrinkInformationFields";
import ActivityInformationFields from "./ActivityInformationFields";
import PoolInformationFields from "./PoolInformationFields";
import MeetingRoomInformationFields from "./MeetingRoomInformationFields";
import ExtraInformationFields from "./ExtraInformationFields";
import useHandleForm from "./useHandleForm";
import { CustomPageDescriptions } from "Components/Custom/UI";
import { ADD_DETAILED_INFO_TO_WEBSITE_DESCRIPTIONS } from "utils/pageDescriptions";
import CreateWebSiteButton from "./CreateWebSiteButton";

const CreateWebSiteForm = () => {
  const {
    imageUrl,
    createWebSite,
    createWebSiteFormChoices,
    createWebSiteFormErrors,
    createWebSiteRequestPayload,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm();

  return (
    <>
      <div className="px-5 mb-4">
        <WebSiteGeneralInfoFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          formChoices={createWebSiteFormChoices}
          imageUrl={imageUrl}
        />
        <FeaturedFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          formChoices={createWebSiteFormChoices}
        />
        <CommercialPopupFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          formChoices={createWebSiteFormChoices}
          imageUrl={imageUrl}
        />
        <BannerOneFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <BannerTwoFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <CustomPageDescriptions
          variant="info"
          descriptions={ADD_DETAILED_INFO_TO_WEBSITE_DESCRIPTIONS}
        />
        <GeneralInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <AccomodationInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <SpaInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <FoodAndDrinkInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <ActivityInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <PoolInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <MeetingRoomInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <ExtraInformationFields
          values={createWebSite}
          formErrors={createWebSiteFormErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <CreateWebSiteButton
          values={createWebSite}
          requestPayload={createWebSiteRequestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CreateWebSiteForm;
