import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
import MeetingRoomInformationImage from "./MeetingRoomInformationImage";
import MeetingRoomInformationText from "./MeetingRoomInformationText";

interface MeetingRoomInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const MeetingRoomInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: MeetingRoomInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Toplantı Salonu Bilgileri" lg={12} xl={12}>
        <MeetingRoomInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <MeetingRoomInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default MeetingRoomInformationFields;
