import { useSelector } from "react-redux";
import AddHotelFeatureForm from "./AddHotelFeatureForm";
import { AddHotelFeatureInitialState } from "slices/definitions/addHotelFeature/reducer";

const AddHotelFeaturePage = () => {
  const { isLoading } = useSelector(
    (state: { AddHotelFeature: AddHotelFeatureInitialState }) =>
      state.AddHotelFeature
  );
  return (
    <>
      <AddHotelFeatureForm isLoading={isLoading} />
    </>
  );
};

export default AddHotelFeaturePage;
