import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { ContractRulesInitialState } from "slices/financeAndAccounting/contractRules/reducer";

const useHandleForm = () => {
  const [formValues, setFormValues] = useState({
    tarih: "",
    tutar: "",
    islem_adi: "",
    unvan: "",
    vergi_dairesi: "",
    vergi_no: "",
    adres: "",
    mail: "",
    aciklama: "",
  });

  const {
    hotelContractInvoice,
    hotelContractInvoiceLoading,
    hotelContractInvoiceError,
  } = useSelector(
    (state: { ContractRules: ContractRulesInitialState }) => state.ContractRules
  );

  useEffect(() => {
    setFormValues(hotelContractInvoice);
  }, [hotelContractInvoice]);

  const handleFormChange = (key: string, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFormSubmit = () => {
    //TODO: Implement form submit
    alert(`Form submitted with values: ${JSON.stringify(formValues, null, 2)}`);
  };

  return {
    formValues,
    handleFormChange,
    onSubmit: handleFormSubmit,
    isLoading: hotelContractInvoiceLoading,
    error: hotelContractInvoiceError,
  };
};

export default useHandleForm;
