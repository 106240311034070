import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveReservationTableResponse,
  handleSaveReservationPaymentTableResponse,
  handleSaveReservationCollectionResponse,
  setIsLoading,
  setError,
  setUpdatePriceProviderLoading,
  setUpdatePriceProviderError,
  setUpdateCollectionLoading,
  setUpdateCollectionError,
  setUpdatePaymentStatusLoading,
  setUpdatePaymentStatusError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  GET_RESERVATION_TABLE,
  UPDATE_PRICE_PROVIDER,
  GET_RESERVATION_PAYMENT,
  UPDATE_COLLECTION,
  GET_RESERVATION_COLLECTION,
  UPDATE_PAYMENT_STATUS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  ReservationCollectionPayload,
  ReservationPaymentTablePayload,
  ReservationTablePayload,
} from "types/userOperations";

type SuccessCallback = () => void;

export const getReservationTable =
  (rez_kod: string, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const response = await axios.get(
        `${GET_RESERVATION_TABLE}?rez_kod=${rez_kod}`
      );
      const res = (response as any).message ? response.data : response;

      successCallback();
      dispatch(handleSaveReservationTableResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updatePriceProvider =
  (payload: ReservationTablePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdatePriceProviderLoading(true));

      await axios.post(UPDATE_PRICE_PROVIDER, payload);

      successCallback();
      dispatch(setToastSuccess("Fiyat değiştirici başarıyla güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdatePriceProviderError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdatePriceProviderLoading(false));
    }
  };

export const getReservationPaymentTable =
  (rez_kod: string, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_RESERVATION_PAYMENT}?rez_kod=${rez_kod}`
      );
      const res = (response as any).message ? response.data : response;

      successCallback();
      dispatch(handleSaveReservationPaymentTableResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateCollection =
  (payload: ReservationPaymentTablePayload, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateCollectionLoading(true));
      await axios.post(UPDATE_COLLECTION, payload);

      successCallback();
      dispatch(setToastSuccess("Taksit başarıyla güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateCollectionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateCollectionLoading(false));
    }
  };

export const getReservationCollection =
  (rez_kod: string, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_RESERVATION_COLLECTION}?rez_kod=${rez_kod}`
      );
      const res = (response as any).message ? response.data : response;

      successCallback();
      dispatch(handleSaveReservationCollectionResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updatePaymentStatus =
  (payload: ReservationCollectionPayload, successCallback: SuccessCallback) => async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdatePaymentStatusLoading(true));
      await axios.post(UPDATE_PAYMENT_STATUS, payload);

      successCallback();
      dispatch(setToastSuccess("Tahsilat durumu başarıyla güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdatePaymentStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdatePaymentStatusLoading(false));
    }
  };
