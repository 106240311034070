import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FacilityRoomsInitialState } from "slices/facility/facilityRooms/reducer";
import {
  getFacilityRoom,
  updateFacilityRoom,
} from "slices/facility/facilityRooms/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
  aktifmi: boolean;
  lastItem: boolean;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    rooms,
    allRoomsNames,
    pagination,
    roomsLoading,
    updateFacilityRoomLoading,
  } = useSelector(
    (state: { FacilityRooms: FacilityRoomsInitialState }) => state.FacilityRooms
  );

  const { hotel, passiveRooms } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;

  const [roomsOptions, setRoomsOptions] = useState<Option[]>([]);
  const [selectedRoom, setSelectedRoom] = useState("");

  const handleSelectRoom = (selectedRoom: string) => {
    setSelectedRoom(selectedRoom);
  };
  const hotelRooms = useMemo(
    () => rooms.filter((item) => item.aktifmi),
    [rooms]
  );

  useEffect(() => {
    setRoomsOptions(
      allRoomsNames.map((item, index) => ({
        value: item.id.toString(),
        label: item.oda_isim,
        aktifmi: false,
        lastItem: index === allRoomsNames.length - 1,
      }))
    );
  }, [allRoomsNames]);

  const handleAddRoomToFacility = () => {
    const selectedRoomName = roomsOptions.find(
      (item) => item.value === selectedRoom
    )?.label;

    const formData = new FormData();
    formData.append("otel_id", otel_id.toString());
    formData.append("oda_ismi", selectedRoomName || "");
    formData.append("yeni_oda_ekle", "1");

    if (selectedRoomName) {
      dispatch(
        updateFacilityRoom(formData, () => {
          dispatch(
            getFacilityRoom({
              otel_id,
              oda_id: parseInt(selectedRoom),
            })
          );

          setSelectedRoom("");
        })
      );
    }
  };

  return {
    roomsOptions,
    handleSelectRoom,
    onAddRoom: handleAddRoomToFacility,
    selectedRoom,
    hotelRooms,
    passiveRooms,
    pagination,
    loading: roomsLoading,
    updateFacilityRoomLoading,
  };
};

export default useHandleForm;
