import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetAfterAddNewHotelFeature } from "slices/definitions/addHotelFeature/reducer";
import { addHotelFeature } from "slices/definitions/addHotelFeature/thunk";

import {
  AddHotelFeatureRequestPayload,
  AddHotelFeatureState,
} from "types/definitions";

interface AddNewHotelFeatureButtonProps {
  values: AddHotelFeatureState;
  requestPayload: AddHotelFeatureRequestPayload;
  validateForm: (values: AddHotelFeatureState) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const AddNewHotelFeatureButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: AddNewHotelFeatureButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleAddNewHotelFeature = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(addHotelFeature(requestPayload));
    }
  };

  const handleClearForm = () => {
    dispatch(resetAfterAddNewHotelFeature());
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton variant="danger" onClick={handleClearForm}>
        Formu Temizle
      </CustomButton>
      <CustomButton
        variant="success"
        onClick={handleAddNewHotelFeature}
        isLoading={isLoading}
      >
        Yeni Tesis Özelliği Ekle
      </CustomButton>
    </div>
  );
};

export default AddNewHotelFeatureButton;
