import { CustomButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { resetAfterUpdateDefineCurrency } from "slices/definitions/defineCurrency/reducer";

import { updateCurrency } from "slices/definitions/defineCurrency/thunk";

import {
  DefineCurrencyRequestPayload,
  DefineCurrencyState,
} from "types/definitions";

interface UpdateCurrenciesButtonProps {
  values: DefineCurrencyState;
  requestPayload: DefineCurrencyRequestPayload;
  validateForm: (values: DefineCurrencyState) => {
    isValid: boolean;
  };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const UpdateCurrenciesButton = ({
  values,
  requestPayload,
  validateForm,
  handleSubmitted,
  isLoading,
}: UpdateCurrenciesButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateDefineCurrency = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      dispatch(updateCurrency(requestPayload));
    }
  };

  const handleClearForm = () => {
    dispatch(resetAfterUpdateDefineCurrency());
  };

  return (
    <div className="d-flex justify-content-end gap-2 mt-4">
      <CustomButton variant="danger" onClick={handleClearForm}>
        Formu Temizle
      </CustomButton>

      <CustomButton
        variant="success"
        onClick={handleUpdateDefineCurrency}
        isLoading={isLoading}
      >
        Güncelle
      </CustomButton>
    </div>
  );
};

export default UpdateCurrenciesButton;
