import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { RoomsLackPictureInitialState } from "slices/reports/roomsLackPicture/reducer";

import { getAllRoomsLackPicture } from "slices/reports/roomsLackPicture/thunk";

const useRoomsLackPicture = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { RoomsLackPicture: RoomsLackPictureInitialState }) =>
      state.RoomsLackPicture
  );

  useEffect(() => {
    dispatch(getAllRoomsLackPicture());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useRoomsLackPicture;
