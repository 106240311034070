import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { handleSelectedHotelsToChange, PersonnelHotelAssignmentsInitialState } from "slices/userOperations/personnelHotelAssignments/reducer";
import { AssignedHotels } from "types/userOperations";

interface Option {
  value: string;
  label: string;
}

const useHandleList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { changeHotelAssigneeFormChoices, selectedHotelsToChange, selectedUsersToChange } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const [hotelOptions, setHotelOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const userId = selectedUsersToChange[0];
    if (!userId || userId === 0) {
      setHotelOptions([]);
      return;
    }

    const user = changeHotelAssigneeFormChoices.personeller.find(
      (user) => user.id === userId
    );

    /* Backend returns duplicate data, I had to remove duplicates by mapping them*/
    if (user && user.bagli_oteller) {
        const uniqueHotelsMap = new Map<number, AssignedHotels>();
        user.bagli_oteller.forEach((hotel) => {
          if (!uniqueHotelsMap.has(hotel.otel_id)) {
            uniqueHotelsMap.set(hotel.otel_id, hotel);
          }
        });
        const uniqueHotels = Array.from(uniqueHotelsMap.values());

        const options = uniqueHotels.map((hotel) => ({
          value: hotel.otel_id.toString(),
          label: hotel.otel_adi,
        }));
        setHotelOptions(options);
      } else {
        setHotelOptions([]);
      }
    }, [changeHotelAssigneeFormChoices.personeller, selectedUsersToChange]);

  useEffect(() => {
    const userId = selectedUsersToChange[0];
    if (!userId || userId === 0) {
      setSelectedOptions([]);
      return;
    }

    const user = changeHotelAssigneeFormChoices.personeller.find(
      (user) => user.id === userId
    );

    if (!user || !user.bagli_oteller) {
      setSelectedOptions([]);
      return;
    }

    const selected = selectedHotelsToChange.map((id) => {
      const hotel = user.bagli_oteller.find((hotel) => hotel.otel_id === id);
      return hotel
        ? { value: hotel.otel_id.toString(), label: hotel.otel_adi }
        : { value: id.toString(), label: "Unknown Hotel" };
    });
    setSelectedOptions(selected);
  }, [
    selectedHotelsToChange,
    changeHotelAssigneeFormChoices.personeller,
    selectedUsersToChange,
  ]);

  const handleSelectOptions = (selected: string[]) => {
    const uniqueSelected = Array.from(new Set(selected)); 
    const hotelIds = uniqueSelected.map((id) => parseInt(id, 10));
    dispatch(handleSelectedHotelsToChange(hotelIds));
  };

  return {
    hotelOptions,
    selectedOptions,
    setSelectedOptions,
    handleSelectOptions,
  };
};

export default useHandleList;
