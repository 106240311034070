import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { ReservationFilterPayload } from "types/reports";

interface Option {
  label: string;
  value: number | string;
}

interface DateTypeSelectorProps {
  options: { value: string; label: string }[];
  values: ReservationFilterPayload;
  handleFormChange: (key: string, value: string) => void;
  fieldKey: keyof ReservationFilterPayload;
}

const DateTypeSelector = ({ 
    options, 
    values,
    handleFormChange,
    fieldKey
}: DateTypeSelectorProps) => {
  return (
    <>
      <CustomFormFieldContainer label="Tarih Tipi">
        <Select
          options={options}
          value={options.find(
            (option) => option.value === values[fieldKey]
          )}
          onChange={(selectedOption: Option) => {
            handleFormChange(fieldKey, String(selectedOption.value));
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateTypeSelector;
