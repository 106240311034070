import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const CustomGlobalFilter = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <form>
      <Row>
        <Col sm={5} lg={3}>
          <div className="search-box me-2 mb-2 d-inline-block col-12">
            <input
              {...props}
              value={value}
              id="search-bar-0"
              className="form-control search"
              onChange={(e) => setValue(e.target.value)}
              style={{
                width: "300px",
              }}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default CustomGlobalFilter;
