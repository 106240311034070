interface PriceCalculationStatusMessagesProps {
  messages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
}

const PriceCalculationStatusMessages = ({
  messages,
}: PriceCalculationStatusMessagesProps) => {
  return (
    <div className="d-flex flex-column gap-2">
      {messages.map((message, index) => {
        const showMessages = message.status;

        return showMessages ? (
          <div key={index} className="d-flex flex-column gap-1">
            <span className="fw-medium theme-text-danger fs-14">
              {message.message}
            </span>
            <span className="text-muted fs-14">{message.description}</span>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default PriceCalculationStatusMessages;
