import { createSlice } from "@reduxjs/toolkit";

import {
  MailOrder,
  MailOrderState,
  MailOrderFormErrors,
} from "types/financeAndAccounting";

export interface MailOrderInfoInitialState {
  mailOrders: MailOrder[];
  banks: {
    [key: string]: string;
  };
  mailOrder: MailOrderState;
  formErrors: MailOrderFormErrors;
  isLoading: boolean;
  error: string;
  mailOrderLoading: boolean;
  mailOrderError: string;
}

const initialState: MailOrderInfoInitialState = {
  mailOrders: [],
  banks: {},
  mailOrder: {
    id: 0,
    kart_sahibi: "",
    kart_kodu: "",
    adi: "",
    kart_numarasi: "",
    kart_son_kullanim: "",
    kart_cvc: "",
    durum: "0",
    otel_odeme: "0",
  },
  formErrors: {},
  isLoading: false,
  error: "",
  mailOrderLoading: false,
  mailOrderError: "",
};

const mailOrderInfoSlice = createSlice({
  name: "mailOrderInfo",
  initialState,
  reducers: {
    handleSaveMailOrders(state, action) {
      const {
        mail_order_listesi,
        sabit_data: { adi_ve_kart_kodu },
      } = action.payload;
      state.mailOrders = mail_order_listesi;
      state.banks = adi_ve_kart_kodu;
    },
    handleSaveMailOrderStateForEdit(state, action) {
      state.mailOrder = action.payload;
    },
    handleSetMailOrderFormErrors(state, action) {
      state.formErrors = action.payload;
    },
    handleChangeMailOrderForm(state, action) {
      state.mailOrder = { ...state.mailOrder, ...action.payload };
    },
    resetMailOrderForm(state) {
      state.mailOrder = {
        id: 0,
        kart_sahibi: "",
        kart_kodu: "",
        adi: "",
        kart_numarasi: "",
        kart_son_kullanim: "",
        kart_cvc: "",
        durum: "0",
        otel_odeme: "0",
      };
      state.formErrors = {};
    },
    setMailOrderLoading(state, action) {
      state.mailOrderLoading = action.payload;
    },
    setMailOrderError(state, action) {
      state.mailOrderError = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveMailOrders,
  handleSaveMailOrderStateForEdit,
  handleSetMailOrderFormErrors,
  handleChangeMailOrderForm,
  resetMailOrderForm,
  setMailOrderLoading,
  setMailOrderError,
  setIsLoading,
  setError,
} = mailOrderInfoSlice.actions;

export default mailOrderInfoSlice.reducer;
