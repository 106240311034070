import { RiskyHotelsPage } from "Components/Custom/ReportsPages";
import useRiskyHotels from "Components/Hooks/reports/useRiskyHotels";
import PageContainer from "pages/PageContainer";

const RiskyHotels = () => {
  const { isLoading } = useRiskyHotels();

  return (
    <PageContainer title="Riskli Oteller">
      <RiskyHotelsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default RiskyHotels;
