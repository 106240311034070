import { CustomModal } from "Components/Custom/UI";
import { FACILITY_MANAGER_ROLE } from "enums";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { FacilityCallInitialState } from "slices/facility/facilityCalls/reducer";
import { FacilityManager } from "types/facility";

interface ShowFacilityManagersModalProps {
  isOpen: boolean;
  handleToggle: () => void;
  hotelId: number;
}

const ShowFacilityManagersModal = ({
  isOpen,
  handleToggle,
}: ShowFacilityManagersModalProps) => {
  const { facilityManagers, facilityManagersLoading: isLoading } = useSelector(
    (state: { FacilityCalls: FacilityCallInitialState }) => state.FacilityCalls
  );

  return (
    <CustomModal
      isOpen={isOpen}
      handleToggle={handleToggle}
      title="Tesis Yöneticileri"
    >
      <div className="d-flex flex-column gap-2 p-2">
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center py-4">
            <Spinner color="primary" />
          </div>
        ) : (
          <table className="table table-bordered my-2">
            <thead>
              <tr>
                <th>Yetkili Adı</th>
                <th>Telefon</th>
                <th>Birim</th>
              </tr>
            </thead>
            {facilityManagers?.map((facilityManager: FacilityManager) => (
              <tbody>
                <tr>
                  <td>{facilityManager.yetkili_adi}</td>
                  <td>{facilityManager.telefon}</td>
                  <td>{FACILITY_MANAGER_ROLE[facilityManager.birim]}</td>
                </tr>
              </tbody>
            ))}
          </table>
        )}
      </div>
    </CustomModal>
  );
};

export default ShowFacilityManagersModal;
