import { handleChangeInput } from "slices/facility/addFacility/reducer";
import { AddFacilityFormErrors, FacilityState } from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FACILITY_FEATURES_CHECKBOX_DESCRIPTION } from "utils/fieldDescriptions";
import { Input, Label } from "reactstrap";

interface FacilityFeaturesAndChildrenOptionsProps {
  values: FacilityState;
  formErrors: AddFacilityFormErrors;
  validateFormChange: (values: FacilityState) => void;
  facilityFeaturesOptions: Option[];
  facilityChildrenOptions: Option[];
}

interface Option {
  value: string;
  label: string;
}

const FacilityFeaturesAndChildrenOptions = ({
  values,
  formErrors,
  validateFormChange,
  facilityFeaturesOptions,
  facilityChildrenOptions,
}: FacilityFeaturesAndChildrenOptionsProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Özellikleri"
        error={formErrors.otel_ozellikleri}
        description={FACILITY_FEATURES_CHECKBOX_DESCRIPTION}
        divider
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityFeaturesOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.otel_ozellikleri.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.otel_ozellikleri, parseInt(value)]
                    : values.otel_ozellikleri.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      otel_ozellikleri: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    otel_ozellikleri: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Çocuklara Özel"
        error={formErrors.cocuk_ozellikleri}
        description={FACILITY_FEATURES_CHECKBOX_DESCRIPTION}
        required
        orientation="vertical"
      >
        <div
          className="bg-white rounded border p-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {facilityChildrenOptions.map((option) => (
            <div key={option.value} className="d-flex gap-2">
              <Input
                type="checkbox"
                id={option.value}
                checked={values.cocuk_ozellikleri.includes(
                  parseInt(option.value)
                )}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const value = option.value;

                  const newValues = checked
                    ? [...values.cocuk_ozellikleri, parseInt(value)]
                    : values.cocuk_ozellikleri.filter(
                        (item) => item !== parseInt(value)
                      );

                  dispatch(
                    handleChangeInput({
                      ...values,
                      cocuk_ozellikleri: newValues,
                    })
                  );
                  validateFormChange({
                    ...values,
                    cocuk_ozellikleri: newValues,
                  });
                }}
              />
              <Label for={option.value} className="fw-normal fs-14">
                {option.label}
              </Label>
            </div>
          ))}
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default FacilityFeaturesAndChildrenOptions;
