import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { handleChangeFacilityPullImageFormUrlInput } from "slices/facility/facilityImages/reducer";
import {
  FacilityPullImageFormUrlErrors,
  FacilityPullImageFormUrlState,
} from "types/facility";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface UrlFieldProps {
  values: FacilityPullImageFormUrlState;
  formErrors: FacilityPullImageFormUrlErrors;
  validateFormChange: (values: FacilityPullImageFormUrlState) => void;
}

const UrlField = ({
  values,
  validateFormChange,
  formErrors,
}: UrlFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Resim Çekilecek Link"
        error={formErrors.url}
        required
      >
        <Input
          type="text"
          value={values.url}
          onChange={(e) => {
            dispatch(
              handleChangeFacilityPullImageFormUrlInput({
                url: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              url: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UrlField;
