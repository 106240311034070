import { CustomLoadingPage } from "Components/Custom/UI";
import AddGroupForm from "./AddGroupForm";

interface AddGroupPageProps {
  isLoading: boolean;
}

const AddGroupPage = ({ isLoading }: AddGroupPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <AddGroupForm />
      </div>
    </>
  );
};

export default AddGroupPage;
