import { AddFacilityPage } from "Components/Custom/FacilityPages";
import useAddFacility from "Components/Hooks/facility/useAddFacility";
import PageContainer from "pages/PageContainer";

const AddFacility = () => {
  const { isLoading } = useAddFacility();

  return (
    <PageContainer title="Tesis Ekle">
      <AddFacilityPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default AddFacility;
