import { MobileDiscountsPage } from "Components/Custom/DiscountsAndActionsPages";
import useMobileDiscounts from "Components/Hooks/discountsAndActions/useMobileDiscounts";
import PageContainer from "pages/PageContainer";

const MobileDiscounts = () => {
  const { error, loading, isLoading, isValidHotel, ...props } =
    useMobileDiscounts();

  return (
    <PageContainer title="Mobil İndirimler">
      <MobileDiscountsPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        isLoading={isLoading}
        error={error}
        {...props}
      />
    </PageContainer>
  );
};

export default MobileDiscounts;
