import { PAYMENT_METHODS } from "enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MobileDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { MobileDiscountsInitialState } from "slices/discountsAndActions/mobileDiscounts/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    mobileDiscount,
    mobileDiscountFormErrors,
    addDiscountLoading,
    updateDiscountLoading,
  } = useSelector(
    (state: { MobileDiscounts: MobileDiscountsInitialState }) =>
      state.MobileDiscounts
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<Option[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<Option[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newRoomsOptions: Option[] = hotel.odalar.map((room: any) => ({
        value: room.id,
        label: room.oda_isim,
      }));

      const newConceptsOptions: Option[] = hotel.pansiyonlar.map(
        (concept: any) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setRoomsOptions(newRoomsOptions);
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const startReservationDate = moment(
    mobileDiscount.rez_tarihi_baslangic
  ).format("YYYY-MM-DD");
  const endReservationDate = moment(mobileDiscount.rez_tarihi_bitis).format(
    "YYYY-MM-DD"
  );
  const startAccommodationDate = moment(
    mobileDiscount.konaklama_tarihi_baslangic
  ).format("YYYY-MM-DD");
  const endAccommodationDate = moment(
    mobileDiscount.konaklama_tarihi_bitis
  ).format("YYYY-MM-DD");

  const addNewMobileDiscountRequestPayload: MobileDiscountRequestPayload = {
    otel_id,
    otel_oda_id: oda_id,
    grup_id: mobileDiscount.grup_id,
    rez_tarihi: `${startReservationDate} - ${endReservationDate}`,
    konaklama_tarihi: `${startAccommodationDate} - ${endAccommodationDate}`,
    min_gece_sayisi: mobileDiscount.min_gece_sayisi,
    ozel_oran: mobileDiscount.ozel_oran,
    indirim_orani: mobileDiscount.ozel_oran,
    giris_gunu_durum: "0",
    giris_gunu_tarihi: "",
    radio_gun: mobileDiscount.radio_gun,
    gun: mobileDiscount.gun,
    maliyete_uygula: mobileDiscount.maliyete_uygula,
    eb_birlikte: mobileDiscount.eb_birlikte,
    kalode_birlikte: mobileDiscount.kalode_birlikte,
    diger_birlikte: mobileDiscount.diger_birlikte,
    yalniz_bu_otel: mobileDiscount.yalniz_bu_otel,
    secili_oteller: mobileDiscount.secili_oteller,
    tum_pansiyonlar: mobileDiscount.tum_pansiyonlar,
    secili_pansiyonlar: mobileDiscount.secili_pansiyonlar,
    tum_odeme_tipleri: mobileDiscount.tum_odeme_tipleri,
    secili_odeme_tipleri: mobileDiscount.secili_odeme_tipleri,
  };

  const updateMobileDiscountRequestPayload: MobileDiscountRequestPayload = {
    mobile_indirim_id: mobileDiscount.id,
    otel_id,
    otel_oda_id: oda_id,
    grup_id: mobileDiscount.grup_id,
    rez_tarihi: `${startReservationDate} - ${endReservationDate}`,
    konaklama_tarihi: `${startAccommodationDate} - ${endAccommodationDate}`,
    min_gece_sayisi: mobileDiscount.min_gece_sayisi,
    ozel_oran: mobileDiscount.ozel_oran,
    indirim_orani: mobileDiscount.ozel_oran,
    giris_gunu_durum: "0",
    giris_gunu_tarihi: "",
    radio_gun: mobileDiscount.radio_gun,
    gun: mobileDiscount.gun,
    maliyete_uygula: mobileDiscount.maliyete_uygula,
    eb_birlikte: mobileDiscount.eb_birlikte,
    kalode_birlikte: mobileDiscount.kalode_birlikte,
    diger_birlikte: mobileDiscount.diger_birlikte,
    yalniz_bu_otel: mobileDiscount.yalniz_bu_otel,
    secili_oteller: mobileDiscount.secili_oteller,
    tum_pansiyonlar: mobileDiscount.tum_pansiyonlar,
    secili_pansiyonlar: mobileDiscount.secili_pansiyonlar,
    tum_odeme_tipleri: mobileDiscount.tum_odeme_tipleri,
    secili_odeme_tipleri: mobileDiscount.secili_odeme_tipleri,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    mobileDiscount,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    addDiscountLoading,
    updateDiscountLoading,
    addNewMobileDiscountRequestPayload,
    updateMobileDiscountRequestPayload,
    formErrors: mobileDiscountFormErrors,
    validateForm,
    handleSubmitted,
    validateFormChange,
  };
};

export default useHandleForm;
