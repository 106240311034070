import {
  ChartContainer,
  CustomPieChart,
  CustomSimpleBarChart,
  PieChartContainer,
} from "Components/Custom/UI/CustomCharts";
import useHandleChart from "./useHandleChart";

interface ForecastReportChartsProps {
  isLoading: boolean;
}

const ForecastReportCharts = ({ isLoading }: ForecastReportChartsProps) => {
  const { formattedData, colors, series, labels, seriesColor } =
    useHandleChart();

  return (
    <div className="d-flex flex-column gap-4">
      <PieChartContainer
        noData={formattedData.length === 0}
        containerWidth="500px"
        containerHeight={300}
        isLoading={isLoading}
      >
        <CustomPieChart
          chartId="forecast-report-chart"
          title="Forecast Raporu"
          series={series}
          labels={labels}
          chartHeight={300}
          chartType="pie"
          legendPosition="right"
          colors={seriesColor}
        />
      </PieChartContainer>
      <ChartContainer
        noData={formattedData.length === 0}
        containerHeight={300}
        containerWidth="100%"
        isLoading={isLoading}
      >
        <CustomSimpleBarChart data={formattedData} colors={colors} />
      </ChartContainer>
    </div>
  );
};

export default ForecastReportCharts;
