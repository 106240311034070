import {
  getConceptOptions,
  getRoomOptions,
  getSpecialAgeOptions,
} from "helpers/general_helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import {
  handleSaveSelectedConcept,
  handleSaveSelectedRoom,
} from "slices/pricesAndQuota/advancedEntry/reducer";
import { getPeriods } from "slices/pricesAndQuota/advancedEntry/thunk";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
  pansiyon_id: number;
  tarihler: string;
  tum_gunler: number;
  gunler: number[];
  geceleme_gunu: number;
  cocuk_ozel_yas_id: number;
}

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [roomOptions, setRoomOptions] = useState([
    {
      label: "--Seçiniz--",
      value: 0,
    },
  ]);

  const [conceptOptions, setConceptOptions] = useState([
    {
      label: "--Seçiniz--",
      value: 0,
    },
  ]);

  const [childSpecialAgeOptions, setChildSpecialAgeOptions] = useState([
    {
      label: "Tüm Yaşlar",
      value: 0,
    },
  ]);

  const selectedAdvancedEntryState = (state: any) => state.AdvancedEntry;
  const selectAdvancedEntryProperties = createSelector(
    selectedAdvancedEntryState,
    (state) => ({
      selectedRoom: state.selectedRoom,
      selectedConcept: state.selectedConcept,
      selectedDates: state.selectedDates,
      selectedDays: state.selectedDays,
      isAllDaysSelected: state.isAllDaysSelected,
      nights: state.nights,
      childSpeacialAgeId: state.childSpeacialAgeId,
      periods: state.periods,
      isLoading: state.isLoading,
      error: state.error,
      periodsLoading: state.periodsLoading,
      periodsError: state.periodsError,
    })
  );

  const selectedHotelState = (state: any) => state.Hotel;
  const selectHotelProperties = createSelector(selectedHotelState, (state) => ({
    hotel: state.hotel,
  }));

  const {
    selectedRoom,
    selectedConcept,
    selectedDates,
    selectedDays,
    isAllDaysSelected,
    nights,
    childSpeacialAgeId,
    periods,
    isLoading,
    error,
    periodsLoading,
    periodsError,
  } = useSelector(selectAdvancedEntryProperties);

  const { hotel } = useSelector(selectHotelProperties);

  useEffect(() => {
    const isHotelDataAvailable = hotel.otel_id !== 0;

    if (isHotelDataAvailable) {
      setRoomOptions(getRoomOptions(hotel.odalar));
      setConceptOptions(getConceptOptions(hotel.pansiyonlar));
      dispatch(handleSaveSelectedRoom(hotel.odalar[0].id));
      dispatch(handleSaveSelectedConcept(hotel.pansiyonlar[0].id));
    }
  }, [hotel, dispatch]);

  useEffect(() => {
    setChildSpecialAgeOptions(getSpecialAgeOptions(periods.ozel_yas_araligi));
  }, [periods]);

  const handleSave = () => {
    const startDate = moment(selectedDates.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectedDates.endDate).format("YYYY-MM-DD");

    const payload: RequestPayload = {
      otel_id: hotel.otel_id,
      oda_id: selectedRoom,
      pansiyon_id: selectedConcept,
      tarihler: `${startDate} - ${endDate}`,
      tum_gunler: isAllDaysSelected ? 1 : 0,
      gunler: selectedDays,
      geceleme_gunu: nights,
      cocuk_ozel_yas_id: childSpeacialAgeId,
    };

    dispatch(getPeriods(payload));
  };

  const concept = conceptOptions.find(
    (item) => item.value === selectedConcept
  )?.label;

  const roomName = roomOptions.find(
    (item) => item.value === selectedRoom
  )?.label;

  return {
    selectedRoom,
    selectedConcept,
    selectedDates,
    selectedDays,
    isAllDaysSelected,
    nights,
    childSpeacialAgeId,
    roomOptions,
    conceptOptions,
    childSpecialAgeOptions,
    concept,
    handleSave,
    data: periods,
    isLoading,
    error,
    roomName,
    periodsLoading,
    periodsError,
  };
};

export default useHandleForm;
