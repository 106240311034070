import { CustomButton } from "Components/Custom/UI";
import ConceptButtonContainer from "../ConceptButtonContainer";
import KismiPaymentModal from "../KismiPaymentModal";
import ButtonTooltip from "../ButtonTooltip";

interface NormalPayButtonProps {
  oda_id: string;
  oda_adi: string;
  pansiyon_id: string;
  pansiyon_adi: string;
  stop: 0 | 1 | 2 | 3 | null;
  stopMessage: string;
  payNowFiyatDurum: number;
  payNowPrice: number;
  payNowPriceButtonText: string;
  kismiOdeme: number;
  kismiOdemePrice: number;
  kismiOdemeTarihi: string;
  kismiOdemeButtonText: string;
  onClick: (data: {
    oda_id: string;
    oda_adi: string;
    pansiyon_id: string;
    pansiyon_adi: string;
    odeme_tipi: string;
    fiyat: number;
  }) => void;
  modal: boolean;
  toggle: () => void;
  showPayNowButton: boolean;
  iptal_sigortasi_durum: "1" | "0";
  cancellationDate: string;
}

const NormalPayButton = ({
  oda_id,
  oda_adi,
  pansiyon_id,
  pansiyon_adi,
  stop,
  stopMessage,
  payNowFiyatDurum,
  payNowPrice,
  payNowPriceButtonText,
  kismiOdeme,
  kismiOdemePrice,
  kismiOdemeTarihi,
  kismiOdemeButtonText,
  onClick,
  modal,
  toggle,
  showPayNowButton,
  iptal_sigortasi_durum,
  cancellationDate,
}: NormalPayButtonProps) => {
  return (
    <div className="d-flex flex-column gap-2">
      <ConceptButtonContainer
        tooltipId={"payNow-" + pansiyon_id}
        showButton={showPayNowButton}
        text={
          stop === 1 && iptal_sigortasi_durum === "1"
            ? `<span class='fw-medium'>Son Odalar</span><br><span class=' theme-text-success'>${cancellationDate} tarihine kadar ücretsiz iptal hakkı vardır.</span>`
            : stop === 0 && iptal_sigortasi_durum === "1"
            ? `<span class="theme-text-success">
      ${cancellationDate} tarihine kadar <span class='fw-medium'> ücretsiz iptal hakkı</span> vardır.
    </span>`
            : stop === 1
            ? "<span class='fw-medium'>Son Odalar</span>"
            : ""
        }
        stop={stop}
        stopMessage={stopMessage}
      >
        <CustomButton
          classes="w-100"
          variant={
            stop === 1 ? "warning" : payNowFiyatDurum ? "primary" : "warning"
          }
          disabled={payNowFiyatDurum === 0}
          onClick={() =>
            onClick({
              oda_id,
              oda_adi,
              pansiyon_id,
              pansiyon_adi,
              odeme_tipi: "normal",
              fiyat: payNowPrice,
            })
          }
        >
          {payNowPriceButtonText}
        </CustomButton>
      </ConceptButtonContainer>
      <div id={"kismi" + pansiyon_id}>
        <ConceptButtonContainer
          tooltipId={"kismi-" + pansiyon_id}
          showButton={kismiOdeme === 1}
          text={
            stop === 1
              ? `<span class='fw-medium'>Son Odalar</span><br><span>Kalan tutar <strong> ${kismiOdemeTarihi} tarihinde tahsil edilecektir.</span>`
              : `<span>Kalan tutar <strong>${kismiOdemeTarihi}</strong> tarihinde tahsil edilecektir.</span>`
          }
          stop={stop}
          stopMessage={stopMessage}
        >
          <CustomButton
            classes="w-100"
            variant={stop === 1 ? "warning" : kismiOdeme ? "orange" : "warning"}
            disabled={kismiOdeme === 0}
            onClick={toggle}
          >
            {kismiOdemeButtonText}
          </CustomButton>
          <KismiPaymentModal
            paymentDate={kismiOdemeTarihi}
            modal={modal}
            toggle={toggle}
            onApprove={() => {
              onClick({
                oda_id,
                oda_adi,
                pansiyon_id,
                pansiyon_adi,
                odeme_tipi: "kismi",
                fiyat: payNowPrice,
              });
            }}
          />
        </ConceptButtonContainer>
      </div>
      {kismiOdeme === 1 && (
        <ButtonTooltip
          target={"kismi" + pansiyon_id}
          title="Misafiri Bilgilendiriniz!"
          description={`<span>1. Kredi kartınızdan <strong>${kismiOdemePrice} TL</strong> Ön Ödeme tutarı şimdi tahsil edilip kalan tutarın ${kismiOdemeTarihi} tarihinde tahsil edilmesi gerekmektedir.</span>`}
        />
      )}
    </div>
  );
};

export default NormalPayButton;
