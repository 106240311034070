import DiscountsPage from "Components/Custom/DiscountsAndActionsPages/DiscountsPage";
import useDiscounts from "Components/Hooks/discountsAndActions/useDiscounts";
import PageContainer from "pages/PageContainer";

const Discounts = () => {
  const { error, loading, isValidHotel } = useDiscounts();
  return (
    <PageContainer title="İndirimler">
      <DiscountsPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
      />
    </PageContainer>
  );
};

export default Discounts;
