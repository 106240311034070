import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";
import {
  getAdsAndHotels,
  getMatchedHotels,
  getUnmatchedAdsGroups,
  getUnmatchedHotels,
} from "slices/adsAndAnalyze/adsGroups/thunk";

const useAdsGroups = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    isLoading,
    matchedHotelsLoading,
    unmatchedHotelsLoading,
    unmatchedAdsGroupsLoading,
    matchAdHotelLoading,
  } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 10,
      search: "",
    };
    dispatch(getAdsAndHotels());
    dispatch(getMatchedHotels(payload));
    dispatch(getUnmatchedHotels(payload));
    dispatch(getUnmatchedAdsGroups(payload));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    matchedHotelsLoading,
    unmatchedHotelsLoading,
    unmatchedAdsGroupsLoading,
    matchAdHotelLoading,
  };
};

export default useAdsGroups;
