import CustomNumberSelector from "Components/Custom/UI/CustomNumberSelector";
import { CompetitorAnalysisInfoFormState } from "types/adsAndAnalyze";

interface GuestNumbersProps {
  formValues: CompetitorAnalysisInfoFormState;
  handleChangeForm: (key: string, value: number | string) => void;
}

const GuestNumbers = ({ formValues, handleChangeForm }: GuestNumbersProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-2">
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <p className="fw-medium m-0">Yetişkin Sayısı</p>
          <CustomNumberSelector
            min={1}
            value={formValues.yetiskin_sayisi}
            onChange={(value) => handleChangeForm("yetiskin_sayisi", value)}
            disabled={true}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <p className="fw-medium m-0">Çocuk Sayısı</p>
          <CustomNumberSelector
            value={formValues.cocuk_sayisi}
            max={4}
            onChange={(value) => handleChangeForm("cocuk_sayisi", value)}
            disabled={true}
          />
        </div>
      </div>
    </>
  );
};

export default GuestNumbers;
