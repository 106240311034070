import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
    handleSaveFilterOptionsResponse,
    handleSaveLogResponse, 
    setIsLoading, 
    setError,
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import { GET_LOGS, FILTER_LOGS} from "helpers/url_helper";
import { Dispatch } from "redux";
import { FilterLogsPayload } from "types/userOperations";


export const getLogFilterChoices =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_LOGS);
      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveFilterOptionsResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const getFilteredLogs =
  (payload: FilterLogsPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_LOGS, payload);

      dispatch(handleSaveLogResponse(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
