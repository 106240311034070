import LocationSelectors from "./LocationSelectors";
import LocationName from "./LocationName";
import useHandleForm from "./useHandleForm";
import { CustomFormContainer } from "Components/Custom/UI";
import AddNewLocationButton from "./AddNewLocationButton";

interface AddLocationFormProps {
  isLoading: boolean;
}

const AddLocationForm = ({ isLoading }: AddLocationFormProps) => {
  const {
    location,
    addNewLocationRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer>
      <LocationSelectors
        values={location}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <LocationName
        values={location}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddNewLocationButton
        values={location}
        requestPayload={addNewLocationRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default AddLocationForm;
