import ShowInstallmentAmounts from "Components/Custom/ReservationPages/CommonComponents/ShowInstallmentAmounts";
import { useState } from "react";
import { Modal } from "reactstrap";
import { BankInstallment } from "types/reservation";

interface ConceptPriceProps {
  price: number;
  overlinedPrice: number;
  showPrice: boolean;
  normal?: {
    status: boolean;
    kismiOdeme: 0 | 1;
    kismiOdemePrice: number;
    iptal_sigortasi_durum: "1" | "0";
    iptalSigortasiFiyat: number;
  };
  kaporali?: {
    status: boolean;
    kaporaAmount: number;
  };
  banka_taksit: BankInstallment[];
}

const ConceptPrice = ({
  price,
  overlinedPrice,
  showPrice,
  normal,
  kaporali,
  banka_taksit,
}: ConceptPriceProps) => {
  const [modal, setModal] = useState(0);

  const showPriceInstallments = (price: number) => setModal(price);
  const hidePriceInstallments = (prop: any) => {
    console.log("prop", prop);
    setModal(0);
  };

  return (
    <>
      <Modal isOpen={!!modal} toggle={hidePriceInstallments}>
        <div className="border shadow p-2">
          <ShowInstallmentAmounts
            basePrice={modal}
            installments={banka_taksit ?? []}
          />
        </div>
      </Modal>
      {showPrice && (
        <div className="d-flex flex-column gap-2">
          <div
            className="d-flex flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => showPriceInstallments(price)}
          >
            <span className="fs-16 text-muted text-decoration-line-through">
              {Math.ceil(overlinedPrice)} TL
            </span>
            <span className="fs-20 fw-bold">{Math.ceil(price)} TL</span>
          </div>
          {normal?.status && (
            <div className="d-flex flex-column">
              {normal.iptal_sigortasi_durum === "1" && (
                <span className="text-center theme-text-info fs-14 px-6 py-2">
                  <i className="ri-check-double-line me-1 fw-medium theme-text-info"></i>
                  <span className="fw-medium me-1">
                    {normal.iptalSigortasiFiyat} TL
                  </span>
                  İptal Güvence Paketi
                </span>
              )}
              {normal.kismiOdeme ? (
                <span
                  className="text-center theme-text-orange fs-14 px-6 py-2 cursor-pointer"
                  onClick={() => showPriceInstallments(normal.kismiOdemePrice)}
                >
                  <i className="ri-shield-check-fill me-1 fw-medium theme-text-orange"></i>
                  <span className="fw-medium me-1">
                    {normal.kismiOdemePrice} TL
                  </span>
                  Ön Ödeme ile <br /> Rezervasyonu Garantile
                </span>
              ) : (
                <></>
              )}
            </div>
          )}
          {kaporali?.status && (
            <span
              className="text-center theme-text-success fs-14 px-6 py-2 cursor-pointer"
              onClick={() => showPriceInstallments(kaporali.kaporaAmount)}
            >
              <i className="ri-shield-check-fill me-1 fw-medium theme-text-success"></i>
              <span className="fw-medium me-1">{kaporali.kaporaAmount} TL</span>
              Ön Ödeme ile <br /> Rezervasyonu Garantile
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default ConceptPrice;
