import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import * as Yup from "yup";

import { loginUser } from "slices/thunks";

import { Router } from "types";

interface FormValues {
  username: string;
  password: string;
}

const useHandleForm = (props: Router) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    error: state.Login.error,
    errorMsg: state.Login.errorMsg,
  }));
  // Inside your component
  const { error } = useSelector(loginpageData);

  const [passwordShow, setPasswordShow] = useState(false);

  const [loader, setLoader] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Kullanıcı Adı Gerekli"),
      password: Yup.string().required("Parola Gerekli"),
    }),
    onSubmit: (values: FormValues) => {
      dispatch(loginUser(values, props.router.navigate));
      setLoader(true);
    },
  });

  useEffect(() => {
    if (error) {
      setLoader(false);
    }
  }, [error]);

  return { validation, loader, passwordShow, setPasswordShow, error };
};

export default useHandleForm;
