import { AddEarlyReservationDefinitionPage } from "Components/Custom/DefinitionsPages";
import useAddEarlyReservationDefinition from "Components/Hooks/definitions/useAddEarlyReservationDefinition";
import PageContainer from "pages/PageContainer";

const AddEarlyReservationDefinition = () => {
  const { isLoading } = useAddEarlyReservationDefinition();

  return (
    <PageContainer title="Erken Rezervasyon Tarihi Tanımlama">
      <AddEarlyReservationDefinitionPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default AddEarlyReservationDefinition;
