import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../CustomButton";

interface CustomModalProps {
  isOpen: boolean;
  handleToggle: () => void;
  title?: string;
  message?: string;
  approveButtonLabel?: string;
  approveCallback?: () => void;
  cancelButtonLabel?: string;
  cancelCallback?: () => void;
  size?: "sm" | "md" | "lg" | "xl";
  children?: React.ReactNode;
  preventClose?: boolean;
}

const CustomModal = ({
  isOpen,
  handleToggle,
  title,
  message,
  approveButtonLabel,
  approveCallback = () => {},
  cancelButtonLabel,
  cancelCallback = () => {},
  size = "md",
  children,
  preventClose,
}: CustomModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      size={size}
      backdrop={preventClose ? "static" : true}
      backdropClassName="bg-dark bg-opacity-25"
    >
      {title && (
        <ModalHeader className="border-0" toggle={handleToggle}>
          {title}
        </ModalHeader>
      )}
      {children ? (
        children
      ) : (
        <>
          {message && <ModalBody>{message}</ModalBody>}
          <ModalFooter className="border-0">
            <div className="d-flex gap-2 w-50">
              {cancelButtonLabel && (
                <CustomButton
                  variant="danger"
                  classes="flex-grow-1"
                  onClick={cancelCallback}
                >
                  {cancelButtonLabel}
                </CustomButton>
              )}
              {approveButtonLabel && (
                <CustomButton
                  variant="success"
                  classes="flex-grow-1"
                  onClick={approveCallback}
                >
                  {approveButtonLabel}
                </CustomButton>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default CustomModal;
