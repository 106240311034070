import { CustomButton } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import CitySelector from "./CitySelector";
import PersonnelSelector from "./PersonnelSelector";
import { Dispatch, SetStateAction } from "react";

type ShowTableState = "show_data" | "no_data" | "default";
interface CityAndPersonnelFilterFormProps{
  setShowTableData: Dispatch<SetStateAction<ShowTableState>>
}

const CityAndPersonnelFilterForm = ({setShowTableData} : CityAndPersonnelFilterFormProps) => {
  const {
    filterForm,
    cityOptions,
    personnelOptions,
    handleFormChange,
    handleGetFilteredList,
    handleClearFilter,
  } = useHandleForm(setShowTableData);

  return (
    <>
      <CitySelector
        options={cityOptions}
        values={filterForm}
        handleFormChange={handleFormChange}
      />
      <PersonnelSelector
        options={personnelOptions}
        values={filterForm}
        handleFormChange={handleFormChange}
      />
      <div className="d-flex justify-content-end gap-4 mt-4">
        <CustomButton
          variant="primary"
          disabled={false}
          onClick={handleGetFilteredList}
        >
          Listeyi Getir
        </CustomButton>
        <CustomButton
          variant="danger"
          disabled={false}
          onClick={handleClearFilter}
        >
          Temizle
        </CustomButton>
      </div>
    </>
  );
};

export default CityAndPersonnelFilterForm;
