import CustomColumnFilter from "./CustomColumnFilter";

interface CustomTableHeaderProps {
  getHeaderGroups: any;
  theadClass: string;
  trClass: string;
  thClass: string;
  flexRender: any;
  table: any;
}

const CustomTableHeader = ({
  getHeaderGroups,
  theadClass,
  trClass,
  thClass,
  flexRender,
  table,
}: CustomTableHeaderProps) => {
  return (
    <thead className={theadClass}>
      {getHeaderGroups().map((headerGroup: any, index: number) => (
        <tr className={trClass} key={index}>
          {headerGroup.headers.map((header: any, index: number) => (
            <th
              key={index}
              className={thClass}
              {...{
                onClick: header.column.getToggleSortingHandler(),
              }}
            >
              {header.isPlaceholder ? null : (
                <>
                  <span className="d-flex align-items-end flex-nowrap gap-1">
                    {flexRender(header.column.columnDef.header)}
                    <span>{" " + String.fromCharCode(0x021c5)}</span>
                  </span>
                  {{
                    asc: " ",
                    desc: " ",
                  }[header.column.getIsSorted() as string] ?? null}
                  {header.column.getCanFilter() ? (
                    <div>
                      <CustomColumnFilter
                        column={header.column}
                        table={table}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default CustomTableHeader;
