import { Spinner, Table } from "reactstrap";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import CustomColumnFilter from "Components/Custom/UI/CustomReactTable/CustomTableContainer/CustomColumnFilter";


interface CustomTableHeaderProps {
  getHeaderGroups: any;
  theadClass: string;
  trClass: string;
  thClass: string;
  flexRender: any;
  table: any;
}

const CustomTableHeader = ({
  getHeaderGroups,
  theadClass,
  trClass,
  thClass,
  flexRender,
  table,
}: CustomTableHeaderProps) => {
  return (
    <thead className={theadClass}>
      {getHeaderGroups().map((headerGroup: any, index: number) => (
        <tr className={trClass} key={index}>
          {headerGroup.headers.map((header: any, index: number) => (
            <th
              key={index}
              className={thClass}
              {...{
                onClick: header.column.getToggleSortingHandler(),
              }}
            >
              {header.isPlaceholder ? null : (
                <>
                  <span className="d-flex align-items-end flex-nowrap gap-1">
                    {flexRender(header.column.columnDef.header)}
                    <span>{" " + String.fromCharCode(0x021c5)}</span>
                  </span>
                  {{
                    asc: " ",
                    desc: " ",
                  }[header.column.getIsSorted() as string] ?? null}
                  {header.column.getCanFilter() ? (
                    <div>
                      <CustomColumnFilter
                        column={header.column}
                        table={table}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};


interface CustomTableBodyProps {
  loading: boolean;
  columns: any;
  getRowModel: any;
  flexRender: any;
}

const CustomTableBody = ({
  loading,
  columns,
  getRowModel,
  flexRender,
}: CustomTableBodyProps) => {
  return (
    <>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={columns.length} className="text-center py-2 fs-13">
              <Spinner color="primary" size="sm" />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="text-center fs-13">
                Kayıt bulunamadı.
              </td>
            </tr>
          ) : (
            getRowModel().rows.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  {row.getVisibleCells().map((cell: any, index: number) => {
                    const rowBgColor = cell.row.original.rowColor;
                    return (
                      <td
                        key={index}
                        className="fs-13 text-nowrap border"
                        style={{ backgroundColor: rowBgColor }}
                      >
                        <span>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      )}
    </>
  );
};


interface TableContainerProps {
  columns?: any;
  data?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  loading?: boolean;
}

const CustomSimpleTableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  loading = false,
}: TableContainerProps) => {
 
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
  } = table;

  return (
    <>
      <div className={divClass}>
        <Table hover className={tableClass}>
          <CustomTableHeader
            getHeaderGroups={getHeaderGroups}
            thClass={thClass}
            trClass={trClass}
            theadClass={theadClass}
            flexRender={flexRender}
            table={table}
          />

          <CustomTableBody
            loading={loading}
            columns={columns}
            getRowModel={getRowModel}
            flexRender={flexRender}
          />
        </Table>
      </div>
    </>
  );
};

export default CustomSimpleTableContainer;
