import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import { handleSavePhoneStatus, setIsLoading, setError } from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_ALL_PHONE_STATUS,
  GET_FILTERED_PHONE_STATUS,
} from "helpers/url_helper";

import { Dispatch } from "redux";

import { FilterActivityStatusRequestPayload } from "types/reports";

export const getAllPhoneStatus = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_ALL_PHONE_STATUS);

    dispatch(handleSavePhoneStatus(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getFilteredPhoneStatus =
  (payload: FilterActivityStatusRequestPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(GET_FILTERED_PHONE_STATUS, payload);

      dispatch(handleSavePhoneStatus(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
