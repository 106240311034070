import { createSlice } from "@reduxjs/toolkit";
import {
  AddGroupFormErrors,
  AddGroupPayload,
  AddGroupResponse,
} from "types/userOperations";

export interface AddGroupInitialState {
  addGroupFormChoices: AddGroupResponse;
  addGroup: AddGroupPayload;
  addGroupFormErrors: AddGroupFormErrors;
  isLoading: boolean;
  error: string;
  addGroupLoading: boolean;
  addGroupError: string;
}

export const addGroupInitialState: AddGroupInitialState = {
  addGroupFormChoices: {
    yetkililer: [],
    uyeler: [],
  },
  addGroup: {
    grup_adi: "",
    yonetici_id: 0,
    grup_uyeleri_id: [],
  },
  addGroupFormErrors: {},
  isLoading: false,
  error: "",
  addGroupLoading: false,
  addGroupError: "",
};

export const addGroupSlice = createSlice({
  name: "addGroup",
  initialState: addGroupInitialState,
  reducers: {
    handleAddGroupFormChoices(state, action) {
      state.addGroupFormChoices = action.payload;
    },
    handleAddGroup(state, action) {
      state.addGroup = action.payload;
    },
    handleChangeInput(state, action) {
      state.addGroup = {
        ...state.addGroup,
        ...action.payload,
      };
    },
    handleAddGroupFormErrors(state, action) {
      state.addGroupFormErrors = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setAddGroupLoading(state, action) {
      state.addGroupLoading = action.payload;
    },
    setAddGroupError(state, action) {
      state.addGroupError = action.payload;
    },
    handleResetUserState(state) {
      state.addGroup = {
        grup_adi: "",
        yonetici_id: 0,
        grup_uyeleri_id: [],
      };
    },
  },
});

export const {
  handleAddGroupFormChoices,
  handleAddGroup,
  handleChangeInput,
  handleAddGroupFormErrors,
  setIsLoading,
  setError,
  setAddGroupLoading,
  setAddGroupError,
  handleResetUserState,
} = addGroupSlice.actions;

export default addGroupSlice.reducer;
