import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveRoomsLackPicture,
  handleSaveFilteredRoomsLackPicture,
  setIsLaoding,
  setError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_ALL_ROOMS_LACK_PICTURE,
  GET_FILTERED_ROOMS_LACK_PICTURE,
} from "helpers/url_helper";

import { Dispatch } from "redux";

export const getAllRoomsLackPicture = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLaoding(true));
    const response = await axios.get(GET_ALL_ROOMS_LACK_PICTURE);

    const res = (response as any).message ? response.data : response;

    dispatch(handleSaveRoomsLackPicture(res));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLaoding(false));
  }
};

export const getFilteredRoomsLackPicture =
  (payload: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLaoding(true));
      const response = await axios.post(
        GET_FILTERED_ROOMS_LACK_PICTURE,
        payload
      );

      dispatch(handleSaveFilteredRoomsLackPicture(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLaoding(false));
    }
  };
