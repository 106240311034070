import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setToastError } from "slices/toast/reducer";
import { handleCreateNotificationFormErrors } from "slices/userOperations/createNotification/reducer";
import { CreateNotificationFormErrors, CreateNotificationPayload } from "types/userOperations";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: CreateNotificationPayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: CreateNotificationFormErrors = {};

    if (!values.baslik) {
      errors.baslik = "Bildirim boş bırakılamaz.";
    }
    if (!values.bildirim) {
      errors.bildirim = "Bildirim alanı boş bırakılamaz.";
    }
    if (!values.kullanici_idler) {
      errors.kullanici_idler = "Kullanıcı seçilmeli.";
    }
    if (!values.tipi) {
      errors.tipi = "Bildirim tipi boş bırakılamaz.";
    }

    dispatch(handleCreateNotificationFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: CreateNotificationPayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
