import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleChangeInput } from "slices/themes/listThemes/reducer";
import { UpdateThemeFormErrors, UpdateThemeState } from "types/themes";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";

interface ThemeTitleAndSubtitleProps {
  values: UpdateThemeState;
  formErrors: UpdateThemeFormErrors;
  validateFormChange: (values: UpdateThemeState) => void;
}

const UpdateThemeTitleAndSubtitle = ({
  values,
  validateFormChange,
  formErrors,
}: ThemeTitleAndSubtitleProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer label="Başlık" error={formErrors.baslik}>
        <Input
          type="text"
          autoCorrect="on"
          value={values.baslik}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                baslik: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              baslik: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Alt Başlık"
        error={formErrors.alt_baslik}
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.alt_baslik}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                ...values,
                alt_baslik: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              alt_baslik: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UpdateThemeTitleAndSubtitle;
