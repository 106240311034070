import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  HotelContract,
  HotelContractState,
  AddHotelContractFormErrors,
  UpdateHotelContractFormErrors,
} from "types/financeAndAccounting";
import { Pagination } from "types/GlobalTypes";

export interface HotelContractsInitialState {
  hotelContracts: HotelContract[];
  hotelContract: HotelContractState;
  addHotelContractFormErrors: AddHotelContractFormErrors;
  updateHotelContractFormErrors: UpdateHotelContractFormErrors;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
  contractLoading: boolean;
  contractError: string;
}

const initialState: HotelContractsInitialState = {
  hotelContracts: [],
  hotelContract: {
    kontrat_id: 0,
    otel_id: 0,
    dosya_adi: "",
    b_tarih: moment().format("YYYY-MM-DD"),
    s_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
    kontrol_durum: "0",
    belge: null,
  },
  addHotelContractFormErrors: {},
  updateHotelContractFormErrors: {},
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
  contractLoading: false,
  contractError: "",
};

const hotelContractsSlice = createSlice({
  name: "hotelContracts",
  initialState,
  reducers: {
    handleSaveHotelContracts(state, action) {
      const { data, ...rest } = action.payload;
      state.hotelContracts = data;
      state.pagination = rest;
    },
    handleSaveHotelContractStateForEdit(state, action) {
      state.hotelContract = action.payload;
    },
    handleAddHotelContractFormErrors(state, action) {
      state.addHotelContractFormErrors = action.payload;
    },
    handleUpdateHotelContractFormErrors(state, action) {
      state.updateHotelContractFormErrors = action.payload;
    },
    handleChangeHotelContractForm(state, action) {
      state.hotelContract = {
        ...state.hotelContract,
        ...action.payload,
      };
    },
    resetHotelContractState(state) {
      state.hotelContract = {
        kontrat_id: 0,
        otel_id: 0,
        dosya_adi: "",
        b_tarih: moment().format("YYYY-MM-DD"),
        s_tarih: moment().add(1, "months").format("YYYY-MM-DD"),
        kontrol_durum: "0",
        belge: null,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setContractLoading(state, action) {
      state.contractLoading = action.payload;
    },
    setContractError(state, action) {
      state.contractError = action.payload;
    },
  },
});

export const {
  handleSaveHotelContracts,
  handleSaveHotelContractStateForEdit,
  handleAddHotelContractFormErrors,
  handleUpdateHotelContractFormErrors,
  handleChangeHotelContractForm,
  resetHotelContractState,
  setIsLoading,
  setError,
  setContractLoading,
  setContractError,
} = hotelContractsSlice.actions;

export default hotelContractsSlice.reducer;
