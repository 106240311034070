import { CustomLoadingPage } from "Components/Custom/UI";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import WidgetForm from "./WidgetForm";

interface CreateWidgetPageProps {
  isLoading: boolean;
}

const CreateWidgetPage = (props: CreateWidgetPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={props.isLoading} />
      <WidgetForm />
    </>
  );
};

export default withPageNeedsHotel(CreateWidgetPage);
