import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface RoomsLackDescriptionTableProps {
  isLoading: boolean;
}

const RoomsLackDescriptionTable = ({
  isLoading,
}: RoomsLackDescriptionTableProps) => {
  const { tableData, tableColumns } = useHandleTable();
  return (
    <CustomReactTable
      fileName="Oda Açıklaması Olmayanlar"
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default RoomsLackDescriptionTable;
