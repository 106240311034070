import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  FacilityManagersInitialState,
  handleDeleteFromFacilityManagersWithId,
  handleSaveFacilityManagerForEdit,
} from "slices/facility/facilityManagers/reducer";
import { deleteFacilityManager } from "slices/facility/facilityManagers/thunk";
import { FacilityManager, FacilityManagerState } from "types/facility";

const roles = {
  ana_yetkili: "Ana Yetkili",
  rezervasyon: "Rezervasyon",
  muhasebe: "Muhasebe",
  bilgi_islem: "Bilgi İşlem",
  otel_genel_iletisim: "Otel Genel İletişim",
} as const;

const useHandleTable = () => {
  const { facilityManagers } = useSelector(
    (state: { FacilityManagers: FacilityManagersInitialState }) =>
      state.FacilityManagers
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleClickUpdate = (id: number) => {
    const selectedRow = facilityManagers.find((row) => row.id === id);

    if (selectedRow) {
      dispatch(handleSaveFacilityManagerForEdit(selectedRow));
      window.scrollTo(0, 0);
    }
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFacilityManager(id));
    dispatch(handleDeleteFromFacilityManagersWithId(id));
  };

  const tableData: FacilityManagerState[] = facilityManagers?.map(
    (manager: FacilityManager) => {
      return {
        id: manager.id,
        unvan: manager.unvan,
        yetkili_adi: manager.yetkili_adi,
        telefon: manager.telefon,
        mail: manager.mail,
        birim: manager.birim,
      };
    }
  );

  const tableColumns = [
    { header: "Ünvanı", accessorKey: "unvan", enableColumnFilter: false },
    {
      header: "Adı",
      accessorKey: "yetkili_adi",
      enableColumnFilter: false,
    },
    {
      header: "Telefon",
      accessorKey: "telefon",
      enableColumnFilter: false,
    },
    { header: "Mail", accessorKey: "mail", enableColumnFilter: false },
    {
      header: "Birim",
      accessorKey: "birim",
      cell: (cell: any) => {
        const role = cell.getValue();

        const roleKey = role as keyof typeof roles;
        const label = roles[roleKey];

        return <span>{label}</span>;
      },
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();

        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomRowOperationButton
              variant="update"
              onClick={handleClickUpdate.bind(null, id)}
            />
            <CustomRowOperationButton
              variant="delete"
              onClick={() => {
                handleDelete(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
