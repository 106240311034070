import { CustomModal } from "Components/Custom/UI";

interface FacilityThemeFeatureMatchModalProps {
  facilityThemeFeatureMatchOptions: {
    key: string;
    value: string[];
  }[];
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const FacilityThemeFeatureMatchModal = ({
  facilityThemeFeatureMatchOptions,
  showModal,
  setShowModal,
}: FacilityThemeFeatureMatchModalProps) => {
  return (
    <CustomModal
      isOpen={showModal}
      handleToggle={() => setShowModal(!showModal)}
    >
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Tema</th>
            <th scope="col">Özellikler</th>
          </tr>
        </thead>
        <tbody>
          {facilityThemeFeatureMatchOptions.map((option) => (
            <tr key={option.key}>
              <td>{option.key}</td>
              <td>{option.value.join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </CustomModal>
  );
};

export default FacilityThemeFeatureMatchModal;
