import { CustomFormContainer } from "Components/Custom/UI";
import LocationHotelsTable from "./LocationHotelsTable";

interface CalculatePriceForLocationPageProps {
  isLoading: boolean;
}

const CalculatePriceForLocationPage = ({
  isLoading,
}: CalculatePriceForLocationPageProps) => {
  return (
    <CustomFormContainer title=" Konuma Göre Otel Listesi">
      <LocationHotelsTable isLoading={isLoading} />
    </CustomFormContainer>
  );
};

export default CalculatePriceForLocationPage;
