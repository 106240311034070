import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddAddEarlyReservationDefinitionFormErrors } from "slices/definitions/addEarlyReservationDefinition/reducer";
import { setToastError } from "slices/toast/reducer";

import {
  AddEarlyReservationDefinitionFormErrors,
  AddEarlyReservationDefinitionState,
} from "types/definitions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddEarlyReservationDefinitionState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddEarlyReservationDefinitionFormErrors = {};

    if (!values.donem) {
      errors.donem = "Dönem boş bırakılamaz.";
    }

    if (!values.tarih) {
      errors.tarih = "Tarih boş bırakılamaz.";
    }
    dispatch(handleAddAddEarlyReservationDefinitionFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddEarlyReservationDefinitionState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
