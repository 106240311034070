import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AdsGroupsInitialState } from "slices/adsAndAnalyze/adsGroups/reducer";
import {
  getAdsAndHotels,
  getMatchedHotels,
  getUnmatchedHotels,
  getUnmatchedAdsGroups,
  addAdAndHotelMatch,
} from "slices/adsAndAnalyze/adsGroups/thunk";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = () => {
  const { adsAndHotels } = useSelector(
    (state: { AdsGroups: AdsGroupsInitialState }) => state.AdsGroups
  );

  const [adsOptions, setAdsOptions] = useState<Option[]>([]);
  const [hotelOptions, setHotelOptions] = useState<Option[]>([]);

  useEffect(() => {
    const adsOptions = adsAndHotels.reklam.map((ad) => ({
      value: ad.groupId,
      label: ad.name,
    }));

    const hotelOptions = adsAndHotels.oteller.map((hotel) => ({
      value: hotel.otel_id,
      label: hotel.otel_adi,
    }));

    setAdsOptions(adsOptions);
    setHotelOptions(hotelOptions);
  }, [adsAndHotels]);

  const [formValues, setFormValues] = useState({
    otel_id: 0,
    reklam_grup_id: 0,
  });

  const handleFormChange = (key: string, value: number) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleAddAdAndHotelMatch = () => {
    const payload = {
      otel_id: formValues.otel_id,
      reklam_grup_id: formValues.reklam_grup_id,
    };

    const successCallback = () => {
      setFormValues({
        otel_id: 0,
        reklam_grup_id: 0,
      });
      dispatch(getAdsAndHotels());
      dispatch(getMatchedHotels({ page: 1, per_page: 10, search: "" }));
      dispatch(getUnmatchedHotels({ page: 1, per_page: 10, search: "" }));
      dispatch(getUnmatchedAdsGroups({ page: 1, per_page: 10, search: "" }));
    };

    dispatch(addAdAndHotelMatch(payload, successCallback));
  };

  return {
    adsOptions,
    hotelOptions,
    formValues,
    handleFormChange,
    onAddAdAndHotelMatch: handleAddAdAndHotelMatch,
  };
};

export default useHandleForm;
