import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FacilityRoomsInitialState } from "slices/facility/facilityRooms/reducer";
import {
  getFacilityRoom,
  getFacilityRoomNames,
} from "slices/facility/facilityRooms/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

const useFacilityRooms = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [selectedRoom, setSelectedRoom] = useState(0);

  const handleSelectRoom = (selectedRoom: number) => {
    setSelectedRoom(selectedRoom);
  };

  const otel_id = hotel.otel_id;
  const rooms = hotel.odalar;

  const { isLoading } = useSelector(
    (state: { FacilityRooms: FacilityRoomsInitialState }) => state.FacilityRooms
  );

  const hotelRooms = rooms.map((room) => ({
    otel_id: room.otel_id,
    oda_id: room.id,
    oda_isim: room.oda_isim,
  }));

  const hotelAndSelectedRoom = {
    otel_id,
    selectedRoom,
    handleSelectRoom,
  };

  useEffect(() => {
    if (otel_id) {
      dispatch(getFacilityRoom({ otel_id, oda_id: 0 }));
      dispatch(
        getFacilityRoomNames({
          page: 1,
          per_page: 100,
          search: "",
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    error,
    isValidHotel,
    hotelRooms,
    hotelAndSelectedRoom,
    isLoading,
  };
};

export default useFacilityRooms;
