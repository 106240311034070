import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";

import { MoneyPointsInitialState } from "slices/reports/moneyPoints/reducer";
import { useEffect, useState } from "react";
import { AddMoneyPointRequestPayload } from "types/reports";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const {
    hotelUsers,
    addMoneyPoint,
    addMoneyPointFormErrors,
    addMoneyPointLoading,
    addMoneyPointError,
  } = useSelector(
    (state: { MoneyPoints: MoneyPointsInitialState }) => state.MoneyPoints
  );

  const [hotelUsersOptions, setHotelUsersOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = hotelUsers
      ? hotelUsers.map((user) => ({
          value: user.value,
          label: user.text,
        }))
      : [];
    setHotelUsersOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelUsers]);

  const addMoneyPointRequestPayload: AddMoneyPointRequestPayload = {
    operator_kodu: addMoneyPoint.operator_kodu,
    parapuan_tutar: addMoneyPoint.parapuan_tutar,
  };

  // const payload = new URLSearchParams();
  // payload.append("operator_kodu", addMoneyPoint.operator_kodu);
  // payload.append("parapuan_tutar", addMoneyPoint.parapuan_tutar);

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    addMoneyPoint,
    addMoneyPointRequestPayload,
    formErrors: addMoneyPointFormErrors,
    isLoading: addMoneyPointLoading,
    error: addMoneyPointError,
    hotelUsersOptions,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
