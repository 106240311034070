import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  HourlyDiscountFormErrors,
  HourlyDiscountState,
} from "types/DiscountsAndActions";

import { handleChangeInput } from "slices/discountsAndActions/hourlyDiscounts/reducer";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface HourRangeSelectorProps {
  values: HourlyDiscountState;
  formErrors: HourlyDiscountFormErrors;
  validateFormChange: (values: HourlyDiscountState) => void;
}

const HourRangeSelector = ({
  values,
  formErrors,
  validateFormChange,
}: HourRangeSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="İndirim Saat Aralığı"
        error={formErrors.indirim_saat_araligi}
      >
        <div className="d-flex gap-2 align-items-center">
          <div className="d-flex flex-column gap-2">
            <Flatpickr
              id="baslangic_saati"
              className="form-control"
              value={[values.baslangic_saati]}
              onChange={(date) => {
                const [startDate] = date;
                const stringStartDate = moment(startDate).format("HH:00");

                dispatch(
                  handleChangeInput({
                    baslangic_saati: stringStartDate,
                  })
                );
                validateFormChange({
                  ...values,
                  baslangic_saati: stringStartDate,
                });
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                locale: Turkish,
                onDayCreate,
              }}
            />
          </div>
          <span>-</span>
          <div className="d-flex flex-column gap-2">
            <Flatpickr
              id="bitis_saati"
              className="form-control"
              value={[values.bitis_saati]}
              onChange={(date) => {
                const [endDate] = date;
                const stringEndDate = moment(endDate).format("HH:00");

                dispatch(
                  handleChangeInput({
                    bitis_saati: stringEndDate,
                  })
                );
                validateFormChange({
                  ...values,
                  bitis_saati: stringEndDate,
                });
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                locale: Turkish,
                onDayCreate,
              }}
            />
          </div>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default HourRangeSelector;
