import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import { CustomLoadingPage } from "Components/Custom/UI";
import FacilityPullImageFormUrlForm from "./FacilityPullImageFormUrlForm";
import FacilityAddNewImagesForm from "./FacilityAddNewImagesForm";
import FacilityOrderImagesForm from "./FacilityOrderImagesForm";

interface FacilityImagesPageProps {
  isLoading: boolean;
}

const FacilityImagesPage = ({ isLoading }: FacilityImagesPageProps) => {
  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <FacilityPullImageFormUrlForm />
        <FacilityAddNewImagesForm />
        <FacilityOrderImagesForm />
      </div>
    </>
  );
};

export default withPageNeedsHotel(FacilityImagesPage);
