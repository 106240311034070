import { CustomFormContainer } from "Components/Custom/UI";
import FacilityFeatures from "./FacilityFeatures";
import FacilityRoomDescriptionField from "./FacilityRoomDescriptionField";
import FacilityRoomNameAndAreaFields from "./FacilityRoomNameAndAreaFields";
import FacilitySpecialFeatures from "./FacilitySpecialFeatures";
import RoomSelector from "./RoomSelector";
import UpdateFacilityRoomInfoButton from "./UpdateFacilityRoomInfoButton";
import useHandleForm from "./useHandleForm";

interface UpdateFacilityRoomInfoFormProps {
  hotelRooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    selectedRoom: number;
    handleSelectRoom: (selectedRoom: number) => void;
  };
}

const UpdateFacilityRoomInfoForm = (props: UpdateFacilityRoomInfoFormProps) => {
  const { selectedRoom } = props.hotelAndSelectedRoom;

  const {
    updateFacilityRoom,
    updateFacilityRoomRequestPayload,
    roomFeaturesOptions,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm(selectedRoom);

  return (
    <CustomFormContainer title="Oda Bilgilerini Düzenle" lg={12} xl={12}>
      <RoomSelector {...props} />
      {selectedRoom ? (
        <>
          <FacilityRoomNameAndAreaFields
            values={updateFacilityRoom}
            formErrors={formErrors}
            validateFormChange={validateFormChange}
          />
          <FacilityRoomDescriptionField
            values={updateFacilityRoom}
            formErrors={formErrors}
            validateFormChange={validateFormChange}
          />
          <FacilityFeatures
            values={updateFacilityRoom}
            formErrors={formErrors}
            validateFormChange={validateFormChange}
            roomFeaturesOptions={roomFeaturesOptions}
          />
          <FacilitySpecialFeatures
            values={updateFacilityRoom}
            formErrors={formErrors}
            validateFormChange={validateFormChange}
            roomFeaturesOptions={roomFeaturesOptions}
          />
          <UpdateFacilityRoomInfoButton
            values={updateFacilityRoom}
            requestPayload={updateFacilityRoomRequestPayload}
            validateForm={validateForm}
            handleSubmitted={handleSubmitted}
            selectedRoom={selectedRoom}
            isLoading={isLoading}
          />
        </>
      ) : (
        <div className="flexjustify-center items-center border rounded p-4 bg-white">
          <p className="text-center m-0 fw-semibold">
            Düzenlemek istediğiniz odayı seçiniz...
          </p>
        </div>
      )}
    </CustomFormContainer>
  );
};

export default UpdateFacilityRoomInfoForm;
