export const ANALYSIS_COLORS = {
  red: "#ef4444",
  orange: "#f97316",
  yellow: "#eab308",
  green: "#22c55e",
  teal: "#14b8a6",
  blue: "#3b82f6",
  sky: "#0ea5e9",
  indigo: "#6366f1",
  purple: "#8b5cf6",
  rose: "#ec4899",
  gray: "#71717a",
  slate: "#94a3b8",
};
