import { useState } from "react";
import { CustomAvailabilityCalendar } from "Components/Custom/UI";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Spinner,
} from "reactstrap";
import { CompetitorAnalysisFacilityAvailability } from "types/adsAndAnalyze";

interface FacilityAvailabilityModalProps {
  modal: boolean;
  toggle: () => void;
  facilityAvailabilities: CompetitorAnalysisFacilityAvailability[];
  isLoading: boolean;
}

function FacilityAvailabilityModal({
  modal,
  toggle,
  facilityAvailabilities,
  isLoading,
}: FacilityAvailabilityModalProps) {
  const [open, setOpen] = useState("1");

  const toggleAccordion = (id: string) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>Müsaitlik Durumu</ModalHeader>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "30vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <ModalBody>
          <Accordion open={open} toggle={toggleAccordion}>
            <div className="d-flex flex-column gap-2">
              {facilityAvailabilities.map((availability, index) => (
                <div key={index}>
                  {Object.entries(availability).map(([key, value]) => (
                    <AccordionItem>
                      <AccordionHeader targetId={index.toString()}>
                        <span className="text-uppercase fw-bold text-center">
                          {key} Ödeme
                        </span>
                      </AccordionHeader>
                      <AccordionBody accordionId={index.toString()}>
                        <div key={key} className="d-flex flex-column gap-4">
                          {Object.entries(value).map(([key, value]) => (
                            <div
                              key={key}
                              className="d-flex flex-column gap-2 border p-2 theme-bg-light rounded"
                            >
                              <span className="text-uppercase text-success bg-success bg-opacity-25 fw-bold text-center">
                                {key}
                              </span>
                              <div className=" overflow-auto">
                                <CustomAvailabilityCalendar bookings={value} />
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </div>
              ))}
            </div>
          </Accordion>
        </ModalBody>
      )}
    </Modal>
  );
}

export default FacilityAvailabilityModal;
