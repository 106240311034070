import { useSelector } from "react-redux";
import {
  ListLocationsInitialState,
  handleAddToLocationsDontHaveChild,
  handleSaveSelectedLocations,
} from "slices/definitions/listLocations/reducer";

import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import { Location } from "types/definitions";
import LocationSelect from "./LocationSelect";
import { useEffect } from "react";

interface SelectedLocation {
  key: number;
  value: Location[];
}

const ListLocationsForm = () => {
  const { locations, selectedLocations, locationsDontHaveChild } = useSelector(
    (state: { ListLocations: ListLocationsInitialState }) => state.ListLocations
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleAddLocation = (selected: Location[], index: number) => {
    const dumb = [...selectedLocations];

    const key = selected[0]?.parent_id;

    if (key) {
      if (dumb.length >= index) {
        dumb.splice(index + 1, dumb.length - index);
        dumb.push({ key, value: selected });
        dispatch(handleSaveSelectedLocations(dumb));
      } else {
        dumb.push({ key, value: selected });
        dispatch(handleSaveSelectedLocations(dumb));
      }
    } else {
      dumb.splice(index + 1, dumb.length - index);
      dispatch(handleSaveSelectedLocations(dumb));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(handleAddToLocationsDontHaveChild([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="border px-2 py-4 rounded bg-light d-flex gap-4">
      {selectedLocations.map(
        (selectedLocation: SelectedLocation, index: number) => (
          <LocationSelect
            key={index}
            index={index}
            isDefaultLocation={index === 0}
            isPreLocation={index === selectedLocations.length - 2}
            isLocation={index === selectedLocations.length - 1}
            showList={
              index === selectedLocations.length - 1 ||
              index === selectedLocations.length - 2
            }
            showButtons={index === selectedLocations.length - 1}
            locations={locations}
            selectedLocation={selectedLocation}
            selectedLocations={selectedLocations}
            handleSelectedLocation={handleAddLocation}
            locationsDontHaveChild={locationsDontHaveChild}
          />
        )
      )}
    </div>
  );
};

export default ListLocationsForm;
