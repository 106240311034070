//Add Facility

export const FACILITY_NAME_DESCRIPTION =
  "Tesisin resmi sitesinde yer alan tam adı yazılmalı, baş harfleri büyük olmalıdır.";

export const FACILITY_PHONE_DESCRIPTION =
  "BU KISIMA HİÇBİR ŞEKİLDE MÜDAHALE ETMEYİN! (SİLMEYİN-NUMARA YAZMAYIN)";

export const FACILITY_LOGO_DESCRIPTION =
  "Tesisin resmi sitesinde yer alan Orijinal logo kullanılmalıdır. Yazılar ve simgeler beyaz, gri olmamalıdır. Logo eklendikten sonra kontrol edilmeli, okunmayan, küçük kalan logolar eklenmemelidir. Arka fonu renkli logo tercihen kullanmayalım.";

export const FACILITY_STARS_DESCRIPTION =
  "Resmi sitede yıldız sayısı belirtiliyorsa buna göre seçilmeli. Eğer yoksa sizin tesise vereceğiniz değerlendirme yıldızı ve minimum 3 yıldız olacak şekilde seçilmeli.";

export const FACILITY_LINK_DESCRIPTION =
  "Lütfen kontrol et! Boşluk ve Türkçe karakter olmasın !! Tesisin ismi ve ili aralarında tre bırakılarak, İngilizce karakterler kullanılarak yazılmalı. (abant-gokdere-kartal-yuvasi-bolu)";

export const FACILITY_CONSTRUCTION_DATE_DESCRIPTION =
  "Resmi sitedeki bilgilerde yoksa eğer tesis aranmalıdır. (Sadece yıl belirtin. 2012-2010-2009 gibi). Sadece Yıl Giriniz";

export const FACILITY_RENOVATION_DATE_DESCRIPTION =
  "Resmi sitedeki bilgilerde yoksa eğer tesis aranmalıdır.(Sadece yıl belirtin. 2012-2010-2009 gibi). Sadece Yıl Giriniz";

export const FACILITY_ROOM_COUNT_DESCRIPTION =
  "Resmi sitedeki bilgilerde yoksa eğer tesis aranmalıdır.(Sadece sayı belirtin.325-200-50-20 gibi). Sadece sayı Giriniz";

export const FACILITY_DISTANCE_TO_SEA_DESCRIPTION =
  "Resmi sitedeki bilgilerde yoksa eğer tesis aranmalıdır.( 100 M – 250 M – Denize Sıfır – 1200 M ). En fazla 25 karakter!";

export const FACILITY_FEATURES_DESCRIPTION =
  "Bu alanda tesise özel olan özellikler belirtilmeli. Şehir oteli ise, şehir otelinde aranacak bir özellik ( Wi-Fi, Otopark, Kapalı Havuz gibi) Doğa oteli ise ( Spa Center, Kapalı Havuz, Doğa Yürüyüşleri gibi) Termal otel ise ( Odalarda Termal Su, Aquapark'lı Havuz, Alkol Bulunmaktadır-Alkolsüz Tesis gibi ) Yazlık bir otel ise ( Aquapark- Çocuk Havuzu, Mini Club-Çocuk Büfesi, Spa Center, Animasyon, Konserler, Su Sporları gibi). En fazla 25 Karakter !!";

export const FACILITY_GENERAL_DESCRIPTION =
  "Bu paragraf otel içerik bölümünün ilk kısmıdır. Bu alanda tesisin nerede olduğu, konsepti, odaları, genel özelliğinden (termal-yazlık-doğa-şehir oteli) kısa özetler halinde kendi yorumunuzla yazılmalı. Süslü cümleler kullanılmalıdır.( Doğa ile iç içe olan tesisimiz-ödüllü aşçılarımızın hazırlamış olduğu açık büfe – Eşsiz hizmet kalitesi – Şifa dolu termal suları – vs)";

export const FACILITY_FEATURES_CHECKBOX_DESCRIPTION =
  "Bu alandaki özelliklerin tikleri mutlaka doğru ve eksiksiz olmalıdır. Her özelliğin doğruluğunu teyit ederek işaretleme yapınız.";

export const FACILITY_ADDRESS_DESCRIPTION =
  "Tesisin resmi sitesinde yer alan adres yazılmalı. Baş harfler büyük şeklinde yazılmalı. Posta kodu, Türkiye, gibi bilgileri yazmıyoruz. Tam adres sonunda mevkii, ilçe, il olacak. Alsancak Konak İzmir gibi.";

export const FACILITY_LOCATION_ID_DESCRIPTION =
  "Tesisin bulunduğu İlçe ve Mevkii mutlaka seçilmelidir. Örnek : Ege Bölgesi - İzmir - Çeşme - Alaçatı ( BÖLGE - İL - İLÇE - MEVKİİ )";

export const FACILITY_LOCATION_DESCRIPTION =
  "Tesisin konumu, bölgede bulunan Havaalanı-Otogar-İlçe Merkezi-İl Merkezi gibi yerlere olan mesafesi, yine bölgede bulunan özel yerler (müze-stadyum-alışveriş merkezi gibi ) gezilecek, görülmesi gereken yerlerdendir olarak belirtilmeli.";

export const FACILITY_CRM_LOCATION_DESCRIPTION =
  "Bu alan misafirler tarafından görünmeyecektir. Sadece Crm ekranında sizin ulaşabileceğiniz bilgidir. Detaylı olarak adres isteyen misafirler için tesisle görüşerek tamamlamanız gereken bilgidir. Örneğin, İzmir ofisimizin adresi tarif edilirken, Basmane Meydanında bulunan Vatan Computer'ın yan binası. Ontur Otel İzmir'in karşısı A Blok. Nüfus Müdürlüğü yanı gibi)";

export const FACILITY_ACCOMMODATION_DESCRIPTION =
  "Tesisin toplam oda sayısı, tesiste bulunan oda çeşitleri ve kaçar tane olduğu, bu odaların özellikleri ( metrekaresi, yatak sayısı, araç ve gereçler) belirtilmelidir.";

export const FACILITY_ROOM_FEATURES_DESCRIPTION =
  "Bu alandaki özelliklerin tikleri mutlaka doğru ve eksiksiz olmalıdır. Her özelliğin doğruluğunu teyit ederek işaretleme yapınız.";

export const FACILITY_FOOD_DESCRIPTION =
  "Konsept, yiyecek ve içecekler detaylı olarak belirtilmeli. Restoran açılış kapanış saatleri, barların açılış kapanış saatleri, ücretli-ücretsiz barlar(varsa), A'la Carte restoranlar ( ücretli-ücretsiz) detaylı olarak belirtin.";

export const FACILITY_FOOD_FEATURES_DESCRIPTION =
  "Tesis özelliğine göre seçimler yapılmalıdır.";

export const FACILITY_ACTIVITY_DESCRIPTION =
  "Otelde bulunan animasyon – spa – oyun odaları – mini club – konserler – doğa gezileri – tekne turları – su sporları gibi tüm aktivite çeşitleri belirtilmelidir.";

export const FACILITY_ACTIVITY_FEATURES_DESCRIPTION =
  "Aktivite ikonları doğru şekilde seçilmelidir.";

export const FACILITY_POOL_DESCRIPTION =
  "Tesis özelliğine göre seçimler yapılmalıdır.";

export const FACILITY_THEME_DESCRIPTION =
  "Bu bölümde tesis özelliğine uygun tüm özellikler seçilmelidir. Burdan yapılan seçimler sitemizdeki tema sayfalarında otelin görünmesini sağlar. Örneğin kayak oteli işaretlendiğinde sitemizdeki Kayak Otelleri tema sayfasında tesisimiz otomatik olarak görünür.";

export const FACILITY_GOOGLE_MAPS_DESCRIPTION =
  "Google Maps üzerinden tesisin konumunu bulunuz. Konum üzerinde mouse üzerinde sağ tıklayıp 'Burası Neresi' seçiniz. Çıkacak olan koordinatta virgüle kadar olan kısımı X, virgül sonrasını Y kısmına yazınız. ( Örnek 38.433235, 30.195132 )";

export const FACILITY_COMPETITOR_OTELZ_DESCRIPTION =
  "www.otelz.com adresinden analiz yapılacak otele giderek, fiyatı olan bir tarihte fiyat hesaplayın. Sonrasında roomprice kısmından sonraki ID'yi alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_HOTELS_DESCRIPTION =
  "www.hotels.com adresinden analiz yapılacak otele giderek herhangi bir fiyat hesaplayın. Sonrasında adres çubuğunda en sona giderek f-hotel-id kısmındaki ID'yi alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_ETSTUR_DESCRIPTION =
  "www.etstur.com adresinden analiz yapılacak otele gidin. Sonrasında adres çubuğundaki seflink kısmını alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_TATILSEPETI_DESCRIPTION =
  "www.tatilsepeti.com adresinden analiz yapılacak otele gidin. Sonrasında adres çubuğundaki seflink kısmını alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_TATILCOM_DESCRIPTION =
  "www.tatil.com adresinden analiz yapılacak otele gidin. Sonrasında adres çubuğundaki seflink kısmını alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_TATILBUDUR_DESCRIPTION =
  "www.tatilbudur.com adresinden analiz yapılacak otele gidin. Sonrasında adres çubuğundaki seflink kısmını alarak buraya ekleyiniz.";

export const FACILITY_COMPETITOR_ODAMAX_DESCRIPTION =
  "www.odamax.com adresinden analiz yapılacak otele gidin. Sonrasında adres çubuğundaki seflink kısmını alarak buraya ekleyiniz.";

export const FACILITY_SPECIAL_FEATURES_DESCRIPTION =
  "Burada eklenecek olan öne çıkan özelliklerin yukarıdaki kutucularda seçili olması gerekmektedir. Lütfen yukarıdaki kutucuklarda seçili olduğundan emin olunuz!";

export const FACILITY_USER_USERNAME_DESCRIPTION =
  "Kullanıcı adı ingilizce karakterler, sayı ve nokta '.' kullanılarak yazılmalı. Boşluklar yerine nokta ''.' kullanınız.";

export const FACILITY_SPECIAL_DAY_CATEGORY_DESCRIPTION =
  "Bu alandan seçeceğiniz kategorinin, tanımlayacağınız özel güne ve tarihlere uygun olduğunu kontrol edin.";

export const FACILITY_SPECIAL_DAY_TITLE_DESCRIPTION =
  "Baş harfleri büyük olacak ve uzun olmayacak şekilde başlığınızı oluşturun. (Örn; Yılbaşı Özel Programı - Sevgililer Gününe Özel - Babalar Gününe Özel vs.) Bu alana otel adı ve tarih yazılmayacak.";

export const FACILITY_SPECIAL_DAY_SINGER_NAME_DESCRIPTION =
  "Eğer sanatçı var ise baş harfleri büyük olacak şekilde sanatçı adı ve soyadı yazılmalı.";

export const FACILITY_SPECIAL_DAY_DATE_DESCRIPTION =
  "Başlangıç tarihini, özel günün o otel için web sitesinde görünmeye başlayacağı tarih olarak seçip, bitiş tarihini özel günün son günü seçmelisiniz. Örn: Yılbaşında 01.12.2024 - 31.12.2024 seçilmeli.";

export const FACILITY_SPECIAL_DAY_IMAGE_DESCRIPTION =
  "Otel veya farklı bir firmaya ait telefon numarası olan resimleri yüklemeyiniz. Yükleme işleminden sonra otelfiyat.com ve crm'de resmin düzgün açıldığını kontrol edin!";

// !My Web Site
export const ADD_DOMAIN_DESCRIPTION =
  "Domain Yönetim Panelinizin Nameservers (DNS) yönetimi kısmından DNS 1 için jake.ns.cloudflare.com, DNS 2 için vita.ns.cloudflare.com yazmanız yeterlidir.";

export const ADD_FEATURES_DESCRIPTION = "En fazla 3 adet seçim yapabilirsiniz.";

export const HOTEL_NO_LOCATION_DESCRIPTION =
  "Varsayılan olarak 'iletişim' sayfasında göster seçilidir. 'İletişim ve Headerda Göster' seçili ise otel numarası sayfanın üst kısmında ve iletişim sayfasında gösterilir. 'Gösterme' seçili ise otel numarası hiçbir yerde gösterilmez.";

export const ADD_CERTIFICATE_IMAGE =
  "Yalnızca pdf formatında sertifikalar yükleyebilirsiniz.";

export const PERSONNEL_ASSIGNEE_MAIN_AUTHORITY_OPTION_DESCRIPTION =
  "Personeli ana yetkili olarak işaretlemek istiyorsanız bir otel seçin.";

export const KEEP_BOTH_PERSONNEL_DESCRIPTION =
  "Seçilirse aynı otel iki kullanıcıda da olur.";
