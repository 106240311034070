import axios from "axios";

export const handleErrors = (
  error: any,
  callback = (message: string) => {}
) => {
  if (axios.isAxiosError(error)) {
    const message = error.response
      ? error.response.data?.data?.message || error.response.data.message
      : "Bir hata oluştu.";

    callback(message);
  } else if (error instanceof Error) {
    callback(error?.message || error?.message);
  } else {
    callback("Bir hata oluştu.");
  }
};
