import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { CreateWebSiteFormErrors, CreateWebSiteState } from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/createWebSite/reducer";

interface AccomodationInformationImageProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const AccomodationInformationImage = ({
  values,
  validateFormChange,
  formErrors,
  imageUrl,
}: AccomodationInformationImageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.konaklama_bilgileri_resim
    ? [values.konaklama_bilgileri_resim]
    : [];

  const imageToShow = `${imageUrl}${values.konaklama_bilgileri_resim_onizleme}`;

  return (
    <>
      <CustomFormFieldContainer
        label="Konaklama Bilgileri Resim :"
        error={formErrors.konaklama_bilgileri_resim}
        divider
        orientation="vertical"
      >
        <div className="d-flex w-100 gap-5">
          {imageToShow ? (
            <div className="flex-grow-1" style={{ height: "200px" }}>
              <img
                src={imageToShow}
                alt="Resim"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          ) : (
            <p>Resim Bulunamadı</p>
          )}
          <div className="flex-grow-1">
            <CustomMultipleFileUploader
              files={files as any}
              handleAddFiles={(fileItems) => {
                dispatch(
                  handleChangeInput({
                    konaklama_bilgileri_resim: fileItems[0],
                  })
                );
                validateFormChange({
                  ...values,
                  konaklama_bilgileri_resim: fileItems[0],
                });
              }}
              maxFiles={1}
              maxFileSize={10000000}
            />
          </div>
        </div>
      </CustomFormFieldContainer>
    </>
  );
};

export default AccomodationInformationImage;
