import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import { FilterLogsPayload, ListLogsResponse, Logs, LogsFilterFormChoices  } from "types/userOperations";

export interface ListLogsInitialState {
  logs: Logs[];
  formChoices : LogsFilterFormChoices;
  logsResponse: ListLogsResponse;
  filterLogs: FilterLogsPayload;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

export const listLogsInitialState: ListLogsInitialState = {
  logs: [],
  formChoices: {
    sayfalar: [],
    personeller: [],
  },
  logsResponse: {
    logs: [],
    sayfalar: {},
    personeller: [],
  },
  filterLogs: {
    start_date: "",
    end_date: "",
    user_id: 0,
    sayfa: "",
    per_page: 10,
    page: 1,
    search: "",
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const listLogsSlice = createSlice({
  name: "listLogs",
  initialState: listLogsInitialState,
  reducers: {
    handleSaveFilterOptionsResponse(state, action) {
      state.logsResponse = action.payload;
      const { logs, ...formchoices } = action.payload;
      state.formChoices = formchoices;
    },
    handleSaveLogResponse(state, action){
      const { data, ...rest } = action.payload;
      state.logs = data;
      state.pagination = { ...rest };
    },
    handleChangeFilterLogsInput(state, action){
      state.filterLogs = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveFilterOptionsResponse,
  handleSaveLogResponse,
  handleChangeFilterLogsInput,
  setIsLoading,
  setError,
} = listLogsSlice.actions;
export default listLogsSlice.reducer;