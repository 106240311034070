import { CustomFormContainer } from "Components/Custom/UI";
import { CreateWebSiteState, CreateWebSiteFormErrors } from "types/myWebSite";
import SpaInformationImage from "./SpaInformationImage";
import SpaInformationText from "./SpaInformationText";

interface SpaInformationFieldsProps {
  values: CreateWebSiteState;
  formErrors: CreateWebSiteFormErrors;
  validateFormChange: (values: CreateWebSiteState) => void;
  imageUrl: string;
}

const SpaInformationFields = ({
  values,
  formErrors,
  validateFormChange,
  imageUrl,
}: SpaInformationFieldsProps) => {
  return (
    <div className="mb-4">
      <CustomFormContainer title="Spa & Sauna Bilgileri" lg={12} xl={12}>
        <SpaInformationImage
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
          imageUrl={imageUrl}
        />
        <SpaInformationText
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </CustomFormContainer>
    </div>
  );
};
export default SpaInformationFields;
