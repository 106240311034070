import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import DiscountRate from "./DiscountRate";
import Switches from "./Switches";
import UpdateRateButton from "./UpdateRateButton";

interface UncancellableRoomRatesFormProps {
  rooms: {
    otel_id: number;
    oda_id: number;
    oda_isim: string;
  }[];
  hotelAndSelectedRoom: {
    otel_id: number;
    oda_id: number;
  };
}

const UncancellableRoomRatesForm = (props: UncancellableRoomRatesFormProps) => {
  const {
    uncancellableRoomRate,
    updateUncancellableRoomRatePayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    isLoading,
  } = useHandleForm(props.hotelAndSelectedRoom);

  return (
    <CustomFormContainer>
      <DiscountRate
        values={uncancellableRoomRate}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <Switches
        values={uncancellableRoomRate}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <UpdateRateButton
        values={uncancellableRoomRate}
        requestPayload={updateUncancellableRoomRatePayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        hotelAndSelectedRoom={props.hotelAndSelectedRoom}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default UncancellableRoomRatesForm;
