import { createSlice } from "@reduxjs/toolkit";

import {
  RoomLackPictureResponse,
  RoomsLackPictureFilterItem,
  RoomLackPicture,
} from "types/reports";

export interface RoomsLackPictureInitialState {
  roomsLackPicture: RoomLackPictureResponse;
  filters: RoomsLackPictureFilterItem[];
  roomsLackPictureData: RoomLackPicture[];
  isLoading: boolean;
  error: string;
}

const initialState: RoomsLackPictureInitialState = {
  roomsLackPicture: {
    filters: [],
    data: [],
  },
  filters: [],
  roomsLackPictureData: [],
  isLoading: false,
  error: "",
};

const roomsLackPictureSlice = createSlice({
  name: "roomsLackPicture",
  initialState,
  reducers: {
    handleSaveRoomsLackPicture(state, action) {
      const payload: RoomLackPictureResponse = action.payload;
      const { filters, data } = payload;

      state.roomsLackPicture = action.payload;
      state.filters = filters;
      state.roomsLackPictureData = data;
    },

    handleSaveFilteredRoomsLackPicture(state, action) {
      state.roomsLackPictureData = action.payload;
    },
    setIsLaoding(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveRoomsLackPicture,
  handleSaveFilteredRoomsLackPicture,
  setIsLaoding,
  setError,
} = roomsLackPictureSlice.actions;

export default roomsLackPictureSlice.reducer;
