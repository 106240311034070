import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import { setToastError } from "slices/toast/reducer";
import { ResetFacilityUserPasswordRequestPayload } from "types/facility";
import { resetFacilityUserPassword } from "slices/facility/facilityUsers/thunk";
import { CustomButton } from "Components/Custom/UI";

interface ResetPasswordButtonProps {
  selectedMail: string;
  username: string;
  isLoading: boolean;
}

const ResetPasswordButton = ({
  selectedMail,
  username,
  isLoading,
}: ResetPasswordButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleResetPassword = () => {
    if (!selectedMail) {
      dispatch(setToastError("Lütfen bir e-posta adresi seçiniz"));
      return;
    }
    const resetUserPasswordRequestPayload: ResetFacilityUserPasswordRequestPayload =
      {
        username,
        mail: selectedMail,
        sifre_guncelleme: 1,
      };

    dispatch(resetFacilityUserPassword(resetUserPasswordRequestPayload));
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <CustomButton
        variant="success"
        onClick={handleResetPassword}
        isLoading={isLoading}
      >
        Parolayı Sıfırla
      </CustomButton>
    </div>
  );
};

export default ResetPasswordButton;
