interface LoginFormFooterProps {
  title: string;
  label: string;
  link: string;
}

const LoginFormFooter = ({ title, label, link }: LoginFormFooterProps) => {
  return (
    <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
      <p>{title}</p>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="w-100 text-center border border-primary theme-text-primary p-2 rounded text-decoration-none"
      >
        {label}
      </a>
    </div>
  );
};

export default LoginFormFooter;
