import { useSelector } from "react-redux";
import { PersonnelRequestInitialState } from "slices/personnelRequest/reducer"
import { CustomRowOperationButton } from "Components/Custom/UI";
import useHandleRequest from "./useHandleRequest";
import PersonnelRequestModal from "./TableModal/PersonnelRequestModal";



const useHandleTable = () => {
    const { personnelRequest } = useSelector(
        (state: { PersonnelRequest: PersonnelRequestInitialState }) => state.PersonnelRequest
    );

    const { showPersonnelRequestModal,
        onTogglePersonnelRequestModal,
        onSavePersonnelRequestAnswer
    } = useHandleRequest();

    const tableData = personnelRequest?.map(
        (request) => ({
            "Talep Id": request.id,
            "Üye Id": request.uye_id,
            "Üye Ad Soyad": request.ad_soyad,
            "Talep Konu": request.talep_konu,
            "Talep Zamanı": request.talep_zamani,
            "Talep Mesajı": request.talep_mesaj,
            "Talep Cevap Zamanı": request.talep_cevap_zamani,
            "Talep Cevap Mesajı": request.talep_cevap,
        })
    );

    const tableColumns = [
        {
            header: "Üye Ad Soyad",
            accessorKey: "Üye Ad Soyad",
            enableColumnFilter: false,
        },
        {
            header: "Talep Konu",
            accessorKey: "Talep Konu",
            enableColumnFilter: false,
        },
        {
            header: "Talep Zamanı",
            accessorKey: "Talep Zamanı",
            enableColumnFilter: false,
        },
        {
            header: "Talep Mesajı",
            accessorKey: "Talep Mesajı",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const value = cell.getValue();
                return (
                    <div className="text-wrap" style={{width:"400px"}}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: "Talep Cevap Zamanı",
            accessorKey: "Talep Cevap Zamanı",
            enableColumnFilter: false,

        },
        {
            header: "Talep Cevap Mesajı",
            accessorKey: "Talep Cevap Mesajı",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const value = cell.getValue();
                return (
                    <div className="text-wrap" style={{width:"400px"}}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: "Durum",
            accessorKey: "Durum",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const value = cell.getValue('Talep Cevap Mesajı');

                const status =
                    value !== null ? "Cevaplandı" : "Cevaplanmadı";

                const color =
                    value !== null ? "success" : "danger";

                return (
                    <span className={`badge bg-${color} text-${color} bg-opacity-25`}>
                        {status}
                    </span>
                )
            }
        },
        {
            header: "Cevapla",
            cell: (cell: any) => {
                const id = cell.getValue();
                return (
                    <>
                        <div className="d-flex justify-content-center gap-2">
                            <CustomRowOperationButton
                                variant="update"
                                onClick={() => onTogglePersonnelRequestModal(id)}
                            />
                        </div>
                        <PersonnelRequestModal
                            request_id={id}
                            isOpen={showPersonnelRequestModal[id] || false}
                            handleToggle={() => onTogglePersonnelRequestModal(id)}
                            saveAnswer={(message) => onSavePersonnelRequestAnswer({
                                talep_id : id, 
                                cevap: message
                            })}
                        />
                    </>
                );
            },
            accessorKey: "Talep Id",
            enableColumnFilter: false,
        },
    ];

    return { tableData, tableColumns };
};

export default useHandleTable;
