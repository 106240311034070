import { AddGroupPayload } from "types/userOperations";
import AddGroupButton from "./FormFields/AddGroupButton";
import GroupManagerSelector from "./FormFields/GroupManagerSelector";
import GroupNameInput from "./FormFields/GroupNameInput";
import useHandleForm from "./useHandleForm";

import { CustomFormContainer } from "Components/Custom/UI";
import GroupMembersList from "./FormFields/GroupMembersList";

const AddGroupForm = () => {
  const {
    formChoices,
    values,
    formErrors,
    isLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    requestPayload,
  } = useHandleForm();

  return (
    <CustomFormContainer title="Grup Ekle">
      <GroupNameInput
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <GroupManagerSelector
        values={values}
        formChoices={formChoices}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <GroupMembersList
        values={values}
        formChoices={formChoices}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <div className="d-flex justify-content-end">
        <AddGroupButton
          values={values}
          requestPayload={requestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={isLoading}
        />
      </div>
    </CustomFormContainer>
  );
};

export default AddGroupForm;
