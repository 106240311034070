import { Input } from "reactstrap";

interface InputFieldProps {
  identifier: string;
  value: number;
  isEditable: boolean;
  type: string;
  isBasePrice: boolean;
  onChange: (type: string, value: number, isBasePrice: boolean) => void;
  notAllowedToEdit?: boolean;
}

const InputField = ({
  identifier,
  value,
  isEditable,
  type,
  isBasePrice,
  onChange,
  notAllowedToEdit,
}: InputFieldProps) => {
  const parsedValue = value;

  return (
    <div
      className={`input-group border border-2 rounded ${
        isBasePrice ? "fw-bold border-primary " : ""
      }`}
    >
      <Input
        type="number"
        className="form-control fs-12 border-0"
        value={Math.ceil(parsedValue)}
        disabled={!isEditable || notAllowedToEdit}
        onChange={(e) => {
          e.target.value === ""
            ? onChange(type, 0, isBasePrice)
            : onChange(
                type,
                Math.ceil(parseFloat(e.target.value)),
                isBasePrice
              );
        }}
        onFocus={(e) => (e.target.value === "0" ? e.target.select() : null)}
      />
      <span className="input-group-text fs-12 border-0">{identifier}</span>
    </div>
  );
};

export default InputField;
