import { createSlice } from "@reduxjs/toolkit";

import {
  StayLongPayLessAction,
  StayLongPayLessActionFormErrors,
  StayLongPayLessActionState,
} from "types/DiscountsAndActions";
import moment from "moment";

export interface StayLongPayLessActionsInitialState {
  stayLongPayLessActions: StayLongPayLessAction[];
  stayLongPayLessAction: StayLongPayLessActionState;
  stayLongPayLessActionFormErrors: StayLongPayLessActionFormErrors;
  selectedRoom: number;
  isLoading: boolean;
  error: string;
  addActionLoading: boolean;
  addActionError: string;
  updateActionLoading: boolean;
  updateActionError: string;
  deleteActionLoading: boolean;
  deleteActionError: string;
}

export const initialState: StayLongPayLessActionsInitialState = {
  stayLongPayLessActions: [],
  stayLongPayLessAction: {
    id: 0,
    otel_id: 0,
    otel_oda_id: 0,
    grup_id: 0,
    baslangic_tarih: moment().format("YYYY-MM-DD"),
    bitis_tarih: moment().add(1, "month").format("YYYY-MM-DD"),
    kal_gun_sayisi: 1,
    ode_gun_sayisi: 1,
    tum_gunler: "1",
    gunler: [
      {
        "1": "Ptesi",
      },
      {
        "2": "Sali",
      },
      {
        "3": "Carsamba",
      },
      {
        "4": "Persembe",
      },
      {
        "5": "Cuma",
      },
      {
        "6": "Ctesi",
      },
      {
        "7": "Pazar",
      },
    ],
    kal_ode_geceleme: "0",
    desk_alti: "0",
    ozel_komisyon_orani_durum: "0",
    yineleme: "0",
    eb_birlikte: "0",
    tum_otel_pansiyonlar: "1",
    otel_pansiyonlar: [],
    tum_odeme_tipleri: "1",
    odeme_tipleri: [],
  },
  stayLongPayLessActionFormErrors: {},
  selectedRoom: 0,
  isLoading: false,
  error: "",
  addActionLoading: false,
  addActionError: "",
  updateActionLoading: false,
  updateActionError: "",
  deleteActionLoading: false,
  deleteActionError: "",
};

const stayLongPayLessActionsSlice = createSlice({
  name: "stayLongPayLessActions",
  initialState,
  reducers: {
    handleSaveStayLongPayLessActions(state, action) {
      state.stayLongPayLessActions = action.payload;
    },
    handleDeleteFromStayLongPayLessActionsWithId(state, action) {
      state.stayLongPayLessActions = state.stayLongPayLessActions.filter(
        (item) => item.id !== action.payload
      );
    },
    handleSaveStayLongPayLessActionForEdit(state, action) {
      state.stayLongPayLessAction = action.payload;
    },
    handleChangeInput(state, action) {
      state.stayLongPayLessAction = {
        ...state.stayLongPayLessAction,
        ...action.payload,
      };
    },
    handleAddStayLongPayLessActionFormErrors(state, action) {
      state.stayLongPayLessActionFormErrors = action.payload;
    },
    handleSelectRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetStayLongPayLessActions(state) {
      state.stayLongPayLessActions = [];
    },
    resetStayLongPayLessAction(state) {
      state.stayLongPayLessAction = {
        id: 0,
        otel_id: 0,
        otel_oda_id: 0,
        grup_id: 0,
        baslangic_tarih: moment().format("YYYY-MM-DD"),
        bitis_tarih: moment().add(1, "month").format("YYYY-MM-DD"),
        kal_gun_sayisi: 1,
        ode_gun_sayisi: 1,
        tum_gunler: "1",
        gunler: [
          {
            "1": "Ptesi",
          },
          {
            "2": "Sali",
          },
          {
            "3": "Carsamba",
          },
          {
            "4": "Persembe",
          },
          {
            "5": "Cuma",
          },
          {
            "6": "Ctesi",
          },
          {
            "7": "Pazar",
          },
        ],
        kal_ode_geceleme: "0",
        desk_alti: "0",
        ozel_komisyon_orani_durum: "0",
        yineleme: "0",
        eb_birlikte: "0",
        tum_otel_pansiyonlar: "1",
        otel_pansiyonlar: [],
        tum_odeme_tipleri: "1",
        odeme_tipleri: [],
      };
    },
    setAddDiscountLoading(state, action) {
      state.addActionLoading = action.payload;
    },
    setAddDiscountError(state, action) {
      state.addActionError = action.payload;
    },
    setUpdateDiscountLoading(state, action) {
      state.updateActionLoading = action.payload;
    },
    setUpdateDiscountError(state, action) {
      state.updateActionError = action.payload;
    },
    setDeleteDiscountLoading(state, action) {
      state.deleteActionLoading = action.payload;
    },
    setDeleteDiscountError(state, action) {
      state.deleteActionError = action.payload;
    },
  },
});

export const {
  handleSaveStayLongPayLessActions,
  handleDeleteFromStayLongPayLessActionsWithId,
  handleSaveStayLongPayLessActionForEdit,
  handleChangeInput,
  handleAddStayLongPayLessActionFormErrors,
  handleSelectRoom,
  setIsLoading,
  setError,
  resetStayLongPayLessActions,
  resetStayLongPayLessAction,
  setAddDiscountLoading,
  setAddDiscountError,
  setUpdateDiscountLoading,
  setUpdateDiscountError,
  setDeleteDiscountLoading,
  setDeleteDiscountError,
} = stayLongPayLessActionsSlice.actions;

export default stayLongPayLessActionsSlice.reducer;
