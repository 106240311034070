import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { handleAddAddLocationFormErrors } from "slices/definitions/addLocation/reducer";
import { setToastError } from "slices/toast/reducer";

import { AddLocationFormErrors, AddLocationState } from "types/definitions";

const useValidateForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    window.scrollTo(0, 0);

    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: AddLocationState,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: AddLocationFormErrors = {};

    if (!values.lokasyon) {
      errors.lokasyon = "Lokasyon adı boş bırakılamaz.";
    }

    // if (!values.parent_id) {
    //   errors.parent_id = "Üst lokasyon seçmelisiniz.";
    // }

    dispatch(handleAddAddLocationFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    isScroll && scrollToError();

    showErrorToast &&
      !isValid &&
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));

    return {
      isValid: Object.keys(errors).length === 0,
    };
  };

  const validateFormChange = (values: AddLocationState) => {
    isSubmitted && validateForm(values, false, false);
  };

  return { validateForm, validateFormChange, handleSubmitted: setIsSubmitted };
};

export default useValidateForm;
