import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { IYear } from "./types";
import { daysOfTheWeek, daysOfTheWeekOffset, getMonthName } from "./utils";

dayjs.extend(isBetween);

const Year = ({
  activeYear,
  showNumberOfMonths = 12,
  bookedDates = [],
  availableDates = [],
  lastRoomsDates = [],
  unavailableDates = [],
  noQuotaDates = [],
  monthsFrom = 1,
}: IYear): JSX.Element => {
  const _year = activeYear || dayjs().year();

  return (
    <div className="year" data-testid="year">
      {new Array(showNumberOfMonths).fill("").map((_, pos) => {
        const arrOffset = 1;
        const month = monthsFrom + pos;
        const date = `${_year}-${month}`;
        const monthName = getMonthName(month);
        const totalDays = dayjs(date).daysInMonth();
        const firstDayOfWeek = dayjs(`${date}-01`).day();

        const offsetDays =
          firstDayOfWeek !== 0
            ? new Array(firstDayOfWeek - arrOffset).fill("")
            : new Array(Number(daysOfTheWeekOffset[firstDayOfWeek])).fill("");

        const daysArr = new Array(totalDays).fill("");

        return (
          <div key={pos} className="month border rounded p-1">
            <h3 className="monthName">{monthName}</h3>

            <div className="content dayOfTheWeek">
              {daysOfTheWeek.map((dayOfTheWeek, pos) => {
                return (
                  <div key={pos} className="day">
                    {dayOfTheWeek}
                  </div>
                );
              })}
            </div>

            <div className="content">
              {offsetDays.map((_, pos) => {
                return <div key={pos} className="day" />;
              })}

              {daysArr.map((_, pos) => {
                const day = pos + arrOffset;
                const _date = `${month}-${day}-${_year}`;

                const isAvailable = Array.isArray(availableDates)
                  ? availableDates.includes(_date)
                  : false;
                const isLastRooms = Array.isArray(lastRoomsDates)
                  ? lastRoomsDates.includes(_date)
                  : false;
                const isUnavailable = Array.isArray(unavailableDates)
                  ? unavailableDates.includes(_date)
                  : false;
                const isNoQuota = Array.isArray(noQuotaDates)
                  ? noQuotaDates.includes(_date)
                  : false;

                return (
                  <div
                    key={pos}
                    className="day"
                    style={{
                      backgroundColor: isAvailable
                        ? "var(--theme-success)"
                        : isLastRooms
                        ? "var(--theme-warning)"
                        : isUnavailable
                        ? "var(--theme-danger)"
                        : isNoQuota
                        ? "var(--theme-info)"
                        : "inherit",
                    }}
                  >
                    <span>{day}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Year;
