import { CustomFormContainer } from "Components/Custom/UI";
import useHandleInfo from "./useHandleInfo";
import ChildrenInfoRow from "./ChildrenInfoRow";
import { Spinner } from "reactstrap";

const ChildrenInfo = () => {
  const { childrenInfo, childrenInfoLoading } = useHandleInfo();

  return (
    <CustomFormContainer>
      {childrenInfoLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" size="sm" />
        </div>
      ) : (
        <div className="d-flex flex-column gap-3">
          {childrenInfo.oda_bilgileri.map((room, index) => (
            <ChildrenInfoRow key={index} {...room} />
          ))}
        </div>
      )}
    </CustomFormContainer>
  );
};

export default ChildrenInfo;
