import React, { useState } from "react";
import { Collapse } from "reactstrap";

interface CustomCollapseProps {
  title: string;
  bgColor?: string;
  icon?: string;
  isOpen?: boolean;
  children: React.ReactNode;
  isBorder?: boolean;
}

const CustomCollapse = ({
  title,
  bgColor = "initial",
  icon,
  isOpen = false,
  children,
  isBorder = true,
}: CustomCollapseProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const toggle = () => setOpen(!open);

  return (
    <div
      className={
        isBorder
          ? "d-flex flex-column gap-3 border rounded theme-bg-light p-3"
          : "d-flex flex-column gap-3 border rounded bg-white"
      }
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer py-1 px-3 text-white rounded"
        onClick={toggle}
        style={{ backgroundColor: bgColor }}
      >
        <div className="d-flex align-items-center gap-3">
          {icon && <i className={`${icon} fs-24`}></i>}
          <p className="fs-18 fw-bold m-0">{title}</p>
        </div>
        <i className={`ri-arrow-${open ? "up" : "down"}-s-line fs-24`}></i>
      </div>
      <Collapse isOpen={open}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
