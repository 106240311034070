import { createSlice } from "@reduxjs/toolkit";

import {
  FacilityCall,
  FacilityManager,
  UpdateFacilityCallState,
} from "types/facility";

import { Pagination } from "types/GlobalTypes";

export interface FacilityCallInitialState {
  facilityManagers: FacilityManager[];
  facilityCalls: FacilityCall[];
  facilityCall: UpdateFacilityCallState;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
  startDate: string;
  endDate: string;
  facilityManagersLoading: boolean;
  facilityManagersError: string;
  updateCallLoading: boolean;
  updateCallError: string;
}

export const facilityCallInitialState: FacilityCallInitialState = {
  facilityManagers: [],
  facilityCalls: [],
  facilityCall: {
    otel_id: 0,
    operasyon_durum: "0",
    operasyon_durum_aciklama: "",
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  startDate: "",
  endDate: "",
  isLoading: false,
  error: "",
  facilityManagersLoading: false,
  facilityManagersError: "",
  updateCallLoading: false,
  updateCallError: "",
};

export const facilityCallSlice = createSlice({
  name: "facilityCalls",
  initialState: facilityCallInitialState,
  reducers: {
    handleSaveFacilityManagers(state, action) {
      state.facilityManagers = action.payload;
    },
    handleSaveFacilityCalls: (state, action) => {
      const { data, ...rest } = action.payload;
      state.facilityCalls = data;
      state.pagination = rest;
    },
    handleChangeUpdateFacilityCallInput: (state, action) => {
      state.facilityCall = {
        ...state.facilityCall,
        ...action.payload,
      };
    },
    handleChangeDateInput: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setFacilityManagersLoading: (state, action) => {
      state.facilityManagersLoading = action.payload;
    },
    setFacilityManagersError: (state, action) => {
      state.facilityManagersError = action.payload;
    },
    setUpdateCallLoading: (state, action) => {
      state.updateCallLoading = action.payload;
    },
    setUpdateCallError: (state, action) => {
      state.updateCallError = action.payload;
    },
  },
});

export const {
  handleSaveFacilityManagers,
  handleSaveFacilityCalls,
  handleChangeUpdateFacilityCallInput,
  handleChangeDateInput,
  setIsLoading,
  setError,
  setFacilityManagersLoading,
  setFacilityManagersError,
  setUpdateCallLoading,
  setUpdateCallError,
} = facilityCallSlice.actions;

export default facilityCallSlice.reducer;
