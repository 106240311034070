import useHandleSavePeriods from "../useHandleSavePeriods";
import { CustomButton } from "Components/Custom/UI";

interface SavePeriodsButtonProps {
  showButton: boolean;
}

const SavePeriodsButton = ({ showButton }: SavePeriodsButtonProps) => {
  const { handleSavePeriods } = useHandleSavePeriods();

  return (
    <div className="d-flex justify-content-end mt-4">
      {showButton && (
        <CustomButton variant="primary" onClick={handleSavePeriods}>
          Kaydet
        </CustomButton>
      )}
    </div>
  );
};

export default SavePeriodsButton;
