import { RoomPersonPriceRatesPage } from "Components/Custom/PricesAndQuotaPages";
import useRoomPersonPriceRates from "Components/Hooks/roomPersonPriceRates/usePersonPriceRates";
import PageContainer from "pages/PageContainer";
import { useLocation } from "react-router-dom";

const RoomPersonPriceRates = () => {
  const location = useLocation();
  const { cocuk_yas_id } = location.state || {};

  const { error, loading, isValidHotel, isLoading, ...props } =
    useRoomPersonPriceRates(cocuk_yas_id);

  return (
    <PageContainer title="Oda / Kişi / Fiyat Oranları">
      <RoomPersonPriceRatesPage
        isValidHotel={isValidHotel}
        data={true}
        loading={loading}
        error={error}
        isLoading={isLoading}
        {...props}
      />
    </PageContainer>
  );
};

export default RoomPersonPriceRates;
