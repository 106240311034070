import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { PersonPriceRatesInitialState } from "slices/pricesAndQuota/roomPersonPriceRates/reducer";
import { ChildRate, SaveRoomPersonPriceRates } from "types/PricesQuotas";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  saveRoomPersonPriceRates,
  getRoomPersonPriceRates,
} from "slices/pricesAndQuota/roomPersonPriceRates/thunk";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectRoomPersonPriceRateState = (state: {
    RoomPersonPriceRates: PersonPriceRatesInitialState;
  }) => state.RoomPersonPriceRates;

  const selectRoomPersonPriceRateProperties = createSelector(
    selectRoomPersonPriceRateState,
    (state) => ({
      roomPersonPriceRate: state.roomPersonPriceRate,
      roomPersonPriceRateState: state.roomPersonPriceRateState,
      checkedAccommodationTypes: state.checkedAccommodationTypes,
      isLoading: state.isLoading,
      error: state.error,
      saveRatesLoading: state.saveRatesLoading,
      saveRatesError: state.saveRatesError,
    })
  );

  const selectHotelState = (state: any) => state.Hotel;
  const selectHotelProperties = createSelector(selectHotelState, (state) => ({
    hotel: state.hotel,
  }));

  const {
    roomPersonPriceRate,
    roomPersonPriceRateState,
    checkedAccommodationTypes,
    saveRatesLoading,
    saveRatesError,
  } = useSelector(selectRoomPersonPriceRateProperties);

  const [childOptions, setChildOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = roomPersonPriceRate.cocuk_ozel_yas.length
      ? roomPersonPriceRate.cocuk_ozel_yas.map((item) => {
          return {
            value: item.yas_id,
            label: item.ozel_yas_ismi,
          };
        })
      : (Object.entries(roomPersonPriceRate.cocuk_ozel_yas).map(
          ([key, value]) => {
            return {
              value: value,
              label: key,
            };
          }
        ) as unknown as Option[]);

    options.unshift({ value: 0, label: "Tüm Tarihler İçin" });
    setChildOptions(options);
  }, [roomPersonPriceRate]);

  const { hotel } = useSelector(selectHotelProperties);

  const numberOfChildren =
    roomPersonPriceRateState.uygulanabilecek_max_konuk - 1 > 4
      ? 4
      : roomPersonPriceRateState.uygulanabilecek_max_konuk - 1;

  const calculateWithOutChild = (defaultRate: boolean) => {
    const withOutChild: ChildRate[] = [];

    const without = roomPersonPriceRateState.misafir_oranlari.filter(
      (rate) => rate.tek_yani_cocuk === "0"
    );

    const firstChildRate =
      without.find((rate) => rate.kisi_tipi === "c1")?.kisi_orani || "0";

    const secondChildRate =
      without.find((rate) => rate.kisi_tipi === "c2")?.kisi_orani || "0";

    const thirdChildRate =
      without.find((rate) => rate.kisi_tipi === "c3")?.kisi_orani || "0";

    const fourthChildRate =
      without.find((rate) => rate.kisi_tipi === "c4")?.kisi_orani || "0";

    numberOfChildren >= 1 &&
      withOutChild.push({
        kisi_tipi: "c1",
        kisi_sayisi: 1,
        tek_yani_cocuk: "0",
        kisi_orani: defaultRate ? firstChildRate : withOutChild[0]?.kisi_orani,
      });

    numberOfChildren >= 2 &&
      withOutChild.push({
        kisi_tipi: "c2",
        kisi_sayisi: 2,
        tek_yani_cocuk: "0",
        kisi_orani: defaultRate ? secondChildRate : withOutChild[1]?.kisi_orani,
      });

    numberOfChildren >= 3 &&
      withOutChild.push({
        kisi_tipi: "c3",
        kisi_sayisi: 3,
        tek_yani_cocuk: "0",
        kisi_orani: defaultRate ? thirdChildRate : withOutChild[2]?.kisi_orani,
      });

    numberOfChildren >= 4 &&
      withOutChild.push({
        kisi_tipi: "c4",
        kisi_sayisi: 4,
        tek_yani_cocuk: "0",
        kisi_orani: defaultRate ? fourthChildRate : withOutChild[3]?.kisi_orani,
      });

    withOutChild.sort((a, b) => a.kisi_tipi.localeCompare(b.kisi_tipi));

    return withOutChild;
  };

  const calculateWithChild = (defaultRate: boolean) => {
    const withChildArray: ChildRate[] = [];

    const withC = roomPersonPriceRateState.misafir_oranlari.filter(
      (rate) => rate.tek_yani_cocuk === "1"
    );

    const firstChildRate =
      withC.find((rate) => rate.kisi_tipi === "c1")?.kisi_orani || "0";

    const secondChildRate =
      withC.find((rate) => rate.kisi_tipi === "c2")?.kisi_orani || "0";

    const thirdChildRate =
      withC.find((rate) => rate.kisi_tipi === "c3")?.kisi_orani || "0";

    const fourthChildRate =
      withC.find((rate) => rate.kisi_tipi === "c4")?.kisi_orani || "0";

    numberOfChildren >= 1 &&
      withChildArray.push({
        kisi_tipi: "c1",
        kisi_sayisi: 1,
        tek_yani_cocuk: "1",
        kisi_orani: defaultRate ? firstChildRate : withChild[0]?.kisi_orani,
      });

    numberOfChildren >= 2 &&
      withChildArray.push({
        kisi_tipi: "c2",
        kisi_sayisi: 2,
        tek_yani_cocuk: "1",
        kisi_orani: defaultRate ? secondChildRate : withChild[1]?.kisi_orani,
      });

    numberOfChildren >= 3 &&
      withChildArray.push({
        kisi_tipi: "c3",
        kisi_sayisi: 3,
        tek_yani_cocuk: "1",
        kisi_orani: defaultRate ? thirdChildRate : withChild[2]?.kisi_orani,
      });

    numberOfChildren >= 4 &&
      withChildArray.push({
        kisi_tipi: "c4",
        kisi_sayisi: 4,
        tek_yani_cocuk: "1",
        kisi_orani: defaultRate ? fourthChildRate : withChild[3]?.kisi_orani,
      });

    withChildArray.sort((a, b) => a.kisi_tipi.localeCompare(b.kisi_tipi));

    return withChildArray;
  };

  const [isClicked, setIsClicked] = useState("0");
  const [withChild, setWithChild] = useState<ChildRate[]>(
    calculateWithChild(true)
  );

  const [withOutChild, setWithOutChild] = useState<ChildRate[]>(
    calculateWithOutChild(true)
  );

  useEffect(() => {
    setWithChild(calculateWithChild(true));
    setWithOutChild(calculateWithOutChild(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfChildren]);

  const handleSave = () => {
    const value = roomPersonPriceRateState.konaklama_cesitleri;
    const detailed = roomPersonPriceRateState.detayli_oda_kontenjan;
    const special = roomPersonPriceRateState.ozel_katsayi;

    const accomodation =
      detailed && special
        ? Object.entries(value)
            .filter(([key, value]) => checkedAccommodationTypes.includes(key))
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {} as { [key: string]: string })
        : detailed && !special
        ? checkedAccommodationTypes
        : [];

    const guestRates = [
      ...roomPersonPriceRateState.misafir_oranlari.filter(
        (rate) => rate.kisi_tipi === "y"
      ),
      ...withChild,
      ...withOutChild,
    ];

    const p = {
      otel_id: otel_id,
      oda_id: oda_id,
      cocuk_ozel_yas_id: roomPersonPriceRateState.ozel_katsayi
        ? roomPersonPriceRateState.cocuk_ozel_yas_ozel_katsayi || 0
        : roomPersonPriceRateState.cocuk_ozel_yas || 0,
      detayli_oda_kontenjan: roomPersonPriceRateState.detayli_oda_kontenjan,
      ozel_katsayi: roomPersonPriceRateState.ozel_katsayi,
      uygulanabilecek_max_yetiskin:
        roomPersonPriceRateState.uygulanabilecek_max_yetiskin,
      uygulanabilecek_max_konuk:
        roomPersonPriceRateState.uygulanabilecek_max_konuk,
    };

    const requestPayload: SaveRoomPersonPriceRates =
      detailed && special
        ? {
            ...p,
            konaklama_cesitleri: accomodation,
          }
        : detailed && !special
        ? {
            ...p,
            konaklama_cesitleri: accomodation,
            misafir_oranlari: {
              kisi_oranlari: guestRates,
            },
          }
        : {
            ...p,
            misafir_oranlari: {
              kisi_oranlari: guestRates,
            },
          };

    dispatch(
      saveRoomPersonPriceRates(requestPayload, () => {
        const payload = {
          otel_id: otel_id,
          oda_id: oda_id,
          cocuk_ozel_yas_id: 0,
        };

        dispatch(getRoomPersonPriceRates(payload));
      })
    );
  };

  const arrangeAccumodationTypes = (maxAdults: number, maxGuests: number) => {
    const array = Array.from({ length: maxAdults }, (_, i) => i + 1);
    const types = array.map((item) => {
      const maxChild = maxGuests - item > 4 ? 4 : maxGuests - item;
      const childArray = Array.from({ length: maxChild + 1 }, (_, i) => i);
      return childArray.map((child) => {
        return item.toString() + "+" + child.toString();
      });
    });

    return types.flat();
  };

  return {
    roomPersonPriceRate,
    roomPersonPriceRateState,
    numberOfChildren,
    withChild,
    setWithChild,
    withOutChild,
    setWithOutChild,
    isClicked,
    setIsClicked,
    calculateWithChild,
    calculateWithOutChild,
    childOptions,
    isLoading: saveRatesLoading,
    error: saveRatesError,
    hotel,
    handleSave,
    arrangeAccumodationTypes,
  };
};

export default useHandleForm;
