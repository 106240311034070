import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";

const useHandleAvailabilities = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const availabilities = calculatePriceForHotel.musaitlik;

  return { availabilities };
};

export default useHandleAvailabilities;
