import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { DefineCurrencyInitialState } from "slices/definitions/defineCurrency/reducer";
import { getCurrency } from "slices/definitions/defineCurrency/thunk";

const useDefineCurrency = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, error } = useSelector(
    (state: { DefineCurrency: DefineCurrencyInitialState }) =>
      state.DefineCurrency
  );

  useEffect(() => {
    dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useDefineCurrency;
