import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ForecastReportInitialState } from "slices/reports/forecastReport/reducer";
import { getForecastReport } from "slices/reports/forecastReport/thunk";
import { HotelInitialState } from "slices/hotel/reducer";
import moment from "moment";

const useForecastReport = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ForecastReport: ForecastReportInitialState }) =>
      state.ForecastReport
  );

  const { hotel, loading, error } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const params = {
    otel_id,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    if (otel_id) {
      dispatch(getForecastReport(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    isLoading,
    loading,
    error,
    isValidHotel,
  };
};

export default useForecastReport;
