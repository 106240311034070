import AdvancedEntryDateRangeSelector from "./AdvancedEntryDateRangeSelector";
import PeriodsContainer from "./PeriodsContainer";
import useHandleForm from "./useHandleForm";
import SavePeriodsButton from "./SavePeriodsButton";
import { withPageNeedsHotel } from "Components/Custom/HigherOrderComponents";
import ChildSpecialAgeSelector from "./ChildSpecialAgeSelector";
import { CustomLoadingPage } from "Components/Custom/UI";

const AdvancedEntryPage = () => {
  const {
    handleSave,
    data,
    isLoading,
    error,
    concept,
    childSpeacialAgeId,
    childSpecialAgeOptions,
    roomName,
    ...props
  } = useHandleForm();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <AdvancedEntryDateRangeSelector onSave={handleSave} {...props} />
      <ChildSpecialAgeSelector
        childSpeacialAgeId={childSpeacialAgeId}
        childSpecialAgeOptions={childSpecialAgeOptions}
      />
      <PeriodsContainer
        data={data}
        loading={isLoading}
        error={error}
        concept={concept}
        roomName={roomName}
      />
      <SavePeriodsButton showButton={data.veriler.length > 0} />
    </>
  );
};

export default withPageNeedsHotel(AdvancedEntryPage);
