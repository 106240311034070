import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BasicAvailabilityEntryPage } from "Components/Custom/PricesAndQuotaPages";
import { useHttpRequest } from "Components/Hooks";
import { GET_PRICES_QUOTA_DATA } from "helpers/url_helper";
import { useSearchParams } from "react-router-dom";
import { HotelInitialState } from "slices/hotel/reducer";
import PageContainer from "pages/PageContainer";

const BasicAvailabilityEntry = () => {
  const { hotel, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [searchParams] = useSearchParams();

  const idFromUrl = searchParams.get("otel_id") ?? "";

  const otel_id = idFromUrl ? idFromUrl : hotel?.otel_id;

  const { data, error, isLoading, httpRequest } = useHttpRequest();

  useEffect(() => {
    httpRequest({
      config: {
        method: "post",
        url: GET_PRICES_QUOTA_DATA,
        data: {
          otel_id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  return (
    <PageContainer title="Basit Müsaitlik Girişi">
      <BasicAvailabilityEntryPage
        isValidHotel={
          data &&
          data.odalar.length > 0 &&
          data.odalar[0]?.pansiyonlar.length > 0
        }
        loading={loading}
        error={error}
        data={data}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default BasicAvailabilityEntry;
