import {
  CustomFormContainer,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { FACILITY_ADD_NEW_IMAGES_FORM_DESCRIPTIONS } from "utils/pageDescriptions";
import FacilityImageSelector from "./FacilityImageSelector";

const FacilityAddNewImagesForm = () => {
  return (
    <CustomFormContainer title="Tesise Yeni Resim Ekleme" lg={12} xl={12}>
      <div className="d-flex flex-column gap-2 bg-white px-4 py-2 rounded">
        <CustomPageDescriptions
          descriptions={FACILITY_ADD_NEW_IMAGES_FORM_DESCRIPTIONS}
        />
      </div>
      <FacilityImageSelector />
    </CustomFormContainer>
  );
};

export default FacilityAddNewImagesForm;
