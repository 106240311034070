import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import RoomInfo from "./RoomInfo";

interface ReservationRoomsInfoProps {
  reservation: ReservationReview;
  reservationOtherRooms: ReservationReview[];
}

const ReservationRoomsInfo = ({
  reservation,
  reservationOtherRooms,
}: ReservationRoomsInfoProps) => {
  let count = 0;

  return (
    <CustomFormContainer title="Oda Bilgileri">
      <RoomInfo reservation={reservation} count={++count} />
      {reservationOtherRooms.map((reservationOtherRoom) => (
        <RoomInfo
          reservation={reservationOtherRoom}
          count={++count}
          key={reservationOtherRoom.rezervasyon_id}
        />
      ))}
    </CustomFormContainer>
  );
};

export default ReservationRoomsInfo;
