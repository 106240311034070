import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getOutboundCalls } from "slices/reports/outboundCallsList/thunk";

const useHandleForm = (defaultDates: {
  startDate: string;
  endDate: string;
}) => {
  const [filterForm, setFilterForm] = useState({
    start_date: defaultDates.startDate,
    end_date: defaultDates.endDate,
  });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
    };
    dispatch(getOutboundCalls(params));
  };

  const handdleResetFilter = () => {
    setFilterForm({
      start_date: defaultDates.startDate,
      end_date: defaultDates.endDate,
    });

    const params = {
      start_date: defaultDates.startDate,
      end_date: defaultDates.endDate,
    };

    dispatch(getOutboundCalls(params));
  };

  return {
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handdleResetFilter,
  };
};

export default useHandleForm;
