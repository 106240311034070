import { ForecastReportPage } from "Components/Custom/ReportsPages";
import useForecastReport from "Components/Hooks/reports/useForecastReport";
import PageContainer from "pages/PageContainer";

const ForecastReport = () => {
  const { isLoading, loading, error, isValidHotel } = useForecastReport();

  return (
    <PageContainer title="Forecast Raporu">
      <ForecastReportPage
        data={true}
        loading={loading}
        error={error}
        isValidHotel={isValidHotel}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default ForecastReport;
