import { DailyCallsChartsPage } from "Components/Custom/ReportsPages";
import useDailyCallsCharts from "Components/Hooks/reports/useDailyCallsCharts";
import PageContainer from "pages/PageContainer";

const DailyCallsCharts = () => {
  const { isLoading } = useDailyCallsCharts();

  return (
    <PageContainer title="Günlük Çağrı Raporu">
      <DailyCallsChartsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default DailyCallsCharts;
