import { CustomPhotoAlbum } from "Components/Custom/UI";
import useHandleAlbum from "./useHandleAlbum";

interface ViewFeaturedImagesAlbumProps {
  index: number;
  handleChangeIndex: (index: number) => void;
}

const ViewFeaturedImagesAlbum = ({
  index,
  handleChangeIndex,
}: ViewFeaturedImagesAlbumProps) => {
  const { album } = useHandleAlbum();

  return (
    <CustomPhotoAlbum
      images={album}
      index={index}
      handleChangeIndex={handleChangeIndex}
    />
  );
};

export default ViewFeaturedImagesAlbum;
