import { PAYMENT_METHODS } from "enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HourlyDiscountRequestPayload } from "types/DiscountsAndActions";
import useValidateForm from "./useValidateForm";
import { HourlyDiscountsInitialState } from "slices/discountsAndActions/hourlyDiscounts/reducer";
import { HotelInitialState } from "slices/hotel/reducer";

interface Option {
  value: string;
  label: string;
}

interface HotelOption {
  value: number;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const {
    hourlyDiscount,
    hourlyDiscountFormErrors,
    addDiscountLoading,
    updateDiscountLoading,
  } = useSelector(
    (state: { HourlyDiscounts: HourlyDiscountsInitialState }) =>
      state.HourlyDiscounts
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const [conceptsOptions, setConceptsOptions] = useState<HotelOption[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<HotelOption[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    if (hotel) {
      const newRoomsOptions: HotelOption[] = hotel.odalar.map((room) => ({
        value: room.id,
        label: room.oda_isim,
      }));

      const newConceptsOptions: HotelOption[] = hotel.pansiyonlar.map(
        (concept) => ({
          value: concept.id,
          label: concept.pansiyon_isim,
        })
      );

      const newPaymentMethodOptions: Option[] = [
        {
          value: PAYMENT_METHODS.normal,
          label: "Normal",
        },
        {
          value: PAYMENT_METHODS.kapida,
          label: "Kapıda Ödemeli",
        },
        {
          value: PAYMENT_METHODS.iptal_edilemez,
          label: "İptal Edilemez",
        },
        {
          value: PAYMENT_METHODS.kaporali,
          label: "Kaporalı",
        },
      ];
      setRoomsOptions(newRoomsOptions);
      setConceptsOptions(newConceptsOptions);
      setPaymentMethodOptions(newPaymentMethodOptions);
    }
  }, [hotel]);

  const addNewHourlyDiscountRequestPayload: HourlyDiscountRequestPayload = {
    otel_id: otel_id,
    otel_oda_id: oda_id,
    grup_id: hourlyDiscount.grup_id,
    radio_gun: hourlyDiscount.radio_gun,
    gun: hourlyDiscount.gun,
    indirim_orani: hourlyDiscount.indirim_orani,
    baslangic_saati: hourlyDiscount.baslangic_saati,
    bitis_saati: hourlyDiscount.bitis_saati,
    eb_birlikte: hourlyDiscount.eb_birlikte,
    kalode_birlikte: hourlyDiscount.kalode_birlikte,
    diger_birlikte: hourlyDiscount.diger_birlikte,
    tum_pansiyonlar: hourlyDiscount.tum_pansiyonlar,
    secili_pansiyonlar: hourlyDiscount.secili_pansiyonlar,
    tum_odeme_tipleri: hourlyDiscount.tum_odeme_tipleri,
    secili_odeme_tipleri: hourlyDiscount.secili_odeme_tipleri,
  };

  const updateHourlyDiscountRequestPayload: HourlyDiscountRequestPayload = {
    saatlik_indirim_id: hourlyDiscount.id,
    otel_id: otel_id,
    otel_oda_id: oda_id,
    grup_id: hourlyDiscount.grup_id,
    radio_gun: hourlyDiscount.radio_gun,
    gun: hourlyDiscount.gun,
    indirim_orani: hourlyDiscount.indirim_orani,
    baslangic_saati: hourlyDiscount.baslangic_saati,
    bitis_saati: hourlyDiscount.bitis_saati,
    eb_birlikte: hourlyDiscount.eb_birlikte,
    kalode_birlikte: hourlyDiscount.kalode_birlikte,
    diger_birlikte: hourlyDiscount.diger_birlikte,
    tum_pansiyonlar: hourlyDiscount.tum_pansiyonlar,
    secili_pansiyonlar: hourlyDiscount.secili_pansiyonlar,
    tum_odeme_tipleri: hourlyDiscount.tum_odeme_tipleri,
    secili_odeme_tipleri: hourlyDiscount.secili_odeme_tipleri,
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    hourlyDiscount,
    roomsOptions,
    conceptsOptions,
    paymentMethodOptions,
    addNewHourlyDiscountRequestPayload,
    updateHourlyDiscountRequestPayload,
    formErrors: hourlyDiscountFormErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
    addDiscountLoading,
    updateDiscountLoading,
  };
};

export default useHandleForm;
