import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {
  FacilityRoomsInitialState,
  handleChangeFacilityRoomImageFilesInput,
} from "slices/facility/facilityRooms/reducer";

import { CustomMultipleFileUploader } from "Components/Custom/UI";
import { useSelector } from "react-redux";
import AddNewImagesButton from "./AddNewImagesButton";

interface ImageSelectorProps {
  selectedRoom: number;
}

const ImageSelector = ({ selectedRoom }: ImageSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { facilityRoomImageFiles, addFacilityRoomImagesLoading } = useSelector(
    (state: { FacilityRooms: FacilityRoomsInitialState }) => state.FacilityRooms
  );

  return (
    <>
      <CustomMultipleFileUploader
        files={facilityRoomImageFiles as any}
        handleAddFiles={(fileItems) => {
          dispatch(handleChangeFacilityRoomImageFilesInput(fileItems));
        }}
        maxFiles={10}
      />
      <AddNewImagesButton
        facilityRoomImageFiles={facilityRoomImageFiles}
        selectedRoom={selectedRoom}
        isLoading={addFacilityRoomImagesLoading}
      />
    </>
  );
};

export default ImageSelector;
