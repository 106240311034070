import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveGroupsResponse,
  handleChangeGroupStatus,
  setIsLoading,
  setError,
  setChangeGroupStatusLoading,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { GET_GROUPS, CHANGE_GROUP_STATUS } from "helpers/url_helper";
import { Dispatch } from "redux";

type SuccessCallback = () => void;

export const getGroups =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_GROUPS);
      
      const res = (response as any).message ? response.data : response;

      dispatch(handleSaveGroupsResponse(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const changeGroupStatus =
  (grup_id: number, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeGroupStatusLoading(true));

      const response = await axios.post(CHANGE_GROUP_STATUS, {grup_id});

      const res = (response as any).message ? response.data : response;

      dispatch(handleChangeGroupStatus(grup_id));

      const successMessage =
      res.message
          ? res.message
          : "Grup durumu başarıyla değiştirildi.";

      dispatch(setToastSuccess(`${successMessage}`));

      successCallback();
    } catch (error) {
      dispatch(setToastError(error));
    } finally {
      dispatch(setChangeGroupStatusLoading(false));
    }
  };
