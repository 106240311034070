import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import ThemeSelectors from "./ThemeSelectors";
import ThemeTitleAndSubtitle from "./ThemeTitleAndSubtitle";
import ThemeContentAndLink from "./ThemeContentAndLink";
import ThemeImageSelector from "./ThemeImageSelector";
import AddNewThemeButton from "./AddNewThemeButton";

const AddNewThemeForm = () => {
  const {
    themeRegionOptions,
    themeTypesOptions,
    theme,
    addNewThemeRequestPayload,
    formErrors,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <CustomFormContainer>
      <ThemeSelectors
        values={theme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        themeRegionOptions={themeRegionOptions}
        themeTypesOptions={themeTypesOptions}
      />
      <ThemeTitleAndSubtitle
        values={theme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ThemeContentAndLink
        values={theme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <ThemeImageSelector
        values={theme}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <AddNewThemeButton
        values={theme}
        requestPayload={addNewThemeRequestPayload}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
      />
    </CustomFormContainer>
  );
};

export default AddNewThemeForm;
