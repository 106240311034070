import { ListLocationsPage } from "Components/Custom/DefinitionsPages";
import useListLocations from "Components/Hooks/definitions/useListLocations";
import PageContainer from "pages/PageContainer";

const ListLocations = () => {
  const { error, isLoading } = useListLocations();

  return (
    <PageContainer title="Lokasyon Listele">
      <ListLocationsPage loading={isLoading} error={error} />
    </PageContainer>
  );
};

export default ListLocations;
