import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/userOperations/updateUser/reducer";
import { UpdateUserFormErrors, UpdateUserPayload } from "types/userOperations";

interface UserEmailInputProps {
  values: UpdateUserPayload;
  formErrors: UpdateUserFormErrors;
  validateFormChange: (values: UpdateUserPayload) => void;
}

const UserEmailInput = ({
  values,
  formErrors,
  validateFormChange,
}: UserEmailInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Email Adresi"
        error={formErrors.email_adresi}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.email_adresi}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                email_adresi: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              email_adresi: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default UserEmailInput;
