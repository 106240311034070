import axios from "axios";

import { Dispatch } from "redux";

import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setIsResetCodeSent,
  setResetEmail,
} from "./reducer";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "helpers/url_helper";
import { handleErrors } from "helpers/axios_helpers";

interface SendMailFormData {
  email: string;
}

interface ResetPasswordFormData {
  code: string;
  password: string;
  password_confirmation: string;
}

export const userForgetPassword =
  (formData: SendMailFormData) => async (dispatch: Dispatch<any>) => {
    const data = {
      email: formData.email,
    };

    dispatch(userForgetPasswordError(""));
    dispatch(userForgetPasswordSuccess(""));

    try {
      const response = await axios.post(FORGOT_PASSWORD, data);

      if (response) {
        dispatch(userForgetPasswordError(""));
        dispatch(
          userForgetPasswordSuccess(
            "Sıfırlama kodu gönderildi. Lütfen e-posta adresinizi kontrol edin."
          )
        );
        dispatch(setIsResetCodeSent(true));
        dispatch(setResetEmail(formData.email));
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(userForgetPasswordError(message));
        dispatch(userForgetPasswordSuccess(""));
      });
    }
  };

export const userResetPassword =
  (formData: ResetPasswordFormData, resetEmail: string, navigate: any) =>
  async (dispatch: Dispatch<any>) => {
    const data = {
      email: resetEmail,
      reset_code: formData.code,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    };

    dispatch(userForgetPasswordError(""));
    dispatch(userForgetPasswordSuccess(""));

    try {
      const response = await axios.post(RESET_PASSWORD, data);

      if (response) {
        dispatch(userForgetPasswordError(""));
        dispatch(
          userForgetPasswordSuccess(
            "Şifreniz sıfırlandı. Giriş sayfasına yönlendiriliyorsunuz..."
          )
        );

        setTimeout(() => {
          navigate("/giris");
          dispatch(setIsResetCodeSent(false));
          dispatch(setResetEmail(""));
          dispatch(userForgetPasswordError(""));
          dispatch(userForgetPasswordSuccess(""));
        }, 3000);
      }
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(userForgetPasswordError(message));
        dispatch(userForgetPasswordSuccess(""));
      });
    }
  };
