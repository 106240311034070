import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";

import useHandleForm from "./useHandleForm";
import Select from "react-select";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleSaveSelectedTheme } from "slices/themes/listThemes/reducer";

interface Option {
  value: number;
  label: string;
}

interface ThemeSelectorProps {
  themesOptions: Option[];
}
const ThemeSelector = ({ themesOptions }: ThemeSelectorProps) => {
  const { selectedTheme, handleSelectTheme } = useHandleForm();

  const dispatch: Dispatch<any> = useDispatch();
  return (
    <CustomFormContainer>
      <CustomFormFieldContainer label="Tema">
        <Select
          value={themesOptions.find((option) => option.value === selectedTheme)}
          onChange={(selected: Option) => {
            dispatch(handleSaveSelectedTheme(selected.value));
            handleSelectTheme(selected.value);
          }}
          options={themesOptions}
          placeholder="Tema Seçiniz"
        />
      </CustomFormFieldContainer>
    </CustomFormContainer>
  );
};

export default ThemeSelector;
