import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  HotelSalesInitialState,
  handleSaveDistricts,
} from "slices/reports/hotelSales/reducer";

import {
  getHotelSales,
  getHotelSaleFilterDistricts,
} from "slices/reports/hotelSales/thunk";
import { GetFilteredHotelSalesRequestPayload } from "types/reports";

interface Option {
  label: string;
  value: number | string;
}

const useHandleForm = () => {
  const { hotelSaleFilter, districts, isLoading } = useSelector(
    (state: { HotelSales: HotelSalesInitialState }) => state.HotelSales
  );

  const [filterForm, setFilterForm] =
    useState<GetFilteredHotelSalesRequestPayload>({
      baslangic_tarihi: moment().subtract(1, "months").format("YYYY-MM-DD"),
      bitis_tarihi: moment().format("YYYY-MM-DD"),
      personel_id: 0,
      fiyat_saglayici: "",
      hesaplama_tarih_tipi: "kayit_tarihi",
      sehir_id: 0,
      ilce_id: 0,
      karsilama_kodu: [""],
    });

  const handleFormChange = (key: string, value: string) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleWelcomeCodeChange = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setFilterForm((prevState) => ({
        ...prevState,
        karsilama_kodu:
          value === ""
            ? [value]
            : [...prevState.karsilama_kodu, value].filter(
                (code) => code !== ""
              ),
      }));
    } else {
      setFilterForm((prevState) => ({
        ...prevState,
        karsilama_kodu: prevState.karsilama_kodu.filter(
          (code) => code !== value
        ).length
          ? prevState.karsilama_kodu.filter((code) => code !== value)
          : [""],
      }));
    }
  };

  const [staffOptions, setStaffOptions] = useState<Option[]>([]);
  const [providerOptions, setProviderOptions] = useState<Option[]>([]);
  const [calculateDateTypeOptions, setCalculateDateTypeOptions] = useState<
    Option[]
  >([]);
  const [cityOptions, setCityOptions] = useState<Option[]>([]);
  const [districtOptions, setDistrictOptions] = useState<Option[]>([]);
  const [welcomeCodeOptions, setWelcomeCodeOptions] = useState<Option[]>([]);

  useEffect(() => {
    const staffOptions = hotelSaleFilter.personel_listesi.map((staff) => ({
      label: staff.ad_soyad,
      value: staff.id,
    }));

    staffOptions.unshift({ label: "Tüm Personeller", value: 0 });

    const providerOptions = hotelSaleFilter.fiyat_saglayici.map((provider) => ({
      label: provider.value,
      value: provider.key,
    }));

    const calculateDateTypeOptions = hotelSaleFilter.hesaplama_tarih_tipi.map(
      (calculateDateType) => ({
        label: calculateDateType.value,
        value: calculateDateType.key,
      })
    );

    const cityOptions = hotelSaleFilter.sehirler.map((city) => ({
      label: city.lokasyon,
      value: city.otel_lokasyon_id,
    }));

    cityOptions.unshift({ label: "Tüm Şehirler", value: 0 });

    const welcomeCodeOptions = hotelSaleFilter.karsilama_kodlari.map(
      (welcomeCode) => ({
        label:
          welcomeCode.karsilama_kodu === ""
            ? "Tüm Karşılama Kodları"
            : welcomeCode.karsilama_kodu,
        value: welcomeCode.karsilama_kodu,
      })
    );

    setStaffOptions(staffOptions);
    setProviderOptions(providerOptions);
    setCalculateDateTypeOptions(calculateDateTypeOptions);
    setCityOptions(cityOptions);
    setWelcomeCodeOptions(welcomeCodeOptions);
  }, [hotelSaleFilter, districts]);

  useEffect(() => {
    const districtOptions = districts.map((district) => ({
      label: district.lokasyon,
      value: district.otel_lokasyon_id,
    }));

    districtOptions.unshift({ label: "Tüm İlçeler", value: 0 });

    setDistrictOptions(districtOptions);
  }, [districts]);

  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    dispatch(getHotelSales(filterForm));
  };

  const handleResetFilter = () => {
    setFilterForm({
      baslangic_tarihi: moment().subtract(1, "months").format("YYYY-MM-DD"),
      bitis_tarihi: moment().format("YYYY-MM-DD"),
      personel_id: 0,
      fiyat_saglayici: "",
      hesaplama_tarih_tipi: "kayit_tarihi",
      sehir_id: 0,
      ilce_id: 0,
      karsilama_kodu: [""],
    });

    dispatch(getHotelSales(filterForm));
  };

  const handleGetDistricts = (sehir_id: number) => {
    dispatch(getHotelSaleFilterDistricts({ sehir_id }));
  };

  const handleResetDistricts = () => {
    dispatch(handleSaveDistricts([]));
    setFilterForm((prevState) => ({
      ...prevState,
      ilce_id: 0,
    }));
  };

  return {
    filterForm,
    handleFormChange,
    handleWelcomeCodeChange,
    staffOptions,
    providerOptions,
    calculateDateTypeOptions,
    cityOptions,
    districtOptions,
    welcomeCodeOptions,
    isLoading,
    onGetFilteredList: handleGetFilteredList,
    onGetDistricts: handleGetDistricts,
    onResetFilter: handleResetFilter,
    onResetDistricts: handleResetDistricts,
  };
};

export default useHandleForm;
