import { CustomButton } from "Components/Custom/UI";

interface UpdatePriceProviderButtonProps {
    handleUpdate: () => void
}
const UpdatePriceProviderButton = ({
    handleUpdate,
}: UpdatePriceProviderButtonProps) => {

    return (
        <div className="d-flex justify-content-end gap-4 ">
            <CustomButton
                variant="primary"
                onClick={() => {
                    handleUpdate();
                }}
            >
                Güncelle
            </CustomButton>
        </div>
    )
}

export default UpdatePriceProviderButton;