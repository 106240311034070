import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { useState } from "react";
import {
  CATEGORIZE_FACILITY_DESCRIPTION,
  CHANGE_FACILITY_CATEGORY,
} from "utils/pageDescriptions";
import useHandleForm from "./useHandleForm";
import CategorySelector from "./FormFields/CategorySelector";
import FacilityList from "./FormFields/FacilityList";
import CategorySelectBoxes from "./FormFields/CategorySelectBoxes";
import ChangeHotelCategoryButtons from "./FormFields/ChangeHotelCategoryButtons";

interface ChangeFacilityCategoryFormProps {
  isLoading: boolean;
}

const ChangeFacilityCategoryForm = ({
  isLoading,
}: ChangeFacilityCategoryFormProps) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const {
    values,
    categoryOptions,
    facilityOptions,
    handleFormChange,
    handleSelectCategory,
    handleSave,
    handleClearForm,
  } = useHandleForm(setSelectedCategory);

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div style={{ padding: "8px 16px" }}>
        <CustomPageDescriptions
          variant="danger"
          descriptions={CATEGORIZE_FACILITY_DESCRIPTION}
        />
        <CategorySelector
          options={categoryOptions}
          handleSelectCategory={handleSelectCategory}
        />
        {selectedCategory !== "" ? (
          <>
            <FacilityList
              values={values}
              options={facilityOptions}
              handleFormChange={handleFormChange}
            />
            <CategorySelectBoxes
              values={values}
              options={categoryOptions}
              handleFormChange={handleFormChange}
            />
            <div className="my-4">
              <CustomPageDescriptions
                variant="danger"
                descriptions={CHANGE_FACILITY_CATEGORY}
              />
            </div>
            <ChangeHotelCategoryButtons
              handleSave={handleSave}
              handleClearForm={handleClearForm}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ChangeFacilityCategoryForm;
