import { FilePondFile } from "filepond";
import { AddUserState } from "types/userOperations";

const useHandlePayload = (requestPayload: FormData, values: AddUserState) => {
  const getPayload = () => {
    Object.entries(values).forEach(([key, value]) => {
      if (key === "resim") {
        const file = value as FilePondFile;
        requestPayload.append(key, file.file, file.file.name);
      }
      if (value instanceof Array) {
        requestPayload.append(key, JSON.stringify(value));
      } else {
        requestPayload.append(key, value);
      }
    });

    return requestPayload;
  };

  return getPayload;
};

export default useHandlePayload;
