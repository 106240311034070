import { CustomButton } from "Components/Custom/UI";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { resetChangeHotelAssigneeForm } from "slices/userOperations/personnelHotelAssignments/reducer";
import {
  changeHotelAssignees,
  getHotelsUsersAndCities,
} from "slices/userOperations/personnelHotelAssignments/thunk";
import { ChangeHotelAssigneePayload } from "types/userOperations";

interface ChangeHotelAssigneeButtonProps {
  values: ChangeHotelAssigneePayload;
  validateForm: (values: ChangeHotelAssigneePayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  isLoading: boolean;
}

const ChangeHotelAssigneeButton = ({
  values,
  validateForm,
  handleSubmitted,
  isLoading,
}: ChangeHotelAssigneeButtonProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const onClick = () => {
    const { isValid } = validateForm(values);

    handleSubmitted(true);

    if (isValid) {
      dispatch(
        changeHotelAssignees(values, () => {
          dispatch(getHotelsUsersAndCities());
          dispatch(resetChangeHotelAssigneeForm());
          handleSubmitted(false);
        })
      );
    }
  };

  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Kaydet
      </CustomButton>
    </div>
  );
};

export default ChangeHotelAssigneeButton;
